<template>
  <div class="dashboard-switch">
    <div class="dashboard-switch__switch-wrap">
      <span class="label">{{ title }}</span>
      <el-switch
        v-model="switchState"
        :active-color="activeColor"
        :inactive-color="inactiveColor"
        :disabled="disabled"
        @change="handlerChangeSwitch"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'DashboardSwitch',

  props: {
    title: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueSwitch: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      switchState: this.valueSwitch,
      activeColor: this.$theme.accentColor,
      inactiveColor: this.$theme.dangerColor,
    };
  },


  watch: {
    valueSwitch() {
      this.reloadDataComponent();
    },
  },

  methods: {
    reloadDataComponent() {
      this.switchState = this.valueSwitch;
      this.activeColor = this.$theme.accentColor;
      this.inactiveColor = this.$theme.dangerColor;
    },

    handlerChangeSwitch(value) {
      this.$emit('handlerChangeSwitch', value);
    },
  },
};
</script>
