// reports views
import Reports from '@/views/Reports.vue';

// import MainReport from '@/views/Reports/MainReport.vue';
import PaymentsReport from '@/views/Reports/PaymentsReport.vue';
import PaymentsSubReport from '@/views/Reports/PaymentsSubReport.vue';
import CustomReport from '@/views/Reports/CustomReport.vue';

export default [
  {
    path: '/reports',
    component: Reports,
    meta: {
      requiresAuth: true,
      view: 'reports',
    },
    children: [
      // {
      //   path: 'main/:type',
      //   component: MainReport,
      //   name: 'main-report',
      //   meta: {
      //     view: 'main-report',
      //   },
      // },
      {
        path: 'payments',
        component: PaymentsReport,
        name: 'payments',
        meta: {
          view: 'payments',
        },
      },
      {
        path: 'payments/sub',
        component: PaymentsSubReport,
        name: 'payments-sub',
        meta: {
          view: 'payments',
        },
      },
      {
        path: 'custom',
        component: CustomReport,
        name: 'custom-report',
        meta: {
          view: 'custom-report',
        },
      },
      {
        path: '',
        redirect: 'custom',
      },
      {
        path: '*',
        redirect: 'custom',
      },
    ],
  },
];
