<template>
  <el-dropdown
    trigger="click"
    placement="bottom"
    @command="exportData"
  >
    <ui-button
      class="btn export"
      lib="fa"
      substyle="fas"
      icon="long-arrow-down"
    />
    <el-dropdown-menu
      slot="dropdown"
      class="ddgroup"
    >
      <el-dropdown-item
        v-for="(item, index) in data"
        :key="index"
        :command="item.command"
      >
        {{ item.label }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'ExportData',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    exportData(format) {
      this.$emit('exportData', format);
    },
  },
};
</script>
