export default {
  mainColor: 'green',
  logoName: 'caswino-logo.svg',
  maxLogoHeight: '20px',
  accentColor: '#20815e',
  activeColor: '#20815e',
  dangerColor: '#d26370',
  uiTheme: {
    primaryColor: '#1b1c2d',
    dangerColor: '#d26370',
    infoColor: '#3b94e3',
    warningColor: '#ffc700',
    lightColor: '#fff',
    darkColor: '#303634',
    // the-header
    headerColor: '#1b1c2d',
    headerBorder: 'rgba(255, 255, 255, 0.2)',
    headerText: '#ffffff',
    headerTextHover: '#ffffff',
    headerTextBorder: '#ffc700',
    reportDropdownBackground: '#000',
    reportDropdownBackgroundHover: '#1b1c2d',
    reportDropdownActive: '#ffc700',
    reportDropdownActiveHover: '#ffc700',
    reportDropdownTitleBorder: 'rgba(255, 255, 255, 0)',
    reportDropdownTitleColor: '#ffffff',
    scrollThumb: '#c3c3c3',
    scrollBackground: '#20815e',
    colorFirst: 'rgba(0, 0, 0, 0.4)',
    colorSecond: '#ffce05',
    backLink: '#1b1c2d',
  },
};
