<template>
  <div v-if="isOpen" v-show="isRedraw" class="add-payment-method-popup">
    <div class="popup">
      <div class="head">
        <span class="name">
          {{
            paymentId
              ? $t('settings.paymentMethods.popup.titleEdit')
              : $t('settings.paymentMethods.popup.title')
          }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <adm-ui-scrollbar
        wrap-style="max-height: 75vh; height: auto;"
        :native="false"
      >
        <div v-loading="isLoading" class="body">
          <div class="ui-fg-1">
            <ui-input
              v-model="data.name"
              class="form-input ui-m-xl-b"
              :label="$t('settings.paymentMethods.popup.name')"
              autosize
              size="big"
              :placeholder="$t('settings.paymentMethods.popup.placeholderName')"
              type="text"
              :error="getError('name')"
            />
            <div class="ui-m-md-b row-bg ui-d-flex ui-ai-center ui-jc-space-between">
              <div class="ui-d-flex ui-ai-center">
                {{ $t('settings.paymentMethods.popup.status') }}
              </div>
              <div class="ui-d-flex ui-ai-center">
                <span :style="{ color: data.isDisabled ? $theme.dangerColor : $theme.accentColor}" class="toggle-label">
                  {{ $t(`settings.paymentMethods.statuses.${data.isDisabled ? 'disabled' : 'active'}`) }}
                </span>
                <el-switch
                  v-model="status"
                  class="ui-m-sm-l"
                  :active-color="$theme.accentColor"
                  :inactive-color="$theme.dangerColor"
                />
              </div>
            </div>
            <div v-if="getError('hidden')" class="field-error">
              {{ getError('hidden') }}
            </div>

            <el-divider class="divider" />

            <div class="row ui-m-xl-b">
              <div class="field">
                <div class="label-text">
                  {{ $t('settings.paymentMethods.popup.currencies') }}
                </div>
                <el-radio-group
                  v-model="allowedCurrencies"
                  class="file-attachments-radio ui-m-md-t"
                  size="medium"
                >
                  <el-radio label="all">
                    {{ $t('settings.paymentMethods.popup.all') }}
                  </el-radio>
                  <el-radio label="selected">
                    {{ $t('settings.paymentMethods.popup.selected') }}
                  </el-radio>
                </el-radio-group>

                <ui-select
                  v-model="selectedCurrencies"
                  :placeholder="$t('settings.paymentMethods.popup.placeholderCurrencies')"
                  :options="currencies"
                  width="100%"
                  :disabled="allowedCurrencies === 'all'"
                  :multiple="true"
                  :filterable="true"
                  :remote="false"
                  value-prop="code"
                  label-prop="name"
                  list-label-prop="code"
                  :max-count-tags="5"
                  class="ui-m-md-t"
                  :class="{ error: getError('currencies') }"
                />
                <div v-if="getError('currencies')" class="field-error">
                  {{ getError('currencies') }}
                </div>
              </div>
            </div>

            <ui-input
              v-model="data.walletRegexp"
              class="form-input ui-m-xl-b"
              :label="$t('settings.paymentMethods.popup.walletRegexp')"
              autosize
              size="big"
              :placeholder="$t('settings.paymentMethods.popup.walletRegexp')"
              type="text"
              :error="getError('wallet_regexp')"
            />

            <ui-input
              v-model="data.walletPlaceholder"
              class="form-input ui-m-xl-b"
              :label="$t('settings.paymentMethods.popup.walletPlaceholder')"
              autosize
              size="big"
              :placeholder="
                $t('settings.paymentMethods.popup.walletPlaceholder')
              "
              type="text"
              :error="getError('wallet_placeholder')"
            />

            <div class="row ui-p-sm-b">
              <UploadFile
                v-model="data.files"
                accept=".png, .jpg"
                :limit-file="1"
                class="upload-file"
                :upload-files-action="uploadFile"
                :remove-file="removeFile"
              >
                <template slot="title">
                  <div class="ui-m-xl-t">
                    <span class="upload-title">{{
                      $t('settings.paymentMethods.popup.logo')
                    }}</span>
                  </div>
                </template>
                <template slot="upload-text">
                  <span class="upload-text" :class="{ disabled: data.files.length > 0 }">
                    {{ $t('settings.paymentMethods.popup.dropFileHereOr') }}
                    <em>{{ $t('settings.paymentMethods.popup.selectFile') }}</em>
                  </span>
                </template>
              </UploadFile>
              <div
                v-if="getError('files') || getError('logo_base64')"
                class="field-error"
              >
                {{ getError('logo_base64') || getError('files') }}
              </div>
            </div>

            <el-divider class="divider" />

            <div class="limits-label">
              {{ $t('settings.mediaBuyingPaymentMethods.limits') }}
            </div>
            <div class="row-bg limits ui-m-xl-t">
              <div class="label">
                {{ $t('settings.mediaBuyingPaymentMethods.default') }}
              </div>
              <div class="ui-d-flex ui-m-md-t">
                <ui-range-input
                  :value="data.defaultLimit"
                  label=""
                  class="card-filter__range-input"
                  error-tooltip
                  :error="getRangeError(['limits', 0])"
                  type="number"
                  @change="
                    $set(data.defaultLimit, 'min', $event.min);
                    $set(data.defaultLimit, 'max', $event.max);
                  "
                />

                <el-select
                  v-model="data.defaultLimit.currency_code"
                  :placeholder="$t('settings.paymentMethods.popup.placeholderCurrencies')"
                  class="ui-m-xl-l select"
                  size="big"
                  filterable
                  :class="{ error: getError(['limits', 0, 'currency_code']) }"
                >
                  <el-option
                    v-for="currency in [...(data.defaultLimit.currency_code ? [getCurrencyByCode(data.defaultLimit.currency_code)] : []), ...filteredCurrencyOptions]"
                    :key="currency.code"
                    :label="currency.name"
                    :value="currency.code"
                  />
                </el-select>
              </div>
            </div>

            <div class="row-bg limits ui-m-xl-t">
              <div class="ui-d-flex ui-jc-space-between">
                <span class="label">
                  {{ $t('settings.mediaBuyingPaymentMethods.otherCurrencies') }}
                </span>
                <div :style="{color: $theme.accentColor }" class="cursor-pointer" @click="addCurrency">
                  <i class="fas fa-plus plus-icon-custom" /><span class="ui-m-sm-l">{{ $t('settings.mediaBuyingPaymentMethods.addCurrency') }}</span>
                </div>
              </div>
              <div v-for="(limit, i) in data.limits" :key="i" class="ui-d-flex ui-ai-center ui-m-md-t">
                <ui-range-input
                  :value="limit"
                  label=""
                  class="card-filter__range-input"
                  type="number"
                  error-tooltip
                  :error="getRangeError(['limits', i + 1])"
                  @change="
                    $set(limit, 'min', $event.min);
                    $set(limit, 'max', $event.max);
                  "
                />
                <el-select
                  v-model="limit.currency_code"
                  :placeholder="$t('settings.paymentMethods.popup.placeholderCurrencies')"
                  class="ui-m-xl-l select"
                  size="big"
                  filterable
                  :class="{ error: getError(['limits', i + 1, 'currency_code']) }"
                >
                  <el-option
                    v-for="currency in [...(limit.currency_code ? [getCurrencyByCode(limit.currency_code)] : []), ...filteredCurrencyOptions]"
                    :key="currency.code"
                    :label="currency.name"
                    :value="currency.code"
                  />
                </el-select>
                <ui-icon
                  size="14px"
                  class="ui-m-md-l remove-currency"
                  lib="fa"
                  substyle="fas"
                  name="times"
                  :color="$theme.dangerColor"
                  @click.native="removeCurrency(i)"
                />
              </div>
            </div>
          </div>
        </div>
      </adm-ui-scrollbar>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="isLoading"
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          @click="handleSave()"
        >
          {{ $t('crm.buttons.save') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActivitiesLanguageMixin from '@/views/CRM/mixins/activities-language-mixin';
import UploadFile from '@/components/UploadFile';
import errorHandleMixin from '@/views/mixins/error-hadle';
import UiRangeInput from '@/components/UIElements/UiRangeInput.vue';

const getBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const defaultLimit = {
  currency_code: '',
  is_default: true,
  max: '',
  min: '',
};

const formatLimit = limit => ({
  currency_code: limit.currency_code,
  is_default: limit.is_default,
  max: limit.max_amount,
  min: limit.min_amount,
});

export default {
  name: 'AddPaymentMethodMediaBuyingPopup',
  components: {
    UiRangeInput,
    UploadFile,
  },
  mixins: [ActivitiesLanguageMixin, errorHandleMixin],
  data() {
    return {
      isOpen: false,
      isLoading: true,
      isRedraw: false,
      paymentId: null,
      allowedCurrencies: 'all',
      data: {
        ...this.getDefaultForm(),
      },
    };
  },

  computed: {
    ...mapGetters({
      currencies: 'misc/currencies',
    }),
    filteredCurrencyOptions() {
      return this.currencies.filter(el => ![this.data.defaultLimit.currency_code, ...this.data.limits
        .map(({ currency_code }) => currency_code),
      ].includes(el.code));
    },
    status: {
      get() {
        return !this.data.isDisabled;
      },
      set(val) {
        this.data.isDisabled = !val;
      },
    },
    selectedCurrencies: {
      get() {
        return this.data.currenciesCodes;
      },
      set(value) {
        this.data.currenciesCodes = value;
      },
    },
  },
  watch: {},
  created() {},
  methods: {
    getRangeError(path) {
      const min = this.$_.get(this.errors, [...path, 'min_amount']);
      const max = this.$_.get(this.errors, [...path, 'max_amount']);

      return {
        min,
        max,
      };
    },
    getCurrencyByCode(code) {
      return this.currencies?.find(el => el.code === code);
    },
    addCurrency() {
      this.data.limits.push({
        ...defaultLimit,
        is_default: false,
      });
    },
    removeCurrency(i) {
      this.data.limits = [...this.data.limits.slice(0, i), ...this.data.limits.slice(i + 1)];
      delete this.errors.limits;
    },
    uploadFile(asset) {
      if (!asset) return;

      this.errors.files = null;

      if (asset.file.size > 100000) {
        // 100kb
        this.errors.files = [
          { message: this.$t('settings.paymentMethods.popup.limitFileSize') },
        ];
        this.data.files = [];
        return;
      }

      getBase64(asset.file).then((res) => {
        this.data.files = [
          {
            uid: asset.file.uid,
            name: asset.file.name,
            file: res,
          },
        ];
      });
    },
    removeFile() {
      this.data.files = [];
    },
    open(id) {
      this.isRedraw = false;
      this.isOpen = true;

      this.$nextTick(() => {
        setTimeout(() => {
          this.isRedraw = true;
        }, 100);
      });

      this.data = this.getDefaultForm();
      this.errors = {};
      this.paymentId = id;

      if (id) {
        this.isLoading = true;
        this.$api.getControlMediaBuyingPaymentMethodById(id).then((res) => {
          const {
            name,
            hidden,
            logo_base64,
            wallet_regexp,
            wallet_placeholder,
            currencies,
            allowed_currencies,
            limits,
          } = res.data.payload;
          this.allowedCurrencies = allowed_currencies;

          this.data = {
            name,
            isDisabled: hidden,
            walletRegexp: wallet_regexp,
            walletPlaceholder: wallet_placeholder,
            limits: [...limits.filter(limit => !limit.is_default).map(formatLimit)],
            defaultLimit: formatLimit(limits.find(limit => limit.is_default) || { ...defaultLimit }),
            files: logo_base64 ? [{
              uid: 1,
              name: 'File uploaded',
              file: logo_base64,
            }] : [],
          };

          this.selectedCurrencies = currencies;
        })
          .catch(() => this.close())
          .finally(() => {
            setTimeout(() => {
              this.isLoading = false;
            }, 300);
          });
      } else {
        this.isLoading = false;
      }
    },
    close() {
      this.isOpen = false;
      this.isLoading = true;
    },
    handleSave() {
      const {
        name,
        files,
        isDisabled,
        walletRegexp,
        walletPlaceholder,
        currenciesCodes,
      } = this.data;


      const params = {
        name,
        ...(files.length && { logo_base64: files[0].file }),
        hidden: isDisabled,
        wallet_regexp: walletRegexp,
        wallet_placeholder: walletPlaceholder,
        ...(this.allowedCurrencies === 'selected' && {
          currencies: currenciesCodes,
        }),
        allowed_currencies: this.allowedCurrencies,
        limits: [this.data.defaultLimit, ...this.data.limits].map(limit => ({
          currency_code: limit.currency_code,
          is_default: limit.is_default,
          max_amount: limit.max,
          min_amount: limit.min,
        })),
      };

      const promise = this.paymentId
        ? this.$api.putControlMediaBuyingPaymentMethods(this.paymentId, params)
        : this.$api.postControlMediaBuyingPaymentMethods(params);

      promise
        .then(() => {
          this.close();
          this.$emit('save');
        })
        .catch((e) => {
          this.errors = e.data.errors;
        });
    },
    getDefaultForm() {
      return {
        name: '',
        defaultLimit: { ...defaultLimit },
        limits: [],
        isDisabled: false,
        walletRegexp: '',
        walletPlaceholder: '',
        files: [],
        currenciesCodes: [],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.add-payment-method-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
    width: 100%;
  }

  .limits-label {
    font-size: 16px;
    font-weight: 500;
  }

  .row-bg {
    padding: 8px;
    border-radius: 4px;
    background-color: var(--crm-panel-bg);
    font-size: 14px;

    &.limits {
      padding: 16px;
      flex-direction: column;

      .label {
        font-size: 14px;
        font-weight: 500;
      }
    }

    /deep/ .ui-range-input {
      .label {
        margin: 0;
      }

      .wrapper {
        width: 100%;
        flex-direction: row;
        min-width: auto;
        margin: 0;
      }
    }
  }

  .popup {
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 8vh;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;

    .head {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      .name {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
      }
      .close {
        font-size: 15px;
        cursor: pointer;
        &:hover {
          color: var(--main-color) !important;
        }
      }
    }

    .body {
      padding: 16px 24px;
      display: flex;
      position: relative;
      max-height: 84vh;
      min-height: 215px;

      .card__title {
        .card-content {
          .label {
            font-size: 24px;
            font-weight: 500;
            line-height: 1.25;
          }
        }
      }
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      .ui-button + .ui-button {
        margin-left: 8px;
      }
    }
  }


  .file-attachments-radio {
    /deep/ {
      .el-radio + .el-radio {
        margin-left: 10px;
      }

      .el-radio {
        margin-right: 0;

        .el-radio__label {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }

  .divider {
    margin-top: 16px;
    margin-bottom: 16px;
    background-color: var(--border-color);

    &.active {
      margin-bottom: 8px;
    }
  }

  .label-text {
    font-size: 14px;
    color: var(--crm-panel-text);
    margin-bottom: 5px;
    display: block;
  }

  .select {
    width: 100%;

    /deep/ {
      .el-input__suffix-inner {
        align-items: center;
        display: flex;
      }
    }

    &.error {
      /deep/ {
        .el-input__inner {
          border-color: var(--danger-color);
        }
      }
    }
  }

  .toggle-label {
    font-weight: 400;
    font-size: 12px;
  }

  .field-error {
    text-align: right;
  }

  .upload-file {
    /deep/ {
      .upload {
        margin-top: 5px;

        .el-upload-dragger {
          padding: 10px;
        }
      }
    }
  }

  .form-input {
    /deep/ {
      .input-error {
        text-transform: initial !important;
        font-weight: 400 !important;
      }
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .upload-text {
    width: 100%;
    color: #7d7d7d;
    font-size: 13px;

    em {
      color: #20815e;
    }

    &.disabled {
      em {
        color: #7d7d7d;
      }
    }
  }

  .remove-currency {
    cursor: pointer;
  }
}
</style>
