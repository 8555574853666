<template>
  <ui-confirm
    ref="confirm"
    class="crm-activity-actions__confirm-send-test-email"
    :width="480"
    type="success"
    :action-name="$t('crm.buttons.confirm')"
    action="send_test_email"
    action-icon="check"
    :action-disabled="isConfirmSendTestDisabled || isLoading"
    @save="handleConfirmSendTest"
  >
    <div
      slot="title"
      v-html="$t('crm.activities.actions.send_test_email.title')"
    />
    <div class="body__wrap ui-m-xxxl-b">
      <i class="fas fa-exclamation-triangle success" />
      <div
        v-if="confirmSendTestActions"
        class="crm-activity-actions__confirm-send-test-email-msg body__msg"
        v-html="$t('crm.activities.actions.send_test_email.msg', {
          mailName: confirmSendTestActions.defaultName
            ? confirmSendTestActions.defaultName
            : $t('crm.activities.actions.empty_name'),
          email: adminInfo.email,
        })"
      />
    </div>
    <div class="select-block ui-m-xxxl-b">
      <span class="label">{{ $t('crm.activities.actions.send_test_email.emails_title') }}</span>
      <el-input
        v-model="emails"
        :label="$t('crm.activities.actions.send_test_email.emails_placeholder')"
        type="textarea"
        :rows="6"
        tabindex="1"
        :placeholder="$t('crm.activities.actions.send_test_email.emails_placeholder')"
        :class="{'error-textarea': emailsError}"
      />
      <div class="ui-d-flex ui-ai-center ui-jc-end">
        <crm-error-panel
          v-if="emailsError"
          class="ui-g-md ui-ai-baseline"
        >
          {{ emailsError }}
        </crm-error-panel>
      </div>
    </div>
    <div
      v-if="confirmSendTestActions"
      class="crm-activity-actions__confirm-send-test-email-checkbox-wrapper"
    >
      <el-checkbox
        v-model="isConfirmSendTestSelectedAll"
        class="crm-activity-actions__confirm-send-test-email-checkbox"
        :label="$t('crm.activities.actions.send_test_email.all_localizations')"
        :disabled="isConfirmSendTestSelectedAllDisabled"
      />
      <div
        v-for="(actionGroup, index) in confirmSendTestActions.actions"
        :key="index"
        :class="[
          'ui-d-flex',
          'ui-g-w-md',
          'crm-activity-actions__confirm-send-test-email-checkbox',
        ]"
      >
        <el-checkbox
          v-model="confirmSendTestSelectedActions[index]"
          class="ui-g-md"
          :label="languageIndex[actionGroup.language_code].name"
          :disabled="isConfirmSendTestActionsDisabled[index]"
        />
        <ActionIcon
          v-if="isConfirmSendTestActionsDisabled[index]"
          class="ui-g-md"
          :tooltip="$t('crm.tooltips.empty_mail_message')"
        >
          <div>
            <ui-icon
              name="exclamation-triangle"
              :color="$theme.accentColor"
              lib="fa"
              :size="12"
            />
          </div>
        </ActionIcon>
      </div>
    </div>
  </ui-confirm>
</template>

<script>

import CrmErrorPanel from '@/views/CRM/components/CrmErrorPanel.vue';

export default {
  name: 'ConfirmSendTestEmail',
  components: {
    CrmErrorPanel,
  },
  props: {
    currentBrand: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      confirmSendTestSelectedActions: [],
      confirmSendTestActions: null,
      emails: '',
      errors: {},
    };
  },
  computed: {
    emailsError() {
      return this.getErrorMessage(this.$_.get(this.errors, 'emails', []));
    },
    languageIndex() {
      const languages = this.$store.state.misc.languages || [];

      return languages.reduce((acc, item) => {
        acc[item.code] = item;
        return acc;
      }, {});
    },
    isConfirmSendTestSelectedAll: {
      get() {
        if (this.confirmSendTestActions) {
          const filtered = this.confirmSendTestSelectedActions
            .filter((item, index) => !this.isConfirmSendTestActionsDisabled[index]);
          return filtered.length > 0 && filtered.every(item => item);
        }

        return false;
      },
      set(value) {
        this.confirmSendTestSelectedActions = this.confirmSendTestActions.actions
          .map((item, index) => (this.isConfirmSendTestActionsDisabled[index] ? false : value));
      },
    },
    isConfirmSendTestDisabled() {
      return this.confirmSendTestSelectedActions.every(item => !item);
    },
    isConfirmSendTestSelectedAllDisabled() {
      return this.isConfirmSendTestActionsDisabled.every(item => !!item);
    },
    isConfirmSendTestActionsDisabled() {
      return this.confirmSendTestActions.actions.map(action => !action.message.trim());
    },
    adminInfo() {
      return this.$store.getters['auth/adminInfo'];
    },
  },
  methods: {
    getErrorMessage(error) {
      if (Array.isArray(error)) {
        return this.getErrorMessage(error
          .filter(err => !!err)
          .find(err => this.getErrorMessage(err)));
      }

      return error?.message || '';
    },
    handleConfirmSendTest() {
      if (this.isLoading) return;

      this.isLoading = true;

      const actions = this.confirmSendTestActions.actions
        .filter((item, index) => this.confirmSendTestSelectedActions[index]);

      const emails = this.emails.split('\n')
        .map(email => email.trim())
        .filter(email => !!email);

      const payload = {
        site_id: this.currentBrand,
        emails,
        actions: actions.map(item => ({
          email_subject: item.email_subject,
          message: item.message,
          email_template_id: item.email_template_id,
        })),
      };

      this.$api.sendActivityTestEmails(payload)
        .then(() => {
          this.close();
          this.$noty.info(this.$t('crm.activities.actions.send_test_email.success'));
        })
        .catch((errors) => {
          if (errors.data.code === 'VALIDATION_ERROR') {
            this.errors = errors.data.errors;
          }
        })
        .finally(() => this.isLoading = false);
    },
    open({ actions, selectedActions }) {
      this.confirmSendTestSelectedActions = selectedActions;
      this.confirmSendTestActions = actions;
      this.errors = {};
      this.emails = '';
      this.isLoading = false;

      this.$refs.confirm.open();
    },
    close() {
      this.$refs.confirm.close();
    },
  },
};
</script>

<style lang="scss">
.crm-activity-actions__confirm-send-test-email {
  .select-block {
    display: flex;
    flex-direction: column;

    .label {
      font-size: 14px;
      padding-bottom: 5px;
    }
  }

  .body {
    .error-textarea {
      .el-textarea__inner {
        border-color: var(--danger-color)!important;
      }
    }
  }
}
</style>
