import * as qs from 'qs';
import { clearObject } from './helper';

export default {
  updateGetParams(data = {}) {
    /*
    * Чистим объект от не валидных значений
    * */
    const dataLoc = clearObject(data);
    window.history.replaceState({}, null, `${window.location.origin}${window.location.pathname}?${this.calculateUrl(dataLoc)}`);
  },
  calculateUrl(hash) {
    return qs.stringify(hash, { encode: false });
  },
  getParseParams(hash, protectedKey = []) {
    let _getParams = window.location.search;

    if (_getParams[0] === '?') {
      _getParams = _getParams.substring(1);
    }

    const _data = qs.parse(_getParams, {
      charset: 'utf-8',
      charsetSentinel: true,
    });
    /*
    * убираем лишние ключи для того чтобы не сломалась система, при изминении url)
    * */
    if (protectedKey.length !== 0) {
      // eslint-disable-next-line
      for (const key in _data) {
        if (!(protectedKey.indexOf(key) + 1)) {
          delete _data[key];
        }
      }
    }

    return _data;
  },
};
