<template>
  <div v-if="groupsAll.length" class="dashboard-grouping-metric">
    <div class="dashboard-grouping-metric__switch-wrap">
      <span class="label">{{ $t('dashboards.grouping.grouping') }}</span>
      <el-switch
        v-model="switchState"
        :active-color="activeColor"
        :inactive-color="inactiveColor"
        :disabled="disabled"
        @change="handlerChangeSwitch"
      />
    </div>
    <div v-if="switchState" class="dashboard-grouping-metric__controls-wrap">
      <div class="dashboard-grouping-metric__select-wr">
        <span class="label">{{ $t('dashboards.grouping.group_by') }}</span>
        <adm-ui-select
          :data="groupsAll"
          :default="dimension"
          @select="handlerSelectGroup"
        />
      </div>
      <div class="dashboard-grouping-metric__other-wrap">
        <span class="dashboard-grouping-metric__text">{{
          $t('dashboards.grouping.and_show')
        }}</span>
        <adm-ui-select
          :data="resultCount"
          :default="count"
          @select="handlerSelectCount"
        />
        <span class="dashboard-grouping-metric__text">{{
          $t('dashboards.grouping.result')
        }}</span>
        <div class="vertical-hr" />
        <el-checkbox
          v-model="otherValue"
          class="dashboard-grouping-metric__checkbox"
          @change="handlerShowOther"
        >
          {{ $t('dashboards.grouping.other_value') }}
        </el-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardGroupingMetric',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    groupingSwitch: {
      type: Boolean,
      default: false,
    },
    seriesGroupByDimensionName: {
      type: String,
      default: null,
    },
    seriesGroupByLimit: {
      type: Number,
      default: 5,
    },
    seriesGroupByAddOthers: {
      type: Boolean,
      default: false,
    },
    selectCountValue: {
      type: Array,
      default() {
        return [3, 5, 10, 15, 20];
      },
    },
    groupsAll: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      switchState: this.groupingSwitch,
      activeColor: this.$theme.accentColor,
      inactiveColor: this.$theme.dangerColor,
      otherValue: this.seriesGroupByAddOthers,
      count: this.seriesGroupByLimit,
      dimension: this.seriesGroupByDimensionName,
    };
  },

  computed: {
    resultCount() {
      return this.selectCountValue.map(count => ({
        value: count,
        label: `${this.$t('dashboards.grouping.top')} ${count}`,
      }));
    },
  },

  watch: {
    groupingSwitch() {
      this.reloadDataComponent();
    },
  },

  methods: {
    reloadDataComponent() {
      this.switchState = this.groupingSwitch;
      this.activeColor = this.$theme.accentColor;
      this.inactiveColor = this.$theme.dangerColor;
      this.otherValue = this.seriesGroupByAddOthers;
      this.count = this.seriesGroupByLimit;
      this.dimension = this.seriesGroupByDimensionName;
    },

    handlerSelectGroup(value) {
      this.$emit('handlerSelectGroup', value);
    },

    handlerSelectCount(value) {
      this.$emit('handlerSelectCount', value);
    },

    handlerChangeSwitch(value) {
      this.$emit('handlerChangeSwitch', value);
    },

    handlerShowOther(value) {
      this.$emit('handlerShowOther', value);
    },
  },
};
</script>
