export default {
  // Live Latest Events

  // Get 20 latest sport bets
  getNewSportBets(params) {
    return this.affiliatesApi.get('/live/latest-events/new-sport-bets', { params });
  },
  // Get 20 latest deposits
  getNewDeposits(params) {
    return this.affiliatesApi.get('/live/latest-events/new-deposits', { params });
  },

  // Live Top Event
  getTop20EventsWithoutResult(params) {
    return this.affiliatesApi.get('/live/top-events/top-20-events-without-result', { params });
  },
  getTop20EventsWithResult(params) {
    return this.affiliatesApi.get('/live/top-events/top-20-events-with-result', { params });
  },
};
