const colors = [
  '#20815e',
  '#d26370',
  '#ffce06',
  '#303634',
  '#c3c3c3',
  '#000000',
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#f7a35c',
  '#8085e9',
  '#f15c80',
  '#e4d354',
  '#2b908f',
  '#f45b5b',
  '#91e8e1',
  '#7cb5ec',
  '#7cb5ec',
  '#90ed7d',
  '#f7a35c',
  '#8085e9',
];

export default colors;
