<template>
  <div v-if="isOpen" class="marketing-material-popup">
    <div class="popup">
      <div class="head">
        <span class="name">{{ $t(`marketingMaterials.popup.${actionName}`) }}
          {{ $t(`marketingMaterials.popup.title`) }}</span>
        <ui-icon
          :size="20"
          class="close"
          lib="fa"
          substyle="fas"
          name="times"
          @click.native="close"
        />
      </div>
      <adm-ui-scrollbar
        wrap-style="max-height: 75vh; height: auto;"
        :native="false"
      >
        <div class="form full-width">
          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t(`marketingMaterials.popup.form.name.label`) }}
                <span class="required">*</span></span>
              <ui-input
                v-model="name"
                :placeholder="
                  $t(`marketingMaterials.popup.form.name.placeholder`)
                "
                autosize
                :class="{ error: isInvalidField('name') }"
                @blur="touchField('name')"
              />
            </div>
          </div>

          <div v-if="!isUpdate" class="row">
            <div class="field">
              <span class="label-text">{{
                $t(`marketingMaterials.popup.form.type.label`)
              }}</span>
              <el-radio v-model="type" :label="'banner'">
                {{ $t(`marketingMaterials.popup.form.type.banner`) }}
              </el-radio>
              <el-radio v-model="type" :label="'landing'">
                {{ $t(`marketingMaterials.popup.form.type.landing`) }}
              </el-radio>
            </div>
          </div>

          <div v-if="type === 'banner' && !isUpdate" class="row">
            <div class="field">
              <span class="label-text">{{
                $t(`marketingMaterials.popup.form.bannerType.label`)
              }}</span>
              <el-radio v-model="bannerType" :label="'simple_banner'">
                {{
                  $t(`marketingMaterials.popup.form.bannerType.simple_banner`)
                }}
              </el-radio>
              <el-radio v-model="bannerType" :label="'html5_banner'">
                {{
                  $t(`marketingMaterials.popup.form.bannerType.html5_banner`)
                }}
              </el-radio>
            </div>
          </div>

          <div class="row">
            <div class="field">
              <span class="label-text">{{
                $t(`marketingMaterials.popup.form.status.label`)
              }}</span>
              <el-radio v-model="status" :label="'active'">
                {{ $t(`marketingMaterials.popup.form.status.active`) }}
              </el-radio>
              <el-radio v-model="status" :label="'inactive'">
                {{ $t(`marketingMaterials.popup.form.status.inactive`) }}
              </el-radio>
            </div>
          </div>

          <div class="row">
            <div class="field">
              <span class="label-text">{{
                $t(`affiliates.offer_popup.brands`)
              }}</span>
              <div class="card-filter__radio-wrap">
                <el-radio-group
                  v-model="brand_visibility"
                  @change="checkBrands(brand_visibility)"
                >
                  <el-radio label="all" class="checkbox">
                    {{ $t(`affiliates.offer_popup.brands_all`) }}
                  </el-radio>
                  <el-radio label="only_selected" class="checkbox">
                    {{ $t(`affiliates.offer_popup.brands_only_selected`) }}
                  </el-radio>
                </el-radio-group>
              </div>
              <ui-tags-input
                v-model="visible_brands"
                :disabled="brand_visibility === 'all'"
                :typeahead-activation-threshold="0"
                :only-existing-tags="true"
                :existing-tags="list_brands"
                :typeahead="true"
                :placeholder="$t(`affiliates.offer_popup.add_brands`)"
                class="card-filter__input-tag"
                :class="{ error: isInvalidField(`visible_brands`) }"
                typeahead-style="dropdown"
                @blur="touchField(`visible_brands`)"
              />
            </div>
          </div>
          <div v-if="isAffiliatesFilter" class="row">
            <div class="field">
              <span class="label-text">{{
                $t(`marketingMaterials.popup.form.visibility.label`)
              }}</span>
              <div class="card-filter__radio-wrap">
                <el-radio-group
                  v-model="visibility"
                  @change="checkAffiliates(visibility)"
                >
                  <el-radio label="public" class="checkbox">
                    {{ $t(`marketingMaterials.popup.form.visibility.public`) }}
                  </el-radio>
                  <el-radio label="private" class="checkbox">
                    {{ $t(`marketingMaterials.popup.form.visibility.private`) }}
                  </el-radio>
                  <el-radio label="hidden" class="checkbox">
                    {{ $t(`marketingMaterials.popup.form.visibility.hidden`) }}
                  </el-radio>
                </el-radio-group>
                <ui-select
                  v-model="affiliate_ids"
                  :placeholder="
                    $t('marketingMaterials.popup.form.visibility.placeholder')
                  "
                  :class="{ 'disable-select': visibility !== 'private' }"
                  :filter-method="getAffiliates"
                  :remote="true"
                  value-prop="id"
                  label-prop="email"
                  width="100%"
                  filterable
                  multiple
                />
              </div>
            </div>
          </div>

          <div v-if="type === 'landing'" class="row">
            <div class="field">
              <span class="label-text">{{
                                         $t(`marketingMaterials.popup.form.supported_platforms.label`)
                                       }}
                <span class="required">*</span></span>
              <el-checkbox v-model="supported_platforms" :label="'mobile'">
                {{
                  $t(`marketingMaterials.popup.form.supported_platforms.mobile`)
                }}
              </el-checkbox>
              <el-checkbox v-model="supported_platforms" :label="'desktop'">
                {{
                  $t(
                    `marketingMaterials.popup.form.supported_platforms.desktop`
                  )
                }}
              </el-checkbox>
            </div>
          </div>

          <div v-if="type === 'landing'" class="row">
            <div class="field">
              <span class="label-text">{{ $t(`marketingMaterials.popup.form.landing_path.label`) }}
                <span v-if="!isUpdate" class="required">*</span></span>
              <ui-input
                v-model="landing_path"
                :placeholder="
                  $t(`marketingMaterials.popup.form.landing_path.placeholder`)
                "
                autosize
                :disabled="isUpdate"
                :class="{
                  error:
                    isInvalidField('landing_path') || serverErrors.landing_path,
                }"
                @blur="touchField('landing_path')"
                @focus="touchLandingPath"
              />
            </div>
            <div v-if="serverErrors.landing_path" class="field__error">
              {{ generateErrorMessage(serverErrors.landing_path) }}
            </div>
          </div>

          <div
            v-for="(asset, index) in assets"
            :key="asset.id"
            class="asset-item"
          >
            <div class="asset-item__remove" @click="removeAsset(index)">
              <ui-icon
                size="14px"
                lib="fa"
                substyle="fas"
                name="times"
                :color="$theme.dangerColor"
                class="zip-icon"
              />
            </div>
            <div class="asset-item__preview">
              <img
                v-if="isImage(asset.file)"
                :src="asset.file.preview_url"
                :title="asset.file.filename"
              >
              <ui-icon
                v-else
                size="67px"
                lib="fa"
                substyle="fas"
                name="file-archive"
                color="#C0C4CC"
              />
            </div>
            <div class="asset-item__right">
              <div class="asset-item__name">
                <ui-icon
                  size="14px"
                  lib="fa"
                  substyle="far"
                  name="file"
                  color="#000"
                  class="asset-item__icon"
                />
                <span :title="asset.file.filename">{{
                  asset.file.filename
                }}</span>
                <el-upload
                  action=""
                  :show-file-list="false"
                  :http-request="uploadFile(index)"
                  :accept="acceptedTypes"
                  :disabled="!!isUploading"
                >
                  <el-tooltip
                    :content="$t('marketingMaterials.messages.replace_file')"
                    placement="top"
                    :open-delay="300"
                  >
                    <ui-icon
                      size="14px"
                      lib="fa"
                      substyle="fas"
                      name="upload"
                      :color="$theme.accentColor"
                      class="asset-item__download-icon"
                    />
                  </el-tooltip>
                </el-upload>
                <a :href="asset.file.download_url">
                  <el-tooltip
                    :content="$t('marketingMaterials.messages.download_file')"
                    placement="top"
                    :open-delay="300"
                  >
                    <ui-icon
                      size="14px"
                      lib="fa"
                      substyle="fas"
                      name="download"
                      :color="$theme.accentColor"
                      class="asset-item__download-icon"
                    />
                  </el-tooltip>
                </a>
              </div>

              <div class="asset-item__inputs">
                <div v-if="type === 'banner'" class="asset-item__column">
                  <span class="label-text">{{ $t(`marketingMaterials.popup.form.width.label`) }}
                    <span class="required">*</span></span>
                  <ui-input
                    v-model.number="assets[index].width"
                    width="53px"
                    :class="{
                      error: isInvalidField(`assets.$each[${index}].width`),
                    }"
                    @blur="touchField(`assets.$each[${index}].width`)"
                  />
                </div>

                <div v-if="type === 'banner'" class="asset-item__column">
                  <span class="label-text">{{ $t(`marketingMaterials.popup.form.height.label`) }}
                    <span class="required">*</span></span>
                  <ui-input
                    v-model.number="assets[index].height"
                    width="53px"
                    :class="{
                      error: isInvalidField(`assets.$each[${index}].height`),
                    }"
                    @blur="touchField(`assets.$each[${index}].height`)"
                  />
                </div>

                <div class="asset-item__column">
                  <span class="label-text">{{
                                             $t(`marketingMaterials.popup.form.language_codes.label`)
                                           }}
                    <span class="required">*</span></span>
                  <el-select
                    v-model="assets[index].language_codes"
                    :placeholder="
                      $t(
                        `marketingMaterials.popup.form.language_codes.placeholder`
                      )
                    "
                    filterable
                    multiple
                    class="select"
                    :class="{
                      error: isInvalidField(
                        `assets.$each[${index}].language_codes`
                      ),
                    }"
                    @blur="touchField(`assets.$each[${index}].language_codes`)"
                  >
                    <el-option
                      v-for="languages in languages"
                      :key="languages.code"
                      :label="languages.name"
                      :value="languages.code"
                    />
                  </el-select>
                </div>
              </div>
              <div
                v-if="serverErrors.assets && serverErrors.assets[index]"
                class="asset-item__error"
              >
                {{ generateErrorMessage(serverErrors.assets[index].file_id) }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="field">
              <span class="label-text">{{
                $t(`marketingMaterials.popup.form.upload.label`)
              }}</span>
              <el-upload
                class="upload"
                drag
                :multiple="type === 'banner'"
                action=""
                :show-file-list="false"
                :http-request="uploadFile()"
                :accept="acceptedTypes"
                :disabled="!!isUploading"
              >
                <div v-if="isUploading" class="uploading-progress">
                  <div class="el-uploading__text">
                    <i class="el-icon-loading" />
                    {{ $t('marketingMaterials.messages.uploading') }}
                  </div>
                  <el-progress
                    :percentage="uploadPercentage"
                    :color="$theme.accentColor"
                  />
                </div>
                <div v-else>
                  <i class="el-icon-upload" />
                  <div class="el-upload__text">
                    {{ $t('marketingMaterials.messages.drop-file-here-or') }}
                    <em>{{
                      $t('marketingMaterials.messages.click-to-upload')
                    }}</em>
                  </div>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
      </adm-ui-scrollbar>
      <div class="foot">
        <ui-button
          color="red"
          class="btn cancel-button"
          lib="fa"
          substyle="fas"
          icon="times"
          size="big"
          @click.native="close"
        >
          {{ $t(`marketingMaterials.popup.cancel`) }}
        </ui-button>
        <ui-button
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          size="big"
          :disabled="$v.$invalid || assets.length < 1 || !!isUploading"
          :loading="!!isUploading"
          @click="save"
        >
          {{ $t(`marketingMaterials.popup.save`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';
import validateMixin from './mixin/validate';
import { isLandingPath } from '@/helpers/validators';

export default {
  name: 'MarketingMaterialPopup',

  mixins: [validateMixin],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    isAffiliatesFilter: {
      type: Boolean,
      required: true,
    },
    initialData: {
      type: [Object],
      default() {
        return {
          name: '',
          type: 'simple_banner',
          assets: [],
          status: 'active',
          landing_path: '',
          supported_platforms: [],
          brand_visibility: 'all',
          visibility: 'public',
          visible_brands: null,
          affiliates: {},
        };
      },
    },
    serverErrors: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      affiliatesSearch: '',
      name: this.initialData.name,
      type: this.getType(),
      bannerType: this.getBannerType(),
      assets: this.mapAssets(this.initialData.assets),
      status: this.initialData.status,
      brand_visibility: this.initialData.brand_visibility,
      visibility: this.initialData.visibility,
      visible_brands: this.getVisibleBrands(),
      affiliate_ids: this.getAffiliatesIds(),
      landing_path: this.initialData.landing_path,
      supported_platforms: Array.isArray(this.initialData.supported_platforms)
        ? this.initialData.supported_platforms
        : [],
      uploadPercentage: 0,
      isUploading: 0,
      list_brands: {},
    };
  },

  validations() {
    return {
      name: {
        required,
      },
      landing_path: {
        required: requiredIf(
          data => !data.isUpdate && data.type === 'landing',
        ),
        isLandingPath,
      },
      supported_platforms: {
        required: requiredIf(data => data.type === 'landing'),
      },
      visible_brands: {
        required: requiredIf(
          data => data.brand_visibility === 'only_selected',
        ),
        minLength: 1,
      },
      affiliate_ids: {
        required: requiredIf(data => data.visibility === 'private'),
        minLength: 1,
      },
      assets: {
        $each: {
          width: {
            required: requiredIf(() => this.type === 'banner'),
          },
          height: {
            required: requiredIf(() => this.type === 'banner'),
          },
          language_codes: {
            required,
            minLength: 1,
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      languages: 'misc/languages',
      brands: 'misc/brands',
    }),

    isUpdate() {
      return !!this.initialData.id;
    },
    actionName() {
      return !this.initialData.id ? 'add' : 'edit';
    },

    acceptedTypes() {
      return this.type === 'banner' && this.bannerType === 'simple_banner'
        ? '.png, .jpeg, .jpg, .gif'
        : '.zip';
    },
  },

  watch: {
    isOpen(value) {
      [
        this.name,
        this.type,
        this.bannerType,
        this.assets,
        this.status,
        this.landing_path,
        this.supported_platforms,
        this.brand_visibility,
        this.visibility,
        this.visible_brands,
        this.affiliate_ids,
      ] = [
        this.initialData.name,
        this.getType(),
        this.getBannerType(),
        this.mapAssets(this.initialData.assets),
        this.initialData.status,
        this.initialData.landing_path,
        Array.isArray(this.initialData.supported_platforms)
          ? this.initialData.supported_platforms
          : [],
        this.initialData.brand_visibility,
        this.initialData.visibility,
        this.getVisibleBrands(),
        this.getAffiliatesIds(),
      ];
      this.listBrands();
      if (value && this.isAffiliatesFilter) {
        this.getAffiliates();
      }

      if (!value) {
        this.resetForm();
      }
    },
  },

  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },

  destroyed() {
    window.removeEventListener('keyup', this.keyupHandler);
  },

  methods: {
    checkBrands(value) {
      this.errors = {};
      if (value === 'all') {
        this.visible_brands = null;
      }
    },
    checkAffiliates(value) {
      this.errors = {};
      if (value !== 'private') {
        this.affiliate_ids = [];
      }
    },
    listBrands() {
      this.list_brands = _.mapValues(_.keyBy(this.brands, 'id'), 'name');
    },
    getAffiliates(search) {
      const query = {
        limit: 100,
        offset: 0,
        search,
        account_status: ['approved'],
      };

      return new Promise((resolve, reject) => {
        this.$api
          .getAffiliates(query)
          .then((response) => {
            const array = _.unionBy(
              this.initialData.affiliates,
              response.data.payload,
              'id',
            );
            resolve(array);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getVisibleBrands() {
      return _.map(this.initialData.visible_brands, 'site_id');
    },

    getAffiliatesIds() {
      return _.map(this.initialData.affiliates, 'id');
    },

    close() {
      this.resetForm();
      this.$emit('close');
    },

    uploadFile(assetId) {
      return async (request) => {
        const formData = new FormData();
        formData.append('files', request.file);

        const onProgressUploadHandler = (progressEvent) => {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total),
            10,
          );

          // Hotfix for top progressbar
          // See more: https://bwaffiliates.atlassian.net/secure/RapidBoard.jspa?rapidView=26&projectKey=ACTECH&modal=detail&selectedIssue=ACTECH-3
          this.$Progress.set(this.uploadPercentage);
        };

        this.isUploading += 1;

        const {
          data: {
            payload: { files },
          },
        } = await this.$api.postFile(formData, onProgressUploadHandler);
        this.isUploading -= 1;
        this.uploadPercentage = 0;
        if (assetId !== undefined) {
          this.$set(this.assets, assetId, {
            ...this.assets[assetId],

            file_id: files.id,
            width: this.isImage(files) ? files.metadata.width : this.assets[assetId].width,
            height: this.isImage(files) ? files.metadata.height : this.assets[assetId].height,
            file: files,
          });

          this.$emit('clear-server-errors', `assets[${assetId}]`);
        } else {
          this.assets.push({
            file_id: files.id,
            width: this.isImage(files) ? files.metadata.width : undefined,
            height: this.isImage(files) ? files.metadata.height : undefined,
            language_codes: [],
            file: files,
          });
        }
      };
    },

    isImage(file) {
      return !!file.preview_url;
    },

    mapAssets(assets) {
      return assets.map(({
        language_codes, sizes, file_id, file,
      }) => {
        const [width, height] = this.getType() === 'banner' && sizes[0] ? sizes[0].split('x') : [];
        return {
          file_id,
          width: Number(width),
          height: Number(height),
          language_codes: language_codes.map(code => code.toLowerCase()),
          file,
        };
      });
    },

    getType() {
      return this.initialData.type === 'landing' ? 'landing' : 'banner';
    },

    getBannerType() {
      return this.getType() === 'banner'
        ? this.initialData.type
        : 'simple_banner';
    },

    save() {
      const assets = this.assets.map(
        ({
          file_id, width, height, language_codes,
        }) => (this.getType() === 'banner'
          ? {
            file_id,
            sizes: [`${Number(width)}x${Number(height)}`],
            language_codes,
          }
          : {
            file_id,
            language_codes,
          }),
      );

      // Prepare data for create landing or banner
      const data = this.isUpdate
        ? {
          name: this.name,
          assets,
          status: this.status,
          brand_visibility: this.brand_visibility,
          affiliate_ids: this.affiliate_ids,
          visibility: this.visibility,
          visible_brands: this.visible_brands,
        }
        : {
          name: this.name,
          type: this.type === 'landing' ? 'landing' : this.bannerType,
          assets,
          status: this.status,
          brand_visibility: this.brand_visibility,
          affiliate_ids: this.affiliate_ids,
          visibility: this.visibility,
          visible_brands: this.visible_brands,
        };

      // Add field for landing create
      if (this.type === 'landing' && !this.isUpdate) {
        data.landing_path = this.landing_path;
      }

      // Add field for landing
      if (this.type === 'landing') {
        data.supported_platforms = this.supported_platforms;
      }

      if (!this.initialData.id) {
        this.$emit('create', data);
      } else {
        this.$emit('update', data);
      }
    },

    removeAsset(index) {
      this.assets.splice(index, 1);
      this.$emit('clear-server-errors', `assets[${index}]`);
    },

    touchLandingPath() {
      this.$emit('clear-server-errors', 'landing_path');
    },

    keyupHandler(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    },

    generateErrorMessage(errors) {
      const errorMessages = errors.reduce((acc, error) => {
        acc.push(error.message);

        return acc;
      }, []);

      return errorMessages.join('\n');
    },

    resetForm() {
      this.resetTouchedFields();

      this.name = '';
      this.type = 'banner';
      this.bannerType = 'simple_banner';
      this.brand_visibility = 'all';
      this.visibility = 'public';
      this.assets = [];
      this.status = 'active';
      this.landing_path = '';
      this.supported_platforms = [];

      this.uploadPercentage = 0;
      this.isUploading = false;
    },
  },
};
</script>
