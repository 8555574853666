<template>
  <img
    :src="require(`@/assets/images/logos/${logoName}`)"
    alt="logo"
    class="primary-logo"
  >
</template>

<script>
export default {
  name: 'PrimaryLogo',
  data() {
    return {
      logoName: this.$theme.logoName,
    };
  },
};
</script>

<style lang="scss" scoped>
.primary-logo{
  width: 185px;
  // height: 34px;
  object-fit: contain;
}
</style>
