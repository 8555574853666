<template>
  <div class="rule-block ui-p-xl-b ui-m-xl-b">
    <div class="title-wrap rev-share">
      <h2 class="title">
        {{ 'RevShare' }}
      </h2>
      <el-switch
        v-model="isRevShare"
        :disabled="isDisabledRs"
        class="ui-g-md ui-m-md-r"
        :active-color="$theme.accentColor"
        :inactive-color="$theme.dangerColor"
      />
    </div>
    <div v-if="isRevShare" class="card-filter p-8">
      <div class="card-filter__header pb-0">
        <h2 class="card-filter__sub-title">
          {{ $t('affiliates.offer_popup.separate') }}
        </h2>
        <el-switch
          v-model="isSeparate"
          class="ui-m-md-r"
          :disabled="!ableToSetRevShare"
          :active-color="$theme.accentColor"
          :inactive-color="$theme.dangerColor"
        />
      </div>
    </div>
    <div v-if="isRevShare" class="rev-share-container">
      <div class="card-filter rev-share-block">
        <div class="card-filter__header pb-4">
          <h2 class="card-filter__sub-title">
            {{ isSeparate ? $t('affiliates.offer_popup.sport') : $t('affiliates.offer_popup.sport_casino') }}
          </h2>
          <ui-button
            icon="plus"
            lib="fa"
            substyle="fas"
            color="#20815E"
            :disabled="!ableToSetRevShare"
            :icon-size="12"
            class="btn ui-filter-select__add-btn add-level"
            @click="addLevel('sport_levels')"
          >
            {{ $t('affiliates.offer_popup.add_level') }}
          </ui-button>
        </div>
        <div class="card-filter__body">
          <div
            v-for="(item, i) in payoutRule.settings.rev_share.sport_levels"
            :key="i"
            class="ui-d-flex ui-ai-center ui-m-md-t"
          >
            <ui-input
              v-model="item.ftd_count"
              pre-icon="greater-than-equal"
              class="rev-share-input"
              :class="{ 'readonly': i === 0 }"
              post-text="FTD"
              :readonly="i === 0"
              :disabled="!ableToSetRevShare"
              error-tooltip
              :error="getError(['sport_levels', i, 'ftd_count'])"
            />
            <hr class="thin-hr">
            <ui-input
              v-model="item.percent"
              class="rev-share-input"
              post-icon="percent"
              error-tooltip
              :disabled="!ableToSetRevShare"
              :error="getError(['sport_levels', i, 'percent'])"
            />
            <ui-icon
              size="14px"
              class="ui-m-md-l remove-level"
              :class="{ 'disabled': i === 0 || !ableToSetRevShare }"
              lib="fa"
              substyle="fas"
              name="times"
              :disabled="!ableToSetRevShare"
              :color="$theme.dangerColor"
              @click.native="removeLevel(i, 'sport_levels')"
            />
          </div>
        </div>
      </div>
      <div v-show="isSeparate" class="card-filter rev-share-block">
        <div class="card-filter__header pb-4">
          <h2 class="card-filter__sub-title">
            {{ $t('affiliates.offer_popup.casino') }}
          </h2>
          <ui-button
            icon="plus"
            lib="fa"
            substyle="fas"
            color="#20815E"
            :icon-size="12"
            :disabled="!ableToSetRevShare"
            class="btn ui-filter-select__add-btn add-level"
            @click="addLevel('casino_levels')"
          >
            {{ $t('affiliates.offer_popup.add_level') }}
          </ui-button>
        </div>
        <div class="card-filter__body">
          <div v-for="(item, i) in payoutRule.settings.rev_share.casino_levels" :key="i" class="ui-d-flex ui-ai-center ui-m-md-t">
            <ui-input
              v-model="item.ftd_count"
              pre-icon="greater-than-equal"
              class="rev-share-input"
              :class="{ 'readonly': i === 0 }"
              post-text="FTD"
              :disabled="!ableToSetRevShare"
              :readonly="i === 0"
              error-tooltip
              :error="getError(['casino_levels', i, 'ftd_count'])"
            />
            <hr class="thin-hr">
            <ui-input
              v-model="item.percent"
              class="rev-share-input"
              post-icon="percent"
              :disabled="!ableToSetRevShare"
              error-tooltip
              :error="getError(['casino_levels', i, 'percent'])"
            />
            <ui-icon
              size="14px"
              class="ui-m-md-l remove-level"
              :class="{ 'disabled': i === 0}"
              lib="fa"
              substyle="fas"
              name="times"
              :disabled="!ableToSetRevShare"
              :color="$theme.dangerColor"
              @click.native="removeLevel(i, 'casino_levels')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aclMixin from '@/views/mixins/acl';

export default {
  name: 'RevShare',
  mixins: [aclMixin],
  props: {
    payoutRule: {
      type: Object,
      required: true,
    },
    indexRule: {
      type: Number,
      required: true,
    },
    errorRules: {
      type: [Array, Object, undefined],
      default() {
        return {};
      },
    },
    action: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      hasRsValues: true,
    };
  },
  computed: {
    isDisabledRs() {
      if (!this.ableToSetRevShare) {
        return true;
      }

      if (this.isRevShare) {
        return !this.ableToSetCpa || !this.payoutRule.settings.cpa;
      }

      return false;
    },
    isRevShare: {
      get() {
        if (!this.ableToSetRevShare) {
          return false;
        }

        if (this.payoutRule.settings.rev_share) {
          if (!this.ableToSetCpa) {
            return true;
          }

          return this.hasRsValues;
        }

        return false;
      },
      set(val) {
        if (val) {
          if (!this.hasRsValues) {
            this.hasRsValues = true;
          }
          this.payoutRule.settings.rev_share = {
            sport_levels: [
              {
                percent: 0,
                ftd_count: 0,
              },
            ],
            casino_levels: null,
          };
        } else {
          this.payoutRule.settings.rev_share = null;
        }
      },
    },
    isSeparate: {
      get() {
        return !this.$_.isEmpty(this.payoutRule.settings.rev_share?.casino_levels);
      },
      set(val) {
        if (val) {
          this.$set(this.payoutRule.settings.rev_share, 'casino_levels', this.$_.cloneDeep(this.payoutRule.settings.rev_share.sport_levels));
        } else {
          this.$set(this.payoutRule.settings.rev_share, 'casino_levels', null);
        }
      },
    },
  },

  watch: {
    indexRule: {
      handler() {
        const amount = this.$_.get(this.payoutRule, ['settings', 'cpa', 0, 'amount']);
        this.hasRsValues = ([this.payoutRule.settings.rev_share?.sport_levels, this.payoutRule.settings.rev_share?.casino_levels].some(this.hasRs)) || !amount;
      },
    },
    payoutRule: {
      deep: true,
      handler(newVal) {
        this.$emit('change-rule', newVal);
      },
    },
  },
  mounted() {
    if (this.ableToSetRevShare) {
      const amount = this.$_.get(this.payoutRule, ['settings', 'cpa', 0, 'amount']);
      this.hasRsValues = ([this.payoutRule.settings.rev_share?.sport_levels, this.payoutRule.settings.rev_share?.casino_levels].some(this.hasRs)) || !amount;
    } else {
      this.payoutRule.settings.rev_share = null;
    }
  },
  methods: {
    hasRs(lvl) {
      return lvl?.some(el => !!+el.percent);
    },
    errorRule(index) {
      if (this.errors.hasOwnProperty('payout_rules')) {
        if (this.errors.payout_rules[index] === null || this.errors.payout_rules[index] === undefined) return false;
        if (this.errors.payout_rules[index].hasOwnProperty('settings')) {
          if (this.errorsRules.length < index) {
            return false;
          }
          if (this.errorsRules[index] === null) {
            return false;
          }
          return true;
        }
      }
      return false;
    },
    getError(path) {
      return this.$_.get(this.errorRules, ['settings', 'rev_share', ...path]);
    },
    removeLevel(index, key) {
      if (index > 0 && this.ableToSetRevShare) {
        const levels = [...this.payoutRule.settings.rev_share[key]];
        levels.splice(index, 1);

        this.$set(
          this.payoutRule.settings.rev_share,
          key,
          levels,
        );
      }
    },
    addLevel(key) {
      const rule = this.payoutRule.settings.rev_share[key];
      const { ftd_count, percent } = rule[rule.length - 1];
      this.$set(
        this.payoutRule.settings.rev_share,
        key,
        [...rule, { ftd_count: Number(ftd_count) + 1, percent: Number(percent) + 1 }],
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.rev-share-container {
  display: flex;
  gap: 16px;

  .remove-level {
    cursor: pointer;
  }
  .remove-level.disabled{
    opacity: .5;
    cursor: not-allowed;
  }
}

.pb-0 {
  padding-bottom: 0!important;
}

.pb-4 {
  padding-bottom: 4px!important;
}

.p-8 {
  padding: 8px!important;
}

.remove-level.disabled, .remove-rule.disabled, .rules-drag .exclude {
  opacity: .5;
}

.rev-share-block {
  flex: 1;
  max-width: calc(50% - 40px);
}

.rev-share-input {
  width: 140px!important;

  &.readonly {
    cursor: not-allowed;

    /deep/ {
      .input-element {
        cursor: not-allowed;
      }
    }
  }

  /deep/ {
    .input-pre-icon {
      font-weight: 500!important;
    }

    .input-post-text {
      font-size: 13px;
    }

    .input-post-icon {
      font-size: 14px!important;
    }
  }
}

.thin-hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  height: 2px;
  width: 10px;
  background-color: #d3d3d3;
}

.add-level {
  height: auto!important;
  align-items: self-end!important;
}

.add-offer-popup .popup{
  .title-wrap{
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.rev-share {
      justify-content: flex-start;
      align-items: flex-end;
    }
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #303634;
  }
}
</style>
