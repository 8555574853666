// Players views
import Players from '@/views/Players.vue';
import PlayersList from '@/views/Players/PlayersList.vue';

import Player from '@/views/Players/Player.vue';
import PlayerInfo from '@/views/Players/Player/PlayerInfo.vue';
import PlayerLast from '@/views/Players/Player/PlayerLast.vue';
import PlayerCrm from '@/views/Players/Player/PlayerCrm.vue';

export default [
  {
    path: '/players',
    component: Players,
    meta: {
      requiresAuth: true,
      view: 'players',
    },
    children: [
      {
        path: 'list',
        component: PlayersList,
        name: 'players-list',
        meta: {
          view: 'playersList',
        },
      },
      {
        path: ':id',
        component: Player,
        name: 'player',
        children: [
          {
            path: 'info',
            component: PlayerInfo,
            name: 'player-info',
            meta: {
              view: 'info',
            },
          },
          {
            path: 'last-operations',
            component: PlayerLast,
            name: 'player-last',
            meta: {
              view: 'last',
            },
          },
          {
            path: 'crm',
            component: PlayerCrm,
            name: 'player-crm',
            meta: {
              view: 'crm',
            },
          },
        ],
      },

      {
        path: '',
        redirect: 'list',
      },
      {
        path: '*',
        redirect: 'list',
      },
    ],
  },
];
