export default {
  /**
   * Get domain registrar accounts count
   *
   * @param {Object} params
   * @param {String} params.search
   *
   * @return {Promise<Object>} api response
   */
  getDomainRegistrarAccountsCount(params) {
    return this.affiliatesApi.get('/domains-management/registrar-accounts/count', { params });
  },

  /**
   * Get domain registrar accounts
   *
   * @param {Object} params
   * @param {String} params.sort_column
   * @param {String} params.sort_dir
   * @param {String} params.search
   * @param {Number} params.limit
   * @param {Number} params.offset
   *
   * @return {Promise<Object>} api response
   */
  getDomainRegistrarAccounts(params) {
    return this.affiliatesApi.get('/domains-management/registrar-accounts', { params });
  },

  /**
   * Create domain registrar account
   *
   * @param {Object} data
   * @param {String} data.name
   * @param {String} data.domain_registrar_id
   * @param {Boolean} data.auto_ns_management
   * @param {Object} data.auth_data
   *
   * @return {Promise<Object>} api response
   */
  createDomainRegistrarAccount(data) {
    return this.affiliatesApi.post('/domains-management/registrar-accounts', data);
  },

  /**
   * Get domain registrar account by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  getDomainRegistrarAccountById(id) {
    return this.affiliatesApi.get(`/domains-management/registrar-accounts/${id}`);
  },

  /**
   * Update domain registrar account
   *
   * @param {String} id
   * @param {Object} data
   * @param {String} data.name
   * @param {Boolean} data.auto_ns_management
   * @param {Object} data.auth_data
   *
   * @return {Promise<Object>} api response
   */
  updateDomainRegistrarAccount(id, data) {
    return this.affiliatesApi.put(`/domains-management/registrar-accounts/${id}`, data);
  },

  /**
   * Delete domain registrar account by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  deleteDomainRegistrarAccount(id) {
    return this.affiliatesApi.delete(`/domains-management/registrar-accounts/${id}`);
  },
};
