import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      onSessionUnlock: () => {},
    };
  },
  computed: {
    ...mapGetters('session', ['significantActionsSeconds']),
  },
  watch: {
    significantActionsSeconds(newVal, oldVal) {
      if (oldVal === 0 && newVal) {
        this.onSessionUnlock();
      }
    },
  },
};
