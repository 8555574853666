export default {
  tools: {
    name: 'Инструменты',
    menu: {
      promoChecker: 'Проверка промокодов',
    },
    promoChecker: {
      players: {
        table: {
          site_player_id: 'ID игрока',
          promo_codes_used_count: 'Использованные промокоды',
          win_count: 'Количество выигрышей',
          lost_count: 'Количество проигрышей',
          win_sum: 'Сумма выигрышей',
        },
      },
      input_promocode: 'Ввести промокод',
      form_promocode: 'Промокод из',
      period: 'Период',
      list: 'Список',
      unload_promo: 'Загрузить промокоды',
      download: 'Загрузить неиспользованные',
      last_180: 'Данные за последние 180 дней',
      promo_codes: 'Промокоды',
      used: 'Использованные',
      not_used: 'Неиспользованные',
      players1: 'Игроки',
      win: 'Победители',
      player_sub: 'старый / новый / общий',
      depos: 'Первые депозиты',
      depos_sub: 'количество / сумма',
      avg_depos: 'Средний депозит',
      caff: 'Коэффициент',
      avg_max: 'сред / макс',
      event: 'События',
      win_sum: 'Сумма выигрыша',
      total: 'Общий',
      looser: 'Проигравшие',
      edit: 'Редактировать',
      sec: 'Секунды',
      min: 'Минуты',
      h: 'Часы',
      day: 'Дни',
      week: 'Недели',
      reset: 'Сбросить',
      apply: 'Применить',
    },
    confirm: {
      title: 'Загрузить промокоды',
      action: 'Добавить промокоды',
      msg: 'Промокоды',

    },
    landingLeagues: {
      leagues: {
        fields: {
          name: {
            title: 'Лига',
          },
          matches_count: {
            title: 'Будущие матчи',
          },
          last_starts_at: {
            title: 'Недавние матчи',
          },
        },
        land_leagues: 'Лендинги по лигам',
        add_match: 'Добавить матч',
        no_match: 'Нет матчей',
        edit: 'Редактировать',
        delete: 'Удалить',
        preview: 'Предпросмотр',
        Add: 'Добавить',
        New: 'Новый',
        Edit: 'Редактировать',

      },
      matches: {
        fields: {
          starts_at: {
            title: 'Дата',
          },
          match: {
            title: 'Матч',
          },
        },
      },
      confirm: {
        title: 'Удалить матч',
        msg: 'Вы уверены, что хотите удалить матч?',
      },
    },
    match_popup: {
      league: 'Лига',
      select_league: 'Выбрать лигу',
      date_time: 'Дата и время',
      placeholder_date_time: 'Выбрать дату и время',
      timezone: 'Часовой пояс',
      placeholder_timezone: 'Выбрать часовой пояс',
      team: 'Команда',
      select_team: 'Выбрать команду',
      get_odds: 'Получать коэффициенты автоматически',
      link_btw: 'Ссылка на Betting',
      odd: 'Коэффициент',
      input_odd: 'Ввести коэффициент',
      cancel: 'Отменить',
      match: 'Матч',
      save: 'Сохранить',

    },
  },
};
