export default {
  crm: {
    menu: {
      dashboard: {
        main: 'Dashboard',
      },
      activities: {
        main: 'Activities',
      },
      running: {
        main: 'Running',
      },
      quality_assurance: {
        main: 'Quality Assurance',
        activities: 'Activities',
        segments: 'Segments',
      },
      segments: {
        main: 'Segments',
      },
    },
    status: {
      updating: 'Updating...',
    },
    errors: {
      changes: 'Activity was paused due to implementing changes in the segment',
    },
    fields: {
      incrementing_id: {
        title: 'ID',
      },
      name: {
        title: 'Name',
      },
      segment: {
        title: 'Segment',
      },
      actions: {
        title: 'Actions',
        values: {
          email: 'Mail',
          sms: 'SMS',
          push: 'Push',
          call_center: 'Call Center',
        },
      },
      activity_period_start: {
        title: 'Start Date',
      },
      segment_players_count: {
        title: 'Players',
      },
      activity_period_end: {
        title: 'End Date',
      },
      computed_status: {
        title: 'Status',
        values: {
          all: 'All',
          approved: 'Approved',
          new: 'New',
          on_rework: 'On Rework',
          for_rework: 'For Rework',
          on_review: 'On Review',
          declined: 'Declined',
          running: 'Running',
          completed: 'Completed',
          paused: 'Paused',
          draft: 'Draft',
          active: 'Active',
          archived: 'Archived',
        },
      },
      type: {
        title: 'Type',
        values: {
          fixed: 'Fixed',
          dynamic: 'Dynamic',
          manual: 'Manual',
        },
      },
    },
    segments: {
      title: 'Segments',
      title_quality_assurance: 'Segments Quality Assurance',
      add_segment: 'Create Segment',
      create_segment_title: 'Create Segment',
      edit_segment_title: 'Edit Segment',
      using_activities: 'Activities Using This Segment',
      create_segment_name_input: 'Name',
      create_segment_name_input_placeholder: 'Input Name',
      create_segment_value_placeholder: 'Input value',
      activities_empty: 'No activity uses this segment',
      activity_filters: {
        title: 'Filters',
        hint: 'Filters are applied to the result of rules configured in the segment.',
      },
      type: {
        title: 'Type',
        fixed: 'Fixed',
        dynamic: 'Dynamic',
        manual: 'Manual',
      },
      manual: {
        title: 'Configure Segment',
        replace: 'Replace',
        replaceNotice: 'There are {playersCount} players in the segment',
        type: {
          csv: 'Upload CSV',
          manual: 'Enter Manually',
        },
        csv: {
          title: 'Upload CSV with a list of player\'s IDs',
          note: `
            <div><b>Note:</b> for CSV there should be a column with one of the following names:</div>
            <ul>
              <li>
                — id
              </li>
              <li>
                — player_id
              </li>
              <li>
                — site_player_id
              </li>
            </ul>
          `,
        },
        text: {
          title: 'Enter list of players below <span>(each on a new line)</span>',
          placeholder: 'Each player on a new line',
        },
        upload: {
          dropFileHereOr: 'Drag and Drop or',
          selectFile: 'Select File',
        },
      },
      configure: {
        title: 'Configure Filter',
        add_new_group: 'Add New Rule Group',
        add_new_rule: 'New Rule',
      },
      filters: {
        seconds_since_registration: 'Time Since Registration',
        seconds_since_last_deposit: 'Time Since Deposit',
        seconds_since_last_bet: 'Time Since Bet',
        seconds_since_last_sports_bet: 'Time Since Sport Bet',
        seconds_since_last_games_bet: 'Time Since Games Bet',
        registration_player_attributes: 'Registration & Player Attributes',
        deposits: 'Deposits',
        gaming_activity: 'Gaming Activity',
        registration_date_time: 'Registration Date & Time',
        latest_deposit_date_time: 'Latest Deposit Date & Time',
        latest_bet_date_time: 'Latest Bet Date & Time',
        latest_sport_bet_date_time: 'Latest Sport Bet Date & Time',
        latest_games_bet_date_time: 'Latest Games Bet Date & Time',
        registration_timestamp: 'Registration Date & Time',
        latest_deposit_timestamp: 'Latest Deposit Date & Time',
        latest_bet_timestamp: 'Latest Bet Date & Time',
        latest_sport_bet_timestamp: 'Latest Sport Bet Date & Time',
        latest_games_bet_timestamp: 'Latest Games Bet Date & Time',
        has_deposits: 'Has Deposits',
        has_bets: 'Has Bets',
        has_sports_bets: 'Has Sport Bets',
        has_games_bets: 'Has Games Bets',
        favourite_bet_by_sum: 'Favourite Bet By Sum',
        favourite_bet_by_count: 'Favourite Bet By Count',
        player_country_code: 'Player Country',
        player_region_code: 'Player Region',
        favourite_payment_system_id: 'Favourite Payment System',
        favourite_sport_type: 'Favourite Sport',
        favourite_sport_league: 'Favourite League',
        select_operator_placeholder: 'Select Operator',
        select_rule_placeholder: 'Select Filter',
        player_contacts: 'Contacts',
        player_affiliate_id: 'Affiliate',
        player_sub_affiliate_id: 'Sub-Affiliate',
        betting_profit_sum: 'Profit, $',
        deposits_count: 'Deposits',
        deposits_sum: 'Deposits, $',
        sport_bets_count: 'Sport Bets',
        sport_bets_sum: 'Sport Bets, $',
        sport_bets_live_count: 'Sport Bets Live',
        sport_bets_live_sum: 'Sport Bets Live, $',
        games_bets_count: 'Casino Bets',
        games_bets_sum: 'Casino Bets, $',
      },
      logical_relations: {
        and: 'And',
        or: 'Or',
      },
      boolean: {
        true: 'Yes',
        false: 'No',
      },
      favourite_bet: {
        sport: 'Sport',
        games: 'Games',
      },
      player_contacts: {
        email: 'E-mail',
        phone: 'Phone',
      },
      operators: {
        '=': 'Equal',
        '<': 'Less',
        '<=': 'Less or equal',
        '>': 'Greater',
        '>=': 'Greater or Equal',
        between: 'Period',
      },
      time_ranges: {
        seconds: 'Seconds',
        minutes: 'Minutes',
        days: 'Days',
        hours: 'Hours',
        between: 'Period',
      },
      stats: {
        players: 'Players',
        statistics: 'Statistics',
        deposit: 'Deposit',
        sport_bet: 'Sport Bets',
        casino_bet: 'Casino Bets',
        avg_med: 'Avg. / Med.',
        refresh: 'Refresh',
      },
      status: {
        all: 'All Segments',
        declined: 'Declined Segments',
        new: 'New Segments',
        approved: 'Approved Segments',
        on_review: 'On Review Segments',
      },
    },
    activities: {
      title: 'Activities',
      title_running: 'Running Activities',
      title_quality_assurance: 'Activities Quality Assurance',
      add_activity: 'Create Activity',
      players_count: 'Players count: {count}',
      info: {
        new_activity: 'New Activity',
        info: 'Info',
        status: 'Status',
        created_at: 'Created At',
        period: 'Period',
        general: 'General',
        start_date: 'Start Date',
        end_date: 'End Date',
        name: 'Activity Name',
        name_placeholder: 'Enter Activity Name',
        comments: 'Comments',
        add_metric: 'Add Metric',
      },
      metrics: {
        title: 'Metrics',
        days: 'Day(s)',
        cooldown: 'Cooldown',
        cooldown_tooltip: 'Specify the number of days for which we will continue to collect statistics on players who may leave the configured segment',
        no_metrics_selected_error: 'No metrics selected',
        no_avaliable_data_error: 'No avaliable Data',
        cooldown_stat: 'Cooldown: {cooldown} day(s)',
        updated_at_stat: 'Updated at {datetime}',
        players_tooltip: 'Amount of active players (% of active players from the total amount) | Total amount of players',
      },
      segment: {
        title: 'Segment',
        hint: 'Choose the existing one from <b>Global Segments</b> or create a new one <b>Local</b>',
        local: 'Local',
        global: 'Global',
        create_button: 'Create Segment',
        replace_button: 'Replace Segment',
        not_approved: 'Not approved',
        filtered: 'Filtered',
        local_segment_error: 'Local segment contains errors',
      },
      actions: {
        title: 'Actions',
        send: {
          repeat: 'Repeat',
          label_1: 'Send',
          label_2: 'time(s) per user every',
          hint: 'Send Actions <b>{count} time(s)</b> per user every <b>{interval} day(s)</b>',
          hint_once: 'Send Actions <b>once</b>',
          day: 'day(s)',
        },
        control_group: 'Control Group',
        of_segment: 'of Segment',
        action_group: 'Action Group',
        group: 'Group',
        add: 'Add',
        action: 'action',
        add_translation: 'Add Translation',
        new_action_group_button: 'Add New Action Group',
        exceed_action_group_limit_hint: 'Activity can’t contain more than {limit} Action Groups',
        empty_name: '<empty name>',
        action_popup: {
          call_center_title: 'Select The Project',
          sms_title: 'SMS Action',
          mail_title: 'E-Mail Action',
          mail_template_title: 'Select Email Template',
          push_title: 'Push Action',
          add_metrics_title: 'Add Metrics',
          call_center_hint: 'Select the project from Call Center which will be linked with this Activity',
          call_center_select: 'Project From Call Center',
          call_center_select_placeholder: 'Select The Project',
          mail_hint: 'The following data will be inserted into the e-mail template',
          mail_subject_label: 'E-Mail Subject',
          mail_header_label: 'E-Mail Header',
          mail_message_placeholder: 'Your message goes here',
          mail_add_param: 'Add message parameter',
          default: 'Default',
          search_languages_placeholder: 'Search Languages',
          email_fields: {
            name: 'Action Name',
            message: 'Text Message',
            subject: 'E-Mail Subject',
          },
          sms_fields: {
            name: 'Action Name',
            message: 'Text Message',
            symbols_remaining: '{numberOfSMS} SMS, {remaining} Symbols Remaining',
          },
        },
        action_params_popup: {
          title: 'Parameter',
          search_placeholder: 'Search Parameters',
          not_found: 'Not found',
        },
        action_params: {
          firstName: 'First Name',
          lastName: 'Last Name',
          email: 'E-Mail',
          phone: 'Phone',
        },
        send_test_email: {
          title: 'Send Test E-Mail',
          emails_title: 'E-Mails',
          emails_placeholder: 'Input E-Mails each on a new line',
          msg: 'Enter the e-mail addresses (each on a new line) to send the test message',
          all_localizations: 'All Localizations',
          success: 'E-Mail sent successfully',
        },
        send_test_sms: {
          title: 'Send Test SMS',
          msg: 'Confirm sending <b>{smsName}</b>',
          phone_number: 'Phone number',
          all_localizations: 'All Localizations',
          success: 'Sms sent successfully',
        },
        sms_statistics: {
          title: 'SMS Statistics',
          playersCount: 'Number of players',
          acceptedCount: 'SMS Accepted',
          sent: 'SMS Sent',
          delivered: 'SMS Delivered',
          spent: 'Spent',
        },
        email_statistics: {
          title: 'E-mail Statistics',
          playersCount: 'Number of players',
          acceptedCount: 'E-mails Accepted',
          sent: 'E-mails Sent',
          bounced: 'E-mails Bounced',
          opened: 'E-mails Opened',
          uniqueClicks: 'Unique Clicks',
        },
        call_statistics: {
          title: 'Statistics',
          successfulCalls: 'Successful Calls',
          failedCalls: 'Failed Calls',
          totalCalls: 'Total Calls',
        },
        mail_template: {
          title: 'Change Template',
          template: 'Template',
          confirm_title: 'Change Template?',
          confirm_body: 'Changing the template will delete all the content of the email. Do you want to change it?',
        },
        errors: {
          param_error: '{param}: {error}',
          has_param_error: 'Has errors in {entity} template',
          has_call_error: 'Has errors in call center template',
        },
      },
      history_card: {
        title: 'History',
        no_history: 'No History',
      },
      status_groups: {
        status: {
          title: 'Status',
          values: {
            draft: 'Draft',
            active: 'Active',
            archived: 'Archived',
          },
        },
        moderation_status: {
          title: 'Moderation status',
          values: {
            on_review: 'On Review',
            for_rework: 'For Rework',
            approved: 'Approved',
            declined: 'Declined',
          },
        },
        launch_status: {
          title: 'Launch status',
          values: {
            pending: 'Pending',
            running: 'Running',
            paused: 'Paused',
            completed: 'Completed',
          },
        },
      },
      errors: {
        activity_expired_error: 'Activity period has expired',
      },
    },
    tooltips: {
      download: 'Download',
      preview: 'Preview',
      edit: 'Edit',
      delete: 'Delete',
      clone: 'Clone',
      filter: 'Filter',
      shadow_percent: 'Use remaining percent as value',
      send_test_email: 'Send Test E-mail',
      send_test_sms: 'Send Test SMS',
      empty_mail_message: 'E-mail is empty',
    },
    buttons: {
      edit: 'Edit',
      delete: 'Delete',
      view: 'View',
      cancel: 'Cancel',
      save: 'Save',
      close: 'Close',
      decline: 'Decline',
      approve: 'Approve',
      for_rework: 'Send For Rework',
      review: 'Send to Review',
      save_and_send: 'Save & Send to Review',
      send: 'Send to Review',
      start_activity: 'Start Activity',
      resume_activity: 'Resume Activity',
      pause_activity: 'Pause Activity',
      stop_activity: 'Stop Activity',
      save_action: 'Save Action',
      save_metrics: 'Save Metrics',
      archive: 'Archive',
      complete: 'Complete',
      make_default: 'Make Default',
      preview: 'Preview',
      confirm: 'Confirm',
      send_test_email: 'Send Test Mail',
      send_test_sms: 'Send Test SMS',
      clone: 'Clone',
      select_template: 'Select Template',
      change: 'Change',
    },
    confirm: {
      to_review_activity: {
        title: 'Send to Review',
        msg: 'Confirm sending <span>{activityName}</span> to review',
        action: 'Confirm',
      },
      save_and_to_review_activity: {
        title: 'Save & Send to Review',
        msg: 'Confirm sending <span>{activityName}</span> to review',
        action: 'Confirm',
      },
      approve_activity: {
        title: 'Approve Activity',
        msg: 'Are you sure want to approve activity <span>{activityName}</span>?',
        action: 'Confirm',
      },
      for_rework_activity: {
        title: 'Send For Rework',
        msg: 'Confirm sending <span>{activityName}</span> for rework',
        action: 'Confirm',
      },
      decline_activity: {
        title: 'Decline Activity',
        msg: 'Are you sure want to decline activity <span>{activityName}</span>?',
        action: 'Confirm',
      },
      run_activity: {
        title: 'Start Activity',
        msg: '<span>{activityName}</span> will start at <span>{dateTime}</span>',
        action: 'Confirm',
      },
      pause_activity: {
        title: 'Pause Activity',
        msg: 'Are you sure want to pause activity <span>{activityName}</span>?',
        action: 'Confirm',
      },
      complete_activity: {
        title: 'Stop Activity',
        msg: 'Are you sure want to stop activity <span>{activityName}</span>?',
        action: 'Confirm',
      },
      clone_activity: {
        title: 'Clone Activity',
        msg: 'Are you sure want to clone <span>{activityName}</span>?',
        action: 'Confirm',
      },
      delete_activity: {
        title: 'Archive Activity',
        msg: 'Are you sure want to archive activity <span>{activityName}</span>?',
        action: 'Archive',
      },
      delete_segment: {
        title: 'Delete Segment',
        msg: 'Are you sure want to delete segment <span>{segmentName}</span>?',
        action: 'Delete',
      },
    },
    only_mine: 'Only My',
  },
};
