<template>
  <div
    v-if="isOpen"
    class="rotation-group-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">{{ $t(`domainManagement.popup.rotationGroups`) }}</span>
        <ui-icon
          :size="20"
          class="close"
          lib="fa"
          substyle="fas"
          name="times"
          @click.native="close"
        />
      </div>
      <div class="form full-width">
        <div class="row">
          <div class="field">
            <span class="label-text">{{ $t(`domainManagement.popup.form.platform_service_rotation_group_id.label`) }}</span>
            <el-select
              v-model="platform_service_rotation_group_id"
              filterable
              class="select"
            >
              <el-option
                v-for="platformServiceRotationGroup in selectData"
                :key="platformServiceRotationGroup.id"
                :label="platformServiceRotationGroup.name"
                :value="platformServiceRotationGroup.id"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          class="btn cancel-button"
          lib="fa"
          substyle="fas"
          icon="times"
          size="big"
          @click.native="close"
        >
          {{ $t(`domainManagement.popup.cancel`) }}
        </ui-button>
        <ui-button
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          size="big"
          :disabled="!platform_service_rotation_group_id"
          @click="save"
        >
          {{ $t(`affiliates.offer_popup.Add`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
export default {
  name: 'AffiliateRotationGroupsPopup',

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },

    initalData: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      platform_service_rotation_group_id: null,
      platformServiceRotationGroups: [],
    };
  },

  computed: {
    selectData() {
      const arrayAlreadyФddedКotations = this.initalData.map(el => el.id);
      return this.platformServiceRotationGroups.filter(
        elem => !arrayAlreadyФddedКotations.includes(elem.id),
      );
    },
  },

  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },

  destroyed() {
    window.removeEventListener('keyup', this.keyupHandler);
  },

  async created() {
    await this.fetchRotationGroups();
  },

  methods: {
    async fetchRotationGroups() {
      const { data: { payload } } = await this.$api.getRotationGroups();

      const arrayAlreadyФddedКotations = this.initalData.map(el => el.id);
      this.platformServiceRotationGroups = payload.filter(
        elem => !arrayAlreadyФddedКotations.includes(elem.id),
      );
    },

    close() {
      this.resetForm();
      this.$emit('close');
    },

    save() {
      const data = {
        platform_service_rotation_group_id: this.platform_service_rotation_group_id,
      };

      this.$emit('save', data);

      this.close();
    },

    keyupHandler(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    },

    resetForm() {
      this.platform_service_rotation_group_id = null;
    },
  },
};
</script>
