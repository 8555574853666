// Settings views
import Settings from '@/views/Settings.vue';
import Profile from '@/views/Profile.vue';

import Sites from '@/views/Settings/Sites.vue';
import Fees from '@/views/Settings/Fees.vue';
import TokenManagement from '@/views/Settings/TokenManagement.vue';
import PaymentMethods from '@/views/Settings/PaymentMethods.vue';
import SystemSettings from '@/views/Settings/SystemSettings.vue';
import MediaBuying from '@/views/Settings/MediaBuying.vue';
import SystemDomains from '@/views/Settings/SystemDomains.vue';
import PredefinedPage from '@/views/Settings/PredefinedPage.vue';
import OffersCountryGroups from '@/views/Settings/OffersCountryGroups.vue';
import Currencies from '@/views/Settings/Currencies.vue';

export default [
  {
    path: '/settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      view: 'settings',
    },
    children: [
      {
        path: 'sites',
        component: Sites,
        name: 'sites',
        meta: {
          view: 'sites',
        },
      },
      {
        path: 'fees',
        component: Fees,
        name: 'fees',
        meta: {
          view: 'fees',
        },
      },
      {
        path: 'token-management',
        component: TokenManagement,
        name: 'tokenManagement',
        meta: {
          view: 'tokenManagement',
        },
      },
      {
        path: 'payment-methods',
        component: PaymentMethods,
        name: 'paymentMethods',
        meta: {
          view: 'paymentMethods',
        },
      },
      {
        path: 'system-settings',
        component: SystemSettings,
        name: 'systemSettings',
        meta: {
          view: 'systemSettings',
        },
      },
      {
        path: 'media-buying',
        component: MediaBuying,
        name: 'mediaBuying',
        meta: {
          view: 'mediaBuying',
        },
      },
      {
        path: 'system-domains',
        component: SystemDomains,
        name: 'systemDomains',
        meta: {
          view: 'systemDomains',
        },
      },
      {
        path: 'predefined-page',
        component: PredefinedPage,
        name: 'predefinedPage',
        meta: {
          view: 'predefinedPage',
        },
      },
      {
        path: 'offers-country-groups',
        component: OffersCountryGroups,
        name: 'offersCountryGroups',
        meta: {
          view: 'offersCountryGroups',
        },
      },
      {
        path: 'currencies',
        component: Currencies,
        name: 'currencies',
        meta: {
          view: 'currencies',
        },
      },
      {
        path: '',
        redirect: 'sites',
      },
      {
        path: '*',
        redirect: 'sites',
      },
    ],
  },
  {
    path: '/profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      view: 'profile',
    },
  },
];
