<template>
  <div
    :class="{
      'tree-branch': true,
      'tree-branch_outer': type === 'outer',
      'tree-branch_thickness-thin': thickness === 'thin',
      'tree-branch_thickness-medium': thickness === 'medium',
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TreeBranch',

  inject: ['type', 'thickness'],
};
</script>

<style lang="scss">
.tree-branch {
  padding-left: 16px;
  position: relative;

  &_thickness-thin {
    &::before {
      border-left: 1px solid #d8d8d8;
    }

    &::after {
      border-top: 1px solid #d8d8d8;
    }
  }

  &_thickness-medium {
    &::before {
      border-left: 2px solid #d8d8d8;
    }

    &::after {
      border-top: 2px solid #d8d8d8;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 8px;
    top: 0;
    height: 100%;
    // border-left: 2px solid #d8d8d8;
  }

  &:last-of-type {
    &::before{
      height: 50%;
    }
  }

  &:only-of-type {
    &::before,
    &::after {
      content: none;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 8px;
    top: 50%;
    // border-top: 2px solid #d8d8d8;
    width: 8px;
  }

  &_outer {
    padding-left: 8px;
    &::before {
      left: 0;
    }

    &:first-child {
      &::before {
        height: 50%;
        top: 50%;
      }
    }

    &::after {
      left: 0px;
    }
  }
}
</style>
