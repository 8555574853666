export default {
  data() {
    return {
      touchedFilelds: [],
    };
  },

  methods: {
    touchField(fieldName) {
      if (!this.touchedFilelds.includes(fieldName)) {
        this.touchedFilelds.push(fieldName);
      }
    },

    resetTouchedFields() {
      this.touchedFilelds = [];
    },

    isInvalidField(fieldName) {
      const field = this.$_.get(this.$v, fieldName);
      if (field === undefined) {
        window.console.error(`You should add "${fieldName}" field to validate object!`);
      }
      return this.touchedFilelds.includes(fieldName) && field.$invalid;
    },
  },
};
