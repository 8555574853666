<template>
  <div
    class="toolbar filters"
  >
    <div class="wrapper">
      <div class="item all">
        <span
          class="field"
          @click="clearCurrentFilter"
        >{{ $t('reports.other.all') }}</span>
      </div>
      <div
        v-for="(value, key) in reports[currentReport].currentFilter"
        :key="key"
        class="item"
      >
        <div
          style="display: flex"
        >
          <ui-icon
            v-if="value.op === '='"
            class="context-icon"
            style="margin-right: 4px;"
            size="10px"
            lib="fa"
            substyle="fas"
            name="random"
          />
          <ui-icon
            v-if="value.op === '!='"
            class="context-icon"
            style="margin-right: 4px;"
            lib="fa"
            size="10px"
            substyle="fas"
            name="page-break"
          />

          <span class="field">{{ getDimensionKeyAndTranslate(value) }}:</span>

          <el-dropdown
            trigger="click"
            placement="bottom"
          >
            <span class="value">
              {{ value.row_name[0][value.group] | datetimeFilter(key) }}
              {{ value.row_name.length - 1 ? `[+${value.row_name.length - 1}]` : '' }}
            </span>
            <el-dropdown-menu
              slot="dropdown"
              :class="{'hidden': !(value.row_name.length - 1)}"
              class="ddgroup drill-item"
            >
              <el-dropdown-item
                v-for="(row, index) in value.row_name"
                :key="index"
              >
                <span class="drill-item--text">{{ row[value.group] | datetimeFilter(key) }}</span>
                <ui-icon
                  :color="$theme.dangerColor"
                  lib="fa"
                  substyle="fas"
                  name="times"
                  size="12px"
                  class="close"
                  @click.native.stop="removeItemCurrentFilter(row, value.row_id[index], key)"
                />
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <ui-icon
            :color="$theme.dangerColor"
            lib="fa"
            substyle="fas"
            name="times"
            size="12px"
            class="close"
            @click.native="removeCurrentFilter(key)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DrilldownFilter',
  props: {
    reports: {
      type: Array,
      required: true,
    },
    currentReport: {
      type: Number,
      required: true,
    },

  },

  computed: {
    ...mapGetters('reports', ['reportsSettings']),
  },

  methods: {
    getDimensionKeyAndTranslate(value) {
      const dimensionKey = Object.keys(value.row_name[0])
        .filter(
          elem => elem !== 'row_id' && elem !== 'row_filter_column',
        )[0];
      return this.tabsTranslate(dimensionKey);
    },

    tabsTranslate(key) {
      const defaulTitle = this.reportsSettings.dimensions.find(dim => dim.column_name === key);
      return defaulTitle.title || '';
    },

    clearCurrentFilter() {
      this.$emit('clearCurrentFilter');
    },
    removeItemCurrentFilter(row, value, key) {
      this.$emit('removeItemCurrentFilter', row, value, key);
    },
    removeCurrentFilter(key) {
      this.$emit('removeCurrentFilter', key);
    },

  },
};
</script>
