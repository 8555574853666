<template>
  <div class="payments-report">
    <div
      v-if="drillFilter !== null"
      class="toolbar toolbar-chain"
    >
      <div class="wrapper">
        <div class="item all">
          <span
            class="field"
            @click="clearChain"
          >{{ $t('reports.other.all') }}</span>
        </div>
        <div
          v-for="(value, key) in drillFilter"
          :key="key"
          class="item"
        >
          <div style="display: flex">
            <ui-icon
              v-if="value.op === '='"
              class="context-icon"
              style="margin-right: 4px;"
              size="10px"
              lib="fa"
              substyle="fas"
              name="random"
            />
            <ui-icon
              v-if="value.op === '!='"
              class="context-icon"
              style="margin-right: 4px;"
              lib="fa"
              size="10px"
              substyle="fas"
              name="page-break"
            />
            <span class="field">{{ tabsTranslate(value) }}:&nbsp;</span>
            <el-dropdown
              trigger="click"
              placement="bottom"
            >
              <span class="value">
                {{ value.row_name[0][value.group] | datetimeFilter(key) }}
                {{ value.row_name.length - 1 ? `[+${value.row_name.length - 1}]` : '' }}&nbsp;
              </span>
              <el-dropdown-menu
                slot="dropdown"
                :class="{'hidden': !(value.row_name.length - 1)}"
                class="ddgroup drill-item"
              >
                <el-dropdown-item
                  v-for="(row, index) in value.row_name"
                  :key="index"
                >
                  <span class="drill-item--text">{{ row[value.group] | datetimeFilter(key) }}</span>
                  <ui-icon
                    :color="$theme.dangerColor"
                    lib="fa"
                    substyle="fas"
                    name="times"
                    size="12px"
                    class="close"
                    @click.native.stop="removeItemCurrentFilter(row, value.row_id[index], key)"
                  />
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <ui-icon
              :color="$theme.dangerColor"
              lib="fa"
              substyle="fas"
              name="times"
              size="12px"
              class="close"
              @click.native="removeCurrentFilter(key)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="toolbar">
      <div class="wrapper">
        <div class="section">
          <div class="select-label">
            <el-date-picker
              v-model="dateRange"
              :picker-options="{
                firstDayOfWeek: 1,
                shortcuts: shortcutsDates,
              }"
              :start-placeholder="$t('reports.other.start_date')"
              :end-placeholder="$t('reports.other.end_date')"
              type="daterange"
              range-separator="-"
              format="dd MMM yyyy"
              class="date-picker"
            />
          </div>
          <ui-filter
            ref="filter"
            v-model="filters"
            :disabled="disabledFilter"
            :use-filters="filtersItems"
            class="ui-filter-new ui-m-md-l"
            @input="getReport"
          />
          <span
            v-if="filtersObj"
            class="reset_link"
            @click="resetFilters"
          >{{ $t('reports.payments.reset_filter') }}</span>
        </div>
        <div class="section">
          <ui-currency-picker
            class="ui-m-md-r"
            :value="currencyCode"
            :currencies="customerCurrencies"
            @input="changeCurrencies"
          />
          <ui-pagination
            :page="page"
            :page-size="limit"
            :count="count"
            show-size-select
            @page-change="pageChange"
          />
          <ui-button
            lib="fa"
            substyle="fas"
            icon="sync-alt"
            class="btn"
            @click="getReport"
          >
            {{ $t('reports.other.refresh') }}
          </ui-button>
        </div>
      </div>
    </div>
    <ui-table
      ref="dataTable"
      v-loading="isDataLoading.data"
      :currency="currencyCode"
      class="main-table-pr"
      :fields="fields"
      :data="data"
      :total="totals"
      :rows-count="limit"
      :custom-total-row="getTotalRow()"
      lazy-loading
      show-total
      i18n-path="reports"
      element-loading-custom-class="data-loader-spinner"
      @expand-change="handleExpand"
    >
      <template
        slot="columns"
        slot-scope="{ renderHeader, formatValue }"
      >
        <el-table-column
          :label="$t(`reports.fields.payment_system_name.title`)"
          :width="150"
          prop="payment_system_name"
          fixed
        >
          <template slot-scope="scope">
            <div
              class="row-value link"
              @click="handleReportClick(scope.row.row_id, null, scope.row.payment_system_name)"
            >
              <span>{{ scope.row.payment_system_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isCountryFilterAvailable"
          :width="36"
          fixed
          type="expand"
        >
          <template slot-scope="scope">
            <keep-alive>
              <ui-table
                v-loading="isDataLoading[`${scope.row.row_id}`]"
                :fields="fields"
                :data="subData[`${scope.row.row_id}`]"
                :external-height="subData[`${scope.row.row_id}`].th"
                :padding="0"
                is-static
                element-loading-custom-class="data-loader-spinner"
                class="inner_table"
              >
                <template
                  slot="columns"
                >
                  <el-table-column
                    :width="185"
                    prop="country_name"
                    align="right"
                    fixed
                  >
                    <template slot-scope="cscope">
                      <div
                        class="row-value link"
                        @click="handleReportClick(scope.row.row_id, cscope.row.row_id, scope.row.payment_system_name)"
                      >
                        <span>{{ cscope.row.country_name }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-for="day in dates"
                    :key="day"
                    align="center"
                  >
                    <el-table-column
                      v-for="field in fields"
                      :key="field.name"
                      :prop="field.name"
                      :min-width="110"
                      align="right"
                      resizable
                    >
                      <template slot-scope="inscope">
                        <div
                          :style="{color: highlightValue(inscope.row, field.name, day)}"
                          :class="[field.class]"
                          class="row-value"
                        >
                          <span>
                            {{ inscope.row.stats[day][`${field.name}`] !== null
                              ? formatValue(inscope.row.stats[day][`${field.name}`],field.format)
                              : `&mdash;`
                            }}
                          </span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column
                    :width="440"
                    align="center"
                    fixed="right"
                  >
                    <el-table-column
                      v-for="field in fields"
                      :key="field.name"
                      :prop="field.name"
                      :label="$t(`reports.fields.${field.name}.title`)"
                      :width="110"
                      align="right"
                      resizable
                    >
                      <template slot-scope="inscope">
                        <div
                          :class="[field.class]"
                          class="row-value"
                        >
                          <span>
                            {{ inscope.row.stats.total[`${field.name}`] !== null
                              ? formatValue(inscope.row.stats.total[`${field.name}`], field.format)
                              : `&mdash;` }}
                          </span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </template>
              </ui-table>
            </keep-alive>
          </template>
        </el-table-column>
        <el-table-column
          v-for="day in datesComputed"
          :key="day"
          :label="formatColumnLabel(day)"
          align="center"
          header-align="center"
        >
          <el-table-column
            v-for="field in fields"
            :key="field.name"
            :prop="field.name"
            :label="$t(`reports.fields.${field.name}.title`)"
            :min-width="110"
            align="right"
            resizable
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <Vnodes :vnodes="renderHeader($createElement, scope)" />
            </template>
            <template slot-scope="scope">
              <div
                :style="{color: highlightValue(scope.row, field.name, day)}"
                :class="[field.class]"
                class="row-value"
              >
                <span>{{ scope.row.stats[day][`${field.name}`] !== null ? formatValue(scope.row.stats[day][`${field.name}`], field.format) : `&mdash;` }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :width="440"
          :label="$t('reports.other.total')"
          align="center"
          header-align="center"
          fixed="right"
        >
          <el-table-column
            v-for="field in fields"
            :key="field.name"
            :prop="field.name"
            :label="$t(`reports.fields.${field.name}.title`)"
            :width="110"
            align="right"
            resizable
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <Vnodes :vnodes="renderHeader($createElement, scope)" />
            </template>
            <template slot-scope="scope">
              <div
                :class="[field.class]"
                class="row-value"
              >
                <span>
                  {{ scope.row.stats.total[`${field.name}`] !== null ? formatValue(scope.row.stats.total[`${field.name}`], field.format) : `&mdash;`
                  }}
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>
<script>
import _ from 'lodash/function';
import { mapGetters } from 'vuex';
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import updateUrl from '../../service/updateUrl';
import detectPermissions from '../../service/detectPermissions';
import formatCurrency from '@/views/mixins/format-currency';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'reports/payments/limit';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

const compareFilterKey = {
  country_code_payments_report: 'country_code',
  player_country_code_payments_report: 'player_country_code',
  player_id: 'site_player_id',
  site_id: 'site_id',
  affiliate_payments_report_id: 'affiliate_id',
  // traffic_type: 'traffic_source_id',
};

export default {
  name: 'PaymentsReport',
  components: {
    Vnodes: {
      functional: true,
      render: (h, ctx) => (ctx.props.vnodes ? ctx.props.vnodes : ''),
    },
  },
  mixins: [pageSizeMixin, formatCurrency],
  data() {
    return {
      viewName,
      data: [],
      totals: {},
      dates: [],
      subData: {},
      currencyCode: '',
      fields: [
        {
          name: 'deposits_all_sum',
          format: 'formatMoney',
        },
        {
          name: 'deposits_all_count',
          format: 'number',
        },
        {
          name: 'deposits_withdrawals_sum',
          format: 'formatMoney',
        },
        {
          name: 'deposits_withdrawals_count',
          format: 'number',
        },
      ],
      isDataLoading: {
        data: false,
        affiliates: false,
        players: false,
      },
      dateRange: [],
      shortcutsDates: [
        {
          text: this.$t('reports.date_picker.last_7_day'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(6, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.last_30_day'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(29, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.this_month'),
          range: [this.$moment().startOf('month'), this.$moment().endOf('day')],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.prev_month'),
          range: [
            this.$moment()
              .subtract(1, 'months')
              .startOf('month'),
            this.$moment()
              .subtract(1, 'months')
              .endOf('month'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.last_90_day'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(89, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
      ],
      expandedRows: [],
      count: 0,
      page: 1,
      limit: pageSize,
      isInit: false,
      reportType: 'd',
      filters: {},
      titleRoute: '',
      dataRoute: '',
      disabledFilter: false,
      idChain: [],
      nameChain: [],
      idDelete: [],
      drillFilter: {},
      onDelayReset: false,
    };
  },
  computed: {
    ...mapGetters('reports', ['reportsSettings']),
    ...mapGetters('other', ['getDataPr']),
    ...mapGetters({
      customerCurrencies: 'misc/customerCurrencies',
    }),
    datesComputed() {
      if (this.dates.length === 0) {
        return [
          '2021-03-01',
        ];
      }
      return this.dates;
    },

    days() {
      return this.$_.round(this.$moment
        .duration(this.dateRange[1].diff(this.dateRange[0]))
        .asDays());
    },
    filtersObj() {
      return Object.keys(this.filters).length;
    },

    isCountryFilterAvailable() {
      return this.isPlayerCountryFilter;
    },

    isAffiliatesFilter() {
      if (
        !this.$store.getters['auth/adminAcl'].is_superuser
        && this.$store.getters['auth/currentAcl']
      ) {
        return (
          this.$store.getters['auth/currentAcl'].reports_group_by_affiliates
          === 'allow'
        );
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },

    isPlayerCountryFilter() {
      if (
        !this.$store.getters['auth/adminAcl'].is_superuser
        && this.$store.getters['auth/currentAcl']
      ) {
        return (
          this.$store.getters['auth/currentAcl'].reports_group_by_countries
          === 'allow'
        );
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },

    filtersItems() {
      const val = ['player', 'trafficType', 'brand'];
      if (this.isAffiliatesFilter) {
        val.push('affiliatePaymentsReport');
      }
      if (this.isPlayerCountryFilter) {
        val.push('playerCountryPaymentsReport', 'countryPaymentsReport');
      }
      return val;
    },
  },
  watch: {
    filters: {
      handler(nv) {
        this.$ls.set('reports/payments/filters', JSON.stringify(nv));
      },
      deep: true,
    },
    currencyCode(nv) {
      this.$ls.set('reports/payments/currency_code', nv);
    },
    drillFilter: {
      deep: true,
      handler(v) {
        this.$ls.set('reports/payments/drillFilter', JSON.stringify(v));
      },
    },
    dateRange: {
      deep: true,
      handler(nv, ov) {
        this.$ls.set('reports/payments/daterange', JSON.stringify(nv));
        this.onDelayReset = true;
        if (nv) {
          if (nv !== ov) {
            this.dateRange[0] = this.$moment(nv[0]).startOf('day');
            this.dateRange[1] = this.$moment(nv[1]).endOf('day');
            this.page = 1;
            this.getReport();
          }
        } else {
          this.dateRange = this.shortcutsDates[2].range;
          this.page = 1;
          this.getReport();
        }
        const dur = this.$moment.duration(this.dateRange[1].diff(this.dateRange[0]));
        if (dur.asDays() < 13) {
          this.reportType = 'd';
        } else if (dur.asDays() < 59) {
          this.reportType = 'w';
        } else {
          this.reportType = 'm';
        }
        /*
         * Добавляем URL
         * */
        this._updateCompletedUrl();
      },
    },
  },
  destroyed() {
    this.$store.commit('other/DATA_PR', null);
  },
  created() {
    this.drillFilter = this.getDataPr
      || JSON.parse(this.$ls.get('reports/payments/drillFilter'));

    if (detectPermissions.checkRequest('reports_group_by_payments')) {
      /*
       * Берем hash из роутера и преоброзовываем в object
       * */
      const _filterParams = updateUrl.getParseParams(this.$route.hash, [
        'date',
        'filters',
        'limit',
        'page',
        'currency_code',
      ]);

      /*
       * проверяем hash на пустоту, и подставялем в фильтр
       * */
      if (!this.$_.isEmpty(_filterParams)) {
        /*
         * Подставляем дефолтные значения если в фильтре указаны не валидные данные
         * Не понятно почему QS подменяет массив Обьектом для
         * этого сделал checkFormatBeforeQSparse
         * */
        this.filters = this.checkFormatBeforeQSparse(this.$_.get(_filterParams, ['filters']) || {});

        this.limit = Number(this.$_.get(_filterParams, ['limit'])) || pageSize;
        this.page = Number(this.$_.get(_filterParams, ['page'])) || 1;
        this.currencyCode = this.checkCurrencyCode(this.$_.get(_filterParams, 'currency_code'));

        /*
         * Делаем запрос ajax чтобы не было пустых значений
         * */
        if (this.$_.has(this.filters, 'player_id')) {
          this.$nextTick(async () => {
            const res = await this.$refs.filter.allFilters.player.method.bind(this)('');
            this.$refs.filter.allFilters.player.options = res;
          });
        }

        /*
         * Проверяем даты на валидность дабы не ломать систему
         * */

        if (this.$_.has(this.$route.query, 'to')) {
          this._setParametersGetDate();
        } else if (
          this.$moment(this.$_.get(_filterParams, ['date', 'from'])).isValid()
          && this.$moment(this.$_.get(_filterParams, ['date', 'to'])).isValid()
        ) {
          this.dateRange[0] = this.$moment(this.$_.get(_filterParams, ['date', 'from']));
          this.dateRange[1] = this.$moment(this.$_.get(_filterParams, ['date', 'to']));
          this.durationDate();
        } else {
          this.dateRange = JSON.parse(this.$ls.get('reports/payments/daterange'))
            || this.shortcutsDates[2].range;
        }
      } else {
        if (this.$_.has(this.$route.query, 'to')) {
          this.dateRange[0] = this.$moment(this.$route.query.from);
          this.dateRange[1] = this.$moment(this.$route.query.to);
          this.dateRange = this.$_.cloneDeep(this.dateRange);
          this._setParametersGetDate();
        } else {
          this.dateRange = JSON.parse(this.$ls.get('reports/payments/daterange'))
            || this.shortcutsDates[2].range;
        }
        this.filters = JSON.parse(this.$ls.get('reports/payments/filters')) || {};
        this.limit = +this.$ls.get('reports/payments/limit') || pageSize;
        this.currencyCode = this.checkCurrencyCode(this.$ls.get('reports/payments/currency_code'));
      }
    }
    detectPermissions.checkRoute('reports_group_by_payments');
  },
  mounted() {
    if (detectPermissions.checkRequest('reports_group_by_payments')) {
      if (this.$_.has(this.$route.query, 'to') || this.drillFilter !== null) {
        this.resetFilters();
        this.disabledFilter = true;
      }
      if (
        this.filters
        && ((this.filters.affiliate_payments_report_id
          && !this.isAffiliatesFilter)
          || (this.filters.country_code_payments_report
            && !this.isPlayerCountryFilter)
          || (this.filters.player_country_code_payments_report
            && !this.isPlayerCountryFilter))
      ) {
        this.resetFilters();
      }

      this.isInit = true;
      this.getReport();
    }
  },
  methods: {
    changeCurrencies(currencyCode) {
      this.getReport({ currencyCode });
    },
    checkFormatBeforeQSparse(filters) {
      const filterToArray = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
          if (this.$_.isObject(filters[key]) && !this.$_.isArray(filters[key])) {
            filterToArray[key] = Object.values(filters[key]);
          } else {
            filterToArray[key] = filters[key];
          }
        }
      }
      return filterToArray;
    },

    tabsTranslate(value) {
      const keyName = Object.keys(value.row_name[0])
        .filter(val => !['row_filter_column', 'row_id'].includes(val))[0];
      const defaultTitle = this.reportsSettings.dimensions.find(dim => dim.column_name === keyName);
      return defaultTitle?.title || '';
    },

    clearChain() {
      this.drillFilter = null;
      this.$store.commit('other/DATA_PR', this.drillFilter);
      this.disabledFilter = false;
      this.getReport();
    },
    removeCurrentFilter(filter) {
      delete this.drillFilter[filter];
      this.$store.commit('other/DATA_PR', this.drillFilter);
      if (this.$_.isEmpty(this.drillFilter)) {
        this.$store.commit('other/DATA_PR', null);
        this.drillFilter = null;
        this.disabledFilter = false;
      }
      this.drillFilter = this.$_.clone(this.drillFilter);
      this.getReport();
    },
    removeItemCurrentFilter(row, id, key) {
      this.$_.remove(this.drillFilter[key].row_id, e => e === id);
      this.$_.remove(this.drillFilter[key].row_name, e => e.row_id === id);
      this.$store.commit('other/DATA_PR', this.drillFilter);
      this.drillFilter = this.$_.clone(this.drillFilter);
      this.getReport();
    },
    /*
     * Парсим get параметры из роутера
     * */
    _setParametersGetDate() {
      if (
        this.$_.has(this.$route.query, 'to')
        && this.$_.has(this.$route.query, 'from')
      ) {
        this.dateRange[0] = this.$moment(this.$route.query.from);
        this.dateRange[1] = this.$moment(this.$route.query.to);
        this.durationDate();
        this.disabledFilter = false;
        delete this.$route.query.from;
        delete this.$route.query.to;
      }
    },
    /*
     * Обновляем хеш функции
     * */
    _updateCompletedUrl() {
      const _data = {
        filters: this.filters,
        date: {
          from: this.$moment(this.dateRange[0]).format('YYYY-MM-DD'),
          to: this.$moment(this.dateRange[1]).format('YYYY-MM-DD'),
        },
        limit: this.limit,
        page: this.page,
        currency_code: this.currencyCode,
      };
      updateUrl.updateGetParams(_data);
    },

    durationDate() {
      const dur = this.$moment.duration(this.dateRange[1].diff(this.dateRange[0]));
      if (dur.asDays() < 13) {
        this.reportType = 'd';
      } else if (dur.asDays() < 59) {
        this.reportType = 'w';
      } else {
        this.reportType = 'm';
      }
    },

    queryFilterParse(filters) {
      const parseFilters = {};
      this.$_.forIn(filters, (v, key) => {
        const getkey = compareFilterKey[key] ? compareFilterKey[key] : undefined;
        if (getkey) parseFilters[getkey] = { op: '=', value: v };
      });

      return parseFilters;
    },

    dataQuery() {
      const filters = {};
      this.$_.forIn(this.drillFilter, (v, key) => {
        filters[key] = { op: v.op, value: v.row_id };
      });

      const q = {
        group_by: 'payment_system_name',
        limit: this.limit,
        offset: (this.limit * this.page) - this.limit,
        from: this.dateRange[0]
          ? this.$moment(this.dateRange[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          : '',
        to: this.dateRange[1]
          ? this.$moment(this.dateRange[1])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          : '',
        filters: { ...filters, ...this.queryFilterParse(this.filters) },
        currency_code: this.currencyCode,
        ...this.$_.pick(this.filters, ['traffic_type']),
      };
      if (q.affiliate_payments_report_id) {
        q.affiliate_id = q.affiliate_payments_report_id;
        delete q.affiliate_payments_report_id;
      }
      if (q.player_country_code_payments_report) {
        q.player_country_code = q.player_country_code_payments_report;
        delete q.player_country_code_payments_report;
      }
      if (q.country_code_payments_report) {
        q.country_code = q.country_code_payments_report;
        delete q.country_code_payments_report;
      }
      return q;
    },
    getReport: _.debounce(async function getReport({ currencyCode } = {}) {
      if (!this.isInit) {
        return;
      }
      this.isDataLoading.data = true;

      if (currencyCode) {
        this.currencyCode = currencyCode;
      }

      /*
       * Добавляем фильтры в url
       * */
      this._updateCompletedUrl();

      try {
        const response = await this.$api.getPaymentsReport(this.dataQuery());
        this.dates = this.$_.reverse(this.$_.clone(response.data.payload.dates));
        this.count = this.$_.get(response.data, ['misc', 'count']);
        this.data = this.$_.cloneDeep(response.data.payload.data);
        this.totals = this.$_.cloneDeep(response.data.payload.totals);
      } finally {
        this.$nextTick(this.$refs.dataTable?.$refs.dataTable.doLayout);
        if (this.onDelayReset && this.$refs.dataTable) {
          this.$nextTick(this.$refs.dataTable.$refs.dataTable.doLayout);
          this.$nextTick(() => {
            this.$refs.dataTable.$refs.dataTable.doLayout();
            this.isDataLoading.data = false;
            this.onDelayReset = false;
          });
        } else {
          this.isDataLoading.data = false;
        }
      }
    }, 300),
    getTotalRow() {
      const sum = [];
      sum.push(this.$t('affiliates.estimatedPayment.total'));
      sum.push('');
      if (!this.dates || this.dates.length === 0) {
        return sum;
      }
      if (
        this.$refs.dataTable
        && this.data.length > 0
        && !this.$_.isEmpty(this.totals)
      ) {
        this.dates.forEach((day) => {
          this.fields.forEach((field) => {
            if (this.totals[day][field.name] !== null) {
              sum.push(this.$refs.dataTable.formatValue(
                this.totals[day][field.name],
                field.format,
              ));
            } else {
              sum.push('—');
            }
          });
        });
        this.fields.forEach((field) => {
          if (this.totals.total[field.name] !== null) {
            sum.push(this.$refs.dataTable.formatValue(
              this.totals.total[field.name],
              field.format,
            ));
          } else {
            sum.push('—');
          }
        });
      }
      return sum;
    },

    async getSubReport(row) {
      const filters = {
        payment_system_id: {
          op: '=',
          value: [
            row,
          ],
        },
      };
      const group_by = 'country_name';
      this.isDataLoading[`${row}`] = true;
      const q = {
        ...this.dataQuery(),
        group_by,
        filters: { ...this.dataQuery().filters, ...filters },
      };

      try {
        const response = await this.$api.getPaymentsReport(q);
        this.subData[`${row}`] = this.$_.cloneDeep(response.data.payload.data);
        this.subData[`${row}`].th = this.subData[`${row}`].length * 34;
      } finally {
        this.isDataLoading[`${row}`] = false;
      }
    },

    handleExpand(row, expRows) {
      this.expandedRows = this.$_.cloneDeep(expRows);
      if (!this.$_.has(this.subData, `${row.row_id}`)) {
        this.$set(this.subData, `${row.row_id}`, []);
        this.$set(this.isDataLoading, `${row.row_id}`);
      }
      this.getSubReport(`${row.row_id}`);
    },
    highlightValue(row, field, day) {
      const curr = this.dates.indexOf(day);
      const prev = this.dates.indexOf(day) + 1;
      if (
        curr === 0
        || curr === this.dates.length - 1
        || prev >= this.dates.length
      ) {
        return '';
      }
      const val = parseFloat(row.stats[day][field]);
      const prevVal = parseFloat(row.stats[this.dates[prev]][field]);
      if (val > prevVal * 1.15) {
        return '#5cb000';
      } if (val * 1.15 < prevVal) {
        return this.$theme.dangerColor;
      }
      return '';
    },
    resetFilters() {
      this.page = 1;
      this.$refs.filter.reset();
    },
    handleReportClick(p, c, psn) {
      const q = {
        payment_system_id: p,
        payment_system_name: psn,
        filters: this.filters,
        from: this.dateRange[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        to: this.dateRange[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        // back_link: this.$route.fullPath,
      };
      if (c) {
        q.country_code = c;
      }

      this.$router.push({
        path: `/reports/payments/sub?${this.$qs.stringify(q, {
          arrayFormat: 'repeat',
        })}`,
      });
    },
    handleTableRows(rows) {
      this.limit = rows;
      this.page = 1;
      this.getReport();
    },
    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.$nextTick(() => {
          this.getReport();
        });
      }
    },
    formatColumnLabel(day) {
      switch (this.reportType) {
      case 'd':
        return this.$moment(day).format('DD.MM.YYYY');
      case 'w':
        return `${this.$moment(day)
          .startOf('isoWeek')
          .format('DD.MM')} - ${this.$moment(day)
            .endOf('isoWeek')
            .format('DD.MM')}`;
      case 'm':
        return this.$moment(day).format('MMMM YYYY');
      default:
        return this.$moment(day).format('DD.MM.YYYY');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payments-report {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 10;

  /deep/ {
    .is-group th .cell {
      display: block;
    }
  }
  .toolbar {
    padding: 16px 0;
    border-bottom: 1px solid #eaeaea;
    .wrapper {
      width: 90%;
      min-width: 1140px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .date-label {
        font-size: 16px;
        align-self: center;

        .back {
          margin-right: 12px;
          text-decoration: none;
          cursor: pointer;
        }

        span {
          font-weight: 500;
        }
      }

      .section {
        display: flex;
        align-items: flex-end;

        .pagination {
          margin-right: 20px;
        }

        .left-label {
          color: #303634;
          margin-right: 10px;
        }

        .btn {
          margin-left: 12px;
        }

        .filter_preset {
          margin: 0 12px;
        }

        .reset_link {
          font-size: 14px;
          cursor: pointer;
          margin-bottom: 9px;
          margin-left: 16px;
        }

        .select {
          position: relative;

          .columns {
            position: absolute;
            top: 0px;
            left: -60px;
            opacity: 0;
            visibility: hidden;
          }
        }
      }

      .select-label {
        .range-title {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }

        .text {
          display: block;
          margin-bottom: 5px;
          font-size: 14px;
          color: #303634;
        }

        .select {
          width: 180px;
          height: 34px;
        }
      }
    }

    &.filters {
      position: relative;
      padding: 0;
      border: none;

      .wrapper {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0;
        width: 100%;
        z-index: 900;
        background-color: #f7f7f7;

        .center {
          width: 90%;
          min-width: 1140px;
          margin: 0 auto;

          .filters-wrapper {
            box-sizing: border-box;
            padding: 24px 0;

            .filter-row {
              display: flex;
              margin-bottom: 16px;

              .filter_type {
                width: 160px;
                margin: 0 8px;
              }

              .filter_value {
                width: 280px;
              }
            }

            .controls {
              display: inline-flex;
              flex-wrap: nowrap;
              width: auto;

              .btn + .btn {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }

  .toolbar-chain {
    padding-top: 12px;
    padding-bottom: 0;
    min-height: 37px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 3px;
    width: 100%;
    .item {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-bottom: 8px;
      // height: 24px;
      &.all {
        height: 24px;
        background-color: transparent;
        cursor: pointer;
      }
      &::after {
        content: "|";
        margin: 0 8px;
      }
      &:last-child {
        &::after {
          content: "";
          margin: 0 8px;
        }
      }
      > div {
        height: 24px;
        border-radius: 4px;
        background-color: #eaeaea;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        padding-left: 4px;
        padding-right: 4px;
        .fa-times {
          cursor: pointer;
          padding-left: 4px;
        }
      }
      .value.el-dropdown-selfdefine {
        cursor: pointer;
        padding-right: 4px;
        font-size: 12px;
      }
      .el-dropdown {
        margin-bottom: 2px;
      }
    }
    .section {
      display: flex;
      width: 100%;
    }
    .wrapper {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .link_redirect_back {
      // margin-left: 32px;
      // margin-bottom: 10px;
      display: flex;
      align-items: center;
      width: 100%;
      > span {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-bottom: 12px;
        > span {
          display: flex;
          align-items: center;
          margin-top: 12px;
          // width: 100%;
        }
      }
      .tabs-item-ch {
        // padding-top: 6px;
        max-height: 24px;
        height: 24px;
        border-radius: 4px;
        background-color: #eaeaea;
        // padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        padding-left: 4px;
        padding-right: 4px;
      }
      .title_name {
        display: flex;
        font-size: 13px;
        font-weight: bold;
        color: #303634;
        opacity: 0.9;
        i {
          margin-right: 4px;
        }
      }

      .title_value {
        font-size: 13px;
        color: #303634;
        opacity: 0.9;
      }

      .title_redirect_back {
        cursor: pointer;
        font-size: 14px;
      }

      .title_redirect {
        color: #000;
        font-size: 14px;
      }

      .title_redirect_close {
        display: inline-block;
        cursor: pointer;
        font-size: 18px;
        margin-left: 4px;
      }
    }
  }
}
</style>
