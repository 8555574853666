const tableFieldsExMethodsPost = [
  {
    name: 'incrementing_id',
    width: '60',
  },
  {
    name: 'type',
    width: '90',
  },
  {
    name: 'affiliate_email',
    width: '180',
  },
  {
    name: 'channel_name',
    width: '160',
  },
  {
    name: 'campaign_name',
  },
  {
    name: 'period',
    width: '170',
  },
  {
    name: 'amount',
    width: '120',
  },
  {
    name: 'amount_usd',
    width: '120',
  },
  {
    name: 'review_requested_at',
    width: '120',
  },
];

const tableFieldsExMethodsPayments = [
  {
    name: 'incrementing_id',
    width: '60',
  },
  {
    name: 'affiliate_email',
    width: '180',
  },
  {
    name: 'channel_name',
    width: '160',
  },
  {
    name: 'campaign_name',
  },
  {
    name: 'payment_method_id',
    width: '120',
  },
  {
    name: 'amount',
    width: '120',
  },
  {
    name: 'amount_usd',
    width: '120',
  },
  {
    name: 'review_requested_at',
    width: '120',
  },
];

const tableFieldsUnreportedFunds = [
  {
    name: 'campaigns_without_posts_count',
    width: '250',
  },
  {
    name: 'unreported_sum_usd',
    width: '140',
  },
  {
    name: 'reported_sum_usd',
    width: '140',
  },
];

export { tableFieldsExMethodsPost, tableFieldsExMethodsPayments, tableFieldsUnreportedFunds };
