<template>
  <div class="edit-teams-user">
    <div class="popup">
      <div class="head">
        <span class="name">{{ this.$t('team_popup.edit_user_title', { name: user.name }) }}</span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <adm-ui-scrollbar
        wrap-style="max-height: 60vh; height: auto;"
        :native="false"
      >
        <div
          v-loading="isLoading"
          class="body"
        >
          <div class="select-wrap">
            <ui-input
              v-model="userChange.name"
              :error="errors.name"
              :placeholder="$t(`team_popup.name_placeholder`)"
              :label="$t(`team_popup.name_label`)"
              size="big"
              autosize
            />
          </div>

          <div class="select-wrap">
            <ui-input
              v-model="userChange.email"
              :error="errors.email"
              :placeholder="$t(`team_popup.email_placeholder`)"
              :label="$t(`team_popup.email_label`)"
              size="big"
              autosize
            />
          </div>

          <div class="select-wrap">
            <div class="select-block select-block__full">
              <span class="label">{{ $t(`team_popup.timezone_label`) }}</span>
              <el-select
                v-model="userChange.timezone"
                :placeholder="$t(`team_popup.timezone_placeholder`)"
                filterable
                class="select"
                size="big"
              >
                <el-option
                  v-for="(item) in timezones"
                  :key="item.zone"
                  :label="item.name"
                  :value="item.zone"
                />
              </el-select>
            </div>
          </div>

          <div class="select-wrap">
            <div class="select-block select-block__full">
              <div class="select-block_title">
                <span class="label">{{ $t(`team_popup.see_stats_label`) }}</span>
                <el-checkbox
                  v-model="isOverall"
                  class="checkbox"
                >
                  {{ $t(`team_popup.see_stats_overall`) }}
                </el-checkbox>
              </div>
              <div class="date-block">
                <el-date-picker
                  ref="datepicker"
                  v-model="userChange.entities_visibility_from"
                  :picker-options="{
                    firstDayOfWeek: 1,
                  }"
                  :class="{'error': $_.isArray(errors.entities_visibility_from)}"
                  :placeholder="$t(`team_popup.see_stats_placeholder`)"
                  :disabled="isOverall"
                  format="dd MMM yyyy"
                  type="date"
                  class="dtpicker"
                />
                <div class="spacer">
                  –
                </div>
                <el-date-picker
                  ref="datepicker"
                  v-model="userChange.entities_visibility_to"
                  :picker-options="{
                    firstDayOfWeek: 1,
                  }"
                  :class="{'error': $_.isArray(errors.entities_visibility_to)}"
                  :placeholder="$t(`team_popup.see_stats_placeholder`)"
                  :disabled="isOverall"
                  format="dd MMM yyyy"
                  type="date"
                  class="dtpicker"
                />
              </div>
              <div
                v-if="$_.isArray(errors.entities_visibility_to)"
                class="input-error"
              >
                {{ $t(`${errors.entities_visibility_to[0].message}`) }}
              </div>
            </div>
          </div>

          <div class="select-wrap">
            <div class="select-block">
              <span class="label">{{ $t(`team_popup.messenger_label`) }}</span>
              <el-select
                v-model="userChange.messenger"
                :placeholder="$t(`team_popup.messenger_placeholder`)"
                size="big"
                @change="userChange.messenger_id = ''"
              >
                <el-option
                  v-for="item in messengers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
            <div class="select-block">
              <ui-input
                v-model="userChange.messenger_id"
                :error="errors.messenger_id"
                :disabled="!userChange.messenger"
                :placeholder="messengerPlaceholder"
                :label="$t(`team_popup.messenger_id_label`)"
                size="big"
                autosize
              />
            </div>
          </div>

          <div class="select-wrap">
            <div class="select-block select-block__full">
              <span class="label">{{ $t(`team_popup.note_label`) }}</span>
              <el-input
                v-model="userChange.notes"
                :error="errors.notes"
                :rows="3"
                :placeholder="$t(`team_popup.note_placeholder`)"
                type="textarea"
                resize="none"
              />
            </div>
          </div>
        </div>
      </adm-ui-scrollbar>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          @click.native="close"
        >
          {{ $t(`team_popup.cancel_button`) }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          filled
          @click="save"
        >
          <i class="fas fa-check plus-icon-custom" />{{ $t(`team_popup.save_button`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>

export default {
  name: 'EditTeamsUser',
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    timezones: {
      type: Array,
      default() {
        return [];
      },
    },
    messengers: {
      type: Array,
      default() {
        return [];
      },
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      errors: {},
      userChange: {},
      isOverall: false,
      isLoading: false,
    };
  },

  computed: {
    messengerPlaceholder: {
      get() {
        if (this.userChange.messenger) {
          const placeholder = this.messengers ? this.$_.find(this.messengers, ['id', this.userChange.messenger]) : '';
          return placeholder ? placeholder.example_placeholder : '';
        }
        return `${this.$t('team_popup.messenger_id_placeholder')}`;
      },
    },
  },

  watch: {
    isOpen() {
      this.userChange = this.$_.pick(this.user, [
        'name',
        'email',
        'timezone',
        'messenger',
        'messenger_id',
        'notes',
        'entities_visibility_from',
        'entities_visibility_to',
      ]);
      this.isOverall = this.userChange.entities_visibility_from === null && this.userChange.entities_visibility_to === null;
    },
  },

  mounted() {

  },

  methods: {

    close() {
      this.errors = {};
      this.$emit('close');
    },

    save() {
      this.isLoading = true;
      this.errors = {};
      if (this.isOverall) {
        this.userChange.entities_visibility_from = null;
        this.userChange.entities_visibility_to = null;
      } else {
        this.userChange.entities_visibility_from = this.userChange.entities_visibility_from === null ? null : this.$moment(this.userChange.entities_visibility_from).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.userChange.entities_visibility_to = this.userChange.entities_visibility_to === null ? null : this.$moment(this.userChange.entities_visibility_to).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      this.$api
        .putAdmins(this.user.id, this.userChange)
        .then((response) => {
          this.$emit('refresh', response);
          this.$emit('close');
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

  },
};
</script>
