export default {
  tfaSetupView: {
    backLink: 'Страница входа',
    title: 'Настройка двухфакторной аутентификации',
    steps: [
      '1. Откройте Google Authenticator и <span>отсканируйте QR-код</span>',
      '2. <span>Введите код</span> из <br> Google Authenticator',
    ],
    codeLabel: 'Код',
    checkBtn: {
      text: 'Проверить код',
    },
    google: 'Google',
    authenticator: 'Authenticator',
  },
};
