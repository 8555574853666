const tableFieldsExMethodsPlayerList = [
  {
    name: 'device_model',
  },
  {
    name: 'os',
  },
  {
    name: 'user_ip',
    width: '180',
  },
  {
    name: 'last_active',
    width: '140',
  },
];

export default tableFieldsExMethodsPlayerList;
