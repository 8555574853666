<template>
  <div class="landing-match-popup">
    <div class="popup">
      <div class="head">
        <span
          class="name"
        >{{ $t(`tools.landingLeagues.leagues.${action}`) }} {{ $t(`tools.match_popup.match`) }}</span>
        <ui-icon
          :size="20"
          class="close"
          lib="fa"
          substyle="fas"
          name="times"
          @click.native="close"
        />
      </div>
      <div
        v-loading="isDataLoading"
        class="form"
      >
        <div class="row">
          <div class="field">
            <span class="label">{{ $t(`tools.match_popup.date_time`) }}</span>
            <!-- {{date}}
            {{realDate}}-->
            <el-date-picker
              v-model="date"
              :local-time="false"
              :class="{'error': $_.isArray(errors.starts_at)}"
              :picker-options="{
                firstDayOfWeek: 1,
              }"
              :placeholder="$t(`tools.match_popup.placeholder_date_time`)"
              format="dd MMM yyyy HH:mm"
              type="datetime"
              class="dtpicker"
            />
            <span
              v-if="$_.isArray(errors.starts_at)"
              class="input-error"
            >{{ $t(`${errors.starts_at[0].message}`) }}</span>
          </div>

          <div class="field">
            <span class="label">{{ $t(`tools.match_popup.timezone`) }}</span>
            <el-select
              v-model="utc"
              :placeholder="$t(`tools.match_popup.placeholder_timezone`)"
              filterable
              class="select"
            >
              <el-option
                v-for="(item) in timezones"
                :key="item.name"
                :label="item.name"
                :value="item.zone"
              />
            </el-select>
          </div>
        </div>
        <div
          v-loading="!(!isDataLoading && !isTeamsLoading)"
          class="row"
        >
          <div class="field">
            <span class="label">{{ $t(`tools.match_popup.team`) }} 1</span>
            <el-select
              v-model="team1"
              v-loading="isDataLoading"
              :disabled="teams.lenght === 0"
              :class="{'error': $_.isArray(errors.team_home_id)}"
              :placeholder="$t(`tools.match_popup.select_team`) + ' 1'"
              filterable
              class="select"
            >
              <el-option
                v-for="item in teams"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <span
              v-if="$_.isArray(errors.team_home_id)"
              class="input-error"
            >{{ $t(`${errors.team_home_id[0].message}`) }}</span>
          </div>
          <div class="field">
            <span class="label">{{ $t(`tools.match_popup.team`) }} 2</span>
            <el-select
              v-model="team2"
              v-loading="isDataLoading"
              :disabled="teams.lenght === 0"
              :class="{'error': $_.isArray(errors.team_away_id)}"
              :placeholder="$t(`tools.match_popup.select_team`) + ' 2'"
              filterable
              class="select"
            >
              <el-option
                v-for="item in teams"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <span
              v-if="$_.isArray(errors.team_away_id)"
              class="input-error"
            >{{ $t(`${errors.team_away_id[0].message}`) }}</span>
          </div>
        </div>
        <div class="row">
          <el-checkbox
            v-model="oddAuto"
            class="checkbox"
          >
            {{ $t(`tools.match_popup.get_odds`) }}
          </el-checkbox>
        </div>
        <div class="row">
          <div class="field">
            <ui-input
              v-model="link"
              :error="errors.page_url"
              :disabled="!oddAuto"
              :label="$t(`tools.match_popup.link_btw`)"
              size="big"
              placeholder="https://betting.com/line/category/game.html "
              autosize
              class="select"
            />
            <!-- <span class="tip">For example: line/Football/118587-UEFA-Champions-League/41222767-Porto-Roma/</span> -->
          </div>
        </div>
        <div class="row">
          <div class="field field3">
            <ui-input
              v-model="odd1"
              :error="errors.odd_1"
              :label="$t(`tools.match_popup.odd`) + ' 1'"
              :placeholder="$t(`tools.match_popup.input_odd`) + ' 1'"
              size="big"
              autosize
              class="select"
            />
            <span class="tip err" />
          </div>
          <div class="field field3">
            <ui-input
              v-model="oddx"
              :error="errors.odd_x"
              :label="$t(`tools.match_popup.odd`) + ' X'"
              :placeholder="$t(`tools.match_popup.input_odd`) + ' X'"
              size="big"
              autosize
              class="select"
            />
            <span class="tip err" />
          </div>
          <div class="field field3">
            <ui-input
              v-model="odd2"
              :error="errors.odd_2"
              :label="$t(`tools.match_popup.odd`) + ' 2'"
              :placeholder="$t(`tools.match_popup.input_odd`) + ' 2'"
              size="big"
              autosize
              class="select"
            />
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          class="btn cancel-button"
          lib="fa"
          substyle="fas"
          icon="times"
          size="big"
          @click.native="close"
        >
          {{ $t(`tools.match_popup.cancel`) }}
        </ui-button>
        <ui-button
          v-if="action === 'Add' || action === 'New'"
          :loading="isMatchProcessing"
          filled
          lib="fa"
          substyle="fas"
          size="big"
          icon="plus"
          @click="save"
        >
          {{ $t(`tools.landingLeagues.leagues.${action}`) }} {{ $t(`tools.match_popup.match`) }}
        </ui-button>
        <ui-button
          v-else
          :loading="isMatchProcessing"
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          size="big"
          @click="save"
        >
          {{ $t(`tools.match_popup.save`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
import moment from 'moment-timezone';
// import MixinMoment from './mixin/moment-select';
import { mapGetters } from 'vuex';

export default {
  name: 'LandingMatchPopup',
  // mixins: [MixinMoment],
  props: {
    action: {
      type: String,
      default: 'Add',
    },
    leagueId: {
      type: [String, Number],
      default: '',
    },
    siteId: {
      type: [String],
      default: '',
    },
    match: {
      type: Object,
      default() {
        return {};
      },
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    leagues: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isMatchProcessing: false,
      isDataLoading: false,
      isTeamsLoading: false,
      currentLeagueId: '',
      leaguesNew: {},
      utc: 'UTC',
      teams: [],
      team1: '',
      team2: '',
      oddAuto: true,
      odd1: '',
      oddx: '',
      odd2: '',
      link: '',
      matchId: '',
      matchOne: {},
      date: '',
      dateTimezone: '',
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      timezones: 'misc/timezones',
    }),
    realDate() {
      return moment(this.date).format('YYYY-MM-DD HH:mm:ss');
    },
    checkFilter() {
      if (this.offer.settings.traffic_filter[0].conditions.country_code.predicate === 'EQUALS') {
        if (this.offer.settings.traffic_filter[0].conditions.country_code.values.length > 0) {
          return true;
        }
        return false;
      }
      return true;
    },
    getMatchedContries() {
      return this.tags.map(tag => this.$_.intersection(this.offer.settings.traffic_filter[0].conditions.country_code.values, tag.values));
    },
  },
  watch: {
    isOpen() {
      if (this.action === 'Edit') {
        this.isDataLoading = true;
        this.$api.getMatch(this.match.id)
          .then((res) => {
            this.utc = 'UTC';
            this.matchOne = res.data.payload;
            this.currentLeagueId = this.matchOne.league_id;
            this.team1 = this.matchOne.team_home_id;
            this.team2 = this.matchOne.team_away_id;
            this.link = this.matchOne.page_url;
            this.oddAuto = this.matchOne.page_url !== null;
            this.matchId = this.matchOne.id;
            this.date = moment.utc(this.matchOne.starts_at).tz(this.utc).format('YYYY-MM-DD HH:mm:ss');
            this.odd1 = this.matchOne.odd_1;
            this.odd2 = this.matchOne.odd_2;
            this.oddx = this.matchOne.odd_x;
            this.getTeams();
          })
          .finally(() => {
            this.isDataLoading = false;
          });
      } else {
        this.team1 = '';
        this.team2 = '';
        this.date = '';
        this.link = '';
        this.odd1 = '';
        this.odd2 = '';
        this.oddx = '';
        this.currentLeagueId = parseInt(this.leagueId, 10) || '';
      }
    },
    currentLeagueId() {
      if (this.action === 'Add' || this.action === 'New') {
        this.team1 = '';
        this.team2 = '';
      }
      this.errors.league_id = true;
      this.getTeams();
    },
    date() {
      this.errors.starts_at = true;
    },
    team1() {
      this.errors.team_home_id = true;
    },
    team2() {
      this.errors.team_away_id = true;
    },
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    });
  },
  methods: {
    getTimezoneDate(date) {
      const oldDate = new Date(date);
      const hour = oldDate.getHours();
      this.dateTimezone = oldDate.setHours(hour + this.utc);
    },
    getTeams() {
      this.isTeamsLoading = true;
      this.$api
        .getTeams({
          league_id: this.currentLeagueId,
        })
        .then((response) => {
          this.isTeamsLoading = false;
          this.teams = this.$_.cloneDeep(response.data.payload);
        })
        .finally(() => {
          this.isTeamsLoading = false;
        });
    },
    close() {
      this.currentLeagueId = '';
      this.team1 = '';
      this.utc = 'UTC';
      this.team2 = '';
      this.link = '';
      this.matchId = '';
      this.date = '';
      this.errors = {};
      this.odd1 = '';
      this.odd2 = '';
      this.oddx = '';
      this.$emit('close');
    },
    save() {
      this.isMatchProcessing = true;
      let q;
      switch (this.action) {
      case 'New':
        q = {
          team_home_id: this.team1,
          team_away_id: this.team1 === this.team2 ? '' : this.team2,
          league_id: this.currentLeagueId,
          page_url: this.link,
          starts_at: moment.tz(this.realDate, this.utc).utc().format('YYYY-MM-DD HH:mm:ss'),
          update_odds_automatically: this.oddAuto,
          odd_1: this.odd1 || null,
          odd_2: this.odd2 || null,
          odd_x: this.oddx || null,
          site_id: this.siteId,
        };
        this.$api.createMatch(q)
          .then((response) => {
            this.leaguesNew = this.$_.cloneDeep(response.data.payload);
            this.close();
          })
          .catch((error) => {
            this.errors = error.data.errors;
          })
          .finally(() => {
            this.isMatchProcessing = false;
          });
        break;
      case 'Add':
        q = {
          team_home_id: this.team1,
          team_away_id: this.team1 === this.team2 ? '' : this.team2,
          league_id: this.currentLeagueId,
          page_url: this.link,
          starts_at: moment.tz(this.realDate, this.utc).utc().format('YYYY-MM-DD HH:mm:ss'),
          update_odds_automatically: this.oddAuto,
          odd_1: this.odd1 || null,
          odd_2: this.odd2 || null,
          odd_x: this.oddx || null,
          site_id: this.siteId,
        };
        this.$api
          .createMatch(q)
          .then((response) => {
            this.leaguesNew = this.$_.cloneDeep(response.data.payload);
            this.close();
          })
          .catch((error) => {
            this.errors = error.data.errors;
          })
          .finally(() => {
            this.isMatchProcessing = false;
          });
        break;
      case 'Edit':

        q = {
          team_home_id: this.team1,
          team_away_id: this.team1 === this.team2 ? '' : this.team2,
          league_id: this.$_.cloneDeep(this.currentLeagueId),
          page_url: this.link,
          starts_at: moment.tz(this.realDate, this.utc).utc().format('YYYY-MM-DD HH:mm:ss'),
          update_odds_automatically: this.oddAuto,
          odd_1: this.odd1 || null,
          odd_2: this.odd2 || null,
          odd_x: this.oddx || null,
          site_id: this.siteId,
        };
        this.$api
          .updateMatch(this.matchId, q)
          .then((response) => {
            this.leaguesNew = this.$_.cloneDeep(response.data.payload);
            this.close();
          })
          .catch((error) => {
            this.errors = error.data.errors;
          })
          .finally(() => {
            this.isMatchProcessing = false;
          });
        break;
      default:
        break;
      }
    },
  },
};
</script>
