<template>
  <div
    v-if="isOpen"
    class="dns-hosting-account-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">{{ $t(`domainManagement.popup.${actionName}`) }} {{ $t(`domainManagement.popup.platformServer`) }}</span>
        <ui-icon
          :size="20"
          class="close"
          lib="fa"
          substyle="fas"
          name="times"
          @click.native="close"
        />
      </div>
      <adm-ui-scrollbar
        wrap-style="max-height: 60vh; height: auto;"
        :native="false"
      >
        <div class="form full-width">
          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.server_name.label`) }} <span class="required">*</span></span>
              <ui-input
                v-model="name"
                :placeholder="$t(`domainManagement.popup.form.server_name.placeholder`)"
                autosize
                :class="{ error: isInvalidField('name') }"
                @blur="touchField('name')"
              />
            </div>
          </div>

          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.server_description.label`) }} <span class="required">*</span></span>
              <el-input
                v-model="description"
                :placeholder="$t(`domainManagement.popup.form.server_description.placeholder`)"
                :rows="3"
                resize="none"
                type="textarea"
                :class="{ error: isInvalidField('description') }"
                @blur="touchField('description')"
              />
            </div>
          </div>

          <div class="row">
            <div class="field switch">
              <span class="label-text">{{ $t(`domainManagement.popup.form.server_auto_pick.label`) }}</span>
              <el-switch
                v-model="can_be_picked_automatically"
                :active-color="$theme.accentColor"
                :inactive-color="$theme.dangerColor"
              />
            </div>
          </div>

          <div
            v-if="!isUpdate"
            class="row"
          >
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.main_ipv4_address.label`) }} <span class="required">*</span></span>
              <ui-input
                v-model="main_ipv4_address"
                :placeholder="$t(`domainManagement.popup.form.main_ipv4_address.placeholder`)"
                autosize
                :class="{ error: isInvalidField('main_ipv4_address') }"
                @blur="touchField('main_ipv4_address')"
              />
            </div>
          </div>

          <div
            v-if="!isUpdate"
            class="row"
          >
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.additional_ipv4_addresses.label`) }}</span>
              <el-input
                v-model="additional_ipv4_addresses"
                :placeholder="$t(`domainManagement.popup.form.additional_ipv4_addresses.placeholder`)"
                :rows="3"
                resize="none"
                type="textarea"
                :class="{ error: isInvalidField('additional_ipv4_addresses') }"
                @blur="touchField('additional_ipv4_addresses')"
              />
            </div>
          </div>

          <div
            v-if="!isUpdate"
            class="row"
          >
            <div class="field">
              <ui-input
                v-model="main_ipv6_address"
                :placeholder="$t(`domainManagement.popup.form.main_ipv6_address.placeholder`)"
                :label="$t(`domainManagement.popup.form.main_ipv6_address.label`)"
                autosize
                :class="{ error: isInvalidField('main_ipv6_address') }"
                @blur="touchField('main_ipv6_address')"
              />
            </div>
          </div>

          <div
            v-if="!isUpdate"
            class="row"
          >
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.additional_ipv6_addresses.label`) }}</span>
              <el-input
                v-model="additional_ipv6_addresses"
                :placeholder="$t(`domainManagement.popup.form.additional_ipv6_addresses.placeholder`)"
                :rows="3"
                resize="none"
                type="textarea"
                :class="{ error: isInvalidField('additional_ipv6_addresses') }"
                @blur="touchField('additional_ipv6_addresses')"
              />
            </div>
          </div>
        </div>
      </adm-ui-scrollbar>
      <div class="foot">
        <ui-button
          color="red"
          class="btn cancel-button"
          lib="fa"
          substyle="fas"
          icon="times"
          size="big"
          @click.native="close"
        >
          {{ $t(`domainManagement.popup.cancel`) }}
        </ui-button>
        <ui-button
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          size="big"
          :disabled="$v.$invalid"
          @click="save"
        >
          {{ $t(`domainManagement.popup.save`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
import {
  required, minLength, maxLength, ipAddress, requiredIf,
} from 'vuelidate/lib/validators';
import { ipv6Address, ipv6Addresses, ipv4Addresses } from '@/helpers/validators';
import validateMixin from './mixin/validate';

export default {
  name: 'PlatformServerPopup',

  mixins: [validateMixin],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    initalData: {
      type: Object,
      default() {
        return {
          name: '',
          description: '',
          can_be_picked_automatically: true,
        };
      },
    },
  },

  data() {
    return {
      name: this.initalData.name,
      description: this.initalData.description,
      can_be_picked_automatically: this.initalData.can_be_picked_automatically,
      main_ipv4_address: '',
      additional_ipv4_addresses: '',
      main_ipv6_address: '',
      additional_ipv6_addresses: '',
    };
  },

  computed: {
    isUpdate() {
      return !!this.initalData.id;
    },
    actionName() {
      return (!this.initalData.id) ? 'add' : 'edit';
    },
  },

  validations() {
    return {
      name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255),
      },
      description: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(1024),
      },
      main_ipv4_address: {
        required: requiredIf(data => !data.isUpdate),
        ipAddress,
      },
      main_ipv6_address: {
        ipv6Address,
      },
      additional_ipv4_addresses: {
        ipv4Addresses,
      },
      additional_ipv6_addresses: {
        ipv6Addresses,
      },
    };
  },

  watch: {
    isOpen() {
      [
        this.name,
        this.description,
        this.can_be_picked_automatically,
      ] = [
        this.initalData.name,
        this.initalData.description,
        this.initalData.can_be_picked_automatically,
      ];
    },
  },

  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },

  destroyed() {
    window.removeEventListener('keyup', this.keyupHandler);
  },

  methods: {
    close() {
      this.resetForm();
      this.$emit('close');
    },

    save() {
      const data = {
        name: this.name,
        description: this.description,
        can_be_picked_automatically: this.can_be_picked_automatically,
      };

      if (!this.initalData.id) {
        data.main_ipv4_address = this.main_ipv4_address;
        data.additional_ipv4_addresses = this.additional_ipv4_addresses ? this.additional_ipv4_addresses.trim().split('\n').map(ip => ip.trim()) : [];
        data.main_ipv6_address = this.main_ipv6_address || undefined;
        data.additional_ipv6_addresses = this.additional_ipv6_addresses ? this.additional_ipv6_addresses.trim().split('\n').map(ip => ip.trim()) : [];

        this.$emit('create', data);
      } else {
        this.$emit('update', data);
      }
    },

    keyupHandler(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    },

    resetForm() {
      this.resetTouchedFields();

      this.name = '';
      this.description = '';
      this.can_be_picked_automatically = true;
      this.main_ipv4_address = '';
      this.additional_ipv4_addresses = '';
      this.main_ipv6_address = '';
      this.additional_ipv6_addresses = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.dns-hosting-account-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .cancel-button {
    margin-right: 8px;
  }

  .popup {
    position: absolute;
    z-index: 902;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid #eaeaea;
      padding: 16px 24px;
      box-sizing: border-box;
      margin-bottom: 0;

      .name {
        font-size: 20px;
        font-weight: 500;
      }

      .close {
        color: #303634;
        padding: 5px;
        font-size: 32px;
        cursor: pointer;
      }
    }
    .form {
      padding: 16px 24px;
      box-sizing: border-box;

      .switch{
        display: flex;
        align-items: center;
        .label-text{
          margin-bottom: 0;
          margin-right: 8px;
        }
      }
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      margin-top: 0;
    }
  }

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
</style>
