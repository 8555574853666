<template>
  <panel
    class="crm-activity-segment"
    :error="hasError"
  >
    <create-segment
      :is-open="isOpenCreateSegmentPopup"
      :popup-type="popupType"
      :segment-id="segmentId"
      :current-brand="currentBrand"
      :is-local-segment="segmentType === 'local'"
      :local-segment="localSegment"
      :local-segment-errors="localSegmentErrors"
      @updateLocalSegment="handleUpdateLocalSegment"
      @closePopupSegment="closeSegmentPopup()"
    />

    <view-segment
      :is-open="isOpenViewSegmentPopup"
      :segment-id="segmentId"
      :current-brand="currentBrand"
      :show-filters-mode="showFiltersMode"
      :show-comments="false"
      :show-edit="false"
      :player-attached="activity.is_players_attached"
      :activity="activity"
      :activity-filters.sync="activity.filters"
      :is-local-segment="segmentType === 'local'"
      :activity-segment-type="segmentType"
      :state="state"
      :local-segment="localSegment"
      @update:activity-filters="updateSegmentStats"
      @updateFilterStats="handleUpdateFilterStats"
      @closePopupSegment="closeSegmentPopup()"
    />
    <div class="ui-d-flex ui-m-md-b">
      <panel-header-title>
        {{ $t('crm.activities.segment.title') }}
      </panel-header-title>
    </div>

    <template v-if="state === 'edit' || state === 'rework-edit'">
      <div class="ui-d-flex ui-m-xl-b">
        <panel-header-subtext>
          <span v-html="$t('crm.activities.segment.hint')" />
        </panel-header-subtext>
      </div>

      <div class="ui-d-flex ui-m-xl-b">
        <el-radio-group
          v-model="segmentType"
          class="ui-radio-group"
          size="medium"
        >
          <el-radio-button :disabled="loading" label="local">
            {{ $t('crm.activities.segment.local') }}
          </el-radio-button>
          <el-radio-button :disabled="loading || (!canViewSegments && globalSegmentId === '')" label="global">
            {{ $t('crm.activities.segment.global') }}
          </el-radio-button>
        </el-radio-group>
        <div
          :class="[
            'crm-activity-segment__add-wrapper',
            'ui-m-sm-l',
          ]"
        >
          <div
            v-if="segmentType === 'local'"
            :class="{
              'dashboard-overview__metric-card-add': true,
              'crm-activity-segment__add-local-segment-button': true,
              'crm-activity-segment__add-local-segment-button_disabled': segment,
            }"
            @click="handleAddLocalSegment"
          >
            <adm-ui-icon :icon="['fas', 'fa-plus']" />
            <adm-ui-header tag="h3">
              <!-- <template v-if="segment">
                {{ $t('crm.activities.segment.replace_button') }}
              </template>
              <template v-else>
                {{ $t('crm.activities.segment.create_button') }}
              </template> -->
              {{ $t('crm.activities.segment.create_button') }}
            </adm-ui-header>
          </div>
          <el-select
            v-if="segmentType === 'global'"
            value-key="id"
            :value="globalSegmentId"
            class="crm-activity-segment__add-global-select select"
            size="medium"
            filterable
            autocomplete="on"
            :remote="segments.length > 0"
            :remote-method="setAutocompleteQuery"
            @change="handleUpdateGlobalSegment"
            @visible-change="handlerVisibleChange"
          >
            <el-option
              v-for="item in approvedSegments"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
      </div>
    </template>
    <panel
      v-if="segment"
      type="contrast"
    >
      <activity-check-control
        v-if="state === 'rework' || (state === 'check' && canReview)"
        slot="prepend"
        v-model="reworkState.is_approved_segment"
        :can-edit="state === 'check'"
      />
      <div class="ui-g-w-md ui-jc-space-between">
        <div class="crm-activity-segment__segment-stats ui-d-flex">
          <crm-tag
            v-if="!isHiddenStats"
            class="ui-g-md players-wrapper"
            icon="users"
          >
            <div v-loading="!isDisplayPlayers" class="players-loader" :class="{ loading: !isDisplayPlayers }">
              <span v-if="isDisplayPlayers">{{ playersCount }}</span>
            </div>
          </crm-tag>
          <crm-tag
            class="ui-g-md"
            preset="contrast"
            :icon="typeIcon"
          >
            {{ $t(`crm.segments.type.${segment.type}`) }}
          </crm-tag>
          <crm-tag
            v-if="activity.crm_segment_is_global"
            class="ui-g-md"
            preset="contrast"
            icon="globe"
          >
            {{ $t('crm.activities.segment.global') }}
          </crm-tag>
          <crm-tag
            v-else
            class="ui-g-md"
            preset="contrast"
            icon="sliders-h"
          >
            {{ $t('crm.activities.segment.local') }}
          </crm-tag>
          <crm-tag
            v-if="segmentType === 'global' && segment && segment.moderation_status !== 'approved'"
            class="ui-g-md"
            preset="red"
            icon="thumbs-down"
          >
            {{ $t('crm.activities.segment.not_approved') }}
          </crm-tag>
          <crm-tag
            v-if="hasFilters"
            class="ui-g-md"
            preset="red"
            icon="filter"
          >
            {{ $t('crm.activities.segment.filtered') }}
          </crm-tag>

          <span
            :class="[
              'ui-g-md',
              'crm-activity__panel-text',
              'crm-activity-segment__segment-name',
            ]"
            :title="segment.name"
          >
            {{ segment.name }}
          </span>
        </div>
        <div class="ui-d-flex ui-ai-center crm-activity-segment__right-panel-controls">
          <panel-action-icon
            v-if="(viewSegment || canEditSegment) || !activity.crm_segment_is_global"
            key="preview"
            :tooltip="$t('crm.tooltips.preview')"
            icon="eye"
            :color="$theme.accentColor"
            :disabled="!activity.crm_segment_id || isHiddenStats || (isCreate && !!localSegment)"
            @click="handlePreviewSegment"
          />

          <panel-action-icon
            v-if="(viewSegment || !activity.crm_segment_is_global) && state === 'approved'"
            :tooltip="$t('crm.tooltips.download')"
            icon="down"
            :color="$theme.accentColor"
            @click="exportData"
          />
          <!-- :disabled="!activity.crm_segment_id" -->

          <template v-if="state === 'edit' || state === 'rework-edit'">
            <template v-if="!activity.crm_segment_is_global">
              <panel-action-icon
                key="edit"
                :tooltip="$t('crm.tooltips.edit')"
                icon="pen"
                :color="$theme.accentColor"
                @click="handleEditLocalSegment"
              />
              <panel-action-icon
                key="delete"
                :tooltip="$t('crm.tooltips.delete')"
                icon="trash-alt"
                :color="$theme.dangerColor"
                @click="handleDeleteSegment"
              />
            </template>
            <panel-action-icon
              v-if="(viewSegment || canEditSegment) && activity.crm_segment_is_global"
              key="filter"
              :tooltip="$t('crm.tooltips.filter')"
              icon="filter"
              :color="$theme.accentColor"
              @click="handleAddFilter"
            />
          </template>
        </div>
      </div>
    </panel>
    <div
      v-if="errorsDetailed.length"
      class="ui-d-flex ui-fd-column ui-ai-start ui-g-w-md"
      :class="{
        'ui-m-md-t': segment
      }"
    >
      <crm-error-panel
        v-for="(error, index) in errorsDetailed"
        :key="index"
        class="ui-g-md"
      >
        {{ error }}
      </crm-error-panel>
    </div>
  </panel>
</template>

<script>
import numeral from 'numeral';
import hash from 'object-hash';
import * as Panel from '@/views/CRM/components/Panel';
import CrmTag from '@/views/CRM/components/CrmTag.vue';
import ActivityCheckControl from '@/views/CRM/Activities/components/ActivityCheckControl.vue';
import CreateSegment from '@/views/CRM/components/CreateSegment.vue';
import ViewSegment from '@/views/CRM/components/ViewSegment.vue';
import CrmErrorPanel from '@/views/CRM/components/CrmErrorPanel.vue';
import SegmentStatsMixin from '@/views/CRM/mixins/segment-stats-mixin.js';
import fileSaver from '@/helpers/file-saver.js';
import { uniqByProp, compose } from '@/helpers/removing-duplicates-from-array.js';

export default {
  name: 'ActivitySegment',
  components: {
    ...Panel,
    CrmTag,
    ActivityCheckControl,
    CreateSegment,
    ViewSegment,
    CrmErrorPanel,
  },
  mixins: [SegmentStatsMixin],
  props: {
    canReview: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    viewSegment: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String,
      default: 'edit',
    },
    activity: {
      type: Object,
      default: () => ({}),
    },
    oldActivity: {
      type: Object,
      default: () => ({}),
    },
    segments: {
      type: Array,
      default: () => ([]),
    },
    currentBrand: {
      type: String,
      default: '',
    },
    reworkState: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      checked: true,
      isOpenCreateSegmentPopup: false,
      isOpenViewSegmentPopup: false,
      showFiltersMode: 'preview',
      popupType: 'new',
      localSegment: null,
      localSegmentId: '',
      globalSegmentId: '',
      segmentStats: this.getDefaultsegmentStats(),
      segmentType: 'global',
      autocompleteQuery: '',
      autocompleteQueryResult: null,
      allSegmentEvnSearch: [],
      segmentPermission: {},
      statsLoading: false,
    };
  },
  computed: {
    canEditSegment() {
      return this.segmentPermission.can_edit;
    },
    isDisplayPlayers() {
      if (this.statsLoading) {
        return false;
      }

      if (this.activity.id) {
        return this.activity.is_players_attached;
      }

      return true;
    },
    isHiddenStats() {
      return this.segment.type === 'manual' && this.segmentType === 'local' && (this.state === 'edit' || this.state === 'rework-edit');
    },
    typeIcon() {
      switch (this.segment.type) {
      case 'dynamic':
        return 'wave-square';
      case 'manual':
        return 'gear';
      default:
        return 'lock';
      }
    },
    isSuperUser() {
      return this.$store.getters['auth/adminAcl'].is_superuser;
    },
    canViewSegments() {
      const isUser = !this.isSuperUser && this.$store.getters['auth/currentAcl'].crm_segments_view !== 'deny';
      return this.isSuperUser || isUser;
    },
    localSegmentErrors() {
      const hasErrors = this.getErrors('crm_segment').length > 0;
      return hasErrors && !Array.isArray(this.errors.crm_segment)
        ? this.errors.crm_segment
        : {};
    },
    filtersSegmentError() {
      if (!this.errors.filters) return [];

      const filtersError = this.getError('filters', '');

      if (Array.isArray(filtersError)) {
        return filtersError.map(({ message }) => message);
      }

      return [this.getError('filters')];
    },
    errorsDetailed() {
      return [
        this.getError('crm_segmen_id'),
        ...this.getLocalSegmentError('crm_segment'),
        ...this.filtersSegmentError,
      ].filter(item => !!item);
    },
    hasError() {
      return this.errorsDetailed.length > 0;
    },
    globalSegment() {
      return this.clearDublicate('id', [...this.segments, ...this.allSegmentEvnSearch]).find(segment => segment.id === this.globalSegmentId);
    },
    segment: {
      get() {
        const segment = this.segmentType === 'global'
          ? this.globalSegment
          : this.localSegment;

        return segment;
      },
      set(value) {
        if (this.segmentType === 'global') {
          this.globalSegmentId = value
            ? value.id
            : '';
        } else {
          this.localSegment = value;
        }
      },
    },
    activityId() {
      return this.activity && this.activity.crm_segment_is_global;
    },
    hasFilters() {
      return this.activity.filters && this.activity.filters.length;
    },
    approvedSegments() {
      const segments = this.autocompleteQueryResult
        ? this.autocompleteQueryResult
        : this.segments;

      return segments.filter(segment => segment.moderation_status === 'approved');
    },
    // segmentType: {
    //   get() {
    //     return this.activity.crm_segment_is_global
    //       ? 'global'
    //       : 'local';
    //   },
    //   set(value) {
    //     const segmentType = value === 'global';
    //     this.$set(this.activity, 'crm_segment_is_global', segmentType);
    //   },
    // },
    segmentId: {
      get() {
        return this.activity.crm_segment_id;
      },
    },
    isSegmentChanged() {
      return this.activity.crm_segment_id !== this.oldActivity.crm_segment_id
        || this.activity.crm_segment !== this.oldActivity.crm_segment;
    },
    isFilterChanged() {
      const activityFiltersHash = hash(this.activity.filters, { algorithm: 'md5' });
      const oldActivityFiltersHash = hash(this.oldActivity.filters, { algorithm: 'md5' });

      return activityFiltersHash !== oldActivityFiltersHash;
    },
    playersCount() {
      if (!this.isSegmentChanged && !this.isFilterChanged) {
        const players = this.activity.players_count;
        return typeof players === 'number'
          ? numeral(players).format('0,0')
          : players;
      }

      return this.segmentStats.players_count;
    },
  },
  watch: {
    'activity.crm_segment_id': {
      async handler(nv) {
        if (nv) {
          await this.getSegments(this.activity.crm_segment_name, 'ignore');
        }
      },
    },

    localSegmentId(value) {
      if (value && !this.localSegment) {
        this.getLocalSegment(value);
      }
    },
    autocompleteQuery(value) {
      if (!value) {
        this.autocompleteQueryResult = null;
      } else {
        this.getSegments(value);
      }
    },
  },

  created() {
    this.getSegments(this.globalSegmentId);
  },

  methods: {
    clearDublicate(key, arr) {
      const uniqById = uniqByProp(key);
      const getUniq = compose(uniqById);
      return Object.values(getUniq(arr));
    },

    async exportData() {
      const file = await this.$api.getActivityPlayers(this.activity.id);

      const linkBlob = new Blob(
        [file.data],
        { type: file.headers['content-type'] },
      );

      fileSaver(linkBlob, this.segment.name);
    },

    getSegments(search, action) {
      return this.$api
        .getSegments({
          site_id: this.currentBrand,
          schema: 'short_list',
          moderation_status: 'approved',
          search,
        })
        .then((response) => {
          if (this.segment?.id) {
            this.segmentPermission = response.data.misc.permissions[this.segment.id];
          }

          if (action === 'ignore') {
            this.autocompleteQueryResult = this.clearDublicate(
              'id',
              [...this.autocompleteQueryResult, ...this.$_.cloneDeepWith(response.data.payload)],
            );
          } else {
            this.autocompleteQueryResult = this.$_.cloneDeepWith(response.data.payload);
          }

          this.allSegmentEvnSearch = this.clearDublicate(
            'id', this.allSegmentEvnSearch.concat(this.autocompleteQueryResult),
          );
        });
    },

    handlerVisibleChange(isVisible) {
      if (!isVisible) {
        setTimeout(() => {
          this.autocompleteQueryResult = this.allSegmentEvnSearch;
        }, 200);
      }
    },

    setAutocompleteQuery(value) {
      this.autocompleteQuery = value;
    },
    getLocalSegmentError(field) {
      if (!Array.isArray(this.errors[field])) {
        const filtersError = this.getError(field, ['rules_group']);
        if (Array.isArray(filtersError)) {
          return filtersError.map(({ message }) => message);
        }

        return this.getErrors(field, this.$t('crm.activities.segment.local_segment_error'));
      }

      return [this.getError(field)];
    },
    getError(field, path = [0, 'message']) {
      return this.$_.get(this.errors, [field, ...path]);
    },
    getErrors(field, message = '') {
      if (this.errors[field]) {
        if (Array.isArray(this.errors[field])) {
          return this.errors[field].length > 0 ? [message] : [];
        }

        const keys = Object.keys(this.errors[field]);
        return keys.length > 0 ? [message] : [];
      }

      return [];
    },
    getLocalSegment(value = this.localSegmentId) {
      if (value) {
        this.$api
          .getSegment(value)
          .then((response) => {
            this.localSegment = response.data.payload;
          });
      }
    },
    updateSegmentStats() {
      const isEdit = this.state === 'edit' || this.state === 'rework-edit';

      if (this.segmentId && (this.segment.is_global || !isEdit)) {
        let filters = this.activity.crm_segment_is_global
          ? this.activity.filters
          : [];
        filters = this.clearRules(filters);

        return this.$api
          .getSegmentStatsById(this.segmentId, filters)
          .then((response) => {
            this.segmentStats = response.data.payload;
          });
      }

      if (this.segment && !(this.segment.type === 'manual' && isEdit)) {
        if (!this.$_.isEmpty(this.segment)) {
          const checkSegment = this.clearSegment(this.segment);
          if (this.segment.type !== 'manual' && checkSegment.rules_group.rules.length === 0) {
            this.stats = this.getDefaultsegmentStats();
          } else {
            this.statsLoading = true;

            return this.$api
              .getSegmentStats({
                ...checkSegment,
                site_id: this.currentBrand,
              })
              .then((response) => {
                this.segmentStats = response.data.payload;
              }).finally(() => this.statsLoading = false);
          }
        }
      }

      return Promise.resolve(this.getDefaultsegmentStats());
    },
    setSegmentId(value, restore = false, stats = null) {
      this.segmentType = this.activity.crm_segment_is_global
        ? 'global'
        : 'local';

      if (this.activity.crm_segment_is_global) {
        this.globalSegmentId = value;
        this.localSegmentId = '';
      } else {
        const isForceRestoreLocal = restore && this.localSegmentId === value;

        this.localSegmentId = value;
        this.globalSegmentId = '';

        if (isForceRestoreLocal) {
          this.getLocalSegment(this.localSegmentId);
        }
      }

      if (stats) {
        this.segmentStats = this.$_.cloneDeep(stats);
      } else {
        this.updateSegmentStats();
      }
    },
    handleAddLocalSegment() {
      this.openCreateSegmentPopup('new');
    },
    handleEditLocalSegment() {
      this.openCreateSegmentPopup('edit');
    },
    handlePreviewSegment() {
      this.isOpenViewSegmentPopup = true;
      this.showFiltersMode = this.segmentType === 'local'
        ? 'none'
        : 'preview';
    },
    handleAddFilter() {
      this.isOpenViewSegmentPopup = true;
      this.showFiltersMode = 'edit';
    },
    handleDeleteSegment() {
      this.segment = null;
      this.globalSegmentId = '';
      // this.segmentStats = this.getDefaultsegmentStats();
      this.$set(this.activity, 'crm_segment', {});
      this.$set(this.activity, 'crm_segment_id', '');
    },
    openCreateSegmentPopup(type) {
      this.popupType = type;
      this.isOpenCreateSegmentPopup = true;
    },
    handleUpdateLocalSegment(segment) {
      this.localSegment = this.$_.cloneDeep(segment);
      this.segmentStats = this.getDefaultsegmentStats();
      this.updateSegmentStats();
      this.segment = this.localSegment;
      this.$set(this.activity, 'crm_segment', this.segment);
      this.$set(this.activity, 'crm_segment_is_global', false);
      this.globalSegmentId = '';

      this.isOpenCreateSegmentPopup = false;
    },
    handleUpdateFilterStats(segmentStats) {
      this.segmentStats = this.$_.cloneDeep(segmentStats);
    },
    handleUpdateGlobalSegment(item) {
      this.globalSegmentId = item;
      this.segment = this.globalSegment;
      this.$set(this.activity, 'crm_segment_id', item);
      this.$set(this.activity, 'crm_segment_is_global', true);
      this.localSegment = null;
      this.updateSegmentStats();
    },
    closeSegmentPopup() {
      this.isOpenCreateSegmentPopup = false;
      this.isOpenViewSegmentPopup = false;
      // this.segmentId = '';
      // this.getSegments();
    },

    getDefaultSegement() {
      return {};
    },

    getDefaultsegmentStats() {
      return {
        players_count: 0,
        deposits_all_avg: 0,
        deposits_all_median: 0,
        avg_sports_bet_amount: 0,
        median_sports_bet_amount: 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.players-wrapper {
  /deep/.ui-tag__title {
    height: 100%;
  }
}

.players-loader {
  &.loading {
    height: 100%;
    min-width: 16px;
    z-index: 9;
  }

  /deep/ {
    .el-loading-mask {
      background-color: inherit;
    }
    .el-loading-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-top: 0;
      transform: translateY(-50%);

      .circular {
        height: 100%;
        width: 10px;
        .path {
          stroke: white;
        }
      }
    }
  }
}
</style>
<style lang="scss">
  .crm-activity-segment {
    .ui-radio-group .is-disabled .el-radio-button__inner:hover {
      color: #C0C4CC;
    }

     &__add-wrapper {
      display: flex;
      flex-grow: 1;
    }

    &__add-global-select {
      flex-grow: 1;

      &.el-select--medium .el-input__inner {
        height: 34px;
        line-height: 34px;
      }
    }

    &__add-local-segment-button {
      &_disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    &__segment-stats {
      min-width: 0;
    }

    &__segment-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & &__segment-name {
      display: block;
    }
  }
</style>
