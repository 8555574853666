<template>
  <div
    v-loading="isDataLoading"
    class="league-matches"
  >
    <landing-match-popup
      v-show="isPopupOpen"
      :is-open="isPopupOpen"
      :action="action"
      :leagues="leagues"
      :league-id="leagueId"
      :site-id="siteId"
      :match="editingMatch"
      @close="onClosePopup"
    />
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirm.type"
      :action-name="$t('tools.landingLeagues.leagues.delete')"
      action="Delete"
      action-icon="trash-alt"
      @save="deleteMatch"
    >
      <div slot="title">
        {{ confirm.title }}
      </div>
      <div class="body__wrap">
        <i
          :class="confirm.type"
          class="fas fa-trash-alt"
        />
        <div
          class="body__msg"
          v-html="confirm.msg"
        />
      </div>
    </ui-confirm>
    <div class="head">
      <div class="nav">
        <router-link
          :to="`/tools/landing-leagues?site_id=${siteId}`"
          class="back"
        >
          {{ $t('tools.landingLeagues.leagues.land_leagues') }}
        </router-link>
        <span
          v-if="league"
          class="title"
        >> {{ league.name }}</span>
      </div>
      <div class="toolbar">
        <span
          v-if="league"
          class="title"
        >{{ league.name }}</span>
        <ui-button
          v-if="editLeague"
          class="btn"
          color="green"
          lib="fa"
          substyle="fas"
          filled
          icon="plus"
          @click="action = 'Add'; isPopupOpen = true"
        >
          {{ $t('tools.landingLeagues.leagues.add_match') }}
        </ui-button>
      </div>
    </div>
    <ui-table
      :fields="fields"
      :data="matches"
      :rows-count="matchesCount"
      :sort="{
        prop: sortBy,
        order: sortDir,
      }"
      :computed-row-class="getRowClass"
      :empty-text="$t('tools.landingLeagues.leagues.no_match')"
      lazy-loading
      is-static
      i18n-path="tools.landingLeagues.matches"
      element-loading-custom-class="data-loader-spinner"
      @sort="handleSortLeagues"
    >
      <template slot="append">
        <el-table-column
          v-if="editLeague"
          :width="80"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <div
              class="row-value actions"
            >
              <ActionIcon
                :tooltip="$t('tools.landingLeagues.leagues.edit')"
              >
                <span
                  class="action-ico"
                  @click="editMatch(scope.row)"
                >
                  <ui-icon
                    name="pen"
                    :color="$theme.accentColor"
                    lib="fa"
                  />
                </span>
              </ActionIcon>
              <ActionIcon
                :tooltip="$t('tools.landingLeagues.leagues.delete')"
              >
                <span
                  class="action-ico"
                  @click="clickDeleteHandler(scope.row)"
                >
                  <ui-icon
                    name="trash-alt"
                    :color="$theme.dangerColor"
                    lib="fa"
                  />
                </span>
              </ActionIcon>
            </div>
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>
import LandingMatchPopup from '@/components/Popups/LandingMatchPopup.vue';
import detectPermissions from '../../service/detectPermissions';

export default {
  name: 'LeagueMatches',
  components: {
    LandingMatchPopup,
  },
  data() {
    return {
      leagueId: '',
      leagueName: '',
      fields: [
        {
          name: 'starts_at',
          width: 140,
          align: 'center',
          sortable: 'custom',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD MMM YYYY HH:mm',
          },
          class: 'last-match',
        },
        {
          name: 'match',
          sortable: false,
          align: 'left',
          computedValue: this.computeMatch,
        },
      ],
      matches: [],
      leagues: [],
      teams: [],
      matchesCount: 0,
      teamsCount: 0,
      sortBy: 'starts_at',
      sortDir: 'asc',
      isDataLoading: false,
      isPopupOpen: false,
      action: 'Add',
      editingMatch: {},
      virtualData: {},
      confirm: {
        title: '',
        msg: '',
        type: 'error',
      },
    };
  },
  computed: {
    editLeague() {
      const isUser = !this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl'].tools_promo_landings_edit === 'allow';
      const isSuperUser = this.$store.getters['auth/adminAcl'].is_superuser;
      return isSuperUser || isUser;
    },
    league() {
      return this.$_.find(this.leagues, { id: parseInt(this.leagueId, 10) });
    },
    siteId() {
      return this.$route.query.site_id ? this.$route.query.site_id : '';
    },
    query() {
      return {
        site_id: this.siteId,
        league_id: this.leagueId,
        sort_column: this.sortBy,
        sort_dir: this.sortDir,
      };
    },
  },
  created() {
    detectPermissions.checkRoute('tools_promo_landings_view');
  },
  mounted() {
    if (detectPermissions.checkRequest('tools_promo_landings_view')) {
      this.leagueId = this.$route.params.id;
      if (!this.leagueId) {
        this.$router.push('/tools/landing-leagues');
      }
      this.getMatches();
      this.getLeagues();
      this.getTeams();
    }
  },
  methods: {
    clickDeleteHandler(value) {
      this.virtualData = value;
      this.confirm.title = `${this.$t('tools.landingLeagues.leagues.delete')} ${this.computeMatch(value)}`;
      this.confirm.msg = `${this.$t('tools.landingLeagues.leagues.delete')} ${this.computeMatch(value)}`;
      this.confirm.type = 'warning';
      this.$refs.confirm.open();
    },

    onClosePopup() {
      this.isPopupOpen = false;
      this.getMatches();
    },
    addMatch() {
      this.action = 'Add';
      this.editingMatch = {};
      this.isPopupOpen = true;
    },
    editMatch(row) {
      this.action = 'Edit';
      this.editingMatch = this.$_.clone(row);
      this.isPopupOpen = true;
    },
    computeMatch(row) {
      const t1 = this.$_.find(this.teams, ['id', row.team_home_id]);
      const t2 = this.$_.find(this.teams, ['id', row.team_away_id]);
      return t1 && t2 ? `${t1.name} - ${t2.name}` : '-';
    },
    getMatches() {
      this.isDataLoading = true;
      this.$api
        .getMatchesCount(this.query)
        .then((resp) => {
          this.matchesCount = resp.data.payload.count;
          this.$api
            .getMatches(this.query)
            .then((response) => {
              this.matches = this.$_.cloneDeep(response.data.payload);
            })
            .finally(() => {
              this.isDataLoading = false;
            });
        })
        .catch(() => {
          this.isDataLoading = false;
        });
    },
    getLeagues() {
      this.isDataLoading = true;
      this.$api
        .getLeagues({ site_id: this.siteId })
        .then((response) => {
          this.leagues = this.$_.cloneDeep(response.data.payload);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    getTeams() {
      this.isDataLoading = true;
      this.$api
        .getTeamsCount({
          league_id: this.leagueId,
        })
        .then((resp) => {
          this.teamsCount = resp.data.payload.count;
          this.$api
            .getTeams({
              league_id: this.leagueId,
            })
            .then((response) => {
              this.teams = this.$_.cloneDeep(response.data.payload);
            })
            .finally(() => {
              this.isDataLoading = false;
            });
        })
        .catch(() => {
          this.isDataLoading = false;
        });
    },
    getRowClass({ row }) {
      return this.$moment(row.last_starts_at).diff(this.$moment(), 'hours', true) < 72 ? 'late' : '';
    },
    handleSortLeagues({ prop, order }) {
      if (this.sortBy !== prop) {
        this.sortBy = prop;
      } else {
        this.sortDir = order;
      }
      this.getMatches();
    },
    deleteMatch() {
      this.isDataLoading = true;
      this.$api
        .deleteMatch(this.virtualData.id)
        .then(() => {
          this.getMatches();
          this.$refs.confirm.close();
        })
        .catch(() => {
          this.isDataLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.league-matches {
  width: 90%;
  min-width: 1140px;
  max-width: 1700px;
  margin: 0 auto;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
}
</style>
