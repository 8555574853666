import _ from 'lodash';
import app from '../main';
import {
  NAV_SET_MAIN_VIEW,
  NAV_SET_SUB_VIEW,
  NAV_SET_IS_AUTH,
} from '@/store/action_types/nav';
import router from '@/router';
import store from '@/store';

const DEFAULT_ROUTE = '/affiliates';

const hasPermissions = (permissions, mode) => {
  // Allow all for super user
  if (store.getters['auth/adminAcl'].is_superuser) return true;

  const callback = permission => store.getters['auth/currentAcl'][permission] !== 'deny';

  return (mode === 'and') ? permissions.every(callback) : permissions.some(callback);
};

export default () => {
  // eslint-disable-next-line consistent-return
  router.beforeEach(async (to, from, next) => {
    let hasRedirect = false;
    /**
     * Запрет перехода в Teams если статус lock
     */
    if (from.matched[0] !== undefined && to.matched[0].path === '/teams' && store.getters['session/significantActionsSeconds'] === 0) {
      app.$eventBus.$emit('checkSensitiveAction', 'teamsRoute');
      return;
    }

    if (from.matched[0] !== undefined && to.path === '/settings/token-management' && store.getters['session/significantActionsSeconds'] === 0) {
      app.$eventBus.$emit('checkSensitiveAction', 'tokenManagementRoute');
      return;
    }

    if (from.matched[0] !== undefined && to.path === '/settings/fees' && store.getters['session/significantActionsSeconds'] === 0) {
      app.$eventBus.$emit('checkSensitiveAction', 'feesRoute');
      return;
    }

    if (from.matched[0] !== undefined && to.path === '/settings/payment-methods' && store.getters['session/significantActionsSeconds'] === 0) {
      app.$eventBus.$emit('checkSensitiveAction', 'paymentMethodsRoute');
      return;
    }

    if (from.matched[0] !== undefined && to.path === '/settings/system-settings' && store.getters['session/significantActionsSeconds'] === 0) {
      app.$eventBus.$emit('checkSensitiveAction', 'systemSettings');
      return;
    }

    if (from.matched[0] !== undefined && to.path === '/settings/system-domains' && store.getters['session/significantActionsSeconds'] === 0) {
      app.$eventBus.$emit('checkSensitiveAction', 'systemDomains');
      return;
    }

    if (from.matched[0] !== undefined && to.path === '/settings/predefined-page' && store.getters['session/significantActionsSeconds'] === 0) {
      app.$eventBus.$emit('checkSensitiveAction', 'predefinedPage');
      return;
    }

    if (from.matched[0] !== undefined && to.path === '/settings/offers-country-groups' && store.getters['session/significantActionsSeconds'] === 0) {
      app.$eventBus.$emit('checkSensitiveAction', 'offersCountryGroups');
      return;
    }

    if (from.matched[0] !== undefined && to.path === '/settings/currencies' && store.getters['session/significantActionsSeconds'] === 0) {
      app.$eventBus.$emit('checkSensitiveAction', 'currenciesRoute');
      return;
    }

    if (from.matched[0] !== undefined && to.path === '/settings/media-buying' && store.getters['session/significantActionsSeconds'] === 0) {
      app.$eventBus.$emit('checkSensitiveAction', 'mediaBuyingRoute');
      return;
    }


    /**
     * Запрет перехода со страницы редактирования дашборда
     */
    if (from.matched[0]?.path === '/dashboards' && store.state.dashboards.editMode) {
      app.$eventBus.$emit('confirmEditDashboards', to);
      return;
    }

    /**
     * Проверяем есть ли setting report, и не пустой ли он
     */
    if (store.getters['auth/isAuthenticated']) {
      const match = ['/reports', '/dashboards'].includes(to.matched[0].path);
      const reportSettings = store.getters['reports/reportsSettings'];
      if (match && _.isEmpty(reportSettings)) await store.dispatch('reports/getSettings');
    }


    store.commit(`nav/${NAV_SET_MAIN_VIEW}`, to.matched[0].meta.view);
    if (to.matched[1] && to.matched[1].meta.view) {
      store.commit(`nav/${NAV_SET_SUB_VIEW}`, to.matched[1].meta.view);
    }
    store.commit(`nav/${NAV_SET_IS_AUTH}`, to.matched.some(record => record.meta.type === 'auth'));
    if (to.matched.some(record => record.meta.type === 'token-auth')) {
      store.commit('auth/AUTH_BY_TOKEN', to.params.token);
      store.$api.affiliatesApi.defaults.headers.common.Authorization = to.params.token;

      next('/affiliates/list');
    }
    if (to.matched.some(record => record.meta.type === 'auth')) {
      if (store.getters['auth/isAuthenticated']) {
        next(DEFAULT_ROUTE);
      } else {
        switch (to.params.action) {
        case 'auth':
          if (store.getters['auth/authStatus'] === 'success') {
            next('/login/2fa');
          }
          next();
          break;
        case '2fa':
          if (store.getters['auth/authStatus'] !== 'success') {
            next('/login/auth');
          } else if (store.getters['auth/tfaStatus'] !== 'success') {
            next();
          } else {
            next(DEFAULT_ROUTE);
          }
          break;
        default:
          break;
        }
        next();
      }
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (_.isEmpty(store.getters['auth/currentAcl']) && localStorage.getItem('active-team') !== '') {
        await store.dispatch('auth/AUTH_ADMIN_ACL');
      }

      if (!store.getters['auth/adminAcl'].is_superuser) {
        if (to.path === '/crm/activities/list' && store.getters['auth/currentAcl'].crm_activities_view === 'deny') {
          hasRedirect = true;
          next('/crm/segments');
        }
        if (to.path === '/crm/segments' && store.getters['auth/currentAcl'].crm_segments_view === 'deny') {
          next(DEFAULT_ROUTE);
        }
      }
      if (to.meta.permission) {
        const { mode = 'and', list = [] } = to.meta.permission;

        if (!hasPermissions(list, mode)) {
          next(DEFAULT_ROUTE);
        }
      }

      if (!store.getters['auth/isAuthenticated']) {
        next('/login');
      } else if (!hasRedirect) {
        next();
      }
    } else if (!hasRedirect) {
      next();
    }
  });
};
