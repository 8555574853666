<template>
  <div class="payments-sub-report">
    <div class="toolbar toolbar-chain">
      <div class="wrapper">
        <div class="section">
          <span class="link_redirect_back">
            <span>
              <span>
                <span
                  class="title_name link"
                  @click="$router.push(backLink)"
                >{{ $t(`reports.payments.payment_method_report`) }}</span>
                <span>&nbsp;&gt;&nbsp;</span>
              </span>
            </span>
            <span>
              <span>
                <span class="title_name">{{ $t(`reports.fields.payment_system_id.title`) }}:</span>
                <span class="title_value">&nbsp;{{ $route.query.payment_system_name }}</span>
                <span v-if="country_code">&nbsp;&gt;&nbsp;</span>
              </span>
            </span>
            <span v-if="country_code">
              <span>
                <span class="title_name">{{ $t(`reports.fields.country_code.title`) }}:</span>
                <span class="title_value">
                  &nbsp;
                  {{ country_code !== 'null' ? $_.find(countries, (el) => el.code === country_code) ?
                    $_.find(countries, (el) => el.code === country_code).name : '' : 'Unknown' }}
                </span>
              </span>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="toolbar">
      <div class="wrapper">
        <div class="section">
          <div class="select-label">
            <el-date-picker
              v-model="dateRange"
              :picker-options="{
                firstDayOfWeek: 1,
                shortcuts: shortcutsDates,
              }"
              :start-placeholder="$t('reports.other.start_date')"
              :end-placeholder="$t('reports.other.end_date')"
              type="daterange"
              range-separator="-"
              format="dd MMM yyyy"
              class="date-picker"
              @change="handlerDatePicker"
            />
          </div>
        </div>
        <div class="section">
          <ui-currency-picker
            class="ui-m-md-r"
            :value="currencyCode"
            :currencies="customerCurrencies"
            @input="changeCurrency"
          />
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="pageChange"
            />
          </div>
          <ui-button
            lib="fa"
            substyle="fas"
            icon="sync-alt"
            class="btn"
            @click="getReport"
          >
            {{ $t('reports.other.refresh') }}
          </ui-button>
        </div>
      </div>
    </div>
    <ui-table
      v-loading="isDataLoading"
      :fields="filteredFields"
      :data="data"
      :rows-count="limit"
      :custom-sorting="true"
      :currency="currencyCode"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      :total="totals"
      lazy-loading
      show-total
      i18n-path="reports"
      element-loading-custom-class="data-loader-spinner"
      @sort="handleSort"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import updateUrl from '../../service/updateUrl';
import formatCurrency from '@/views/mixins/format-currency';


const compareFilterKey = {
  country_code_payments_report: 'country_code',
  player_country_code_payments_report: 'player_country_code',
  player_id: 'player_id',
  site_id: 'site_id',
  affiliate_payments_report_id: 'affiliate_id',
  // traffic_type: 'traffic_source_id',
};

const sizes = {
  1080: 20,
  1440: 30,
};

const LS_KEY = 'reports/payments_sub';
const viewName = `${LS_KEY}/limit`; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});
export default {
  name: 'PaymentsSubReport',
  mixins: [pageSizeMixin, formatCurrency],
  data() {
    return {
      viewName,
      data: [],
      totals: {},
      fields: [
        {
          name: 'date_formatted',
          minWidth: 100,
          sortable: 'custom',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD MMM YYYY',
          },
        },
        {
          name: 'deposits_first_sum',
          minWidth: 120,
          sortable: 'custom',
          format: 'formatMoney',
          tableType: 'report_table',
          align: 'right',
          computedClass(value) {
            return value < 0 ? 'negative' : '';
          },
        },
        {
          name: 'deposits_first_count',
          minWidth: 120,
          sortable: 'custom',
          format: 'number',
          tableType: 'report_table',
          align: 'right',
        },
        {
          name: 'deposits_all_sum',
          minWidth: 120,
          sortable: 'custom',
          format: 'formatMoney',
          tableType: 'report_table',
          align: 'right',
          computedClass(value) {
            return value < 0 ? 'negative' : '';
          },
        },
        {
          name: 'deposits_all_count',
          minWidth: 120,
          sortable: 'custom',
          format: 'number',
          tableType: 'report_table',
          align: 'right',
        },
        {
          name: 'deposits_withdrawals_sum',
          minWidth: 160,
          sortable: 'custom',
          format: 'formatMoney',
          tableType: 'report_table',
          align: 'right',
          computedClass(value) {
            return value < 0 ? 'negative' : '';
          },
        },
        {
          name: 'deposits_withdrawals_count',
          minWidth: 160,
          sortable: 'custom',
          format: 'number',
          tableType: 'report_table',
          align: 'right',
        },
        {
          name: 'players_uniq_count',
          minWidth: 120,
          sortable: 'custom',
          format: 'number',
          align: 'right',
        },
        {
          name: 'affiliates_uniq_count',
          minWidth: 120,
          sortable: 'custom',
          format: 'number',
          align: 'right',
        },
      ],
      dateRange: [],
      shortcutsDates: [
        {
          text: this.$t('reports.date_picker.last_7_day'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(6, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.last_30_day'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(29, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.this_month'),
          range: [this.$moment().startOf('month'), this.$moment().endOf('day')],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.prev_month'),
          range: [
            this.$moment()
              .subtract(1, 'months')
              .startOf('month'),
            this.$moment()
              .subtract(1, 'months')
              .endOf('month'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.last_90_day'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(89, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
      ],
      count: 0,
      page: 1,
      limit: pageSize,
      isDataLoading: false,
      sortProp: 'date_formatted',
      sortOrder: 'desc',
      group_by: 'date_formatted',
      payment_system_id: '',
      country_code: '',
      currencyCode: '',
      param: '',
      backLink: '',
    };
  },
  computed: {
    ...mapGetters({
      countries: 'misc/countries',
      panelSettings: 'reports/panelSettings',
      customerCurrencies: 'misc/customerCurrencies',
    }),
    allowedMetrics() {
      return ['date_formatted', ...this.panelSettings.reports.metrics.map(({ column_name }) => column_name)];
    },
    filteredFields() {
      return this.fields.filter(field => this.allowedMetrics.includes(field.name));
    },
  },
  watch: {
    currencyCode(newVal) {
      this.$ls.set(`${LS_KEY}/currency_code`, newVal);
    },
    dateRange: {
      deep: true,
      handler(nv, ov) {
        if (nv) {
          if (nv !== ov) {
            this.dateRange[0] = this.$moment(nv[0]).startOf('day');
            this.dateRange[1] = this.$moment(nv[1]).endOf('day');
            this.getReport();
          }
        } else {
          this.dateRange = this.shortcutsDates[0].range;
          this.getReport();
        }
      },
    },
  },
  created() {
    const q = this.$qs.parse(this.$route.query);

    if (q && q.payment_system_id) {
      if (q.from && q.to) {
        this.dateRange.push(q.from);
        this.dateRange.push(q.to);
      } else {
        this.dateRange = this.shortcutsDates[0].range;
      }

      this.payment_system_id = q.payment_system_id;
      this.country_code = q.country_code || '';

      this.backLink = /* this.$_.get(q, ['back_link']) ||  */'/reports/payments';
      this.group_by = this.$_.get(q, ['group_by']) || 'date_formatted';
      this.sortProp = this.$_.get(q, ['sort_by']) || 'date_formatted';
      this.sortOrder = this.$_.get(q, ['sort_dir']) || 'desc';
      this.currencyCode = this.checkCurrencyCode(this.$_.get(q, 'currency_code', this.$ls.get(`${LS_KEY}/currency_code`)));

      this.limit = Number(this.$_.get(q, ['limit']))
        || +this.$ls.get('reports/payments_sub/limit')
        || pageSize;
      this.page = Number(this.$_.get(q, ['page'])) || 1;

      delete q.group_by;
      delete q.sort_by;
      delete q.sort_dir;
      delete q.limit;
      delete q.page;
      delete q.back_link;

      this.param = q;
    } else {
      this.$router.push({ path: '/reports/payments/' });
    }

    this.getReport();
  },
  methods: {
    changeCurrency(currencyCode) {
      this.getReport({ currencyCode });
    },
    queryFilterParse(filters) {
      const parseFilters = {};
      this.$_.forIn(filters, (v, key) => {
        const getkey = compareFilterKey[key] ? compareFilterKey[key] : undefined;
        if (getkey) parseFilters[getkey] = { op: '=', value: v };
      });

      return parseFilters;
    },

    handlerDatePicker(v) {
      if (v === null) {
        this.param.from = this.$moment()
          .startOf('day')
          .subtract(6, 'd')
          .format('YYYY-MM-DD HH:mm:ss');
        this.param.to = this.$moment()
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.param.from = this.$moment(v[0])
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss');
        this.param.to = this.$moment(v[1])
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss');
      }
      setTimeout(() => {
        this.getReport();
      });
    },
    dataQuery() {
      const filters = {
        payment_system_id: this.payment_system_id ? {
          op: '=',
          value: [this.payment_system_id],
        } : undefined,
        country_code: this.country_code ? {
          op: '=',
          value: [this.country_code],
        } : undefined,
      };
      const q = {
        dimensions: [this.group_by],
        limit: this.limit,
        currency_code: this.currencyCode,
        offset: (this.limit * this.page) - this.limit,
        sorting: [{
          sort_by: this.sortProp,
          sort_dir: this.sortOrder,
        }],
        metrics: this.filteredFields.filter(field => field.name !== this.group_by).map(field => field.name),
        filters: { ...filters, ...this.queryFilterParse(this.param.filters) },
        ...this.$_.pick(this.param.filters, ['traffic_type']),
        from: this.dateRange[0]
          ? this.$moment(this.dateRange[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          : '',
        to: this.dateRange[1]
          ? this.$moment(this.dateRange[1])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          : '',
      };
      delete q.fullname;
      delete q.payment_system_name;

      return q;
    },
    getReport({ currencyCode } = {}) {
      this.isDataLoading = true;

      /*
       * Добавляем фильтры в url
       * */

      if (currencyCode) {
        this.currencyCode = currencyCode;
      }

      this._updateCompletedUrl();
      this.$api
        .getMainReport(this.dataQuery())
        .then((response) => {
          this.data = this.$_.cloneDeep(response.data.payload.data);
          this.totals = this.$_.clone(response.data.payload.totals);
          this.count = response.data.misc.count;
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.getReport();
    },
    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.getReport();
      }
    },
    _updateCompletedUrl() {
      const _data = {
        group_by: this.group_by,
        sort_by: this.sortProp,
        sort_dir: this.sortOrder,
        country_code: this.country_code,
        from: this.$moment(this.dateRange[0]).format('YYYY-MM-DD'),
        to: this.$moment(this.dateRange[1]).format('YYYY-MM-DD'),
        payment_system_id: this.payment_system_id,
        payment_system_name: this.$route.query.payment_system_name,
        limit: this.limit,
        page: this.page,
        currency_code: this.currencyCode,
      };
      updateUrl.updateGetParams(_data);
    },
  },
};
</script>
