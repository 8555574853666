// eslint-disable-next-line no-useless-concat, no-unused-vars
const disableLinksScript = '<scr' + `ipt>document.addEventListener("DOMContentLoaded", function() {
  var a = document.querySelectorAll('a'), i;

  for (i = 0; i < a.length; ++i) {
    a[i].setAttribute("target", "_blank");
  }
})</scr`
// eslint-disable-next-line no-useless-concat
+ 'ipt>';

export default {
  props: {
    activity: {
      type: Object,
      default: () => ({}),
    },
    currentBrand: {
      type: String,
      default: '',
    },
    template: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      actions: [],
      previewList: null,
      email_template_id: '',
      index: 0,
    };
  },
  computed: {
    languages() {
      const languages = this.$store.state.misc.languages || [];

      return this.actions.map(action => ({
        ...action,
        ...(languages.find(language => language.code === action.language_code) || {}),
      }));
    },
    selectedLanguages() {
      return this.languages.map(language => language.language_code);
    },
    currentPreviewState() {
      return this.getPreviewState(this.index);
    },
    currentPreviewStateWatcherParams() {
      return {
        currentPreviewState: this.currentPreviewState,
        index: this.index,
      };
    },
    stateEnum() {
      // local -- состояние для отдельного элемента Preview
      // explicit -- состояние указывается явно в previewList
      // virtual -- состояние вычисляется динамически по принакам
      // global -- состояние для всех элементов previewList

      return {
        loaded: 0, // local, virtual
        loading: 1, // local, explicit
        error: 2, // local, explicit
        not_loaded: 3, // local, virtual
        not_avaliable: 4, // global
      };
    },
    isActionsSendDisabled() {
      return this.actions.every(action => !action.message.trim()) || !this.actions[this.index].message;
    },
  },
  methods: {
    handleSelectTab(index) {
      this.index = index;
    },
    loadCurrentPreview() {
      this.loadPreview(this.index);
    },
    getPreviewState(index) {
      if (!this.previewList) {
        return this.stateEnum.not_avaliable;
      }

      if (!this.previewList[index]) {
        return this.stateEnum.not_loaded;
      }

      if (this.previewList[index] === this.stateEnum.loading) {
        return this.stateEnum.loading;
      }

      if (this.previewList[index] === this.stateEnum.error || typeof this.previewList[index] === 'object') {
        return this.stateEnum.error;
      }

      return this.stateEnum.loaded;
    },
    loadPreview(index) {
      if (this.email_template_id !== '' && this.previewList && !this.previewList[index]) {
        this.$set(this.previewList, index, this.stateEnum.loading);

        const payload = {
          site_id: this.currentBrand,
          markdown: this.actions[index].message,
          crm_mail_template_id: this.email_template_id,
        };

        this.$api
          .postEmailTemplatePreview(payload)
          .then((response) => {
            if (this.previewList) {
              let { html } = response.data.payload;
              const bodyIndex = html.indexOf('</body>');
              html = `${html.slice(0, bodyIndex)}${disableLinksScript}${html.slice(bodyIndex)}`;

              this.$set(this.previewList, index, html);
              this.previewList[index] = html;
            }
          })
          .catch((response) => {
            if (this.previewList && response.data) {
              this.$set(this.previewList, index, response.data);
            }
          });
      }
    },
    handleSendTestEmail() {
      const actionGroup = {
        actions: this.actions,
      };

      this.$emit('send-test-email', {
        actionGroup,
        index: this.index,
      });
    },
    getDefaultForm() {
      return {
        name: '',
        type: 'email',
        message: '',
        email_subject: '',
        email_header: '',
        language_code: 'en',
        is_default: true,
      };
    },
  },
};
