<template>
  <div
    v-loading="commentsLoading"
    class="body-comments"
  >
    <div class="card">
      <div class="comments-wrapper">
        <div class="title">
          <span>{{ $t(`mediaBuying.comments.title`) }}</span>
        </div>
        <div class="comments-type">
          <el-select
            v-model="currentCommentsType"
            placeholder="Select"
            @change="clearContent()"
          >
            <el-option
              v-for="item in commentsTypes"
              :key="item"
              :label="$t(`mediaBuying.comments.types.${item}`)"
              :value="item"
            />
          </el-select>
        </div>
      </div>
      <div class="card-content without-padding">
        <div class="field field-comments">
          <div
            ref="posts"
            class="posts"
          >
            <div
              v-for="commentItem in filteredComments"
              :key="commentItem.id"
              :class="{'my-post': commentItem.author_type === 'admin' && commentItem.type !== 'system'}"
              class="posts-item"
            >
              <div
                class="post"
                :class="{'post-edit': commentItem.id === commentId, 'post__system': commentItem.type === 'system' }"
              >
                <ui-icon
                  v-if="commentItem.type === 'system'"
                  name="history"
                  :color="$theme.accentColor"
                  lib="fa"
                />
                <div class="post-wrapper">
                  <div
                    v-if="commentItem.author_type === 'admin'"
                    class="comment-info"
                  >
                    <div class="name">
                      <ui-icon
                        name="user"
                        color="#7d7d7d"
                        substyle="far"
                        lib="fa"
                      />
                      {{ commentItem.admin_name }}
                    </div>
                    <div
                      v-if="checkDate(commentItem.created_at) && isCommentOwner(commentItem) && commentItem.id !== commentId && isEditable"
                      class="comment-controls"
                    >
                      <div
                        @click="updateCommentHandler(commentItem.id, commentItem.content, commentItem.files)"
                      >
                        <ui-icon
                          name="pen"
                          :color="$theme.accentColor"
                          lib="fa"
                          :size="10"
                        />
                      </div>
                      <div
                        @click="deleteComment(commentItem.id)"
                      >
                        <ui-icon
                          name="times"
                          :color="$theme.dangerColor"
                          lib="fa"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="comment-info">
                    <div class="date">
                      <ui-icon
                        name="calendar-alt"
                        :color="$theme.accentColor"
                        substyle="far"
                        lib="fa"
                      />
                      {{ commentItem.created_at | formatDate3 }}
                    </div>
                  </div>
                  {{ commentItem.content }}
                  <div
                    v-if="commentItem.files.length !== 0"
                    class="files"
                  >
                    <a
                      v-for="file in commentItem.files"
                      :key="file.id"
                      :href="file.download_url"
                      :download="file.filename"
                      target="_blank"
                      class="item"
                    >
                      {{ file.filename }}
                      <ui-icon
                        name="download"
                        :color="$theme.accentColor"
                        lib="fa"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-upload
            ref="upload"
            class="upload"
            action=""
            multiple
            :file-list="fileList"
            :http-request="uploadFilesAction"
            :on-remove="removeFile"

            :auto-upload="false"
            :disabled="!isEditable || isSystem"
          >
            <ui-button
              slot="trigger"
              type="primary"
              class="btn"
              lib="fa"
              substyle="fas"
              color="green"
              icon="paperclip"
              :disabled="!isEditable || isSystem"
            />
            <el-input
              ref="commentArea"
              v-model="comment.content"
              :placeholder="$t(`mediaBuying.comments.placeholder`)"
              :rows="3"
              type="textarea"
              maxlength="1023"
              :disabled="!isEditable || isSystem"
            />
            <div>
              <ui-button
                v-if="buttonType !== 'create'"
                class="btn cancel-edit"
                lib="fa"
                substyle="fas"
                color="red"
                icon="times"
                :disabled="!isEditable"
                @click="toDefault()"
              />
              <ui-button
                :disabled="comment.content === '' || isSystem"
                filled
                icon="check"
                lib="fa"
                substyle="fas"
                color="green"
                class="btn"
                type="success"
                @click="submitUpload()"
              >
                {{ $t(`mediaBuying.comments.button_${buttonType}`) }}
              </ui-button>
            </div>
          </el-upload>
          <div
            v-if="error.length > 0"
            class="upload-error"
          >
            <div
              v-for="(item, id) in error"
              :key="id"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import app from '@/main';

export default {
  name: 'Comments',

  props: {
    comments: {
      type: Array,
      default() {
        return [];
      },
    },
    postId: {
      type: String,
      default() {
        return '';
      },
    },
    commentsLoading: {
      type: Boolean,
      default() {
        return true;
      },
    },
    isEditable: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data() {
    return {
      comment: {
        content: '',
        file_ids: [],
      },
      tmpComment: '',
      tmpFileList: [],
      commentIsCopied: false,
      commentsTypes: ['user', 'system', 'all'],
      currentCommentsType: 'user',
      error: [],
      fileList: [],
      filesCount: 0,
      isDataLoading: false,
      buttonType: 'create',
      commentId: '',
    };
  },

  computed: {
    isSystem() {
      return this.currentCommentsType === 'system';
    },

    filteredComments() {
      if (!this.$_.isEmpty(this.comments)) {
        if (this.currentCommentsType === 'all') {
          return this.comments;
        }
        return this.$_.filter(this.comments, ['type', this.currentCommentsType]);
      }
      return [];
    },
  },

  mounted() {
    app.$eventBus.$on('defaultCommentsState', this.toDefault);
  },

  beforeDestroy() {
    app.$eventBus.$off('defaultCommentsState', this.toDefault);
  },

  methods: {
    clearContent() {
      if (this.currentCommentsType === 'system') {
        this.tmpComment = this.comment.content;
        this.tmpFileList = this.$refs.upload.uploadFiles.slice(0);
        this.$refs.upload.uploadFiles = [];
        this.comment.content = '';
        this.commentIsCopied = true;
      } else if (this.commentIsCopied) {
        this.comment.content = this.tmpComment;
        this.$refs.upload.uploadFiles = this.tmpFileList.slice(0);
        this.tmpFileList = [];
        this.tmpComment = '';
        this.commentIsCopied = false;
      }
    },
    deleteComment(id) {
      this.$emit('update:commentsLoading', true);
      this.$api
        .deleteComment(id)
        .then(() => {
          this.$emit('get-comments');
        });
    },
    postFile() {
      this.comment.file_ids = [];
      this.error = [];
      this.$refs.upload.submit();
    },
    async uploadFilesAction(file) {
      const formData = new FormData();
      formData.append('files', file.file);

      try {
        const response = await this.$api.postFile(formData);
        this.comment.file_ids.push(response.data.payload.files.id);

        this.filesCount -= 1;

        if (this.filesCount === 0) {
          if (this.buttonType === 'update') {
            this.comment.file_ids = this.$_.uniq([...this.comment.file_ids, ...this.fileList.map(el => el.id)]);
            this.updateComment();
          } else {
            this.postComment();
          }
        }
      } catch {
        this.error.push(`File "${file.file.name}" is not valid`);
        this.$emit('update:commentsLoading', false);
      }
    },
    removeFile(file) {
      [this.comment.file_ids, this.fileList].forEach((el) => {
        const fileId = el.indexOf(file.id);
        if (fileId !== -1) {
          el.splice(fileId, 1);
        }
      });
    },
    submitUpload() {
      this.$emit('update:commentsLoading', true);
      this.filesCount = this.$refs.upload.uploadFiles.filter(e => e.status === 'ready').length;
      if (this.filesCount > 0) {
        this.postFile();
      } else if (this.buttonType === 'update') {
        this.updateComment();
      } else {
        this.postComment();
      }
    },
    postComment() {
      this.$emit('post-comments', this.comment);
    },
    updateCommentHandler(id, content, files) {
      this.buttonType = 'update';
      this.commentId = id;
      this.comment.content = content;
      if (files.length > 0) {
        files.forEach((e) => {
          this.fileList.push({ name: e.filename, id: e.id });
          this.comment.file_ids.push(e.id);
        });
      }
    },
    updateComment() {
      this.$api
        .updateComment(this.commentId, this.comment)
        .then(() => {
          this.$emit('get-comments');
        })
        .finally(() => {
          this.$emit('update:commentsLoading', false);
        });
    },
    checkDate(date) {
      return this.$moment(new Date()).diff(this.$moment(date), 'seconds') < 86400;
    },

    scrollToEnd() {
      const postsBlock = this.$refs.posts;
      postsBlock.scrollTop = postsBlock.scrollHeight;
    },

    isCommentOwner(comment) {
      return !!comment.is_owner && comment.type !== 'system';
    },

    toDefault() {
      this.comment.content = '';
      this.comment.file_ids = [];
      this.fileList = [];
      this.commentId = '';
      this.buttonType = 'create';
      this.$refs.upload.uploadFiles = [];
      this.$nextTick(() => {
        this.scrollToEnd();
      });
    },
  },
};
</script>
