import Axios from 'axios';
import QS from 'qs';
import { config } from '@/config';

export default {
  affiliatesApi(baseUrl) {
    return Axios.create({
      baseURL: baseUrl,
      withCredentials: true,
      headers: {
        common: {
          Authorization: localStorage.getItem('auth-token') || '',
          'X-Team-Id': localStorage.getItem('active-team') || '',
          'X-Customer-Id': config.customerId || '',
        },
      },
      paramsSerializer(params) {
        return QS.stringify(params, { arrayFormat: 'repeat' });
      },
      timeout: 120000,
    });
  },
  miscApi(baseUrl) {
    return Axios.create({
      baseURL: baseUrl,
      withCredentials: true,
      headers: {
        common: {
          Authorization: localStorage.getItem('auth-token') || '',
          'X-Customer-Id': config.customerId || '',
        },
      },
      timeout: 60000,
    });
  },
};
