<template>
  <div class="container">
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirm.type"
      :action-name="confirm.actionName"
      :close-action-name="confirm.closeActionName"
      :action="confirm.action"
      :data="confirm.data"
      :action-icon="confirm.actionIcon"
      @save="actionConfirm"
    >
      <div slot="title">
        {{ confirm.title }}
      </div>
      <div class="body__wrap">
        <i
          :class="confirm.type"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirm.msg"
        />
      </div>
    </ui-confirm>
    <div class="ui-d-flex ui-jc-space-between ui-ai-center">
      <div class="title-wrap">
        <h2 class="title">
          {{ $t(`affiliates.offer_popup.cpa_payout`) }}
        </h2>
      </div>
      <div class="card-filter__del" @click="confirmPayout">
        <ui-icon
          size="20px"
          lib="fa"
          substyle="fas"
          name="times"
          class="remove-payout"
          :class="{ disabled: isDisableDeletePayout }"
          :color="$theme.dangerColor"
        />
      </div>
    </div>
    <div class="rule-block__flex-line ui-m-lg-t">
      <div class="rule-block__cpa-wrap">
        <div class="rule-block__cpa">
          <p class="label-text">
            {{ $t(`affiliates.offer_popup.payout`) }}
          </p>
          <div class="rule-block__wr">
            <el-select
              v-model="cpa.currency_code"
              :disabled="!ableToSetCpa"
              size="big"
              placeholder=""
              class="select"
            >
              <el-option
                v-for="item in customerCurrencies"
                :key="item.code"
                :label="item.code"
                :value="item.code"
              />
            </el-select>
            <ui-input
              v-model="cpa.amount"
              :disabled="!ableToSetCpa"
              error-tooltip
              size="big"
              class="offer-input offer-input-cpa"
              :error="errorRule.amount"
            />
          </div>
        </div>
      </div>
      <div class="rule-block__daily-wrap">
        <ui-input
          v-model="cpa.cap_players_count"
          :disabled="!ableToSetCpa"
          :label="$t('affiliates.offer_popup.cap_players')"
          placeholder="100"
          size="big"
          class="offer-input offer-input-daily"
          error-tooltip
          :error="errorRule.cap_players_count"
        />
        <div class="per">
          {{ $t('affiliates.offer_popup.per') }}
        </div>
        <ui-input
          v-model="dailyCap"
          size="big"
          placeholder="0"
          :disabled="!ableToSetCpa"
          type="number"
          error-tooltip
          autosize
          :error="errorRule.cap_period_seconds"
          class="days"
          :post-text="$t('affiliates.offersView.holdRanges.days')"
        />
      </div>
      <div class="rule-block__wrapper">
        <div class="rule-block__hold-wrap">
          <ui-input
            v-model="holdRevShare"
            :disabled="!ableToSetCpa"
            :label="$t(`affiliates.offer_popup.hold`)"
            size="big"
            class="offer-input offer-input-hold"
            error-tooltip
            :error="errorRule.hold_seconds"
          />
        </div>
        <div class="rule-block__hold-time-wrap">
          <el-select
            v-model="holds.dep"
            :disabled="!ableToSetCpa"
            :placeholder="$t(`affiliates.offer_popup.day`)"
            class="select"
          >
            <el-option
              v-for="item in timeRanges"
              :key="item"
              :label="$t(`affiliates.offersView.holdRanges.${item}`)"
              :value="item"
            />
          </el-select>
        </div>
      </div>
    </div>
    <div class="payment-block ui-m-md-t">
      <div class="title-wrap">
        <h2 class="title">
          {{ $t(`affiliates.offer_popup.cpa_conditions`) }}
        </h2>
        <el-popover
          v-model="visible3"
          :disabled="listPayment.length === 0 || !ableToSetCpa"
          :transition="null"
          popper-class="payment-block__rules-list"
          placement="bottom"
          width="200"
          trigger="click"
        >
          <ui-button
            slot="reference"
            :color="$theme.mainColor"
            :disabled="listPayment.length === 0 || !ableToSetCpa"
            class="payment-block__btn"
            :class="{ 'payment-block__btn-error': errorRule.message }"
          >
            <i class="fas fa-plus plus-icon-custom" />
            {{ $t(`affiliates.offer_popup.add_condition`) }}
            <ui-icon
              :class="{'open': visible3}"
              class="chevron-btn"
              size="9px"
              lib="fa"
              substyle="fas"
              name="chevron-left"
              color="#000000"
            />
          </ui-button>
          <div class="filters-block__rules-list-wr">
            <p
              v-for="(item, index) in listPayment"
              :key="index"
              @click="visible3 = false; addPayment(item)"
            >
              {{ $t(`affiliates.offersView.filter.rule.${item}`) }}
            </p>
          </div>
        </el-popover>
      </div>
      <!-- card  deposit-->
      <div v-if="!hasCondition || listPayment.length === defaultListPayment.length">
        <div class="notification ui-m-md-b">
          <i class="fas fa-info-circle warning" />
          <div class="ui-m-sm-l">
            {{ $t('affiliates.offer_popup.empty_cpa_conditions') }}
          </div>
        </div>
      </div>
      <div
        v-if="listPayment.indexOf('deposits') === -1"
        ref="deposits"
        class="card-filter payment-card deposits"
      >
        <div class="card-filter__header">
          <h2 class="card-filter__title">
            {{ $t(`affiliates.offer_popup.deposits`) }}
          </h2>
          <div
            class="card-filter__del"
            :class="{ 'disabled': !ableToSetCpa }"
            @click="ableToSetCpa && confirmPayment('deposits')"
          >
            <ui-icon
              size="14px"
              lib="fa"
              substyle="fas"
              name="times"
              :color="$theme.dangerColor"
            />
          </div>
        </div>
        <div class="card-filter__body">
          <ui-range-input
            :value="deposits_count"
            :label="$t(`affiliates.offer_popup.deposit_count`)"
            :disabled="!ableToSetCpa"
            class="card-filter__range-input"
            type="number"
            :error="getRuleError('deposits_count')"
            @change="
              $set(cpa, 'deposits_count_min', prepareCpaValue($_.clone($event.min)))
              $set(cpa, 'deposits_count_max', prepareCpaValue($_.clone($event.max)))
            "
          />
          <ui-range-input
            :value="deposits_sum"
            :label="$t(`affiliates.offer_popup.deposit_sum`)"
            :disabled="!ableToSetCpa"
            error-tooltip
            class="card-filter__range-input"
            type="number"
            :error="getRuleError('deposits_sum')"
            @change="
              $set(cpa, 'deposits_sum_min', prepareCpaValue($_.clone($event.min)))
              $set(cpa, 'deposits_sum_max', prepareCpaValue($_.clone($event.max)))
            "
          />
          <ui-range-input
            :value="deposits_amount"
            :label="$t(`affiliates.offer_popup.deposit_amount`)"
            :disabled="!ableToSetCpa"
            class="card-filter__range-input"
            error-tooltip
            type="number"
            :error="getRuleError('deposit_amount')"
            @change="
              $set(cpa, 'deposit_amount_min', prepareCpaValue($_.clone($event.min)))
              $set(cpa, 'deposit_amount_max', prepareCpaValue($_.clone($event.max)))
            "
          />
        </div>
      </div>
      <!-- card  deposit-->
      <div
        v-if="listPayment.indexOf('sport_bets') === -1"
        ref="sport_bets"
        class="card-filter payment-card sport_bets"
      >
        <div class="card-filter__header">
          <h2 class="card-filter__title">
            {{ $t(`affiliates.offer_popup.sport_bet`) }}
          </h2>
          <div
            class="card-filter__del"
            :class="{ 'disabled': !ableToSetCpa }"
            @click="ableToSetCpa && confirmPayment('sport_bets')"
          >
            <ui-icon
              size="14px"
              lib="fa"
              substyle="fas"
              name="times"
              :color="$theme.dangerColor"
            />
          </div>
        </div>
        <div class="card-filter__body">
          <ui-range-input
            :value="sport_bets_count"
            :label="$t(`affiliates.offer_popup.bet_count`)"
            :disabled="!ableToSetCpa"
            class="card-filter__range-input"
            error-tooltip
            type="number"
            :error="getRuleError('sport_bets_count')"
            @change="
              $set(cpa, 'sport_bets_count_min', prepareCpaValue($_.clone($event.min)))
              $set(cpa, 'sport_bets_count_max', prepareCpaValue($_.clone($event.max)))
            "
          />
          <ui-range-input
            :value="sport_bets_sum"
            :label="$t(`affiliates.offer_popup.bet_sum`)"
            :disabled="!ableToSetCpa"
            class="card-filter__range-input"
            error-tooltip
            type="number"
            :error="getRuleError('sport_bets_sum')"
            @change="
              $set(cpa, 'sport_bets_sum_min', prepareCpaValue($_.clone($event.min)))
              $set(cpa, 'sport_bets_sum_max', prepareCpaValue($_.clone($event.max)))
            "
          />
          <ui-range-input
            :value="sport_bet_amount"
            :label="$t(`affiliates.offer_popup.bet_amount`)"
            :disabled="!ableToSetCpa"
            class="card-filter__range-input"
            error-tooltip
            type="number"
            :error="getRuleError('sport_bet_amount')"
            @change="
              $set(cpa, 'sport_bet_amount_min', prepareCpaValue($_.clone($event.min)))
              $set(cpa, 'sport_bet_amount_max', prepareCpaValue($_.clone($event.max)))
            "
          />
          <ui-range-input
            :value="sport_bets_odd"
            :label="$t(`affiliates.offer_popup.bet_odd`)"
            :disabled="!ableToSetCpa"
            class="card-filter__range-input"
            error-tooltip
            type="number"
            :error="getRuleError('sport_bets_odd')"
            @change="
              $set(cpa, 'sport_bets_odd_min', prepareCpaValue($_.clone($event.min)))
              $set(cpa, 'sport_bets_odd_max', prepareCpaValue($_.clone($event.max)))
            "
          />
        </div>
      </div>

      <div
        v-if="listPayment.indexOf('casino_bets') === -1"
        ref="casino_bets"
        class="card-filter payment-card casino_bets"
      >
        <div class="card-filter__header">
          <h2 class="card-filter__title">
            {{ $t(`affiliates.offer_popup.casino_bets`) }}
          </h2>
          <div
            class="card-filter__del"
            :class="{ 'disabled': !ableToSetCpa }"
            @click="ableToSetCpa && confirmPayment('casino_bets')"
          >
            <ui-icon
              size="14px"
              lib="fa"
              substyle="fas"
              name="times"
              :color="$theme.dangerColor"
            />
          </div>
        </div>
        <div class="card-filter__body">
          <ui-range-input
            :value="casino_bets_count"
            :label="$t(`affiliates.offer_popup.bet_count`)"
            :disabled="!ableToSetCpa"
            class="card-filter__range-input"
            error-tooltip
            type="number"
            :error="getRuleError('casino_bets_count')"
            @change="
              $set(cpa, 'casino_bets_count_min', prepareCpaValue($_.clone($event.min)))
              $set(cpa, 'casino_bets_count_max', prepareCpaValue($_.clone($event.max)))
            "
          />
          <ui-range-input
            :value="casino_bets_sum"
            :label="$t(`affiliates.offer_popup.bet_sum`)"
            :disabled="!ableToSetCpa"
            class="card-filter__range-input"
            error-tooltip
            type="number"
            :error="getRuleError('casino_bets_sum')"
            @change="
              $set(cpa, 'casino_bets_sum_min', prepareCpaValue($_.clone($event.min)))
              $set(cpa, 'casino_bets_sum_max', prepareCpaValue($_.clone($event.max)))
            "
          />
          <ui-range-input
            :value="casino_bet_amount"
            :label="$t(`affiliates.offer_popup.bet_amount`)"
            :disabled="!ableToSetCpa"
            class="card-filter__range-input"
            error-tooltip
            type="number"
            :error="getRuleError('casino_bet_amount')"
            @change="
              $set(cpa, 'casino_bet_amount_min', prepareCpaValue($_.clone($event.min)))
              $set(cpa, 'casino_bet_amount_max', prepareCpaValue($_.clone($event.max)))
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setTimeout } from 'timers';
import aclMixin from '@/views/mixins/acl';
import UiRangeInput from '@/components/UIElements/UiRangeInput.vue';

export default {
  name: 'CpaItem',
  components: {
    UiRangeInput,
  },
  mixins: [aclMixin],
  props: {
    indexRule: {
      type: Number,
      required: true,
    },
    customerCurrencies: {
      type: Array,
      default: () => [],
    },
    cpa: {
      type: Object,
      required: true,
    },
    isDisableDeletePayout: {
      type: Boolean,
      default: false,
    },
    errorRule: {
      type: [Array, Object, undefined],
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      confirm: {
        title: '',
        msg: '',
        type: 'success',
        actionIcon: 'check',
        action: '',
        actionName: '',
        data: {},
      },
      listPayment: ['deposits', 'sport_bets', 'casino_bets'],
      defaultListPayment: ['deposits', 'sport_bets', 'casino_bets'],
      holds: {
        rev: 'days',
        dep: 'days',
      },
      timeRanges: ['seconds', 'minutes', 'hours', 'days'],
      visible3: false,
      cap_players_count: 0,
      cap_period_seconds: 0,
      dailyCap: 0,
      deposits_count: {
        min: null,
        max: null,
      },
      deposits_sum: {
        min: null,
        max: null,
      },
      deposits_amount: {
        min: null,
        max: null,
      },
      sport_bets_count: {
        min: null,
        max: null,
      },
      sport_bets_sum: {
        min: null,
        max: null,
      },
      sport_bet_amount: {
        min: null,
        max: null,
      },
      sport_bets_odd: {
        min: null,
        max: null,
      },
      casino_bets_count: {
        min: null,
        max: null,
      },
      casino_bets_sum: {
        min: null,
        max: null,
      },
      casino_bet_amount: {
        min: null,
        max: null,
      },
    };
  },
  computed: {
    hasCondition() {
      return Object.values(this.$_.pick(this.cpa, [
        'deposit_amount_max',
        'deposit_amount_min',
        'deposits_sum_min',
        'deposits_sum_max',
        'deposits_count_max',
        'deposits_count_min',
        'sport_bets_odd_max',
        'sport_bets_odd_min',
        'sport_bets_sum_max',
        'sport_bets_sum_min',
        'casino_bets_sum_max',
        'casino_bets_sum_min',
        'sport_bet_amount_max',
        'sport_bet_amount_min',
        'sport_bets_count_max',
        'sport_bets_count_min',
        'casino_bet_amount_max',
        'casino_bet_amount_min',
        'casino_bets_count_max',
        'casino_bets_count_min',
        'casino_bets_sum_min',
        'casino_bets_sum_max',
      ])).some(el => !!el);
    },
    holdRevShare: {
      get() {
        if (this.cpa.hold_seconds === null) {
          return null;
        }

        const dur = this.$moment.duration(
          this.cpa.hold_seconds,
          'seconds',
        );
        return dur.as(this.holds.dep) || 0;
      },
      set(value) {
        if (value || value === 0) {
          this.cpa.hold_seconds = this.$moment
            .duration(parseFloat(value), this.holds.dep)
            .asSeconds();
        } else {
          this.cpa.hold_seconds = null;
        }
      },
    },
  },

  watch: {
    indexRule() {
      this.getPayment();
      this.reloadDataPayload();
      this.holdDur();
      this.holdRev();
    },
    dailyCap: {
      handler(value) {
        this.cpa.cap_period_seconds = this.$moment.duration(+value, 'days').asSeconds();
      },
    },
  },
  mounted() {
    this.getPayment();
    this.holdDur();
    this.holdRev();
    this.reloadDataPayload();
  },
  methods: {
    holdDur() {
      const holdDur = this.$moment.duration(
        this.cpa.hold_seconds,
        'seconds',
      );
      Object.keys(holdDur._data)
        .reverse()
        .forEach((key) => {
          if (holdDur._data[key] !== 0) {
            this.holds.dep = key;
          }
        });
      if (this.holds.dep === 'months' || this.holds.dep === 'years') {
        this.holds.dep = 'days';
      }
    },
    holdRev() {
      if (this.holds.rev === 'months' || this.holds.rev === 'years') {
        this.holds.rev = 'days';
      }
    },
    getRuleError(key) {
      const min = this.$_.get(this.errorRule, ['conditions', key, 'min_value']);
      const max = this.$_.get(this.errorRule, ['conditions', key, 'max_value']);

      return {
        min,
        max,
      };
    },
    deletePayout() {
      this.$emit('remove-payout');
    },
    reloadDataPayload() {
      this.deposits_count.min = this.cpa.deposits_count_min;
      this.deposits_count.max = this.cpa.deposits_count_max;
      this.deposits_sum.min = this.cpa.deposits_sum_min;
      this.deposits_sum.max = this.cpa.deposits_sum_max;
      this.deposits_amount.min = this.cpa.deposit_amount_min;
      this.deposits_amount.max = this.cpa.deposit_amount_max;
      // sport
      this.sport_bets_count.min = this.cpa.sport_bets_count_min;
      this.sport_bets_count.max = this.cpa.sport_bets_count_max;
      this.sport_bets_sum.min = this.cpa.sport_bets_sum_min;
      this.sport_bets_sum.max = this.cpa.sport_bets_sum_max;
      this.sport_bet_amount.min = this.cpa.sport_bet_amount_min;
      this.sport_bet_amount.max = this.cpa.sport_bet_amount_max;
      this.sport_bets_odd.min = this.cpa.sport_bets_odd_min;
      this.sport_bets_odd.max = this.cpa.sport_bets_odd_max;
      // casino
      this.casino_bets_count.min = this.cpa.casino_bets_count_min;
      this.casino_bets_count.max = this.cpa.casino_bets_count_max;
      this.casino_bets_sum.min = this.cpa.casino_bets_sum_min;
      this.casino_bets_sum.max = this.cpa.casino_bets_sum_max;
      this.casino_bet_amount.min = this.cpa.casino_bet_amount_min;
      this.casino_bet_amount.max = this.cpa.casino_bet_amount_max;

      if (this.cpa.cap_period_seconds !== null) {
        this.dailyCap = this.$moment.duration(this.cpa.cap_period_seconds, 'seconds').asDays();
      }
    },
    getPayment() {
      this.listPayment = ['deposits', 'sport_bets', 'casino_bets'];

      const {
        deposits_count_min,
        deposits_count_max,
        deposits_sum_min,
        deposits_sum_max,
        deposit_amount_min,
        deposit_amount_max,
      } = this.cpa;

      if ([
        deposits_count_min,
        deposits_count_max,
        deposits_sum_min,
        deposits_sum_max,
        deposit_amount_min,
        deposit_amount_max].some(el => !!el)
      ) {
        this.listPayment.splice(this.listPayment.indexOf('deposits'), 1);
      }

      const {
        sport_bets_count_min,
        sport_bets_count_max,
        sport_bets_sum_min,
        sport_bets_sum_max,
        sport_bet_amount_min,
        sport_bet_amount_max,
        sport_bets_odd_min,
        sport_bets_odd_max,
      } = this.cpa;

      if ([
        sport_bets_count_min,
        sport_bets_count_max,
        sport_bets_sum_min,
        sport_bets_sum_max,
        sport_bet_amount_min,
        sport_bet_amount_max,
        sport_bets_odd_min,
        sport_bets_odd_max].some(el => !!el)
      ) {
        this.listPayment.splice(this.listPayment.indexOf('sport_bets'), 1);
      }

      const {
        casino_bets_count_min,
        casino_bets_count_max,
        casino_bets_sum_min,
        casino_bets_sum_max,
        casino_bet_amount_min,
        casino_bet_amount_max,
      } = this.cpa;
      if ([
        casino_bets_count_min,
        casino_bets_count_max,
        casino_bets_sum_min,
        casino_bets_sum_max,
        casino_bet_amount_min,
        casino_bet_amount_max].some(el => !!el)
      ) {
        this.listPayment.splice(this.listPayment.indexOf('casino_bets'), 1);
      }
    },
    confirmPayment(v) {
      this.confirm.title = this.$t('affiliates.offer_popup.confirm_payment.title');
      this.confirm.msg = this.$t('affiliates.offer_popup.confirm_payment.msg');
      this.confirm.type = 'warning';
      this.confirm.action = 'payment';
      this.confirm.actionName = this.$t('affiliates.offer_popup.confirm_payment.action');
      this.confirm.closeActionName = '';
      this.confirm.data = v;
      this.$refs.confirm.open();
    },
    confirmPayout() {
      if (this.isDisableDeletePayout) return;

      this.confirm.title = this.$t('affiliates.offer_popup.confirm_cpa_payout.title');
      this.confirm.msg = this.$t('affiliates.offer_popup.confirm_cpa_payout.msg');
      this.confirm.type = 'warning';
      this.confirm.action = 'payout';
      this.confirm.actionName = this.$t('affiliates.offer_popup.confirm_cpa_payout.action');
      this.confirm.closeActionName = '';
      this.$refs.confirm.open();
    },
    deletePayment(v) {
      this.listPayment.push(v);
      if (v === 'deposits') {
        this.cpa.deposits_count_min = null;
        this.cpa.deposits_count_max = null;
        this.cpa.deposits_sum_min = null;
        this.cpa.deposits_sum_max = null;
        this.cpa.deposit_amount_min = null;
        this.cpa.deposit_amount_max = null;
      }
      if (v === 'sport_bets') {
        this.cpa.sport_bets_count_min = null;
        this.cpa.sport_bets_count_max = null;
        this.cpa.sport_bets_sum_min = null;
        this.cpa.sport_bets_sum_max = null;
        this.cpa.sport_bet_amount_min = null;
        this.cpa.sport_bet_amount_max = null;
        this.cpa.sport_bets_odd_min = null;
        this.cpa.sport_bets_odd_max = null;
      }
      if (v === 'casino_bets') {
        this.cpa.casino_bets_count_min = null;
        this.cpa.casino_bets_count_max = null;
        this.cpa.casino_bets_sum_min = null;
        this.cpa.casino_bets_sum_max = null;
        this.cpa.casino_bet_amount_min = null;
        this.cpa.casino_bet_amount_max = null;
      }
    },
    actionConfirm(action, type) {
      switch (action) {
      case 'payment':
        this.deletePayment(type);
        this.$refs.confirm.close();
        break;
      case 'payout':
        this.deletePayout(type);
        this.$refs.confirm.close();
        break;
      default:
        break;
      }
    },
    toScroll(elem) {
      setTimeout(() => {
        if (this.$refs) {
          // eslint-disable-next-line no-unused-expressions
          this.$refs[elem]?.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    addPayment(item) {
      this.listPayment.splice(this.listPayment.indexOf(item), 1);
      this.toScroll(item);
    },
    prepareCpaValue(val) {
      if (val || val === 0) {
        return val;
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.label-text, .offer-input /deep/ .label-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal!important;
  letter-spacing: normal;
}

.days {
  font-size: 13px;

  /deep/ {
    .input-post-text {
      padding: 0 4px;
    }
  }
}

.remove-payout.disabled {
  opacity: .5;
  cursor: not-allowed;
}
.container {
  background-color: #f4f7fa;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.payment-card {
  margin-top: 12px;
  background-color: #e3e8ef!important;
}

.notification {
  display: flex;
  padding: 12px 16px 12px 12px;
  border-radius: 8px;
  font-size: 13px;
  background-color: var(--danger-color-alpha-10);
  color: var(--danger-color);
  margin-top: 8px;
}

.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 16px;
    font-weight: 500;
    color: #303634;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}
</style>
