export default {
  getCrmMetrics() {
    return this.affiliatesApi.get('/crm/misc/metrics');
  },
  getCrmTemplateVariables() {
    return this.affiliatesApi.get('/crm/misc/template-variables');
  },
  getSegments(params) {
    return this.affiliatesApi.get('/crm/segments', { params });
  },
  getSegmentStats(params) {
    return this.affiliatesApi.post('/crm/segments/stats', params);
  },
  getSegmentStatsById(id, filters = []) {
    return this.affiliatesApi.post(`/crm/segments/${id}/stats`, { filters });
  },

  postSegments(payload) {
    return this.affiliatesApi.post('/crm/segments', payload);
  },

  getSegment(id) {
    return this.affiliatesApi.get(`/crm/segments/${id}`);
  },
  putSegment(id, payload) {
    return this.affiliatesApi.put(`/crm/segments/${id}`, payload);
  },
  deleteSegment(id) {
    return this.affiliatesApi.delete(`/crm/segments/${id}`);
  },
  postSegmentChangeStatus(id, status) {
    return this.affiliatesApi.post(`/crm/segments/${id}/${status}`);
  },

  getSegmentComments(id) {
    return this.affiliatesApi.get(`/crm/segments/${id}/comments?sort_dir=asc`);
  },
  postSegmentComments(id, data) {
    return this.affiliatesApi.post(`/crm/segments/${id}/comments`, data);
  },

  getSegmentPlayers(id) {
    return this.affiliatesApi.get(`/crm/segments/${id}/players`);
  },
  getActionGroupsPlayers(id) {
    return this.affiliatesApi.get(`/crm/action-groups/${id}/players`);
  },
  getActivityPlayers(id) {
    return this.affiliatesApi.get(`/crm/activities/${id}/players`);
  },

  getActivities(params) {
    return this.affiliatesApi.get('/crm/activities', { params });
  },
  postActivity(payload) {
    return this.affiliatesApi.post('/crm/activities', payload);
  },
  getActivityStatsById(id) {
    return this.affiliatesApi.get(`/crm/activities/${id}/stats`);
  },
  getActivity(id, params) {
    if (params) {
      return this.affiliatesApi.get(`/crm/activities/${id}`, { params });
    }

    return this.affiliatesApi.get(`/crm/activities/${id}`);
  },
  putActivity(id, payload) {
    return this.affiliatesApi.put(`/crm/activities/${id}`, payload);
  },
  sendActivityTestEmails(payload) {
    return this.affiliatesApi.post('/crm/activities/send-test-email', payload);
  },
  sendActivityTestSms(payload) {
    return this.affiliatesApi.post('/crm/activities/send-test-sms', payload);
  },
  // deleteActivity(id) {
  //   return this.affiliatesApi.delete(`/crm/activities/${id}`);
  // },
  // putActivityStatus(id, status) {
  //   return this.affiliatesApi.put(`/crm/activities/${id}/status`, { status });
  // },
  postActivityStatus(id, status, payload) {
    const allowedStatuses = [
      'archive',
      'to-review',
      'for-rework',
      'approve',
      'decline',
      'run',
      'pause',
      'complete',
      'clone',
    ];

    if (allowedStatuses.includes(status)) {
      return this.affiliatesApi.post(`/crm/activities/${id}/${status}`, payload);
    }

    return Promise.reject(new TypeError('Incorect status'));
  },
  getActivityComments(id, params) {
    return this.affiliatesApi.get(`/crm/activities/${id}/comments`, { params });
  },
  postActivityComment(id, comment) {
    return this.affiliatesApi.post(`/crm/activities/${id}/comments`, comment);
  },
  postEmailTemplatePreview(payload = {}) {
    const { site_id, markdown, crm_mail_template_id } = payload;
    return this.affiliatesApi.post('/crm/activities/email-template-preview', {
      site_id,
      markdown,
      crm_mail_template_id,
    });
  },
  updateActivityMetrics(id, metrics) {
    return this.affiliatesApi.put(`crm/activities/${id}/metrics`, { metrics });
  },
  getCallCenterProjects(params) {
    return this.affiliatesApi.get('/crm/misc/call-center/projects', { params });
  },
  getEmailTemplates(params) {
    return this.affiliatesApi.get('/crm/misc/email-templates', { params });
  },
};
