export default {
  crm: {
    menu: {
      dashboard: {
        main: 'Дашборд',
      },
      activities: {
        main: 'Активности',
      },
      running: {
        main: 'Запущенные',
      },
      quality_assurance: {
        main: 'Модерация',
        activities: 'Активности ',
        segments: 'Сегменты',
      },
      segments: {
        main: 'Сегменты',
      },
    },
    status: {
      updating: 'Обновляется...',
    },
    errors: {
      changes: 'Активность была приостановлена из-за внесенных изменений в настройки сегмента',
    },
    fields: {
      incrementing_id: {
        title: 'ID',
      },
      name: {
        title: 'Название',
      },
      segment: {
        title: 'Сегмент',
      },
      actions: {
        title: 'Действия',
        values: {
          email: 'Письмо',
          sms: 'SMS',
          push: 'Push',
          call_center: 'Колл-центр',
        },
      },
      activity_period_start: {
        title: 'Дата начала',
      },
      segment_players_count: {
        title: 'Игроки',
      },
      activity_period_end: {
        title: 'Дата окончания',
      },
      computed_status: {
        title: 'Статус',
        values: {
          all: 'Все',
          approved: 'Одобрено',
          new: 'Новая',
          on_rework: 'На доработке',
          for_rework: 'На доработке',
          on_review: 'На рассмотрении',
          declined: 'Отклонено',
          running: 'Запущено',
          completed: 'Завершено',
          paused: 'Приостановлено',
          draft: 'Черновик',
          active: 'Активно',
          archived: 'В архиве',
        },
      },
      type: {
        title: 'Тип',
        values: {
          fixed: 'Фиксированный',
          dynamic: 'Динамический',
          manual: 'Ручной',
        },
      },
    },
    segments: {
      title: 'Сегменты',
      title_quality_assurance: 'Сегменты на модерации',
      add_segment: 'Создать сегмент',
      create_segment_title: 'Создание сегмента',
      edit_segment_title: 'Редактирование сегмента',
      using_activities: 'Активности, использующие этот сегмент',
      create_segment_name_input: 'Название',
      create_segment_name_input_placeholder: 'Введите название',
      create_segment_value_placeholder: 'Введите значение',
      activities_empty: 'Нет активностей, использующих этот сегмент',
      activity_filters: {
        title: 'Фильтры',
        hint: 'Фильтрация будет применена к итоговой выборке, настроенной в сегменте',
      },
      type: {
        title: 'Тип',
        fixed: 'Фиксированный',
        dynamic: 'Динамический',
        manual: 'Ручной',
      },
      manual: {
        title: 'Настройка сегмента',
        replace: 'Заменить',
        replaceNotice: 'В сегменте {playersCount} игроков',
        type: {
          csv: 'Загрузка CSV',
          manual: 'Добавление вручную',
        },
        csv: {
          title: 'Загрузите CSV со списком ID игроков',
          note: `
            <div><b>Важно:</b> для CSV в файле должна быть колонка с одним из следующих имен:</div>
            <ul>
              <li>
                — id
              </li>
              <li>
                — player_id
              </li>
              <li>
                — site_player_id
              </li>
            </ul>
          `,
        },
        text: {
          title: 'Введите ID игроков <span>(каждый ID с новой строки)</span>',
          placeholder: 'Каждый игрок с новой строки',
        },
        upload: {
          dropFileHereOr: 'Перетащите сюда или',
          selectFile: 'выберите файл',
        },
      },
      configure: {
        title: 'Настройка фильтрации',
        add_new_group: 'Добавить новую группу правил',
        add_new_rule: 'Новое правило',
      },
      filters: {
        seconds_since_registration: 'Времени с момента регистрации',
        seconds_since_last_deposit: 'Времени с момента депозита',
        seconds_since_last_bet: 'Времени с момента ставки',
        seconds_since_last_sports_bet: 'Времени с момента ставки на спорт',
        seconds_since_last_games_bet: 'Времени с момента ставки на казино',
        registration_player_attributes: 'Регистрация и атрибуты игрока',
        deposits: 'Депозиты',
        gaming_activity: 'Игровая активность',
        registration_date_time: 'Дата и время регистрации',
        latest_deposit_date_time: 'Дата и время последнего депозита',
        latest_bet_date_time: 'Дата и время последней ставки',
        latest_sport_bet_date_time: 'Дата и время последней спортивной ставки',
        latest_games_bet_date_time: 'Дата и время ставок в последних играх',
        registration_timestamp: 'Дата и время регистрации',
        latest_deposit_timestamp: 'Дата и время последнего депозита',
        latest_bet_timestamp: 'Дата и время последней ставки',
        latest_sport_bet_timestamp: 'Дата и время последней спортивной ставки',
        latest_games_bet_timestamp: 'Дата и время ставок в последних играх',
        has_deposits: 'Наличие депозитов',
        has_bets: 'Наличие ставок',
        has_sports_bets: 'Наличие ставок на спорт ',
        has_games_bets: 'Наличие ставок на казино',
        favourite_bet_by_sum: 'Предпочитаемый тип ставок (по сумме)',
        favourite_bet_by_count: 'Предпочитаемый тип ставок (по количеству)',
        player_country_code: 'Страна игрока',
        player_region_code: 'Регион игрока',
        favourite_payment_system_id: 'Предпочитаемая платежная система',
        favourite_sport_type: 'Предпочитаемый вид спорта',
        favourite_sport_league: 'Предпочитаемый турнир',
        select_operator_placeholder: 'Выберите оператор сравнения',
        select_rule_placeholder: 'Выберите значение',
        player_contacts: 'Контакты',
        player_affiliate_id: 'Партнёры',
        player_sub_affiliate_id: 'Суб-Партнёры',
        betting_profit_sum: 'Профит, $',
        deposits_count: 'Депозиты',
        deposits_sum: 'Депозиты, $',
        sport_bets_count: 'Ставки на спорт',
        sport_bets_sum: 'Ставки на спорт, $',
        sport_bets_live_count: 'Ставки на спорт (Live)',
        sport_bets_live_sum: 'Ставки на спорт (Live), $',
        games_bets_count: 'Ставки в казино',
        games_bets_sum: 'Ставки в казино, $',
      },
      logical_relations: {
        and: 'И',
        or: 'Или',
      },
      boolean: {
        true: 'Да',
        false: 'Нет',
      },
      favourite_bet: {
        sport: 'Спорт',
        games: 'Казино',
      },
      player_contacts: {
        email: 'Email',
        phone: 'Телефон',
      },
      operators: {
        '=': 'Равно',
        '<': 'Меньше',
        '<=': 'Меньше либо равно',
        '>': 'Больше',
        '>=': 'Больше либо равно',
        between: 'Период',
      },
      time_ranges: {
        seconds: 'Секунды',
        minutes: 'Минуты',
        days: 'Дни',
        hours: 'Часы',
        between: 'Период',
      },
      stats: {
        players: 'Игроки',
        statistics: 'Статистика',
        deposit: 'Депозиты',
        sport_bet: 'Ставки на спорт',
        casino_bet: 'Ставки на казино',
        avg_med: 'Ср. / Мед.',
        refresh: 'Обновить',
      },
      status: {
        all: 'Все сегменты',
        declined: 'Отклоненные сегменты',
        new: 'Новые сегменты',
        approved: 'Одобренные сегменты',
        on_review: 'Сегменты на рассмотрении',
      },
    },
    activities: {
      title: 'Активности',
      title_running: 'Запущенные активности',
      title_quality_assurance: 'Активности на модерации',
      add_activity: 'Создать активность',
      players_count: 'Количество игроков: {count}',
      info: {
        new_activity: 'Новая активность',
        info: 'Информация',
        status: 'Статус',
        created_at: 'Время создания',
        period: 'Период',
        general: 'Общее',
        start_date: 'Дата начала',
        end_date: 'Дата окончания',
        name: 'Название активности',
        name_placeholder: 'Введите название активности',
        comments: 'Комментарии',
        add_metric: 'Добавить метрику',
      },
      metrics: {
        title: 'Метрики',
        days: 'День (дни)',
        cooldown: 'Кулдаун',
        cooldown_tooltip: 'Укажите количество дней, в течение которых мы будем продолжать собирать статистику по игрокам, которые могут покинуть настроенный сегмент',
        no_metrics_selected_error: 'Не выбраны метрики',
        no_avaliable_data_error: 'Нет доступных данных',
        cooldown_stat: 'Кулдаун: 0 дней | Кулдаун: 1 день | Кулдаун: {cooldown} дня | Кулдаун: {cooldown} дней',
        updated_at_stat: 'Обновлено {datetime}',
        players_tooltip: 'Количество активных игроков (% активных игроков от общего кол-ва игроков в группе) | Общее кол-во игроков в группе',
      },
      segment: {
        title: 'Сегмент',
        hint: 'Выберите один из существующих <b>глобальных сегментов</b> или создайте новый <b>локальный</b>',
        local: 'Локальный',
        global: 'Глобальный',
        create_button: 'Создать сегмент',
        replace_button: 'Заменить сегмент',
        not_approved: 'Не одобрен',
        filtered: 'Отфильтровано',
        local_segment_error: 'Локальный сегмент содержит ошибки',
      },
      actions: {
        title: 'Действия',
        send: {
          repeat: 'Повторять',
          label_1: 'Отправлять',
          label_2: 'раз(а) в',
          hint: 'Отправлять <b>{count} раз(а)</b> в <b>0 дней</b> | Отправлять <b>{count} раз(а)</b> в <b>1 день</b> | Отправлять <b>{count} раз(а)</b> в <b>{interval} дня</b> | Отправлять <b>{count} раз(а)</b> в <b>{interval} дней</b>',
          hint_once: 'Отправить <b>однократно</b>',
          day: 'день (дни)',
        },
        control_group: 'Контрольная группа',
        of_segment: 'сегмента',
        action_group: 'Группа',
        group: 'Группа',
        add: 'Добавить',
        action: 'действие',
        add_translation: 'Добавить перевод',
        new_action_group_button: 'Добавить новую группу',
        exceed_action_group_limit_hint: 'Активность не может включать более {limit} групп',
        empty_name: '<без имени>',
        action_popup: {
          call_center_title: 'Выберите проект',
          sms_title: 'SMS',
          mail_title: 'E-Mail',
          mail_template_title: 'Выбор шаблона письма',
          push_title: 'Push',
          add_metrics_title: 'Добавить метрику',
          call_center_hint: 'Выберите проект из колл-центра, который будет привязан к этой активности',
          call_center_select: 'Проект из колл-центра',
          call_center_select_placeholder: 'Выберите проект',
          mail_hint: 'В шаблон письма будут вставлены следующие данные',
          mail_subject_label: 'Тема письма',
          mail_header_label: 'Заголовок письма',
          mail_message_placeholder: 'Текст сообщения',
          mail_add_param: 'Добавить параметр',
          default: 'По умолчанию',
          search_languages_placeholder: 'Поиск языков',
          email_fields: {
            name: 'Название действия',
            message: 'Текст сообщения',
            subject: 'Тема письма',
          },
          sms_fields: {
            name: 'Название действия',
            message: 'Текст сообщения',
            symbols_remaining: '{numberOfSMS} SMS, {remaining} символов осталось',
          },
        },
        action_params_popup: {
          title: 'Параметр',
          search_placeholder: 'Поиск параметров',
          not_found: 'Не найдено',
        },
        action_params: {
          firstName: 'Имя',
          lastName: 'Фамилия',
          email: 'E-Mail',
          phone: 'Телефон',
        },
        send_test_email: {
          title: 'Отправить тестовое письмо',
          emails_title: 'E-mails',
          emails_placeholder: 'Введите адреса e-mail каждый с новой строки',
          msg: 'Введите список e-mail адресов (каждый с новой строки) для отправки тестового письма',
          all_localizations: 'Все локализации',
          success: 'Письмо успешно отправлено',
          empty_mail_message: 'Письмо пустое',
        },
        send_test_sms: {
          title: 'Отправить тестовое SMS',
          msg: 'Подтвердите отправку SMS <b>{smsName}</b>',
          phone_number: 'Контактный номер',
          all_localizations: 'Все локализации',
          success: 'SMS успешно отправлено',
        },
        sms_statistics: {
          title: 'SMS Статистика',
          playersCount: 'Количество игроков',
          acceptedCount: 'Принято SMS',
          sent: 'Отправлено SMS',
          delivered: 'Доставлено SMS',
          spent: 'Сумма',
        },
        email_statistics: {
          title: 'Статистика по E-mail',
          playersCount: 'Количество игроков',
          acceptedCount: 'Принято писем',
          sent: 'Отправлено писем',
          bounced: 'Не удалось доставить',
          opened: 'Прочитано писем',
          uniqueClicks: 'Уникальные клики',
        },
        call_statistics: {
          title: 'Статистика',
          successfulCalls: 'Успешные вызовы',
          failedCalls: 'Неудачные вызовы',
          totalCalls: 'Всего звонков',
        },
        mail_template: {
          title: 'Сменить шаблон',
          template: 'Шаблон',
          confirm_title: 'Сменить шаблон?',
          confirm_body: 'Смена шаблона приведет к удалению всего заполненного контента для письма. Сменить его?',
        },
        errors: {
          param_error: '{param}: {error}',
          has_param_error: 'Есть ошибки в {entity} шаблоне',
          has_call_error: 'Есть ошибки в шаблоне колл-центра',
        },
      },
      history_card: {
        title: 'История',
        no_history: 'История отсутствует',
      },
      status_groups: {
        status: {
          title: 'Статус',
          values: {
            draft: 'Черновик',
            active: 'Активно',
            archived: 'В архиве',
          },
        },
        moderation_status: {
          title: 'Статус модерации',
          values: {
            on_review: 'На рассмотрении',
            for_rework: 'На доработке',
            approved: 'Одобрено',
            declined: 'Отклонено',
          },
        },
        launch_status: {
          title: 'Статус запуска',
          values: {
            pending: 'Ожидает',
            running: 'Запущено',
            paused: 'Приостановлено',
            completed: 'Завершено',
          },
        },
      },
      errors: {
        activity_expired_error: 'Период активности истёк',
      },
    },
    tooltips: {
      download: 'Загрузить',
      preview: 'Превью',
      edit: 'Редактировать',
      delete: 'Удалить',
      clone: 'Клонировать',
      filter: 'Фильтр',
      shadow_percent: 'Использовать оставшийся % в качестве значения',
      send_test_email: 'Отправить тестовое письмо',
      send_test_sms: 'Отправить тестовое SMS',
    },
    buttons: {
      edit: 'Редактировать',
      delete: 'Удалить',
      view: 'Просмотр',
      cancel: 'Отмена',
      save: 'Сохранить',
      close: 'Закрыть',
      decline: 'Отклонить',
      approve: 'Одобрить',
      for_rework: 'Отправить на доработку',
      review: 'Отправить на проверку',
      save_and_send: 'Сохранить и отправить на проверку',
      send: 'Отправить на проверку',
      start_activity: 'Запустить активность',
      resume_activity: 'Возобновить активность',
      pause_activity: 'Приостановить активность',
      stop_activity: 'Остановить активность',
      save_action: 'Сохранить действие',
      save_metrics: 'Сохранить метрики',
      archive: 'Архивировать',
      complete: 'Завершить',
      make_default: 'Сделать по умолчанию',
      preview: 'Превью',
      confirm: 'Подтвердить',
      send_test_email: 'Отправить тестовое письмо',
      send_test_sms: 'Отправить тестовое SMS',
      clone: 'Клонировать',
      select_template: 'Выбрать шаблон',
      change: 'Сменить',
    },
    confirm: {
      to_review_activity: {
        title: 'Отправить на проверку',
        msg: 'Подтвердите отправку <span>{activityName}</span> на проверку',
        action: 'Подтвердить',
      },
      save_and_to_review_activity: {
        title: 'Сохранить и отправить на проверку',
        msg: 'Подтвердите отправку <span>{activityName}</span> на проверку',
        action: 'Подтвердить',
      },
      approve_activity: {
        title: 'Одобрить активность',
        msg: 'Вы уверены, что хотите одобрить активность <span>{activityName}</span>?',
        action: 'Подтвердить',
      },
      for_rework_activity: {
        title: 'Отправить на доработку',
        msg: 'Подтвердите отправку <span>{activityName}</span> на доработку',
        action: 'Подтвердить',
      },
      decline_activity: {
        title: 'Отклонить активность',
        msg: 'Вы уверены, что хотите отклонить активность <span>{activityName}</span>?',
        action: 'Подтвердить',
      },
      run_activity: {
        title: 'Запустить активность',
        msg: '<span>{activityName}</span> начнется в <span>{dateTime}</span>',
        action: 'Подтвердить',
      },
      pause_activity: {
        title: 'Приостановить активность',
        msg: 'Вы уверены, что хотите приостановить <span>{activityName}</span>?',
        action: 'Подтвердить',
      },
      complete_activity: {
        title: 'Остановить активность',
        msg: 'Вы уверены, что хотите остановить <span>{activityName}</span>?',
        action: 'Подтвердить',
      },
      clone_activity: {
        title: 'Клонировать активность',
        msg: 'Вы уверены, что хотите клонировать активность <span>{activityName}</span>?',
        action: 'Подтвердить',
      },
      delete_activity: {
        title: 'Архивировать активность',
        msg: 'Вы уверены, что хотите добавить <span>{activityName}</span> в архив?',
        action: 'Архивировать',
      },
      delete_segment: {
        title: 'Удалить сегмент',
        msg: 'Вы уверены, что хотите удалить <span>{segmentName}</span>?',
        action: 'Удалить',
      },
    },
    only_mine: 'Только мои',
  },
};
