<template>
  <div class="ui-dropdown-select">
    <el-dropdown
      v-if="!disabled"
      trigger="click"
      :placement="placement"
      :disabled="disabled"
      @visible-change="handleVisibleChange"
      @command="handleParamClick"
    >
      <slot
        name="activator"
        v-bind="{ active }"
      />

      <el-dropdown-menu
        slot="dropdown"
        class="ddgroup ui-dropdown-select__dropdown-menu"
        style="display: none"
        :class="menuClass"
        :style="{}"
      >
        <ui-input
          v-model="filter"
          class="ui-dropdown-select__search-input search-input"
          :placeholder="searchPlaceholder"
          width="100%"
          pre-icon="search"
        />

        <div
          v-if="itemsFiltered.length"
          class="ui-dropdown-select__list-container"
        >
          <el-dropdown-item
            v-for="item in itemsFiltered"
            :key="item.param"
            class="ui-dropdown-select__list-item"
            :command="item"
          >
            {{ item.label }}
          </el-dropdown-item>
        </div>
        <template v-else-if="notFoundMessage">
          <slot name="not-found">
            <div class="ui-dropdown-select__not-found">
              {{ notFoundMessage }}
            </div>
          </slot>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <div
      v-else
      class="ui-dropdown-select__activator-disabled"
    >
      <slot
        name="activator"
        v-bind="{ active }"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'UiDropdownSelect',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    searchPlaceholder: {
      type: String,
      default: '',
    },
    notFoundMessage: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'bottom-start',
    },
    menuClass: {
      type: String,
      default: '',
    },
    debounceTimeout: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
      filter: '',
      pFilter: '',
    };
  },
  computed: {
    setPFilterDebounced() {
      return this.$_.debounce((value) => {
        this.pFilter = value;
      }, this.debounceTimeout);
    },
    pItems() {
      return this.items.map(item => ({
        ...item,
        searchString: `${item.param.toLowerCase()}\r\n${item.label.toLowerCase()}`,
      }));
    },
    itemsNotSelected() {
      return this.pItems.filter(item => !this.selected.includes(item.param));
    },
    itemsFiltered() {
      const filterLowerCase = this.pFilter.toLowerCase();
      return this.itemsNotSelected
        .filter(item => item.searchString.includes(filterLowerCase));
    },
  },
  watch: {
    filter(value) {
      this.setPFilterDebounced(value);
    },
  },
  methods: {
    handleParamClick(item) {
      this.$emit('select', item.param);
    },
    handleVisibleChange(active) {
      this.active = active;
      if (active) {
        this.filter = '';
      }
    },
  },
};
</script>

<style lang="scss">
  .ui-dropdown-select {
    &__dropdown-menu {
      &.el-dropdown-menu {
        overflow: visible;
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        width: 332px;
        max-height: 500px;
      }
    }

    &__list-container {
      overflow: auto;
    }

    &__list-item {
      font-size: 13px !important;
      line-height: 28px !important;
    }

    &__not-found {
      font-size: 14px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--crm-panel-stats)
    }

    &__search-input {
      padding-top: 5px;
      padding-bottom: 4px;
      border-bottom: 1px solid #dbdbdb;

      .input-wrapper {
        border: 0 !important;
      }

      .input-pre-icon {
        margin-left: 16px !important;
        font-size: 13px !important;
        color: #d3d3d3 !important;
        font-weight: 400 !important;
      }

      .input-element {
        padding-left: 4px !important;
      }
    }
  }
</style>
