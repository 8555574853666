<template>
  <div
    class="crm"
  >
    <sub-header
      :menu="menuFiltered"
      :current-route="currentRoute"
    >
      <div
        v-if="activeBrand"
        slot="prepend"
        class="brand"
      >
        <div class="brand__item brand__item_current">
          <img
            v-if="getBrandImgUrl()"
            :src="getBrandImgUrl()"
            :alt="activeBrand.name"
          >
          {{ activeBrand.name }}
          <i class="menu__icon fas fa-chevron-down" />
          <div class="brand__dropdown">
            <adm-ui-scrollbar
              class="brands-list"
              wrap-style="max-height: 400px;"
              :native="false"
            >
              <div
                v-for="brand in brands"
                :key="brand.id"
                class="brand__item"
                :class="{'brand__item_active': currentBrand === brand.id }"
                @click="currentBrand = brand.id"
              >
                <img
                  v-if="brand.favicon_base64"
                  :src="brand.favicon_base64"
                  :alt="brand.name"
                >
                {{ brand.name }}
              </div>
            </adm-ui-scrollbar>
          </div>
        </div>
      </div>
    </sub-header>
    <div class="crm__router-view-wrapper">
      <router-view
        :current-brand="currentBrand"
        class="crm__view"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SubHeader from '@/views/CRM/components/SubHeader.vue';

export default {
  name: 'CRM',

  components: {
    SubHeader,
  },

  data() {
    return {
      currentBrand: '',
    };
  },

  computed: {
    ...mapGetters({
      brands: 'misc/brands',
    }),

    menu() {
      return [
        // {
        //   name: this.$t('crm.menu.dashboard.main'),
        //   link: '/crm/dashboard',
        //   sections: [],
        // },
        {
          name: this.$t('crm.menu.activities.main'),
          link: '/crm/activities/list',
          sections: [],
          permission: 'crm_activities_view',
          showCondition: (/* item */) => this.adminAcl.is_superuser || this.$store.getters['auth/currentAcl'].crm_activities_view !== 'deny',
        },
        {
          name: this.$t('crm.menu.running.main'),
          link: '/crm/running',
          sections: [],
          permission: 'crm_activities_run',
          showCondition: (/* item */) => this.adminAcl.is_superuser || this.$store.getters['auth/currentAcl'].crm_activities_run !== 'deny',
        },
        {
          name: this.$t('crm.menu.quality_assurance.main'),
          sections: [
            {
              subItems: [
                {
                  name: this.$t('crm.menu.quality_assurance.activities'),
                  link: '/crm/quality-assurance/activities',
                  showCondition: (/* item */) => this.adminAcl.is_superuser || this.$store.getters['auth/currentAcl'].crm_activities_review !== 'deny',
                  permission: 'crm_activities_review',
                },
                {
                  name: this.$t('crm.menu.quality_assurance.segments'),
                  link: '/crm/quality-assurance/segments',
                  showCondition: (/* item */) => this.adminAcl.is_superuser || this.$store.getters['auth/currentAcl'].crm_segments_review !== 'deny',
                  permission: 'crm_segments_review',
                },
              ],
            },
          ],
        },
        {
          name: this.$t('crm.menu.segments.main'),
          link: '/crm/segments',
          sections: [],
          permission: 'crm_segments_view',
          showCondition: (/* item */) => this.adminAcl.is_superuser || this.$store.getters['auth/currentAcl'].crm_segments_view !== 'deny',
        },
      ];
    },
    adminAcl() {
      return this.$store.getters['auth/adminAcl'];
    },
    menuFiltered() {
      return this.menu
        .filter((item) => {
          const commonCondition = ((this.$store.getters['auth/currentAcl'][item.permission] !== 'deny' || !item.permission) && !this.adminAcl.is_superuser) || this.adminAcl.is_superuser;
          const alwaysShowCondition = item.showCondition && item.showCondition(item);
          return commonCondition || alwaysShowCondition;
        })
        .map(item => ({
          ...item,
          sections: item.sections
            .filter(section => ((this.$store.getters['auth/currentAcl'][section.permission] !== 'deny' || !section.permission) && !this.adminAcl.is_superuser) || this.adminAcl.is_superuser)
            .map(section => ({
              ...section,
              subItems: section.subItems.filter((subItem) => {
                const isArray = Array.isArray(this.$store.getters['auth/currentAcl'][subItem.permission]);
                let isIncludeItem = false;
                if (isArray) {
                  const permissionArray = this.$store.getters['auth/currentAcl'][subItem.permission];
                  const permissionArrayItem = subItem.subPermission;
                  isIncludeItem = permissionArray.includes(permissionArrayItem);
                }
                return this.adminAcl.is_superuser || (
                  !subItem.permission
                  || this.$store.getters['auth/currentAcl'][subItem.permission] !== 'deny'
                  || (isArray && isIncludeItem)
                );
              }),
            }))
            .filter(section => (section.subItems.length > 0)),
        }))
        .filter((item) => {
          const alwaysShowCondition = item.showCondition && item.showCondition(item);
          const subSectionsCount = item.sections
            .map(section => section.subItems.length)
            .reduce((a, b) => a + b, 0);
          return subSectionsCount > 0 || alwaysShowCondition;
        });
    },

    activeBrand() {
      return this.brands.filter(brand => brand.id === this.currentBrand)[0];
    },

    siteId: {
      get() {
        return this.currentBrand ? this.currentBrand : '';
      },
      set(newVal) {
        this.currentBrand = newVal;
      },
    },

    currentRoute() {
      const currentRoute = this.$store.getters['nav/getSubView'];
      return this.$t(`crm.menu.${currentRoute}.main`);
    },
  },

  watch: {
    currentBrand(v) {
      this.$ls.set('crm/siteId', JSON.stringify(v));
    },
  },

  created() {
    this.currentBrand = JSON.parse(this.$ls.get('crm/siteId')) ? JSON.parse(this.$ls.get('crm/siteId')) : this.brands[0].id;
  },


  methods: {
    getBrandImgUrl() {
      const brandFavicon = this.currentBrand.length !== 0 ? this.brands.filter(brand => brand.id === this.currentBrand)[0].favicon_base64 : '';
      return brandFavicon !== undefined ? brandFavicon : false;
    },
  },
};
</script>
