<template>
  <div class="ui-filter popover-filter filters">
    <ui-button
      ref="mainBtn"
      :filled="!!filtersOpen || hasFilters"
      icon="filter"
      lib="fa"
      substyle="fas"
      color="green"
      class="main-btn"
      @click="toggleFilters"
    >
      {{ $t('ui.filters.inner.filter') }}
    </ui-button>
    <transition name="fade">
      <div v-show="filtersOpen" ref="popup" class="pop" @keyup.enter.stop>
        <div class="arrow" />

        <div class="filter-row">
          <span class="label">{{ $t('payments.cpa_conversions.filter_popup.brand') }}</span>
          <el-select
            ref="brandSelect"
            v-model="currentFilters.site_id"
            :placeholder="$t('payments.cpa_conversions.filter_popup.brand')"
            filterable
            class="select"
            value-key="currentBrand"
          >
            <template slot="prefix">
              <img
                v-if="getBrandImgUrl()"
                :src="getBrandImgUrl()"
                class="prefix"
              >
            </template>
            <el-option
              v-for="item in brands"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <img
                v-if="item.favicon_base64"
                :src="item.favicon_base64"
              >
              <span>{{ item.name }}</span>
            </el-option>
          </el-select>
        </div>

        <div class="filter-row">
          <span class="label">{{ $t('payments.cpa_conversions.filter_popup.affiliate') }}</span>
          <el-select
            ref="affiliateSelect"
            v-model="currentFilters.affiliate_id"
            value-key="id"
            :placeholder="$t('payments.cpa_conversions.filter_popup.affiliate')"
            class="select"
            :remote-method="searchMethod"
            remote
            :loading="loading"
            filterable
            @visible-change="handleToggleSelect"
            @change="handleChangeAffiliateId"
          >
            <el-option
              v-for="item in affiliateOptions"
              :key="item.id"
              :label="item.email"
              :value="item.id"
            />
          </el-select>
        </div>

        <div class="filter-row">
          <span class="label">{{ $t('payments.cpa_conversions.filter_popup.offer') }}</span>
          <el-select
            ref="offerSelect"
            v-model="currentFilters.affiliate_offer_id"
            value-key="id"
            :placeholder="$t('payments.cpa_conversions.filter_popup.offer')"
            class="select"
            :loading="loading"
            :disabled="!currentFilters.affiliate_id"
            filterable
          >
            <el-option
              v-for="item in affiliateOffersOptions"
              :key="item.id"
              :label="item.full_name"
              :value="item.id"
            />
          </el-select>
        </div>

        <div class="filter-row">
          <span class="label">{{ $t('payments.cpa_conversions.filter_popup.status') }}</span>
          <ui-select
            v-model="currentFilters.status"
            :placeholder="$t('payments.cpa_conversions.filter_popup.status')"
            :options="statuses"
            :multiple="true"
            value-prop="value"
            label-prop="label"
            class="filter_type"
            :max-count-tags="1"
          />
        </div>

        <div class="controls">
          <ui-button
            color="red"
            lib="fa"
            substyle="fas"
            icon="times"
            class="btn"
            @click="resetFilters"
          >
            {{ $t('ui.table.reset') }}
          </ui-button>
          <ui-button
            filled
            :disabled="isDisabledGroup"
            lib="fa"
            substyle="fas"
            color="green"
            icon="check"
            class="btn"
            @click="applyFilters"
          >
            {{ $t('ui.table.apply') }}
          </ui-button>
        </div>
      </div>
    </transition>
    <span
      v-if="hasValue"
      class="reset_link"
      @click="resetFilters"
    >
      {{ $t('ui.filters.inner.reset_filter') }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import hash from 'object-hash';
import errorHandleMixin from '@/views/mixins/error-hadle';

const filterFn = v => v !== undefined && v !== '' && v !== null && v.length !== 0;

export default {
  name: 'CpaConversionsFilter',
  mixins: [
    errorHandleMixin,
  ],
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      currentFilters: {},
      affiliateOptions: [],
      affiliateOffersOptions: [],
      filtersOpen: false,
      oldHash: null,
      newHash: null,
    };
  },
  computed: {
    ...mapGetters({
      brands: 'misc/brands',
    }),
    statuses() {
      return [
        {
          value: 'pending_qualification',
          label: this.$t('payments.cpa_conversions.statuses.pending_qualification'),
        },
        {
          value: 'in_hold',
          label: this.$t('payments.cpa_conversions.statuses.in_hold'),
        },
        {
          value: 'on_review',
          label: this.$t('payments.cpa_conversions.statuses.on_review'),
        },
        {
          value: 'approved',
          label: this.$t('payments.cpa_conversions.statuses.approved'),
        },
        {
          value: 'declined',
          label: this.$t('payments.cpa_conversions.statuses.declined'),
        },
      ];
    },
    hasFilters() {
      return !this.$_.isEmpty(this.$_.pickBy(this.currentFilters, filterFn));
    },
    hasValue() {
      return !this.$_.isEmpty(this.$_.pickBy(this.value, filterFn));
    },
    isDisabledGroup() {
      return this.oldHash === this.newHash;
    },
    ...mapGetters('misc', ['countries']),
  },
  watch: {
    'currentFilters.affiliate_id': {
      handler(val) {
        if (val) {
          this.fetchAffiliateOffers(val);
        }
      },
    },
    currentFilters: {
      deep: true,
      handler(value) {
        this.newHash = hash(this.$_.pickBy(value, filterFn), { algorithm: 'md5' });
      },
    },
  },
  destroyed() {
    document.removeEventListener('click', this.clickOutside, true);
  },
  created() {
    this.currentFilters = this.$_.cloneDeep(this.value);
  },
  mounted() {
    document.addEventListener('click', this.clickOutside, true);
    this.getAffiliates('', { isInit: true });
  },
  methods: {
    handleChangeAffiliateId() {
      this.currentFilters = this.$_.omit(this.currentFilters, 'affiliate_offer_id');
    },
    fetchAffiliateOffers(id) {
      const queryParams = {
        sort_column: 'incrementing_id',
        sort_dir: 'asc',
      };

      this.$api.getAffiliateOffers(id, queryParams).then((res) => {
        this.affiliateOffersOptions = res.data.payload;
      });
    },
    handleToggleSelect(v) {
      if (v) {
        this.loading = true;
        this.getAffiliates('').then(() => {
          this.loading = false;
        });
      }
    },
    searchMethod(v) {
      this.$_.debounce(this.getAffiliates, 800)(v);
    },
    async getAffiliates(search, { isInit } = {}) {
      const query = {
        limit: 100,
        offset: 0,
        search,
        sort_column: 'email',
        sort_dir: 'asc',
      };

      let affiliates = [];

      if (isInit && this.currentFilters.affiliate_id) {
        const { data: { payload } } = await this.$api.getAffiliateInfo(this.currentFilters.affiliate_id);
        affiliates = [payload];
      }

      return new Promise((resolve, reject) => {
        this.$api.getAffiliates(query)
          .then((response) => {
            this.affiliateOptions = [...affiliates, ...response.data.payload];
            resolve([...affiliates, ...response.data.payload]);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBrandImgUrl() {
      const brandFavicon = this.currentFilters.site_id ? this.brands.filter(brand => brand.id === this.currentFilters.site_id)[0].favicon_base64 : '';
      return brandFavicon !== undefined ? brandFavicon : false;
    },
    clickOutside(e) {
      if (this.$refs.mainBtn.$el?.contains(e.target)) return;
      const filters = [
        this.$refs.popup,
        this.$refs.brandSelect.$children[1].$el,
        this.$refs.affiliateSelect.$children[1].$el,
        this.$refs.offerSelect.$children[1].$el,
      ].filter(el => !!el);

      if (this.filtersOpen === true && !filters.some(el => e.target && el?.contains(e.target))) {
        this.toggleFilters();
      }
    },
    toggleFilters() {
      this.filtersOpen = !this.filtersOpen;
      this.currentFilters = this.$_.cloneDeep(this.value);

      if (this.filtersOpen) {
        this.oldHash = hash(this.$_.pickBy(this.value, filterFn), { algorithm: 'md5' });
      }

      this.$emit('toggle', this.filtersOpen);
    },
    applyFilters() {
      this.$emit('submit', this.currentFilters);
      this.filtersOpen = false;
    },
    resetFilters() {
      this.currentFilters = {};
      this.$emit('submit', this.currentFilters);
      this.filtersOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-filter {
  position: relative;
  line-height: 0;

  .pop {
    position: absolute;
    width: 476px;
    z-index: 9999;
    top: 44px;
    left: -20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    user-select: none;

    .ui-select.filter_type.medium {
      width: 200px !important;
      flex: 0 !important;

      /deep/ {
        .control-wrapper {
          width: 200px;
          background-image: linear-gradient(180deg, #ffffff 0%, #f6f6f6 100%);

          .dd-icon {
            font-weight: 700 !important;
            font-size: 13px;
          }

          > .tags {
            height: 28px;

            .value .label {
              max-width: 132px;
            }
          }
        }

        .placeholder {
          color: #606266;
          opacity: 0.5;
          padding-left: 4px;
        }
      }
    }

    &.fade-enter-active,
    &.fade-leave-active {
      transition: all 0.4s;
    }

    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
      transform: translateY(8px);
    }

    .arrow {
      position: absolute;
      top: -5px;
      left: 56px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
    }
    .filter-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 54px;
      border-bottom: 1px solid #eaeaea;

      > * {
        flex: 1;
      }

      > div {
        justify-content: flex-end;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .label {
        font-size: 14px;
        font-weight: 500;
        color: #303634;
      }

      .filter_type {
        width: 200px;
        margin-left: 8px !important;
      }
      .btn {
        margin-left: 0 !important;
      }
    }
    .controls {
      display: inline-flex;
      justify-content: flex-end;
      flex-wrap: nowrap;
      width: auto;
      margin-top: 16px;

      .btn {
        margin-left: 0 !important;
      }
      .btn + .btn {
        margin-left: 8px !important;
      }
    }
  }

  .select {
    display: flex;
  }

  /deep/ {
    .el-select .el-input {
      width: 200px;

      .el-input__inner {
        height: 30px!important;
      }
    }

    .el-select .el-input .el-select__caret{
      color: #7d7d7d;
      font-family: "Font Awesome 6 Pro" !important;

      &::before{
        position: relative;
        top: -1px;
        content: "\f106";
      }
      &.is-reverse::before{
        top: -3px;
      }
    }
  }
}
</style>
