<template functional>
  <div
    class="crm-panel-header-title"
    :class="{
      'crm-panel-header-title_type-default': props.type === 'default',
      'crm-panel-header-title_type-compact': props.type === 'compact',
      [data.staticClass]: data.staticClass,
    }"
    :style="data.staticStyle || ''"
    v-bind="data.attrs"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
  },
};
</script>

<style lang="scss">
.crm-panel-header-title {
  display: inline-block;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--crm-panel-text);

  &_type-default {
    font-size: 20px;
  }

  &_type-compact {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
