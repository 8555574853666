<template>
  <div class="blocks-right">
    <div class="title-wrapper">
      <div class="title">
        <span
          v-if="team.name"
          class="ma-0"
        >
          {{ team.name }}
        </span>
        <span
          v-else
          class="ma-0"
        >
          {{ $t('teams.teams_fields.default_team') }}
        </span>
        <span
          v-if="team.name"
          class="action"
          @click="editTeamHandler"
        >
          <ui-icon
            name="pen"
            color="#20815e"
            lib="fa"
          />
        </span>
        <span
          v-if="team.name && team.type !== 'owners'"
          class="action"
          @click="deleteTeamHandler"
        >
          <ui-icon
            name="trash-alt"
            :color="$theme.dangerColor"
            lib="fa"
          />
        </span>
      </div>
      <div
        v-if="$route.matched[3].meta.view === 'permissions' && !notEdit"
        class="buttons"
      >
        <div class="buttons-wrapper">
          <div class="text">
            {{ $t('permissions.save_changes', {teamName: team.name }) }}
          </div>
          <ui-button
            color="red"
            class="btn-red"
            icon="times"
            lib="fa"
            substyle="fas"
            @click="cancelChanges"
          >
            {{ $t(`team_popup.cancel_button`) }}
          </ui-button>
          <ui-button
            color="green"
            class="btn action-btn"
            lib="fa"
            filled
            substyle="fas"
            icon="check"
            @click="saveChanges"
          >
            {{ $t(`team_popup.save_button`) }}
          </ui-button>
        </div>
      </div>
    </div>
    <div class="sub-nav">
      <nav class="menu">
        <router-link
          v-for="item in $_.filter(nav, e => team.name && team.type !== 'owners' || e.name !== 'permissions')"
          :key="item.name"
          :class="{active : isLinkActive(item.name)}"
          :to="item.link"
          class="item"
        >
          <span
            :title="$t(`teams.sub_nav.${item.name}`)"
            class="link-title"
          >
            {{ $t(`teams.sub_nav.${item.name}`) }}
          </span>
        </router-link>
      </nav>
    </div>
    <router-view
      :team-info="team"
      :permissions="permissions"
      :edit.sync="notEdit"
      class="view"
      @refresh="refresh()"
      @refresh-users-list="refreshList()"
    />
  </div>
</template>

<script>
import app from '@/main';

export default {

  props: {
    permissions: {
      type: Object,
      default() {
        return {
          affiliates_types_edit: [],
          affiliates_types_view: [],
          players_available_properties: [],
          reports_affiliates_types: [],
        };
      },
    },
    team: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isDataLoading: false,
      isPopupOpen: false,
      isActiveTeam: 0,
      isConfirmOpen: false,

      notEdit: true,

      dataTeam: {},

      confirmMsg: '',
      confirmAction: '',
      confirmTitle: '',
      confirmType: '',
      actionName: '',
      actionFaIcon: '',
      actionIcon: '',
      currentTab: '',
      nav: [
        {
          name: 'users',
          link: 'users',
        },
        {
          name: 'permissions',
          link: 'permissions',
        },
      ],
      id: '',
    };
  },

  watch: {
    $route() {
      this.currentTab = this.$route.matched[3].meta.view;
    },
  },
  mounted() {
    this.currentTab = this.$route.matched[3].meta.view;
  },

  methods: {

    isLinkActive(name) {
      return this.currentTab === name;
    },

    saveChanges() {
      app.$eventBus.$emit('save-changes');
    },
    cancelChanges() {
      app.$eventBus.$emit('cancel-changes');
    },

    refreshList() {
      this.$emit('refresh-users-list');
    },
    refresh() {
      this.$emit('refresh');
    },

    editTeamHandler() {
      this.$emit('open-edit');
    },

    deleteTeamHandler() {
      this.$emit('open-delete');
    },
  },
};
</script>

<style lang="scss" scoped>
  .teams-list {
    .wrapper {
      width: 90%;
      min-width: 1140px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
</style>
