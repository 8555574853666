<template>
  <div class="dasboard-filter">
    <adm-ui-header class="dasboard-filter__title" tag="h2">
      {{ $t('dashboards.dashboards_filters.series') }}
    </adm-ui-header>

    <adm-ui-wall
      v-for="(currSeries, index) in currentSeries"
      :key="index"
      class="dasboard-filter__wrap"
    >
      <div class="dasboard-filter__name-wrap">
        <ui-input
          :label="$t('dashboards.dashboards_filters.label_series')"
          autosize
          size="big"
          :placeholder="$t('dashboards.dashboards_filters.series_placeholder')"
          class="form-input"
          type="text"
          :value="currSeries.name"
          @input="setNameSeriesMain($event, index)"
        />

        <div class="dasboard-filter__select-metric-wr">
          <select-metrics
            :label="$t('dashboards.popup_column.metrics')"
            :value="currSeries.metric_name || ''"
            :prop-currency-code="propCurrencyCode"
            @change="handlerSelectMetric($event, index)"
          />

          <adm-ui-controls-widget :data="controlsData(index)" />
        </div>
      </div>

      <div class="dasboard-filter__select-filter">
        <ui-filter-select
          ref="filter"
          :key="index"
          v-model="filters[index]"
          :use-filters="filtersItems"
          @input="handlerSelectFilter($event, index)"
          @isDisableSelectFilter="isDisableSelectFilter"
        />
      </div>
      <hr>

      <filter-tabs
        :key="index"
        :filter-list="defaultFilterList"
        :current-search="
          currSeries.filters !== null ? currSeries.filters.search || {} : {}
        "
        :reports-settings="reportsSettings"
        :search-by-list="searchByList"
        :is-searching-filter="isSearchingFilter(index)"
        @deleteCurrentSearchGroup="clearReportsSearchFilter($event, index)"
        @selectedSearchFilter="selectedSearchFilter($event, index)"
      />
    </adm-ui-wall>
    <div
      class="
        dashboard-overview__metric-card-wrap
        dashboard-overview__metric-card-wrap--add
      "
      :class="{'disabled': disabled}"
    >
      <div
        v-if="currentSeries.length < 20"
        class="dashboard-overview__metric-card-add"
        :class="{'disabled': disabled}"
        @click="addSeries"
      >
        <adm-ui-icon :icon="['fas', 'fa-plus']" />
        <adm-ui-header tag="h3">
          {{ $t('dashboards.dashboards_filters.add_series') }}
        </adm-ui-header>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import FilterTabs from '@/components/FilterTabs.vue';
import SelectMetrics from './SelectMetrics.vue';
import {
  ADD_DASHBOARD_IS_LOADING,
} from '@/store/action_types/dashboards';
import formatCurrency from '@/views/mixins/format-currency';

export default {
  name: 'DashboardFilter',
  components: {
    FilterTabs,
    SelectMetrics,
  },
  mixins: [formatCurrency],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    series: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      selectedMetric: '',
      filters: this.computedSeriesFilter() || {},
      defaultFilterList: {
        site_player_id: {
          icon: 'id-badge',
          order: 1,
        },
        affiliate_email: {
          icon: 'user',
          order: 2,
        },
        sub_affiliate_email: {
          icon: 'users',
          order: 3,
        },
        referrer_domain: {
          icon: 'globe',
          order: 4,
        },
      },
      isOpenOtherPopup: false,
      currentSeries: this.$_.cloneDeep(this.series),
      defaultSerie: {
        name: this.$t('dashboards.dashboards_filters.new_series'),
        y_axis: 'left',
        filters: {},
      },

      filtersSeriesSelect: this.initfilterSelect() || [],
      currentSearch: this.ifEditCurrentSerach() || [{}],
    };
  },

  computed: {
    ...mapGetters('reports', ['reportsSettings']),

    filtersItems() {
      const val = [
        'trafficType',
        'playerFilter',
        'playerCountryNameFilter',
        'playerRegionNameFilter',
        'trafficRegionNameFilter',
      ];
      return val;
    },

    searchByList() {
      return this.reportsSettings.dimensions.reduce((acc, el) => {
        if (this.defaultFilterList.hasOwnProperty(el.column_name)) {
          acc[el.column_name] = this.defaultFilterList[el.column_name];
        }
        return acc;
      }, {});
    },

    filterSeries() {
      const filters = this.currentSeries.map((series, index) => ({
        ...this.filtersSeriesSelect[index],
        search: {
          ...this.currentSearch[index],
        },
      }));
      return filters;
    },
  },

  methods: {
    handlerSelectMetric(value, index) {
      this.$set(this.currentSeries[index], 'metric_name', value);
      this.$emit('updateSeries', this.currentSeries);
    },

    isDisableSelectFilter(v) {
      this.$emit('isDisableSelectFilter', v);
    },

    initfilterSelect() {
      return this.series.map(s => this.$_.omit(s.filters, ['search']));
    },

    computedSeriesFilter() {
      return {
        ...this.series.map(s => this.decodeFilterSelect(s.filters)),
      };
    },

    ifEditCurrentSerach() {
      const res = (this.series || []).map((s) => {
        if (s.filters?.search) {
          return s.filters.search;
        }
        return {};
      });
      return res;
    },

    decodeFilterSelect(dataApiFilter) {
      const noParse = ['traffic_type', 'players_filter'];
      const filterSelect = this.$_.cloneDeep(dataApiFilter);
      delete filterSelect.search;
      const valueDecoding = this.$_.cloneDeep(filterSelect);

      const keyFilters = Object.keys(valueDecoding.filters || {});
      const reducedObj = keyFilters.reduce((acc, val) => {
        acc[val] = valueDecoding.filters[val];
        return acc;
      }, {});
      const result = { ...reducedObj, ...valueDecoding };
      delete result.filters;

      noParse.forEach((elParse) => {
        if (Object.keys(result).includes(elParse)) {
          this.$set(result, elParse, {
            value: result[elParse],
            op: null,
          });
        }
      });

      return result;
    },

    setNameSeriesMain(name, index) {
      this.$store.commit(`dashboards/${ADD_DASHBOARD_IS_LOADING}`, true);
      this.setNameSeries(name, index);
    },

    setNameSeries: _.debounce(function setNameSeries(name, index) {
      this.$set(this.currentSeries[index], 'name', name);
      this.$emit('updateSeries', this.currentSeries);
    }, 700),

    isSearchingFilter(index) {
      const filtersList = Object.keys(this.searchByList);
      return filtersList.reduce((acc, elem) => {
        acc[elem] = this.$_.has(this.currentSearch[index], elem);
        return acc;
      }, {});
    },

    setFilters() {
      this.currentSeries = this.currentSeries.map((series, index) => {
        this.$set(series, 'filters', this.filterSeries[index]);
        return series;
      });
      this.$emit('updateSeries', this.currentSeries);
    },

    handlerSelectFilter(arg, index) {
      const noParse = ['traffic_type', 'players_filter'];
      const allKeyFilters = Object.keys(arg);

      const parseFilter = allKeyFilters.reduce(
        (acc, val) => {
          if (noParse.includes(val)) {
            acc[val] = arg[val].value;
            return acc;
          }
          acc.filters[val] = {
            op: arg[val].op || '=',
            value: arg[val].value,
          };
          return acc;
        },
        { filters: {} },
      );

      this.$set(this.filtersSeriesSelect, index, parseFilter);

      this.setFilters();
    },

    selectedSearchFilter(search, index) {
      this.$set(this.currentSearch, index, this.$_.cloneDeep(search));
      this.currentSearch = this.$_.cloneDeep(this.currentSearch);
      this.setFilters();
    },

    clearReportsSearchFilter(group, index) {
      this.$delete(this.currentSearch[index], group);
      this.setFilters();
    },

    controlsData(index) {
      const maxSeries = 20;
      return [
        {
          title: this.$t('dashboards.dashboards_filters.tooltip_copy'),
          type: 'primary',
          icon: 'far fa-clone',
          disabled: this.currentSeries.length >= maxSeries || this.disabled,
          clickHandler: () => {
            const cloneObj = this.$_.cloneDeep(this.currentSeries[index]);
            const currSearch = this.$_.cloneDeep(this.currentSearch[index]);
            const filterCloneObj = this.$_.cloneDeep(this.filters[`${index}`]);
            this.currentSeries.push(cloneObj);
            this.currentSearch.push(currSearch);

            this.$set(
              this.filters,
              `${this.currentSeries.length - 1}`,
              filterCloneObj,
            );
            this.$emit('updateSeries', this.currentSeries);
            this.currentSeries = this.$_.cloneDeep(this.currentSeries);
          },
        },
        {
          title: this.$t('dashboards.dashboards_filters.tooltip_delete'),
          type: 'danger',
          icon: 'fas fa-trash-alt',
          disabled: this.currentSeries.length <= 1 || this.disabled,
          clickHandler: () => {
            this.$delete(this.currentSeries, index);
            this.$delete(this.currentSearch, index);
            this.$delete(this.filters, `${index}`);
            this.$emit('updateSeries', this.currentSeries);
          },
        },
      ];
    },

    addSeries() {
      this.$set(
        this.currentSeries,
        this.currentSeries.length,
        this.$_.cloneDeep(this.defaultSerie),
      );
      this.$emit('updateSeries', this.currentSeries);
    },
  },
};
</script>
