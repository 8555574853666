// live views
import Live from '@/views/Live.vue';

import LastBets from '@/views/live/LastBets.vue';
import TopEvents from '@/views/live/TopEvents.vue';

export default [
  {
    path: '/live',
    component: Live,
    meta: {
      requiresAuth: true,
      view: 'live',
    },
    children: [
      {
        path: 'last-bets',
        component: LastBets,
        name: 'last-bets',
        meta: {
          view: 'last-bets',
        },
      },
      {
        path: 'top-events',
        component: TopEvents,
        name: 'top-events',
        meta: {
          view: 'top-events',
        },
      },
      {
        path: '',
        redirect: 'last-bets',
      },
      {
        path: '*',
        redirect: 'last-bets',
      },
    ],
  },
];
