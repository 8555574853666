<template>
  <div
    v-if="isOpen"
    class="init-commands-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">{{ $t(`domainManagement.popup.initCommands`) }}</span>
        <ui-icon
          :size="20"
          class="close"
          lib="fa"
          substyle="fas"
          name="times"
          @click.native="close"
        />
      </div>
      <div class="form full-width">
        <div
          v-for="(initCommand, index) in initCommands"
          :key="index"
          class="row"
        >
          <div class="field">
            <span class="label-text">{{ $t(`domainManagement.popup.step`) }} {{ index + 1 }}</span>
            <ui-button
              v-clipboard:copy="initCommands[index]"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              filled
              lib="fa"
              substyle="fas"
              icon="copy"
              size="medium"
              class="copy-btn"
            />
            <el-input
              :value="initCommand"
              readonly
              :rows="5"
              type="textarea"
              class="init-commands"
            />
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          size="big"
          @click="close"
        >
          {{ $t(`domainManagement.popup.done`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
export default {
  name: 'PlatformServerInitCommandsPopup',

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    initCommands: {
      type: String,
      required: true,
    },
  },

  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },

  destroyed() {
    window.removeEventListener('keyup', this.keyupHandler);
  },

  methods: {
    close() {
      this.$emit('close');
    },

    onCopy() {
      this.$noty.info(this.$t('domainManagement.messages.copied_to_clipboard'));
    },

    onError() {
      this.$noty.error(this.$t('domainManagement.messages.copy_error'));
    },

    keyupHandler(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.init-commands-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .cancel-button {
    margin-right: 8px;
  }

  .copy-btn {
    position: absolute;
    right: 6px;
    top: 28px;
    z-index: 10;
  }

  .popup {
    position: absolute;
    z-index: 902;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid #eaeaea;
      padding: 16px 24px;
      box-sizing: border-box;
      margin-bottom: 0;

      .name {
        font-size: 20px;
        font-weight: 500;
      }

      .close {
        color: #303634;
        padding: 5px;
        font-size: 32px;
        cursor: pointer;
      }
    }
    .form {
      padding: 16px 24px;
      box-sizing: border-box;

      .field {
        position: relative;
      }
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      margin-top: 0;
    }
  }

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
</style>

<style lang="scss">
  .init-commands {
    .el-textarea__inner {
      background-color: #f4f7fa;
    }
  }
</style>
