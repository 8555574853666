<template>
  <div class="service-domains">
    <ServiceDomainPopup
      v-if="canEdit"
      ref="serviceDomainPopup"
      :is-open="isOpenPopup"
      :inital-data="editableRow"
      :errors="errors"
      @close="closePopup"
      @create="createServiceDomain"
      @update="updateSeviceDomain"
    />
    <BlockedCountriesPopup
      ref="blockedCountriesPopup"
      :can-edit="canEdit"
      @submit="getServiceDomains({ isSilent: true })"
    />

    <ui-confirm
      ref="confirm"
      :width="480"
      :type="'warning'"
      :action-name="$t('domainManagement.buttons.delete')"
      :action-fa-icon="'trash-alt'"
      :data="confirmData"
      @save="deleteHandler"
      @close="confirmClose"
    >
      <div
        slot="title"
        v-html="$t('domainManagement.buttons.delete')"
      />
      <div class="body__wrap">
        <i
          class="fas fa-trash-alt warning"
        />
        <div
          class="body__msg"
          v-html="$t('domainManagement.messages.delete', { name: confirmData.domain_name })"
        />
      </div>
    </ui-confirm>

    <div class="toolbar">
      <div class="wrapper">
        <div class="section">
          <span class="title">{{ $t('domainManagement.pageTitle.serviceDomains') }}</span>
          <ui-input
            v-model.trim="search"
            class="service-domains-search"
            :width="180"
            :is-search="true"
            @search="getServiceDomains"
          />
          <div class="filters">
            <ui-filter
              ref="filter"
              v-model="allowedFilters"
              :use-filters="availableFilters"
              class="btn ui-filter-new"
              @input="getServiceDomains"
            />
            <span
              v-if="Object.keys(filters).length !== 0"
              class="reset_link"
              @click="$refs.filter.reset()"
            >{{ $t('ui.filters.inner.reset_filter') }}</span>
          </div>
        </div>
        <div class="section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="paginationHandler"
            />
          </div>
          <ui-button
            v-if="canEdit"
            color="green"
            lib="fa"
            substyle="fas"
            filled
            icon="plus"
            class="btn btn-add"
            @click="openPopup"
          >
            {{ $t('domainManagement.buttons.add_service_domain') }}
          </ui-button>
        </div>
      </div>
    </div>
    <ui-table
      v-loading="isDataLoading"
      :lazy-loading-delay="4000"
      :fields="fields"
      :data="data"
      :rows-count="limit"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      lazy-loading
      class="table"
      i18n-path="domainManagement"
      element-loading-custom-class="data-loader-spinner"
      @sort="sortingHandler"
    >
      <template
        v-if="canEdit || canRunJob"
        slot="append"
      >
        <el-table-column width="100">
          <template slot-scope="scope">
            <ActionIcon
              :tooltip="$t('domainManagement.buttons.recheck')"
            >
              <el-link
                v-if="canRunJob"
                class="action-ico"
                @click="reCheckHandler(scope.row)"
              >
                <ui-icon
                  name="history"
                  color="#3b94e3"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
            <ActionIcon
              :tooltip="$t('domainManagement.buttons.edit')"
            >
              <el-link
                v-if="canEdit"
                :disabled="scope.row.setup_mode === 'manual'"
                class="action-ico"
                type="success"
                @click="editHandler(scope.row)"
              >
                <ui-icon
                  name="pen"
                  :color="$theme.accentColor"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
            <ActionIcon
              :tooltip="$t('domainManagement.buttons.delete')"
            >
              <el-link
                v-if="canEdit"
                :disabled="scope.row.used_in_groups_count > 0"
                class="action-ico"
                type="danger"
                @click="confirmOpen(scope.row)"
              >
                <ui-icon
                  name="trash-alt"
                  :color="$theme.dangerColor"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>
import { pageSizeMixin, resolvePageSize } from '../../service/pageSize';
import queryParamsMixin from '../mixins/query-params';

import ServiceDomainPopup from '@/components/Popups/ServiceDomainPopup';
import BlockedCountriesPopup from './components/BlockedCountriesPopup';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'service-domains/list/limit'; // for localstorage
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

const filters = [
  { name: 'nsManagement', queryParam: 'ns_management' },
  { name: 'dnsManagement', queryParam: 'dns_management' },
  { name: 'sslIssuance', queryParam: 'ssl_issuance' },
  { name: 'usedInAnyGroup', queryParam: 'used_in_any_group' },
  { name: 'blockedInAnyCountry', queryParam: 'blocked_in_any_country' },
  { name: 'platformServiceOrigin', queryParam: 'platform_service_origin_id' },
  { name: 'platformServer', permission: 'platform_servers_view', queryParam: 'platform_server_id' },
  { name: 'domainRegistrarAccount', permission: 'domain_registrar_accounts_view', queryParam: 'domain_registrar_account_id' },
  { name: 'dnsHostingAccount', permission: 'dns_hosting_accounts_view', queryParam: 'dns_hosting_account_id' },
];

export default {
  name: 'ServiceDomains',

  components: {
    ServiceDomainPopup,
    BlockedCountriesPopup,
  },

  mixins: [pageSizeMixin, queryParamsMixin],

  data() {
    return {
      isDataLoading: false,
      isOpenPopup: false,
      errors: {},
      search: '',
      count: 0,
      page: 1,
      limit: pageSize,
      sortProp: 'incrementing_id',
      sortOrder: 'desc',

      data: [],
      fields: [
        {
          name: 'incrementing_id',
          sortable: 'custom',
          align: 'left',
          width: '60',
        },
        {
          name: 'domain_name',
          sortable: 'custom',
          minWidth: '100',
        },
        {
          name: 'setup_mode',
          renderValue: value => this.$t(`domainManagement.popup.form.setup_mode.${value}`),
        },
        {
          name: 'platform_service_origin_name',
          sortable: 'custom',
        },
        {
          name: 'platform_server_name',
          sortable: 'custom',
        },
        {
          name: 'used_in_groups_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
        },
        {
          name: 'blocked_countries_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
          click: this.blockedCountriesRender,
          class: 'cursor-pointer positive',
        },
        {
          name: 'domain_registrar_account_name',
          sortable: 'custom',
          computedValue: row => row.domain_registrar_account_name || '—',
        },
        {
          name: 'dns_hosting_account_name',
          sortable: 'custom',
          computedValue: row => row.dns_hosting_account_name || '—',
        },
        {
          name: 'ns_records_state',
          sortable: 'custom',
          computedValue: row => this.$t(`domainManagement.fields.ns_records_state.values.${row.ns_records_state}`),
          computedClass: value => `row-value--${value}`,
        },
        {
          name: 'dns_records_state',
          sortable: 'custom',
          computedValue: row => this.$t(`domainManagement.fields.dns_records_state.values.${row.dns_records_state}`),
          computedClass: value => `row-value--${value}`,
        },
        {
          name: 'ssl_certificate_state',
          sortable: 'custom',
          computedValue: row => this.$t(`domainManagement.fields.ssl_certificate_state.values.${row.ssl_certificate_state}`),
          computedClass: value => `row-value--${value}`,
        },
        {
          name: 'traffic_last_30_days_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
        },
        {
          name: 'traffic_last_7_days_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
        },
        {
          name: 'traffic_last_day_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
        },
        {
          name: 'created_at',
          sortable: 'custom',
          width: '120',
          format: 'date-time',
        },
      ],

      editableRow: undefined,
      editableRowId: undefined,

      filters: {},

      confirmData: {},
    };
  },

  computed: {
    isSuperUser() {
      return this.$store.getters['auth/adminAcl'].is_superuser;
    },

    canEdit() {
      return this.isSuperUser || this.$store.getters['auth/currentAcl'].platform_service_domains_edit === 'allow';
    },

    canRunJob() {
      return this.isSuperUser || this.$store.getters['auth/currentAcl'].platform_service_domains_can_run_jobs === 'allow';
    },

    availableFilters() {
      const availableFilters = filters
        .filter(filter => !filter.permission || this.isSuperUser || this.$store.getters['auth/currentAcl'][filter.permission] === 'allow')
        .map(filter => filter.name);

      return availableFilters;
    },

    allowedFilters: {
      get() {
        return this.availableFilters.reduce((acc, filterName) => {
          const [filter] = filters.filter(filterItem => filterItem.name === filterName);
          if (this.filters[filter.queryParam] !== undefined) {
            acc[filter.queryParam] = this.filters[filter.queryParam];
          }
          return acc;
        }, {});
      },
      set(value) {
        this.filters = value;
      },
    },

    queryParams() {
      return {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        search: this.search || null,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        ...this.allowedFilters,
      };
    },
  },

  methods: {
    // For query-params mixin (auto-change url query)
    fetch() {
      return this.getServiceDomains();
    },
    blockedCountriesRender(value) {
      this.$refs.blockedCountriesPopup.open(value.id);
    },

    async getServiceDomains({ isSilent = false } = {}) {
      if (!isSilent) {
        this.isDataLoading = true;
      }

      const { data: { payload, misc } } = await this.$api.getServiceDomains(this.queryParams);

      this.count = misc.count;
      this.data = payload;

      this.isDataLoading = false;
    },

    paginationHandler(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;

        this.getServiceDomains();
      }
    },

    sortingHandler({ prop, order }) {
      if (order === '') return;

      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }

      this.getServiceDomains();
    },

    closePopup() {
      this.isOpenPopup = false;
      this.editableRow = undefined;
      this.errors = {};
    },

    openPopup() {
      this.isOpenPopup = true;
    },

    async createServiceDomain(data) {
      await this.$api.createServiceDomain(data).catch((e) => {
        this.errors = e.data.errors;
        throw e;
      });
      await this.getServiceDomains();
      this.$refs.serviceDomainPopup.close();
    },

    async updateSeviceDomain(data) {
      await this.$api.updateServiceDomain(this.editableRowId, data).catch((e) => {
        this.errors = e.data.errors;
        throw e;
      });
      await this.getServiceDomains();
      this.$refs.serviceDomainPopup.close();
      this.editableRowId = undefined;
    },

    async deleteHandler(action, row) {
      this.$refs.confirm.close();
      await this.$api.deleteServiceDomain(row.id);
      await this.getServiceDomains();
    },

    async editHandler(row) {
      const { data: { payload } } = await this.$api.getServiceDomainById(row.id);
      this.editableRow = payload;
      this.editableRowId = row.id;

      this.openPopup();
    },

    async reCheckHandler(row) {
      const { status } = await this.$api.reCheckServiceDomain(row.id);
      if (status === 204) {
        this.$noty.info(this.$t('domainManagement.messages.success'));
        await this.getServiceDomains();
      } else {
        this.$noty.error(this.$t('domainManagement.messages.failed'));
      }
    },

    confirmOpen(row) {
      this.confirmData = row;
      this.$refs.confirm.open();
    },

    confirmClose() {
      this.confirmData = {};
    },
  },
};
</script>
