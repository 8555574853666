import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currencies: 'misc/currencies',
    }),
  },
  methods: {
    getCurrencyLabelByField(field) {
      if (field?.currencyKey) {
        return '';
      }

      if (this.currency && [
        'currency',
        'formatMoney',
        '-formatMoney',
        'ncurrency',
        '-ncurrency',
      ].includes(field?.format)) {
        const currency = this.currencies.find((el => el.code === this.currency));

        return `, ${currency?.symbol || this.currency}`;
      }

      return '';
    },
    renderHeader(h, { column }) {
      const findFieldCol = this.fields.find(el => el.name === column.property);
      const currencyLabel = this.getCurrencyLabelByField(findFieldCol);
      const filterable = findFieldCol !== undefined ? (findFieldCol.filterable || false) : false;
      const allowedOperators = findFieldCol !== undefined ? (findFieldCol.allowedOperators || ['<', '=', '>']) : ['<', '=', '>'];
      const filterInputType = findFieldCol !== undefined ? (findFieldCol.filterInputType || 'text') : 'text';

      const operators = Array.isArray(allowedOperators) ? allowedOperators.map(allowedOperator => h(
        'el-radio-button',
        {
          props: {
            label: allowedOperator,
          },
        },
        allowedOperator,
      )) : [];

      const _self = this;
      return h(
        'div',
        {
          class: [
            'custom-header',
            _self.customSorting && column.property === _self.sortProp
              ? _self.sortOrder
              : '',
            column.sortable === false ? '' : 'no-sort',
          ],
          on: {
            click(/* event */) {
              if (filterable) {
                if (_self.lastOpenFilter) {
                  _self.columnFilters[_self.lastOpenFilter].visible = false;
                  _self.lastOpenFilter = '';
                }
              }
              _self.handleSort(
                {
                  prop: column.property,
                  order: _self.sortOrder === 'asc' ? 'desc' : 'asc',
                },
                column.id,
              );
            },
          },
        },
        [
          h('div', { class: 'text' }, [
            h('span', { class: 'title' }, `${column.label}${currencyLabel}`),
          ]),
          _self.customSorting
            ? h(
              'span',
              {
                class: 'custom-sorting',
                slot: 'reference',
              },
              [
                h('ui-icon', {
                  props: {
                    lib: 'fa',
                    substyle: 'fas',
                    name: 'caret-down',
                    size: 16,
                    color: '#ffffff',
                  },
                  style: { transform: 'rotate(180deg) scaleY(0.9)' },
                  class: 'asc',
                }),
                h('ui-icon', {
                  props: {
                    lib: 'fa',
                    substyle: 'fas',
                    name: 'caret-down',
                    size: 16,
                    color: '#ffffff',
                  },
                  style: { marginTop: '-10px', transform: 'scaleY(0.9)' },
                  class: 'desc',
                }),
              ],
            )
            : '',
          h(
            'span',
            {
              class: {
                'filters-wrapper': true,
                'open-filters':
                  this.columnFilters[column.property]
                  && (this.lastOpenFilter === column.property
                    || ((this.columnFilters[column.property].value || this.columnFilters[column.property].dateRange !== null)
                    && this.columnFilters[column.property].op)),
              },
            },
            [
              filterable && this.isFiltersInit
                ? h(
                  'el-popover',
                  {
                    class: 'col-filter',
                    props: {
                      trigger: 'manual',
                      value: this.columnFilters[column.property].visible,
                    },
                  },
                  [
                    h(
                      'div',
                      {
                        class: 'col-filter-wrapper',
                      },
                      [
                        h(
                          'el-radio-group',
                          {
                            props: {
                              value: this.columnFilters[column.property].op,
                            },
                            on: {
                              input(event) {
                                _self.columnFilters[
                                  column.property
                                ].op = event;

                                if (event === '=') _self.columnFilters[column.property].dateRange = null;
                                if (Array.isArray(_self.columnFilters[column.property].dateRange)) {
                                  _self.columnFilters[column.property].dateRange = null;
                                }
                              },
                            },
                          },
                          operators,
                        ),
                        h('ui-input', {
                          class: `filter-input filter-input-num ${
                            this.customFilterColumn.indexOf(column.property) >= 0
                            || this.customFilterColumnCreated.includes(column.property)
                              ? 'filter-input__hide'
                              : ''
                          }`,
                          props: {
                            placeholder: this.$t('ui.table.value'),
                            value: this.columnFilters[column.property].value,
                            type: filterInputType,
                          },
                          on: {
                            input(event) {
                              _self.columnFilters[
                                column.property
                              ].value = event;
                            },
                          },
                        }),
                        h('ui-input', {
                          class: `filter-input filter-input-date ${
                            this.customFilterColumn.indexOf(column.property) >= 0
                              ? ''
                              : 'filter-input__hide'
                          }`,
                          props: {
                            placeholder: this.$t('ui.table.value'),
                            value: this.columnFilters[column.property]
                              .valueSec,
                          },
                          on: {
                            input(event) {
                              _self.columnFilters[
                                column.property
                              ].valueSec = event;
                              _self.setTimeMoment(column.property);
                            },
                          },
                        }),
                        /*  */
                        h('el-date-picker', {
                          class: {
                            'filter__datapicker-daterange date-picker date-picker__style': true,
                            'filter-input__hide': !this.customFilterColumnCreated.includes(column.property),
                            'el-date-picker__hidden': this.columnFilters[column.property].op !== '=',
                          },
                          props: {
                            placeholder: this.$t('ui.table.value'),
                            value: this.columnFilters[column.property].dateRange,
                            'start-placeholder': this.$t('reports.other.start_date'),
                            'end-placeholder': this.$t('reports.other.end_date'),
                            name: 'range_picker',
                            type: 'daterange',
                            'range-separator': '-',
                            format: 'dd MMM yyyy',
                            clearable: false,
                            disabled: this.columnFilters[column.property].op === '',
                          },
                          on: {
                            input: (event) => {
                              this.$set(
                                this.columnFilters[column.property],
                                'dateRange',
                                event.map(elem => this.$moment(elem).format('YYYY-MM-DD')),
                              );
                            },
                          },
                        }),

                        h('el-date-picker', {
                          class: {
                            'filter__datapicker-date date-picker date-picker__style': true,
                            'filter-input__hide': !this.customFilterColumnCreated.includes(column.property),
                            'el-date-picker__hidden': this.columnFilters[column.property].op === '=',
                          },
                          props: {
                            placeholder: this.$t('ui.table.value'),
                            value: this.columnFilters[column.property].dateRange,
                            'start-placeholder': this.$t('reports.other.start_date'),
                            'end-placeholder': this.$t('reports.other.end_date'),
                            name: 'range_picker',
                            type: 'date',
                            'range-separator': '-',
                            format: 'dd MMM yyyy',
                            clearable: false,
                            disabled: this.columnFilters[column.property].op === '',
                          },
                          on: {
                            input: (event) => {
                              this.$set(this.columnFilters[column.property], 'dateRange', this.$moment(event).format('YYYY-MM-DD'));
                            },
                          },
                        }),

                        /* //  */
                        h('ui-select', {
                          class: `filter-select ${
                            this.customFilterColumn.indexOf(column.property) >= 0
                              ? ''
                              : 'filter-select__hide'
                          }`,
                          props: {
                            placeholder: this.$t('affiliates.offersView.holdRanges.seconds'),
                            value:
                                this.columnFilters[column.property]
                                  .dateFilter
                                || `${
                                  this.customFilterColumn.indexOf(column.property) >= 0
                                    ? (this.columnFilters[
                                      column.property
                                    ].dateFilter = 'hours')
                                    : ''
                                }`,
                            options: [
                              {
                                label: this.$t('affiliates.offersView.holdRanges.minutes'),
                                value: 'minutes',
                              },
                              {
                                label: this.$t('affiliates.offersView.holdRanges.hours'),
                                value: 'hours',
                              },
                              {
                                label: this.$t('affiliates.offersView.holdRanges.days'),
                                value: 'days',
                              },
                              {
                                label: this.$t('affiliates.offersView.holdRanges.weeks'),
                                value: 'weeks',
                              },
                            ],
                          },
                          on: {
                            input(event) {
                              _self.columnFilters[
                                column.property
                              ].dateFilter = event;
                              _self.setTimeMoment(column.property);
                            },
                          },
                        }),
                        h(
                          'div',
                          {
                            class: 'btns',
                          },
                          [
                            h(
                              'ui-button',
                              {
                                props: {
                                  color: 'red',
                                  lib: 'fa',
                                  substyle: 'fas',
                                  icon: 'times',
                                },
                                on: {
                                  click() {
                                    _self.columnFilters[column.property].op = '';
                                    _self.columnFilters[column.property].value = '';
                                    _self.columnFilters[column.property].valueSec = '';
                                    _self.columnFilters[column.property].dateFilter = '';
                                    _self.columnFilters[column.property].dateRange = null;
                                    _self.columnFilters[column.property].visible = false;
                                    _self.lastOpenFilter = '';
                                    _self.$emit(
                                      'filter',
                                      _self.columnFilters,
                                      'reset',
                                    );
                                    _self.checkApplyFilter = true;
                                    _self.$nextTick(() => {
                                      _self.checkApplyFilter = false;
                                    });
                                  },
                                },
                              },
                              this.$t('ui.table.reset'),
                            ),
                            h(
                              'ui-button',
                              {
                                props: {
                                  lib: 'fa',
                                  substyle: 'fas',
                                  icon: 'check',
                                  filled: true,
                                  disabled: !(
                                    (this.columnFilters[column.property].value
                                      || this.columnFilters[column.property].dateRange)
                                    && this.columnFilters[column.property].op
                                  ),
                                },
                                on: {
                                  click() {
                                    _self.columnFilters[column.property].visible = false;
                                    _self.lastOpenFilter = '';
                                    _self.$emit(
                                      'filter',
                                      _self.columnFilters,
                                    );
                                    _self.checkApplyFilter = true;
                                    _self.$nextTick(() => {
                                      _self.checkApplyFilter = false;
                                    });
                                  },
                                },
                              },
                              this.$t('ui.table.apply'),
                            ),
                          ],
                        ),
                      ],
                    ),
                    h(
                      'div',
                      {
                        class: {
                          'filter-opener': true,
                          open:
                              this.lastOpenFilter === column.property
                              || ((this.columnFilters[column.property].value || this.columnFilters[column.property].dateRange !== null)
                                && this.columnFilters[column.property].op),
                        },
                        slot: 'reference',
                        props: {},
                        on: {
                          click(event) {
                            event.stopPropagation();
                            const set = !_self.columnFilters[column.property]
                              .visible;
                            _self.columnFilters[
                              column.property
                            ].visible = set;
                            if (set) {
                              if (_self.lastOpenFilter) {
                                _self.columnFilters[
                                  _self.lastOpenFilter
                                ].visible = false;
                              }
                              _self.lastOpenFilter = column.property;
                            } else {
                              _self.lastOpenFilter = '';
                            }
                          },
                        },
                      },
                      [
                        h('ui-icon', {
                          props: {
                            lib: 'fa',
                            substyle: 'fas',
                            name: 'filter',
                            size: 8,
                          },
                        }),
                      ],
                    ),
                  ],
                )
                : '',
            ],
          ),
        ],
      );
    },
  },
};
