<template>
  <div
    class="forecast-switch"
    :class="!value ? 'success' : 'danger'"
    @click="change"
  >
    <adm-ui-icon :icon="config.icon" />
    <span class="text">{{ config.text }}</span>
  </div>
</template>

<script>
export default {
  name: 'ForecastSwitch',

  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    config() {
      if (!this.value) {
        return {
          text: this.$t('dashboards.other.show_forecast'),
          icon: 'fas fa-eye',
        };
      }
      return {
        text: this.$t('dashboards.other.hide_forecast'),
        icon: 'fas fa-eye-slash',
      };
    },
  },

  methods: {
    change() {
      this.$emit('change', this.value);
    },
  },
};
</script>
