<template>
  <panel
    class="crm-activity-actions"
    :error="hasErrors"
  >
    <add-sms-action-popup
      ref="addSmsActionPopup"
      :activity="activity"
      @save="handleSaveAction"
      @preview="handlePreviewAction($event.actionGroup, 'sms', $event)"
      @send-test-sms="handleSendTest($event.actionGroup, 'sms', $event.index)"
    />
    <add-sms-action-preview-popup
      ref="addSmsActionPreviewPopup"
      :activity="activity"
      @send-test-sms="handleSendTest($event.actionGroup, 'sms', $event.index)"
    />
    <add-mail-action-popup
      ref="addMailActionPopup"
      :activity="activity"
      :current-brand="currentBrand"
      :templates="templates"
      @preview="handlePreviewAction($event.actionGroup, 'email', $event)"
      @send-test-email="handleSendTest($event.actionGroup, 'email', $event.index)"
      @save="handleSaveAction"
      @change-template="handleChangeTemplate"
    />
    <template-mail-action-popup
      ref="templateMailActionPopup"
      :templates="templates"
    />
    <add-mail-action-preview-popup
      ref="addMailActionPreviewPopup"
      :activity="activity"
      :current-brand="currentBrand"
      :templates="templates"
      @send-test-email="handleSendTest($event.actionGroup, 'email', $event.index)"
    />
    <add-call-center-action-popup
      ref="addCallCenterActionPopup"
      :activity="activity"
      :current-brand="currentBrand"
      @save="handleSaveAction"
    />
    <add-push-action-popup
      ref="addPushActionPopup"
      @save="handleSaveAction"
    />
    <confirm-send-test-email
      ref="confirmSendTestEmail"
      :current-brand="currentBrand"
    />
    <confirm-send-test-sms
      ref="confirmSendTestSms"
    />
    <div
      :class="[
        'ui-m-xxl-b',
        'ui-d-flex',
        'ui-jc-space-between',
      ]"
    >
      <div class="ui-d-flex ui-ai-center">
        <panel-header-title>
          {{ $t('crm.activities.actions.title') }}
        </panel-header-title>
      </div>
      <div
        v-if="state === 'pause-edit' || state === 'edit' || state === 'rework-edit'"
        class="ui-d-flex ui-g-w-md ui-ai-center"
      >
        <div class="ui-g-md ui-ai-center">
          <panel-header-subtext class="ui-m-md-r">
            {{ $t('crm.activities.actions.send.repeat') }}
          </panel-header-subtext>
          <el-switch
            v-model="activity.send_actions_repeat"
            :active-color="$theme.accentColor"
            :inactive-color="$theme.dangerColor"
            :disabled="state === 'pause-edit'"
          />
        </div>
        <div class="ui-g-md ui-ai-center ui-m-xl-r ui-m-xl-l">
          <panel-delimiter
            class="crm-activity-actions__header-delimiter"
            type="vertical"
            color="contrast"
          />
        </div>
        <ui-input-wrapper
          class="ui-g-md"
          :disabled="!activity.send_actions_repeat || state === 'pause-edit'"
          :prepend-border="false"
        >
          <ui-input
            v-model="activity.send_actions_count"
            class="crm-activity-actions__hide-input-error"
            width="68px"
            :disabled="!activity.send_actions_repeat || state === 'pause-edit'"
            :input-mask="'######'"
            :error="getError('send_actions_count')"
          />
          <ActionIcon
            v-if="getError('send_actions_count')"
            slot="append"
            :tooltip="getError('send_actions_count')"
          >
            <ui-icon
              name="exclamation-circle"
              :color="$theme.dangerColor"
              lib="fa"
              size="14"
            />
          </ActionIcon>
        </ui-input-wrapper>
        <panel-header-subtext class="ui-g-md ui-m-md-r">
          {{ $t('crm.activities.actions.send.label_2') }}
        </panel-header-subtext>
        <ui-input-wrapper
          class="ui-g-md"
          :disabled="!activity.send_actions_repeat || state === 'pause-edit'"
          :prepend-border="false"
        >
          <ui-input
            v-model="activity.send_actions_repeat_interval"
            class="crm-activity-actions__hide-input-error"
            width="68px"
            :disabled="!activity.send_actions_repeat || state === 'pause-edit'"
            :input-mask="'######'"
            :error="getError('send_actions_repeat_interval')"
          />
          <ActionIcon
            v-if="getError('send_actions_repeat_interval')"
            slot="append"
            :tooltip="getError('send_actions_repeat_interval')"
          >
            <ui-icon
              name="exclamation-circle"
              :color="$theme.dangerColor"
              lib="fa"
              size="14"
            />
          </ActionIcon>
        </ui-input-wrapper>
        <panel-header-subtext class="ui-g-md ui-m-md-r">
          {{ $tc('crm.activities.actions.send.day') }}
        </panel-header-subtext>
        <el-tooltip
          :open-delay="500"
          placement="top"
          :disabled="isCanAddActionGroup"
          :content="$t('crm.activities.actions.exceed_action_group_limit_hint', { limit: actionGroupsCountLimit})"
        >
          <ui-button
            icon="plus"
            lib="fa"
            substyle="fas"
            color="green"
            class="btn ui-g-md"
            :transparent-background="true"
            :disabled="!isCanAddActionGroup || state === 'pause-edit'"
            @click="handleAddNewActionGroup"
          >
            {{ $t('crm.activities.actions.new_action_group_button') }}
          </ui-button>
        </el-tooltip>
      </div>

      <panel
        v-if="state === 'approved' || state === 'preview' || state === 'preview-edit'"
        class="crm-activity__panel-text"
        type="contrast"
      >
        <span v-html="sendHint" />
      </panel>
    </div>
    <panel
      v-if="state === 'rework' || state === 'check'"
      class="ui-m-xl-b crm-activity__panel-text"
      type="contrast"
    >
      <activity-check-control
        v-if="state === 'rework' || (state === 'check' && canReview)"
        slot="prepend"
        v-model="reworkState.is_approved_actions"
        :can-edit="state === 'check'"
      />
      <span v-html="sendHint" />
    </panel>

    <panel
      v-show="false"
      v-if="(state === 'edit' || state === 'rework-edit' || state === 'pause-edit' || isHasControlGroup) && (controlGroupActive || ['edit', 'rework-edit'].includes(state))"
      type="contrast"
    >
      <activity-check-control
        v-if="state === 'rework' || (state === 'check' && canReview)"
        slot="prepend"
        v-model="getCheckActionGroup(activity.action_groups[0].id).is_approved"
        :can-edit="state === 'check'"
      />
      <div
        v-if="state === 'edit' || state === 'rework-edit' || state === 'pause-edit'"
        class="ui-d-flex ui-ai-center ui-g-w-md"
      >
        <span class="ui-g-md">
          {{ $t('crm.activities.actions.control_group') }}
        </span>
        <el-switch
          :value="controlGroupActive"
          class="ui-g-md ui-m-md-r"
          :active-color="$theme.accentColor"
          :inactive-color="$theme.dangerColor"
          :disabled="state === 'pause-edit'"
          @input="setControlGroupActive($event)"
        />
        <ui-input-wrapper
          class="ui-g-md"
          :disabled="!controlGroupActive || state === 'pause-edit'"
        >
          <span slot="prepend">%</span>
          <ui-input
            class="crm-activity-actions__hide-input-error"
            :value="getShadowedValue('control')"
            width="100px"
            :disabled="!controlGroupActive || shadowKey === 0 || state === 'pause-edit'"
            :input-mask="(!controlGroupActive || shadowKey === 0) ? '' : '###'"
            :error="getActionGroupsErrorNotEmpty(0, 'percent')"
            @input="setPercent(0, $event)"
          />
          <span slot="append">
            <panel-action-icon
              class="crm-activity-actions__shadow-icon"
              :tooltip="$t('crm.tooltips.shadow_percent')"
              icon="angle-double-down"
              :color="shadowKey === 0 ? $theme.accentColor : $theme.dangerColor"
              :disabled="!controlGroupActive || state === 'pause-edit'"
              @click="toggleShadowKey(0)"
            />
          </span>
        </ui-input-wrapper>
        <span class="ui-g-md">
          {{ $t('crm.activities.actions.of_segment') }}
        </span>
        <div
          v-if="getActionGroupsErrorNotEmpty(0, 'percent')"
          class="ui-d-flex ui-ai-start ui-m-sm-l"
        >
          <div class="ui-fd-column">
            <crm-error-panel
              v-for="(error, errorIndex) in getActionGroupsErrorList(0, 'percent')"
              :key="errorIndex"
              class="ui-g-md ui-ai-baseline"
            >
              {{ error }}
            </crm-error-panel>
          </div>
        </div>
      </div>
      <div
        v-else
        class="ui-jc-space-between ui-d-flex ui-ai-center"
      >
        <div class="ui-g-w-md ui-ai-center">
          <div
            :class="[
              'crm-activity__control-group',
              'ui-g-md',
            ]"
          >
            {{ controlGroupPercent }}%
          </div>
          <crm-tag
            class="ui-g-md players-wrapper"
            icon="users"
          >
            <div v-loading="!isDisplayPlayers" class="players-loader" :class="{ loading: !isDisplayPlayers }">
              <span v-if="isDisplayPlayers">{{ `${parseFloat(controlGroupPlayers).toLocaleString('en-US')}` }}</span>
            </div>
          </crm-tag>
          <span class="ui-g-md">
            {{ $t('crm.activities.actions.control_group') }}
          </span>
        </div>

        <panel-action-icon
          v-if="activity.moderation_status === 'approved'"
          :tooltip="$t('crm.tooltips.download')"
          icon="down"
          style="margin-bottom: 0"
          :color="$theme.accentColor"
          @click="exportData(0)"
        />
      </div>
    </panel>
    <div
      v-for="(group, index) in actionGroupsWithoutControl"
      :key="index"
    >
      <tree
        class="crm-activity-actions__edit-state-tree"
        thickness="thin"
      >
        <panel
          class="ui-m-xl-t"
          type="contrast"
        >
          <activity-check-control
            v-if="state === 'rework' || (state === 'check' && canReview)"
            slot="prepend"
            v-model="getCheckActionGroup(group.id).is_approved"
            :can-edit="state === 'check'"
          />

          <div
            v-if="state === 'edit' || state === 'rework-edit' || state === 'pause-edit'"
            :class="[
              'ui-d-flex',
              'ui-jc-space-between',
              'ui-g-w-md',
            ]"
          >
            <div class="ui-d-flex ui-ai-center">
              <span class="ui-g-md">
                {{ $t('crm.activities.actions.action_group') }} {{ getLiter(index) }}
              </span>
              <ui-input-wrapper class="ui-g-md" :disabled="state === 'pause-edit'">
                <span slot="prepend">%</span>
                <ui-input
                  class="crm-activity-actions__hide-input-error"
                  :value="getShadowedValue(index + indexShift)"
                  width="100px"
                  :disabled="state === 'pause-edit' || (shadowKey === index + indexShift)"
                  :input-mask="(shadowKey === index + indexShift) ? '' : '###'"
                  :error="getActionGroupsErrorNotEmpty(index + indexShift, 'percent')"
                  @input="setPercent(index + indexShift, $event)"
                />
                <span slot="append">
                  <panel-action-icon
                    class="crm-activity-actions__shadow-icon"
                    :tooltip="$t('crm.tooltips.shadow_percent')"
                    icon="angle-double-down"
                    :color="shadowKey === index + indexShift ? $theme.accentColor : $theme.dangerColor"
                    :disabled="state === 'pause-edit'"
                    @click="toggleShadowKey(index + indexShift)"
                  />
                </span>
              </ui-input-wrapper>
              <span class="ui-g-md">
                {{ $t('crm.activities.actions.of_segment') }}
              </span>
              <div
                v-if="getActionGroupsErrorNotEmpty(index + indexShift, 'percent')"
                class="ui-d-flex ui-ai-start ui-m-sm-l"
              >
                <div class="ui-fd-column">
                  <crm-error-panel
                    v-for="(error, errorIndex) in getActionGroupsErrorList(index + indexShift, 'percent')"
                    :key="errorIndex"
                    class="ui-g-md ui-ai-baseline"
                  >
                    {{ error }}
                  </crm-error-panel>
                </div>
              </div>
            </div>
            <div class="ui-d-flex ui-ai-center crm-activity-actions__right-panel-controls">
              <panel-action-icon
                :tooltip="$t('crm.tooltips.clone')"
                icon="clone"
                :color="$theme.accentColor"
                :disabled="!isCanAddActionGroup || state === 'pause-edit'"
                @click="handleCloneActionGroup(group)"
              />
              <panel-action-icon
                :tooltip="$t('crm.tooltips.delete')"
                icon="trash-alt"
                :color="$theme.dangerColor"
                :disabled="state === 'pause-edit'"
                @click="handleDeleteActionGroup(group)"
              />
            </div>
          </div>

          <div
            v-else
            class="ui-jc-space-between ui-d-flex ui-ai-center"
          >
            <div class="ui-g-w-md ui-ai-center">
              <div
                :class="[
                  'crm-activity__control-group',
                  'ui-g-md',
                ]"
              >
                {{ group.percent }}%
              </div>
              <crm-tag
                class="ui-g-md players-wrapper"
                icon="users"
              >
                <div v-loading="!isDisplayPlayers" class="players-loader" :class="{ loading: !isDisplayPlayers }">
                  <span v-if="isDisplayPlayers">{{ `${parseFloat(group.players_count).toLocaleString('en-US')}` }}</span>
                </div>
              </crm-tag>
              <span class="ui-g-md">
                {{ $t('crm.activities.actions.action_group') }} {{ getLiter(index) }}
              </span>
            </div>

            <panel-action-icon
              v-if="activity.moderation_status === 'approved'"
              :tooltip="$t('crm.tooltips.download')"
              icon="down"
              style="margin-bottom: 0"
              :color="$theme.accentColor"
              @click="exportData(index + 1)"
            />
          </div>
        </panel>
        <tree-branch
          v-for="(action, actionInd) in actionGroupsMeta[index].list"
          :key="actionInd"
        >
          <panel
            class="crm-activity-actions__edit-state-tree-branch"
            type="contrast"
          >
            <activity-check-control
              v-if="state === 'rework' || (state === 'check' && canReview)"
              slot="prepend"
              :value="getCheckAction(group.id, action.type)"
              :can-edit="state === 'check'"
              @input="setCheckAction(group.id, action.type, $event)"
            />

            <div
              v-if="state === 'edit' || state === 'rework-edit'"
              :class="[
                'ui-d-flex',
                'ui-jc-space-between',
                'ui-g-w-md',
              ]"
            >
              <div class="crm-activity-actions__action-info ui-d-flex ui-ai-center">
                <crm-tag
                  class="ui-g-md"
                  :preset="`notify-${action.type}`"
                />
                <span
                  :class="[
                    'crm-activity-actions__action-info-name',
                    'ui-g-md',
                  ]"
                  :title="action.name"
                >
                  {{ action.name || $t('crm.activities.actions.empty_name') }}
                </span>
              </div>
              <div class="ui-d-flex ui-ai-center crm-activity-actions__right-panel-controls">
                <!-- <panel-action-icon
                  :tooltip="$t('crm.tooltips.preview')"
                  icon="eye"
                  :color="$theme.accentColor"
                  @click="handlePreviewAction(group, action.type)"
                /> -->
                <activity-actions-languages
                  class="ui-d-flex ui-m-md-r"
                  :meta="action.languages"
                />
                <panel-action-icon
                  :tooltip="$t('crm.tooltips.edit')"
                  icon="pen"
                  :color="$theme.accentColor"
                  @click="handleEditAction(group, action.type, false, {
                    errors: errorsActionGroups.actionGroups[index + indexShift] && errorsActionGroups.actionGroups[index + indexShift].actions
                  }, true)"
                />
                <panel-action-icon
                  v-if="isActionsSendAvailable(action.type)"
                  :tooltip="$t(`crm.tooltips.send_test_${action.type}`)"
                  icon="paper-plane"
                  :color="$theme.accentColor"
                  :disabled="isActionsSendDisabled(group.actions)"
                  @click="handleSendTest(group, action.type)"
                />
                <panel-action-icon
                  :tooltip="$t('crm.tooltips.delete')"
                  icon="trash-alt"
                  :color="$theme.dangerColor"
                  @click="handleDeleteAction(group, action.type)"
                />
              </div>
            </div>
            <div
              v-else
              class="ui-d-flex ui-jc-space-between ui-g-w-md"
            >
              <div
                :class="[
                  'crm-activity-actions__action-info',
                  'ui-d-flex',
                  'ui-ai-center'
                ]"
              >
                <crm-tag
                  class="ui-g-md"
                  :preset="`notify-${action.type}`"
                />
                <span
                  :class="[
                    'ui-g-md',
                    'crm-activity__panel-text',
                    'crm-activity-actions__action-info-name'
                  ]"
                >{{ action.name || $t('crm.activities.actions.empty_name') }}</span>
              </div>
              <div class="ui-d-flex ui-ai-center crm-activity-actions__right-panel-controls">
                <activity-actions-languages
                  class="ui-d-flex ui-m-md-r"
                  :meta="action.languages"
                />
                <div>
                  <el-dropdown
                    v-if="isStatisticAvailable(action.type)"
                    trigger="click"
                    placement="top"
                    @visible-change="visibleChangeStatistic"
                  >
                    <div class="value">
                      <panel-action-icon
                        icon="chart-bar"
                        :tooltip="!isVisibleStatistic ? $t('crm.segments.stats.statistics') : ''"
                        :color="$theme.accentColor"
                      />
                    </div>
                    <el-dropdown-menu
                      slot="dropdown"
                      class="crm-activity-actions__statistics-menu"
                    >
                      <div v-if="rangeLabel" class="card-content range-label">
                        <ui-icon
                          name="calendar-alt"
                          lib="fa"
                          color="#7F7F7F"
                          :size="11"
                        />
                        <span class="ui-m-sm-l">{{ rangeLabel }}</span>
                      </div>
                      <div class="card">
                        <div class="title">
                          <div class="title-content">
                            <span>{{ action.stats.title }}</span>
                          </div>
                        </div>
                        <div class="card-content">
                          <div v-for="(stat, i) in action.stats.values" :key="i" class="field">
                            <span class="label" :class="stat.class">{{ stat.label }}</span>
                            <div class="values">
                              {{ stat.value }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <panel-action-icon
                  v-if="isActionsSendAvailable(action.type)"
                  :tooltip="$t(`crm.tooltips.send_test_${action.type}`)"
                  icon="paper-plane"
                  :color="$theme.accentColor"
                  :disabled="isActionsSendDisabled(group.actions)"
                  @click="handleSendTest(group, action.type)"
                />
                <panel-action-icon
                  v-if="isPreviewAvailable(action.type)"
                  :tooltip="$t('crm.tooltips.preview')"
                  icon="eye"
                  :color="$theme.accentColor"
                  @click="handlePreviewAction(group, action.type)"
                />
                <panel-action-icon
                  v-if="isEditAvailable(action.type)"
                  :tooltip="$t('crm.tooltips.edit')"
                  icon="pen"
                  :color="$theme.accentColor"
                  @click="handleEditAction(group, action.type, false, {
                    errors: errorsActionGroups.actionGroups[index + indexShift] && errorsActionGroups.actionGroups[index + indexShift].actions
                  }, true)"
                />
              </div>
            </div>
          </panel>
        </tree-branch>
        <tree-branch
          v-if="(state === 'edit' || state === 'rework-edit') && !actionGroupsMeta[index].list.length"
          class="crm-activity-actions__add-action-branch"
        >
          <div class="ui-d-flex ui-g-w-md ui-ai-center">
            <span class="ui-g-md">
              {{ $t('crm.activities.actions.add') }}
            </span>
            <crm-tag
              v-if="!actionGroupsMeta[index].isHaveSms && !isCallActionEnabled"
              class="ui-g-md"
              size="large"
              preset="notify-sms"
              clickable
              @click="handleAddAction(group, 'sms')"
            />
            <crm-tag
              v-if="!actionGroupsMeta[index].email && !isCallActionEnabled"
              class="ui-g-md"
              size="large"
              preset="notify-email"
              clickable
              @click="handleAddAction(group, 'email')"
            />
            <crm-tag
              v-if="!actionGroupsMeta[index].call && !isCallActionDisabled && !isCallActionEnabled"
              class="ui-g-md"
              size="large"
              preset="notify-call"
              clickable
              @click="handleAddAction(group, 'call')"
            />
            <!-- <crm-tag
              v-if="!actionGroupsMeta[index].isHaveSms"
              class="ui-g-md"
              size="large"
              preset="notify-push"
              clickable
              @click="handleAddAction(group, 'push')"
            /> -->
            <span class="ui-g-md">
              {{ $t('crm.activities.actions.action') }}
            </span>
          </div>
        </tree-branch>
      </tree>
      <div
        v-if="getActionGroupsErrorNotEmpty(index + indexShift, 'paramsList')"
        class="ui-d-flex ui-fd-column ui-ai-start ui-m-xl-l"
        :class="{
          'ui-m-md-t': actionGroupsMeta[index].list.length,
          'ui-m-lg-t': !actionGroupsMeta[index].list.length,
        }"
      >
        <div class="ui-g-w-md ui-fd-column">
          <crm-error-panel
            v-for="(error, errorIndex) in getActionGroupsErrorList(index + indexShift, 'paramsList')"
            :key="errorIndex"
            class="ui-g-md"
          >
            {{ error }}
          </crm-error-panel>
        </div>
      </div>
    </div>
    <div
      v-if="errorsActionGroups.general.length"
      class="ui-d-flex ui-fd-column ui-ai-start ui-g-w-md ui-m-lg-t"
    >
      <div class="crm-activity-actions__delimiter-wrapper ui-g-md ui-m-sm-b">
        <panel-delimiter />
      </div>
      <crm-error-panel
        v-for="(error, index) in errorsActionGroups.general"
        :key="index"
        class="ui-g-md"
      >
        {{ error }}
      </crm-error-panel>
    </div>
  </panel>
</template>

<script>
import numeral from 'numeral';
import * as Panel from '@/views/CRM/components/Panel';
import * as Tree from '@/views/CRM/components/Tree';
import UiInputWrapper from '@/views/CRM/components/UiInputWrapper.vue';
import CrmTag from '@/views/CRM/components/CrmTag.vue';
import ActivityCheckControl from '@/views/CRM/Activities/components/ActivityCheckControl.vue';
import ActivitiesActionsCommonMixin from '@/views/CRM/mixins/activities-actions-common-mixin';
import TemplateMailActionPopup from '@/views/CRM/components/TemplateMailActionPopup';
import AddCallCenterActionPopup from '@/views/CRM/components/AddCallCenterActionPopup.vue';
import AddSmsActionPopup from '@/views/CRM/components/AddSmsActionPopup.vue';
import AddSmsActionPreviewPopup from '@/views/CRM/components/AddSmsActionPreviewPopup.vue';
import AddMailActionPopup from '@/views/CRM/components/AddMailActionPopup.vue';
import AddPushActionPopup from '@/views/CRM/components/AddPushActionPopup.vue';
import ConfirmSendTestSms from '@/views/CRM/components/ConfirmSendTestSms.vue';
import ConfirmSendTestEmail from '@/views/CRM/components/ConfirmSendTestEmail.vue';
import AddMailActionPreviewPopup from '@/views/CRM/components/AddMailActionPreviewPopup.vue';
import ActivityActionsLanguages from '@/views/CRM/Activities/components/ActivityActionsLanguages.vue';
import CrmErrorPanel from '@/views/CRM/components/CrmErrorPanel.vue';
import { getContryCodeByLanguage } from '@/helpers/language.js';
import fileSaver from '@/helpers/file-saver.js';

export default {
  name: 'ActivityActions',
  components: {
    ...Panel,
    ...Tree,
    UiInputWrapper,
    CrmTag,
    ActivityCheckControl,
    AddCallCenterActionPopup,
    AddSmsActionPopup,
    AddMailActionPopup,
    AddMailActionPreviewPopup,
    AddPushActionPopup,
    ActivityActionsLanguages,
    CrmErrorPanel,
    ConfirmSendTestSms,
    ConfirmSendTestEmail,
    AddSmsActionPreviewPopup,
    TemplateMailActionPopup,
  },
  mixins: [
    ActivitiesActionsCommonMixin,
  ],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    activityRange: {
      type: [Array, Object],
      default: null,
    },
    canReview: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String,
      default: 'edit',
    },
    activity: {
      type: Object,
      default: () => ({}),
    },
    templates: {
      type: Array,
      default: () => ([]),
    },
    controlGroupActive: {
      type: Boolean,
      default: true,
    },
    controlGroupPercent: {
      type: [Number, String],
      default: 20,
    },
    controlGroupPlayers: {
      type: [Number],
      default: 0,
    },
    reworkState: {
      type: Object,
      default: () => ({}),
    },
    currentBrand: {
      type: String,
      default: '',
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      checked: true,
      shadowKey: -1,
      oldShadowKey: -1,
      actionGroupsCountLimit: 3,
      isVisibleStatistic: false,
      mailTemplate: {},
    };
  },
  computed: {
    rangeLabel() {
      if (this.activityRange) {
        const { stats_period_start, stats_period_end } = this.activityRange;
        const start = `
          ${this.$moment(stats_period_start).format('DD')}
          ${this.$t(`el.datepicker.months.${this.$moment(stats_period_start).format('MMM').toLowerCase()}`)}
          ${this.$moment(stats_period_start).year()}
        `;

        const end = `
          ${this.$moment(stats_period_end).format('DD')}
          ${this.$t(`el.datepicker.months.${this.$moment(stats_period_end).format('MMM').toLowerCase()}`)}
          ${this.$moment(stats_period_end).year()}
        `;

        return `${start} - ${end}`;
      }

      return null;
    },
    isDisplayPlayers() {
      if (this.activity.id) {
        return this.activity.is_players_attached;
      }

      return true;
    },
    isCallActionEnabled() {
      return this.actionGroupsMeta.some(group => Object.keys(group).includes('call'));
    },
    isCallActionDisabled() {
      return this.actionGroupsMeta.some(group => Object.keys(group).some(type => ['email', 'sms'].includes(type)));
    },
    isCanAddActionGroup() {
      return this.actionGroupsCount < this.actionGroupsCountLimit && !this.isCallActionEnabled;
    },
    actionGroupsCount() {
      return this.activity.action_groups.filter(item => !item.is_control).length;
    },
    actionGroupsWithoutControl() {
      const actionGroups = this.activity.action_groups || [];
      return actionGroups.filter(actionGroup => !actionGroup.is_control);
    },
    sendHint() {
      return this.activity.send_actions_repeat
        ? this.$tc('crm.activities.actions.send.hint', this.activity.send_actions_repeat_interval, {
          interval: this.activity.send_actions_repeat_interval,
          count: this.activity.send_actions_count,
        })
        : this.$t('crm.activities.actions.send.hint_once');
    },
    shadowedGroups() {
      return this.activity.action_groups.map((item, index) => ({
        ...item,
        index,
        percent: this.getShadowedValue(index),
      }));
    },
    actionIndex() {
      const result = {};
      this.activity.action_groups.forEach((actionGroup) => {
        const actions = actionGroup.actions || [];
        actions.forEach((action) => {
          const key = this.getActionIndexKey(actionGroup.id, action.id);
          result[key] = action;
        });
      });
      return result;
    },
    actionGroupsMeta() {
      const result = this.actionGroupsWithoutControl
        .map((actionGroup) => {
          const meta = {
            list: [],
          };
          const emailActions = actionGroup.actions.filter(action => action.type === 'email');
          if (emailActions.length) {
            let languages = actionGroup.actions.map(action => ({
              name: action.name,
              is_default: action.is_default,
              languageCode: action.language_code,
              email_template_id: action.email_template_id,
              countryCode: getContryCodeByLanguage(action.language_code),
            }));

            const stats = {
              title: this.$t('crm.activities.actions.email_statistics.title'),
              values: [
                {
                  label: this.$t('crm.activities.actions.email_statistics.playersCount'),
                  value: actionGroup?.stats?.players_count || 0,
                },
                {
                  label: this.$t('crm.activities.actions.email_statistics.acceptedCount'),
                  value: actionGroup?.stats?.email_accepted_count || 0,
                },
                {
                  label: this.$t('crm.activities.actions.email_statistics.sent'),
                  value: actionGroup?.stats?.email_send_count || 0,
                },
                {
                  label: this.$t('crm.activities.actions.email_statistics.bounced'),
                  value: actionGroup?.stats?.email_bounced_count || 0,
                },
                {
                  label: this.$t('crm.activities.actions.email_statistics.opened'),
                  value: this.getStatsWithPercent(actionGroup?.stats?.email_view_count, actionGroup?.stats?.email_view_percent) || 0,
                },
                {
                  label: this.$t('crm.activities.actions.email_statistics.uniqueClicks'),
                  value: this.getStatsWithPercent(actionGroup?.stats?.email_click_count, actionGroup?.stats?.email_click_percent) || 0,
                },
              ],
            };

            const defaultItem = languages.find(action => action.is_default);

            languages = languages.filter(action => !action.is_default);
            languages.unshift(defaultItem);

            const languagesBase = languages.slice(0, 3);
            const languagesRest = languages.slice(3);

            meta.email = {
              type: 'email',
              name: (defaultItem.name || '').trim(),
              languages: {
                full: languages,
                base: languagesBase,
                rest: languagesRest,
              },
              stats,
            };

            meta.list.push(meta.email);
          }

          const smsActions = actionGroup.actions.filter(action => action.type === 'sms');
          if (smsActions.length) {
            let languages = actionGroup.actions.map(action => ({
              name: action.name,
              is_default: action.is_default,
              languageCode: action.language_code,
              countryCode: getContryCodeByLanguage(action.language_code),
            }));

            const stats = {
              title: this.$t('crm.activities.actions.sms_statistics.title'),
              values: [
                {
                  label: this.$t('crm.activities.actions.sms_statistics.playersCount'),
                  value: actionGroup?.stats?.players_count || 0,
                },
                {
                  label: this.$t('crm.activities.actions.sms_statistics.acceptedCount'),
                  value: actionGroup?.stats?.sms_accepted_count || 0,
                },
                {
                  label: this.$t('crm.activities.actions.sms_statistics.sent'),
                  value: this.getStatsWithPercent(actionGroup?.stats?.sms_sent_count, actionGroup?.stats?.sms_sent_percent) || 0,
                },
                {
                  label: this.$t('crm.activities.actions.sms_statistics.delivered'),
                  value: this.getStatsWithPercent(actionGroup?.stats?.sms_delivered_count, actionGroup?.stats?.sms_delivered_percent) || 0,
                },
                {
                  label: this.$t('crm.activities.actions.sms_statistics.spent'),
                  value: actionGroup?.stats?.price_sum || `$${numeral(0).format('0,0.00')}`,
                },
              ],
            };

            const defaultItem = languages.find(action => action.is_default);

            languages = languages.filter(action => !action.is_default);
            languages.unshift(defaultItem);

            const languagesBase = languages.slice(0, 3);
            const languagesRest = languages.slice(3);

            meta.sms = {
              type: 'sms',
              name: (defaultItem.name || '').trim(),
              languages: {
                full: languages,
                base: languagesBase,
                rest: languagesRest,
              },
              stats,
            };

            meta.list.push(meta.sms);
          }

          const callCenterActions = actionGroup.actions.filter(action => action.type === 'call');
          if (callCenterActions.length) {
            const stats = {
              title: this.$t('crm.activities.actions.call_statistics.title'),
              values: [
                {
                  label: this.$t('crm.activities.actions.call_statistics.successfulCalls'),
                  value: actionGroup?.stats?.calls_success_count || 0,
                  class: 'success',
                },
                {
                  label: this.$t('crm.activities.actions.call_statistics.failedCalls'),
                  value: actionGroup?.stats?.calls_fail_count || 0,
                  class: 'decline',
                },
                {
                  label: this.$t('crm.activities.actions.call_statistics.totalCalls'),
                  value: actionGroup?.stats?.calls_count || 0,
                },
              ],
            };

            const { call_center_project_id, name } = actionGroup.actions[0];

            meta.call = {
              type: 'call',
              call_center_project_id,
              name,
              stats,
            };

            meta.list.push(meta.call);
          }

          // isHavePush: actionGroup.actions.some(action => action.type === 'push'),
          // isHaveEmail: actionGroup.actions.some(action => action.type === 'email'),
          // isHaveSms: actionGroup.actions.some(action => action.type === 'sms'),
          return meta;
        });

      return result;
    },
    hasErrors() {
      return !!(this.errorsActionGroups.hasErrors
        || this.getError('send_actions_repeat_interval')
        || this.getError('send_actions_count'));
    },
    errorsActionGroups() {
      const { action_groups } = this.errors;

      const errors = {
        general: [],
        actionGroups: {},
        percent: {},
        hasErrors: false,
      };

      if (action_groups && action_groups.length) {
        action_groups.forEach((actionGroup, index) => {
          if (!actionGroup) {
            return true;
          }

          if (actionGroup.message) {
            errors.general.push(actionGroup.message);
          }

          if (actionGroup.actions && actionGroup.actions.length) {
            actionGroup.actions
              .forEach((action, actionIndex) => {
                if (!action) {
                  return true;
                }

                if (!errors.actionGroups[index]) {
                  errors.actionGroups[index] = {
                    paramsList: [],
                    actions: {},
                    percent: [],
                  };
                }

                if (typeof action.code === 'number') {
                  errors.actionGroups[index].paramsList.push(action.message);
                } else {
                  const fields = Object.keys(action);
                  fields.forEach((field) => {
                    action[field].forEach((actionParamError) => {
                      const { type } = this.activity.action_groups[index].actions[actionIndex] || {};

                      if (!errors.actionGroups[index].actions[type]) {
                        let errorText = this.$t('crm.activities.actions.errors.has_param_error', {
                          entity: type,
                        });

                        if (type === 'call') {
                          errorText = this.$t('crm.activities.actions.errors.has_call_error');
                        }

                        errors.actionGroups[index].paramsList.push(errorText);
                        errors.actionGroups[index].actions[type] = {};
                      }

                      if (!errors.actionGroups[index].actions[type][actionIndex]) {
                        errors.actionGroups[index].actions[type][actionIndex] = {};
                      }
                      errors.actionGroups[index].actions[type][actionIndex][field] = actionParamError.message;
                    });
                  });
                }

                return true;
              });
          }

          if (actionGroup.percent && actionGroup.percent.length) {
            if (!errors.actionGroups[index]) {
              errors.actionGroups[index] = {
                paramsList: [],
                actions: {},
                percent: [],
              };
            }

            actionGroup.percent.forEach((percentError) => {
              errors.actionGroups[index].percent.push(percentError.message);
            });
          }

          return true;
        });
      }

      if (errors.general.length || !this.$_.isEmpty(errors.actionGroups) || !this.$_.isEmpty(errors.percent)) {
        errors.hasErrors = true;
      }

      return errors;
    },
    languageIndex() {
      const languages = this.$store.state.misc.languages || [];
      const result = {};

      languages.forEach((item) => {
        result[item.code] = item;
      });

      return result;
    },
  },
  watch: {
    isCallActionEnabled: {
      handler(val) {
        if (val) {
          this.activity.action_groups = this.activity.action_groups
            .filter(({ actions, is_control }) => !!is_control || !!actions?.length);
        }
      },
    },
    shadowedGroups: {
      immediate: true,
      handler(value) {
        this.$emit('updateShadowedGroups', value);
      },
    },
  },
  methods: {
    visibleChangeStatistic(val) {
      this.isVisibleStatistic = val;
    },
    getStatsWithPercent(num, percent) {
      if (num && percent) {
        return `${num} / ${percent}`;
      }

      return 0;
    },
    isPreviewAvailable(type) {
      return this.state !== 'pause-edit' && ['email', 'sms'].includes(type);
    },
    isEditAvailable(type) {
      return this.state === 'pause-edit' && ['email', 'sms'].includes(type);
    },
    isActionsSendAvailable(type) {
      return ['sms', 'email'].includes(type);
    },
    isStatisticAvailable(type) {
      return ['sms', 'call', 'email'].includes(type);
    },
    async exportData(index) {
      const file = await this.$api.getActionGroupsPlayers(this.activity.action_groups[index].id);
      const linkBlob = new Blob(
        [file.data],
        { type: file.headers['content-type'] },
      );

      fileSaver(linkBlob, this.activity.name);
    },
    isActionsSendDisabled(actions) {
      return actions.every(action => !action.message.trim());
    },
    getActionGroupsErrorList(index, field) {
      return (this.errorsActionGroups.actionGroups[index] && this.errorsActionGroups.actionGroups[index][field]) || [];
    },
    getActionGroupsErrorNotEmpty(index, field) {
      const list = this.getActionGroupsErrorList(index, field);
      return list.length > 0
        ? list[0]
        : undefined;
    },
    getError(field) {
      return this.errors[field] ? this.errors[field][0].message : '';
    },
    saveState() {
      this.oldShadowKey = this.shadowKey;
    },
    restoreState() {
      this.shadowKey = this.oldShadowKey;
    },
    toggleShadowKey(value) {
      this.shadowKey = this.shadowKey !== value
        ? value
        : -1;
    },
    setControlGroupActive(value) {
      if (!this.isHasControlGroup && value) {
        const controlGroup = this.getDefaultActionGroup();
        controlGroup.is_control = true;
        controlGroup.percent = 100;

        if (this.shadowKey === 0) {
          this.shadowKey = 1;
        }

        this.activity.action_groups.unshift(controlGroup);
      }

      this.$emit('update:controlGroupActive', value);

      if (this.shadowKey === 0 && !value) {
        this.shadowKey = -1;
      }
    },
    setPercent(key, value) {
      const _value = +value > 100
        ? 100
        : +value;
      this.$set(this.activity.action_groups[key], 'percent', _value);
    },
    getShadowedValue(key) {
      if (key === 'control' && !this.controlGroupActive) {
        return 0;
      }

      const _key = key === 'control' && this.controlGroupActive
        ? 0
        : key;

      if (_key === this.shadowKey) {
        const totalWithoutShadow = this.activity.action_groups
          .filter((item, index) => {
            const indexConditon = index !== _key;
            const controlGroupConditon = (!this.controlGroupActive && !item.is_control) || this.controlGroupActive;

            return indexConditon && controlGroupConditon;
          })
          .reduce((a, b) => a + +b.percent, 0);
        return 100 - totalWithoutShadow;
      }

      return this.activity.action_groups[_key].percent;
    },
    handleSendTest(actionGroup, type, translationIndex = -1) {
      const refsMap = {
        email: this.$refs.confirmSendTestEmail,
        sms: this.$refs.confirmSendTestSms,
      };

      const actionsFiltered = actionGroup.actions
        .filter(action => action.type === type);

      const defaultAction = actionsFiltered.find(action => action.is_default) || {};

      const confirmSendTestActions = {
        defaultName: (defaultAction.name || '').trim(),
        actions: actionsFiltered,
      };

      const cb = translationIndex === -1
        ? item => !!item.message
        : (item, index) => index === translationIndex;

      const confirmSendTestSelectedActions = actionsFiltered.map(cb);

      refsMap[type].open({
        actions: confirmSendTestActions,
        selectedActions: confirmSendTestSelectedActions,
      });
    },
    handleAddNewActionGroup() {
      this.activity.action_groups.push(this.getDefaultActionGroup());
    },
    handleDeleteActionGroup(actionGroup) {
      this.handleSpliceActionGroup(actionGroup, true);
    },
    handleCloneActionGroup(actionGroup) {
      this.handleSpliceActionGroup(actionGroup, false);
    },
    handleSpliceActionGroup(actionGroup, isDelete) {
      if (!this.activity.action_groups) {
        return;
      }

      const index = this.activity.action_groups.indexOf(actionGroup);
      const args = isDelete
        ? [index, 1]
        : [index + 1, 0, {
          ...this.$_.cloneDeep(actionGroup),
          percent: 0,
        }];

      if (index === this.shadowKey && isDelete) {
        this.shadowKey = -1;
      } else if (index < this.shadowKey) {
        const shift = isDelete ? -1 : 1;
        this.shadowKey = this.shadowKey + shift;
      }

      this.activity.action_groups.splice(...args);

      if (this.errors && this.errors.action_groups && this.errors.action_groups.length) {
        const argsErrors = isDelete
          ? [index, 1]
          : [index + 1, 0, null];
        this.errors.action_groups.splice(...argsErrors);
      }
    },
    handleAddAction(actionGroup, type, preview = false) {
      this.handleEditAction(actionGroup, type, preview);
    },
    handleEditAction(actionGroup, type, preview = false, payload = null, isEdit = false) {
      if (!actionGroup.actions) {
        this.$set(actionGroup, 'actions', []);
      }

      switch (type) {
      case 'sms':
        if (!preview) {
          this.$refs.addSmsActionPopup.open(actionGroup, payload);
        } else {
          this.$refs.addSmsActionPreviewPopup.open(actionGroup, payload);
        }
        break;
      case 'email':
        if (!preview) {
          if (isEdit) {
            this.$refs.addMailActionPopup.open(actionGroup, payload);
          } else if (this.templates.length === 1) {
            this.$refs.addMailActionPopup.open(actionGroup, payload, this.templates[0].id);
          } else {
            const templateId = this.templates.find(({ is_default }) => !!is_default)?.id;

            this.$refs.templateMailActionPopup.open({
              templateId,
              cb: (id) => {
                this.$refs.addMailActionPopup.open(actionGroup, payload, id);
              },
            });
          }
        } else {
          this.$refs.addMailActionPreviewPopup.open(actionGroup, payload);
        }
        break;

      case 'call':
        if (!preview) {
          this.$refs.addCallCenterActionPopup.open(actionGroup, payload);
        }

        break;
      case 'push':
        this.$refs.addPushActionPopup.open(actionGroup, preview);
        break;
      default:
      }
    },
    handlePreviewAction(actionGroup, type, index = null) {
      this.handleEditAction(actionGroup, type, true, index);
    },
    handleChangeTemplate({ templateId, cb }) {
      this.$refs.templateMailActionPopup.open({
        templateId,
        cb,
        isEdit: true,
      });
    },
    handleSaveAction(actionGroup, actions, type) {
      const editedActions = actionGroup.actions
        .filter(action => action.type !== type)
        .concat(actions);

      this.$set(actionGroup, 'actions', editedActions);
      // if (index < 0) {
      //   actionGroup.actions.push(form);
      // } else {
      //   this.$set(actionGroup.actions, index, form);
      // }
    },
    handleDeleteAction(actionGroup, type) {
      const actions = actionGroup.actions.filter(action => action.type !== type);
      this.$set(actionGroup, 'actions', actions);
    },
    getDefaultActionGroup() {
      return {
        is_control: false,
        percent: '',
        actions: [],
      };
    },
    // setCheckActionGroup(actionGroupId) {
    //   const actionGroup = this.reworkState.action_groups.find(item => item.id === actionGroupId);
    //   actionGroup.is_approved = !actionGroup.is_approved;
    // },
    getCheckActionGroup(actionGroupId) {
      return this.reworkState.action_groups.find(item => item.id === actionGroupId) || {};
    },
    getActionIndexKey(actionGroupId, actionId) {
      return `${actionGroupId}_${actionId}`;
    },
    getCheckActionList(actionGroupId, type) {
      const actionGroup = this.getCheckActionGroup(actionGroupId);
      return actionGroup.actions.filter((item) => {
        const key = this.getActionIndexKey(actionGroupId, item.id);
        const action = this.actionIndex[key];
        return action.type === type;
      });
    },
    getCheckAction(actionGroupId, type) {
      const actions = this.getCheckActionList(actionGroupId, type);
      return actions[0].is_approved;
    },
    setCheckAction(actionGroupId, type, value) {
      const actions = this.getCheckActionList(actionGroupId, type);
      actions.forEach((item) => {
        item.is_approved = value;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-activity-actions__statistics-menu {
  max-height: initial;

  .card {
    margin-bottom: 0;
  }
}
.range-label {
  display: flex;
  color: #7F7F7F;
  font-size: 12px;
  margin-bottom: 8px;

  /deep/ {
    .ui-icon {
      height: 100%;
    }
  }
}
.crm-activity-actions {
  .players-wrapper {
    height: 16px;

    /deep/ .ui-tag__title {
      height: 100%;
    }
  }

  .players-loader {
    &.loading {
      height: 100%;
      min-width: 16px;
      z-index: 9;
    }

    /deep/ {
      .el-loading-mask {
        background-color: inherit;
      }
      .el-loading-spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-top: 0;
        transform: translateY(-50%);

        .circular {
          height: 100%;
          width: 10px;
          .path {
            stroke: white;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
  .crm-activity-actions {
    &__statistics-menu {
      overflow: initial!important;
      min-width: 300px;
      color: var(--crm-panel-text);
  }
    &__add-action-branch {
      padding-top: 18px;
      padding-left: 24px;

      &::after {
        top: calc(50% + 9px);
      }

      &:last-of-type::before {
        height: calc(50% + 9px);
      }
    }

    &__tree-branch-action-panel {
      & + & {
        padding-top: 8px
      }
    }

    &__tree-branch-action-group {
      & + & {
        padding-top: 16px
      }
    }

    &__edit-state-tree {
      margin-left: -10px
    }

    &__edit-state-tree-branch {
      padding-top: 8px;
    }

    &__shadow-icon {
      margin-top: -8px;
    }

    &__header-delimiter {
      height: 16px;
    }

    &__right-panel-controls {
      margin-right: -8px;
    }

    .crm-panel__content {
      min-width: 0;
    }

    &__action-info {
      min-width: 0;
    }

    &__action-info-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__action-info &__action-info-name {
      display: block;
    }

    &__hide-input-error .input-error {
      display: none;
    }

    &__delimiter-wrapper {
      flex-grow: 1;
      align-self: normal;
      // margin: 8px 0 4px 8px;
    }

    &__confirm-send-test-email, &__confirm-send-test-sms {
      &.ui-confirm {
        z-index: 2000;
      }

      .el-checkbox__label {
        line-height: 12px;
        font-size: 12px;
      }

      .el-checkbox {
        display: flex;
        align-items: center;
        line-height: 14px;
        margin-right: 0;
      }

      .el-checkbox__input {
        display: flex;
      }

      &-msg {
        b {
          font-weight: bold;
        }

        .email {
          display: inline-block;
        }
      }

      &-checkbox + &-checkbox {
        margin-top: 12px;
      }

      &-checkbox-wrapper {
        max-height: 400px;
        overflow-y: auto;
      }
    }
  }
</style>
