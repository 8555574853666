export default {
  // promo checker
  getPromoCheckerOverallStats(params) {
    return this.affiliatesApi.get('/tools/bet-promo-codes-checker/overall-stats', { params });
  },
  getPromoCheckerPlayersStats(params) {
    return this.affiliatesApi.get('/tools/bet-promo-codes-checker/players-stats', { params });
  },
  promoCheckerUploadCodes(payload) {
    return this.affiliatesApi.post('/tools/bet-promo-codes-checker/upload-promo-codes', payload);
  },

  // landing leagues
  getLeaguesCount() {
    return this.affiliatesApi.get('/tools/promo-matches/leagues/count');
  },
  getLeagues(params) {
    return this.affiliatesApi.get('/tools/promo-matches/leagues', { params });
  },
  getMatchesCount(params) {
    return this.affiliatesApi.get('/tools/promo-matches/matches/count', { params });
  },
  getMatches(params) {
    return this.affiliatesApi.get('/tools/promo-matches/matches', { params });
  },
  getTeamsCount(params) {
    return this.affiliatesApi.get('/tools/promo-matches/teams/count', { params });
  },
  getTeams(params) {
    return this.affiliatesApi.get('/tools/promo-matches/teams', { params });
  },
  getMatch(id) {
    return this.affiliatesApi.get(`/tools/promo-matches/matches/${id}`);
  },
  deleteMatch(id) {
    return this.affiliatesApi.delete(`/tools/promo-matches/matches/${id}`);
  },
  updateMatch(id, data) {
    return this.affiliatesApi.put(`/tools/promo-matches/matches/${id}`, data);
  },
  createMatch(data) {
    return this.affiliatesApi.post('/tools/promo-matches/matches', data);
  },
};
