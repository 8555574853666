export default {
  /**
   * Get Platform betting domains count
   *
   * @param {Object} params
   * @param {String} [params.search]
   * @param {String} [params.affiliate_id]
   *
   * @return {Promise<Object>} api response
   */
  getPlatformBettingDomainCount(params) {
    return this.affiliatesApi.get(
      '/domains-management/platform-betting-domains/count',
      { params },
    );
  },

  /**
   * Get Platform betting domains
   *
   * @param {Object} params
   * @param {String} [params.sort_column]
   * @param {String} [params.sort_dir]
   * @param {String} [params.search]
   * @param {String} [params.affiliate_id]
   * @param {Number} [params.limit]
   * @param {Number} [params.offset]
   *
   * @return {Promise<Object>} api response
   */
  getPlatformBettingDomain(params) {
    return this.affiliatesApi.get(
      '/domains-management/platform-betting-domains',
      { params },
    );
  },

  /**
   * Create Platform betting domains
   *
   * @param {Object} data
   * @param {String} data.domain_name
   * @param {String} data.affiliate_offer_id
   *
   * @return {Promise<Object>} api response
   */
  createPlatformBettingDomain(data) {
    return this.affiliatesApi.post(
      '/domains-management/platform-betting-domains',
      data,
    );
  },

  /**
   * Get Platform betting domains by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  getPlatformBettingDomainById(id) {
    return this.affiliatesApi.get(
      `/domains-management/platform-betting-domains/${id}`,
    );
  },

  /**
   * Update Platform betting domains
   *
   * @param {String} id
   * @param {Object} data
   * @param {String} data.affiliate_offer_id
   *
   * @return {Promise<Object>} api response
   */
  updatePlatformBettingDomain(id, data) {
    return this.affiliatesApi.put(
      `/domains-management/platform-betting-domains/${id}`,
      data,
    );
  },

  /**
   * Delete Platform betting domains by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  deletePlatformBettingDomain(id) {
    return this.affiliatesApi.delete(
      `/domains-management/platform-betting-domains/${id}`,
    );
  },
};
