var render = function render(_c,_vm){return _c('div',_vm._b({staticClass:"crm-panel-header-subtext",class:{
    'crm-panel-header-subtext_type-default': _vm.props.type === 'default',
    'crm-panel-header-subtext_type-compact': _vm.props.type === 'compact',
    'crm-panel-header-subtext_type-large': _vm.props.type === 'large',
    [_vm.data.staticClass]: _vm.data.staticClass,
    ...(_vm.data.class || {})
  },style:(_vm.data.staticStyle || '')},'div',_vm.data.attrs,false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }