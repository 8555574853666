var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[(_vm.isOpen)?_c('div',{staticClass:"ui-popup"},[_c('div',{staticClass:"wrapper",style:({
        position: _vm.fixed ? 'fixed' : 'absolute',
        width: `${_vm.width}px`,
        left: `calc(50% - ${_vm.width / 2 }px`,})},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"title"},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]})],2),_c('ui-icon',{staticClass:"close",attrs:{"size":19,"lib":"fa","substyle":"fas","name":"times"},nativeOn:{"click":function($event){return (() => {
            if (_vm.escClose) {
              _vm.close(); _vm.$emit('cancel');
              return
            }
            _vm.cancel()
          }).apply(null, arguments)}}})],1),_c('div',{staticClass:"body"},[_vm._t("default")],2),_c('div',{staticClass:"controls"},[_vm._t("controls",function(){return [_c('span',{staticClass:"sync",on:{"click":_vm.reset}},[_c('i',{staticClass:"fas fa-sync-alt"}),_vm._v(" "+_vm._s(_vm.$t('reports.other.reset_to_defaults'))+" ")]),_c('div',{staticClass:"controls__right"},[_c('ui-button',{staticClass:"btn",attrs:{"size":"big","color":"red","lib":"fa","substyle":"fas","icon":"times"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('reports.other.close'))+" ")]),_c('ui-button',{staticClass:"btn",attrs:{"disabled":_vm.actionsDisabled,"size":"big","filled":"","color":"green","lib":"fa","substyle":"fas","icon":"check"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('reports.other.save'))+" ")])],1)]})],2)]),_c('div',{staticClass:"overlay"})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }