<template functional>
  <div
    class="crm-error-panel"
    :class="{
      'crm-error-panel_type-default': props.type === 'default',
      'crm-error-panel_type-no-data': props.type === 'no-data',
      [data.staticClass]: data.staticClass,
      ...(data.class || {})
    }"
    :style="data.staticStyle || ''"
    v-bind="data.attrs"
  >
    <i class="crm-error-panel__icon fas fa-info-circle" />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CrmErrorPanel',
  props: {
    type: {
      type: String,
      default: 'default',
    },
  },
};
</script>

<style lang="scss">
  .crm-error-panel {
    display: flex;
    padding: 7px 16px 7px 12px;
    border-radius: 8px;

    &_type-default {
      font-size: 11px;
      line-height: 1.36;
      background-color: var(--danger-color-alpha-10);
      color: var(--danger-color);
      align-items: baseline;
    }

    &_type-no-data {
      font-size: 12px;
      line-height: normal;
      background-color: var(--crm-panel-bg);
      color: var(--crm-panel-stats);
      align-items: baseline;
    }

    &__icon {
      margin-right: 4px;
    }
  }
</style>
