var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dedicated-site-domains"},[_c('text-area-popup',{ref:"textareaPopup",attrs:{"is-open-popup":_vm.isOpenOtherPopup,"exclude-hidden":"","title":_vm.$t('domainManagement.popup.add_dedicated_site_domain'),"text":_vm.$t('domainManagement.popup.info_input'),"error":!!_vm.errorListDomains.length,"text-error":_vm.$t('domainManagement.popup.text_error'),"text-input":_vm.$t('domainManagement.popup.domain_names'),"action-disabled":_vm.actionDisabled},on:{"changeError":() => _vm.respanseData = [],"confirm":_vm.addDomain,"close":_vm.closeOtherPopup}}),_c('pin-affiliate-to-domain-popup',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPopupOpen),expression:"isPopupOpen"}],attrs:{"is-open":_vm.isPopupOpen,"data":_vm.dataAffiliate,"action":1,"leagues":1,"league-id":1,"match":1},on:{"confirm":_vm.addAffiliateOffer,"close":_vm.onClosePopup}}),_c('ui-confirm',{ref:"confirm",attrs:{"width":480,"type":'warning',"action-name":_vm.$t('domainManagement.buttons.delete'),"action-fa-icon":'trash-alt',"data":_vm.confirmData},on:{"save":_vm.deleteHandler,"close":_vm.confirmClose}},[_c('div',{attrs:{"slot":"title"},domProps:{"innerHTML":_vm._s(_vm.$t('domainManagement.buttons.delete'))},slot:"title"}),_c('div',{staticClass:"body__wrap"},[_c('i',{staticClass:"fas fa-trash-alt warning"}),_c('div',{staticClass:"body__msg",domProps:{"innerHTML":_vm._s(
          _vm.$t('domainManagement.messages.delete', { name: _vm.confirmData.name })
        )}})])]),_c('div',{staticClass:"toolbar"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"section"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('domainManagement.pageTitle.dedicatedSiteDomains')))]),_c('ui-input',{staticClass:"dns-hosting-accounts-search",attrs:{"width":180,"is-search":true},on:{"search":_vm.getPlatformBettingDomain},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('div',{staticClass:"section"},[_c('div',{staticClass:"pagination"},[_c('ui-pagination',{attrs:{"page":_vm.page,"page-size":_vm.limit,"count":_vm.count,"show-size-select":""},on:{"page-change":_vm.paginationHandler}})],1),(_vm.canEdit)?_c('ui-button',{staticClass:"btn btn-add",attrs:{"color":"green","lib":"fa","substyle":"fas","filled":"","icon":"plus"},on:{"click":_vm.openReportsFilterPopup}},[_vm._v(" "+_vm._s(_vm.$t('domainManagement.buttons.add_domain'))+" ")]):_vm._e()],1)])]),_c('ui-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isDataLoading),expression:"isDataLoading"}],staticClass:"table",attrs:{"lazy-loading-delay":4000,"fields":_vm.fields,"data":_vm.data,"rows-count":_vm.limit,"sort":{
      prop: _vm.sortProp,
      order: _vm.sortOrder,
    },"lazy-loading":"","i18n-path":"domainManagement","element-loading-custom-class":"data-loader-spinner"},on:{"sort":_vm.sortingHandler}},[(_vm.canEdit)?_c('template',{slot:"append"},[_c('el-table-column',{attrs:{"width":"75"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('ActionIcon',{attrs:{"tooltip":!!scope.row.affiliate_email
                ? _vm.$t('domainManagement.buttons.edit')
                : _vm.$t('domainManagement.popup.pinned')}},[_c('el-link',{staticClass:"action-ico",attrs:{"type":"success"},on:{"click":function($event){return _vm.clickEditAffiliateHandler(scope.row)}}},[(!!scope.row.affiliate_email)?_c('ui-icon',{attrs:{"name":"pen","color":_vm.$theme.accentColor,"lib":"fa"}}):_c('ui-icon',{attrs:{"name":"link","color":_vm.$theme.accentColor,"lib":"fa"}})],1)],1),_c('ActionIcon',{attrs:{"tooltip":_vm.$t('domainManagement.buttons.delete')}},[_c('el-link',{staticClass:"action-ico",attrs:{"disabled":!!scope.row.affiliate_email,"type":"danger"},on:{"click":function($event){return _vm.confirmOpen(scope.row)}}},[_c('ui-icon',{attrs:{"name":"trash-alt","color":_vm.$theme.dangerColor,"lib":"fa"}})],1)],1)]}}],null,false,230562366)})],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }