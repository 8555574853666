import enLocale from 'element-ui/lib/locale/lang/en';

// meta
import validation from './en/validation';

// components
import theHeader from './en/theHeader';

// views
import login from './en/login';
import tfaSetup from './en/tfaSetup';
import affiliates from './en/affiliates';
import payments from './en/payments';
import players from './en/players';
import reports from './en/reports';
import tools from './en/tools';
import settings from './en/settings';
import profile from './en/profile';
import live from './en/live';
import ui from './en/ui';
import timezone from './en/timezone';
import teams from './en/teams';
import mediaBuying from './en/mediaBuying';
import domainManagement from './en/domainManagement';
import marketingMaterials from './en/marketingMaterials';
import dashboards from './en/dashboards';
import crm from './en/crm';

export default {
  ...validation,
  ...theHeader,
  ...login,
  ...tfaSetup,
  ...affiliates,
  ...payments,
  ...players,
  ...reports,
  ...tools,
  ...settings,
  ...profile,
  ...enLocale,
  ...live,
  ...ui,
  ...timezone,
  ...teams,
  ...mediaBuying,
  ...domainManagement,
  ...marketingMaterials,
  ...dashboards,
  ...crm,
};
