export default {
  props: {
    activity: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      actions: [],
      index: 0,
      deboucedMessageHandlers: {},
    };
  },
  computed: {
    languages() {
      const languages = this.$store.state.misc.languages || [];

      return this.actions.map(action => ({
        ...action,
        ...(languages.find(language => language.code === action.language_code) || {}),
      }));
    },
    selectedLanguages() {
      return this.languages.map(language => language.language_code);
    },
    defaultLanguageActionIndex() {
      return this.actions.findIndex(action => action.is_default);
    },
    defaultLanguage: {
      get() {
        if (this.actionGroupIndex >= 0) {
          const defaultAction = this.actions.find(action => action.is_default);
          if (defaultAction) {
            return defaultAction.language_code;
          }
        }

        return 'en';
      },
      set(value) {
        if (this.actionGroupIndex >= 0) {
          this.actions.forEach((action) => {
            this.$set(action, 'is_default', false);
          });
          const defaultAction = this.actions.find(action => action.language_code === value);
          if (defaultAction) {
            this.$set(defaultAction, 'is_default', true);
          }
        }
      },
    },
  },
  methods: {
    handleSelectTab(index) {
      this.index = index;
    },
    handleMakeDefault(index) {
      this.defaultLanguage = this.languages[index].language_code;
    },
    getDebounceKey(languageCode, field) {
      return `${languageCode}_${field}`;
    },
    setActionsValue(field, value) {
      if (this.index !== -1) {
        const languageCode = this.actions[this.index].language_code;
        const key = this.getDebounceKey(languageCode, field);

        if (!this.deboucedMessageHandlers[key]) {
          const callback = this.$_.debounce((_value) => {
            const index = this.actions.findIndex(item => item.language_code === languageCode);
            if (index !== -1) {
              this.$set(this.actions[index], field, _value);
            }
          }, 300);

          this.$set(this.deboucedMessageHandlers, key, callback);
        }

        this.deboucedMessageHandlers[key](value);
      }
    },
  },
};
