// payments views
import Tools from '@/views/Tools.vue';

import PromoChecker from '@/views/Tools/PromoChecker.vue';
import LandingLeagues from '@/views/Tools/LandingLeagues.vue';
import LeagueMatches from '@/views/Tools/LeagueMatches.vue';
import MarketingMaterials from '@/views/Tools/MarketingMaterials.vue';
import DnsHostingAccounts from '@/views/Tools/DnsHostingAccounts';
import DedicatedSiteDomains from '@/views/Tools/DedicatedSiteDomains';
import DomainRegistrarAccounts from '@/views/Tools/DomainRegistrarAccounts';
import PlatformServers from '@/views/Tools/PlatformServers';
import RotationGroups from '@/views/Tools/RotationGroups';
import ServiceDomains from '@/views/Tools/ServiceDomains';

export default [
  {
    path: '/tools',
    component: Tools,
    meta: {
      requiresAuth: true,
      view: 'tools',
    },
    children: [
      {
        path: 'promo-checker',
        component: PromoChecker,
        name: 'promo-checker',
        meta: {
          view: 'promoChecker',
        },
      },
      {
        path: 'landing-leagues',
        component: LandingLeagues,
        name: 'landing-leagues',
        meta: {
          view: 'landingLeagues',
        },
      },
      {
        path: 'league/:id/matches',
        component: LeagueMatches,
        name: 'league-matches',
        meta: {
          view: 'leagueMatches',
        },
      },
      {
        path: 'dns-hosting-accounts',
        component: DnsHostingAccounts,
        name: 'dns_hosting_accounts',
        meta: {
          view: 'dns_hosting_accounts',
          permission: {
            list: ['dns_hosting_accounts_view'],
          },
        },
      },
      {
        path: 'domain-registrar-accounts',
        component: DomainRegistrarAccounts,
        name: 'domain_registrar_accounts',
        meta: {
          view: 'domain_registrar_accounts',
          permission: {
            list: ['domain_registrar_accounts_view'],
          },
        },
      },
      {
        path: 'dedicated-site-domains',
        component: DedicatedSiteDomains,
        name: 'dedicated_site_domains',
        meta: {
          view: 'dedicated_site_domains',
          permission: {
            list: ['platform_betting_domains_view'],
          },
        },
      },
      {
        path: 'servers',
        component: PlatformServers,
        name: 'platform_servers',
        meta: {
          view: 'platform_servers',
          permission: {
            list: ['platform_servers_view'],
          },
        },
      },
      {
        path: 'rotation-groups',
        component: RotationGroups,
        name: 'rotation_groups',
        meta: {
          view: 'rotation_groups',
          permission: {
            list: ['service_rotation_groups_view'],
          },
        },
      },
      {
        path: 'service-domains',
        component: ServiceDomains,
        name: 'service_domains',
        meta: {
          view: 'service_domains',
          permission: {
            list: ['platform_service_domains_view'],
          },
        },
      },
      {
        path: 'marketing-materials',
        component: MarketingMaterials,
        name: 'marketing-materials',
        meta: {
          view: 'marketing-materials',
          permission: {
            list: ['marketing_materials_view'],
          },
        },
      },
      {
        path: '',
        redirect: 'promo-checker',
      },
      {
        path: '*',
        redirect: 'promo-checker',
      },
    ],
  },
];
