export default {
  mainColor: 'green',
  logoName: 'marathonbet-logo.svg',
  maxLogoHeight: '28px',
  accentColor: '#045998',
  activeColor: '#045998',
  successColor: '#20815e',
  dangerColor: '#cb2f43',
  defaultLanguage: 'en',
  uiTheme: {
    primaryColor: '#045998',
    dangerColor: '#cb2f43',
    infoColor: '#3b94e3',
    warningColor: '#ffce06',
    lightColor: '#fff',
    darkColor: '#303634',
    // the-header
    headerColor: '#0e2344',
    headerBorder: '#2f425e',
    headerText: '#ffffff',
    headerTextHover: '#5191ff',
    headerTextBorder: '#5191ff',
    reportDropdownTitleColor: '#ffffff', // default #4d997e;
    reportDropdownTitleBorder: '#2f425e', // default: #9ecaba;
    reportDropdownBackground: '#045998',
    reportDropdownBackgroundHover: '#5191ff',
    reportDropdownActive: '#ffffff',
    reportDropdownActiveHover: '#ffffff',
    scrollThumb: '#c3c3c3',
    scrollBackground: '#5191ff',
    colorFirst: '#306cd3',
    colorSecond: '#ffce05',
    backLink: '#0250d7',
  },
};
