<template>
  <div class="affiliates-list">
    <send-notification-popup ref="sendNotificationPopup" @confirm="fetchData" />
    <view-notification-popup ref="viewNotificationPopup" />
    <div class="toolbar" @keyup.enter="handleRefresh">
      <div class="wrapper">
        <div class="section filters">
          <span class="title">{{ $t('affiliates.notifications.title') }}</span>
        </div>
        <div class="section">
          <ui-pagination
            :page="page"
            :page-size="limit"
            :count="count"
            show-size-select
            @page-change="pageChange"
          />

          <ui-button
            v-if="hasPermission('affiliates_notifications_edit')"
            class="btn"
            lib="fa"
            filled
            substyle="fas"
            icon="bell"
            @click="handleOpenSendNotificationPopup"
          >
            {{ $t('affiliates.notifications.popup.sendNotification') }}
          </ui-button>
        </div>
      </div>
    </div>

    <ui-table
      v-loading="isDataLoading"
      :fields="fields"
      :data="tableData"
      :rows-count="limit"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      lazy-loading
      i18n-path="affiliates.notifications"
      element-loading-custom-class="data-loader-spinner"
      class="table"
      @sort="handleSort"
    >
      <template slot="append">
        <el-table-column align="center" width="80">
          <template slot="header">
            <i class="fas fa-check" />
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.status === 'pending'" style="word-break: normal">
              {{ $t('affiliates.notifications.sending') }}
            </div>
            <div
              v-else
              class="read-count"
              :class="{
                success: scope.row.read_count === scope.row.sent_count,
              }"
            >
              {{ scope.row.read_count }}/{{ scope.row.sent_count }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" width="45">
          <template slot-scope="scope">
            <ui-icon
              name="eye"
              :color="$theme.accentColor"
              lib="fa"
              class="cursor-pointer"
              @click.native="previewNotification(scope.row)"
            />
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import updateUrl from '@/service/updateUrl';
import detectPermissions from '@/service/detectPermissions';
import SendNotificationPopup from './components/SendNotificationPopup.vue';
import ViewNotificationPopup from './components/ViewNotificationPopup.vue';

import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';

const sizes = {
  1080: 20,
  1440: 30,
};
const lsKey = 'Affiliates/notifications';
const viewName = `${lsKey}/limit`; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'AffiliatesNotifications',
  components: {
    SendNotificationPopup,
    ViewNotificationPopup,
  },
  mixins: [pageSizeMixin],
  data() {
    return {
      viewName,
      fields: [
        {
          name: 'created_at',
          width: '140',
          sortable: 'custom',
          align: 'left',
          format: 'date-time',
        },
        {
          name: 'title',
          minWidth: '320',
          width: '320',
          class: 'row-icon',
          computedValueHtml(row) {
            const warnClass = row.is_required_to_read ? 'visible' : 'hidden';
            return `<div>${row.title}</div><i class="fas fa-exclamation-triangle ${warnClass}" />`;
          },
        },
        {
          name: 'message',
          class: 'text',
          minWidth: '425',
        },
      ],
      isDataLoading: false,
      tableData: [],
      page: 1,
      limit: pageSize,
      count: 0,
      searchQuery: '',
      options: {
        sort_column: '',
        sort_dir: '',
        search: '',
      },
      errors: {},
      sortProp: '',
      sortOrder: '',
      fetchData: Function,

      blockedFetch: false,
    };
  },

  computed: {
    ...mapGetters({
      countries: 'misc/countries',
      currencies: 'misc/currencies',
      groups: 'misc/groups',
      currentAcl: 'auth/currentAcl',
      adminAcl: 'auth/adminAcl',
      reportsSettings: 'reports/reportsSettings',
    }),
  },

  watch: {
    searchQuery() {
      this.options.search = this.searchQuery;
    },
    sortProp(newVal) {
      this.options.sort_column = this.sortProp;
      /*
       * Прверяем сортировку чтобы не было шибки с сервера 422
       * */
      if (
        this.fields.some(
          item => item.name === this.options.sort_column && item.sortable === 'custom',
        )
      ) {
        this.$ls.set(`${lsKey}/sort_column`, newVal || 'created_at');
      }
    },
    sortOrder(newVal) {
      this.options.sort_dir = this.sortOrder;
      this.$ls.set(`${lsKey}/sort_dir`, newVal || 'desc');
    },
  },

  created() {
    this.fetchData = this.$_.debounce(this.fetchDataD, 300);
    if (detectPermissions.checkRequest('affiliates_notifications_view')) {
      /*
       * Считывай hash из routers
       * */
      const _urlData = updateUrl.getParseParams(this.$route.hash);

      if (!this.$_.isEmpty(_urlData)) {
        /*
         * Приводим объект к дефолтным данным
         * */
        const { limit = 0, page = 1 } = _urlData;

        this.limit = Number(limit) || pageSize;
        this.page = Number(page) || 1;

        delete _urlData.page;
        delete _urlData.limit;

        this.$_.merge(this.options, _urlData);

        this.sortProp = this.$_.get(
          _urlData,
          ['sort_column'],
          'created_at',
        );
        this.sortOrder = this.$_.get(_urlData, ['sort_dir'], 'desc');

        this.searchQuery = this.$_.get(this.options, ['search']) || '';

        /*
         * Блокируем функцию на один вызов
         * */
        if (this.searchQuery.length > 0) {
          this.blockedFetch = true;
        }
      } else {
        this.sortProp = this.$ls.get(`${lsKey}/sort_column`)
          ? this.$ls.get(`${lsKey}/sort_column`)
          : 'created_at';
        this.sortOrder = this.$ls.get(`${lsKey}/sort_dir`)
          ? this.$ls.get(`${lsKey}/sort_dir`)
          : 'desc';

        this.limit = +this.$ls.get(`${lsKey}/limit`) || pageSize;
      }

      this.fetchData();
    }
    detectPermissions.checkRoute('affiliates_notifications_view');
  },

  mounted() {
    detectPermissions.checkRoute('affiliates_notifications_view');
  },

  methods: {
    hasPermission(permission) {
      return (
        this.currentAcl[permission] === 'allow' || this.adminAcl.is_superuser
      );
    },
    previewNotification(data) {
      this.$refs.viewNotificationPopup.open(data);
    },
    handleOpenSendNotificationPopup() {
      this.$refs.sendNotificationPopup.open();
    },
    /*
     * Формируем запроос
     * */
    _query() {
      return {
        ...this.options,
        limit: this.limit,
        offset: this.limit * this.page - this.limit,
      };
    },

    /*
     * Делаем debounce чтобы не было повторных запрос при изминении фильтра
     * */
    fetchDataD(action) {
      if (action !== 'silent') {
        this.isDataLoading = true;
      }
      /*
       * Формируем URL Filter
       * */
      this._completedFilterUrl();

      /* подготовка опций для мультиаккаунтов */
      const options = this._query();

      return this.$api
        .getAffiliatesNotifications(options)
        .then((response) => {
          this.count = response.data.misc.count;
          this.tableData = this.$_.cloneDeep(response.data.payload);
          setTimeout(() => {
            this.blockedFetch = false;
          }, 300);
          this.errors = {};
        })
        .catch((e) => {
          this.errors = e.data.errors;
        })
        .finally(() => this.isDataLoading = false);
    },

    pageChange(page, size) {
      if (this.blockedFetch) {
        return;
      }

      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.fetchData('pageChange');
      }
    },
    handleSort({ prop, order }) {
      if (this.blockedFetch) {
        return;
      }

      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.fetchData('handleSort');
    },
    handleRefresh() {
      if (this.blockedFetch) {
        return;
      }

      this.page = 1;
      this.fetchData('handleRefresh');
    },
    /*
     * Создаем filter URL
     * */
    _completedFilterUrl() {
      const _dataFilters = this._query();
      _dataFilters.page = this.page;

      delete _dataFilters.offset;

      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>
