<template>
  <div
    v-if="isOpen"
    class="create-segment"
  >
    <div class="popup">
      <div class="head">
        <span class="name">{{ popupType === 'new' ? this.$t('crm.segments.create_segment_title') : this.$t('crm.segments.edit_segment_title') }}</span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div
        v-loading="isLoading"
        class="body"
      >
        <item-card-layout-column type="grow">
          <div class="content-header">
            <div class="select-wrap">
              <span class="select-wrap__label">{{ $t('crm.segments.type.title') }}</span>
              <el-radio-group
                v-model="segment.type"
                size="small"
                :disabled="isSaveLoading"
              >
                <el-radio-button label="fixed">
                  {{ $t('crm.segments.type.fixed') }}
                </el-radio-button>
                <el-radio-button label="dynamic">
                  {{ $t('crm.segments.type.dynamic') }}
                </el-radio-button>
                <el-radio-button label="manual">
                  {{ $t('crm.segments.type.manual') }}
                </el-radio-button>
              </el-radio-group>
            </div>
            <div class="select-wrap select-wrap_grow">
              <ui-input
                v-model="segment.name"
                :label="$t('crm.segments.create_segment_name_input')"
                autosize
                size="big"
                :placeholder="$t('crm.segments.create_segment_name_input_placeholder')"
                class="form-input"
                type="text"
                :error="getError('name')"
              />
            </div>
          </div>
          <div class="content-info">
            <div v-if="['fixed', 'dynamic'].includes(segment.type)" v-loading="isSegmentInfoLoading || filterLoading">
              <div class="title">
                <span>{{ $t('crm.segments.configure.title') }}</span>
              </div>
              <tree
                class="create-segment__rule-tree"
                type="outer"
              >
                <tree-branch>
                  <el-radio-group
                    v-model="segment.rules_group.logical_relations"
                    size="small"
                  >
                    <el-radio-button label="and">
                      {{ $t('crm.segments.logical_relations.and') }}
                    </el-radio-button>
                    <el-radio-button label="or">
                      {{ $t('crm.segments.logical_relations.or') }}
                    </el-radio-button>
                  </el-radio-group>
                </tree-branch>
                <tree-branch
                  v-for="(group, id) in segment.rules_group.rules"
                  :key="id"
                  class="create-segment__rule-tree-branch"
                >
                  <panel
                    class="create-segment__rule-panel"
                    :error="!$_.isEmpty(errorFiltersBlock(id))"
                  >
                    <tree-branch>
                      <el-radio-group
                        v-model="group.logical_relations"
                        size="small"
                      >
                        <el-radio-button label="and">
                          {{ $t('crm.segments.logical_relations.and') }}
                        </el-radio-button>
                        <el-radio-button label="or">
                          {{ $t('crm.segments.logical_relations.or') }}
                        </el-radio-button>
                      </el-radio-group>
                    </tree-branch>
                    <tree-branch
                      v-for="(group_rule, group_rule_id) in group.rules"
                      :key="group_rule_id"
                    >
                      <filter-component
                        :available-filters="availableFilters"
                        :group-rule="group_rule"
                        :current-brand="currentBrand"
                        :errors="errorFilters(id, group_rule_id)"
                        @loading="filterLoading = $event"
                        @deleteFilter="deleteFilter(id, group_rule_id)"
                      />
                    </tree-branch>
                    <span
                      class="add-new-rule"
                      @click="addNewFilter(id)"
                    >
                      <ui-button
                        :icon-size="12"
                        color="green"
                        lib="fa"
                        substyle="fas"
                        icon="plus"
                        class="btn"
                      />
                      {{ $t('crm.segments.configure.add_new_rule') }}
                    </span>
                  </panel>
                  <div
                    v-if="!$_.isEmpty(errorFiltersBlock(id))"
                    class="filter-item__field-error"
                    style="text-transform: uppercase"
                  >
                    {{ errorFiltersBlock(id).message }}
                  </div>
                </tree-branch>
              </tree>
              <div
                class="dashboard-overview__metric-card-add"
                @click="addNewRuleGroup()"
              >
                <adm-ui-icon :icon="['fas', 'fa-plus']" />
                <adm-ui-header tag="h3">
                  {{ $t('crm.segments.configure.add_new_group') }}
                </adm-ui-header>
              </div>
            </div>
            <div v-if="segment.type === 'manual'">
              <div class="title">
                <span>{{ $t('crm.segments.manual.title') }}</span>
              </div>
              <div v-if="!allowChangeManualSegment" class="replace-container">
                <div>
                  <span v-if="$_.isNumber(segment.players_count)">{{ $t('crm.segments.manual.replaceNotice', { playersCount }) }}</span>
                </div>
                <div class="action" @click="replaceSegment">
                  <ui-icon
                    class="ui-m-sm-r"
                    name="sync-alt"
                    :color="$theme.accentColor"
                    lib="fa"
                  />
                  {{ $t('crm.segments.manual.replace') }}
                </div>
              </div>
              <div>
                <el-radio-group
                  v-model="manual.type"
                  class="manual-radio"
                  :disabled="!allowChangeManualSegment"
                >
                  <el-radio
                    label="file"
                    class="checkbox"
                  >
                    {{ $t(`crm.segments.manual.type.csv`) }}
                  </el-radio>
                  <el-radio
                    label="text"
                    class="checkbox"
                  >
                    {{ $t(`crm.segments.manual.type.manual`) }}
                  </el-radio>
                </el-radio-group>
              </div>
              <div v-if="manual.type === 'file'" class="content">
                <div>
                  <UploadFile
                    v-model="segment.files"
                    accept=".csv"
                    :limit-file="1"
                    class="upload-file"
                    :error="getError('players_file_id')"
                    :disabled="!allowChangeManualSegment"
                    @is-loading="setLoadingFile"
                  >
                    <template slot="title">
                      <div class="ui-m-xl-t">
                        <span class="upload-title">{{ $t('crm.segments.manual.csv.title') }}</span>
                      </div>
                      <div v-if="allowChangeManualSegment" class="note-container">
                        <ui-icon
                          name="triangle-exclamation"
                          color="#f19d41"
                          lib="fa"
                          substyle="fas"
                          :size="16"
                        />
                        <div class="ui-m-md-l" v-html="$t('crm.segments.manual.csv.note')" />
                      </div>
                    </template>
                  </UploadFile>
                </div>
              </div>
              <div v-if="manual.type === 'text'" class="content">
                <div class="ui-m-xl-t">
                  <span class="text-title" v-html="$t(`crm.segments.manual.text.title`)" />
                </div>
                <div class="ui-m-sm-t">
                  <el-input
                    v-model="segment.players_ids"
                    :disabled="!allowChangeManualSegment"
                    :placeholder="$t(`crm.segments.manual.text.placeholder`)"
                    :rows="10"
                    resize="none"
                    type="textarea"
                    :class="{'error-textarea': !!getError('players_ids')}"
                  />
                  <div
                    v-if="getError('players_ids')"
                    class="error-content"
                  >
                    {{ getError('players_ids') }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="segmentId !== '' && !isLocalSegment"
              class="segment-comments"
            >
              <comments
                :is-editable="true"
                :comments="comments"
                :post-id="segment.id"
                :comments-loading.sync="commentsLoading"
                @post-comments="postComment"
                @get-comments="getComments"
              />
            </div>
          </div>
        </item-card-layout-column>
        <item-card-layout-column v-if="segment.type !== 'manual'">
          <segment-info
            :stats="stats"
            :stats-loader="isStatsLoading"
            :content-loader="isLoading"
            @refreshStats="checkChanges"
          />
        </item-card-layout-column>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading || isStatsLoading || isSaveLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="!isActive"
          @click="save()"
        >
          <i class="fas fa-check plus-icon-custom" />{{ $t('crm.buttons.save') }}
        </ui-button>
        <ui-button
          v-if="!isLocalSegment"
          :color="$theme.mainColor"
          filled
          :disabled="!isActive"
          @click="saveAndReview()"
        >
          <i class="fas fa-check-double plus-icon-custom" />{{ $t('crm.buttons.save_and_send') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import numeral from 'numeral';
import * as Tree from '@/views/CRM/components/Tree';
import * as Panel from '@/views/CRM/components/Panel';
import SegmentInfo from '@/views/CRM/Segments/SegmentInfo.vue';
import FilterComponent from '@/views/CRM/components/FilterComponent.vue';
import ItemCardLayoutColumn from '@/components/ItemCard/ItemCardLayoutColumn.vue';
import Comments from '@/components/Comments.vue';
import SegmentStatsMixin from '@/views/CRM/mixins/segment-stats-mixin.js';
import UploadFile from '@/components/UploadFile';

import app from '@/main';

export default {
  name: 'CreateSegment',

  components: {
    SegmentInfo,
    FilterComponent,
    ItemCardLayoutColumn,
    ...Tree,
    ...Panel,
    Comments,
    UploadFile,
  },

  mixins: [SegmentStatsMixin],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    popupType: {
      type: String,
      required: true,
    },
    segmentId: {
      type: String,
      required: true,
    },
    localSegment: {
      type: Object,
      default: null,
    },
    localSegmentErrors: {
      type: Object,
      default: () => ({}),
    },
    isLocalSegment: {
      type: Boolean,
      default: false,
    },
    currentBrand: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isSaveLoading: false,
      isLoading: false,
      statsLoadingCount: 0,
      commentsLoading: false,
      isSegmentInfoLoading: false,
      comments: [],
      allowChangeManualSegment: true,
      filterLoading: false,
      filter: {
        rule_type: '',
        operator: '',
        values: '',
      },
      rule: {
        logical_relations: 'and',
        rules: [],
      },
      segment: {},
      stats: {},
      manual: {
        type: 'file',
        files: [],
      },
      segmentTemplate: {
        name: '',
        type: 'fixed',
        rules_group: {
          logical_relations: 'and',
          rules: [
            {
              logical_relations: 'and',
              rules: [
                {
                  rule_type: '',
                  operator: '',
                  values: '',
                },
              ],
            },
          ],
        },
      },
      availableFilters: [
        {
          name: 'registration_player_attributes',
          items: [
            {
              name: 'registration_timestamp',
              comparable: true,
              type: 'date_picker',
            },
            {
              name: 'latest_deposit_timestamp',
              comparable: true,
              type: 'date_picker',
            },
            {
              name: 'latest_bet_timestamp',
              comparable: true,
              type: 'date_picker',
            },
            {
              name: 'latest_sport_bet_timestamp',
              comparable: true,
              type: 'date_picker',
            },
            {
              name: 'latest_games_bet_timestamp',
              comparable: true,
              type: 'date_picker',
            },
            {
              name: 'seconds_since_registration',
              comparable: true,
              type: 'time',
            },
            {
              name: 'seconds_since_last_deposit',
              comparable: true,
              type: 'time',
            },
            {
              name: 'seconds_since_last_bet',
              comparable: true,
              type: 'time',
            },
            {
              name: 'seconds_since_last_sports_bet',
              comparable: true,
              type: 'time',
            },
            {
              name: 'seconds_since_last_games_bet',
              comparable: true,
              type: 'time',
            },
            {
              name: 'player_country_code',
              comparable: false,
              type: 'text',
              dictionary: 'countries',
            },
            {
              name: 'player_region_code',
              comparable: false,
              type: 'text',
              dictionary: 'regions',
            },
            {
              name: 'player_contacts',
              comparable: false,
              type: 'player_contacts',
            },
            {
              name: 'player_affiliate_id',
              comparable: false,
              type: 'player_affiliate_id',
            },
            {
              name: 'player_sub_affiliate_id',
              comparable: false,
              type: 'player_sub_affiliate_id',
            },
          ],
        },
        {
          name: 'deposits',
          items: [
            {
              name: 'has_deposits',
              comparable: false,
              type: 'boolean',
            },
            {
              name: 'favourite_payment_system_id',
              comparable: false,
              type: 'text',
              dictionary: 'paymentsSystems',
            },
            {
              name: 'deposits_count',
              comparable: true,
              type: 'number',
            },
            {
              name: 'deposits_sum',
              comparable: true,
              type: 'number',
            },
          ],
        },
        {
          name: 'gaming_activity',
          items: [
            {
              name: 'betting_profit_sum',
              comparable: true,
              type: 'number',
            },
            {
              name: 'has_bets',
              comparable: false,
              type: 'boolean',
            },
            {
              name: 'favourite_bet_by_sum',
              comparable: false,
              type: 'sport_casino',
            },
            {
              name: 'favourite_bet_by_count',
              comparable: false,
              type: 'sport_casino',
            },
            {
              name: 'has_sports_bets',
              comparable: false,
              type: 'boolean',
            },
            {
              name: 'has_games_bets',
              comparable: false,
              type: 'boolean',
            },
            {
              name: 'favourite_sport_type',
              comparable: false,
              type: 'text',
              dictionary: 'sportType',
            },
            {
              name: 'favourite_sport_league',
              comparable: false,
              type: 'text',
              dictionary: 'sportLeague',
            },
            {
              name: 'sport_bets_count',
              comparable: true,
              type: 'number',
            },
            {
              name: 'sport_bets_sum',
              comparable: true,
              type: 'number',
            },
            {
              name: 'sport_bets_live_count',
              comparable: true,
              type: 'number',
            },
            {
              name: 'sport_bets_live_sum',
              comparable: true,
              type: 'number',
            },
            {
              name: 'games_bets_count',
              comparable: true,
              type: 'number',
            },
            {
              name: 'games_bets_sum',
              comparable: true,
              type: 'number',
            },
          ],
        },
      ],
      errors: {},
    };
  },

  computed: {
    playersCount() {
      return numeral(this.segment.players_count).format('0,0');
    },
    preparedSegmentData() {
      const segment = this.clearSegment(this.segment);

      if (segment.type === 'manual') {
        if (this.manual.type === 'file') {
          const { files, ...restSegment } = this.$_.omit(segment, ['players_ids', 'rules_group']);
          const file_id = this.$_.get(segment, ['files', 0, 'uploadId']);

          return {
            ...restSegment,
            files,
            ...((this.popupType === 'new' || file_id) && { players_file_id: file_id }),
          };
        }

        if (this.manual.type === 'text') {
          const { players_ids = '', ...restSegment } = this.$_.omit(segment, ['players', 'rules_group']);
          const ids = players_ids.trim().split('\n')
            .map(player => player.trim())
            .filter(player => player);

          return {
            ...restSegment,
            ...((this.popupType === 'new' || ids.length) && { players_ids: ids }),
          };
        }
      }

      return this.$_.omit(segment, ['players', 'players_ids']);
    },
    isStatsLoading() {
      return this.statsLoadingCount !== 0;
    },
    isActive() {
      if (this.segment.type !== 'manual') {
        const segmentCopy = this.$_.cloneDeep(this.segment);
        segmentCopy.rules_group.rules = segmentCopy.rules_group.rules?.filter((group) => {
          group.rules = group.rules.filter(filter => filter.rule_type === '' || filter.operator === '' || filter.values === '' || filter.values.length
            === 0);
          return group.rules.length !== 0;
        });
      }

      return !this.isLoading && !this.isStatsLoading && !this.isSaveLoading;
    },
  },

  watch: {
    async isOpen(open) {
      if (open) {
        if (this.isLocalSegment && this.localSegment) {
          const { players_ids = '' } = this.localSegment;

          this.segment = this.$_.cloneDeep({
            ...this.localSegment,
            files: [],
            players_ids: Array.isArray(players_ids) ? players_ids.join('\n') : players_ids,
            rules_group: {
              ...this.segmentTemplate.rules_group,
              ...this.localSegment.rules_group,
            },
          });
          this.errors = this.$_.cloneDeep(this.localSegmentErrors);
          this.checkChanges();
        } else {
          this.segment = this.$_.cloneDeep(this.segmentTemplate);
          this.checkChanges();
        }

        if (this.popupType !== 'new' && !this.isLocalSegment) {
          await this.getSegment();
        }
        this.allowChangeManualSegment = this.segment.type !== 'manual';
      }
    },
  },
  methods: {
    setLoadingFile(val) {
      this.isSaveLoading = val;
    },
    replaceSegment() {
      this.allowChangeManualSegment = true;
    },
    errorFilters(index, group_rule_id) {
      if (!this.$_.isEmpty(this.errors)
            && this.errors.rules_group
            && this.errors.rules_group.rules
            && this.errors.rules_group.rules[index]
            && this.errors.rules_group.rules[index].rules) {
        return this.errors.rules_group.rules[index].rules[group_rule_id];
      }
      if (!this.$_.isEmpty(this.errors)
            && this.errors.rules_group
            && this.errors.rules_group.rules
            && this.errors.rules_group.rules[index]) {
        return this.errors.rules_group.rules[index];
      }
      return {};
    },
    errorFiltersBlock(index) {
      if (!this.$_.isEmpty(this.errors)
            && this.errors.rules_group
            && this.errors.rules_group.rules
            && this.errors.rules_group.rules[index]
            && this.errors.rules_group.rules[index].message) {
        return this.errors.rules_group.rules[index];
      }
      return {};
    },

    async checkChanges(value = this.segment) {
      if (!this.$_.isEmpty(value) && value.type !== 'manual') {
        const checkSegment = this.clearSegment(value);
        if (checkSegment.rules_group.rules?.length !== 0) {
          await this.getStats(checkSegment);
        } else {
          this.stats = {};
        }
      }
    },

    async getStats(segmentRules) {
      this.statsLoadingCount = this.statsLoadingCount + 1;
      try {
        segmentRules.site_id = this.currentBrand;
        const { data: { payload } } = this.segmentId && this.segment.type === 'manual'
          ? await this.$api.getSegmentStatsById(this.segmentId)
          : await this.$api.getSegmentStats(segmentRules);
        this.stats = payload;
      } finally {
        this.statsLoadingCount = this.statsLoadingCount - 1;
      }
    },

    async getSegment() {
      this.isLoading = true;
      this.isSegmentInfoLoading = true;
      this.commentsLoading = true;

      const { data: { payload } } = await this.$api.getSegment(this.segmentId);

      this.segment = this.$_.cloneDeep({
        ...payload,
        rules_group: {
          ...this.segmentTemplate.rules_group,
          ...payload.rules_group,
        },
      });

      this.checkChanges();
      await this.getComments();

      this.$nextTick(() => {
        this.isLoading = false;
        this.isSegmentInfoLoading = false;
      });
    },

    addNewRuleGroup() {
      const rule = this.$_.cloneDeep(this.rule);
      const filter = this.$_.cloneDeep(this.filter);
      rule.rules.push(filter);
      this.segment.rules_group.rules.push(rule);
    },

    addNewFilter(id) {
      const filter = this.$_.cloneDeep(this.filter);
      this.segment.rules_group.rules[id].rules.push(filter);
    },

    deleteFilter(rulesId, filterId) {
      const rulesList = this.segment.rules_group.rules;
      const filterList = rulesList[rulesId].rules;
      if (filterList.length !== 1 || rulesList.length !== 1) {
        filterList.splice(filterId, 1);
      }
      if (filterList.length === 0) {
        rulesList.splice(rulesId, 1);
        if (!this.$_.isEmpty(this.errors)) this.errors.rules_group.rules.splice(rulesId, 1);
      }
      if (!this.$_.isEmpty(this.errors)) this.errors.rules_group.rules[rulesId].rules.splice(filterId, 1);
    },

    close() {
      this.$emit('closePopupSegment');
      this.segment = {};
      this.errors = {};
      this.stats = {};
      this.comments = [];
    },

    getError(field) {
      return this.$_.get(this.errors, [field, 0, 'message']) || this.$_.get(this.errors, [field, 'message'], '');
    },

    async save() {
      if (!this.isLocalSegment) {
        this.isSaveLoading = true;
        this.segment.site_id = this.currentBrand;

        try {
          if (this.popupType === 'new') {
            await this.$api.postSegments(this.preparedSegmentData);
          } else {
            await this.$api.putSegment(this.segmentId, this.preparedSegmentData);
          }
          await this.close();
        } catch (e) {
          if (e.data.code === 'WRONG_FILE_FORMAT_ERROR') {
            this.errors.players_file_id = e.data;
          } else {
            this.errors = e.data?.errors;
          }
        } finally {
          this.isSaveLoading = false;
        }
      } else {
        const { type } = this.segment;
        this.$emit('updateLocalSegment', type === 'manual' ? this.preparedSegmentData : this.segment, this.stats);
        await this.close();
      }
    },

    async saveAndReview() {
      this.isSaveLoading = true;
      this.segment.site_id = this.currentBrand;
      try {
        if (this.popupType === 'new') {
          const { data: { payload: { id } } } = await this.$api.postSegments(this.preparedSegmentData);
          await this.$api.postSegmentChangeStatus(id, 'to-review');
        } else {
          const { data: { payload: { id } } } = await this.$api.putSegment(this.segmentId, this.preparedSegmentData);
          await this.$api.postSegmentChangeStatus(id, 'to-review');
        }
        await this.close();
      } catch (e) {
        if (e.data.code === 'WRONG_FILE_FORMAT_ERROR') {
          this.errors.players_file_id = e.data;
        } else {
          this.errors = e.data?.errors;
        }
      } finally {
        this.isSaveLoading = false;
      }
    },

    postComment(comment) {
      this.commentsLoading = true;
      this.$api
        .postSegmentComments(this.segmentId, comment)
        .then(() => {
          this.getComments();
        })
        .catch(() => {
          this.commentsLoading = false;
        });
    },

    getComments() {
      this.$api
        .getSegmentComments(this.segmentId)
        .then((response) => {
          this.comments = this.$_.cloneDeepWith(response.data.payload);
          app.$eventBus.$emit('defaultCommentsState');
        })
        .finally(() => {
          this.commentsLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.replace-container {
  display: flex;
  justify-content: space-between;
  background-color: #f5f7fa;
  padding: 8px;
  border-radius: 4px;
  color: #303634;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;

  .action {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    color: var(--primary-color);
    cursor: pointer;
  }
}
.create-segment {
  /deep/ .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    box-shadow: none;
  }
}

.upload-title {
  font-size: 14px;
  line-height: 18px;
}

.note-container {
  font-size: 13px;
  display: flex;
  align-items: flex-start;
  line-height: 18px;
  border-radius: 4px;
  padding: 8px;
  margin-top: 4px;

  background-color: #fefae8;

  /deep/ b {
    font-weight: 600;
  }
}

.error-content {
  margin-top: 6px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  font-style: italic;
  color: var(--danger-color);
  text-align: right;
}

.text-title {
  font-size: 14px;

  /deep/ span {
    color: #606266;
  }
}

.manual-radio {
  /deep/.el-radio {
    font-weight: 400;
    margin-right: 16px;

    .el-radio__label {
      font-size: 12px;
    }
  }
}
</style>
