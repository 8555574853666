<template>
  <ui-confirm
    ref="confirm"
    :width="480"
    :type="confirmPw.type"
    :action-name="confirmPw.actionName"
    :action="confirmPw.action"
    :data="confirmPw.data"
    action-fa-icon="check"
    class="reset-pw"

    @close="confirmClose"
    @save="resetPassword"
  >
    <div
      slot="title"
      v-html="confirmPw.title"
    />
    <div
      class="body__msg"
      v-html="confirmPw.msg"
    />
    <div class="body__wrap">
      <ui-input
        v-model="newPwd"
        :placeholder="$t('affiliates.resetPassword.newPassword')"
        :label="$t('affiliates.resetPassword.newPassword')"
        :error="errors.new_password"
        autosize
        class="edit-input"
        type="password"
        @input="resetErr"
      />
      <ui-input
        v-model="newPwdRepeat"
        :placeholder="$t('affiliates.resetPassword.repeatPassword')"
        :label="$t('affiliates.resetPassword.repeatPassword')"
        :error="errors.new_password"
        autosize
        class="edit-input"
        type="password"
        @input="resetErr"
      />
    </div>
  </ui-confirm>
</template>

<script>
export default {
  name: 'ConfirmEditPopup',
  props: {
    onCancel: {
      type: Function,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      newPwd: '',
      newPwdRepeat: '',
      confirmPw: {
        title: '',
        msg: '',
        type: 'danger',
        action: '',
        actionName: '',
        data: {},
      },
      errors: {},
    };
  },
  watch: {
    isOpen(v) {
      if (v) {
        this.confirmPw.title = `${this.$t('affiliates.resetPassword.title_1', { affiliateEmail: this.email })}`;
        this.confirmPw.msg = `${this.$t('affiliates.resetPassword.msg_1', { affiliateEmail: this.email })}`;
        this.confirmPw.actionName = `${this.$t('affiliates.resetPassword.action_1')}`;
        this.$refs.confirm.open();
      }
    },
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && this.isOpen) {
        this.$emit('close');
      }
    });
  },
  methods: {
    confirmClose() {
      this.newPwd = '';
      this.newPwdRepeat = '';
      this.errors = {};
      this.$emit('close');
    },
    resetPassword() {
      if (this.newPwd === this.newPwdRepeat) {
        this.$api
          .resetAffiliatePassword(this.id, this.newPwd)
          .then(() => {
            this.$refs.confirm.close();
            this.$emit('close');
          })
          .catch((error) => {
            this.errors = this.$_.cloneDeepWith(error.data.errors);
          });
      } else {
        this.errors = {
          new_password: [
            {
              code: 2002,
              message: this.$t('affiliates.resetPassword.error_1'),
            },
          ],
        };
      }
    },

    resetErr() {
      this.errors = {};
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/colors.scss";

.reset-pw {
  .head{
    .title{
      span{
        white-space: nowrap;
      }
    }
  }
  .body{
    &__wrap{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      label:first-child{
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }
  }
}
</style>
