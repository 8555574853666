import { SMSCalculator } from '@fasttrack-solutions/sms-calculator';

SMSCalculator.encoding = {
  UTF16: [70, 70, 70],
  GSM_7BIT: [140, 140, 140],
  GSM_7BIT_EX: [140, 140, 140],
};

export default {
  data() {
    return {
      message: '',
    };
  },
  computed: {
    messageStats() {
      const { numberOfSMS, remaining } = SMSCalculator.getCount(this.message);
      return this.$t('crm.activities.actions.action_popup.sms_fields.symbols_remaining', { numberOfSMS, remaining });
    },
    messageStyle() {
      const stats = SMSCalculator.getCount(this.message);
      const isMoreThanOneSms = stats.numberOfSMS > 1;

      return {
        ...(isMoreThanOneSms && {
          color: this.$theme.dangerColor,
          'font-weight': 700,
        }),
      };
    },
  },
};
