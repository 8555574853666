export default {
  signIn(payload) {
    return this.affiliatesApi.post('/auth/login', payload);
  },
  signOut() {
    return this.affiliatesApi.post('/auth/logout');
  },
  confirm2fa(payload) {
    return this.affiliatesApi.post('/auth/totp', payload);
  },
  significant(status, payload) {
    return this.affiliatesApi.post(`/auth/significant-actions/${status}`, payload);
  },
};
