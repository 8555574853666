<template>
  <div class="user-info profile-info">
    <ui-confirm
      ref="editUser"
      :width="480"
      :action-name="`${$t(`team_popup.save_button`)}`"
      :action-disabled="isLoading"
      type="success"
      action-fa-icon="check"
      class="edit-user-popup"
      @save="saveEditUser"
    >
      <div
        slot="title"
        v-html="editUserTitle"
      />
      <div class="body__wrap">
        <div class="select-wrap">
          <ui-input
            v-model="popupInfo.name"
            :error="errors.name"
            :placeholder="$t(`team_popup.name_placeholder`)"
            :label="$t(`team_popup.name_label`)"
            size="big"
            autosize
          />
        </div>
        <div class="select-wrap">
          <div class="select-block select-block__full">
            <span class="label">{{ $t(`team_popup.timezone_label`) }}</span>
            <el-select
              v-model="popupInfo.timezone"
              :placeholder="$t(`team_popup.timezone_placeholder`)"
              filterable
              class="select"
              size="big"
            >
              <el-option
                v-for="(item) in timezones"
                :key="item.zone"
                :label="item.name"
                :value="item.zone"
              />
            </el-select>
          </div>
        </div>
        <div class="select-wrap">
          <div class="select-block">
            <span class="label">{{ $t(`team_popup.messenger_label`) }}</span>
            <el-select
              v-model="popupInfo.messenger"
              :placeholder="$t(`team_popup.messenger_placeholder`)"
              size="big"
              @change="popupInfo.messenger_id = ''"
            >
              <el-option
                v-for="item in messengers"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
          <div class="select-block">
            <ui-input
              v-model="popupInfo.messenger_id"
              :error="errors.messenger_id"
              :disabled="!popupInfo.messenger"
              :placeholder="messengerPlaceholder"
              :label="$t(`team_popup.messenger_id_label`)"
              size="big"
              autosize
            />
          </div>
        </div>
      </div>
    </ui-confirm>
    <ui-confirm
      ref="editSignificantTtl"
      :width="480"
      :action-name="`${$t(`team_popup.save_button`)}`"
      :action-disabled="isLoading"
      type="success"
      action-fa-icon="check"
      class="edit-user-popup"
      @save="saveSignificantTtl"
    >
      <div slot="title">
        {{ $t('profile_popup.action_unlock_period') }}
      </div>
      <div class="body__wrap">
        <div class="ui-d-flex ui-p-xl-b">
          <i class="fas fa-exclamation-triangle warning" />
          <div class="body__msg">
            {{ $t('profile_popup.unlock_period_warning') }}
          </div>
        </div>

        <div class="select-block select-block__full">
          <span class="label">{{ $t('profile_popup.action_unlock_period') }}</span>
          <el-select
            v-model="significantTtl"
            :placeholder="$t('profile_popup.action_unlock_period')"
            filterable
            class="select"
            size="big"
          >
            <el-option
              v-for="{ label, value } in timeMap"
              :key="value"
              :label="label"
              :value="value"
            />
          </el-select>
        </div>
      </div>
    </ui-confirm>
    <ui-confirm
      ref="isResetOpen"
      :width="480"
      :action-name="`${this.$t('profile_popup.reset_password_action')}`"
      class="reset-user-password"
      type="success"
      action-icon="actionIcon"
      action-fa-icon="check"
      :action-disabled="isLoading"
      @save="resetUserPassword"
      @close="confirmClose"
    >
      <div
        slot="title"
        v-html="confirmTitle"
      />
      <div class="body__wrap">
        <ui-input
          v-model="resetPassword.old_password"
          :placeholder="$t('profile_popup.reset_password_old_placeholder')"
          :label="$t('profile_popup.reset_password_old_label')"
          :error="errors.old_password"
          autosize
          class="edit-input"
          type="password"
        />
        <ui-input
          v-model="resetPassword.new_password"
          :placeholder="$t('profile_popup.reset_password_new_placeholder')"
          :label="$t('profile_popup.reset_password_new_label')"
          :error="errors.new_password"
          autosize
          class="edit-input"
          type="password"
        />
        <ui-input
          v-model="repeat_new_password"
          :placeholder="$t('profile_popup.reset_password_repeat_placeholder')"
          :label="$t('profile_popup.reset_password_repeat_label')"
          :error="errors.new_password"
          autosize
          class="edit-input"
          type="password"
        />
      </div>
    </ui-confirm>
    <ui-confirm
      ref="isSetupQrOpen"
      :width="480"
      :action-name="`${$t('profile_popup.done_button')}`"
      class="reset-user-password reset_tfa"
      type="success"
      action-icon="actionIcon"
      action-fa-icon="check"
      @save="confirmCloseQr"
      @close="confirmClose"
    >
      <div
        slot="title"
        v-html="confirmTitle"
      />
      <div class="body__wrap">
        <div
          class="text"
          v-html="$t('profile_popup.setup_tfa_qr_text')"
        />
        <img
          :src="qrSrc"
          class="qr"
        >
      </div>
    </ui-confirm>
    <ui-confirm
      ref="confirm"
      :width="480"
      :action-name="actionName"
      :action="confirmAction"
      type="warninf"
      action-icon="check"
      action-fa-icon="check"
      @save="onConfirm"
      @close="confirmClose"
    >
      <div
        slot="title"
        v-html="confirmTitle"
      />
      <div class="body__wrap">
        <i
          class="fas fa-exclamation-triangle warning"
        />
        <div
          class="body__msg"
          v-html="confirmMsg"
        />
      </div>
    </ui-confirm>
    <div class="wrapper">
      <div class="user-card">
        <div class="head">
          <div class="title-email">
            <h2>{{ adminInfo.name }}</h2>
            <ui-button
              icon="key"
              lib="fa"
              substyle="fas"
              color="green"
              class="btn"
              @click="checkSensitiveAction('updatePassword')"
            >
              {{ $t('profile_popup.reset_password_title') }}
            </ui-button>
          </div>
        </div>
      </div>
      <div class="info">
        <div class="sidebar">
          <div class="card">
            <div class="title">
              <span>{{ $t(`user_info.personal_information`) }}</span>
              <span
                class="action success"
                @click="editUserHandler"
              >
                <ui-icon
                  name="pen"
                  class="success"
                  lib="fa"
                />
                {{ $t(`user_info.edit`) }}
              </span>
            </div>
            <div class="card-content">
              <div class="field personal-info">
                <div class="label name">
                  <el-tooltip
                    :disabled="isDisabledTooltipName"
                    :content="adminInfo.name"
                    popper-class="wlt"
                    placement="bottom"
                    effect="light"
                  >
                    <div ref="labelName">
                      {{ adminInfo.name }}
                    </div>
                  </el-tooltip>
                </div>
                <div class="registration">
                  <span class="label">{{ $t('profile.registration_date') }}:</span> {{ adminInfo.created_at | formatDate3 }}
                </div>
              </div>
              <div class="field field-solo">
                <ui-icon
                  :size="14"
                  :color="$theme.accentColor"
                  lib="fa"
                  substyle="fas"
                  name="clock"
                  class="ico"
                />
                <span class="timezone">
                  {{ viewTimezone }}
                </span>
              </div>
              <div class="field field-solo">
                <ui-icon
                  :size="14"
                  :color="$theme.accentColor"
                  lib="fa"
                  substyle="fas"
                  name="envelope"
                  class="ico"
                />
                <span
                  v-if="adminInfo.email"
                  class="phone"
                >
                  <a
                    :href="`mailto:${adminInfo.email}`"
                    rel="noopener noreferrer"
                    target="_blank"
                  >{{ adminInfo.email }}</a>
                </span>
                <span
                  v-else
                  class="phone empty"
                >{{ $t(`user_info.not_specified`) }}</span>
              </div>
              <div class="field">
                <span
                  v-show="adminInfo.messenger_id"
                  :class="adminInfo.messenger"
                  class="messenger"
                >
                  <a
                    :href="getMessangerLink"
                    rel="noopener noreferrer"
                    target="_blank"
                  >{{ adminInfo.messenger_id }}</a>
                </span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t('profile_popup.action_unlock_period') }}</span>
              <span class="action success" @click="editSignificantTtlHandler">
                <ui-icon name="pen" class="success" lib="fa" />
                {{ $t(`user_info.edit`) }}
              </span>
            </div>
            <div class="card-content">
              <div class="field">
                {{ timeMap.find(({ value }) => value === adminInfo.significant_actions_ttl).label }}
              </div>
            </div>
          </div>
        </div>
        <div class="body">
          <div class="toolbar">
            <div class="section">
              <div
                class="title"
              >
                {{ $t(`profile.active_sessions`) }}
              </div>
            </div>
            <div class="section">
              <ui-button
                size="medium"
                color="red"
                lib="fa"
                substyle="fas"
                icon="times"
                class="btn"
                @click="handleTerminateAll"
              >
                {{ $t('profile.terminate_all') }}
              </ui-button>
            </div>
          </div>
          <ui-table
            ref="dataTable"
            :lazy-loading-delay="4000"
            lazy-loading
            :fields="fields"
            :data="tableData"
            i18n-path="profile.list"
            element-loading-custom-class="data-loader-spinner"
            class="table"
            @headerDragend="headerDragend"
            @update="resizeHeaderDragend()"
          >
            <template slot="append">
              <el-table-column
                width="44"
                align="center"
                header-align="center"
                :resizable="false"
              >
                <template slot-scope="scope">
                  <ActionIcon
                    :tooltip="$t('profile.terminate')"
                  >
                    <span
                      class="action-ico"
                      @click="handleTerminate(scope.row)"
                    >
                      <ui-icon
                        name="times"
                        :color="$theme.dangerColor"
                        lib="fa"
                      />
                    </span>
                  </ActionIcon>
                </template>
              </el-table-column>
            </template>
          </ui-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AUTH_ADMIN_INFO } from '@/store/action_types/auth';

import tableFieldsEx from './tableHelpers/fieldTableProfile';
import dragendColumnMixin from '@/components/UIElements/UiTableHelper/dragendColumnMixin';

export default {
  name: 'Profile',
  mixins: [dragendColumnMixin],

  data() {
    return {
      tableFieldsEx,
      fieldsLocalStorageKey: 'Profile/width-column',
      resizeHeaderDragendFlag: false,
      doLayoutFlag: true,

      sessions: [],
      isResetOpen: false,
      errors: {},
      confirmData: {},
      resetPassword: {
        old_password: '',
        new_password: '',
      },
      repeat_new_password: '',
      qrSrc: '',
      isConfirmOpen: false,
      confirmTitle: '',
      editUserTitle: '',
      popupInfo: {},
      fields: [
        {
          name: 'device_model',
          align: 'left',
          minWidth: '132',
          flexible: true,
        },
        {
          name: 'os',
          align: 'left',
          minWidth: '132',
          flexible: true,
          renderValue: this.osRender,
        },
        {
          name: 'user_ip',
          align: 'left',
          width: '125',
          minWidth: '125',
          format: 'ip-flag',
          class: 'ip-flex',
          className: 'inherit-tooltip',
        },
        {
          name: 'last_active',
          width: '150',
          minWidth: '150',
          align: 'left',
          headerAlign: 'left',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD MMM YYYY HH:mm',
          },
        },
      ],
      confirmMsg: '',
      confirmAction: '',
      actionName: '',
      widthLabelName: 0,
      significantTtl: null,
      isLoading: false,
    };
  },
  computed: {
    viewTimezone() {
      if (this.adminInfo.timezone && this.timezones) {
        const timezone = this.$_.filter(this.timezones, item => item.zone.includes(this.adminInfo.timezone));
        return timezone[0] ? timezone[0].name : this.$t('profile.tz_invalid');
      }
      return this.$t('profile.tz_invalid');
    },
    timeMap() {
      return [
        {
          value: 30 * 60,
          label: this.$tc('affiliates.time.minutes', 30, { count: 30 }),
        },
        ...[1, 2, 4, 8, 12, 24].reduce((acc, count) => [...acc, {
          value: count * 60 * 60,
          label: this.$tc('affiliates.time.hours', count, { count }),
        }], []),
      ];
    },
    adminInfo() {
      return this.$store.getters['auth/adminInfo'];
    },

    tableData: {
      get() {
        return this.sessions ? this.sessions : [];
      },
    },

    messengerPlaceholder: {
      get() {
        if (this.popupInfo && this.popupInfo.messenger) {
          const placeholder = this.$_.find(this.messengers, ['id', this.popupInfo.messenger]);
          return placeholder.example_placeholder;
        }
        return `${this.$t('team_popup.messenger_id_placeholder')}`;
      },
    },

    getMessangerLink() {
      switch (this.adminInfo.messenger) {
      case 'telegram':
        return `tg://resolve?domain=${this.adminInfo.messenger_id}`;
      case 'skype':
        return `skype:${this.adminInfo.messenger_id}?chat`;
      case 'viber':
        return `viber://chat?number=${this.adminInfo.messenger_id}`;
      case 'whatsapp':
        return `https://api.whatsapp.com/send?phone=${this.adminInfo.messenger_id.replace(/[^\d]/g, '')}`;
      case 'facebook':
        return `https://www.facebook.com/${this.adminInfo.messenger_id}`;
      case 'instagram':
        return `https://www.instagram.com/${this.adminInfo.messenger_id}`;
      case 'twitter':
        return `https://twitter.com/@${this.adminInfo.messenger_id}`;
      default:
        return '#';
      }
    },

    isDisabledTooltipName() {
      return +this.widthLabelName < 274;
    },

    ...mapGetters({
      timezones: 'misc/timezones',
      messengers: 'misc/apiMessengers',
    }),
  },

  watch: {
    sessions: {
      deep: true,
      handler() {
        this.checkWidthName();
      },
    },
  },

  created() {
    this.$eventBus.$on('updatePassword', this.updatePassword);
  },

  beforeDestroy() {
    this.$eventBus.$off('updatePassword', this.updatePassword);
  },

  mounted() {
    this.getSessions();
  },

  methods: {
    async saveSignificantTtl() {
      this.isLoading = true;
      try {
        const { data: { payload } } = await this.$api.putProfileInfo({
          ...this.adminInfo,
          significant_actions_ttl: this.significantTtl,
        });

        this.$store.commit(`auth/${AUTH_ADMIN_INFO}`, payload);
        await this.$store.dispatch('session/initialSessionData');

        this.$refs.editSignificantTtl.close();
      } finally {
        this.isLoading = false;
      }
    },
    editSignificantTtlHandler() {
      this.significantTtl = this.adminInfo.significant_actions_ttl;
      this.$refs.editSignificantTtl.open();
    },
    checkWidthName() {
      this.$nextTick(() => {
        this.widthLabelName = this.$refs.labelName?.offsetWidth || 0;
      });
    },

    checkSensitiveAction(action, row) {
      this.$eventBus.$emit('checkSensitiveAction', action, row);
    },

    osRender(field, row) {
      if (field || row.browser) {
        return `${field}/${row.browser}`;
      }
      return 'unknown';
    },
    handleTerminate(row) {
      this.confirmAction = 'terminateSession';
      this.confirmTitle = `${this.$t('profile_popup.terminate_title')}`;
      this.confirmMsg = `${this.$t('profile_popup.terminate_msg')}`;
      this.actionName = `${this.$t('profile_popup.terminate_action')}`;
      this.confirmData = row;
      this.$refs.confirm.open();
    },
    terminateSession(data) {
      this.$api.deleteSession(data.id)
        .then(() => {
          this.getSessions();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
    handleTerminateAll() {
      this.confirmAction = 'terminateSessionAll';
      this.confirmTitle = `${this.$t('profile_popup.terminate_title_all')}`;
      this.confirmMsg = `${this.$t('profile_popup.terminate_msg_all')}`;
      this.actionName = `${this.$t('profile_popup.terminate_action_all')}`;
      this.$refs.confirm.open();
    },
    terminateSessionAll() {
      this.$api
        .deleteSessions()
        .then(() => {
          this.getSessions();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors) || error.data.message;
        });
    },
    onConfirm() {
      this.isConfirmOpen = false;
      switch (this.confirmAction) {
      case 'terminateSession':
        this.terminateSession(this.confirmData);
        this.$refs.confirm.close();
        break;
      case 'terminateSessionAll':
        this.terminateSessionAll();
        this.$refs.confirm.close();
        break;
      default:
        break;
      }
    },
    getSessions() {
      this.$api.getSessions()
        .then((response) => {
          this.sessions = this.$_.clone(response.data.payload);
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors) || { message: error.data.message };
        });
    },

    updatePassword() {
      this.confirmTitle = `${this.$t('profile_popup.reset_password_title')}`;
      this.resetPassword.old_password = '';
      this.resetPassword.new_password = '';
      this.repeat_new_password = '';
      this.$refs.isResetOpen.open();
    },
    resetUserPassword() {
      if (this.resetPassword.new_password === this.repeat_new_password) {
        this.isLoading = true;
        this.$api
          .postUpdatePassword(this.resetPassword)
          .then(() => {
            this.resetPassword = {
              old_password: '',
              new_password: '',
            };
            this.repeat_new_password = '';
            this.errors = {};
            this.$refs.isResetOpen.close();
          })
          .catch((error) => {
            this.errors = this.$_.cloneDeepWith(error.data.errors) || { message: error.data.message };
          })
          .finally(() => this.isLoading = false);
      } else {
        this.errors = {
          new_password: [
            {
              message: `${this.$t('profile_popup.repeat_error')}`,
            },
          ],
        };
      }
    },

    confirmCloseQr() {
      this.errors = {};
      this.$refs.isSetupQrOpen.close();
    },

    confirmClose() {
      this.errors = {};
    },

    saveEditUser() {
      this.isLoading = true;
      this.$api
        .putProfileInfo(this.popupInfo)
        .then((response) => {
          this.$store.commit(`auth/${AUTH_ADMIN_INFO}`, response.data.payload);
          this.$refs.editUser.close();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors) || { message: error.data.message };
        })
        .finally(() => {
          this.isLoading = false;
          this.checkWidthName();
        });
    },

    editUserHandler() {
      this.popupInfo = this.$_.pick(this.adminInfo, [
        'name',
        'timezone',
        'messenger',
        'messenger_id',
      ]);
      this.editUserTitle = `${this.$t('team_popup.edit_user_title', { name: this.popupInfo.name })}`;
      this.errors = {};
      this.$refs.editUser.open();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

.user-info {
  .card {
    margin-bottom: 24px;

    .field.personal-info {
      flex-direction: column;
      align-items: flex-start;

      .registration {
        font-size: 12px;
        color: $mainGrey;
        margin-top: 4px;

        .label {
          color: $disabledGrey;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 60%;
  }

  .info {
    flex-direction: column;

    .sidebar {
      width: 100%;
      margin-right: 0;
    }

    .body {
      min-width: auto;

      .table {
        width: 100%;
        min-width: auto;
      }
    }
  }

  .title-email {
    justify-content: space-between;
    align-items: center;
    border-bottom: none;
  }
}
</style>
