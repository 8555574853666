export default {
  mainColor: 'green',
  logoName: 'sprut-logo.png',
  maxLogoHeight: '24px',
  accentColor: '#0250d7',
  activeColor: '#0250d7',
  dangerColor: '#d26370',
  defaultLanguage: 'ru',
  uiTheme: {
    primaryColor: '#20815e',
    dangerColor: '#d26370',
    infoColor: '#3b94e3',
    warningColor: '#ffce06',
    lightColor: '#fff',
    darkColor: '#303634',
    // the-header
    headerColor: '#001a42',
    headerBorder: '#2f425e',
    headerText: '#ffffff',
    headerTextHover: '#5191ff',
    headerTextBorder: '#5191ff',
    reportDropdownBackground: '#0250d7',
    reportDropdownBackgroundHover: '#5191ff',
    reportDropdownActive: '#ffffff',
    reportDropdownActiveHover: '#ffffff',
    scrollThumb: '#c3c3c3',
    scrollBackground: '#5191ff',
    colorFirst: '#306cd3',
    colorSecond: '#ffce05',
    backLink: '#0250d7',
  },
};
