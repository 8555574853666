export default {
  data() {
    return {
      activeRow: '',
    };
  },

  methods: {
    renderNeedAttentionIcon(row) {
      return (row.needs_attention ? this.$createElement('ui-icon', {
        attrs: {
          name: 'exclamation-circle',
          color: this.$theme.dangerColor,
          lib: 'fa',
        },
      }, []) : this.$createElement('span', {}, ''));
    },

    appendTeam(row) {
      if (row.team_name) {
        return this.$createElement('span', [row.team_name]);
      }
      return this.$createElement('span', '—');
    },
  },
};
