var render = function render(){var _vm=this,_c=_vm._self._c;return _c('adm-ui-chart',{key:_vm.loading,staticClass:"dashboard-overview__metric-chart-wrap",class:[
    _vm.loading || _vm.isLoading ? 'ui-chart--loading' : '',
    _vm.index === null ? '' : _vm.classChart(_vm.index + 1),
    _vm.isRemoved
      ? `dashboard-overview__metric-card-wrap--draggable dashboard-overview__metric-card-wrap--draggable${_vm.rowIndex}`
      : '',
    !_vm.filterIsEmpty ? 'dashboard-overview__metric-chart-wrap--filtered' : '',
  ],attrs:{"chart-options":_vm.configChart}},[_c('template',{slot:"header"},[_c('adm-ui-header',{attrs:{"tag":"h3","tooltip":_vm.computedheaderTooltip}},[_vm._v(" "+_vm._s(_vm.nameChart)+" ")]),_c('div',{staticClass:"dashboard-overview__chart-action-wrap"},[(!_vm.isRemoved)?_c('div',{staticClass:"chart-action-default-wrap"},[(_vm.chartVisualization !== 'pie')?_c('forecast-switch',{attrs:{"value":_vm.forecastValue},on:{"change":_vm.handlerForecastSwitch}}):_vm._e()],1):_c('adm-ui-controls-widget',{attrs:{"data":_vm.controlsData}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }