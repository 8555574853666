<template>
  <div class="settings">
    <div class="content-wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Settings',
  data() {
    return {};
  },
};
</script>
