<template>
  <div
    class="custom-header"
    :class="[
      customSorting && column.property === sortProp ? sortOrder : '',
      column.sortable === false ? '' : 'no-sort',
    ]"
    @click="handlerCustomHeader"
  >
    <div class="text">
      <span class="title">{{ column.label }}{{ getCurrencyLabelByField(findFieldCol) }}</span>
    </div>

    <span v-if="customSorting" slot="reference" class="custom-sorting">
      <ui-icon
        lib="fa"
        substyle="fas"
        name="caret-down"
        :size="16"
        color="#ffffff"
        class="asc"
        style="transform: rotate(180deg) scaleY(0.9)"
      />
      <ui-icon
        lib="fa"
        substyle="fas"
        name="caret-down"
        :size="16"
        color="#ffffff"
        class="desc"
        style="marginTop: -10px; transform: scaleY(0.9)"
      />
    </span>

    <span :class="{ 'open-filters': openFilter }" class="filters-wrapper">
      <el-popover
        v-if="filterable && isFiltersInit"
        class="col-filter"
        trigger="manual"
        :value="columnFilters[column.property].visible"
      >
        <div class="col-filter-wrapper">
          <el-radio-group
            :class="
              customFilterColumnCreated.includes(column.property) ? filterTabDateHidden : filterInputDateHiddenClass
            "
            :value="columnFilters[column.property].op"
            @input="handlerRadioBtn"
          >
            <el-radio-button
              v-for="(allowedOperator, index) in Array.isArray(allowedOperators) ? allowedOperators : []"
              :key="index + allowedOperator"
              :label="allowedOperator"
            >
              {{ allowedOperator }}
            </el-radio-button>
          </el-radio-group>

          <div :class="filterInputHiddenClass" class="input-filter-wrap-gen2">
            <div class="input-filter-wrap-gen2__title-wr">
              <span>{{ $t('ui.table.from') }}</span>
              <span>{{ $t('ui.table.to') }}</span>
            </div>
            <div
              v-if="columnFilters[column.property].valueRange"
              class="input-filter-wrap-gen2__input-wr"
              :class="focusInput ? 'focus' : ''"
            >
              <ui-input
                class="filter-input filter-input-num filter-input-num__range"
                placeholder="-∞"
                :value="columnFilters[column.property].valueRange[0]"
                type="number"
                @focus="focusInput = true"
                @blur="focusInput = false"
                @input="handlerRangeValue($event, 'dataRange[0]')"
              />
              <span>-</span>
              <ui-input
                class="filter-input filter-input-num filter-input-num__range"
                placeholder="+∞"
                :value="columnFilters[column.property].valueRange[1]"
                type="number"
                @focus="focusInput = true"
                @blur="focusInput = false"
                @input="handlerRangeValue($event, 'dataRange[1]')"
              />
            </div>
            <el-checkbox
              :value="columnFilters[column.property].isExclude"
              class="input-filter-wrap-gen2__checkbox"
              @change="changeExclude"
            >
              {{
                columnFilters[column.property].valueRange &&
                  columnFilters[column.property].valueRange[1] &&
                  columnFilters[column.property].valueRange[0]
                  ? $t('ui.table.exclude')
                  : $t('ui.table.exclude2')
              }}
            </el-checkbox>
          </div>

          <ui-input
            :class="filterInputDateHiddenClass"
            class="filter-input filter-input-date"
            :placeholder="$t('ui.table.value')"
            :value="columnFilters[column.property].valueSec"
            @input="handlerColumnFilterInput($event, 'date')"
          />
          <el-date-picker
            :class="{
              'filter__datapicker-daterange date-picker date-picker__style': true,
              'filter-input__hide': !customFilterColumnCreated.includes(column.property),
              'el-date-picker__hidden': columnFilters[column.property].op !== '=',
            }"
            :placeholder="$t('ui.table.value')"
            :value="columnFilters[column.property].dateRange"
            :start-placeholder="$t('reports.other.start_date')"
            :end-placeholder="$t('reports.other.end_date')"
            name="range_picker"
            type="daterange"
            range-separator="-"
            format="dd MMM yyyy"
            :clearable="false"
            :disabled="columnFilters[column.property].op === ''"
            @input="handlerDatepickerRange($event, 'daterange')"
          />
          <el-date-picker
            class="filter__datapicker-date date-picker date-picker__style"
            :class="{
              'filter-input__hide': !customFilterColumnCreated.includes(column.property),
              'el-date-picker__hidden': columnFilters[column.property].op === '=',
            }"
            :placeholder="$t('ui.table.value')"
            :value="columnFilters[column.property].dateRange"
            :start-placeholder="$t('reports.other.start_date')"
            :end-placeholder="$t('reports.other.end_date')"
            name="range_picker"
            type="date"
            range-separator="-"
            format="dd MMM yyyy"
            :clearable="false"
            :disabled="columnFilters[column.property].op === ''"
            @input="handlerDatepickerRange($event, 'date')"
          />
          <ui-select
            class="filter-select"
            :class="filterSelectHiddenClass"
            :placeholder="$t('affiliates.offersView.holdRanges.seconds')"
            :value="createValueSelect()"
            :options="optionsFilterSelect"
            @input="handlerColumnFilterInput($event, 'select')"
          />

          <div class="btns">
            <ui-button color="red" lib="fa" substyle="fas" icon="times" @click="handlerResetBtn">{{
              $t('ui.table.reset')
            }}</ui-button>
            <!-- && columnFilters[column.property].op -->
            <ui-button
              lib="fa"
              substyle="fas"
              icon="check"
              :disabled="
                !readyFilter && !columnFilters[column.property].valueSec && !columnFilters[column.property].dateRange
              "
              @click="handlerSuccessBtn"
            >{{ $t('ui.table.apply') }}</ui-button>
          </div>
        </div>
        <div
          slot="reference"
          :class="{
            open:
              lastOpenFilter === column.property ||
              ((columnFilters[column.property].value || columnFilters[column.property].dateRange !== null) &&
                columnFilters[column.property].op),
          }"
          class="filter-opener"
          @click.stop="handlerFilter"
        >
          <ui-icon lib="fa" substyle="fas" name="filter" :size="8" />
        </div>
      </el-popover>
    </span>
  </div>
</template>

<script>
import renderHeaderMixin from '@/components/UIElements/UiTableHelper/renderHeaderMixin';

export default {
  name: 'RenderHeader',
  mixins: [renderHeaderMixin],
  props: {
    currency: {
      type: String,
      default: '',
    },
    column: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    customSorting: {
      type: Boolean,
      default: false,
    },
    sortProp: {
      type: String,
      required: true,
    },
    sortOrder: {
      type: String,
      required: true,
    },
    lastOpenFilter: {
      type: String,
      required: true,
    },
    columnFilters: {
      type: Object,
      required: true,
    },
    isFiltersInit: {
      type: Boolean,
      required: true,
    },
    customFilterColumn: {
      type: Array,
      required: true,
    },
    customFilterColumnCreated: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      focusInput: false,
      readyFilter: false,
      optionsFilterSelect: [
        {
          label: this.$t('affiliates.offersView.holdRanges.minutes'),
          value: 'minutes',
        },
        {
          label: this.$t('affiliates.offersView.holdRanges.hours'),
          value: 'hours',
        },
        {
          label: this.$t('affiliates.offersView.holdRanges.days'),
          value: 'days',
        },
        {
          label: this.$t('affiliates.offersView.holdRanges.weeks'),
          value: 'weeks',
        },
      ],
    };
  },

  computed: {
    findFieldCol() {
      return this.fields.find(el => el.name === this.column.property);
    },

    filterable() {
      return this.findFieldCol !== undefined ? this.findFieldCol.filterable || false : false;
    },

    allowedOperators() {
      return this.findFieldCol !== undefined ? this.findFieldCol.allowedOperators || ['<', '=', '>'] : ['<', '=', '>'];
    },

    filterInputType() {
      return this.findFieldCol !== undefined ? this.findFieldCol.filterInputType || 'text' : 'text';
    },

    openFilter() {
      return (
        this.columnFilters[this.column.property]
        && (this.lastOpenFilter === this.column.property
          || ((this.columnFilters[this.column.property].value
            || this.columnFilters[this.column.property].dateRange !== null)
            && this.columnFilters[this.column.property].op))
      );
    },

    filterInputHiddenClass() {
      return this.customFilterColumn.indexOf(this.column.property) >= 0
        || this.customFilterColumnCreated.includes(this.column.property)
        ? 'filter-input__hide'
        : '';
    },

    filterInputDateHiddenClass() {
      return this.customFilterColumn.indexOf(this.column.property) >= 0 ? '' : 'filter-input__hide';
    },

    filterTabDateHidden() {
      return this.customFilterColumnCreated.includes(this.column.property) ? '' : 'filter-input__hide';
    },

    filterSelectHiddenClass() {
      return this.customFilterColumn.indexOf(this.column.property) >= 0 ? '' : 'filter-select__hide';
    },
  },

  methods: {
    changeExclude(checked) {
      this.$emit('changeExclude', this.column.property, checked);
      this.checkReadyFilter();
    },

    handlerRadioBtn(value) {
      this.$emit('handlerRadioBtn', this.column.property, value);
    },

    handlerFilter() {
      const columnFiltersClone = this.columnFilters;
      const set = !columnFiltersClone[this.column.property].visible;

      columnFiltersClone[this.column.property].visible = set;

      if (set) {
        if (this.lastOpenFilter) {
          columnFiltersClone[this.lastOpenFilter].visible = false;
        }
        this.$emit('changeColumnFilters', columnFiltersClone);
        this.$emit('lastOpenFilter', this.column.property);
      } else {
        this.$emit('changeColumnFilters', columnFiltersClone);
        this.$emit('lastOpenFilter', '');
      }
    },

    toValueAndOp(valueRange, isExclude) {
      if (valueRange[0] !== '' && valueRange[1] !== '') {
        return {
          value: [valueRange[0], valueRange[1]],
          op: isExclude ? 'notBetween' : 'between',
        };
      }
      if (valueRange[0] !== '') {
        return {
          value: valueRange[0],
          op: isExclude ? '>' : '>=',
        };
      }
      return {
        value: valueRange[1],
        op: isExclude ? '<' : '<=',
      };
    },

    handlerSuccessBtn() {
      const columnFiltersClone = this.columnFilters;
      columnFiltersClone[this.column.property].visible = false;

      if (this.filterInputHiddenClass !== 'filter-input__hide') {
        const { valueRange } = columnFiltersClone[this.column.property];
        const { isExclude } = columnFiltersClone[this.column.property];

        columnFiltersClone[this.column.property].op = this.toValueAndOp(valueRange, isExclude).op;
        columnFiltersClone[this.column.property].value = this.toValueAndOp(valueRange, isExclude).value;
      }

      this.$emit('lastOpenFilter', '');
      this.$emit('filter', columnFiltersClone);
      this.$emit('setCheckApplyFilter', true);
      this.$nextTick(() => {
        this.$emit('setCheckApplyFilter', false);
      });
      this.readyFilter = false;
    },

    handlerResetBtn() {
      const columnFiltersClone = this.columnFilters;
      columnFiltersClone[this.column.property].op = '';
      columnFiltersClone[this.column.property].value = '';
      columnFiltersClone[this.column.property].valueRange = ['', ''];
      columnFiltersClone[this.column.property].isExclude = false;
      columnFiltersClone[this.column.property].valueSec = '';
      columnFiltersClone[this.column.property].dateFilter = '';
      columnFiltersClone[this.column.property].dateRange = null;
      columnFiltersClone[this.column.property].visible = false;
      this.$emit('lastOpenFilter', '');
      this.$emit('filter', columnFiltersClone, 'reset');
      this.$emit('setCheckApplyFilter', true);
      this.$nextTick(() => {
        this.$emit('setCheckApplyFilter', false);
      });
    },

    handlerDatepickerRange(date, type) {
      const formatDate = () => {
        if (type === 'daterange') {
          return date.map(elem => this.$moment(elem).format('YYYY-MM-DD'));
        }
        return this.$moment(date).format('YYYY-MM-DD');
      };
      this.$emit('handlerDatepickerRange', this.column.property, formatDate());
    },

    handlerRangeValue(val, type) {
      this.$emit('handlerRangeValue', type, this.column.property, val.replace());
      this.checkReadyFilter();
    },

    checkReadyFilter() {
      this.readyFilter = !!(
        this.columnFilters[this.column.property].valueRange[0] || this.columnFilters[this.column.property].valueRange[1]
      );
    },

    handlerColumnFilterInput(val, type) {
      this.$emit('handlerColumnFilterInput', type, this.column.property, val);
    },

    handlerCustomHeader() {
      if (this.filterable) {
        if (this.lastOpenFilter) {
          this.columnFilters[this.lastOpenFilter].visible = false;
          this.$emit('lastOpenFilter', '');
        }
      }

      const paramSort = [
        {
          prop: this.column.property,
          order: this.sortOrder === 'asc' ? 'desc' : 'asc',
        },
        this.column.id,
      ];

      this.$emit('handleSort', ...paramSort);
    },

    createValueSelect() {
      return (
        this.columnFilters[this.column.property].dateFilter
        || `${
          this.customFilterColumn.indexOf(this.column.property) >= 0
            ? (this.columnFilters[this.column.property].dateFilter = 'hours')
            : ''
        }`
      );
    },
  },
};
</script>

<style lang="scss">
.input-filter-wrap-gen2 {
  display: flex;
  flex-direction: column;
  &__title-wr {
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    span {
      font-size: 12px;
    }
  }
  &__input-wr {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    border-radius: 4px;

    .filter-input-num__range {
      margin: 0;
      &.focus .input-wrapper {
        border-color: transparent !important;
      }
      .input-wrapper {
        border-color: transparent !important;
      }
      .input-element {
        text-align: center;
        min-width: 84px;
        width: 90px;
        border-color: transparent;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          /* display: none; <- Crashes Chrome on hover */
          -webkit-appearance: none;
          margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }
        &::placeholder {
          font-size: 17px !important;
        }
      }
    }
  }
  &__checkbox {
    margin-bottom: 8px;
    .el-checkbox__label {
      font-size: 12px;
    }
  }
}
</style>
