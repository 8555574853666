import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './locales/en';
import ru from './locales/ru';

Vue.use(VueI18n);

class VueI18nExtend extends VueI18n {
  getChoiceIndex(choice, choicesLength) {
    // eslint-disable-next-line no-empty
    if (this.locale !== 'ru') {}
    if (choice === 0) {
      return 0;
    }
    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;
    if (!teen && endsWithOne) {
      return 1;
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
      return 2;
    }
    return (choicesLength < 4) ? 2 : 3;
  }
}

const I18nInstance = new VueI18nExtend({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    ru,
  },
});

export default I18nInstance;
