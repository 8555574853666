<template>
  <div class="dedicated-site-domains">
    <text-area-popup
      ref="textareaPopup"
      :is-open-popup="isOpenOtherPopup"
      exclude-hidden
      :title="$t('domainManagement.popup.add_dedicated_site_domain')"
      :text="$t('domainManagement.popup.info_input')"
      :error="!!errorListDomains.length"
      :text-error="$t('domainManagement.popup.text_error')"
      :text-input="$t('domainManagement.popup.domain_names')"
      :action-disabled="actionDisabled"
      @changeError="() => respanseData = []"
      @confirm="addDomain"
      @close="closeOtherPopup"
    />

    <pin-affiliate-to-domain-popup
      v-show="isPopupOpen"
      :is-open="isPopupOpen"
      :data="dataAffiliate"
      :action="1"
      :leagues="1"
      :league-id="1"
      :match="1"
      @confirm="addAffiliateOffer"
      @close="onClosePopup"
    />

    <ui-confirm
      ref="confirm"
      :width="480"
      :type="'warning'"
      :action-name="$t('domainManagement.buttons.delete')"
      :action-fa-icon="'trash-alt'"
      :data="confirmData"
      @save="deleteHandler"
      @close="confirmClose"
    >
      <div slot="title" v-html="$t('domainManagement.buttons.delete')" />
      <div class="body__wrap">
        <i class="fas fa-trash-alt warning" />
        <div
          class="body__msg"
          v-html="
            $t('domainManagement.messages.delete', { name: confirmData.name })
          "
        />
      </div>
    </ui-confirm>

    <div class="toolbar">
      <div class="wrapper">
        <div class="section">
          <span class="title">{{
            $t('domainManagement.pageTitle.dedicatedSiteDomains')
          }}</span>
          <ui-input
            v-model.trim="search"
            class="dns-hosting-accounts-search"
            :width="180"
            :is-search="true"
            @search="getPlatformBettingDomain"
          />
        </div>
        <div class="section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="paginationHandler"
            />
          </div>
          <ui-button
            v-if="canEdit"
            color="green"
            lib="fa"
            substyle="fas"
            filled
            icon="plus"
            class="btn btn-add"
            @click="openReportsFilterPopup"
          >
            {{ $t('domainManagement.buttons.add_domain') }}
          </ui-button>
        </div>
      </div>
    </div>
    <ui-table
      v-loading="isDataLoading"
      :lazy-loading-delay="4000"
      :fields="fields"
      :data="data"
      :rows-count="limit"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      lazy-loading
      class="table"
      i18n-path="domainManagement"
      element-loading-custom-class="data-loader-spinner"
      @sort="sortingHandler"
    >
      <template v-if="canEdit" slot="append">
        <el-table-column width="75">
          <template slot-scope="scope">
            <ActionIcon
              :tooltip="
                !!scope.row.affiliate_email
                  ? $t('domainManagement.buttons.edit')
                  : $t('domainManagement.popup.pinned')
              "
            >
              <el-link
                class="action-ico"
                type="success"
                @click="clickEditAffiliateHandler(scope.row)"
              >
                <ui-icon
                  v-if="!!scope.row.affiliate_email"
                  name="pen"
                  :color="$theme.accentColor"
                  lib="fa"
                />
                <ui-icon
                  v-else
                  name="link"
                  :color="$theme.accentColor"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
            <ActionIcon :tooltip="$t('domainManagement.buttons.delete')">
              <el-link
                :disabled="!!scope.row.affiliate_email"
                class="action-ico"
                type="danger"
                @click="confirmOpen(scope.row)"
              >
                <ui-icon
                  name="trash-alt"
                  :color="$theme.dangerColor"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Bluebird from 'bluebird';
import { pageSizeMixin, resolvePageSize } from '../../service/pageSize';
import queryParamsMixin from '../mixins/query-params';
import TextAreaPopup from '@/components/Popups/TextAreaPopup.vue';
import PinAffiliateToDomainPopup from '@/components/Popups/PinAffiliateToDomainPopup.vue';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'dedicated-site-domains/list/limit'; // for localstorage
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'DedicatedSiteDomains',

  components: {
    TextAreaPopup,
    PinAffiliateToDomainPopup,
  },

  mixins: [pageSizeMixin, queryParamsMixin],

  data() {
    return {
      isDataLoading: false,
      isOpenPopup: false,

      search: '',
      count: 0,
      page: 1,
      limit: pageSize,
      sortProp: 'incrementing_id',
      sortOrder: 'desc',

      editableRow: undefined,
      editableRowId: undefined,

      data: [],
      fields: [
        {
          name: 'incrementing_id',
          sortable: 'custom',
          align: 'left',
          width: '60',
        },
        {
          name: 'domain_name',
          label: 'domain_name',
          sortable: 'custom',
          minWidth: '100',
        },
        {
          name: 'affiliate_email',
          label: 'affiliate_email',
          sortable: 'custom',
          computedValue: row => row.affiliate_email || '—',
          link: this.getAffiliateLink,
        },
        {
          name: 'affiliate_offer_name',
          label: 'affiliate_offer_name',
          computedValue: row => row.affiliate_offer_name || '—',
        },

        {
          name: 'created_at',
          sortable: 'custom',
          width: '130',
          format: 'date-time',
        },
      ],

      filters: {},

      confirmData: {},
      isOpenOtherPopup: false,
      isOpenPinDomain: false,
      respanseData: [],
      actionDisabled: false,

      /* --> */
      isPopupOpen: false,
      dataAffiliate: {},
      /* <-- */

    };
  },

  computed: {
    ...mapGetters('misc', ['dnsHostingProviders']),

    isSuperUser() {
      return this.$store.getters['auth/adminAcl'].is_superuser;
    },

    errorListDomains() {
      return this.respanseData.filter(domainData => !domainData.created);
    },

    canEdit() {
      return (
        this.isSuperUser
        || this.$store.getters['auth/currentAcl'].platform_betting_domains_edit
          === 'allow'
      );
    },

    queryParams() {
      return {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        search: this.search || undefined,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        ...this.filters,
      };
    },
  },

  methods: {
    /* ----> */
    clickEditAffiliateHandler(row) {
      this.isPopupOpen = true;
      this.dataAffiliate = row;
    },

    onClosePopup() {
      this.isPopupOpen = false;
    },

    /* <----- увауау */
    getAffiliateLink(row) {
      if (!row.affiliate_email
        || this.$store.getters['auth/currentAcl'].affiliates_view === 'deny') {
        return '';
      }
      return `/affiliates/${row.affiliate_id}/info`;
    },

    addAffiliateOffer(id, affiliate_offer_id) {
      this.updatePlatformBettingDomain(id, { affiliate_offer_id });
    },

    closePinDomainPopup() {
      this.isOpenPinDomain = false;
    },

    openPinDomainPopup(row) {
      this.isOpenPinDomain = true;
      this.$refs.pinDomainPopup.open(row);
    },

    closeOtherPopup() {
      this.isOpenOtherPopup = false;
      this.respanseData = [];
    },

    openReportsFilterPopup() {
      this.isOpenOtherPopup = true;
      this.$refs.textareaPopup.open({}, 'domain');
    },

    async addDomain(value) {
      this.actionDisabled = true;
      const domainList = value.domain.value;

      try {
        const response = await Bluebird.map(domainList, async (domain) => {
          try {
            await this.createPlatformBettingDomain({
              domain_name: domain,
              affiliate_offer_id: null,
            });
            return { domain, created: true };
          } catch (error) {
            if (error.status === 422) {
              return { domain, created: false, error };
            }
            return error;
          }
        }, { concurrency: 3 });

        this.respanseData = response;
      } finally {
        await this.getPlatformBettingDomain();
        this.afterDomainRequest();
        this.actionDisabled = false;
      }
    },

    afterDomainRequest() {
      if (!this.errorListDomains.length) {
        this.$refs.textareaPopup.close();
      } else {
        const obj = {
          domain: {
            op: 'like',
            value: this.errorListDomains.map(list => list.domain),
          },
        };
        this.$refs.textareaPopup.open(obj, 'domain');
      }
    },

    async getPlatformBettingDomain() {
      this.isDataLoading = true;

      const {
        data: { payload, misc },
      } = await this.$api.getPlatformBettingDomain(this.queryParams);

      this.count = misc.count;
      this.data = payload;

      this.isDataLoading = false;
    },

    // For query-params mixin (auto-change url query)
    fetch() {
      return this.getPlatformBettingDomain();
    },

    paginationHandler(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;

        this.getPlatformBettingDomain();
      }
    },

    sortingHandler({ prop, order }) {
      if (order === '') return;

      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }

      this.getPlatformBettingDomain();
    },

    closePopup() {
      this.isOpenPopup = false;
      this.editableRow = undefined;
    },

    openPopup() {
      this.isOpenPopup = true;
    },

    async createPlatformBettingDomain(data) {
      await this.$api.createPlatformBettingDomain(data);
    },

    async updatePlatformBettingDomain(id, data) {
      await this.$api.updatePlatformBettingDomain(id, data);
      await this.getPlatformBettingDomain();
      this.editableRowId = undefined;
    },

    async deleteHandler(action, row) {
      this.$refs.confirm.close();
      await this.$api.deletePlatformBettingDomain(row.id);
      await this.getPlatformBettingDomain();
    },

    async editHandler(row) {
      const {
        data: { payload },
      } = await this.$api.getPlatformBettingDomainById(row.id);
      this.editableRow = payload;
      this.editableRowId = row.id;
      this.openPopup();
    },

    confirmOpen(row) {
      this.confirmData = row;
      this.$refs.confirm.open();
    },

    confirmClose() {
      this.confirmData = {};
    },
  },
};
</script>
