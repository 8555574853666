var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-overview__metric-table-wrap dashboard-metric-table",class:[
    _vm.isRemoved
      ? `dashboard-overview__metric-table-wrap--draggable dashboard-overview__metric-table-wrap--draggable${_vm.rowIndex}`
      : '',
    _vm.loading || _vm.isLoading ? 'dashboard-metric-table--loading' : '',
    !_vm.filterIsEmpty ? 'dashboard-overview__metric-table-wrap--filtered' : '',
  ]},[_c('div',{staticClass:"dashboard-metric-table__header"},[_c('adm-ui-header',{staticClass:"dashboard-metric-table__title",attrs:{"tag":"h3","tooltip":_vm.getTranslateMetrics(_vm.table.metric_name).description}},[_vm._v(" "+_vm._s(_vm.table.name)+" ")]),(_vm.isRemoved)?_c('adm-ui-controls-widget',{attrs:{"data":_vm.controlsData}}):_vm._e()],1),_c('ui-table',{ref:"table",staticClass:"table",class:[_vm.computedTotalClass],attrs:{"select-row":false,"fields":_vm.fields,"data":_vm.getTableData,"total":_vm.getTotals,"sort":{
      prop: _vm.sortProp,
      order: _vm.sortOrder,
    },"min-table-height":280,"i18n-path":_vm.translateMap,"show-total":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }