<template>
  <div class="player-crm">
    <div class="content">
      <div class="column">
        <div class="wrapper">
          <div class="toolbar">
            <div class="section">
              <span class="title">{{ $t('players.crm.title') }}</span>
            </div>
            <div class="section">
              <div class="pagination">
                <ui-pagination
                  :page="page"
                  :page-size="limit"
                  :count="count"
                  show-size-select
                  @page-change="paginationHandler"
                />
              </div>
            </div>
          </div>
          <ui-table
            v-loading="isDataLoading"
            :lazy-loading-delay="4000"
            :fields="fields"
            :data="data"
            :rows-count="limit"
            lazy-loading
            :disable-sort="true"
            class="table"
            i18n-path="players.crm"
            element-loading-custom-class="data-loader-spinner"
          >
            <template
              slot="append"
            >
              <el-table-column width="70" align="center">
                <template slot-scope="scope">
                  <ActionIcon
                    :tooltip="$t('crm.buttons.view')"
                  >
                    <router-link
                      :to="`/crm/activities/${scope.row.activity_id}`"
                      class="action-ico"
                    >
                      <ui-icon
                        name="eye"
                        :color="$theme.accentColor"
                        lib="fa"
                      />
                    </router-link>
                  </ActionIcon>
                </template>
              </el-table-column>
            </template>
          </ui-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import { getContryCodeByLanguage } from '@/helpers/language.js';

import CrmTag from '@/views/CRM/components/CrmTag';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = '/players/crm'; // for localstorage
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'PlayerCrm',
  mixins: [
    pageSizeMixin,
  ],
  data() {
    return {
      viewName,
      isDataLoading: false,
      page: 1,
      count: 0,
      limit: pageSize,
      data: [],
      fields: [
        {
          name: 'event_timestamp',
          width: '150',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD MMM YYYY HH:mm:ss',
          },
        },
        {
          name: 'activity_name',
        },
        {
          name: 'language',
          label: 'language',
          align: 'center',
          linkAppend: this.renderLanguage,
          width: '90',
          headerAlign: 'center',
        },
        {
          name: 'type',
          linkAppend: this.renderActions,
          align: 'center',
          width: '70',
          headerAlign: 'center',
        },
      ],
    };
  },
  created() {
    this.limit = +this.$ls.get(viewName) || pageSize;
  },
  mounted() {
    this.getPlayerLogs();
  },
  methods: {
    _query() {
      return {
        limit: this.limit,
        offset: this.limit * this.page - this.limit,
      };
    },
    async getPlayerLogs() {
      this.isDataLoading = true;
      try {
        const { data: { payload, misc } } = await this.$api.getPlayerLogs(this.$route.params.id, {
          limit: this.limit,
          offset: (this.page - 1) * this.limit,
        });

        this.count = misc.count || 0;
        this.data = payload;
      } finally {
        this.isDataLoading = false;
      }
    },


    renderActions(row) {
      const h = this.$createElement;

      const tags = [h(CrmTag, {
        props: {
          preset: `notify-${row.action_type}`,
        },
        class: 'ui-g-sm',
      })];

      return h('div', {
        class: 'ui-g-w-sm',
      }, tags);
    },

    renderLanguage(row) {
      const h = this.$createElement;

      const countryCode = getContryCodeByLanguage(row.action_language_code);
      const flag = countryCode ? h('flag', {
        props: {
          iso: countryCode,
        },
        slot: 'prepend',
      }) : h('i', {
        class: 'fas fa-globe',
        slot: 'prepend',
      });

      const tags = [h(CrmTag, {
        props: {
          preset: 'gray',
        },
        class: 'ui-g-sm',
      }, [
        flag,
        h('span', { class: 'language-name' }, row.action_language_code),
      ])];

      return h('div', { class: 'ui-g-w-sm' }, tags);
    },

    paginationHandler(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;

        this.getPlayerLogs();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.player-crm {
  width: 90%;
  min-width: 1140px;
  margin: 0 auto;
  padding-top: 0;
  display: flex;
  flex-direction: column;

  /deep/.el-table {
    height: initial !important;
    max-height: initial !important;

    &__body-wrapper{
      height: initial !important;
    }
  }
}

.language-name {
  text-transform: capitalize;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  font-size: 20px;
}
</style>
