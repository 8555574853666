import { render, staticRenderFns } from "./TeamsList.vue?vue&type=template&id=b5428148&scoped=true&"
import script from "./TeamsList.vue?vue&type=script&lang=js&"
export * from "./TeamsList.vue?vue&type=script&lang=js&"
import style0 from "./TeamsList.vue?vue&type=style&index=0&id=b5428148&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5428148",
  null
  
)

export default component.exports