<template>
  <div class="sites">
    <div
      v-for="site in siteSettings"
      :key="site.id"
      class="sites-item"
    >
      <div class="head wrapper">
        <span class="title">{{ site.name }}</span>
      </div>
      <div class="content wrapper">
        <div class="betting-availability">
          <ui-table
            :fields="bettingDomainsFields"
            :data="site.betting_domains"
            :disable-sort="true"
            i18n-path="settings"
            class="table"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $store from '@/store';
import { SITE_SETTINGS_FETCH } from '@/store/modules/site-settings/action-types';

export default {
  name: 'Sites',
  data() {
    return {
      bettingDomainsFields: [
        {
          name: 'country_name',
          width: '300',
          align: 'left',
          headerAlign: 'left',
          sortable: false,
        },
        {
          name: 'desktop_domain',
          minWidth: 'auto',
          align: 'left',
          headerAlign: 'left',
          sortable: false,
        },
        {
          name: 'mobile_domain',
          minWidth: 'auto',
          align: 'left',
          headerAlign: 'left',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    siteSettings() {
      return $store.getters['siteSettings/getSettings'];
    },
  },
  created() {
    $store.dispatch(`siteSettings/${SITE_SETTINGS_FETCH}`);
  },
};
</script>
