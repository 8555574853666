<template>
  <transition name="fade">
    <div
      v-show="isContextOpen"
      ref="context"
      :style="{
        left: `${position.contextLeft}px`,
        top: `${position.contextTop}px`,
      }"
      class="table-context-menu"
    >
      <div
        :class="{'context-tab-disabled': $_.isEmpty(selectedRow) && selectIds.length === 0}"
        class="item"
        @click="drilldown"
      >
        <ui-icon
          class="context-icon"
          lib="fa"
          substyle="fas"
          name="random"
        />
        <span>{{ $t('reports.other.drilldown') }}</span>
      </div>
      <div
        :class="{'context-tab-disabled': $_.isEmpty(selectedRow) && selectIds.length === 0}"
        class="item"
        @click="exclude"
      >
        <ui-icon
          class="context-icon"
          lib="fa"
          substyle="fas"
          name="page-break"
        />
        <span>{{ $t('reports.other.exclude') }}</span>
      </div>
      <div
        class="item"
        @click="showPlayersReport"
      >
        {{ $t('reports.other.show_player') }}
      </div>
      <div
        v-if="isPaymentGroup"
        class="item"
        @click="openPR( selectRows.length ? selectRows : [selectedRow])"
      >
        <span>{{ $t('reports.other.open_payment') }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ContextMenu',
  props: {
    selectRows: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedRow: {
      type: Object,
      default() {
        return {};
      },
    },
    selectIds: {
      type: Array,
      default() {
        return [];
      },
    },
    isContextOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    disableDrilldown: {
      type: Boolean,
      default: true,
    },
    isPaymentGroup: {
      type: Boolean,
      default: true,
    },
    currentReport: {
      type: Number,
      default: 0,
    },
    position: {
      type: Object,
      default() {
        return {
          contextLeft: 0,
          contextTop: 0,
        };
      },
    },
  },
  methods: {
    openPR(rows) {
      this.$emit('openPR', rows);
    },
    showPlayersReport() {
      this.$emit('showPlayersReport');
    },
    drilldown() {
      if (this.disableDrilldown || this.currentReport === 0) {
        this.$emit('createReport', null);
        return;
      }
      this.$emit('drilldownReport', '=');
    },
    exclude() {
      if (this.disableDrilldown || this.currentReport === 0) {
        this.$emit('createReport', null, '!=');
        return;
      }
      this.$emit('drilldownReport', '!=');
    },
  },
};
</script>
