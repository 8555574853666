export default {
  mainColor: 'green',
  logoName: 'winon-logo.svg',
  maxLogoHeight: '32px',
  accentColor: '#f37021',
  activeColor: '#f37021',
  dangerColor: '#d80000',
  uiTheme: {
    primaryColor: '#f37021',
    dangerColor: '#d80000',
    infoColor: '#3b94e3',
    warningColor: '#ffb600',
    lightColor: '#fff',
    darkColor: '#303634',
    // the-header
    headerColor: '#100325',
    headerBorder: '#5b5b5b',
    headerText: '#ffffff',
    headerTextHover: '#f37021',
    headerTextBorder: '#f37021',
    reportDropdownBackground: '#222222',
    reportDropdownBackgroundHover: '#181818',
    reportDropdownActive: '#f37021',
    reportDropdownActiveHover: '#f37021',
    scrollThumb: '#f37021',
    scrollBackground: '#c3c3c3',
    colorFirst: '#f37021',
    colorSecond: '#ffb600',
    backLink: '#222222',
  },
};
