<template>
  <div
    v-if="isOpen"
    class="rotation-group-popup"
  >
    <div class="popup" :class="{ canViewDomains: viewDomains }">
      <div class="head">
        <span class="name">{{ $t(`domainManagement.popup.${actionName}`) }} {{ $t(`domainManagement.popup.rotationGroup`) }}</span>
        <ui-icon
          :size="20"
          class="close"
          lib="fa"
          substyle="fas"
          name="times"
          @click.native="close"
        />
      </div>
      <div class="form full-width">
        <div class="group">
          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.group_name.label`) }} <span class="required">*</span></span>
              <ui-input
                v-model="name"
                :placeholder="$t(`domainManagement.popup.form.group_name.placeholder`)"
                :disabled="isDefaultRotationGroup"
                autosize
                :class="{ error: isInvalidField('name') }"
                @blur="touchField('name')"
              />
            </div>
          </div>

          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.group_description.label`) }} <span class="required">*</span></span>
              <el-input
                v-model="description"
                :disabled="isDefaultRotationGroup"
                :placeholder="$t(`domainManagement.popup.form.group_description.placeholder`)"
                :rows="3"
                resize="none"
                type="textarea"
                :class="{ error: isInvalidField('description') }"
                @blur="touchField('description')"
              />
            </div>
          </div>

          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.auto_replace_banned_domains.label`) }}</span>

              <el-radio
                v-model="auto_replace_banned_domains"
                :label="true"
                :disabled="isDefaultRotationGroup"
              >
                {{ $t(`domainManagement.popup.form.auto_replace_banned_domains.yes`) }}
              </el-radio>
              <el-radio
                v-model="auto_replace_banned_domains"
                :label="false"
                :disabled="isDefaultRotationGroup"
              >
                {{ $t(`domainManagement.popup.form.auto_replace_banned_domains.no`) }}
              </el-radio>
            </div>
          </div>
          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.countries_to_check_availability.label`) }}</span>
              <el-radio
                v-model="auto_replace_domains"
                label="any_country"
                :disabled="!auto_replace_banned_domains"
              >
                {{ $t('domainManagement.popup.form.countries_to_check_availability.any') }}
              </el-radio>
              <el-radio
                v-model="auto_replace_domains"
                label="only_selected_countries"
                :disabled="!auto_replace_banned_domains"
              >
                {{ $t(`domainManagement.popup.form.countries_to_check_availability.only_selected`) }}
              </el-radio>
              <el-radio
                v-model="auto_replace_domains"
                label="except_selected_countries"
                :disabled="!auto_replace_banned_domains"
              >
                {{ $t(`domainManagement.popup.form.countries_to_check_availability.except_selected`) }}
              </el-radio>
            </div>
          </div>
          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t('domainManagement.popup.form.countries_list') }}</span>
              <el-select
                v-model="countriesList"
                :placeholder="$t('domainManagement.popup.form.countries_placeholder')"
                filterable
                class="select"
                :class="{ 'disable-select': !auto_replace_banned_domains || auto_replace_domains === 'any_country' }"
                multiple
                collapse-tags
                :disabled="!auto_replace_banned_domains || auto_replace_domains === 'any_country'"
              >
                <el-option
                  v-for="country in sortedCountries"
                  :key="country.code"
                  :label="country.name"
                  :value="country.code"
                />
              </el-select>
              <span
                v-if="getError('auto_replace_domains_countries_list', 0) || getError('auto_replace_domains_countries_list.0', 0)"
                class="input-error"
              >{{ getError('auto_replace_domains_countries_list', 0) || getError('auto_replace_domains_countries_list.0', 0) }}</span>
            </div>
          </div>
        </div>
        <div v-if="viewDomains" class="group">
          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.redirect_domain_id.label`) }}</span>
              <el-select
                v-model="redirect_domain_id"
                filterable
                class="select"
                :disabled="!editableDomains"
              >
                <el-option
                  v-for="redirectDomain in redirectDomains"
                  :key="redirectDomain.id"
                  :label="redirectDomain.domain_name"
                  :value="redirectDomain.id"
                />
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.adserver_domain_id.label`) }}</span>
              <el-select
                v-model="adserver_domain_id"
                filterable
                class="select"
                :disabled="!editableDomains"
              >
                <el-option
                  v-for="adserverDomain in adserverDomains"
                  :key="adserverDomain.id"
                  :label="adserverDomain.domain_name"
                  :value="adserverDomain.id"
                />
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.landings_storage_domain_id.label`) }}</span>
              <el-select
                v-model="landings_storage_domain_id"
                filterable
                class="select"
                :disabled="!editableDomains"
              >
                <el-option
                  v-for="landingsStorageDomain in landingsStorageDomains"
                  :key="landingsStorageDomain.id"
                  :label="landingsStorageDomain.domain_name"
                  :value="landingsStorageDomain.id"
                />
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.promo_api_domain_id.label`) }}</span>
              <el-select
                v-model="promo_api_domain_id"
                filterable
                class="select"
                :disabled="!editableDomains"
              >
                <el-option
                  v-for="promoApiDomain in promoApiDomains"
                  :key="promoApiDomain.id"
                  :label="promoApiDomain.domain_name"
                  :value="promoApiDomain.id"
                />
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          class="btn cancel-button"
          lib="fa"
          substyle="fas"
          icon="times"
          size="big"
          @click.native="close"
        >
          {{ $t(`domainManagement.popup.cancel`) }}
        </ui-button>
        <ui-button
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          size="big"
          :disabled="$v.$invalid"
          @click="save"
        >
          {{ $t(`domainManagement.popup.save`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
import {
  required, minLength, maxLength, requiredIf,
} from 'vuelidate/lib/validators';
import validateMixin from './mixin/validate';
import errorHandleMixin from '@/views/mixins/error-hadle';

export default {
  name: 'RotationGroupPopup',

  mixins: [validateMixin, errorHandleMixin],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    editableDomains: {
      type: Boolean,
      required: true,
    },
    viewDomains: {
      type: Boolean,
      required: true,
    },
    initalData: {
      type: Object,
      default() {
        return {
          name: '',
          description: '',
          auto_replace_banned_domains: false,
          auto_replace_domains: 'disabled',
          auto_replace_domains_countries_list: [],
          domains: {
            promo_api_domain_id: null,
            adserver_domain_id: null,
            landings_storage_domain_id: null,
            redirect_domain_id: null,
          },
          type: '',
        };
      },
    },
  },

  data() {
    return {
      name: this.initalData.name,
      description: this.initalData.description,
      auto_replace_banned_domains: this.initalData.auto_replace_banned_domains,
      auto_replace_domains: this.initalData.auto_replace_domains,
      auto_replace_domains_countries_list: this.initalData.auto_replace_domains_countries_list,

      redirect_domain_id: this.initalData.domains.redirect_domain_id,
      adserver_domain_id: this.initalData.domains.adserver_domain_id,
      landings_storage_domain_id: this.initalData.domains.landings_storage_domain_id,
      promo_api_domain_id: this.initalData.domains.promo_api_domain_id,

      redirectDomains: [],
      adserverDomains: [],
      landingsStorageDomains: [],
      promoApiDomains: [],
    };
  },

  computed: {
    countriesList: {
      get() {
        if (this.auto_replace_domains === 'any_country' || !this.auto_replace_banned_domains) {
          return [];
        }

        return this.auto_replace_domains_countries_list;
      },
      set(val) {
        this.auto_replace_domains_countries_list = val;
      },
    },
    actionName() {
      return (!this.initalData.id) ? 'add' : 'edit';
    },
    isDefaultRotationGroup() {
      return this.initalData.type === 'default';
    },
    sortedCountries() {
      return [...this.countries].sort((a, b) => {
        if (!this.countriesList?.includes(a.code)) {
          return 1;
        }
        if (!this.countriesList?.includes(b.code)) {
          return -1;
        }
        return a.name.localeCompare(b.name);
      });
    },
    countries() {
      return this.$store.state.misc.countries || [];
    },
  },

  validations() {
    return {
      name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255),
      },
      description: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(1024),
      },

      redirect_domain_id: {
        required,
      },
      adserver_domain_id: {
        required,
      },
      landings_storage_domain_id: {
        required,
      },
      promo_api_domain_id: {
        required,
      },
      auto_replace_domains_countries_list: {
        required: requiredIf(() => this.auto_replace_banned_domains && this.auto_replace_domains !== 'any_country'),
      },
    };
  },

  watch: {
    isOpen() {
      [
        this.name,
        this.description,

        this.redirect_domain_id,
        this.adserver_domain_id,
        this.landings_storage_domain_id,
        this.promo_api_domain_id,
        this.type,
      ] = [
        this.initalData.name,
        this.initalData.description,

        this.initalData.domains.redirect_domain_id,
        this.initalData.domains.adserver_domain_id,
        this.initalData.domains.landings_storage_domain_id,
        this.initalData.domains.promo_api_domain_id,
        this.initalData.type,
      ];

      if (this.initalData.auto_replace_domains === 'disabled') {
        this.auto_replace_banned_domains = false;
        this.auto_replace_domains = 'any_country';
        this.auto_replace_domains_countries_list = [];
      } else {
        this.auto_replace_banned_domains = true;
        this.auto_replace_domains = this.initalData.auto_replace_domains;
        this.auto_replace_domains_countries_list = this.initalData.auto_replace_domains_countries_list;
      }
    },
  },

  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },

  destroyed() {
    window.removeEventListener('keyup', this.keyupHandler);
  },

  async created() {
    const { data: { payload: serviceOrigins } } = await this.$api.getServiceOrigins();

    // { <origin_type>: <origin_id>, ....}
    const serviceOriginIdsMap = Object.keys(serviceOrigins).reduce((acc, serviceOriginKey) => {
      const serviceOrigin = serviceOrigins[serviceOriginKey];
      acc[serviceOrigin.service_type] = serviceOrigin.id;

      return acc;
    }, {});

    // Get domains for all service origins types
    const [
      adserverDomains,
      landingsStorageDomains,
      promoApiDomains,
      redirectDomains,
    ] = await Promise.all([
      this.getDomains(serviceOriginIdsMap.adserver),
      this.getDomains(serviceOriginIdsMap.landings_storage),
      this.getDomains(serviceOriginIdsMap.promo_api),
      this.getDomains(serviceOriginIdsMap.redirect),
    ]);

    const emptyDomain = { id: null, domain_name: this.$t('domainManagement.popup.empty') };

    this.redirectDomains = [emptyDomain, ...redirectDomains];
    this.adserverDomains = [emptyDomain, ...adserverDomains];
    this.landingsStorageDomains = [emptyDomain, ...landingsStorageDomains];
    this.promoApiDomains = [emptyDomain, ...promoApiDomains];
  },
  methods: {
    async getDomains(platform_service_origin_id) {
      const params = { offset: 0, limit: 1000, platform_service_origin_id };
      const { data: { payload } } = await this.$api.getServiceDomains(params);
      return payload;
    },

    close() {
      this.resetForm();
      this.$emit('close');
    },

    save() {
      const data = {
        name: this.name,
        description: this.description,
        auto_replace_domains: 'disabled',
        ...(this.auto_replace_banned_domains && {
          auto_replace_domains: this.auto_replace_domains,
          ...(this.auto_replace_domains !== 'any_country' && {
            auto_replace_domains_countries_list: this.auto_replace_domains_countries_list,
          }),
        }),

        domains: {
          redirect_domain_id: this.redirect_domain_id,
          adserver_domain_id: this.adserver_domain_id,
          landings_storage_domain_id: this.landings_storage_domain_id,
          promo_api_domain_id: this.promo_api_domain_id,
        },
      };

      if (!this.initalData.id) {
        this.$emit('create', data);
      } else {
        this.$emit('update', data);
      }
    },

    keyupHandler(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    },

    resetForm() {
      this.resetTouchedFields();

      this.name = '';
      this.description = '';
      this.auto_replace_banned_domains = this.initalData.auto_replace_banned_domains;
      this.auto_replace_domains = 'disabled';
      this.auto_replace_domains_countries_list = [];

      this.redirect_domain_id = null;
      this.adserver_domain_id = null;
      this.landings_storage_domain_id = null;
      this.promo_api_domain_id = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.rotation-group-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .select {
    cursor: pointer;

    /deep/ {
      .el-select__input {
        cursor: pointer;
      }
    }
  }

  .disable-select{
    opacity: .5;
    pointer-events: none;
  }

  .input-error {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    font-style: italic;
    text-transform: uppercase;
    font-weight: 500;
    text-align: right;
    color: var(--danger-color);
    margin-top: 6px;
    width: 100%;
  }

  .cancel-button {
    margin-right: 8px;
  }

  .popup {
    position: absolute;
    z-index: 902;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    width: 468px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &.canViewDomains {
      width: 936px;
    }

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid #eaeaea;
      padding: 16px 24px;
      box-sizing: border-box;
      margin-bottom: 0;

      .name {
        font-size: 20px;
        font-weight: 500;
      }

      .close {
        color: #303634;
        padding: 5px;
        font-size: 32px;
        cursor: pointer;
      }
    }
    .form {
      display: flex;
      padding: 16px 24px;
      box-sizing: border-box;
      gap: 24px;
    }

    .group {
      flex: 1;
    }

    .field {
      /deep/ .el-radio {
        margin-right: 12px!important;
      }
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      margin-top: 0;
    }
  }

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
</style>
