<template>
  <div class="rotation-groups">
    <RotationGroupPopup
      v-if="canEdit"
      ref="rotationGroupPopup"
      :is-open="isOpenPopup"
      :inital-data="editableRow"
      :editable-domains="canEditDomains"
      :view-domains="canViewDomains"
      :props-errors.sync="errors"
      @close="closePopup"
      @create="createRotationGroup"
      @update="updateRotationGroup"
    />

    <RotationGroupAffiliatesPopup
      :is-open="isOpenAffiliatePopup"
      :inital-data="editableAffiliateRow"
      @close="closeAffiliatePopup"
    />

    <ui-confirm
      ref="confirm"
      :width="480"
      :type="'warning'"
      :action-name="$t('domainManagement.buttons.delete')"
      :action-fa-icon="'trash-alt'"
      :data="confirmData"
      @save="deleteHandler"
      @close="confirmClose"
    >
      <div
        slot="title"
        v-html="$t('domainManagement.buttons.delete')"
      />
      <div class="body__wrap">
        <i
          class="fas fa-trash-alt warning"
        />
        <div
          class="body__msg"
          v-html="$t('domainManagement.messages.delete', { name: confirmData.name })"
        />
      </div>
    </ui-confirm>

    <div class="toolbar">
      <div class="wrapper">
        <div class="section">
          <span class="title">{{ $t('domainManagement.pageTitle.rotationGroups') }}</span>
          <ui-input
            v-model.trim="search"
            class="rotation-groups-search"
            :width="180"
            :is-search="true"
            @search="getRotationGroups"
          />
        </div>
        <div class="section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="paginationHandler"
            />
          </div>
          <ui-button
            v-if="canEdit"
            color="green"
            lib="fa"
            substyle="fas"
            filled
            icon="plus"
            class="btn btn-add"
            @click="openPopup"
          >
            {{ $t('domainManagement.buttons.add_rotation_group') }}
          </ui-button>
        </div>
      </div>
    </div>
    <ui-table
      v-loading="isDataLoading"
      :lazy-loading-delay="4000"
      :fields="fields"
      :data="data"
      :rows-count="limit"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      lazy-loading
      class="table"
      i18n-path="domainManagement"
      element-loading-custom-class="data-loader-spinner"
      @sort="sortingHandler"
    >
      <template
        v-if="canEdit || canViewDomains"
        slot="append"
      >
        <el-table-column width="70">
          <template slot-scope="scope">
            <ActionIcon
              :tooltip="$t('domainManagement.buttons.edit')"
            >
              <el-link
                v-if="canEdit"
                class="action-ico"
                type="success"
                @click="editHandler(scope.row)"
              >
                <ui-icon
                  name="pen"
                  :color="$theme.accentColor"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
            <ActionIcon
              :tooltip="$t('domainManagement.buttons.delete')"
            >
              <el-link
                v-if="canEdit"
                :disabled="scope.row.affiliates_using_group_count > 0"
                class="action-ico"
                type="danger"
                @click="confirmOpen(scope.row)"
              >
                <ui-icon
                  name="trash-alt"
                  :color="$theme.dangerColor"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>
import { pageSizeMixin, resolvePageSize } from '../../service/pageSize';
import queryParamsMixin from '../mixins/query-params';

import RotationGroupPopup from '@/components/Popups/RotationGroupPopup';
import RotationGroupAffiliatesPopup from '@/components/Popups/RotationGroupAffiliatesPopup';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'rotation-groups/list/limit'; // for localstorage
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'RotationGroups',

  components: {
    RotationGroupPopup,
    RotationGroupAffiliatesPopup,
  },

  mixins: [pageSizeMixin, queryParamsMixin],

  data() {
    return {
      isDataLoading: false,
      isOpenPopup: false,
      isOpenAffiliatePopup: false,

      search: '',
      count: 0,
      page: 1,
      limit: pageSize,
      sortProp: 'incrementing_id',
      sortOrder: 'desc',
      errors: {},

      data: [],
      fields: [
        {
          name: 'incrementing_id',
          sortable: 'custom',
          align: 'left',
          width: '60',
        },
        {
          name: 'name',
          label: 'group_name',
          sortable: 'custom',
          minWidth: '100',
        },
        {
          name: 'affiliates_using_group_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
          computedClass: () => this.isAffiliatesFilter && 'link',
          click: this.handlerRotationAffiliates,
        },
        {
          name: 'type',
          sortable: 'custom',
          computedValue: row => this.$t(`domainManagement.fields.type.values.${row.type}`),
        },
        {
          name: 'dns_records_state',
          sortable: 'custom',
          computedValue: row => this.$t(`domainManagement.fields.dns_records_state.values.${row.dns_records_state}`),
          computedClass: value => `row-value--${value}`,
        },
        {
          name: 'ssl_certificate_state',
          sortable: 'custom',
          computedValue: row => this.$t(`domainManagement.fields.ssl_certificate_state.values.${row.ssl_certificate_state}`),
          computedClass: value => `row-value--${value}`,
        },
        {
          name: 'reachability_state',
          sortable: 'custom',
          computedValue: row => this.$t(`domainManagement.fields.reachability_state.values.${row.reachability_state}`),
          computedClass: value => `row-value--${value}`,
        },
        {
          name: 'traffic_last_30_days_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
        },
        {
          name: 'traffic_last_7_days_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
        },
        {
          name: 'traffic_last_day_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
        },
        {
          name: 'created_at',
          sortable: 'custom',
          width: '120',
          format: 'date-time',
        },
      ],

      editableRow: undefined,
      editableRowId: undefined,
      editableAffiliateRow: undefined,

      confirmData: {},
    };
  },

  computed: {
    isAffiliatesFilter() {
      if (!this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl']) {
        return this.$store.getters['auth/currentAcl'].affiliates_view === 'allow';
      } return !!this.$store.getters['auth/adminAcl'].is_superuser;
    },
    isSuperUser() {
      return this.$store.getters['auth/adminAcl'].is_superuser;
    },

    canEdit() {
      return this.isSuperUser || this.$store.getters['auth/currentAcl'].service_rotation_groups_edit === 'allow';
    },

    canViewDomains() {
      return this.isSuperUser || this.$store.getters['auth/currentAcl'].service_rotation_groups_domains_view === 'allow';
    },

    canEditDomains() {
      return this.isSuperUser || this.$store.getters['auth/currentAcl'].service_rotation_groups_domains_edit === 'allow';
    },

    queryParams() {
      return {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        search: this.search || null,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
      };
    },
  },

  methods: {
    handlerRotationAffiliates(row) {
      if (!this.isAffiliatesFilter) return;

      this.isOpenAffiliatePopup = true;
      this.editableAffiliateRow = row;
    },

    // For query-params mixin (auto-change url query)
    fetch() {
      return this.getRotationGroups();
    },

    async getRotationGroups(silent) {
      if (!silent) {
        this.isDataLoading = true;
      }

      const { data: { payload, misc } } = await this.$api.getRotationGroups(this.queryParams);

      this.count = misc.count;
      this.data = payload;

      if (!silent) {
        this.isDataLoading = false;
      }
    },

    paginationHandler(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;

        this.getRotationGroups();
      }
    },

    sortingHandler({ prop, order }) {
      if (order === '') return;

      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }

      this.getRotationGroups();
    },

    closePopup() {
      this.isOpenPopup = false;
      this.editableRow = undefined;
      this.errors = {};
    },

    openPopup() {
      this.isOpenPopup = true;
    },

    closeAffiliatePopup() {
      this.isOpenAffiliatePopup = false;
      this.editableAffiliateRow = undefined;
    },

    async createRotationGroup(data) {
      this.errors = {};

      try {
        await this.$api.createRotationGroup(data);
        await this.getRotationGroups(true);
        this.$refs.rotationGroupPopup.close();
      } catch (e) {
        this.errors = e.data.errors;
      }
    },

    async updateRotationGroup(data) {
      this.errors = {};

      try {
        await this.$api.updateRotationGroup(this.editableRowId, data);
        await this.getRotationGroups(true);
        this.$refs.rotationGroupPopup.close();
        this.editableRowId = undefined;
      } catch (e) {
        this.errors = e.data.errors;
      }
    },

    async deleteHandler(action, row) {
      this.$refs.confirm.close();
      await this.$api.deleteRotationGroup(row.id);
      await this.getRotationGroups();
    },

    async editHandler(row) {
      const { data: { payload } } = await this.$api.getRotationGroupById(row.id);

      this.editableRow = payload;
      this.editableRowId = row.id;

      this.openPopup();
    },

    confirmOpen(row) {
      this.confirmData = row;
      this.$refs.confirm.open();
    },

    confirmClose() {
      this.confirmData = {};
    },
  },
};
</script>
