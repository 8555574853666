import ConfirmPopup from '@/components/Popups/ConfirmPopup.vue';
import ConfirmEditPopup from '@/components/Popups/ConfirmEditPopup.vue';

import UiIcon from '@/components/UIElements/UiIcon.vue';
import UiButton from '@/components/UIElements/UiButton.vue';
import UiInput from '@/components/UIElements/UiInput.vue';
import UiSelect from '@/components/UIElements/UiSelect.vue';
import UiTable from '@/components/UIElements/UiTable.vue';
import UiPagination from '@/components/UIElements/UiPagination.vue';
import UiFilter from '@/components/UIElements/UiFilter.vue';
import UiFilterSelect from '@/components/UIElements/UiFilterSelect.vue';
import UiPopupCol from '@/components/UIElements/UiPopupCol.vue';
import UiPopup from '@/components/UIElements/UiPopup.vue';
import UiConfirm from '@/components/UIElements/UiConfirm.vue';
import UiTagsInput from '@/components/UIElements/UiTagsInput.vue';
import ActionIcon from '@/components/ActionIcon.vue';
import UiCurrencyPicker from '@/components/UIElements/UiCurrencyPicker.vue';

export default (Vue) => {
  Vue.component('ConfirmPopup', ConfirmPopup);
  Vue.component('ConfirmEditPopup', ConfirmEditPopup);
  Vue.component('UiPagination', UiPagination);
  Vue.component('UiIcon', UiIcon);
  Vue.component('UiButton', UiButton);
  Vue.component('UiInput', UiInput);
  Vue.component('UiSelect', UiSelect);
  Vue.component('UiTable', UiTable);
  Vue.component('UiFilter', UiFilter);
  Vue.component('UiFilterSelect', UiFilterSelect);
  Vue.component('UiPopupCol', UiPopupCol);
  Vue.component('UiPopup', UiPopup);
  Vue.component('UiConfirm', UiConfirm);
  Vue.component('ActionIcon', ActionIcon);
  Vue.component('UiTagsInput', UiTagsInput);
  Vue.component('UiCurrencyPicker', UiCurrencyPicker);
};
