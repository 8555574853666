var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-popup-col',{ref:"columnsPopup",staticClass:"columns-popup",attrs:{"width":1300,"title":_vm.$t('reports.colunms_settings.header'),"actions-disabled":_vm.actionsDisabled},on:{"reset":_vm.resetColumnsOrder,"save":_vm.applyColumnsSettings,"cancel":_vm.cancelColumnsSettings}},[_c('div',{staticClass:"columns-popup__wr",class:{ 'is-show': _vm.showSelectAllCol }},[_c('div',{staticClass:"columns-popup__left"},[_c('div',{staticClass:"columns-popup__title"},[_vm._v(" "+_vm._s(_vm.$t('reports.colunms_settings.title_visible'))+" ")]),_c('div',{staticClass:"controls"},[_c('ui-input',{staticClass:"search-input",attrs:{"placeholder":_vm.$t('reports.colunms_settings.placeholder_search'),"width":"100%","pre-icon":"search"},model:{value:(_vm.searchColumns),callback:function ($$v) {_vm.searchColumns=$$v},expression:"searchColumns"}})],1),_c('div',{staticClass:"controls controls-padding"},[(!_vm.searchColumns)?_c('div',{staticClass:"checkbox checkbox-custom",class:{ checked: _vm.selectAllCol },on:{"click":function($event){_vm.selectAllCol = !_vm.selectAllCol;
            _vm.selectAllColumns(_vm.selectAllCol, 'all');}}},[_c('div',{staticClass:"box"},[_c('ui-icon',{staticClass:"chk-icon",attrs:{"name":'check',"size":8,"lib":"fa","substyle":"fas"}})],1),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('reports.colunms_settings.select_all')))])]):_vm._e(),(_vm.searchColumns)?_c('div',{staticClass:"checkbox checkbox-custom",class:{ checked: _vm.selectSearchCol },on:{"click":function($event){_vm.selectSearchCol = !_vm.selectSearchCol;
            _vm.selectAllColumns(_vm.selectSearchCol, 'search');}}},[_c('div',{staticClass:"box"},[_c('ui-icon',{staticClass:"chk-icon",attrs:{"name":'check',"size":8,"lib":"fa","substyle":"fas"}})],1),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('reports.colunms_settings.select_match')))])]):_vm._e(),_c('div',{staticClass:"checkbox checkbox-custom",class:{ checked: _vm.showSelectAllCol },on:{"click":function($event){_vm.showSelectAllCol = !_vm.showSelectAllCol}}},[_c('div',{staticClass:"box"},[_c('ui-icon',{staticClass:"chk-icon",attrs:{"name":'check',"size":8,"lib":"fa","substyle":"fas"}})],1),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('reports.colunms_settings.show_only_select')))])])]),_c('el-collapse',{model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},[(_vm.noData)?_c('div',{staticClass:"field__empty-text"},[_c('ui-icon',{attrs:{"size":32,"name":"folder-open","lib":"fa","substyle":"fal","color":"#a6b2c4"}}),_c('div',[_vm._v(_vm._s(_vm.$t('reports.colunms_settings.no_data')))])],1):_vm._e(),_vm._l((_vm.columnGroup.filter((e) => _vm.calcAll(e))),function(group,index){return _c('el-collapse-item',{key:index,class:{
            'show-only-select': _vm.showSelectAllCol && _vm.calcExpand(group.group_name) === 0,
            'hide-search': _vm.arrayHideSearch[index] === 0,
          },attrs:{"name":group.group_name}},[_c('template',{slot:"title"},[_c('div',{staticClass:"columns-popup__col-header"},[_c('div',{staticClass:"columns-popup__sub-wr"},[(!_vm.searchColumns)?_c('div',{staticClass:"checkbox checkbox-custom",class:{ checked: _vm.selectBlocks[index] || _vm.calcExpand(group.group_name) === _vm.calcAll(group) },on:{"click":function($event){$event.stopPropagation();_vm.selectAllBlock(group.group_name, index, 'all');
                    _vm.checkSelectAll();}}},[_c('div',{staticClass:"box"},[_c('ui-icon',{staticClass:"chk-icon",attrs:{"name":'check',"size":8,"lib":"fa","substyle":"fas"}})],1)]):_vm._e(),(_vm.searchColumns)?_c('div',{staticClass:"checkbox checkbox-custom",class:{ checked: _vm.selectBlocksSearch[index] },on:{"click":function($event){$event.stopPropagation();_vm.selectBlocksSearch[index] = !_vm.selectBlocksSearch[index];
                    _vm.selectAllBlock(group.group_name, index, 'search');
                    _vm.checkSearchAll();}}},[_c('div',{staticClass:"box"},[_c('ui-icon',{staticClass:"chk-icon",attrs:{"name":'check',"size":8,"lib":"fa","substyle":"fas"}})],1)]):_vm._e(),_c('span',[_c('span',{staticClass:"columns-popup__sub-title"},[_vm._v(" "+_vm._s(_vm.getTranslateGroup(group.group_name))+" ")]),_c('span',{staticClass:"columns-popup__count-col"},[_vm._v(_vm._s(_vm.calcExpand(group.group_name))+" / "+_vm._s(_vm.calcAll(group))+" "+_vm._s(_vm.$t('reports.colunms_settings.colunms'))+" ")])])]),_c('span',{staticClass:"columns-popup__count-col is-right"},[_vm._v(_vm._s(_vm.$t(`reports.colunms_settings.${_vm.callapseLabel(group.group_name)}`)))])])]),_c('div',{staticClass:"wrap-item"},_vm._l((_vm.$_.filter(group.items, (column) => {
                return (
                  _vm.getTranslateMetrics(column.name)
                    .title.toLowerCase()
                    .indexOf(_vm.searchColumns.toLowerCase()) > -1 ||
                  _vm.getTranslateMetrics(column.name)
                    .description.toLowerCase()
                    .indexOf(_vm.searchColumns.toLowerCase()) > -1 ||
                  _vm.filtrableInner(group.group_name, column.name)
                );
              })),function(column,key){return _c('div',{key:key},[_c('div',{staticClass:"item",class:{
                  'show-only-select': !_vm.watchSelectedMain(column.name),
                }},[_c('div',{staticClass:"checkbox checkbox-custom",class:{ checked: _vm.watchSelectedMain(column.name) },on:{"click":function($event){_vm.showSelectedMain(column.name);
                    _vm.checkBlock(group.group_name, index, _vm.searchColumns ? 'search' : 'all');
                    _vm.checkSelectAll();
                    _vm.checkSearchAll();}}},[_c('div',{staticClass:"box"},[_c('ui-icon',{staticClass:"chk-icon",attrs:{"name":'check',"size":8,"lib":"fa","substyle":"fas"}})],1),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.getTranslateMetrics(column.name).title))])]),_c('span',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.getTranslateMetrics(column.name).description)+" ")])]),(_vm.$_.has(column, 'items'))?_c('div',_vm._l((_vm.$_.filter(column.items, (subcolumn) => {
                    return (
                      _vm.getTranslateMetrics(subcolumn.name)
                        .title.toLowerCase()
                        .indexOf(_vm.searchColumns.toLowerCase()) > -1 ||
                      _vm.getTranslateMetrics(subcolumn.name)
                        .description.toLowerCase()
                        .indexOf(_vm.searchColumns.toLowerCase()) > -1
                    );
                  })),function(subcolumn,subkey){return _c('div',{key:subkey,staticClass:"item item-sub",class:{
                    'show-only-select': !_vm.watchSelectedMain(subcolumn.name),
                  }},[_c('div',{staticClass:"checkbox checkbox-custom",class:{ checked: _vm.watchSelectedMain(subcolumn.name) },on:{"click":function($event){_vm.showSelectedMain(subcolumn.name);
                      _vm.checkBlock(group.group_name, index, _vm.searchColumns ? 'search' : 'all');
                      _vm.checkSelectAll();
                      _vm.checkSearchAll();}}},[_c('div',{staticClass:"box"},[_c('ui-icon',{staticClass:"chk-icon",attrs:{"name":'check',"size":8,"lib":"fa","substyle":"fas"}})],1),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.getTranslateMetrics(subcolumn.name).title))])]),_c('span',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.getTranslateMetrics(subcolumn.name).description)+" ")])])}),0):_vm._e()])}),0)],2)})],2)],1),_c('div',{staticClass:"columns-popup__right"},[_c('div',{staticClass:"columns-popup__title"},[_vm._v(" "+_vm._s(_vm.$t('reports.colunms_settings.title_order'))+" ")]),_c('div',{staticClass:"columns-popup__col-header"},[_c('span',{staticClass:"columns-popup__sub-title"},[_vm._v(_vm._s(_vm.$t('reports.colunms_settings.vis_column')))]),_c('span',{staticClass:"columns-popup__count-col"},[_vm._v(" "+_vm._s(_vm.calcColumn().visible.length)+" "+_vm._s(_vm.$t('reports.colunms_settings.colunms'))+" ")])]),_c('div',{staticClass:"list"},[_c('draggable',{attrs:{"list":_vm.allMetricsClone}},_vm._l((_vm.allMetricsClone),function(column,index){return _c('div',{key:index,staticClass:"item",class:{
              'hid-row': column.shadowSelected === false && _vm.showOnlySelected === true,
            }},[_c('div',{staticClass:"checkbox checkbox-custom",class:{ checked: column.shadowSelected }},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.getTranslateMetrics(column.name).title))])]),_c('span',{staticClass:"description",staticStyle:{"display":"flex","align-item":"center"}},[_vm._v(" "+_vm._s(_vm.getTranslateMetrics(column.name).description)+" ")]),_c('ui-icon',{staticClass:"handle",attrs:{"name":'handle',"size":5,"color":"#a6b2c4"}})],1)}),0)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }