export default {
  /**
   * Get Dashboards
   *
   * @return {Promise<Object>} api response
   */
  getDashboards(params) {
    return this.affiliatesApi.get('/dashboards', { params });
  },

  /**
   * Create Dashboard
   *
   * @param {Object} data
   * @param {String} data.name - Name Dashboard
   * @param {Number} data.refresh_period_seconds - Refresh interval only [ 60, 300, 900, 1800, 3600 ]
   * @param {Object[]} data.rows - []
   * @param {String} data.rows[].name - Name Section dashboard
   * @param {Object[]} data.rows[].panels - Array card this row
   * @param {String} data.rows[].panels[].type - Type only [ line_chart, bar_chart, kpi ]
   * @param {String} data.rows[].panels[].metric_name - Name
   * @param {String} data.rows[].panels[].chart_group_by - Group by only [ daily, weekly, monthly, yearly ]
   * @param {String} data.rows[].panels[].kpi_period - Period only [ daily, weekly, monthly, yearly, rolling_30_days ]
   * @param {String} data.rows[].panels[].kpi_comparison_enabled
   * @param {String} data.rows[].panels[].kpi_comparison_period - Comparison period only [ this_vs_last, this_vs_last_td, last_vs_one_before ]
   *
   * @return {Promise<Object>} api response
   */
  createDashboards(data) {
    return this.affiliatesApi.post('/dashboards', data);
  },

  /**
   * Get Dashboards count
   *
   * @param {Object} params
   * @param {String} [params.search]
   *
   * @return {Promise<Object>} api response
   */
  getDashboardsCount(params) {
    return this.affiliatesApi.get('/dashboards/count', { params });
  },

  /**
   * Get Dashboards template
   *
   * @param {Object} [params]
   *
   * @return {Promise<Object>} api response
   */
  getDashboardsTemplates(params) {
    return this.affiliatesApi.get('/dashboards/templates', { params });
  },

  /**
   * Get Dashboards by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */

  getDashboard(id) {
    return this.affiliatesApi.get(`/dashboards/${id}`);
  },

  /**
   * Set Default Dashboard by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */

  setDefaultDashboard(id) {
    return this.affiliatesApi.post(`/dashboards/${id}/set-default`);
  },

  /**
   * Get preview stats panel
   *
   * @return {Promise<Object>} api response
   */

  dashboardsStats(params) {
    return this.affiliatesApi.post('/dashboards/stats', params);
  },

  /**
   * Update Dashboard
   *
   * @param {String} id
   * @param {Object} data
   * @param {String} data.name - Name Dashboard
   * @param {Number} data.refresh_period_seconds - Refresh interval only [ 60, 300, 900, 1800, 3600 ]
   * @param {Object[]} data.rows - []
   * @param {String} data.rows[].name - Name Section dashboard
   * @param {Object[]} data.rows[].panels - Array card this row
   * @param {String} data.rows[].panels[].type - Type only [ line_chart, bar_chart, kpi ]
   * @param {String} data.rows[].panels[].metric_name - Name
   * @param {String} data.rows[].panels[].chart_group_by - Group by only [ daily, weekly, monthly, yearly ]
   * @param {String} data.rows[].panels[].kpi_period - Period only [ daily, weekly, monthly, yearly, rolling_30_days ]
   * @param {String} data.rows[].panels[].kpi_comparison_enabled
   * @param {String} data.rows[].panels[].kpi_comparison_period - Comparison period only [ this_vs_last, this_vs_last_td, last_vs_one_before ]
   *
   * @return {Promise<Object>} api response
   */
  updateDashboard(id, data) {
    return this.affiliatesApi.put(`/dashboards/${id}`, data);
  },

  /**
   * Delete Dashboard by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  deleteDashboard(id) {
    return this.affiliatesApi.delete(`/dashboards/${id}`);
  },

  /**
   * Update Forecast Dashboard Panel
   *
   * @param {String} id
   * @param {String} idPanel
   * @param {Object} data
   * @param {Boolean} data.enable_forecast - set forecast for panel
   *
   * @return {Promise<Object>} api response
   */
  updateDashboardPanelForecast(id, idPanel, data) {
    return this.affiliatesApi.put(
      `/dashboards/${id}/panels/${idPanel}/update-forecast`,
      data,
    );
  },

  /**
   * Update Timeframe Dashboard
   *
   * @param {String} id
   * @param {Object} data
   * @param {String} data.relative_timeframe - time label relative timeframe
   * @param {String} data.custom_timeframe_from - time from
   * @param {String} data.custom_timeframe_to - time to
   *
   * @return {Promise<Object>} api response
   */
  updateDashboardTimeframe(id, data) {
    return this.affiliatesApi.put(`/dashboards/${id}/update-timeframe`, data);
  },
};
