export default {
  /**
   * Get rotation groups count
   *
   * @param {Object} params
   * @param {String} params.search
   *
   * @return {Promise<Object>} api response
   */
  getRotationGroupsCount(params) {
    return this.affiliatesApi.get('/domains-management/service-rotation-groups/count', { params });
  },

  /**
   * Get rotation groups
   *
   * @param {Object} params
   * @param {String} params.sort_column
   * @param {String} params.sort_dir
   * @param {String} params.search
   * @param {Number} params.limit
   * @param {Number} params.offset
   *
   * @return {Promise<Object>} api response
   */
  getRotationGroups(params) {
    return this.affiliatesApi.get('/domains-management/service-rotation-groups', { params });
  },

  /**
   * Create rotation group
   *
   * @param {Object} data
   * @param {String} data.name
   * @param {String} data.description
   * @param {String} data.auto_replace_banned_domains
   *
   * @return {Promise<Object>} api response
   */
  createRotationGroup(data) {
    return this.affiliatesApi.post('/domains-management/service-rotation-groups', data);
  },

  /**
   * Get rotation group by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  getRotationGroupById(id) {
    return this.affiliatesApi.get(`/domains-management/service-rotation-groups/${id}`);
  },

  /**
   * Update rotation group
   *
   * @param {String} id
   * @param {Object} data
   * @param {String} data.name
   * @param {String} data.description
   * @param {String} data.auto_replace_banned_domains
   *
   * @return {Promise<Object>} api response
   */
  updateRotationGroup(id, data) {
    return this.affiliatesApi.put(`/domains-management/service-rotation-groups/${id}`, data);
  },

  /**
   * Delete rotation group by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  deleteRotationGroup(id) {
    return this.affiliatesApi.delete(`/domains-management/service-rotation-groups/${id}`);
  },
};
