export default {
  dashboards: {
    autorefresh: {
      title: 'Autorefresh',
      300: '5 Min',
      600: '10 Min',
      900: '15 Min',
      1800: '30 Min',
      3600: '1 Hour',
    },

    compared: {
      this_vs_last: 'This vs Last',
      this_vs_last_td: 'This vs Last TD',
      last_vs_one_before: 'Last vs One before',
    },

    peroid: {
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      yearly: 'Yearly',
      rolling_30_days: 'Rolling 30 days',
    },

    peroid_table: {
      last_24_hours: 'Daily',
      last_week: 'Weekly',
      last_month: 'Monthly',
      last_year: 'Yearly',
    },

    peroid_stat: {
      this_day: 'Daily',
      this_week: 'Weekly',
      this_month: 'Monthly',
      this_year: 'Yearly',
      rolling_30_days: 'Rolling 30 days',
    },

    popup_column: {
      metrics: 'Metric',
      select_metric: 'Select Metric',
      configure_metric: 'Configure Panel',
      preview_panel: 'Preview Panel',
      view: 'View',
      period: 'Period',
      compare: 'Compare',
      type: 'Type',
      card: 'Stat',
      chart: 'Chart',
      table: 'Table',
    },

    type_chart: {
      line: 'Line chart',
      bar: 'Bar chart',
      pie: 'Pie chart',
    },

    other: {
      edit_alert: 'Drag and drop metrics to rearrange them.',
      set_as_default: 'Set as Default',
      dont_set_as_default: 'Don\'t set as default',
      add_metric: 'Add Panel',
      edit_metric: 'Edit Panel',
      new_section: 'New Section',
      default_section: 'New Section',
      new_dashboard: 'New Dashboard',
      edit_dashboard: 'Edit Dashboard',
      refresh: 'Refresh',
      edit: 'Edit',
      delete: 'Delete',
      cancel: 'Cancel',
      save_changes: 'Save Changes',
      save_dashboard: 'Save dashboard',
      panel_name: 'Panel Name',
      panel_name_placeholder: 'Input Panel Name',
      others: 'Others',
      forecast: 'Forecast',
      show_forecast: 'Show Forecast',
      hide_forecast: 'Hide Forecast',
      enable_comparison: 'Comparison',
      clone: 'Clone',
      clone_success: 'Copied:',
      clone_danger: 'Fail to cloned',
    },

    confirm: {
      title: 'Attention',
      text: 'You have entered new information on this page. If you leave the page without saving changes, they will be lost.',
      btn_сonfirm: 'Go',

      delete_title: 'Delete Dashboard?',
      delete_text: 'Are you sure you want to delete the dashboard?',
      delete_btn_сonfirm: 'Delete',

      cancel_title: 'Attention',
      cancel_text: 'You’ve changed some details here. Do you want to leave the page without saving?',
      cancel_btn_сonfirm: 'Yes',
      cancel_btn_cancel: 'No',

      clone_title: 'Dashboard clone',
      clone_text: 'Clone a dashboard with a name:',
      clone_copy: 'Copy',
    },

    metric_table: {
      fields: {
        dimension: {
          title: 'Dimension',
        },
        current: {
          title: 'This',
        },
        previous: {
          title: 'Last',
        },
        one_before: {
          title: 'One Before',
        },
        difference_percent: {
          title: 'Change (%)',
        },
      },
    },

    grouping: {
      grouping: 'Grouping',
      group_by: 'Group By',
      and_show: 'and show',
      result: 'results',
      other_value: 'Show other values',
      affiliate_email: 'Affiliates',
      sub_affiliate_email: 'Sub-Affiliates',
      player_country_name: 'Player country',
      player_region_name: 'Player region',
      affiliate_offer_name: 'Offer',
      payment_system_name: 'Payment System',
      top: 'Top',
    },

    dashboards_filters: {
      filter: 'Filters',
      add_filter: 'Add Filter',
      reload_filter: 'Reload for view',
      series: 'Series',
      label_series: 'Name',
      series_placeholder: 'Input name',
      name_series: 'Series name',
      new_series: 'New series',
      add_series: 'Add Series',
      tooltip_copy: 'Copy',
      tooltip_delete: 'Delete',
    },


  },
};
