import {
  NAV_SET_MAIN_VIEW,
  NAV_SET_SUB_VIEW,
  NAV_SET_IS_AUTH,
} from '../action_types/nav';

const getters = {
  getSubView: state => state.subView,
  getMainView: state => state.mainView,
  isAuthView: state => state.isAuth,
};

const mutations = {
  [NAV_SET_MAIN_VIEW]: (state, payload) => {
    state.mainView = payload;
  },
  [NAV_SET_SUB_VIEW]: (state, payload) => {
    state.subView = payload;
  },
  [NAV_SET_IS_AUTH]: (state, payload) => {
    state.isAuth = payload;
  },
};

const state = {
  mainView: '',
  subView: '',
  isAuth: false,
};

export default {
  state,
  getters,
  mutations,
  namespaced: true,
};
