export default {
  profile: {
    active_sessions: 'Active Sessions',
    terminate_all: 'Terminate All',
    terminate: 'Terminate',
    registration_date: 'Registration Date',
    list: {
      fields: {
        device_model: {
          title: 'Device',
        },
        os: {
          title: 'OS',
        },
        user_ip: {
          title: 'IP Address',
        },
        last_active: {
          title: 'Last Activity',
        },
      },
    },
    tz_invalid: 'Invalid time zone',
  },
  profile_popup: {
    reset_password_title: 'Change Password',
    reset_password_action: 'Change Password',
    reset_password_old_label: 'Current Password',
    reset_password_old_placeholder: 'Current Password',
    reset_password_new_label: 'New Password',
    reset_password_new_placeholder: 'New Password',
    reset_password_repeat_label: 'Confirm New Password',
    reset_password_repeat_placeholder: 'Confirm New Password',

    terminate_title: 'Terminate Session?',
    terminate_action: 'Terminate',
    terminate_msg: 'Are you sure you want terminate this session?',

    terminate_title_all: 'Terminate All Session?',
    terminate_action_all: 'Terminate All',
    terminate_msg_all: 'Are you sure you want to terminate all sessions?',

    setup_2fa: 'Setup 2FA',
    setup_tfa_title: 'Reset 2FA',
    setup_tfa_action: 'Reset 2FA',
    setup_tfa_text: 'Input your current password to setup 2FA configuration',
    setup_tfa_qr_text: ' Open Google Authenticator and <span>scan QR-code</span>',

    repeat_error: 'Passwords do not match',
    done_button: 'Done',
    action_unlock_period: 'Actions Unlock Period',
    unlock_period_warning: 'If you will extend the period for unlocking significant actions, you may face a risk to your account in the event of unathorized access to your device.',
  },
};
