<template>
  <div
    v-loading="isDataLoading"
    class="payment-info"
  >
    <UploadFilesPopup
      :is-open="isOpenPopup"
      :payment-id="info.id"
      :uploaded-files="info.files"
      @update-payment-info="getPaymentInfo"
      @close="closePopup"
    />

    <div class="head">
      <ui-confirm
        ref="confirm"
        :width="480"
        :type="confirm.type"
        :action-name="confirm.actionName"
        :action="confirm.action"
        action-icon="check"
        @save="actionPayment"
      >
        <div slot="title">
          {{ confirm.title }}
        </div>
        <div class="body__wrap">
          <i
            v-if="confirm.action === 'deleteNote'"
            :class="confirm.type"
            class="fas fa-trash-alt"
          />
          <i
            v-else
            :class="confirm.type"
            class="fas fa-exclamation-triangle"
          />
          <div
            class="body__msg"
            v-html="confirm.msg"
          />
        </div>
      </ui-confirm>

      <div class="wrapper">
        <div
          v-if="!$_.isEmpty(info)"
          class="nav"
        >
          <router-link
            v-if="fromPage === 'payments_moderation' || info.moderation_status !== 'approved'"
            class="back"
            :to="`/payments/moderation/${info.moderation_status ? info.moderation_status : '' }`"
          >
            {{ $t(`payments.page_title.payments_moderation.name`) }}
            {{ $t(`payments.page_title.payments_moderation.${info.moderation_status}`) }}
          </router-link>
          <router-link
            v-else
            class="back"
            :to="`/payments/payout/${info.payout_status}`"
          >
            {{ $t(`payments.page_title.payments_payout.name`) }}
            {{ $t(`payments.page_title.payments_payout.${info.payout_status}`) }}
          </router-link>
          <span class="title">> {{ $t('payments.info.payment_info') }}</span>
        </div>
        <div class="toolbar">
          <span class="title"><span class="title-id">ID: {{ info.incrementing_id }} </span> {{ $t('payments.info.payment_for') }} {{ info.affiliate_email }} ({{ formatDate(info.payment_period_start) }} - {{ formatDate(info.payment_period_end) }})</span>
        </div>
      </div>
    </div>
    <div
      v-if="reviewActions && (info.moderation_status === 'on_review' || ( info.moderation_status === 'approved' && (info.payout_status === 'not_paid' || info.payout_status === 'for_rework')))"
      class="action-btn"
    >
      <div class="wrapper">
        <div
          v-if="reviewActions && info.moderation_status === 'on_review'"
          class="btns"
        >
          <el-tooltip
            :open-delay="300"
            placement="bottom"
            :content="info.site_payment_method_id !== null ? $t('payments.info.approve_tooltip') : $t('payments.info.approve_wallet')"
            :disabled="!isDisabledButton"
          >
            <ui-button
              color="green"
              filled
              lib="fa"
              substyle="fas"
              icon="check"
              class="main"
              :disabled="isDisabledButton"
              @click="approveHandler"
            >
              {{ $t('payments.info.approve') }}
            </ui-button>
          </el-tooltip>
          <ui-button
            color="green"
            lib="fa"
            substyle="fas"
            icon="money-bill-wave"
            class="main"
            :disabled="isDisabledButton"
            @click="paidManuallyHandler"
          >
            {{ $t('payments.info.paidManually') }}
          </ui-button>
          <ui-button
            lib="fa"
            substyle="fas"
            icon="times"
            color="red"
            class="add"
            @click="declineHandler"
          >
            {{ $t('payments.info.decline') }}
          </ui-button>
        </div>
        <div
          v-if="reviewActions && info.moderation_status === 'approved' && info.payout_status === 'not_paid'"
          class="btns"
        >
          <ui-button
            filled
            lib="fa"
            substyle="fas"
            icon="check"
            class="main"
            @click="reviewHandler"
          >
            {{ $t('payments.info.to_review') }}
          </ui-button>
          <ui-button
            lib="fa"
            substyle="fas"
            icon="times"
            color="red"
            class="add"
            @click="declineHandler"
          >
            {{ $t('payments.info.decline') }}
          </ui-button>
        </div>
        <div
          v-if="reviewActions && info.moderation_status === 'approved' && info.payout_status === 'for_rework'"
          class="btns"
        >
          <ui-button
            filled
            lib="fa"
            substyle="fas"
            icon="check"
            class="main"
            @click="reworkedHandler"
          >
            {{ $t('payments.info.reworked') }}
          </ui-button>
          <ui-button
            lib="fa"
            substyle="fas"
            icon="times"
            color="red"
            class="add"
            @click="declineHandler"
          >
            {{ $t('payments.info.decline') }}
          </ui-button>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="info-block">
        <div
          ref="contentHeight"
          :class="!hasPermission('affiliates_notes_view') ? 'full-width' : ''"
          class="info-block_content"
        >
          <div class="block-card">
            <div class="overview-wrapper">
              <div class="card overview">
                <div class="title">
                  <span>{{ $t(`mediaBuying.info.account_overview.title`) }}</span>
                </div>
                <div class="card-content">
                  <div class="field without-padding">
                    <div class="card new-card four-elems">
                      <div class="field">
                        <span class="label">{{ $t(`payments.info.available`) }}</span>
                        <div class="values available">
                          {{ balanceCurrencyLabel }}{{ info.available_balance | formatMoney }}
                        </div>
                      </div>
                      <div class="field">
                        <span class="label">{{ $t(`payments.info.in_hold`) }}</span>
                        <div class="values hold">
                          {{ balanceCurrencyLabel }}{{ info.hold_balance | formatMoney }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-title-wrapper">
                  <div class="title">
                    <span>{{ $t(`payments.info.payment_info`) }}</span>
                  </div>
                  <div class="card-title-statuses">
                    <div
                      class="status"
                      :class="info.moderation_status"
                    >
                      {{ $t(`payments.moderation.title`) }}
                      <span class="values">{{ info.moderation_status ? $t(`payments.moderation.${info.moderation_status}`) : '' }}</span>
                    </div>
                    <div
                      class="status"
                      :class="info.payout_status"
                    >
                      {{ $t(`payments.payout.title`) }}
                      <span class="values">{{ info.payout_status ? $t(`payments.payout.${info.payout_status}`) : '' }}</span>
                    </div>
                  </div>
                </div>
                <div class="card-content payment-info-block">
                  <div class="field">
                    <div class="requested">
                      {{ $t(`payments.info.created_at`) }} {{ info.created_at | formatDate3 }}
                    </div>
                    <span class="label big-num request-summ">
                      {{ infoCurrencyLabel }}{{ info.amount|formatMoney }}
                    </span>
                    <div class="to">
                      {{ $t(`payments.info.to`) }}
                      <router-link
                        v-if="hasAffiliateToPermission"
                        :to="`/affiliates/${info.affiliate_id}/info`"
                        class="email"
                      >
                        {{ info.affiliate_email }}
                      </router-link>
                      <span v-else> {{ info.affiliate_email }} </span>
                    </div>
                  </div>
                  <div class="field without-padding">
                    <div class="card new-card three-elems">
                      <div class="field">
                        <span class="label">{{ $t(`affiliates.affiliatesInfo.method`) }}</span>
                        <img
                          v-if="info.site_payment_method_logo_base64"
                          :src="info.site_payment_method_logo_base64"
                          :alt="info.site_payment_method_name"
                        >
                        <div
                          v-else
                          :class="{'not-specefied': !info.site_payment_method_name}"
                          class="values cut-text"
                        >
                          {{ info.site_payment_method_name ? info.site_payment_method_name : $t(`affiliates.affiliatesInfo.not_specefied`) }}
                        </div>
                      </div>
                      <div
                        ref="checkLong"
                        class="field"
                      >
                        <span class="label">{{ $t(`affiliates.affiliatesInfo.wallet`) }}</span>
                        <div class="ui-d-flex w-100">
                          <ui-icon
                            style="margin-right: 4px; cursor: pointer"
                            lib="fa"
                            substyle="fas"
                            :color="$theme.accentColor"
                            name="clone"
                            @click.native="copyWallet"
                          />
                          <el-tooltip
                            :content="info.payment_wallet"
                            :disabled="!info.payment_wallet"
                            popper-class="wlt"
                            placement="bottom"
                            effect="light"
                          >
                            <div
                              ref="wlt"
                              :class="{'not-specefied': !info.payment_wallet}"
                              style="cursor: pointer"
                              class="values cut-text"
                              @click="copyWallet"
                            >
                              {{ info.payment_wallet ? info.payment_wallet : $t(`affiliates.affiliatesInfo.not_specefied`) }}
                            </div>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="field">
                        <span class="label">{{ $t(`affiliates.affiliatesInfo.period`) }}</span>
                        <div class="values">
                          {{ formatDate(info.payment_period_start) }} - {{ formatDate(info.payment_period_end) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="info.additional_payout_information"
                  class="card add-information"
                >
                  <div class="title">
                    <span>{{ $t(`payments.info.add_information`) }}</span>
                  </div>
                  <div class="card-content">
                    <div class="field">
                      <span class="label description pre-line">
                        {{ info.additional_payout_information }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="info">
              <div class="card information">
                <div class="title">
                  <span>{{ $t(`payments.info.information`) }}</span>
                </div>
                <div class="card-content">
                  <div class="field">
                    <span
                      class="label nowrap"
                    >{{ $t('payments.info.introduced_by') }}</span>
                    <div
                      v-if="info.affiliate_referred_by_info && info.affiliate_referred_by_info.email"
                      class="values"
                    >
                      <router-link
                        v-if="$_.get(info, 'affiliate_referred_by_info.permissions.view', false)"
                        :to="`/affiliates/${info.affiliate_referred_by_info.id}/info`"
                        class="email"
                      >
                        {{ info.affiliate_referred_by_info.email }}
                      </router-link>
                      <div
                        v-else
                      >
                        {{ info.affiliate_referred_by_info.email }}
                      </div>
                    </div>
                    <div
                      v-else
                      class="values not-specefied"
                    >
                      {{ $t(`affiliates.affiliatesInfo.not_specefied`) }}
                    </div>
                  </div>
                  <div class="field">
                    <span
                      class="label"
                    >{{ $t('payments.info.approved_by') }}</span>
                    <div
                      :class="{'not-specefied': !info.approved_by_admin_name}"
                      class="values"
                    >
                      {{ info.approved_by_admin_name ? info.approved_by_admin_name : $t(`affiliates.affiliatesInfo.not_specefied`) }}
                    </div>
                  </div>
                  <div
                    v-if="info.team_name"
                    class="field"
                  >
                    <span
                      class="label"
                    >{{ $t('payments.info.team') }}</span>
                    <div class="values">
                      {{ info.team_name }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="fileAttachmentAllowed"
                class="card"
              >
                <div class="title">
                  <span>{{ $t(`mediaBuying.info.files_card.title`) }}</span>
                  <span
                    v-if="isPaymentsReview && info.moderation_status === 'on_review'"
                    class="action"
                    @click="openPopup"
                  >
                    <ui-icon
                      name="pen"
                      lib="fa"
                    />
                    {{ $t(`affiliates.affiliatesInfo.edit`) }}
                  </span>
                </div>
                <div class="card-content">
                  <div
                    v-for="(item, index) in info.files"
                    :key="index"
                    class="field media-field"
                  >
                    <div class="media-item">
                      <div class="media-item_wrapper">
                        <span>{{ item.filename }}</span>
                        <el-image
                          v-if="item.mime_type === 'image/jpeg' || item.mime_type === 'image/png'"
                          :src="item.preview_url"
                          :preview-src-list="srcList"
                          :fit="'contain'"
                        />
                      </div>
                      <el-dropdown
                        v-if="item.mime_type === 'image/jpeg' || item.mime_type === 'image/png'"
                        trigger="hover"
                        placement="top"
                      >
                        <div class="el-dropdown-link">
                          <ui-icon
                            lib="fa"
                            substyle="fas"
                            :color="$theme.accentColor"
                            name="eye"
                          />
                          <el-dropdown-menu class="img-preview">
                            <el-dropdown-item>
                              <img :src="item.preview_url">
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </div>
                      </el-dropdown>
                    </div>
                    <div class="values ">
                      <a
                        :href="item.download_url"
                        :download="item.filename"
                        target="_blank"
                        class="action"
                      >
                        <ui-icon
                          name="download"
                          :color="$theme.accentColor"
                          lib="fa"
                        />
                        {{ $t(`mediaBuying.info.files_card.download`) }}
                      </a>
                    </div>
                  </div>
                  <div
                    v-if="info.files && info.files.length === 0"
                    class="field"
                  >
                    <div class="field__empty-text">
                      <ui-icon
                        :size="32"
                        name="folder-open"
                        lib="fa"
                        substyle="fal"
                        color="#a6b2c4"
                      />
                      <div>{{ $t(`mediaBuying.info.files_card.no_files`) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="stats">
            <span class="title">{{ $t('payments.info.statistics') }}</span>
            <el-table
              v-if="!$_.isEmpty(info)"
              :data="info.affiliate_stats.data"
              :summary-method="getSummaryRow"
              show-summary
              border
              class="table"
            >
              <el-table-column
                :width="450"
                :label="$t('payments.info.offer')"
                prop="offer_name"
                align="left"
                header-align="left"
              />
              <el-table-column
                :width="96"
                :label="$t('payments.info.table_players')"
                prop="uniq_players_count"
                align="right"
                header-align="right"
              >
                <template slot-scope="scope">
                  <div
                    class="row-value"
                  >
                    <span>{{ scope.row.uniq_players_count }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :width="104"
                :label="`${$t('payments.info.rev_share')}, ${currencyLabel.trim()}`"
                prop="revshare_revenue"
                align="right"
                header-align="right"
              >
                <template slot-scope="scope">
                  <div
                    :class="getCurrencyClass(scope.row.revshare_revenue)"
                    class="row-value"
                  >
                    <span>{{ formatCurrency(scope.row.revshare_revenue) }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :width="96"
                :label="`${$t('payments.info.cpa')}, ${currencyLabel.trim()}`"
                prop="cpa_revenue"
                align="right"
                header-align="right"
              >
                <template slot-scope="scope">
                  <div
                    :class="getCurrencyClass(scope.row.cpa_revenue)"
                    class="row-value"
                  >
                    <span>{{ formatCurrency(scope.row.cpa_revenue) }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :width="96"
                :label="`${$t('payments.players.fields.total_profit.title')}, ${currencyLabel.trim()}`"
                prop="revenue"
                align="right"
                header-align="right"
              >
                <template slot-scope="scope">
                  <div
                    :class="getCurrencyClass(scope.row.revenue)"
                    class="row-value"
                  >
                    <span>{{ formatCurrency(scope.row.revenue) }}</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <Notes
          v-if="isLoadNotes && hasPermission('affiliates_notes_view')"
          :affiliate-id="info.affiliate_id"
          :add-note-permission="editAffiliatePermission && hasPermission('affiliates_notes_edit')"
          :max-height="notesHeight()"
        />
      </div>
    </div>
    <div
      v-loading="isPlayersLoading"
      class="players players-custom"
    >
      <div class="wrapper">
        <div class="header">
          <div class="tools">
            <span class="title">{{ $t('payments.info.players') }}</span>
            <div
              v-if="playerIds.length > 0"
              class="multicard"
            >
              <div class="multicard__select-count">
                {{ `${this.$tc('payments.info.players_selected', playerIds.length)}` }}
              </div>
              <div class="multicard__wr-btn">
                <div
                  class="multicard__btn"
                  @click="clearCheck"
                >
                  <ui-icon
                    :size="13"
                    lib="fa"
                    substyle="fas"
                    color="#3C3C3C"
                    class="close"
                    name="times"
                    @click.prevent
                  />
                  <span>{{ $t('payments.info.cancel') }}</span>
                </div>
                <div
                  class="multicard__btn"
                  @click="massChargeHandler"
                >
                  <ui-icon
                    :size="13"
                    lib="fa"
                    :color="$theme.dangerColor"
                    substyle="fas"
                    class="close"
                    name="money-bill"
                  />
                  <span>{{ $t('payments.info.charge') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="tools">
            <el-checkbox
              v-model="hide_stats_after_conversion"
              class="check"
              @change="getPlayers"
            >
              {{ $t('payments.info.hide_conversion') }}
            </el-checkbox>
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="pageChange"
            />
            <div class="select">
              <ui-button
                class="btn"
                lib="fa"
                substyle="fas"
                icon="eye"
                @click="openColumnsSelect"
              />
              <el-select
                id="select_group"
                ref="select_group"
                v-model="selectedFields"
                class="columns"
                popper-class="columns_popper"
                clearable
                multiple
                collapse-tags
                placeholder="Select columns"
                @change="handleSelectColumns"
              >
                <el-option
                  v-for="item in playersFields"
                  :key="item.name"
                  :label="`${$t(`payments.players.fields.${item.name}.title`)}
                  ${$te(`payments.players.fields.${item.name}.tip`) ? '' : ''}`"
                  :value="item.name"
                />
              </el-select>
            </div>
            <export-data
              :data="configExport"
              @exportData="exportPlayers"
            />
          </div>
        </div>
        <ui-table
          ref="tablePlayer"
          :fields="$_.filter(playersFields, field => selectedFields.indexOf(field.name) !== -1)"
          :data="playersStats"
          :currency="currencyCode"
          :total="playerSums"
          :rows-count="limit"
          :external-height="playersTableHeight"
          :sort="{
            prop: sortBy,
            order: sortDir,
          }"
          :checked-row="chargeActions && info.moderation_status === 'on_review'"
          :show-check-all="true"
          hidden-check-box="chargeback"
          checked-row-id="player_id"
          lazy-loading
          show-total
          is-static
          i18n-path="payments.players"
          class="max-h"
          element-loading-custom-class="data-loader-spinner"
          @checkedRows="checkedRows"
          @sort="handleSortPlayers"
        >
          <template slot="append">
            <el-table-column
              v-if="chargeActions && info.moderation_status === 'on_review'"
              width="60"
              align="center"
            >
              <template slot-scope="scope">
                <ActionIcon
                  :tooltip="$t('payments.info.charge')"
                >
                  <span
                    v-show="scope.row.chargebacks_count === 0"
                    class="action-ico"
                    @click="chargePlayer(scope.row)"
                  >
                    <ui-icon
                      name="money-bill"
                      :color="$theme.dangerColor"
                      lib="fa"
                    />
                  </span>
                </ActionIcon>
              </template>
            </el-table-column>
          </template>
        </ui-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import { NAV_SET_SUB_VIEW } from '@/store/action_types/nav';
import Notes from '@/components/Notes.vue';
import app from '@/main';
import detectPermissions from '../../service/detectPermissions';
import updateUrl from '../../service/updateUrl';
import ExportData from '@/components/ExportData';

import UploadFilesPopup from '@/components/Popups/UploadFilesPopup';


export default {
  name: 'PaymentInfo',
  components: {
    Notes,
    ExportData,
    UploadFilesPopup,
  },
  data() {
    return {
      isLoadNotes: false,
      isOpenPopup: false,
      playerIds: [],
      id: '',
      from: '',
      currencyCode: '',
      info: {},
      permissions: {},
      confirmData: {},
      isDataLoading: true,
      exportUrls: {},
      configExport: [
        {
          command: 'csv',
          label: 'CSV',
        },
        {
          command: 'xlsx',
          label: 'XLSX',
        },
        {
          command: 'csv_network',
          label: `CSV ( ${this.$t('payments.info.for_network')} )`,
        },
        {
          command: 'xlsx_network',
          label: `XLSX ( ${this.$t('payments.info.for_network')} )`,
        },
      ],
      backLink: {
        link: '',
        title: '',
      },
      playersStats: [],
      playerSums: {},
      playersFields: [
        {
          name: 'site_player_id',
          minWidth: 150,
          align: 'left',
          sortable: 'custom',
          link: this.getPlayerLink,
          action: () => {},
          actionLib: 'fa',
          controlType: 'icon',
          controlColorIco: () => this.$theme.dangerColor,
          popperClass: 'light-tooltip',
          actionName: row => (row.is_blocked ? 'lock' : ''),
          computedClass: (_, row) => (row.is_blocked ? 'right-action-icon' : ''),
          tooltip: 'players.info.blocked_by_security_service',
        },
        {
          name: 'external_id',
          minWidth: 110,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'registration_timestamp',
          minWidth: 110,
          align: 'left',
          sortable: 'custom',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD.MM.YYYY HH:mm',
          },
        },
        {
          name: 'site_registration_type_name',
          minWidth: 110,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'first_deposit_timestamp',
          minWidth: 140,
          align: 'left',
          sortable: 'custom',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD.MM.YYYY HH:mm',
          },
        },
        {
          name: 'conversion_timestamp',
          minWidth: 140,
          align: 'left',
          sortable: 'custom',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD.MM.YYYY HH:mm',
          },
        },
        {
          name: 'days_since_reg',
          minWidth: 130,
          align: 'right',
          sortable: 'custom',
        },
        {
          name: 'days_active',
          minWidth: 110,
          align: 'right',
          sortable: 'custom',
        },
        {
          name: 'player_country_name',
          minWidth: 100,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'latest_activity_timestamp',
          minWidth: 130,
          align: 'left',
          sortable: 'custom',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD.MM.YYYY HH:mm',
          },
        },
        {
          name: 'latest_deposit_timestamp',
          minWidth: 130,
          align: 'left',
          sortable: 'custom',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD.MM.YYYY HH:mm',
          },
        },
        {
          name: 'top_deposits_payment_system_name',
          minWidth: 170,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'top_withdrawals_payment_system_name',
          minWidth: 170,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'chargebacks_count',
          minWidth: 130,
          align: 'right',
          sortable: 'custom',
        },
        {
          name: 'chargebacks_sum',
          minWidth: 130,
          align: 'right',
          sortable: 'custom',
          format: '-formatMoney',
          class: 'negative',
        },
        {
          name: 'deposits_count',
          minWidth: 130,
          align: 'right',
          sortable: 'custom',
        },
        {
          name: 'deposits_sum',
          minWidth: 120,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'withdrawals_count',
          minWidth: 150,
          align: 'right',
          sortable: 'custom',
        },
        {
          name: 'withdrawals_sum',
          minWidth: 150,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'sport_bets_count',
          minWidth: 140,
          align: 'right',
          sortable: 'custom',
        },
        {
          name: 'sport_bets_sum',
          minWidth: 130,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'avg_sport_bets_odd',
          minWidth: 150,
          align: 'right',
          sortable: 'custom',
          format: 'odd',
        },
        {
          name: 'games_bets_count',
          minWidth: 150,
          align: 'right',
          sortable: 'custom',
        },
        {
          name: 'games_bets_sum',
          minWidth: 140,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'betting_profit',
          minWidth: 120,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'cpa_revenue',
          minWidth: 120,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'revshare_revenue',
          minWidth: 120,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'revenue',
          minWidth: 100,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'subid1',
          minWidth: 100,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'subid2',
          minWidth: 100,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'subid3',
          minWidth: 100,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'subid4',
          minWidth: 100,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'subid5',
          minWidth: 100,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'total_profit',
          minWidth: 100,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
          computedClass(value) {
            return value < 0 ? 'negative' : '';
          },
        },
      ],
      selectedFields: [],
      isPlayersLoading: false,
      page: 1,
      limit: 10,
      sortBy: 'site_player_id',
      sortDir: 'asc',
      count: 0,
      hide_stats_after_conversion: false,
      confirm: {
        title: '',
        msg: '',
        type: 'success',
        actionName: '',
      },
    };
  },
  computed: {
    fromPage() {
      return this.$route.query.from;
    },
    ...mapGetters({
      currentAcl: 'auth/currentAcl',
      adminAcl: 'auth/adminAcl',
      paymentMethods: 'misc/paymentMethods',
      currencies: 'misc/currencies',
    }),
    currencyLabel() {
      const currency = this.currencies.find((el => el.code === this.currencyCode));
      return currency?.symbol || `${this.currencyCode} `;
    },
    balanceCurrencyLabel() {
      if (!this.info?.balance_currency_code) return '';

      const currency = this.currencies.find((el => el.code === this.info.balance_currency_code));
      return currency?.symbol || `${this.info.balance_currency_code} `;
    },
    infoCurrencyLabel() {
      if (!this.info?.currency_code) return '';

      const currency = this.currencies.find((el => el.code === this.info.currency_code));
      return currency?.symbol || `${this.info.currency_code} `;
    },
    hasAffiliateToPermission() {
      return this.adminAcl.is_superuser || this.permissions.edit_affiliate;
    },
    isPaymentsReview() {
      return this.adminAcl.is_superuser || this.$store.getters['auth/currentAcl'].payments_review_actions === 'allow';
    },

    srcList() {
      return this.info.files.filter(img => img.preview_url !== null).map(img => img.preview_url) || [];
    },

    noteData: {
      get() {
        return this.confirmData ? this.confirmData : {};
      },
    },

    isDisabledButton() {
      const method = this.info.site_payment_method_id;
      const attachmentAllowed = this.paymentMethods && method ? this.checkPaymentMethodProperty('file_attachment_allowed') : false;
      const attachmentRequired = this.paymentMethods && method ? this.checkPaymentMethodProperty('file_attachment_required') : false;
      return (attachmentAllowed && attachmentRequired && !this.info.files.length) || method === null;
    },

    fileAttachmentAllowed() {
      return this.paymentMethods && this.info.site_payment_method_id ? this.checkPaymentMethodProperty('file_attachment_allowed') : false;
    },

    reviewActions() {
      return this.calcPermissions('review_actions');
    },
    chargeActions() {
      return this.calcPermissions('chargebacks_actions');
    },
    editAffiliatePermission() {
      return this.calcPermissions('edit_affiliate');
    },
    playersQuery() {
      return {
        limit: this.limit,
        offset: (this.page * this.limit) - this.limit,
        sort_column: this.sortBy,
        sort_dir: this.sortDir,
        hide_stats_after_conversion: this.hide_stats_after_conversion,
      };
    },
    playersTableHeight() {
      return this.count < this.limit ? (this.count * 32) + 100 : (this.limit * 32) + 450;
    },
  },
  created() {
    /*
    * берем hash
    * */
    if (detectPermissions.checkRequest('payments_view')) {
      const _paramsHash = updateUrl.getParseParams(this.$route.hash);

      const _defaultColumns = [
        'site_player_id',
        'registration_timestamp',
        'site_registration_type_name',
        'deposits_count',
        'deposits_sum',
        'withdrawals_sum',
        'betting_profit',
        'cpa_revenue',
        'revshare_revenue',
        'chargebacks_sum',
        'total_profit',
      ];

      if (!this.$_.isEmpty(this.$_.omit(_paramsHash, 'from'))) {
        this.selectedFields = Object.values(this.$_.get(_paramsHash, ['columns']) || _defaultColumns);
        this.sortBy = this.$_.get(_paramsHash, ['sort_column']) || this.sortBy;
        this.sortDir = this.$_.get(_paramsHash, ['sort_dir']) || this.sortDir;
        this.hide_stats_after_conversion = this.$_.get(_paramsHash, ['hide_stats_after_conversion']) === 'true';
        this.limit = Number(this.$_.get(_paramsHash, ['limit'])) || this.limit;
        this.page = Number(this.$_.get(_paramsHash, ['page'])) || 1;
      } else {
        this.selectedFields = JSON.parse(this.$ls.get('paymentPlayersColumns')) || _defaultColumns;
      }
    }
    detectPermissions.checkRoute('payments_view');
  },

  beforeDestroy() {
    app.$eventBus.$off('delete-note');
    this.$eventBus.$off('changeLocaleGlobal', this.changeLocaleGlobal);
  },

  mounted() {
    if (detectPermissions.checkRequest('payments_view')) {
      app.$eventBus.$on('delete-note', note => this.deleteNote(note));
      this.id = this.$route.params.id;
      if (!this.id) {
        this.$router.push('/payments');
      }
      this.getPaymentInfo();
      this.$eventBus.$on('changeLocaleGlobal', this.changeLocaleGlobal);
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name) {
        vm.backLink.link = from.fullPath;
        switch (from.meta.view) {
        case 'history':
          switch (from.params.status) {
          case 'paid':
            vm.backLink.title = vm.$t('payments.route.history');
            break;
          case 'not-paid':
            vm.backLink.title = vm.$t('payments.route.not_paid');
            break;
          default:
            vm.backLink.title = vm.$t('payments.route.payments');
            vm.backLink.link = '/payments';
            break;
          }
          break;
        case 'review':
          vm.backLink.title = vm.$t('payments.route.review');
          break;
        default:
          vm.backLink.title = vm.$t('payments.route.payments');
          vm.backLink.link = '/payments';
          break;
        }
      } else if (from.matched[0] && from.matched[0].meta.view === 'affiliates') {
        vm.backLink.title = vm.$t('payments.route.affiliate');
        vm.backLink.link = from.fullPath;
      }
    });
  },

  methods: {
    async copyWallet() {
      try {
        await this.$copyText(
          this.info.payment_wallet
            ? this.info.payment_wallet
            : $t('affiliates.affiliatesInfo.not_specefied'),
        );
        this.$noty.info(`${this.$t('reports.other.copied_to_clipboard')}`);
      } catch (error) {
        this.$noty.error(`${this.$t('reports.other.failed_to_copy')}`);
      }
    },
    changeLocaleGlobal() {
      this.getPlayers('silent');
    },

    checkPaymentMethodProperty(property) {
      if (!this.paymentMethods.length) return false;
      const pmProperty = this.$_.find(this.paymentMethods, item => item.id === this.info.site_payment_method_id);
      return pmProperty ? pmProperty[property] : false;
    },
    openPopup() {
      this.isOpenPopup = true;
    },
    closePopup() {
      this.isOpenPopup = false;
    },

    hasPermission(permission) {
      return this.currentAcl[permission] === 'allow' || this.adminAcl.is_superuser;
    },
    calcPermissions(item) {
      return this.permissions[item];
    },
    notesHeight() {
      return this.$refs.contentHeight ? `${this.$refs.contentHeight.clientHeight}px` : '';
    },
    checkedRows(id, ids) {
      this.playerIds = ids;
    },
    openConfirm() {
      this.$refs.confirm.open();
    },

    getPaymentInfo() {
      this.$api.getPaymentInfo(this.id)
        .then((response) => {
          this.info = this.$_.cloneDeepWith(response.data.payload);
          this.permissions = this.$_.cloneDeepWith(response.data.misc.permissions);

          if (this.info.moderation_status === 'on_review') {
            this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'review');
            if (!this.backLink.title && !this.backLink.link) {
              this.backLink.title = this.$t('payments.route.review');
              this.backLink.link = '/payments/review';
            }
          } else if (this.info.moderation_status !== 'on_review' && this.info.payout_status === 'not_paid') {
            this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'history');
            if (!this.backLink.title && !this.backLink.link) {
              this.backLink.title = this.$t('payments.route.not_paid');
              this.backLink.link = '/payments/history/not-paid';
            }
          } else {
            this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'dashboard');
            if (!this.backLink.title && !this.backLink.link) {
              this.backLink.title = this.$t('payments.route.history');
              this.backLink.link = `/payments/history/paid?date=${this.$moment(this.info.paid_at).format('YYYY-MM-DD')}`;
            }
          }

          this.isLoadNotes = true;
          this.getPlayers();
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    getSummaryRow() {
      const sums = [
        this.$t('payments.players.fields.total_profit.title'),
        this.info.affiliate_stats.totals.uniq_players_count,
        this.formatCurrency(this.info.affiliate_stats.totals.revshare_revenue),
        this.formatCurrency(this.info.affiliate_stats.totals.cpa_revenue),
        this.formatCurrency(this.info.affiliate_stats.totals.revenue),
      ];
      return sums;
    },
    formatCurrency(value) {
      return `${this.currencyLabel}${value < 0 ? '-' : ''}${numeral(Math.abs(value)).format('0,0.00')}`;
    },
    formatDate(value, options) {
      if (value) {
        if (options) {
          const m = value._isAMomentObject ? value : this.$moment(value, options.in).locale(this.$i18n.locale);
          return m.isValid() ? m.format(options.out) : value;
        }
        return this.$moment(value).locale(this.$i18n.locale).format('DD MMM YYYY');
      }
      return value;
    },
    formatActivity(value) {
      return this.$moment(value).locale(this.$i18n.locale).format('DD MMM YYYY HH:mm');
    },
    getCurrencyClass(value) {
      return value < 0 ? 'negative' : '';
    },
    actionPayment(action) {
      switch (action) {
      case 'Approve':
        this.approvePayment();
        break;
      case 'PaidManually':
        this.paidManually();
        break;
      case 'Decline':
        this.declinePayment();
        break;
      case 'Review':
        this.reviewPayment();
        break;
      case 'Reworked':
        this.reworkedPayment();
        break;
      case 'Charge':
        this.massCharge();
        break;
      case 'deleteNote':
        this.handleDeleteNote(this.noteData);
        this.$refs.confirm.close();
        break;
      default:
        break;
      }
    },
    handleDeleteNote(note) {
      app.$eventBus.$emit('delete-note-component', note);
    },
    deleteNote(note) {
      this.confirm.action = 'deleteNote';
      this.confirm.title = `${this.$t('affiliates.confirm.delete_note_title')}`;
      this.confirm.msg = `${this.$t('affiliates.confirm.delete_note_text')}`;
      this.confirm.actionName = `${this.$t('affiliates.confirm.delete_note_action')}`;
      this.confirm.type = 'warning';
      this.actionFaIcon = 'trash-alt';
      this.confirmData = note;
      this.$refs.confirm.open();
    },
    clearCheck() {
      this.$refs.tablePlayer.clearCheck();
      this.$refs.tablePlayer.changeRow('alldel');
    },
    massCharge() {
      this.isDataLoading = true;
      this.$api.massChargePaymentCpaRevenue(this.id, this.playerIds)
        .then(() => {
          this.getPaymentInfo();
          this.$refs.confirm.close();
          this.clearCheck();
        })
        .catch(() => {
          this.isDataLoading = false;
          this.$refs.confirm.close();
        });
    },
    approvePayment() {
      this.isDataLoading = true;
      this.$api.approvePayment(this.id)
        .then(() => {
          this.getPaymentInfo();
          this.$refs.confirm.close();
        })
        .catch((error) => {
          this.isDataLoading = false;
          this.$noty.error(`${this.$t('payments.noty.req_fail', { statusCode: error.status })}`);
          this.$refs.confirm.close();
        });
    },
    paidManually() {
      this.isDataLoading = true;
      this.$api.manualPayout(this.id)
        .then(() => {
          this.getPaymentInfo();
          this.$refs.confirm.close();
        })
        .catch((error) => {
          this.isDataLoading = false;
          this.$noty.error(`${this.$t('payments.noty.req_fail', { statusCode: error.status })}`);
          this.$refs.confirm.close();
        });
    },
    declinePayment() {
      this.isDataLoading = true;
      this.$api.declinePayment(this.id)
        .then(() => {
          this.getPaymentInfo();
          this.$refs.confirm.close();
        })
        .catch((error) => {
          this.isDataLoading = false;
          this.$noty.error(`${this.$t('payments.noty.req_fail', { statusCode: error.status })}`);
          this.$refs.confirm.close();
        });
    },
    reviewPayment() {
      this.isDataLoading = true;
      this.$api.reviewPayment(this.id)
        .then(() => {
          this.getPaymentInfo();
          this.$refs.confirm.close();
        })
        .catch((error) => {
          this.isDataLoading = false;
          this.$noty.error(`${this.$t('payments.noty.req_fail', { statusCode: error.status })}`);
          this.$refs.confirm.close();
        });
    },
    reworkedPayment() {
      this.isDataLoading = true;
      this.$api.reworkedPayment(this.id)
        .then(() => {
          this.getPaymentInfo();
          this.$refs.confirm.close();
        })
        .catch((error) => {
          this.isDataLoading = false;
          this.$noty.error(`${this.$t('payments.noty.req_fail', { statusCode: error.status })}`);
          this.$refs.confirm.close();
        });
    },
    approveHandler() {
      this.confirm.title = `${this.$t('payments.confirm.title_1')}`;
      this.confirm.msg = `${this.$t('payments.confirm.msg_1', { affiliateEmail: this.info.affiliate_email })}`;
      this.confirm.type = 'success';
      this.confirm.action = 'Approve';
      this.confirm.actionName = `${this.$t('payments.confirm.action_1')}`;
      this.openConfirm();
    },
    paidManuallyHandler() {
      this.confirm.title = this.$t('payments.confirm.title_6');
      this.confirm.msg = this.$t('payments.confirm.msg_6', { affiliateEmail: this.info.affiliate_email });
      this.confirm.type = 'success';
      this.confirm.action = 'PaidManually';
      this.confirm.actionName = this.$t('payments.confirm.action_6');
      this.openConfirm();
    },
    declineHandler() {
      this.confirm.title = `${this.$t('payments.confirm.title_2')}`;
      this.confirm.msg = `${this.$t('payments.confirm.msg_2', { affiliateEmail: this.info.affiliate_email })}`;
      this.confirm.type = 'warning';
      this.confirm.action = 'Decline';
      this.confirm.actionName = `${this.$t('payments.confirm.action_2')}`;
      this.openConfirm();
    },
    reviewHandler() {
      this.confirm.title = `${this.$t('payments.confirm.title_3')}`;
      this.confirm.msg = `${this.$t('payments.confirm.msg_3')}`;
      this.confirm.type = 'success';
      this.confirm.action = 'Review';
      this.confirm.actionName = `${this.$t('payments.confirm.action_3')}`;
      this.openConfirm();
    },
    reworkedHandler() {
      this.confirm.title = `${this.$t('payments.confirm.title_7')}`;
      this.confirm.msg = `${this.$t('payments.confirm.msg_7')}`;
      this.confirm.type = 'success';
      this.confirm.action = 'Reworked';
      this.confirm.actionName = `${this.$t('payments.confirm.action_7')}`;
      this.openConfirm();
    },
    massChargeHandler() {
      this.confirm.title = `${this.$t('payments.confirm.title_5')}`;
      this.confirm.msg = `${this.$t('payments.confirm.msg_5', { id: this.playerIds.length })}`;
      this.confirm.type = 'success';
      this.confirm.action = 'Charge';
      this.confirm.actionName = `${this.$t('payments.confirm.action_5')}`;
      this.openConfirm();
    },

    getPlayers(action) {
      if (action !== 'silent') {
        this.isPlayersLoading = true;
      }
      /*
      * URL Hash
      * */
      this._completedFilterUrl();

      this.$api.getPaymentInfoPlayers(this.id, this.playersQuery)
        .then((resp) => {
          this.count = parseInt(resp.data.misc.count, 10);
          this.exportUrls = resp.data.misc.export_urls;
          this.currencyCode = resp.data.misc.currency_code;
          this.playersStats = resp.data.payload.data;
          this.playerSums = resp.data.payload.totals;
          this.playerSums.registration_timestamp = '';
          this.playerSums.latest_activity_timestamp = '';
          this.playerSums.player_country_name = '';
          this.playerSums.top_deposits_payment_system = '';
          this.playerSums.top_withdrawals_payment_system = '';
        })
        .finally(() => {
          if (action !== 'silent') {
            this.isPlayersLoading = false;
          }
        });
    },
    chargePlayer(player) {
      this.$api.chargePaymentCpaRevenue(this.id, player.player_id)
        .then(() => {
          this.$noty.info(this.$t('payments.noty.charge_soon'));
          this.clearCheck();
          this.getPaymentInfo();
        });
    },
    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.getPlayers();
      }
    },
    openColumnsSelect() {
      this.$refs.select_group.toggleMenu();
    },
    handleSelectColumns() {
      this.$ls.set('paymentPlayersColumns', JSON.stringify(this.selectedFields));
    },
    handleSortPlayers({ prop, order }) {
      if (this.sortBy !== prop) {
        this.sortBy = prop;
      } else {
        this.sortDir = order;
      }
      this.getPlayers();
    },
    exportPlayers(format) {
      window.open(this.exportUrls[format]);
    },
    getPlayerLink(row) {
      const isUser = !this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl'].players_view === 'allow';
      const isSuperUser = this.$store.getters['auth/adminAcl'].is_superuser;
      return isSuperUser || isUser ? `/players/${row.player_id}/info` : false;
    },
    /*
 * Создаем filter URL
 * */
    _completedFilterUrl() {
      const _dataFilters = {
        limit: this.limit,
        page: this.page,
        sort_column: this.sortBy,
        sort_dir: this.sortDir,
        hide_stats_after_conversion: this.hide_stats_after_conversion,
        columns: this.selectedFields,
        from: this.fromPage,
      };
      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-info{
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  padding-bottom: 44px;

  .w-100{
    width: 100%;
  }

  .wrapper{
    margin: 0 auto;
    min-width: 1140px;
    width: 90%;
  }
  .head{
    padding: 24px 0 0px;
    .toolbar{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title{
        font-size: 24px;
      }
      .btns{
        display: flex;
        align-items: center;
        .main{
          width: 160px;
        }
        .add{
          margin-right: 16px;
        }
      }
    }
  }
  .stats{
    // padding: 16px 0 0px;
    .wrapper{
      align-items: flex-start;
    }
    .title{
      font-size: 24px;
      margin-bottom: 20px;
    }
    .table{
      margin: 0;
      width: auto;
    }
  }
  .players{
    padding-top: 16px;
    .wrapper{
      .header{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 20px;
        .title{
          font-size: 24px;
        }
        .tools{
          display: flex;
          .check{
            align-self: center;
            margin-right: 12px;
          }
          .select {
            position: relative;
            margin-left: 16px;
            .columns {
              position: absolute;
              top: 0px;
              left: -60px;
              opacity: 0;
              visibility: hidden;
            }
          }
        }
      }
      .table{
        margin: 0;
        width: auto;
      }
    }
  }
}
</style>

<style lang="scss">
  .columns_popper {
  .popper__arrow {
    left: 70px !important;
  }
}

.select.columns {
  &.el-select {
    .el-select__tags {
      & > span {
        display: flex;
        width: 100%;
        overflow: hidden;
        .el-tag:not(:last-child) {
          display: flex;
          align-items: center;
          .el-select__tags-text {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
  .payment-info{
    .export{
      margin-left: 12px;
    }
    .wrapper{
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 16px;

    }
    .players .wrapper{
      border-bottom: none;
    }
    .players-custom .header {
      .tools{
         min-height: 36px;
        display: flex;
        align-items: center;
      }

    }
    .action-btn{
      padding-top: 12px;
      .wrapper{
        padding-bottom: 12px;
        .ui-button + .ui-button{
          margin-left: 8px;
        }
      }
    }
    .max-h{
      .el-table{
        max-height: inherit !important;
        height: inherit !important;
        &__body-wrapper{
          height: inherit !important;
        }
      }
    }
  }
</style>
