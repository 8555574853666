import InfiniteScroll from 'vue-infinite-scroll';
import VInfiniteScroll from 'v-infinite-scroll';
import Noty from 'vuejs-noty';
import 'vuejs-noty/dist/vuejs-noty.css';
import VueProgressBar from 'vue-progressbar';
import VueClipboard from 'vue-clipboard2';
import FlagIcon from 'vue-flag-icon';
import Element from 'element-ui';
import * as UiElements from '@affctrl-front/ui-elements';
import Storage from 'vue-ls';
import '@/assets/styles/element-theme.scss';
import Vuelidate from 'vuelidate';
import VueCookies from 'vue-cookies';

import VueMarkdownEditor from '@kangc/v-md-editor';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
// import Prism from 'prismjs';
// // highlight code
// import 'prismjs/components/prism-json';

import {
  storagePrefix,
  ignoreLocalStorageItem,
} from '@/service/configLocalStorage';
import { config } from '../config';

/* Удаляем все ключи со старым префиксами */
const lsDeleteOldPrefix = function lsDeleteOldPrefix() {
  // eslint-disable-next-line
  for (const key in window.localStorage) {
    if (
      localStorage.hasOwnProperty(key)
      && !key.startsWith(storagePrefix)
      && !ignoreLocalStorageItem.includes(key)
    ) {
      localStorage.removeItem(key);
    }
  }
};

export default (App) => {
  const progressBarConfig = {
    color: config.activeColor,
    failedColor: config.dangerColor,
    thickness: '4px',
    autoRevert: true,
    inverse: false,
    transition: {
      speed: '0.2s',
      opacity: '0.6s',
      termination: 600,
    },
  };

  const notyConfig = {
    timeout: 1500,
    progressBar: true,
    killer: true,
    visibilityControl: false,
    maxVisible: 3,
    callbacks: {
      onShow() {},
      onClose() {},
    },
  };

  const storageOptions = {
    namespace: storagePrefix,
    name: 'ls',
    storage: 'local',
  };

  const uiElementsOptions = {
    prefix: 'Adm', // Optional
    theme: config.uiTheme,
  };

  lsDeleteOldPrefix();
  App.use(VueCookies);
  App.use(Vuelidate);
  App.use(InfiniteScroll);
  App.use(VInfiniteScroll);
  App.use(VueClipboard);
  App.use(VueProgressBar, progressBarConfig);
  App.use(Noty, notyConfig);
  App.use(FlagIcon);
  App.use(FlagIcon);
  App.use(Storage, storageOptions);
  App.use(UiElements, uiElementsOptions);
  App.use(Element);
  App.use(VueMarkdownEditor);
  VueMarkdownEditor.use(vuepressTheme, {
    // Prism,
  });
  // VueMarkdownEditor.lang.use('en-US', enUS);
  // VueMarkdownEditor.lang.use('ru-RU', ruRU);
};
