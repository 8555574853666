export default {
  getPlayers(params) {
    return this.affiliatesApi.get('/players', { params });
  },
  getPlayersCount(params) {
    return this.affiliatesApi.get('/players/count', { params });
  },
  changePlayersStatus(id, virtual) {
    return this.affiliatesApi.put(`/players/${id}`, {
      is_virtual: virtual,
    });
  },
  getPlayerInfo(id) {
    return this.affiliatesApi.get(`/players/${id}`);
  },
  getPlayerLogs(id, params) {
    return this.affiliatesApi.get(`/players/${id}/crm-logs`, { params });
  },
  getPlayerStats(id, params) {
    return this.affiliatesApi.get(`/players/${id}/stats`, { params });
  },
  attachPlayerAffiliate(id, params) {
    return this.affiliatesApi.post(`/players/${id}/attach`, params);
  },
  detachPlayerAffiliate(id) {
    return this.affiliatesApi.post(`/players/${id}/detach`);
  },
  markAsVirtual(id) {
    return this.affiliatesApi.post(`/players/${id}/mark-as-virtual`);
  },
};
