var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isOpen)?_c('div',{staticClass:"crm-activity-metrics-popup"},[_c('div',{staticClass:"popup"},[_c('div',{staticClass:"head"},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t('crm.activities.actions.action_popup.add_metrics_title'))+" ")]),_c('ui-icon',{staticClass:"close",attrs:{"size":20,"lib":"fa","substyle":"fas","name":"times"},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}})],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"body"},[_c('div',{staticClass:"columns-popup__wr ui-fg-1",class:{ 'is-show': false }},[_c('div',{staticClass:"crm-activity-metrics-popup__content columns-popup__left ui-fg-1",class:{
            'crm-activity-metrics-popup__content_no-data': _vm.noData,
          }},[_c('div',{staticClass:"controls ui-m-lg-b"},[_c('ui-input',{staticClass:"search-input",attrs:{"placeholder":_vm.$t('reports.colunms_settings.placeholder_search'),"width":"100%","pre-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('el-collapse',{staticStyle:{"overflow":"visible"},model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},[(_vm.noData)?_c('div',{staticClass:"field__empty-text"},[_c('ui-icon',{attrs:{"size":32,"name":"folder-open","lib":"fa","substyle":"fal","color":"#a6b2c4"}}),_c('div',[_vm._v(_vm._s(_vm.$t('reports.colunms_settings.no_data')))])],1):_vm._e(),_c('div',{staticClass:"wrap-item el-collapse-item"},_vm._l((_vm.columnGroup[0].items),function(metric,index){return _c('div',{key:metric.key,class:{
                  'hide-search': _vm.isHiddenEntityIndex[metric.key],
                }},[_c('div',{staticClass:"item",class:{
                    'item_last': index === _vm.columnGroup[0].items.length - 1
                  }},[_c('div',{staticClass:"checkbox checkbox-custom",class:{'checked': _vm.selectedMetricsIndex[metric.name] },on:{"click":function($event){return _vm.handleSelectMetric(metric.name)}}},[_c('div',{staticClass:"box"},[_c('ui-icon',{staticClass:"chk-icon",attrs:{"name":'check',"size":8,"lib":"fa","substyle":"fas"}})],1),_c('span',{staticClass:"label"},[_vm._v(_vm._s(metric.title))])]),_c('span',{staticClass:"description"},[_vm._v(" "+_vm._s(metric.description)+" ")])])])}),0)])],1)])]),_c('div',{staticClass:"foot"},[_c('ui-button',{attrs:{"color":"red","lib":"fa","substyle":"fas","icon":"times","disabled":_vm.isLoading},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t('crm.buttons.cancel'))+" ")]),_c('ui-button',{attrs:{"color":_vm.$theme.mainColor,"filled":""},on:{"click":function($event){return _vm.handleSave()}}},[_c('i',{staticClass:"fas fa-check plus-icon-custom"}),_vm._v(_vm._s(_vm.$t('crm.buttons.save_metrics'))+" ")])],1)]),_c('div',{staticClass:"wrapper"})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }