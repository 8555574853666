<template>
  <item-card
    v-loading="loading"
    class="crm-activity"
    :class="{
      'crm-activity--no-toolbar': actionsHeader.length === 0
    }"
    :breadcrumbs="breadcrumbs"
    :title="activityName"
  >
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirm.type"
      :action-name="confirm.actionName"
      :action="confirm.action"
      action-icon="check"
      @save="confirm.confirmAction"
    >
      <div
        slot="title"
        v-html="confirm.title"
      />
      <div class="body__wrap">
        <i
          :class="confirm.type"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirm.msg"
        />
      </div>
    </ui-confirm>

    <div
      slot="toolbar"
      class="ui-g-w-md"
    >
      <div
        v-for="action in actionsHeader"
        :key="action.key"
        class="ui-g-md"
      >
        <ui-button
          class="action-btn"
          :icon="action.icon"
          lib="fa"
          substyle="fas"
          :color="action.color"
          :filled="action.filled"
          :disabled="action.disabled && action.disabled()"
          @click="action.action()"
        >
          {{ action.text }}
        </ui-button>
      </div>
      <!-- <div class="ui-fg-1" />
      <el-radio-group
        v-model="state"
        :class="[
          'crm-activity__date-switch',
          'ui-radio-group',
          'ui-g-md',
          'ui-m-md-r'
        ]"
      >
        <el-radio-button label="edit">
          edit
        </el-radio-button>
        <el-radio-button label="approved">
          approved
        </el-radio-button>
        <el-radio-button label="rework">
          rework
        </el-radio-button>
        <el-radio-button label="check">
          check
        </el-radio-button>
        <el-radio-button label="rework-edit">
          rework-edit
        </el-radio-button>
        <el-radio-button label="preview">
          preview
        </el-radio-button>
        <el-radio-button label="preview-edit">
          preview-edit
        </el-radio-button>
      </el-radio-group> -->
    </div>
    <item-card-layout-column>
      <div class="card">
        <div class="title">
          <span>{{ $t('crm.activities.info.info') }}</span>
        </div>
        <div class="card-content">
          <div class="field">
            <span class="label word-break pre-line">
              {{ $t('crm.activities.info.status') }}
            </span>
            <div
              :class="[
                'values',
                `row-value--${status}`,
              ]"
            >
              {{ $t(`crm.fields.computed_status.values.${status}`) }}
            </div>
          </div>
          <div class="field">
            <span class="label word-break pre-line">
              {{ $t('crm.activities.info.created_at') }}
            </span>
            <div class="values">
              {{ activity.created_at | formatDate3 }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="activity.moderation_status === 'approved' || activity.status === 'archived'"
        class="card"
      >
        <div class="title">
          <span>{{ $t('crm.activities.info.period') }}</span>
        </div>
        <div class="card-content">
          <div class="field">
            <div class="values">
              {{ period }}
            </div>
          </div>
        </div>
      </div>
    </item-card-layout-column>
    <item-card-layout-column type="grow">
      <activity-metrics
        v-if="state === 'approved' || state === 'preview'"
        :can-edit="canEdit && !['archived', 'completed', 'declined'].includes(status)"
        :activity="activity"
        :control-group-active="controlGroupActive"
        preview-mode
        :metric-values="metricValues"
        :errors="errors"
        :is-edit-metrics-date="isEditMetricsDate"
        @change-activity-range="onChangeActivityRange"
      />
      <panel
        v-if="(activity.moderation_status !== 'approved' && activity.status !== 'archived') || state === 'pause-edit'"
        class="ui-m-xl-b"
        :error="!!(nameError || periodError || generalErrors.length)"
      >
        <panel-header-title class="ui-m-xl-b">
          {{ $t('crm.activities.info.general') }}
        </panel-header-title>
        <div
          v-if="state === 'edit' || state === 'rework-edit' || state === 'pause-edit'"
          class="ui-d-flex"
        >
          <ui-input
            v-model="activity.name"
            class="form-input ui-m-xl-r"
            :label="$t('crm.activities.info.name')"
            autosize
            size="big"
            :placeholder="$t('crm.activities.info.name_placeholder')"
            type="text"
            :error="nameError"
            :disabled="state === 'pause-edit'"
          />
          <div>
            <div
              class="crm-activity__date-block"
              :class="{
                'crm-activity__date-block_has-error': periodError,
              }"
            >
              <div>
                <span class="crm-activity__label">
                  {{ $t('crm.activities.info.start_date') }}
                </span>
                <div class="ui-d-flex">
                  <el-date-picker
                    ref="datepicker"
                    v-model="from"
                    :picker-options="{
                      firstDayOfWeek: 1,
                    }"
                    :placeholder="$t(`team_popup.see_stats_placeholder`)"
                    format="dd MMM yyyy HH:mm"
                    type="datetime"
                    class="dtpicker"
                    @change="handlePeriodChange('from', $event)"
                  />
                </div>
              </div>
              <div class="crm-activity__spacer">
                –
              </div>
              <div>
                <span class="crm-activity__label">
                  {{ $t('crm.activities.info.end_date') }}
                </span>
                <el-date-picker
                  ref="datepicker"
                  v-model="to"
                  :picker-options="{
                    firstDayOfWeek: 1,
                  }"
                  :placeholder="$t(`team_popup.see_stats_placeholder`)"
                  format="dd MMM yyyy HH:mm"
                  type="datetime"
                  class="dtpicker"
                  popper-class="crm-activity__endtime-popover"
                  @change="handlePeriodChange('to', $event)"
                />
              </div>
            </div>
            <div
              v-if="periodError"
              class="crm-activity__input-error"
            >
              {{ periodError }}
            </div>
          </div>
        </div>
        <panel
          v-else
          type="contrast"
        >
          <activity-check-control
            v-if="state === 'rework' || (state === 'check' && canReview)"
            slot="prepend"
            v-model="reworkState.is_approved_general"
            :can-edit="state === 'check'"
          />
          <div class="ui-d-flex ui-g-w-md">
            <ui-icon
              class="ui-g-md"
              size="14px"
              lib="fa"
              substyle="far"
              name="calendar-alt"
            />
            <div class="ui-g-md crm-activity__panel-text">
              {{ period }}
            </div>
          </div>
        </panel>
        <div
          v-if="generalErrors.length > 0"
          class="ui-m-lg-t"
        >
          <div class="ui-d-flex ui-fd-column ui-ai-start ui-g-w-md">
            <crm-error-panel
              v-for="(error, index) in generalErrors"
              :key="index"
              class="ui-g-md"
            >
              {{ error }}
            </crm-error-panel>
          </div>
        </div>
      </panel>

      <activity-segment
        ref="activitySegment"
        class="ui-m-xl-b"
        :state="state"
        :activity="activity"
        :old-activity="oldActivity"
        :loading="loading"
        :segments="segments"
        :view-segment="isSuperUser || permissions.can_view_segment"
        :can-review="canReview"
        :is-create="isCreate"
        :current-brand="currentBrand"
        :rework-state="reworkState"
        :errors="errors"
      />

      <activity-actions
        ref="activityActions"
        class="ui-m-xl-b"
        :state="state"
        :activity="activity"
        :templates="templates"
        :loading="loading"
        :control-group-active.sync="controlGroupActive"
        :control-group-percent.sync="controlGroupPercent"
        :control-group-players.sync="controlGroupPlayers"
        :rework-state="reworkState"
        :current-brand="currentBrand"
        :can-review="canReview"
        :errors="errors"
        :activity-range="activityRange"
        @updateShadowedGroups="shadowedActionGroups = $event"
      />

      <activity-metrics
        v-if="['edit', 'rework-edit', 'preview-edit', 'check', 'rework'].includes(state)"
        :activity="activity"
        :control-group-active="controlGroupActive"
        :metric-values="metricValues"
        :can-edit="['edit', 'rework-edit'].includes(state)"
        :preview-mode="['preview-edit', 'check', 'rework'].includes(state)"
        :errors="errors"
      />

      <activity-comments
        v-if="!isCreate"
        :is-edit="canEdit"
        :activity="activity"
        :loading="loading"
        :state="state"
        :error="needComment"
      />

      <panel
        v-if="(state === 'edit' || state === 'check' || state === 'rework-edit') && actionsFooter.length > 0"
        class="crm-activity__bottom-controls-panel"
      >
        <div class="ui-d-flex ui-g-w-md">
          <div
            v-for="action in actionsFooter"
            :key="action.key"
            class="ui-g-md"
          >
            <ui-button
              :icon="action.icon"
              lib="fa"
              substyle="fas"
              :color="action.color"
              :filled="action.filled"
              class="action-btn"
              size="big"
              :disabled="action.disabled && action.disabled()"
              @click="action.action"
            >
              {{ action.text }}
            </ui-button>
          </div>
        </div>
      </panel>
    </item-card-layout-column>
  </item-card>
</template>

<script>
import hash from 'object-hash';
import * as Panel from '@/views/CRM/components/Panel';
import * as Tree from '@/views/CRM/components/Tree';
import ItemCard from '@/components/ItemCard.vue';
import ItemCardLayoutColumn from '@/components/ItemCard/ItemCardLayoutColumn.vue';

import ActivitiesCommonMixin from '@/views/CRM/mixins/activities-common-mixin';
import SegmentStatsMixin from '@/views/CRM/mixins/segment-stats-mixin.js';
import ActivityCheckControl from '@/views/CRM/Activities/components/ActivityCheckControl.vue';
import ActivityMetrics from '@/views/CRM/Activities/components/ActivityMetrics.vue';
import ActivityActions from '@/views/CRM/Activities/components/ActivityActions.vue';
import ActivitySegment from '@/views/CRM/Activities/components/ActivitySegment.vue';
import ActivityComments from '@/views/CRM/Activities/components/ActivityComments.vue';
import CrmErrorPanel from '@/views/CRM/components/CrmErrorPanel.vue';

export default {
  components: {
    ItemCard,
    ItemCardLayoutColumn,
    ActivityMetrics,
    ActivityActions,
    ActivitySegment,
    ActivityCheckControl,
    ActivityComments,
    CrmErrorPanel,
    ...Panel,
    ...Tree,
  },
  mixins: [
    ActivitiesCommonMixin,
    SegmentStatsMixin,
  ],
  props: {
    currentBrand: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: null,
      loading: false,
      state: 'none', // edit | approved | rework | check | rework-edit | preview
      controlGroupActive: true,
      oldControlGroupActive: true,
      controlGroupPercent: 100,
      controlGroupPlayers: 0,
      oldActivity: {},
      activityHash: '',
      oldActivityHash: '',
      segmentType: 'global',
      segments: [],
      templates: [],
      segment: {},
      shadowedActionGroups: [],
      reworkState: {},
      confirm: this.getDefaultConfirm(),
      activity: this.getDefaultActivity(),
      permissions: {},
      errors: {},
      needComment: '',
      metricValues: [],
      now: this.$moment(new Date()),
      activityRange: null,
      isEditMetricsDate: false,
    };
  },
  computed: {
    activityParams() {
      if (this.activityRange) {
        return this.activityRange;
      }
      return null;
    },
    isSuperUser() {
      return this.$store.getters['auth/adminAcl'].is_superuser;
    },
    canEdit() {
      return this.isSuperUser || this.permissions.can_edit;
    },
    canReview() {
      return this.isSuperUser || this.permissions.can_review;
    },
    canRun() {
      return this.isSuperUser || this.permissions.can_run;
    },
    backPage() {
      if (this.$route.query.from === 'running') {
        return {
          title: this.$t('crm.activities.title_running'),
          link: '/crm/running',
        };
      }

      if (this.$route.query.from === 'quality_assurance') {
        return {
          title: this.$t('crm.activities.title_quality_assurance'),
          link: '/crm/quality-assurance/activities',
        };
      }

      return {
        title: this.$t('crm.activities.title'),
        link: '/crm/activities/list',
      };
    },
    breadcrumbs() {
      return [
        this.backPage,
        {
          title: this.activityName,
          dynamic: true,
        },
      ];
    },
    activityName() {
      return this.isCreate
        ? this.$t('crm.activities.info.new_activity')
        : this.oldActivity.name;
    },
    from: {
      get() {
        return this.$moment(this.activity.activity_period_start).toDate();
      },
      set(value) {
        this.$set(this.activity, 'activity_period_start', this.$moment(value).format('YYYY-MM-DD HH:mm:ss'));
      },
    },
    to: {
      get() {
        return this.$moment(this.activity.activity_period_end).toDate();
      },
      set(value) {
        this.$set(this.activity, 'activity_period_end', this.$moment(value).format('YYYY-MM-DD HH:mm:ss'));
      },
    },
    period() {
      const {
        activity_period_start,
        activity_period_end,
      } = this.activity;

      const from = this.$options.filters.formatDate3(activity_period_start);
      const to = this.$options.filters.formatDate3(activity_period_end);

      return `${from} - ${to}`;
    },
    isCreate() {
      return !this.activity.id;
    },
    isFormSegmentValid() {
      const isValidGlobalSegment = this.activity.crm_segment_is_global && this.activity.crm_segment_id;
      const isValidLocalSegment = !this.activity.crm_segment_is_global || !this.$_.isEmpty(this.activity.crm_segment);

      return !!(isValidGlobalSegment
        || isValidLocalSegment);
    },
    isFromActionsValid() {
      const shadowedActionGroups = this.controlGroupActive
        ? this.shadowedActionGroups
        : this.shadowedActionGroups.filter(item => !item.is_control);

      const percentTotal = shadowedActionGroups.reduce((a, b) => a + +b.percent, 0);
      const percent100Condition = percentTotal === 100;

      const hasActionsCoundition = this.shadowedActionGroups
        .filter(item => !item.is_control)
        .every(item => item.actions && item.actions.length);

      return !!(percent100Condition && hasActionsCoundition);
    },
    isFormRequiredValid() {
      return !!(this.activity.name
        && this.activity.activity_period_start
        && this.activity.activity_period_end);
    },
    isFormValid() {
      if (this.state === 'pause-edit') {
        return this.activityHash !== this.oldActivityHash;
      }
      // return !!(this.isFormRequiredValid
      //   && this.isFormSegmentValid
      //   && this.isFromActionsValid);

      return true;
    },
    isApproved() {
      let isActionGroupsApproved = true;

      this.reworkState.action_groups.forEach((actionGroup) => {
        if (actionGroup.is_control && !this.controlGroupActive) {
          return true;
        }

        if (!actionGroup.is_approved) {
          isActionGroupsApproved = false;
        } else {
          actionGroup.actions.forEach((action) => {
            if (!action.is_approved) {
              isActionGroupsApproved = false;
            }
          });
        }

        return isActionGroupsApproved;
      });

      return this.reworkState.is_approved_general
        && (this.reworkState.is_approved_segment || !this.permissions.can_view_segment)
        && this.reworkState.is_approved_actions
        && isActionGroupsApproved;
    },
    isRunning() {
      return this.activity.launch_status === 'running';
    },
    isCanArchive() {
      return this.activity.moderation_status === 'approved'
        && this.activity.status === 'active'
        && this.activity.launch_status === 'completed';
    },
    status() {
      return this.isCreate
        ? 'draft'
        : this.detectActivityStatus(this.activity);
    },
    actionsList() {
      const actions = [
        {
          icon: 'play',
          color: 'green',
          filled: true,
          text: this.$t('crm.buttons.start_activity'),
          action: () => this.handleStart(),
          states: ['approved'],
          placements: ['header', 'footer'],
          showCondition: () => !this.isRunning && this.status === 'approved' && this.canRun,
        },
        {
          icon: 'play',
          color: 'green',
          filled: true,
          text: this.$t('crm.buttons.resume_activity'),
          action: () => this.handleStart(),
          states: ['approved'],
          placements: ['header', 'footer'],
          showCondition: () => !this.isRunning && this.status === 'paused' && this.canRun,
        },
        {
          icon: 'pause',
          color: 'green',
          filled: true,
          text: this.$t('crm.buttons.pause_activity'),
          action: () => this.handlePause(),
          states: ['approved'],
          placements: ['header', 'footer'],
          showCondition: () => this.isRunning && this.status !== 'completed' && this.canRun,
        },
        {
          icon: 'stop',
          color: 'red',
          filled: true,
          text: this.$t('crm.buttons.stop_activity'),
          action: () => this.handleComplete(),
          states: ['approved'],
          placements: ['header', 'footer'],
          showCondition: () => this.status !== 'completed' && this.status !== 'approved' && this.canRun,
        },
        {
          icon: 'check-double',
          color: 'green',
          filled: true,
          text: this.$t('crm.buttons.save_and_send'),
          action: () => this.handleSaveAndSendToReview(),
          disabled: () => !this.isFormValid,
          states: ['rework-edit', 'edit', 'pause-edit'],
          placements: ['header', 'footer'],
        },
        {
          icon: 'check',
          color: 'green',
          filled: false,
          text: this.$t('crm.buttons.save'),
          action: () => this.handleSave(),
          disabled: () => !this.isFormValid,
          states: ['edit', 'rework-edit'],
          placements: ['header', 'footer'],
        },
        {
          icon: 'times',
          color: 'red',
          filled: false,
          text: this.$t('crm.buttons.cancel'),
          action: () => this.handleCancel(),
          states: ['edit', 'rework-edit', 'pause-edit'],
          placements: ['header', 'footer'],
        },
        // {
        //   icon: 'times',
        //   color: 'red',
        //   filled: false,
        //   text: this.$t('crm.buttons.delete'),
        //   action: () => this.handleDecline(),
        //   states: ['rework'],
        //   placements: ['header', 'footer'],
        // },
        {
          icon: 'check',
          color: 'green',
          filled: true,
          text: this.$t('crm.buttons.approve'),
          action: () => this.handleApprove(),
          disabled: () => !this.isApproved,
          showCondition: () => this.canReview,
          states: ['check'],
          placements: ['header', 'footer'],
        },
        {
          icon: 'sync-alt',
          color: 'green',
          filled: false,
          text: this.$t('crm.buttons.for_rework'),
          action: () => this.handleSendForRework(),
          disabled: () => this.isApproved,
          showCondition: () => this.canReview,
          states: ['check'],
          placements: ['header', 'footer'],
        },
        {
          icon: 'times',
          color: 'red',
          filled: false,
          text: this.$t('crm.buttons.decline'),
          action: () => this.handleDecline(),
          showCondition: () => this.canReview,
          states: ['check'],
          placements: ['header', 'footer'],
        },
        {
          icon: 'check-double',
          color: 'green',
          filled: true,
          text: this.$t('crm.buttons.send'),
          action: () => this.handleSendToReview(),
          showCondition: () => this.canEdit,
          disabled: () => !this.isFormValid,
          states: ['preview-edit'],
          placements: ['header', 'footer'],
        },
        {
          icon: 'check-double',
          color: 'green',
          filled: true,
          text: this.$t('crm.buttons.send'),
          action: () => this.handleSendToReview(),
          showCondition: () => this.canEdit && this.activity.comment_needed === false,
          disabled: () => !this.isFormValid,
          states: ['rework'],
          placements: ['header', 'footer'],
        },
        {
          icon: 'pen',
          color: 'green',
          filled: false,
          text: this.$t('crm.buttons.edit'),
          action: () => this.handleEdit(),
          placements: ['header', 'footer'],
          states: ['rework', 'approved'],
          showCondition: () => {
            if (this.state === 'approved') {
              return !this.isRunning && this.status === 'paused' && this.canEdit;
            }

            return this.canEdit;
          },
        },
        {
          icon: 'pen',
          color: 'green',
          filled: false,
          text: this.$t('crm.buttons.edit'),
          action: () => this.handleEdit(),
          showCondition: () => this.canEdit,
          states: ['preview-edit'],
          placements: ['header', 'footer'],
        },
        {
          icon: 'clone',
          color: 'green',
          filled: false,
          text: this.$t('crm.buttons.clone'),
          action: () => this.handleClone(),
          placements: ['header', 'footer'],
          showCondition: () => this.canEdit && this.activity.moderation_status === 'approved',
        },
        {
          icon: 'trash-alt',
          color: 'red',
          filled: false,
          text: this.$t('crm.buttons.archive'),
          action: () => this.handleArchive(),
          disabled: () => !this.isCanBeArchived(this.activity),
          states: ['approved', 'preview', 'preview-edit'],
          placements: ['header', 'footer'],
          showCondition: () => this.canEdit && this.isCanBeArchived(this.activity)
            && ((this.state === 'preview' && this.status === 'declined') || this.state !== 'preview'),
        },
      ];

      return actions.map((item, index) => ({
        ...item,
        key: index,
      }));
    },
    actionsFiltered() {
      return this.actionsList
        .filter(item => !item.showCondition || item.showCondition())
        .filter((item) => {
          if (!item.states) return true;

          return item.states.includes(this.state);
        });
    },
    actionsHeader() {
      return this.actionsFiltered
        .filter(item => item.placements.includes('header'));
    },
    actionsFooter() {
      return this.actionsFiltered
        .filter(item => item.placements.includes('footer'));
    },
    calcActivityHashDebouced() {
      return this.$_.debounce(() => this.calcActivityHash(this.activity, 'activityHash'), 200);
    },
    periodError() {
      return this.getError('activity_period_start') || this.getError('activity_period_end');
    },
    nameError() {
      return this.getError('name');
    },
    periodExpiredError() {
      const activityPeriodEnd = this.$moment(this.activity.activity_period_end);
      const hasError = this.state === 'check' && this.now.isAfter(activityPeriodEnd);
      return hasError && this.$t('crm.activities.errors.activity_expired_error');
    },
    generalErrors() {
      return [
        this.periodExpiredError,
      ].filter(item => !!item);
    },
  },
  watch: {
    activity: {
      deep: true,
      immediate: true,
      handler() {
        this.calcActivityHashDebouced();
      },
    },
    oldActivity: {
      deep: true,
      immediate: true,
      handler() {
        this.calcActivityHash(this.oldActivity, 'oldActivityHash');
      },
    },
    async currentBrand() {
      this.$router.push('/crm/activities/list');
    },
    '$route.params.isEdit': {
      handler(value) {
        if (value) {
          this.initComponent();
        }
      },
    },
  },
  mounted() {
    this.initComponent();
  },
  methods: {
    onChangeActivityRange(date) {
      this.activityRange = date ? {
        stats_period_start: this.$moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
        stats_period_end: this.$moment(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      } : null;

      this.getActivity();
    },
    async initComponent() {
      this.id = this.$route.params.id;
      const promises = [
        () => this.getSegments(),
        () => this.getTemplates(),
      ];

      if (this.id) {
        promises.push(() => this.getActivity());
      } else {
        this.activity = this.getDefaultActivity();
        this.saveComponentState();
        this.state = 'edit';
      }

      this.loading = true;

      await Promise.all(promises.map(defered => defered()));

      if (this.$route.params.isEdit) {
        this.state = 'edit';
      }

      this.$nextTick(() => {
        const segmentStats = this.$refs.activitySegment?.getDefaultsegmentStats();
        if (!segmentStats) return;

        segmentStats.players_count = this.activity.players_count;

        this.$refs.activitySegment.setSegmentId(this.activity.crm_segment_id, false, segmentStats);
        if (this.segments.length === 0 && this.activity.crm_segment_id) {
          const currentSegment = {
            id: this.activity.crm_segment_id,
            is_global: this.activity.crm_segment_is_global,
            name: this.activity.crm_segment_name,
            players_count: this.activity.crm_segment_players_count,
            type: this.activity.crm_segment_type,
            filters: this.activity.filters,
            moderation_status: 'approved',
          };
          this.segments.push(currentSegment);
        }
      });

      this.loading = false;
    },
    getError(field) {
      return this.errors[field] ? this.errors[field][0].message : '';
    },
    saveComponentState() {
      this.$nextTick(() => {
        this.oldActivity = this.$_.cloneDeep(this.activity);
        this.$refs.activityActions.saveState();
        this.oldControlGroupActive = this.controlGroupActive;

        const segmentStats = this.$refs.activitySegment.getDefaultsegmentStats();
        this.$refs.activitySegment.setSegmentId(this.activity.crm_segment_id, false, segmentStats);
      });
    },
    calcActivityHash(activity, hashName) {
      const result = hash(activity, { algorithm: 'md5' });
      this.$set(this, hashName, result);
    },
    getPlayers() {
      const interval = setInterval(async () => {
        const { data: { payload } } = await this.$api.getActivity(this.id);

        if (payload.is_players_attached || !this.$route.params.id) {
          if (this.$route.params.id) {
            this.$set(this.activity, 'is_players_attached', payload.is_players_attached);
            this.$set(this.activity, 'players_count', payload.players_count);

            this.activity.action_groups.forEach((action) => {
              const players_count = payload.action_groups.find(({ id }) => id === action.id)?.players_count || action.players_count;
              this.$set(action, 'players_count', players_count);
            });

            const controlGroup = this.activity.action_groups.find(item => item.is_control);
            this.$set(this, 'controlGroupPlayers', controlGroup?.players_count || 0);
          }

          clearInterval(interval);
        }
      }, 15000);
    },
    getActivity() {
      const promises = [
        () => this.$api
          .getActivity(this.id, this.activityParams)
          .then((response) => {
            this.activity = this.$_.cloneDeep(response.data.payload);

            if (!this.activity.is_players_attached) {
              this.getPlayers();
            }

            this.permissions = this.$_.cloneDeep(response.data.misc.permissions);
            const defaultActivity = this.getDefaultActivity();

            this.$set(this.activity, 'metrics_has_cooldown', this.activity.metrics_cooldown !== null);

            if (this.activity.metrics_has_cooldown) {
              const metricsCooldown = this.$moment.duration(
                this.activity.metrics_cooldown,
                'seconds',
              );

              this.activity.metrics_cooldown = metricsCooldown.asDays();
            } else {
              this.activity.metrics_cooldown = defaultActivity.metrics_cooldown;
            }

            const sendSctionsRepeatInterval = this.activity.send_actions_repeat_interval || 0;
            const sendSctionsRepeatIntervalDuration = this.$moment.duration(
              sendSctionsRepeatInterval,
              'seconds',
            );

            this.activity.send_actions_repeat_interval = sendSctionsRepeatIntervalDuration.asDays();

            const controlGroup = this.activity.action_groups.find(item => item.is_control);
            this.controlGroupActive = !!controlGroup;
            this.controlGroupPlayers = controlGroup?.players_count || 0;
            this.controlGroupPercent = controlGroup ? controlGroup.percent : 0;

            if (!this.controlGroupActive) {
              this.activity.action_groups.unshift({
                index: 0,
                is_control: true,
                percent: 0,
              });
            }

            if (this.activity.metrics.length > 0) {
              const hasMetricsValues = typeof this.activity.metrics[0] === 'object';
              const metrics = hasMetricsValues
                ? this.activity.metrics.map(metric => metric.name)
                : this.$_.cloneDeep(this.activity.metrics);

              if (hasMetricsValues) {
                this.metricValues = this.$_.cloneDeep(this.activity.metrics);
              }

              // this.metricValues = this.genStubMetrics(this.activity);
              this.$set(this.activity, 'metrics', metrics);
            }

            if (this.activity.metrics_updated_at) {
              const metricsUpdatedAt = this.$moment(this.activity.metrics_updated_at).format('HH:mm DD.MM.YYYY');
              this.$set(this.activity, 'metrics_updated_at', metricsUpdatedAt);
            }

            this.now = this.$moment(new Date());

            this.saveComponentState();

            switch (this.status) {
            case 'draft':
              this.state = 'preview-edit';
              break;
            case 'on_review':
              this.state = 'check';
              this.reworkState = this.getReworkState(this.activity.launch_status !== 'paused');
              break;
            case 'approved':
            case 'running':
            case 'paused':
            case 'completed':
              this.state = 'approved';
              break;
            case 'for_rework':
              this.state = 'rework';
              this.reworkState = this.getReworkState(true);
              break;
            case 'archived':
            case 'declined':
            default:
              this.state = 'preview';
            }
          }).catch((err) => {
            if (err.status === 404) {
              this.$router.push('/crm/activities/list');
            }
          }),
      ];

      return Promise.all(promises.map(defered => defered()));
    },
    getTemplates() {
      return this.$api
        .getEmailTemplates({
          site_id: this.currentBrand,
        })
        .then((response) => {
          this.templates = this.$_.cloneDeepWith(response.data.payload);
        });
    },
    getSegments() {
      return this.$api
        .getSegments({
          site_id: this.currentBrand,
          schema: 'short_list',
          moderation_status: 'approved',
        })
        .then((response) => {
          this.segments = this.$_.cloneDeepWith(response.data.payload);
        });
    },
    getDefaultActivity() {
      const { from, to } = this.getDefaultPeriod();

      return {
        name: '',
        site_id: this.currentBrand,
        activity_period_start: from.format('YYYY-MM-DD 00:00:00'),
        activity_period_end: to.format('YYYY-MM-DD 23:59:59'),
        crm_segment_id: '',
        crm_segment: {},
        send_actions_repeat_interval: 1,
        send_actions_count: 1,
        send_actions_period: 'day',
        send_actions_repeat: false,
        action_groups: [
          {
            index: 0,
            is_control: true,
            percent: 100,
          },
        ],
        metrics: [],
        metrics_has_cooldown: false,
        metrics_cooldown: 7,
        metrics_updated_at: from.format('HH:mm DD.MM.YYYY'),
        created_at: from.format('YYYY-MM-DD 00:00:00'),
        filters: [],
      };
    },
    getDefaultActionGroup() {
      return {
        is_control: false,
        percent: 0,
        actions: [],
      };
    },
    getReworkState(fromActivity = true) {
      const actionGroups = this.activity.action_groups.map((actionGroup) => {
        const result = {
          id: actionGroup.id,
          is_control: actionGroup.is_control,
          is_approved: fromActivity && actionGroup.is_approved,
        };

        if (actionGroup.actions) {
          result.actions = actionGroup.actions.map(action => ({
            id: action.id,
            is_approved: fromActivity && action.is_approved,
          }));
        }

        return result;
      });

      return {
        is_approved_general: fromActivity && this.activity.is_approved_general,
        is_approved_segment: fromActivity && this.activity.is_approved_segment,
        is_approved_actions: fromActivity && this.activity.is_approved_actions,
        action_groups: actionGroups,
      };
    },
    getDefaultPeriod() {
      return {
        from: this.$moment(new Date()).startOf('day'),
        to: this.$moment(new Date())
          .add(1, 'y')
          .endOf('day'),
      };
    },
    genStubMetrics(activity) {
      let i = 0;
      if (activity.metrics.length === 0) {
        return [];
      }

      const metrics = typeof activity.metrics[0] === 'object'
        ? activity.metrics.map(metric => metric.name)
        : activity.metrics;

      const genValuesStub = () => activity.action_groups.map((actionGroup) => {
        i += 1;

        return {
          crm_action_group_id: actionGroup.id,
          value: (5011 + (2777 * i)) % 9001,
        };
      });

      return metrics.map(metric => ({
        name: metric,
        values: genValuesStub(),
      }));
    },
    prepareActivity(activity) {
      const _activity = this.$_.cloneDeep(activity);
      const isGlobal = this.$refs.activitySegment.segmentType === 'global';

      if (isGlobal) {
        delete _activity.crm_segment;
      } else {
        const { segment } = this.$refs.activitySegment;

        _activity.crm_segment = segment
          ? this.clearSegment(segment)
          : segment;

        delete _activity.crm_segment_id;
        delete _activity.filters;
      }

      if (_activity.filters && _activity.filters.length) {
        _activity.filters = this.clearRules(_activity.filters);
      }

      _activity.action_groups = this.shadowedActionGroups
        .map(actionGroup => ({
          ...actionGroup,
          percent: +actionGroup.percent,
        }));

      if (!this.controlGroupActive) {
        _activity.action_groups = _activity.action_groups.filter(item => !item.is_control);
      }

      const sendSctionsRepeatInterval = _activity.send_actions_repeat_interval || 0;
      const sendSctionsRepeatIntervalDuration = this.$moment.duration(
        sendSctionsRepeatInterval,
        'days',
      );

      _activity.send_actions_repeat_interval = sendSctionsRepeatIntervalDuration.asSeconds();

      if (!_activity.metrics_has_cooldown) {
        _activity.metrics_cooldown = null;
      } else {
        const metricsCooldown = this.$moment.duration(
          _activity.metrics_cooldown,
          'days',
        );

        _activity.metrics_cooldown = metricsCooldown.asSeconds();
      }

      delete _activity.metrics_has_cooldown;

      return _activity;
    },
    openChangeStatusConfirm(status, payload) {
      this.confirm = this.confirmIndex[status](this.activity, () => this.changeStatus(status, payload));
      this.$refs.confirm.open();
    },
    async changeStatus(status, payload) {
      this.loading = true;
      this.$refs.confirm.close();

      try {
        const { data } = await this.$api.postActivityStatus(this.activity.id, status, payload);

        switch (status) {
        case 'clone':
          return this.$router.push({
            name: 'crm-activity',
            params: {
              id: data.payload.id,
              isEdit: true,
            },
          });
        default:
          return await this.getActivity();
        }
      } finally {
        this.loading = false;
      }
    },
    handleApprove() {
      this.openChangeStatusConfirm('approve');
    },
    handleSendForRework() {
      const reworkState = {
        ...this.reworkState,
        action_groups: this.reworkState.action_groups.filter(actionGroup => this.controlGroupActive || !actionGroup.is_control),
      };

      this.openChangeStatusConfirm('for-rework', reworkState);
    },
    handleDecline() {
      this.openChangeStatusConfirm('decline');
    },
    handleClone() {
      this.openChangeStatusConfirm('clone');
    },
    handleArchive() {
      this.openChangeStatusConfirm('archive');
    },
    handleStart() {
      this.openChangeStatusConfirm('run');
    },
    handlePause() {
      this.openChangeStatusConfirm('pause');
    },
    handleComplete() {
      this.openChangeStatusConfirm('complete');
    },
    handleEdit() {
      if (this.status === 'paused' || this.activity.launch_status === 'paused') {
        this.state = 'pause-edit';
      } else if (this.state === 'preview-edit') {
        this.state = 'edit';
      } else if (this.state === 'rework') {
        this.state = 'rework-edit';
      }
    },
    handleCancel() {
      if (this.isCreate) {
        this.$router.push(this.backPage.link);
      } else {
        this.activity = this.$_.cloneDeep(this.oldActivity);
        this.controlGroupActive = this.oldControlGroupActive;

        if (this.state === 'rework-edit' || (this.status === 'for_rework' && this.activity.launch_status === 'paused')) {
          this.state = 'rework';
        } else if (this.state === 'edit') {
          this.state = 'preview-edit';
        } else if (this.state === 'pause-edit') {
          this.state = 'approved';
        }

        this.errors = {};

        this.$nextTick(() => {
          this.$refs.activityActions.restoreState();

          const segmentStats = this.$refs.activitySegment.getDefaultsegmentStats();
          segmentStats.players_count = this.activity.players_count;

          this.$refs.activitySegment.setSegmentId(this.activity.crm_segment_id, true, segmentStats);
        });

        this.needComment = '';
      }
    },
    async handleSave() {
      this.save();
    },
    async handleSaveAndSendToReview() {
      this.confirm = this.confirmIndex['save-and-send-to-review'](this.activity, async () => {
        this.$refs.confirm.close();
        await this.save(this.state !== 'pause-edit' || this.status === 'for_rework');
      });
      this.$refs.confirm.open();
    },
    async handleSendToReview() {
      this.confirm = this.confirmIndex['send-to-review'](this.activity, async () => {
        this.$refs.confirm.close();
        await this.sendToReview();
      });
      this.$refs.confirm.open();
    },
    async sendToReview() {
      this.loading = true;
      this.needComment = '';
      try {
        await this.$api.postActivityStatus(this.id, 'to-review');
        await this.getActivity();
      } catch (error) {
        if (error.data && error.data.code === 'CRM_ACTIVITY_COMMENT_NEEDED_ERROR') {
          this.needComment = error.data.message;
        }
      } finally {
        this.loading = false;
      }
    },
    handlePeriodChange(param, value) {
      if (!value) {
        this.$set(this, param, value);

        this.$nextTick(() => {
          const defaultPeriod = this.getDefaultPeriod();
          this.$set(this, param, defaultPeriod[param]);
        });
      }
    },
    async save(toReview = false) {
      this.errors = {};
      this.loading = true;

      const activity = this.prepareActivity(this.activity);
      this.needComment = '';

      // const { playersCount } = this.$refs.activitySegment;

      try {
        const promise = this.id
          ? this.$api.putActivity(this.id, activity)
          : this.$api.postActivity(activity);

        const response = await promise
          .catch((errors) => {
            if (errors.data.code === 'WRONG_FILE_FORMAT_ERROR') {
              this.$set(this.errors, 'crm_segment', {
                players_file_id: errors.data,
              });
            }

            if (errors.data.code === 'VALIDATION_ERROR') {
              this.errors = errors.data.errors;
              if (this.errors.action_groups && !this.controlGroupActive) {
                this.errors.action_groups.unshift(null);
              }
            }

            throw errors;
          });
        const id = this.$_.get(response, 'data.payload.id', null);
        const cb = async () => {
          if (toReview) {
            await this.$api.postActivityStatus(this.id, 'to-review');
          }

          await this.getActivity();
          this.$refs.activitySegment.getLocalSegment();

          // this.$set(this.activity, 'players_count', playersCount);
        };

        if (this.isCreate && id) {
          this.id = id;
          this.$router.push(`/crm/activities/${id}`, cb, cb);
        } else {
          await cb();
        }
      } catch (error) {
        if (error.data && error.data.code === 'CRM_ACTIVITY_COMMENT_NEEDED_ERROR') {
          this.needComment = error.data.message;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.crm-activity {
  &--no-toolbar {
    .item-card__toolbar {
      display: none;
    }

    .item-card__head {
      margin-bottom: 16px;
    }
  }

  &__tree-branch-translate {
    padding-left: 28px;
    &::after {
      width: 16px;
    }
  }

  &__panel-text {
    line-height: 16px;
    font-size: 14px;

    b {
      font-weight: 500;
    }
  }

  & &__preview-button.ui-button.medium {
    height: 16px;
  }

  &__label {
    margin-bottom: 5px;
    font-size: 14px;
    height: 14px;
    line-height: 14px;
    display: block;
  }

  &__date-block {
    display: flex;
    align-self: baseline;
    min-width: 340px;
  }

  &__spacer {
    display: flex;
    align-items: center;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 12px;
    height: 34px;
    align-self: flex-end;
  }

  &__date-switch {
    .el-radio-button__inner {
      padding: 7px 8px;
    }
  }

  &__control-group {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 8px;
    border: solid 1px var(--main-color);
    font-size: 16px;
  }

  &__bottom-controls-panel {
    .crm-panel__content {
      padding: 16px 24px;
    }
  }

  &__endtime-popover {
    left: auto !important;
    right: 25px;

    .el-time-panel {
      margin-left: -20px;
    }
  }

  &__input-error {
    align-self: flex-end;
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    font-style: italic;
    text-align: right;
    margin-top: 6px;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--danger-color);
  }

  &__date-block_has-error {
    .dtpicker .el-input__inner {
      border-color: var(--danger-color);
    }
  }
}
</style>
