<template>
  <div class="domain-registrar-accounts">
    <DomainRegistrarAccountPopup
      v-if="canEdit"
      ref="domainRegistrarAccountPopup"
      :is-open="isOpenPopup"
      :inital-data="editableRow"
      :props-errors="popupDomainRegistrarAccountError"
      @close="closePopup"
      @create="createDomainRegistrarAccount"
      @update="updateDomainRegistrarAccount"
    />

    <ui-confirm
      ref="confirm"
      :width="480"
      :type="'warning'"
      :action-name="$t('domainManagement.buttons.delete')"
      :action-fa-icon="'trash-alt'"
      :data="confirmData"
      @save="deleteHandler"
      @close="confirmClose"
    >
      <div
        slot="title"
        v-html="$t('domainManagement.buttons.delete')"
      />
      <div class="body__wrap">
        <i
          class="fas fa-trash-alt warning"
        />
        <div
          class="body__msg"
          v-html="$t('domainManagement.messages.delete', { name: confirmData.name })"
        />
      </div>
    </ui-confirm>

    <div class="toolbar">
      <div class="wrapper">
        <div class="section">
          <span class="title">{{ $t('domainManagement.pageTitle.domainRegistrarAccounts') }}</span>
          <ui-input
            v-model.trim="search"
            class="domain-registrar-accounts-search"
            :width="180"
            :is-search="true"
            @search="getDomainRegistrarAccounts"
          />
          <div class="filters">
            <ui-filter
              ref="filter"
              v-model="filters"
              :use-filters="availableFilters"
              class="btn ui-filter-new"
              @input="getDomainRegistrarAccounts"
            />
            <span
              v-if="Object.keys(filters).length !== 0"
              class="reset_link"
              @click="$refs.filter.reset()"
            >{{ $t('ui.filters.inner.reset_filter') }}</span>
          </div>
        </div>
        <div class="section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="paginationHandler"
            />
          </div>
          <ui-button
            v-if="canEdit"
            color="green"
            lib="fa"
            substyle="fas"
            filled
            icon="plus"
            class="btn btn-add"
            @click="openPopup"
          >
            {{ $t('domainManagement.buttons.add_domain_registrar_account') }}
          </ui-button>
        </div>
      </div>
    </div>
    <ui-table
      v-loading="isDataLoading"
      :lazy-loading-delay="4000"
      :fields="fields"
      :data="data"
      :rows-count="limit"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      lazy-loading
      class="table"
      i18n-path="domainManagement"
      element-loading-custom-class="data-loader-spinner"
      @sort="sortingHandler"
    >
      <template
        v-if="canEdit"
        slot="append"
      >
        <el-table-column width="75">
          <template slot-scope="scope">
            <ActionIcon
              :tooltip="$t('domainManagement.buttons.edit')"
            >
              <el-link
                class="action-ico"
                type="success"
                @click="editHandler(scope.row)"
              >
                <ui-icon
                  name="pen"
                  :color="$theme.accentColor"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
            <ActionIcon
              :tooltip="$t('domainManagement.buttons.delete')"
            >
              <el-link
                :disabled="scope.row.platform_service_domains_count > 0"
                class="action-ico"
                type="danger"
                @click="confirmOpen(scope.row)"
              >
                <ui-icon
                  name="trash-alt"
                  :color="$theme.dangerColor"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { pageSizeMixin, resolvePageSize } from '../../service/pageSize';
import queryParamsMixin from '../mixins/query-params';

import DomainRegistrarAccountPopup from '@/components/Popups/DomainRegistrarAccountPopup';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'domain-registrar-accounts/list/limit'; // for localstorage
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'DomainRegistrarAccounts',

  components: {
    DomainRegistrarAccountPopup,
  },

  mixins: [pageSizeMixin, queryParamsMixin],

  data() {
    return {
      isDataLoading: false,
      isOpenPopup: false,

      search: '',
      count: 0,
      page: 1,
      limit: pageSize,
      sortProp: 'incrementing_id',
      sortOrder: 'desc',
      popupDomainRegistrarAccountError: {},

      data: [],
      fields: [
        {
          name: 'incrementing_id',
          sortable: 'custom',
          align: 'left',
          width: '60',
        },
        {
          name: 'name',
          label: 'account_name',
          sortable: 'custom',
          minWidth: '100',
        },
        {
          name: 'domain_registrar_name',
          sortable: 'custom',
          computedValue: row => row.domain_registrar_name || '—',
        },
        {
          name: 'platform_service_domains_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
        },
        {
          name: 'auto_ns_management',
          sortable: 'custom',
          computedValue(row) {
            return row.auto_ns_management ? 'Auto' : 'Manual';
          },
        },
        {
          name: 'created_at',
          sortable: 'custom',
          width: '120',
          format: 'date-time',
        },
      ],

      editableRow: undefined,
      editableRowId: undefined,

      availableFilters: ['autoNsManagement', 'domainRegistrarId'],
      filters: {},

      confirmData: {},
    };
  },

  computed: {
    ...mapGetters('misc', ['domainRegistrarProviders']),

    isSuperUser() {
      return this.$store.getters['auth/adminAcl'].is_superuser;
    },

    canEdit() {
      return this.isSuperUser || this.$store.getters['auth/currentAcl'].domain_registrar_accounts_edit === 'allow';
    },

    queryParams() {
      return {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        search: this.search || undefined,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        ...this.filters,
      };
    },
  },

  // For query-params mixin (auto-change url query)
  methods: {
    fetch() {
      return this.getDomainRegistrarAccounts();
    },

    async getDomainRegistrarAccounts() {
      this.isDataLoading = true;

      const { data: { payload, misc } } = await this.$api.getDomainRegistrarAccounts(this.queryParams);

      this.count = misc.count;
      this.data = payload;

      this.isDataLoading = false;
    },

    paginationHandler(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;

        this.getDomainRegistrarAccounts();
      }
    },

    sortingHandler({ prop, order }) {
      if (order === '') return;

      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }

      this.getDomainRegistrarAccounts();
    },

    closePopup() {
      this.isOpenPopup = false;
      this.editableRow = undefined;
    },

    openPopup() {
      this.isOpenPopup = true;
      this.popupDomainRegistrarAccountError = {};
    },

    createDomainRegistrarAccount(data) {
      this.$api.createDomainRegistrarAccount(data)
        .then(async () => {
          await this.getDomainRegistrarAccounts();
          this.$refs.domainRegistrarAccountPopup.close();
        })
        .catch((e) => {
          this.popupDomainRegistrarAccountError = e.data.errors;
        });
    },

    updateDomainRegistrarAccount(data) {
      this.$api.updateDomainRegistrarAccount(this.editableRowId, data)
        .then(async () => {
          await this.getDomainRegistrarAccounts();
          this.$refs.domainRegistrarAccountPopup.close();
          this.editableRowId = undefined;
        })
        .catch((e) => {
          this.popupDomainRegistrarAccountError = e.data.errors;
        });
    },

    async deleteHandler(action, row) {
      this.$refs.confirm.close();
      await this.$api.deleteDomainRegistrarAccount(row.id);
      await this.getDomainRegistrarAccounts();
    },

    async editHandler(row) {
      const { data: { payload } } = await this.$api.getDomainRegistrarAccountById(row.id);
      this.editableRow = payload;
      this.editableRowId = row.id;
      this.openPopup();
    },

    confirmOpen(row) {
      this.confirmData = row;
      this.$refs.confirm.open();
    },

    confirmClose() {
      this.confirmData = {};
    },
  },
};
</script>
