<template>
  <div class="token-management">
    <ui-confirm
      ref="confirm"
      :width="480"
      type="success"
      :action-name="$t('settings.tokens.create_token')"
      action="create"
      action-icon="check"
      @save="handleCreateToken"
    >
      <div slot="title" v-html="$t('settings.tokens.create_token')" />
      <div class="body__wrap ui-w-wrap">
        <ui-input
          v-model="tokenName"
          :placeholder="$t('settings.tokens.enter_token_name')"
          :label="$t('settings.tokens.token_name')"
          size="big"
          :error="getError('name')"
          autosize
          class="offer-input offer-input-name"
        />
        <div class="ui-m-xl-t">
          <div class="label-text">
            {{ $t('settings.tokens.service') }}
          </div>
          <el-radio-group
            v-model="service"
            class="ui-m-md-t service"
            size="small"
          >
            <el-radio label="api">
              API
            </el-radio>
            <el-radio label="events-gw">
              Events Gateway
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </ui-confirm>
    <ui-confirm
      ref="createData"
      :width="480"
      type="success"
      :action-name="$t('settings.tokens.complete')"
      action="create"
      action-icon="check"
      :has-close-btn="false"
      @save="closeCreateData"
    >
      <div slot="title" v-html="$t('settings.tokens.create_token')" />
      <div class="body__wrap">
        <div style="flex: 1">
          <div class="token-container">
            <div class="token-header">
              <span class="title">{{ $t('settings.tokens.access_key') }}</span>
              <div class="copy" @click="() => copyText(createData.access_key)">
                <ui-icon
                  :size="12"
                  lib="fa"
                  substyle="fas"
                  name="clone"
                  :color="$theme.accentColor"
                />
                <span>{{ $t('reports.other.copy') }}</span>
              </div>
            </div>
            <div class="token" @click="() => copyText(createData.access_key)">
              {{ createData.access_key }}
            </div>
          </div>
          <div class="token-container">
            <div class="token-header">
              <span class="title">{{ $t('settings.tokens.secret_key') }}</span>
              <div class="copy" @click="() => copyText(createData.secret_key)">
                <ui-icon
                  :size="12"
                  lib="fa"
                  substyle="fas"
                  name="clone"
                  :color="$theme.accentColor"
                />
                <span>{{ $t('reports.other.copy') }}</span>
              </div>
            </div>
            <div class="token" @click="() => copyText(createData.secret_key)">
              {{ createData.secret_key }}
            </div>
          </div>
        </div>
      </div>
    </ui-confirm>
    <ui-confirm
      ref="delete"
      :width="480"
      type="warning"
      :action-name="$t('settings.tokens.delete')"
      action="delete"
      action-icon="trash-alt"
      @save="handleDeleteToken"
    >
      <div slot="title" v-html="deleteData.title" />
      <div class="body__wrap">
        <i class="fas fa-trash-alt warning" />
        <div class="body__msg" v-html="deleteData.msg" />
      </div>
    </ui-confirm>
    <div class="toolbar">
      <div class="wrapper">
        <div class="section">
          <span class="title">{{ $t('settings.tokens.title') }}</span>
          <div class="section">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="pageChange"
            />
            <ui-button
              color="green"
              filled
              lib="fa"
              substyle="fas"
              icon="plus"
              class="btn btn-add ui-m-lg-l"
              :disabled="isDataLoading"
              @click="createToken()"
            >
              {{ $t('settings.tokens.create_token') }}
            </ui-button>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <ui-table
        v-loading="isDataLoading"
        lazy-loading
        :fields="fields"
        :data="tableData"
        :rows-count="limit"
        i18n-path="settings.tokens"
        element-loading-custom-class="data-loader-spinner"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        @sort="handleSort"
      >
        <template slot="append">
          <el-table-column width="74">
            <template slot="header" slot-scope="scope">
              <div :key="scope.$index">
                {{ $t('settings.tokens.actions') }}
              </div>
            </template>
            <template slot-scope="scope">
              <div class="ui-d-flex ui-jc-center ui-fg-1">
                <ActionIcon :tooltip="$t('crm.buttons.delete')">
                  <div class="action-ico" @click="deleteToken(scope.row)">
                    <ui-icon
                      name="trash-alt"
                      :color="$theme.dangerColor"
                      lib="fa"
                    />
                  </div>
                </ActionIcon>
              </div>
            </template>
          </el-table-column>
        </template>
      </ui-table>
    </div>
  </div>
</template>

<script>
import { resolvePageSize } from '@/service/pageSize';
import updateUrl from '@/service/updateUrl';
import sessionAction from '@/views/mixins/session-action';

const sizes = {
  720: 5,
  1080: 20,
  1440: 30,
};

const viewName = 'token-management/list/limit';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

const SERVICE_MAP = {
  'events-gw': 'Events Gateway',
  api: 'API',
};

export default {
  name: 'TokenManagement',
  mixins: [sessionAction],
  data() {
    return {
      deleteData: {},
      createData: {},

      tokenName: '',
      service: 'api',

      limit: pageSize,
      page: 1,
      count: 0,

      isDataLoading: false,
      errors: {},

      sortProp: 'created_at',
      sortOrder: 'asc',

      tableData: [],
      fields: [
        {
          name: 'name',
          minWidth: '120',
        },
        {
          name: 'service',
          width: '200',
          minWidth: '120',
          computedValue: ({ service }) => SERVICE_MAP[service],
        },
        {
          name: 'created_at',
          width: '200',
          minWidth: '120',
          format: 'date-time',
          sortable: 'custom',
        },
        {
          name: 'last_active',
          width: '200',
          minWidth: '120',
          format: 'date-time',
        },
        {
          name: 'id',
          width: '265',
          minWidth: '120',
          actionLib: 'fa',
          controlType: 'icon',
          class: 'copy-token',
          action: () => {},
          controlColorIco: () => this.$theme.accentColor,
          actionName: row => (row.id ? 'clone' : ''),
          click: row => this.copyText(row.id),
        },
      ],
    };
  },
  watch: {
    sortProp(newVal) {
      if (
        this.fields.some(
          item => item.name === this.sortProp.sort_column && item.sortable === 'custom',
        )
      ) {
        this.$ls.set(
          'token-management/list/sort_column',
          newVal || 'created_at',
        );
      }
    },
    sortOrder(newVal) {
      this.$ls.set('token-management/list/sort_dir', newVal || 'desc');
    },
    limit(newVal) {
      this.$ls.set('token-management/list/limit', newVal || 20);
    },
  },

  created() {
    this.onSessionUnlock = this.initData;
    this.initData();
  },
  methods: {
    initData() {
      const _urlData = updateUrl.getParseParams(this.$route.hash);

      if (!this.$_.isEmpty(_urlData)) {
        const { limit = 0, page = 1 } = _urlData;

        this.limit = Number(limit) || pageSize;
        this.page = Number(page) || 1;

        delete _urlData.page;
        delete _urlData.limit;

        this.sortProp = this.$_.get(_urlData, 'sort_column', 'created_at');
        this.sortOrder = this.$_.get(_urlData, 'sort_dir', 'asc');
      } else {
        this.sortProp = this.$ls.get('token-management/list/sort_column')
          ? this.$ls.get('token-management/list/sort_column')
          : 'created_at';
        this.sortOrder = this.$ls.get('token-management/list/sort_dir')
          ? this.$ls.get('token-management/list/sort_dir')
          : 'desc';
        this.limit = +this.$ls.get('token-management/list/limit') || pageSize;
      }
      this.fetchData();
    },
    _query() {
      return {
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        limit: this.limit,
        offset: this.limit * this.page - this.limit,
      };
    },

    getError(field) {
      return this.$_.get(this.errors, [field, 0, 'message'], '');
    },

    closeCreateData() {
      this.$refs.createData.close();
    },
    async copyText(text) {
      try {
        await this.$copyText(text);
        this.$noty.info(`${this.$t('reports.other.copied_to_clipboard')}`);
      } catch (error) {
        this.$noty.error(`${this.$t('reports.other.failed_to_copy')}`);
      }
    },

    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.fetchData('handleSort');
    },
    createToken() {
      this.errors = {};
      this.tokenName = '';
      this.service = 'api';
      this.$refs.confirm.open();
    },
    handleCreateToken() {
      this.$api
        .postAccessToken({ name: this.tokenName, service: this.service })
        .then((res) => {
          const { id, secret_key } = res.data.payload;
          this.fetchData('silent');

          this.createData = {
            access_key: id,
            secret_key,
          };
          this.$refs.confirm.close();
          this.$refs.createData.open();
        })
        .catch((e) => {
          this.errors = this.$_.cloneDeepWith(e.data.errors);
        });
    },

    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.fetchData();
      }
    },

    deleteToken(params) {
      this.deleteData = {
        title: this.$t('settings.tokens.title_delete_token', {
          token: params.name,
        }),
        msg: this.$t('settings.tokens.msg_delete_token', {
          token: params.name,
        }),
        id: params.id,
      };
      this.$refs.delete.open();
    },

    handleDeleteToken() {
      this.$api.deleteAccessToken(this.deleteData.id).then(() => {
        this.$refs.delete.close();
        this.fetchData('silent');
      });
    },

    fetchData(action) {
      if (action !== 'silent') {
        this.isDataLoading = true;
      }

      /*
       * Формируем URL Filter
       * */
      this._completedFilterUrl();

      const options = this._query();

      this.$api
        .getAccessTokens(options)
        .then((response) => {
          this.count = response.data.misc.count;
          this.tableData = this.$_.cloneDeep(response.data.payload);
        })
        .finally(() => {
          if (action !== 'silent') {
            this.isDataLoading = false;
          }
        });
    },
    /*
     * Создаем filter URL
     * */
    _completedFilterUrl() {
      const _dataFilters = this._query();
      _dataFilters.page = this.page;

      delete _dataFilters.offset;

      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>
<style lang="scss">
.token-management {
  .copy-token {
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end!important;
    text-overflow: ellipsis;
    cursor: pointer;

    span {
      display: block;
      margin-left: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
<style scoped lang="scss">
.token-management {
  .token-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .token-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    padding: 10px 8px;
    border-radius: 4px;
    background-color: #f4f7fa;

    .title {
      font-size: 12px;
      font-weight: 500;
    }

    .copy {
      display: flex;
      cursor: pointer;
      color: var(--main-color);
      font-size: 12px;

      i {
        padding-right: 4px !important;
      }
    }
  }

  .token {
    color: var(--main-color);
    cursor: pointer;
    font-size: 14px;
    margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .title {
    font-weight: 500;
    font-size: 24px;
  }

  .toolbar {
    width: 100%;
    padding: 16px 0;
  }

  .wrapper {
    width: 90%;
    min-width: 1140px;
    margin: 0 auto;
  }

  .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .label-text {
    font-size: 14px;
    height: 14px;
    line-height: 14px;
  }

  .service {
    /deep/ {
      .el-radio__label {
        font-weight: normal;
      }
    }
  }
}
</style>
