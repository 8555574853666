export default {
  computed: {
    ableToSetCpa() {
      return this.isAllowed('offers_cpa_edit_settings');
    },
    ableToSetRevShare() {
      return this.isAllowed('offers_revshare_edit_settings');
    },
  },

  methods: {
    isAllowed(item) {
      const isSuperuser = this.$store.getters['auth/adminAcl'].is_superuser;
      const allowedByTeam = this.$store.getters['auth/currentAcl'][item] !== 'deny';
      return isSuperuser || allowedByTeam;
    },
  },
};
