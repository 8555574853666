import sites from './sites';
import apiUrls from './api-urls';

const customerId = process.env.VUE_APP_CUSTOMER_ID || 'demo';
const environmentName = process.env.VUE_APP_ENVIRONMENT_NAME || 'test';
const selectedTheme = process.env.VUE_APP_THEME_OVERRIDE || customerId;

if (typeof apiUrls[environmentName] === 'undefined') {
  throw new Error(`Environment ${environmentName} doesnt have defined API URL`);
}

const apiBaseUrl = apiUrls[environmentName];
const themeConfig = typeof sites[selectedTheme] !== 'undefined' ? sites[selectedTheme] : sites.demo;

export async function loadStyles() {
  if (typeof sites[selectedTheme] === 'undefined') {
    return import('./styles/demo');
  }

  return import(`./styles/${selectedTheme}`);
}

export const config = {
  apiBaseUrl, customerId, ...themeConfig,
};
