<template>
  <panel
    class="crm-activity-comments ui-m-xl-b"
    :error="!!error"
  >
    <panel-header-title class="crm-activity-comments__header">
      {{ $t('crm.activities.info.comments') }}
    </panel-header-title>
    <comments
      class="crm-activity-comments__component"
      :is-editable="isEdit"
      :comments="comments"
      :post-id="postId"
      :comments-loading.sync="commentsLoading"
      @post-comments="postComment"
      @get-comments="getComments"
    />
    <crm-error-panel
      v-if="error"
    >
      {{ error }}
    </crm-error-panel>
  </panel>
</template>

<script>
import * as Panel from '@/views/CRM/components/Panel';
import Comments from '@/components/Comments.vue';
import CrmErrorPanel from '@/views/CRM/components/CrmErrorPanel.vue';
import app from '@/main';

export default {
  name: 'ActivityComments',
  components: {
    ...Panel,
    Comments,
    CrmErrorPanel,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    activity: {
      type: Object,
      default: () => ({}),
    },
    state: {
      type: String,
      default: 'edit',
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      comments: [],
      postId: '',
      commentsLoading: false,
    };
  },
  computed: {
    id() {
      return this.activity && this.activity.id;
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getComments();
        }
      },
    },
  },
  methods: {
    getComments() {
      const params = {
        sort_column: 'created_at',
        sort_dir: 'asc',
      };

      this.$api
        .getActivityComments(this.id, params)
        .then((response) => {
          this.comments = this.$_.cloneDeepWith(response.data.payload);
          app.$eventBus.$emit('defaultCommentsState');
        })
        .finally(() => {
          // this.isDataLoading = false;
          this.commentsLoading = false;
        });
    },
    postComment(comment) {
      this.commentsLoading = true;
      this.$api
        .postActivityComment(this.id, comment)
        .then(() => {
          this.getComments();
        })
        .catch(() => {
          this.commentsLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.crm-activity-comments {
  position: relative;

  &__header {
    position: absolute;
    line-height: 32px;
  }

  &__component {
    &.body-comments {
      .comments-wrapper {
        justify-content: flex-end;

        .title{
          display: none;
        }
      }

      .upload .el-upload-list__item {
        max-width: 400px;
        height: 29px;

        .el-icon-close {
          top: 8px;
        }

        &:hover {
          border-radius: 4px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
          background-color: #fff;
        }
      }
    }
  }
}
</style>
