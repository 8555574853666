<template>
  <div
    class="dashboard-overview__metric-card-wrap"
    :class="[
      !filterIsEmpty ? 'metric-card--filtered' : '',
      isRemoved
        ? `dashboard-overview__metric-card-wrap--draggable dashboard-overview__metric-card-wrap--draggable${rowIndex}`
        : '',
    ]"
  >
    <adm-ui-dashboard-metric
      :loading="loading"
      :title="statValue.title"
      :title-tooltip="statValue.titleTooltip"
      :value="statValue.value"
      :value-diff="statValue.valueDiff"
      :value-diff-percentage="statValue.valueDiffPercentage"
      :diff-style="statValue.diffStyle"
      :description="statValue.description"
      :is-removed="isRemoved"
      :controls-data="controlsData"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import formatCurrency from '@/views/mixins/format-currency';

export default {
  name: 'DashboardMetricStar',
  mixins: [formatCurrency],
  props: {
    isRemoved: {
      type: Boolean,
      default: false,
    },

    stat: {
      type: Object,
      required: true,
    },

    rowIndex: {
      type: Number,
      default: null,
    },

    index: {
      type: Number,
      default: null,
    },

    period: {
      type: Array,
      default() {
        return [];
      },
    },

    // currencyCode: {
    //   type: String,
    //   default: '',
    // },
  },

  data() {
    return {
      statValue: {
        title: '',
        value: '0',
        valueDiff: '',
        valueDiffPercentage: '',
        diffStyle: 'success',
      },
      loading: true,
      report: {},
    };
  },

  computed: {
    ...mapGetters('reports', ['reportsSettings']),
    ...mapState('dashboards', ['stateDashboardFilter']),

    controlsData() {
      return [
        {
          title: this.$t('dashboards.other.edit'),
          type: 'primary',
          icon: 'fas fa-pen',
          clickHandler: () => this.editMetric(this.rowIndex, 'stat', this.index, this.stat),
        },
        {
          title: this.$t('dashboards.other.delete'),
          type: 'danger',
          icon: 'fas fa-trash-alt',
          clickHandler: () => this.removeMetric(this.rowIndex, 'stat', this.index),
        },
      ];
    },

    statId() {
      return this.stat.id;
    },

    paramsData() {
      const period = {
        from: this.$moment(this.period[0]).format('YYYY-MM-DD HH:mm:ss'),
        to: this.$moment(this.period[1]).format('YYYY-MM-DD HH:mm:ss'),
      };

      const panel = {
        name: this.stat.name,
        type: this.stat.type,
        metric_name: this.stat.metric_name,
        filters: this.stat.filters,
        current_period: this.stat.current_period,
        comparison_period: this.stat.comparison_period,
        enable_comparison: this.stat.enable_comparison || false,
      };

      return {
        panel,
        filters: this.stateDashboardFilter,
        ...period,
        currency_code: this.propCurrencyCode,
      };
    },

    filterIsEmpty() {
      if (this.stat.filters === null) return true;
      const { search, filters } = this.stat.filters;
      const countKey = Object.keys(this.stat.filters).length;
      const func = (count, filter) => {
        const keyArray = Object.keys(filter);
        if (count === 1) {
          return keyArray.includes('filters') || keyArray.includes('search');
        }
        if (count === 2) {
          return keyArray.includes('filters') && keyArray.includes('search');
        }
        return false;
      };
      return (
        this.$_.isEmpty(search)
        && this.$_.isEmpty(filters)
        && func(countKey, this.stat.filters)
      );
    },
  },

  watch: {
    propCurrencyCode() {
      this.getStat();
    },
  },

  methods: {
    removeMetric(rowIndex, type, index) {
      this.$emit('removeMetric', rowIndex, type, index);
    },

    editMetric(rowIndex, type, index, data) {
      this.$emit('editMetric', rowIndex, type, index, data);
    },

    setValueStat(stat, value) {
      this.statValue.title = stat.name;
      this.statValue.titleTooltip = this.getTranslateMetrics(
        stat.metric_name,
      ).description;
      this.statValue.value = value.current.formatted;
      this.statValue.valueDiff = value.previous?.formatted || '—';
      if (!value.hasOwnProperty('difference_percent')) {
        this.statValue.diffStyle = 'default';
        this.statValue.valueDiffPercentage = ' ';
      } else {
        this.statValue.valueDiffPercentage = `${value.difference_percent || 0}%`;
        this.statValue.diffStyle = value.difference_percent >= 0
          ? 'success'
          : 'danger';
      }
      this.statValue.description = this.getDiscriptionStat();
      this.statValue = this.$_.cloneDeep(this.statValue);
    },

    getDiscriptionStat() {
      if (this.statValue.diffStyle === 'default') {
        return 'Comparison Off';
      }
      return this.$t('dashboards.compared.this_vs_last');
      // return `${ this.$t(`dashboards.peroid_stat.${this.stat.current_period}`)} (${this.$t(`dashboards.compared.${this.stat.comparison_period}`)})`;
    },

    /**
     * Called outside the component
     */
    async getStat(type) {
      if (type !== 'getLocalData') {
        await this.getDashboardsStats(this.paramsData);
      } else {
        this.report = this.stat.report;
      }
      this.setValueStat(this.stat, this.report);
      this.loading = false;
    },

    getTranslateMetrics(metric) {
      return this.formatMetric(this.reportsSettings.metrics.find(e => e.column_name === metric));
    },

    async getDashboardsStats(params) {
      const {
        data: { payload },
      } = await this.$api.dashboardsStats(params);
      this.report = payload;
      this.$emit(
        'emitDataReport',
        this.rowIndex,
        'stat',
        this.index,
        this.report,
      );
    },
  },
};
</script>
<style lang="scss">
.ui-dashboard-metric__bottom-wrap {
  display: none !important;
}
</style>
