var render = function render(_c,_vm){return _c('div',_vm._b({staticClass:"crm-panel-delimiter",class:{
    [_vm.data.staticClass]: _vm.data.staticClass,
    'crm-panel-delimiter_type-vertical': _vm.props.type === 'vertical',
    'crm-panel-delimiter_type-horizontal': _vm.props.type === 'horizontal',
    'crm-panel-delimiter_color-default': _vm.props.color === 'default',
    'crm-panel-delimiter_color-contrast': _vm.props.color === 'contrast',
  },style:(_vm.data.staticStyle || '')},'div',_vm.data.attrs,false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }