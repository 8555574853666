<template>
  <div class="marketing-materials">
    <div class="dns-hosting-accounts">
      <MarketingMaterialPopup
        v-if="canEdit"
        :is-open="isOpenPopup"
        :initial-data="editableRow"
        :server-errors="serverErrors"
        :is-affiliates-filter.sync="isAffiliatesFilter"
        @close="closePopup"
        @create="createMarketingMaterial"
        @update="updateMarketingMaterial"
        @clear-server-errors="clearServerErrors"
      />

      <ui-confirm
        ref="confirm"
        :width="480"
        :type="'warning'"
        :action-name="$t('marketingMaterials.buttons.delete')"
        :action-fa-icon="'trash-alt'"
        :data="confirmData"
        @save="deleteHandler"
        @close="confirmClose"
      >
        <div
          slot="title"
          v-html="$t('marketingMaterials.buttons.delete')"
        />
        <div class="body__wrap">
          <i
            class="fas fa-trash-alt warning"
          />
          <div
            class="body__msg"
            v-html="$t('marketingMaterials.messages.delete', { name: confirmData.name })"
          />
        </div>
      </ui-confirm>

      <div class="toolbar">
        <div class="wrapper">
          <div class="section">
            <span class="title">{{ $t('marketingMaterials.pageTitle') }}</span>
            <ui-input
              v-model.trim="search"
              class="dns-hosting-accounts-search"
              :width="180"
              :is-search="true"
              @search="getMarketingMaterials"
            />
            <div class="filters">
              <ui-filter
                ref="filter"
                v-model="filters"
                :use-filters="availableFilters"
                class="btn ui-filter-new"
                @input="getMarketingMaterials"
              />
              <span
                v-if="Object.keys(filters).length !== 0"
                class="reset_link"
                @click="$refs.filter.reset()"
              >{{ $t('ui.filters.inner.reset_filter') }}</span>
            </div>
          </div>
          <div class="section">
            <div class="pagination">
              <ui-pagination
                :page="page"
                :page-size="limit"
                :count="count"
                show-size-select
                @page-change="paginationHandler"
              />
            </div>
            <ui-button
              v-if="canEdit"
              color="green"
              lib="fa"
              substyle="fas"
              filled
              icon="plus"
              class="btn btn-add"
              @click="openPopup"
            >
              {{ $t('marketingMaterials.buttons.add_material') }}
            </ui-button>
          </div>
        </div>
      </div>
      <ui-table
        v-loading="isDataLoading"
        :lazy-loading-delay="4000"
        :fields="fields"
        :data="data"
        :rows-count="limit"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        lazy-loading
        class="table"
        i18n-path="marketingMaterials"
        element-loading-custom-class="data-loader-spinner"
        @sort="sortingHandler"
      >
        <template
          v-if="canEdit"
          slot="append"
        >
          <el-table-column width="75">
            <template slot-scope="scope">
              <ActionIcon
                :tooltip="$t('marketingMaterials.buttons.edit')"
              >
                <el-link
                  class="action-ico"
                  type="success"
                  @click="editHandler(scope.row)"
                >
                  <ui-icon
                    name="pen"
                    :color="$theme.accentColor"
                    lib="fa"
                  />
                </el-link>
              </ActionIcon>
              <ActionIcon
                :tooltip="$t('marketingMaterials.buttons.delete')"
              >
                <el-link
                  :disabled="scope.row.platform_service_domains_count > 0"
                  class="action-ico"
                  type="danger"
                  @click="confirmOpen(scope.row)"
                >
                  <ui-icon
                    name="trash-alt"
                    :color="$theme.dangerColor"
                    lib="fa"
                  />
                </el-link>
              </ActionIcon>
            </template>
          </el-table-column>
        </template>
      </ui-table>
    </div>
  </div>
</template>

<script>
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import queryParamsMixin from '../mixins/query-params';

import MarketingMaterialPopup from '@/components/Popups/MarketingMaterialPopup';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'marketing-materials/list/limit'; // for localstorage
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'MarketingMaterials',

  components: {
    MarketingMaterialPopup,
  },

  mixins: [pageSizeMixin, queryParamsMixin],

  data() {
    return {
      isDataLoading: false,
      isOpenPopup: false,

      search: '',
      count: 0,
      page: 1,
      limit: pageSize,
      sortProp: 'incrementing_id',
      sortOrder: 'desc',

      editableRow: undefined,
      editableRowId: undefined,

      data: [],
      fields: [
        {
          name: 'incrementing_id',
          sortable: 'custom',
          align: 'left',
          width: '60',
        },
        {
          name: 'name',
          sortable: 'custom',
          minWidth: '100',
        },
        {
          name: 'type',
          computedValue: row => this.$t(`marketingMaterials.fields.type.${row.type}`),
        },
        {
          name: 'brand_visibility',
          width: '100',
          linkAppend: (item) => {
            if (item.brand_visibility !== 'all') {
              const firstBrand = item.visible_brands.slice(0, 1)[0].site_name;
              if (item.visible_brands.length > 1) {
                const lastBrands = item.visible_brands.slice(1);

                const otherBrands = lastBrands.map(brand => this.$createElement('div', { class: 'el-dropdown-menu__item' }, brand.site_name));

                const visibleBrand = this.$createElement('span', {
                  class: 'server-adresses-value',
                }, firstBrand);

                const dropdown = this.$createElement(
                  'el-dropdown', {
                    class: 'server-adresses',
                    props: {
                      trigger: 'click',
                      placement: 'bottom',
                    },
                    on: {
                      'visible-change': isOpened => this.activePopper = isOpened ? item.id : null,
                    },
                  },
                  [
                    this.$createElement(
                      'div',
                      {
                        class: 'el-dropdown-link',
                      },
                      [
                        this.$createElement('span', { class: { 'server-adresses-count': true, active: this.activePopper === item.id } }, `+${lastBrands.length}`),
                      ],
                    ),
                    this.$createElement(
                      'el-dropdown-menu',
                      {
                        class: 'server-adresses-dropdown ddgroup',
                      },
                      otherBrands,
                    ),
                  ],
                );
                return this.$createElement(
                  'span', [
                    [visibleBrand],
                    [dropdown],
                  ],
                );
              }
              return this.$createElement('span', firstBrand);
            }

            return this.$createElement('span', this.$t('marketingMaterials.fields.brand_visibility.all'));
          },
        },
        {
          name: 'language_codes',
          width: '290',
          computedClass: () => 'block-ellipsis',
          linkAppend: (item) => {
            if (item.language_codes.length > 12) {
              const first10Languages = item.language_codes.slice(0, 12);
              const lastLanguages = item.language_codes.slice(12);

              const otherLanguages = lastLanguages.map(language => this.$createElement('div', { class: 'el-dropdown-menu__item' }, language));

              const visibleLanguages = this.$createElement('span', {
                class: 'server-adresses-value',
              },
              first10Languages.join(', '));

              const dropdown = this.$createElement(
                'el-dropdown', {
                  class: 'server-adresses',
                  props: {
                    trigger: 'click',
                    placement: 'bottom',
                  },
                  on: {
                    'visible-change': isOpened => this.activePopper = isOpened ? item.id : null,
                  },
                },
                [
                  this.$createElement(
                    'div',
                    {
                      class: 'el-dropdown-link',
                    },
                    [
                      this.$createElement('span', { class: { 'server-adresses-count': true, active: this.activePopper === item.id } }, `+${lastLanguages.length}`),
                    ],
                  ),
                  this.$createElement(
                    'el-dropdown-menu',
                    {
                      class: 'server-adresses-dropdown ddgroup',
                    },
                    otherLanguages,
                  ),
                ],
              );
              return this.$createElement(
                'span', [
                  [visibleLanguages],
                  [dropdown],
                ],
              );
            }
            return this.$createElement('span', { class: 'block-ellipsis' }, item.language_codes.join(', '));
          },
        },
        {
          name: 'visibility',
          computedValue: row => this.$t(`marketingMaterials.fields.visibility.${row.visibility}`),
        },
        {
          name: 'impressions_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
          width: '95',
        },
        {
          name: 'visits_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
          width: '95',
        },
        {
          name: 'registrations_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
          width: '95',
        },
        {
          name: 'conversion_rate',
          align: 'right',
          sortable: 'custom',
          format: 'percent',
          width: '110',
        },
        {
          name: 'status',
          computedValue: row => this.$t(`marketingMaterials.fields.status.${row.status}`),
          computedClass: value => `row-value--${value}`,
          width: '80',
        },
        {
          name: 'created_at',
          sortable: 'custom',
          width: '120',
          format: 'date-time',
        },
        {
          name: 'updated_at',
          sortable: 'custom',
          width: '120',
          format: 'date-time',
        },
      ],

      filters: {},

      confirmData: {},

      serverErrors: {},

      activePopper: null,
    };
  },

  computed: {
    isSuperUser() {
      return this.$store.getters['auth/adminAcl'].is_superuser;
    },
    canEdit() {
      return this.isSuperUser || this.$store.getters['auth/currentAcl'].marketing_materials_edit === 'allow';
    },
    isAffiliatesFilter() {
      return !!(this.isSuperUser || this.$store.getters['auth/currentAcl'].affiliates_view === 'allow');
    },

    availableFilters() {
      const filters = ['marketingMaterialType', 'languageCode', 'marketingMaterialStatus', 'brand', 'availabilityAffiliateId'];

      if (this.isAffiliatesFilter) {
        filters.push('availabilityMaterials');
      }

      return filters;
    },

    queryParams() {
      return {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        search: this.search || undefined,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        ...this.filters,
      };
    },
  },

  methods: {
    async getMarketingMaterials() {
      this.isDataLoading = true;
      const { data: { payload, misc } } = await this.$api.getMarketingMaterials(this.queryParams);

      this.count = misc.count;
      this.data = payload;

      this.isDataLoading = false;
    },

    // For query-params mixin (auto-change url query)
    fetch() {
      return this.getMarketingMaterials();
    },

    paginationHandler(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;

        this.getMarketingMaterials();
      }
    },

    sortingHandler({ prop, order }) {
      if (order === '') return;

      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }

      this.getMarketingMaterials();
    },

    closePopup() {
      this.isOpenPopup = false;
      this.editableRow = undefined;
      this.serverErrors = {};
    },

    clearServerErrors(key) {
      if (key) {
        this.serverErrors = this.$_.set(this.serverErrors, key, undefined);
      } else {
        this.serverErrors = {};
      }
    },

    openPopup() {
      this.isOpenPopup = true;
    },

    async createMarketingMaterial(data) {
      try {
        await this.$api.createMarketingMaterial(data);
        this.closePopup();
        await this.getMarketingMaterials();
      } catch (e) {
        this.serverErrors = e.data.errors;
      }
    },

    async updateMarketingMaterial(data) {
      try {
        await this.$api.updateMarketingMaterial(this.editableRowId, data);
        this.editableRowId = undefined;
        this.closePopup();
        await this.getMarketingMaterials();
      } catch (e) {
        this.serverErrors = e.data.errors;
      }
    },

    async deleteHandler(action, row) {
      this.$refs.confirm.close();
      await this.$api.deleteMarketingMaterial(row.id);
      await this.getMarketingMaterials();
    },

    async editHandler(row) {
      const { data: { payload } } = await this.$api.getMarketingMaterialById(row.id);
      this.editableRow = payload;
      this.editableRowId = row.id;
      this.openPopup();
    },

    confirmOpen(row) {
      this.confirmData = row;
      this.$refs.confirm.open();
    },

    confirmClose() {
      this.confirmData = {};
    },
  },
};
</script>

<style lang="scss">
.marketing-materials{
  display: flex;
  flex-direction: column;

  .block-ellipsis{
    > span{
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ui-filter .pop .filter-row.hide-filter{
    margin-bottom: 0;
  }

  .table {
    .el-table {
      height: initial !important;
      max-height: initial !important;

      &__body-wrapper{
        height: initial !important;
      }
    }
  }
}
</style>
