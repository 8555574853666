<template>
  <ui-dropdown-select
    :items="messageParams"
    :search-placeholder="$t('crm.activities.actions.action_params_popup.search_placeholder')"
    :not-found-message="$t('crm.activities.actions.action_params_popup.not_found')"
    :disabled="disabled"
    @select="handleSelect"
  >
    <template v-slot:activator="slotProps">
      <div
        class="crm-action-params__dropdown-button"
        :class="{
          'crm-action-params__dropdown-button_active': slotProps.active,
          'crm-action-params__dropdown-button_disabled': disabled,
        }"
      >
        <div class="ui-g-w-sm">
          <i class="far fa-plus ui-g-sm" />
          <span class="ui-g-sm">{{ $t('crm.activities.actions.action_params_popup.title') }}</span>
          <div class="ui-g-sm">
            <ui-chevron :rotate="!slotProps.active" />
          </div>
        </div>
      </div>
    </template>
  </ui-dropdown-select>
</template>

<script>
import UiDropdownSelect from '@/views/CRM/components/UiDropdownSelect.vue';
import UiChevron from '@/views/CRM/components/UiChevron.vue';

export default {
  name: 'CrmActionParams',
  components: {
    UiDropdownSelect,
    UiChevron,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    messageParams: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleSelect(event) {
      this.$emit('select', event);
    },
  },
};
</script>

<style lang="scss">
  .crm-action-params {
    &__dropdown-button {
      color: var(--site-text-color);
      font-size: 12px;
      padding: 5px 8px;
      border-radius: 16px;
      border: solid 1px var(--main-color);
      cursor: pointer;
      user-select: none;

      &_active {
        background-color: var(--main-color);
        color: var(--site-text-color-alt);
      }

      &_disabled {
        cursor: not-allowed;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: .5;
      }
    }
  }
</style>
