import store from '@/store';

export default function requestFinish(link, action) {
  const key = link.replaceAll('/', '');
  if (action === 'set') {
    store.commit('other/setRequestCall', key);
  }

  if (action === 'delete') {
    store.commit('other/deleteRequestCall', key);
  }
}
