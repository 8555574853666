<template>
  <div class="rule-block">
    <div class="ui-d-flex ui-jc-space-between ui-ai-center ui-m-xl-b">
      <div class="title-wrap">
        <h2 class="title">
          {{ 'CPA' }}
        </h2>
        <el-switch
          v-model="isCpa"
          :disabled="isDisabledCpa"
          class="ui-g-md ui-m-md-r"
          :active-color="$theme.accentColor"
          :inactive-color="$theme.dangerColor"
        />
      </div>
      <ui-button
        v-if="isCpa"
        slot="reference"
        :color="$theme.mainColor"
        class="payment-block__btn"
        @click="onAddCpa"
      >
        <i class="fas fa-plus plus-icon-custom" />
        {{ $t(`affiliates.offer_popup.add_cpa_payout`) }}
      </ui-button>
    </div>
    <div v-if="isCpa">
      <CpaItem
        v-for="(cpa, i) in payoutRule.settings.cpa"
        :ref="`cpa-${i}`"
        :key="`cpa-${indexRule}-${i}`"
        :is-disable-delete-payout="payoutRule.settings.cpa.length === 1"
        :cpa.sync="cpa"
        :customer-currencies.sync="customerCurrencies"
        :currency.sync="currency"
        :index-rule.sync="indexRule"
        :error-rule.sync="$_.get(errorRules, ['settings', 'cpa', i])"
        @remove-payout="onRemovePayout(i)"
      />
    </div>
  </div>
</template>

<script>
import aclMixin from '@/views/mixins/acl';
import CpaItem from './CpaItem';

export default {
  name: 'Cpa',
  components: {
    CpaItem,
  },
  mixins: [aclMixin],
  props: {
    customerCurrencies: {
      type: Array,
      default: () => [],
    },
    payoutRule: {
      type: Object,
      required: true,
    },
    indexRule: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    errorRules: {
      type: [Array, Object, undefined],
      default() {
        return {};
      },
    },
  },

  data() {
    return {
    };
  },
  computed: {
    isDisabledCpa() {
      if (!this.ableToSetCpa) {
        return true;
      }

      if (this.isCpa) {
        return !this.ableToSetRevShare || !this.payoutRule.settings.rev_share;
      }

      return false;
    },
    isCpa: {
      get() {
        if (!this.ableToSetCpa) {
          return false;
        }

        if (this.payoutRule.settings.cpa) {
          if (!this.ableToSetRevShare) {
            return true;
          }
          return true;
        }

        return false;
      },
      set(val) {
        if (val) {
          this.payoutRule.settings.cpa = [
            {
              amount: 0,
              currency_code: this.currency,
              cap_players_count: 0,
              cap_period_seconds: 0,
              hold_seconds: 0,
            },
          ];
          // this.reloadDataPayload();
        } else {
          this.payoutRule.settings.cpa = null;
        }
      },
    },
  },

  watch: {
  },
  mounted() {
  },
  methods: {
    onRemovePayout(i) {
      this.payoutRule.settings.cpa.splice(i, 1);
      if (this.errorRules?.settings?.cpa) {
        this.errorRules.settings.cpa.splice(i, 1);
      }
    },
    onAddCpa() {
      this.payoutRule.settings.cpa.push({
        amount: 0,
        currency_code: this.defaultCurrency,
        cap_players_count: 0,
        cap_period_seconds: 0,
        hold_seconds: 0,
      });

      this.$nextTick(() => {
        const data = this.$_.get(this.$refs, [`cpa-${this.payoutRule.settings.cpa.length - 1}`, 0]);
        // eslint-disable-next-line no-unused-expressions
        data?.$el?.scrollIntoView({ behavior: 'smooth' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  .title {
    font-size: 20px;
    font-weight: 500;
    color: #303634;
  }
}
</style>
