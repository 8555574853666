<template>
  <div class="activities">
    <ui-confirm
      ref="confirm"
      :width="480"
      :action-disabled.sync="isDataLoading"
      :type="confirm.type"
      :action-name="confirm.actionName"
      :action="confirm.action"
      action-icon="check"
      @save="confirm.confirmAction"
    >
      <div
        slot="title"
        v-html="confirm.title"
      />
      <div class="body__wrap">
        <i
          :class="confirm.type"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirm.msg"
        />
      </div>
    </ui-confirm>
    <div class="wrapper">
      <div class="toolbar">
        <div class="toolbar__section">
          <span class="title">{{ currentViewMeta.title }}</span>
          <ui-input
            v-model.trim="search"
            :width="180"
            :is-search="true"
            @search="getActivities"
          />
          <el-select
            v-if="!currentViewMeta.staticFilter"
            ref="select"
            v-model="combinedFilter"
            class="select"
            @change="$nextTick(() => setSanitizedFilterStatus(pFilterStatus))"
          >
            <el-option
              v-for="item in combinedFilterMeta"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-checkbox
            v-model="onlyMine"
            class="ui-m-lg-l"
            @change="getActivities"
          >
            {{ $t('crm.only_mine') }}
          </el-checkbox>
          <!-- фильтр должен быть рабочим, если изменятся требования к проекту то раскоментить -->
          <!-- <el-select
            v-if="!currentViewMeta.staticFilter"
            ref="select"
            v-model="pFilterStatus"
            class="custom-filter"
            popper-class="custom-filter-popper"
            collapse-tags
            multiple
            @input="setSanitizedFilterStatus"
          >
            <el-option-group
              v-for="group in statusMetaFiltered"
              :key="group.label"
              :label="$t(`crm.activities.status_groups.${group.label}.title`)"
            >
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="$t(`crm.activities.status_groups.${group.label}.values.${item.label}`)"
                :value="item.value"
                class="filter-item"
                :disabled="isStatusDisabled(pFilters, item.value, item)"
              />
            </el-option-group>
          </el-select> -->
        </div>
        <div class="toolbar__section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="paginationHandler"
            />
          </div>
          <ui-button
            v-if="isCreateActivity && currentViewMeta.hasCreateButton"
            color="green"
            lib="fa"
            substyle="fas"
            filled
            icon="plus"
            class="btn btn-add"
            @click="handleAddActivity"
          >
            {{ $t('crm.activities.add_activity') }}
          </ui-button>
          <ui-button
            color="green"
            lib="fa"
            substyle="fas"
            icon="sync-alt"
            class="btn btn-add"
            :disabled="isDataLoading"
            @click="refreshTable()"
          >
            {{ $t('reports.other.refresh') }}
          </ui-button>
        </div>
      </div>
      <ui-table
        v-loading="isDataLoading"
        :lazy-loading-delay="4000"
        :fields="fields"
        :data="data"
        :rows-count="limit"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        lazy-loading
        class="table"
        i18n-path="crm"
        element-loading-custom-class="data-loader-spinner"
        @sort="sortingHandler"
      >
        <template
          slot="append"
        >
          <el-table-column width="100">
            <template slot-scope="scope">
              <ActionIcon
                :tooltip="$t('crm.buttons.view')"
              >
                <router-link
                  :to="getActivityLink(scope.row)"
                  class="action-ico"
                >
                  <ui-icon
                    name="eye"
                    :color="$theme.accentColor"
                    lib="fa"
                  />
                </router-link>
              </ActionIcon>
              <ActionIcon
                :tooltip="$t('crm.tooltips.clone')"
              >
                <el-link
                  class="action-ico"
                  :disabled="!canEdit(scope.row.id) || !isCanBeCloned(scope.row)"
                  @click="handleClone(scope.row)"
                >
                  <ui-icon
                    name="clone"
                    :color="$theme.accentColor"
                    lib="fa"
                  />
                </el-link>
              </ActionIcon>
              <ActionIcon
                :tooltip="$t('crm.buttons.archive')"
              >
                <el-link
                  class="action-ico"
                  type="danger"
                  :disabled="!canEdit(scope.row.id) || !isCanBeArchived(scope.row)"
                  @click="handleDelete(scope.row)"
                >
                  <ui-icon
                    name="trash-alt"
                    :color="$theme.dangerColor"
                    lib="fa"
                  />
                </el-link>
              </ActionIcon>
            </template>
          </el-table-column>
        </template>
      </ui-table>
    </div>
  </div>
</template>

<script>

import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import queryParamsMixin from '@/views/mixins/query-params';
import ActivitiesCommonMixin from '@/views/CRM/mixins/activities-common-mixin';
import CrmTag from '@/views/CRM/components/CrmTag.vue';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'crm/activities/list/limit'; // for localstorage
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'Activities',
  mixins: [
    pageSizeMixin,
    queryParamsMixin,
    ActivitiesCommonMixin,
  ],
  props: {
    currentBrand: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filterStatus: this.getDefaultFilterStatus(),
      pFilterStatus: this.getDefaultFilterStatus(),
      isDataLoading: false,
      onlyMine: false,
      search: '',
      count: 0,
      page: 1,
      limit: pageSize,
      sortProp: 'incrementing_id',
      sortOrder: 'desc',
      data: [],
      permissions: {},
      confirm: this.getDefaultConfirm(),
      pCombinedFilter: '',
      fields: [
        {
          name: 'incrementing_id',
          sortable: 'custom',
          align: 'left',
          width: '60',
        },
        {
          name: 'moderation_status',
          label: 'computed_status',
          sortable: 'custom',
          computedValue: row => this.$t(`crm.fields.computed_status.values.${this.detectActivityStatus(row)}`),
          computedClass: (value, row) => `row-value--${this.detectActivityStatus(row)}`,
          width: '150',
          // linkAppend: () => {
          //   // такая ситуация возникнет когда активность запущена, но в сегменте, который она использует, есть изменения, которые еще не чекнули
          //   // следовательно до тех пор пока не сможем изменять сегметны после апрува (после добавления версионирования сегментов), она возникнуть не сможет
          //   const forcePausedCondition = true;
          //   if (forcePausedCondition) {
          //     return this.$createElement(
          //       'el-dropdown', {
          //         props: {
          //           trigger: 'click',
          //           placement: 'bottom',
          //         },
          //       },
          //       [
          //         this.$createElement(
          //           'div',
          //           {
          //             class: 'el-dropdown-link',
          //           },
          //           [
          //             this.$createElement('ui-icon', {
          //               props: {
          //                 lib: 'fa',
          //                 substyle: 'fas',
          //                 color: this.$theme.dangerColor,
          //                 name: 'exclamation-circle',
          //               },
          //             }),
          //           ],
          //         ),
          //         this.$createElement(
          //           'el-dropdown-menu',
          //           {
          //             class: 'activities-dropdown',
          //           },
          //           [
          //             this.$createElement(
          //               'el-dropdown-item',
          //               [
          //                 this.$createElement('span', this.$t('crm.errors.changes')),
          //               ],
          //             ),
          //           ],
          //         ),
          //       ],
          //     );
          //   }
          //   return null;
          // },
        },
        {
          name: 'name',
          sortable: 'custom',
          minWidth: '100',
        },
        {
          name: 'crm_segment_name',
          label: 'segment',
          minWidth: '100',
          // computedValue: row => `${row.crm_segment_name} (${this.$t('crm.activities.players_count', { count: row.crm_segment_players_count })})`,
        },
        {
          name: 'players_count',
          label: 'segment_players_count',
          sortable: 'custom',
          minWidth: '60',
          format: 'number',
          computedValue: row => (row.is_players_attached
            ? `${parseFloat(row.players_count).toLocaleString('en-US')}`
            : this.$t('crm.status.updating')),
        },
        {
          name: 'action_types',
          label: 'actions',
          minWidth: '100',
          linkAppend: this.renderActions,
          computedClass: () => 'activities-actions',
        },
        {
          name: 'activity_period_start',
          sortable: 'custom',
          width: '120',
          format: 'date-time',
        },
        {
          name: 'activity_period_end',
          sortable: 'custom',
          width: '120',
          format: 'date-time',
        },
      ],
    };
  },

  computed: {
    isSuperUser() {
      return this.$store.getters['auth/adminAcl'].is_superuser;
    },
    isCreateActivity() {
      const isUser = !this.isSuperUser && this.$store.getters['auth/currentAcl'].crm_activities_edit !== 'deny';
      return this.isSuperUser || isUser;
    },
    combinedFilter: {
      get() {
        return this.pCombinedFilter;
      },
      set(value) {
        this.pCombinedFilter = value;
        this.pFilterStatus = this.$_.cloneDeep(this.combinedFilterMetaIndex[value].filter);
      },
    },
    combinedFilterMeta() {
      return [{
        value: '',
        label: this.$t('crm.fields.computed_status.values.all'),
        filter: ['active', 'draft'],
      }, {
        value: 'draft',
        label: this.$t('crm.fields.computed_status.values.draft'),
        filter: ['draft'],
      }, {
        value: 'on_review',
        label: this.$t('crm.fields.computed_status.values.on_review'),
        filter: ['active', 'on_review'],
      }, {
        value: 'for_rework',
        label: this.$t('crm.fields.computed_status.values.for_rework'),
        filter: ['active', 'for_rework'],
      }, {
        value: 'declined',
        label: this.$t('crm.fields.computed_status.values.declined'),
        filter: ['active', 'declined'],
      }, {
        value: 'approved',
        label: this.$t('crm.fields.computed_status.values.approved'),
        filter: ['active', 'approved', 'pending'],
      }, {
        value: 'running',
        label: this.$t('crm.fields.computed_status.values.running'),
        filter: ['active', 'approved', 'running'],
      }, {
        value: 'paused',
        label: this.$t('crm.fields.computed_status.values.paused'),
        filter: ['active', 'approved', 'paused'],
      }, {
        value: 'completed',
        label: this.$t('crm.fields.computed_status.values.completed'),
        filter: ['active', 'approved', 'completed'],
      }, {
        value: 'archived',
        label: this.$t('crm.fields.computed_status.values.archived'),
        filter: ['archived'],
      }];
    },
    combinedFilterMetaIndex() {
      const result = {};
      this.combinedFilterMeta.forEach((item) => {
        result[item.value] = item;
      });
      return result;
    },
    statusMetaFiltered() {
      return this.getStatusMetaForEntities('activity');
    },
    setFilterStatus() {
      const timeout = 0; // 500 для мультиселекта
      return this.$_.debounce((value) => {
        this.filterStatus = value;
        this.getActivities();
      }, timeout);
    },
    // фильтр для мультиселекта, раскомментить в случае необходимости
    // filters: {
    //   get() {
    //     return this.currentViewMeta.staticFilter || this.getStatusGroupFromList(this.filterStatus);
    //   },
    //   set(value) {
    //     if (!this.$_.isEmpty(value) && !this.currentViewMeta.staticFilter) {
    //       const keys = Object.keys(value);
    //       this.filterStatus = keys
    //         .map(key => value[key])
    //         .reduce((acc, item) => acc.concat(item), []);

    //       this.pFilterStatus = this.filterStatus;
    //     }
    //   },
    // },
    filters: {
      get() {
        return {
          status: this.combinedFilter,
          onlyMine: this.onlyMine,
        };
      },
      set(value) {
        if (!this.$_.isEmpty(value)) {
          this.combinedFilter = value.status;
          this.onlyMine = value.onlyMine;
        }
      },
    },
    pFilters() {
      return this.getStatusGroupFromList(this.pFilterStatus);
    },
    queryParams() {
      const filters = this.currentViewMeta.staticFilter || this.pFilters;

      return {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        search: this.search || null,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        site_id: this.currentBrand,
        only_mine: this.onlyMine || undefined,
        ...filters,
      };
    },
    actionTypesIndex() {
      const result = {};

      this.data.forEach((activity) => {
        const types = [];

        if (Array.isArray(activity.action_groups)) {
          activity.action_groups.forEach((actionGroup) => {
            if (Array.isArray(actionGroup.actions)) {
              actionGroup.actions.forEach((action) => {
                if (types.indexOf(action.type) === -1) {
                  types.push(action.type);
                }
              });
            }
          });
        }
        result[activity.id] = types;
      });

      return result;
    },
    viewsMeta() {
      return [
        {
          name: 'activities',
          staticFilter: null,
          title: this.$t('crm.activities.title'),
          hasCreateButton: true,
        },
        {
          name: 'running',
          staticFilter: {
            status: ['active'],
            moderation_status: ['approved'],
            launch_status: ['running'],
          },
          title: this.$t('crm.activities.title_running'),
          hasCreateButton: false,
          from: 'running',
        },
        {
          name: 'quality-assurance-activities',
          staticFilter: {
            status: ['active'],
            moderation_status: ['on_review'],
          },
          title: this.$t('crm.activities.title_quality_assurance'),
          hasCreateButton: false,
          from: 'quality_assurance',
        },
      ];
    },
    viewsMetaIndex() {
      const result = {};
      this.viewsMeta.forEach((item) => {
        result[item.name] = item;
      });
      return result;
    },
    currentViewMeta() {
      return this.viewsMetaIndex[this.view] || this.viewsMetaIndex.activities;
    },
    view() {
      return this.$route.meta.view;
    },
  },
  watch: {
    async currentBrand() {
      await this.getActivities();
    },
    view() {
      this.addToHistory();
    },
  },

  methods: {
    refreshTable() {
      this.fetch();
    },
    // @Override
    generateQuery() {
      if (!this.currentViewMeta.staticFilter) {
        return queryParamsMixin.methods.generateQuery.call(this);
      }

      const params = {};

      if (this.limit) {
        params.limit = this.limit;
      }

      if (this.page) {
        params.page = this.page;
      }

      if (this.search) {
        params.search = this.search;
      }

      if (this.sortProp) {
        params.sort_column = this.sortProp;
      }

      if (this.sortOrder) {
        params.sort_dir = this.sortOrder;
      }

      if (this.onlyMine) {
        params.only_mine = this.onlyMine;
      }

      // фильтр отсутствует т.к. он статический

      return params;
    },
    fetch() {
      return this.getActivities();
    },

    async getActivities() {
      this.isDataLoading = true;
      try {
        const { data: { payload, misc } } = await this.$api.getActivities(this.queryParams);
        this.data = payload;
        this.permissions = this.$_.clone(misc.permissions);
        this.count = misc.count || 0;
      } catch (error) {
        // console.error(error);
      } finally {
        this.isDataLoading = false;
      }
    },

    paginationHandler(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;

        this.getActivities();
      }
    },

    sortingHandler({ prop, order }) {
      if (order === '') return;

      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.getActivities();
    },

    renderActions(row) {
      const h = this.$createElement;

      const finalActions = this.actionTypesIndex[row.id].map(action => h(CrmTag, {
        props: {
          preset: `notify-${action}`,
        },
        class: 'ui-g-sm',
      }));

      return h('div', {
        class: 'ui-g-w-sm',
      }, finalActions);
    },

    getActivityLink(row) {
      const params = this.currentViewMeta.from
        ? `?from=${this.currentViewMeta.from}`
        : '';

      return `/crm/activities/${row.id}${params}`;
    },

    handleAddActivity() {
      this.$router.push('/crm/activities');
    },

    handleClone(row) {
      this.confirm = this.confirmIndex.clone(row, () => this.cloneActivity(row.id));
      this.$refs.confirm.open();
    },

    async cloneActivity(id) {
      if (this.isDataLoading) return;

      this.isDataLoading = true;

      try {
        const { data } = await this.$api.postActivityStatus(id, 'clone');
        this.$router.push({
          name: 'crm-activity',
          params: {
            id: data.payload.id,
            isEdit: true,
          },
        });
      } finally {
        this.$refs.confirm.close();
        this.isDataLoading = false;
      }
    },

    handleDelete(row) {
      this.confirm = this.confirmIndex.archive(row, () => this.deleteActivity(row.id));
      this.$refs.confirm.open();
    },

    async deleteActivity(id) {
      if (this.isDataLoading) return;

      this.isDataLoading = true;

      try {
        await this.$api.postActivityStatus(id, 'archive');
        await this.getActivities();
      } finally {
        this.$refs.confirm.close();
        this.isDataLoading = false;
      }
    },

    setSanitizedFilterStatus(value) {
      const sanitizedValue = this.sanitizeStatus(value);
      this.pFilterStatus = sanitizedValue;
      this.setFilterStatus(sanitizedValue);
    },

    getDefaultFilterStatus() {
      return [
        'draft',
        'active',
      ];
    },

    canEdit(id) {
      return this.isSuperUser || this.permissions[id].can_edit;
    },
  },
};
</script>
