<template>
  <ui-popup-col
    ref="columnsPopup"
    :width="1300"
    :title="$t('reports.colunms_settings.header')"
    :actions-disabled="actionsDisabled"
    class="columns-popup"
    @reset="resetColumnsOrder"
    @save="applyColumnsSettings"
    @cancel="cancelColumnsSettings"
  >
    <div :class="{ 'is-show': showSelectAllCol }" class="columns-popup__wr">
      <div class="columns-popup__left">
        <div class="columns-popup__title">
          {{ $t('reports.colunms_settings.title_visible') }}
        </div>
        <div class="controls">
          <ui-input
            v-model="searchColumns"
            :placeholder="$t('reports.colunms_settings.placeholder_search')"
            width="100%"
            pre-icon="search"
            class="search-input"
          />
        </div>
        <div class="controls controls-padding">
          <div
            v-if="!searchColumns"
            :class="{ checked: selectAllCol }"
            class="checkbox checkbox-custom"
            @click="
              selectAllCol = !selectAllCol;
              selectAllColumns(selectAllCol, 'all');
            "
          >
            <div class="box">
              <ui-icon
                :name="'check'"
                :size="8"
                lib="fa"
                substyle="fas"
                class="chk-icon"
              />
            </div>
            <span class="label">{{ $t('reports.colunms_settings.select_all') }}</span>
          </div>
          <div
            v-if="searchColumns"
            :class="{ checked: selectSearchCol }"
            class="checkbox checkbox-custom"
            @click="
              selectSearchCol = !selectSearchCol;
              selectAllColumns(selectSearchCol, 'search');
            "
          >
            <div class="box">
              <ui-icon
                :name="'check'"
                :size="8"
                lib="fa"
                substyle="fas"
                class="chk-icon"
              />
            </div>
            <span class="label">{{ $t('reports.colunms_settings.select_match') }}</span>
          </div>
          <div
            :class="{ checked: showSelectAllCol }"
            class="checkbox checkbox-custom"
            @click="showSelectAllCol = !showSelectAllCol"
          >
            <div class="box">
              <ui-icon
                :name="'check'"
                :size="8"
                lib="fa"
                substyle="fas"
                class="chk-icon"
              />
            </div>
            <span class="label">{{ $t('reports.colunms_settings.show_only_select') }}</span>
          </div>
        </div>
        <el-collapse v-model="activeNames">
          <div v-if="noData" class="field__empty-text">
            <ui-icon
              :size="32"
              name="folder-open"
              lib="fa"
              substyle="fal"
              color="#a6b2c4"
            />
            <div>{{ $t('reports.colunms_settings.no_data') }}</div>
          </div>

          <el-collapse-item
            v-for="(group, index) in columnGroup.filter((e) => calcAll(e))"
            :key="index"
            :class="{
              'show-only-select': showSelectAllCol && calcExpand(group.group_name) === 0,
              'hide-search': arrayHideSearch[index] === 0,
            }"
            :name="group.group_name"
          >
            <template slot="title">
              <div class="columns-popup__col-header">
                <div class="columns-popup__sub-wr">
                  <div
                    v-if="!searchColumns"
                    :class="{ checked: selectBlocks[index] || calcExpand(group.group_name) === calcAll(group) }"
                    class="checkbox checkbox-custom"
                    @click.stop="
                      selectAllBlock(group.group_name, index, 'all');
                      checkSelectAll();
                    "
                  >
                    <div class="box">
                      <ui-icon :name="'check'" :size="8" lib="fa" substyle="fas" class="chk-icon" />
                    </div>
                  </div>
                  <div
                    v-if="searchColumns"
                    :class="{ checked: selectBlocksSearch[index] }"
                    class="checkbox checkbox-custom"
                    @click.stop="
                      selectBlocksSearch[index] = !selectBlocksSearch[index];
                      selectAllBlock(group.group_name, index, 'search');
                      checkSearchAll();
                    "
                  >
                    <div class="box">
                      <ui-icon :name="'check'" :size="8" lib="fa" substyle="fas" class="chk-icon" />
                    </div>
                  </div>
                  <span>
                    <span class="columns-popup__sub-title">
                      {{ getTranslateGroup(group.group_name) }}
                    </span>
                    <span class="columns-popup__count-col">{{ calcExpand(group.group_name) }} /
                      {{ calcAll(group) }}
                      {{ $t('reports.colunms_settings.colunms') }}
                    </span>
                  </span>
                </div>
                <span class="columns-popup__count-col is-right">{{
                  $t(`reports.colunms_settings.${callapseLabel(group.group_name)}`)
                }}</span>
              </div>
            </template>

            <div class="wrap-item">
              <div
                v-for="(column, key) in $_.filter(group.items, (column) => {
                  return (
                    getTranslateMetrics(column.name)
                      .title.toLowerCase()
                      .indexOf(searchColumns.toLowerCase()) > -1 ||
                    getTranslateMetrics(column.name)
                      .description.toLowerCase()
                      .indexOf(searchColumns.toLowerCase()) > -1 ||
                    filtrableInner(group.group_name, column.name)
                  );
                })"
                :key="key"
              >
                <div
                  :class="{
                    'show-only-select': !watchSelectedMain(column.name),
                  }"
                  class="item"
                >
                  <div
                    :class="{ checked: watchSelectedMain(column.name) }"
                    class="checkbox checkbox-custom"
                    @click="
                      showSelectedMain(column.name);
                      checkBlock(group.group_name, index, searchColumns ? 'search' : 'all');
                      checkSelectAll();
                      checkSearchAll();
                    "
                  >
                    <div class="box">
                      <ui-icon :name="'check'" :size="8" lib="fa" substyle="fas" class="chk-icon" />
                    </div>
                    <span class="label">{{ getTranslateMetrics(column.name).title }}</span>
                  </div>
                  <span class="description">
                    {{ getTranslateMetrics(column.name).description }}
                  </span>
                </div>

                <div v-if="$_.has(column, 'items')">
                  <div
                    v-for="(subcolumn, subkey) in $_.filter(column.items, (subcolumn) => {
                      return (
                        getTranslateMetrics(subcolumn.name)
                          .title.toLowerCase()
                          .indexOf(searchColumns.toLowerCase()) > -1 ||
                        getTranslateMetrics(subcolumn.name)
                          .description.toLowerCase()
                          .indexOf(searchColumns.toLowerCase()) > -1
                      );
                    })"
                    :key="subkey"
                    :class="{
                      'show-only-select': !watchSelectedMain(subcolumn.name),
                    }"
                    class="item item-sub"
                  >
                    <div
                      :class="{ checked: watchSelectedMain(subcolumn.name) }"
                      class="checkbox checkbox-custom"
                      @click="
                        showSelectedMain(subcolumn.name);
                        checkBlock(group.group_name, index, searchColumns ? 'search' : 'all');
                        checkSelectAll();
                        checkSearchAll();
                      "
                    >
                      <div class="box">
                        <ui-icon :name="'check'" :size="8" lib="fa" substyle="fas" class="chk-icon" />
                      </div>
                      <span class="label">{{ getTranslateMetrics(subcolumn.name).title }}</span>
                    </div>
                    <span class="description">
                      {{ getTranslateMetrics(subcolumn.name).description }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="columns-popup__right">
        <div class="columns-popup__title">
          {{ $t('reports.colunms_settings.title_order') }}
        </div>
        <div class="columns-popup__col-header">
          <span class="columns-popup__sub-title">{{ $t('reports.colunms_settings.vis_column') }}</span>
          <span class="columns-popup__count-col">
            {{ calcColumn().visible.length }} {{ $t('reports.colunms_settings.colunms') }}
          </span>
        </div>
        <div class="list">
          <draggable :list="allMetricsClone">
            <div
              v-for="(column, index) in allMetricsClone"
              :key="index"
              :class="{
                'hid-row': column.shadowSelected === false && showOnlySelected === true,
              }"
              class="item"
            >
              <div :class="{ checked: column.shadowSelected }" class="checkbox checkbox-custom">
                <span class="label">{{ getTranslateMetrics(column.name).title }}</span>
              </div>

              <span style="display: flex; align-item: center" class="description">
                {{ getTranslateMetrics(column.name).description }}
              </span>

              <ui-icon
                :name="'handle'"
                :size="5"
                color="#a6b2c4"
                class="handle"
              />
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </ui-popup-col>
</template>

<script>
import draggable from 'vuedraggable';
import { setTimeout } from 'timers';
import Config from '@/views/Reports/reportSettings';
import formatCurrencyMixin from '@/views/mixins/format-currency';

export default {
  name: 'CustomReportColumnPopup',

  components: {
    draggable,
  },
  mixins: [formatCurrencyMixin],

  props: {
    currency: {
      type: String,
      default: '',
    },
    reportsSettings: {
      type: Object,
      default() {
        return {};
      },
    },
    groupBy: {
      type: String,
      default: '',
    },
    columnGroup: {
      type: Array,
      default() {
        return [];
      },
    },
    objGroup: {
      type: Object,
      default() {
        return {};
      },
    },
    allMetrics: {
      type: Array,
      default() {
        return [];
      },
    },
    allMetricsStatic: {
      type: Array,
      default() {
        return [];
      },
    },
    allMetricsClone: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      actionsDisabled: false,
      searchColumns: '',
      selectAllCol: false,
      selectSearchCol: false,
      defaultColumn: [],
      showOnlySelected: true,
      activeNames: [],
      selectAllRaising: false,
      selectAllDeposit: false,
      selectBlocks: [],
      selectBlocksSearch: [],
      showSelectAllCol: false,
      arrayHideSearch: [1, 1, 1, 1, 1, 1, 1, 11, 1, 1, 1, 1, 1, 1, 1, 1],
      noData: false,
      foundColumns: [],
    };
  },

  watch: {
    searchColumns(v) {
      setTimeout(() => {
        const el = this.$el.querySelectorAll('.el-collapse-item.hide-search').length;
        this.noData = el === this.columnGroup.length;
      });
      this.foundColumns = this.searchColumnsArray(v);

      this.activeNames = Object.keys(this.objGroup);
      this.hideCollapseSearch();
      this.checkBlockSearch();
      this.checkSearchAll();
    },
    allMetricsClone: {
      deep: true,
      handler(v) {
        this.actionsDisabled = !v.some(e => e.selected === true);
      },
    },
  },
  methods: {
    getTranslateGroup(groupName) {
      return this.reportsSettings.metrics_groups.find(e => e.group_name === groupName).title;
    },

    getTranslateMetrics(metric) {
      return this.formatMetric(this.reportsSettings.metrics.find(e => e.column_name === metric), this.currency);
    },

    checkSearchAll() {
      if (this.foundColumns.length === 0) {
        this.selectSearchCol = false;
        return;
      }
      this.selectSearchCol = this.foundColumns.every((el) => {
        let a = true;
        this.allMetricsClone.forEach((e) => {
          if (e.name === el) {
            a = e.selected;
          }
        });
        return a;
      });
      if (this.selectSearchCol) {
        this.selectBlocksSearch.forEach((e, i) => {
          this.selectBlocksSearch[i] = true;
        });
      }
    },

    searchColumnsArray(v) {
      const headCol = [];
      this.$_.cloneDeep(this.columnGroup).forEach((group) => {
        headCol.push(
          ...this.$_.filter(
            group.items,
            column => this.getTranslateMetrics(column.name)
              .title.toLowerCase()
              .indexOf(v.toLowerCase()) > -1
              || this.getTranslateMetrics(column.name)
                .description.toLowerCase()
                .indexOf(v.toLowerCase()) > -1
              || this.filtrableInner(group.group_name, column.name),
          ),
        );
      });
      const allColumnSearch = [];
      headCol.forEach((column) => {
        allColumnSearch.push(column.name);
        if (this.$_.has(column, 'items') && this.$_.isArray(column.items)) {
          column.items.filter(child => this.getTranslateMetrics(child.name)
            .title.toLowerCase()
            .indexOf(v.toLowerCase()) > -1
            || this.getTranslateMetrics(child.name)
              .description.toLowerCase()
              .indexOf(v.toLowerCase()) > -1).forEach((items) => {
            allColumnSearch.push(items.name);
          });
        }
      });
      return allColumnSearch;
    },

    filtrableInner(group, name) {
      const s = this.$_.find(this.columnGroup, o => this.$_.find(o.items, e => e.name === name));
      if (!s) return false;

      const gg = this.$_.filter(s.items, item => item.name === name);
      const fin = this.$_.filter(
        gg[0].items,
        el => this.getTranslateMetrics(el.name)
          .title.toLowerCase()
          .indexOf(this.searchColumns.toLowerCase()) > -1
          || this.getTranslateMetrics(el.name)
            .description.toLowerCase()
            .indexOf(this.searchColumns.toLowerCase()) > -1,
      );
      return fin.length !== 0;
    },

    hideCollapseSearch() {
      if (this.searchColumns !== '') {
        this.arrayHideSearch = [];
        this.$nextTick(() => {
          this.$el.querySelectorAll('.wrap-item').forEach((e) => {
            this.arrayHideSearch.push(e.childElementCount);
          });
        });
      } else {
        this.arrayHideSearch = Array(this.columnGroup.length).fill(1);
      }
    },

    resetColumnsOrder() {
      this.$emit('reset');
      this.selectAllCol = false;
      this.selectBlocks = Array(this.selectBlocks.length).fill(false);
      this.selectBlocksSearch = Array(this.selectBlocksSearch.length).fill(false);
    },

    applyColumnsSettings() {
      this.allMetricsClone.forEach((column, index) => {
        column.order = index;
        column.selected = column.shadowSelected;
      });
      this.$emit('save', this.allMetricsClone);
    },
    cancelColumnsSettings() {
      this.$emit('cancel');
    },

    open() {
      this.defaultCheckStatic();
      this.$refs.columnsPopup.open();
      this.columnGroup.forEach((e) => {
        if (this.activeNames.length === 0) {
          this.activeNames.push(e.group_name);
        }
      });

      this.columnGroup.forEach((e, i) => {
        this.columnGroup[i].columns = this.$_.filter(this.columnGroup[i].columns, (ge) => {
          let flag = false;
          Config.allMetrics.forEach((el) => {
            if (ge.column_name === el.name) flag = true;
          });
          return flag;
        });
      });
      this.columnGroup.forEach((e, i) => {
        this.columnGroup[i].columns.forEach((ge, j) => {
          if (this.$_.has(ge, 'childs')) {
            this.columnGroup[i].columns[j].childs = this.$_.filter(ge.childs, (ch) => {
              let flag = false;
              Config.allMetrics.forEach((el) => {
                if (ch.column_name === el.name) flag = true;
              });
              return flag;
            });
          }
        });
      });

      this.columnGroup
        .filter(el => !!el.items.length)
        .forEach(({ group_name }, i) => {
          const selected = this.objGroup[group_name]
            .every(col => this.allMetricsClone.find(metric => metric.name === col)?.selected);

          this.selectBlocks[i] = selected;
          this.selectBlocksSearch[i] = selected;
        });

      this.selectAllCol = this.selectBlocks.every(el => !!el);
    },
    cancel() {
      this.searchColumns = '';
      this.$refs.columnsPopup.cancel();
    },
    close() {
      this.searchColumns = '';
      this.$refs.columnsPopup.close();
    },
    callapseLabel(group) {
      return this.activeNames.indexOf(group) === -1 ? 'show' : 'hide';
    },
    calcAll(group) {
      const calcFn = items => this.$_.filter(items, column => (
        this.getTranslateMetrics(column.name)
          .title.toLowerCase()
          .indexOf(this.searchColumns.toLowerCase()) > -1
        || this.getTranslateMetrics(column.name)
          .description.toLowerCase()
          .indexOf(this.searchColumns.toLowerCase()) > -1
        || this.filtrableInner(group.group_name, column.name)
      )).reduce((acc, el) => {
        if (el.items?.length) {
          return acc + 1 + calcFn(el.items);
        }

        if (this.foundColumns.length && !this.foundColumns.includes(el.name)) {
          return acc;
        }

        return acc + 1;
      }, 0);

      return calcFn(group?.items || []);
    },
    calcExpand(group) {
      let incr = 0;
      this.allMetricsClone.forEach((e) => {
        if (e.group === group && e.selected && (!this.foundColumns.length || this.foundColumns.includes(e.name))) {
          incr += 1;
        }
      });
      return incr;
    },
    checkSelectAll() {
      const a = this.selectBlocks.some(e => e === false);
      if (a) this.selectAllCol = !a;

      const b = this.selectBlocks.every(e => e === true);
      if (b) this.selectAllCol = b;
    },
    calcColumn() {
      const a = {
        visible: [],
        raising: [],
        deposit: [],
      };
      this.allMetricsClone.forEach((e) => {
        if (e.selected) {
          a.visible.push(e.selected);
        }
        if (e.selected && e.group === 'raising') {
          a.raising.push(e.selected);
        }
        if (e.selected && e.group === 'deposit') {
          a.deposit.push(e.selected);
        }
      });

      return a;
    },
    checkBlock(group, index, type) {
      const ar = [];
      if (type === 'all') {
        this.allMetricsClone.forEach((e) => {
          if (e.group === group && e.selected === true) {
            ar.push(e.name);
          }
        });

        if (ar.length === this.objGroup[group].length) {
          this.selectBlocks[index] = true;
        } else {
          this.selectBlocks[index] = false;
        }
      }
      if (type === 'search') {
        this.allMetricsClone.forEach((e) => {
          if (e.group === group && e.selected === true) {
            this.foundColumns.forEach((el) => {
              if (el === e.name) {
                ar.push(e.name);
              }
            });
          }
        });
        const arrS = this.$_.filter(this.objGroup[group], (col) => {
          let flag = false;
          this.foundColumns.forEach((e) => {
            if (e === col) {
              flag = true;
            }
          });
          return flag;
        });
        if (ar.length === arrS.length) {
          this.selectBlocksSearch[index] = true;
        } else {
          this.selectBlocksSearch[index] = false;
        }
      }
    },
    checkBlockSearch() {
      const ar = [];
      Object.keys(this.objGroup).forEach((elemGroup, index) => {
        this.allMetricsClone.forEach((e) => {
          if (e.group === elemGroup && e.selected === true) {
            this.foundColumns.forEach((el) => {
              if (el === e.name) {
                ar.push(e.name);
              }
            });
          }
        });
        const arrS = this.$_.filter(this.objGroup[elemGroup], (col) => {
          let flag = false;
          this.foundColumns.forEach((e) => {
            if (e === col) {
              flag = true;
            }
          });
          return flag;
        });
        if (ar.length === arrS.length) {
          this.selectBlocksSearch[index] = true;
        } else {
          this.selectBlocksSearch[index] = false;
        }
      });
    },
    checkSelectColumn(checked, num) {
      this.allMetricsClone.forEach((e) => {
        if (e.defaultOrder === num) {
          e.selected = checked;
          e.shadowSelected = checked;
        }
      });
    },
    watchSelectedMain(col) {
      let a = false;
      this.$_.forEach(this.allMetricsClone, (e) => {
        if (e.name === col) {
          a = e.shadowSelected;
        }
      });
      return a;
    },
    showSelectedMain(col) {
      this.allMetricsClone.forEach((e) => {
        if (e.name === col) {
          e.selected = !e.selected;
          e.shadowSelected = !e.shadowSelected;
        }
      });
    },
    triggerSelectedBlock(col, v, type) {
      if (type === 'all') {
        this.allMetricsClone.forEach((e) => {
          if (e.name === col) {
            e.selected = v;
            e.shadowSelected = v;
          }
        });
      }
      if (type === 'search') {
        this.allMetricsClone.forEach((e) => {
          if (e.name === col) {
            this.foundColumns.forEach((el) => {
              if (el === col) {
                e.selected = v;
                e.shadowSelected = v;
              }
            });
          }
        });
      }
    },
    defaultCheckStatic() {
      this.selectAllCol = true;
      this.allMetricsClone.forEach((el) => {
        if (el.selected === false) {
          this.selectAllCol = false;
        }
        this.allMetricsStatic.forEach((e) => {
          if (el.defaultOrder === e.defaultOrder) {
            e.selected = el.selected;
            e.shadowSelected = el.shadowSelected;
          }
        });
      });
    },
    selectAllBlock(block, i, type) {
      const value = !this.selectBlocks[i];
      this.selectBlocks[i] = value;

      this.objGroup[block].forEach((e) => {
        this.triggerSelectedBlock(e, value, type);
      });
    },
    selectAllColumns(v, type) {
      if (type === 'all') {
        this.allMetricsClone.forEach((column) => {
          column.shadowSelected = v;
          column.selected = v;
        });
        this.selectBlocks.forEach((e, i) => {
          this.selectBlocks[i] = v;
        });
      }
      if (type === 'search') {
        if (v) {
          this.foundColumns.forEach((col) => {
            this.allMetricsClone.forEach((column) => {
              if (col === column.name) {
                column.shadowSelected = v;
                column.selected = v;
              }
            });
          });
          this.selectBlocksSearch.forEach((e, i) => {
            this.selectBlocksSearch[i] = v;
          });
        } else {
          this.foundColumns.forEach((col) => {
            this.allMetricsClone.forEach((column) => {
              if (col === column.name) {
                column.shadowSelected = v;
                column.selected = v;
              }
            });
          });
          this.selectBlocksSearch.forEach((e, i) => {
            this.selectBlocksSearch[i] = v;
          });
        }
      }
    },
  },
};
</script>
