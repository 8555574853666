<template>
  <div class="body-notes">
    <div
      :style="{height: maxHeight}"
      class="card"
    >
      <div class="title">
        <span>{{ $t(`affiliates.cardView.notes.title`) }}</span>
        <span
          v-if="!isAddingNewNote && addNotePermission && currentNotesType !== 'system'"
          :style="{color: $theme.accentColor}"
          class="action"
          @click="addNote()"
        >
          <ui-icon
            name="plus"
            :color="$theme.accentColor"
            substyle="fas"
            lib="fa"
          />
          {{ $t(`affiliates.cardView.notes.addNote`) }}
        </span>
      </div>
      <div
        ref="scroll"
        class="card-content without-padding"
      >
        <div class="field notes">
          <div class="notes-tabs">
            <el-radio-group
              v-model="currentNotesType"
              size="small"
            >
              <el-radio-button
                v-for="item in notesTypes"
                :key="item"
                :label="item"
              >
                {{ $t(`affiliates.cardView.notes.types.${item}`) }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <div
            v-if="isAddingNewNote"
            class="add-note"
          >
            <el-input
              ref="textareaNoteNew"
              v-model="newNote.text"
              :class="[newNote.tag_ids[0], !$_.isEmpty(errors) ? 'el-textarea_error' : '']"
              :placeholder="$t(`affiliates.cardView.notes.newNote`)"
              :rows="3"
              :error="errors.text"
              resize="none"
              type="textarea"
            />
            <div
              v-if="!$_.isEmpty(errors)"
              class="error-content"
            >
              {{ getError('text') }}
            </div>
            <div class="add-note-controls">
              <div class="note-tags">
                <el-radio
                  v-for="(item, index) in tags"
                  :key="index"
                  v-model="newNote.tag_ids[0]"
                  :class="item.content"
                  :label="item.content"
                />
              </div>
              <div class="note-buttons">
                <ui-button
                  color="red"
                  class="btn-red"
                  icon="times"
                  lib="fa"
                  substyle="fas"
                  @click="cancelNote"
                >
                  {{ $t(`team_popup.cancel_button`) }}
                </ui-button>
                <ui-button
                  color="green"
                  class="btn action-btn"
                  lib="fa"
                  filled
                  substyle="fas"
                  icon="check"
                  @click="saveNote"
                >
                  {{ $t(`team_popup.save_button`) }}
                </ui-button>
              </div>
            </div>
          </div>
          <div
            v-if="notesList === false"
            class="field__empty-text"
          >
            <ui-icon
              :size="32"
              name="folder-open"
              lib="fa"
              substyle="fal"
              color="#a6b2c4"
            />
            <div>{{ $t(`affiliates.cardView.notes.noNotes`) }}</div>
          </div>
          <div
            v-for="(item, index) in notesList"
            v-else
            :key="index"
            class="note-block"
          >
            <div
              v-show="editingNote !== index"
              :class="item.tags[0] ? 'note-wrapper_'+item.tags[0].content : ''"
              class="note-wrapper"
            >
              <div
                v-show="isEditNotesPermission(item.id) && item.type !== 'system'"
                class="note-header_actions"
              >
                <span
                  v-if="item.pinned"
                  class="action"
                  @click="updateNote('pin_action', item)"
                >
                  <ui-icon
                    name="unlink"
                    :color="item.tags[0] && item.tags[0].content === 'red' ? '#ffffff' : $theme.accentColor"
                    lib="fa"
                  />
                </span>
                <span
                  v-else
                  class="action"
                  @click="updateNote('pin_action', item)"
                >
                  <ui-icon
                    name="map-pin"
                    :color="item.tags[0] && item.tags[0].content === 'red' ? '#ffffff' : $theme.accentColor"
                    lib="fa"
                  />
                </span>
                <span
                  class="action"
                  @click="editNote(index, item)"
                >
                  <ui-icon
                    name="pen"
                    :color="item.tags[0] && item.tags[0].content === 'red' ? '#ffffff' : $theme.accentColor"
                    lib="fa"
                  />
                </span>
                <span
                  class="action"
                  @click="deleteNote(item)"
                >
                  <ui-icon
                    name="times"
                    :color="item.tags[0] && item.tags[0].content === 'red' ? '#ffffff' : $theme.accentColor"
                    lib="fa"
                  />
                </span>
              </div>
              <div
                v-if="item.admin_name"
                class="note-header"
              >
                <div class="note-header_author">
                  <ui-icon
                    :name="item.type === 'user' ? 'user' : 'cog'"
                    :color="item.tags[0] && item.tags[0].content === 'red' ? '#ffffff' : '#7d7d7d'"
                    substyle="far"
                    lib="fa"
                  />
                  <span class="auth">{{ item.type === 'user' ? item.admin_name : $t(`affiliates.cardView.info.system`) }}</span>
                </div>
              </div>
              <div class="note-header">
                <div class="note-header_time">
                  <ui-icon
                    name="calendar-alt"
                    :color="item.tags[0] && item.tags[0].content === 'red' ? '#ffffff' : $theme.accentColor"
                    substyle="far"
                    lib="fa"
                  />
                  <span class="time">
                    {{ item.created_at | formatDate3 }}
                  </span>
                  <ui-icon
                    v-if="item.pinned"
                    name="map-pin"
                    :color="item.tags[0] && item.tags[0].content === 'red' ? '#ffffff' : $theme.accentColor"
                    substyle="far"
                    lib="fa"
                  />
                </div>
              </div>
              <div class="note-text">
                {{ item.text }}
              </div>
            </div>
            <div
              v-show="editingNote === index"
              class="note-edit"
            >
              <div class="add-note">
                <el-input
                  ref="textareaNote"
                  v-model="newNote.text"
                  :class="[newNote.tag_ids[0], !$_.isEmpty(errors) ? 'el-textarea_error' : '']"
                  :placeholder="$t(`affiliates.cardView.notes.newNote`)"
                  :rows="3"
                  resize="none"
                  type="textarea"
                />
                <div
                  v-if="!$_.isEmpty(errors)"
                  class="error-content"
                >
                  {{ $t(`validation.errors.${errors.text[0].code}`) }}
                </div>
                <div class="add-note-controls">
                  <div class="note-tags">
                    <el-radio
                      v-for="(el, key) in tags"
                      :key="key"
                      v-model="newNote.tag_ids[0]"
                      :class="el.content"
                      :label="el.content"
                    />
                  </div>
                  <div class="note-buttons">
                    <ui-button
                      color="red"
                      class="btn-red"
                      icon="times"
                      lib="fa"
                      substyle="fas"
                      @click="cancelNote"
                    >
                      {{ $t(`team_popup.cancel_button`) }}
                    </ui-button>
                    <ui-button
                      color="green"
                      class="btn action-btn"
                      lib="fa"
                      filled
                      substyle="fas"
                      icon="check"
                      @click="updateNote('edit_action', newNote)"
                    >
                      {{ $t(`team_popup.save_button`) }}
                    </ui-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import errorHandleMixin from '@/views/mixins/error-hadle';

export default {
  name: 'Notes',
  mixins: [
    errorHandleMixin,
  ],
  props: {
    affiliateId: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: String,
      default: '',
    },
    addNotePermission: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editingNote: -1,
      whiteElem: {
        content: 'white',
        id: '',
        type: 'colourful_circle',
      },
      notesTypes: ['all', 'user', 'system'],
      currentNotesType: 'all',
      availableNoteTags: ['white', 'green', 'red', 'yellow'],
      newNote: {
        text: '',
        tag_ids: [],
      },
      isAddingNewNote: false,
      notes: [],
      notesPermissions: [],
      errors: {},
    };
  },

  computed: {
    tags() {
      const tempArray = this.$_.cloneDeep(this.noteTags);
      tempArray.unshift(this.whiteElem);
      return tempArray.filter(item => this.availableNoteTags.indexOf(item.content) > -1);
    },
    notesList() {
      if (!this.$_.isEmpty(this.notes)) {
        if (this.currentNotesType === 'all') {
          return this.notes;
        }
        return this.$_.filter(this.notes, ['type', this.currentNotesType]).length > 0 ? this.$_.filter(this.notes, ['type', this.currentNotesType])
          : false;
      }
      return false;
    },
    ...mapGetters({
      noteTags: 'misc/noteTags',
    }),
  },

  watch: {
    affiliateId() {
      this.getNotes(this.affiliateId);
    },
  },

  created() {
    this.$eventBus.$on('delete-note-component', note => this.deleteNoteComponent(note));
    this.$eventBus.$on('update-note-component', id => this.getNotes(id));
  },

  mounted() {
    this.getNotes(this.affiliateId);
  },

  beforeDestroy() {
    this.$eventBus.$off('delete-note-component');
    this.$eventBus.$off('update-note-component');
  },

  methods: {
    deleteNote(note) {
      this.$eventBus.$emit('delete-note', note);
    },
    deleteNoteComponent(note) {
      this.$api
        .deleteAffiliateNote(note.id)
        .then(() => {
          this.getNotes(this.affiliateId);
        });
    },
    isEditNotesPermission(id) {
      return this.notesPermissions[id].edit;
    },
    getNotes(id) {
      this.$api.getAffiliateNotes(id)
        .then((response) => {
          this.notes = this.$_.cloneDeep(response.data.payload);
          this.notesPermissions = this.$_.cloneDeep(response.data.misc.permissions);
          this.cancelNote();
        });
    },
    saveNote() {
      const tags_val = [];
      if (this.newNote.tag_ids[0] !== 'white') {
        tags_val.push(this.$_.find(this.noteTags, { content: this.newNote.tag_ids[0] }).id);
      }
      const q = {
        affiliate_id: this.affiliateId,
        text: this.newNote.text,
        tag_ids: tags_val.length ? tags_val : [],
      };
      this.errors = {};
      this.$api.postAffiliateNote(q)
        .then(() => {
          this.getNotes(this.affiliateId);
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
    cancelNote() {
      this.isAddingNewNote = false;
      this.editingNote = -1;
      this.errors = {};
      this.newNote = {
        text: '',
        tag_ids: ['white'],
      };
    },
    addNote() {
      this.newNote = {
        text: '',
        tag_ids: ['white'],
      };
      this.isAddingNewNote = true;
      this.$nextTick(() => {
        this.$refs.textareaNoteNew.focus();
      });
      this.$refs.scroll.scrollTop = 0;
      this.editingNote = -1;
    },
    editNote(note_index, item) {
      this.isAddingNewNote = false;
      this.editingNote = note_index;
      const tags_val = [];
      if (item.tags[0]) {
        tags_val.push(item.tags[0].content);
      } else {
        tags_val.push('white');
      }
      this.newNote = {
        id: item.id,
        text: item.text,
        pinned: item.pinned,
        tag_ids: tags_val,
      };
      this.$nextTick(() => {
        this.$refs.textareaNote[this.editingNote].focus();
      });
    },
    updateNote(action, note) {
      const q = {
        text: note.text,
        pinned: note.pinned,
        tag_ids: [],
      };
      const tags_val = [];
      if (action === 'pin_action') {
        if (note.tags.length > 0) {
          tags_val.push(this.$_.find(this.noteTags, { content: note.tags[0].content }).id);
        }
        q.pinned = !q.pinned;
      }
      if (action === 'edit_action') {
        if (note.tag_ids.length > 0 && note.tag_ids[0] !== 'white') {
          tags_val.push(this.$_.find(this.noteTags, { content: note.tag_ids[0] }).id);
        }
      }
      q.tag_ids = tags_val.length ? tags_val : [];
      this.errors = {};
      this.$api.putAffiliateNote(note.id, q)
        .then(() => {
          this.getNotes(this.affiliateId);
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
  },
};
</script>
