import numeral from 'numeral';
import i18n from '@/i18n';

const secondsToHumanTime = (value, daysText) => {
  const parsedFloat = parseFloat(value);
  if (Number.isNaN(parsedFloat)) {
    return '00:00:00';
  }
  const seconds = Math.round(parsedFloat);
  if (seconds < 86400) {
    return numeral(seconds).format('00:00:00');
  }
  const fullDays = Math.floor(seconds / 86400);
  const remainingSeconds = seconds % 86400;
  return `${fullDays} ${daysText} ${numeral(remainingSeconds).format('00:00:00')}`;
};

const formatColumn = (value, columnFormat, languageCode = 'en', currency = '$') => {
  switch (columnFormat) {
  case 'money':
    return `${currency}${numeral(parseFloat(value)).format('0,0.00')}`;
  case 'number':
    return numeral(parseInt(value, 10)).format('0,0');
  case 'percent':
    return numeral(parseFloat(value) / 100).format('0,0.00%');
  case 'time':
    return secondsToHumanTime(value, i18n.t('reports.time_days', languageCode));
  default:
    throw new Error(`Unknown format ${columnFormat}`);
  }
};

export default formatColumn;
