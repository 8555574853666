<template>
  <div class="user-card">
    <div class="head">
      <div class="breadcrumbs">
        <router-link
          v-if="teamId"
          :to="`/teams/${teamId}/details/users`"
          class="back-link"
        >
          {{ teamInfo.name }}
        </router-link>
        <router-link
          v-else
          class="back-link"
          to="/teams/all/details/users"
        >
          {{ $t('teams.teams_fields.default_team') }}
        </router-link>
        <span class="divider">></span>
        <span class="email">{{ userInfo.name }}</span>
      </div>
      <div class="title-email">
        <h2>{{ userInfo.name }}</h2>
        <ui-icon
          v-if="userStatus === 'active'"
          :size="16"
          name="check"
          :color="$theme.accentColor"
          lib="fa"
          substyle="fas"
        />
        <ui-icon
          v-else
          :size="16"
          name="times-circle"
          :color="$theme.dangerColor"
          lib="fa"
          substyle="fas"
        />
      </div>
      <div class="sub-nav">
        <nav class="menu">
          <router-link
            v-for="item in nav"
            :key="item.name"
            :to="`/teams/user/${item.link}`"
            :class="{active : isLinkActive(item.name)}"

            class="item"
          >
            <span
              :title="$t(`user_info.sub_nav.${item.name}`)"
              class="link-title"
            >
              {{ $t(`user_info.sub_nav.${item.name}`) }}
            </span>
          </router-link>
        </nav>
      </div>
    </div>
    <router-view
      :user-info.sync="userInfo"
      :user-status="userStatus"
      class="view"
      @change-status="changeStatus"
      @get-user-info="getAdmin"
    />
  </div>
</template>

<script>

import sessionAction from '@/views/mixins/session-action';

export default {
  mixins: [sessionAction],

  data() {
    return {
      nav: [
        {
          name: 'info',
          link: 'info',
        },
      ],
      teamId: '',
      teamInfo: {},
      userInfo: {},
      userStatus: '',
      errors: {},
      currentTab: '',
    };
  },

  watch: {
    $route() {
      this.currentTab = this.$route.matched[2].meta.view;
    },
  },

  mounted() {
    this.onSessionUnlock = this.initData;
    this.initData();
  },
  methods: {
    initData() {
      this.currentTab = this.$route.matched[2].meta.view;
      this.teamId = this.$route.query.team_id ? this.$route.query.team_id : '';
      if (this.teamId) {
        this.getTeam(this.teamId);
      }
      this.getAdmin(this.$route.params.id);
    },
    isLinkActive(name) {
      return this.currentTab === name;
    },

    getTeam(id) {
      this.$api.getTeam(id).then((response) => {
        this.teamInfo = response.data.payload;
      })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    getAdmin(id) {
      this.$api.getAdmin(id).then((response) => {
        this.userInfo = response.data.payload;
        this.userStatus = this.userInfo.account_status;
      })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    changeStatus(val) {
      this.userStatus = val;
    },
  },
};

</script>

<style lang="scss" scoped>
  .user-card {
    .head {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 90%;
      min-width: 1140px;
      padding: 16px 0 0 0;
    }
  }
</style>
