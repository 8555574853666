export default {
  postUpdatePassword(pwd) {
    return this.affiliatesApi.post('/profile/update-password', pwd);
  },
  postSetupTotp(password) {
    return this.affiliatesApi.post('/profile/setup-totp', { password });
  },
  putProfileInfo(data) {
    return this.affiliatesApi.put('/profile', data);
  },
  postLanguage(languageCode) {
    return this.affiliatesApi.post('/profile/update-language', { language_code: languageCode });
  },
  getSessionsData() {
    return this.affiliatesApi.get('/sessions/session-data');
  },
  getSessions() {
    return this.affiliatesApi.get('/sessions');
  },
  deleteSession(id) {
    return this.affiliatesApi.delete(`/sessions/${id}`);
  },
  deleteSessions() {
    return this.affiliatesApi.delete('/sessions');
  },
  getAcl() {
    return this.affiliatesApi.get('/acl');
  },
};
