<template>
  <div class="media-buying-setting-card">
    <div class="card">
      <div class="title">
        <div class="title-content">
          <span>{{
            $t('affiliates.affiliatesInfo.media_buying_setting')
          }}</span>
          <span
            v-if="isEditPermission && hasPermission"
            :style="{color: activeColor}"
            class="action"
            @click="editMediaBuying"
          >
            <ui-icon
              name="pen"
              :color="activeColor"
              lib="fa"
            />
            {{ $t(`affiliates.affiliatesInfo.edit`) }}
          </span>
        </div>
      </div>

      <div class="card-content">
        <div class="field without-padding">
          <div class="card">
            <div class="field field-solo field__access-media-buying">
              <div class="column">
                <el-checkbox
                  :value="mediaBuying.media_buying_enabled"
                  :disabled="!editBuying"
                  class="checkbox"
                >
                  {{ $t(`affiliates.affiliatesInfo.media_buying_access`) }}
                </el-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card-content"
        :style="{'opacity': mediaBuying.media_buying_enabled ? '1' : '0.3'}"
      >
        <div class="card card__panding-balance">
          <div class="field field__panding-balance">
            <span class="label">{{
              $t('affiliates.media_buying_setting_card.pending_balance')
            }}</span>
            <div
              class="values"
              style="font-weight: 500"
              :style="{
                color: activeColor,
              }"
            >
              {{ pendingBalanceCurrency }}{{ mediaBuying.media_buying_pending_balance | formatMoney }}
            </div>
          </div>
        </div>
        <div class="field without-padding">
          <div class="card">
            <div class="field">
              <span class="label">{{ $t('affiliates.media_buying_setting_card.balance_limit') }} </span>
              <div
                class="values"
                :style="{
                  color:
                    autoApproveUnreportedBalance === 'disabled'
                      ? inactiveColor
                      : activeColor,
                }"
              >
                {{ $t(`affiliates.media_buying_setting_card.${autoApproveUnreportedBalance}`) }}
              </div>
            </div>
            <div class="field">
              <span class="label">{{ $t('affiliates.media_buying_setting_card.available_balance') }}</span>
              <div class="values">
                {{
                  autoApproveUnreportedBalance === 'disabled'
                    ? '–'
                    : `${balanceCurrency}${this.$options.filters.formatMoney(mediaBuying.media_buying_balance_limit)}`
                }}
              </div>
            </div>
          </div>
          <div class="card">
            <div class="field">
              <span class="label">{{ $t('affiliates.media_buying_setting_card.auto_approve_post') }}</span>
              <div
                class="values"
                :style="{
                  color:
                    autoApprovePosts === 'disabled'
                      ? inactiveColor
                      : activeColor,
                }"
              >
                {{ $t(`affiliates.media_buying_setting_card.${autoApprovePosts}`) }}
              </div>
            </div>
            <div class="field">
              <span
                v-if="
                  mediaBuying.media_buying_auto_approve_posts_max_amount !==
                    null
                "
                class="label"
              >{{ $t('affiliates.media_buying_setting_card.amount_up_to') }} </span>
              <span v-else class="label">{{ $t('affiliates.media_buying_setting_card.cost_per_player_up') }} </span>
              <div class="values">
                {{
                  autoApprovePosts === 'disabled'
                    ? '–'
                    : mediaBuying.media_buying_auto_approve_posts_max_amount !==
                      null
                      ? `${balanceCurrency}${this.$options.filters.formatMoney(mediaBuying.media_buying_auto_approve_posts_max_amount)}`
                      : `${balanceCurrency}${this.$options.filters.formatMoney(mediaBuying.media_buying_auto_approve_posts_cost_per_player)}`
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ui-confirm -->
    <ui-confirm
      ref="confirmEditMediaBuyingSetting"
      :width="550"
      :type="confirmEditMediaBuyingSetting.type"
      :action-name="confirmEditMediaBuyingSetting.actionName"
      :close-action-name="confirmEditMediaBuyingSetting.closeActionName"
      :action="confirmEditMediaBuyingSetting.action"
      :action-fa-icon="'check'"
      :action-disabled="confirmEditMediaBuyingSetting.disabled"
      class="confirm-edit-fees confirm-edit-media-buying-setting"
      @close="cancelEdit"
      @save="saveEdit"
    >
      <div slot="title" v-html="confirmEditMediaBuyingSetting.title" />
      <div class="body__wrap">
        <div class="title">
          <span>{{ $t(`affiliates.affiliatesInfo.media_buying_access`) }}</span>
          <el-switch
            v-model="mediaBuyingEnabled"
            :active-color="activeColor"
            :inactive-color="inactiveColor"
          />
        </div>

        <div class="select-block select-block__full ui-m-lg-b">
          <span class="label ui-m-sm-b">{{ $t('affiliates.affiliatesInfo.currency.label') }}</span>
          <el-select
            v-model="mediaBuyingBalanceLimitCurrency"
            :class="getError('media_buying_limits_currency_code') && 'error'"
            filterable
            :placeholder="$t('affiliates.affiliatesInfo.currency.label')"
          >
            <el-option
              v-for="item in sortedCurrencies"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
          <div
            v-if="getError('media_buying_limits_currency_code')"
            class="input-error"
          >
            {{ getError('media_buying_limits_currency_code') }}
          </div>
        </div>

        <div class="select-wrap select-wrap__popup-media">
          <div class="card card__panding-balance-popup">
            <div class="field field__panding-balance-popup">
              <span class="label">{{ $t('affiliates.media_buying_setting_card.pending_balance') }} </span>
              <div
                class="values"
                style="font-weight: 500"
                :style="{
                  color: activeColor,
                }"
              >
                {{ pendingBalanceCurrency }}{{ mediaBuying.media_buying_pending_balance | formatMoney }}
              </div>
            </div>
          </div>
          <div class="checkbox-block">
            <el-checkbox v-model="checkBalanceLimit" class="checkbox">
              {{ $t('affiliates.media_buying_setting_card.balance_limit') }}
            </el-checkbox>
          </div>

          <ui-input
            v-model="mediaBuyingBalanceLimitUsd"
            :label="$t('affiliates.media_buying_setting_card.available_balance')"
            :error="errors.media_buying_balance_limit"
            :disabled="!checkBalanceLimit"
            size="big"
            type="number"
            :post-text="currencyLabel"
            autosize
          />
        </div>

        <div class="select-wrap">
          <div class="checkbox-block">
            <el-checkbox v-model="checkAmount" class="checkbox">
              {{
                $t('affiliates.media_buying_setting_card.auto_approve_post')
              }}
            </el-checkbox>
          </div>
          <div class="body__radio-wr">
            <el-radio v-model="radio" label="amount" :disabled="!checkAmount">
              {{ $t('affiliates.media_buying_setting_card.amount') }}
            </el-radio>
            <el-radio
              v-model="radio"
              label="cost_per_player"
              :disabled="!checkAmount"
            >
              {{ $t('affiliates.media_buying_setting_card.cost_per_player') }}
            </el-radio>
          </div>
          <ui-input
            v-if="radio === 'amount'"
            v-model="mediaBuyingAutoApprovePostsMaxAmount"
            :label="$t('affiliates.media_buying_setting_card.amount_up_to')"
            :error="errors.media_buying_auto_approve_posts_max_amount"
            :disabled="!checkAmount"
            size="big"
            type="number"
            autosize
            :post-text="currencyLabel"
          />
          <ui-input
            v-else
            v-model="mediaBuyingAutoApprovePostsCostPerPlayer"
            :label="$t('affiliates.media_buying_setting_card.cost_per_player_up')"
            :error="errors.media_buying_auto_approve_posts_cost_per_player"
            :disabled="!checkAmount"
            type="number"
            size="big"
            autosize
            :post-text="currencyLabel"
          />
        </div>
      </div>
    </ui-confirm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import errorHandleMixin from '@/views/mixins/error-hadle';

export default {
  name: 'MediaBuyingSettingCard',
  mixins: [errorHandleMixin],
  props: {
    id: {
      type: String,
      required: true,
    },

    info: {
      type: Object,
      required: true,
    },

    isEditPermission: {
      type: Boolean,
      default: false,
    },

    hasPermission: {
      type: Boolean,
      default: false,
    },

    editBuying: {
      type: Boolean,
      required: true,
    },

    permissions: {
      type: Object,
      default: () => ({}),
    },

    customerCurrency: {
      type: String,
      default: '',
    },

    mediaBuying: {
      type: Object,
      default: () => ({}),
    },

    getContryName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      activeColor: this.$theme.successColor,
      inactiveColor: this.$theme.dangerColor,

      checkBalanceLimit: false,
      checkAmount: false,

      mediaBuyingAutoApprovePostsCostPerPlayer: null,
      mediaBuyingAutoApprovePostsMaxAmount: null,
      mediaBuyingBalanceLimitUsd: null,
      mediaBuyingEnabled: false,
      mediaBuyingPendingBalanceUsd: null,
      mediaBuyingBalanceLimitCurrency: null,

      radio: 'amount',
      value: true,
      errors: {},
      customerCurrencies: [],
    };
  },

  computed: {
    ...mapGetters({
      currencies: 'misc/currencies',
    }),
    currencyLabel() {
      return this.getCurrencyLabel(this.mediaBuyingBalanceLimitCurrency);
    },
    pendingBalanceCurrency() {
      return this.getCurrencyLabel(this.mediaBuying.media_buying_pending_balance_currency_code);
    },
    balanceCurrency() {
      return this.getCurrencyLabel(this.mediaBuying.media_buying_limits_currency_code);
    },
    sortedCurrencies() {
      return [...this.customerCurrencies].sort((a, b) => {
        if (b.code === this.mediaBuyingBalanceLimitCurrency) {
          return 1;
        }

        if (a.code === this.mediaBuyingBalanceLimitCurrency) {
          return -1;
        }

        return a.code > b.code ? 1 : -1;
      });
    },
    confirmEditMediaBuyingSetting() {
      return {
        type: 'success',
        title: this.$t('affiliates.media_buying_setting_card.mb_settings'),
        action: '',
        actionFaIcon: 'check',
        actionName: this.$t('affiliates.confirm.save'),
        closeActionName: this.$t('dashboards.other.cancel'),
        disabled: this.firstBlockDisabled || this.secondBlockDisabled,
      };
    },

    firstBlockDisabled() {
      return this.checkBalanceLimit
        && (this.mediaBuyingBalanceLimitUsd === ''
          || Number.isNaN(+this.mediaBuyingBalanceLimitUsd));
    },

    secondBlockDisabled() {
      return (
        (this.checkAmount
          && this.radio === 'amount'
          && (this.mediaBuyingAutoApprovePostsMaxAmount === ''
            || Number.isNaN(+this.mediaBuyingAutoApprovePostsMaxAmount)))
        || (this.checkAmount
          && this.radio === 'cost_per_player'
          && (this.mediaBuyingAutoApprovePostsCostPerPlayer === ''
            || Number.isNaN(+this.mediaBuyingAutoApprovePostsCostPerPlayer)))
      );
    },

    setRadioBtn() {
      if (this.mediaBuying.media_buying_auto_approve_posts_cost_per_player === null
        && this.mediaBuying.media_buying_auto_approve_posts_max_amount === null) {
        return 'amount';
      }
      if (this.mediaBuying.media_buying_auto_approve_posts_max_amount !== null) {
        return 'amount';
      }
      return 'cost_per_player';
    },

    _query() {
      return {
        media_buying_auto_approve_posts_cost_per_player:
          // eslint-disable-next-line no-nested-ternary
          this.checkAmount
            ? this.radio === 'amount'
              ? null
              : Number(this.mediaBuyingAutoApprovePostsCostPerPlayer)
            : null,
        media_buying_auto_approve_posts_max_amount:
          // eslint-disable-next-line no-nested-ternary
          this.checkAmount
            ? this.radio === 'amount'
              ? Number(this.mediaBuyingAutoApprovePostsMaxAmount)
              : null
            : null,
        media_buying_balance_limit: this.checkBalanceLimit
          ? Number(this.mediaBuyingBalanceLimitUsd)
          : null,
        media_buying_limits_currency_code: this.mediaBuyingBalanceLimitCurrency,
        media_buying_enabled: this.mediaBuyingEnabled,
      };
    },

    autoApproveUnreportedBalance() {
      return this.mediaBuying.media_buying_balance_limit === null
        ? 'disabled'
        : 'enabled';
    },

    autoApprovePosts() {
      return this.mediaBuying
        .media_buying_auto_approve_posts_cost_per_player === null
        && this.mediaBuying.media_buying_auto_approve_posts_max_amount === null
        ? 'disabled'
        : 'enabled';
    },
  },

  mounted() {
    this.fetchCustomerCurrencies();
  },

  methods: {
    getCurrencyLabel(code) {
      const currency = this.currencies.find(el => el.code === code);

      if (currency) {
        return currency.symbol || `${currency.code} `;
      }

      return '';
    },
    fetchCustomerCurrencies() {
      this.$api.getMiscCustomerCurrencies()
        .then((res) => {
          this.customerCurrencies = res.data.payload;
        });
    },

    async updateMediaBuyingOptions() {
      await this.$api.updateAffiliatMediaBuyingOptions(this.id, this._query);
    },

    editMediaBuying() {
      this.mediaBuyingAutoApprovePostsCostPerPlayer = this.mediaBuying.media_buying_auto_approve_posts_cost_per_player ?? 200;
      this.mediaBuyingAutoApprovePostsMaxAmount = this.mediaBuying.media_buying_auto_approve_posts_max_amount ?? 5000;
      this.mediaBuyingBalanceLimitUsd = this.mediaBuying.media_buying_balance_limit ?? 5000;
      this.mediaBuyingEnabled = this.mediaBuying.media_buying_enabled;
      this.mediaBuyingPendingBalanceUsd = this.mediaBuying.media_buying_pending_balance;
      this.mediaBuyingBalanceLimitCurrency = this.mediaBuying.media_buying_limits_currency_code || this.customerCurrency;

      this.radio = this.setRadioBtn;
      this.checkBalanceLimit = this.autoApproveUnreportedBalance === 'enabled';
      this.checkAmount = this.autoApprovePosts === 'enabled';

      this.errors = {};

      this.$refs.confirmEditMediaBuyingSetting.open();
    },

    cancelEdit() {},

    async saveEdit() {
      try {
        await this.updateMediaBuyingOptions();
        this.$refs.confirmEditMediaBuyingSetting.close();
        this.$emit('saveEdit');
      } catch (error) {
        this.errors = error.data.errors;
      }
    },
  },
};
</script>
