export default {
  validation: {
    errors: {
      // Common
      1: 'Доступ запрещен',
      2: 'Внутренняя ошибка',
      3: 'Действие не разрешено',
      4: 'Требуется аутентификация 2FA',
      5: 'Не разрешено',
      6: 'Ошибка проверки',
      7: 'Не найдено',
      8: 'Ограничение скорости превышено',
      9: 'Доступ запрещен',
      // Validation
      1000: 'Требуется проверка',
      1001: 'Неверное значение',
      1002: 'Неверный URL значения',
      1003: 'Неверное логическое значение',
      1004: 'Максимальная длина превышена',
      1005: 'Неверная дата',
      1006: 'Неверный номер телефона',
      1007: 'Неверный код страны',
      1008: 'Email уже занят',
      1009: 'Пароль слишком короткий',
      1010: 'Неверный единоразовый пароль',
      1011: 'Неверный символ',
      1012: 'Неверный Email',
      1013: 'Неверный пароль',
      1014: 'Данный Email не найден',
      1015: 'Неверный символ для сброса пароля',
      1016: 'String Expected',
      1017: 'Промокод уже занят',
      1018: 'Способ оплаты уже существует',
      1019: 'Неверные настройки оффера',
      1020: 'Должен быть неотрицательным',
      1021: 'Неверное значение процента',
      1022: 'Array Or Null Expected',
      1023: 'Array Expected',
      1024: 'String Expected',
      1025: 'Неверный объект настроек',
      1026: 'Недопустимые символы в промокоде',
      1027: 'Object Expected',
      1038: 'Неверный кошелек',
      // Auth
      2000: 'Неверные учетные данные',
      2001: 'Аккаунт был заблокирован',
      2002: 'Аккаунт был отклонен',
      2003: 'Аккаунт все еще на рассмотрении',
      2004: 'Неверный ответ на запрос',
      2005: 'Email не был подтвержден',
      2006: 'Неверный код',
    },
  },
};
