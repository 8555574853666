import { use } from 'element-ui/src/locale';
import en from 'element-ui/src/locale/lang/en';
import ru from 'element-ui/src/locale/lang/ru-RU';

export default function changeLocaleFork(locale) {
  const targetLanguage = convertLocale(locale);
  use(targetLanguage);
}

function convertLocale(locale) {
  if (locale === 'en') {
    return en;
  }

  if (locale === 'ru') {
    return ru;
  }

  return en;
}
