export default {
  /**
   * Get service origins count
   *
   * @param {Object} params
   * @param {String} params.search
   *
   * @return {Promise<Object>} api response
   */
  getServiceOriginsCount(params) {
    return this.affiliatesApi.get('/domains-management/platform-service-origins/count', { params });
  },

  /**
   * Get service origins
   *
   * @param {Object} params
   * @param {String} params.sort_column
   * @param {String} params.sort_dir
   * @param {String} params.search
   * @param {Number} params.limit
   * @param {Number} params.offset
   *
   * @return {Promise<Object>} api response
   */
  getServiceOrigins(params) {
    return this.affiliatesApi.get('/domains-management/platform-service-origins', { params });
  },

  /**
   * Get service origin by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  getServiceOriginById(id) {
    return this.affiliatesApi.get(`/domains-management/platform-service-origins/${id}`);
  },
};
