export default {


  timezone: {
    EtcGMT12: 'Линия перемены дат',
    PacificMidway: 'острова Мидуэй, Самоа',
    PacificHonolulu: 'Гавайи',
    AmericaJuneau: 'Аляска',
    AmericaDawson: 'Тихоокеанское время (США и Канада); Тихуана',
    AmericaBoise: 'Зона зимнего времени (США и Канада)',
    AmericaChihuahua: 'Чиуауа, Ла-Пас, Мазатлан',
    AmericaPhoenix: 'Аризона',
    AmericaChicago: 'Центральное время (США и Канада)',
    AmericaRegina: 'Саскачеван',
    AmericaMexico_City: 'Гвадалахара, Мексика, Монтеррей',
    AmericaBelize: 'Центральная Америка',
    AmericaDetroit: 'Восточное время (США и Канада)',
    AmericaIndianaIndianapolis: 'Индиана (Восток)',
    AmericaBogota: 'Богота, Лайма, Кито',
    AmericaGlace_Bay: 'Атлантическое время (Канада)',
    AmericaCaracas: 'Каракас, Ла-Пас',
    AmericaSantiago: 'Сантьяго',
    AmericaSt_Johns: 'Ньюфаундленд и Лабрадор',
    AmericaSao_Paulo: 'Бразилиа',
    AmericaArgentinaBuenos_Aires: 'Буэнос-Айрес, Джорджтаун',
    AmericaGodthab: 'Гренландия',
    EtcGMT2: 'Среднеатлантический ',
    AtlanticAzores: 'Азорские острова',
    AtlanticCape_Verde: 'острова Зелёного Мыса',
    GMT: 'Дублин, Единбург, Лиссабон, Лондон',
    AfricaCasablanca: 'Касабланка, Монровия',
    AtlanticCanary: 'Канарские острова',
    EuropeBelgrade: 'Белград, Братислава, Будапешт, Любляна, Прага',
    EuropeSarajevo: 'Сараево, Скопье, Варшава, Загреб',
    EuropeBrussels: 'Брюссель, Копенгаген, Мадрид, Париж',
    EuropeAmsterdam: 'Амстердам, Берлин, Берн, Рим, Стокгольм, Вена',
    AfricaAlgiers: 'Западно-центральная Африка',
    EuropeBucharest: 'Бухарест',
    AfricaCairo: 'Каир',
    EuropeHelsinki: 'Хельсинки, Киев, Рига, София, Таллин, Вильнус',
    EuropeAthens: 'Афины, Стамбул, Минск',
    AsiaJerusalem: 'Иерусалим',
    AfricaHarare: 'Хараре, Претория',
    EuropeMoscow: 'Москва, Санкт-Петербург, Волгоград',
    AsiaKuwait: 'Кувейт, Эр-Рияд',
    AfricaNairobi: 'Найроби',
    AsiaBaghdad: 'Багдад',
    AsiaTehran: 'Тегеран',
    AsiaDubai: 'Абу Даби, Маскат',
    AsiaBaku: 'Баку, Тбилиси, Ереван',
    AsiaKabul: 'Кабул',
    AsiaYekaterinburg: 'Екатеринбург',
    AsiaKarachi: 'Исламабад, Карачи, Ташкент',
    AsiaKolkata: 'Ченнаи, Колката, Мумбай, Нью-Дели',
    AsiaKathmandu: 'Катманду',
    AsiaDhaka: 'Астана, Дакка',
    AsiaColombo: 'Шри-Джаяварденепура',
    AsiaAlmaty: 'Алма-Ата, Новосибирск',
    AsiaRangoon: 'Янгон',
    AsiaBangkok: 'Бангкок, Ханой, Джакарта',
    AsiaKrasnoyarsk: 'Красноярск',
    AsiaShanghai: 'Пекин, Чунцин, Особый административный район Гонконг, Урумчи',
    AsiaKuala_Lumpur: 'Куала Лумпур, Сингапур',
    AsiaTaipei: 'Тайбэй',
    AustraliaPerth: 'Перт',
    AsiaIrkutsk: 'Иркутск, Улан-Батор',
    AsiaSeoul: 'Сеул',
    AsiaTokyo: 'Осака, Саппоро, Токио',
    AsiaYakutsk: 'Якутск',
    AustraliaDarwin: 'Дарвин',
    AustraliaAdelaide: 'Аделаида',
    AustraliaSydney: 'Канберра, Мельбурн, Сидней',
    AustraliaBrisbane: 'Брисбен',
    AustraliaHobart: 'Хобарт',
    AsiaVladivostok: 'Владивосток',
    PacificGuam: 'Гуам, Порт-Морсби',
    AsiaMagadan: 'Магадан, Соломоновы о-ва, Новая Каледония',
    PacificFiji: 'Фиджи, Камчатка, Маршалловы о-ва',
    PacificAuckland: 'Окленд, Веллингтон',
    PacificTongatapu: 'Нукуалофа',

  },


};
