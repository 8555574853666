<template>
  <div
    v-loading="isDataLoading"
    class="affiliate-bonuses"
  >
    <div class="wrapper">
      Bonuses
    </div>
  </div>
</template>

<script>

export default {
  name: 'AffiliateBonuses',
  data() {
    return {
      id: '',
      info: {},
      isDataLoading: true,
    };
  },
  computed: {
    isInfoEmpty() {
      return this.$_.isEmpty(this.info);
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    if (!this.id) {
      this.$router.push('/affiliates/list');
    }
    this.$api.getAffiliateInfo(this.id)
      .then((response) => {
        this.info = this.$_.cloneDeepWith(response.data.payload);
      })
      .finally(() => {
        this.isDataLoading = false;
      });
  },
};
</script>

<style lang="scss" scoped>

.affiliate-bonuses{
  padding-bottom: 44px;
  .msg{
    padding-top: 40px;
    width: 100%;
    text-align: center;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-width: 1140px;
    width: 90%;
  }
}
</style>
