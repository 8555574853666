<template>
  <div
    :class="action"
    class="unlink-offer-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">{{ $t('affiliates.delete_offer.title') }}</span>
        <ui-icon
          :size="19"
          class="close"
          lib="fa"
          substyle="fas"
          name="times"
          @click.native="close"
        />
      </div>
      <div
        v-loading="isDataLoading"
        class="form"
      >
        <div class="row">
          <span
            v-if="action === 'update'"
            class="text-top"
          >{{ $t('affiliates.delete_offer.msg') }}</span>
        </div>
        <div class="row">
          <div class="field">
            <el-tooltip
              :content="$t('affiliates.delete_offer.required')"
              placement="top"
            >
              <span class="label">{{ $t('affiliates.delete_offer.offer') }}<span class="req-red">*</span></span>
            </el-tooltip>
            <div class="wrap-unpin">
              <el-select
                v-model="offerId"
                :disabled="disabledOffer"
                :placeholder="$t('affiliates.delete_offer.select_offer')"
                filterable
                class="select"
              >
                <el-option
                  v-for="(item) in filteredOffersByBrand"
                  :key="item.id"
                  :label="item.full_name"
                  :value="item.id"
                />
              </el-select>
              <span class="text-top">{{ $t('affiliates.delete_offer.or') }}</span>
              <ui-button
                v-if="action === 'update'"
                filled
                size="big"
                color="red"
                lib="fa"
                substyle="fas"
                icon="unlink"
                @click="unpinPlayer('unpin')"
              >
                {{ $t('affiliates.delete_offer.unpin_player') }}
              </ui-button>
            </div>
          </div>
        </div>

        <div
          v-show="false"
          class="row"
        >
          <div class="field">
            <span class="label">{{ $t('affiliates.delete_offer.offer') }}</span>
            <el-select
              v-model="affiliateId"
              :placeholder="$t('affiliates.delete_offer.select_aff')"
              filterable
              class="select"
              @input="changeAffiliate"
            >
              <el-option
                v-for="item in getAffiliate"
                :key="item.id"
                :label="item.email"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>
      </div>

      <div class="foot">
        <ui-button
          color="red"
          size="big"
          lib="fa"
          substyle="fas"
          icon="times"
          @click.native="close"
        >
          {{ $t('affiliates.delete_offer.cancel') }}
        </ui-button>
        <ui-button
          v-if="action === 'update'"
          :disabled="disabledLink"
          size="big"
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          color="green"
          @click="unpinPlayer('confirm')"
        >
          {{ $t('affiliates.delete_offer.confirm') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>


export default {
  name: 'LinkAffiliatePopup',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    isOpen: {
      type: Boolean,
      required: true,
    },

  },
  data() {
    return {
      isDataLoading: true,
      getAffiliate: [],
      affiliateId: null,
      offerId: null,
      affiliateOffers: [],
      disabledOffer: true,
      hiddenBtnUnlink: false,
      pinnedAffiliateId: null,
      pinnedAffiliateOfferId: null,
      action: 'update',
    };
  },
  computed: {
    disabledLink() {
      return this.pinnedAffiliateId === this.affiliateId && this.pinnedAffiliateOfferId === this.offerId;
    },

    filteredOffersByBrand() {
      const allBrands = this.affiliateOffers.filter(offer => offer.brand_visibility === 'all');
      const onlySelectedBrands = this.affiliateOffers.filter(offer => offer.brand_visibility === 'all' || (offer.brand_visibility === 'only_selected' && this.$_.differenceBy(this.data.visible_brands, offer.visible_brands, 'site_id').length === 0));

      return this.data.brand_visibility === 'all' ? allBrands : onlySelectedBrands;
    },
  },

  watch: {
    isOpen(e) {
      // open
      if (e) {
        this.resetForm();
        this.isDataLoading = true;
        this.pinnedAffiliateId = this.$_.clone(this.data.affiliate_id);
        this.pinnedAffiliateOfferId = this.$_.clone(this.data.affiliate_offer_id);
        // already pinned
        if (this.data.affiliate_id !== null) {
          this.action = 'update';
          this.disabledOffer = false;
          this.hiddenBtnUnlink = true;
          this.affiliateId = this.data.affiliate_id;
          this.getAffiliates();
          this.offerId = this.data.affiliate_offer_id;
          this.getAffiliateOffers(this.affiliateId);
        }
      }
    },
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    });
  },

  methods: {
    changeAffiliate(id) {
      if (id !== this.pinnedAffiliateId) {
        this.offerId = null;
      }
      this.getAffiliateOffers(id).then(() => { this.disabledOffer = false; });
    },
    resetForm() {
      this.isDataLoading = true;
      this.getAffiliate = [];
      this.affiliateOffers = [];
      this.disabledOffer = true;
      this.hiddenBtnUnlink = false;
      this.pinnedAffiliateId = null;
      this.pinnedAffiliateOfferId = null;
    },
    getAffiliateOffers(id) {
      this.isDataLoading = true;
      return this.$api
        .getAffiliateOffers(id)
        .then((res) => {
          this.affiliateOffers = this.$_.clone(res.data.payload).filter(e => e.id !== this.data.id);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    getAffiliates(id) {
      this.isDataLoading = true;
      this.$api
        .getAffiliates({ search: id })
        .then((res) => {
          this.getAffiliate = res.data.payload;
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    unpinPlayer(action) {
      if (action === 'confirm') {
        const nameNewOffer = this.$_.find(this.affiliateOffers, e => e.id === this.offerId).name;
        this.$emit('unpin', this.offerId, 'confirm', nameNewOffer);
      }
      if (action === 'unpin') {
        this.$emit('unpin', '', 'unpin', null);
      }
    },
    close(v) {
      this.$emit('close', v);
    },
  },
};
</script>
