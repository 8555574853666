export default {
  // team
  getTeamsList(params) {
    return this.affiliatesApi.get('/teams', { params });
  },
  getTeam(id) {
    return this.affiliatesApi.get(`/teams/${id}`);
  },
  postTeam(name) {
    return this.affiliatesApi.post('/teams', { name });
  },
  putTeam(id, name) {
    return this.affiliatesApi.put(`/teams/${id}`, { name });
  },
  deleteTeam(id) {
    return this.affiliatesApi.delete(`/teams/${id}`);
  },
  attachAdmin(id, admin_id) {
    return this.affiliatesApi.post(`/teams/${id}/attach-admin`, { admin_id });
  },

  getTeamPermissions(id) {
    return this.affiliatesApi.get(`/teams/${id}/permissions`);
  },
  postTeamPermissions(id, data) {
    return this.affiliatesApi.post(`/teams/${id}/permissions`, data);
  },


  // Admin
  getAdmins(params) {
    return this.affiliatesApi.get('/admins', { params });
  },
  getAdmin(id) {
    return this.affiliatesApi.get(`/admins/${id}`);
  },
  postAdmins(data) {
    return this.affiliatesApi.post('/admins', data);
  },
  putAdmins(id, data) {
    return this.affiliatesApi.put(`/admins/${id}`, data);
  },
  deleteAdmins(id) {
    return this.affiliatesApi.delete(`/admins/${id}`);
  },
  postBlock(id) {
    return this.affiliatesApi.post(`/admins/${id}/block`);
  },
  postUnblock(id) {
    return this.affiliatesApi.post(`/admins/${id}/unblock`);
  },
  postResetPassword(id) {
    return this.affiliatesApi.post(`/admins/${id}/reset-password`);
  },
  postResetTotp(id) {
    return this.affiliatesApi.post(`/admins/${id}/reset-totp`);
  },

  postAttachToTeam(id, team_id) {
    return this.affiliatesApi.post(`/admins/${id}/attach-to-team`, { team_id });
  },
  postDetachFromTeam(id, team_id) {
    return this.affiliatesApi.post(`/admins/${id}/detach-from-team`, team_id);
  },

  postAttachAffiliate(id, { affiliate_id, team_id }) {
    return this.affiliatesApi.post(`/admins/${id}/attach-affiliate`, { affiliate_id, team_id });
  },
  postDetachAffiliate(id, { affiliate_id, team_id }) {
    return this.affiliatesApi.post(`/admins/${id}/detach-affiliate`, { affiliate_id, team_id });
  },
  getAdminAffiliates(id, params) {
    return this.affiliatesApi.get(`/admins/${id}/affiliates`, { params });
  },

  getAdminAcl(id) {
    return this.affiliatesApi.get(`/admins/${id}/teams-permissions-preview`);
  },
  getAdminPermissions(id, team_id) {
    return this.affiliatesApi.get(`/admins/${id}/permissions`, { params: { team_id } });
  },
  postAdminPermissions(id, team_id, data) {
    return this.affiliatesApi.post(`/admins/${id}/permissions?team_id=${team_id}`, data);
  },
};
