import numeral from 'numeral';
import Config from '@/views/Reports/reportSettings';

export default (App, i18n) => {
  App.filter('uppercase', (value) => {
    let v = value;
    if (!v) return '';
    v = v.toString();
    return v.toUpperCase();
  });
  App.filter('capitalize', (value) => {
    let v = value;
    if (!v) return '';
    v = v.toString();
    return v[0].toUpperCase() + v.slice(1);
  });
  App.filter('formatDate', (value) => {
    const d = App.prototype.$moment(value);
    return d.isValid() ? d.format('DD/MM/YYYY') : '';
  });

  App.filter('formatDate2', value => App.prototype.$moment(value).format('DD.MM.YYYY'));
  App.filter('formatDate3', value => App.prototype.$moment(value).locale(i18n.locale).format('DD MMM YYYY HH:mm'));
  App.filter('formatDate4', value => App.prototype.$moment(value).locale(i18n.locale).format('DD MMM YYYY'));
  App.filter('formatMoney', value => numeral(value).format('0,0.00'));
  App.filter('formatMoneyNoFrac', value => numeral(value).format('0,0'));
  App.filter('duration', value => App.prototype.$moment.duration(value, 'seconds').humanize());
  App.filter('currency', (value) => {
    switch (value ? value.toLowerCase() : value) {
    case 'rub':
      return '₽';
    case 'eur':
      return '€';
    case 'gpb':
      return '£';
    case 'btc':
      return '₿';
    case 'usd':
      return '$';
    case 'uah':
      return '₴';
    default:
      return value;
    }
  });
  App.filter('datetimeFilter', (val, type) => {
    let a = '';
    Config.allDimensions.forEach((v) => {
      if (v.name === type && App.prototype.$_.has(v, 'formatOptions')) {
        a = `${App.prototype.$moment(val.toString().split(' ')[0], v.formatOptions.in).format(v.formatOptions.out)
        } ${App.prototype.$_.isArray(val.toString().split(' ')) && val.toString().split(' ').length > 1
          ? val.toString().split(' ')[1]
          : ''}`;
      }
    });
    if (a === '') {
      a = val;
    }
    return a;
  });
};
