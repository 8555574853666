export default {
  id: '',
  name: '',
  description: '',
  template_settings: {
    payout_rules: [
      {
        filters: {},
        settings: {
          rev_share: {
            sport_levels: [
              {
                percent: 0,
                ftd_count: 0,
              },
            ],
            casino_levels: [
              {
                percent: 0,
                ftd_count: 0,
              },
            ],
          },
          cpa: [
            {
              amount: 0,
              currency_code: 'usd',
              cap_period_seconds: 0,
              cap_players_count: 0,
              hold_seconds: 0,
              deposits_sum_max: null,
              deposits_sum_min: null,
              hold_seconds_cpa: null,
              deposit_amount_max: null,
              deposit_amount_min: null,
              deposits_count_max: null,
              deposits_count_min: null,
              sport_bets_odd_max: null,
              sport_bets_odd_min: null,
              sport_bets_sum_max: null,
              sport_bets_sum_min: null,
              casino_bets_sum_max: null,
              casino_bets_sum_min: null,
              sport_bet_amount_max: null,
              sport_bet_amount_min: null,
              sport_bets_count_max: null,
              sport_bets_count_min: null,
              casino_bet_amount_max: null,
              casino_bet_amount_min: null,
              casino_bets_count_max: null,
              casino_bets_count_min: null,
              hold_seconds_rev_share: null,
              sport_bets_odd_out_of_bounds_increment_count: false,
            },
          ],
        },
      },
    ],
    visible_brands: [],
    brand_visibility: 'all',
    global_cap_period_seconds: 0,
    global_cap_players_count: 0,
    global_hold_seconds_cpa: 0,
    global_hold_seconds_rev_share: 0,
  },
};
