<template>
  <div class="platform-servers">
    <PlatformServerPopup
      v-if="canEdit"
      ref="platformServerPopup"
      :is-open="isOpenPopup"
      :inital-data="editableRow"
      @close="closePopup"
      @create="createPlatformServer"
      @update="updatePlatformServer"
    />

    <PlatformServerInitCommandsPopup
      :is-open="isOpenScriptPopup"
      :init-commands="initCommands"
      @close="closeScriptPopup"
    />

    <ui-confirm
      ref="confirm"
      :width="480"
      :type="'warning'"
      :action-name="$t('domainManagement.buttons.delete')"
      :action-fa-icon="'trash-alt'"
      :data="confirmData"
      @save="deleteHandler"
      @close="confirmClose"
    >
      <div
        slot="title"
        v-html="$t('domainManagement.buttons.delete')"
      />
      <div class="body__wrap">
        <i
          class="fas fa-trash-alt warning"
        />
        <div
          class="body__msg"
          v-html="$t('domainManagement.messages.delete', { name: confirmData.name })"
        />
      </div>
    </ui-confirm>

    <div class="toolbar">
      <div class="wrapper">
        <div class="section">
          <span class="title">{{ $t('domainManagement.pageTitle.platformServers') }}</span>
          <ui-input
            v-model.trim="search"
            class="platform-servers-search"
            :width="180"
            :is-search="true"
            @search="getPlatformServers"
          />
        </div>
        <div class="section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="paginationHandler"
            />
          </div>
          <ui-button
            v-if="canRunJob"
            color="green"
            lib="fa"
            substyle="fas"
            icon="cogs"
            class="btn btn-add"
            @click="updateServersConfig"
          >
            {{ $t('domainManagement.buttons.rebuild_servers_config') }}
          </ui-button>
          <ui-button
            v-if="canEdit"
            color="green"
            lib="fa"
            substyle="fas"
            filled
            icon="plus"
            class="btn btn-add"
            @click="openPopup"
          >
            {{ $t('domainManagement.buttons.add_platform_server') }}
          </ui-button>
        </div>
      </div>
    </div>
    <ui-table
      v-loading="isDataLoading"
      :lazy-loading-delay="4000"
      :fields="fields"
      :data="data"
      :rows-count="limit"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      lazy-loading
      class="table"
      i18n-path="domainManagement"
      element-loading-custom-class="data-loader-spinner"
      @sort="sortingHandler"
    >
      <template slot="append">
        <el-table-column width="100">
          <template slot-scope="scope">
            <ActionIcon
              :tooltip="$t('domainManagement.buttons.view_script')"
            >
              <el-link
                class="action-ico"
                @click="openScriptPopup(scope.row)"
              >
                <ui-icon
                  name="code"
                  color="#3b94e3"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
            <ActionIcon
              :tooltip="$t('domainManagement.buttons.edit')"
            >
              <el-link
                v-if="canEdit"
                class="action-ico"
                type="success"
                @click="editHandler(scope.row)"
              >
                <ui-icon
                  name="pen"
                  :color="$theme.accentColor"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
            <ActionIcon
              :tooltip="$t('domainManagement.buttons.delete')"
            >
              <el-link
                v-if="canEdit"
                :disabled="scope.row.platform_service_domains_count > 0"
                class="action-ico"
                type="danger"
                @click="confirmOpen(scope.row)"
              >
                <ui-icon
                  name="trash-alt"
                  :color="$theme.dangerColor"
                  lib="fa"
                />
              </el-link>
            </ActionIcon>
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import queryParamsMixin from '../mixins/query-params';

import PlatformServerPopup from '@/components/Popups/PlatformServerPopup';
import PlatformServerInitCommandsPopup from '@/components/Popups/PlatformServerInitCommandsPopup';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'platform-servers/list/limit'; // for localstorage
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'PlatformServers',

  components: {
    PlatformServerPopup,
    PlatformServerInitCommandsPopup,
  },

  mixins: [pageSizeMixin, queryParamsMixin],

  data() {
    return {
      isDataLoading: false,
      isOpenPopup: false,
      isOpenScriptPopup: false,

      activePopperIPv4: null,
      activePopperIPv6: null,

      search: '',
      count: 0,
      page: 1,
      limit: pageSize,
      sortProp: 'incrementing_id',
      sortOrder: 'desc',

      data: [],
      fields: [
        {
          name: 'incrementing_id',
          sortable: 'custom',
          align: 'left',
          width: '60',
        },
        {
          name: 'name',
          label: 'server_name',
          sortable: 'custom',
          minWidth: '100',
        },
        {
          name: 'can_be_picked_automatically',
          computedValue: row => this.$t(`domainManagement.fields.can_be_picked_automatically.values.${row.can_be_picked_automatically}`),
          computedClass: this.getComputedClass,
        },
        {
          name: 'latest_config_request_status',
          computedValue: row => this.$t(`domainManagement.fields.latest_config_request_status.values.${row.latest_config_request_status}`),
          computedClass: this.getComputedStatusesClass,
        },
        {
          name: 'ipv4_addresses_total_count',
          sortable: 'custom',
          linkAppend: (item) => {
            if (item.additional_ipv4_addresses.length > 0) {
              const addresses = item.additional_ipv4_addresses.map(address => this.$createElement('div', { class: 'el-dropdown-menu__item' }, address.ip_address));
              const ip = this.$createElement('span', {
                class: 'server-adresses-value',
              },
              item.main_ipv4_address);
              const dropdown = this.$createElement(
                'el-dropdown', {
                  class: 'server-adresses',
                  props: {
                    trigger: 'click',
                    placement: 'bottom',
                  },
                  on: {
                    'visible-change': isOpened => this.activePopperIPv4 = isOpened ? item.id : null,
                  },
                },
                [
                  this.$createElement(
                    'div',
                    {
                      class: 'el-dropdown-link',
                    },
                    [
                      this.$createElement('span', { class: { 'server-adresses-count': true, active: this.activePopperIPv4 === item.id } }, `+${item.additional_ipv4_addresses.length}`),
                    ],
                  ),
                  this.$createElement(
                    'el-dropdown-menu',
                    {
                      class: 'server-adresses-dropdown ddgroup',
                    },
                    addresses,
                  ),
                ],
              );
              return this.$createElement(
                'span', [
                  [ip],
                  [dropdown],
                ],
              );
            }
            return this.$createElement('span', item.main_ipv4_address);
          },
        },
        {
          name: 'ipv4_addresses_available_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
        },
        {
          name: 'ipv6_addresses_total_count',
          sortable: 'custom',
          linkAppend: (item) => {
            if (item.additional_ipv6_addresses.length > 0) {
              const addresses = item.additional_ipv6_addresses.map(address => this.$createElement('div', { class: 'el-dropdown-menu__item' }, address.ip_address));
              const ip = this.$createElement('span', { class: 'server-adresses-value' }, item.main_ipv6_address);
              const dropdown = this.$createElement(
                'el-dropdown', {
                  class: 'server-adresses',
                  props: {
                    trigger: 'click',
                    placement: 'bottom',
                  },
                  on: {
                    'visible-change': isOpened => this.activePopperIPv6 = isOpened ? item.id : null,
                  },
                },
                [
                  this.$createElement(
                    'div',
                    {
                      class: 'el-dropdown-link',
                    },
                    [
                      this.$createElement('span', { class: { 'server-adresses-count': true, active: this.activePopperIPv6 === item.id } }, `+${item.additional_ipv6_addresses.length}`),
                    ],
                  ),
                  this.$createElement(
                    'el-dropdown-menu',
                    {
                      class: 'server-adresses-dropdown ddgroup',
                    },
                    addresses,
                  ),
                ],
              );
              return this.$createElement(
                'span', [
                  [ip],
                  [dropdown],
                ],
              );
            }
            return this.$createElement('span', item.main_ipv6_address || '—');
          },
        },
        {
          name: 'ipv6_addresses_available_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
        },
        {
          name: 'platform_service_domains_count',
          align: 'right',
          sortable: 'custom',
          link: this.serviceDomainsClickHandler,
          format: 'number',
          class: 'service-domain-link',
        },
        {
          name: 'traffic_last_30_days_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
        },
        {
          name: 'traffic_last_7_days_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
        },
        {
          name: 'traffic_last_day_count',
          align: 'right',
          sortable: 'custom',
          format: 'number',
        },
        {
          name: 'created_at',
          sortable: 'custom',
          width: '120',
          format: 'date-time',
        },
      ],

      editableRow: undefined,
      editableRowId: undefined,
      initCommands: '',

      confirmData: {},
    };
  },
  computed: {
    isSuperUser() {
      return this.$store.getters['auth/adminAcl'].is_superuser;
    },

    canEdit() {
      return this.isSuperUser || this.$store.getters['auth/currentAcl'].platform_servers_edit === 'allow';
    },

    canRunJob() {
      return this.isSuperUser || this.$store.getters['auth/currentAcl'].platform_servers_can_run_jobs === 'allow';
    },

    queryParams() {
      return {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        search: this.search || null,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
      };
    },
  },

  methods: {
    // For query-params mixin (auto-change url query)
    fetch() {
      return this.getPlatformServers();
    },

    getComputedClass(value) {
      return value ? 'positive' : 'negative';
    },
    getComputedStatusesClass(value) {
      switch (value) {
      case 'ok':
        return 'positive';
      case 'warning':
        return 'negative';
      default:
        return null;
      }
    },

    serviceDomainsClickHandler(row) {
      return row.platform_service_domains_count > 0
        ? `/tools/service-domains?filters={"platform_server_id":"${row.id}"}` : false;
    },

    async getPlatformServers() {
      this.isDataLoading = true;

      const { data: { payload, misc } } = await this.$api.getPlatformServers(this.queryParams);

      this.count = misc.count;
      this.data = payload;
      this.isDataLoading = false;
    },

    paginationHandler(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;

        this.getPlatformServers();
      }
    },

    sortingHandler({ prop, order }) {
      if (order === '') return;

      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }

      this.getPlatformServers();
    },

    closePopup() {
      this.isOpenPopup = false;
      this.editableRow = undefined;
    },

    openPopup() {
      this.isOpenPopup = true;
    },

    closeScriptPopup() {
      this.isOpenScriptPopup = false;
      this.initCommands = '';
    },

    openScriptPopup(row) {
      this.isOpenScriptPopup = true;
      this.initCommands = row.init_commands;
    },

    async createPlatformServer(data) {
      const { data: { payload } } = await this.$api.createPlatformServer(data);
      this.openScriptPopup(payload);
      await this.getPlatformServers();
      this.$refs.platformServerPopup.close();
    },

    async updatePlatformServer(data) {
      await this.$api.updatePlatformServer(this.editableRowId, data);
      await this.getPlatformServers();
      this.$refs.platformServerPopup.close();
      this.editableRowId = undefined;
    },

    async deleteHandler(action, row) {
      this.$refs.confirm.close();
      await this.$api.deletePlatformServer(row.id);
      await this.getPlatformServers();
    },

    async updateServersConfig() {
      const { status } = await this.$api.updatePlatformServersConfig();
      if (status === 204) {
        this.$noty.info(this.$t('domainManagement.messages.servers_config_update_success'));
      } else {
        this.$noty.error(this.$t('domainManagement.messages.servers_config_update_fail'));
      }
    },

    async editHandler(row) {
      const { data: { payload } } = await this.$api.getPlatformServerById(row.id);
      this.editableRow = payload;
      this.editableRowId = row.id;

      this.openPopup();
    },

    confirmOpen(row) {
      this.confirmData = row;
      this.$refs.confirm.open();
    },

    confirmClose() {
      this.confirmData = {};
    },
  },
};
</script>
