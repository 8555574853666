var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ui-popup-col',{ref:"columnsPopup",staticClass:"add-dashboard-metrics columns-popup",class:{ 'add-dashboard-metrics__edit': _vm.typePopup !== 'add' },attrs:{"width":1300,"title":_vm.$t(
        `dashboards.other.${
          _vm.typePopup === 'add' ? 'add_metric' : 'edit_metric'
        }`
      ),"actions-disabled":_vm.actionsDisabled || _vm.isDisableSelectFilter || _vm.addDashbordIsLoading,"esc-close":false},on:{"save":_vm.applyColumnsSettings,"cancel":() => {
        _vm.isEdit ? _vm.alertClosePopup() : _vm.cancelColumnsSettings();
      }}},[_c('div',{staticClass:"columns-popup__wr",class:{ 'is-show': false }},[_c('div',{staticClass:"columns-popup__left"},[_c('div',{staticClass:"columns-popup__title"},[_vm._v(" "+_vm._s(_vm.$t('dashboards.popup_column.configure_metric'))+" ")]),_c('div',[(_vm.selectedMetric)?_c('div',{staticClass:"columns-popup__input-name"},[_c('ui-input',{staticClass:"form-input",attrs:{"label":_vm.$t('dashboards.other.panel_name'),"autosize":"","size":"big","placeholder":_vm.$t('dashboards.other.panel_name_placeholder'),"type":"text","value":_vm.setNamePanel},on:{"input":(val) => {
                  _vm.panelName = val;
                  _vm.countCalcQuery();
                }}})],1):_vm._e(),_c('adm-ui-scrollbar',{attrs:{"wrap-style":"height: 55vh;","native":false,"outside":true}},[(_vm.selectedMetric)?_c('div',{staticClass:"controls columns-popup__right-control"},[(_vm.typePopup === 'add')?_c('div',{staticClass:"columns-popup__right-view-wr"},[_c('div',{staticClass:"columns-popup__right-select-wr"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('dashboards.popup_column.view')))]),_c('el-radio-group',{attrs:{"size":"small"},on:{"change":_vm.setTypeMetric},model:{value:(_vm.typeMetric),callback:function ($$v) {_vm.typeMetric=$$v},expression:"typeMetric"}},[_c('el-radio-button',{attrs:{"disabled":_vm.loading,"label":"stat"}},[_vm._v(" "+_vm._s(_vm.$t('dashboards.popup_column.card'))+" ")]),_c('el-radio-button',{attrs:{"disabled":_vm.loading,"label":"chart"}},[_vm._v(" "+_vm._s(_vm.$t('dashboards.popup_column.chart'))+" ")]),_c('el-radio-button',{attrs:{"disabled":_vm.loading,"label":"table"}},[_vm._v(" "+_vm._s(_vm.$t('dashboards.popup_column.table'))+" ")])],1)],1)]):_vm._e(),(_vm.typeMetric === 'chart')?_c('div',{staticClass:"columns-popup__right-select-wr"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('dashboards.popup_column.type')))]),_c('adm-ui-select',{attrs:{"data":_vm.chartTypeData,"default":_vm.chartType},on:{"select":_vm.handlerChartType}})],1):_vm._e()]):_vm._e(),(
                (_vm.typeMetric !== 'pie' && _vm.groupingSwitch) ||
                  _vm.typeMetric === 'stat' ||
                  _vm.typeMetric === 'table'
              )?_c('select-metrics',{attrs:{"label":_vm.$t('dashboards.popup_column.metrics'),"value":_vm.selectedMetric,"prop-currency-code":_vm.propCurrencyCode},on:{"change":_vm.handlerSelectMetric}}):_vm._e(),(_vm.typeMetric !== 'chart')?_c('dashboard-switch',{attrs:{"title":_vm.$t('dashboards.other.enable_comparison'),"value-switch":_vm.enableComparison},on:{"handlerChangeSwitch":_vm.handlerComparison}}):_vm._e(),(_vm.selectedMetric)?_c('div',[(
                  _vm.typeMetric !== 'stat' &&
                    _vm.typeMetric !== 'table' &&
                    _vm.chartType !== 'pie'
                )?_c('dashboard-switch',{attrs:{"title":_vm.$t('dashboards.other.forecast'),"value-switch":_vm.forecastValue},on:{"handlerChangeSwitch":_vm.handlerForecast}}):_vm._e(),(_vm.typeMetric !== 'stat')?_c('dashboard-grouping-metric',{attrs:{"grouping-switch":_vm.groupingSwitch,"series-group-by-dimension-name":_vm.groupByGrouping,"series-group-by-limit":_vm.groupingCount,"series-group-by-add-others":_vm.groupingOther,"disabled":_vm.disabledGroupingSwitch || _vm.addDashbordIsLoading,"groups-all":_vm.groupsAll},on:{"handlerChangeSwitch":_vm.handlerChangeSwitch,"handlerSelectGroup":_vm.handlerSelectGroup,"handlerSelectCount":_vm.handlerSelectCount,"handlerShowOther":_vm.handlerShowOther}}):_vm._e(),_c('dashboard-filter',{attrs:{"data":_vm.filterTab,"init-filters":_vm.initFiltetsdashboard},on:{"handlerDashboardTabFilter":_vm.handlerDashboardTabFilter,"handlerDashboardFilter":_vm.handlerDashboardFilter,"isDisableSelectFilter":_vm.handlerDisableSelectFilter}}),(_vm.typeMetric === 'chart' && !_vm.groupingSwitch)?_c('dashboard-series',{attrs:{"series":_vm.computedSeries,"disabled":_vm.actionsDisabled ||
                    _vm.isDisableSelectFilter ||
                    _vm.addDashbordIsLoading,"prop-currency-code":_vm.propCurrencyCode},on:{"updateSeries":_vm.updateSeries,"isDisableSelectFilter":_vm.handlerDisableSelectFilter}}):_vm._e()],1):_vm._e()],1)],1)]),_c('div',{staticClass:"columns-popup__right"},[(_vm.selectedMetric)?_c('div',[_c('div',{staticClass:"columns-popup__title"},[_vm._v(" "+_vm._s(_vm.$t('dashboards.popup_column.preview_panel'))+" ")]),_c('adm-ui-scrollbar',{attrs:{"wrap-style":"height: 55vh;","native":false,"outside":true}},[_c('div',{staticClass:"columns-popup__right-content-wr"},[(_vm.typeMetric === 'stat')?_c('dashboard-metric-stat',{ref:"stat",staticStyle:{"width":"260px"},attrs:{"currency-code":_vm.currencyCode,"stat":{
                  ..._vm.dashboardStatComputed,
                  name: _vm.getTranslateMetrics(_vm.dashboardStatComputed.metric_name)
                    .title,
                },"is-removed":false,"period":_vm.period},on:{"emitDataReport":_vm.setReportData}}):_vm._e(),(_vm.typeMetric === 'chart')?_c('dashboard-chart',{key:_vm.chartType,ref:"chart",attrs:{"prop-currency-code":_vm.propCurrencyCode,"chart":{
                  ..._vm.dashboardStatComputed,
                  name: _vm.getTranslateMetrics(
                    _vm.dashboardStatComputed.series_group_by_metric_name ||
                      _vm.dashboardStatComputed.series[0].metric_name
                  ).title,
                },"preview":"","animation":_vm.animation,"is-loading":_vm.loading,"period":_vm.period},on:{"changeSelectType":_vm.changeSelectType,"emitDataReport":_vm.setReportData}}):_vm._e(),(_vm.typeMetric === 'table')?_c('dashboard-metric-table',{ref:"table",attrs:{"is-loading":_vm.loading,"table":{
                  ..._vm.dashboardStatComputed,
                  name: _vm.getTranslateMetrics(_vm.dashboardStatComputed.metric_name)
                    .title,
                },"period":_vm.period}}):_vm._e()],1)])],1):_vm._e()])])]),_c('adm-ui-confirm',_vm._b({ref:"confirm"},'adm-ui-confirm',_vm.confirmConfig,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }