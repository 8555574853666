<template>
  <transition name="fade">
    <div v-if="isOpen" class="pin-domain-popup select-metrics">
      <div class="popup">
        <div class="head">
          <span class="name">
            {{ $t('domainManagement.buttons.pin_domain') }}
          </span>
          <ui-icon
            :size="20"
            lib="fa"
            substyle="fas"
            class="close"
            name="times"
            @click.native="close"
          />
        </div>
        <div v-loading="isLoadingAffiliate" class="body">
          <div class="title">
            {{ $t('domainManagement.popup.domain_title') }}
          </div>
          <div
            :style="[isLoadingAffiliate && { visibility: 'hidden' }]"
            class="textarea-wrapper"
          >
            <el-select
              v-model="selectDomain"
              filterable
              popper-class="select-metrics__popper select-metrics__popper-aff"
              :filter-method="filterMethod"
              :disabled="isLoadingAffiliate"
              :placeholder="$t('domainManagement.popup.placeholder_domain')"
              @change="changeAffiliate"
              @visible-change="visibleChange"
            >
              <el-option
                v-for="item in optionsDomain"
                :key="item.id"
                :label="item.domain_name"
                :value="item.id"
              >
                <span class="select-label" style="float: left">
                  {{ item.domain_name }}
                </span>
                <span class="select-description" />
              </el-option>
            </el-select>
            <ui-button
              v-if="rowData.affiliate_id"
              class="btn-unpin"
              color="red"
              lib="fa"
              substyle="fas"
              icon="link"
              size="big"
              @click.native="unpin"
            >
              {{ $t(`affiliates.affiliatesInfo.unpin`) }}
            </ui-button>
          </div>
          <div class="title">
            {{ $t('domainManagement.fields.affiliate_offer_name.title') }}
          </div>
          <div
            :style="[
              isLoadingAffiliate && isLoadingOffer && { visibility: 'hidden' },
            ]"
            class="textarea-wrapper"
          >
            <el-select
              v-model="selectOffer"
              :disabled="isLoadingOffer || selectDomain === null"
              :placeholder="$t('domainManagement.popup.placeholder_offer')"
              @change="changeOffer"
            >
              <el-option
                v-for="item in optionsOffer"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
                <span class="select-label" style="float: left">
                  {{ item.name }}
                </span>
                <span class="select-description" />
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="foot">
          <div />
          <div>
            <ui-button
              color="red"
              lib="fa"
              substyle="fas"
              icon="times"
              @click.native="close"
            >
              {{ $t('reports.other.close') }}
            </ui-button>
            <ui-button
              :color="$theme.mainColor"
              :disabled="disabledConfirm"
              filled
              lib="fa"
              substyle="fas"
              icon="check"
              @click="confirm"
            >
              {{ $t('domainManagement.popup.allocate') }}
            </ui-button>
          </div>
        </div>
      </div>
      <div class="wrapper" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PinDomainToAffiliatePopup',

  props: {
    title: {
      type: String,
      default: '',
    },

    isOpenPopup: {
      type: Boolean,
      default() {
        return false;
      },
    },

    excludeHidden: {
      type: Boolean,
      default() {
        return false;
      },
    },

    error: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data() {
    return {
      isOpen: false,
      allDomains: [],
      allOffers: [],
      selectDomain: null,
      selectOffer: null,
      rowData: {},
      searchValue: '',
      isLoadingAffiliate: false,
      isLoadingOffer: false,
    };
  },

  computed: {
    optionsDomain() {
      return this.allDomains
        .filter(
          domain => domain.affiliate_offer_id === null,
        )
        .map(domain => ({
          id: domain.id,
          domain_name: domain.domain_name,
        }))
        .filter(
          domain => domain.domain_name
            .toLowerCase()
            .indexOf(this.searchValue.toLowerCase()) > -1,
        );
    },

    optionsOffer() {
      return this.allOffers.map(offer => ({
        id: offer.id,
        name: offer.full_name,
      }));
    },

    disabledConfirm() {
      return this.selectDomain === null || this.selectOffer === null;
    },
  },

  watch: {
    selectDomain(domainId, oldDomainId) {
      if (domainId) {
        this.getAffiliateOffers(domainId);
        if (oldDomainId !== null) {
          this.selectOffer = null;
        }
      }
    },
  },

  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.keyupHandler);
  },

  methods: {
    unpin() {
      this.$emit('confirm', this.rowData.id, null);
      this.close();
    },

    changeAffiliate(id) {
      this.selectDomain = id;
    },

    changeOffer(id) {
      this.selectOffer = id;
    },

    visibleChange(v) {
      if (v) this.searchValue = '';
    },

    filterMethod(data) {
      this.searchValue = data;
    },

    open(row) {
      this.rowData = this.$_.cloneDeep(row);
      this.getData();
      this.isOpen = true;
    },

    async getData() {
      await this.getDomains();
    },

    async getDomains() {
      this.isLoadingAffiliate = true;
      const params = {
        sort_column: 'domain_name',
        sort_dir: 'asc',
        search: '',
        limit: 10000,
        offset: 0,
      };
      try {
        const {
          data: { payload },
        } = await this.$api.getPlatformBettingDomain(params);
        this.allDomains = payload;
      } finally {
        this.isLoadingAffiliate = false;
      }
    },

    async getAffiliateOffers() {
      this.isLoadingOffer = true;
      try {
        const {
          data: { payload },
        } = await this.$api.getAffiliateOffers(this.rowData.id);
        this.allOffers = payload;
      } finally {
        this.isLoadingOffer = false;
      }
    },

    close() {
      this.isOpen = false;
      this.allDomains = [];
      this.allOffers = [];
      this.selectDomain = null;
      this.selectOffer = null;
      this.rowData = {};
      this.searchValue = '';
      setTimeout(() => {
        this.$emit('close');
      }, 0);
    },

    keyupHandler(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    },

    confirm() {
      this.$emit('confirm', this.selectDomain, this.selectOffer);
      this.close();
    },
  },
};
</script>


<style lang="scss" scoped>
.pin-domain-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;

  .exclamation-triangle-err {
    margin-right: 12px;
  }
  &.fade-enter-active,
  &.fade-leave-active {
    transition: all 0.4s;
    .wrapper {
      transition: all 0.4s;
    }
  }
  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
    .wrapper {
      transform: translateY(20px);
    }
  }

  .cancel-button {
    margin-right: 8px;
  }

  .popup {
    position: absolute;
    z-index: 902;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid #eaeaea;
      padding: 16px 24px;
      box-sizing: border-box;
      margin-bottom: 0;

      .name {
        font-size: 20px;
        font-weight: 500;
      }

      .close {
        color: #303634;
        padding: 5px;
        font-size: 32px;
        cursor: pointer;
      }
    }
    .body {
      padding: 16px 24px;
      box-sizing: border-box;

      .el-radio-button__inner {
        min-width: 70px;
        &:hover {
          color: #303634;
          background-color: #e8f2ee;
        }
      }

      .title {
        margin-bottom: 8px;
        font-size: 14px;
      }

      .el-radio-group {
        margin-bottom: 16px;
      }

      .textarea-wrapper {
        position: relative;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .clear-search {
          position: absolute;
          top: 12px;
          right: 25px;
          font-size: 16px;
          color: #d26370;
          cursor: pointer;
        }
      }
    }
    .foot {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      margin-top: 0;

      .ui-button {
        margin-left: 8px;
      }
    }
  }

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
</style>

<style lang="scss">
.select-metrics__popper-aff {
  .select-label {
    margin-left: 0px !important;
  }
}
.pin-domain-popup {
  .btn-unpin {
    margin-left: 8px;
  }
  .el-select {
    .el-input__inner {
      height: 34px !important;
    }
  }
  .title-body-wr {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .title {
      margin-bottom: 0 !important;
    }
  }
  .popup {
    .body {
      .el-radio-button__inner {
        min-width: 70px;
        outline: none;
        &:hover {
          color: #303634;
          background-color: #e8f2ee;
        }
      }
      .el-textarea__inner {
        resize: none;
        padding: 10px 40px 10px 10px;
      }
    }
  }
}
</style>
