<template>
  <div
    v-if="isOpen"
    class="crm-add-call-center-action-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ $t('crm.activities.actions.action_popup.call_center_title') }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div
        v-loading="isLoading"
        class="body"
      >
        <div class="tooltip-hint ui-m-xl-b">
          {{ $t('crm.activities.actions.action_popup.call_center_hint') }}
        </div>
        <span class="select-label">{{ $t('crm.activities.actions.action_popup.call_center_select') }}</span>
        <el-select
          v-model="call_center_project_id"
          filterable
          :placeholder="$t('crm.activities.actions.action_popup.call_center_select_placeholder')"
          class="project-select"
          :class="{ error: cellError }"
          @change="onChangeProject"
        >
          <el-option
            v-for="item in projectOptions"
            :key="item.name"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <div
          v-if="cellError"
          class="filter-item__field-error"
        >
          {{ cellError }}
        </div>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="isLoading"
          filled
          @click="handleSave()"
        >
          <i class="fas fa-check plus-icon-custom" />{{ $t('crm.buttons.save') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import ActivitiesLanguageMixin from '@/views/CRM/mixins/activities-language-mixin';

export default {
  name: 'AddCallCenterActionPopup',
  components: {
  },
  mixins: [
    ActivitiesLanguageMixin,
  ],
  props: {
    currentBrand: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      ...this.getDefaultForm(),
      index: -1,
      actionGroupIndex: -1,
      actionGroup: null,
      errors: [],
      call_center_project_id: '',
      projectOptions: [],
      name: '',
    };
  },

  computed: {
    cellError() {
      return this.$_.get(this.errors, [0, 'call_center_project_id'], '');
    },
  },
  watch: {
    call_center_project_id(value) {
      this.setActionsValue('call_center_project_id', value);
    },
    name(value) {
      this.setActionsValue('name', value);
    },
  },
  created() {},
  mounted() {},
  methods: {
    onChangeProject(id) {
      this.name = this.projectOptions.find(project => project.id === id).name;
    },
    open(actionGroup, payload) {
      this.$api.getCallCenterProjects({ site_id: this.currentBrand }).then((res) => {
        this.projectOptions = res.data.payload;
      });

      this.actionGroup = actionGroup;
      this.actionGroupIndex = this.activity.action_groups.indexOf(actionGroup);

      const actions = (this.activity.action_groups[this.actionGroupIndex].actions || [])
        .filter(item => item.type === 'call');

      if (!actions.length) {
        actions.push(this.getDefaultForm());
      }

      this.actions = this.$_.cloneDeep(actions);
      this.call_center_project_id = this.$_.get(this.actions, [0, 'call_center_project_id'], '');

      this.index = 0;
      this.isOpen = true;
      this.errors = [];

      if (payload?.errors?.['call']) {
        this.errors = this.actions.map((action, index) => payload.errors.call[index] || null);
      }
    },
    close() {
      this.isOpen = false;
      this.$emit('closePopupSegment');
      this.form = this.getDefaultForm();
      this.actionGroupIndex = -1;
      this.index = -1;
      this.actionGroup = null;
      this.call_center_project_id = '';
      this.name = '';
    },
    handleSave() {
      this.$emit('save', this.actionGroup, this.actions, 'call');
      this.close();
    },
    getDefaultForm() {
      return {
        name: '',
        type: 'call',
        language_code: 'en',
        is_default: true,
        call_center_project_id: '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.select-label {
  font-size: 14px;
  margin-bottom: 5px;
}
.project-select /deep/ {
  .control-wrapper {
    margin-top: 4px;
  }

  &.el-select.error .el-input.is-disabled .el-input__inner:hover,
  &.el-select.error .el-input .el-input__inner {
    border-color: #d26370!important;
  }
}

.tooltip-hint {
  font-size: 14px;
}
</style>
