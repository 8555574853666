export default {
  // offers
  getAffiliateOffers(id, params) {
    return this.affiliatesApi.get(`/affiliate-offers?affiliate_id=${id}`, { params });
  },
  getAffiliateOffer(id) {
    return this.affiliatesApi.get(`/affiliate-offers/${id}`);
  },
  getAffiliateOfferBeforeDelete(id) {
    return this.affiliatesApi.get(`/affiliate-offers/${id}/before-delete`);
  },
  unpinAffiliateOffer(id, v) {
    return this.affiliatesApi.delete(`/affiliate-offers/${id}`, { data: v });
  },
  getOffer(id) {
    return this.affiliatesApi.get(`/offers/${id}`);
  },
  getOffers() {
    return this.affiliatesApi.get('/offers');
  },
  addAffiliateOffer(payload) {
    return this.affiliatesApi.post('/affiliate-offers', payload);
  },
  putAffiliateOffer(id, payload) {
    return this.affiliatesApi.put(`/affiliate-offers/${id}`, payload);
  },
  getAffiliateOfferTemplates() {
    return this.affiliatesApi.get('/affiliate-offer-templates');
  },
  cancelScheduledChanges(id) {
    return this.affiliatesApi.post(`/affiliate-offers/${id}/cancel-scheduled-changes`);
  },
};
