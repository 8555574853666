<template>
  <span
    class="ui-tag"
    :class="{
      'ui-tag_medium': size === 'medium',
      'ui-tag_large': size === 'large',
    }"
    @click="$emit('click', $event)"
  >
    <span class="ui-tag__prepend">
      <slot name="prepend">
        <ui-icon
          v-if="isIcon"
          class="ui-tag__icon"
          :size="pSize"
          lib="lib"
          substyle="fas"
          :name="icon"
          color=" "
        />
        <div v-else>{{ icon }}</div>
      </slot>
    </span>
    <span class="ui-tag__title">
      <slot />
    </span>
  </span>
</template>


<script>
export default {
  props: {
    icon: {
      type: String,
      default: undefined,
    },
    lib: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
  computed: {
    isIcon() {
      return this.lib !== 'utf' && this.lib !== 'none';
    },
    pSize() {
      switch (this.size) {
      case 'large':
        return 12;
      default:
      case 'medium':
        return 10;
      }
    },
  },
};
</script>


<style lang="scss">
.ui-tag {
  display: inline-flex;
  padding: 0 8px;
  align-items: center;
  background-color: var(--primary-color);
  color: #ffffff;

  &_medium {
    font-size: 10px;
    border-radius: 8px;
    line-height: 16px;
  }

  &_large {
    font-size: 12px;
    line-height: 21px;
    border-radius: 10.5px;
  }

  &__prepend {
    line-height: 10px;
    margin-right: 4px;
  }

  &__icon {
    font-family: 'Font Awesome 6 Pro';
    font-weight: 900;
  }

  &__title {
    font-weight: 500;
  }
}
</style>
