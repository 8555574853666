<template>
  <div>
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirm.type"
      :action-name="confirm.actionName"
      :action="confirm.action"
      :action-fa-icon="confirm.icon"
      @save="signOut"
    >
      <div slot="title">
        {{ confirm.title }}
      </div>
      <div class="body__wrap">
        <i
          :class="confirm.type"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirm.msg"
        />
      </div>
    </ui-confirm>
    <sensitive-check />
    <adm-ui-the-header
      :class="[{'is-firefox': isFirefox}, {'is-windows': detectOS === 'Windows'}]"
      :main-menu="mainMenuFiltered"
      :logo="logo"
      :user="user"
      :min-width-header="minWidthHeader"
      :locale="locale"
      :settings-visible="true"
      :admin-acl="adminAcl"
      :current-acl="currentAcl"
      :admin-teams="adminTeams"
      :admin-info="adminInfo"
      :current-team="currentTeam"
      :current-route="currentRoute"
      :current-route-key="currentRouteKey"
      @setLanguage="setLanguage"
      @accessHandler="checkSensitiveAction(lockStatus === 'unlock' ? 'lock' : 'unlock')"
      @changeTeam="changeTeam"
      @signOut="confirmSignOut"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import changeLocaleFork from '@/helpers/changeLocaleFork';
import { I18N_SET_LANG } from '@/store/action_types/i18n';
import { version } from '../../package.json';
import SensitiveCheck from '@/components/sensitiveCheck';
import isFirefox from '@/helpers/is-firefox';
import detectOS from '@/helpers/detect-os';
import router from '@/router';
import {
  AUTH_LOGOUT,
  AUTH_ADMIN_INFO,
  AUTH_CURRENT_TEAM,
  AUTH_ADMIN_ACL,
  AUTH_2FA_SUCCESS,
  AUTH_SUCCESS,
} from '@/store/action_types/auth';

import { MISC_INIT_COUNTERS, MISC_INIT_DATA, MISC_FETCH_BRANDS } from '@/store/action_types/misc';


// eslint-disable-next-line
const getUrlLogo = logoName => require(`@/assets/images/logos/${logoName}`);
// eslint-disable-next-line
const getUrlFlag = flagName => require(`@/assets/images/flag/${flagName}`);

export default {
  name: 'TheHeader',

  components: {
    SensitiveCheck,
  },

  data() {
    return {
      detectOS,
      isFirefox,
      minWidthHeader: '1140',

      logo: {
        url: getUrlLogo(this.$theme.logoName),
        maxLogoHeight: this.$theme.maxLogoHeight,
        click: () => this.reloadApp(),
      },

      confirm: {
        title: '',
        icon: '',
        msg: '',
        type: 'warning',
        action: '',
        actionName: '',
      },
    };
  },

  computed: {
    ...mapGetters('dashboards', ['dashboards', 'overviewDashboard']),

    lockStatus() {
      return typeof this.$store.getters['session/significantActionsSeconds']
        !== 'number'
        || this.$store.getters['session/significantActionsSeconds'] === 0
        ? 'lock'
        : 'unlock';
    },

    user() {
      return {
        version,
        accessRights: this.lockStatus,
        profileRoute: '/profile',
        languageTitle: this.$t('theHeader.language.language'),
        profileTitle: this.$t('theHeader.profile.main'),
        allTeamsTitle: this.$t('theHeader.profile.all_teams'),
        affiliateProgramTitle: this.$t('theHeader.menu.settings.affiliateProgram'),
        configAffiliateProgram: [
          ...(this.adminAcl.is_superuser ? [
            {
              title: this.$t('theHeader.menu.settings.currencies'),
              code: 'currencies',
              route: '/settings/currencies',
            },
            {
              title: this.$t('theHeader.menu.settings.fees'),
              code: 'fees',
              route: '/settings/fees',
            },
            {
              title: this.$t('theHeader.menu.settings.offersCountryGroups'),
              code: 'offersCountryGroups',
              route: '/settings/offers-country-groups',
            },
            {
              title: this.$t('theHeader.menu.settings.paymentMethod'),
              code: 'paymentMethods',
              route: '/settings/payment-methods',
            },
            {
              title: this.$t('theHeader.menu.settings.predefinedPage'),
              code: 'predefinedPage',
              route: '/settings/predefined-page',
            },
          ] : []),
          {
            title: this.$t('theHeader.menu.settings.domains'),
            code: 'domains',
            route: '/settings/sites',
          },
          ...(this.adminAcl.is_superuser ? [
            {
              title: this.$t('theHeader.menu.settings.systemDomains'),
              code: 'systemDomains',
              route: '/settings/system-domains',
            },
            {
              title: this.$t('theHeader.menu.settings.systemSettings'),
              code: 'systemSettings',
              route: '/settings/system-settings',
            },
            {
              title: this.$t('settings.tokens.title'),
              code: 'tokenManagement',
              route: '/settings/token-management',
            },
          ] : []),
          {
            title: this.$t('theHeader.menu.settings.mediaBuying'),
            code: 'mediaBuying',
            route: '/settings/media-buying',
          },
        ],
        configLanguage: [
          {
            code: 'en',
            title: this.$t('theHeader.language.en'),
            image: getUrlFlag('en.png'),
          },
          {
            code: 'ru',
            title: this.$t('theHeader.language.ru'),
            image: getUrlFlag('ru.png'),
          },
        ],
        logout: this.$t('theHeader.profile.logout'),
      };
    },

    locale() {
      return this.$i18n.locale;
    },

    adminAcl() {
      return this.$store.getters['auth/adminAcl'];
    },

    adminTeams() {
      return this.adminAcl.teams ? this.adminAcl.teams : [];
    },

    adminInfo() {
      return this.$store.getters['auth/adminInfo'];
    },

    currentAcl() {
      return this.$store.getters['auth/currentAcl'];
    },

    crmAllow() {
      return (this.$store.getters['auth/currentAcl'].crm_segments_view && this.$store.getters['auth/currentAcl'].crm_segments_view !== 'deny') || (this.$store.getters['auth/currentAcl'].crm_activities_view && this.$store.getters['auth/currentAcl'].crm_activities_view !== 'deny');
    },
    currentTeam() {
      if (this.adminTeams && this.$store.getters['auth/currentTeam']) {
        const team = this.$_.find(
          this.adminTeams, ['id', this.$store.getters['auth/currentTeam']],
        );
        return team || { name: this.$t('theHeader.profile.all_teams') };
      }
      return { name: this.$t('theHeader.profile.all_teams') };
    },

    currentRoute() {
      const currentRoute = this.$store.getters['nav/getMainView'];
      return this.$t(`theHeader.menu.${currentRoute}.main`);
    },

    currentRouteKey() {
      return this.$store.getters['nav/getMainView'];
    },

    mainMenu() {
      const overviewDashboard = this.overviewDashboard.map(select => ({
        name: select.name,
        icon: {
          size: 12,
          name: ['fas', 'fa-home'],
        },
        link: '/dashboards/overview',
        notifications: [],
      }));

      const dashboards = this.dashboards.map(select => ({
        name: select.name,
        link: `/dashboards/view/${select.id}`,
        notifications: [],
      }));

      return [
        {
          name: this.$t('theHeader.menu.dashboards.main'),
          link: '/dashboards',
          permission: 'reports_view',
          notifications: [],
          sections: [
            {
              title: '',
              subItems: overviewDashboard,
            },
            {
              title: '',
              subItems: dashboards,
            },
            {
              title: '',
              subItems: [
                {
                  name: this.$t('theHeader.menu.dashboards.add_dashboard'),
                  icon: {
                    size: 12,
                    name: ['fas', 'fa-plus'],
                  },
                  link: '/dashboards/add-dashboard',
                  notifications: [],
                },
                // {
                //   name: this.$t('theHeader.menu.dashboards.templates'),
                //   icon: {
                //     size: 12,
                //     name: ['fas', 'fa-columns'],
                //   },
                //   link: '/dashboards/template',
                //   notifications: [],
                // },
              ],
            },
          ],
        },
        {
          name: this.$t('theHeader.menu.live.main'),
          link: '/live',
          notifications: [],
          sections: [
            {
              subItems: [
                {
                  name: this.$t('theHeader.menu.live.last-bets'),
                  link: '/live/last-bets',
                  notifications: [],
                  permission: 'last_bets_and_deposits_view',
                },
                {
                  name: this.$t('theHeader.menu.live.top-events'),
                  link: '/live/top-events',
                  notifications: [],
                  permission: 'top_events_view',
                },
              ],
            },
          ],
        },
        {
          name: this.$t('theHeader.menu.reports.main'),
          link: '/reports/custom',
          permission: 'reports_view',
          notifications: [],
          sections: [
            {
              subItems: [
                {
                  name: this.$t('theHeader.menu.reports.report'),
                  link: '/reports/custom',
                  notifications: [],
                },
                {
                  name: this.$t('theHeader.menu.reports.paymentMethod'),
                  link: '/reports/payments',
                  permission: 'reports_group_by_payments',
                  notifications: [],
                },
              ],
            },
          ],
        },
        {
          name: this.$t('theHeader.menu.affiliates.main'),
          link: '/affiliates/list',
          permission: 'affiliates_view',
          notifications: [
            {
              count: this.$store.getters['misc/affiliatesTotal'],
            },
          ],
          sections: [
            {
              subItems: [
                {
                  name: this.$t('theHeader.menu.affiliates.list'),
                  link: '/affiliates/list',
                  notifications: [],
                },
                {
                  name: this.$t('theHeader.menu.affiliates.review'),
                  link: '/affiliates/review',
                  notifications: [
                    {
                      count: this.$store.getters['misc/affiliatesReviews'],
                    },
                  ],
                },
                {
                  name: this.$t('theHeader.menu.affiliates.notifications'),
                  link: '/affiliates/notification',
                  notifications: [],
                  permission: 'affiliates_notifications_view',
                },
              ],
            },
          ],
        },
        {
          name: this.$t('theHeader.menu.payments.main'),
          link: '/payments',
          permission: 'payments_view',
          notifications: [
            {
              count: this.$store.getters['misc/paymentsReviews'],
            },
            {
              style: 'red',
              count: this.$store.getters['misc/paymentsForRework'],
            },
            {
              style: 'yellow',
              count: this.$store.getters['misc/paymentsNotPaid'],
            },
          ],
          sections: [
            {
              subItems: [
                {
                  name: this.$t('theHeader.menu.payments.dashboard'),
                  link: '/payments/dashboard',
                  notifications: [],
                },
              ],
            },
            {
              title: this.$t(
                'theHeader.menu.payments.payment_moderation_section',
              ),
              subItems: [
                {
                  name: this.$t('theHeader.menu.payments.on_review'),
                  link: '/payments/moderation/on_review',
                  notifications: [
                    {
                      count: this.$store.getters['misc/paymentsReviews'],
                    },
                  ],
                },
                {
                  name: this.$t('theHeader.menu.payments.approved'),
                  link: '/payments/moderation/approved',
                  notifications: [],
                },
                {
                  name: this.$t('theHeader.menu.payments.declined'),
                  link: '/payments/moderation/declined',
                  notifications: [],
                },
              ],
            },
            {
              title: this.$t('theHeader.menu.payments.payment_payout_section'),
              subItems: [
                {
                  name: this.$t('theHeader.menu.payments.not_paid'),
                  link: '/payments/payout/not_paid',
                  notifications: [
                    {
                      count: this.$store.getters['misc/paymentsNotPaid'],
                      style: 'yellow',
                    },
                  ],
                },
                {
                  name: this.$t('theHeader.menu.payments.for_rework'),
                  link: '/payments/payout/for_rework',
                  notifications: [
                    {
                      count: this.$store.getters['misc/paymentsForRework'],
                      style: 'red',
                    },
                  ],
                },
                {
                  name: this.$t('theHeader.menu.payments.processing'),
                  link: '/payments/payout/processing',
                  notifications: [],
                },
                {
                  name: this.$t('theHeader.menu.payments.paid'),
                  link: '/payments/payout/paid',
                  notifications: [],
                },
                {
                  name: this.$t('theHeader.menu.payments.failed'),
                  link: '/payments/payout/failed',
                  notifications: [],
                },
              ],
            },
            {
              title: this.$t('theHeader.menu.payments.payment_cpa_section'),
              subItems: [
                {
                  name: this.$t('theHeader.menu.payments.cpa_conversions'),
                  link: '/payments/cpa-conversions',
                  notifications: [],
                },
              ],
            },
          ],
        },
        {
          name: this.$t('theHeader.menu.players.main'),
          link: '/players',
          permission: 'players_view',
          showCondition: (/* item */) => this.adminAcl.is_superuser
            || this.$store.getters['auth/currentAcl'].players_view === 'allow',
          notifications: [],
          sections: [],
        },
        {
          name: this.$t('theHeader.menu.tools.main'),
          link: '/tools',
          notifications: [],
          sections: [
            {
              title: this.$t('theHeader.menu.tools.promo-codes'),
              subItems: [
                {
                  name: this.$t('theHeader.menu.tools.promoChecker'),
                  link: '/tools/promo-checker',
                  notifications: [],
                  permission: 'tools_promo_codes_checker_view',
                },
              ],
            },
            {
              title: this.$t('theHeader.menu.tools.dms'),
              subItems: [
                {
                  name: this.$t('theHeader.menu.tools.rotation_groups'),
                  link: '/tools/rotation-groups',
                  notifications: [],
                  permission: 'service_rotation_groups_view',
                },
                {
                  name: this.$t('theHeader.menu.tools.service_domains'),
                  link: '/tools/service-domains',
                  notifications: [],
                  permission: 'platform_service_domains_view',
                },
                {
                  name: this.$t('theHeader.menu.tools.servers'),
                  link: '/tools/servers',
                  notifications: [],
                  permission: 'platform_servers_view',
                },
                {
                  name: this.$t('theHeader.menu.tools.dns_hosting_accounts'),
                  link: '/tools/dns-hosting-accounts',
                  notifications: [],
                  permission: 'dns_hosting_accounts_view',
                },
                {
                  name: this.$t(
                    'theHeader.menu.tools.domain_registrar_accounts',
                  ),
                  link: '/tools/domain-registrar-accounts',
                  notifications: [],
                  permission: 'domain_registrar_accounts_view',
                },
                {
                  name: this.$t('theHeader.menu.tools.dedicated_site_domains'),
                  link: '/tools/dedicated-site-domains',
                  notifications: [],
                  permission: 'platform_betting_domains_view',
                },
              ],
            },
            {
              title: this.$t('theHeader.menu.tools.promo-materials'),
              subItems: [
                {
                  name: this.$t('theHeader.menu.tools.marketing-materials'),
                  link: '/tools/marketing-materials',
                  permission: 'marketing_materials_view',
                  notifications: [],
                },
                {
                  name: this.$t('theHeader.menu.tools.landingLeagues'),
                  link: '/tools/landing-leagues',
                  notifications: [],
                  permission: 'tools_promo_landings_view',
                },
              ],
            },
          ],
        },
        {
          name: this.$t('theHeader.menu.teams.main'),
          link: '/teams',
          permission: 'teams_view',
          showCondition: (/* item */) => this.adminAcl.is_superuser,
          notifications: [],
          sections: [],
        },
        {
          name: this.$t('theHeader.menu.media-buying.main'),
          notifications: [
            {
              count:
                this.$store.getters[
                  'misc/mediaBuyingPaymentsModerationReviews'
                ]
                + this.$store.getters['misc/mediaBuyingPaymentsPayoutNotPaid']
                + this.$store.getters['misc/mediaBuyingPostsReviews'],
              style: 'red',
            },
            {
              style: 'yellow',
              count:
                this.$store.getters[
                  'misc/mediaBuyingPaymentsModerationRework'
                ]
                + this.$store.getters['misc/mediaBuyingPaymentsPayoutRework']
                + this.$store.getters['misc/mediaBuyingPostsRework'],
            },
            {
              style: 'white',
              count: this.$store.getters[
                'misc/mediaBuyingPaymentsPayoutProcessing'
              ],
            },
          ],
          showCondition: (/* item */) => this.adminAcl.is_superuser,
          sections: [
            {
              subItems: [
                {
                  name: this.$t('theHeader.menu.media-buying.blacklist'),
                  link: '/media-buying/blacklist',
                  notifications: [],
                  permission: 'media_buying_payment_methods_blacklist_view',
                },
              ],
            },
            {
              title: this.$t('theHeader.menu.media-buying.reports_section'),
              subItems: [
                {
                  name: this.$t('theHeader.menu.media-buying.summary_reports'),
                  link: '/media-buying/reports',
                  permission: 'media_buying_reports_view',
                  notifications: [],
                },
                {
                  name: this.$t('theHeader.menu.media-buying.unreported_funds'),
                  link: '/media-buying/unreported-funds',
                  permission: 'media_buying_reports_view',
                  notifications: [],
                },
              ],
            },
            {
              title: this.$t(
                'theHeader.menu.media-buying.payment_moderation_section',
              ),
              permission: 'media_buying_payments_view',
              subItems: [
                {
                  name: this.$t('theHeader.menu.media-buying.on_review'),
                  permission:
                    'media_buying_payments_moderation_statuses_visible',
                  subPermission: 'on_review',
                  link: '/media-buying/payments-moderation/on-review',
                  notifications: [
                    {
                      count: this.$store.getters[
                        'misc/mediaBuyingPaymentsModerationReviews'
                      ],
                      style: 'red',
                    },
                  ],
                },
                {
                  name: this.$t('theHeader.menu.media-buying.for_rework'),
                  permission:
                    'media_buying_payments_moderation_statuses_visible',
                  subPermission: 'for_rework',
                  link: '/media-buying/payments-moderation/for-rework',
                  notifications: [
                    {
                      style: 'yellow',
                      count: this.$store.getters[
                        'misc/mediaBuyingPaymentsModerationRework'
                      ],
                    },
                  ],
                },
                {
                  name: this.$t('theHeader.menu.media-buying.approved'),
                  permission:
                    'media_buying_payments_moderation_statuses_visible',
                  subPermission: 'approved',
                  link: '/media-buying/payments-moderation/approved',
                  notifications: [],
                },
                {
                  name: this.$t('theHeader.menu.media-buying.declined'),
                  permission:
                    'media_buying_payments_moderation_statuses_visible',
                  subPermission: 'declined',
                  link: '/media-buying/payments-moderation/declined',
                  notifications: [],
                },
              ],
            },
            {
              title: this.$t(
                'theHeader.menu.media-buying.payment_payout_section',
              ),
              permission: 'media_buying_payments_view',
              subItems: [
                {
                  name: this.$t('theHeader.menu.media-buying.not_paid'),
                  permission: 'media_buying_payments_payout_statuses_visible',
                  subPermission: 'not_paid',
                  link: '/media-buying/payments-payout/not-paid',
                  notifications: [
                    {
                      count: this.$store.getters[
                        'misc/mediaBuyingPaymentsPayoutNotPaid'
                      ],
                      style: 'red',
                    },
                  ],
                },
                {
                  name: this.$t('theHeader.menu.media-buying.for_rework'),
                  permission: 'media_buying_payments_payout_statuses_visible',
                  subPermission: 'for_rework',
                  link: '/media-buying/payments-payout/for-rework',
                  notifications: [
                    {
                      style: 'yellow',
                      count: this.$store.getters[
                        'misc/mediaBuyingPaymentsPayoutRework'
                      ],
                    },
                  ],
                },
                {
                  name: this.$t('theHeader.menu.media-buying.processing'),
                  permission: 'media_buying_payments_payout_statuses_visible',
                  subPermission: 'processing',
                  link: '/media-buying/payments-payout/processing',
                  notifications: [
                    {
                      style: 'white',
                      count: this.$store.getters[
                        'misc/mediaBuyingPaymentsPayoutProcessing'
                      ],
                    },
                  ],
                },
                {
                  name: this.$t('theHeader.menu.media-buying.paid_out'),
                  permission: 'media_buying_payments_payout_statuses_visible',
                  subPermission: 'paid',
                  link: '/media-buying/payments-payout/paid',
                  notifications: [],
                },
                {
                  name: this.$t('theHeader.menu.media-buying.failed'),
                  permission: 'media_buying_payments_payout_statuses_visible',
                  subPermission: 'failed',
                  link: '/media-buying/payments-payout/failed',
                  notifications: [],
                },
              ],
            },
            {
              title: this.$t('theHeader.menu.media-buying.posts_section'),
              permission: 'media_buying_posts_view',
              subItems: [
                {
                  name: this.$t('theHeader.menu.media-buying.on_review'),
                  permission: 'media_buying_posts_statuses_visible',
                  subPermission: 'on_review',
                  link: '/media-buying/posts/on-review',
                  notifications: [
                    {
                      count: this.$store.getters[
                        'misc/mediaBuyingPostsReviews'
                      ],
                      style: 'red',
                    },
                  ],
                },
                {
                  name: this.$t('theHeader.menu.media-buying.for_rework'),
                  permission: 'media_buying_posts_statuses_visible',
                  subPermission: 'for_rework',
                  link: '/media-buying/posts/for-rework',
                  notifications: [
                    {
                      style: 'yellow',
                      count: this.$store.getters['misc/mediaBuyingPostsRework'],
                    },
                  ],
                },
                {
                  name: this.$t('theHeader.menu.media-buying.approved'),
                  permission: 'media_buying_posts_statuses_visible',
                  subPermission: 'approved',
                  link: '/media-buying/posts/approved',
                  notifications: [],
                },
                {
                  name: this.$t('theHeader.menu.media-buying.declined'),
                  permission: 'media_buying_posts_statuses_visible',
                  subPermission: 'declined',
                  link: '/media-buying/posts/declined',
                  notifications: [],
                },
              ],
            },
          ],
        },
        {
          name: this.$t('theHeader.menu.crm.main'),
          link: '/crm',
          showCondition: (/* item */) => this.adminAcl.is_superuser || this.crmAllow,
          notifications: [],
          sections: [],
        },
      ];
    },

    mainMenuFiltered() {
      return this.mainMenu
        .filter((item) => {
          const commonCondition = ((this.$store.getters['auth/currentAcl'][item.permission]
              === 'allow'
              || !item.permission)
              && !this.adminAcl.is_superuser)
            || this.adminAcl.is_superuser;
          const alwaysShowCondition = item.showCondition && item.showCondition(item);
          return commonCondition || alwaysShowCondition;
        })
        .map(item => ({
          ...item,
          sections: item.sections
            .filter(
              section => ((this.$store.getters['auth/currentAcl'][section.permission]
                  === 'allow'
                  || !section.permission)
                  && !this.adminAcl.is_superuser)
                || this.adminAcl.is_superuser,
            )
            .map(section => ({
              ...section,
              subItems: section.subItems.filter((subItem) => {
                const isArray = Array.isArray(
                  this.$store.getters['auth/currentAcl'][subItem.permission],
                );
                let isIncludeItem = false;
                if (isArray) {
                  const permissionArray = this.$store.getters[
                    'auth/currentAcl'
                  ][subItem.permission];
                  const permissionArrayItem = subItem.subPermission;
                  isIncludeItem = permissionArray.includes(permissionArrayItem);
                }
                return (
                  this.adminAcl.is_superuser
                  || !subItem.permission
                  || this.$store.getters['auth/currentAcl'][subItem.permission]
                    === 'allow'
                  || (isArray && isIncludeItem)
                );
              }),
            }))
            .filter(section => section.subItems.length > 0),
        }))
        .filter((item) => {
          const alwaysShowCondition = item.showCondition && item.showCondition(item);
          const subSectionsCount = item.sections
            .map(section => section.subItems.length)
            .reduce((a, b) => a + b, 0);
          return subSectionsCount > 0 || alwaysShowCondition;
        });
    },
  },

  created() {
    this.$eventBus.$on('lock', this.lock);
    this.$eventBus.$on('teamsRoute', this.teamsRoute);
    this.$eventBus.$on('tokenManagementRoute', this.tokenManagementRoute);
    this.$eventBus.$on('feesRoute', this.feesRoute);
    this.$eventBus.$on('paymentMethodsRoute', this.paymentMethodsRoute);
    this.$eventBus.$on('systemSettings', this.systemSettings);
    this.$eventBus.$on('systemDomains', this.systemDomains);
    this.$eventBus.$on('predefinedPage', this.predefinedPage);
    this.$eventBus.$on('offersCountryGroups', this.offersCountryGroups);
    this.$eventBus.$on('currenciesRoute', this.currenciesRoute);
    this.$eventBus.$on('mediaBuyingRoute', this.mediaBuyingPaymentMethodsRoute);
  },

  beforeDestroy() {
    this.$eventBus.$off('lock', this.lock);
    this.$eventBus.$off('teamsRoute', this.teamsRoute);
    this.$eventBus.$off('tokenManagementRoute', this.tokenManagementRoute);
    this.$eventBus.$off('feesRoute', this.feesRoute);
    this.$eventBus.$off('paymentMethodsRoute', this.paymentMethodsRoute);
    this.$eventBus.$off('systemDomains', this.systemDomains);
    this.$eventBus.$off('predefinedPage', this.predefinedPage);
    this.$eventBus.$off('offersCountryGroups', this.offersCountryGroups);
    this.$eventBus.$off('currenciesRoute', this.currenciesRoute);
    this.$eventBus.off('mediaBuyingRoute', this.mediaBuyingPaymentMethodsRoute);
  },

  methods: {
    async setLanguage(lang) {
      changeLocaleFork(lang);
      await this.$store.dispatch(`i18n/${I18N_SET_LANG}`, lang);
      this.$api.postLanguage(lang);

      if (this.$route.path !== '/reports/custom') {
        /* TODO: поправить timeout */
        setTimeout(this.getSetting);
      }
      if (this.$route.path === '/reports/custom') {
        this.$eventBus.$emit('changeLocale', lang);
      }
      setTimeout(() => this.$eventBus.$emit('changeLocaleGlobal', lang));
    },

    async getSetting() {
      await this.$store.dispatch('reports/getSettings');
      this.$eventBus.$emit('changeLocale');
    },

    async changeTeam(id) {
      localStorage.setItem('active-team', id || '');
      await this.reloadApp();
    },

    async reloadApp() {
      this.$store.commit(`auth/${AUTH_CURRENT_TEAM}`, localStorage.getItem('active-team'));

      await this.$store.dispatch(`auth/${AUTH_ADMIN_ACL}`);
      await Promise.all([
        this.$store.dispatch('session/initialSessionData'),
        this.$store.dispatch(`misc/${MISC_FETCH_BRANDS}`),
        this.$store.dispatch(`auth/${AUTH_ADMIN_INFO}`),
        this.$store.dispatch(`misc/${MISC_INIT_DATA}`),
        this.$store.dispatch(`misc/${MISC_INIT_COUNTERS}`, false),
      ]);
      this.$store.commit(`auth/${AUTH_SUCCESS}`);
      this.$store.commit(`auth/${AUTH_2FA_SUCCESS}`);
      this.$store.commit('other/uuidRender');
    },

    checkSensitiveAction(action) {
      this.$eventBus.$emit('checkSensitiveAction', action);
    },

    async lock() {
      this.$store.dispatch('session/getSessionAuth');
      await this.$api.significant('lock');
      this.$store.dispatch('session/lockSession');
    },

    teamsRoute() {
      router.push('/teams');
    },

    tokenManagementRoute() {
      router.push('/settings/token-management');
    },

    predefinedPage() {
      router.push('/settings/predefined-page');
    },

    offersCountryGroups() {
      router.push('/settings/offers-country-groups');
    },

    currenciesRoute() {
      router.push('/settings/currencies');
    },

    feesRoute() {
      router.push('/settings/fees');
    },

    paymentMethodsRoute() {
      router.push('/settings/payment-methods');
    },

    systemSettings() {
      router.push('/settings/system-settings');
    },

    systemDomains() {
      router.push('/settings/system-domains');
    },

    mediaBuyingPaymentMethodsRoute() {
      router.push('/settings/media-buying');
    },

    confirmSignOut() {
      this.confirm.title = this.$t('theHeader.confirm.title');
      this.confirm.msg = this.$t('theHeader.confirm.msg');
      this.confirm.type = 'warning';
      this.confirm.action = 'Sign Out';
      this.confirm.icon = 'sign-out';
      this.confirm.actionName = this.$t('theHeader.confirm.action');
      this.$refs.confirm.open();
    },

    signOut() {
      this.$store.dispatch('session/clearTimers');
      this.$store
        .dispatch(`auth/${AUTH_LOGOUT}`)
        .then(() => {
          this.$router.push({
            name: 'login',
            params: {
              action: 'auth',
            },
          });
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';
.settings-wrapper {
  position: relative;
}
</style>
