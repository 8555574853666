<template>
  <div class="permission-row permission-tree">
    <el-checkbox
      v-model="teammatesAffiliatesActive"
      :disabled="disabled"
      class="checkbox"
    >
      {{ $t(`permissions.teammates_affiliates`) }}
    </el-checkbox>
    <div class="field permission-tree__branch">
      <el-radio-group
        v-model="teammatesAffiliatesType"
        :disabled="!teammatesAffiliatesActive || disabled"
      >
        <div class="permission-row">
          <el-radio
            label="teammates_affiliates"
            class="checkbox"
          >
            {{ $t(`permissions.allow_all`) }}
          </el-radio>
          <el-radio
            label="teammates_affiliates_only_selected"
            class="checkbox"
          >
            {{ $t(`permissions.allow_selected`) }}
          </el-radio>
          <el-radio
            label="teammates_affiliates_except_selected"
            class="checkbox"
          >
            {{ $t(`permissions.deny_selected`) }}
          </el-radio>
        </div>
      </el-radio-group>
      <ui-tags-input
        :disabled="!teammatesAffiliatesActive || teammatesAffiliatesType === 'teammates_affiliates' || disabled"
        :value="pTeammatesIds"
        :typeahead-activation-threshold="0"
        :only-existing-tags="true"
        :existing-tags="usersList || {}"
        :typeahead="true"
        :placeholder="$t(`permissions.add_teammates`)"
        id-field="id"
        text-field="name"
        class="card-filter__input-tag card-filter__input-tag--z"
        typeahead-style="dropdown"
        @input="$emit('update:teammatesIds', $event )"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'PermissionTree',

  props: {
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    affiliatesTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    teammatesIds: {
      type: Array,
      default() {
        return [];
      },
    },
    usersList: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      teammatesAffiliatesActive: false,
      teammatesAffiliatesType: '',
      modeArray: ['teammates_affiliates', 'teammates_affiliates_only_selected', 'teammates_affiliates_except_selected'],
    };
  },

  computed: {
    pTeammatesIds() {
      return this.usersList === null ? [] : this.teammatesIds;
    },
  },

  watch: {
    affiliatesTypes: {
      handler(value) {
        const curAffiliatesTypes = this.$_.cloneDeep(value);
        const intersection = this.modeArray.filter(modeItem => curAffiliatesTypes.includes(modeItem));
        if (intersection.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.teammatesAffiliatesType = intersection[0];
          this.teammatesAffiliatesActive = true;
        } else {
          this.teammatesAffiliatesActive = false;
        }
      },
      immediate: true,
    },
    teammatesAffiliatesType: {
      handler(value) {
        this.clear();
        if (value) {
          this.affiliatesTypes.push(value);
          if (value === 'teammates_affiliates') {
            this.$emit('update:teammatesIds', []);
          }
        }
      },
      immediate: true,
    },
    teammatesAffiliatesActive: {
      handler(value) {
        if (!value) {
          this.teammatesAffiliatesType = '';
          this.$emit('update:teammatesIds', []);
        } else if (!this.teammatesAffiliatesType) {
          this.teammatesAffiliatesType = 'teammates_affiliates';
        }
      },
      immediate: true,
    },
  },

  methods: {
    clear() {
      this.modeArray.forEach((item) => {
        const index = this.affiliatesTypes.indexOf(item);
        if (index >= 0) {
          this.affiliatesTypes.splice(index, 1);
        }
      });
    },
  },
};

</script>
