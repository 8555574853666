<template>
  <div
    v-if="isOpen"
    class="crm-activity-metrics-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ $t('crm.activities.actions.action_popup.add_metrics_title') }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>

      <div
        v-loading="isLoading"
        class="body"
      >
        <div :class="{ 'is-show': false }" class="columns-popup__wr ui-fg-1">
          <div
            class="crm-activity-metrics-popup__content columns-popup__left ui-fg-1"
            :class="{
              'crm-activity-metrics-popup__content_no-data': noData,
            }"
          >
            <!-- <div class="columns-popup__title">
              {{ $t('dashboards.popup_column.select_metric') }}
            </div> -->
            <div class="controls ui-m-lg-b">
              <ui-input
                v-model="search"
                :placeholder="$t('reports.colunms_settings.placeholder_search')"
                width="100%"
                pre-icon="search"
                class="search-input"
              />
            </div>

            <el-collapse
              v-model="activeNames"
              style="overflow: visible;"
            >
              <div v-if="noData" class="field__empty-text">
                <ui-icon
                  :size="32"
                  name="folder-open"
                  lib="fa"
                  substyle="fal"
                  color="#a6b2c4"
                />
                <div>{{ $t('reports.colunms_settings.no_data') }}</div>
              </div>
              <!-- <el-collapse-item
                v-for="group in columnGroup"
                :key="group.key"
                :class="{
                  'hide-search': isHiddenEntityIndex[group.key],
                }"
                :name="group.group_name"
              >
                <template slot="title">
                  <div class="columns-popup__col-header">
                    <div class="columns-popup__sub-wr">
                      <span>
                        <span class="columns-popup__sub-title">
                          {{ group.title }}
                        </span>
                      </span>
                    </div>
                    <span class="columns-popup__count-col is-right">
                      {{
                        $t(
                          `reports.colunms_settings.${callapseLabel(
                            group.group_name
                          )}`
                        )
                      }}
                    </span>
                  </div>
                </template>
                <div class="wrap-item">
                  <div
                    v-for="metric in group.items"
                    :key="metric.key"
                    :class="{
                      'hide-search': isHiddenEntityIndex[metric.key],
                    }"
                  >
                    <div class="item">
                      <div
                        :class="{'checked': selectedMetricsIndex[metric.name] }"
                        class="checkbox checkbox-custom"
                        @click="handleSelectMetric(metric.name)"
                      >
                        <div class="box">
                          <ui-icon
                            :name="'check'"
                            :size="8"
                            lib="fa"
                            substyle="fas"
                            class="chk-icon"
                          />
                        </div>
                        <span class="label">{{ metric.title }}</span>
                      </div>
                      <span class="description">
                        {{ metric.description }}
                      </span>
                    </div>

                    <div v-if="$_.has(metric, 'items')">
                      <div
                        v-for="submetric in metric.items"
                        :key="submetric.key"
                        class="item item-sub"
                        :class="{
                          'hide-search': isHiddenEntityIndex[submetric.key],
                        }"
                      >
                        <div
                          :class="{'checked': selectedMetricsIndex[submetric.name] }"
                          class="checkbox checkbox-custom"
                          @click="handleSelectMetric(submetric.name)"
                        >
                          <div class="box">
                            <ui-icon
                              :name="'check'"
                              :size="8"
                              lib="fa"
                              substyle="fas"
                              class="chk-icon"
                            />
                          </div>
                          <span class="label">{{ submetric.title }}</span>
                        </div>
                        <span class="description">
                          {{ submetric.description }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </el-collapse-item> -->
              <div class="wrap-item el-collapse-item">
                <div
                  v-for="(metric, index) in columnGroup[0].items"
                  :key="metric.key"
                  :class="{
                    'hide-search': isHiddenEntityIndex[metric.key],
                  }"
                >
                  <div
                    class="item"
                    :class="{
                      'item_last': index === columnGroup[0].items.length - 1
                    }"
                  >
                    <div
                      :class="{'checked': selectedMetricsIndex[metric.name] }"
                      class="checkbox checkbox-custom"
                      @click="handleSelectMetric(metric.name)"
                    >
                      <div class="box">
                        <ui-icon
                          :name="'check'"
                          :size="8"
                          lib="fa"
                          substyle="fas"
                          class="chk-icon"
                        />
                      </div>
                      <span class="label">{{ metric.title }}</span>
                    </div>
                    <span class="description">
                      {{ metric.description }}
                    </span>
                  </div>
                </div>
              </div>
            </el-collapse>
          </div>
        </div>
      </div>

      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          filled
          @click="handleSave()"
        >
          <i class="fas fa-check plus-icon-custom" />{{ $t('crm.buttons.save_metrics') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import CrmMetrics from '@/views/CRM/mixins/crm-metrics.js';

export default {
  name: 'ActivityMetricsPopup',
  components: {},
  mixins: [
    CrmMetrics,
  ],
  props: {
    selectedMetrics: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      form: {},
      actionGroup: null,

      isActive: true,
      search: '',
      selectedMetric: '',
      activeNames: [],
      selectedMetricsIndex: {},
    };
  },

  computed: {
    ...mapGetters('reports', ['reportsSettings']),
    computedMetrics() {
      // return this.reportsSettings.metrics;
      return this.pCrmMetrics;
    },
    noData() {
      const hiddenKeys = Object.keys(this.isHiddenEntityIndex);
      return hiddenKeys.every(key => this.isHiddenEntityIndex[key]);
    },
    pSearch() {
      const search = this.search || '';
      return search.trim().toLowerCase();
    },
    columnGroup() {
      // const groups = this.reportsSettings.grouped_metrics;
      const groups = this.crmMetricGroups;

      return groups.map(group => ({
        key: `group_${group.group_name}`,
        title: '', // this.metricsGroupsIndex[group.group_name].title,
        searchString: '', // this.metricsGroupsIndex[group.group_name].title.toLowerCase(),
        group_name: group.group_name,
        items: group.items.map(metric => ({
          key: `metric_${metric.name}`,
          name: metric.name,
          ...this.metricsIndex[metric.name],
          searchString: [
            this.metricsIndex[metric.name].title.toLowerCase(),
            this.metricsIndex[metric.name].description.toLowerCase(),
          ].join(' '),
          items: metric.items.map(submetric => ({
            key: `submetric_${submetric.name}`,
            name: submetric.name,
            ...this.metricsIndex[submetric.name],
            searchString: [
              this.metricsIndex[submetric.name].title.toLowerCase(),
              this.metricsIndex[submetric.name].description.toLowerCase(),
            ].join(' '),
          })),
        })),
      }));
    },
    selectedMetricsList() {
      const metrics = Object.keys(this.selectedMetricsIndex);
      return metrics.filter(metric => this.selectedMetricsIndex[metric]);
    },
    entityList() {
      const result = [];

      this.columnGroup.forEach((group) => {
        result.push({
          type: 'group',
          key: `group_${group.group_name}`,
          entity: group,
        });

        group.items.forEach((metric) => {
          result.push({
            type: 'metric',
            key: `metric_${metric.name}`,
            entity: metric,
          });

          metric.items.forEach((submetric) => {
            result.push({
              type: 'submetric',
              key: `submetric_${submetric.name}`,
              entity: submetric,
            });
          });
        });
      });

      return result;
    },
    isHiddenEntityIndex() {
      const result = {};

      this.entityList
        .filter(item => item.type === 'submetric')
        .forEach((item) => {
          result[item.key] = !!(item.entity.searchString.indexOf(this.pSearch) < 0);
        });

      this.entityList
        .filter(item => item.type === 'metric')
        .forEach((item) => {
          const isEverySubItemHidden = item.entity.items.every(subItem => result[`submetric_${subItem.name}`]);
          result[item.key] = !!(item.entity.searchString.indexOf(this.pSearch) < 0 && isEverySubItemHidden);
        });

      this.entityList
        .filter(item => item.type === 'group')
        .forEach((item) => {
          const isEverySubItemHidden = item.entity.items.every(subItem => result[`metric_${subItem.name}`]);
          result[item.key] = !!(item.entity.searchString.indexOf(this.pSearch) < 0 && isEverySubItemHidden);
        });

      return result;
    },
    metricsIndex() {
      const result = {};
      this.computedMetrics
        .forEach((item) => {
          result[item.column_name] = item;
        });
      return result;
    },

    metricsGroupsIndex() {
      const result = {};
      this.reportsSettings.metrics_groups
        .forEach((item) => {
          result[item.group_name] = item;
        });
      return result;
    },

    getTranslateMetrics(metric) {
      return this.computedMetrics.find(e => e.column_name === metric);
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    getDefaultActiveNames() {
      return this.columnGroup.map(group => group.group_name);
    },
    initSelectedMetrics() {
      const result = {};
      this.selectedMetrics.forEach(item => result[item] = true);
      return result;
    },
    open() {
      this.isOpen = true;
      this.selectedMetric = '';
      this.selectedMetricsIndex = this.initSelectedMetrics();
      this.search = '';
      this.activeNames = this.getDefaultActiveNames();
    },
    close() {
      this.isOpen = false;
      this.$emit('closePopupSegment');
    },
    callapseLabel(group) {
      return this.activeNames.indexOf(group) === -1 ? 'show' : 'hide';
    },
    handleSelectMetric(metric) {
      this.$set(this.selectedMetricsIndex, metric, !this.selectedMetricsIndex[metric]);
    },
    handleSave() {
      this.isOpen = false;
      this.$emit('select', this.selectedMetricsList);
    },
  },
};
</script>
