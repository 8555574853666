<template>
  <div
    v-if="isOpen"
    class="crm-add-sms-action-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ $t('crm.activities.actions.action_popup.sms_title') }}
          <template v-if="name">
            ({{ name }})
          </template>
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div class="body">
        <div class="ui-fg-1">
          <div class="ui-m-xl-b">
            <div class="ui-d-flex ui-m-lg-b">
              <crm-action-language-tab
                :languages="languages"
                :selected-index="index"
                @select="handleSelectTab"
              />
            </div>
          </div>

          <div class="select-block ui-m-sm-b">
            <span class="label">{{ $t('crm.activities.actions.action_popup.sms_fields.message') }}</span>
            <el-input
              v-model="actions[index].message"
              :readonly="true"
              :label="$t('crm.activities.actions.action_popup.sms_fields.message')"
              type="textarea"
              :rows="6"
              :placeholder="$t('crm.activities.actions.action_popup.sms_fields.message')"
            />
          </div>
          <div class="ui-d-flex ui-ai-center ui-jc-end">
            <span class="crm-add-sms-action-popup__statistics" :style="messageStyle">{{ messageStats }}</span>
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          lib="fa"
          substyle="fas"
          icon="paper-plane"
          @click="handleSendTestSms()"
        >
          {{ $t('crm.buttons.send_test_sms') }}
        </ui-button>
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          @click="close()"
        >
          {{ $t('crm.buttons.close') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import CrmActionLanguageTab from '@/views/CRM/components/CrmActionLanguageTab.vue';
import ActivitiesLanguageMixin from '@/views/CRM/mixins/activities-language-mixin';
import SmsStatMixin from '@/views/CRM/mixins/sms-stat-mixin';

export default {
  name: 'AddSmsActionPreviewPopup',
  components: {
    CrmActionLanguageTab,
  },
  mixins: [
    ActivitiesLanguageMixin,
    SmsStatMixin,
  ],
  props: {
    activity: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpen: false,
      index: 0,
      actionGroup: null,
      actionGroups: [],
      actionGroupIndex: -1,
      actions: [],
      name: '',
      message: '',
    };
  },
  watch: {
    index: {
      immediate: true,
      handler(index) {
        const action = this.actions[index];

        if (action) {
          this.message = action.message;
          this.name = action.name;
        }
      },
    },
  },
  methods: {
    handleSendTestSms() {
      this.$emit('send-test-sms', {
        actionGroup: {
          actions: this.actions,
        },
        index: this.index,
      });
    },
    open(actionGroup, payload = {}) {
      this.actionGroup = actionGroup;
      this.actionGroupIndex = this.activity.action_groups.indexOf(actionGroup);

      const actions = (this.activity.action_groups[this.actionGroupIndex].actions || [])
        .filter(item => item.type === 'sms');

      if (!actions.length) {
        actions.push(this.getDefaultForm());
      }

      this.actions = this.$_.cloneDeep(payload?.actions || actions);

      this.index = Number.isFinite(payload?.index) ? payload?.index : 0;


      const action = this.actions[this.index];

      if (action) {
        this.message = action.message;
        this.name = action.name;
      }

      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.actionGroup = null;
      this.actionGroups = [];
      this.actionGroupIndex = -1;
    },
    getDefaultForm() {
      return {
        name: '',
        type: 'sms',
        message: '',
        language_code: 'en',
        is_default: true,
      };
    },
  },
};
</script>
