const stateData = () => ({
  crmMetrics: null,
  crmTemplateVariables: null,
});

const getters = {
  crmMetrics: state => state.crmMetrics,
  crmTemplateVariables: state => state.crmTemplateVariables,
};


const mutations = {
  getMetrics(state, payload) {
    state.crmMetrics = payload;
  },
  getTemplateVariables(state, payload) {
    state.crmTemplateVariables = payload;
  },
};

const actions = {
  async getMetrics({ commit }) {
    try {
      const hasPermission = true;
      if (hasPermission) {
        commit('getMetrics', []);
        const { data: { payload } } = await this.$api.getCrmMetrics();
        commit('getMetrics', payload);
      }
    } catch (error) {
      commit('getMetrics', null);
    }
  },
  async getTemplateVariables({ commit }) {
    try {
      const hasPermission = true;
      if (hasPermission) {
        commit('getTemplateVariables', []);
        const { data: { payload } } = await this.$api.getCrmTemplateVariables();
        commit('getTemplateVariables', payload);
      }
    } catch (error) {
      commit('getTemplateVariables', null);
    }
  },
};

export default {
  state: stateData,
  getters,
  mutations,
  actions,
  namespaced: true,
};
