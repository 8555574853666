import Vue from 'vue';
import Router from 'vue-router';

import Test from '@/views/Test';

import Auth from './modules/auth';
import Payments from './modules/payments';
import Reports from './modules/reports';
import Tools from './modules/tools';
import Settings from './modules/settings';
import Affiliates from './modules/affiliates';
import Players from './modules/players';
import Service from './modules/service';
import Live from './modules/live';
import Teams from './modules/teams';
import MediaBuying from './modules/mediaBuying';
import Dashboard from './modules/dashboard';
import CRM from './modules/crm';

Vue.use(Router);

const RouterInstance = new Router({
  routes: [
    {
      path: '/test',
      component: Test,
    },
    ...Auth,
    ...Affiliates,
    ...Payments,
    ...Players,
    ...Reports,
    ...Tools,
    ...Settings,
    ...Service,
    ...Live,
    ...Teams,
    ...MediaBuying,
    ...Dashboard,
    ...CRM,
  ],
  mode: 'history',
});

export default RouterInstance;
