<template>
  <div
    v-if="isOpen"
    class="add-country-group-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ isEdit
            ? `${$t('settings.offersCountryGroups.confirm.editCountryGroup')}`
            : $t('settings.offersCountryGroups.confirm.addCountryGroup')
          }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div
        v-loading="isLoading"
        class="body"
      >
        <div class="ui-fg-1">
          <div class="field ui-m-xl-b label">
            {{ $t('settings.offersCountryGroups.confirm.label') }}
          </div>
          <ui-input
            v-model="data.name"
            class="form-input ui-m-xl-b"
            :label="$t('settings.offersCountryGroups.confirm.name')"
            autosize
            size="big"
            :placeholder="$t('settings.offersCountryGroups.confirm.name')"
            type="text"
            :error="getError('name')"
          />
          <div class="field ui-m-xl-b country">
            <span class="label-text">{{ $t('settings.offersCountryGroups.confirm.country_list') }}</span>
            <ui-select
              v-model="data.country_code"
              :placeholder="$t('settings.offersCountryGroups.confirm.country_list')"
              :options="countries"
              width="100%"
              :multiple="true"
              :filterable="true"
              :remote="false"
              value-prop="code"
              label-prop="name"
              list-label-prop="code"
              :max-count-tags="6"
              class="filter_type"
              :class="{error: getError('values')}"
            />
            <div v-if="getError('values')" class="field-error">
              {{ getError('values') }}
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="isLoading"
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          @click="handleSave()"
        >
          {{ $t('crm.buttons.save') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import errorHandleMixin from '@/views/mixins/error-hadle';

export default {
  name: 'AddCountryGroupPopup',
  components: {
  },
  mixins: [
    errorHandleMixin,
  ],
  data() {
    return {
      isOpen: false,
      isLoading: false,
      isEdit: false,
      selectedCountries: [],
      data: {
        ...this.getDefaultForm(),
      },
    };
  },

  computed: {
    ...mapGetters({
      countries: 'misc/countries',
    }),
  },
  created() {},
  mounted() {},
  methods: {
    open(data) {
      this.isOpen = true;

      this.data = this.getDefaultForm();
      if (data) {
        this.data = this.$_.cloneDeep(data);
        this.isEdit = true;
      }

      this.errors = {};
    },
    close() {
      this.isOpen = false;
      this.isEdit = false;
    },
    handleSave() {
      this.errors = {};
      const { id, name, country_code } = this.data;

      const promise = id
        ? this.$api.putCountriesTags(id, { name: name.trim(), values: country_code })
        : this.$api.postCountriesTags({ name: name.trim(), values: country_code });

      promise.then(() => {
        this.close();
        this.$emit('save');
      })
        .catch((e) => {
          this.errors = e.data.errors;
        });
    },
    getDefaultForm() {
      return {
        country_code: '',
        name: '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.add-country-group-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
    width: 100%;
  }

  .row-bg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    background-color: var(--crm-panel-bg);
    height: 32px;

    font-size: 14px;
    font-weight: 500;
  }

  .popup {
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 8vh;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;


    .head {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      .name {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-right: 16px;
      }
      .close {
        font-size: 15px;
        cursor: pointer;
        &:hover {
          color: var(--main-color) !important;
        }
      }
    }

    .body {
      padding: 16px 24px;
      display: flex;
      position: relative;
      max-height: 84vh;
      min-height: 168px;

      .label {
        font-size: 14px;
      }

      .country {
        position: relative;

        /deep/ {
          .field-error {
            position: absolute;
            right: 0;
            bottom: -32px;
          }
        }
      }

      .content-header{
        margin-bottom: 16px;
        display: flex;
        align-items: flex-start;
      }

      .select-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-right: 16px;

        &_grow{
          flex-grow: 1;
        }

        &__label{
          font-size: 14px;
          margin-bottom: 5px;
        }
        &:last-child{
          margin-right: 0;
        }
      }

      .card__title{
        .card-content{
          .label{
            font-size: 24px;
            font-weight: 500;
            line-height: 1.25;
          }
        }
      }
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      .ui-button + .ui-button{
        margin-left: 8px;
      }
    }
  }

  .label-text {
    font-size: 14px;
    color: var(--crm-panel-text);
    margin-bottom: 5px;
    display: block;
  }

  .select {
    width: 100%;

    /deep/ {
      .el-input__suffix-inner {
        align-items: center;
        display: flex;
      }
    }

    &.error {
      /deep/ {
        .el-input__inner {
          border-color: var(--danger-color);
        }
      }
    }
  }

  .field-error {
    text-align: right;
  }

  .form-input {
    margin-bottom: 16px;
    position: relative;

    /deep/ {
      .label-text {
        font-size: 14px;
        color: var(--crm-panel-text);
        margin-bottom: 5px;
        display: block;
      }

      .input-error {
        text-transform: initial!important;
        font-weight: 400!important;
        position: absolute;
        bottom: -18px;
      }
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
</style>
