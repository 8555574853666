<template>
  <ui-confirm
    ref="confirm"
    class="crm-activity-actions__confirm-send-test-sms"
    :width="480"
    type="success"
    :action-name="$t('crm.buttons.confirm')"
    action="send_test_sms"
    action-icon="check"
    :action-disabled="isConfirmSendTestDisabled || isLoading"
    @save="handleConfirmSendTest"
  >
    <div
      slot="title"
      v-html="$t('crm.activities.actions.send_test_sms.title')"
    />
    <div class="body__wrap ui-m-xxxl-b">
      <i class="fas fa-exclamation-triangle success" />
      <div
        v-if="confirmSendTestActions"
        class="crm-activity-actions__confirm-send-test-sms-msg body__msg"
        v-html="$t('crm.activities.actions.send_test_sms.msg', {
          smsName: confirmSendTestActions.defaultName
            ? confirmSendTestActions.defaultName
            : $t('crm.activities.actions.empty_name'),
        })"
      />
    </div>
    <div class="ui-m-xxxl-b">
      <ui-input
        v-model="phoneNumber"
        input-mask="+###############"
        placeholder="+"
        :label="$t(`crm.activities.actions.send_test_sms.phone_number`)"
        size="big"
        autosize
        :error="phoneNumberError"
      />
    </div>
    <div
      v-if="confirmSendTestActions"
      class="crm-activity-actions__confirm-send-test-sms-checkbox-wrapper"
    >
      <el-checkbox
        v-model="isConfirmSendTestSelectedAll"
        class="crm-activity-actions__confirm-send-test-sms-checkbox"
        :label="$t('crm.activities.actions.send_test_sms.all_localizations')"
        :disabled="isConfirmSendTestSelectedAllDisabled"
      />
      <div
        v-for="(actionGroup, index) in confirmSendTestActions.actions"
        :key="index"
        :class="[
          'ui-d-flex',
          'ui-g-w-md',
          'crm-activity-actions__confirm-send-test-sms-checkbox',
        ]"
      >
        <el-checkbox
          v-model="confirmSendTestSelectedActions[index]"
          class="ui-g-md"
          :label="languageIndex[actionGroup.language_code].name"
          :disabled="isConfirmSendTestActionsDisabled[index]"
        />
        <ActionIcon
          v-if="isConfirmSendTestActionsDisabled[index]"
          class="ui-g-md"
          :tooltip="$t('crm.tooltips.empty_mail_message')"
        >
          <div>
            <ui-icon
              name="exclamation-triangle"
              :color="$theme.accentColor"
              lib="fa"
              :size="12"
            />
          </div>
        </ActionIcon>
      </div>
    </div>
  </ui-confirm>
</template>

<script>

export default {
  name: 'ConfirmSendTestSms',
  data() {
    return {
      confirmSendTestSelectedActions: [],
      phoneNumber: '+',
      confirmSendTestActions: null,
      errors: {},
      isLoading: false,
    };
  },
  computed: {
    phoneNumberError() {
      return this.$_.get(this.errors, ['phone_number', 0, 'message'], '');
    },
    languageIndex() {
      const languages = this.$store.state.misc.languages || [];

      return languages.reduce((acc, item) => {
        acc[item.code] = item;
        return acc;
      }, {});
    },
    isConfirmSendTestSelectedAll: {
      get() {
        if (this.confirmSendTestActions) {
          const filtered = this.confirmSendTestSelectedActions
            .filter((item, index) => !this.isConfirmSendTestActionsDisabled[index]);
          return filtered.length > 0 && filtered.every(item => item);
        }

        return false;
      },
      set(value) {
        this.confirmSendTestSelectedActions = this.confirmSendTestActions.actions
          .map((item, index) => (this.isConfirmSendTestActionsDisabled[index] ? false : value));
      },
    },
    isConfirmSendTestSelectedAllDisabled() {
      return this.isConfirmSendTestActionsDisabled.every(item => !!item);
    },
    isConfirmSendTestDisabled() {
      return this.confirmSendTestSelectedActions.every(item => !item);
    },
    isConfirmSendTestActionsDisabled() {
      return this.confirmSendTestActions.actions.map(action => !action.message.trim());
    },
  },
  methods: {
    handleConfirmSendTest() {
      if (this.isLoading) return;

      this.isLoading = true;

      const selectedActions = this.confirmSendTestActions.actions
        .filter((item, index) => this.confirmSendTestSelectedActions[index]);

      const payload = {
        actions: selectedActions.map(item => ({
          name: item.name,
          message: item.message,
        })),
        phone_number: this.phoneNumber,
      };


      this.$api.sendActivityTestSms(payload)
        .then(() => {
          this.close();
          this.$noty.info(this.$t('crm.activities.actions.send_test_sms.success'));
        })
        .catch((errors) => {
          if (errors.data.code === 'VALIDATION_ERROR') {
            this.errors = errors.data.errors;
          }
        })
        .finally(() => this.isLoading = false);
    },
    open({ actions, selectedActions }) {
      this.confirmSendTestSelectedActions = selectedActions;
      this.confirmSendTestActions = actions;
      this.phoneNumber = '+';
      this.errors = {};
      this.isLoading = false;

      this.$refs.confirm.open();
    },
    close() {
      this.$refs.confirm.close();
    },
  },
};
</script>

<style lang="scss">

</style>
