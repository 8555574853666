export default {
  props: {
    activity: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isHasControlGroup() {
      return this.activity.action_groups.filter(item => item.is_control).length;
    },
    indexShift() {
      return this.isHasControlGroup ? 1 : 0;
    },
  },
  methods: {
    getLiter(index) {
      let i = index;
      const literCodes = [];
      const startLiter = 'A'.charCodeAt(0);
      const maxLiters = 26;

      do {
        const literIndex = i % maxLiters;
        literCodes.unshift(literIndex + startLiter);
        i = (i - literIndex) / maxLiters;
      } while (i > 0);

      if (literCodes.length > 1) {
        literCodes[0] -= 1;
      }

      return String.fromCharCode(...literCodes);
    },
  },
};
