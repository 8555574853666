<template>
  <div class="dashboard-overview" :class="{ 'edit-mode': editMode }">
    <div ref="toolbar" class="toolbar">
      <div class="wrapper dashboard-overview__wrapper">
        <div class="section dashboard-overview__section">
          <div class="dashboard-overview__title-wrap">
            <adm-ui-header v-if="!editMode" tag="h1">
              {{ dashboard.name }}
            </adm-ui-header>

            <div v-else class="dashboard-overview__title-editable">
              <ui-input
                v-model="dashboard.name"
                class="dashboard-overview__title-input"
                placeholder=""
              />
              <el-tooltip
                class="item"
                effect="dark"
                :content=" isDefault
                  ? $t('dashboards.other.dont_set_as_default')
                  : $t('dashboards.other.set_as_default')"
                placement="top"
              >
                <adm-ui-button
                  v-if="!dashboard.is_default"
                  size="small"
                  :icon="!isDefault ? 'fas fa-home' : 'fad fa-house-damage'"
                  plain
                  :type="!isDefault ? undefined : 'danger'"
                  @click="setHomeDashboard"
                />
              </el-tooltip>
            </div>

            <dashboard-global-filter
              :key="rerenderGlobalFilter"
              ref="dashboardGlobalFilterRef"
              v-model="filters"
              :use-filters="filtersItems"
              :set-current-search="searchFilters"
              :disabled-mode="disabledRequestStats"
              class="btn ui-filter-new"
              @input="handlerGlobalFilter"
            />
          </div>

          <div class="dashboard-overview__btn-wrap">
            <div v-if="editMode" class="dashboard-overview__autorefresh">
              <span>{{ $t('dashboards.autorefresh.title') }}</span>
              <adm-ui-select
                :data="autorefreshSelect"
                :default="refreshPeriod"
                @select="changeAutorefresh"
              />
            </div>
            <ui-currency-picker
              class="ui-m-md-r"
              :value="currencyCode"
              :currencies="customerCurrencies"
              @input="changeCurrency"
            />
            <date-picker
              id="range_picker"
              :key="$i18n.locale + updatePiker"
              v-model="pickerDateRange"
              type="datetimerange"
              align="right"
              unlink-panels
              range-separator="-"
              :start-placeholder="$t('reports.other.start_date')"
              :end-placeholder="$t('reports.other.end_date')"
              :picker-options="pickerOptions()"
              :shortcut-name="shortcutName"
              :default-time="['00:00:00', '23:59:59']"
              show-time
              action-custom
              class="date-picker date-picker-fork"
              name="range_picker"
              format="dd MMM yyyy"
              :disabled="disabledRequestStats"
              @change="selectDateRange"
              @blur="datepickerBlur"
            />

            <adm-ui-buttons-widget
              :data="editBtnData"
              :config="{ size: 'small' }"
            />
          </div>
        </div>
        <div v-if="editMode" class="section dashboard-overview__section">
          <adm-ui-alert
            class="dashboard-overview__alert"
            :text="$t('dashboards.other.edit_alert')"
            :icon="['fas', 'fa-info-circle']"
            type="primary"
            justify-content="center"
          />
        </div>
      </div>
    </div>

    <draggable
      :list="dashboard.rows"
      :group="`description`"
      :disabled="!editMode"
      ghost-class="ghost"
      handle=".dashboard-overview__metric-title"
      :force-fallback="true"
      @change="changeDraggable"
      @start="drag = true"
      @end="drag = false"
    >
      <div
        v-for="(row, rowIndex) in dashboard.rows"
        :key="rowIndex + row.name"
        class="dashboard-overview__metric-container"
        :class="{ drag: drag }"
      >
        <div class="wrapper dashboard-overview__metric-wrap">
          <div class="dashboard-overview__metric-title">
            <adm-ui-header v-if="!editMode" :key="rowIndex + row.name" tag="h2">
              {{ row.name }}
            </adm-ui-header>
            <adm-ui-editable-header
              v-else
              :key="rowIndex + row.name"
              :value="row.name"
              :controls-data="[
                {
                  title: $t('dashboards.other.delete'),
                  type: 'danger',
                  icon: 'fas fa-trash-alt',
                  clickHandler: () => removeDashboardRow(rowIndex),
                },
              ]"
              @update="inputTitleRow($event, rowIndex)"
            >
              <template v-slot="{ type }">
                <adm-ui-icon
                  class="ui-editable-header__prefix-icon"
                  :class="`ui-editable-header__prefix-icon--${type}`"
                  :icon="['far', 'fa-bars']"
                />
              </template>
            </adm-ui-editable-header>
          </div>
          <div
            v-if="editMode"
            class="
              dashboard-overview__metric-card-wrap
              dashboard-overview__metric-card-wrap--add
            "
          >
            <div
              class="dashboard-overview__metric-card-add"
              @click="handlerAddMerticRow(rowIndex)"
            >
              <adm-ui-icon :icon="['fas', 'fa-plus']" />
              <adm-ui-header tag="h3">
                {{ $t('dashboards.other.add_metric') }}
              </adm-ui-header>
            </div>
          </div>

          <draggable
            :list="row.panels.stat"
            :group="`description1`"
            :disabled="false"
            ghost-class="ghost"
            :draggable="`.dashboard-overview__metric-card-wrap--draggable${rowIndex}`"
            class="dashboard-overview__metric-card"
            :class="{ 'dashboard-overview__metric-card--draggable': drag }"
            :force-fallback="true"
            @change="changeDraggable"
            @start="drag = true"
            @end="drag = false"
          >
            <dashboard-metric-stat
              v-for="(stat, index) in row.panels.stat"
              :key="index"
              ref="stat"
              :stat="stat"
              :prop-currency-code="currencyCode"
              :is-removed="editMode"
              :index="index"
              :row-index="rowIndex"
              :period="pickerDateRange"
              @removeMetric="removeMetric"
              @editMetric="editMetric"
              @emitDataReport="setReportData"
              @isLoadingStat="loadingMetrics"
            />
          </draggable>

          <draggable
            class="dashboard-overview__metric-chart"
            :force-fallback="true"
            :list="row.panels.chart"
            :group="`description-chart`"
            :disabled="false"
            ghost-class="ghost"
            :draggable="`.dashboard-overview__metric-card-wrap--draggable${rowIndex}`"
            :class="{ 'dashboard-overview__metric-card--draggable': drag }"
            @change="changeDraggable"
            @start="drag = true"
            @end="drag = false"
          >
            <dashboard-chart
              v-for="(chart, index) in row.panels.chart"
              :key="`${index}${random}`"
              ref="chart"
              :chart="chart"
              :prop-currency-code="currencyCode"
              :index="index"
              :row-index="rowIndex"
              :is-removed="editMode"
              :save-period="!editMode"
              :animation="!editMode"
              :period="pickerDateRange"
              :dashboard-id="dashboard.id"
              @changeForecast="changeForecast"
              @editMetric="editMetric"
              @removeMetric="removeMetric"
              @emitDataReport="setReportData"
              @isLoadingChart="loadingMetrics"
            />
          </draggable>

          <draggable
            :force-fallback="true"
            :list="row.panels.table"
            :group="`description-table`"
            :disabled="false"
            ghost-class="ghost"
            :draggable="`.dashboard-overview__metric-table-wrap--draggable${rowIndex}`"
            :class="{ 'dashboard-overview__metric-card--draggable': drag }"
            class="dashboard-overview__metric-table"
            @change="changeDraggable"
            @start="drag = true"
            @end="drag = false"
          >
            <dashboard-metric-table
              v-for="(table, index) in row.panels.table"
              :key="index"
              ref="table"
              :period="pickerDateRange"
              :currency-code="currencyCode"
              :table="table"
              :index="index"
              :row-index="rowIndex"
              :is-removed="editMode"
              @emitDataReport="setReportData"
              @editMetric="editMetric"
              @removeMetric="removeMetric"
            />
          </draggable>
        </div>
      </div>
    </draggable>

    <div
      v-if="editMode"
      class="dashboard-overview__metric-container"
      @click="addDashboardRow"
    >
      <div class="wrapper dashboard-overview__metric-wrap">
        <div
          class="
            dashboard-overview__metric-title
            dashboard-overview__metric-title--add
          "
        >
          <adm-ui-icon :icon="['far', 'fa-plus']" />
          <adm-ui-header tag="h2">
            {{ $t('dashboards.other.new_section') }}
          </adm-ui-header>
        </div>
      </div>
    </div>

    <add-dashboard-metrics
      ref="addDashboardMetrics"
      :prop-currency-code="currencyCode"
      :period="pickerDateRange"
      @applyColumnsSettings="addMetricFromPopup"
    />

    <adm-ui-confirm ref="confirm" v-bind="confirmConfig" />
    <adm-ui-confirm
      ref="confirmClone"
      class="confirm-clone"
      v-bind="confirmConfigClone"
    >
      <template #content>
        <div class="confirm-dialog__content">
          <div class="confirm-dialog__text">
            {{ $t('dashboards.confirm.clone_text') }} <span>{{ nameDashbordClone }}</span>
          </div>
          <ui-input
            v-model="nameDashbordClone"
            placeholder=""
          />
        </div>
      </template>
    </adm-ui-confirm>
  </div>
</template>
<script>

import draggable from 'vuedraggable';
import { mapGetters, mapState } from 'vuex';
import AddDashboardMetrics from '@/views/Dashboard/components/AddDashboardMetrics.vue';
import {
  GET_DASHBOARDS,
  EDIT_MODE,
  SET_DASHBOARD_FILTER,
  GET_DASHBOARD_BY_ID,
} from '@/store/action_types/dashboards';
import DashboardMetricStat from './components/DashboardMetricStat.vue';
import DashboardChart from './components/DashboardChart.vue';
import detectPermissions from '../../service/detectPermissions';
import DashboardMetricTable from './components/DashboardMetricTable.vue';
import DashboardGlobalFilter from './components/DasboardGlobalFilter.vue';
import DatePicker from '@/components/DatePicker';
import datePickerShortcuts from '@/views/mixins/date-picker-shortcuts';
import formatCurrency from '@/views/mixins/format-currency';
import randomizer from '../../helpers/randomizer';
import updateUrl from '@/service/updateUrl';

const LS_KEY = 'Dashboard';


export default {
  name: 'Overview',
  components: {
    draggable,
    AddDashboardMetrics,
    DashboardMetricStat,
    DashboardChart,
    DashboardMetricTable,
    DashboardGlobalFilter,
    DatePicker,
  },

  mixins: [datePickerShortcuts, formatCurrency],

  props: {
    typeView: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      cloneDateSettings: {},
      updatePiker: 0,
      isChangeDatepicker: false,
      shortcutName: 'last_30_days',
      pickerDateRange: [
        this.$moment().format('YYYY-MM-DD HH:mm:ss'),
        this.$moment().format('YYYY-MM-DD HH:mm:ss'),
      ],
      isDefault: false,
      cloneDashboard: {},
      isLoadingDashboard: false,
      dashboard: {},
      drag: false,
      input: 'Overview',
      editMode: false,
      value: '',
      intervalReload: Function,

      loadAllItem: false,
      arrayIsLoading: [],
      disabledRefresh: false,
      dataRouteTo: {},
      confirmType: '',
      filters: {},
      filterFilters: {},
      searchFilters: {},
      random: 0,
      refreshPeriod: this.dashboard?.refresh_period_seconds || 1800,
      editOnce: false,
      rerenderGlobalFilter: 0,
      disabledCloneBtn: false,
      nameDashbordClone: '',
      currencyCode: '',
    };
  },

  computed: {
    ...mapGetters('dashboards', ['overviewDashboard', 'dashboards', 'dashboardById']),
    ...mapGetters('reports', ['reportsSettings']),
    ...mapState('dashboards', ['stateDashboardFilter, dashboardsFlagBlock']),
    ...mapGetters('other', ['disabledRequestStats']),
    ...mapGetters({
      customerCurrencies: 'misc/customerCurrencies',
    }),
    rowNameIsEmpty() {
      return this.dashboard.rows.some(row => row.name === '');
    },

    filterIsEmpty() {
      const { search, filters } = this.stateDashboardFilter;
      const countKey = Object.keys(this.stateDashboardFilter).length;
      return (
        this.$_.isEmpty(search) && this.$_.isEmpty(filters) && countKey === 2
      );
    },

    autorefreshSelect() {
      return [300, 600, 900, 1800, 3600].map(sec => ({
        value: sec,
        label: this.$t(`dashboards.autorefresh.${sec}`),
      }));
    },

    filtersItems() {
      const val = [
        'trafficType',
        'playerFilter',
        'playerCountryNameFilter',
        'playerRegionNameFilter',
        'trafficRegionNameFilter',
      ];
      return val;
    },

    templateDashboard() {
      return {
        name: this.$t('dashboards.other.new_dashboard'),
        refresh_period_seconds: this.refreshPeriod,
        relative_timeframe: 'last_30_days',
        custom_timeframe_from: this.pickerDateRange[0],
        custom_timeframe_to: this.pickerDateRange[1],
        is_default: false,
        filters: {},
        rows: [],
      };
    },

    checkExistsTemplateDefault() {
      return this.dashboard.is_default === undefined;
    },

    checkOverviewTemplate() {
      return this.$route.path === '/dashboards/overview';
    },

    confirmConfigClone() {
      return {
        title: this.$t('dashboards.confirm.clone_title'),
        icon: 'fas fa-exclamation-triangle',
        text: this.$t('dashboards.confirm.clone_text'),
        buttonsData: [
          {
            title: this.$t('dashboards.other.cancel'),
            key: 'cancel',
            type: 'danger',
            icon: 'fas fa-times',
            plain: true,
            clickHandler: () => {
              this.$refs.confirmClone.close();
            },
          },
          {
            title: this.$t('dashboards.other.clone'),
            key: 'yes',
            type: 'primary',
            icon: 'fas fa-copy',
            clickHandler: () => {
              this.saveCloneConfirm();
              this.$refs.confirmClone.close();
            },
          },
        ],
      };
    },

    confirmConfig() {
      switch (this.confirmType) {
      case 'cancel':
        return {
          title: this.$t('dashboards.confirm.cancel_title'),
          icon: 'fas fa-exclamation-triangle',
          text: this.$t('dashboards.confirm.cancel_text'),
          buttonsData: [
            {
              title: this.$t('dashboards.confirm.cancel_btn_cancel'),
              key: 'cancel',
              type: 'primary',
              icon: 'fas fa-times',
              plain: true,
              clickHandler: this.cancelHandler,
            },
            {
              title: this.$t('dashboards.confirm.cancel_btn_сonfirm'),
              key: 'yes',
              type: 'danger',
              icon: 'fas fa-trash',
              clickHandler: () => {
                this.cancelDashboard(this.$refs.confirm.close);
              },
            },
          ],
        };
      case 'delete':
        return {
          title: this.$t('dashboards.confirm.delete_title'),
          icon: 'fas fa-exclamation-triangle',
          text: this.$t('dashboards.confirm.delete_text'),
          buttonsData: [
            {
              title: this.$t('dashboards.other.cancel'),
              key: 'cancel',
              type: 'primary',
              icon: 'fas fa-times',
              plain: true,
              clickHandler: this.cancelHandler,
            },
            {
              title: this.$t('dashboards.confirm.delete_btn_сonfirm'),
              key: 'yes',
              type: 'danger',
              icon: 'fas fa-trash',
              clickHandler: this.deleteDashboardSuccess,
            },
          ],
        };

      default:
        return {
          title: this.$t('dashboards.confirm.title'),
          icon: 'fas fa-exclamation-triangle',
          text: this.$t('dashboards.confirm.text'),
          buttonsData: [
            {
              title: this.$t('dashboards.other.cancel'),
              key: 'cancel',
              type: 'primary',
              icon: 'fas fa-times',
              plain: true,
              clickHandler: this.cancelHandler,
            },
            {
              title: this.$t('dashboards.confirm.btn_сonfirm'),
              key: 'yes',
              type: 'danger',
              icon: 'fas fa-trash',
              clickHandler: this.confirmHandler,
            },
          ],
        };
      }
    },

    computedDashboard() {
      const currentDashboard = this.getCurrentDashbord();
      const newPanels = [];

      currentDashboard.rows.forEach((row, index) => {
        newPanels[index] = row.panels.reduce(
          (acc, val) => {
            if (val.type === 'stat') {
              acc.stat.push({ ...val });
              return acc;
            }
            if (val.type === 'table') {
              acc.table.push({ ...val });
              return acc;
            }
            /* TODO: if fix backed */
            if (!Array.isArray(val.series)) val.series = [];

            acc.chart.push({ ...val });

            return acc;
          },
          { stat: [], table: [], chart: [] },
        );
      });

      const sortPanel = currentDashboard.rows.map((row, ind) => ({
        ...row,
        panels: newPanels[ind],
      }));

      const sortCurrentDashboard = { ...currentDashboard, rows: sortPanel };
      return sortCurrentDashboard;
    },

    editBtnData() {
      if (!this.editMode) {
        const btnCollection = [
          {
            title: this.$t('dashboards.other.clone'),
            key: 'clone',
            type: 'primary',
            icon: 'fas fa-clone',
            plain: true,
            disabled: this.isLoadingDashboard || this.disabledRequestStats || this.disabledCloneBtn,
            clickHandler: this.openCloneConfirm,
          },
          {
            title: this.$t('dashboards.other.edit_dashboard'),
            key: 'edit',
            type: 'primary',
            icon: 'fas fa-pen',
            plain: true,
            disabled: this.isLoadingDashboard || this.disabledRequestStats,
            clickHandler: () => {
              this.cloneDashboard = this.$_.cloneDeep(this.dashboard);
              this.editMode = true;
              this.isDefault = this.dashboard.is_default;
              this.cloneDateSettings = {
                pickerDateRange: this.pickerDateRange,
                shortcutName: this.shortcutName,
              };
            },
          },
          {
            title: this.$t('dashboards.other.refresh'),
            key: 'refresh',
            type: 'primary',
            icon: 'fas fa-sync-alt',
            disabled: this.disabledRefresh || this.disabledRequestStats,
            plain: true,
            clickHandler: () => this.handlerRefresh(),
          },
        ];
        return btnCollection;
      }
      if (this.typeView === 'add') {
        return [
          {
            title: this.$t('dashboards.other.cancel'),
            key: 'cancel',
            type: 'primary',
            icon: 'fas fa-times',
            plain: true,
            clickHandler: () => {
              this.$router.push('/dashboards/overview');
            },
          },
          {
            title: this.$t('dashboards.other.save_dashboard'),
            key: 'save',
            type: 'primary',
            icon: 'fas fa-check',
            disabled:
              this.disabledSave || !this.dashboard.name || this.rowNameIsEmpty,
            clickHandler: () => this.createDashboard(),
          },
        ];
      }
      const btnCollection = [
        {
          title: this.$t('dashboards.other.delete'),
          key: 'delete',
          type: 'danger',
          icon: 'fas fa-trash-alt',
          disabled: this.checkOverviewTemplate,
          clickHandler: () => this.alertDeleteDashboard(),
        },
        {
          title: this.$t('dashboards.other.cancel'),
          key: 'cancel',
          type: 'primary',
          icon: 'fas fa-times',
          plain: true,
          clickHandler: this.editOnce
            ? this.alertCancelDashboard
            : this.cancelDashboard,
        },
        {
          title: this.$t('dashboards.other.save_changes'),
          key: 'save',
          type: 'primary',
          icon: 'fas fa-check',
          disabled:
            this.disabledSave || !this.dashboard.name || this.rowNameIsEmpty,
          clickHandler: () => this.editDashboard(),
        },
      ];
      return btnCollection;
    },

    checkRoute() {
      return this.$route.params.id;
    },

    queryParamsDashboard() {
      const rows = this.dashboard.rows.map((row) => {
        const panels = [
          ...row.panels.stat,
          ...row.panels.chart,
          ...row.panels.table,
        ].map(panel => this.$_.omit(panel, ['id', 'report']));
        return {
          name: row.name,
          panels,
        };
      });

      const result = {
        name: this.dashboard.name,
        refresh_period_seconds: this.refreshPeriod,
        filters: this.computedFilters,
        relative_timeframe: this.shortcutName || 'custom',
        custom_timeframe_from: this.$moment(this.pickerDateRange[0]).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
        custom_timeframe_to: this.$moment(this.pickerDateRange[1]).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
        rows,
      };

      return result;
    },

    disabledSave() {
      let disabled = false;
      if (this.dashboard.rows.length === 0) return true;
      this.dashboard.rows.forEach((row) => {
        if (
          row.panels.stat.length === 0
          && row.panels.chart.length === 0
          && row.panels.table.length === 0
        ) {
          disabled = true;
        }
      });
      return disabled;
    },

    computedFilters() {
      return {
        ...this.parseFilter(this.filterFilters),
        search: this.searchFilters,
      };
    },

    supportedDimension() {
      return (this.reportsSettings.dimensions || []).map(dim => dim.column_name);
    },
  },

  watch: {
    currencyCode(val) {
      this.$ls.set(`${LS_KEY}/currency_code`, val);
      this._completedFilterUrl();
    },
    shortcutName() {
      if (this.typeView !== 'add' && !this.editMode) {
        this.updateDashboardTimeframe();
      }
    },

    dashboard: {
      deep: true,
      handler() {
        if (this.editMode) {
          this.editOnce = true;
          window.onbeforeunload = () => true;
          this.$store.commit(`dashboards/${EDIT_MODE}`, this.editMode);
        }
      },
    },

    editMode(value) {
      if (value) {
        clearInterval(this.intervalReload);
      } else {
        this.setDashboardInterval();
      }
    },

    async checkRoute() {
      const _break = this.redirectIfNotHomePage();
      if (_break === 'break') return;

      this.loadAllItem = false;
      this.isDefault = this.dashboard.is_default;
      if (!this.dashboardsFlagBlock) await this.initDashboard();
      this.updateStat(undefined, true);
      this.updateChart(undefined, true);
      this.updateTable(undefined, true);

      this.arrayIsLoading = [];
    },
  },

  async created() {
    if (detectPermissions.checkRequest('reports_view')) {
      const _urlData = updateUrl.getParseParams(this.$route.hash);

      if (!this.$_.isEmpty(_urlData)) {
        this.currencyCode = this.checkCurrencyCode(this.$_.get(_urlData, ['currency_code']));
      } else {
        this.currencyCode = this.checkCurrencyCode(this.$ls.get(`${LS_KEY}/currency_code`));
      }

      this.loadAllItem = false;

      const _break = this.redirectIfNotHomePage();
      if (_break === 'break') return;

      if (this.typeView === 'add') {
        this.dashboard = this.templateDashboard;
        this.setDateParams();
        this.dashboard = this.$_.cloneDeep(this.dashboard);
        this.isDefault = false;
        this.$nextTick(() => (this.editMode = this.typeView === 'add'));
      } else {
        await this.initDashboard();
        this.editMode = false;
        this.updateStat(undefined, true);
        this.updateChart(undefined, true);
        this.updateTable(undefined, true);
        this.arrayIsLoading = [];
        this.setDashboardInterval();
        this.isDefault = this.dashboard.is_default;
      }

      this.$eventBus.$on('confirmEditDashboards', this.confirmEditDashboards);
      this.$eventBus.$on('changeLocale', this.changeLocale);
    }
    detectPermissions.checkRoute('reports_view');
  },

  destroyed() {
    this.$eventBus.$off('confirmEditDashboards', this.confirmEditDashboards);
    this.$eventBus.$off('changeLocale', this.changeLocale);

    clearInterval(this.intervalReload);
  },

  methods: {
    _query() {
      return {
        currency_code: this.currencyCode,
      };
    },

    _completedFilterUrl() {
      const _dataFilters = this._query();
      updateUrl.updateGetParams(_dataFilters);
    },
    changeCurrency(currencyCode) {
      this.currencyCode = currencyCode;
    },
    getCurrentDashbord() {
      const currentDashboard = this.checkRoute
        ? this.dashboards.find(d => d.id === this.checkRoute)
        : this.overviewDashboard[0];
      return currentDashboard;
    },

    openCloneConfirm() {
      const currentDashboard = this.getCurrentDashbord();
      this.nameDashbordClone = `${currentDashboard.name} (${this.$t('dashboards.confirm.clone_copy')})`;
      this.$refs.confirmClone.open();
    },

    saveCloneConfirm() {
      this.cloneDashboardHandler();
    },

    async cloneDashboardHandler() {
      const { id } = this.dashboard;

      try {
        await this.$store.dispatch(`dashboards/${GET_DASHBOARD_BY_ID}`, id);
      } catch (error) {
        this.$noty.error(this.$t('dashboards.other.clone_danger'));
      } finally {
        this.disabledCloneBtn = false;
      }

      const currentDashboard = {
        ...this.dashboardById,
        /* TODO: можно удалить если поправят валидацию на вервере. series */
        rows: this.dashboardById.rows.map(row => ({
          ...row,
          panels: row.panels.map((panel) => {
            if (panel.type === 'chart') {
              return {
                ...panel,
                series: panel.series === undefined ? [] : panel.series,
              };
            }
            return panel;
          }),
        })),
      };

      const query = {
        ...this.$_.pick(currentDashboard, [
          'custom_timeframe_from',
          'custom_timeframe_to',
          'filters',
          'is_default',
          'refresh_period_seconds',
          'relative_timeframe',
          'rows',
        ]),
        name: this.nameDashbordClone,
      };

      try {
        this.disabledCloneBtn = true;
        await this.$api.createDashboards(query);
        this.$noty.info(`${this.$t('dashboards.other.clone_success')} ${this.nameDashbordClone}`).setTimeout(2500);
        await this.getDashboard('only');
      } catch (error) {
        this.$noty.error(this.$t('dashboards.other.clone_danger'));
      } finally {
        this.disabledCloneBtn = false;
      }
    },

    changeForecast(rowIndex, type, index, forecast) {
      this.$set(
        this.dashboard.rows[rowIndex].panels[type][index],
        'enable_forecast',
        forecast,
      );
    },

    async updateDashboardTimeframe() {
      const params = {
        relative_timeframe: this.shortcutName || 'custom',
        custom_timeframe_from: this.$moment(this.pickerDateRange[0]).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
        custom_timeframe_to: this.$moment(this.pickerDateRange[1]).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      };

      await this.$api.updateDashboardTimeframe(this.dashboard.id, params);
    },

    datepickerBlur() {
      if (!this.isChangeDatepicker) {
        this.updatePiker = randomizer();
      }
    },

    async selectDateRange(range, shortName) {
      this.isChangeDatepicker = true;
      if (range === null) {
        this.pickerDateRange = this.pickerOptions().shortcuts[3].range;
        this.shortcutName = this.pickerOptions().shortcuts[3].short_title;
        this.$nextTick(() => {
          this.shortcutName = this.pickerOptions().shortcuts[3].short_title;
          this.pickerDateRange = this.pickerOptions().shortcuts[3].range;
        });
      } else {
        this.pickerDateRange = range;
      }
      // await this.getDashboard();
      setTimeout(() => {
        if (range !== null) this.shortcutName = shortName;
        this.updateStat(undefined, true);
        this.updateChart(undefined, true);
        this.updateTable(undefined, true);
        this.isChangeDatepicker = false;
      });
    },

    changeAutorefresh(val) {
      this.refreshPeriod = val;
    },

    cancelDashboard(cb = Function) {
      /* datepicker change */
      this.pickerDateRange = this.cloneDateSettings.pickerDateRange;
      this.shortcutName = this.cloneDateSettings.shortcutName;
      this.setDateParams({
        relative_timeframe: this.shortcutName,
        custom_timeframe_from: this.pickerDateRange[0],
        custom_timeframe_to: this.pickerDateRange[1],
      });
      this.datepickerBlur();
      /* <--- datepicker change */

      this.dashboard = this.$_.cloneDeep(this.cloneDashboard);
      this.refreshPeriod = this.dashboard.refresh_period_seconds;
      this.editMode = false;
      this.$store.commit(`dashboards/${EDIT_MODE}`, this.editMode);
      window.onbeforeunload = null;
      this.editOnce = false;
      this.initGlobalFilters();
      this.rerenderGlobalFilter = randomizer();

      this.$nextTick(() => {
        this.updateStat('getLocalData');
        this.updateChart('getLocalData');
        this.updateTable('getLocalData');
        this.$refs.dashboardGlobalFilterRef.applyFilters();
        cb();
      });
    },

    parseFilter(filterData) {
      const noParse = ['traffic_type', 'players_filter'];
      const allKeyFilters = Object.keys(filterData);

      const parseFilter = allKeyFilters.reduce(
        (acc, val) => {
          if (noParse.includes(val)) {
            acc[val] = filterData[val].value;
            return acc;
          }
          acc.filters[val] = {
            op: filterData[val].op || '=',
            value: filterData[val].value,
          };
          return acc;
        },
        { filters: {} },
      );

      return parseFilter;
    },

    handlerGlobalFilter(filter, search) {
      this.filterFilters = filter;
      this.searchFilters = search;
      this.$store.commit(
        `dashboards/${SET_DASHBOARD_FILTER}`,
        this.computedFilters,
      );
      this.updateStat();
      this.updateChart();
      this.updateTable();
    },

    redirectIfNotHomePage() {
      if (
        this.overviewDashboard.length === 0
        && this.$route.path === '/dashboards/overview'
        && this.dashboards.length
      ) {
        this.$router.replace(`/dashboards/view/${this.dashboards[0].id}`);
        return 'break';
      }
      if (
        this.overviewDashboard.length === 0
        && this.$route.path !== '/dashboards/add-dashboard'
        && this.dashboards.length === 0
      ) {
        this.$router.push('/dashboards/add-dashboard');
        return 'break';
      }
      return 'continue';
    },

    setHomeDashboard() {
      this.isDefault = !this.isDefault;
      this.$store.commit(`dashboards/${EDIT_MODE}`, this.editMode);
      window.onbeforeunload = null;
      this.editOnce = true;
    },

    alertDeleteDashboard() {
      this.confirmType = 'delete';
      this.$refs.confirm.open();
    },

    alertCancelDashboard() {
      this.confirmType = 'cancel';
      this.$refs.confirm.open();
    },

    async deleteDashboardSuccess() {
      this.editMode = false;
      this.$store.commit(`dashboards/${EDIT_MODE}`, false);
      window.onbeforeunload = null;
      this.editOnce = false;
      this.$refs.confirm.close();
      if (this.overviewDashboard.length + this.dashboards.length === 1) {
        this.$router.replace('/dashboards/add-dashboard');
      } else if (
        this.overviewDashboard.length === 0
        && this.dashboards.length > 1
      ) {
        this.$router.replace(
          `/dashboards/view/${
            this.dashboards[this.dashboards[0].id === this.checkRoute ? 1 : 0]
              .id
          }`,
        );
      } else {
        this.$router.replace('/dashboards/overview');
      }
      await this.deleteDashboard();
      this.confirmType = '';
    },

    cancelHandler() {
      this.$refs.confirm.close();
      this.confirmType = '';
    },

    confirmHandler() {
      this.$refs.confirm.close();
      this.$store.commit(`dashboards/${EDIT_MODE}`, false);
      window.onbeforeunload = null;
      this.$router.push(this.dataRouteTo.fullPath);
    },

    confirmEditDashboards(to) {
      this.dataRouteTo = to;
      this.$nextTick(this.$refs.confirm.open);
    },

    async handlerRefresh() {
      this.loadAllItem = false;
      this.setDisabledBtnRefresh();
      await this.$store.dispatch('reports/getSettings');
      await this.getDashboard();
      this.initGlobalFilters();

      this.updateStat(undefined, true);
      this.updateChart(undefined, true);
      this.updateTable(undefined, true);
      this.setDashboardInterval(true);
      this.arrayIsLoading = [];
    },

    setDisabledBtnRefresh() {
      const delay = 10000;
      this.disabledRefresh = true;
      setTimeout(() => (this.disabledRefresh = false), delay);
    },

    setDashboardInterval(reload = false) {
      if (reload) clearInterval(this.intervalReload);
      const interval = this.refreshPeriod * 1000;

      this.intervalReload = setInterval(() => {
        this.setDateParams({
          relative_timeframe: this.shortcutName,
          custom_timeframe_from: this.pickerDateRange[0],
          custom_timeframe_to: this.pickerDateRange[1],
        });
        setTimeout(() => {
          this.updateStat();
          this.updateChart();
          this.updateTable();
        });
      }, interval);
    },

    async deleteDashboard() {
      await this.$api.deleteDashboard(this.dashboard.id);
      await this.getDashboard('only');
    },

    async changeLocale() {
      if (!this.editMode) await this.getDashboard();
      this.$nextTick(() => {
        this.updateStat();
        this.updateChart();
        this.updateTable();
      });
    },

    removeDashboardRow(rowIndex) {
      this.dashboard.rows.splice(rowIndex, 1);

      this.$nextTick(() => {
        this.updateStat('getLocalData');
        this.updateChart('getLocalData');
        this.updateTable('getLocalData');
      });
    },

    loadingMetrics(val) {
      let countItem = [];
      if (this.$refs.stat) countItem.push(...this.$refs.stat);
      if (this.$refs.chart) countItem.push(...this.$refs.chart);
      if (this.$refs.table) countItem.push(...this.$refs.table);
      countItem = countItem.length;
      this.arrayIsLoading.push(val);
      const countLoadedItem = this.arrayIsLoading.length;
      this.loadAllItem = countItem <= countLoadedItem;
    },

    changeDraggable() {
      this.random = randomizer();
      this.$nextTick(() => {
        this.updateStat('getLocalData');
        this.updateChart('getLocalData');
        this.updateTable('getLocalData');
      });
    },

    updateStat(type, force = false) {
      if (this.$refs.stat === undefined) return;

      this.$refs.stat.forEach((elem) => {
        if (force) elem.loading = true;
        elem.getStat(type);
      });
    },

    updateChart(type, force = false) {
      if (this.$refs.chart === undefined) return;

      this.$refs.chart.forEach((elem) => {
        if (force) elem.loading = true;
        elem.getReport(type);
      });
    },

    updateTable(type, force = false) {
      if (this.$refs.table === undefined) return;

      this.$refs.table.forEach((elem) => {
        if (force) elem.loading = true;
        elem.getReport(type);
      });
    },

    inputTitleRow(name, index) {
      this.dashboard.rows[index].name = name;
      this.$nextTick(() => {
        this.updateStat('getLocalData');
        this.updateChart('getLocalData');
        this.updateTable('getLocalData');
      });
    },

    addDashboardRow() {
      this.dashboard.rows.push({
        name: this.$t('dashboards.other.default_section'),
        panels: {
          stat: [],
          chart: [],
          table: [],
        },
      });
    },

    async editDashboard() {
      this.editMode = false;
      this.$store.commit(`dashboards/${EDIT_MODE}`, this.editMode);
      window.onbeforeunload = null;
      await this.$api.updateDashboard(
        this.dashboard.id,
        this.queryParamsDashboard,
      );
      if (this.isDefault) {
        await this.$api.setDefaultDashboard(this.dashboard.id);
      }
      if (this.isDefault && this.$route.path !== '/dashboards/overview') {
        this.$router.push('/dashboards/overview');
        return;
      }

      await this.getDashboard();

      this.updateStat();
      this.updateChart();
      this.updateTable();
    },

    /* prop method */
    async createDashboard() {
      try {
        const {
          data: { payload },
        } = await this.$api.createDashboards(this.queryParamsDashboard);
        const { id } = payload;
        if (this.isDefault) await this.$api.setDefaultDashboard(id);
        this.editMode = false;
        this.$store.commit(`dashboards/${EDIT_MODE}`, this.editMode);
        window.onbeforeunload = null;

        await this.getDashboard('only');
        this.$router.push(
          this.isDefault ? '/dashboards/overview' : `/dashboards/view/${id}`,
        );
      } catch (error) {
        this.$router.push('/dashboards/overview');
      }
    },

    setReportData(rowIndex, type, index, report) {
      this.$set(
        this.dashboard.rows[rowIndex].panels[type][index],
        'report',
        report,
      );
    },

    removeMetric(rowIndex, type, index) {
      this.dashboard.rows[rowIndex].panels[type].splice(index, 1);

      this.$nextTick(() => {
        this.updateStat('getLocalData');
        this.updateChart('getLocalData');
        this.updateTable('getLocalData');
      });
    },

    editMetric(rowIndex, type, index, data) {
      this.$refs.addDashboardMetrics.open('edit', rowIndex, index, data);
    },

    addMetricFromPopup(type, rowIndex, metricConfig, index) {
      const typeMetric = metricConfig.type;
      if (type === 'add') {
        this.dashboard.rows[rowIndex].panels[typeMetric].push(metricConfig);
      } else {
        this.$set(
          this.dashboard.rows[rowIndex].panels[typeMetric],
          index,
          metricConfig,
        );
      }

      this.$nextTick(() => {
        this.updateStat('getLocalData');
        this.updateChart('getLocalData');
        this.updateTable('getLocalData');
      });
    },

    handlerAddMerticRow(rowIndex) {
      this.$refs.addDashboardMetrics.open('add', rowIndex);
    },

    setDateParams(dashboard = {}) {
      if (dashboard.relative_timeframe === 'custom') {
        this.shortcutName = dashboard.relative_timeframe;
        this.pickerDateRange = [
          this.$moment(dashboard.custom_timeframe_from),
          this.$moment(dashboard.custom_timeframe_to),
        ];

        return;
      }

      const findShortcut = this.pickerOptions().shortcuts.find(
        sc => sc.short_title === dashboard.relative_timeframe,
      );

      if (findShortcut) {
        this.shortcutName = findShortcut.short_title;
        this.pickerDateRange = findShortcut.range;

        return;
      }

      this.shortcutName = this.pickerOptions().shortcuts[3].short_title;
      this.pickerDateRange = this.pickerOptions().shortcuts[3].range;
    },

    async initDashboard() {
      this.editMode = false;
      try {
        this.isLoadingDashboard = true;
        await this.getDashboard();
      } finally {
        this.isLoadingDashboard = false;
      }
    },

    async getDashboard(only) {
      await this.$store.dispatch(`dashboards/${GET_DASHBOARDS}`);
      if (only) return;

      this.dashboard = this.$_.cloneDeep(this.computedDashboard);

      this.setDateParams(this.dashboard);

      this.refreshPeriod = this.dashboard.refresh_period_seconds;

      this.initGlobalFilters();
      this.rerenderGlobalFilter = randomizer();
      this.updateStat();
    },

    decodeFilterSelect(dataApiFilter) {
      const noParse = ['traffic_type', 'players_filter'];

      const filterSelect = this.$_.cloneDeep(dataApiFilter);
      delete filterSelect.search;
      const valueDecoding = this.$_.cloneDeep(filterSelect);

      const keyFilters = Object.keys(valueDecoding.filters || {});
      const reducedObj = keyFilters.reduce((acc, val) => {
        acc[val] = valueDecoding.filters[val];
        return acc;
      }, {});
      const result = { ...reducedObj, ...valueDecoding };
      delete result.filters;

      noParse.forEach((elParse) => {
        if (Object.keys(result).includes(elParse)) {
          this.$set(result, elParse, {
            value: result[elParse],
            op: null,
          });
        }
      });

      return result;
    },

    initGlobalFilters() {
      this.filters = this.decodeFilterSelect(this.dashboard.filters || {});
      this.filterFilters = this.$_.cloneDeep(this.filters);
      this.searchFilters = this.dashboard.filters?.search || {};
      this.$store.commit(
        `dashboards/${SET_DASHBOARD_FILTER}`,
        this.computedFilters,
      );

      this.rerenderGlobalFilter = randomizer();
    },
  },
};
</script>
