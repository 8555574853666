<template>
  <div class="crm-language-tab">
    <div
      :class="[
        'crm-language-tab__container',
        'ui-d-flex',
        'ui-fg-1',
        'ui-w-wrap',
        'ui-m-xl-r',
      ]"
    >
      <div
        v-for="(language, languageIndex) in languages"
        :key="language.language_code"
        class="crm-language-tab__item"
        :class="{
          'crm-language-tab__item_active': selectedIndex === languageIndex,
          'crm-language-tab__item_has-error': !!errors[languageIndex],
        }"
        @click="$emit('select', languageIndex)"
      >
        <div class="crm-language-tab__item-content">
          {{ language.name }}
          <template v-if="language.is_default">
            ({{ 'Default' }})
          </template>
        </div>
      </div>
      <div
        key="filler"
        class="crm-language-tab__filler ui-fg-1"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CrmActionLanguageTab',
  props: {
    languages: {
      type: Array,
      default: () => [],
    },
    selectedIndex: {
      type: Number,
      default: -1,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.crm-language-tab {
  overflow: hidden;
  flex: 1;

  &__container {
    margin-left: -8px;
  }

  &__item {
    font-size: 14px;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #eaeaea;

    &::before {
      content: '';
      border-bottom: 1px solid #eaeaea;
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
    }

    &:first-child {
      margin-left: 8px;
    }

    & + & {
      padding-left: 8px;
    }

    &-content {
      padding: 8px 0;
    }

    &_active &-content {
      font-weight: 500;
      position: relative;

      &::after {
        content: '';
        border-bottom: 2px solid var(--primary-color);
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
      }
    }

    &_has-error {
      color: var(--danger-color);
    }
  }

  &__filler {
    border-bottom: 1px solid #eaeaea;
  }
}
</style>
