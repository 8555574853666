<template>
  <div class="affiliates">
    <router-view class="view" />
  </div>
</template>

<script>

export default {
  name: 'Affiliates',

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.affiliates{
  display: flex;
  flex-direction: column;
  .content-wrapper{
    flex-grow: 10;
    display: flex;
    flex-direction: column;
  }
}
</style>
