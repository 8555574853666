import store from '@/store';
import router from '@/router';

/**
 * Ignore the vuex err regarding  navigating to the page they are already on.
 * */
const ignoreDublicateError = (err) => {
  if (
    err.name !== 'NavigationDuplicated'
    && !err.message.includes('Avoided redundant navigation to current location')
  ) {
    logError(err);
  }
};

export default {
  checkUserPermissions(permissionName, permissionArray, permissionArrayValue) {
    const permissionAllow = store.getters['auth/currentAcl'][permissionName] === 'allow';
    let permissionArrayTransform;
    if (permissionArray === 'media_buying_payments_payout') {
      permissionArrayTransform = 'media_buying_payments_payout_statuses_visible';
    } else if (permissionArray === 'media_buying_payments_moderation') {
      permissionArrayTransform = 'media_buying_payments_moderation_statuses_visible';
    } else {
      permissionArrayTransform = permissionArray;
    }
    const arrayPermission = Array.isArray(store.getters['auth/currentAcl'][permissionArrayTransform]);
    const replacedPermissionArrayValue = permissionArrayValue ? permissionArrayValue.replace('-', '_') : '';
    let arrayPermissionAllow = true;
    if (arrayPermission) {
      arrayPermissionAllow = store.getters['auth/currentAcl'][permissionArrayTransform].includes(replacedPermissionArrayValue);
    }
    const isSuperUserTemp = store.getters['auth/adminAcl'].is_superuser;
    const isUserTemp = !isSuperUserTemp;
    const isAllowedTemp = isUserTemp && permissionAllow && arrayPermissionAllow;
    const isUserTeamlesTemp = isUserTemp && store.getters['auth/adminAcl'] && store.getters['auth/adminAcl'].teams && store.getters['auth/adminAcl'].teams.length === 0;
    return {
      isSuperUser: isSuperUserTemp,
      isUser: isUserTemp,
      isAllowed: isAllowedTemp,
      isUserTeamles: isUserTeamlesTemp,
    };
  },

  checkRequest(permissionName, permissionArray, permissionArrayValue) {
    const userPermission = this.checkUserPermissions(permissionName, permissionArray, permissionArrayValue);
    if (permissionName === 'teams_view' && userPermission.isUser) {
      return false;
    }
    return (userPermission.isSuperUser || userPermission.isAllowed) && !userPermission.isUserTeamles;
  },

  checkRoute(permissionName, permissionArray, permissionArrayValue) {
    const userPermission = this.checkUserPermissions(permissionName, permissionArray, permissionArrayValue);
    if (userPermission.isSuperUser) {
      return;
    }
    if (userPermission.isUserTeamles) {
      router.push('/profile').catch(ignoreDublicateError);
    }
    if (!userPermission.isAllowed || (permissionName === 'teams_view' && userPermission.isUser)) {
      if (store.getters['auth/currentAcl'].affiliates_view === 'allow') {
        router.push('/affiliates');
      } else if (store.getters['auth/currentAcl'].reports_view === 'allow') {
        router.push('/reports');
      } else {
        router.push('/profile').catch(ignoreDublicateError);
      }
    }
  },
};
