export default {
  settings: {
    name: 'Настройки',
    menu: {
      sites: 'Сайты',
    },
    fields: {
      country_name: {
        title: 'Страна',
      },
      desktop_domain: {
        title: 'ПК',
      },
      mobile_domain: {
        title: 'Мобильное устройство',
      },
    },
    domains: {
      title: 'Настройки сайтов',
      homepage: 'Главная страница',
      api: 'API',
      click: 'Клик',
      adserver: 'Adserver',
      banners: 'Баннеры',
      promo: 'Промо',
      promo_api: 'Промо API',
      mail: 'Mail',
    },
    tokens: {
      title: 'Управление токенами',
      actions: 'Действия',
      create_token: 'Создать токен',
      access_key: 'Access Key',
      secret_key: 'Secret Key',
      token_name: 'Имя токена',
      enter_token_name: 'Введите имя токена',
      title_delete_token: 'Удалить {token} Токен?',
      msg_delete_token: 'Вы уверены, что хотите удалить <span>{token}</span>?',
      delete: 'Удалить',
      service: 'Сервис',
      complete: 'Завершить',
      fields: {
        id: {
          title: 'Access Key',
        },
        name: {
          title: 'Имя',
        },
        service: {
          title: 'Сервис',
        },
        last_active: {
          title: 'Последнее использование',
        },
        created_at: {
          title: 'Создан',
        },
      },
    },
    fees: {
      brand: 'Бренд',
      placeholder_country: 'Выберите страну',
      deposit: 'Депозиты',
      withdrawal: 'Вывод средств',
      sport: 'Спорт',
      casino: 'Казино',
      gaming_tax: 'Игровой налог',
      admin: 'Администрирование',
      fees: 'Комиссии',
      default: 'По умолчанию',
      edit_fees: 'Редактировать комиссии',
      add_country: 'Добавить страну',
    },
    paymentMethods: {
      title: 'Платежные методы',
      fields: {
        id: {
          title: 'ID',
        },
        status: {
          title: 'Статус',
        },
        visibility: {
          title: 'Видимость',
        },
        name: {
          title: 'Название',
        },
        logo: {
          title: 'Логотип',
        },
        export: {
          title: 'Экспорт через API',
        },
        fileAttachments: {
          title: 'Прикрепление файлов',
        },
        minPaymentAmount: {
          title: 'Минимальный платеж',
        },
        currencies: {
          title: 'Валюты',
        },
        actions: {
          title: 'Действия',
        },
      },
      statuses: {
        active: 'Активен',
        disabled: 'Отключен',
      },
      fileAttachments: {
        required: 'Обязательно',
        allowed: 'Разрешено',
        no: 'Нет',
      },
      visible: 'Видимый',
      hidden: 'Скрытый',
      addPaymentMethod: 'Добавить платежный метод',
      popup: {
        title: 'Добавить платежный метод',
        titleEdit: 'Редактировать платежный метод',
        name: 'Название',
        currencies: 'Валюты',
        placeholderCurrencies: 'Выберите валюту',
        placeholderName: 'Название метода оплаты',
        status: 'Статус',
        statusHint: 'Отключенный платежный метод будет скрыт в Кабинете Партнера, недоступен для выбора и проведения платежей.',
        visibility: 'Видимость',
        visibilityHint: 'Скрытый платежный метод не будет отображаться в Кабинете Партнера, но может быть выбран для проведения платежей через админ. панель.',
        export: 'Экспорт через API',
        walletRegexp: 'Регулярное выражение для адреса кошелька',
        walletPlaceholder: 'Плейсхолдер для кошелька',
        requiredAffiliateInfo: 'Необходимые данные о партнере',
        description: 'Описание',
        descriptionPlaceholder: 'Ваше описание будет здесь',
        yes: 'Да',
        no: 'Нет',
        all: 'Все',
        selected: 'Выбранные',
        allowed: 'Разрешено',
        required: 'Обязательно',
        fileAttachments: 'Прикрепление файлов',
        logo: 'Логотип',
        minimalPaymentAmount: 'Минимальный платеж',
        minimalPaymentAmountCurrency: 'Валюта минимального платежа',
        dropFileHereOr: 'Перетащите файл сюда или',
        selectFile: 'нажмите, чтобы загрузить',
        notSelected: 'Не выбран',
        requiredOptions: {
          phone_number: 'Номер телефона',
          country_code: 'Код страны',
          address: 'Адрес',
          postal_code: 'Почтовый индекс',
        },
        limitFileSize: 'Размер файла не может превышать 100KB',
      },
    },
    mediaBuyingPaymentMethods: {
      title: 'Настройки медиабаинга',
      addCurrency: 'Добавить валюту',
      limits: 'Лимиты',
      default: 'По умолчанию',
      otherCurrencies: 'Другие валюты',
      fields: {
        id: {
          title: 'ID',
        },
        status: {
          title: 'Статус',
        },
        name: {
          title: 'Название',
        },
        logo: {
          title: 'Логотип',
        },
        currencies: {
          title: 'Валюты',
        },
        actions: {
          title: 'Действия',
        },
      },
    },
    systemSettings: {
      title: 'Системные настройки',
      affiliateProgramName: 'Название партнерской программы',
      emailSettings: 'Настройки Email',
      serviceEmailHint: 'Этот адрес будет использоваться в качестве отправителя для транзакционных писем, например: noreply@example.com',
      supportEmail: 'Email поддержки',
      replyTo: 'Reply-To',
      transactionalEmailSender: 'Отправитель транзакционных писем',
      supportEmailHint: 'Этот адрес будет использоваться как обратный для транзакционных писем',
      recaptcha: 'ReCAPTCHA',
      v3PublicKey: 'v3 Публичный ключ',
      v3SecretKey: 'v3 Секретный ключ',
      affiliatesTelegramBot: 'Telegram бот для партнеров',
      affiliatesTelegramBotHint: 'Telegram бот может использоваться для генерации трекинговых ссылок и получения уведомлений о смене доменов',
      botUsername: 'Имя бота',
      botToken: 'Токен бота',
      policies: 'Политики',
      policiesHint: 'Эти ссылки будут размещены на форме регистрации',
      termsOfServiceURL: 'Ссылка на правила сервиса',
      privacyPolicyURL: 'Ссылка на политику конфиденциальности',
      supportContacts: 'Контакты поддержки',
      supportContactsHint: 'Эта информация будет отображена на попапе после успешной регистрации',
      skype: 'Скайп',
      telegram: 'Телеграм',
      email: 'Email',
      referralProgramDefaultSettings: 'Настройки реферальной программы',
      referralProgram: 'Реферальная программа',
      referralPercent: 'Реферальный процент',
      enabled: 'Включена',
      disabled: 'Отключена',
      paymentsDefaultSettings: 'Платежи',
      domainChangeNotifications: 'Уведомления о смене домена',
      enableNotifications: 'Включить оповещения для партнеров по умолчанию',
      period: 'Период',
      negativeCarryover: 'Негативный баланс',
      yes: 'Да',
      no: 'Нет',
      on: 'Вкл',
      off: 'Выкл',
      popup: {
        affiliateProgram: 'Партнерская программа',
        referralProgram: 'Реферальная программа',
        revenueCalculation: 'Расчет дохода',
        ggr: 'GGR',
        ngr: 'NGR',
        net_project_profit: 'Чистая прибыль проекта',
        paymentPeriod: 'Платежный период',
        net7: 'Net 7',
        net15: 'Net 15',
        net30: 'Net 30',
        net60: 'Net 60',
        manual: 'Ручной',
      },
    },
    systemDomains: {
      title: 'Системные домены',
      default: 'По умолчанию',
      fields: {
        country_code: {
          title: 'Страна',
        },
        front_domain: {
          title: 'Домен фронта',
        },
        panel_domain: {
          title: 'Домен кабинета партнера',
        },
        admin_domain: {
          title: 'Домен админ. панели',
        },
      },
      confirm: {
        addSystemDomains: 'Добавить системный домен',
        editSystemDomains: 'Редактировать системный домен',
        deleteSystemDomains: 'Удалить системный домен',
        setAsDefaultDomains: 'Назначить по умолчанию',
        setAsDefault: 'Назначить по умолчанию ',
        confirmSetAsDefault: 'Вы уверены, что хотите установить предоставленные доменные имена по умолчанию для <b>{country_name}</b>?',
        confirmDelete: 'Вы уверены, что хотите удалить доменные имена для <b>{country_name}</b>?',

        country_code: 'Страна',
        notSelected: 'Не выбран',
        front_domain: 'Домен фронта',
        front_domain_placeholder: 'Введите домен фронта',
        panel_domain: 'Домен кабинета партнера',
        panel_domain_placeholder: 'Введите домен кабинета',
        admin_domain: 'Домен админ. панели',
        admin_domain_placeholder: 'Введите домен админ. панели',
      },
    },
    predefinedPage: {
      title: 'Предопределенные URL',
      url: 'URL',
      addUrl: 'Добавить URL',
      missingTranslations: 'Есть отсутствующие переводы',
      confirm: {
        deleteUrl: {
          title: 'Удалить {text}',
          msg: 'Вы уверены, что хотите удалить <b>{text}</b> из <b>{brandName}</b>?',
        },
        unsavedChanges: {
          title: 'Несохраненные изменения',
          msg: 'Есть несохраненные изменения. Хотите сохранить их или выйти без сохранения?',
        },
      },
    },
    offersCountryGroups: {
      title: 'Группы стран для офферов',
      addCountryGroup: 'Добавить новую группу стран',
      fields: {
        name: {
          title: 'Имя',
        },
        country_list: {
          title: 'Список стран',
        },
      },
      confirm: {
        editCountryGroup: 'Редактировать группу стран',
        addCountryGroup: 'Добавить новую группу стран',
        country_list: 'Список стран',
        name: 'Имя',
        deleteOffersCountryGroup: 'Удалить группу стран',
        confirmDelete: 'Вы уверены, что хотите удалить группу стран <b>{name}</b>?',
        label: 'Укажите имя группы и выберите для нее страны',
      },
    },
    currencies: {
      title: 'Валюты',
      allCurrencies: 'Все валюты',
      currency: 'Валюта',
      addCurrency: 'Добавить валюту',
      confirm: {
        currency: 'Валюта',
        deleteCurrency: 'Удалить валюту',
        confirmDelete: 'Вы уверены, что хотите удалить валюту <b>{name}</b>?',
        setAsDefaultCurrency: 'Назначить валютой по умолчанию',
        setAsDefault: 'Назначить по умолчанию',
        confirmSetAsDefault: 'Вы уверены, что хотите установить <b>{currency}</b> в качестве валюты по умолчанию?',
      },
      fields: {
        code: {
          title: 'Код',
        },
        name: {
          title: 'Имя',
        },
        type: {
          title: 'Тип',
        },
      },
    },
    buttons: {
      save: 'Сохранить',
    },
  },
};
