<template>
  <div class="cpa-conversions">
    <div class="head">
      <div class="section">
        <span class="title">{{ $t('payments.page_title.cpa_conversions') }}</span>
        <cpa-conversions-filter
          ref="filter"
          v-model="filters"
          class="btn ui-m-xl-l"
          @submit="getConversionsFilter"
        />
      </div>
      <div class="control">
        <div class="pagination">
          <ui-pagination
            :page="page"
            :page-size="limit"
            :count="count"
            show-size-select
            @page-change="pageChange"
          />
        </div>
      </div>
    </div>
    <ui-table
      ref="dataTable"
      v-loading="isDataLoading"
      class="table"
      :fields="fields"
      :data="data"
      :rows-count="limit"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      lazy-loading
      i18n-path="payments.cpa_conversions"
      element-loading-custom-class="data-loader-spinner"
      @sort="handleSort"
    >
    <!--        <template slot="append">-->
    <!--          <el-table-column-->
    <!--            :width="74"-->
    <!--            align="center"-->
    <!--            fixed="right"-->
    <!--            header-align="center"-->
    <!--          >-->
    <!--            <template slot="header" slot-scope="scope">-->
    <!--              {{ $t('settings.tokens.actions') }}-->
    <!--            </template>-->
    <!--            <template slot-scope="scope">-->
    <!--              <div class="row-value">-->
    <!--                <ActionIcon-->
    <!--                  :tooltip="$t('edit')"-->
    <!--                >-->
    <!--                  <router-link-->
    <!--                    :to="handleEdit(scope.row)"-->
    <!--                    class="action-ico"-->
    <!--                  >-->
    <!--                    <ui-icon-->
    <!--                      name="pen"-->
    <!--                      :color="$theme.accentColor"-->
    <!--                      lib="fa"-->
    <!--                    />-->
    <!--                  </router-link>-->
    <!--                </ActionIcon>-->
    <!--              </div>-->
    <!--            </template>-->
    <!--          </el-table-column>-->
    <!--        </template>-->
    </ui-table>
  </div>
</template>

<script>
import updateUrl from '@/service/updateUrl';
import detectPermissions from '@/service/detectPermissions';
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import CpaConversionsFilter from './components/CpaConversionsFilter.vue';

const sizes = {
  1080: 20,
  1440: 30,
};

const LS_KEY = 'cpa_conversions';
const viewName = `${LS_KEY}/limit`;
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'CpaConversions',
  components: { CpaConversionsFilter },
  mixins: [pageSizeMixin],
  data() {
    return {
      viewName,
      fields: [
        {
          name: 'incrementing_id',
          align: 'left',
          sortable: 'custom',
          width: '80',
          class: 'text',
        },
        {
          name: 'site_name',
          width: '188',
          align: 'left',
          renderValue: (val, row) => {
            const img = row.site_favicon_base64 ? `<img class="ui-m-sm-r" src="${row.site_favicon_base64}"/>` : '';

            return `<div style="overflow: hidden" class="ui-d-flex ui-ai-center">${img}<div class="text">${row.site_name}</div></div>`;
          },
        },
        {
          name: 'site_player_id',
          minWidth: '124',
          class: 'text',
          link: this.getPlayerLink,
        },
        {
          name: 'affiliate_email',
          align: 'left',
          minWidth: '190',
          class: 'text',
          link: this.getAffiliateLink,
        },
        {
          name: 'affiliate_offer_name',
          minWidth: '140',
          class: 'text',
        },
        {
          name: 'status',
          width: '76',
          class: 'text',
          computedClass: val => (this.statusMap[val] || {}).class,
          renderValue: (val) => {
            const { label = '' } = this.statusMap[val] || {};
            return label;
          },
        },
        {
          name: 'revenue',
          headerAlign: 'right',
          align: 'right',
          width: '92',
          format: 'formatMoney',
          computedClass: this.getCurrencyClass,
        },
        {
          name: 'hold_expires_at',
          width: '120',
          sortable: 'custom',
          format: 'date-time',
          class: 'text',
        },
        {
          name: 'reviewed_by',
          minWidth: '160',
          link: this.getReviewedByLink,
          renderValue: (_, row) => row.reviewed_by_admin_name,
        },
      ],

      filters: {},

      data: [],
      permissions: {},
      count: 0,
      page: 1,
      limit: pageSize,
      isDataLoading: false,
      blockedRequest: false,
      sortProp: '',
      sortOrder: '',

      confirm: {
        title: '',
        msg: '',
        type: 'success',
        actionName: '',
      },
    };
  },
  computed: {
    statusMap() {
      return {
        pending_qualification: {
          label: this.$t('payments.cpa_conversions.statuses.pending_qualification'),
        },
        in_hold: {
          label: this.$t('payments.cpa_conversions.statuses.in_hold'),
          class: 'row-value--info',
        },
        on_review: {
          label: this.$t('payments.cpa_conversions.statuses.on_review'),
          class: 'row-value--on_review',
        },
        approved: {
          label: this.$t('payments.cpa_conversions.statuses.approved'),
          class: 'row-value--approved',
        },
        declined: {
          label: this.$t('payments.cpa_conversions.statuses.declined'),
          class: 'row-value--declined',
        },
      };
    },
  },
  watch: {
    filters: {
      handler(nv) {
        this.$ls.set(`${LS_KEY}/filters`, JSON.stringify(nv));
      },
    },
    sortProp(newVal) {
      this.$ls.set(`${LS_KEY}/sort_column`, newVal || 'incrementing_id');
    },
    sortOrder(newVal) {
      this.$ls.set(`${LS_KEY}/sort_dir`, newVal || 'desc');
    },
    limit(newVal) {
      this.$ls.set(`${LS_KEY}/limit`, newVal || pageSize);
    },
    $route() {
      const _urlData = updateUrl.getParseParams(this.$route.hash);
      this.page = Number(this.$_.get(_urlData, ['page'])) || 1;
      this.limit = this.$ls.get(`${LS_KEY}/limit`) || pageSize;
      this.sortProp = this.$ls.get(`${LS_KEY}/sort_column`) || 'incrementing_id';
      this.sortOrder = this.$ls.get(`${LS_KEY}/sort_dir`) || 'desc';

      this.getCpaConversions();
    },
  },

  created() {
    if (detectPermissions.checkRequest('payments_view')) {
      const _urlData = updateUrl.getParseParams(this.$route.hash);
      if (!this.$_.isEmpty(_urlData)) {
        this.limit = Number(this.$_.get(_urlData, ['limit'])) || pageSize;
        this.page = Number(this.$_.get(_urlData, ['page'])) || 1;

        this.sortProp = this.$_.get(_urlData, ['sort_column']) || 'incrementing_id';
        this.sortOrder = this.$_.get(_urlData, ['sort_dir']) || 'desc';
        this.filters = this.$_.get(_urlData, ['filters'], {});
      } else {
        this.limit = this.$ls.get(`${LS_KEY}/limit`) || pageSize;
        this.sortProp = this.$ls.get(`${LS_KEY}/sort_column`) || 'incrementing_id';
        this.sortOrder = this.$ls.get(`${LS_KEY}/sort_dir`) || 'desc';
        this.filters = JSON.parse(this.$ls.get(`${LS_KEY}/filters`)) || {};
      }

      this.getCpaConversions();
    }
    detectPermissions.checkRoute('payments_view');
  },

  methods: {
    getConversionsFilter(val) {
      this.filters = val;
      this.getCpaConversions();
    },
    getAffiliateLink(row) {
      return `/affiliates/${row.affiliate_id}/info`;
    },
    getReviewedByLink(row) {
      return `/affiliates/${row.reviewed_by_admin_id}/info`;
    },
    getPlayerLink(row) {
      return `/players/${row.player_id}/info`;
    },
    handleEdit() {},
    getCpaConversions() {
      if (!this.blockedRequest) {
        this.isDataLoading = true;
        this.blockedRequest = true;

        this._completedFilterUrl();
        const _filter = this._query();

        this.$api
          .getCpaConversions(_filter)
          .then((res) => {
            this.count = res.data.misc.count;
            this.data = this.$_.cloneDeep(res.data.payload);
          })
          .finally(() => {
            setTimeout(() => {
              this.blockedRequest = false;
            }, 300);
            this.isDataLoading = false;
          });
      }
    },


    _query() {
      return {
        limit: this.limit,
        offset: (this.limit * this.page) - this.limit,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        ...this.filters,
      };
    },

    pageChange(page, size) {
      this.page = page;
      this.limit = size;
      this.getCpaConversions();
    },

    formatDate(value) {
      return this.$moment(value).locale(this.$i18n.locale).format('DD MMM YYYY');
    },
    getCurrencyClass(value) {
      return value < 0 ? 'negative' : '';
    },
    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop || '';
      } else {
        this.sortOrder = order;
      }

      this.getCpaConversions();
    },
    /*
    * Создаем filter URL
    * */
    _completedFilterUrl() {
      const _dataFilters = {
        limit: this.limit,
        page: this.page,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        filters: this.filters,
      };

      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  width: 100%;
  margin: 0 auto;

  /deep/ &.ui-table__data{
    .el-table{
      height: initial !important;
      max-height: initial !important;
      &__body-wrapper{
        height: initial !important;
      }
    }
  }
}
</style>
