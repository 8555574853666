var render = function render(_c,_vm){return _c('div',_vm._b({staticClass:"crm-panel",class:{
    [_vm.data.staticClass]: _vm.data.staticClass,
    ...(_vm.data.class || {})
  },style:(_vm.data.staticStyle || '')},'div',_vm.data.attrs,false),[_c('div',{staticClass:"crm-panel__content-wrapper",class:{
      'crm-panel__content-wrapper_type-default': _vm.props.type === 'default',
      'crm-panel__content-wrapper_type-compact': _vm.props.type === 'compact',
      'crm-panel__content-wrapper_type-contrast': _vm.props.type === 'contrast',
      'crm-panel__content-wrapper_error': _vm.props.error,
    }},[_vm._t("prepend"),_c('div',{staticClass:"crm-panel__content"},[_vm._t("default")],2),_vm._t("append")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }