<template>
  <div>
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirm.confirmType"
      :action-name="confirm.actionName"
      :action="confirm.confirmAction"
      :action-icon="confirm.actionIcon"
      :action-fa-icon="confirm.actionFaIcon"
      @save="onConfirm"
      @close="confirmClose"
    >
      <div
        slot="title"
        v-html="confirm.confirmTitle"
      />
      <div class="body__wrap">
        <i
          v-if="confirm.confirmAction === 'Decline'"
          :class="confirm.confirmType"
          class="fas fa-times"
        />
        <i
          v-else
          :class="confirm.confirmType"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirm.confirmMsg"
        />
      </div>
    </ui-confirm>
    <div class="affiliates-review">
      <div
        class="toolbar"
        @keyup.enter="refreshHandler"
      >
        <div class="wrapper">
          <div class="section">
            <span class="title">{{ $t('affiliates.menu.review') }}</span>
            <ui-input
              v-model.trim="search"
              :width="180"
              class="search"
              :is-search="true"
              @search="refreshHandler"
            />
          </div>
          <div class="section">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="paginationHandler"
            />
          </div>
        </div>
      </div>
      <ui-table
        v-loading="isDataLoading"
        :fields="fields"
        :data="data"
        :rows-count="limit"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        lazy-loading
        class="table"
        i18n-path="affiliates.list"
        element-loading-custom-class="data-loader-spinner"
        @sort="sortingHandler"
      >
        <template
          v-if="hasPermission('affiliates_can_decline')"
          slot="append"
        >
          <el-table-column align="center" width="45">
            <template v-if="calcPermissions('edit', scope)" slot-scope="scope">
              <ActionIcon
                :tooltip="$t('payments.history.decline')"
              >
                <span
                  class="action-ico"
                  @click="handleDeclineClick(scope.row, $event)"
                >
                  <ui-icon
                    name="times"
                    :color="$theme.dangerColor"
                    lib="fa"
                  />
                </span>
              </ActionIcon>
            </template>
          </el-table-column>
        </template>
      </ui-table>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import queryParamsMixin from '../mixins/query-params';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'affiliate/review/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'AffiliatesReview',
  mixins: [pageSizeMixin, queryParamsMixin],
  data() {
    return {
      isDataLoading: false,
      confirm: {
        confirmMsg: '',
        confirmAction: '',
        confirmTitle: '',
        confirmType: '',
        actionName: '',
        actionFaIcon: '',
        actionIcon: '',
        data: {},
      },
      fields: [
        {
          name: 'incrementing_id',
          width: '70',
          sortable: 'custom',
          align: 'left',
        },
        {
          name: 'email',
          minWidth: '280',
          align: 'left',
          sortable: 'custom',
          link: this.getAffiliateLink,
          computedClass(f, row) {
            return row.email_verified ? 'verified' : 'not-verified';
          },
        },
        {
          name: 'team_name',
          width: '120',
          minWidth: '120',
        },
        {
          name: 'referred_by_email',
          minWidth: '280',
          sortable: 'custom',
          link: this.getReferredAffiliateLink,
        },
        {
          name: 'country_code',
          minWidth: '200',
          align: 'left',
          sortable: 'custom',
          format: 'country',
        },
        {
          name: 'created_at',
          width: '134',
          align: 'left',
          headerAlign: 'left',
          sortable: 'custom',
          format: 'date-time',
        },
      ],
      data: [],

      search: '',
      count: 0,
      permissions: {},
      page: 1,
      limit: pageSize,
      sortProp: 'created_at',
      sortOrder: 'desc',

      options: {
        group_id: '',
        country_code: '',
        account_status: 'pending',
      },
    };
  },
  computed: {
    ...mapGetters({
      currentAcl: 'auth/currentAcl',
      adminAcl: 'auth/adminAcl',
    }),
    queryParams() {
      return {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        search: this.search || undefined,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        ...this.options,
      };
    },
  },

  methods: {
    calcPermissions(permission, data) {
      return this.$_.get(this.permissions, [data.row.id, permission]);
    },
    hasPermission(permission) {
      return (
        this.currentAcl[permission] === 'allow' || this.adminAcl.is_superuser
      );
    },
    getReferredAffiliateLink(row) {
      return `/affiliates/${row.referred_by_id}/info`;
    },
    handleDeclineClick(row) {
      this.confirm.confirmAction = 'Decline';
      this.confirm.confirmMsg = `${this.$t('affiliates.affiliatesInfo.declineText', { affiliateEmail: row.email })}`;
      this.confirm.actionName = `${this.$t('affiliates.affiliatesInfo.decline')}`;
      this.confirm.confirmTitle = `${this.$t('affiliates.confirm.title_8_2', { affiliateEmail: row.email })}`;
      this.confirm.confirmType = 'warning';
      this.confirm.actionFaIcon = 'check';
      this.confirm.data = row;
      this.$refs.confirm.open();
    },
    decline(id) {
      this.$api
        .rejectAffiliate(id, false)
        .then(() => {
          this.fetch();
        });
    },
    onConfirm() {
      this.isConfirmOpen = false;
      switch (this.confirm.confirmAction) {
      case 'Decline':
        this.decline(this.confirm.data.id);
        this.$refs.confirm.close();
        break;
      default:
        break;
      }
    },
    confirmClose() {
      this.confirm.actionIcon = '';
      this.confirm.actionFaIcon = '';
      this.confirm.data = {};
    },
    async getPendingAffiliates() {
      this.isDataLoading = true;

      const { data: { payload, misc } } = await this.$api.getAffiliates(this.queryParams);

      this.count = misc.count;
      this.permissions = misc.permissions;
      this.data = payload;

      this.isDataLoading = false;
    },

    fetch() {
      return this.getPendingAffiliates();
    },

    getAffiliateLink(row) {
      return `/affiliates/${row.id}/info`;
    },

    paginationHandler(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;

        this.getPendingAffiliates();
      }
    },

    sortingHandler({ prop, order }) {
      if (order === '') return;

      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }

      this.getPendingAffiliates();
    },

    refreshHandler() {
      this.page = 1;

      this.getPendingAffiliates();
    },
  },
};
</script>


<style lang="scss">
  @import "../../assets/styles/icons";

  .affiliates-review {
    .table-wrapper {
      .link.email {
        display: flex;
        align-items: center;

        &:before {
          content: "";
          font-family: "#{$ico-font-family}" !important;
          font-size: 10px;
          width: 12px;
          margin-right: 8px;
        }

        &.verified:before {
          content: $tick-icon;
        }
      }
    }
  }
</style>
