export default {
  /**
   * Get platform servers count
   *
   * @param {Object} params
   * @param {String} params.search
   *
   * @return {Promise<Object>} api response
   */
  getPlatformServersCount(params) {
    return this.affiliatesApi.get('/domains-management/platform-servers/count', { params });
  },

  /**
   * Get platform servers
   *
   * @param {Object} params
   * @param {String} params.sort_column
   * @param {String} params.sort_dir
   * @param {String} params.search
   * @param {Number} params.limit
   * @param {Number} params.offset
   *
   * @return {Promise<Object>} api response
   */
  getPlatformServers(params) {
    return this.affiliatesApi.get('/domains-management/platform-servers', { params });
  },

  /**
   * Create platform server
   *
   * @param {Object} data
   * @param {String} data.name
   * @param {String} data.description
   * @param {String} main_ipv4_address
   * @param {String} main_ipv6_address
   * @param {Array} additional_ipv4_addresses
   * @param {Array} additional_ipv6_addresses
   *
   * @return {Promise<Object>} api response
   */
  createPlatformServer(data) {
    return this.affiliatesApi.post('/domains-management/platform-servers', data);
  },

  /**
   * Get platform server by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  getPlatformServerById(id) {
    return this.affiliatesApi.get(`/domains-management/platform-servers/${id}`);
  },

  /**
   * Update platform server
   *
   * @param {String} id
   * @param {Object} data
   * @param {String} data.name
   * @param {String} data.description
   *
   * @return {Promise<Object>} api response
   */
  updatePlatformServer(id, data) {
    return this.affiliatesApi.put(`/domains-management/platform-servers/${id}`, data);
  },

  /**
   * Delete platform server by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  deletePlatformServer(id) {
    return this.affiliatesApi.delete(`/domains-management/platform-servers/${id}`);
  },

  /**
   * Update servers config
   *
   * @return {Promise<Object>} api response
   */
  updatePlatformServersConfig() {
    return this.affiliatesApi.post('/domains-management/platform-servers/update-servers-config');
  },
};
