<template>
  <div class="team-users">
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirmType"
      :action-name="actionName"
      :action="confirmAction"
      :action-icon="actionIcon"
      :action-fa-icon="actionFaIcon"
      @save="onConfirm"
      @close="confirmClose"
    >
      <div
        slot="title"
        v-html="confirmTitle"
      />
      <div class="body__wrap">
        <i
          v-if="confirmAction === 'delFromSystem'"
          :class="confirmType"
          class="fas fa-trash-alt"
        />
        <i
          v-else-if="confirmAction === 'delFromTeam'"
          :class="confirmType"
          class="fas fa-trash-alt"
        />
        <i
          v-else-if="confirmAction === 'blockUser'"
          :class="confirmType"
          class="fas fa-lock"
        />
        <i
          v-else-if="confirmAction === 'unblockUser'"
          :class="confirmType"
          class="fas fa-unlock"
        />
        <i
          v-else-if="confirmAction === 'reset2faUser'"
          :class="confirmType"
          class="fas fa-exclamation-triangle"
        />
        <i
          v-else-if="confirmAction === 'resetUserPassword'"
          :class="confirmType"
          class="fas fa-exclamation-triangle"
        />


        <i
          v-else-if="confirmAction === 'Decline'"
          :class="confirmType"
          class="fas fa-times"
        />
        <i
          v-else-if="confirmAction === 'Decline w/'"
          :class="confirmType"
          class="fas fa-bell"
        />

        <i
          v-else
          :class="confirmType"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirmMsg"
        />
      </div>
    </ui-confirm>
    <user-permissions
      v-show="isPermissionsOpen"
      :id="$route.params.id"
      ref="userPermissions"
      :user-info="popupUserInfo"
      :is-open="isPermissionsOpen"
      @close="onClosePermissions"
    />

    <add-users-in-team
      v-show="isPopupOpen"
      ref="addUsersInTeam"
      :team="teamInfo"
      :is-open="isPopupOpen"
      @refresh="getUsers"
      @close="onClosePopup"
    />

    <edit-teams-user
      v-show="isEditPopupOpen"
      :user="editUserInfo"
      :timezones="timezones"
      :messengers="messengers"
      :is-open="isEditPopupOpen"
      @close="onClosePopup"
      @refresh="getUsers"
    />

    <ui-confirm
      ref="createUser"
      :width="480"
      :action-name="`${this.$t(`team_popup.create_button`)}`"
      type="success"
      action-fa-icon="check"
      class="create-user-popup"
      @save="createUser"
    >
      <div
        slot="title"
        v-html="`${this.$t(`team_popup.create_user_title`)}`"
      />
      <adm-ui-scrollbar
        wrap-style="max-height: 60vh; height: auto;"
        :native="false"
      >
        <div class="body__wrap">
          <div class="select-wrap">
            <ui-input
              v-model="userInfo.name"
              :error="getError('name')"
              :placeholder="$t(`team_popup.name_placeholder`)"
              :label="$t(`team_popup.name_label`)"
              size="big"
              autosize
            />
          </div>
          <div class="select-wrap">
            <ui-input
              v-model="userInfo.email"
              :error="getError('email')"
              :placeholder="$t(`team_popup.email_placeholder`)"
              :label="$t(`team_popup.email_label`)"
              size="big"
              autosize
            />
          </div>
          <div class="select-wrap">
            <div class="select-block select-block__full">
              <span class="label">{{ $t(`team_popup.timezone_label`) }}</span>
              <el-select
                v-model="userInfo.timezone"
                :placeholder="$t(`team_popup.timezone_placeholder`)"
                filterable
                class="select"
                size="big"
              >
                <el-option
                  v-for="(item) in timezones"
                  :key="item.zone"
                  :label="item.name"
                  :value="item.zone"
                />
              </el-select>
            </div>
          </div>
          <div class="select-wrap">
            <div class="select-block select-block__full">
              <div class="select-block_title">
                <span class="label">{{ $t(`team_popup.see_stats_label`) }}</span>
                <el-checkbox
                  v-model="isOverall"
                  class="checkbox"
                >
                  {{ $t(`team_popup.see_stats_overall`) }}
                </el-checkbox>
              </div>
              <div class="date-block">
                <el-date-picker
                  ref="datepicker"
                  v-model="userInfo.entities_visibility_from"
                  :picker-options="{
                    firstDayOfWeek: 1,
                  }"
                  :class="{'error': $_.isArray(getError('entities_visibility_from'))}"
                  :placeholder="$t(`team_popup.see_stats_placeholder`)"
                  :disabled="isOverall"
                  format="dd MMM yyyy"
                  type="date"
                  class="dtpicker"
                />
                <div class="spacer">
                  –
                </div>
                <el-date-picker
                  ref="datepicker"
                  v-model="userInfo.entities_visibility_to"
                  :picker-options="{
                    firstDayOfWeek: 1,
                  }"
                  :class="{'error': $_.isArray(getError('entities_visibility_to'))}"
                  :placeholder="$t(`team_popup.see_stats_placeholder`)"
                  :disabled="isOverall"
                  format="dd MMM yyyy"
                  type="date"
                  class="dtpicker"
                />
              </div>
              <div
                v-if="$_.isArray(getError('entities_visibility_to'))"
                class="input-error"
              >
                {{ $t(`${errors.entities_visibility_to[0].message}`) }}
              </div>
            </div>
          </div>
          <div class="select-wrap">
            <div class="select-block">
              <span class="label">{{ $t(`team_popup.messenger_label`) }}</span>
              <el-select
                v-model="userInfo.messenger"
                :placeholder="$t(`team_popup.messenger_placeholder`)"
                size="big"
              >
                <el-option
                  v-for="item in messengers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
            <div class="select-block">
              <ui-input
                v-model="userInfo.messenger_id"
                :error="getError('messenger_id')"
                :placeholder="messengerPlaceholder"
                :disabled="!userInfo.messenger"
                :label="$t(`team_popup.messenger_id_label`)"
                size="big"
                autosize
              />
            </div>
          </div>
          <div class="select-wrap">
            <div class="select-block select-block__full">
              <span class="label">{{ $t(`team_popup.note_label`) }}</span>
              <el-input
                v-model="userInfo.notes"
                :error="getError('notes')"
                :rows="3"
                :placeholder="$t(`team_popup.note_placeholder`)"
                type="textarea"
                resize="none"
              />
            </div>
          </div>
        </div>
      </adm-ui-scrollbar>
    </ui-confirm>

    <div class="toolbar">
      <div class="section">
        <ui-input
          v-model.trim="searchQuery"
          is-search
          autosize
          @search="handleSearch"
        />
        <el-checkbox-group
          v-model="statusUser"
          class="status-user"
          @change="handleStatusUser"
        >
          <el-checkbox label="active">
            {{ $t('affiliates.listView.toolbar[1].statusFilter.active') }}
          </el-checkbox>
          <el-checkbox label="blocked">
            {{ $t('affiliates.listView.toolbar[1].statusFilter.blocked') }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="section">
        <ui-pagination
          :page="page"
          :page-size="limit"
          :count="count"
          show-size-select
          @page-change="pageChange"
        />
        <ui-button
          v-if="teamInfo.name"
          size="medium"
          color="green"
          class="btn"
          @click="addUsersHandler"
        >
          + {{ $t('teams.teams_buttons.add_user') }}
        </ui-button>
        <ui-button
          size="medium"
          color="green"
          class="btn"
          @click="createUserHandler"
        >
          + {{ $t('teams.teams_buttons.create_user') }}
        </ui-button>
      </div>
    </div>

    <ui-table
      :fields="fields"
      :data="tableData"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      lazy-loading

      i18n-path="team_users.list"
      element-loading-custom-class="data-loader-spinner"
      class="table"
      @sort="handleSort"
    >
      <template slot="append">
        <el-table-column
          :width="44"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <ActionIcon
              :tooltip="$t(`team_users.list.actions.name`)"
            >
              <el-dropdown
                class="actions"
                placement="bottom"
                trigger="click"
              >
                <span class="action-ico el-dropdown-link">
                  <ui-icon
                    name="ellipsis-h"
                    :color="$theme.accentColor"
                    lib="fa"
                  />
                </span>
                <el-dropdown-menu
                  slot="dropdown"
                  class="action-wrapper"
                >
                  <el-dropdown-item
                    v-for="(item, key) in $_.filter( actions, e => !(e.isHide && e.isHide(scope.row)) )"
                    :key="key"
                    :class="item.class"
                    :title="$t(`team_users.list.actions.${item.name}`)"
                    class="actions-item"
                  >
                    <div v-if="item.link && item.name !== 'login'">
                      <router-link
                        :to="item.link(scope.row)"
                      >
                        {{ $t(`team_users.list.actions.${item.name}`) }}
                      </router-link>
                    </div>
                    <a
                      v-else-if="item.name === 'login' && item.link"
                      class="login-button"
                      :href="item.link(scope.row)"
                      @click.stop.prevent
                    >
                      {{ $t(`team_users.list.actions.${item.name}`) }}
                    </a>
                    <div
                      v-else
                      @click="item.click(scope.row)"
                    >
                      {{ $t(`team_users.list.actions.${item.name}`) }}
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </ActionIcon>
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import app from '@/main';
import UserPermissions from '@/components/Popups/UserPermissions.vue';
import AddUsersInTeam from '@/components/Popups/AddUsersInTeam.vue';
import EditTeamsUser from '@/components/Popups/editTeamsUser.vue';

import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import sessionAction from '@/views/mixins/session-action';

const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = 'teams/affiliates/list/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  components: {
    UserPermissions,
    AddUsersInTeam,
    EditTeamsUser,
  },
  mixins: [pageSizeMixin, sessionAction],

  props: {
    teamInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      statusUser: ['active'],
      oldStatusUser: ['active'],

      viewName,
      page: 1,
      limit: 10,
      count: 0,

      isPopupOpen: false,
      isEditPopupOpen: false,
      isOverall: false,
      userInfo: {},
      editUserInfo: {},
      users: [],
      defaultParams: {
        name: '',
        email: '',
        timezone: 'UTC',
        messenger: '',
        messenger_id: '',
        notes: '',
        entities_visibility_from: '',
        entities_visibility_to: '',
      },
      searchQuery: '',
      options: {
        search: '',
        team_id: '',
        sort_column: '',
        sort_dir: '',
      },
      sortProp: 'name',
      sortOrder: 'asc',
      isDataLoading: true,
      isPermissionsOpen: false,
      fields: [
        {
          name: 'account_status',
          width: '32',
          align: 'center',
          sortable: 'custom',
          renderValue: this.renderStatus,
        },
        {
          name: 'name',
          className: 'users-table',
          sortable: 'custom',
          minWidth: '120',
          align: 'left',
          link: this.getUserProfile,
          linkAppend: (item) => {
            const days = item.last_seen_days_ago;
            if (days >= 14) {
              return this.$createElement(
                'el-dropdown', {
                  props: {
                    trigger: 'click',
                    placement: 'bottom',
                  },
                },
                [
                  this.$createElement(
                    'div',
                    {
                      class: 'el-dropdown-link',
                    },
                    [
                      this.$createElement('ui-icon', {
                        props: {
                          lib: 'fa',
                          substyle: 'fas',
                          color: this.$theme.dangerColor,
                          name: 'exclamation-triangle',
                        },
                      }),
                    ],
                  ),
                  this.$createElement(
                    'el-dropdown-menu',
                    {
                      class: 'user-login-dropdown',
                    },
                    [
                      this.$createElement(
                        'el-dropdown-item',
                        [
                          this.$createElement('span', this.$t('team_users.list.alert.last_seen')),
                          this.$createElement(
                            'span',
                            {
                              class: 'red-period',
                            }, `${this.$t('team_users.list.alert.days_ago', { days })}`,
                          ),
                        ],
                      ),
                    ],
                  ),
                ],
              );
            }
            return null;
          },
        },
        {
          name: 'email',
          minWidth: '150',
          align: 'left',
          sortable: true,
        },
        {
          name: 'last_active',
          width: '120',
          align: 'left',
          headerAlign: 'left',
          sortable: 'custom',
          format: 'format-date',
        },
        {
          name: 'teams',
          width: '55',
          align: 'center',
          headerAlign: 'left',
          sortable: false,
          linkAppend: (item) => {
            if (item.teams_count > 0) {
              const teams = (item.teams || []).map(team => this.$createElement('el-dropdown-item', team.name));

              return this.$createElement(
                'el-dropdown', {
                  class: 'user-teams',
                  props: {
                    trigger: 'click',
                    placement: 'bottom',
                  },
                },
                [
                  this.$createElement(
                    'div',
                    {
                      class: 'el-dropdown-link',
                    },
                    [
                      this.$createElement('span', item.teams_count),
                    ],
                  ),
                  this.$createElement(
                    'el-dropdown-menu',
                    {
                      class: 'user-teams-dropdown',
                    },
                    teams,
                  ),
                ],
              );
            }
            return this.$createElement('span', item.teams_count);
          },
        },
      ],
      actions: [
        {
          name: 'view_profile',
          link: this.getUserProfile,
        },
        {
          name: 'edit_profile',
          click: this.editUserProfile,
        },
        {
          name: 'permissions',
          // eslint-disable-next-line
          isHide: item => !!(this.$_.find(item.teams, item => item.type === 'owners') || item.teams_count === 0),
          click: this.permissionsHandler,
        },
        {
          name: 'reset_password',
          class: 'red-btn',
          click: this.resetUserPasswordHandler,
        },
        {
          name: 'reset_2fa',
          class: 'red-btn',
          click: this.reset2faHandler,
        },
        {
          name: 'unblock',
          click: this.unblockHandler,
          isHide: item => item.account_status === 'active',
        },
        {
          name: 'block',
          class: 'red-btn',
          click: this.blockHandler,
          isHide: item => item.account_status === 'blocked',
        },
        {
          name: 'delete_from_team',
          class: 'red-btn',
          isHide: () => this.$route.name === 'all-users',
          click: this.delFromTeamHandler,
        },
        {
          name: 'delete_from_system',
          class: 'red-btn',
          click: this.delFromSystemHandler,
        },
      ],
      errors: {},
      popupCreate: {},
      popupUserInfo: {},
      isConfirmOpen: false,
      confirmMsg: '',
      confirmAction: '',
      confirmTitle: '',
      confirmType: '',
      confirmData: {},
      actionName: '',
      actionFaIcon: '',
      actionIcon: '',
      userAccessTitle: '',
    };
  },

  computed: {
    tableData: {
      get() {
        return this.users ? this.users : [];
      },
    },

    messengerPlaceholder: {
      get() {
        if (this.userInfo && this.userInfo.messenger) {
          const placeholder = this.$_.find(this.messengers, ['id', this.userInfo.messenger]);
          return placeholder.example_placeholder;
        }
        return `${this.$t('team_popup.messenger_id_placeholder')}`;
      },
    },

    userData: {
      get() {
        return this.confirmData ? this.confirmData : {};
      },
      set(newVal) {
        this.confirmData = newVal;
      },
    },


    ...mapGetters({
      timezones: 'misc/timezones',
      messengers: 'misc/apiMessengers',
    }),
  },

  watch: {
    limit(value) {
      this.$ls.set('teams/details/users/limit', value);
    },

    searchQuery() {
      this.options.search = this.searchQuery;
    },

    $route() {
      this.searchQuery = '';
      this.getUsers();
    },
  },

  created() {
    this.sortProp = this.$ls.get('teams/details/users/prop') || 'name';
    this.sortOrder = this.$ls.get('teams/details/users/order') || 'asc';
    this.limit = +this.$ls.get('teams/details/users/limit') || pageSize;
    app.$eventBus.$on('open-popup-add-users-in-team', this.addUsersHandler);
  },

  beforeDestroy() {
    app.$eventBus.$off('open-popup-add-users-in-team', this.addUsersHandler);
  },

  mounted() {
    this.onSessionUnlock = this.getUsers;
    this.getUsers();
  },

  methods: {
    getError(key) {
      return this.$_.get(this.errors, [key], '');
    },
    handleSearch() {
      this.page = 1;
      this.getUsers();
    },

    handleStatusUser(value) {
      // if (value.length === 0 && this.oldStatusUser[0] === 'active') {
      //   this.statusUser = ['blocked'];
      //   this.oldStatusUser = this.statusUser;
      //   this.getUsers();
      //   return;
      // }
      // if (value.length === 0 && this.oldStatusUser[0] === 'blocked') {
      //   this.statusUser = ['active'];
      //   this.oldStatusUser = this.statusUser;
      //   this.getUsers();
      //   return;
      // }
      this.statusUser = value;
      this.oldStatusUser = value;
      this.getUsers();
    },

    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.getUsers();
      }
    },

    addUsersHandler() {
      this.isPopupOpen = true;
    },
    onClosePopup() {
      this.isPopupOpen = false;
      this.isEditPopupOpen = false;
    },
    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
        this.$ls.set('teams/details/users/prop', prop);
      } else {
        this.sortOrder = order;
        this.$ls.set('teams/details/users/order', order);
      }
      this.getUsers();
    },

    _query() {
      this.options.sort_column = this.sortProp;
      this.options.sort_dir = this.sortOrder;
      const _paramsFilter = {
        ...this.options,
        limit: this.limit,
        offset: this.limit * this.page - this.limit,
        account_status: this.statusUser,
      };
      return _paramsFilter;
    },

    getUsers() {
      this.errors = {};
      this.options.team_id = this.$route.params.id;
      this.$api.getAdmins(this._query()).then((response) => {
        this.users = response.data.payload;
        this.count = response.data.misc.count;
      })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },


    getUserProfile(row) {
      if (this.options.team_id) {
        return `/teams/user/${row.id}/info?team_id=${this.options.team_id}`;
      }
      return `/teams/user/${row.id}/info`;
    },
    renderStatus(value) {
      return `<div class="mark ${value}"/>`;
    },
    onConfirm() {
      this.isConfirmOpen = false;
      switch (this.confirmAction) {
      case 'delFromSystem':
        this.delFromSystem(this.userData);
        this.$refs.confirm.close();
        break;
      case 'delFromTeam':
        this.delFromTeam(this.userData);
        this.$refs.confirm.close();
        break;
      case 'blockUser':
        this.blockUser(this.userData);
        this.$refs.confirm.close();
        break;
      case 'unblockUser':
        this.unblockUser(this.userData);
        this.$refs.confirm.close();
        break;
      case 'reset2faUser':
        this.reset2faUser(this.userData);
        this.$refs.confirm.close();
        break;
      case 'resetUserPassword':
        this.resetUserPassword(this.userData);
        this.$refs.confirm.close();
        break;
      default:
        break;
      }
    },

    delFromSystemHandler(row) {
      this.confirmAction = 'delFromSystem';
      this.confirmTitle = `${this.$t('team_popup.delete_title_system', { name: row.name })}`;
      this.confirmMsg = `${this.$t('team_popup.delete_msg_system', { name: row.name })}`;
      this.actionName = `${this.$t('team_popup.delete_action_system')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'trash-alt';
      this.confirmData = row;
      this.$refs.confirm.open();
    },
    delFromSystem(data) {
      this.$api
        .deleteAdmins(data.id)
        .then(() => {
          this.getUsers();
          this.$emit('refresh');
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    delFromTeamHandler(row) {
      this.confirmAction = 'delFromTeam';
      this.confirmTitle = `${this.$t('team_popup.delete_title_team', { name: row.name, teamName: this.teamInfo.name })}`;
      this.confirmMsg = `${this.$t('team_popup.delete_msg_team', { name: row.name, teamName: this.teamInfo.name })}`;
      this.actionName = `${this.$t('team_popup.delete_action_team')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'trash-alt';
      this.confirmData = row;
      this.$refs.confirm.open();
    },
    delFromTeam(data) {
      this.$api
        .postDetachFromTeam(data.id, { team_id: this.$route.params.id })
        .then(() => {
          this.getUsers();
          this.$emit('refresh');
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    blockHandler(row) {
      this.confirmAction = 'blockUser';
      this.confirmTitle = `${this.$t('team_popup.block_title', { name: row.name })}`;
      this.confirmMsg = `${this.$t('team_popup.block_msg', { name: row.name })}`;
      this.actionName = `${this.$t('team_popup.block_action')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'lock';
      this.confirmData = row;
      this.$refs.confirm.open();
    },
    blockUser(data) {
      this.$api
        .postBlock(data.id)
        .then(() => {
          this.confirmData.account_status = 'blocked';
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    unblockHandler(row) {
      this.confirmAction = 'unblockUser';
      this.confirmTitle = `${this.$t('team_popup.unblock_title', { name: row.name })}`;
      this.confirmMsg = `${this.$t('team_popup.unblock_msg', { name: row.name })}`;
      this.actionName = `${this.$t('team_popup.unblock_action')}`;
      this.confirmType = 'success';
      this.actionFaIcon = 'unlock';
      this.confirmData = row;
      this.$refs.confirm.open();
    },
    unblockUser(data) {
      this.$api
        .postUnblock(data.id)
        .then(() => {
          this.confirmData.account_status = 'active';
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    reset2faHandler(row) {
      this.confirmAction = 'reset2faUser';
      this.confirmTitle = `${this.$t('team_popup.reset_2fa_title', { name: row.name })}`;
      this.confirmMsg = `${this.$t('team_popup.reset_2fa_msg', { name: row.name })}`;
      this.actionName = `${this.$t('team_popup.reset_2fa_action')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'check';
      this.confirmData = row;
      this.$refs.confirm.open();
    },
    reset2faUser(data) {
      this.$api
        .postResetTotp(data.id)
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    resetUserPasswordHandler(row) {
      this.confirmAction = 'resetUserPassword';
      this.confirmTitle = `${this.$t('team_popup.reset_pass_title', { name: row.name })}`;
      this.confirmMsg = `${this.$t('team_popup.reset_pass_msg', { name: row.name })}`;
      this.actionName = `${this.$t('team_popup.reset_pass_action')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'check';
      this.confirmData = row;
      this.$refs.confirm.open();
    },
    resetUserPassword(data) {
      this.$api
        .postResetPassword(data.id)
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    confirmClose() {
      this.actionIcon = '';
      this.actionFaIcon = '';
    },

    createUserHandler() {
      this.userInfo = this.$_.cloneDeep(this.defaultParams);
      this.isOverall = false;
      this.$refs.createUser.open();
    },

    createUser() {
      this.userAccessTitle = `${this.$t('team_popup.create_user_title')}`;

      if (this.isOverall) {
        this.userInfo.entities_visibility_from = null;
        this.userInfo.entities_visibility_to = null;
      } else {
        this.userInfo.entities_visibility_from = this.userInfo.entities_visibility_from === null || this.userInfo.entities_visibility_from === '' ? null : this.$moment(this.userInfo.entities_visibility_from).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.userInfo.entities_visibility_to = this.userInfo.entities_visibility_to === null || this.userInfo.entities_visibility_to === '' ? null : this.$moment(this.userInfo.entities_visibility_to).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }

      this.$api
        .postAdmins(this.userInfo)
        .then(() => {
          this.$emit('refresh-users-list');
          this.getUsers();
          this.$refs.createUser.close();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },

    editUserProfile(row) {
      this.editUserInfo = this.$_.cloneDeep(row);
      this.isEditPopupOpen = true;
    },

    permissionsHandler(row) {
      this.popupUserInfo = this.$_.cloneDeep(row);
      this.isPermissionsOpen = true;
    },
    onClosePermissions() {
      this.isPermissionsOpen = false;
    },
  },
};
</script>
