<template>
  <div
    class="pending-changes"
  >
    <div class="popup">
      <div class="head">
        <span
          class="name"
          v-html="$t('affiliates.affiliatesOffers.pending_date', {date: pendingChangesDate})"
        />
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div
        v-loading="isDataLoading"
        class="popup-body"
      >
        <offer-info
          v-if="isOpen"
          :is-diff="true"
          :offer-info="getOfferInfo"
        />
      </div>
      <div
        class="foot"
        :class="{ 'foot__is-not-edit': !isEditOffer }"
      >
        <ui-button
          v-if="isEditOffer"
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          @click="cancelScheduledChanges"
        >
          {{ $t(`affiliates.affiliatesOffers.cancel_pand_change`) }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          filled
          @click.native="close"
        >
          <i class="fas fa-check plus-icon-custom" />{{ $t(`affiliates.cardView.toolbar.btns.close`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
import OfferInfo from '@/components/OfferInfo.vue';

export default {
  name: 'PendingChanges',
  components: {
    OfferInfo,
  },
  props: {
    offerInfo: {
      type: Object,
      default() {
        return {};
      },
    },

    isOpen: {
      type: Boolean,
      required: true,
    },
    isEditOffer: {
      type: Boolean,
      required: true,
    },
    isDataLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    getOfferInfo() {
      if (!this.offerInfo.scheduled_offer_settings) {
        return this.offerInfo.scheduled_offer_settings;
      }

      const { payout_rules, ...restProps } = this.offerInfo.scheduled_offer_settings;
      return {
        ...restProps,
        payout_rules: payout_rules?.map(rule => ({
          ...rule,
          settings: {
            ...rule.settings,
            rev_share: rule.settings.rev_share ? {
              sport_levels: rule.settings.rev_share.sport_levels,
              casino_levels: this.$_.isEqual(rule.settings.rev_share.sport_levels, rule.settings.rev_share.casino_levels)
                ? null : rule.settings.rev_share.casino_levels,
            } : null,
            cpa: rule.settings.cpa?.map(this.genCpaParams) || null,
          },
        })) || null,
      };
    },
    pendingChangesDate() {
      return this.$options.filters.formatDate3(this.offerInfo.scheduled_changes_effective_timestamp);
    },
  },
  watch: {

  },

  async mounted() {
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    });
  },
  methods: {
    genCpaParams(cpa) {
      const {
        amount,
        currency_code,
        cap_period_seconds,
        cap_players_count,
        hold_seconds,
        conditions,
      } = cpa;
      const values = conditions.reduce((acc, item) => {
        acc[`${item.name}_min`] = item.min_value;
        acc[`${item.name}_max`] = item.max_value;

        return acc;
      }, {});
      return {
        amount,
        currency_code,
        cap_period_seconds,
        cap_players_count,
        hold_seconds,
        ...values,
      };
    },
    close() {
      this.$emit('close');
    },
    cancelScheduledChanges() {
      this.$emit('cancelScheduledChanges');
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
