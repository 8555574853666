import Connectors from './connectors';
import Interceptors from './interceptors';
import Requests from './requests';

export default {
  install(Vue, config) {
    const { affiliatesApi, miscApi } = Connectors;

    const affiliatesApiInstance = affiliatesApi(config.affiliatesBaseUrl);
    const miscApiInstance = miscApi(config.miscBaseUrl);

    affiliatesApiInstance.interceptors.request.use(Interceptors.requestSuccessInterceptor, Interceptors.requestErrorInterceptor);
    miscApiInstance.interceptors.request.use(Interceptors.requestSuccessInterceptor, Interceptors.requestErrorInterceptor);

    affiliatesApiInstance.interceptors.response.use(Interceptors.responseSuccessInterceptor, Interceptors.responseErrorInterceptor);
    miscApiInstance.interceptors.response.use(Interceptors.responseSuccessInterceptor, Interceptors.responseErrorInterceptor);

    if (!Vue.prototype.$api) {
      Object.defineProperty(Vue.prototype, '$api', {
        value: new Requests(affiliatesApiInstance, miscApiInstance),
        writable: false,
      });
    }

    if (!config.store.$api) {
      Object.defineProperty(config.store, '$api', {
        value: Vue.prototype.$api,
        writable: false,
      });
    }
  },
};
