import ruLocale from 'element-ui/lib/locale/lang/ru-RU';

// meta
import validation from './ru/validation';

// components
import theHeader from './ru/theHeader';

// views
import login from './ru/login';
import tfaSetup from './ru/tfaSetup';
import affiliates from './ru/affiliates';
import payments from './ru/payments';
import players from './ru/players';
import reports from './ru/reports';
import tools from './ru/tools';
import settings from './ru/settings';
import profile from './ru/profile';
import live from './ru/live';
import ui from './ru/ui';
import timezone from './ru/timezone';
import teams from './ru/teams';
import mediaBuying from './ru/mediaBuying';
import domainManagement from './ru/domainManagement';
import marketingMaterials from './ru/marketingMaterials';
import dashboards from './ru/dashboards';
import crm from './ru/crm';

export default {
  ...validation,
  ...theHeader,
  ...login,
  ...tfaSetup,
  ...affiliates,
  ...payments,
  ...players,
  ...reports,
  ...tools,
  ...settings,
  ...profile,
  ...ruLocale,
  ...live,
  ...ui,
  ...timezone,
  ...teams,
  ...mediaBuying,
  ...domainManagement,
  ...marketingMaterials,
  ...dashboards,
  ...crm,
};
