export default {
  domainManagement: {
    pageTitle: {
      dnsHostingAccounts: 'DNS Hosting Accounts',
      dedicatedSiteDomains: 'Dedicated Site Domains',
      domainRegistrarAccounts: 'Domain Registrar Accounts',
      platformServers: 'Servers',
      rotationGroups: 'Rotation Groups',
      serviceDomains: 'Service Domains',
    },
    fields: {
      affiliate_email: {
        title: 'Affiliate',
      },
      affiliate_offer_name: {
        title: 'Offer',
      },
      incrementing_id: {
        title: 'ID',
      },
      name: {
        title: 'Name',
      },
      setup_mode: {
        title: 'Mode',
      },
      group_name: {
        title: 'Group Name',
      },
      server_name: {
        title: 'Server Name',
      },
      account_name: {
        title: 'Account Name',
      },
      dns_hosting_name: {
        title: 'DNS Hosting Provider',
      },
      domain_registrar_name: {
        title: 'Domain Registrar Provider',
      },
      auto_dns_management: {
        title: 'DNS Records Management',
      },
      auto_ns_management: {
        title: 'NS Servers Management',
      },
      platform_service_domains_count: {
        title: 'Number of Domains',
      },
      created_at: {
        title: 'Created at',
      },
      ipv4_addresses_total_count: {
        title: 'IPv4',
      },
      ipv6_addresses_total_count: {
        title: 'IPv6',
      },
      main_ipv4_address: {
        title: 'IPv4',
      },
      main_ipv6_address: {
        title: 'IPv6',
      },
      ipv4_addresses_available_count: {
        title: 'Free IPv4 Addresses',
      },
      ipv6_addresses_available_count: {
        title: 'Free IPv6 Addresses',
      },
      affiliates_using_group_count: {
        title: 'Number of Affiliates',
      },
      traffic_last_30_days_count: {
        title: 'Traffic Last 30 Days',
      },
      traffic_last_7_days_count: {
        title: 'Traffic Last 7 Days',
      },
      traffic_last_day_count: {
        title: 'Traffic Last Day',
      },
      type: {
        title: 'Type',
        values: {
          default: 'Default',
          custom: 'Custom',
        },
      },
      dns_records_state: {
        title: 'DNS Records',
        values: {
          unknown: 'Unknown',
          valid: 'Valid',
          invalid: 'Invalid',
        },
      },
      latest_config_request_status: {
        title: 'Config Updates',
        values: {
          unknown: 'Unknown',
          ok: 'OK',
          warning: 'Warning',
        },
      },
      can_be_picked_automatically: {
        title: 'Auto Pick',
        values: {
          true: 'Enabled',
          false: 'Disabled',
        },
      },
      ns_records_state: {
        title: 'NS Records',
        values: {
          unknown: 'Unknown',
          valid: 'Valid',
          invalid: 'Invalid',
        },
      },
      ssl_certificate_state: {
        title: 'SSL',
        values: {
          unknown: 'Unknown',
          active: 'Active',
          expired: 'Expired',
          expiring_soon: 'Expiring Soon',
        },
      },
      reachability_state: {
        title: 'Availability',
        values: {
          unknown: 'Unknown',
          ok: 'OK',
          warning: 'Warning',
          severe: 'Severe',
        },
      },
      domain_name: {
        title: 'Domain Name',
      },
      platform_service_origin_name: {
        title: 'Service Type',
      },
      platform_server_name: {
        title: 'Server',
      },
      used_in_groups_count: {
        title: 'Rotation Groups',
      },
      blocked_countries_count: {
        title: 'Blocked Countries',
      },
      domain_registrar_account_name: {
        title: 'Registrar',
      },
      dns_hosting_account_name: {
        title: 'DNS Hosting',
      },
    },
    buttons: {
      add_dns_hosting_account: 'Add DNS Hosting Account',
      edit: 'Edit',
      delete: 'Delete',
      recheck: 'Re-Check',
      domains: 'Domains',
      add_domain_registrar_account: 'Add Domain Registrar Account',
      add_domain: 'Add Domain',
      pin_domain: 'Pin Domain',
      add_platform_server: 'Add Server',
      rebuild_servers_config: 'Rebuild Servers Configurations',
      view_script: 'View Script',
      add_rotation_group: 'Add Rotation Group',
      add_service_domain: 'Add Service Domain',
    },
    popup: {
      add_dedicated_site_domain: 'Add Dedicated Site Domain',
      placeholder_offer: 'Select Offer',
      placeholder_domain: 'Select Dedicated Domain',
      domain_title: 'Dedicated Domain',
      pin_aff_to_domain: 'Pinned Affiliate to Domain',
      info_input: 'Input domain names each from the new line.',
      text_error: 'The following domains have not been added:',
      domain_names: 'Domain Names',
      pinned: 'Pin',
      save: 'Save',
      cancel: 'Cancel',
      close: 'Close',
      add: 'Add',
      edit: 'Edit',
      done: 'Done',
      empty: 'Empty',
      allocate: 'Allocate',
      dnsHostingAccount: 'DNS Hosting Account',
      domainRegistrarAccount: 'Domain Registrar Account',
      initCommands: 'View Script',
      step: 'Step ',
      rotationGroup: 'Rotation Group',
      domains: 'Domains',
      serviceDomain: 'Service Domain',
      platformServer: 'Server',
      rotationGroups: 'Rotation Groups',
      form: {
        authentication_data: 'Authentication Data',
        name: {
          label: 'Name',
          placeholder: 'Enter name',
        },
        account_name: {
          label: 'Account Name',
          placeholder: 'Enter Account name',
        },
        auto_dns_management: {
          label: 'DNS Records Management',
          auto: 'Auto',
          manual: 'Manual',
        },
        dns_hosting_provider: {
          label: 'DNS Hosting Provider',
          placeholder: 'Select DNS Hosting Provider',
        },
        domin_registrar_provider: {
          label: 'Domain Registrar',
          placeholder: 'Select Domain Registrar',
        },
        auto_ns_management: {
          label: 'NS Servers Management',
        },
        domain_registrar_account: {
          label: 'Domain Registrar Account',
          placeholder: 'Select Domain Registrar Account',
        },
        api_token: {
          label: 'API Token',
          placeholder: 'Enter API Token',
        },
        account_id: {
          label: 'Account ID',
          placeholder: 'Enter Account ID',
        },
        server_name: {
          label: 'Server Name',
          placeholder: 'Enter server name',
        },
        server_description: {
          label: 'Server Description',
          placeholder: 'Enter server description',
        },
        main_ipv4_address: {
          label: 'Main IPv4',
          placeholder: 'Enter IPv4 address',
        },
        main_ipv6_address: {
          label: 'Main IPv6',
          placeholder: 'Enter IPv6 address',
        },
        additional_ipv4_addresses: {
          label: 'Additional IPv4',
          placeholder: 'Enter IPv4 addresses',
        },
        additional_ipv6_addresses: {
          label: 'Additional IPv6',
          placeholder: 'Enter IPv6 addresses',
        },
        group_name: {
          label: 'Group Name',
          placeholder: 'Enter group name',
        },
        group_description: {
          label: 'Group Description',
          placeholder: 'Enter group description',
        },
        auto_replace_banned_domains: {
          label: 'Auto Replace Banned Domains',
          yes: 'Yes',
          no: 'No',
        },
        countries_list: 'Countries List',
        countries_placeholder: 'Select Countries',
        countries_to_check_availability: {
          label: 'Countries to Check Availability',
          any: 'Any',
          only_selected: 'Only Selected',
          except_selected: 'Except Selected',
        },
        redirect_domain_id: {
          label: 'Redirects',
        },
        adserver_domain_id: {
          label: 'Adserver',
        },
        server_auto_pick: {
          label: 'Auto Pick',
        },
        landings_storage_domain_id: {
          label: 'Landings Storage',
        },
        banners_storage_domain_id: {
          label: 'Banners Storage',
        },
        promo_api_domain_id: {
          label: 'Promo API',
        },
        domain_name: {
          label: 'Domain Name',
          placeholder: 'Enter Domain Name',
        },
        dns_management: {
          label: 'DNS Records Management',
          auto: 'Auto',
          manual: 'Manual',
        },
        setup_mode: {
          label: 'Setup Mode',
          managed: 'Managed',
          manual: 'Manual',
        },
        ns_management: {
          label: 'NS Management',
          auto: 'Auto',
          manual: 'Manual',
        },
        ssl_issuance: {
          label: 'SSL Issuance',
          auto: 'Auto',
          manual: 'Manual',
        },
        pick_platform_server_type: {
          label: 'Pick Server & IP',
          auto: 'Auto',
          manual: 'Manual',
        },
        platform_service_origin_id: {
          label: 'Service Type',
          placeholder: 'Select Service Type',
        },
        platform_server_id: {
          label: 'Server',
          placeholder: 'Select Server',
        },
        domain_registrar_account_id: {
          label: 'Domain Registrar Account',
          placeholder: 'Select Domain Registrar Account',
        },
        ns_servers: {
          label: 'NS Servers',
          placeholder: 'Enter NS Servers',
        },
        dns_hosting_account_id: {
          label: 'DNS Hosting Account',
          placeholder: 'Select DNS Hosting Account',
        },
        platform_server_ipv4_address_id: {
          label: 'IPv4',
          placeholder: 'Select IPv4 Address',
        },
        platform_server_ipv6_address_id: {
          label: 'IPv6',
          placeholder: 'Select IPv6 Address',
        },
        platform_service_rotation_group_id: {
          label: 'Platform Service Rotation Group',
        },
      },
    },
    blockedPopup: {
      title: 'Blocked Countries',
      addCountry: 'Add Country',
      addCountryPlaceholder: 'Select Country',
      fields: {
        country_name: {
          title: 'Country',
        },
      },
    },
    messages: {
      servers_config_update_success: 'Servers configuration has been updated!',
      servers_config_update_fail: 'Servers configuration not updated!',
      success: 'Success!',
      failed: 'Failed!',
      delete: 'Are you sure you want to delete <span>{name}</span> ?',
      copied_to_clipboard: 'Copied to clipboard',
      copy_error: 'Copy error!',
    },
  },
};
