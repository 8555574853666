<template>
  <div class="activity-actions-languages">
    <crm-tag
      v-for="language in meta.base"
      :key="language.languageCode"
      class="ui-g-md"
      preset="gray"
      lib="none"
    >
      <template slot="prepend">
        <flag
          v-if="language.countryCode"
          :iso="language.countryCode"
        />
        <i
          v-else
          class="fas fa-globe"
        />
      </template>
      <span class="activity-actions-languages__language-name">
        {{ language.languageCode }}
      </span>
    </crm-tag>
    <el-tooltip
      v-if="meta.rest.length > 0"
      :open-delay="300"
      placement="top"
      effect="light"
    >
      <div
        slot="content"
        class="activity-actions-languages__language-rest-tooltip ui-g-w-md"
      >
        <div
          v-for="languageRest in meta.rest"
          :key="languageRest.languageCode"
          class="activity-actions-languages__tag-wrapper ui-g-md"
        >
          <crm-tag
            preset="gray"
            lib="none"
          >
            <template slot="prepend">
              <flag
                v-if="languageRest.countryCode"
                :iso="languageRest.countryCode"
              />
              <i
                v-else
                class="fas fa-globe"
              />
            </template>
            <span class="activity-actions-languages__language-name">
              {{ languageRest.languageCode }}
            </span>
          </crm-tag>
        </div>
      </div>
      <crm-tag
        key="rest"
        class="ui-g-md"
        preset="gray"
        lib="none"
      >
        <template slot="prepend">
          <!-- <i class="fas fa-plus"/> -->
          +
        </template>
        <span class="activity-actions-languages__language-name">
          {{ meta.rest.length }}
        </span>
      </crm-tag>
    </el-tooltip>
  </div>
</template>

<script>
import CrmTag from '@/views/CRM/components/CrmTag.vue';

export default {
  name: 'ActivityActionsLanguages',
  components: {
    CrmTag,
  },
  props: {
    meta: {
      type: Object,
      default: () => ({
        full: [],
        base: [],
        rest: [],
      }),
    },
  },
};
</script>

<style lang="scss">
  .activity-actions-languages {
    &__language-rest-tooltip {
      display: flex;
      max-width: 270px;
      flex-wrap: wrap;
    }

    &__language-name {
      text-transform: capitalize;
    }

    &__tag-wrapper {
      min-width: calc(20% - 8px);
    }
  }
</style>
