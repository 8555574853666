<template>
  <div
    v-if="isOpen"
    class="add-currency-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ $t('settings.currencies.addCurrency') }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div
        v-loading="isLoading"
        class="body"
      >
        <div class="ui-fg-1">
          <div class="field ui-m-xl-b currency">
            <span class="label-text">{{ $t('settings.currencies.confirm.currency') }}</span>
            <ui-select
              v-model="data.currency_code"
              :placeholder="$t('settings.currencies.confirm.currency')"
              :options="currencies"
              :exclude-options="excluded"
              width="100%"
              :filterable="true"
              :remote="false"
              value-prop="code"
              label-prop="name"
              :allow-remove-value="false"
              list-label-prop="code"
              class="filter_type"
              :class="{error: getError('currency_code')}"
            />
            <div v-if="getError('currency_code')" class="field-error">
              {{ getError('currency_code') }}
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="isLoading"
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          @click="handleSave()"
        >
          {{ $t('crm.buttons.save') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import errorHandleMixin from '@/views/mixins/error-hadle';
import { MISC_GET_CUSTOMER_CURRENCIES } from '@/store/action_types/misc';

export default {
  name: 'AddCurrencyPopup',
  components: {
  },
  mixins: [
    errorHandleMixin,
  ],
  props: {
    currencies: {
      type: Array,
      default: () => [],
    },
    excluded: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      isEdit: false,
      data: {
        ...this.getDefaultForm(),
      },
    };
  },

  computed: {},
  created() {},
  mounted() {},
  methods: {
    open() {
      this.isOpen = true;

      this.data = this.getDefaultForm();

      this.errors = {};
    },
    close() {
      this.isOpen = false;
      this.isEdit = false;
    },
    handleSave() {
      this.errors = {};
      const { currency_code } = this.data;
      const promise = this.$api.postCustomerCurrencies({ currency_code });

      promise.then(() => {
        this.close();
        this.$store.dispatch(`misc/${MISC_GET_CUSTOMER_CURRENCIES}`);
        this.$emit('save');
      })
        .catch((e) => {
          this.errors = e.data.errors;
        });
    },
    getDefaultForm() {
      return {
        currency_code: '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.add-currency-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
    width: 100%;
  }

  .row-bg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    background-color: var(--crm-panel-bg);
    height: 32px;

    font-size: 14px;
    font-weight: 500;
  }

  .popup {
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 8vh;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;


    .head {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      .name {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-right: 16px;
      }
      .close {
        font-size: 15px;
        cursor: pointer;
        &:hover {
          color: var(--main-color) !important;
        }
      }
    }

    .body {
      padding: 16px 24px;
      display: flex;
      position: relative;
      max-height: 84vh;

      .label {
        font-size: 14px;
      }

      .currency {
        position: relative;

        /deep/ {
          .field-error {
            position: absolute;
            right: 0;
            bottom: -32px;
          }
        }
      }

      .card__title{
        .card-content{
          .label{
            font-size: 24px;
            font-weight: 500;
            line-height: 1.25;
          }
        }
      }
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      .ui-button + .ui-button{
        margin-left: 8px;
      }
    }
  }

  .label-text {
    font-size: 14px;
    color: var(--crm-panel-text);
    margin-bottom: 5px;
    display: block;
  }

  .select {
    width: 100%;

    /deep/ {
      .el-input__suffix-inner {
        align-items: center;
        display: flex;
      }
    }

    &.error {
      /deep/ {
        .el-input__inner {
          border-color: var(--danger-color);
        }
      }
    }
  }

  .field-error {
    text-align: right;
  }

  .form-input {
    margin-bottom: 16px;
    position: relative;
  }
}
</style>
