import app from '../../main';
import store from '@/store';
import filtredMetricIsNotAvailable from '../../views/Dashboard/helper/filtredMetricIsNotAvailable';

import {
  GET_DASHBOARDS,
  GET_DASHBOARDS_TEMPLATES,
  EDIT_MODE,
  SET_DASHBOARD_FILTER,
  ADD_DASHBOARD_IS_LOADING,
  GET_DASHBOARD_BY_ID,
  GET_DASHBOARDS_FLAG_BLOCK,
} from '../action_types/dashboards';

const stateData = () => ({
  dashboards: [],
  dashboardsFlagBlock: false,
  dashboardById: {},
  templates: [],
  editMode: false,
  saveEditModePath: {},
  stateDashboardFilter: {},
  addDashbordIsLoading: false,
});

const getters = {
  overviewDashboard(state) {
    const availebleColumns = (
      app.$store.getters['reports/reportsSettings'].metrics || []
    ).map(metric => metric.column_name);
    const availebleDimensions = (
      app.$store.getters['reports/reportsSettings'].dimensions || []
    ).map(metric => metric.column_name);
    const findOverview = state.dashboards.filter(board => board.is_default);
    return filtredMetricIsNotAvailable(findOverview, availebleColumns, availebleDimensions).length
      ? filtredMetricIsNotAvailable(findOverview, availebleColumns, availebleDimensions)
      : filtredMetricIsNotAvailable(state.templates, availebleColumns, availebleDimensions);
    // const result = state.dashboards.filter(board => board.is_default);
    // return result;
  },

  dashboards: (state) => {
    const availebleColumns = (
      app.$store.getters['reports/reportsSettings'].metrics || []
    ).map(metric => metric.column_name);
    const availebleDimensions = (
      app.$store.getters['reports/reportsSettings'].dimensions || []
    ).map(metric => metric.column_name);

    const result = filtredMetricIsNotAvailable(
      state.dashboards,
      availebleColumns,
      availebleDimensions,
    ).filter(dash => !dash.is_default);
    // // const result = state.dashboards.filter(dash => !dash.is_default);
    // console.log(result, 'result');
    return result;
  },

  dashboardById(state) {
    const availebleColumns = (
      app.$store.getters['reports/reportsSettings'].metrics || []
    ).map(metric => metric.column_name);
    const availebleDimensions = (
      app.$store.getters['reports/reportsSettings'].dimensions || []
    ).map(metric => metric.column_name);

    const result = filtredMetricIsNotAvailable(
      [state.dashboardById],
      availebleColumns,
      availebleDimensions,
    );

    return result[0];
  },
};

const mutations = {
  [GET_DASHBOARDS_FLAG_BLOCK](state, flag) {
    state.dashboardsFlagBlock = flag;
  },

  [EDIT_MODE](state, value) {
    state.editMode = value;
  },

  [GET_DASHBOARDS_TEMPLATES](state, templates) {
    state.templates = templates;
  },

  [GET_DASHBOARDS](state, dashboards) {
    state.dashboards = dashboards;
  },

  [GET_DASHBOARD_BY_ID](state, dashboards) {
    state.dashboardById = dashboards;
  },

  [SET_DASHBOARD_FILTER](state, filters) {
    state.stateDashboardFilter = filters;
  },

  [ADD_DASHBOARD_IS_LOADING](state, loading) {
    state.addDashbordIsLoading = loading;
  },
};

const actions = {
  async [GET_DASHBOARDS_TEMPLATES]({ commit }) {
    // if (store.getters['auth/adminAcl'].is_superuser
    //   || store.getters['auth/currentAcl'].reports_view === 'allow') {
    //   const { data: { payload } } = await this.$api.getDashboardsTemplates();
    //   commit(GET_DASHBOARDS_TEMPLATES, payload);
    // }
    commit(GET_DASHBOARDS_TEMPLATES, []);
  },

  async [GET_DASHBOARDS]({ commit }) {
    const params = {
      sort_column: 'created_at',
      sort_dir: 'asc',
    };
    if (
      store.getters['auth/adminAcl'].is_superuser
      || store.getters['auth/currentAcl'].reports_view === 'allow'
    ) {
      const {
        data: { payload },
      } = await this.$api.getDashboards(params);
      commit(GET_DASHBOARDS, payload);
    }
  },

  async [GET_DASHBOARD_BY_ID]({ commit }, id) {
    if (
      store.getters['auth/adminAcl'].is_superuser
      || store.getters['auth/currentAcl'].reports_view === 'allow'
    ) {
      const {
        data: { payload },
      } = await this.$api.getDashboard(id);
      commit(GET_DASHBOARD_BY_ID, payload);
    }
  },
};

export default {
  state: stateData,
  getters,
  mutations,
  actions,
  namespaced: true,
};
