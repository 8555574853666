<template>
  <ui-tag
    class="crm-tag"
    :class="computedClasses"
    :icon="pIcon"
    :lib="lib"
    :size="size"
    @click="$emit('click', $event)"
  >
    <template slot="prepend">
      <slot name="prepend" />
    </template>
    <slot>{{ pText }}</slot>
  </ui-tag>
</template>


<script>
import UiTag from '@/views/CRM/components/UiTag.vue';

export default {
  components: {
    UiTag,
  },
  props: {
    icon: {
      type: String,
      default: undefined,
    },
    lib: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: undefined,
    },
    preset: {
      type: String,
      default: 'default',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    presetList() {
      return {
        default: () => ({}),
        contrast: () => ({
          type: 'contrast',
        }),
        gray: () => ({
          type: 'gray',
        }),
        red: () => ({
          type: 'red',
        }),
        'notify-email': () => ({
          text: 'Mail',
          icon: 'envelope',
          type: 'notify-email',
        }),
        'notify-sms': () => ({
          text: 'SMS',
          icon: 'comments',
          type: 'notify-sms',
        }),
        'notify-call': () => ({
          text: this.$t('crm.fields.actions.values.call_center'),
          icon: 'phone-flip',
          type: 'notify-call',
        }),
        'notify-push': () => ({
          text: this.$t('crm.fields.actions.values.push'),
          icon: 'bell',
          type: 'notify-push',
        }),
      };
    },
    pPreset() {
      const result = this.presetList[this.preset] || this.presetList.default;
      return result();
    },
    pIcon() {
      return this.icon || this.pPreset.icon;
    },
    pText() {
      return this.pPreset.text;
    },
    computedClasses() {
      const result = {
        'crm-tag_clickable': this.clickable || this.clickable === '',
      };
      if (this.pPreset.type) {
        result[`crm-tag_type-${this.pPreset.type}`] = true;
      }
      return result;
    },
  },
};
</script>


<style lang="scss">
.crm-tag {
  &_type-contrast {
    border-radius: 8px;
    line-height: 14px;
    border: solid 1px var(--primary-color);
    background-color: #ffffff;
    color: var(--primary-color);
  }

  &_type-gray {
    background-color: #eaeaea;
    color: #303634;
  }

  &_type-red {
    border-radius: 8px;
    line-height: 14px;
    border: solid 1px var(--danger-color);
    background-color: #ffffff;
    color: var(--danger-color);
  }

  &_type-notify-sms {
    background-color: #3e78cf;
    color: #ffffff;
  }

  @at-root #{selector-append(& + '_type-notify-sms', & + '_clickable')} {
    &:hover {
      background-color: darken(#3e78cf, 5%);
    }
  }

  &_type-notify-call {
    background-color: #4d9d5a;
    color: #ffffff;
  }

  @at-root #{selector-append(& + '_type-notify-call', & + '_clickable')} {
    &:hover {
      background-color: darken(#4d9d5a, 5%);
    }
  }

  &_type-notify-email {
    background-color: #20815e;
    color: #ffffff;
  }

  @at-root #{selector-append(& + '_type-notify-email', & + '_clickable')} {
    &:hover {
      background-color: darken(#20815e, 5%);
    }
  }

  &_type-notify-push {
    background-color: #92298f;
    color: #ffffff;
  }

  @at-root #{selector-append(& + '_type-notify-push', & + '_clickable')} {
    &:hover {
      background-color: darken(#92298f, 5%);
    }
  }

  &_clickable {
    cursor: pointer;
  }
}
</style>
