<template>
  <div class="wrapper">
    <ui-confirm
      ref="confirm"
      :width="715"
      :close-action-name="$t('affiliates.offer_popup.confirm_close.closeAction')"
      :action-name="$t('dashboards.other.save_changes')"
      action-fa-icon="check"
      @save="onSave"
    >
      <div slot="title">
        {{ $t('settings.fees.edit_fees') }}
      </div>
      <div>
        <div class="title ui-m-xl-b">
          <div class="popup-brand">
            <img
              v-if="activeBrand.favicon_base64"
              :src="activeBrand.favicon_base64"
              :alt="activeBrand.name"
              class="prefix"
            >
            <span class="title">{{ activeBrand.name }}</span>
          </div>
        </div>
        <adm-ui-scrollbar
          wrap-style="max-height: 60vh; height: auto;"
          :native="false"
        >
          <div v-loading="isLoading" class="edit-container">
            <div v-for="([key, field]) in Object.entries(confirm.fields)" :key="key" class="edit">
              <div class="header">
                <span class="title">
                  {{ $t(`settings.fees.${key}`) }}
                </span>
                <ui-button
                  icon="plus"
                  lib="fa"
                  substyle="fas"
                  color="#20815E"
                  :icon-size="12"
                  class="btn ui-filter-select__add-btn add-level"
                  @click="addCountry($event, field)"
                >
                  {{ $t('settings.fees.add_country') }}
                </ui-button>
              </div>
              <div class="row-wrapper">
                <div class="row">
                  <div class="ui-d-flex ui-jc-space-between ui-ai-center">
                    <div class="title">
                      {{ $t('settings.fees.default') }}
                    </div>
                    <div class="ui-d-flex ui-m-xl-l">
                      <ui-input
                        v-model="field.default_fee_percent"
                        post-icon="percent"
                        error-tooltip
                        width="70px"
                      />
                      <ui-icon
                        size="14px"
                        class="ui-m-md-l remove-level disabled"
                        lib="fa"
                        substyle="fas"
                        name="times"
                        :disabled="true"
                        :color="$theme.dangerColor"
                      />
                    </div>
                  </div>
                  <div v-if="getError(`${key}.default_fee_percent`)" class="field-error">
                    {{ getError(`${key}.default_fee_percent`) }}
                  </div>
                </div>
                <div v-for="(fee, i) in field.custom_fees" :key="fee.country_code" class="row">
                  <div class="ui-d-flex ui-jc-space-between">
                    <el-select
                      v-model="fee.country_code"
                      :placeholder="$t('settings.fees.placeholder_country')"
                      filterable
                      class="select"
                      size="big"
                    >
                      <el-option
                        v-for="item in filterCountries(field.custom_fees, fee.country_code)"
                        :key="item.code"
                        :label="item.name"
                        :value="item.code"
                      />
                    </el-select>
                    <div class="ui-d-flex ui-m-xl-l">
                      <ui-input
                        v-model.number="fee.fee_percent"
                        post-icon="percent"
                        width="70px"
                        class="percent"
                      />
                      <ui-icon
                        size="14px"
                        class="ui-m-md-l remove-level"
                        lib="fa"
                        substyle="fas"
                        name="times"
                        :color="$theme.dangerColor"
                        @click.native="removeLevel(i, field)"
                      />
                    </div>
                  </div>
                  <div v-if="getError(`${key}.custom_fees.${i}.fee_percent`) || getError(`${key}.custom_fees.${i}.country_code`)" class="field-error">
                    {{ getError(`${key}.custom_fees.${i}.fee_percent`) || getError(`${key}.custom_fees.${i}.country_code`) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </adm-ui-scrollbar>
      </div>
    </ui-confirm>
    <div class="toolbar">
      <span class="title">{{ $t('settings.fees.fees') }}</span>
      <ui-button
        icon="pen"
        lib="fa"
        substyle="fas"
        filled
        class="btn bold"
        @click="handleEditPayments"
      >
        {{ $t('settings.fees.edit_fees') }}
      </ui-button>
    </div>
    <div class="brands">
      <div
        v-for="item in brands"
        :key="item.id"
        class="brands-item-info"
        :class="{'active': item.id === currentBrand}"
        @click="onSelectBrand(item.id)"
      >
        <img
          v-if="item.favicon_base64"
          :src="item.favicon_base64"
          :alt="item.name"
        >
        {{ item.name }}
      </div>
    </div>
    <div v-loading="isLoading" class="body" :class="{ lazyLoading }">
      <div v-for="([key, value]) in Object.entries(fees)" :key="key" class="card card-column">
        <div class="title">
          <div class="title-content">
            <span>{{ $t(`settings.fees.${key}`) }}</span>
          </div>
        </div>
        <div class="card-content">
          <div class="card">
            <div class="field">
              <div>{{ $t('settings.fees.default') }}</div><div>{{ value.default_fee_percent }}%</div>
            </div>
            <div v-for="item in value.custom_fees" :key="item.country_code" class="field">
              <div v-if="countriesCode[item.country_code]" class="country-label">
                {{ countriesCode[item.country_code].name }}
              </div>
              <div>{{ item.fee_percent }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import { mapGetters } from 'vuex';
import errorHandleMixin from '@/views/mixins/error-hadle';
import sessionAction from '@/views/mixins/session-action';

const sortingArr = ['deposit', 'withdrawal', 'sport', 'casino', 'gaming_tax', 'admin'];

export default {
  name: 'Fees',
  mixins: [errorHandleMixin, sessionAction],
  data() {
    return {
      isLoading: true,
      lazyLoading: true,
      currentBrand: null,
      fees: {
        admin: {
          default_fee_percent: 0,
          custom_fees: [],
        },
        casino: {
          default_fee_percent: 0,
          custom_fees: [],
        },
        deposit: {
          default_fee_percent: 0,
          custom_fees: [],
        },
        gaming_tax: {
          default_fee_percent: 0,
          custom_fees: [],
        },
        sport: {
          default_fee_percent: 0,
          custom_fees: [],
        },
        withdrawal: {
          default_fee_percent: 0,
          custom_fees: [],
        },
      },
      confirm: {
        title: '',
        msg: '',
        type: '',
        action: '',
        actionName: '',
        fields: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      brands: 'misc/brands',
    }),
    countriesCode() {
      return this.countries.reduce((acc, item) => {
        acc[item.code] = item;
        return acc;
      }, {});
    },
    countries() {
      return this.$store.state.misc.countries || [];
    },
    activeBrand() {
      const { name = '', favicon_base64 = '' } = this.brands.find(brand => brand.id === this.currentBrand) || {};

      return {
        name,
        favicon_base64,
      };
    },
  },
  watch: {
    currentBrand(id) {
      this.fetchData(id);
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.lazyLoading = false;
      }, 200);
    });
  },
  created() {
    this.onSessionUnlock = () => {
      if (this.brands[0].id === this.currentBrand) {
        this.fetchData(this.brands[0].id);
      } else {
        this.onSelectBrand(this.brands[0].id);
      }
    };

    this.onSelectBrand(this.brands[0].id);
  },
  methods: {
    fetchData(id) {
      this.isLoading = true;
      return this.$api.getFees(id).then((res) => {
        this.fees = Object.entries(res.data.payload)
          .sort((a, b) => sortingArr.indexOf(a[0]) - sortingArr.indexOf(b[0]))
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});
        this.confirm.fields = this.$_.cloneDeep(this.fees);
        this.errors = {};
      }).finally(() => this.isLoading = false);
    },
    filterCountries(filter, curCode) {
      return this.countries.filter(lang => !filter.some(el => el.country_code === lang.code && lang.code !== curCode));
    },
    onSave() {
      this.$api.updateFees(this.currentBrand, this.confirm.fields).then(() => {
        this.$set(this, 'fees', this.$_.cloneDeep(this.confirm.fields));
        this.$refs.confirm.close();
      }).catch((e) => {
        this.errors = this.$_.cloneDeep(e.data.errors);
      });
    },
    removeLevel(i, field) {
      const levels = [...field.custom_fees];
      levels.splice(i, 1);
      field.custom_fees = levels;
    },
    addCountry(e, field) {
      field.custom_fees = [...field.custom_fees, {
        fee_percent: 0,
      }];

      e
        .target
        .closest('.edit')
        .getElementsByClassName('row-wrapper')[0]
        .lastChild
        .scrollIntoView({ block: 'end', behavior: 'smooth' });
    },
    onSelectBrand(id) {
      this.currentBrand = id;
    },
    handleEditPayments() {
      this.confirm.fields = this.$_.cloneDeep(this.fees);
      this.errors = {};
      this.$refs.confirm.open();
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper{
  width: 90%;
  margin: 0 auto;
}
.body {
  display: flex;
  gap: 24px;
  line-height: normal;
  position: relative;

  &.lazyLoading {
    display: none;
  }
}
.toolbar {
  margin: 16px 0;
  display: flex;
  justify-content: space-between;

  .title {
    font-size: 24px;
    margin-right: 16px;
    line-height: 30px;
    font-weight: 500;
  }
}

.select-wrap {
  padding-bottom: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.select-block {
  width: calc(50% - 8px);
  display: flex;
}

.body-title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal);
}

.brands {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 0 15px 0;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--border-color);

  .brands-item-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #303634;
    font-size: 16px;
    padding: 4px 0 2px 0;
    margin-right: 24px;
    height: 24px;
    position: relative;

    &.pending {
      cursor: default;
    }

    &.active {
      font-weight: 500;

      &:after {
        content: '';
        left: 0;
        right: 0;
        height: 2px;
        background-color: var(--main-color);
        position: absolute;
        bottom: -16px;
      }
    }

    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      border-radius: 4px;
    }
  }
}

.edit-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.edit {
    padding: 16px;
    border-radius: 8px;
    background-color: var(--crm-panel-bg);
    flex: calc(50% - 8px);
    box-sizing: border-box;
    position: relative;
    margin-top: 16px;

  &::after {
    content: '';
    border-bottom: 1px solid var(--border-color);
    position: absolute;
    top: -16px;
    left: 0;
    width: 100%;
  }

  &:nth-child(odd) {
    &::after {
      width: 110%;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .row {
    scroll-margin-bottom: 50px;

    & + .row {
      margin-top: 8px;
    }

    .field-error {
      margin-bottom: 0;
      text-align: right;
    }

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .title {
      font-size: 12px;
    }

    .percent {
      /deep/ span.input-wrapper {
        height: 34px;
      }
    }
  }

  .remove-level {
    cursor: pointer;

    &.disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }
}

.popup-brand {
  display: flex;
  align-items: center;

  .title {
    font-size: 16px;
    font-weight: 500;
    margin-left: 4px;
  }
}

.popup-brand-select {
  width: calc(50% - 8px);
  margin-top: 5px;
}

.country-label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-column {
  width: calc((100% - 120px) / 6);
}
</style>
