<template>
  <div class="test">
    <div class="content-wrapper">
      <h1>Test Page</h1>
      <el-collapse v-model="activeSections">
        <el-collapse-item
          title="Icons"
          name="icons"
        >
          <div class="icons-wrapper">
            <h2>Icons</h2>
            <h3>> All available icons</h3>
            <div class="icons">
              <div
                v-for="icon in icons"
                :key="icon"
                class="icon"
              >
                <ui-icon :name="icon" />
                <span class="label">{{ icon }}</span>
              </div>
            </div>
            <h3>> Customization</h3>
            <div class="icons">
              <div class="icon">
                <ui-icon name="settings" />
                <span class="label">Color</span>
                <span class="label">Default #4c525c</span>
              </div>
              <div class="icon">
                <ui-icon
                  :size="4"
                  name="arrow-down"
                />
                <span class="label">Color</span>
                <span class="label">Default #4c525c</span>
              </div>
              <div class="icon">
                <ui-icon
                  name="settings"
                  color="red"
                />
                <span class="label">Color</span>
                <span class="label">Red</span>
              </div>
              <div class="icon">
                <ui-icon
                  name="settings"
                  color="#ffce06"
                />
                <span class="label">Color</span>
                <span class="label">#ffce06</span>
              </div>
            </div>
            <div class="icons">
              <div class="icon">
                <ui-icon name="settings" />
                <span class="label">size (height)</span>
                <span class="label">Default 12px</span>
              </div>
              <div class="icon">
                <ui-icon
                  :size="24"
                  name="settings"
                />
                <span class="label">size</span>
                <span class="label">24 => '24px'</span>
              </div>
              <div class="icon">
                <ui-icon
                  :size="2"
                  name="minus"
                />
                <span class="label">size</span>
                <span class="label">2 => '2px'</span>
              </div>
              <div class="icon">
                <ui-icon
                  :size="4"
                  name="arrow-down"
                />
                <span class="label">size</span>
                <span class="label">4 => '4px'</span>
              </div>
              <div class="icon">
                <ui-icon
                  :size="80"
                  name="user"
                />
                <span class="label">size</span>
                <span class="label">80 => '80px'</span>
              </div>
              <div class="icon">
                <ui-icon
                  :size="'4em'"
                  name="settings"
                />
                <span class="label">size</span>
                <span class="label">'4em'</span>
              </div>
              <div class="icon">
                <ui-icon
                  :size="'2vh'"
                  name="settings"
                />
                <span class="label">size</span>
                <span class="label">'2vh'</span>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item
          title="Buttons"
          name="btns"
        >
          <div class="bnts-wrapper">
            <h2>Buttons</h2>
            <h3>> Default state with different content</h3>
            <div class="btns">
              <div class="item">
                <ui-button @click="fff('ru')" />
                <div class="description">
                  <div class="label">
                    Default State
                  </div>
                  <div class="label">
                    Empty
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button @click="fff('en')">
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Default State
                  </div>
                  <div class="label">
                    With Label
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button icon="settings" />
                <div class="description">
                  <div class="label">
                    Default State
                  </div>
                  <div class="label">
                    With Icon
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button icon="settings">
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Default State
                  </div>
                  <div class="label">
                    Label + Icon
                  </div>
                </div>
              </div>
            </div>
            <h3>> Customization</h3>
            <div class="btns">
              <div class="item">
                <ui-button filled>
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Filled
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button
                  theme="rounded"
                  filled
                >
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Theme: 'rounded'
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button disabled>
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Disabled
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button loading>
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Loading
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button size="big">
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Size: 'big'
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button
                  size="big"
                  filled
                >
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Size: 'big'
                  </div>
                  <div class="label">
                    Filled
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button
                  size="big"
                  filled
                  disabled
                >
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Size: 'big'
                  </div>
                  <div class="label">
                    Filled
                  </div>
                  <div class="label">
                    Disabled
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button
                  size="big"
                  filled
                  loading
                >
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Size: 'big'
                  </div>
                  <div class="label">
                    Filled
                  </div>
                  <div class="label">
                    Loading
                  </div>
                </div>
              </div>
            </div>
            <div class="btns">
              <div class="item">
                <ui-button color="green">
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Color: 'green'
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button
                  filled
                  color="green"
                >
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Color: 'green'
                  </div>
                  <div class="label">
                    Filled
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button
                  filled
                  disabled
                  color="green"
                >
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Color: 'green'
                  </div>
                  <div class="label">
                    Filled
                  </div>
                  <div class="label">
                    Disabled
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button
                  filled
                  loading
                  color="green"
                >
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Color: 'green'
                  </div>
                  <div class="label">
                    Filled
                  </div>
                  <div class="label">
                    Loading
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button color="red">
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Color: 'red'
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button
                  filled
                  color="red"
                >
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Color: 'red'
                  </div>
                  <div class="label">
                    Filled
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button
                  filled
                  disabled
                  color="red"
                >
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Color: 'red'
                  </div>
                  <div class="label">
                    Filled
                  </div>
                  <div class="label">
                    Disabled
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button
                  filled
                  loading
                  color="red"
                >
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Color: 'red'
                  </div>
                  <div class="label">
                    Filled
                  </div>
                  <div class="label">
                    Loading
                  </div>
                </div>
              </div>
            </div>
            <div class="btns">
              <div class="item">
                <ui-button
                  size="big"
                  icon="settings"
                />
                <div class="description">
                  <div class="label">
                    Default State
                  </div>
                  <div class="label">
                    With Icon
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button
                  size="big"
                  icon="settings"
                >
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Default State
                  </div>
                  <div class="label">
                    Label + Icon
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button
                  size="big"
                  icon="settings"
                  filled
                />
                <div class="description">
                  <div class="label">
                    Default State
                  </div>
                  <div class="label">
                    With Icon
                  </div>
                </div>
              </div>
              <div class="item">
                <ui-button
                  size="big"
                  icon="settings"
                  filled
                  color="red"
                >
                  Button
                </ui-button>
                <div class="description">
                  <div class="label">
                    Default State
                  </div>
                  <div class="label">
                    Label + Icon
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item
          title="Inputs"
          name="inputs"
        >
          <div class="inputs-wrapper">
            <h2>Inputs: {{ testValue }}</h2>
            <div class="inputs">
              <div class="item">
                <ui-input
                  v-model="testValue"
                  placeholder="Placeholder"
                  label="Label"
                  required
                />
                <div class="description">
                  <span class="label">Default input</span>
                </div>
              </div>
              <div class="item">
                <ui-input
                  v-model="testValue"
                  placeholder="Placeholder"
                  label="Label"
                  required
                  borderless
                />
                <div class="description">
                  <span class="label">Borderless input</span>
                </div>
              </div>
              <div class="item">
                <ui-input
                  v-model="testValue"
                  :width="300"
                  readonly
                  placeholder="Placeholder"
                  label="Label"
                />
                <div class="description">
                  <span class="label">Width: 300</span>
                  <span class="label">Readonly input</span>
                </div>
              </div>
              <div
                class="item"
                style="width: 400px"
              >
                <ui-input
                  v-model="testValue"
                  autosize
                  disabled
                />
                <div class="description">
                  <span class="label">Autosize, container width 400px</span>
                  <span class="label">Disabled input</span>
                </div>
              </div>
              <div class="item">
                <ui-input
                  v-model="testValue"
                  pre-icon="user"
                  post-icon="settings"
                />
                <div class="description">
                  <span class="label">Icons input</span>
                </div>
              </div>
              <div class="item">
                <ui-input
                  v-model="testValue"
                  clearable
                />
                <div class="description">
                  <span class="label">Clearable input</span>
                </div>
              </div>
              <div class="item">
                <ui-input
                  v-model="testValue"
                  required
                  label="Big Label"
                  placeholder="Big Placeholder"
                  size="big"
                />
                <div class="description">
                  <span class="label">Default input</span>
                  <span class="label">Size: 'big'</span>
                </div>
              </div>
              <div class="item">
                <ui-input
                  v-model="testValue"
                  pre-icon="user"
                  post-icon="settings"
                  size="big"
                />
                <div class="description">
                  <span class="label">Icons input</span>
                  <span class="label">Size: 'big'</span>
                </div>
              </div>
              <div class="item">
                <ui-input
                  v-model="testSearch"
                  is-search
                  @search="searchHandler"
                />
                <div class="description">
                  <span class="label">Search input</span>
                </div>
              </div>
            </div>
            <ui-range-input
              :value="testBet"
              type="number"
              @change="handleBetChange('testBet', $event)"
            />
          </div>
        </el-collapse-item>
        <el-collapse-item
          title="Selects"
          name="selects"
        >
          <div class="selects">
            <div class="item">
              <ui-select
                v-model="select1.value"
                :options="select1.options"
              />
              <div class="description">
                <span class="label">Default select</span>
              </div>
            </div>
            <div class="item">
              <ui-select
                v-model="select1.value"
                :options="select1.options"
                :label="'Label for select Label for select'"
              />
              <div class="description">
                <span class="label">Default select</span>
                <span class="label">With label</span>
              </div>
            </div>
            <div class="item">
              <ui-select
                v-model="select1.value"
                :options="select1.options"
                filterable
              />
              <div class="description">
                <span class="label">Default select</span>
                <span class="label">filterable</span>
              </div>
            </div>
            <div class="item">
              <ui-select
                v-model="select2.value"
                :options="select2.options"
                multiple
              />
              <div class="description">
                <span class="label">Muiltiple select</span>
              </div>
            </div>
            <!-- <div class="item">
              <ui-select
                v-model="select2.value"
                :options="select2.options"
                multiple
                filterable/>
              <div class="description">
                <span class="label">Muiltiple select</span>
                <span class="label">filterable</span>
              </div>
            </div> -->
            <!-- <div class="item">
              <ui-select
                v-model="select2.value"
                :options="select2.options"
                disabled/>
              <div class="description">
                <span class="label">Muiltiple select</span>
                <span class="label">disabled</span>
              </div>
            </div> -->
            <div class="item">
              <ui-select
                v-model="remoteSelect.value"
                :options="remoteSelect.options"
                :filter-method="remoteSelect.method"
                value-prop="id"
                label-prop="email"
                multiple
                filterable
                remote
              />
              <div class="description">
                <span class="label">Muiltiple select</span>
                <span class="label">Remote filter</span>
              </div>
            </div>
            <div class="item">
              <ui-filter
                v-model="filters"
                class="btn ui-filter-new"
              />
              <div class="description">
                <span class="label">Filter component</span>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item
          title="Popups"
          name="popups"
        >
          <div class="popups">
            <div class="item">
              <ui-popup-col ref="popup">
                123
              </ui-popup-col>
              <ui-button @click="openPopup">
                Open popup
              </ui-button>
              <div class="description">
                <div class="label">
                  Test Popup
                </div>
              </div>
            </div>
            <div class="item">
              <ui-confirm ref="popup">
                <div slot="title">
                  title
                </div>
                ferwhwerhewrhwerhwerhwerhwerewrhwerrhew
              </ui-confirm>
              <ui-button @click="openConfirm">
                Open Confirm
              </ui-button>
              <div class="description">
                <div class="label">
                  Test Confirm
                </div>
              </div>
            </div>
            <div class="item">
              <ui-popup ref="popup">
                <div slot="title">
                  title
                </div>
                ferwhwerhewrhwerhwerhwerhwerewrhwerrhew
              </ui-popup>
              <ui-button @click="openConfirm">
                Open Confirm
              </ui-button>
              <div class="description">
                <div class="label">
                  Test Confirm
                </div>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import UiRangeInput from '@/components/UIElements/UiRangeInput.vue';
import { I18N_SET_LANG } from '@/store/action_types/i18n';

export default {
  name: 'Test',
  components: {
    UiRangeInput,
  },
  data() {
    return {
      activeSections: ['popups'],
      icons: [
        'settings',
        'user',
        'arrow-down',
        'download',
        'tick',
        'arrow-right',
        'password',
        'refresh',
        'login',
        'notification',
        'edit',
        'filter',
        'eye',
        'hamburger',
        'copy',
        'link',
        'minus',
        'plus',
        'delete',
        'history',
        'notes',
        'phone',
        'skype',
        'telegram',
        'search',
        'ok',
        'left-chevron',
        'left-chevron-double',
        'right-chevron',
        'right-chevron-double',
        'logout',
        'loading',
        'info',
        'drilldown',
        'star-outline',
        'star-filled',
        'percent',
        'caret',
        'close',
        'handle',
        'whatsapp',
        'viber',
        'twitter',
        'instagram',
        'facebook',
      ],
      testValue: '123',
      testSearch: '',
      testBet: {
        min: 1000,
        max: null,
      },
      select1: {
        value: '',
        options: [
          {
            label: 'Option 1',
            value: 111,
          },
          {
            label: 'Option 2',
            value: 222,
          },
          {
            label: 'Option 3',
            value: 333,
          },
          {
            label: 'Option 4',
            value: 444,
          },
          {
            label: 'Option 5',
            value: 555,
          },
          {
            label: 'Option 6',
            value: 666,
          },
        ],
      },
      select2: {
        value: [],
        options: [
          {
            label: 'Option 1',
            value: 111,
          },
          {
            label: 'Option 2',
            value: 222,
          },
          {
            label: 'Option 3',
            value: 333,
          },
        ],
      },
      remoteSelect: {
        value: [],
        options: [],
        method(q) {
          const query = {
            limit: 1000,
            offset: 0,
            sort_column: '',
            sort_dir: '',
            search: q,
            site_id: '',
            group_id: [],
            country_code: '',
            account_status: ['approved'],
          };
          return new Promise((resolve, reject) => {
            this.$api.getAffiliates(query)
              .then((response) => {
                resolve(response.data.payload);
              })
              .catch((error) => {
                reject(error);
              });
          });
        },
      },
      filters: {},
    };
  },
  methods: {
    fff(lang) {
      this.$store.dispatch(`i18n/${I18N_SET_LANG}`, lang);
    },
    handleBetChange(type, event) {
      this[type] = this.$_.clone(event);
    },
    searchHandler(/* query */) {

    },
    openPopup() {
      this.$refs.popup.open();
    },
    openConfirm() {
      this.$refs.popup.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  display: flex;
  flex-direction: column;
  .content-wrapper {
    width: 90%;
    min-width: 1140px;
    margin: 0 auto;
    padding: 36px 0;
    flex-grow: 10;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 32px;
      font-weight: 300;
      text-transform: uppercase;
      margin-bottom: 32px;
    }
    h2 {
      font-size: 20px;
      font-weight: 300;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 16px;
    }
    .icons {
      display: flex;
      flex-wrap: wrap;
      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid #d3d3d3;
        .label {
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
          margin-top: 8px;
        }
      }
    }
    .btns,
    .inputs,
    .selects,
    .popups {
      display: flex;
      flex-wrap: wrap;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid #d3d3d3;
        .description {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
          margin-top: 12px;
          .label {
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
</style>
