export default {
  mainColor: 'green',
  logoName: 'spelet-logo.svg',
  maxLogoHeight: '24px',
  accentColor: '#20a052',
  activeColor: '#20a052',
  dangerColor: '#D6001C',
  uiTheme: {
    primaryColor: '#20a052',
    dangerColor: '#D6001C',
    infoColor: '#3b94e3',
    warningColor: '#ffce06',
    lightColor: '#fff',
    darkColor: '#303634',
    // the-header
    headerColor: '#222222',
    headerBorder: '#5b5b5b',
    headerText: '#ffffff',
    headerTextHover: '#55d186',
    headerTextBorder: '#55d186',
    reportDropdownBackground: '#20a052',
    reportDropdownBackgroundHover: '#222222',
    reportDropdownActive: '#222222',
    reportDropdownActiveHover: '#ffffff',
    scrollThumb: '#c3c3c3',
    scrollBackground: '#20815e',
    colorFirst: '#20815e',
    colorSecond: '#ffce05',
    backLink: '#20a052',
  },
};
