<template>
  <div
    v-if="isOpen"
    class="transfer-sub-affiliates-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ $t('affiliates.transfer_popup.transferSubAffiliates') }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div
        v-loading="isLoading"
        class="body overflow-visible"
      >
        <div class="form full-width">
          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t('affiliates.transfer_popup.from') }}</span>
              <ui-select
                v-model="fromAffiliate"
                :placeholder="$t('affiliates.transfer_popup.placeholder_affiliate')"
                :filter-method="getAffiliatesFrom"
                :exclude-options="excludeFromAffiliate"
                value-prop="id"
                label-prop="email"
                :remote="true"
                :allow-remove-value="false"
                filterable
                width="100%"
              />
              <div v-if="getError('affiliate_id_from')" class="field-error">
                {{ getError('affiliate_id_from') }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="field">
              <span class="title">{{ $t('affiliates.transfer_popup.sub_affiliates') }}</span>
              <el-radio-group v-model="subAffiliates">
                <div class="ui-m-md-t">
                  <el-radio
                    label="all"
                    class="checkbox"
                  >
                    {{ $t('affiliates.transfer_popup.all') }}
                  </el-radio>
                  <el-radio
                    label="selected"
                    class="checkbox"
                  >
                    {{ $t('affiliates.transfer_popup.selected') }}
                  </el-radio>
                </div>
              </el-radio-group>

              <div v-if="subAffiliates === 'selected'" class="ui-m-xl-t">
                <div class="field">
                  <span class="label-text">{{ $t('affiliates.transfer_popup.country') }}</span>
                  <el-select
                    v-model="selectedCountries"
                    filterable
                    class="select"
                    :class="{error: getError('country_codes')}"
                    :placeholder="$t('affiliates.transfer_popup.placeholder_country')"
                    multiple
                  >
                    <el-option
                      v-for="country in countries"
                      :key="country.code"
                      :label="country.name"
                      :value="country.code"
                    />
                  </el-select>
                  <div v-if="getError('country_codes')" class="field-error">
                    {{ getError('country_codes') }}
                  </div>
                </div>
                <div class="field">
                  <span class="label-text">{{ $t('affiliates.transfer_popup.earning') }}</span>
                  <div class="ui-d-flex ui-ai-center">
                    <el-radio-group
                      v-model="lastMonthRevenue"
                      size="small"
                    >
                      <el-radio-button label="<=">
                        ≤
                      </el-radio-button>
                      <el-radio-button label=">=">
                        ≥
                      </el-radio-button>
                    </el-radio-group>
                    <ui-input
                      v-model="lastMonthRevenueAmount"
                      class="field-input"
                      type="number"
                      :placeholder="$t('affiliates.transfer_popup.amount')"
                      :class="{ error: getError('filters.last_month_revenue.value') }"
                      @change="preventSymbol"
                    />
                    <div class="values ui-m-md-l currency">
                      <el-select
                        v-model="lastMonthRevenueCurrency"
                        :placeholder="$t('permissions.offers.not_selected')"
                        class="select"
                        filterable
                        size="small"
                        :class="{ error: getError('filters.last_month_revenue_currency_code') }"
                      >
                        <el-option
                          v-for="item in currencyOptions"
                          :key="item.code"
                          :label="item.name"
                          :value="item.code"
                        />
                      </el-select>
                      <div v-if="getError('filters.last_month_revenue_currency_code')" class="field-error">
                        {{ getError('filters.last_month_revenue_currency_code') }}
                      </div>
                    </div>
                  </div>
                  <div v-if="getError('filters.last_month_revenue.value')" class="field-error">
                    {{ getError('filters.last_month_revenue.value') }}
                  </div>
                </div>
                <div class="field">
                  <span class="label-text">{{ $t('affiliates.transfer_popup.balance') }}</span>
                  <div class="ui-d-flex ui-ai-center">
                    <el-radio-group
                      v-model="balance"
                      size="small"
                    >
                      <el-radio-button label="<=">
                        ≤
                      </el-radio-button>
                      <el-radio-button label=">=">
                        ≥
                      </el-radio-button>
                    </el-radio-group>
                    <ui-input
                      v-model="balanceAmount"
                      class="field-input"
                      :placeholder="$t('affiliates.transfer_popup.amount')"
                      :class="{ error: getError('filters.balance.value') }"
                      type="number"
                      @change="preventSymbol"
                    />
                    <div class="values ui-m-md-l currency">
                      <el-select
                        v-model="balanceCurrency"
                        :placeholder="$t('permissions.offers.not_selected')"
                        class="select"
                        filterable
                        size="small"
                        :class="{ error: getError('filters.balance_currency_code') }"
                      >
                        <el-option
                          v-for="item in currencyOptions"
                          :key="item.code"
                          :label="item.name"
                          :value="item.code"
                        />
                      </el-select>
                      <div v-if="getError('filters.balance_currency_code')" class="field-error">
                        {{ getError('filters.balance_currency_code') }}
                      </div>
                    </div>
                  </div>
                  <div v-if="getError('filters.balance.value')" class="field-error">
                    {{ getError('filters.balance.value') }}
                  </div>
                </div>
                <div class="field">
                  <span class="label-text">{{ $t('affiliates.transfer_popup.registration_date') }}</span>
                  <el-date-picker
                    ref="dateRange"
                    v-model="dateRange"
                    :picker-options="{
                      firstDayOfWeek: 1,
                    }"
                    :start-placeholder="$t('reports.other.start_date')"
                    :end-placeholder="$t('reports.other.end_date')"
                    type="daterange"
                    range-separator="–"
                    format="dd MMM yyyy"
                    class="date-picker"
                  />
                  <div v-if="getError('filters.registration_date.value')" class="field-error">
                    {{ getError('filters.registration_date.value') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t('affiliates.transfer_popup.to') }}</span>
              <ui-select
                v-model="toAffiliate"
                :placeholder="$t('affiliates.transfer_popup.placeholder_affiliate')"
                :filter-method="getAffiliatesTo"
                :exclude-options="excludeToAffiliate"
                value-prop="id"
                :disabled="!fromAffiliate"
                label-prop="email"
                :remote="true"
                :allow-remove-value="false"
                filterable
                width="100%"
              />
              <div v-if="getError('affiliate_id_to') || getError('hierarchy')" class="field-error">
                {{ getError('affiliate_id_to') || getError('hierarchy') }}
              </div>
            </div>
            <div>
              <div v-if="!fromAffiliate" class="hint" v-html="$t('affiliates.transfer_popup.emptyAffiliates')" />
              <div v-else class="hint">
                <span v-loading="isLoadingCounter" class="sub-affiliate-loader" :class="{ loading: isLoadingCounter }">
                  <span v-if="!isLoadingCounter" class="bold">{{ counterSubAffiliates }}</span>
                </span>
                &nbsp;
                <span v-html="$t('affiliates.transfer_popup.transferSubAffiliatesCounter')" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          lib="fa"
          filled
          substyle="fas"
          icon="check"
          :disabled="isLoading"
          @click="handleTransfer()"
        >
          {{ $t('affiliates.transfer_popup.transfer') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>

import { mapGetters } from 'vuex';
import errorHandleMixin from '@/views/mixins/error-hadle';

export default {
  name: 'TransferAffiliatesPopup',
  mixins: [errorHandleMixin],
  props: {
    currency: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      isLoadingCounter: false,
      counterSubAffiliates: 0,
      fromAffiliate: '',
      toAffiliate: '',
      selectedCountries: null,
      lastMonthRevenue: '<=',
      lastMonthRevenueAmount: null,
      lastMonthRevenueCurrency: null,
      balance: '<=',
      balanceAmount: null,
      balanceCurrency: null,
      dateRange: [],
      subAffiliates: 'all',
      affiliatesFrom: [],
      affiliatesTo: [],
      currencyOptions: [],
    };
  },

  computed: {
    ...mapGetters({
      countries: 'misc/countries',
    }),
    isValidSendTest() {
      return this.message;
    },
    excludeFromAffiliate() {
      return this.toAffiliate ? [this.toAffiliate] : [];
    },
    excludeToAffiliate() {
      return this.fromAffiliate ? [this.fromAffiliate] : [];
    },
    fetchParams() {
      return {
        ...(this.fromAffiliate && { affiliate_id_from: this.fromAffiliate }),
        ...(this.subAffiliates === 'selected' && {
          filters: {
            ...(this.selectedCountries?.length && {
              country_code: {
                op: '=',
                value: this.selectedCountries,
              },
            }),
            ...(this.balanceAmount !== null && this.balanceAmount !== '' && {
              balance: {
                op: this.balance,
                value: this.balanceAmount,
              },
            }),
            ...(this.lastMonthRevenueAmount !== null && this.lastMonthRevenueAmount !== '' && {
              last_month_revenue: {
                op: this.lastMonthRevenue,
                value: this.lastMonthRevenueAmount,
              },
            }),
            last_month_revenue_currency_code: this.lastMonthRevenueCurrency,
            balance_currency_code: this.balanceCurrency,
            ...(this.dateRange?.length && {
              registration_date: {
                op: 'between',
                value: [
                  this.$moment(this.dateRange[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                  this.$moment(this.dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
                ],
              },
            }),
          },
        }),
      };
    },
  },
  watch: {
    fetchParams(value) {
      if (this.fromAffiliate) {
        this.isLoadingCounter = true;
        this.fetchDataPopup(value);
      }
    },
    fromAffiliate(val) {
      if (val && this.toAffiliate) {
        this.toAffiliate = '';
      }
    },
  },
  mounted() {
    this.fetchDataPopup = this.$_.debounce(this.fetchData, 1000);
  },
  methods: {
    getAffiliatesFrom(search) {
      const query = {
        limit: 100,
        offset: 0,
        search,
        referral_program_enabled: true,
      };

      return new Promise((resolve, reject) => {
        this.$api
          .getAffiliates(query)
          .then((response) => {
            const array = _.unionBy(
              this.affiliatesFrom,
              response.data.payload,
              'id',
            );
            resolve(array);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAffiliatesTo(search) {
      if (!this.fromAffiliate) return new Promise(resolve => resolve([]));

      const body = {
        affiliate_id_from: this.fromAffiliate,
      };

      const params = {
        limit: 100,
        offset: 0,
        search,
        referral_program_enabled: true,
      };

      return new Promise((resolve, reject) => {
        this.$api
          .availableAffiliateReferralProgram(body, params)
          .then((response) => {
            const array = _.unionBy(
              this.affiliatesTo,
              response.data.payload,
              'id',
            );
            resolve(array);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    preventSymbol(e) {
      if (['e', 'E', '+', '-'].includes(e.key)) {
        e.preventDefault();
      }
    },
    resetToDefaultData() {
      this.isLoadingCounter = false;
      this.counterSubAffiliates = 0;
      this.fromAffiliate = '';
      this.toAffiliate = '';
      this.selectedCountries = null;
      this.lastMonthRevenue = '<=';
      this.lastMonthRevenueAmount = null;
      this.balance = '<=';
      this.balanceAmount = null;
      this.dateRange = [];
      this.subAffiliates = 'all';
      this.lastMonthRevenueCurrency = this.currency;
      this.balanceCurrency = this.currency;
    },
    fetchData(value) {
      this.$api.updateAffiliateReferralProgramCount(value).then((res) => {
        this.counterSubAffiliates = res?.data?.payload?.count || 0;
      }).finally(() => this.isLoadingCounter = false);
    },
    getCurrencies() {
      this.$api.getMiscCustomerCurrencies().then((res) => {
        this.currencyOptions = res.data.payload;
      });
    },
    open() {
      this.isOpen = true;
      this.getCurrencies();
      this.resetToDefaultData();
    },
    close() {
      this.isOpen = false;
    },
    handleTransfer() {
      this.$emit('confirm', {
        ...this.fetchParams,
        ...(this.toAffiliate && { affiliate_id_to: this.toAffiliate }),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow-visible {
  overflow: visible!important;
}

.field-error {
  text-align: right;
}

.transfer-sub-affiliates-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
    width: 100%;
  }

  .popup {
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 8vh;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;


    .head {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      .name {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
      }
      .close {
        font-size: 15px;
        cursor: pointer;
        &:hover {
          color: var(--main-color) !important;
        }
      }
    }

    .body {
      padding: 16px 24px;
      display: flex;
      position: relative;
      max-height: 84vh;
      min-height: 215px;
      overflow-y: auto;
      font-size: 14px;

      .hint {
        align-items: center;
        display: flex;
        padding: 5px 8px 5px 8px;
        line-height: 24px;
        border-radius: 4px;
        background-color: var(--crm-panel-bg);
      }

      .currency {
        position: relative;

        /deep/ .field-error {
          position: absolute;
          right: 0;
          bottom: -32px;
        }
      }

      .field-input {
        height: 32px;
        margin-left: 8px;

        /deep/ {
          .input-wrapper {
            height: 100%;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input[type=number] {
            -moz-appearance:textfield;
          }
        }
      }

      /deep/ {
        .ui-select {
          .control-wrapper {
            background-image: linear-gradient(180deg, #ffffff 0%, #f6f6f6 100%);

            .label {
              line-height: 16px;
            }
          }

          .placeholder {
            opacity: 0.7;
          }
        }

        .el-select {
          .el-input__suffix {
            display: flex;
            align-items: center;

            .el-select__caret {
              color: rgb(76, 82, 92);
              font-weight: 900;
            }
          }
        }
        .full-width {
          width: 100%;
        }

        .label-text, .el-radio__label {
          font-weight: 400!important;
        }

        .el-radio__label {
          font-size: 12px!important;
        }

        .error-textarea {
          .el-textarea__inner {
            border-color: var(--danger-color);
          }
        }

        .row:not(:first-child) {
          border-top: 1px solid #eaeaea;
          padding-top: 16px;
        }

        .row .bold {
          font-weight: 700;
        }

        .el-radio-button__inner:hover {
          color: #303634;
          background-color: var(--ddgroup-bg);
        }

        .el-radio-button {
          box-shadow: none!important;
        }
      }
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      .ui-button + .ui-button{
        margin-left: 8px;
      }
    }
  }
  .sub-affiliate-loader {
    display: inline-block;

    &.loading {
      width: 16px;
      height: 16px;
      z-index: 9;
    }

    /deep/ {
      .el-loading-mask {
        background-color: transparent;
      }
      .el-loading-spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-top: 0;
        transform: translateY(-50%);

        .circular {
          height: 100%;
          width: 14px;
          .path {
            stroke: var(--main-color);
            stroke-width: 4px;
          }
        }
      }
    }
  }

  /deep/ .el-date-editor .el-range-input {
    width: 42%;
  }
}
</style>
