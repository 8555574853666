<template>
  <div
    :class="{
      'tree': true,
      'tree_type-outer': type === 'outer'
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tree',

  props: {
    type: {
      type: String,
      default: undefined,
    },
    thickness: {
      type: String,
      default: 'medium',
    },
  },

  provide() {
    return {
      type: this.type,
      thickness: this.thickness,
    };
  },
};

</script>

<style lang="scss">
  .tree{
    padding-left: 10px;
    &_type-outer {
      padding-left: 0;
    }
  }
</style>
