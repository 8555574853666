<template>
  <div class="affiliate-activity">
    <div class="head">
      <div class="control">
        <div class="section">
          <div class="date-range-picker">
            <el-date-picker
              v-model="dateRange"
              :picker-options="{
                firstDayOfWeek: 1,
                shortcuts: quickDates
              }"
              :start-placeholder="$t('reports.other.start_date')"
              :end-placeholder="$t('reports.other.end_date')"
              type="daterange"
              range-separator="-"
              format="dd MMM yyyy"
              class="date-picker"
            />
          </div>
          <div class="filter">
            <el-radio-group
              v-model="initiator_type"
              @change="getLog"
            >
              <el-radio :label="'affiliate'">
                {{ $t('affiliates.affiliatesActivity.panel') }}
              </el-radio>
              <el-radio :label="'admin'">
                {{ $t('affiliates.affiliatesActivity.admin') }}
              </el-radio>
              <el-radio :label="'all'">
                {{ $t('affiliates.affiliatesActivity.overall') }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="pageChange"
            />
          </div>
        </div>
      </div>
    </div>
    <ui-table
      v-loading="isDataLoading"
      :fields="fields"
      :data="data"
      :rows-count="limit"
      lazy-loading
      i18n-path="affiliates.list"
      element-loading-custom-class="data-loader-spinner"
      disable-sort
    />
  </div>
</template>

<script>
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import updateUrl from '../../../service/updateUrl';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'affiliate/activity/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'AffiliateActivity',
  mixins: [pageSizeMixin],
  data() {
    return {
      viewName,
      id: '',
      info: {},
      fields: [
        {
          name: 'action_timestamp',
          align: 'left',
          width: '140',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD MMM YYYY HH:mm:ss',
          },
        },
        {
          name: 'action_name',
          align: 'left',
          width: '400',
          overflow: true,
        },
        {
          name: 'ip_address',
          align: 'left',
          format: 'ip-flag',
          class: 'ip-flex',
        },
        {
          name: 'initiator',
          align: 'left',
          renderValue: this.initiatorRender,
        },
        {
          name: 'os',
          align: 'left',
          renderValue: this.osRender,
        },
        {
          name: 'device',
          align: 'left',
          renderValue: this.deviceRender,
        },
      ],
      data: [],
      count: 0,
      page: 1,
      limit: pageSize,
      isDataLoading: false,
      dateRange: [],
      quickDates: [
        {
          text: this.$t('affiliates.affiliatesActivity.week'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(6, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('affiliates.affiliatesActivity.week_2'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(13, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('affiliates.affiliatesActivity.month'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(29, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
      ],
      initiator_type: 'admin',
    };
  },
  computed: {
    dataQuery() {
      return {
        affiliate_id: this.id,
        initiator_type: this.initiator_type,
        limit: this.limit,
        offset: (this.limit * this.page) - this.limit,
        from: this.dateRange[0]
          ? this.$moment(this.dateRange[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          : '',
        to: this.dateRange[1]
          ? this.$moment(this.dateRange[1])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          : '',
      };
    },
  },
  watch: {
    dateRange(nv) {
      if (!nv) {
        this.dateRange = this.quickDates[1].range;
      }
      this.getLog();
    },
  },
  created() {
    /*
    * Считываем hash
    * */
    const _paramsHash = updateUrl.getParseParams(this.$route.hash);

    if (!this.$_.isEmpty(_paramsHash)) {
      if (this.$moment(this.$_.get(_paramsHash, ['dateStart'])).isValid() && this.$moment(this.$_.get(_paramsHash, ['dateEnd'])).isValid()) {
        this.dateRange[0] = this.$_.get(_paramsHash, ['dateStart']);
        this.dateRange[1] = this.$_.get(_paramsHash, ['dateEnd']);
      }
      this.initiator_type = this.$_.get(_paramsHash, ['initiator_type']) || this.initiator_type;

      this.limit = Number(this.$_.get(_paramsHash, ['limit'])) || pageSize;
      this.page = Number(this.$_.get(_paramsHash, ['page'])) || 1;
    } else {
      this.dateRange = this.quickDates[2].range;
      this.limit = +this.$ls.get('affiliate/activity/limit') || pageSize;
    }


    this.id = this.$route.params.id;
    if (!this.id) {
      this.$router.push('/affiliates/list');
    }
  },
  mounted() {
    this.$api
      .getAffiliateInfo(this.id)
      .then((response) => {
        this.info = this.$_.cloneDeepWith(response.data.payload);
      });

    this.getLog();
  },
  methods: {
    initiatorRender(field, row) {
      return `${row.initiator_name} (${row.initiator_type})`;
    },
    deviceRender(field, row) {
      if (row.device_vendor || row.device_mode) {
        return `${row.device_vendor}/${row.device_model}`;
      }
      return 'unknown';
    },
    osRender(field, row) {
      if (field || row.browser) {
        return `${field}/${row.browser}`;
      }
      return 'unknown';
    },

    pageChange(page, size) {
      this.page = page;
      this.limit = size;
      this.getLog();
    },
    async getLog() {
      this.isDataLoading = true;

      /*
      * Update URL
      * */
      this._completedFilterUrl();
      try {
        const {
          data: {
            misc,
            payload,
          },
        } = await this.$api.getAffiliateActivity(this.dataQuery);
        this.count = misc.count;
        this.data = payload;
      } finally {
        this.isDataLoading = false;
      }
    },
    formatDate(value) {
      return value ? this.$moment(value).format('DD MMM YYYY  HH:mm') : '';
    },
    /*
    * Создаем filter URL
    * */
    _completedFilterUrl() {
      const _dataFilters = {
        dateStart: this.$moment(this.dateRange[0]).format('YYYY-MM-DD'),
        dateEnd: this.$moment(this.dateRange[1]).format('YYYY-MM-DD'),
        limit: this.limit,
        page: this.page,
        initiator_type: this.initiator_type,
      };
      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>
