var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-button",class:[
    _vm.theme,
    _vm.size,
    _vm.color,
    {'disabled': _vm.disabled},
    {'loading': _vm.loading},
    {'filled': _vm.filled},
    {'empty': _vm.isEmpty},
    {'transparent-background': _vm.transparentBackground}
  ],on:{"click":_vm.handleClick}},[(_vm.icon)?_c('ui-icon',{staticClass:"btn-icon",attrs:{"name":_vm.icon,"color":_vm.getIconColor,"lib":_vm.lib,"substyle":_vm.substyle,"size":_vm.getIconSize}}):_vm._e(),(!_vm.isEmpty)?_c('span',{staticClass:"btn-text"},[_vm._t("default")],2):_vm._e(),(_vm.loading)?_c('div',{staticClass:"loading-cover"},[_c('ui-icon',{staticClass:"spinner",attrs:{"color":_vm.getIconColor,"size":_vm.getIconSize + 4,"name":"loading"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }