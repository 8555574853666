<template>
  <div class="affiliates-list">
    <transfer-affiliates-popup
      ref="transferAffiliatesPopup"
      :props-errors="transferPopupErrors"
      :currency="currencyCode"
      @confirm="transferAffiliates"
    />
    <div class="toolbar" @keyup.enter="handleRefresh">
      <div class="wrapper">
        <div class="section filters">
          <span class="title">{{ $t('affiliates.name') }}</span>
          <ui-input
            v-model.trim="searchQuery"
            :width="180"
            class="select-label"
            is-search
            @search="handleRefresh"
          />
          <affiliates-filter
            ref="filter"
            v-model="filters"
            class="btn ui-filter-new"
            :props-errors.sync="errors"
            @submit="getAffiliatesFilter"
          />
        </div>
        <ui-currency-picker
          class="ui-m-md-r"
          :value="currencyCode"
          :currencies="customerCurrencies"
          @input="changeCurrency"
        />
        <div class="section">
          <ui-pagination
            :page="page"
            :page-size="limit"
            :count="count"
            show-size-select
            @page-change="pageChange"
          />

          <ui-button
            v-if="hasPermission('affiliates_sub_affiliates_edit_settings')"
            class="btn"
            lib="fa"
            substyle="fas"
            icon="right-left"
            @click="handleOpenTransferPopup"
          />

          <export-data :data="configExport" @exportData="exportData" />
        </div>
      </div>
    </div>

    <ui-table
      v-loading="isDataLoading"
      :fields="fields"
      :currency="currencyCode"
      :data="tableData"
      :rows-count="limit"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      lazy-loading
      i18n-path="affiliates.list"
      element-loading-custom-class="data-loader-spinner"
      class="table"
      @sort="handleSort"
    >
      <template
        v-if="hasPermission('reports_view') && affiliateStatisticSupported"
        slot="append"
      >
        <el-table-column align="center" width="45">
          <template slot-scope="scope">
            <ActionIcon
              :tooltip="$t('affiliates.cardView.toolbar.btns.statistics')"
            >
              <router-link
                v-if="isViewReportsItem(scope.row)"
                class="action-ico"
                :to="{
                  path: '/reports/custom',
                  query: {
                    affiliate_stats: $qs.stringify(affiliateStats(scope.row), {
                      arrayFormat: 'repeat',
                    }),
                  },
                }"
              >
                <ui-icon name="chart-bar" color="#3b94e3" lib="fa" />
              </router-link>
            </ActionIcon>
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import updateUrl from '../../service/updateUrl';
import detectPermissions from '../../service/detectPermissions';
import ExportData from '@/components/ExportData';
import TransferAffiliatesPopup from './components/TransferAffiliatesPopup.vue';
import AffiliatesFilter from './components/AffiliatesFilter.vue';

import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import formatCurrency from '@/views/mixins/format-currency';

const FILTERS = [
  'account_status',
  'country_code',
  'with_multiaccounts',
  'affiliate_referral_id',
  'referred_by',
  'referral_program_enabled',
  'media_buying_enabled',
  'payment_wallet',
  'payments_enabled',
  'payments_negative_carryover',
  'site_payment_method_id',
  'payments_net',
  'promo_code',
];

const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = 'affiliates/list/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'AffiliatesList',
  components: {
    ExportData,
    TransferAffiliatesPopup,
    AffiliatesFilter,
  },
  mixins: [pageSizeMixin, formatCurrency],
  data() {
    return {
      viewName,
      transferPopupErrors: {},
      configExport: [
        {
          command: 'csv',
          label: 'CSV',
        },
        {
          command: 'xlsx',
          label: 'XLSX',
        },
      ],
      fields: [
        {
          name: 'incrementing_id',
          width: '70',
          sortable: 'custom',
          align: 'left',
        },
        {
          name: 'email',
          minWidth: '280',
          sortable: 'custom',
          class: 'link-affiliafe-list',
          link: this.getAffiliateLink,
          linkAppend: this.renderStatus,
        },
        {
          name: 'team_name',
          width: '120',
          minWidth: '120',
        },
        {
          name: 'referred_by_email',
          minWidth: '280',
          sortable: 'custom',
          link: this.getReferredAffiliateLink,
        },
        {
          name: 'country_code',
          minWidth: '180',
          sortable: 'custom',
          format: 'country',
        },
        {
          name: 'last_month_revenue',
          minWidth: '170',
          align: 'right',
          headerAlign: 'left',
          sortable: 'custom',
          computedClass: this.getCurrencyClass,
          format: 'formatMoney',
        },
        {
          name: 'available_balance',
          minWidth: '120',
          align: 'right',
          headerAlign: 'left',
          sortable: 'custom',
          computedClass: this.getCurrencyClass,
          format: 'formatMoney',
          currencyKey: 'balance_currency_code',
        },
        {
          name: 'hold_balance',
          minWidth: '120',
          align: 'right',
          headerAlign: 'left',
          sortable: 'custom',
          computedClass: this.getCurrencyClass,
          format: 'formatMoney',
          currencyKey: 'balance_currency_code',
        },
        {
          name: 'created_at',
          width: '134',
          align: 'left',
          headerAlign: 'left',
          sortable: 'custom',
          format: 'date-time',
        },
      ],
      isDataLoading: false,
      tableData: [],
      permissions: [],
      exportUrls: {},
      page: 1,
      limit: pageSize,
      count: 0,
      searchQuery: '',
      currencyCode: '',
      filters: {
        account_status: ['approved'],
      },
      options: {
        sort_column: '',
        sort_dir: '',
        search: '',
      },
      errors: {},
      sortProp: '',
      sortOrder: '',
      defaultSort: {},
      tableHeight: 0,
      fetchData: Function,

      /*
       * Запретить вызов функции
       * */
      blockedFetch: false,
    };
  },

  computed: {
    ...mapGetters({
      countries: 'misc/countries',
      currencies: 'misc/currencies',
      groups: 'misc/groups',
      currentAcl: 'auth/currentAcl',
      adminAcl: 'auth/adminAcl',
      reportsSettings: 'reports/reportsSettings',
      customerCurrencies: 'misc/customerCurrencies',
    }),
    isViewReports() {
      const hasPermit = this.currentAcl.reports_view === 'allow';
      const isSuperUser = this.adminAcl.is_superuser;
      return hasPermit || isSuperUser;
    },

    affiliateStatisticSupported() {
      return !!this.reportsSettings.dimensions
        .map(dim => dim.column_name)
        .find(grouping => grouping === 'affiliate_email');
    },
  },

  watch: {
    searchQuery() {
      this.options.search = this.searchQuery;
    },
    sortProp(newVal) {
      this.options.sort_column = this.sortProp;
      /*
       * Прверяем сортировку чтобы не было шибки с сервера 422
       * */
      if (
        this.fields.some(
          item => item.name === this.options.sort_column && item.sortable === 'custom',
        )
      ) {
        this.$ls.set('Affiliates/List/sort_column', newVal || 'created_at');
      }
    },
    sortOrder(newVal) {
      this.options.sort_dir = this.sortOrder;
      this.$ls.set('Affiliates/List/sort_dir', newVal || 'desc');
    },
    currencyCode(newVal) {
      this.$ls.set('Affiliates/List/currency_code', newVal);
    },
  },

  created() {
    this.fetchData = this.$_.debounce(this.fetchDataD, 300);
    if (detectPermissions.checkRequest('affiliates_view')) {
      /*
       * Считывай hash из routers
       * */
      const _urlData = updateUrl.getParseParams(this.$route.hash);

      const {
        with_multiaccounts,
        referral_program_enabled,
        payments_enabled,
        media_buying_enabled,
        payments_negative_carryover,
        account_status,
        ...restFilters
      } = this.$_.pick(_urlData, FILTERS);

      this.filters = {
        ...restFilters,
        account_status: account_status || [],
        ...(with_multiaccounts && { with_multiaccounts: with_multiaccounts === 'true' }),
        ...(referral_program_enabled && { referral_program_enabled: referral_program_enabled === 'true' }),
        ...(payments_enabled && { payments_enabled: payments_enabled === 'true' }),
        ...(media_buying_enabled && { media_buying_enabled: media_buying_enabled === 'true' }),
        ...(payments_negative_carryover && { payments_negative_carryover: payments_negative_carryover === 'true' }),
      };

      if (!this.$_.isEmpty(_urlData)) {
        /*
         * Приводим объект к дефолтным данным
         * */
        const { limit = 0, page = 1 } = _urlData;

        this.limit = Number(limit) || pageSize;
        this.page = Number(page) || 1;

        delete _urlData.page;
        delete _urlData.limit;

        this.$_.merge(this.options, this.$_.omit(_urlData, FILTERS));

        this.sortProp = this.$_.get(_urlData, ['sort_column'], 'incrementing_id');
        this.sortOrder = this.$_.get(_urlData, ['sort_dir'], 'desc');

        this.currencyCode = this.checkCurrencyCode(this.$_.get(_urlData, ['currency_code']));

        this.searchQuery = this.$_.get(this.options, ['search']) || '';

        /*
         * Блокируем функцию на один вызов
         * */
        if (this.searchQuery.length > 0) {
          this.blockedFetch = true;
        }
      } else {
        this.sortProp = this.$ls.get('Affiliates/List/sort_column')
          ? this.$ls.get('Affiliates/List/sort_column')
          : 'created_at';
        this.sortOrder = this.$ls.get('Affiliates/List/sort_dir')
          ? this.$ls.get('Affiliates/List/sort_dir')
          : 'desc';

        this.currencyCode = this.checkCurrencyCode(this.$ls.get('Affiliates/List/currency_code'));
        this.limit = +this.$ls.get('affiliates/list/limit') || pageSize;

        this.filters = {
          account_status: ['approved'],
          ...this.$ls.get('Affiliates/List/filters'),
        };
      }

      this.fetchData('mouted');
    }
    detectPermissions.checkRoute('affiliates_view');

    this.$eventBus.$on('checkTransferAction', this.openTransferModal);
  },
  beforeDestroy() {
    this.$eventBus.$off('checkTransferAction', this.openTransferModal);
  },

  mounted() {
    if (detectPermissions.checkRequest('affiliates_view')) {
      this.$eventBus.$on('changeLocaleGlobal', this.changeLocaleGlobal);
    }
    detectPermissions.checkRoute('affiliates_view');
  },

  destroyed() {
    this.$eventBus.$off('changeLocaleGlobal', this.changeLocaleGlobal);
  },

  methods: {
    changeCurrency(value) {
      this.currencyCode = value;
      this.fetchDataD();
    },
    getAffiliatesFilter(data) {
      this.filters = this.$_.cloneDeep(data);
      this.$ls.set('Affiliates/List/filters', data);

      this.handleRefresh();
    },
    handleOpenTransferPopup() {
      this.$eventBus.$emit('checkSensitiveAction', 'checkTransferAction');
    },
    openTransferModal() {
      this.transferPopupErrors = {};
      this.$refs.transferAffiliatesPopup.open();
    },
    transferAffiliates(data) {
      this.$api.updateAffiliateReferralProgram(data).then(() => {
        this.$refs.transferAffiliatesPopup.close();
      }).catch((e) => {
        this.transferPopupErrors = e.data.errors;
      });
    },
    changeLocaleGlobal() {
      this.fetchDataD('silent');
      this.$api.getMiscCustomerCurrencies().then((res) => {
        this.currencyOptions = res.data.payload;
      });
    },

    affiliateStats(row) {
      return {
        id: row.id,
        email: row.email,
      };
    },
    hasPermission(permission) {
      return (
        this.currentAcl[permission] === 'allow' || this.adminAcl.is_superuser
      );
    },
    isViewReportsItem(row) {
      return (
        this.permissions[row.id].view_reports || this.adminAcl.is_superuser
      );
    },

    /*
     * Формируем запроос
     * */
    _query() {
      /* подготовка опций для мультиакаунтов */
      return {
        ...this.options,
        ...this.filters,
        limit: this.limit,
        offset: this.limit * this.page - this.limit,
        currency_code: this.currencyCode,
      };
    },

    renderStatus(row) {
      if (row.account_status === 'approved') return '';

      return this.$createElement(
        'div',
        {
          attrs: { key: row.id },
          class: 'ui-d-flex',
        },
        [
          this.$createElement('div', {
            class: `ui-m-md-l mark ${row.account_status}`,
          }),
        ],
      );
    },

    /*
     * Делаем debounce чтобы не было повторных запрос при изминении фильтра
     * */
    fetchDataD(action) {
      if (action !== 'silent') {
        this.isDataLoading = true;
      }
      /*
       * Формируем URL Filter
       * */
      this._completedFilterUrl();

      /* подготовка опций для мультиаккаунтов */
      const options = this._query();

      return this.$api
        .getAffiliatesWithStats(options)
        .then((response) => {
          this.count = response.data.misc.count;
          this.currencyCode = response.data.misc.currency_code;
          this.tableData = this.$_.cloneDeep(response.data.payload);
          this.permissions = this.$_.cloneDeep(response.data.misc.permissions);
          this.exportUrls = this.$_.cloneDeep(response.data.misc.export_urls);
          setTimeout(() => {
            this.blockedFetch = false;
          }, 300);
          this.errors = {};
          this.$refs.filter.filtersOpen = false;
        })
        .catch((e) => {
          this.errors = e.data.errors;
        })
        .finally(() => {
          if (action !== 'silent') {
            this.isDataLoading = false;
          }
        });
    },

    pageChange(page, size) {
      if (this.blockedFetch) {
        return;
      }

      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.fetchData('pageChange');
      }
    },
    handleSort({ prop, order }) {
      if (this.blockedFetch) {
        return;
      }

      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.fetchData('handleSort');
    },
    handleRefresh() {
      if (this.blockedFetch) {
        return;
      }

      this.page = 1;
      this.fetchData('handleRefresh');
    },
    getAffiliateLink(row) {
      return `/affiliates/${row.id}/info`;
    },
    getReferredAffiliateLink(row) {
      return `/affiliates/${row.referred_by_id}/info`;
    },
    exportData(format) {
      window.open(this.exportUrls[format]);
    },
    getCurrencyClass(value) {
      return value < 0 ? 'negative' : '';
    },
    /*
     * Создаем filter URL
     * */
    _completedFilterUrl() {
      const _dataFilters = this._query();
      _dataFilters.page = this.page;

      delete _dataFilters.offset;

      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>
