<template>
  <div
    class="crm-activity-check-control"
    :class="{
      'crm-activity-check-control_success': value,
      'crm-activity-check-control_danger': !value,
    }"
  >
    <template v-if="!canEdit">
      <i v-if="value" class="fas fa-check" />
      <i v-else class="fas fa-times" />
    </template>

    <el-checkbox
      v-else
      :value="value"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'ActivityCheckControl',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
  .crm-activity-check-control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    border-radius: 8px 0 0 8px;
    font-size: 12px;
    flex-shrink: 0;

    .el-checkbox .el-checkbox__input .el-checkbox__inner {
      width: 24px;
      height: 24px;
      vertical-align: middle;

      &::after {
        font-size: 14px;
        height: auto;
        width: auto;
        left: 4px;
        top: 4px;
      }
    }

    &_success {
      background-color: rgba(32, 129, 94, 0.2);
      color: var(--main-color);
    }

    &_danger {
      background-color: rgba(210, 99, 112, 0.2);
      color: var(--danger-color);
    }
  }
</style>
