import { checkColunmWidth, setColumnWidth, getDeltaWidth } from './listTable';

export default {
  data() {
    return {
      resizeHeaderDragendFlag: true,
      randomValue: '',
    };
  },
  created() {
    /**
     * check local storage for data exist
     */
    const columnWidthMerge = checkColunmWidth(this.fields, this.fieldsLocalStorageKey, this.tableFieldsEx);
    if (columnWidthMerge) {
      this.fields = columnWidthMerge;
    }

    window.addEventListener('resize', this.resizeHeaderDragend);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHeaderDragend);
  },
  methods: {
    randomizer() {
      const random = Math.random();
      return random.toString();
    },
    /**
     * watch event headerDragend and call fn setColumnWidth
     * with set data in local storage
     */
    resizeHeaderDragend() {
      if (getDeltaWidth(this.$refs.dataTable) > 0 && this.resizeHeaderDragendFlag) {
        this.resizeHeaderDragendFlag = false;
        const fieldsExcludeWidth = this.fields.map((el) => {
          if (el.flexible) {
            delete el.width;
            return el;
          }
          return el;
        });
        this.fields = fieldsExcludeWidth;
        this.$forceUpdate();
      }
    },

    headerDragend(columnWidth, startLeft_startColumnLeft, column) {
      this.resizeHeaderDragendFlag = true;
      const setColumnWidthLocal = setColumnWidth(column, this.fields, this.fieldsLocalStorageKey, getDeltaWidth(this.$refs.dataTable), this.viewCustom);
      if (setColumnWidthLocal !== undefined) {
        this.$set(this.fields, ...setColumnWidthLocal.newData);
      }
      if (getDeltaWidth(this.$refs.dataTable) > 0) {
        const countFlexibleColumn = this.fields.filter(el => el.flexible).length;
        this.fields = this.fields.map((el) => {
          const itemDel = el;
          const condition = countFlexibleColumn > 1 ? el.flexible && el.name !== column.property : el.flexible;

          if (this.doLayoutFlag) this.$nextTick(this.$refs.dataTable.$refs.dataTable.doLayout);

          if (condition) {
            this.$delete(itemDel, 'width');
            return { ...itemDel, forceUpdateKey: this.randomizer() };
          }
          return {
            ...el,
            ...this.$set({}, 'width', el.width),
            forceUpdateKey: this.randomizer(),
          };
        });
      }
      if (this.viewCustom === 'customReport') {
        this.updateTableFields = +new Date();
      }
    },
  },
};
