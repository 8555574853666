<template>
  <div
    v-loading="isDataLoading"
    class="teams-list"
  >
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirmType"
      :action-name="actionName"
      :action="confirmAction"
      :action-icon="actionIcon"
      :action-fa-icon="actionFaIcon"
      class="team-popup"
      @save="onConfirm"
      @close="confirmClose"
    >
      <div
        slot="title"
        v-html="confirmTitle"
      />
      <div class="body__wrap">
        <i
          v-if="confirmAction === 'deleteTeam'"
          :class="confirmType"
          class="fas fa-trash-alt"
        />
        <div
          v-if="confirmAction === 'deleteTeam'"
          class="body__msg"
          v-html="confirmMsg"
        />
        <div
          v-else
          class="select-wrap"
        >
          <ui-input
            v-model="team.name"
            :error="getError('name')"
            :placeholder="$t(`team_popup.team_name_placeholder`)"
            :label="$t(`team_popup.team_name_label`)"
            size="big"
            autosize
          />
        </div>
      </div>
    </ui-confirm>
    <div class="wrapper">
      <div class="info">
        <div class="blocks-left">
          <div class="title-wrapper">
            <div class="title">
              {{ $t(`teams.teams_fields.title`) }}
            </div>
          </div>
          <div class="section">
            <ui-input
              v-model.trim="searchQuery"
              is-search
              autosize
              @search="getTeams"
            />
            <ui-button
              size="medium"
              color="green"
              class="edit-btn"
              @click="addTeamHandler"
            >
              + {{ $t('teams.teams_buttons.add_team') }}
            </ui-button>
          </div>
          <div>
            <router-link
              to="/teams/all/details/users"
              class="team-link"
            >
              <div
                :class="{'team-item_active': isActiveTeam === 'all-users' }"
                class="team-item main-team"
              >
                <div class="team-item-wrapper">
                  <h5 class="team-item__title-inner">
                    {{ $t('teams.teams_fields.default_team') }}
                  </h5>
                </div>
                <div class="team-item-count">
                  {{ allUsers.length }}
                  <ui-icon
                    name="user"
                    substyle="fas"
                    color="#a6b2c4"
                    lib="fa"
                  />
                </div>
              </div>
            </router-link>
            <router-link
              v-for="(item, index) in dataTeams"
              :key="index"
              :to="`/teams/${item.id}/details/users`"
              class="team-link"
            >
              <div
                :class="{'team-item_active': isActiveTeam === item.id }"
                class="team-item"
                @click="isActiveTeam = item.id"
              >
                <div class="team-item-wrapper">
                  <!--<span class="text-fade">ID: {{ index+1 }}</span>-->
                  <h5 class="team-item__title-inner">
                    {{ item.name }}
                  </h5>
                </div>
                <div class="team-item-count">
                  {{ item.members_count }}
                  <ui-icon
                    name="user"
                    substyle="fas"
                    color="#a6b2c4"
                    lib="fa"
                  />
                </div>
                <div class="team-item-actions">
                  <span
                    class="action"
                    @click="editTeamHandler(item)"
                  >
                    <ui-icon
                      name="pen"
                      color="#20815e"
                      lib="fa"
                    />
                  </span>
                  <span
                    v-if="item.type !== 'owners'"
                    class="action"
                    @click="deleteTeamHandler(item)"
                  >
                    <ui-icon
                      name="trash-alt"
                      :color="$theme.dangerColor"
                      lib="fa"
                    />
                  </span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <router-view
          :team="activeTeam"
          :permissions="activeTeamPermissions"
          class="view"
          @refresh="refresh"
          @refresh-users-list="getUsers()"
          @open-delete="deleteTeamHandler(activeTeam)"
          @open-edit="editTeamHandler(activeTeam)"
        />
      </div>
    </div>
  </div>
</template>

<script>

import app from '@/main';
import detectPermissions from '../../service/detectPermissions';
import sessionAction from '@/views/mixins/session-action';

export default {
  mixins: [sessionAction],
  data() {
    return {
      isDataLoading: false,
      isActiveTeam: '',
      isConfirmOpen: false,
      searchQuery: '',
      options: {
        search: '',
      },

      errors: {},
      dataPermissions: {
        affiliates_types_edit: [],
        affiliates_types_view: [],
        players_available_properties: [],
        reports_affiliates_types: [],
      },

      dataTeams: [],
      allUsers: [],
      team: {
        id: '',
        name: '',
      },

      confirmMsg: '',
      confirmAction: '',
      confirmTitle: '',
      confirmType: '',
      actionName: '',
      actionFaIcon: '',
      actionIcon: '',
    };
  },

  computed: {
    activeTeam: {
      get() {
        return this.dataTeams ? this.dataTeams.find(item => this.isActiveTeam === item.id) : {};
      },
      set(newValue) {
        this.activeTeam.name = newValue.name;
      },
    },
    activeTeamPermissions: {
      get() {
        return this.dataPermissions ? this.dataPermissions : {};
      },
      set(newValue) {
        this.dataPermissions = newValue;
      },
    },
  },
  watch: {
    $route() {
      this.chooseTeam();
    },
    searchQuery() {
      this.options.search = this.searchQuery;
    },
    isActiveTeam() {
      if (this.activeTeam && this.activeTeam.type !== 'owners') {
        this.getPermissions();
      }
    },
  },
  mounted() {
    this.onSessionUnlock = this.initData;
    this.initData();
  },
  created() {
    app.$eventBus.$on('update-permissions', this.updatePermissions);
  },

  beforeDestroy() {
    app.$eventBus.$off('update-permissions', this.updatePermissions);
  },
  methods: {
    getError(key) {
      return this.$_.get(this.errors, [key], '');
    },
    initData() {
      if (detectPermissions.checkRequest('teams_view')) {
        this.getTeams();
        this.getUsers();
        this.chooseTeam();
      }
      detectPermissions.checkRoute('teams_view');
    },
    _query() {
      const _paramsFilter = {
        ...this.options,
      };
      return _paramsFilter;
    },

    updatePermissions(data) {
      this.dataPermissions = data;
    },

    chooseTeam() {
      this.isActiveTeam = this.$route.params.id;
      if (!this.isActiveTeam) {
        this.isActiveTeam = 'all-users';
      }
    },

    getPermissionsPromise() {
      return this.$api.getTeamPermissions(this.activeTeam.id).then((response) => {
        this.dataPermissions = this.$_.cloneDeep(response.data.payload);
      });
    },

    async getPermissions() {
      this.isDataLoading = true;
      this.errors = {};
      try {
        await this.getPermissionsPromise();
      } catch (error) {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      } finally {
        this.isDataLoading = false;
      }
    },
    async getTeams() {
      this.isDataLoading = true;
      this.errors = {};
      try {
        const response = await this.$api.getTeamsList(this._query());
        this.dataTeams = this.$_.cloneDeep(response.data.payload);

        if (this.activeTeam && this.activeTeam.type !== 'owners') {
          await this.getPermissionsPromise();
        }
      } catch (error) {
        this.errors = this.$_.cloneDeepWith(error.data.errors);
      } finally {
        this.isDataLoading = false;
      }
    },

    getUsers() {
      this.errors = {};
      this.$api.getAdmins().then((response) => {
        this.allUsers = response.data.payload;
      })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },

    isLinkActive(name) {
      return this.currentTab === name;
    },

    onConfirm() {
      this.isConfirmOpen = false;
      switch (this.confirmAction) {
      case 'addTeam':
        this.addTeam();
        break;
      case 'editTeam':
        this.editTeam();
        break;
      case 'deleteTeam':
        this.deleteTeam();
        this.$refs.confirm.close();
        break;
      default:
        break;
      }
    },

    addTeamHandler() {
      this.confirmAction = 'addTeam';
      this.actionName = `${this.$t('team_popup.add_team_button')}`;
      this.confirmTitle = `${this.$t('team_popup.add_team_button')}`;
      this.confirmType = 'success';
      this.actionFaIcon = 'plus';
      this.$refs.confirm.open();
    },

    addTeam() {
      this.isDataLoading = true;
      this.errors = {};
      this.$api
        .postTeam(this.team.name)
        .then((response) => {
          this.getTeams();
          this.isActiveTeam = response.data.payload.id;
          this.$router.push(`/teams/${this.isActiveTeam}/details/users`);

          app.$eventBus.$emit('open-popup-add-users-in-team');

          this.team.name = '';
          this.$refs.confirm.close();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },

    editTeamHandler(team) {
      this.confirmAction = 'editTeam';
      this.actionName = `${this.$t('team_popup.save_button')}`;
      this.confirmTitle = `${this.$t('team_popup.edit_team_button')}`;
      this.confirmType = 'success';
      this.actionFaIcon = 'check';
      this.team.name = team.name;
      this.team.id = team.id;
      this.$refs.confirm.open();
    },
    editTeam() {
      this.$api
        .putTeam(this.team.id, this.team.name)
        .then((response) => {
          // this.getTeams();
          this.activeTeam = this.$_.cloneDeepWith(response.data.payload);
          this.team.name = '';
          this.team.id = '';
          this.$refs.confirm.close();
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },

    deleteTeamHandler(team) {
      this.confirmAction = 'deleteTeam';
      this.confirmTitle = `${this.$t('team_popup.delete_team_title', { name: team.name })}`;
      this.confirmMsg = `${this.$t('team_popup.delete_team_msg', { name: team.name })}`;
      this.actionName = `${this.$t('team_popup.delete_team_action')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'trash-alt';
      this.team.id = team.id;
      this.$refs.confirm.open();
    },
    deleteTeam() {
      this.$api
        .deleteTeam(this.team.id)
        .then(() => {
          this.getTeams();
          this.getUsers();
          this.$router.push('/teams/all/details/users');
          this.team.name = '';
          this.team.id = '';
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },

    confirmClose() {
      this.actionIcon = '';
      this.actionFaIcon = '';
      this.team.name = '';
      this.team.id = '';
    },


    refresh() {
      this.getUsers();
      this.getTeams();
    },
  },
};
</script>

<style lang="scss" scoped>
  .teams-list {
    .wrapper {
      width: 90%;
      min-width: 1140px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
</style>
