<template>
  <div class="balance-history">
    <div class="toolbar">
      <div class="wrapper">
        <div class="section">
          <el-date-picker
            ref="dateRange"
            v-model="dateRange"
            :picker-options="{
              firstDayOfWeek: 1,
              shortcuts: shortcutsDates,
            }"
            :start-placeholder="$t('reports.other.start_date')"
            :end-placeholder="$t('reports.other.end_date')"
            type="daterange"
            range-separator="-"
            format="dd MMM yyyy"
            class="date-picker"
          />
          <el-checkbox-group
            v-model="dataQuery.transaction_type"
            @change="handleTypeChange"
          >
            <el-checkbox
              v-for="type in types"
              :key="type"
              :label="type"
            >
              {{ $t(`affiliates.balanceHistory.transactions.${type}`) }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="pageChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      ref="wrapper"
      class="table-wrapper"
    >
      <ui-table
        v-loading="isDataLoading"
        :fields="fields"
        :currency="currencyCode"
        :data="data"
        :rows-count="limit"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        lazy-loading
        is-static
        i18n-path="affiliates.list"
        element-loading-custom-class="data-loader-spinner"
        @sort="handleSort"
      />
    </div>
  </div>
</template>

<script>
import { NAV_SET_SUB_VIEW } from '@/store/action_types/nav';
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import updateUrl from '../../../service/updateUrl';


const sizes = {
  1080: 20,
  1440: 25,
};

const viewName = 'affiliate/balance_history/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'BalanceHistory',
  mixins: [pageSizeMixin],
  data() {
    return {
      viewName,
      sortProp: 'grouping',
      sortOrder: 'desc',
      id: '',
      walletId: '',
      info: {},
      count: 0,
      currencyCode: '',
      page: 1,
      limit: pageSize,
      dateRange: [],
      shortcutsDates: [
        {
          text: this.$t('reports.date_picker.last_30_day'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(30, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.this_month'),
          range: [this.$moment().startOf('month'), this.$moment().endOf('day')],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.prev_month'),
          range: [
            this.$moment()
              .subtract(1, 'months')
              .startOf('month'),
            this.$moment()
              .subtract(1, 'months')
              .endOf('month'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.last_90_day'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(90, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.last_year'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(1, 'y'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
      ],
      types: ['add', 'chargeback', 'withdraw', 'withdraw_abort', 'add_bonus'],
      dataQuery: {
        sort_column: '',
        sort_dir: '',
        transaction_type: [
          'add',
          'chargeback',
          'withdraw',
          'withdraw_abort',
          'add_bonus',
        ],
      },
      isDataLoading: false,
      fields: [
        {
          name: 'grouping',
          align: 'left',
          width: '90',
          format: 'format-date',
          sortable: 'custom',
        },
        {
          name: 'transaction_type',
          align: 'left',
          width: '120',
          computedValue: this.formatType,
          sortable: false,
        },
        {
          name: 'transaction_description',
          align: 'left',
          minWidth: '180',
          sortable: false,
        },
        {
          name: 'amount_to_hold',
          align: 'right',
          width: '140',
          format: 'formatMoney',
          computedClass: this.getCurrencyClass,
          sortable: false,
        },
        {
          name: 'amount_to_available',
          align: 'right',
          width: '180',
          format: 'formatMoney',
          computedClass: this.getCurrencyClass,
          sortable: false,
        },
        {
          name: 'result_balance_hold',
          align: 'right',
          width: '140',
          format: 'formatMoney',
          computedClass: this.getCurrencyClass,
          sortable: false,
        },
        {
          name: 'result_balance_available',
          align: 'right',
          width: '140',
          format: 'formatMoney',
          computedClass: this.getCurrencyClass,
          sortable: false,
        },
      ],
      data: [],
      defaultSort: {},
      tableHeight: 0,
    };
  },
  computed: {
    isInfoEmpty() {
      return this.$_.isEmpty(this.info);
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.walletId = this.$route.params.wallet_id;

    /*
    * Считываем hash
    * */
    const _paramsHash = updateUrl.getParseParams(this.$route.hash);

    if (!this.$_.isEmpty(_paramsHash)) {
      if (this.$moment(this.$_.get(_paramsHash, ['dateStart'])).isValid() && this.$moment(this.$_.get(_paramsHash, ['dateEnd'])).isValid()) {
        this.dateRange[0] = this.$_.get(_paramsHash, ['dateStart']);
        this.dateRange[1] = this.$_.get(_paramsHash, ['dateEnd']);
      }

      this.dataQuery.sort_column = this.$_.get(_paramsHash, ['dataQuery', 'sort_column']) || 'grouping';
      this.dataQuery.sort_dir = this.$_.get(_paramsHash, ['dataQuery', 'sort_dir']) || 'desc';
      this.sortOrder = this.$_.get(_paramsHash, ['dataQuery', 'sort_dir']) || 'desc';
      this.dataQuery.transaction_type = this.$_.get(_paramsHash, ['dataQuery', 'transaction_type']) || this.dataQuery.transaction_type;
      this.limit = Number(this.$_.get(_paramsHash, ['limit'])) || pageSize;
      this.page = Number(this.$_.get(_paramsHash, ['page'])) || 1;
    } else {
      this.dateRange = this.shortcutsDates[4].range;
      this.dataQuery.sort_column = 'grouping';
      this.dataQuery.sort_dir = this.$ls.get('wallet-history/sort_dir') || 'desc';
      this.sortOrder = this.$ls.get('wallet-history/sort_dir') || 'desc';
      this.limit = +this.$ls.get('affiliate/balance_history/limit') || pageSize;
    }

    this.defaultSort = {
      prop: this.dataQuery.sort_column,
      order: `${this.dataQuery.sort_dir}ending`,
    };
  },
  mounted() {
    if (!this.id) {
      this.$router.push('/affiliates/list');
    }
    this.$api
      .getAffiliateInfo(this.id)
      .then((response) => {
        this.info = this.$_.cloneDeepWith(response.data.payload);
        if (this.info.account_status === 'pending') {
          this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'review');
        } else {
          this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'list');
        }
        this.$watch('tableHeight', this.computeTableRows);
        this.$watch('dateRange', (range) => {
          if (!range) {
            this.dateRange = this.shortcutsDates[4].range;
          }
          this.getWalletHistory();
        });
      });
    this.getWalletHistory();
  },

  methods: {
    getWalletHistory() {
      this.isDataLoading = true;
      const q = {
        group_by: 'day',
        limit: this.limit,
        offset: (this.limit * this.page) - this.limit,
        start_timestamp: this.dateRange[0]
          ? this.$moment(this.dateRange[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          : '',
        end_timestamp: this.dateRange[1]
          ? this.$moment(this.dateRange[1])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          : '',
        ...this.dataQuery,
      };

      /*
      * Refresh url
      * */
      this._completedFilterUrl();

      this.$api
        .getAffiliateWalletHistory(this.walletId, q)
        .then((response) => {
          this.count = response.data.misc.count;
          this.currencyCode = response.data.misc.currency_code;
          this.data = this.$_.cloneDeep(response.data.payload);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    computeTableRows() {
      const defaultSizes = [10, 20, 50, 100];
      if (
        defaultSizes.indexOf(this.limit) < 0
        || this.limit * 32 < this.tableHeight - 34
      ) {
        const lim = Math.floor((this.tableHeight - 34) / 32);
        this.limit = lim > defaultSizes[0] ? lim : defaultSizes[0];
        if (this.$_.indexOf(this.pageSizes, this.limit) < 0) {
          this.pageSizes.push(this.limit);
          this.pageSizes.sort((a, b) => a - b);
        }
      }
    },
    pageChange(page, size) {
      this.page = page;
      this.limit = size;
      this.getWalletHistory();
    },

    formatDate(row, value) {
      const m = this.$moment(value);
      return m.isValid() ? m.format('DD MMM YYYY') : '-';
    },
    formatType(row, value) {
      return this.$t(`affiliates.balanceHistory.transactions.${value}`);
    },
    computePaymentPeriod(row) {
      return `${this.formatDate(row.payment_period_start)} - ${this.formatDate(row.payment_period_end)}`;
    },

    getCurrencyClass(value) {
      return value < 0 ? 'negative' : '';
    },
    // eslint-disable-next-line
    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.dataQuery.sort_column = this.sortProp;
      this.dataQuery.sort_dir = this.sortOrder;

      this.$ls.set(
        'wallet-history/sort_column',
        this.dataQuery.sort_column,
      );
      this.$ls.set('wallet-history/sort_dir', this.dataQuery.sort_dir);
      this.getWalletHistory();
    },
    handleTypeChange() {
      this.getWalletHistory();
    },
    /*
    * Создаем filter URL
    * */
    _completedFilterUrl() {
      const _dataFilters = {
        dateStart: this.$moment(this.dateRange[0]).format('YYYY-MM-DD'),
        dateEnd: this.$moment(this.dateRange[1]).format('YYYY-MM-DD'),
        dataQuery: this.dataQuery,
        limit: this.limit,
        page: this.page,
      };
      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>
