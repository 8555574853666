<template>
  <div
    :class="{
      'item-card-layout-column': true,
      'item-card-layout-column_default': type === 'default',
      'item-card-layout-column_grow': type === 'grow',
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ItemCardLayoutColumn',

  props: {
    type: {
      type: String,
      default: 'default',
    },
  },
};
</script>

<style lang="scss">
  .item-card-layout-column {
    display: flex;
    flex-direction: column;
    & + & {
      margin-left: 30px;
    }

    &_default {
      width: 275px;
      max-width: 275px;
    }

    &_grow {
      flex-grow: 1;
      width: calc(50% - 23px);
      max-width: auto;
    }
  }
</style>
