<template>
  <div class="toolbar grouping">
    <div class="wrapper">
      <div class="section">
        <grouping-dropdown
          v-for="menuItem in configGrouping"
          :key="menuItem.type + menuItem.translate"
          :grouping-selects="groupingSelects"
          :active-class="groupingSelects[menuItem.type]"
          :all-dimensions-filter="allDimensions.filter(item => (item.type === menuItem.type &&
            supportedDimensions.indexOf(item.name) >=0 && deleteGroup().indexOf(item.name) < 0))"
          :type-group="menuItem.type"
          @command="handleGroupingSelect"
          @visibleChange="visibleGroupingSelect"
          @handlerClick="groupingClickBlocked(menuItem.type, menuItem.group) && menuItem.mainClick ?
            selectDefaultGrouping(menuItem.group, $event) : null"
        >
          {{ groupingSelects[menuItem.type]
            ? (groupingSelects[menuItem.type].title
              || $t(`reports.fields.date_and_time.fullTitle`))
            : menuItem.translate }}
        </grouping-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GroupingDropdown from './GroupingReportComponent/GroupingDropdown';


export default {
  name: 'GroupingReport',
  components: { GroupingDropdown },

  props: {
    reports: {
      type: Array,
      required: true,
    },
    currentReport: {
      type: Number,
      required: true,
    },
    allDimensions: {
      type: Array,
      required: true,
    },
    groupingSelects: {
      type: Object,
      required: true,
    },
    isAffiliateGroup: {
      type: Boolean,
      required: true,
    },
    isSubAffiliateGroup: {
      type: Boolean,
      required: true,
    },
    isCountryGroup: {
      type: Boolean,
      required: true,
    },
    isPaymentGroup: {
      type: Boolean,
      required: true,
    },
    supportedDimensions: {
      type: Array,
      required: true,
    },

  },
  data() {
    return {
      isDropped: false,

    };
  },

  computed: {
    ...mapGetters('reports', ['reportsSettings']),
    configGrouping() {
      const defaultField = {
        type: 'other',
        group: '',
        visible: true,
        mainClick: false,
        translate: this.$t(`reports.fields.${this.groupingSelects.subs ? this.groupingSelects.subs.name : 'subs'}.fullTitle`),
      };

      const dimensionsGroups = this.reportsSettings.dimensions_groups;
      const groupedDimensions = this.reportsSettings.grouped_dimensions.map(dimension => dimension.name);

      const filteredConfigDimensions = dimensionsGroups.map(grd => ({
        ...defaultField, translate: grd.title, type: grd.group_name,
      }));

      return this.$_.filter(filteredConfigDimensions, dimension => groupedDimensions.includes(dimension.type));
    },
  },

  mounted() {
    this.addEventMenu();
  },

  methods: {
    deleteGroup() {
      const cf = [];
      this.$_.forIn(this.reports[this.currentReport].currentFilter, (e) => { if (e.op === '=') { cf.push(e.group); } });
      const df = [];
      this.$_.forIn(this.reports[this.currentReport].defaultFilter, (e) => { if (e.op === '=') { df.push(e.group); } });
      return this.$_.concat([], cf, df);
    },

    groupingClickBlocked(type, group) {
      return this.allDimensions.filter(item => (item.type === type
        && this.supportedDimensions.indexOf(item.name) >= 0 && this.deleteGroup().indexOf(item.name) < 0
        && !this.reports[this.currentReport].currentFilter.hasOwnProperty(item.filterName)
      && !this.reports[this.currentReport].defaultFilter.hasOwnProperty(item.filterName))).some(e => e.name === group);
    },

    handleGroupingSelect(gb, block) {
      this.$emit('handleGroupingSelect', gb, block);
    },

    selectDefaultGrouping(name, event) {
      if (event !== 'block') event.stopPropagation();
      const field = this.$_.find(this.allDimensions, { name });
      this.handleGroupingSelect(field, event);
    },
    addEventMenu() {
      document.querySelectorAll('.select.el-dropdown .select-link').forEach((el) => {
        el.addEventListener('mouseover', (e) => {
          document.querySelectorAll('.active-e').forEach((item) => {
            item.classList.remove('active-e');
          });
          setTimeout(() => {
            e.toElement.classList.add('active-e');
          });
          setTimeout(() => {
            if (!this.isDropped) {
              document.querySelectorAll('.active-e').forEach((item) => {
                item.classList.remove('active-e');
              });
            }
          }, 100);
        });
      });
    },
    visibleGroupingSelect(v) {
      this.isDropped = v;
      if (!v) {
        document.querySelectorAll('.select.el-dropdown .select-link').forEach((el) => {
          el.classList.remove('active-e');
        });
      }
    },
  },
};
</script>
