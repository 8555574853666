<template>
  <div class="pin-affiliate-user">
    <div class="popup">
      <div class="head">
        <span class="name">{{ this.$t(`team_popup.add_affiliates_to_user_title`, {name: userInfo.name}) }}</span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div class="popup-panel">
        <div class="wrap-control">
          <ui-input
            v-model.trim="searchQuery"
            class="search-popup"
            is-search
            autosize
            @search="getAffiliates"
          />
        </div>
        <ui-pagination
          :page="page"
          :page-size="limit"
          :count="count"
          show-size-select
          @page-change="pageChange"
        />
      </div>
      <ui-table
        ref="tablePopup"
        v-loading="isDataLoading"
        :select-row="true"
        :fields="fields"
        :data="tableData"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        i18n-path="team_users.list"
        checked-row
        :show-check-all="false"
        checked-row-id="id"
        element-loading-custom-class="data-loader-spinner"
        class="table"
        select-type="radio"
        @sort="handleSort"
        @checkedRows="checkedRows"
      />
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          @click.native="close"
        >
          {{ $t(`team_popup.cancel_button`) }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="!affiliate_id"
          filled
          @click="save"
        >
          <i class="fas fa-plus plus-icon-custom" />{{ $t(`team_popup.add_affiliate_button`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>

import app from '@/main';
import { resolvePageSize } from '../../service/pageSize';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'pinAffiliatesUser/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'PinAffiliateUser',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isDataLoading: true,
      searchQueryPopup: '',
      affiliate_id: null,
      affiliates: [],
      searchQuery: '',
      options: {
        search: '',
        type: 'unattached',
        sort_column: '',
        sort_dir: '',
      },
      sortProp: 'email',
      sortOrder: 'asc',
      page: 1,
      limit: pageSize,
      count: 0,
      fields: [
        {
          name: 'full_name',
          sortable: 'custom',
        },
        {
          name: 'email',
          sortable: 'custom',
        },
        {
          name: 'messenger',
          computedValueHtml(row) {
            return `<div class="messenger ${row.messenger || ''}">${row.messenger_id || ''}</div>`;
          },

          sortable: false,
        },
      ],
    };
  },
  computed: {
    tableData: {
      get() {
        return this.affiliates;
      },
      set(newVal) {
        this.affiliates = newVal;
      },
    },
  },
  watch: {
    searchQuery() {
      this.options.search = this.searchQuery;
    },
    isOpen(v) {
      if (v) {
        this.getAffiliates();
      }
    },
  },

  mounted() {

  },
  methods: {
    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.page = 1;
      this.getAffiliates();
    },
    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.getAffiliates();
      }
    },
    _query() {
      this.options.sort_column = this.sortProp;
      this.options.sort_dir = this.sortOrder;
      const _paramsFilter = {
        ...this.options,
        limit: this.limit,
        offset: (this.limit * this.page) - this.limit,
      };
      return _paramsFilter;
    },


    getAffiliates() {
      this.errors = {};
      this.$api.getAdminAffiliates(this.userInfo.id, this._query())
        .then((response) => {
          this.count = response.data.misc.count;
          this.affiliates = response.data.payload;
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          setTimeout(() => {
            this.isDataLoading = false;
          }, 500);
        });
    },
    checkedRows(id) {
      this.affiliate_id = this.affiliate_id !== id ? id : null;
    },
    open(team_id) {
      this.team_id = team_id;
    },
    close() {
      this.searchQuery = '';
      this.affiliate_id = null;
      app.$eventBus.$emit('clear-check');
      this.page = 1;
      this.$emit('close');
    },
    save() {
      this.errors = {};
      this.$api
        .postAttachAffiliate(this.userInfo.id, { affiliate_id: this.affiliate_id, team_id: this.team_id })
        .then(() => {
          app.$eventBus.$emit('clear-check');
          this.$emit('refresh');
          this.close();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
  },
};
</script>

<style lang="scss">
.pin-affiliate-user {
  .ui-table.table{
    overflow-y: hidden;
    height: 100%;

    > div {
      height: 100%;
      > div {
        height: 100%;

        .el-table {
          height: 100%!important;
        }
      }
    }
    .messenger{
      display: flex;
      align-items: center;
      &:before{
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      &.telegram:before {
        background: url("../../assets/images/social/telegram-icon.svg") no-repeat center;
        background-size: cover;
      }
      &.skype:before {
        background: url("../../assets/images/social/skype-icon.svg") no-repeat center;
        background-size: cover;
      }
      &.viber:before {
        background: url("../../assets/images/social/viber-icon.svg") no-repeat center;
        background-size: cover;
      }
      &.whatsapp:before {
        background: url("../../assets/images/social/whatsapp-icon.svg") no-repeat center;
        background-size: cover;
      }
      &.facebook:before {
        background: url("../../assets/images/social/facebook-icon.svg") no-repeat center;
        background-size: cover;
      }
      &.instagram:before {
        background: url("../../assets/images/social/instagram-icon.svg") no-repeat center;
        background-size: cover;
      }
      &.twitter:before {
        background: url("../../assets/images/social/twitter-icon.svg") no-repeat center;
        background-size: cover;
      }
    }
  }
}
</style>
