<template>
  <ActionIcon
    class="crm-panel-action-icon"
    :class="{
      'crm-panel-action-icon--disabled': disabled,
      'is-disabled': disabled,
    }"
    :tooltip="tooltip"
  >
    <div
      class="action-ico"
      @click="handleClick"
    >
      <ui-icon
        :name="icon"
        :color="color"
        :lib="iconLib"
      />
    </div>
  </ActionIcon>
</template>

<script>
export default {
  name: 'PanelActionIcon',
  props: {
    tooltip: {
      type: String,
      default: undefined,
    },
    icon: {
      type: String,
      default: '',
    },
    iconLib: {
      type: String,
      default: 'fa',
    },
    color: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(event) {
      if (!this.disabled) {
        this.$emit('click', event);
      }
    },
  },
};
</script>

<style lang="scss">
  .crm-panel-action-icon {
    margin-bottom: -8px;

    & + & {
      margin-left: 2px;
    }

    &--disabled {
      &.action-ico:hover {
        cursor: not-allowed;
        background-color: transparent;
      }
    }
  }
</style>
