<template>
  <div
    v-if="isOpen"
    class="create-segment view-segment"
  >
    <div class="popup">
      <div class="head">
        <div class="name">
          <div
            class="name__text"
            :title="segment.name"
          >
            {{ segment.name }}
          </div>
          <span
            v-if="detectActivityStatus(segment) !== 'unknown'"
            class="status__text ui-p-md-l ui-m-sm-t"
            :class="`row-value--${detectActivityStatus(segment)}`"
          >
            {{ $t(`crm.fields.computed_status.values.${detectActivityStatus(segment)}`) }}
          </span>
          <span class="segment-type-label">
            <i v-if="segment.type === 'fixed'" class="fas fa-lock" />
            <i v-if="segment.type === 'dynamic'" class="fas fa-wave-square" />
            <i v-if="segment.type === 'manual'" class="fas fa-gear" />
            {{ $t(`crm.segments.type.${segment.type}`) }}
          </span>
        </div>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div class="body">
        <item-card-layout-column type="grow">
          <div v-loading="isSegmentInfoLoading || filterLoading !== 0">
            <div class="card">
              <div class="title">
                <div class="title-content">
                  <span>{{ $t('crm.segments.using_activities') }}</span>
                </div>
              </div>
              <div class="card-content">
                <div class="field">
                  <span
                    class="label create-segment__activities-list"
                    :class="{
                      'create-segment__activities-list_empty': !usedInActivities
                    }"
                  >{{ usedInActivities || $t('crm.segments.activities_empty') }}</span>
                </div>
              </div>
            </div>
            <tree
              v-if="segment.type !== 'manual'"
              class="create-segment__rule-tree"
              type="outer"
            >
              <tree-branch
                v-if="rulesGroup.rules.length > 1"
              >
                <div class="view-relation">
                  <div class="view-relation__value">
                    {{ $t(`crm.segments.logical_relations.${rulesGroup.logical_relations}`) }}
                  </div>
                </div>
              </tree-branch>
              <tree-branch
                v-for="(group, id) in rulesGroup.rules"
                :key="id"
              >
                <panel class="create-segment__rule-panel">
                  <tree-branch
                    v-if="group.rules.length > 1"
                    class="create-segment__rule-tree-branch"
                  >
                    <div class="view-relation">
                      <div class="view-relation__value">
                        {{ $t(`crm.segments.logical_relations.${group.logical_relations}`) }}
                      </div>
                    </div>
                  </tree-branch>
                  <tree-branch
                    v-for="(group_rule, group_rule_id) in group.rules"
                    :key="group_rule_id"
                    class="create-segment__rule-tree-branch"
                  >
                    <filter-component
                      mode="view"
                      :group-rule="group_rule"
                      :available-filters="availableFilters"
                      :current-brand="currentBrand"
                      @loading="handleFilterLoading"
                    />
                  </tree-branch>
                </panel>
              </tree-branch>
            </tree>
            <div
              v-if="(showFiltersMode === 'preview' && addionalFilters.length > 0) || showFiltersMode === 'edit'"
              class="ui-m-xl-t"
            >
              <div
                :class="[
                  'ui-d-flex',
                  'ui-fg-1',
                  'ui-jc-space-between',
                  'ui-m-md-b'
                ]"
              >
                <div class="view-segment__filters-header">
                  {{ $t('crm.segments.activity_filters.title') }}
                </div>
                <div
                  :class="[
                    'ui-d-flex',
                    'ui-g-w-sm',
                    'ui-ai-baseline'
                  ]"
                >
                  <ui-icon
                    class="view-segment__filters-hint-icon ui-g-sm"
                    :size="12"
                    lib="fa"
                    substyle="fas"
                    :color="' '"
                    name="exclamation-circle"
                  />
                  <div class="view-segment__filters-hint-text ui-g-md">
                    {{ $t('crm.segments.activity_filters.hint') }}
                  </div>
                </div>
              </div>
              <panel
                class="view-segment__filters-panel"
                :class="{
                  'view-segment__filters-panel_preview': showFiltersMode === 'preview',
                }"
              >
                <filter-component
                  v-for="(groupRule, index) of addionalFilters"
                  :key="index"
                  :available-filters="availableFilters"
                  :group-rule="groupRule"
                  :errors="!$_.isEmpty(errors) && errors.rules_group ? errors.rules_group.rules[id].rules[group_rule_id] : {}"
                  :current-brand="currentBrand"
                  :mode="showFiltersMode === 'edit' ? 'create' : 'view'"
                  @loading="handleFilterLoading"
                  @deleteFilter="deleteFilter(index)"
                />
                <span
                  v-if="showFiltersMode === 'edit'"
                  class="add-new-rule"
                  @click="addFilter()"
                >
                  <ui-button
                    :icon-size="12"
                    color="green"
                    lib="fa"
                    substyle="fas"
                    icon="plus"
                    class="btn"
                  />
                  {{ $t('crm.segments.configure.add_new_rule') }}
                </span>
              </panel>
            </div>
          </div>
          <div
            v-if="segment.id !== '' && !isLocalSegment"
            class="segment-comments"
          >
            <comments
              :is-editable="canEdit"
              :comments="comments"
              :post-id="segment.id"
              :comments-loading.sync="commentsLoading"
              @post-comments="postComment"
              @get-comments="getComments"
            />
          </div>
        </item-card-layout-column>
        <item-card-layout-column>
          <segment-info
            :stats="stats"
            :stats-loader="isStatsLoading"
            :content-loader="isLoading"
            :player-attached="calcPlayerAttached"
            :moderation-status="!$_.isEmpty(activity) ? activity.moderation_status : segment.moderation_status"
            :activity-segment-type="activitySegmentType"
            :show-filters-mode="showFiltersMode"
            :state="state"
            segment-type="view"
            @exportData="exportData"
            @refreshStats="getStats(state)"
          />
        </item-card-layout-column>
      </div>
      <div class="foot">
        <ui-button
          v-show="status === 'on_review' && showFiltersMode === 'none' && canReview"
          color="red"
          :disabled="!isActive"
          @click="changeStatus('decline')"
        >
          <i class="fas fa-times plus-icon-custom" />{{ $t('crm.buttons.decline') }}
        </ui-button>
        <ui-button
          v-show="status === 'on_review' && showFiltersMode === 'none' && canReview"
          :color="$theme.mainColor"
          :disabled="!isActive"
          @click="changeStatus('for-rework')"
        >
          <i class="fas fa-sync-alt plus-icon-custom" />{{ $t('crm.buttons.for_rework') }}
        </ui-button>
        <ui-button
          v-show="status === 'on_review' && showFiltersMode === 'none' && canReview"
          filled
          :color="$theme.mainColor"
          :disabled="!isActive"
          @click="changeStatus('approve')"
        >
          <i class="fas fa-check plus-icon-custom" />{{ $t('crm.buttons.approve') }}
        </ui-button>
        <ui-button
          v-if="showEdit"
          v-show="(status === 'draft' || status === 'for_rework') && canEdit"
          :color="$theme.mainColor"
          :disabled="!isActive"
          @click="changeStatus('to-review')"
        >
          <i class="fas fa-check-double plus-icon-custom" />{{ $t('crm.buttons.review') }}
        </ui-button>
        <template v-if="showFiltersMode === 'edit'">
          <ui-button
            color="red"
            :disabled="!isActive"
            @click="close()"
          >
            <i class="fas fa-times plus-icon-custom" />{{ $t('crm.buttons.cancel') }}
          </ui-button>
          <ui-button
            :color="$theme.mainColor"
            :disabled="!isActive"
            @click="save()"
          >
            <i class="fas fa-check plus-icon-custom" />{{ $t('crm.buttons.save') }}
          </ui-button>
        </template>
        <ui-button
          v-if="showEdit"
          v-show="(status === 'draft' || status === 'for_rework') && canEdit"
          :color="$theme.mainColor"
          :disabled="!isActive"
          @click="edit()"
        >
          <i class="fas fa-pen plus-icon-custom" />{{ $t('crm.buttons.edit') }}
        </ui-button>
        <ui-button
          v-if="showFiltersMode !== 'edit'"
          v-show="status !== 'on_review' || (status === 'on_review' && !canReview)"
          :color="$theme.mainColor"
          filled
          :disabled="!isActive"
          @click="close()"
        >
          <i class="fas fa-check plus-icon-custom" />{{ $t('crm.buttons.close') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import hash from 'object-hash';
import app from '@/main';
import * as Tree from '@/views/CRM/components/Tree';
import * as Panel from '@/views/CRM/components/Panel';
import SegmentInfo from '@/views/CRM/Segments/SegmentInfo';
import ItemCardLayoutColumn from '@/components/ItemCard/ItemCardLayoutColumn';
import FilterComponent from '@/views/CRM/components/FilterComponent';
import Comments from '@/components/Comments.vue';
import ActivitiesCommonMixin from '@/views/CRM/mixins/activities-common-mixin';
import fileSaver from '@/helpers/file-saver.js';
import SegmentStatsMixin from '@/views/CRM/mixins/segment-stats-mixin';

const defaultRulesGroup = {
  logical_relations: '',
  rules: [
    {
      logical_relations: '',
      rules: [
        {
          rule_type: '',
          operator: '',
          values: '',
        },
      ],
    },
  ],
};

export default {
  name: 'ViewSegment',

  components: {
    SegmentInfo,
    ItemCardLayoutColumn,
    FilterComponent,
    ...Tree,
    ...Panel,
    Comments,
  },

  mixins: [
    ActivitiesCommonMixin,
    SegmentStatsMixin,
  ],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
    playerAttached: {
      type: Boolean,
      default() {
        return false;
      },
    },
    segmentId: {
      type: String,
      required: true,
    },
    currentBrand: {
      type: String,
      required: true,
    },
    showFiltersMode: {
      type: String,
      default: 'none',
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    activity: {
      type: Object,
      default: () => ({}),
    },
    activityFilters: {
      type: Array,
      default: () => ([]),
    },
    isLocalSegment: {
      type: Boolean,
      default: false,
    },
    localSegment: {
      type: Object,
      default: null,
    },
    activitySegmentType: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isLoading: false,
      commentsLoading: false,
      isSegmentInfoLoading: false,
      filterLoading: 0,
      statsLoadingCount: 0,
      lastHash: '',
      check: Function,

      comments: [],
      stats: {},
      permissions: {},
      addionalFilters: [],
      errors: {},
      segment: {
        name: '',
        type: '',
        rules_group: defaultRulesGroup,
      },
      availableFilters: [
        {
          name: 'registration_player_attributes',
          items: [
            {
              name: 'registration_timestamp',
              comparable: true,
              type: 'date_picker',
            },
            {
              name: 'latest_deposit_timestamp',
              comparable: true,
              type: 'date_picker',
            },
            {
              name: 'latest_bet_timestamp',
              comparable: true,
              type: 'date_picker',
            },
            {
              name: 'latest_sport_bet_timestamp',
              comparable: true,
              type: 'date_picker',
            },
            {
              name: 'latest_games_bet_timestamp',
              comparable: true,
              type: 'date_picker',
            },
            {
              name: 'seconds_since_registration',
              comparable: true,
              type: 'time',
            },
            {
              name: 'seconds_since_last_deposit',
              comparable: true,
              type: 'time',
            },
            {
              name: 'seconds_since_last_bet',
              comparable: true,
              type: 'time',
            },
            {
              name: 'seconds_since_last_sports_bet',
              comparable: true,
              type: 'time',
            },
            {
              name: 'seconds_since_last_games_bet',
              comparable: true,
              type: 'time',
            },
            {
              name: 'player_country_code',
              comparable: false,
              type: 'text',
              dictionary: 'countries',
            },
            {
              name: 'player_region_code',
              comparable: false,
              type: 'text',
              dictionary: 'regions',
            },
            {
              name: 'player_contacts',
              comparable: false,
              type: 'player_contacts',
            },
            {
              name: 'player_affiliate_id',
              comparable: false,
              type: 'player_affiliate_id',
            },
            {
              name: 'player_sub_affiliate_id',
              comparable: false,
              type: 'player_sub_affiliate_id',
            },
          ],
        },
        {
          name: 'deposits',
          items: [
            {
              name: 'has_deposits',
              comparable: false,
              type: 'boolean',
            },
            {
              name: 'favourite_payment_system_id',
              comparable: false,
              type: 'text',
              dictionary: 'paymentsSystems',
            },
            {
              name: 'deposits_count',
              comparable: true,
              type: 'number',
            },
            {
              name: 'deposits_sum',
              comparable: true,
              type: 'number',
            },
          ],
        },
        {
          name: 'gaming_activity',
          items: [
            {
              name: 'betting_profit_sum',
              comparable: true,
              type: 'number',
            },
            {
              name: 'has_bets',
              comparable: false,
              type: 'boolean',
            },
            {
              name: 'favourite_bet_by_sum',
              comparable: false,
              type: 'sport_casino',
            },
            {
              name: 'favourite_bet_by_count',
              comparable: false,
              type: 'sport_casino',
            },
            {
              name: 'has_sports_bets',
              comparable: false,
              type: 'boolean',
            },
            {
              name: 'has_games_bets',
              comparable: false,
              type: 'boolean',
            },
            {
              name: 'favourite_sport_type',
              comparable: false,
              type: 'text',
              dictionary: 'sportType',
            },
            {
              name: 'favourite_sport_league',
              comparable: false,
              type: 'text',
              dictionary: 'sportLeague',
            },
            {
              name: 'sport_bets_count',
              comparable: true,
              type: 'number',
            },
            {
              name: 'sport_bets_sum',
              comparable: true,
              type: 'number',
            },
            {
              name: 'sport_bets_live_count',
              comparable: true,
              type: 'number',
            },
            {
              name: 'sport_bets_live_sum',
              comparable: true,
              type: 'number',
            },
            {
              name: 'games_bets_count',
              comparable: true,
              type: 'number',
            },
            {
              name: 'games_bets_sum',
              comparable: true,
              type: 'number',
            },
          ],
        },
      ],
    };
  },

  computed: {
    calcPlayerAttached() {
      if (!this.$_.isEmpty(this.activity)) {
        return this.playerAttached;
      }

      if (!this.segment.is_players_attached) {
        this.getPlayers();
      }

      return this.segment.is_players_attached;
    },
    rulesGroup() {
      return !this.$_.isEmpty(this.segment.rules_group) ? this.segment.rules_group : defaultRulesGroup;
    },
    canEdit() {
      return this.permissions.can_edit;
    },
    canReview() {
      return this.permissions.can_review;
    },

    isActive() {
      return !this.isLoading && !this.isStatsLoading && !this.isSaveLoading;
    },

    isStatsLoading() {
      return this.statsLoadingCount !== 0;
    },

    statsParams() {
      return {
        site_id: this.currentBrand,
        rules_group: this.segment.rules_group,
      };
    },
    usedInActivities() {
      return this.segment.used_in_activities && this.segment.used_in_activities.length
        ? this.segment.used_in_activities
          .map(activity => activity.name)
          .join(', ')
        : '';
    },
    status() {
      return this.detectSegmentStatus(this.segment);
    },
  },

  watch: {
    isOpen(open) {
      if (open) {
        this.getSegment();
        this.addionalFilters = this.$_.cloneDeep(this.activityFilters);
        this.lastHash = hash(this.clearFilter(), { algorithm: 'md5' });
      }
    },
    /**
     * Автообновление при добавления фильтра ОТКЛ BWADMN-1891
     */
    // addionalFilters: {
    //   deep: true,
    //   handler(value) {
    //     this.check(value);
    //   },
    // },
  },

  created() {},
  mounted() {
    this.check = this.$_.debounce(this.checkChanges, 500);
  },

  methods: {
    getPlayers() {
      const interval = setInterval(async () => {
        if (this.segmentId) {
          const { data: { payload } } = await this.$api.getSegment(this.segmentId);

          if (payload.is_players_attached) {
            this.$set(this.segment, 'is_players_attached', payload.is_players_attached);
            this.$set(this.segment, 'players_count', payload.players_count);
            clearInterval(interval);
          }
        }

        if (!this.segmentId) {
          clearInterval(interval);
        }
      }, 15000);
    },
    async exportData() {
      let file = {};
      if (this.$_.isEmpty(this.activity)) {
        file = await this.$api.getSegmentPlayers(this.segmentId);
      } else {
        file = await this.$api.getActivityPlayers(this.activity.id);
      }
      const linkBlob = new Blob(
        [file.data],
        { type: file.headers['content-type'] },
      );

      fileSaver(linkBlob, this.segment.name);
    },

    getDefaultRule() {
      return {
        rule_type: '',
        operator: '',
        values: '',
      };
    },
    async checkChanges(value = this.addionalFilters) {
      const checkFilter = this.clearFilter(value);
      const currentHash = hash(checkFilter, { algorithm: 'md5' });
      if (currentHash !== this.lastHash) {
        await this.getStats(checkFilter);
        this.lastHash = currentHash;
      }
    },
    clearFilter(value = this.addionalFilters) {
      let filterCopy = this.$_.cloneDeep(value);
      filterCopy = filterCopy
        .filter(filter => filter.values !== '' && filter.values.length !== 0)
        .map(filter => ({
          ...filter,
          values: filter.rule_type === 'player_affiliate_id' || filter.rule_type === 'player_sub_affiliate_id'
            ? filter.values.map(item => item.id)
            : filter.values,
        }));
      return filterCopy;
    },
    addFilter() {
      this.addionalFilters.push(this.getDefaultRule());
    },
    deleteFilter(index) {
      this.addionalFilters.splice(index, 1);
    },
    async getSegment() {
      this.isLoading = true;
      this.isSegmentInfoLoading = true;
      this.commentsLoading = true;

      try {
        if (this.isLocalSegment && this.localSegment) {
          this.segment = this.$_.cloneDeep(this.localSegment);
        } else {
          const { data: { payload, misc } } = await this.$api.getSegment(this.segmentId);
          await this.getComments();
          this.segment = payload;
          this.permissions = misc.permissions;
        }

        await this.getStats(this.state);
      } finally {
        this.$nextTick(() => {
          this.isLoading = false;
          this.isSegmentInfoLoading = false;
        });
      }
    },

    close() {
      this.segment = {
        name: '',
        type: '',
        rules_group: {
          logical_relations: '',
          rules: [
            {
              logical_relations: '',
              rules: [
                {
                  rule_type: '',
                  operator: '',
                  values: '',
                },
              ],
            },
          ],
        },
      };
      this.stats = {};
      this.$emit('closePopupSegment');
    },

    edit() {
      this.$emit('edit');
    },

    save() {
      this.$emit('update:activity-filters', this.addionalFilters);
      if (!this.$_.isEmpty(this.stats)) {
        this.$emit('updateFilterStats', this.stats);
      }

      this.$emit('closePopupSegment');
    },

    async changeStatus(status) {
      try {
        this.isLoading = true;
        await this.$api.postSegmentChangeStatus(this.segmentId, status);
        this.close();
      } finally {
        this.isLoading = false;
      }
    },

    async getStats(state) {
      const isEdit = state === 'edit' || state === 'rework-edit';

      try {
        this.statsLoadingCount = this.statsLoadingCount + 1;
        if (!isEdit && !this.$_.isEmpty(this.activity)) {
          const { data: { payload } } = await this.$api.getActivityStatsById(this.activity.id);
          this.stats = payload;
        } else if (isEdit && !this.segment.is_global) {
          const segmentRules = this.clearSegment(this.segment);
          const { data: { payload } } = await this.$api.getSegmentStats({
            ...segmentRules,
            site_id: this.currentBrand,
          });
          this.stats = payload;
        } else {
          const addionalFilters = this.clearFilter();

          const { data: { payload } } = await this.$api.getSegmentStatsById(this.segmentId, addionalFilters);
          this.stats = payload;
        }
      } finally {
        this.statsLoadingCount = this.statsLoadingCount - 1;
      }
    },

    postComment(comment) {
      this.commentsLoading = true;
      this.$api
        .postSegmentComments(this.segmentId, comment)
        .then(() => {
          this.getComments();
        })
        .catch(() => {
          this.commentsLoading = false;
        });
    },

    getComments() {
      this.$api
        .getSegmentComments(this.segmentId)
        .then((response) => {
          this.comments = this.$_.cloneDeepWith(response.data.payload);
          app.$eventBus.$emit('defaultCommentsState');
        })
        .finally(() => {
          this.isDataLoading = false;
          this.commentsLoading = false;
        });
    },
    handleFilterLoading(value) {
      this.filterLoading += value ? 1 : -1;
    },
  },
};
</script>
