<template>
  <div class="affiliates-list payment-methods">
    <add-payment-method-popup
      ref="addPaymentMethodPopup"
      :currency-options="currencyList"
      @save="fetchData"
    />
    <div class="toolbar" @keyup.enter="handleRefresh">
      <div class="wrapper">
        <div class="section filters">
          <span class="title">{{ $t('settings.paymentMethods.title') }}</span>
          <ui-input
            v-model.trim="searchQuery"
            :width="180"
            class="select-label"
            is-search
            @search="handleRefresh"
          />
        </div>
        <div class="section">
          <ui-pagination
            :page="page"
            :page-size="limit"
            :count="count"
            show-size-select
            @page-change="pageChange"
          />
          <ui-button
            color="green"
            lib="fa"
            substyle="fas"
            filled
            icon="plus"
            class="btn btn-add"
            @click="handleOpenPopup"
          >
            {{ $t('settings.paymentMethods.addPaymentMethod') }}
          </ui-button>
        </div>
      </div>
    </div>

    <ui-table
      v-loading="isDataLoading"
      :fields="fields"
      :data="tableData"
      :rows-count="limit"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      lazy-loading
      i18n-path="settings.paymentMethods"
      element-loading-custom-class="data-loader-spinner"
      class="table"
      @sort="handleSort"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import updateUrl from '../../service/updateUrl';
import AddPaymentMethodPopup from './components/AddPaymentMethodPopup.vue';
import sessionAction from '@/views/mixins/session-action';

import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';

const sizes = {
  1080: 20,
  1440: 30,
};
const LS_PATH = 'PaymentMethods/List';
const viewName = `${LS_PATH}/limit`;
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'PaymentMethods',
  components: {
    AddPaymentMethodPopup,
  },
  mixins: [pageSizeMixin, sessionAction],
  data() {
    return {
      viewName,
      fields: [
        {
          name: 'id',
          width: '250',
          align: 'left',
          class: 'text',
        },
        {
          name: 'status',
          minWidth: '80',
          renderValue: (_, row) => this.$t(`settings.paymentMethods.statuses.${row?.is_disabled ? 'disabled' : 'active'}`),
          computedClass: (_, row) => (`row-value--${row?.is_disabled ? 'invalid' : 'active'}`),
        },
        {
          name: 'visibility',
          width: '90',
          align: 'center',
          headerAlign: 'center',
          actionLib: 'fa',
          controlType: 'icon',
          action: () => {},
          controlColorIco: row => (row.hidden ? this.$theme.dangerColor : this.$theme.accentColor),
          actionName: row => (row.hidden ? 'eye-slash' : 'eye'),
          class: 'default-icon',
        },
        {
          name: 'name',
          minWidth: '264',
          sortable: 'custom',
          class: 'text',
        },
        {
          name: 'currencies',
          minWidth: '264',
          class: 'text',
          renderValue: (value, row) => {
            if (row.supported_currencies === 'all') {
              return this.$t('settings.paymentMethods.popup.all');
            }

            return value.map(({ code }) => code).join(', ');
          },
        },
        {
          name: 'logo',
          minWidth: '120',
          align: 'center',
          headerAlign: 'center',
          class: 'logo',
          computedImg: row => row.logo_base64,
          computedImgNull: () => '',
        },
        {
          name: 'export',
          width: '130',
          align: 'center',
          headerAlign: 'center',
          actionLib: 'fa',
          controlType: 'icon',
          action: () => {},
          controlColorIco: row => (!row.export_via_api ? this.$theme.dangerColor : this.$theme.accentColor),
          actionName: row => (row.export_via_api ? 'check' : 'times'),
          class: 'default-icon',
        },
        {
          name: 'fileAttachments',
          width: '150',
          align: 'center',
          headerAlign: 'center',
          actionLib: 'fa',
          controlType: 'icon',
          class: 'file-attachments default-icon',
          action: () => {},
          controlColorIco: row => (!row.file_attachment_allowed && !row.file_attachment_required ? this.$theme.dangerColor : this.$theme.accentColor),
          actionName: this.renderFileAttachmentsIcon,
          controlColor: row => (!row.file_attachment_allowed && !row.file_attachment_required ? this.$theme.dangerColor : this.$theme.accentColor),
          computedValue: this.renderFileAttachments,
        },
        {
          name: 'minPaymentAmount',
          width: '160',
          align: 'right',
          headerAlign: 'right',
          computedValue: row => this.getCurrencyLabel(row.min_payment_amount_currency_code) + numeral(row.min_payment_amount).format('0,0.00'),
        },
        {
          name: 'actions',
          width: '80',
          align: 'center',
          headerAlign: 'center',
          actionLib: 'fa',
          controlType: 'icon',
          class: 'file-attachments',
          action: row => this.$refs.addPaymentMethodPopup.open(row.id),
          controlColorIco: () => this.$theme.accentColor,
          actionName: 'pen',
          tooltip: 'crm.buttons.edit',
        },
      ],
      isDataLoading: false,
      tableData: [],
      currencyList: [],
      page: 1,
      limit: pageSize,
      count: 0,
      searchQuery: '',
      options: {
        sort_column: '',
        sort_dir: '',
        search: '',
      },
      sortProp: '',
      sortOrder: '',
      fetchData: Function,
    };
  },

  computed: {
    ...mapGetters({
      adminAcl: 'auth/adminAcl',
    }),
  },

  watch: {
    searchQuery() {
      this.options.search = this.searchQuery;
    },
    sortProp(newVal) {
      this.options.sort_column = this.sortProp;
      /*
       * Прверяем сортировку чтобы не было шибки с сервера 422
       * */
      if (
        this.fields.some(
          item => item.name === this.options.sort_column && item.sortable === 'custom',
        )
      ) {
        this.$ls.set(`${LS_PATH}/sort_column`, newVal || 'name');
      }
    },
    sortOrder(newVal) {
      this.options.sort_dir = this.sortOrder;
      this.$ls.set(`${LS_PATH}/sort_dir`, newVal || 'asc');
    },
  },


  created() {
    this.fetchData = this.$_.debounce(this.fetchDataD, 300);
    this.onSessionUnlock = this.initData;
    this.initData();
  },
  beforeDestroy() {
  },

  methods: {
    initData() {
      this.$api.getMiscCustomerCurrencies().then((res) => {
        this.currencyList = res.data.payload;
      });

      //   /*
      //    * Считывай hash из routers
      //    * */
      const _urlData = updateUrl.getParseParams(this.$route.hash);

      if (!this.$_.isEmpty(_urlData)) {
        /*
         * Приводим объект к дефолтным данным
         * */
        const { limit = 0, page = 1 } = _urlData;

        this.limit = Number(limit) || pageSize;
        this.page = Number(page) || 1;

        delete _urlData.page;
        delete _urlData.limit;

        this.$_.merge(this.options, _urlData);

        this.sortProp = this.$_.get(_urlData, ['sort_column'], 'name');
        this.sortOrder = this.$_.get(_urlData, ['sort_dir'], 'asc');

        this.searchQuery = this.$_.get(this.options, ['search'], '');

        /*
         * Блокируем функцию на один вызов
         * */
        if (this.searchQuery.length > 0) {
          this.blockedFetch = true;
        }
      } else {
        this.sortProp = this.$ls.get(`${LS_PATH}/sort_column`)
          ? this.$ls.get(`${LS_PATH}/sort_column`)
          : 'name';
        this.sortOrder = this.$ls.get(`${LS_PATH}/sort_dir`)
          ? this.$ls.get(`${LS_PATH}/sort_dir`)
          : 'asc';
        this.limit = +this.$ls.get(`${LS_PATH}/limit`) || pageSize;
      }

      this.fetchData(this.$refs?.addPaymentMethodPopup?.isOpen && 'silent');
    },
    handleOpenPopup() {
      this.$refs.addPaymentMethodPopup.open();
    },
    getCurrencyLabel(code) {
      const currency = this.currencyList.find((el => el.code === code));
      return currency?.symbol || `${code} `;
    },
    renderFileAttachments(row) {
      if (row.file_attachment_allowed) {
        if (row.file_attachment_required) {
          return this.$t('settings.paymentMethods.fileAttachments.required');
        }

        return this.$t('settings.paymentMethods.fileAttachments.allowed');
      }

      return this.$t('settings.paymentMethods.fileAttachments.no');
    },
    renderFileAttachmentsIcon(row) {
      if (row.file_attachment_allowed) {
        if (row.file_attachment_required) {
          return 'exclamation-circle';
        }

        return 'check';
      }

      return 'times';
    },
    /*
     * Формируем запроос
     * */
    _query() {
      /* подготовка опций для мультиакаунтов */

      return {
        ...this.options,
        limit: this.limit,
        offset: this.limit * this.page - this.limit,
      };
    },

    /*
     * Делаем debounce чтобы не было повторных запрос при изминении фильтра
     * */
    fetchDataD(action) {
      if (action !== 'silent') {
        this.isDataLoading = true;
      }
      /*
       * Формируем URL Filter
       * */
      this._completedFilterUrl();

      /* подготовка опций для мультиаккаунтов */
      const options = this._query();

      this.$api
        .getControlPaymentMethods(options)
        .then((response) => {
          this.count = response.data.misc.count;
          this.tableData = this.$_.cloneDeep(response.data.payload);
        })
        .finally(() => {
          if (action !== 'silent') {
            this.isDataLoading = false;
          }
        });
    },

    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.fetchData();
      }
    },
    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.fetchData();
    },
    handleRefresh() {
      this.page = 1;
      this.fetchData();
    },
    /*
     * Создаем filter URL
     * */
    _completedFilterUrl() {
      const _dataFilters = this._query();
      _dataFilters.page = this.page;

      delete _dataFilters.offset;

      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>
<style lang='scss' scoped>
.payment-methods {
  /deep/ {
    .file-attachments {
      align-items: center;
      flex-direction: row-reverse;

      span {
        margin-left: 4px;
      }
    }

    .logo {
      max-width: 100%;
      max-height: 28px;
    }

    .default-icon {
      .ui-icon {
        cursor: initial!important;
      }
    }
    .el-table__cell .row-value.text {
      display: inline-block!important;
      text-overflow: ellipsis;
    }
  }
}
</style>
