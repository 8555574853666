const tableFieldsExMethodsPlayerList = [
  {
    name: 'site_player_id',
    width: '160',
  },
  {
    name: 'affiliate_email',
    width: '200',
  },
  {
    name: 'player_country_name',
    width: '170',
  },
  {
    name: 'phone_number',
    width: '170',
  },
  {
    name: 'email',
  },
  {
    name: 'first_name',
  },
  {
    name: 'is_virtual',
    width: '100',
  },
  {
    name: 'created_at',
    width: '120',
  },
];

export default tableFieldsExMethodsPlayerList;
