<template>
  <el-tooltip
    :open-delay="300"
    placement="top"
    :content="tooltip"
    :disabled="isEmptyContent"
  >
    <slot />
  </el-tooltip>
</template>

<script>

export default {
  name: 'ActionIcon',

  props: {
    tooltip: {
      type: String,
      default() {
        return '';
      },
    },
  },

  computed: {
    isEmptyContent() {
      return !(this.tooltip.trim());
    },
  },
};

</script>
