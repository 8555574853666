export default {
  getMainReport(params) {
    return this.affiliatesApi.post('/reports', params);
  },
  // getPanelSettings() {
  //   return this.affiliatesApi.get('/reports/panel-settings');
  // },
  // getReportsSettings() {
  //   return this.affiliatesApi.get('/reports/settings');
  // },
  getPaymentsReport(params) {
    return this.affiliatesApi.post('/reports/special/payment-systems', params);
  },
  getShortUrlRequest(path) {
    return this.affiliatesApi.get(`/reports/short-url/${path}`);
  },
  postQueryShortUrl(query) {
    return this.affiliatesApi.post('/reports/short-url', { query });
  },
};
