<template>
  <div
    v-if="isOpen"
    class="service-domain-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">{{ $t(`domainManagement.popup.${actionName}`) }} {{ $t(`domainManagement.popup.serviceDomain`) }}</span>
        <ui-icon
          :size="20"
          class="close"
          lib="fa"
          substyle="fas"
          name="times"
          @click.native="close"
        />
      </div>
      <adm-ui-scrollbar
        wrap-style="max-height: 60vh; height: auto;"
        :native="false"
      >
        <div class="form full-width">
          <div v-if="!isUpdate" class="row">
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.setup_mode.label`) }}</span>
              <el-radio
                v-model="setup_mode"
                label="managed"
              >
                {{ $t(`domainManagement.popup.form.setup_mode.managed`) }}
              </el-radio>
              <el-radio
                v-model="setup_mode"
                label="manual"
              >
                {{ $t(`domainManagement.popup.form.setup_mode.manual`) }}
              </el-radio>
            </div>
          </div>
          <div
            v-if="!isUpdate"
            class="row"
          >
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.domain_name.label`) }} <span class="required">*</span></span>
              <ui-input
                v-model="domain_name"
                :placeholder="$t(`domainManagement.popup.form.domain_name.placeholder`)"
                autosize
                :class="{ error: isInvalidField('domain_name') }"
                :error="getError('domain_name')"
                @blur="touchField('domain_name')"
              />
            </div>
          </div>

          <div
            v-if="!isUpdate"
            class="row"
          >
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.platform_service_origin_id.label`) }} <span class="required">*</span></span>
              <el-select
                v-model="platform_service_origin_id"
                :placeholder="$t(`domainManagement.popup.form.platform_service_origin_id.placeholder`)"
                filterable
                class="select"
                :class="{ error: isInvalidField('platform_service_origin_id') }"
                @blur="touchField('platform_service_origin_id')"
              >
                <el-option
                  v-for="serviceOrigin in serviceOrigins"
                  :key="serviceOrigin.id"
                  :label="serviceOrigin.name"
                  :value="serviceOrigin.id"
                />
              </el-select>
            </div>
          </div>

          <template v-if="isManagedSetupMode">
            <div
              v-if="!isUpdate"
              class="row"
            >
              <div class="field">
                <span class="label-text">{{ $t(`domainManagement.popup.form.pick_platform_server_type.label`) }}</span>
                <el-radio
                  v-model="pick_platform_server_type"
                  :label="'auto'"
                >
                  {{ $t(`domainManagement.popup.form.pick_platform_server_type.auto`) }}
                </el-radio>
                <el-radio
                  v-model="pick_platform_server_type"
                  :label="'manual'"
                >
                  {{ $t(`domainManagement.popup.form.pick_platform_server_type.manual`) }}
                </el-radio>
                <div v-if="getError('pick_platform_server_type')" class="field-error">
                  {{ getError('pick_platform_server_type') }}
                </div>
              </div>
            </div>

            <div
              v-if="pick_platform_server_type === 'manual' || isUpdate"
              class="row"
            >
              <div class="field">
                <span class="label-text">{{ $t(`domainManagement.popup.form.platform_server_id.label`) }} <span class="required">*</span></span>
                <el-select
                  v-model="platform_server_id"
                  :placeholder="$t(`domainManagement.popup.form.platform_server_id.placeholder`)"
                  filterable
                  class="select"
                  :class="{ error: isInvalidField('platform_server_id') }"
                  @blur="touchField('platform_server_id')"
                  @change="changeServer"
                >
                  <el-option
                    v-for="platformServer in platformServers"
                    :key="platformServer.id"
                    :label="platformServer.name"
                    :value="platformServer.id"
                  />
                </el-select>
              </div>
            </div>

            <div
              v-if="(pick_platform_server_type === 'manual' || isUpdate) && platform_server_id"
              class="row"
            >
              <div class="field">
                <span class="label-text">{{ $t(`domainManagement.popup.form.platform_server_ipv4_address_id.label`) }} <span class="required">*</span></span>
                <el-select
                  v-model="platform_server_ipv4_address_id"
                  :placeholder="$t(`domainManagement.popup.form.platform_server_ipv4_address_id.placeholder`)"
                  filterable
                  class="select"
                  :class="{ error: isInvalidField('platform_server_ipv4_address_id') }"
                  @blur="touchField('platform_server_ipv4_address_id')"
                >
                  <el-option
                    v-for="IPv4Adress in IPv4Adresses"
                    :key="IPv4Adress.id"
                    :label="`${IPv4Adress.name} (${IPv4Adress.count})`"
                    :value="IPv4Adress.id"
                  />
                </el-select>
              </div>
            </div>

            <div
              v-if="(pick_platform_server_type === 'manual' || isUpdate) && platform_server_id"
              class="row"
            >
              <div class="field">
                <span class="label-text">{{ $t(`domainManagement.popup.form.platform_server_ipv6_address_id.label`) }}</span>
                <el-select
                  v-model="platform_server_ipv6_address_id"
                  :placeholder="$t(`domainManagement.popup.form.platform_server_ipv6_address_id.placeholder`)"
                  filterable
                  class="select"
                >
                  <el-option
                    v-for="IPv6Adress in IPv6Adresses"
                    :key="IPv6Adress.id"
                    :label="`${IPv6Adress.name}${IPv6Adress.count !== '' ? ` (${IPv6Adress.count})` : '' }`"
                    :value="IPv6Adress.id"
                  />
                </el-select>
              </div>
            </div>

            <div class="row">
              <div class="field">
                <span class="label-text">{{ $t(`domainManagement.popup.form.ssl_issuance.label`) }}</span>
                <el-radio
                  v-model="ssl_issuance"
                  label="auto"
                >
                  {{ $t(`domainManagement.popup.form.ssl_issuance.auto`) }}
                </el-radio>
                <el-radio
                  v-model="ssl_issuance"
                  label="manual"
                >
                  {{ $t(`domainManagement.popup.form.ssl_issuance.manual`) }}
                </el-radio>
              </div>
            </div>

            <div class="row">
              <div class="field">
                <span class="label-text">{{ $t(`domainManagement.popup.form.ns_management.label`) }}</span>
                <el-radio
                  v-model="ns_management"
                  :label="'auto'"
                >
                  {{ $t(`domainManagement.popup.form.ns_management.auto`) }}
                </el-radio>
                <el-radio
                  v-model="ns_management"
                  :label="'manual'"
                >
                  {{ $t(`domainManagement.popup.form.ns_management.manual`) }}
                </el-radio>
              </div>
            </div>

            <div
              v-if="ns_management === 'auto'"
              class="row"
            >
              <div class="field">
                <span class="label-text">{{ $t(`domainManagement.popup.form.domain_registrar_account_id.label`) }} <span class="required">*</span></span>
                <el-select
                  v-model="domain_registrar_account_id"
                  :placeholder="$t(`domainManagement.popup.form.domain_registrar_account_id.placeholder`)"
                  filterable
                  class="select"
                  :class="{ error: isInvalidField('domain_registrar_account_id') }"
                  @blur="touchField('domain_registrar_account_id')"
                >
                  <el-option
                    v-for="registrarAccount in registrarAccounts"
                    :key="registrarAccount.id"
                    :label="registrarAccount.name"
                    :value="registrarAccount.id"
                  />
                </el-select>
              </div>
            </div>

            <div class="row">
              <div class="field">
                <span class="label-text">{{ $t(`domainManagement.popup.form.ns_servers.label`) }}</span>
                <el-input
                  v-model="ns_servers"
                  :placeholder="$t(`domainManagement.popup.form.ns_servers.placeholder`)"
                  :rows="3"
                  resize="none"
                  type="textarea"
                  :class="{ error: isInvalidField('ns_servers') }"
                  @blur="touchField('ns_servers')"
                />
              </div>
            </div>

            <div class="row">
              <div class="field">
                <span class="label-text">{{ $t(`domainManagement.popup.form.dns_management.label`) }}</span>
                <el-radio
                  v-model="dns_management"
                  :label="'auto'"
                >
                  {{ $t(`domainManagement.popup.form.dns_management.auto`) }}
                </el-radio>
                <el-radio
                  v-model="dns_management"
                  :label="'manual'"
                >
                  {{ $t(`domainManagement.popup.form.dns_management.manual`) }}
                </el-radio>
              </div>
            </div>

            <div
              v-if="dns_management === 'auto'"
              class="row"
            >
              <div class="field">
                <span class="label-text">{{ $t(`domainManagement.popup.form.dns_hosting_account_id.label`) }} <span class="required">*</span></span>
                <el-select
                  v-model="dns_hosting_account_id"
                  :placeholder="$t(`domainManagement.popup.form.dns_hosting_account_id.placeholder`)"
                  filterable
                  class="select"
                  :class="{ error: isInvalidField('dns_hosting_account_id') }"
                  @blur="touchField('dns_hosting_account_id')"
                >
                  <el-option
                    v-for="dnsHostingAccount in dnsHostingAccounts"
                    :key="dnsHostingAccount.id"
                    :label="dnsHostingAccount.name"
                    :value="dnsHostingAccount.id"
                  />
                </el-select>
              </div>
            </div>
          </template>
        </div>
      </adm-ui-scrollbar>
      <div class="foot">
        <ui-button
          color="red"
          class="btn cancel-button"
          lib="fa"
          substyle="fas"
          icon="times"
          size="big"
          @click.native="close"
        >
          {{ $t(`domainManagement.popup.cancel`) }}
        </ui-button>
        <ui-button
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          size="big"
          :disabled="$v.$invalid"
          @click.prevent="save"
        >
          {{ $t(`domainManagement.popup.save`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
import {
  required, requiredIf,
} from 'vuelidate/lib/validators';
import { nsServers, isDomain } from '@/helpers/validators';
import validateMixin from './mixin/validate';

export default {
  name: 'ServiceDomainPopup',

  mixins: [validateMixin],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Object,
      default() {
        return {};
      },
    },
    initalData: {
      type: [Object],
      default() {
        return {
          setup_mode: 'managed',
          platform_service_origin_id: null,
          domain_name: '',
          pick_platform_server_type: 'auto',
          platform_server_id: null,
          platform_server_ipv4_address_id: null,
          platform_server_ipv6_address_id: null,
          ssl_issuance: 'auto',
          ns_management: 'auto',
          domain_registrar_account_id: null,
          ns_servers: [],
          dns_management: 'auto',
          dns_hosting_account_id: null,
        };
      },
    },
  },

  data() {
    return {
      platform_service_origin_id: this.initalData.platform_service_origin_id,
      domain_name: this.initalData.domain_name,
      pick_platform_server_type: this.initalData.pick_platform_server_type,
      platform_server_id: this.initalData.platform_server_id,
      platform_server_ipv4_address_id: this.initalData.platform_server_ipv4_address_id,
      platform_server_ipv6_address_id: this.initalData.platform_server_ipv6_address_id,
      ssl_issuance: this.initalData.ssl_issuance,
      ns_management: this.initalData.ns_management,
      setup_mode: this.initalData.setup_mode,
      domain_registrar_account_id: this.initalData.domain_registrar_account_id,
      ns_servers: this.initalData.ns_servers.join('\n'),
      dns_management: this.initalData.dns_management,
      dns_hosting_account_id: this.initalData.dns_hosting_account_id,

      serviceOrigins: [],
      platformServers: [],
      IPv4Adresses: [],
      IPv6Adresses: [],
      registrarAccounts: [],
      dnsHostingAccounts: [],
    };
  },

  validations() {
    return {
      domain_name: {
        required,
        isDomain,
      },
      platform_service_origin_id: {
        required,
      },
      platform_server_id: {
        required: requiredIf(data => this.isManagedSetupMode && (data.pick_platform_server_type === 'manual' || data.isUpdate)),
      },
      platform_server_ipv4_address_id: {
        required: requiredIf(data => this.isManagedSetupMode && (data.pick_platform_server_type === 'manual' || data.isUpdate)),
      },
      domain_registrar_account_id: {
        required: requiredIf(data => this.isManagedSetupMode && data.ns_management === 'auto'),
      },
      dns_hosting_account_id: {
        required: requiredIf(data => this.isManagedSetupMode && data.dns_management === 'auto'),
      },
      ns_servers: {
        nsServers,
      },
    };
  },

  computed: {
    isManagedSetupMode() {
      return this.setup_mode === 'managed';
    },
    isUpdate() {
      return !!this.initalData.id;
    },
    actionName() {
      return (!this.initalData.id) ? 'add' : 'edit';
    },
  },

  watch: {
    ns_management(value) {
      if (value === 'manual') {
        this.domain_registrar_account_id = null;
      }
    },
    dns_management(value) {
      if (value === 'manual') {
        this.dns_hosting_account_id = null;
      }
    },
    pick_platform_server_type(value) {
      if (value === 'auto') {
        this.platform_server_id = null;
        this.platform_server_ipv4_address_id = null;
        this.platform_server_ipv6_address_id = null;
      } else {
        this.fetchServers();
      }
    },

    platform_server_id(value) {
      if (value) {
        this.fetchIPs();
      }
    },

    isOpen() {
      [
        this.setup_mode,
        this.platform_service_origin_id,
        this.domain_name,
        this.pick_platform_server_type,
        this.platform_server_id,
        this.platform_server_ipv4_address_id,
        this.platform_server_ipv6_address_id,
        this.ssl_issuance,
        this.ns_management,
        this.domain_registrar_account_id,
        this.ns_servers,
        this.dns_management,
        this.dns_hosting_account_id,
      ] = [
        this.initalData.setup_mode,
        this.initalData.platform_service_origin_id,
        this.initalData.domain_name,
        this.initalData.pick_platform_server_type,
        this.initalData.platform_server_id,
        this.initalData.platform_server_ipv4_address_id,
        this.initalData.platform_server_ipv6_address_id,
        this.initalData.ssl_issuance,
        this.initalData.ns_management,
        this.initalData.domain_registrar_account_id,
        this.initalData.ns_servers.join('\n'),
        this.initalData.dns_management,
        this.initalData.dns_hosting_account_id,
      ];
    },
  },

  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },

  destroyed() {
    window.removeEventListener('keyup', this.keyupHandler);
  },

  async created() {
    await Promise.all([
      await this.fetchServiceOrigins(),
      await this.fetchRegistrarAccounts(),
      await this.fetchDNSHostingAccounts(),
    ]);

    if (this.isUpdate) {
      await this.fetchServers();
    }
  },

  methods: {
    close() {
      this.resetForm();
      this.$emit('close');
    },
    getError(field) {
      return this.$_.get(this.errors, [field, 0, 'message'], '');
    },

    changeServer() {
      this.platform_server_ipv4_address_id = null;
      this.platform_server_ipv6_address_id = null;
    },

    async fetchServiceOrigins() {
      const { data: { payload: serviceOrigins } } = await this.$api.getServiceOrigins();
      this.serviceOrigins = serviceOrigins;
    },

    async fetchServers() {
      const { data: { payload: platformServers } } = await this.$api.getPlatformServers({ limit: 1000 });
      this.platformServers = platformServers;
      this.fetchIPs();
    },

    async fetchRegistrarAccounts() {
      const { data: { payload: registrarAccounts } } = await this.$api.getDomainRegistrarAccounts({ limit: 1000 });
      this.registrarAccounts = registrarAccounts;
    },

    async fetchDNSHostingAccounts() {
      const { data: { payload: dnsHostingAccounts } } = await this.$api.getDNSHostingAccounts({ limit: 1000 });
      this.dnsHostingAccounts = dnsHostingAccounts;
    },

    fetchIPs() {
      const [selectedPlatformServer] = this.platformServers.filter(platformServer => platformServer.id === this.platform_server_id);
      const IPv4Adresses = [];
      const IPv6Adresses = [{ id: null, name: 'Not Selected', count: '' }];

      if (selectedPlatformServer) {
        const {
          main_ipv4_address,
          main_ipv4_address_id,
          main_ipv4_address_used_count,
          additional_ipv4_addresses,

          main_ipv6_address,
          main_ipv6_address_id,
          main_ipv6_address_used_count,
          additional_ipv6_addresses,
        } = selectedPlatformServer;

        IPv4Adresses.push({ id: main_ipv4_address_id, name: main_ipv4_address, count: main_ipv4_address_used_count });

        if (additional_ipv4_addresses.length) {
          IPv4Adresses.push(...additional_ipv4_addresses.map(item => ({ id: item.id, name: item.ip_address, count: item.used_count })));
        }

        if (main_ipv6_address_id) {
          IPv6Adresses.push({ id: main_ipv6_address_id, name: main_ipv6_address, count: main_ipv6_address_used_count });
        }

        if (additional_ipv6_addresses.length) {
          IPv6Adresses.push(...additional_ipv6_addresses.map(item => ({ id: item.id, name: item.ip_address, count: item.used_count })));
        }
      }

      this.IPv4Adresses = IPv4Adresses.sort((a, b) => Number(a.count) - Number(b.count));
      this.IPv6Adresses = IPv6Adresses.sort((a, b) => Number(a.count) - Number(b.count));
    },

    save() {
      const data = {
        setup_mode: this.setup_mode,
        platform_service_origin_id: this.platform_service_origin_id,
        domain_name: this.domain_name,
        ...(this.isManagedSetupMode && {
          pick_platform_server_type: this.pick_platform_server_type,
          platform_server_id: this.platform_server_id,
          platform_server_ipv4_address_id: this.platform_server_ipv4_address_id,
          platform_server_ipv6_address_id: this.platform_server_ipv6_address_id,
          ssl_issuance: this.ssl_issuance,
          ns_management: this.ns_management,
          domain_registrar_account_id: this.domain_registrar_account_id,
          ns_servers: this.ns_servers ? this.ns_servers.trim().split('\n').map(ns_server => ns_server.trim()) : [],
          dns_management: this.dns_management,
          dns_hosting_account_id: this.dns_hosting_account_id,
        }),
      };

      if (!this.initalData.id) {
        this.$emit('create', data);
      } else {
        this.$emit('update', data);
      }
    },

    keyupHandler(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    },

    resetForm() {
      this.resetTouchedFields();

      this.setup_mode = 'managed';
      this.platform_service_origin_id = null;
      this.domain_name = '';
      this.pick_platform_server_type = 'auto';
      this.platform_server_id = null;
      this.platform_server_ipv4_address_id = null;
      this.platform_server_ipv6_address_id = null;
      this.ssl_issuance = 'auto';
      this.ns_management = 'auto';
      this.domain_registrar_account_id = null;
      this.ns_servers = [];
      this.dns_management = 'auto';
      this.dns_hosting_account_id = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.field-error {
  color: var(--danger-color);
  font-size: 12px;
  margin-bottom: 15px;
  height: 14px;
  line-height: 14px;
  font-style: italic;
}

.service-domain-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .cancel-button {
    margin-right: 8px;
  }

  .popup {
    position: absolute;
    z-index: 902;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid #eaeaea;
      padding: 16px 24px;
      box-sizing: border-box;
      margin-bottom: 0;

      .name {
        font-size: 20px;
        font-weight: 500;
      }

      .close {
        color: #303634;
        padding: 5px;
        font-size: 32px;
        cursor: pointer;
      }
    }
    .form {
      padding: 16px 24px;
      box-sizing: border-box;
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      margin-top: 0;
    }
  }

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
</style>
