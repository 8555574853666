<template>
  <div
    v-if="isOpen"
    class="crm-add-mail-action-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ $t('crm.activities.actions.action_popup.mail_title') }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div
        v-loading="isLoading"
        class="body"
      >
        <div class="ui-fg-1">
          <ui-input
            v-model="name"
            class="form-input ui-m-xl-b"
            :label="$t('crm.activities.actions.action_popup.email_fields.name')"
            autosize
            size="big"
            :placeholder="$t('crm.activities.actions.action_popup.email_fields.name')"
            type="text"
            :disabled="preview"
            :error="curentErrors.name"
          />

          <div
            :class="[
              'crm-add-mail-action-popup__tooltip-hint',
              'ui-m-xl-b',
            ]"
          >
            {{ $t('crm.activities.actions.action_popup.mail_hint') }}
          </div>
          <div class="ui-m-xl-b">
            <div class="ui-d-flex ui-m-xl-r ui-m-lg-b">
              <crm-action-language-tab
                :languages="languages"
                :selected-index="index"
                :errors="errors"
                @select="handleSelectTab"
              />
              <crm-action-languages
                class="ui-fg-0"
                :selected="selectedLanguages"
                :disabled="preview"
                @select="handeAddLanguage"
              >
                <template slot="actions">
                  <div class="ui-d-flex" @click.stop>
                    <div class="ui-m-md-r">
                      <ui-button
                        icon="trash-can"
                        lib="fa"
                        substyle="fas"
                        color="red"
                        class="action-btn"
                        :disabled="index === defaultLanguageActionIndex || preview"
                        @click.stop="handleDelete(index)"
                      />
                    </div>
                    <div class="ui-m-md-r">
                      <ui-button
                        icon="history"
                        lib="fa"
                        substyle="fas"
                        color="green"
                        class="action-btn"
                        :disabled="index === defaultLanguageActionIndex || preview"
                        @click.stop="handleMakeDefault(index)"
                      >
                        {{ $t('crm.buttons.make_default') }}
                      </ui-button>
                    </div>
                  </div>
                </template>
              </crm-action-languages>
            </div>
          </div>

          <ui-input
            v-model="emailSubject"
            class="form-input ui-m-sm-b"
            :label="$t('crm.activities.actions.action_popup.email_fields.subject')"
            autosize
            size="big"
            :placeholder="$t('crm.activities.actions.action_popup.email_fields.subject')"
            type="text"
            :disabled="preview"
          />

          <div
            :class="[
              'ui-d-flex',
              'ui-jc-space-between',
              'ui-m-xl-b',
            ]"
          >
            <crm-action-params
              :disabled="preview"
              :message-params="messageParams"
              @select="handleAddParam($event, 'emailSubject')"
            />
          </div>

          <div class="template-wrapper ui-d-flex ui-jc-space-between">
            <div class="ui-d-flex ui-ai-center">
              <ui-icon
                lib="fa"
                substyle="fas"
                name="solid memo-pad"
                :color="$theme.accentColor"
              />
              <div class="ui-m-sm-l">
                <b>{{ $t('crm.activities.actions.mail_template.template') }}:</b> {{ activeTemplate.name }}
              </div>
            </div>
            <div v-if="templates.length > 1" class="ui-d-flex ui-ai-center change-action" @click="handleChangeTemplate(activeTemplate.id)">
              <ui-icon
                lib="fa"
                substyle="fas"
                name="solid arrows-rotate"
                :color="$theme.accentColor"
              />
              <div class="label">
                {{ $t('crm.activities.actions.mail_template.title') }}
              </div>
            </div>
          </div>
          <!-- <ui-input
            v-model="form.email_header"
            class="form-input ui-m-sm-b"
            :label="$t('crm.activities.actions.action_popup.mail_header_label')"
            autosize
            size="big"
            :placeholder="$t('crm.activities.actions.action_popup.mail_header_label')"
            type="text"
            :disabled="preview"
          />
          <div
            :class="[
              'ui-d-flex',
              'ui-jc-space-between',
              'ui-m-xl-b',
            ]"
          >
            <crm-action-params
              :disabled="preview"
              @select="handleAddParam($event, 'email_header')"
            />
          </div> -->

          <div class="select-block ui-m-sm-b">
            <div
              class="ui-d-flex ui-g-w-md ui-ai-center"
              :class="{
                'ui-m-sm-b': curentErrors.message,
              }"
            >
              <span
                class="crm-add-mail-action-popup__label label ui-g-md"
                :class="{
                  'crm-add-mail-action-popup__label_has-errors': curentErrors.message,
                }"
              >{{ $t('crm.activities.actions.action_popup.email_fields.message') }}</span>
              <crm-error-panel
                v-if="curentErrors.message"
                class="ui-g-md ui-ai-baseline"
              >
                {{ curentErrors.message }}
              </crm-error-panel>
            </div>
            <div class="ui-d-flex ui-jc-space-between">
              <v-md-editor
                ref="editor"
                v-model="message"
                class="crm-add-mail-action-popup__message-editor"
                :class="{
                  'crm-add-mail-action-popup__message-editor_has-error': curentErrors.message,
                }"
                mode="edit"
                right-toolbar="fullscreen"
                left-toolbar="undo redo clear | h bold italic | ul ol | link image | parameter"
                :toolbar="mdEditorToolbar"
                height="370px"
                tabindex="1"
                @input="setPreviewToChangedDebounced(index)"
                @change="injectTabIndexDebounced"
              />
              <ui-iframe
                v-if="currentPreviewState === stateEnum.loaded"
                class="crm-add-mail-action-popup__message-preview"
                :html="previewList[index]"
                height="370px"
              />
              <div
                v-else
                v-loading="true"
                class="ui-fg-1"
                style="width: 626px"
              />
            </div>
          </div>
          <!-- <div class="ui-d-flex ui-jc-space-between">
            <crm-action-params
              :disabled="preview"
              @select="handleAddParam($event, 'message')"
            />
          </div> -->
        </div>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          lib="fa"
          substyle="fas"
          icon="paper-plane"
          :disabled="isLoading || isActionsSendDisabled"
          @click="handleSendTestEmail()"
        >
          {{ $t('crm.buttons.send_test_email') }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="isLoading || !isValid"
          filled
          @click="handleSave()"
        >
          <i class="fas fa-check plus-icon-custom" />{{ $t('crm.buttons.save_action') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import VueMarkdownEditor from '@kangc/v-md-editor'; // left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image | parameter"
import enUS from '@kangc/v-md-editor/lib/lang/en-US';
import ruRU from '@/i18n/misc/vue-markdown-editor-ru.js';
import CrmActionParams from '@/views/CRM/components/CrmActionParams.vue';
import CrmActionLanguages from '@/views/CRM/components/CrmActionLanguages.vue';
import CrmActionLanguageTab from '@/views/CRM/components/CrmActionLanguageTab.vue';
import CrmErrorPanel from '@/views/CRM/components/CrmErrorPanel.vue';
import ActivitiesMailActionMixin from '@/views/CRM/mixins/activities-mail-action-mixin.js';
import UiIframe from '@/views/CRM/components/UiIframe.vue';

export default {
  name: 'AddMailActionPopup',
  components: {
    CrmActionParams,
    CrmActionLanguages,
    CrmActionLanguageTab,
    CrmErrorPanel,
    UiIframe,
  },
  mixins: [
    ActivitiesMailActionMixin,
  ],
  props: {
    templates: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      index: -1,
      actionGroup: null,
      actionGroups: [],
      actionGroupIndex: -1,
      preview: false,
      message: '',
      name: '',
      emailSubject: '',
      emailTemplateId: '',
      deboucedMessageHandlers: {},
      errors: {},
    };
  },

  computed: {
    activeTemplate() {
      return this.templates.find(({ id }) => id === this.emailTemplateId) || {};
    },
    ...mapGetters('crm', ['crmTemplateVariables']),
    curentErrors() {
      return this.errors[this.index]
        ? this.errors[this.index]
        : {
          ...this.getPreviewErrors,
        };
    },
    getPreviewErrors() {
      const result = {};
      if (this.currentPreviewState === this.stateEnum.error) {
        const markdownError = this.$_.get(this.previewList, [this.index, 'errors', 'markdown', 0, 'message'], null);
        if (markdownError) {
          result.message = markdownError;
        }
      }
      return result;
    },
    isValid() {
      return true;
    },
    defaultLanguage: {
      get() {
        if (this.actionGroupIndex >= 0) {
          const defaultAction = this.actions.find(action => action.is_default);
          if (defaultAction) {
            return defaultAction.language_code;
          }
        }

        return 'en';
      },
      set(value) {
        if (this.actionGroupIndex >= 0) {
          this.actions.forEach((action) => {
            this.$set(action, 'is_default', false);
          });
          const defaultAction = this.actions.find(action => action.language_code === value);
          if (defaultAction) {
            this.$set(defaultAction, 'is_default', true);
          }
        }
      },
    },
    defaultLanguageActionIndex() {
      return this.actions.findIndex(action => action.is_default);
    },
    messageParams() {
      const base = this.crmTemplateVariables || [];
      // name title
      return base.map(item => ({
        param: item.name,
        label: item.title,
      }));

      // return [
      //   {
      //     param: 'firstName',
      //     label: this.$t('crm.activities.actions.action_params.firstName'),
      //   },
      //   {
      //     param: 'lastName',
      //     label: this.$t('crm.activities.actions.action_params.lastName'),
      //   },
      //   {
      //     param: 'email',
      //     label: this.$t('crm.activities.actions.action_params.email'),
      //   },
      //   {
      //     param: 'phone',
      //     label: this.$t('crm.activities.actions.action_params.phone'),
      //   },
      // ];
    },
    mdEditorToolbar() {
      const menus = this.messageParams.map((item) => {
        const paramText = this.getParamText(item.param);
        return {
          name: item.param,
          text: () => item.label,
          action(editor) {
            editor.insert(() => ({
              text: paramText,
            }));
          },
        };
      });

      return {
        parameter: {
          icon: 'v-md-icon-parameter',
          title: this.$t('crm.activities.actions.action_popup.mail_add_param'),
          // text: '+',
          hasMenu: true,
          menus,
        },
      };
    },
    setPreviewToChangedDebounced() {
      return this.$_.debounce((index) => {
        if (this.previewList) {
          this.$set(this.previewList, index, null);
          this.loadPreview(index);
        }
      }, 1000);
    },
    injectTabIndexDebounced() {
      return this.$_.debounce(this.injectTabIndex, 300);
    },
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      async handler(value) {
        switch (value) {
        case 'ru':
          VueMarkdownEditor.lang.use('ru-RU', ruRU);
          break;
        case 'en':
        default:
          VueMarkdownEditor.lang.use('en-US', enUS);
        }

        await this.$store.dispatch('crm/getTemplateVariables');
      },
    },
    index: {
      immediate: true,
      handler(value) {
        if (value !== -1) {
          this.updateActionValue(value);
          if (this.$refs.editor) {
            this.$set(this.$refs.editor.$refs.editorEgine, 'historyStack', []);
          }
        }

        if (this.currentPreviewState !== this.stateEnum.not_avaliable) {
          this.previewList = this.previewList.map((item) => {
            if (item === this.stateEnum.error) {
              return null;
            }

            return item;
          });
        }
      },
    },
    currentPreviewStateWatcherParams: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value.currentPreviewState === this.stateEnum.not_loaded) {
          this.loadCurrentPreview();
        }
      },
    },
    emailTemplateId: {
      immediate: true,
      handler(value) {
        this.email_template_id = value;
        if (this.previewList) {
          this.previewList = [...new Array(this.previewList.length)];
        }
        this.loadCurrentPreview();
      },
    },
    message(value) {
      this.setActionsValue('message', value);
    },
    name(value) {
      this.setActionsValue('name', value);
    },
    emailSubject(value) {
      this.setActionsValue('email_subject', value);
    },
    isTemplateVariablesLoaded() {
      return !this.$_.isEmpty(this.crmMetrics);
    },
  },
  created() {},
  methods: {
    handleChangeTemplate(templateId) {
      this.$emit('change-template', {
        templateId,
        cb: (id) => {
          this.message = '';

          this.actions.forEach((_, i) => {
            this.$set(this.actions[i], 'message', '');
            this.$set(this.actions[i], 'email_template_id', id);
          });

          this.emailTemplateId = id;
        },
      });
    },
    getDebounceKey(languageCode, field) {
      return `${languageCode}_${field}`;
    },
    updateActionValue(index) {
      this.message = this.actions[index].message;
      this.name = this.actions[index].name;
      this.emailSubject = this.actions[index].email_subject;
    },
    setActionsValue(field, value) {
      if (this.index !== -1) {
        const languageCode = this.actions[this.index].language_code;
        const key = this.getDebounceKey(languageCode, field);

        if (!this.deboucedMessageHandlers[key]) {
          const callback = this.$_.debounce((_value) => {
            const index = this.actions.findIndex(item => item.language_code === languageCode);
            if (index !== -1) {
              this.$set(this.actions[index], field, _value);
            }
          }, 300);

          this.$set(this.deboucedMessageHandlers, key, callback);
        }

        this.deboucedMessageHandlers[key](value);
      }
    },
    open(actionGroup, payload = {}, templateId) {
      this.actionGroup = actionGroup;
      this.actionGroupIndex = this.activity.action_groups.indexOf(actionGroup);

      let actions = this.activity.action_groups[this.actionGroupIndex].actions || [];
      actions = actions.filter(item => item.type === 'email');

      if (!actions.length) {
        actions.push(this.getDefaultForm());
      }

      this.actions = this.$_.cloneDeep(actions);

      if (templateId) {
        this.actions.forEach((_, i) => {
          this.$set(this.actions[i], 'email_template_id', templateId);
        });
      }

      this.emailTemplateId = templateId || this.actions[0].email_template_id;

      this.index = 0;
      this.isOpen = true;
      this.deboucedMessageHandlers = {};

      this.errors = [];
      this.isOpen = true;
      this.previewList = [];

      if (payload) {
        if (payload.errors && payload.errors.email) {
          this.errors = this.actions.map((action, index) => payload.errors.email[index] || null);
        }
      }
    },
    close() {
      this.index = -1;
      this.isOpen = false;
      this.actionGroup = null;
      this.actionGroups = [];
      this.actionGroupIndex = -1;
      this.previewList = null;
      this.email_template_id = '';
      this.emailTemplateId = '';
    },
    handleSave() {
      this.$emit('save', this.actionGroup, this.actions, 'email');
      this.close();
    },
    getParamText(param) {
      return `{{ ${param} }}`;
    },
    handleAddParam(param, target) {
      this[target] = `${this[target] || ''}${this.getParamText(param)}`;
    },
    handeAddLanguage(languageCode) {
      const newAction = this.getDefaultForm();
      newAction.language_code = languageCode;
      newAction.is_default = false;
      newAction.email_template_id = this.emailTemplateId;

      this.actions.push(newAction);
    },
    handleMakeDefault(index) {
      const languageCode = this.languages[index].language_code;
      this.defaultLanguage = languageCode;
    },
    handleDelete(index) {
      this.actions.splice(index, 1);
      this.errors.splice(index, 1);
      this.previewList.splice(index, 1);

      this.index = this.defaultLanguageActionIndex;
      this.updateActionValue(this.index);
    },
    injectTabIndex() {
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          if (this.$_.has(this.$refs, 'editor.$refs.editorEgine.$refs.textarea')) {
            const { textarea } = this.$refs.editor.$refs.editorEgine.$refs;
            if (+textarea.getAttribute('tabindex') !== 1) {
              textarea.setAttribute('tabindex', 1);
            }
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.template-wrapper {
  width: calc(50% - 15px);
  min-width: 200px;
  padding: 8px;
  font-size: 14px;
  background-color: #F5F7FA;
  border-radius: 4px;
  margin-bottom: 16px;

  b {
    font-weight: 500;
  }

  .change-action {
    display: flex;
    align-items: center;
    cursor: pointer;

    .label {
      margin-left: 4px;
      color: var(--primary-color);
      font-size: 12px;
    }
  }

  .success {
    color: #20815E;
  }
}
</style>
