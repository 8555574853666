<template>
  <div class="live-lastBets">
    <div class="content">
      <div class="column left">
        <div class="head">
          <div class="title title__with-refresh">
            <div class="title-with-button">
              {{ $t('live.tables.last20bets') }}
              <div class="filters">
                <ui-filter
                  ref="filterLastBets"
                  v-model="last20bets.filters"
                  :use-filters="filterBetsItems"
                  class="btn ui-filter-new"
                  @input="getNewSportBets()"
                />
                <span
                  v-if="last20betsObj"
                  class="reset_link"
                  @click="resetFiltersOnLastBets"
                >{{ $t('ui.filters.inner.reset_filter') }}</span>
              </div>
            </div>
            <ui-currency-picker
              :value="last20bets.currencyCode || ''"
              :currencies="customerCurrencies"
              @input="changeBetsCurrency"
            />
          </div>
        </div>

        <div class="table-wrapper">
          <ui-table
            v-loading="last20bets.isDataLoading"
            :lazy-top="-42"
            :fields="last20betsFieldsFilter"
            :data="last20bets.data"
            :currency="last20bets.currencyCode"
            lazy-loading
            disable-sort
            is-static
            i18n-path="live.list"
            element-loading-custom-class="data-loader-spinner"
          />
        </div>
      </div>
      <div class="column right">
        <div class="head">
          <div class="title title__with-refresh">
            <div class="title-with-button">
              {{ $t('live.tables.last20deposits') }}
              <div class="filters">
                <ui-filter
                  ref="filterLastDeposits"
                  v-model="last20deposits.filters"
                  :use-filters="filterDepositsItems"
                  class="btn ui-filter-new"
                  @input="getNewDeposits()"
                />
                <span
                  v-if="last20depositsObj"
                  class="reset_link"
                  @click="resetFiltersOnLastDeposits"
                >{{ $t('ui.filters.inner.reset_filter') }}</span>
              </div>
            </div>
            <div class="ui-d-flex">
              <ui-currency-picker
                class="ui-m-md-r"
                :value="last20deposits.currencyCode || ''"
                :currencies="customerCurrencies"
                @input="changeDepositsCurrency"
              />
              <ui-button
                :disabled="dasabledRefresh"
                icon="sync-alt"
                color="green"
                lib="fa"
                class="btn"
                @click="handleRefresh"
              >
                {{ $t('reports.other.refresh') }}
              </ui-button>
            </div>
          </div>
        </div>
        <div class="table-wrapper">
          <ui-table
            v-loading="last20deposits.isDataLoading"
            :lazy-loading-delay="2000"
            :fields="last20depositsFieldsFilter"
            :data="last20deposits.data"
            :currency="last20deposits.currencyCode"
            lazy-loading
            disable-sort
            is-static
            i18n-path="live.list"
            element-loading-custom-class="data-loader-spinner"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import updateUrl from '@/service/updateUrl';
import detectPermissions from '@/service/detectPermissions';
import formatCurrency from '@/views/mixins/format-currency';

export default {
  name: 'LiveDashboard',
  mixins: [formatCurrency],
  data() {
    return {
      last20bets: {
        fields: [
          {
            name: 'events',
            align: 'left',
            headerAlign: 'left',
            format: 'event',
          },
          {
            name: 'events_count',
            align: 'left',
            headerAlign: 'left',
            width: '100',
            format: 'eventType',
          },
          {
            name: 'player_country_name',
            align: 'left',
            headerAlign: 'left',
            width: '100',
            permission: 'reports_group_by_countries',
          },
          {
            name: 'site_player_id',
            align: 'left',
            headerAlign: 'left',
            class: 'bold',
          },
          {
            name: 'bet_sum',
            align: 'right',
            headerAlign: 'left',
            width: '100',
            class: 'bold',
            format: 'formatMoney',
          },
          {
            name: 'original_bet_sum',
            align: 'right',
            headerAlign: 'left',
            width: '130',
            class: 'bold',
            format: 'formatOriginalMoney',
          },
          {
            name: 'coefficient',
            align: 'right',
            headerAlign: 'left',
            width: '80',
            class: 'bold',
          },
          {
            name: 'timestamp',
            align: 'left',
            headerAlign: 'left',
            width: '134',
            class: 'bold',
            format: 'date-time',
          },
        ],
        data: [],
        currencyCode: '',
        sortProp: '',
        sortOrder: '',
        isDataLoading: false,
        filters: {},
      },
      last20deposits: {
        fields: [
          {
            name: 'player_country_name',
            align: 'left',
            headerAlign: 'left',
            permission: 'reports_group_by_countries',
          },
          {
            name: 'site_player_id',
            align: 'left',
            headerAlign: 'left',
          },
          {
            name: 'deposit_sum',
            align: 'right',
            headerAlign: 'left',
            width: '100',
            format: 'formatMoney',
          },
          {
            name: 'original_deposit_sum',
            align: 'right',
            headerAlign: 'left',
            width: '130',
            format: 'formatOriginalMoney',
          },
          {
            name: 'payment_system_name',
            align: 'left',
            headerAlign: 'left',
            width: '100',
            class: 'method logo',
          },
          {
            name: 'timestamp',
            align: 'left',
            headerAlign: 'left',
            width: '134',
            class: 'bold',
            format: 'date-time',
          },
        ],
        data: [],
        currencyCode: '',
        sortProp: '',
        sortOrder: '',
        isDataLoading: false,
        filters: {},
      },
      refresh: true,
      dasabledRefresh: false,
      counterId: '',
      refreshInterval: 30000,
    };
  },

  computed: {
    ...mapGetters({
      customerCurrencies: 'misc/customerCurrencies',
    }),
    last20betsFieldsFilter() {
      return this.last20bets.fields
        .filter(item => ((this.$store.getters['auth/currentAcl'][item.permission] === 'allow' || !item.permission) && !this.$store.getters['auth/adminAcl'].is_superuser)
            || this.$store.getters['auth/adminAcl'].is_superuser);
    },
    last20depositsFieldsFilter() {
      return this.last20deposits.fields
        .filter(item => ((this.$store.getters['auth/currentAcl'][item.permission] === 'allow' || !item.permission) && !this.$store.getters['auth/adminAcl'].is_superuser)
            || this.$store.getters['auth/adminAcl'].is_superuser);
    },
    last20betsObj() {
      return Object.keys(this.last20bets.filters).length;
    },
    last20depositsObj() {
      return Object.keys(this.last20deposits.filters).length;
    },

    isAffiliatesLiveFilter() {
      if (!this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl']) {
        return this.$store.getters['auth/currentAcl'].reports_group_by_affiliates === 'allow';
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },

    isPlayerCountryFilter() {
      if (!this.$store.getters['auth/adminAcl'].is_superuser) {
        return this.$store.getters['auth/currentAcl'].reports_group_by_countries === 'allow';
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },

    isVisibleFilter() {
      return !(!this.isAffiliatesLiveFilter && !this.isPlayerCountryFilter);
    },

    filterBetsItems() {
      const items = ['brandLive'];
      if (this.isAffiliatesLiveFilter) {
        items.push('affiliateLastBets');
      }
      if (this.isPlayerCountryFilter) {
        items.push('playerCountryLastBets');
      }
      return items;
    },
    filterDepositsItems() {
      const items = ['brandLive'];
      if (this.isAffiliatesLiveFilter) {
        items.push('affiliateLastDeposits');
      }
      if (this.isPlayerCountryFilter) {
        items.push('playerCountryLastDeposits');
      }
      return items;
    },
  },
  watch: {
    refresh(val) {
      if (val) {
        this.autoRefreshOn();
      } else {
        this.autoRefreshOff();
      }
    },
    'last20bets.filters': {
      handler(nv) {
        this.$ls.set(
          'live/last20bets/filters',
          JSON.stringify(nv),
        );
      },
      deep: true,
    },
    'last20deposits.filters': {
      handler(nv) {
        this.$ls.set(
          'live/last20deposits/filters',
          JSON.stringify(nv),
        );
      },
      deep: true,
    },
  },

  created() {
    if (detectPermissions.checkRequest('last_bets_and_deposits_view')) {
      const _filterParams = updateUrl.getParseParams(this.$route.hash, ['deposit', 'bets', 'deposits_currency_code', 'bets_currency_code']);
      /*
        * проверяем hash на пустоту, и подставялем в фильтр
        * */

      if (!this.$_.isEmpty(_filterParams)) {
        this.last20bets.filters = this.$_.get(_filterParams, ['bets']) || {};
        this.last20bets.currencyCode = this.checkCurrencyCode(this.$_.get(_filterParams, 'bets_currency_code'));
        this.last20deposits.filters = this.$_.get(_filterParams, ['deposit']) || {};
        this.last20deposits.currencyCode = this.checkCurrencyCode(this.$_.get(_filterParams, 'deposits_currency_code'));
      } else {
        this.last20bets.filters = JSON.parse(this.$ls.get('live/last20bets/filters')) || {};
        this.last20bets.currencyCode = this.checkCurrencyCode(this.$ls.get('live/last20bets/currency_code'));
        this.last20deposits.filters = JSON.parse(this.$ls.get('live/last20deposits/filters')) || {};
        this.last20deposits.currencyCode = this.checkCurrencyCode(this.$ls.get('live/last20deposits/currency_code'));
      }
    }
    detectPermissions.checkRoute('last_bets_and_deposits_view');
  },
  mounted() {
    if (detectPermissions.checkRequest('last_bets_and_deposits_view')) {
      if (this.last20bets.filters && ((this.last20bets.filters.affiliate_last_bets_id && !this.isAffiliatesLiveFilter) || (this.last20bets.filters.player_country_code_last_bets && !this.isPlayerCountryFilter))) {
        this.resetFiltersOnLastBets();
      }
      if (this.last20deposits.filters && ((this.last20deposits.filters.affiliate_last_deposits_id && !this.isAffiliatesLiveFilter) || (this.last20deposits.filters.player_country_code_last_deposits && !this.isPlayerCountryFilter))) {
        this.resetFiltersOnLastDeposits();
      }
      this.getNewSportBets();
      this.getNewDeposits();
      this.autoRefreshOn();
    }
  },
  beforeDestroy() {
    this.autoRefreshOff();
  },
  methods: {
    changeBetsCurrency(value) {
      this.$ls.set('live/last20bets/currency_code', value);
      this.getNewSportBets(value);
    },
    changeDepositsCurrency(value) {
      this.$ls.set('live/last20deposits/currency_code', value);
      this.getNewDeposits(value);
    },
    resetFiltersOnLastBets() {
      this.$refs.filterLastBets.reset();
    },
    resetFiltersOnLastDeposits() {
      this.$refs.filterLastDeposits.reset();
    },
    autoRefreshOn() {
      const counterId = setInterval(() => {
        this.getNewSportBets();
        this.getNewDeposits();
      }, this.refreshInterval);
      this.counterId = counterId;
    },
    autoRefreshOff() {
      clearInterval(this.counterId);
    },
    handleRefresh() {
      this.getNewDeposits();
      this.getNewSportBets();
      this.dasabledRefresh = true;
      setTimeout(() => {
        this.dasabledRefresh = false;
      }, 8000);
    },
    async getNewSportBets(currencyCode) {
      this.last20bets.isDataLoading = true;

      if (currencyCode) {
        this.last20bets.currencyCode = currencyCode;
      }

      this._formCompletedParams();
      try {
        const param = {
          affiliate_id: this.last20bets.filters.affiliate_last_bets_id,
          player_country_code: this.last20bets.filters.player_country_code_last_bets,
          site_id: this.last20bets.filters.live_site_id,
          currency_code: this.last20bets.currencyCode,
        };
        const response = await this.$api.getNewSportBets(param);
        this.last20bets.data = this.$_.cloneDeep(response.data.payload);
        this.last20bets.currencyCode = response.data.misc.currency_code;
      } finally {
        this.last20bets.isDataLoading = false;
      }
    },
    async getNewDeposits(currencyCode) {
      this.last20deposits.isDataLoading = true;

      if (currencyCode) {
        this.last20deposits.currencyCode = currencyCode;
      }

      this._formCompletedParams();

      try {
        const param = {
          affiliate_id: this.last20deposits.filters.affiliate_last_deposits_id,
          player_country_code: this.last20deposits.filters.player_country_code_last_deposits,
          site_id: this.last20deposits.filters.live_site_id,
          currency_code: this.last20deposits.currencyCode,
        };
        const response = await this.$api.getNewDeposits(param);
        this.last20deposits.data = this.$_.cloneDeep(response.data.payload);
        this.last20deposits.currencyCode = response.data.misc.currency_code;
      } finally {
        this.last20deposits.isDataLoading = false;
      }
    },
    _formCompletedParams() {
      const _data = {
        deposit: this.last20deposits.filters,
        bets: this.last20bets.filters,
        deposits_currency_code: this.last20deposits.currencyCode,
        bets_currency_code: this.last20bets.currencyCode,
      };

      updateUrl.updateGetParams(_data);
    },
  },
};
</script>

<style lang="scss" scoped>
    .live .live-lastBets {
        padding-top: 16px;
    }
</style>
