export default (Vue) => {
  Vue.directive('scroll', {
    inserted(el, binding) {
      const f = function fsc(evt) {
        if (binding.value(evt, el)) {
          window.removeEventListener('scroll', f);
        }
      };
      window.addEventListener('scroll', f);
    },
  });
};
