<template>
  <div class="affiliate-sub-popup">
    <div
      class="popup"
      :class="`page-size-${pageSizePopup}`"
    >
      <div class="head">
        <span class="name">{{ $t(`affiliates.affiliateSub.pin_sub_aff_to`, { email: affiliateData.email }) }}</span>
        <ui-icon
          :size="18"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div class="wrap-control">
        <ui-input
          v-model.trim="searchQueryPopup"
          class="search-popup"
          is-search
          autosize
          @search="fetchDataPopup"
        />
        <ui-pagination
          :page="pagePopup"
          :page-size="pageSizePopup"
          :count="countPopup"
          show-size-select
          @page-change="pageChangePopup"
        />
      </div>
      <ui-table
        ref="tablePopup"
        v-loading="isDataLoading"
        :class="`page-size-${pageSizePopup}`"
        :select-row="true"
        :fields="fields"
        :data="affiliates"
        :rows-count="pageSizePopup"
        :offset-column="2"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        checked-row
        checked-row-id="id"
        i18n-path="affiliates.sublist"
        element-loading-custom-class="data-loader-spinner"
        class="table"
        is-static
        @checkedRows="checkedRows"
        @change-rows-count="handleTableRows"
        @sort="handleSort"
      />
      <div class="foot">
        <div class="field-error">
          {{ getError('hierarchy') || getError('referral_id') || getError('referred_by') }}
        </div>
        <div>
          <ui-button
            color="red"
            lib="fa"
            substyle="fas"
            icon="times"
            @click.native="close"
          >
            {{ $t(`team_popup.cancel_button`) }}
          </ui-button>
          <ui-button
            :color="$theme.mainColor"
            :disabled="selectAffiliates.length === 0"
            filled
            @click="save"
          >
            <i class="fas fa-link link-icon-custom" />
            {{ $t(`affiliates.affiliateSub.pin_sub_aff`) }}
          </ui-button>
        </div>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>

import app from '@/main';
import { resolvePageSize } from '@/service/pageSize';
import errorHandleMixin from '@/views/mixins/error-hadle';

const sizes = {
  1080: 20,
  1440: 20,
};

const viewName = 'affiliate/pin_sub/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'AffiliateSubPopup',
  mixins: [errorHandleMixin],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    affiliateData: {
      type: Object,
      default() {
        return {};
      },
    },

  },
  data() {
    return {
      selectAffiliates: [],
      popup: {
        title: '',
        msg: '',
        type: 'success',
        action: '',
        actionName: '',
        data: {},
      },
      pageSizePopup: pageSize,
      affiliates: [],
      pagePopup: 1,
      countPopup: 0,
      searchQueryPopup: '',
      onSelect: Function,
      sortProp: 'email',
      sortOrder: 'asc',
      renderTable: false,
      fields: [
        {
          name: 'name',
          sortable: false,
        },
        {
          name: 'email',
          sortable: 'custom',
        },
        {
          name: 'messenger_id',
          computedValueHtml(row) {
            return `<div class="messenger ${row.messenger || ''}">${row.messenger_id || ''}</div>`;
          },
          minWidth: '100',
          controlType: 'messenger',
        },
      ],
      allRowSelect: [],
      fetchDataPopup: Function,
      isDataLoading: false,
    };
  },
  computed: {
    offsetPopup() {
      return (this.pageSizePopup * this.pagePopup) - this.pageSizePopup;
    },
    queryPopup() {
      return {
        search: this.searchQueryPopup,
        limit: this.pageSizePopup,
        offset: this.offsetPopup,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
      };
    },
  },
  watch: {
    searchQueryPopup() {
      this.pagePopup = 1;
    },
    isOpen(v) {
      if (v) {
        this.errors = {};
        document.getElementById('app').classList.add('app-overflow');
        this.fetchDataPopup();
      } else {
        document.getElementById('app').classList.remove('app-overflow');
      }
    },
  },

  mounted() {
    this.fetchDataPopup = this.$_.debounce(this.fetchDataPopupD, 50);
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    });
  },
  methods: {
    checkedRows(id, ids) {
      this.selectAffiliates = ids;
    },
    colorIcon(icon) {
      switch (icon.messenger) {
      case 'telegram':
        return '#4aaae7';
      case 'viber':
        return '#765598';
      case 'skype':
        return '#3078d0';
      case 'twitter':
        return '#41abe1';
      case 'whatsapp':
        return '#19c258';
      case 'facebook':
        return '#4a67ad';
      case 'instagram':
        return '#fa7e1e';
      default:
        return '#000';
      }
    },

    fullName(row) {
      return `${row.first_name} ${row.last_name}`;
    },
    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.fetchDataPopup();
    },
    handleTableRows(rows) {
      this.pageSizePopup = rows;
      this.fetchDataPopup();
    },
    pageChangePopup(page, size) {
      if (this.pagePopup !== page || this.limit !== size) {
        this.pagePopup = page;
        this.pageSizePopup = size;
        this.fetchDataPopup();
      }
    },
    fetchDataPopupD() {
      this.isDataLoading = true;
      this.$api.getAffiliateMassAttach(this.affiliateData.id, this.queryPopup).then((res) => {
        this.countPopup = res.data.misc.count;
        this.affiliates = this.$_.cloneDeep(res.data.payload);
      }).catch(() => {
        this.isDataLoading = false;
      }).finally(() => {
        this.isDataLoading = false;
      });
    },

    close() {
      this.searchQueryPopup = '';
      this.selectAffiliates = [];
      app.$eventBus.$emit('clear-check');
      this.$emit('close');
    },
    save() {
      const refIds = [];
      const selectAffiliatesObg = this.$_.filter(this.affiliates, item => this.selectAffiliates.includes(item.id));
      selectAffiliatesObg.forEach((field) => {
        refIds.push(field.referral_id);
      });
      this.$api
        .updateAffiliateReferralMass(this.affiliateData.id, refIds)
        .then(() => {
          this.$emit('refresh');
          app.$eventBus.$emit('clear-check');
          this.close();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.affiliate-sub-popup {
  .foot {
    justify-content: space-between!important;
    align-items: center;
  }

  .field-error {
    margin-bottom: 0;
  }

  .table {
    /deep/ {
      > div {
        height: 100%;

        > div {
          height: 100%;
        }
      }
    }
  }
}
</style>
