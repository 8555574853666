<template>
  <i :class="fontClass" :style="iconStyle" class="ui-icon" />
</template>

<script>
export default {
  name: 'UiIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: [Number, String],
      default: 12,
    },
    color: {
      type: String,
      default: '#4c525c',
    },
    substyle: {
      type: String,
      default: 'fas',
    },
    lib: {
      type: String,
      default: 'icofont',
    },
  },
  computed: {
    fontClass() {
      const name = this.name.split(' ');
      return this.lib === 'icofont'
        ? `${this.name}-icon`
        : `${this.substyle} ${this.lib} ${name.map(icon => `fa-${icon}`).join(' ')} ui-icon__fa`;
    },

    iconStyle() {
      const size = this.$_.isFinite(this.size)
        ? `${this.size}px`
        : this.$_.toString(this.size);
      return {
        color: this.color || '#4c525c',
        fontSize: size,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/icons';

.ui-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  outline: none;
  line-height: 1;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  user-select: none;
  box-shadow: none;
  box-sizing: border-box;
  &__fa {
    font-family: 'Font Awesome 6 Pro' !important;
    &.fa-6 {
      font-family: 'Font Awesome 6 Pro' !important;
    }
  }
  &.settings-icon {
    &:before {
      content: $settings-icon;
    }
  }
  &.user-icon {
    &:before {
      content: $user-icon;
    }
  }
  &.arrow-down-icon {
    &:before {
      content: $arrow-down-icon;
    }
  }
  &.download-icon {
    &:before {
      content: $download-icon;
    }
  }
  &.tick-icon {
    &:before {
      content: $tick-icon;
    }
  }
  &.arrow-right-icon {
    &:before {
      content: $arrow-right-icon;
    }
  }
  &.password-icon {
    &:before {
      content: $password-icon;
    }
  }
  &.refresh-icon {
    &:before {
      content: $refresh-icon;
    }
  }
  &.login-icon {
    &:before {
      content: $login-icon;
    }
  }
  &.notification-icon {
    &:before {
      content: $notification-icon;
    }
  }
  &.edit-icon {
    &:before {
      content: $edit-icon;
    }
  }
  &.filter-icon {
    &:before {
      content: $filter-icon;
    }
  }
  &.eye-icon {
    &:before {
      content: $eye-icon;
    }
  }
  &.hamburger-icon {
    &:before {
      content: $hamburger-icon;
    }
  }
  &.copy-icon {
    &:before {
      content: $copy-icon;
    }
  }
  &.link-icon {
    &:before {
      content: $link-icon;
    }
  }
  &.minus-icon {
    &:before {
      content: $minus-icon;
    }
  }
  &.plus-icon {
    &:before {
      content: $plus-icon;
    }
  }
  &.delete-icon {
    &:before {
      content: $delete-icon;
    }
  }
  &.history-icon {
    &:before {
      content: $history-icon;
    }
  }
  &.notes-icon {
    &:before {
      content: $notes-icon;
    }
  }
  &.phone-icon {
    &:before {
      content: $phone-icon;
    }
  }
  &.skype-icon {
    &:before {
      content: $skype-icon;
    }
  }
  &.telegram-icon {
    &:before {
      content: $telegram-icon;
    }
  }
  &.search-icon {
    &:before {
      content: $search-icon;
    }
  }
  &.ok-icon {
    &:before {
      content: $ok-icon;
    }
  }
  &.left-chevron-icon {
    &:before {
      content: $left-chevron-icon;
    }
  }
  &.left-chevron-double-icon {
    &:before {
      content: $left-chevron-double-icon;
    }
  }
  &.right-chevron-icon {
    &:before {
      content: $right-chevron-icon;
    }
  }
  &.right-chevron-double-icon {
    &:before {
      content: $right-chevron-double-icon;
    }
  }
  &.logout-icon {
    &:before {
      content: $logout-icon;
    }
  }
  &.loading-icon {
    animation: rotating 1.5s linear infinite;
    &:before {
      content: $loading-icon;
    }
  }
  &.info-icon {
    &:before {
      content: $info-icon;
    }
  }
  &.drilldown-icon {
    &:before {
      content: $drilldown-icon;
    }
  }
  &.star-outline-icon {
    &:before {
      content: $star-outline-icon;
    }
  }
  &.star-filled-icon {
    &:before {
      content: $star-filled-icon;
    }
  }
  &.percent-icon {
    // font-family: "Roboto" !important;
    // font-weight: bold;
    &:before {
      content: '%';
    }
  }
  &.usd-icon {
    // font-family: "Roboto" !important;
    // font-weight: bold;
    &:before {
      content: '$';
    }
  }
  &.caret-icon {
    &:before {
      content: $caret-icon;
    }
  }
  &.close-icon {
    &:before {
      content: $close-icon;
    }
  }
  &.handle-icon {
    &:before {
      content: $handle-icon;
    }
  }
  &.long-arrow-icon {
    &:before {
      content: $long-arrow-icon;
    }
  }
  &.whatsapp-icon {
    &:before {
      content: $whatsapp-icon;
    }
  }
  &.viber-icon {
    &:before {
      content: $viber-icon;
    }
  }
  &.twitter-icon {
    &:before {
      content: $twitter-icon;
    }
  }
  &.instagram-icon {
    &:before {
      content: $instagram-icon;
    }
  }
  &.facebook-icon {
    &:before {
      content: $facebook-icon;
    }
  }
}

@keyframes rotating {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
</style>
