<template>
  <iframe
    ref="iframe"
    :width="width"
    :height="height"
  />
</template>

<script>
export default {
  name: 'UiIframe',
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    html: {
      type: String,
      default: '',
    },
  },
  watch: {
    html: {
      immediate: true,
      handler(value) {
        const cb = (iframe, html) => {
          iframe.contentWindow.document.open();
          iframe.contentWindow.document.write(html);
          iframe.contentWindow.document.close();
        };

        if (this.$refs.iframe) {
          cb(this.$refs.iframe, value);
        } else {
          this.$nextTick(() => {
            if (this.$refs.iframe) {
              cb(this.$refs.iframe, value);
            } else {
              // console.log('something went wrong');
            }
          });
        }
      },
    },
  },
};
</script>
