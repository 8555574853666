export const reconstructionFilter = oldFilter => Object.keys(oldFilter).reduce((acc, key) => {
  acc[key] = {
    op: oldFilter[key].op,
    value: typeof oldFilter[key].row_id === 'object'
      ? oldFilter[key].row_id
      : [oldFilter[key].row_id],
  };
  return acc;
}, {});

export default { reconstructionFilter };
