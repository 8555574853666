<template>
  <div class="payments-dashboard">
    <div class="content">
      <div class="column left">
        <div class="head">
          <div class="title">
            {{ $t('payments.dashboard.title') }}
          </div>
          <div class="pagination ui-d-flex">
            <ui-currency-picker
              class="ui-m-md-r"
              :value="historyTable.currencyCode"
              :currencies="customerCurrencies"
              @input="changeHistoryCurrency"
            />
            <ui-pagination
              :page="historyTable.page"
              :page-size="historyTable.limit"
              :count="historyTable.count"
              @page-change="historyPageChange"
            />
          </div>
        </div>
        <div class="table-wrapper">
          <ui-table
            v-loading="historyTable.isDataLoading"
            :fields="historyTable.fields"
            :currency="historyTable.currencyCode"
            :data="historyTable.data"
            :total="historyTable.totals"
            :rows-count="historyTable.limit"
            class="max-h"
            lazy-loading
            show-total
            i18n-path="payments.dashboard"
            element-loading-custom-class="data-loader-spinner"
            disable-sort
            is-static
          />
        </div>
      </div>
      <div class="column right">
        <div class="head">
          <div class="title">
            {{ $t('payments.outsiders.title') }}
          </div>
          <div class="pagination ui-d-flex">
            <ui-currency-picker
              class="ui-m-md-r"
              :value="outsidersTable.currencyCode"
              :currencies="customerCurrencies"
              @input="changeOutsidersCurrency"
            />
            <ui-pagination
              :page="outsidersTable.page"
              :page-size="outsidersTable.limit"
              :count="outsidersTable.count"
              @page-change="outsidersPageChange"
            />
          </div>
        </div>
        <div class="table-wrapper">
          <ui-table
            v-loading="outsidersTable.isDataLoading"
            :lazy-top="-42"
            :currency="outsidersTable.currencyCode"
            :fields="outsidersTable.fields"
            :data="outsidersTable.data"
            :rows-count="outsidersTable.limit"
            lazy-loading
            i18n-path="payments.outsiders"
            element-loading-custom-class="data-loader-spinner"
            disable-sort
            is-static
          />
          <!-- @change-rows-count="handleOutsidersTableRows" -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import updateUrl from '@/service/updateUrl';
import { resolvePageSize } from '@/service/pageSize';
import detectPermissions from '@/service/detectPermissions';
import formatCurrency from '@/views/mixins/format-currency';

const sizes = {
  1080: 20,
  1440: 30,
};

const LS_KEY = 'Payments/Dashboard';
const viewName = `${LS_KEY}/limit`; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'PaymentsDashboard',
  mixins: [formatCurrency],
  data() {
    return {
      historyTable: {
        fields: [
          {
            name: 'date',
            align: 'left',
            headerAlign: 'left',
            class: 'link',
            format: 'format-date',
            link: this.dateClickHandler,
          },
          {
            name: 'affiliates_count',
            align: 'right',
            headerAlign: 'left',
            width: '100',
          },
          {
            name: 'payments_count',
            align: 'right',
            headerAlign: 'left',
            width: '100',
          },
          {
            name: 'paid_amount',
            align: 'right',
            headerAlign: 'left',
            width: '100',
            class: 'bold',
            format: 'formatMoney',
          },
        ],
        data: [],
        totals: {},
        count: 0,
        currencyCode: '',
        page: 1,
        limit: pageSize,
        isDataLoading: false,
        query: '',
      },
      outsidersTable: {
        fields: [
          {
            name: 'affiliate_email',
            align: 'left',
            class: 'link',
            headerAlign: 'left',
            link: this.affiliateClickHandler,
          },
          {
            name: 'latest_payment_amount',
            align: 'right',
            headerAlign: 'left',
            width: '80',
            class: 'bold',
            format: 'formatMoney',
            computedClass: this.getCurrencyClass,
          },
          {
            name: 'latest_payment_at',
            align: 'left',
            headerAlign: 'left',
            width: '120',
            format: 'date-time',
          },
          {
            name: 'last_7_days_revenue',
            align: 'right',
            headerAlign: 'left',
            width: '100',
            format: 'formatMoney',
            computedClass: this.getCurrencyClass,
          },
          {
            name: 'last_14_days_revenue',
            tip: '14 Days',
            align: 'right',
            headerAlign: 'left',
            width: '100',
            format: 'formatMoney',
            computedClass: this.getCurrencyClass,
          },
          {
            name: 'last_30_days_revenue',
            align: 'right',
            headerAlign: 'left',
            width: '100',
            format: 'formatMoney',
            computedClass: this.getCurrencyClass,
          },
        ],
        data: [],
        totals: {},
        count: 0,
        currencyCode: '',
        page: 1,
        limit: pageSize,
        isDataLoading: false,
        query: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      customerCurrencies: 'misc/customerCurrencies',
    }),
  },
  watch: {
    'outsidersTable.currencyCode': {
      handler(val) {
        this.$ls.set(`${LS_KEY}/outsiders_currency_code`, val);
      },
    },
    'historyTable.currencyCode': {
      handler(val) {
        this.$ls.set(`${LS_KEY}/history_currency_code`, val);
      },
    },
  },
  mounted() {
    if (detectPermissions.checkRequest('payments_view')) {
      this.getSummaryReport();
      this.getOutsidersReport();
    }
  },
  created() {
    if (detectPermissions.checkRequest('payments_view')) {
      const _urlData = updateUrl.getParseParams(this.$route.hash);

      this.historyTable.limit = Number(this.$_.get(_urlData, ['tableHistory', 'limit'])) || pageSize;
      this.outsidersTable.limit = Number(this.$_.get(_urlData, ['tableOutsiders', 'limit'])) || pageSize;

      this.historyTable.page = Number(this.$_.get(_urlData, ['tableHistory', 'page'])) || 1;
      this.outsidersTable.page = Number(this.$_.get(_urlData, ['tableOutsiders', 'page'])) || 1;

      this.outsidersTable.currencyCode = this.checkCurrencyCode(this.$_.get(_urlData, ['tableOutsiders', 'currencyCode'], this.$ls.get(`${LS_KEY}/outsiders_currency_code`)));
      this.historyTable.currencyCode = this.checkCurrencyCode(this.$_.get(_urlData, ['tableHistory', 'currencyCode'], this.$ls.get(`${LS_KEY}/history_currency_code`)));
    }
    detectPermissions.checkRoute('payments_view');
  },
  methods: {
    changeHistoryCurrency(value) {
      this.getSummaryReport(value);
    },
    changeOutsidersCurrency(value) {
      this.getOutsidersReport(value);
    },
    handleHistoryTableRows(rows) {
      this.historyTable.limit = rows;
      this.getSummaryReport();
    },
    handleOutsidersTableRows(rows) {
      this.outsidersTable.limit = rows;
      this.getOutsidersReport();
    },
    getSummaryReport(currencyCode) {
      this.historyTable.isDataLoading = true;

      if (currencyCode) {
        this.historyTable.currencyCode = currencyCode;
      }

      this._completedFilterUrl();

      const q = {
        limit: this.historyTable.limit,
        offset: (this.historyTable.limit * this.historyTable.page) - this.historyTable.limit,
        currency_code: this.historyTable.currencyCode,
      };
      this.$api
        .getSummaryReport(q)
        .then((response) => {
          this.historyTable.count = response.data.misc.count;
          this.historyTable.currencyCode = response.data.misc.currency_code;
          this.historyTable.data = this.$_.cloneDeep(response.data.payload.data);
          this.historyTable.totals = this.$_.clone(response.data.payload.totals);
        })
        .finally(() => {
          this.historyTable.isDataLoading = false;
        });
    },
    getOutsidersReport(currencyCode) {
      this.outsidersTable.isDataLoading = true;

      if (currencyCode) {
        this.outsidersTable.currencyCode = currencyCode;
      }

      this._completedFilterUrl();

      const q = {
        limit: this.outsidersTable.limit,
        offset: (this.outsidersTable.limit * this.outsidersTable.page) - this.outsidersTable.limit,
        currency_code: this.outsidersTable.currencyCode,
      };
      this.$api
        .getOutsidersReport(q)
        .then((response) => {
          this.outsidersTable.count = response.data.misc.count;
          this.outsidersTable.currencyCode = response.data.misc.currency_code;
          this.outsidersTable.data = this.$_.cloneDeep(response.data.payload.data);
          this.outsidersTable.totals = this.$_.clone(response.data.payload.data.totals);
        })
        .finally(() => {
          this.outsidersTable.isDataLoading = false;
        });
    },
    historyPageChange(page) {
      this.historyTable.page = page;
      this.getSummaryReport();
    },
    outsidersPageChange(page) {
      this.outsidersTable.page = page;
      this.getOutsidersReport();
    },

    getCurrencyClass(value) {
      return value < 0 ? 'negative' : '';
    },
    affiliateClickHandler(item) {
      return `/affiliates/${item.affiliate_id}/info`;
    },
    dateClickHandler(item) {
      return `/payments/payout/paid?from=payment_dashboard&date=${item.date}`;
    },
    /*
    * Создаем filter URL
    * */
    _completedFilterUrl() {
      const _dataFilters = {
        tableHistory: {
          limit: this.historyTable.limit,
          page: this.historyTable.page,
          currencyCode: this.historyTable.currencyCode,
        },
        tableOutsiders: {
          limit: this.outsidersTable.limit,
          page: this.outsidersTable.page,
          currencyCode: this.outsidersTable.currencyCode,
        },
      };
      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>
