<template>
  <div class="live">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'Live',
  data() {
    return {};
  },
};
</script>
