<template>
  <div
    v-loading="loading"
    class="item-card"
  >
    <div
      v-if="pOptions.hasHead"
      class="item-card__head"
    >
      <slot name="head">
        <item-card-breadcrumbs
          v-if="breadcrumbs.length"
          :items="breadcrumbs"
        />

        <div class="item-card__head-title-wrapper">
          <slot name="title">
            <div
              v-if="titleId"
              class="item-card__head-title-id"
            >
              {{ titleId }}
            </div>
            <div class="item-card__head-title">
              {{ title }}
            </div>
          </slot>
        </div>
      </slot>

      <slot name="head-append" />
    </div>

    <div
      v-if="pOptions.hasToolbar"
      class="item-card__toolbar"
    >
      <slot name="toolbar" />
    </div>

    <div class="item-card__content">
      <slot />
    </div>
  </div>
</template>

<script>
import ItemCardBreadcrumbs from '@/components/ItemCard/ItemCardBreadcrumbs.vue';

export const defaultItemCardOptions = {
  hasHead: true,
  hasToolbar: true,
};

export default {
  name: 'ItemCard',
  components: {
    ItemCardBreadcrumbs,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    titleId: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    pOptions() {
      return {
        ...defaultItemCardOptions,
        ...this.options,
      };
    },
  },
};
</script>

<style lang="scss">
  .item-card {
    width: 90%;
    min-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    &__head {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #eaeaea;//$border-color
      padding-bottom: 16px;
    }

    &__head-title {
      font-size: 24px;
      font-weight: 500;
      margin-right: 8px;
      line-height: 28px;
    }

    &__toolbar {
      padding-bottom: 12px;
      margin-top: 12px;
      margin-bottom: 16px;
      border-bottom: 1px solid #eaeaea;//$border-color
    }

    &__content {
      display: flex;
      flex-grow: 1;
    }

    &__content-block {}

    &__content-column {
      display: flex;
      flex-direction: column;
      width: 275px;
      min-width: 275px;
    }

    &__content-center {
      flex-grow: 1;
      width: calc(50% - 23px);
    }
  }
</style>
