<template>
  <div
    v-if="isOpen"
    v-show="isRedraw"
    class="add-blacklist-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ id ? $t('mediaBuying.blacklist.popup.titleEdit') : $t('mediaBuying.blacklist.popup.title') }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <adm-ui-scrollbar
        wrap-style="max-height: 75vh; height: auto;"
        :native="false"
      >
        <div
          v-loading="isLoading"
          class="body"
        >
          <div class="ui-fg-1">
            <div class="row ui-m-xl-b">
              <div class="field">
                <span class="label-text">{{ $t('mediaBuying.blacklist.popup.paymentMethod') }}</span>
                <el-select
                  v-model="data.media_buying_payment_method_id"
                  :placeholder="$t('mediaBuying.blacklist.popup.placeholderPaymentMethod')"
                  class="select"
                  filterable
                  size="small"
                  :class="{ error: getError(['blacklist', 0, 'media_buying_payment_method_id']) || getError('media_buying_payment_method_id') }"
                >
                  <el-option
                    v-for="item in paymentList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
                <div
                  v-if="getError(['blacklist', 0, 'media_buying_payment_method_id']) || getError('media_buying_payment_method_id')"
                  class="field-error"
                >
                  {{ getError(['blacklist', 0, 'media_buying_payment_method_id']) || getError('media_buying_payment_method_id') }}
                </div>
              </div>
            </div>
            <ui-input
              v-model="data.payment_wallet"
              class="form-input ui-m-xl-b"
              :label="$t('mediaBuying.blacklist.popup.address')"
              autosize
              size="big"
              :placeholder="paymentWalletPlaceholder"
              type="text"
              :error="getError(['blacklist', 0, 'payment_wallet']) || getError('payment_wallet')"
            />
          </div>
        </div>
      </adm-ui-scrollbar>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="isLoading"
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          @click="handleSave()"
        >
          {{ $t('crm.buttons.save') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import errorHandleMixin from '@/views/mixins/error-hadle';

export default {
  name: 'BlacklistPopup',
  components: {},
  mixins: [errorHandleMixin],
  data() {
    return {
      isOpen: false,
      isLoading: true,
      isRedraw: false,
      paymentList: [],
      id: null,
      data: {
        ...this.getDefaultForm(),
      },
    };
  },

  computed: {
    paymentWalletPlaceholder() {
      return this.paymentList.find(el => el.id === this.data.media_buying_payment_method_id)?.wallet_placeholder || this.$t('mediaBuying.blacklist.popup.placeholderAddress');
    },
  },
  created() {},
  mounted() {},
  methods: {
    open({ media_buying_payment_method_id, payment_wallet, id } = {}) {
      this.id = null;
      this.data = {
        ...this.getDefaultForm(),
      };

      this.$api.getMediaBuyingPaymentMethods()
        .then((res) => {
          this.paymentList = res.data.payload;
          this.isLoading = false;
        });

      this.isRedraw = false;
      this.isOpen = true;

      this.$nextTick(() => {
        setTimeout(() => {
          this.isRedraw = true;
        }, 100);
      });

      this.data = this.getDefaultForm();
      this.errors = {};

      if (id) {
        this.id = id;
        this.data.media_buying_payment_method_id = media_buying_payment_method_id;
        this.data.payment_wallet = payment_wallet;
      }
    },
    close() {
      this.isOpen = false;
      this.isLoading = true;
    },
    handleSave() {
      const blacklist = {
        media_buying_payment_method_id: this.data.media_buying_payment_method_id,
        payment_wallet: this.data.payment_wallet.trim(),
      };

      const promise = this.id
        ? this.$api.putMediaBuyingBlacklist(this.id, blacklist)
        : this.$api.postMediaBuyingBlacklist({ blacklist: [blacklist] });

      promise.then(() => {
        this.close();
        this.$emit('save');
      })
        .catch((e) => {
          this.errors = e.data.errors;
        });
    },
    getDefaultForm() {
      return {
        payment_wallet: '',
        media_buying_payment_method_id: null,
      };
    },
  },
};
</script>

<style lang='scss' scoped>
.add-blacklist-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .field-error {
    align-self: flex-end;
    text-align: right;
    text-transform: uppercase;
    margin-top: 6px;
    margin-bottom: 0!important;
    font-weight: 500;
  }

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
    width: 100%;
  }

  .popup {
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 8vh;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;

    .head {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;

      .name {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
      }

      .close {
        font-size: 15px;
        cursor: pointer;

        &:hover {
          color: var(--main-color) !important;
        }
      }
    }

    .body {
      padding: 16px 24px;
      display: flex;
      position: relative;
      max-height: 84vh;
      //min-height: 215px;

      .field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .select {
          width: 100%;

          &.error {
            /deep/ {
              .el-input__inner {
                border-color: var(--danger-color);
              }
            }
          }
        }

        .label-text {
          font-size: 14px;
          color: var(--crm-panel-text);
          margin-bottom: 5px;
          display: block;
        }
      }


      .content-header {
        margin-bottom: 16px;
        display: flex;
        align-items: flex-start;
      }

      .card__title {
        .card-content {
          .label {
            font-size: 24px;
            font-weight: 500;
            line-height: 1.25;
          }
        }
      }
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      .ui-button + .ui-button{
        margin-left: 8px;
      }
      .plus-icon-custom{
        margin-right: 4px;
      }
    }
  }
}
</style>
