export default {
  props: {
    propsErrors: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      errors: {},
    };
  },
  methods: {
    getError(field, index = 0) {
      const fieldPath = Array.isArray(field) ? field : field.split('.');
      return this.$_.get(this.errors, [...fieldPath, index, 'message'], '') || this.$_.get(this.propsErrors, [...fieldPath, index, 'message'], '');
    },
  },
};
