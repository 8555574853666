<template>
  <two-factor-popup
    ref="twoFactorPopup"
    :continue-action="continueAction"
    :continue-action-params="continueActionParams"
  />
</template>

<script>
import TwoFactorPopup from '@/components/Popups/TwoFactorPopup.vue';

export default {
  name: 'SensitiveCheck',

  components: {
    TwoFactorPopup,
  },

  data() {
    return {
      continueAction: '',
      continueActionParams: {},
    };
  },

  created() {
    this.$eventBus.$on('checkSensitiveAction', this.checkSensitiveAction);
  },

  beforeDestroy() {
    this.$eventBus.$off('checkSensitiveAction', this.checkSensitiveAction);
  },

  methods: {
    async checkSensitiveAction(action, row) {
      await this.$store.dispatch('session/checkSession');

      const lockStatus = this.$store.getters['session/significantActionsSeconds'] === 0 ? 'lock' : 'unlock';
      if (lockStatus !== 'lock') {
        this.$eventBus.$emit(action, row);
      } else {
        this.continueAction = action;
        this.continueActionParams = row;
        this.openTwoFactorPopup();
      }
    },

    openTwoFactorPopup() {
      this.$refs.twoFactorPopup.open();
    },
  },
};

</script>
