export default {
  tools: {
    name: 'Tools',
    menu: {
      promoChecker: 'Bets Promo Checker',
    },
    promoChecker: {
      players: {
        table: {
          site_player_id: 'Player ID',
          promo_codes_used_count: 'Used Promo codes',
          win_count: 'Wins Count',
          lost_count: 'Lost Count',
          win_sum: 'Wins Sum',
        },
      },
      input_promocode: 'Input Promo Codes',
      form_promocode: 'Promo Codes From',
      period: 'Period',
      list: 'List',
      unload_promo: 'Upload Promo Codes',
      download: 'Download Unused',
      last_180: 'Data for the last 180 days',
      promo_codes: 'Promo Codes',
      used: 'Used',
      not_used: 'Not Used',
      players1: 'Players',
      win: 'Winners',
      player_sub: 'old / new / total',
      depos: '1st Deposits',
      depos_sub: 'amount / summ',
      avg_depos: 'Avg. Deposit',
      caff: 'Сoefficient',
      avg_max: 'avg / max',
      event: 'Events',
      win_sum: 'Win Sum',
      total: 'Total',
      looser: 'Loosers',
      edit: 'Edit',
      sec: 'Seconds',
      min: 'Minutes',
      h: 'Hours',
      day: 'Days',
      week: 'Weeks',
      reset: 'Reset',
      apply: 'Apply',
    },
    confirm: {
      title: 'Upload Promo Codes',
      action: 'Add Promo Codes',
      msg: 'Promo Codes',

    },
    landingLeagues: {
      leagues: {
        fields: {
          name: {
            title: 'League',
          },
          matches_count: {
            title: 'Future Matches',
          },
          last_starts_at: {
            title: 'Latest Match',
          },
        },
        land_leagues: 'Landing Leagues',
        add_match: 'Add Match',
        no_match: 'No matches',
        edit: 'Edit',
        delete: 'Delete',
        preview: 'Preview',
        Add: 'Add',
        New: 'New',
        Edit: 'Edit',

      },
      matches: {
        fields: {
          starts_at: {
            title: 'Date',
          },
          match: {
            title: 'Match',
          },
        },
      },
      confirm: {
        title: 'Delete match',
        msg: 'Are u sure want to delete match',
      },
    },
    match_popup: {
      league: 'League',
      select_league: 'Select League',
      date_time: 'Date and Time',
      placeholder_date_time: 'Select date and time',
      timezone: 'Timezone',
      placeholder_timezone: 'Select Timezone',
      team: 'Team',
      select_team: 'Select Team',
      get_odds: 'Get Odds Automatically',
      link_btw: 'Link on Betting',
      odd: 'Odd',
      input_odd: 'Input Odd',
      cancel: 'Cancel',
      match: 'Match',
      save: 'Save',

    },
  },
};
