<template>
  <div class="dashboard">
    Dashboard
  </div>
</template>

<script>

export default {

};
</script>
