import app from '@/main';

const checkColunmWidth = function checkColunmWidth(fields, keyLocal, tableFieldsExMethods) {
  const localFiels = app.$ls.get(keyLocal);

  if (localFiels) {
    const mergeField = fields.map((elem) => {
      const indexLocalFields = localFiels.findIndex(e => e.name === elem.name) || {};
      return { ...elem, ...localFiels[indexLocalFields] };
    });
    return mergeField;
  }
  app.$ls.remove(keyLocal);
  app.$ls.set(keyLocal, tableFieldsExMethods);
  return undefined;
};

const setColumnWidth = function setColumnWidth(column, fields, keyLocal, deltaWidth, CUSTOM) {
  const { property, width, minWidth/* , realWidth  */ } = column;

  if (property === undefined) return undefined;
  let findIndexColumnFlex = 0;
  if (deltaWidth > 0) {
    findIndexColumnFlex = fields.findIndex(item => item.flexible && item.name !== property);

    if (findIndexColumnFlex === -1) {
      findIndexColumnFlex = fields.findIndex(item => item.flexible);
    }
    if (findIndexColumnFlex === -1 && CUSTOM === 'customReport') {
      findIndexColumnFlex = 0/* fields.findIndex(item => item.flexible) */;
    }
  }


  let findIndexColumn = 0;
  const changeObj = fields.find((item, index) => {
    findIndexColumn = index;
    return property === item.name;
  });

  changeObj.width = width <= minWidth ? minWidth : width;

  const localFiels = app.$ls.get(keyLocal);
  localFiels[findIndexColumn] = {
    name: property,
    width: changeObj.width,
    forceUpdateKey: CUSTOM === 'customReport' ? +new Date() : undefined,
  };

  if (deltaWidth > 0) delete localFiels[findIndexColumnFlex].width;

  app.$ls.set(keyLocal, localFiels);
  return {
    newData: [findIndexColumn, changeObj],
    // setMinWidth,
  };
};

const getDeltaWidth = function getDeltaWidth(refDataTable) {
  const { offsetWidth: widthContainer } = refDataTable.$el.querySelector('.el-table > .el-table__header-wrapper');
  const columnsCollection = refDataTable.$refs.dataTable.$refs.tableHeader.columns;

  const columnsCollectionWidth = columnsCollection.reduce((acc, e) => {
    if (e.realWidth) {
      return acc + e.realWidth;
    }
    return acc + e.width;
  }, 0);
  const deltaWidth = widthContainer - columnsCollectionWidth;
  return deltaWidth;
};


export {
  checkColunmWidth,
  setColumnWidth,
  getDeltaWidth,
};
