<template>
  <div class="ui-filter popover-filter">
    <ui-button
      ref="mainBtn"
      :filled="!!filtersOpen || (!$_.isEmpty($_.omit(currentFilters, 'activity')) || !!currentFilters.activity[1])"
      icon="filter"
      lib="fa"
      substyle="fas"
      color="green"
      class="main-btn"
      @click="toggleFilters"
    >
      {{ $t('ui.filters.inner.filter') }}
    </ui-button>
    <transition name="fade">
      <div
        v-show="filtersOpen"
        ref="pop"
        class="pop"
      >
        <div class="arrow" />
        <div v-if="isAffiliatesFilter" class="filter-row">
          <span class="label">{{ $t('ui.filters.affiliate') }}</span>
          <ui-select
            v-model="currentFilters.affiliate_id"
            :placeholder="$t('ui.filters.select.all')"
            :options="affiliateOptions"
            :multiple="true"
            :filterable="true"
            :remote="true"
            value-prop="id"
            label-prop="email"
            class="filter_type"
            :filter-method="getAffiliates"
            @input="$set(currentFilters, 'affiliate_id', $_.cloneDeep($event))"
          />
        </div>
        <div v-if="isPlayerCountryFilter" class="filter-row">
          <span class="label">{{ $t('ui.filters.playerCountryNameFilter') }}</span>
          <ui-select
            v-model="currentFilters.player_country_code"
            :placeholder="$t('ui.filters.select.all')"
            :options="countries"
            :multiple="true"
            :filterable="true"
            :remote="false"
            value-prop="code"
            label-prop="name"
            class="filter_type"
            @input="$set(currentFilters, 'player_country_code', $_.cloneDeep($event))"
          />
        </div>
        <div v-if="isAffiliatesFilter" class="filter-row">
          <span class="label">{{ $t('ui.filters.pinnedAffiliate') }}</span>
          <el-radio-group
            v-model="currentFilters.belongs_to_affiliate"
            class="manual-radio"
          >
            <el-radio>
              {{ $t('ui.filters.select.all') }}
            </el-radio>
            <el-radio :label="false">
              {{ $t('ui.filters.select.no') }}
            </el-radio>
            <el-radio :label="true">
              {{ $t('ui.filters.select.yes') }}
            </el-radio>
          </el-radio-group>
        </div>
        <div class="filter-row" :class="{ disabled: isDisabledGroup }">
          <span class="label">{{ $t('ui.filters.deposits') }}</span>
          <ui-select
            v-model="currentFilters.deposits"
            :placeholder="$t('ui.filters.select.all')"
            :options="depositOptions"
            value-prop="type"
            label-prop="name"
            class="filter_type"
          />
        </div>
        <div v-if="isContactFilter" class="filter-row" :class="{ disabled: isDisabledGroup }">
          <span class="label">{{ $t('ui.filters.contacts') }}</span>
          <el-radio-group
            v-model="currentFilters.contacts"
            class="manual-radio"
          >
            <el-radio>
              {{ $t('ui.filters.select.all') }}
            </el-radio>
            <el-radio label="only">
              {{ $t('ui.filters.select.only_content') }}
            </el-radio>
          </el-radio-group>
        </div>
        <div v-if="isLastActivityFilter" class="filter-row" :class="{ disabled: isDisabledGroup }">
          <span class="label">{{ $t('ui.filters.lastActivity') }}</span>
          <div class="f-day">
            <el-radio v-model="currentFilters.activity[1]">
              {{ $t('ui.filters.select.all') }}
            </el-radio>
            <div class="f-day__tabs">
              <div
                :class="{'f-day__tab--active': currentFilters.activity && currentFilters.activity[1] === 'from'}"
                class="f-day__tab"
                @click="$set(currentFilters.activity, 1, 'from')"
              >
                &lt;
              </div>
              <div
                :class="{'f-day__tab--active': currentFilters.activity && currentFilters.activity[1] === 'to'}"
                class="f-day__tab "
                @click="$set(currentFilters.activity, 1, 'to')"
              >
                &gt;
              </div>
            </div>
            <ui-input
              v-model="currentFilters.activity[0]"
              placeholder=""
              :disabled="!currentFilters.activity[1]"
              class="f-day__input"
            />
            <span class="f-day__text">{{ $t('ui.filters.inner.days') }}</span>
          </div>
        </div>
        <div class="controls">
          <ui-button
            color="red"
            lib="fa"
            substyle="fas"
            icon="times"
            class="btn"
            @click="resetFilters"
          >
            {{ $t('ui.table.reset') }}
          </ui-button>
          <ui-button
            filled
            :disabled="isDisabledGroup"
            lib="fa"
            substyle="fas"
            color="green"
            icon="check"
            class="btn"
            @click="applyFilters"
          >
            {{ $t('ui.table.apply') }}
          </ui-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PlayerFilter',
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      currentFilters: {
        activity: [0],
      },

      affiliateOptions: [],

      filtersOpen: false,
    };
  },
  computed: {
    isDisabledGroup() {
      return !this.currentFilters.affiliate_id?.length
        && !this.currentFilters.player_country_code?.length
        && !(this.currentFilters.hasOwnProperty('belongs_to_affiliate')
        && this.currentFilters.belongs_to_affiliate !== undefined);
    },
    depositOptions() {
      return [
        {
          name: this.$t('ui.filters.select.all'),
        },
        {
          type: 'without',
          name: this.$t('ui.filters.select.without'),
        },
        {
          type: 'first',
          name: this.$t('ui.filters.select.only_first_dep'),
        },
        {
          type: 'more',
          name: this.$t('ui.filters.select.more_one'),
        },
        {
          type: 'onlydep',
          name: this.$t('ui.filters.select.only_with_dep'),
        },
      ];
    },

    isAffiliatesFilter() {
      return this.$store.getters['auth/adminAcl'].is_superuser
        || (this.$store.getters['auth/currentAcl'].players_available_properties.includes('affiliate_email')
          && this.$store.getters['auth/currentAcl'].players_available_properties.includes('affiliate_id')
          && this.$store.getters['auth/currentAcl'].affiliates_view === 'allow');
    },
    isPlayerCountryFilter() {
      return this.$store.getters['auth/adminAcl'].is_superuser
        || (this.$store.getters['auth/currentAcl'].players_available_properties.includes('player_country_code')
          && this.$store.getters['auth/currentAcl'].players_available_properties.includes('player_country_name'));
    },
    isContactFilter() {
      return this.$store.getters['auth/adminAcl'].is_superuser
        || this.$store.getters['auth/currentAcl'].players_available_properties.includes('email')
        || this.$store.getters['auth/currentAcl'].players_available_properties.includes('phone_number');
    },
    isLastActivityFilter() {
      return this.$store.getters['auth/adminAcl'].is_superuser
        || this.$store.getters['auth/currentAcl'].players_available_properties.includes('last_active_at');
    },
    ...mapGetters('misc', ['countries']),
  },
  watch: {},
  destroyed() {
    document.removeEventListener('click', this.clickOutside, true);
  },
  created() {
  },
  mounted() {
    document.addEventListener('click', this.clickOutside, true);

    this.currentFilters = this.$_.cloneDeep({
      activity: [0],
      ...this.value,
    });
  },
  methods: {
    getAffiliates(search) {
      const query = {
        limit: 100,
        offset: 0,
        search,
        account_status: ['approved'],
      };
      return new Promise((resolve, reject) => {
        this.$api.getAffiliates(query)
          .then((response) => {
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    clickOutside(e) {
      if (this.$refs.mainBtn.$el.contains(e.target)) return;
      if (!this.$refs.pop.contains(e.target) && this.filtersOpen === true) {
        this.toggleFilters();
      }
    },
    toggleFilters() {
      this.filtersOpen = !this.filtersOpen;

      if (this.filtersOpen) {
        this.currentFilters = this.$_.cloneDeep({
          activity: [0],
          ...this.value,
        });
      }
      this.$emit('toggle', this.filtersOpen);
    },
    applyFilters() {
      this.$emit('input', this.currentFilters);
      this.toggleFilters();
    },
    resetFilters() {
      this.currentFilters = {
        activity: [0],
      };
      this.$emit('input', {});
      this.toggleFilters();
    },
    reset() {
      this.currentFilters = {
        activity: [0],
      };
      this.$emit('input', {});
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-filter{
  position: relative;
  line-height: 0;
  .f-day {
    width: 230px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 8px;

    /deep/ {
      .el-radio {
        margin-right: 16px!important;
      }
    }

    .sufix {
      color: #334444;
    }
    &__tabs {
      width: 70px;
      min-width: 70px;
      height: 34px;
      border-radius: 4px;
      display: flex;
      margin-right: 8px;
      .select-class{
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
    &__tab{
      height: 32px;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      background-color: #fff;
      cursor: pointer;
      &:first-child{
        border: solid 1px #d3d3d3;
        border-radius: 5px 0 0 5px;
      }
      &:last-child{
        border: solid 1px #d3d3d3;
        border-left-color: transparent;
        border-radius: 0 5px 5px 0;
      }
      &--active{
        cursor: pointer;
        color: #fff;
        &:first-child{
          border-right-color: transparent;
        }
        &:last-child{
          border-left-color: transparent;
        }
      }
    }
    &__input{
      width: 100% !important;
      max-width: 62px !important;
    }
    &__text{
      padding-left: 8px;
      font-size: 12px;
      color: #000000;
    }
  }
  .pop{
    position: absolute;
    width: 476px;
    z-index: 9999;
    top: 44px;
    left: -20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    user-select: none;
    .ui-select.filter_type.medium{
      width: 200px !important;
      .tag .value {
        max-width: 181px !important;
      }
    }
    &.fade-enter-active, &.fade-leave-active{
      transition: all .4s;
    }
    &.fade-enter, &.fade-leave-to{
      opacity: 0;
      transform: translateY(8px);
    }
    .arrow{
      position: absolute;
      top: -5px;
      left: 56px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
    }
    .filter-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 54px;
      border-bottom: 1px solid #eaeaea;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .label {
        font-size: 14px;
        font-weight: 500;
        color: #303634;
      }

      .manual-radio {
        /deep/ {
          .el-radio {
            margin-right: 0!important;

            .el-radio__label {
              color: #303634;
              font-size: 12px;
              font-weight: 400;
            }
          }

          .el-radio + .el-radio {
            margin-left: 16px!important;
          }

        }
      }


      .filter_type{
        width: 160px;
        margin-left: 8px !important;
      }
      .btn{
        margin-left: 0 !important;
      }
    }
    .controls{
      display: inline-flex;
      justify-content: flex-end;
      flex-wrap: nowrap;
      width: auto;
      margin-top: 16px;

      .btn{
        margin-left: 0 !important;
      }
      .btn + .btn {
        margin-left: 8px !important;
      }
    }
  }
}

</style>
