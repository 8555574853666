import CRM from '@/views/CRM.vue';
import Activities from '@/views/CRM/Activities.vue';
import Activity from '@/views/CRM/Activities/Activity.vue';

import Dashboard from '@/views/CRM/Dashboard.vue';
import QualityAssurance from '@/views/CRM/QualityAssurance.vue';
import Segments from '@/views/CRM/Segments.vue';

export default [
  {
    path: '/crm',
    name: 'crm',
    component: CRM,
    meta: {
      requiresAuth: true,
      view: 'crm',
    },
    children: [
      {
        path: 'dashboard',
        component: Dashboard,
        name: 'dashboard',
        meta: {
          view: 'dashboard',
        },
      },
      {
        path: 'activities/list',
        component: Activities,
        name: 'activities',
        meta: {
          view: 'activities',
        },
      },
      {
        path: 'activities/:id?',
        component: Activity,
        name: 'crm-activity',
        props: true,
      },
      {
        path: 'running',
        component: Activities,
        name: 'running',
        meta: {
          view: 'running',
        },
      },
      {
        path: 'quality-assurance',
        component: QualityAssurance,
        name: 'quality-assurance',
        meta: {
          view: 'quality_assurance',
        },
        children: [
          {
            path: 'activities',
            component: Activities,
            name: 'activities',
            meta: {
              view: 'quality-assurance-activities',
            },
          },
          {
            path: 'segments',
            component: Segments,
            name: 'segments',
            meta: {
              view: 'quality-assurance-segments',
            },
          },
        ],
      },
      {
        path: 'segments',
        component: Segments,
        name: 'segments',
        meta: {
          view: 'segments',
        },
      },
      {
        path: '',
        redirect: 'activities/list',
      },
      {
        path: '*',
        redirect: 'activities/list',
      },
    ],
  },
];
