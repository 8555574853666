<template>
  <div
    v-if="isOpen"
    class="crm-select-template"
  >
    <ui-confirm
      ref="confirm"
      :width="480"
      :action-name="$t('crm.buttons.change')"
      action-fa-icon="check"
      @save="confirmSelectAction"
    >
      <div slot="title">
        {{ $t('crm.activities.actions.mail_template.confirm_title') }}
      </div>
      <div class="body__wrap">
        <i class="success fas fa-exclamation-triangle" />
        <div class="body__msg">
          {{ $t('crm.activities.actions.mail_template.confirm_body') }}
        </div>
      </div>
    </ui-confirm>
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ $t('crm.activities.actions.action_popup.mail_template_title') }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div
        v-loading="isLoading"
        class="body"
      >
        <div class="ui-fg-1">
          <div class="template">
            <div v-for="(template) in templates" :key="template.id" class="item">
              <el-card
                class="card"
                :class="{
                  'active': template.id === templateId,
                }"
                :body-style="{ padding: '0px' }"
                shadow="never"
              >
                <img :src="template.preview_base64" class="image" :alt="template.name" @click="templateId = template.id">
                <div class="ui-d-flex ui-ai-center ui-jc-center" style="padding: 14px;">
                  <el-radio v-model="templateId" :label="template.id">
                    <span class="name">{{ template.name }}</span>
                  </el-radio>
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          lib="fa"
          substyle="fas"
          filled
          icon="check"
          :disabled="isLoading || !templateId"
          @click="handleSelectTemplate()"
        >
          {{ $t('crm.buttons.select_template') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import ActivitiesMailActionMixin from '@/views/CRM/mixins/activities-mail-action-mixin.js';

export default {
  name: 'TemplateMailActionPopup',
  components: {
  },
  mixins: [
    ActivitiesMailActionMixin,
  ],
  props: {
    templates: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      cb: {},
      isEdit: false,
      templateId: null,
      initTemplateId: null,
    };
  },

  computed: {
  },
  watch: {
  },
  created() {},
  mounted() {},
  methods: {
    open({ templateId = null, cb, isEdit = false } = {}) {
      this.initTemplateId = templateId;
      this.isEdit = isEdit;
      this.cb = cb;
      this.templateId = templateId;
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    confirmSelectAction() {
      this.cb(this.templateId);
      this.close();
    },
    handleSelectTemplate() {
      if (this.templateId && this.initTemplateId === this.templateId) {
        this.close();
      }

      if (this.isEdit) {
        this.$refs.confirm.open();
      } else {
        this.confirmSelectAction();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-select-template {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
    width: 100%;
  }

  .popup {
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 8vh;
    width: 1200px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;

    .head {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      .name {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
      }
      .close {
        font-size: 15px;
        cursor: pointer;
        &:hover {
          color: var(--main-color) !important;
        }
      }
    }

    .body {
      padding: 16px 24px;
      display: flex;
      position: relative;
      max-height: 80vh;
      min-height: 215px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      .ui-button + .ui-button{
        margin-left: 8px;
      }
      .plus-icon-custom{
        margin-right: 4px;
      }
    }
  }
}

.template {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;

  .item {
    flex: 0 1 calc(20% - 16px);
  }

  .image {
    width: 100%;
    height: 320px;
    display: block;
    cursor: pointer;
    border: 2px solid #D3D3D3;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .active {
    .image {
      border-color: var(--primary-color);
    }
  }

  .card {
    border: none;
  }

  .name {
    color: #333333;
    font-size: 16px;
    margin-left: 2px;
  }
}
</style>
