<template>
  <el-popover
    v-model="isPopoverVis"
    placement="bottom-end"
    trigger="manual"
    popper-class="boookmarks"
  >
    <div
      slot="reference"
      ref="bookmarkMain"
      :class="{'bookmark-title-disabled': bookmarks.length === 0 && searchBookmarkText.length === 0}"
      class="tabs-right-btn__bookmarks"
      @click="(isPopoverVis = !isPopoverVis) && (notFoundBookmark = false)"
    >
      <ui-icon
        name="star"
        size="13px"
        lib="fa"
        substyle="fas"
        class="tab-action"
      />
      <!-- <span>{{ $t('reports.other.bookmark') }}</span> -->
      <ui-icon
        :color="`#ffffff`"
        size="9px"
        lib="fa"
        substyle="fas"
        name="chevron-left"
        :class="{'tabs-right-btn__chevron--open': isPopoverVis}"
        class="tab-action tabs-right-btn__chevron"
      />
    </div>
    <div
      v-if="isPopoverVis"
      v-loading="isLoadingBookmark"
    >
      <div class="search-input-popover__wrap">
        <ui-input
          is-search
          class="search-input-popover"
          @search="searchBookmark"
        />
      </div>
      <div class="select-row-popover__wrapper-list">
        <div
          v-for="(item, key) in bookmarks"
          :key="item.key"
          class="select-row-popover"
          @mouseover.prevent.stop="showPopperEditDel(key)"
          @click="pushBookmark(key); closeAllBookmark()"
        >
          <span class="select-row-popover__title">{{ item.bookmarkName }}</span>
          <div
            v-if="bookmarkRenderPopover[key]"
            class="select-row-popover__wrap"
          >
            <el-popover
              ref="popoverref"
              v-model="isPopoverEdit[key]"
              placement="bottom-end"
              trigger="click"
              popper-class="boookmark-edit boookmark-edit-ls"
            >
              <span
                slot="reference"
                :class="{'select-row-popover__edit--active': isPopoverEdit[key]}"
                class="select-row-popover__edit"
                @click.stop="actionBookmarkName('Edit', item.bookmarkName)"
              >{{ $t('reports.other.edit') }}</span>
              <div ref="poplist">
                <ui-input
                  v-model="constBookmarkName"
                  placeholder
                  :label="$t(`reports.other.bookmark_edit`)"
                  class="boookmark-edit__input"
                />
                <div class="boookmark-edit__wrap-btn">
                  <ui-button
                    class="boookmark-edit__close"
                    color="red"
                    lib="fa"
                    substyle="fas"
                    icon="times"
                    @click="actionBookmarkName('Show')"
                  >
                    {{ $t('reports.other.close') }}
                  </ui-button>
                  <ui-button
                    class="boookmark-edit__success"
                    filled
                    lib="fa"
                    substyle="fas"
                    icon="check"
                    @click="saveEditBookmarkName(key); actionBookmarkName('Show')"
                  >
                    {{ $t('reports.other.save') }}
                  </ui-button>
                </div>
              </div>
            </el-popover>

            <el-popover
              ref="popoverdel"
              v-model="isPopoverDelete[key]"
              placement="bottom"
              popper-class="boookmark-delete"
            >
              <span
                slot="reference"
                :class="{'select-row-popover__delete--active': isPopoverDelete[key]}"
                class="select-row-popover__delete"
                @click.stop="actionBookmarkName('Delete')"
              >{{ $t('reports.other.delete') }}</span>
              <div
                class="boookmark-delete__wrap-btn"
                style="text-align: right; margin: 0"
              >
                <div
                  class
                  style="text-align: right; margin: 0"
                >
                  <ui-button
                    class="boookmark-delete__close"
                    color="red"
                    lib="fa"
                    substyle="fas"
                    icon="times"
                    @click="actionBookmarkName('Show')"
                  >
                    {{ $t('reports.other.close') }}
                  </ui-button>
                  <ui-button
                    class="boookmark-delete__success"
                    filled
                    color="red"
                    lib="fa"
                    substyle="fas"
                    icon="times"
                    @click="deleteBookmark(key); actionBookmarkName('Show')"
                  >
                    {{ $t('reports.other.delete') }}
                  </ui-button>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
      <div v-if="notFoundBookmark">
        <span class="notFoundBookmark">{{ $t('reports.other.not_found_bookmark') }}</span>
      </div>
    </div>
  </el-popover>
</template>

<script>
export default {
  name: 'Bookmark',
  props: {
    reports: {
      type: Array,
      required: true,
    },
    currentReport: {
      type: Number,
      required: true,
    },
    bookmarks: {
      type: Array,
      required: true,
    },
    isLoadingBookmark: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      searchBookmarkText: '',
      notFoundBookmark: false,
      isPopoverVis: false,
      isPopoverEdit: [],
      constBookmarkName: '',
      bookmarkRenderPopover: [],
      isPopoverDelete: [],
    };
  },

  watch: {
    isPopoverVis(v) {
      if (v) {
        document.addEventListener('click', this.eventStop, true);
        setTimeout(() => {
          document.querySelector('.select-row-popover__wrapper-list').addEventListener('scroll', () => {
            this.switchToFalse();
            if (this.$refs.popoverref) {
              this.$refs.popoverref.forEach((el, i) => {
                this.$refs.popoverref[i].showPopper = false;
              });
            }
            if (this.$refs.popoverdel) {
              this.$refs.popoverdel.forEach((el, i) => {
                this.$refs.popoverdel[i].showPopper = false;
              });
            }
          });
        });
      }
    },
  },
  destroyed() {
    document.removeEventListener('click', this.eventStop, true);
    this.$eventBus.$off('afterSearch');
  },

  mounted() {
    this.$eventBus.$on('afterSearch', this.afterSearch);
  },
  methods: {
    switchToFalse() {
      this.isPopoverEdit = this.isPopoverEdit.map(() => false);
      this.isPopoverDelete = this.isPopoverDelete.map(() => false);
    },
    actionBookmarkName(action, name) {
      if (action === 'Show') {
        this.$refs.popoverdel.forEach(e => e.showPopper = false);
        this.$refs.popoverref.forEach(e => e.showPopper = false);
      }
      if (action === 'Edit') {
        this.constBookmarkName = name;
        this.switchToFalse();
      }
      if (action === 'Delete') {
        this.switchToFalse();
      }
    },
    saveEditBookmarkName(index) {
      this.bookmarks[index].bookmarkName = this.constBookmarkName;
      this.$emit('update:bookmarks', this.bookmarks);
      this.$emit('saveBookmark');
    },
    eventStop(e) {
      if (this.$refs.bookmarkMain.contains(e.target)) return;
      if (this.$parent.$parent.$el.contains(e.target)) {
        this.isPopoverVis = false;
        this.switchToFalse();
      }
    },
    deleteBookmark(key) {
      const deleteBookmark = this.bookmarks;
      deleteBookmark.splice(key, 1);
      this.$emit('update:bookmarks', deleteBookmark);
      this.$emit('saveBookmark');
    },
    closeAllBookmark() {
      this.isPopoverVis = false;
      this.switchToFalse();
    },
    pushBookmark(v) {
      if (this.bookmarks[v].dateRange.length === 0) {
        const { dateRange } = this.reports[this.currentReport];
        this.$emit('createReport', { ...this.bookmarks[v], dateRange });
      } else {
        this.$emit('createReport', this.bookmarks[v]);
      }
    },
    showPopperEditDel(key) {
      this.bookmarkRenderPopover[key] = true;
      this.bookmarkRenderPopover = this.$_.cloneDeep(this.bookmarkRenderPopover);
    },
    searchBookmark(v) {
      this.searchBookmarkText = v || '';
      this.$eventBus.$emit('emitGetBookmarksSearch');
      this.isPopoverEdit.forEach((el, i) => {
        this.isPopoverEdit[i] = false;
      });
    },
    afterSearch() {
      const search = this.bookmarks.filter(e => e.bookmarkName.toLowerCase().indexOf(this.searchBookmarkText.toLowerCase()) !== -1);
      this.$emit('update:bookmarks', search);
      this.notFoundBookmark = search.length === 0;
    },
  },

};
</script>
