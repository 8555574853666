var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-confirm',{ref:"confirm",staticClass:"crm-activity-actions__confirm-send-test-email",attrs:{"width":480,"type":"success","action-name":_vm.$t('crm.buttons.confirm'),"action":"send_test_email","action-icon":"check","action-disabled":_vm.isConfirmSendTestDisabled || _vm.isLoading},on:{"save":_vm.handleConfirmSendTest}},[_c('div',{attrs:{"slot":"title"},domProps:{"innerHTML":_vm._s(_vm.$t('crm.activities.actions.send_test_email.title'))},slot:"title"}),_c('div',{staticClass:"body__wrap ui-m-xxxl-b"},[_c('i',{staticClass:"fas fa-exclamation-triangle success"}),(_vm.confirmSendTestActions)?_c('div',{staticClass:"crm-activity-actions__confirm-send-test-email-msg body__msg",domProps:{"innerHTML":_vm._s(_vm.$t('crm.activities.actions.send_test_email.msg', {
        mailName: _vm.confirmSendTestActions.defaultName
          ? _vm.confirmSendTestActions.defaultName
          : _vm.$t('crm.activities.actions.empty_name'),
        email: _vm.adminInfo.email,
      }))}}):_vm._e()]),_c('div',{staticClass:"select-block ui-m-xxxl-b"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('crm.activities.actions.send_test_email.emails_title')))]),_c('el-input',{class:{'error-textarea': _vm.emailsError},attrs:{"label":_vm.$t('crm.activities.actions.send_test_email.emails_placeholder'),"type":"textarea","rows":6,"tabindex":"1","placeholder":_vm.$t('crm.activities.actions.send_test_email.emails_placeholder')},model:{value:(_vm.emails),callback:function ($$v) {_vm.emails=$$v},expression:"emails"}}),_c('div',{staticClass:"ui-d-flex ui-ai-center ui-jc-end"},[(_vm.emailsError)?_c('crm-error-panel',{staticClass:"ui-g-md ui-ai-baseline"},[_vm._v(" "+_vm._s(_vm.emailsError)+" ")]):_vm._e()],1)],1),(_vm.confirmSendTestActions)?_c('div',{staticClass:"crm-activity-actions__confirm-send-test-email-checkbox-wrapper"},[_c('el-checkbox',{staticClass:"crm-activity-actions__confirm-send-test-email-checkbox",attrs:{"label":_vm.$t('crm.activities.actions.send_test_email.all_localizations'),"disabled":_vm.isConfirmSendTestSelectedAllDisabled},model:{value:(_vm.isConfirmSendTestSelectedAll),callback:function ($$v) {_vm.isConfirmSendTestSelectedAll=$$v},expression:"isConfirmSendTestSelectedAll"}}),_vm._l((_vm.confirmSendTestActions.actions),function(actionGroup,index){return _c('div',{key:index,class:[
        'ui-d-flex',
        'ui-g-w-md',
        'crm-activity-actions__confirm-send-test-email-checkbox',
      ]},[_c('el-checkbox',{staticClass:"ui-g-md",attrs:{"label":_vm.languageIndex[actionGroup.language_code].name,"disabled":_vm.isConfirmSendTestActionsDisabled[index]},model:{value:(_vm.confirmSendTestSelectedActions[index]),callback:function ($$v) {_vm.$set(_vm.confirmSendTestSelectedActions, index, $$v)},expression:"confirmSendTestSelectedActions[index]"}}),(_vm.isConfirmSendTestActionsDisabled[index])?_c('ActionIcon',{staticClass:"ui-g-md",attrs:{"tooltip":_vm.$t('crm.tooltips.empty_mail_message')}},[_c('div',[_c('ui-icon',{attrs:{"name":"exclamation-triangle","color":_vm.$theme.accentColor,"lib":"fa","size":12}})],1)]):_vm._e()],1)})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }