<template>
  <div
    class="ui-input-wrapper"
    :class="{
      'ui-input-wrapper_disabled': pDisabled,
    }"
  >
    <div class="ui-input-wrapper__prepend">
      <slot name="prepend" />
    </div>
    <slot />
    <div
      v-if="pPrependBorder"
      class="ui-input-wrapper__prepend-border"
    />
    <div class="ui-input-wrapper__append">
      <slot name="append" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiInputWrapper',
  props: {
    disabled: {
      type: [Boolean, String],
      default: false,
    },
    prependBorder: {
      type: [Boolean, String],
      default: true,
    },
  },
  computed: {
    pDisabled() {
      return this.disabled || this.disabled === '';
    },
    pPrependBorder() {
      return this.prependBorder || this.prependBorder === '';
    },
  },
};
</script>

<style lang="scss">
.ui-input-wrapper {
  position: relative;

  &_disabled &__prepend,
  &_disabled &__append {
    opacity: 0.3;
  }

  &__prepend,
  &__append {
    &:empty {
      display: none;
    }
  }

  &__prepend {
    position: absolute;
    z-index: 100;
    width: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }

  &__append {
    position: absolute;
    z-index: 100;
    width: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    right: 0;
  }

  &__prepend-border {
    position: absolute;
    left: 30px;
    z-index: 100;
    border-left: 1px solid var(--border-color);
    height: 100%;
    .focus + & {
      transition: all 0.3s ease 0s;
      border-color: var(--primary-color);
    }

    .error + & {
      transition: all 0.3s ease 0s;
      border-color: var(--danger-color);
    }

    .focus.error + & {
      transition: all 0.3s ease 0s;
      border-color: var(--primary-color);
    }
  }

  &__prepend:not(:empty) + .ui-input{
    width: 68px;
    .input-element {
      padding-left: 38px !important;
    }
  }
}
</style>
