<template>
  <div>
    <div
      :class="{'filter-tabs--disabled': disabled}"
      class="filter-tabs search-by-wrapper"
    >
      <!-- {{ isSearchingFilter }} -->
      <ui-button
        v-for="(item, key) in searchByList"
        :key="key"
        :filled="isSearchingFilter[key]"
        class="btn"
        :style="{order: item['order']}"
        lib="fa"
        substyle="fas"
        :icon="item.icon"
        :disabled="disabled"
        @click="openReportsFilterPopup(key)"
      >
        {{ $t(`reports.other.search_by.${key}`) }}
        <div
          v-if="isSearchingFilter[key]"
          class="clear-search fa fas fa-times"
          @click="disabled ? false : clearReportsSearchFilter(key, $event)"
        />
      </ui-button>
    </div>
    <reports-filter-popup
      ref="reportsFilterPopup"
      :is-open-popup="isOpenOtherPopup"
      @confirm="selectedSearchFilter"
      @close="closeOtherPopup"
    />
  </div>
</template>

<script>
import ReportsFilterPopup from '@/components/Popups/ReportsFilterPopup.vue';

export default {
  name: 'FilterTabs',
  components: {
    ReportsFilterPopup,
  },

  props: {
    filterList: {
      type: Object,
      required: true,
    },

    searchByList: {
      type: Object,
      required: true,
    },

    isSearchingFilter: {
      type: Object,
      required: true,
    },

    reportsSettings: {
      type: Object,
      required: true,
    },

    currentSearch: {
      type: Object,
      default() {
        return {};
      },
    },

    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data() {
    return {
      isOpenOtherPopup: false,
    };
  },

  methods: {
    openReportsFilterPopup(group) {
      this.isOpenOtherPopup = true;
      this.$refs.reportsFilterPopup.open(this.currentSearch, group);
    },

    clearReportsSearchFilter(group, event) {
      event.stopPropagation();
      this.$emit('deleteCurrentSearchGroup', group, event);
      // this.$delete(this.currentSearch, group);
    },

    closeOtherPopup() {
      this.isOpenOtherPopup = false;
    },

    selectedSearchFilter(search) {
      this.$emit('selectedSearchFilter', search);
    },
  },

};
</script>
