<template>
  <div class="sub-header">
    <div class="wrapper">
      <div class="sub-header__container">
        <slot name="prepend" />
        <div class="menu">
          <div
            v-for="item in menu"
            :key="item.name"
            :class="[
              { menu__item_active: isLinkActive(item.name) },
              { menu__item_sub: item.sections.length > 0 },
            ]"
            class="menu__item"
          >
            <router-link
              v-if="isInternalLink(item.link)"
              :title="item.name"
              class="menu__link"
              :to="item.link"
            >
              {{ item.name }}
            </router-link>
            <span v-else-if="item.link === undefined" class="menu__link">
              {{ item.name }}
            </span>
            <i
              v-if="item.sections.length > 0"
              class="menu__icon fas fa-chevron-down"
            />
            <div v-if="item.sections.length > 0" class="menu__pop">
              <adm-ui-scrollbar
                v-for="(section, index) in item.sections"
                :key="section.title + index"
                class="section"
                wrap-style="max-height: 400px;"
                :native="false"
              >
                <div v-if="section.title" class="section__title">
                  {{ section.title }}
                </div>
                <div
                  v-for="(slink, indexSlink) in section.subItems"
                  :key="slink.name + indexSlink"
                  class="section__item"
                >
                  <router-link
                    :title="slink.name"
                    class="section__link"
                    :to="slink.link"
                  >
                    {{ slink.name }}
                  </router-link>
                </div>
              </adm-ui-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    menu: {
      type: Array,
      required: true,
    },
    currentRoute: {
      type: String,
      required: true,
    },
  },

  methods: {
    isLinkActive(name) {
      return this.currentRoute === name;
    },
    isInternalLink(link) {
      if (link === undefined) return undefined;
      return link.indexOf('http') !== 0;
    },
  },
};
</script>

<style lang="scss">
  .sub-header{
    background-color: var(--report-dropdown-background);
    &__container{
      display: flex;
      align-items: center;
    }

    .el-scrollbar__wrap {
      padding-bottom: 15px;
      overflow-x: hidden;
    }

    .menu{
      display: flex;

      &__item{
        position: relative;
        display: inline-flex;
        flex: 0 0 auto;
        align-items: stretch;
        justify-content: center;
        cursor: pointer;
        padding: 0 8px;
        &_active,
        &:hover {
          box-shadow: inset 0 -2px 0 0 var(--header-text-border);
          background-color: rgba(0, 0, 0, 0.3);
        }

        &_sub:hover{
          .menu__pop{
            display: flex;
          }
        }
      }

      &__link{
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: var(--header-text);
        text-decoration: none;
        padding: 12px 0;
      }

      &__icon{
        font-size: 9px;
        display: flex;
        align-items: center;
        padding-left: 4px;
        color: var(--header-text);
      }

      &__pop{
        display: none;
        position: absolute;
        z-index: 9999;
        left: 0;
        flex-direction: column;
        box-sizing: border-box;
        min-width: 120px;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
        top: 100%;

        .section{
          border-bottom: 1px solid #4d997e;
          background-color: var(--back-link);
          &:last-child{
            border-bottom: none;
          }

          &__item{
            display: flex;
            align-items: center;
            background-color: var(--report-dropdown-background);
            color: #fff;

            &:hover{
              background-color: var(--report-dropdown-background-hover);
            }
          }
          &__link{
            font-size: 12px;
            text-decoration: none;
            white-space: nowrap;
            padding: 0 34px 0 8px;
            height: 30px;
            width: 100%;
            position: relative;
            color: #fff;

            display: block;
            max-width: 400px;
            text-overflow: ellipsis;
            overflow-x: hidden;
            line-height: 2.4;

            &.router-link-active{
              color: var(--report-dropdown-active);
            }
          }
        }
      }
    }
  }
</style>
