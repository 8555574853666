<template>
  <div class="affiliate-sub-popup affiliate-sub-popup-temporary">
    <div
      class="popup"
      :class="`page-size-${pageSizePopup}`"
    >
      <div class="head">
        <span
          v-if="affInfo === 'sub'"
          class="name"
        >{{ $t(`affiliates.affiliateSub.pin_sub_aff_to`, { email: affiliateData.email }) }}</span>
        <span
          v-else-if="affInfo === 'aff'"
          class="name"
        >{{ $t(`affiliates.affiliateSub.pin_to_aff`, { email: affiliateData.email }) }}</span>
        <ui-icon
          :size="19"
          class="close"
          lib="fa"
          substyle="fas"
          name="times"
          @click.native="close"
        />
      </div>
      <div class="wrap-control">
        <ui-input
          v-model.trim="searchQueryPopup"
          class="search-popup"
          is-search
          autosize
          @search="fetchDataPopup"
        />
        <ui-pagination
          :page="pagePopup"
          :page-size="pageSizePopup"
          :count="countPopup"
          show-size-select
          @page-change="pageChangePopup"
        />
      </div>
      <ui-table
        ref="tablePopup"
        v-loading="isDataLoading"
        :class="`page-size-${pageSizePopup}`"
        :rows-count="pageSizePopup"
        :fields="fields"
        :data="affiliates"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        lazy-loading
        is-static
        i18n-path="affiliates.sublist"
        element-loading-custom-class="data-loader-spinner"
        class="table"
        @sort="handleSort"
      >
        <template
          slot="append"
        >
          <el-table-column width="50">
            <template slot-scope="scope">
              <ActionIcon
                :tooltip="$t('affiliates.affiliatesInfo.pin')"
              >
                <div
                  class="action-ico"
                  @click="handleRowClick(scope.row)"
                >
                  <ui-icon
                    name="link"
                    :color="$theme.accentColor"
                    lib="fa"
                  />
                </div>
              </ActionIcon>
            </template>
          </el-table-column>
        </template>
      </ui-table>
      <div class="foot">
        <div class="field-error">
          {{ getError('hierarchy') || getError('referred_by') }}
        </div>
        <ui-button
          size="medium"
          color="red"
          class="btn"
          lib="fa"
          substyle="fas"
          icon="fas fa-times"
          @click="close"
        >
          {{ $t('affiliates.cardView.toolbar.btns.close') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>

import { resolvePageSize } from '@/service/pageSize';
import errorHandleMixin from '@/views/mixins/error-hadle';

const sizes = {
  1080: 20,
  1440: 20,
};

const viewName = 'affiliate/pin_sub/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'AffiliateSubPopup',
  mixins: [errorHandleMixin],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    affiliateData: {
      type: Object,
      default() {
        return {};
      },
    },
    affInfo: {
      type: String,
      default: 'sub',
    },

  },
  data() {
    return {
      viewName,
      popup: {
        title: '',
        msg: '',
        type: 'success',
        action: '',
        actionName: '',
        data: {},
      },
      affiliates: [],
      pagePopup: 1,
      pageSizePopup: pageSize,
      countPopup: 0,
      searchQueryPopup: '',
      onSelect: Function,
      filtersPopup: { referred_by: '' },
      sortProp: 'email',
      sortOrder: 'asc',
      renderTable: false,
      fields: [
        {
          name: 'email',
          sortable: 'custom',
        },
      ],
      pageRowSelect: [],
      allRowSelect: [],
      fetchDataPopup: Function,
      isDataLoading: false,
    };
  },
  computed: {
    offsetPopup() {
      return (this.pageSizePopup * this.pagePopup) - this.pageSizePopup;
    },
    queryPopup() {
      if (this.affInfo === 'sub') {
        return {
          search: this.searchQueryPopup,
          limit: this.pageSizePopup,
          offset: this.offsetPopup,
          sort_column: this.sortProp,
          sort_dir: this.sortOrder,
        };
      } if (this.affInfo === 'aff') {
        return {
          search: this.searchQueryPopup,
          limit: this.pageSizePopup,
          offset: this.offsetPopup,
          sort_column: this.sortProp,
          sort_dir: this.sortOrder,
        };
      }
      return {};
    },
  },
  watch: {
    searchQueryPopup() {
      this.pagePopup = 1;
    },
    isOpen(v) {
      if (v) {
        this.errors = {};
        document.getElementById('app').classList.add('app-overflow');
        this.pagePopup = 1;
        this.fetchDataPopup();
      } else {
        document.getElementById('app').classList.remove('app-overflow');
      }
    },
  },
  created() {
    this.pageSizePopup = +this.$ls.get('affiliate/pin_sub/limit') || pageSize;
    this.fetchDataPopup = this.$_.debounce(this.fetchDataPopupD, 0);
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    });
  },
  methods: {
    setError(errors) {
      this.errors = errors;
    },
    handleRowClick(row) {
      this.$emit('confirm', { affiliate: row, cb: this.setError });
    },
    colorIcon(icon) {
      switch (icon.messenger) {
      case 'telegram':
        return '#4aaae7';
      case 'viber':
        return '#765598';
      case 'skype':
        return '#3078d0';
      case 'twitter':
        return '#41abe1';
      case 'whatsapp':
        return '#19c258';
      case 'facebook':
        return '#4a67ad';
      case 'instagram':
        return '#fa7e1e';
      default:
        return '#000';
      }
    },

    fullName(row) {
      return `${row.first_name} ${row.last_name}`;
    },
    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.fetchDataPopup();
    },
    handleTableRows(rows) {
      this.customOffset = 2;
      this.pageSizePopup = rows;
      this.fetchDataPopup();
    },
    pageChangePopup(page, size) {
      if (this.pagePopup !== page || this.limit !== size) {
        this.pagePopup = page;
        this.pageSizePopup = size;
        this.fetchDataPopup();
      }
    },
    fetchDataPopupD() {
      this.isDataLoading = true;
      const promise = this.affInfo === 'sub'
        ? this.$api.getAffiliateMassAttach(this.affiliateData.id, this.queryPopup)
        : this.$api.getAffiliateReferralProgram(this.affiliateData.id, this.queryPopup);

      promise.then((res) => {
        this.countPopup = res.data.misc.count;
        this.affiliates = this.$_.cloneDeep(res.data.payload);
      }).finally(() => {
        this.isDataLoading = false;
      });
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.affiliate-sub-popup-temporary {
  .foot {
    justify-content: space-between!important;
    align-items: center;
  }

  .field-error {
    margin-bottom: 0;
  }

  .table {
    /deep/ {
      > div {
        height: 100%;

        > div {
          height: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.app-overflow {
  overflow: hidden;
  height: 100vh;
}
.affiliate-sub-popup {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;
  .el-table {
    height: inherit !important;
  }
  .ui-table.table {
    height: 100%;
    overflow-y: hidden;
  }
  // .ui-table.table.page-size-20 .el-table__body-wrapper{
  //   height: calc(31px * 20) !important;
  // }
  // .ui-table.table.page-size-15 .el-table__body-wrapper{
  //   height: calc(31px * 15) !important;
  // }
  .wrap-control {
    padding: 16px 24px;
    display: flex;
    align-items: center;

    .search {
      .input-wrapper {
        border: 1px solid #d3d3d3 !important;
        height: 30px !important;
        border-radius: 5px !important;
        margin-right: 16px;
      }
      .input-pre-icon {
        margin-left: 12px !important;
        color: #7d7d7d !important;
        font-size: 11px !important;
        font-weight: bold;
      }
      .input-post-icon {
        margin-right: 12px !important;
      }
      input {
        font-size: 12px !important;
        &::placeholder {
          font-size: 12px !important;
          color: #7d7d7d !important;
          opacity: 1;
        }
      }
    }
  }
  .size-select {
    display: none;
  }
  .input-error {
    align-self: flex-end;
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    font-style: italic;
    text-align: right;
    margin-top: 6px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .popup {
    position: relative;
    z-index: 902;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .ui-table {
      padding-bottom: 0 !important;
    }
    .head {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      .name {
        font-size: 20px;
        font-weight: 500;
      }
      .close {
        font-size: 15px;
        cursor: pointer;
      }
    }
    .form {
      .row {
        display: flex;
        width: 100%;
        & + .row {
          margin-top: 16px;
        }
        .field {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 10 0 302px;
          &.field3 {
            flex: 10 1 302px;
          }
          & + .field {
            margin-left: 16px;
          }
          .label {
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 8px;
          }
          .select {
            margin: 0;
            width: 100%;
            flex: 0 0 auto;
          }
          .dtpicker {
            margin: 0;
            width: 100% !important;
            flex: 0 0 auto;
          }
          .tip {
            font-size: 12px;
            margin-top: 6px;
            &.err {
              width: 100%;
              display: block;
              font-size: 12px;
              font-style: italic;
              text-align: right;
              text-transform: uppercase;
              font-weight: 700;
            }
          }
        }
      }
    }
    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      .btn-text {
        text-transform: capitalize;
      }
      .close-icon {
        font-size: 10px !important;
      }
    }
  }
  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
.affiliate-sub-popup-temporary {
  .popup {
    &.page-size-20 {
      max-height: 842px !important;
    }
    &.page-size-15 {
      max-height: 686px !important;
    }
    // height: initial !important;
  }
}
@media (max-height: 600px) {
  .affiliate-sub-popup {
    align-items: flex-start;
    .popup {
      top: 20px;
    }
  }
}
</style>

<style lang="scss">
.affiliate-sub-popup {
  .el-table__body-wrapper {
    .is-center {
      .row-value.actions {
        justify-content: center !important;
      }
    }
  }
  .select {
    .el-select__caret {
      line-height: 48px;
      &:before {
        line-height: 48px;
      }
    }
    .el-input__inner {
      height: 48px;
      line-height: 48px;
      border-color: #d3d3d3;
      border-width: 2px;
      border-radius: 8px;
      &::placeholder {
        font-size: 13px;
        color: #303634;
      }
    }
  }
  .dtpicker {
    .el-input__inner {
      height: 48px;
      line-height: 48px;
      border-color: #d3d3d3;
      border-width: 2px;
      border-radius: 8px;
      &::placeholder {
        font-size: 13px;
        color: #303634;
      }
    }
  }
}
</style>
