<template>
  <div class="wrapper">
    <ui-confirm
      ref="confirmBookmark"
      :width="480"
      :action="indexCurrent"
      :action-name="$t('reports.other.save')"
      :action-disabled="pickerDateRangeBookmark === null && checkPeriodBookmark"
      action-fa-icon="check"
      type="success"
      class="confirm-boookmark-edit"
      @save="saveTabBookmark"
    >
      <div
        slot="title"
        v-html="$t('reports.other.add_bookmark')"
      />
      <div class="body__wrap">
        <ui-input
          v-model="constTabBookmarkName"
          :label="$t('reports.other.placeholder_name')"
          :placeholder="$t('reports.other.placeholder_name')"
          class="boookmark-edit__input"
        />
        <div class="boookmark-edit__datepicker-wrap">
          <el-checkbox
            v-model="checkPeriodBookmark"
            class="checkbox-mini"
            :label="true"
          >
            {{ $t('reports.other.save_period') }}
          </el-checkbox>

          <el-date-picker
            ref="datepicker"
            v-model="pickerDateRangeBookmark"
            popper-class="picker-bookmark"
            :class="pickerDateRangeBookmark === null && checkPeriodBookmark ? 'picker-seporate-style' : ''"
            :disabled="!checkPeriodBookmark"
            :picker-options="{
              firstDayOfWeek: 1,
              shortcuts: shortcutsDates,
            }"
            :start-placeholder="$t('reports.other.start_date')"
            :end-placeholder="$t('reports.other.end_date')"
            name="range_picker"
            type="daterange"
            range-separator="-"
            format="dd MMM yyyy"
            class="date-picker"
            @change="selectDateRangeBookmark"
          />
        </div>
      </div>
    </ui-confirm>
    <ui-confirm
      ref="copyUrlSafari"
      :width="480"
      :action-name="$t('reports.other.close')"
      action-fa-icon="check"
      type="success"
      class="copy-url-safari"
      @save="() => {$refs.copyUrlSafari.close()}"
    >
      <div
        slot="title"
      >
        {{ $t('reports.other.share_report') }}
      </div>
      <div class="body__wrap">
        <div class="body__title">
          URL
        </div>
        <div class="body__url">
          {{ copyUrl }}
        </div>
        <ui-button
          :color="$theme.mainColor"
          icon="copy"
          size="medium"
          lib="fa"
          substyle="fas"
          class="btn"
          @click="safariCopy"
        >
          {{ $t('reports.other.copy') }}
        </ui-button>
      </div>
    </ui-confirm>
    <div class="inner">
      <draggable
        :list="reportsClone"
        v-bind="dragOptions"
        draggable=".tab__report"
        class="inner-wrap"
        @change="draggable"
        @start="drag = true"
        @end="drag = false; isVisibleTabTooltip = true"
      >
        <div
          v-for="(report, index) in reportsClone"
          ref="tab"
          :key="index"
          :class="{
            'active' : currentReportClone === index,
            'home': index === 0,
            'tab__report': index !== 0 && !(tabCountVisible !== null && tabCountVisible < index),
            'hidden-tab': tabCountVisible !== null && tabCountVisible < index
          }"
          class="tab"
          @mousedown.middle="index ? removeReport(index, $event) : () => {}"
          @mousedown="isVisibleTabTooltip = false"
          @mouseup="isVisibleTabTooltip = true"
          @click="selectReport(index)"
        >
          <div
            class="report_name"
          >
            <ui-icon
              v-if="index !== 0 && report.defaultFilter[Object.keys(report.defaultFilter)[0]].op === '='"
              class="context-icon"
              style="margin-right: 4px; display: inline-block; padding-bottom: 2px;"
              size="12px"
              lib="fa"
              substyle="fas"
              name="random"
            />
            <ui-icon
              v-if="index !== 0 && report.defaultFilter[Object.keys(report.defaultFilter)[0]].op === '!='"
              class="context-icon"
              style="margin-right: 4px; display: inline-block; padding-bottom: 2px;"
              lib="fa"
              size="12px"
              substyle="fas"
              name="page-break"
            />
            <span
              class="field"
            >
              {{ index === 0
                ? $t('reports.other.home')
                : `${tabsTranslate(report.defaultFilter[Object.keys(report.defaultFilter)[0]].group, 'defaulTitle')} :`
              }}
            </span>
            <span
              v-if="index !== 0"
            >
              <el-dropdown
                trigger="click"
                placement="bottom"
              >
                <el-tooltip
                  :content="
                    `${tabsTranslate(report, 'title')}
                    ${tabsTranslate(report)}`
                  "
                  :disabled="!(arrayWidthItemForTooltip[index - 1] === 150 && isVisibleTabTooltip)"
                  placement="top"
                >
                  <span class="wrap-value-tabs-ell">
                    <span
                      ref="valueText"
                      class="value"
                    >
                      {{ tabsTranslate(report, 'title') | datetimeFilter(Object.keys(report.defaultFilter)[0]) }}

                    </span>
                    <span class="count-value">{{ tabsTranslate(report) }}</span>

                  </span>
                </el-tooltip>
                <el-dropdown-menu
                  slot="dropdown"
                  :class="{'hidden': !(report.defaultFilter[Object.keys(report.defaultFilter)[0]].row_name.length - 1)}"
                  class="ddgroup drill-item"
                >
                  <el-dropdown-item
                    v-for="(row, ind) in report.defaultFilter[Object.keys(report.defaultFilter)[0]].row_name"
                    :key="ind"
                  >
                    <span class="drill-item--text">
                      {{ row[report.defaultFilter[Object.keys(report.defaultFilter)[0]].group] | datetimeFilter(Object.keys(report.defaultFilter)[0]) }}
                    </span>
                    <ui-icon
                      :color="$theme.dangerColor"
                      lib="fa"
                      substyle="fas"
                      name="times"
                      size="12px"
                      class="close"
                      @click.native.stop="removeItemCurrentFilter(row, report.defaultFilter[Object.keys(report.defaultFilter)[0]].row_id[ind], Object.keys(report.defaultFilter)[0], 'report')"
                    />
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </div>
          <div
            v-if="index !== 0"
            class="ctrls"
          >
            <el-dropdown
              trigger="hover"
              :show-timeout="0"
              :hide-timeout="200"
              placement="bottom-start"
              @visible-change="handleAngleOpen"
            >
              <ui-icon
                :class="angleOpen[index] ? 'angle-down-rotate' : 'angle-down-no-rotate'"
                lib="fa"
                substyle="fas"
                name="angle-down"
                @mouseover.native="addClassRotate(index, $event)"
              />
              <el-dropdown-menu
                slot="dropdown"
                class="ddgroup ddgroup__tab"
              >
                <el-dropdown-item
                  :disabled="isDisabledGenUrl[index]"
                  @click.native="generateURL(index, $event);"
                >
                  <span class="title">{{ $t('reports.other.copy_url2') }}</span>
                  <ui-icon
                    :class="{'disabled': isDisabledGenUrl[index]}"
                    :size="12"
                    class="tab-action"
                    :color="$theme.accentColor"
                    lib="fa"
                    substyle="fas"
                    name="link"
                  />
                </el-dropdown-item>
                <el-dropdown-item
                  class="afterline-item-tab"
                >
                  <div
                    class="ddgroup__wrapper"
                    @click="indexCurrent = index; setBookmarkName(index); clickBookmarkStar(report, index, 'open');"
                  >
                    <span class="title">{{ $t('reports.other.save_bookmark') }}</span>
                    <i
                      class="fas fa-star"
                      :class="report.isPinnedBookmark ? 'boookmark-edit__star-active' : 'boookmark-edit__star-disactive'"
                    />
                  </div>
                  <!-- </el-tooltip> -->
                </el-dropdown-item>
                <el-dropdown-item
                  class="underline-item-tab"
                  @click.native="resetDefaultTab(index)"
                >
                  <span class="title">{{ $t('reports.payments.reset_filter') }}</span>
                  <ui-icon
                    :size="12"
                    class="tab-action"
                    :color="$theme.accentColor"
                    lib="fa"
                    substyle="fas"
                    name="undo"
                  />
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-tooltip
              :content="$t('reports.other.remove_report')"
              placement="top"
            >
              <ui-icon
                :size="12"
                class="tab-action"
                :color="$theme.dangerColor"
                lib="fa"
                substyle="fas"
                name="times"
                @click.native="removeReport(index, $event)"
              />
            </el-tooltip>
          </div>
          <div
            v-else
            class="ctrls"
          >
            <el-dropdown
              trigger="hover"
              :show-timeout="0"
              :hide-timeout="200"
              placement="bottom-start"
              @visible-change="handleAngleOpen"
            >
              <ui-icon
                :class="angleOpen[index] ? 'angle-down-rotate' : 'angle-down-no-rotate'"
                lib="fa"
                substyle="fas"
                name="angle-down"
                @mouseover.native="addClassRotate(index, $event)"
              />
              <el-dropdown-menu
                slot="dropdown"
                class="ddgroup ddgroup__tab"
              >
                <el-dropdown-item
                  :disabled="isDisabledGenUrl[index]"
                  @click.native="generateHomeURL($event);"
                >
                  <span class="title">{{ $t('reports.other.copy_url2') }}</span>
                  <ui-icon
                    :class="{'disabled': isDisabledGenUrl[index]}"
                    :size="12"
                    class="tab-action"
                    :color="$theme.accentColor"
                    lib="fa"
                    substyle="fas"
                    name="link"
                  />
                </el-dropdown-item>
                <el-dropdown-item
                  class="underline-item-tab"
                  @click.native="resetHomeReport($event)"
                >
                  <span class="title">{{ $t('reports.payments.reset_filter') }}</span>
                  <ui-icon
                    :size="12"
                    class="tab-action"
                    :color="$theme.accentColor"
                    lib="fa"
                    substyle="fas"
                    name="undo"
                  />
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div
          v-if="isVisibleTabMore"
          :class="{'active': isNavTabDropdownVisible}"
          class="nav-tab-dropdown"
        >
          <el-popover
            v-model="isNavTabDropdownVisible"
            popper-class="nav-tab-dropdown__popover"
            placement="bottom-start"
            :visible-arrow="false"
            trigger="hover"
          >
            <div
              slot="reference"
              class="nav-tab-dropdown__wrap"
            >
              <ui-icon
                size="12px"
                color="#303634"
                name="bars"
                lib="fa"
                substyle="fas"
              />
              <span class="nav-tab-dropdown__text">
                {{ $t('reports.other.more', { number: reportsClone.length - tabCountVisible - 1 }) }}
              </span>
              <ui-icon
                :class="isNavTabDropdownVisible ? 'angle-down-rotate' : 'angle-down-no-rotate'"
                size="12px"
                lib="fa"
                substyle="fas"
                name="angle-down"
              />
            </div>
            <el-scrollbar
              wrap-class="nav-tab-dropdown__scroll-wrap"
              view-class="view-box"
              :native="false"
            >
              <div class="nav-tab-dropdown__item-wrap">
                <div
                  v-for="(report, index) in reportsCloneDropdown"
                  :key="index"
                  class="nav-tab-dropdown__item"
                  @click="selectReport(tabCountVisible + index + 1); isNavTabDropdownVisible = false"
                >
                  <div class="nav-tab-dropdown__title-wrap">
                    <ui-icon
                      v-if="report.defaultFilter[Object.keys(report.defaultFilter)[0]].op === '='"
                      class="context-icon"
                      style="margin-right: 4px; display: inline-block; padding-bottom: 2px;"
                      size="12px"
                      lib="fa"
                      substyle="fas"
                      name="random"
                    />
                    <ui-icon
                      v-if="report.defaultFilter[Object.keys(report.defaultFilter)[0]].op === '!='"
                      class="context-icon"
                      style="margin-right: 4px; display: inline-block; padding-bottom: 2px;"
                      lib="fa"
                      size="12px"
                      substyle="fas"
                      name="page-break"
                    />
                    <span
                      class="field"
                    >{{ `${tabsTranslate(report.defaultFilter[Object.keys(report.defaultFilter)[0]].group, 'defaulTitle')} :` }}</span>
                    <span class="value">
                      {{ tabsTranslate(report, 'title') | datetimeFilter(Object.keys(report.defaultFilter)[0]) }}
                      {{ tabsTranslate(report) }}
                    </span>
                  </div>
                  <div class="nav-tab-dropdown__ctrls">
                    <ui-icon
                      :size="12"
                      class="tab-action"
                      :color="$theme.dangerColor"
                      lib="fa"
                      substyle="fas"
                      name="times"
                      @click.native.stop="removeReport(tabCountVisible + index + 1, $event)"
                    />
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </el-popover>
        </div>
      </draggable>
    </div>
    <div
      v-if="tab.isVisible"
      class="nav-tabs"
    >
      <span
        :class="{'disabled': tab.disabledLeft}"
        class="nav-tabs__left"
        @click="tabsMove('left')"
      >
        <i class="fas fa-angle-left" />
      </span>
      <span
        :class="{'disabled': tab.disabledRight}"
        class="nav-tabs__right"
        @click="tabsMove('right')"
      >
        <i class="fas fa-angle-right" />
      </span>
    </div>
    <slot />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState, mapGetters } from 'vuex';
import { version } from '../../package.json';
import isSafari from '../helpers/is-safari';

export default {
  name: 'TabsReport',
  components: {
    draggable,
  },
  props: {
    reports: {
      type: Array,
      required: true,
    },
    currentReport: {
      type: Number,
      required: true,
    },
    bookmarks: {
      type: Array,
      required: true,
    },
    shortcutsDates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isVisibleTabTooltip: true,
      isNavTabDropdownVisible: false,
      tabCountVisible: null,
      arrayWidthItemForTooltip: [],

      reportsClone: [],
      currentReportClone: 0,

      indexCurrent: 0,
      drag: false,
      angleOpenIndex: -1,
      angleOpen: [],
      constTabBookmarkName: '',
      isPinnedPopover: [],
      checkPeriodBookmark: false,
      pickerDateRangeBookmark: [],
      bookmarksSync: this.bookmarks,
      isDisabledGenHomeUrl: false,
      isDisabledGenUrl: [],

      tab: {
        isVisible: false,
        selectTab: false,
        disabledLeft: false,
        disabledRight: false,
      },

      copyUrl: '',
    };
  },
  computed: {
    ...mapGetters('reports', ['reportsSettings']),
    ...mapState('other', ['isDragged']),

    dragOptions() {
      return {
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    reportsCloneDropdown() {
      return this.reportsClone.slice(-(this.reportsClone.length - this.tabCountVisible - 1));
    },
    isVisibleTabMore() {
      if (!(this.tabCountVisible !== null && this.tabCountVisible < this.reportsClone.length - 1)) {
        // eslint-disable-next-line
        this.isNavTabDropdownVisible = false;
      }
      return this.tabCountVisible !== null && this.tabCountVisible < this.reportsClone.length - 1;
    },
  },
  watch: {
    tab: {
      deep: true,
      handler({ isVisible }) {
        this.$emit('isVisible', isVisible);
      },
    },
    reports: {
      deep: true,
      handler(report) {
        /* блокируем кнопку линк */
        this.reportsClone = [...report];
        this.isDisabledGenHomeUrl = false;
        this.isDisabledGenUrl = this.$_.clone(this.isDisabledGenUrl.fill(false));
      },
    },
    currentReport: {
      deep: true,
      handler(index) {
        /* блокируем кнопку линк */
        this.currentReportClone = index;
        this.isDisabledGenHomeUrl = false;
        this.isDisabledGenUrl = this.$_.clone(this.isDisabledGenUrl.fill(false));
      },
    },
    reportsClone: {
      deep: true,
      handler() {
        this.getWidthItemForTooltip();
      },
    },
  },
  created() {
    this.tabCountVisible = this.$ls.get('reports/more_tab') || null;
    this.currentReportClone = this.currentReport;
  },
  mounted() {
    this.getWidthItemForTooltip();
  },
  methods: {
    async safariCopy() {
      try {
        await this.$copyText(this.copyUrl);
        this.$noty.info(`${this.$t('reports.other.copied_to_clipboard')}`);
      } catch (error) {
        this.$noty.error(`${this.$t('reports.other.failed_to_copy')}`);
      }
      this.$refs.copyUrlSafari.close();
    },

    getWidthItemForTooltip() {
      this.$nextTick(() => {
        this.arrayWidthItemForTooltip = [];
        if (this.$refs.valueText) {
          this.$refs.valueText.forEach((elem, i) => {
            this.$set(this.arrayWidthItemForTooltip, i, elem.offsetWidth || 0);
          });
        }
      });
    },
    lastTab() {
      let tabCountVisible = null;
      let tabWidthIterator = 0;
      if (this.$el) {
        const tabWrapWidth = this.$el ? this.$el.querySelector('.inner').offsetWidth : 0;

        this.$refs.tab.forEach((e, i) => {
          if (tabCountVisible === null) tabWidthIterator += e.offsetWidth + 8;

          if (tabWrapWidth - 400 < tabWidthIterator && tabCountVisible === null) {
            tabCountVisible = i;
          }
        });
        const tabsCollection = this.$el.querySelectorAll('.inner .tab');
        tabsCollection.forEach((el, i) => {
          if (tabCountVisible < i && tabCountVisible !== null) {
            el.classList.add('hidden-tab');
          } else {
            el.classList.remove('hidden-tab');
          }
        });
      }
      this.tabCountVisible = tabCountVisible;
      this.$ls.set('reports/more_tab', tabCountVisible);
      let changeItem;
      if (this.currentReportClone > tabCountVisible
          && tabCountVisible !== null
          && changeItem === undefined) {
        this.$store.commit('other/isDragged', true);
        changeItem = this.reportsClone.splice(this.currentReportClone, 1);
        this.reportsClone.splice(tabCountVisible, 0, ...changeItem);
        this.currentReportClone = tabCountVisible;

        this.$store.commit('other/setCurrentReportClone', tabCountVisible);
        this.$store.commit('other/setReportsClone', this.$_.cloneDeep(this.reportsClone));
      } else {
        changeItem = undefined;
      }
    },
    resetDefaultTab(index) {
      const objectDefaultSettings = {
        affiliateCreatedAt: {
          currentAffiliateDateOperator: '=',
          affiliateCreatedAtStart: undefined,
          affiliateCreatedAtEnd: undefined,
        },
        trafficType: 'all',
        playersType: 'all',
        sort_by: this.reportsClone[index].group_by,
        sort_dir: 'desc',
        search: {},
        searchQuery: {},
        columnsFilter: {},
        dateRange: this.shortcutsDates[1].range,
        page: 1,
      };
      // eslint-disable-next-line
      for (const key in objectDefaultSettings) {
        if (objectDefaultSettings.hasOwnProperty(key)) {
          const element = objectDefaultSettings[key];
          this.$set(this.reportsClone[index], key, element);
        }
      }

      this.$emit('selectDateRange');
      this.$emit('update:reports', this.reportsClone);
      this.$emit('resetRows');
      this.$emit('getReport');
    },
    addClassRotate(index) {
      this.angleOpenIndex = index;
    },
    handleAngleOpen(isOpen) {
      if (this.angleOpenIndex !== -1 && isOpen) {
        this.$set(this.angleOpen, this.angleOpenIndex, isOpen);
      } else {
        this.$set(this.angleOpen, this.angleOpenIndex, false);
        this.angleOpenIndex = -1;
      }
    },
    draggable({ moved }) {
      this.$store.commit('other/isDragged', true);
      const { newIndex, oldIndex } = moved;
      let currentReport = this.currentReportClone;

      if (this.currentReportClone === oldIndex) {
        currentReport = newIndex;
        this.currentReportClone = currentReport;
        this.$store.commit('other/setCurrentReportClone', currentReport);
        this.$store.commit('other/setReportsClone', this.$_.cloneDeep(this.reportsClone));
        return;
      }

      if ((this.currentReportClone > oldIndex && this.currentReportClone <= newIndex)) {
        currentReport -= 1;
      } else if ((this.currentReportClone < oldIndex && this.currentReportClone >= newIndex)) {
        currentReport += 1;
      }

      this.currentReportClone = currentReport;
      this.$store.commit('other/setCurrentReportClone', currentReport);
      this.$store.commit('other/setReportsClone', this.$_.cloneDeep(this.reportsClone));
    },

    tabsTranslate(report, flag) {
      if (flag === 'title') {
        const title = report.defaultFilter[Object.keys(report.defaultFilter)[0]]
          ? `${report.defaultFilter[Object.keys(report.defaultFilter)[0]]
            .row_name[0][report.defaultFilter[Object.keys(report.defaultFilter)[0]].group]}`
          : '';
        return title;
      }
      if (flag === 'defaulTitle') {
        const defaulTitle = this.reportsSettings.dimensions
          .find(dim => dim.column_name === report);

        return defaulTitle ? defaulTitle.title : '';
      }
      const subtitle = report.defaultFilter[Object.keys(report.defaultFilter)[0]].row_name.length - 1
        ? `[+${report.defaultFilter[Object.keys(report.defaultFilter)[0]].row_name.length - 1}]`
        : '';
      return subtitle;
    },

    selectDateRangeBookmark(range) {
      this.pickerDateRangeBookmark = range;
    },
    resetHomeReport(event) {
      this.$emit('resetHomeReport', event);
    },
    async generateURL(index, event) {
      this.$emit('updateReport');
      this.isDisabledGenUrl[index] = true;
      this.isDisabledGenUrl = [...this.isDisabledGenUrl];
      setTimeout(() => {
        this.isDisabledGenUrl[index] = false;
        this.isDisabledGenUrl = [...this.isDisabledGenUrl];
      }, 8000);

      event.stopPropagation();

      const q = this.isDragged ? this.reportsClone[index] : this.reports[index];
      q.type = 'add';
      q.version = this.ver;
      try {
        const { data: { payload } } = await this.$api.postQueryShortUrl(q);
        const shortId = payload.short_id;
        const url = `${window.location.origin}/reports/custom?short_url=${shortId}`;
        this.copyUrl = url;
        if (isSafari) {
          this.$refs.copyUrlSafari.open();
        } else {
          await this.$copyText(url);
          this.$noty.info(`${this.$t('reports.other.copied_to_clipboard')}`);
        }
      } catch (error) {
        this.$noty.error(`${this.$t('reports.other.failed_to_copy')}`);
      }
    },
    async generateHomeURL(event) {
      this.$emit('updateReport');

      event.stopPropagation();
      setTimeout(() => this.isDisabledGenHomeUrl = false, 8000);

      const q = this.reports[0];
      q.type = 'home';
      q.version = version;
      try {
        const { data: { payload } } = await this.$api.postQueryShortUrl(q);
        const shortId = payload.short_id;
        const url = `${window.location.origin}/reports/custom?short_url=${shortId}`;
        this.copyUrl = url;
        if (isSafari) {
          this.$refs.copyUrlSafari.open();
        } else {
          await this.$copyText(url);
          this.$noty.info(`${this.$t('reports.other.copied_to_clipboard')}`);
        }
      } catch (error) {
        this.$noty.error(`${this.$t('reports.other.failed_to_copy')}`);
      }
    },
    removeReport(index, event) {
      event.stopPropagation();
      this.reportsClone[index].isPinned = false;
      this.currentReportClone = 0;
      this.$emit('updateReport');
      this.$emit('selectReport', 0, 'noTick');
      this.reportsClone.splice(index, 1);
      this.$emit('update:reports', this.reportsClone);
    },
    saveTabBookmark(index) {
      this.reportsClone[index].bookmarkName = this.constTabBookmarkName;
      this.reportsClone[index].version = version;
      this.reportsClone[index].uid = `${+new Date()}0${(Math.random() * 100).toFixed(0)}`;
      this.reportsClone[index].isPinnedBookmark = true;
      if (this.checkPeriodBookmark) {
        const dateRange = this.pickerDateRangeBookmark;
        this.bookmarks.push({ ...this.reportsClone[index], dateRange });
      } else {
        this.bookmarks.push({ ...this.reportsClone[index], dateRange: [] });
      }
      this.$refs.confirmBookmark.close();
      this.$emit('update:reports', this.reportsClone);
      this.$emit('update:bookmarks', this.bookmarks);
      this.$emit('saveBookmark');
    },
    setBookmarkName(index) {
      if (this.reportsClone[index].bookmarkName === '') {
        const objDefaultTab = this.reportsClone[index].defaultFilter;
        const keyName = Object.keys(objDefaultTab);
        const subKeyName = Object.keys(objDefaultTab[keyName].row_name[0])[0];

        this.tabsTranslate(Object.keys(this.reportsClone[index].defaultFilter)[0], 'defaulTitle');
        this.constTabBookmarkName = `${`${ this.tabsTranslate(subKeyName, 'defaulTitle') }:`} ${this.reportsClone[index].defaultFilter[Object.keys(this.reportsClone[index].defaultFilter)[0]].visible}`;
      } else {
        this.constTabBookmarkName = this.reportsClone[index].bookmarkName;

        this.$emit('update:reports', this.reportsClone);
      }
      this.$refs.confirmBookmark.open();
    },
    clickBookmarkStar(report, index, type) {
      if (type === 'open') {
        const uid = report.hasOwnProperty('uid') ? report.uid : '';
        if (uid) {
          const thisBookmark = this.bookmarks.find(e => e.uid === uid);
          if (thisBookmark.dateRange.length) {
            this.pickerDateRangeBookmark = thisBookmark.dateRange;
            this.checkPeriodBookmark = true;
          } else {
            this.pickerDateRangeBookmark = report.dateRange;
            this.checkPeriodBookmark = false;
          }
        } else {
          this.pickerDateRangeBookmark = report.dateRange;
          this.checkPeriodBookmark = false;
        }
      } else {
        this.checkPeriodBookmark = false;
      }
    },
    selectReport(index) {
      this.$emit('selectReport', index);
    },
    removeItemCurrentFilter(row, id, key, flag) {
      this.$emit('removeItemCurrentFilter', row, id, key, flag);
    },
  },
};
</script>

<style lang="scss">
  .ghost {
    opacity: 0.5;
  }
</style>
