export const clearObject = obj => (obj instanceof Object ? Object.entries(obj).reduce((item, [key, value]) => {
  if (!isEmpty(value)) {
    item[key] = clearObject(value);
  }
  return item;
}, obj instanceof Array ? [] : {}) : obj);
export const typeOf = (obj) => {
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object',
  };
  return map[Object.prototype.toString.call(obj)];
};
export const isEmpty = (obj) => {
  switch (typeOf(obj)) {
  case 'null':
  case 'undefined':
    return true;
  case 'object':
    return Object.keys(obj).length === 0;
  case 'array':
  case 'string':
    return obj.length === 0;
  case 'number':
    // eslint-disable-next-line
      return obj !== obj || !isFinite(obj);
  default:
    break;
  }
  return false;
};
