<template>
  <div
    v-loading="isDataLoading"
    class="user-info"
  >
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirmType"
      :action-name="actionName"
      :action="confirmAction"
      :action-icon="actionIcon"
      :action-fa-icon="actionFaIcon"
      @save="onConfirm"
      @close="confirmClose"
    >
      <div
        slot="title"
        v-html="confirmTitle"
      />
      <div class="body__wrap">
        <i
          v-if="confirmAction === 'delFromSystem'"
          :class="confirmType"
          class="fas fa-trash-alt"
        />
        <i
          v-else-if="confirmAction === 'delFromTeam'"
          :class="confirmType"
          class="fas fa-trash-alt"
        />
        <i
          v-else-if="confirmAction === 'blockUser'"
          :class="confirmType"
          class="fas fa-lock"
        />
        <i
          v-else-if="confirmAction === 'unblockUser'"
          :class="confirmType"
          class="fas fa-unlock"
        />
        <i
          v-else-if="confirmAction === 'reset2faUser'"
          :class="confirmType"
          class="fas fa-exclamation-triangle"
        />
        <i
          v-else-if="confirmAction === 'resetUserPassword'"
          :class="confirmType"
          class="fas fa-exclamation-triangle"
        />

        <i
          v-else-if="confirmAction === 'Decline'"
          :class="confirmType"
          class="fas fa-times"
        />
        <i
          v-else-if="confirmAction === 'Decline w/'"
          :class="confirmType"
          class="fas fa-bell"
        />

        <i
          v-else
          :class="confirmType"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirmMsg"
        />
      </div>
    </ui-confirm>

    <edit-teams-user
      v-show="isEditPopupOpen"
      :is-open="isEditPopupOpen"
      :user="userInfo"
      :timezones="timezones"
      :messengers="messengers"
      @close="onClosePopup"
      @refresh="updateUser"
    />

    <add-user-in-teams
      v-show="isPopupOpen"
      ref="addUserInTeams"
      :is-open="isPopupOpen"
      :user-info="userInfo"
      @refresh="refresh"
      @close="onClosePopup"
    />
    <pin-affiliate-user
      v-show="isPinPopupOpen"
      ref="PinAffiliateUser"
      :user-info="userInfo"
      :is-open="isPinPopupOpen"
      @refresh="refresh"
      @close="onClosePopup"
    />
    <user-permissions
      v-show="isPermissionsOpen"
      :id="$route.query.team_id"
      ref="userPermissions"
      :user-info="userInfo"
      :is-open="isPermissionsOpen"
      @close="onClosePermissions"
    />


    <div class="wrapper">
      <div class="toolbar">
        <div class="btns">
          <ui-button
            v-if="isHide"
            icon="check-double"
            lib="fa"
            substyle="fas"
            color="green"
            class="btn"
            @click="permissionsHandler"
          >
            {{ $t(`team_users.list.actions.permissions`) }}
          </ui-button>
          <ui-button
            icon="key"
            lib="fa"
            substyle="fas"
            color="green"
            class="btn"
            @click="resetUserPasswordHandler"
          >
            {{ $t(`team_users.list.actions.reset_password`) }}
          </ui-button>
          <ui-button
            icon="shield-check"
            color="green"
            lib="fa"
            substyle="fas"
            class="btn"
            @click="reset2faHandler"
          >
            {{ $t(`team_users.list.actions.reset_2fa`) }}
          </ui-button>
          <a
            :href="userInfo.login_url"
            class="login-link"
            @click.stop.prevent
          >
            <ui-button
              color="green"
              lib="fa"
              substyle="fas"
              icon="sign-in-alt"
              class="action-btn el-dropdown-link"
            >{{ $t('affiliates.cardView.toolbar.btns.login') }}</ui-button>
          </a>
          <ui-button
            v-if="userStatus === 'active'"
            color="red"
            icon="lock"
            lib="fa"
            substyle="fas"
            class="btn"
            @click="blockHandler"
          >
            {{ $t(`team_users.list.actions.block`) }}
          </ui-button>
          <ui-button
            v-else
            color="green"
            icon="unlock"
            lib="fa"
            substyle="fas"
            class="btn"
            @click="unblockHandler"
          >
            {{ $t(`team_users.list.actions.unblock`) }}
          </ui-button>

          <ui-button
            color="red"
            icon="trash-alt"
            lib="fa"
            substyle="fas"
            class="btn"
            @click="delFromSystemHandler"
          >
            {{ $t(`team_users.list.actions.delete_from_system`) }}
          </ui-button>
        </div>
      </div>
      <div class="info">
        <div class="sidebar">
          <div class="card">
            <div class="title">
              <span>{{ $t(`user_info.personal_information`) }}</span>
              <span
                :style="{color: $theme.accentColor}"
                class="action"
                @click="editUserHandler"
              >
                <ui-icon
                  name="pen"
                  :color="$theme.accentColor"
                  lib="fa"
                />
                {{ $t(`user_info.edit`) }}
              </span>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label name">
                  <el-tooltip
                    :disabled="isDisabledTooltipName"
                    :content="userInfo.name"
                    popper-class="wlt"
                    placement="bottom"
                    effect="light"
                  >
                    <div ref="labelName">
                      {{ userInfo.name }}
                    </div>
                  </el-tooltip>
                  <el-dropdown
                    v-if="userInfo.last_seen_days_ago >= 14"
                    trigger="click"
                    placement="bottom"
                  >
                    <div class="el-dropdown-link">
                      <ui-icon
                        lib="fa"
                        substyle="fas"
                        :color="$theme.dangerColor"
                        name="exclamation-triangle"
                      />
                      <el-dropdown-menu class="user-login-dropdown">
                        <el-dropdown-item>
                          <span>
                            {{ $t('team_users.list.alert.last_seen') }}
                          </span>
                          <span class="red-period">
                            {{ $t('team_users.list.alert.days_ago', { days: userInfo.last_seen_days_ago }) }}
                          </span>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </div>
                  </el-dropdown>
                </span>
              </div>
              <div class="field field-solo">
                <ui-icon
                  :size="14"
                  :color="$theme.accentColor"
                  lib="fa"
                  substyle="fas"
                  name="envelope"
                  class="ico"
                />
                <span
                  v-if="userInfo.email"
                  class="phone"
                >
                  <a
                    :href="`mailto:${userInfo.email}`"
                    rel="noopener noreferrer"
                    target="_blank"
                  >{{ userInfo.email }}</a>
                </span>
                <span
                  v-else
                  class="phone empty"
                >{{ $t(`user_info.not_specified`) }}</span>
              </div>
              <div class="field">
                <span
                  v-show="userInfo.messenger_id"
                  :class="userInfo.messenger"
                  class="messenger"
                >
                  <a
                    :href="getMessangerLink"
                    rel="noopener noreferrer"
                    target="_blank"
                  >{{ userInfo.messenger_id }}</a>
                </span>
              </div>
              <div class="field field-solo">
                <ui-icon
                  :size="14"
                  :color="$theme.accentColor"
                  lib="fa"
                  substyle="fas"
                  name="clock"
                  class="ico"
                />
                <span class="timezone">
                  {{ viewTimezone }}
                </span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`user_info.see_stats`) }}</span>
            </div>
            <div class="card-content">
              <div
                v-if="seeStatsFormat === 'full'"
                class="field"
              >
                <div class="values">
                  {{ userInfo.entities_visibility_from | formatDate4 }}
                  <div class="spacer">
                    –
                  </div>
                  {{ userInfo.entities_visibility_to | formatDate4 }}
                </div>
              </div>
              <div
                v-if="seeStatsFormat === 'leftSide'"
                class="field"
              >
                <span class="label">{{ $t(`user_info.see_stats_from`) }}</span>
                <div class="values">
                  {{ userInfo.entities_visibility_from | formatDate4 }}
                </div>
              </div>
              <div
                v-if="seeStatsFormat === 'rightSide'"
                class="field"
              >
                <span class="label">{{ $t(`user_info.see_stats_to`) }}</span>
                <div class="values">
                  {{ userInfo.entities_visibility_to | formatDate4 }}
                </div>
              </div>
              <div
                v-if="seeStatsFormat === 'overall'"
                class="field"
              >
                <span class="label">{{ $t(`user_info.see_stats_overall`) }}</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="title">
              <span>{{ $t(`user_info.login_reg`) }}</span>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ $t(`user_info.last_login`) }}</span>
                <div
                  v-if="userInfo.last_active"
                  class="values"
                >
                  {{ userInfo.last_active | formatDate3 }}
                </div>
                <span
                  v-else
                  class="label empty"
                />
              </div>
              <div class="field">
                <span class="label">{{ $t(`user_info.reg_date`) }}</span>
                <div class="values">
                  {{ userInfo.created_at | formatDate3 }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="userInfo.notes"
            class="card notes"
          >
            <div class="title">
              <span>{{ $t(`user_info.note`) }} </span>
            </div>
            <div class="card-content">
              <div class="field">
                <div class="values">
                  {{ userInfo.notes }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="body">
          <div class="card">
            <div class="header">
              <span class="label">{{ $t(`user_info.teams`) }}</span>
              <ui-button
                icon="plus"
                lib="fa"
                color="green"
                class="btn"
                @click="addUserHandler"
              >
                {{ $t('user_info.add_team') }}
              </ui-button>
            </div>
            <div class="card-team">
              <div
                v-for="(item, key) in userInfo.teams_tree"
                :key="key"
                class="ui-d-flex ui-fd-column"
              >
                <div class="field team">
                  <router-link
                    :to="`/teams/${item.id}/details/users`"
                    class="label act title"
                  >
                    {{ item.name }}
                  </router-link>
                  <div class="values">
                    <span
                      :style="{color: $theme.dangerColor}"
                      class="action"
                      @click="delFromTeamHandler(item.id, item.name)"
                    >
                      <ui-icon
                        name="times"
                        :color="$theme.dangerColor"
                        lib="fa"
                      />
                      {{ $t(`user_info.delete`) }}
                    </span>
                    <span
                      :style="{color: $theme.accentColor}"
                      class="action"
                      @click="pinAffiliatesHandler(item.id)"
                    >
                      <ui-icon
                        name="link"
                        :color="$theme.accentColor"
                        lib="fa"
                      />
                      {{ $t(`user_info.pin`) }}
                    </span>
                  </div>
                </div>
                <div v-if="item.affiliates.length">
                  <div
                    v-for="affiliate in item.affiliates"
                    :key="affiliate.id"
                    class="field affiliate team"
                  >
                    <div>
                      {{ affiliate.email }}
                    </div>
                    <div class="values">
                      <span
                        :style="{color: $theme.dangerColor}"
                        class="action"
                        @click="unpinAffiliateHandler(affiliate.id, affiliate.email, item.id)"
                      >
                        <ui-icon
                          name="unlink"
                          :color="$theme.dangerColor"
                          lib="fa"
                        />
                        {{ $t('user_info.unpin') }}
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else class="no-pinned field affiliate">
                  <ui-icon
                    class="ui-m-sm-r"
                    name="users"
                    color="#a8b2c2"
                    lib="fa"
                    size="14"
                  />
                  {{ $t('user_info.no_pinned_affiliates') }}
                </div>
              </div>
              <div
                v-if="userInfo.teams_count === 0"
                class="field no-teams"
              >
                <div class="ui-d-flex ui-jc-center">
                  <ui-icon
                    class="ui-m-sm-r"
                    name="users"
                    color="#a8b2c2"
                    lib="fa"
                    size="14"
                  />
                  {{ $t('user_info.no_teams') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddUserInTeams from '@/components/Popups/AddUserInTeams.vue';
import PinAffiliateUser from '@/components/Popups/PinAffiliateUser.vue';
import UserPermissions from '@/components/Popups/UserPermissions.vue';
import EditTeamsUser from '@/components/Popups/editTeamsUser.vue';

export default {
  components: {
    AddUserInTeams,
    PinAffiliateUser,
    UserPermissions,
    EditTeamsUser,
  },

  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    userStatus: {
      type: String,
      default() {
        return '';
      },
    },
  },

  data() {
    return {
      isDataLoading: false,
      isConfirmOpen: false,
      isOverall: false,
      confirmMsg: '',
      confirmAction: '',
      confirmTitle: '',
      confirmType: '',
      actionName: '',
      actionFaIcon: '',
      actionIcon: '',
      errors: {},
      popupInfo: {},
      isPopupOpen: false,
      isEditPopupOpen: false,
      isPinPopupOpen: false,
      isPermissionsOpen: false,
      delId: '',
      del_team_id: '',
      teamsList: [],
      userAffiliates: [],
      affiliatesList: [],
      widthLabelName: 0,
    };
  },

  computed: {
    viewTimezone() {
      if (this.userInfo.timezone && this.timezones) {
        const timezone = this.$_.filter(this.timezones, item => item.zone.includes(this.userInfo.timezone));
        return timezone[0].name;
      }
      return 'Invalid time zone';
    },
    userId: {
      get() {
        return this.$route.params.id;
      },
    },
    seeStatsFormat() {
      if (this.userInfo.entities_visibility_from !== null && this.userInfo.entities_visibility_to !== null) {
        return 'full';
      }
      if (this.userInfo.entities_visibility_from === null && this.userInfo.entities_visibility_to !== null) {
        return 'rightSide';
      }
      if (this.userInfo.entities_visibility_from !== null && this.userInfo.entities_visibility_to === null) {
        return 'leftSide';
      }
      if (this.userInfo.entities_visibility_from === null && this.userInfo.entities_visibility_to === null) {
        return 'overall';
      }
      return '';
    },


    isHide: {
      get() {
        return !this.$_.find(this.userInfo.teams, item => item.type === 'owners') && this.userInfo.teams_count > 0;
      },
    },

    getMessangerLink() {
      switch (this.userInfo.messenger) {
      case 'telegram':
        return `tg://resolve?domain=${this.userInfo.messenger_id}`;
      case 'skype':
        return `skype:${this.userInfo.messenger_id}?chat`;
      case 'viber':
        return `viber://chat?number=${this.userInfo.messenger_id}`;
      case 'whatsapp':
        return `https://api.whatsapp.com/send?phone=${this.userInfo.messenger_id.replace(/[^\d]/g, '')}`;
      case 'facebook':
        return `https://www.facebook.com/${this.userInfo.messenger_id}`;
      case 'instagram':
        return `https://www.instagram.com/${this.userInfo.messenger_id}`;
      case 'twitter':
        return `https://twitter.com/@${this.userInfo.messenger_id}`;
      default:
        return '#';
      }
    },

    isDisabledTooltipName() {
      return +this.widthLabelName < (this.userInfo.last_seen_days_ago >= 14 ? 253 : 274);
    },

    ...mapGetters({
      timezones: 'misc/timezones',
      messengers: 'misc/apiMessengers',
    }),
  },

  watch: {
    userInfo: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.widthLabelName = this.$refs.labelName?.offsetWidth || 0;
        });
      },
    },
  },

  mounted() {},

  methods: {
    onConfirm() {
      this.isConfirmOpen = false;
      switch (this.confirmAction) {
      case 'delFromSystem':
        this.delFromSystem();
        this.$refs.confirm.close();
        break;
      case 'delFromTeam':
        this.delFromTeam();
        this.$refs.confirm.close();
        break;
      case 'blockUser':
        this.blockUser();
        this.$refs.confirm.close();
        break;
      case 'unblockUser':
        this.unblockUser();
        this.$refs.confirm.close();
        break;
      case 'reset2faUser':
        this.reset2faUser();
        this.$refs.confirm.close();
        break;
      case 'resetUserPassword':
        this.resetUserPassword();
        this.$refs.confirm.close();
        break;
      case 'unpinAffiliate':
        this.unpinAffiliate();
        this.$refs.confirm.close();
        break;
      default:
        break;
      }
    },

    delFromSystemHandler() {
      this.confirmAction = 'delFromSystem';
      this.confirmTitle = `${this.$t('team_popup.delete_title_system', { name: this.userInfo.name })}`;
      this.confirmMsg = `${this.$t('team_popup.delete_msg_system', { name: this.userInfo.name })}`;
      this.actionName = `${this.$t('team_popup.delete_action_system')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'trash-alt';
      this.$refs.confirm.open();
    },
    delFromSystem() {
      this.$api
        .deleteAdmins(this.userId)
        .then(() => {
          if (this.teamId) {
            this.$router.push(`/teams/${this.teamId}/details/users`);
          } else {
            this.$router.push('/teams/all/details/users');
          }
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    delFromTeamHandler(id, teamName) {
      this.confirmAction = 'delFromTeam';
      this.confirmTitle = `${this.$t('team_popup.delete_title_team', { name: this.userInfo.name, teamName })}`;
      this.confirmMsg = `${this.$t('team_popup.delete_msg_team', { name: this.userInfo.name, teamName })}`;
      this.actionName = `${this.$t('team_popup.delete_action_team')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'trash-alt';
      this.delId = id;
      this.$refs.confirm.open();
    },
    delFromTeam() {
      this.$api
        .postDetachFromTeam(this.userId, { team_id: this.delId })
        .then(() => {
          this.refresh();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    blockHandler() {
      this.confirmAction = 'blockUser';
      this.confirmTitle = `${this.$t('team_popup.block_title', { name: this.userInfo.name })}`;
      this.confirmMsg = `${this.$t('team_popup.block_msg', { name: this.userInfo.name })}`;
      this.actionName = `${this.$t('team_popup.block_action')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'lock';
      this.$refs.confirm.open();
    },
    blockUser() {
      this.$api
        .postBlock(this.userId)
        .then(() => {
          this.$emit('change-status', 'blocked');
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    unblockHandler() {
      this.confirmAction = 'unblockUser';
      this.confirmTitle = `${this.$t('team_popup.unblock_title', { name: this.userInfo.name })}`;
      this.confirmMsg = `${this.$t('team_popup.unblock_msg', { name: this.userInfo.name })}`;
      this.actionName = `${this.$t('team_popup.unblock_action')}`;
      this.confirmType = 'success';
      this.actionFaIcon = 'unlock';
      this.$refs.confirm.open();
    },
    unblockUser() {
      this.$api
        .postUnblock(this.userId)
        .then(() => {
          this.$emit('change-status', 'active');
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    reset2faHandler() {
      this.confirmAction = 'reset2faUser';
      this.confirmTitle = `${this.$t('team_popup.reset_2fa_title', { name: this.userInfo.name })}`;
      this.confirmMsg = `${this.$t('team_popup.reset_2fa_msg', { name: this.userInfo.name })}`;
      this.actionName = `${this.$t('team_popup.reset_2fa_action')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'check';
      this.$refs.confirm.open();
    },
    reset2faUser() {
      this.$api
        .postResetTotp(this.userId)
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    unpinAffiliateHandler(id, name, team_id) {
      this.del_team_id = team_id;
      this.confirmAction = 'unpinAffiliate';
      this.confirmTitle = `${this.$t('team_popup.unpin_title', { name: this.userInfo.name, affiliateName: name })}`;
      this.confirmMsg = `${this.$t('team_popup.unpin_msg', { name: this.userInfo.name, affiliateName: name })}`;
      this.actionName = `${this.$t('team_popup.unpin_action')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'unlink';
      this.delId = id;
      this.$refs.confirm.open();
    },
    unpinAffiliate() {
      this.$api
        .postDetachAffiliate(this.userId, { affiliate_id: this.delId, team_id: this.del_team_id })
        .then(() => {
          this.refresh();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    resetUserPasswordHandler() {
      this.confirmAction = 'resetUserPassword';
      this.confirmTitle = `${this.$t('team_popup.reset_pass_title', { name: this.userInfo.name })}`;
      this.confirmMsg = `${this.$t('team_popup.reset_pass_msg', { name: this.userInfo.name })}`;
      this.actionName = `${this.$t('team_popup.reset_pass_action')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'check';
      this.$refs.confirm.open();
    },
    resetUserPassword() {
      this.$api
        .postResetPassword(this.userId)
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    confirmClose() {
      this.actionIcon = '';
      this.actionFaIcon = '';
    },

    updateUser(response) {
      this.$emit('update:userInfo', Object.assign(this.userInfo, response.data.payload));
    },

    editUserHandler() {
      this.isEditPopupOpen = true;
    },

    addUserHandler() {
      this.isPopupOpen = true;
    },
    pinAffiliatesHandler(team_id) {
      this.$refs.PinAffiliateUser.open(team_id);
      this.isPinPopupOpen = true;
    },

    permissionsHandler() {
      this.isPermissionsOpen = true;
    },
    onClosePermissions() {
      this.isPermissionsOpen = false;
    },

    onClosePopup() {
      this.isPopupOpen = false;
      this.isEditPopupOpen = false;
      this.isPinPopupOpen = false;
    },

    refresh() {
      this.$emit('get-user-info', this.userId);
    },

  },
};
</script>

<style lang="scss" scoped>
  .user-info {
    .wrapper {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 90%;
      min-width: 1140px;
    }

    .header .label {
      font-size: 22px;
    }

    .no-pinned {
      display: flex;
      align-content: center;
      justify-content: center!important;
      color: #a8b2c2;
    }

    .no-teams {
      background-color: transparent!important;
      color: #a6b2c4;
      justify-content: center!important;
    }
  }
</style>
