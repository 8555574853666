<template>
  <div class="segments">
    <create-segment
      :is-open="isOpenCreateSegmentPopup"
      :popup-type="popupType"
      :segment-id="segmentId"
      :current-brand="currentBrand"
      @closePopupSegment="closeSegmentPopup()"
    />
    <view-segment
      :is-open="isOpenViewSegmentPopup"
      :segment-id="segmentId"
      :current-brand="currentBrand"
      @closePopupSegment="closeSegmentPopup()"
      @edit="editSegmentPopup(segmentId)"
    />
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirm.type"
      :action-name="confirm.actionName"
      :action="confirm.action"
      action-icon="check"
      @save="deleteSegment"
    >
      <div
        slot="title"
        v-html="confirm.title"
      />
      <div class="body__wrap">
        <i
          :class="confirm.type"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirm.msg"
        />
      </div>
    </ui-confirm>

    <div class="wrapper">
      <div class="toolbar">
        <div class="toolbar__section">
          <span class="title">{{ currentViewMeta.title }}</span>
          <ui-input
            v-model.trim="search"
            :width="180"
            :is-search="true"
            @search="getSegments"
          />
          <el-select
            v-if="!currentViewMeta.staticFilter"
            ref="select"
            v-model="combinedFilter"
            class="select"
            @change="$nextTick(() => setSanitizedFilterStatus(pFilterStatus))"
          >
            <el-option
              v-for="item in combinedFilterMeta"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-checkbox
            v-model="onlyMine"
            class="ui-m-lg-l"
            :checked="onlyMine"
            @change="getSegments"
          >
            {{ $t('crm.only_mine') }}
          </el-checkbox>
          <!-- фильтр должен быть рабочим, если изменятся требования к проекту то раскоментить -->
          <!-- <el-select
            v-if="!currentViewMeta.staticFilter"
            ref="select"
            v-model="pFilterStatus"
            class="custom-filter"
            popper-class="custom-filter-popper"
            multiple
            collapse-tags
            @input="setSanitizedFilterStatus"
          >
            <el-option-group
              v-for="group in statusMetaFiltered"
              :key="group.label"
              :label="$t(`crm.activities.status_groups.${group.label}.title`)"
            >
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="$t(`crm.activities.status_groups.${group.label}.values.${item.label}`)"
                :value="item.value"
                class="filter-item"
                :disabled="isStatusDisabled(pFilters, item.value, item)"
              />
            </el-option-group>
          </el-select> -->
        </div>
        <div class="toolbar__section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="paginationHandler"
            />
          </div>
          <ui-button
            v-if="isCreateSegment && currentViewMeta.hasCreateButton"
            color="green"
            lib="fa"
            substyle="fas"
            filled
            icon="plus"
            class="btn btn-add"
            @click="openCreateSegmentPopup('new')"
          >
            {{ $t('crm.segments.add_segment') }}
          </ui-button>
          <ui-button
            color="green"
            lib="fa"
            substyle="fas"
            icon="sync-alt"
            class="btn btn-add"
            :disabled="isDataLoading"
            @click="refreshTable()"
          >
            {{ $t('reports.other.refresh') }}
          </ui-button>
        </div>
      </div>
      <ui-table
        v-loading="isDataLoading"
        :lazy-loading-delay="4000"
        :fields="fields"
        :data="data"
        :rows-count="limit"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        lazy-loading
        class="table"
        i18n-path="crm"
        element-loading-custom-class="data-loader-spinner"
        @sort="sortingHandler"
      >
        <template
          slot="append"
        >
          <el-table-column
            align="center"
            header-align="center"
            width="130"
          >
            <template slot-scope="scope">
              <ActionIcon
                :tooltip="$t('mediaBuying.info.files_card.download')"
              >
                <el-link
                  :disabled="scope.row.moderation_status !== 'approved'"
                  class="action-ico"
                  @click="exportData(scope.row)"
                >
                  <ui-icon
                    name="down"
                    :color="$theme.accentColor"
                    lib="fa-6"
                    substyle="fa-solid"
                  />
                </el-link>
              </ActionIcon>
              <ActionIcon
                :tooltip="$t('crm.buttons.view')"
              >
                <el-link
                  class="action-ico"
                  @click="viewSegmentPopup(scope.row.id)"
                >
                  <ui-icon
                    name="eye"
                    :color="$theme.accentColor"
                    lib="fa"
                  />
                </el-link>
              </ActionIcon>
              <ActionIcon
                :tooltip="$t('crm.buttons.edit')"
              >
                <el-link
                  :disabled="!isEditable(scope.row)"
                  class="action-ico"
                  type="success"
                  @click="editSegmentPopup(scope.row.id)"
                >
                  <ui-icon
                    name="pen"
                    :color="$theme.accentColor"
                    lib="fa"
                  />
                </el-link>
              </ActionIcon>
              <ActionIcon
                :tooltip="$t('crm.buttons.delete')"
              >
                <el-link
                  :disabled="isDelete(scope.row)"
                  class="action-ico"
                  type="danger"
                  @click="handleDelete(scope.row)"
                >
                  <ui-icon
                    name="trash-alt"
                    :color="$theme.dangerColor"
                    lib="fa"
                  />
                </el-link>
              </ActionIcon>
            </template>
          </el-table-column>
        </template>
      </ui-table>
    </div>
  </div>
</template>


<script>

import fileSaver from '@/helpers/file-saver.js';
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import queryParamsMixin from '@/views/mixins/query-params';
import CreateSegment from './components/CreateSegment';
import ViewSegment from './components/ViewSegment';
import ActivitiesCommonMixin from '@/views/CRM/mixins/activities-common-mixin';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'crm/segments/list/limit'; // for localstorage
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'Segments',

  components: {
    CreateSegment,
    ViewSegment,
  },

  mixins: [
    pageSizeMixin,
    queryParamsMixin,
    ActivitiesCommonMixin,
  ],

  props: {
    currentBrand: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      firstLoad: true,
      filterStatus: this.getDefaultFilterStatus(),
      pFilterStatus: this.getDefaultFilterStatus(),

      onlyMine: false,
      isDataLoading: false,
      isOpenCreateSegmentPopup: false,
      isOpenViewSegmentPopup: false,
      popupType: 'new',
      segmentId: '',
      pCombinedFilter: '',

      confirm: {
        type: '',
        title: '',
        msg: '',
        action: '',
        actionName: '',
      },

      search: '',
      count: 0,
      page: 1,
      limit: pageSize,
      sortProp: 'name',
      sortOrder: 'desc',
      data: [],
      permissions: {},
      fields: [
        {
          name: 'incrementing_id',
          sortable: 'custom',
          align: 'left',
          width: '60',
        },
        {
          name: 'moderation_status',
          label: 'computed_status',
          sortable: 'custom',
          computedValue: row => this.$t(`crm.fields.computed_status.values.${this.detectSegmentStatus(row)}`),
          computedClass: (value, row) => `row-value--${this.detectSegmentStatus(row)}`,
          width: '150',
        },
        {
          name: 'name',
          sortable: 'custom',
          minWidth: '100',
        },
        {
          name: 'players_count',
          label: 'segment_players_count',
          sortable: 'custom',
          minWidth: '40',
          format: 'number',
          computedValue: row => (row.is_players_attached
            ? `${parseFloat(row.players_count).toLocaleString('en-US')}`
            : this.$t('crm.status.updating')),
        },
        {
          name: 'type',
          sortable: 'custom',
          computedValue: row => this.$t(`crm.fields.type.values.${row.type}`),
          width: '70',
        },
      ],
    };
  },

  computed: {
    combinedFilter: {
      get() {
        return this.pCombinedFilter;
      },
      set(value) {
        this.pCombinedFilter = value;
        this.pFilterStatus = this.$_.cloneDeep(this.combinedFilterMetaIndex[value].filter);
      },
    },
    combinedFilterMeta() {
      return [{
        value: '',
        label: this.$t('crm.fields.computed_status.values.all'),
        filter: [],
      }, {
        value: 'draft',
        label: this.$t('crm.fields.computed_status.values.draft'),
        filter: ['draft'],
      }, {
        value: 'on_review',
        label: this.$t('crm.fields.computed_status.values.on_review'),
        filter: ['active', 'on_review'],
      }, {
        value: 'for_rework',
        label: this.$t('crm.fields.computed_status.values.for_rework'),
        filter: ['active', 'for_rework'],
      }, {
        value: 'approved',
        label: this.$t('crm.fields.computed_status.values.approved'),
        filter: ['active', 'approved'],
      }, {
        value: 'declined',
        label: this.$t('crm.fields.computed_status.values.declined'),
        filter: ['active', 'declined'],
      }];
    },
    combinedFilterMetaIndex() {
      const result = {};
      this.combinedFilterMeta.forEach((item) => {
        result[item.value] = item;
      });
      return result;
    },
    // filters: {
    //   get() {
    //     return this.currentViewMeta.staticFilter || this.getStatusGroupFromList(this.filterStatus);
    //   },
    //   set(value) {
    //     if (!this.$_.isEmpty(value)) {
    //       const keys = Object.keys(value);
    //       this.filterStatus = keys
    //         .map(key => value[key])
    //         .reduce((acc, item) => acc.concat(item), []);

    //       this.pFilterStatus = this.filterStatus;
    //     }
    //   },
    // },
    filters: {
      get() {
        return {
          status: this.combinedFilter,
          onlyMine: this.onlyMine,
        };
      },
      set(value) {
        if (!this.$_.isEmpty(value)) {
          this.combinedFilter = value.status;
          this.onlyMine = value.onlyMine;
        }
      },
    },
    pFilters() {
      return this.getStatusGroupFromList(this.pFilterStatus);
    },
    queryParams() {
      const filters = this.currentViewMeta.staticFilter || this.pFilters;

      return {
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        search: this.search || undefined,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        // moderation_status: this.moderationStatus,
        site_id: this.currentBrand,
        only_mine: this.onlyMine || undefined,
        ...filters,
      };
    },
    statusMetaFiltered() {
      return this.getStatusMetaForEntities('segment');
    },
    setFilterStatus() {
      const timeout = 0; // 500 для мультиселекта

      return this.$_.debounce((value) => {
        this.filterStatus = value;
        this.getSegments();
      }, timeout);
    },
    isCreateSegment() {
      const isUser = !this.isSuperUser && this.$store.getters['auth/currentAcl'].crm_segments_edit !== 'deny';
      return this.isSuperUser || isUser;
    },
    viewsMeta() {
      return [
        {
          name: 'segments',
          staticFilter: null,
          title: this.$t('crm.segments.title'),
          hasCreateButton: true,
        },
        {
          name: 'quality-assurance-segments',
          staticFilter: {
            status: ['active'],
            moderation_status: ['on_review'],
          },
          title: this.$t('crm.segments.title_quality_assurance'),
          hasCreateButton: false,
        },
      ];
    },
    viewsMetaIndex() {
      const result = {};
      this.viewsMeta.forEach((item) => {
        result[item.name] = item;
      });
      return result;
    },
    currentViewMeta() {
      return this.viewsMetaIndex[this.view] || this.viewsMetaIndex.activities;
    },
    view() {
      return this.$route.meta.view;
    },
    isSuperUser() {
      return this.$store.getters['auth/adminAcl'].is_superuser;
    },
  },

  watch: {
    currentBrand() {
      if (!this.firstLoad) {
        this.getSegments();
      }
    },
    view() {
      this.addToHistory();
    },
  },

  methods: {
    refreshTable() {
      this.fetch();
    },
    async exportData(row) {
      const file = await this.$api.getSegmentPlayers(row.id);
      const linkBlob = new Blob(
        [file.data],
        { type: file.headers['content-type'] },
      );

      fileSaver(linkBlob, row.name);
    },

    // @Override
    generateQuery() {
      if (!this.currentViewMeta.staticFilter) {
        return queryParamsMixin.methods.generateQuery.call(this);
      }

      const params = {};

      if (this.limit) {
        params.limit = this.limit;
      }

      if (this.page) {
        params.page = this.page;
      }

      if (this.search) {
        params.search = this.search;
      }

      if (this.sortProp) {
        params.sort_column = this.sortProp;
      }

      if (this.sortOrder) {
        params.sort_dir = this.sortOrder;
      }

      // фильтр отсутствует т.к. он статический

      return params;
    },

    fetch() {
      return this.getSegments();
    },

    async getSegments() {
      this.isDataLoading = true;
      this.firstLoad = false;

      try {
        const { data: { payload, misc } } = await this.$api.getSegments(this.queryParams);
        this.count = misc.count;
        this.data = payload;
        this.permissions = this.$_.clone(misc.permissions);
      } finally {
        this.isDataLoading = false;
      }
    },

    paginationHandler(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;

        this.getSegments();
      }
    },

    sortingHandler({ prop, order }) {
      if (order === '') return;

      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }

      this.getSegments();
    },

    openCreateSegmentPopup(type) {
      this.popupType = type;
      this.isOpenCreateSegmentPopup = true;
    },
    openViewSegmentPopup() {
      this.isOpenViewSegmentPopup = true;
    },

    editSegmentPopup(segmentId) {
      this.isOpenViewSegmentPopup = false;
      this.segmentId = segmentId;
      this.openCreateSegmentPopup('edit');
    },

    viewSegmentPopup(segmentId) {
      this.segmentId = segmentId;
      this.openViewSegmentPopup();
    },

    closeSegmentPopup() {
      this.isOpenCreateSegmentPopup = false;
      this.isOpenViewSegmentPopup = false;
      this.segmentId = '';
      this.getSegments();
    },
    handleDelete(row) {
      this.deleteSegmentId = row.id;
      this.confirm.title = this.$t('crm.confirm.delete_segment.title');
      this.confirm.msg = `${this.$t('crm.confirm.delete_segment.msg', { segmentName: row.name })}`;
      this.confirm.type = 'warning';
      this.confirm.action = 'delete';
      this.confirm.actionName = `${this.$t('crm.confirm.delete_segment.action')}`;
      this.$refs.confirm.open();
    },
    async deleteSegment() {
      if (this.isDataLoading) return;

      this.isDataLoading = true;
      await this.$api.deleteSegment(this.deleteSegmentId);
      this.$refs.confirm.close();
      await this.getSegments();
      this.isDataLoading = false;
    },
    isEditable(row) {
      const status = this.detectSegmentStatus(row);
      return this.canEdit(row.id) && (status === 'draft' || status === 'for_rework');
    },
    isDelete(row) {
      return !this.canEdit(row.id) || row.used_in_activities.length > 0;
    },
    canEdit(id) {
      return this.isSuperUser || this.permissions[id].can_edit;
    },
    setSanitizedFilterStatus(value) {
      const sanitizedValue = this.sanitizeStatus(value);
      this.pFilterStatus = sanitizedValue;
      this.setFilterStatus(sanitizedValue);
    },
    getDefaultFilterStatus() {
      return [
        'draft',
        'active',
      ];
    },
  },
};
</script>
