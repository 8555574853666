// auth views
import Login from '@/views/Login.vue';
import TfaSetup from '@/views/TfaSetup.vue';

export default [
  {
    path: '/login/:action',
    name: 'login',
    component: Login,
    props: true,
    meta: {
      type: 'auth',
    },
  },
  {
    path: '/auth/login/:token',
    name: 'login-token',
    props: true,
    meta: {
      type: 'token-auth',
    },
  },
  {
    path: '/2fasetup',
    name: 'tfaSetup',
    component: TfaSetup,
    meta: {
      type: 'auth',
    },
  },
];
