import Vue from 'vue';

const EventBusInstance = new Vue();

export default (App) => {
  if (!App.prototype.$eventBus) {
    Object.defineProperty(App.prototype, '$eventBus', {
      value: EventBusInstance,
      writable: false,
    });
  }
};
