<template>
  <div
    v-loading="statsLoader || hideStats"
    class="segment-info"
  >
    <div class="card card__title">
      <div class="title">
        <div class="title-content">
          <span>{{ $t('crm.segments.stats.players') }}</span>
          <span class="ui-d-flex">
            <span
              v-if="segmentType === 'edit'
                || (activitySegmentType === 'global' && showFiltersMode === 'edit')"
              class="action"
              @click="refreshStats()"
            >
              <ui-icon
                name="sync-alt"
                color="#20815e"
                lib="fa"
              />
              {{ $t('crm.segments.stats.refresh') }}
            </span>
            <span
              v-if="(segmentType === 'view' && moderationStatus === 'approved')"
              class="action ui-m-sm-l"
              style="margin-left: 8px"
              @click="exportData()"
            >
              <ui-icon
                name="down"
                :color="$theme.accentColor"
                lib="fa-6"
                substyle="fa-solid"
              />
              {{ $t('mediaBuying.info.files_card.download') }}
            </span>

          </span>
        </div>
      </div>
      <div class="card-content">
        <div class="field">
          <span class="label">{{ stats.players_count || '0' }}</span>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="title">
        <div class="title-content">
          <span>{{ $t('crm.segments.stats.statistics') }}</span>
          <span>{{ $t('crm.segments.stats.avg_med') }}</span>
        </div>
      </div>
      <div class="card-content">
        <div class="field">
          <span class="label">{{ $t('crm.segments.stats.deposit') }}</span>
          <div class="values">
            {{ stats.deposits_all_avg || '$0.00' }} / {{ stats.deposits_all_median || '$0.00' }}
          </div>
        </div>
        <div class="field">
          <span class="label">{{ $t('crm.segments.stats.sport_bet') }}</span>
          <div class="values">
            {{ stats.avg_sports_bet_amount || '$0.00' }} / {{ stats.median_sports_bet_amount || '$0.00' }}
          </div>
        </div>
        <div class="field">
          <span class="label">{{ $t('crm.segments.stats.casino_bet') }}</span>
          <div class="values">
            {{ stats.avg_games_bet_amount || '$0.00' }} / {{ stats.median_games_bet_amount || '$0.00' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SegmentInfo',
  props: {
    stats: {
      type: Object,
      default() {
        return {};
      },
    },
    statsLoader: {
      type: Boolean,
      default() {
        return false;
      },
    },
    playerAttached: {
      type: Boolean,
      default() {
        return false;
      },
    },
    segmentType: {
      type: String,
      default() {
        return 'edit';
      },
    },
    activitySegmentType: {
      type: String,
      default() {
        return '';
      },
    },
    moderationStatus: {
      type: String,
      default() {
        return '';
      },
    },
    state: {
      type: String,
      default() {
        return '';
      },
    },
    showFiltersMode: {
      type: String,
      default: 'none',
    },

  },

  computed: {
    hideStats() {
      return !(this.segmentType === 'edit'
        || (this.activitySegmentType === 'global' && this.showFiltersMode === 'edit'))
        && this.activitySegmentType !== 'global' && !this.playerAttached;
    },
  },
  methods: {
    refreshStats() {
      this.$emit('refreshStats');
    },

    exportData() {
      this.$emit('exportData');
    },
  },
};
</script>
