export default {
  mainColor: 'green',
  logoName: 'betpari-logo.svg',
  maxLogoHeight: '28px',
  accentColor: '#e27900',
  activeColor: '#e27900',
  successColor: '#20815e',
  dangerColor: '#cb2f43',
  defaultLanguage: 'en',
  uiTheme: {
    primaryColor: '#e27900',
    dangerColor: '#cb2f43',
    infoColor: '#3b94e3',
    warningColor: '#ffce06',
    lightColor: '#fff',
    darkColor: '#303634',
    // the-header
    headerColor: '#0c121e',
    headerBorder: '#555555',
    headerText: '#ffffff',
    headerTextHover: '#fca311',
    headerTextBorder: '#fca311',
    reportDropdownTitleColor: '#ffffff', // default #4d997e;
    reportDropdownTitleBorder: '#fca311', // default: #9ecaba;
    reportDropdownBackground: '#e27900',
    reportDropdownBackgroundHover: '#B46106',
    reportDropdownActive: '#000000',
    reportDropdownActiveHover: '#ffffff',
    scrollThumb: '#c3c3c3',
    scrollBackground: '#fca311',
    colorFirst: '#1C7C21',
    colorSecond: '#ffce05',
    backLink: '#e27900',
  },
};
