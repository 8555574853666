<template>
  <div
    :class="{'ui-table__data': data.length !== 0}"
    :style="{ paddingBottom: !showTotal ? `${padding}px` : 'none' }"
    class="ui-table"
  >
    <transition name="fade">
      <sortable-el-table handle=".drag">
        <el-table
          v-show="!isLazyLoading"
          ref="dataTable"
          :data="data"
          :default-sort="getDefaultSort"
          :empty-text="computedEmptyText"
          :height="isStatic ? externalHeight || 'auto' : tableHeight || 'auto'"
          :highlight-current-row="rowSelectable"
          :max-height="isStatic ? externalHeight || 'auto' : tableHeight || 'auto'"
          :row-class-name="computedRowClass"
          :header-row-class-name="headerRowClass"
          :show-header="showHeader"
          :show-summary="showTotal"
          :summary-method="getSummaryRow"
          border
          @current-change="handleCurrentRowChange"
          @expand-change="handleExpand"
          @row-click="handleCurrentRowClick"
          @row-contextmenu="contextMenuHandler"
          @sort-change="handleSort"
          @header-dragend="headerDragend"
        >
          <template v-if="checkedRow">
            <el-table-column
              :render-header="renderCheck"
              :width="columnCheckboxWidth"
              class-name="col-checkbox"
            >
              <template
                slot="header"
                slot-scope="scope"
              >
                <Vnodes :vnodes="renderCheck($createElement, scope)" />
              </template>
              <template slot-scope="scope">
                <el-checkbox-group
                  v-if="selectType === 'checkbox' && hiddenCheckFoo(scope.row)"
                  v-model="checkRows"
                  :disabled="scope.row.disabled"
                >
                  <div class="row-value check">
                    <el-checkbox
                      :label="scope.row[checkedRowId]"
                      @change="changeRow(scope.row[checkedRowId])"
                    />
                  </div>
                </el-checkbox-group>
                <el-radio-group
                  v-if="selectType === 'radio' && hiddenCheckFoo(scope.row)"
                  v-model="checkRows[0]"
                >
                  <el-radio
                    class="hide-label"
                    :label="scope.row[checkedRowId]"
                    @change="changeRow(scope.row[checkedRowId])"
                  />
                </el-radio-group>
              </template>
            </el-table-column>
          </template>
          <slot
            :formatValue="formatValue"
            :renderHeader="renderHeader"
            name="columns"
          >
            <!-- {{ fields }} -->
            <!-- "field.name || field.order" -->
            <el-table-column
              v-for="(field) in $_.filter(fields, field => $_.isUndefined(field.show) ? true : field.show)"
              :key="field.order + field.name + field.forceUpdateKey || ''"
              :column-key="field.name"
              :fixed="field.fixed || false"
              :align="field.align || 'left'"
              :formatter="field.formatter"
              :header-align="field.headerAlign || 'left'"
              :label="getLabel(field)"
              :min-width="field.minWidth || 'auto'"
              :prop="field.name"
              :show-overflow-tooltip="true"
              :sortable="disableSort || customSorting ? false : field.sortable || false"
              :class-name="field.className"
              :type="field.checkbox || ''"
              :width="field.width || 'auto'"
              :resizable="field.resizable"
            >
              <template
                slot="header"
                slot-scope="scope"
              >
                <!-- <Vnodes :vnodes="renderHeader($createElement, scope)" /> -->
                <render-header
                  :column="scope.column"
                  :fields="fields"
                  :custom-sorting="customSorting"
                  :currency="currency"
                  :sort-prop="sortProp"
                  :sort-order="sortOrder"
                  :last-open-filter="lastOpenFilter"
                  :column-filters="columnFilters"
                  :is-filters-init="isFiltersInit"
                  :custom-filter-column="customFilterColumn"
                  :custom-filter-column-created="customFilterColumnCreated"
                  :check-apply-filter="checkApplyFilter"
                  @lastOpenFilter="(val) => lastOpenFilter = val"
                  @handleSort="handleSort"
                  @handlerColumnFilterInput="handlerColumnFilterInput"
                  @handlerDatepickerRange="handlerDatepickerRange"
                  @setCheckApplyFilter="(val) => checkApplyFilter = val"
                  @filter="handlerFilter"
                  @handlerRadioBtn="handlerRadioBtn"
                  @changeColumnFilters="(val) => columnFilters = val"
                  @handlerRangeValue="handlerRangeValue"
                  @changeExclude="changeExclude"
                />
              </template>
              <template slot-scope="scope">
                <img
                  v-if="field.computedImg && field.computedImg(scope.row) !== null && field.computedImgNull"
                  :class="field.class"
                  :src="field.computedImg(scope.row)"
                  alt=""
                >
                <div
                  v-else-if="field.computedImg && field.computedImg(scope.row) === null && field.computedImgNull"
                  :class="field.class"
                  v-html="field.computedImgNull(scope.row[field.name], scope.row, field)"
                />
                <div
                  v-else-if="field.renderValue"
                  :class="[field.class, field.computedClass ? field.computedClass(scope.row[field.name], scope.row) : '']"
                  class="row-value"
                  v-html="field.renderValue(scope.row[field.name], scope.row, field)"
                />
                <div
                  v-else
                  v-loading="field.computedLoad && field.computedLoad(scope.row)"
                  :element-loading-text="field.loadLabel"
                  :class="[field.class, field.computedClass ? field.computedClass(scope.row[field.name], scope.row) : '']"
                  class="row-value"
                  @click="field.click ? field.click(scope.row) : ''"
                >
                  <div v-if="field.computedLoad && field.computedLoad(scope.row)" />
                  <router-link
                    v-else-if="field.link && field.link(scope.row) !== false && field.computedValue"
                    :to="field.link(scope.row)"
                    class="link"
                  >
                    {{ field.computedValue(scope.row, scope.row[field.name]) }}
                  </router-link>
                  <router-link
                    v-else-if="field.link && field.link(scope.row) !== false && !field.format"
                    :to="field.link(scope.row)"
                    class="link"
                  >
                    {{ scope.row[field.name] }}
                  </router-link>
                  <router-link
                    v-else-if="field.link && field.link(scope.row) !== false && field.format"
                    :to="field.link(scope.row)"
                    class="link"
                  >
                    {{ field.format ? formatValue(scope.row[field.name], field.format, field.formatOptions, scope.row) :
                      scope.row[field.name] }}
                  </router-link>
                  <span
                    v-else-if="field.computedValue"
                    :style="{
                      color: field.controlType === 'icon' && field.controlColor(scope.row)
                    }"
                  >{{ field.computedValue(scope.row, scope.row[field.name]) }}</span>
                  <span
                    v-else-if="field.computedValueHtml"
                    v-html="field.computedValueHtml(scope.row, scope.row[field.name])"
                  />
                  <span v-else-if="!field.linkAppend">
                    {{ field.format
                      ? formatValue(scope.row[field.name], field.format, field.formatOptions, scope.row, field.tableType, field)
                      : scope.row[field.name] }}
                  </span>

                  <vnodes
                    v-if="field.linkAppend"
                    :vnodes="field.linkAppend(scope.row)"
                  />
                  <span
                    v-if="field.action && field.controlType === 'link'"
                    class="act blue"
                    @click="field.action(scope.row)"
                  >{{ field.actionName }}</span>

                  <el-tooltip
                    :open-delay="300"
                    placement="top"
                    :popper-class="field.popperClass"
                    :content="$t(field.tooltip)"
                    :disabled="!field.tooltip"
                  >
                    <ui-icon
                      v-if="field.action && field.controlType === 'icon'"
                      :color="field.controlColorIco(scope.row)"
                      :lib="field.actionLib"
                      :name="typeof field.actionName === 'string' ? field.actionName : field.actionName(scope.row)"
                      class="act"
                      @click.native="field.action(scope.row)"
                    />
                  </el-tooltip>

                  <flag
                    v-if="field.format === 'ip-flag'"
                    :iso="scope.row.country_code"
                    :title="scope.row.country_name"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="actions"
              :align="actions.align || 'center'"
              :label="actions.name"
              :width="actions.width || 'auto'"
            >
              <template slot-scope="scope">
                <div class="row-value actions">
                  <template v-for="action in actions.operations">
                    <template v-if="action.link === 'link'">
                      <router-link
                        :key="action.name"
                        :class="action.class"
                        :to="action.callback(scope.row)"
                      >
                        {{ action.name }}
                      </router-link>
                    </template>
                    <span
                      v-else-if="action.computedValue"
                      :key="action.name"
                      :class="action.class"
                      v-html="action.computedValue(scope.row)"
                    />
                    <span
                      v-else
                      :key="action.name"
                      :class="action.class"
                      @click="action.callback(scope.row, action.callbackOptions, $event)"
                    >{{ action.name }}</span>
                    <span
                      :key="action.name + '1'"
                      class="divider"
                    />
                  </template>
                </div>
              </template>
            </el-table-column>
          </slot>
          <slot name="append" />
        </el-table>
      </sortable-el-table>
    </transition>
    <lazy-loading-table
      v-if="isLazyLoading"
      :lazy-top="lazyTop"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { setTimeout } from 'timers';
import numeral from 'numeral';
import LazyLoadingTable from './uiCustomComponent/LazyLoadingTable';
import renderHeaderMixin from './UiTableHelper/renderHeaderMixin';
import RenderHeader from './UiTableHelper/RenderHeader';
import SortableElTable from '@/components/SortableElTable.vue';

export default {
  name: 'UiTable',

  components: {
    SortableElTable,
    LazyLoadingTable,
    RenderHeader,
    Vnodes: {
      functional: true,
      render: (h, ctx) => (ctx.props.vnodes ? ctx.props.vnodes : ''),
    },
  },

  mixins: [renderHeaderMixin],

  props: {
    randomValue: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: '',
    },
    resizable: {
      type: Boolean,
      default: true,
    },
    switchDefaultCheck: {
      type: Boolean,
      default: true,
    },
    columnCheckboxWidth: {
      type: Number,
      default: 31,
    },
    hiddenCheckBox: {
      type: [Boolean, String],
      default: false,
    },
    // скрывает чекбокс для выбава всех полей
    showCheckAll: {
      type: Boolean,
      default: true,
    },
    selectRow: {
      type: Boolean,
      default: false,
    },
    lazyTop: {
      type: Number,
      default: -20,
    },
    lazyLoading: {
      type: Boolean,
      default: null,
    },
    lazyLoadingDelay: {
      type: Number,
      default: 3000,
    },
    emptyText: {
      type: String,
      default() {
        return '';
      },
    },
    checkedRow: {
      type: Boolean,
      default: false,
    },
    checkedRowId: {
      type: String,
      default: 'id',
    },
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Object,
      default() {
        return {};
      },
    },
    customTotalRow: {
      type: Array,
      default() {
        return [];
      },
    },
    sort: {
      type: Object,
      default() {
        return {};
      },
    },
    i18nPath: {
      type: String,
      default: '',
    },
    minTableHeight: {
      type: Number,
      default: 400,
    },
    padding: {
      type: Number,
      default: 40,
    },
    rowsCount: {
      type: Number,
      default: 0,
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    externalHeight: {
      type: [Number, String],
      default: '',
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    selectType: {
      type: String,
      default: 'checkbox',
    },
    actions: {
      type: [Object, Array],
      default: null,
    },
    rowClick: {
      type: Function,
      default: () => {},
    },
    rowSelectable: {
      type: Boolean,
      default: false,
    },
    colFilters: {
      type: Object,
      default: () => {},
    },
    customContext: {
      type: Boolean,
      default: false,
    },
    computedRowClass: {
      type: Function,
      default() {
        return '';
      },
    },
    customSorting: {
      type: Boolean,
      default: false,
    },
    headerRowClass: {
      type: String,
      default: '',
    },
    offsetColumn: {
      type: Number,
      default: 0,
    },
    debounceDelay: {
      type: Number,
      default: 300,
    },
    defaultSelectedRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableHeight: 0,
      tableStatus: {
        height: false,
        rows: false,
      },
      sortProp: '',
      sortOrder: '',
      columnFilters: {},
      lastOpenFilter: '',
      isFiltersInit: false,
      computedRowsCount: 0,
      lastSortProp: this.sort.prop,
      lastSortOrder: this.sort.order,
      checkRows: this.defaultSelectedRows,
      checkAll: false,
      customFilterColumn: [
        'players_avg_lifetime_seconds',
        'players_median_lifetime_seconds',
        'players_avg_fd_since_registration_seconds',
        'players_median_fd_since_registration_seconds',
      ],
      customFilterColumnCreated: ['created_at', 'review_requested_at'],
      isLazyLoading: false,
      // eslint-disable-next-line
      lazyLoadingTable: this.lazyLoading,
      /*
      * для сброса фильтров по колонкам при закрытии
      * */
      checkApplyFilter: false,
      activeFilter: '',
      activeColumnFilters: {},
    };
  },
  computed: {
    computedEmptyText() {
      if (this.emptyText === '') {
        return this.$t('ui.table.no_data');
      }
      return this.emptyText;
    },

    tableReady() {
      return this.tableStatus.height && this.tableStatus.rows;
    },
    getDefaultSort() {
      return {
        prop: this.sortProp,
        order: `${this.sortOrder}ending`,
      };
    },
    ...mapGetters({
      countries: 'misc/countries',
      groups: 'misc/groups',
      currencies: 'misc/currencies',
    }),
  },
  watch: {
    defaultSelectedRows() {
      this.checkRows = this.$_.cloneDeep(this.defaultSelectedRows);
    },
    lastOpenFilter(v) {
      if (v === '') {
        Array.from(document.querySelectorAll('.cell.hover-once')).map(e => e.classList.remove('hover-once'));
        if (!this.checkApplyFilter) {
          this.columnFilters[this.activeFilter] = this.activeColumnFilters[this.activeFilter];
          this.columnFilters[this.activeFilter].visible = false;
        }
      } else {
        this.activeFilter = v;
        this.activeColumnFilters = this.$_.cloneDeep(this.columnFilters);
      }
    },
    data: {
      deep: true,
      handler(v) {
        this.lazyLoadingTable = this.lazyLoading;
        this.checkSelect(v);
        /*
         * Чтобы повторно не вызывать lazy loader
         * */
        if (!this.$_.isNull(this.lazyLoadingTable)) {
          this.lazyLoadingTable = null;
          setTimeout(() => {
            this.isLazyLoading = false;
            if (this.$refs.dataTable) {
              this.$nextTick(() => {
                /*
                 * Правим макет таблицы
                 * */
                this.$refs.dataTable.doLayout();
                this.$emit('update');
              });
            }
          }, 400);
        }
      },
    },
    sort: {
      deep: true,
      handler(nv) {
        if (this.customSorting) {
          this.sortProp = nv.prop;
          this.sortOrder = nv.order;
        } else {
          this.sortProp = nv.prop;
          this.sortOrder = nv.order;
          this.$refs.dataTable.sort(this.sortProp, `${this.sortOrder}ending`);
          this.$refs.dataTable.doLayout();
          this.$nextTick(() => {
            this.$refs.dataTable.sort(this.sortProp, `${this.sortOrder}ending`);
            this.$refs.dataTable.doLayout();
          });
        }
      },
    },
    fields: {
      deep: true,
      handler() {
        this.isFiltersInit = false;
        this.fields.forEach((field) => {
          if (
            field.filterable
            && (this.$_.isEmpty(this.columnFilters[field.name])
              || !this.columnFilters[field.name].op)
          ) {
            this.$set(this.columnFilters, field.name, {
              visible: false,
              op: '',
              value: '',
              valueRange: ['', ''],
              isExclude: false,
              valueSec: '',
              dateFilter: '',
              dateRange: null,
            });
          }
        });
        /*
         * вынес true из цикла. Так и не понял зачем его туда посавили мб по невнимательности BWADMN-898
         * */
        this.isFiltersInit = true;
      },
    },
    colFilters: {
      deep: true,
      handler(nv) {
        this.setColumnFilters(nv);
      },
    },
  },
  created() {
    if (!this.isStatic) {
      this.debouncedTableHeight = this.$_.debounce(
        this.computeTableHeight,
        this.debounceDelay,
      );
    }


    if (this.sort.prop || this.disableSort) {
      this.sortProp = '';
    } else {
      this.sortProp = this.fields[0] ? this.fields[0].name : '';
    }
    this.sortOrder = this.sort.order || this.disableSort ? '' : 'asc';
    document.addEventListener('click', (e) => {
      if (this.lastOpenFilter) {
        let targetEl = e.target;
        do {
          if (targetEl.classList && targetEl.classList.contains('el-popover')) {
            return;
          }
          targetEl = targetEl.parentNode;
        } while (targetEl);
        this.columnFilters[this.lastOpenFilter].visible = false;
        this.lastOpenFilter = '';
      }
    });

    if (!this.$_.isEmpty(this.colFilters)) {
      this.columnFilters = this.$_.cloneDeep(this.colFilters);
    }
    if (!this.$_.isNull(this.lazyLoading) && this.lazyLoading !== false) {
      this.isLazyLoading = true;
    }

    this.$eventBus.$on('clear-check', this.clearCheck);
  },
  mounted() {
    if (!this.isStatic) {
      setInterval(() => {
        this.debouncedTableHeight();
      }, 350);
      this.debouncedTableHeight();
      window.addEventListener('resize', this.debouncedTableHeight);
      this.$eventBus.$on('connection-enabled', this.debouncedTableHeight);
      this.$watch('tableHeight', (nv, ov) => {
        if (nv !== ov) {
          this.computeRowsCount();
        }
      });
    }
    this.$emit('init');
  },
  beforeDestroy() {
    if (!this.isStatic) {
      window.removeEventListener('resize', this.debouncedTableHeight);
    }
    this.$eventBus.$off('clear-check', this.clearCheck);
  },
  updated() {
    this.checkSelect(this.data);
    if (!this.isStatic) {
      this.debouncedTableHeight();
    }
  },
  methods: {
    getCurrencyLabel(code = this.currency) {
      if (!code) {
        return '$';
      }

      const currency = this.currencies.find((el => el.code === code));
      return currency?.symbol || `${code} `;
    },
    changeExclude(col, value) {
      this.columnFilters[col].isExclude = value;
    },

    handlerFilter(filter, action) {
      this.$emit('filter', filter, action);
    },

    handlerRadioBtn(col, value) {
      this.columnFilters[col].op = value;
      if (value === '=') this.columnFilters[col].dateRange = null;
      if (Array.isArray(this.columnFilters[col].dateRange)) {
        this.columnFilters[col].dateRange = null;
      }
    },

    handlerDatepickerRange(col, date) {
      this.$set(this.columnFilters[col], 'dateRange', date);
    },

    handlerRangeValue(type, col, value) {
      if (type === 'dataRange[0]') {
        this.$set(this.columnFilters[col].valueRange, 0, value);
      } else {
        this.$set(this.columnFilters[col].valueRange, 1, value);
      }
    },

    handlerColumnFilterInput(type, col, value) {
      if (type === 'num') {
        this.columnFilters[col].value = value;
      } else if (type === 'date') {
        this.columnFilters[col].valueSec = value;
        this.setTimeMoment(col);
      } else if (type === 'select') {
        this.columnFilters[col].dateFilter = value;
        this.setTimeMoment(col);
      }
    },

    headerDragend(columnWidth, startLeft_startColumnLeft, column, event) {
      this.$emit('headerDragend', columnWidth, startLeft_startColumnLeft, column, event);
    },
    getLabel(field) {
      if (this.i18nPath) {
        if (field.label) return this.$t(`${this.i18nPath}.fields.${field.label}.title`);

        return this.$te(`${this.i18nPath}.fields.${field.name}.title`) ? this.$t(`${this.i18nPath}.fields.${field.name}.title`) : '';
      }
      return field.title;
    },

    hiddenCheckFoo(row) {
      if (this.hiddenCheckBox !== false) {
        let hide = true;
        switch (this.hiddenCheckBox) {
        case 'chargeback': {
          if (row.chargebacks_count !== 0) {
            hide = false;
          } else {
            hide = true;
          }
          break;
        }
        default: {
          hide = true;
          break;
        }
        }
        return hide;
      }
      return true;
    },
    changeRowCheck(row) {
      if (this.selectType === 'checkbox') {
        if (row.disabled) {
          return;
        }

        if (this.checkRows.indexOf(row[this.checkedRowId]) === -1) {
          this.checkRows.push(row[this.checkedRowId]);
        } else {
          this.checkRows = this.checkRows.filter(item => item !== row[this.checkedRowId]);
        }
      }

      if (this.selectType === 'radio') {
        if (this.checkRows.indexOf(row[this.checkedRowId]) === -1) {
          this.checkRows = [row[this.checkedRowId]];
        } else {
          this.checkRows = [];
        }
      }

      this.$emit('checkedRows', row[this.checkedRowId], this.checkRows, row);

      this.checkSelect(this.data);
    },
    clearCheck() {
      this.checkRows = [];
    },
    checkSelect(v) {
      switch (this.hiddenCheckBox) {
      case 'chargeback': {
        this.checkAll = this.data.filter(item => item.chargebacks_count === 0).length !== 0;
        const data = v.filter(item => item.chargebacks_count === 0);
        if (data.length === 0) {
          this.checkAll = false;
        }
        data.forEach((e) => {
          if (this.checkRows.indexOf(e[this.checkedRowId]) === -1) {
            this.checkAll = false;
          }
        });
        break;
      }
      default: {
        this.checkAll = this.data.length !== 0;
        if (v.length === 0) {
          this.checkAll = false;
        }
        v.forEach((e) => {
          if (this.checkRows.indexOf(e[this.checkedRowId]) === -1) {
            this.checkAll = false;
          }
        });
        break;
      }
      }
    },
    handleCheckAllChange(val) {
      this.checkRows = val ? this.checkAllFoo() : this.unCheckAllFoo();
      this.changeRow(val ? 'all' : 'alldel');
      this.checkAll = !this.checkAll;
    },
    checkAllFoo() {
      const arrchall = [];

      this.data.forEach((e) => {
        switch (this.hiddenCheckBox) {
        case 'chargeback': {
          if (e.chargebacks_count === 0) {
            arrchall.push(e[this.checkedRowId]);
          }
          break;
        }
        default: {
          if (!e.disabled) {
            arrchall.push(e[this.checkedRowId]);
          }
          break;
        }
        }
      });
      return this.$_.uniqBy(this.$_.concat(this.checkRows, arrchall));
    },
    unCheckAllFoo() {
      const arrchall = [];
      this.data.forEach((e) => {
        if (!e.disabled) {
          arrchall.push(e[this.checkedRowId]);
        }
      });
      return this.$_.remove(
        this.checkRows,
        arr => arrchall.indexOf(arr) === -1,
      );
    },
    changeRow(v) {
      if (v === 'all' || v === 'alldel') {
        this.$emit('checkedRows', v, this.checkRows);
      } else {
        this.checkSelect(this.data);
        this.$emit('checkedRows', v, this.checkRows);
      }
    },
    contextMenuHandler(row, column, event) {
      if (this.customContext) {
        event.preventDefault();
        this.$emit('context', row, event);
      }
    },
    getRowsCount() {
      return this.computedRowsCount;
    },
    setColumnFilters(filters) {
      this.isFiltersInit = false;
      this.columnFilters = {};
      this.fields.forEach((field) => {
        if (field.filterable) {
          this.$set(this.columnFilters, field.name, {
            visible: false,
            op: '',
            value: '',
            valueRange: ['', ''],
            isExclude: false,
            valueSec: '',
            dateFilter: '',
            dateRange: null,
          });
        }
      });

      if (filters) {
        this.columnFilters = this.$_.assign({}, this.columnFilters, filters);
      }
      this.$_.forEach(this.columnFilters, filter => (filter.visible = false));
      this.isFiltersInit = true;
    },
    computeTableHeight() {
      let th = window.innerHeight - this.$el.offsetTop;
      if (!this.showTotal) {
        th -= this.padding;
      }
      if (th !== this.tableHeight) {
        this.tableHeight = th < this.minTableHeight ? this.minTableHeight : th;
        this.tableStatus.height = true;
        this.tableStatus.rows = false;
      }
    },
    computeRowsCount() {
      const theader = this.$refs.dataTable.$el.querySelector('.el-table__header-wrapper');
      const tbody = this.$refs.dataTable.$el.querySelector('.el-table__body-wrapper');
      const ttotal = this.$refs.dataTable.$el.querySelector('.el-table__footer-wrapper');
      const trow = tbody.querySelector('.el-table__row td');
      let bodyHeight = tbody.clientHeight;

      if (this.showTotal && ttotal.offsetHeight === 0) {
        bodyHeight -= 54;
      }
      if (this.showHeader && theader.offsetHeight === 0) {
        bodyHeight -= 36;
      }
      if (tbody.scrollLeftMax > 0) {
        bodyHeight -= 30;
      }
      const rows = Math.floor((bodyHeight / (trow ? trow.offsetHeight : 32)) - this.offsetColumn);
      if (rows > 10 && rows !== this.rowsCount) {
        this.computedRowsCount = rows;
        this.$emit('change-rows-count', rows);
        this.tableStatus.rows = true;
      }
    },
    handleSort({ prop, order }, id) {
      if (this.customSorting) {
        if (this.lastSortProp === prop) {
          this.sortProp = prop;
          this.sortOrder = order;
          this.$emit('sort', {
            prop: this.sortProp,
            order: this.sortOrder,
          });
          // remove 'asc' 'desc' classes
          const e = document.getElementsByClassName('el-table__header')[0];
          const removeClass = e.getElementsByTagName('th');
          for (let i = 0; i < removeClass.length; i += 1) {
            removeClass[i].classList.remove('asc', 'desc');
          }
          this.lastSortProp = prop;
          this.lastSortOrder = order;
        } else {
          this.sortProp = prop;
          this.sortOrder = this.lastSortOrder;
          this.$emit('sort', {
            prop: this.sortProp,
            order: this.lastSortOrder,
          });
          // remove 'asc' 'desc' classes
          const e = document.getElementsByClassName('el-table__header')[0];
          const removeClass = e.getElementsByTagName('th');
          for (let i = 0; i < removeClass.length; i += 1) {
            removeClass[i].classList.remove('asc', 'desc');
          }
          this.lastSortProp = prop;
        }
        // add class
        const addClass = document.getElementsByClassName(id)[0];
        if (addClass.classList) {
          addClass.classList.add(this.sortOrder);
        }
        this.$refs.dataTable.clearSort();
      } else {
        if (this.lastOpenFilter) {
          this.columnFilters[this.lastOpenFilter].visible = false;
          this.lastOpenFilter = '';
        }
        if (prop && order) {
          if (
            prop !== this.sortProp
            || order.substring(0, order.length - 6) !== this.sortOrder
          ) {
            this.sortProp = prop;
            this.sortOrder = order.substring(0, order.length - 6);
          } else {
            return;
          }
        } else {
          this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
          this.$refs.dataTable.sort(this.sortProp, `${this.sortOrder}ending`);
          this.$refs.dataTable.doLayout();
          this.$nextTick(() => {
            this.$refs.dataTable.sort(this.sortProp, `${this.sortOrder}ending`);
            this.$refs.dataTable.doLayout();
          });
        }
        this.$emit('sort', {
          prop: this.sortProp,
          order: this.sortOrder,
        });
      }
    },
    getSummaryRow(param) {
      if (this.customTotalRow && this.customTotalRow.length > 0) {
        return this.customTotalRow;
      }
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        switch (index) {
        case (this.checkedRow ? 1 : 0): {
          sums[index] = this.$t('ui.table.total');
          break;
        }
        default: {
          sums[index] = '';
          const field = this.fields.find(el => el.name === column.property);
          if (field) {
            sums[index] = field.format
              ? this.formatValue(
                this.total[field.name],
                field.format,
                field.formatOptions,
              )
              : this.total[field.name];
          }
          break;
        }
        }
      });
      return sums;
    },
    renderCheck(h, { column }) {
      const _self = this;
      return this.showCheckAll
        ? h(
          'div',
          {
            class: [
              'custom-header',
              _self.customSorting && column.property === _self.sortProp
                ? _self.sortOrder
                : '',
            ],
          },
          [
            h('el-checkbox', {
              class: 'checkbox',
              props: {
                value: this.checkAll,
              },
              on: {
                change(val) {
                  _self.handleCheckAllChange(val);
                },
              },
            }),
          ],
        )
        : '';
    },

    setTimeMoment(field) {
      switch (this.columnFilters[field].dateFilter) {
      case 'minutes':
        this.columnFilters[field].value = +this.columnFilters[field].valueSec * 60;
        break;
      case 'hours':
        this.columnFilters[field].value = +this.columnFilters[field].valueSec * 3600;
        break;
      case 'days':
        this.columnFilters[field].value = +this.columnFilters[field].valueSec * 86400;
        break;
      case 'weeks':
        this.columnFilters[field].value = +this.columnFilters[field].valueSec * 86400 * 7;
        break;
      default:
        this.columnFilters[field].value = +this.columnFilters[field].valueSec;
      }
    },
    formatValue(value, format, options, row, type, field) {
      if (type === 'report_table' && value === null) {
        return '—';
      }
      if (!value && value !== 0) {
        return '';
      }
      switch (format) {
      case 'device':
        return 'hello';
      case 'date': {
        const m = value._isAMomentObject
          ? value
          : this.$moment(
            value,
            !this.$_.isEmpty(options) && options.in ? options.in : '',
          );
        return m.isValid()
          ? m
            .locale(this.$i18n.locale)
            .format(!this.$_.isEmpty(options) && options.out
              ? options.out
              : 'DD.MM.YYYY HH:mm')
          : value;
      }
      case 'format-date': {
        const moment = this.$moment(value);
        return moment.isValid()
          ? moment.locale(this.$i18n.locale).format('DD MMM YYYY')
          : '-';
      }
      case 'humanizeDuration': {
        if (value === 0) {
          return 0;
        }
        const hd = this.$moment.duration(value, 'seconds');
        const tm = function tmC(d, v) {
          if (v === 'years') {
            return d === 0 ? '' : `${d} years `;
          }
          if (v === 'months') {
            return d === 0 ? '' : `${d} months `;
          }
          if (v === 'days') {
            return d === 0 ? '' : `${d} days `;
          }
          if (v === 'hours') {
            return `${d}`.length === 1 ? `0${d}:` : `${d}:`;
          }
          if (v === 'minutes') {
            return `${d}`.length === 1 ? `0${d}:` : `${d}:`;
          }
          if (v === 'seconds') {
            return `${d}`.length === 1 ? `0${d}` : `${d}`;
          }
          return '';
        };
        let res = '';
        ['years', 'months', 'days', 'hours', 'minutes', 'seconds'].forEach((key) => {
          res += `${tm(hd._data[key], key)}`;
        });
        return res;
      }
      case 'date-time': {
        const moment = this.$moment(value);
        return moment.isValid()
          ? moment.locale(this.$i18n.locale).format('DD MMM YYYY HH:mm')
          : '-';
      }
      case 'number':
        return `${parseFloat(value).toLocaleString('en-US')}`;
      case 'percent':
        return `${parseFloat(value).toLocaleString('en-US')}%`;
      case 'odd':
        return `${parseFloat(parseFloat(value).toFixed(4)).toLocaleString('en-US')}`;
      case 'currency':
        return `${this.getCurrencyLabel(row?.currency_code)}${value < 0 ? '-' : ''}${Math.abs(this.$_.floor(value / 100, 0)).toLocaleString('en-US')}`;
      case 'formatMoney':
        return `${this.getCurrencyLabel(this.$_.get(row, [field?.currencyKey || 'currency_code']))}${value < 0 ? '-' : ''}${numeral(Math.abs(value)).format('0,0.00')}`;
      case '-formatMoney':
        return `${this.getCurrencyLabel(row?.currency_code)}-${numeral(Math.abs(value)).format('0,0.00')}`;
      case 'formatOriginalMoney':
        return `${this.getCurrencyLabel(row?.original_currency_code)}${value < 0 ? '-' : ''}${numeral(Math.abs(value)).format('0,0.00')}`;
      case 'ncurrency':
        return `${this.getCurrencyLabel(row?.currency_code)}${value < 0 ? '-' : ''}${Math.abs(this.$_.floor(value, 0)).toLocaleString('en-US')}`;
      case '-ncurrency':
        return `${this.getCurrencyLabel(row?.currency_code)}${value === 0 ? '' : '-'}${Math.abs(this.$_.floor(value, 0)).toLocaleString('en-US')}`;
      case 'country': {
        if (this.countries.length) {
          const c = this.countries.filter(country => country.code === value);
          return c ? c[0].name : value;
        }
        return value;
      }
      case 'group': {
        const g = this.groups.filter(group => group.id === value);
        return g ? g[0].name : value;
      }
      case 'eventType': {
        if (value === 1) {
          return 'Single';
        } if (value === 0) {
          return '';
        }
        return `Express-${value}`;
      }
      case 'event': {
        if (!Array.isArray(value)) return value;
        if (value.length === 1) {
          return value[0];
        } if (value.length > 1) {
          return `Express(${row.events_count}/${row.coefficient})`;
        }
        return value;
      }
      default:
        return value;
      }
    },
    handleExpand(row, expRows) {
      this.$emit('expand-change', row, expRows);
    },
    handleCurrentRowChange(current) {
      this.$emit('current-change', current);
    },
    handleCurrentRowClick(row, column, e) {
      this.rowClick(row);
      this.$emit('row-click', row);
      if (this.selectRow) {
        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }
        if (this.switchDefaultCheck) this.changeRowCheck(row);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row-value {
  /deep/ {
    .el-loading-mask {
      background-color: inherit;
    }

    .el-loading-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-top: 0;
      transform: translateY(-50%);

      .circular {
        height: 100%;
        width: auto;
        .path {
          stroke: #20815e;
        }
      }
    }

    .el-loading-text {
      font-size: 12px;
      margin-left: 4px;
      color: #20815e;
    }
  }

  .divider:last-child {
    display: none;
  }
}
.ip-flex {
  display: flex;
  justify-content: space-between;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
.ui-table {
  .row-value.check {
    .el-checkbox__label {
      display: none;
    }
  }

  .col-checkbox .cell {
    height: 100%;

    .hide-label .el-radio__label {
      display: none;
    }
  }

  .custom-header.no-sort {
    pointer-events: none;
  }
}
.lazy-loader {
  position: relative;
}
.el-tooltip__popper.light-tooltip {
  border: none;
  border-radius: 8px;
  color: #303634;
  background-color: #fff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .2);
  padding: 14px 16px;
  font-size: 14px;

  .popper__arrow {
    border-top-color: #fff;

    &::after {
      border-top-color: #fff;
    }
  }
}

.el-table .el-table__row .cell .row-value {
  &.right-action-icon {
    align-items: center;

    .link {
      margin-right: 16px;
      overflow: hidden;
    }

    .ui-icon {
      position: absolute;
      right: 8px;
    }
  }
}
</style>
