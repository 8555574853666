<template>
  <div>
    <slot name="title">
      <div class="ui-m-xl-t">
        <span class="upload-title">{{ title }}</span>
      </div>
    </slot>
    <div class="full-width">
      <div class="row">
        <div class="field">
          <el-upload
            ref="upload"
            v-loading="isFilesLoading"
            class="upload"
            :class="{ disabled: disabled || files.length >= limitFile || isFilesLoading, error }"
            :accept="accept"
            :disabled="disabled || files.length >= limitFile"
            drag
            action=""
            :file-list="files"
            :http-request="uploadFilesAction.bind(this)"
            :on-remove="removeFile"
            :auto-upload="true"
            :limit="limitFile"
          >
            <div class="el-upload-container" :class="{ disabled: disabled || files.length >= limitFile}">
              <div class="el-upload-text" :class="{ disabled: disabled || files.length >= limitFile || isFilesLoading }">
                <slot name="upload-text">
                  {{ $t('crm.segments.manual.upload.dropFileHereOr') }} <em>{{ $t('crm.segments.manual.upload.selectFile') }}</em>
                </slot>
              </div>
            </div>
            <template v-slot:file="{ file }">
              <div class="file-container" :class="{ loading: isFilesLoading }">
                <ui-icon
                  :size="12"
                  color="#303634"
                  lib="fa"
                  substyle="far"
                  name="file"
                  class="icon-rows"
                />
                <div class="el-upload__tip">
                  {{ file.name }}
                </div>

                <ui-icon
                  v-if="!isFilesLoading"
                  :size="14"
                  lib="fa"
                  :color="$theme.accentColor"
                  substyle="far"
                  name="check"
                  class="icon-action check"
                />
                <ui-icon
                  v-if="!isFilesLoading"
                  :size="14"
                  lib="fa"
                  substyle="far"
                  name="xmark"
                  class="icon-action remove"
                  @click.native="removeFile(file)"
                />
              </div>
            </template>
          </el-upload>
          <div
            v-if="error"
            class="drop-area-error"
          >
            {{ error }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadFile',
  model: {
    prop: 'files',
    event: 'change',
  },
  props: {
    error: {
      type: String,
      default() {
        return '';
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    files: {
      type: Array,
      default() {
        return [];
      },
    },
    limitFile: {
      type: Number,
      default() {
        return null;
      },
    },
    title: {
      type: String,
      default() {
        return this.$t('payments.uploadFilesPopup.title');
      },
    },
    accept: {
      type: String,
      default() {
        return '.pdf, .doc, .docx, .png, .jpg';
      },
    },
    uploadFilesAction: {
      type: Function,
      default(file) {
        this.isFilesLoading = true;
        const formData = new FormData();
        formData.append('files', file.file);
        formData.append('is_temporary', true);

        this.$api
          .postFiles(formData)
          .then((response) => {
            this.files.push({
              name: file.file.name,
              uid: file.file.uid,
              uploadId: response.data.payload.files.id,
            });

            this.$emit('change', this.files);
          })
          .catch(() => {
            this.$emit('change', []);
          })
          .finally(() => {
            this.isFilesLoading = false;
          });
      },
    },
    removeFile: {
      type: Function,
      default(file) {
        const fileId = this.files.findIndex(({ uid }) => uid === file.uid);
        if (fileId !== -1) {
          this.files.splice(fileId, 1);
        }
        this.$emit('change', this.files);
      },
    },
  },
  data() {
    return {
      isFilesLoading: false,
    };
  },
  watch: {
    isFilesLoading(val) {
      this.$emit('is-loading', val);
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.drop-area-error {
  margin-top: 6px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  font-style: italic;
  text-align: right;
  color: var(--danger-color);
}
.el-upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  .el-upload-text {
    width: 100%;
    color: #7d7d7d;
    font-size: 13px;

    em {
      color: #20815e;
    }

    &.disabled {
      em {
        color: #7d7d7d;
      }

    }
  }
}
.upload-title {
  font-size: 14px;
}

.upload {
  margin-top: 8px;

  &.error {
    /deep/ {
      .el-upload-dragger {
        border: 1px dashed var(--danger-color);
      }
    }
  }

  &.disabled {
    /deep/ {
      .el-upload-dragger {
        cursor: default;
      }

      .el-upload-dragger:hover, .el-upload:focus .el-upload-dragger {
        border: 1px dashed #d9d9d9;
      }
    }
  }

  .file-container {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    cursor: pointer;

    &.loading {
      pointer-events: none;
    }

    &:hover .icon-action {
      &.check {
        display: none;
      }

      &.remove {
        display: block;
      }
    }

    .icon-action {
      position: absolute;
      right: 20px;

      &.remove {
        display: none;
      }
    }
  }

  /deep/ {
    .el-upload-dragger, .el-upload {
      width: 100%;
    }
    .el-upload-text em {
      color: var(--primary-color);
    }

    .el-upload:focus {
      border-color: var(--primary-color);
      color: var(--primary-color);
      .el-upload-dragger {
        border-color: var(--primary-color);
      }
    }

    .el-upload__tip {
      margin-top: 0;
      margin-left: 4px;
      color: #303634;
      padding-right: 30px;
    }

    .el-upload-dragger {
      height: auto;
      padding: 30px;
      &:hover {
        border-color: var(--primary-color);
      }
      &:focus {
        border-color: var(--primary-color);
      }
      &:focus-visible {
        border-color: var(--primary-color);
      }
    }
  }
}
</style>
