<template>
  <div
    :class="[
      theme,
      size,
      color,
      {'disabled': disabled},
      {'loading': loading},
      {'filled': filled},
      {'empty': isEmpty},
      {'transparent-background': transparentBackground}
    ]"
    class="ui-button"
    @click="handleClick"
  >
    <ui-icon
      v-if="icon"
      :name="icon"
      :color="getIconColor"
      :lib="lib"
      :substyle="substyle"
      :size="getIconSize"
      class="btn-icon"
    />
    <span
      v-if="!isEmpty"
      class="btn-text"
    ><slot /></span>
    <div
      v-if="loading"
      class="loading-cover"
    >
      <ui-icon
        :color="getIconColor"
        :size="getIconSize + 4"
        name="loading"
        class="spinner"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'green',
    },
    theme: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'medium',
    },
    filled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: [String, Number],
      default: '',
    },
    substyle: {
      type: String,
      default: 'fas',
    },
    lib: {
      type: String,
      default: 'icofont',
    },
    iconColor: {
      type: String,
      default: '#303634',
    },
    transparentBackground: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isBtnDisabled() {
      return this.disabled || this.loading;
    },
    getIconSize() {
      if (this.iconSize) {
        return this.iconSize;
      }
      switch (this.size) {
      case 'medium':
        return 12;
      case 'big':
        return 14;
      default:
        return 12;
      }
    },
    getIconColor() {
      if (this.filled) {
        switch (this.color) {
        case 'red':
        case 'green':
          return '#fff';
        case 'white':
          return '#ffffff';
        default:
          break;
        }
      } else {
        switch (this.color) {
        case 'red':
          return this.$theme.dangerColor;
        case 'green':
          return '#303634';
        case 'white':
          return '#ffffff';
        default:
          break;
        }
      }
      return '';
    },
    isEmpty() {
      return !this.$slots.default;
    },
  },
  methods: {
    handleClick(e) {
      if (this.isBtnDisabled) {
        return;
      }
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-button{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .3s ease 0s;
  user-select: none;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
  box-sizing: border-box !important;
  position: relative;
  font-weight: 700;
  padding: 8px 16px;
  // states
  &.disabled{
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: .5;
    .btn-text{
      opacity: .8;
    }
  }
  &.loading{
    cursor: wait;
    .btn-text{
      opacity: 0;
    }
    .btn-icon{
      opacity: 0;
    }
  }
  .loading-cover{
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // sizes
  &.medium{
    font-size: 12px;
    line-height: 12px;
    .btn-icon{
      margin-right: 4px;
    }
  }
  &.big{
    font-size: 14px;
    line-height: 14px;
    .btn-icon{
      margin-right: 4px;
    }
  }
  // themes
  &.default{
    border-radius: 5px;
  }
  &.rounded{
    border-radius: 100px;
  }
  &.brick{
    border-radius: 0;
  }
  &.circle{
    border-radius: 100%;
  }
  // colors
  &.yellow{
    border-color: #ffce06;
    &:hover{
      background-color: transparentize(#ffce06, .9);
    }
    &.filled{
      background-color: #ffce06;
      .btn-text{
        color: #000;
      }
    }
  }
  &.green{
    border-color: #20815e;
    &:hover{
      background-color: transparentize(#20815e, .9);
    }
    &.filled{
      background-color: #20815e;
      i{
        color: #fff !important;
      }
      .btn-text{
        color: #fff;
      }
    }
  }
  &.red{
    &.filled{
      .btn-text{
        color: #fff;
      }
    }
  }
  &.medium{
    height: 30px;
  }
  &.big{
    height: 34px;
  }

  // empty state
  &.empty{
    padding: 0;
    &.medium{
      width: 30px;
      height: 30px !important;
    }
    &.big{
      width: 34px;
      height: 34px;
    }
    .btn-icon{
      margin-right: 0;
    }
  }
  &.transparent-background {
    background-color: transparent;
  }
}
</style>
