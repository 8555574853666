<template>
  <div v-if="isOpen" class="rotation-group-affiliates-popup">
    <div class="popup">
      <div class="head">
        <span class="name">{{ initalData.name }}</span>
        <ui-icon
          :size="20"
          class="close"
          lib="fa"
          substyle="fas"
          name="times"
          @click.native="close"
        />
      </div>
      <div class="form full-width">
        <div class="row">
          <div class="field">
            <span class="label-text">{{ $t('affiliates.name') }}</span>
          </div>
        </div>
        <ui-table
          v-loading="isDataLoading"
          :fields="fields"
          :data="tableData"
          class="table"
          i18n-path="affiliates.list"
          element-loading-custom-class="data-loader-spinner"
        />
      </div>
      <div class="foot">
        <ui-button
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          size="big"
          @click="save"
        >
          {{ $t(`domainManagement.popup.close`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
export default {
  name: 'RotationGroupAffiliatesPopup',

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },

    initalData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      tableData: [],
      isDataLoading: false,
      fields: [
        {
          name: 'email',
          minWidth: '300',
          link: this.getAffiliateLink,
        },
      ],
    };
  },

  computed: {},

  watch: {
    async isOpen(action) {
      if (action) {
        await this.getAffiliatesWithStats();
      } else {
        this.tableData = [];
      }
    },
  },

  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },

  destroyed() {
    window.removeEventListener('keyup', this.keyupHandler);
  },

  methods: {
    async getAffiliatesWithStats() {
      this.isDataLoading = true;
      const params = {
        sort_column: 'email',
        sort_dir: 'asc',
        platform_service_rotation_group_id: this.initalData.id,
      };

      try {
        const {
          data: { payload },
        } = await this.$api.getAffiliatesWithStats(params);
        this.tableData = payload;
      } finally {
        this.isDataLoading = false;
      }
    },

    getAffiliateLink(row) {
      return `/affiliates/${row.id}/info`;
    },

    close() {
      this.$emit('close');
    },

    save() {
      this.close();
    },

    keyupHandler(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss">
.rotation-group-affiliates-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .cancel-button {
    margin-right: 8px;
  }

  .popup {
    position: absolute;
    z-index: 902;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid #eaeaea;
      padding: 16px 24px;
      box-sizing: border-box;
      margin-bottom: 0;

      .name {
        font-size: 20px;
        font-weight: 500;
      }

      .close {
        color: #303634;
        padding: 5px;
        font-size: 32px;
        cursor: pointer;
      }
    }
    .form {
      padding: 16px 24px;
      box-sizing: border-box;
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      margin-top: 0;
    }
    .el-table th.ascending,
    .el-table th.is-leaf.ascending {
      color: #ffffff !important;
    }
  }

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
</style>
