<template>
  <div
    v-if="isOpen"
    class="crm-add-push-action-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">{{ 'Push Action' }}</span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div
        v-loading="isLoading"
        class="body"
      >
        <div class="ui-fg-1">
          <ui-input
            v-model="form.name"
            class="form-input ui-m-xl-b"
            :label="'Action Name'"
            autosize
            size="big"
            :placeholder="'Action Name'"
            type="text"
          />

          <div class="select-block ui-m-sm-b">
            <span class="label">{{ 'Text Message' }}</span>
            <el-input
              v-model="form.message"
              :label="'Text Message'"
              type="textarea"
              :rows="6"
              :placeholder="'Text Message'"
            />
          </div>
          <div class="ui-d-flex ui-jc-space-between">
            <crm-action-params @select="handleAddParam" />
            <span class="crm-add-sms-action-popup__statistics">{{ messageStats }}</span>
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="!isActive"
          filled
          @click="handleSave()"
        >
          <i class="fas fa-check plus-icon-custom" />{{ $t('crm.buttons.save_action') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import { SMSCalculator } from '@fasttrack-solutions/sms-calculator';
import CrmActionParams from '@/views/CRM/components/CrmActionParams.vue';

export default {
  name: 'AddPushActionPopup',
  components: {
    CrmActionParams,
  },
  props: {},

  data() {
    return {
      isOpen: false,
      isLoading: false,
      form: {},
      index: 0,
      actionGroup: null,

      isActive: true,
    };
  },

  computed: {
    messageStats() {
      const stats = SMSCalculator.getCount(this.form.message);
      return `${stats.numberOfSMS} SMS, ${stats.remaining} Symbols Remaining`;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    exportData() {},
    open(actionGroup, index) {
      this.actionGroup = actionGroup;
      this.form = index >= 0
        ? this.$_.cloneDeep(actionGroup.actions[index])
        : this.getDefaultForm();
      this.index = index;
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.$emit('closePopupSegment');
      this.form = {};
      this.actionGroup = null;
    },
    handleSave() {
      this.$emit('save', this.actionGroup, this.form, this.index);
      this.close();
    },
    handleAddParam(param) {
      this.form.message += `{${param}}`;
    },
    getDefaultForm() {
      return {
        name: '',
        type: 'push',
        message: '',
      };
    },
  },
};
</script>
