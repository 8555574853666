import Dashboards from '@/views/Dashboards.vue';
import DashboardView from '@/views/Dashboard/DashboardView.vue';
import DashboardTemplate from '@/views/Dashboard/DashboardTemplate.vue';
import AddDashboard from '@/views/Dashboard/AddDashboard.vue';

export default [
  {
    path: '/dashboards',
    name: 'dashboards',
    component: Dashboards,
    meta: {
      requiresAuth: true,
      view: 'dashboards',
    },
    children: [
      {
        path: 'overview',
        component: DashboardView,
        name: 'dashboards_overview',
        meta: {
          view: 'overview',
          permission: {
            list: ['reports_view'],
          },
        },
      },
      {
        path: 'view/:id',
        component: DashboardView,
        name: 'dashboards_overview',
        meta: {
          view: 'overview-view',
          permission: {
            list: ['reports_view'],
          },
        },
      },
      {
        path: 'add-dashboard',
        component: AddDashboard,
        name: 'add_dashboard',
        meta: {
          view: 'add-dashboard',
          permission: {
            list: ['reports_view'],
          },
        },
      },
      {
        path: 'template',
        component: DashboardTemplate,
        name: 'dashboard_template',
        meta: {
          view: 'template',
          permission: {
            list: ['reports_view'],
          },
        },
      },
      {
        path: '',
        redirect: 'overview',
      },
      {
        path: '*',
        redirect: 'overview',
      },
    ],
  },
];
