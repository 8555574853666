<template>
  <label
    :class="[
      size,
      {'search': config.isSearch},
      {'borderless': config.borderless},
      {'disabled': config.disabled},
      {'required': config.required},
      {'clearable': config.clearable},
      {'focus': focused},
      {'filled': !!currentValue},
      {'error': currentError},
      {'with-icon': config.preIcon || config.postIcon},
      {'with-post-icon': config.postIcon},
      {'with-post-text': config.postText},
    ]"
    :style="{ width: getInputWidth }"
    class="ui-input"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <span
      v-if="config.label"
      class="label-text"
    >{{ config.label }}</span>
    <el-tooltip
      placement="top"
      :class="{'error-tooltip-input': errorTooltip}"
      :content="currentError"
      :disabled="!(currentError && errorTooltip && !focused)"
    >
      <span class="input-wrapper">
        <ui-icon
          v-if="config.preIcon"
          :name="config.preIcon"
          :size="getIconSize"
          :color="config.isSearch ? '#7d7d7d' : ''"
          lib="fa"
          substyle="fas"
          class="input-pre-icon"
        />
        <input
          v-if="config.inputMask"
          ref="input"
          v-mask="config.inputMask"
          :tabindex="config.tabindex"
          :placeholder="config.placeholder"
          :readonly="config.readonly"
          :type="config.type || 'text'"
          :disabled="config.disabled"
          :value="currentValue"
          class="input-element"
          @input="handleInput"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
        >
        <input
          v-else
          ref="input"
          :tabindex="config.tabindex"
          :placeholder="config.placeholder"
          :readonly="config.readonly"
          :type="config.type || 'text'"
          :disabled="config.disabled"
          :value="currentValue"
          class="input-element"
          @input="handleInput"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
          @keydown="handleKeydown"
        >
        <span
          v-if="config.clearable"
          :class="{'show-clear': showClear}"
          class="input-post-icon clear-btn"
          @click="clear"
        >
          <ui-icon
            :size="12"
            lib="fa"
            substyle="fas"
            name="times"
          />
        </span>
        <ui-icon
          v-else-if="config.postIcon"
          :name="config.postIcon"
          :size="getIconSize"
          class="input-post-icon"
        />
        <span
          v-else-if="config.postText"
          class="input-post-text"
        >{{ config.postText }}</span>
      </span>
    </el-tooltip>
    <span
      v-if="currentError && !errorTooltip"
      class="input-error"
    >{{ currentError }}</span>
  </label>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  name: 'UiInput',
  directives: { mask },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    searchTimer: {
      type: Number,
      default: 500,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: [String, Number],
      default: '',
    },
    error: {
      type: [String, Number, Array],
      default: '',
    },
    errorTooltip: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    width: {
      type: [String, Number],
      default: 0,
    },
    autosize: {
      type: Boolean,
      default: false,
    },
    preIcon: {
      type: String,
      default: '',
    },
    postIcon: {
      type: String,
      default: '',
    },
    postText: {
      type: String,
      default: '',
    },
    tabindex: {
      type: Number,
      default: 1,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    inputMask: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      focused: false,
      hovered: false,
      currentValue: '',
      currentError: null,
      config: {},
    };
  },
  computed: {
    getIconSize() {
      if (this.config.isSearch) {
        return 12;
      }
      switch (this.config.size) {
      case 'medium':
        return 12;
      case 'big':
        return 16;
      default:
        return 12;
      }
    },
    getInputWidth() {
      if (this.config.autosize) {
        return '100%';
      }
      if (this.config.width) {
        return this.$_.isNumber(this.config.width)
          ? `${this.config.width}px`
          : this.config.width;
      }
      switch (this.config.size) {
      case 'medium':
        return '180px';
      case 'big':
        return '280px';
      default:
        return '180px';
      }
    },
    showClear() {
      return (
        this.config.clearable
        && !this.config.disabled
        && !this.config.readonly
        && this.currentValue !== ''
        && (this.focused || this.hovered)
      );
    },
  },
  watch: {
    '$i18n.locale': function locale() {
      if (this.config.isSearch) {
        this.config.placeholder = this.$t('ui.input.search');
      }
    },

    value: {
      handler(nv, ov) {
        if (nv !== ov) {
          this.currentValue = nv === undefined || nv === null ? '' : nv.toString();
        }
      },
      deep: true,
    },
    error(nv, ov) {
      if (nv !== ov) {
        this.currentError = this.$_.isArray(nv) ? nv[0].message : nv;
      }
    },
    _props: {
      deep: true,
      immediate: true,
      handler() {
        this.initConfig();
      },
    },
    currentValue(nv, ov) {
      if (nv !== ov && this.config.isSearch) {
        this.debouncedSearch();
      }
    },
  },
  created() {
    this.currentValue = this.value;
    this.currentError = this.$_.isArray(this.error)
      ? this.error[0].message
      : this.error;
  },
  methods: {
    initConfig() {
      this.config = this.$_.cloneDeep(this._props);
      if (this.config.isSearch) {
        if (!this.debouncedSearch) {
          this.debouncedSearch = this.$_.debounce(() => {
            this.$emit('search', this.currentValue);
          }, this.config.searchTimer);
        }
        if (!this.config.placeholder) {
          this.config.placeholder = this.$t('ui.input.search');
        }
        this.config.preIcon = 'search';
        this.config.size = 'big';
        this.config.borderless = true;
        this.config.clearable = true;
      }
      this.$emit('init');
    },
    clear() {
      this.$emit('input', '');
      this.$emit('change', '');
      this.$emit('clear');
      this.currentValue = '';
      this.focus();
    },
    handleInput(event) {
      const { value } = event.target;
      this.currentValue = value;
      this.$emit('input', value);
    },
    handleChange(event) {
      const { value } = event.target;
      this.$emit('change', value);
    },
    handleKeydown(event) {
      this.$emit('change', event);
    },
    handleFocus(event) {
      this.focused = true;
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.focused = false;
      if (this.required && !event.target.value) {
        this.currentError = 1000;
        this.$emit('error', this.currentError);
      }
      this.$emit('blur', event);
    },
    focus() {
      this.$refs.input.focus();
    },
    blur() {
      this.$refs.input.blur();
    },
    select() {
      this.$refs.input.select();
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-input {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease 0s;
  box-sizing: border-box;

  //elements
  .input-wrapper {
    display: inline-flex;
    align-items: center;
    border-style: solid;
    border-color: #d3d3d3;
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    transition: all 0.2s;
    .input-element {
      display: flex;
      align-items: center;
      height: 100%;
      min-width: 10%;
      max-width: 100%;
      flex: 10 1 auto;
      box-sizing: border-box;
      border: none;
      outline: none;
      padding: 0;
      background: transparent;
      // font-family: "Roboto", serif;
    }
    .clear-btn {
      opacity: 0;
      transition: all 0.2s;
      cursor: pointer;
      &.show-clear {
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .input-error {
    align-self: flex-end;
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    font-style: italic;
    text-align: right;
  }

  // sizes
  &.medium {
    .label-text {
      font-size: 14px;
      height: 14px;
      line-height: 14px;
      font-weight: 500;
      color: #303634;
      margin-bottom: 8px;
    }
    .input-wrapper {
      height: 30px;
      border-radius: 5px;
      border-width: 1px;
      .input-element {
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px;
      }
      .input-pre-icon {
        width: 12px;
        margin-left: 10px;
      }
      .input-post-icon {
        width: 12px;
        margin-right: 10px;
      }
    }
    .input-error {
      margin-top: 4px;
    }
  }
  &.big {
    .label-text {
      font-size: 14px;
      height: 14px;
      line-height: 14px;
      margin-bottom: 5px;
    }
    .input-wrapper {
      height: 34px;
      border-radius: 5px;
      border-width: 1px;
      .input-element {
        font-size: 14px;
        padding-left: 8px;
        padding-right: 8px;
      }
      .input-pre-icon {
        width: 16px;
        margin-left: 16px;
      }
      .input-post-icon {
        width: 16px;
        margin-right: 8px;
      }
    }
    .input-error {
      margin-top: 6px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  // states
  &.borderless {
    .input-wrapper {
      border: none;
      border-radius: 0;
    }
  }
  &.with-post-icon {
    .input-element {
      margin-right: 8px;
      border-right: 1px solid #d3d3d3;
    }
  }
  &.with-post-text {
    .input-element {
      margin-right: 4px;
      border-right: 1px solid #d3d3d3;
    }
    .input-post-text {
      margin-right: 4px;
    }
  }
  &.search {
    margin-right: 8px;
    .input-wrapper {
      height: 30px;
      border: 1px solid #d3d3d3;
      border-radius: 5px;
      padding: 0 12px;
      .input-element {
        padding: 0 8px;
        font-size: 12px;
      }
      .input-pre-icon {
        margin: 0;
      }
      .input-post-icon {
        margin: 0;
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &.focus {
    .input-wrapper {
      border-color: #20815e;
    }
  }
  &.required {
    .label-text {
      &:after {
        content: "*";
        margin-left: 2px;
      }
    }
  }

}
</style>
