<template>
  <div class="item-card-breadcrumbs">
    <div
      v-for="(item, index) in items"
      :key="item.link"
      class="item-card-breadcrumbs__item"
      :class="{
        'item-card-breadcrumbs__item_dynamic': item.dynamic,
      }"
      :title="item.dynamic ? item.title : undefined"
    >
      <template v-if="index < items.length - 1">
        <router-link
          class="item-card-breadcrumbs__link"
          :to="item.link"
        >
          {{ item.title }}
        </router-link>

        <span class="item-card-breadcrumbs__divider">></span>
      </template>

      <div
        v-else
        class="item-card-breadcrumbs__inactive-link"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemCardBreadcrumbs',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
  .item-card-breadcrumbs {
    display: flex;
    font-size: 16px;
    margin-bottom: 16px;
    flex-grow: 1;

    &__item {
      white-space: nowrap;

      &_dynamic {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & + & {
        margin-left: 8px;
      }
    }

    &__link {
      color: #20815e;
      text-decoration: none;
    }

    &__divider {
      margin-left: 8px;
    }

    &__inactive-link {
      font-weight: 700;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
