<template>
  <div
    v-if="isOpen"
    class="crm-add-mail-action-preview-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ $t('crm.activities.actions.action_popup.mail_title') }}
          <template v-if="actions && actions[index] && actions[index].email_subject">
            ({{ actions[index].email_subject }})
          </template>
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div
        v-loading="isLoading"
        class="body"
      >
        <div class="ui-d-flex ui-fg-1 ui-fd-column">
          <div class="ui-d-flex ui-m-xl-r ui-m-lg-b">
            <crm-action-language-tab
              :languages="languages"
              :selected-index="index"
              @select="handleSelectTab"
            />
          </div>
          <ui-iframe
            v-if="currentPreviewState === stateEnum.loaded"
            :html="previewList[index]"
          />
          <div
            v-else
            v-loading="true"
            class="ui-fg-1"
          />
        </div>
      </div>
      <div class="foot">
        <ui-button
          lib="fa"
          substyle="fas"
          icon="paper-plane"
          :disabled="isActionsSendDisabled"
          @click="handleSendTestEmail()"
        >
          {{ $t('crm.buttons.send_test_email') }}
        </ui-button>
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.close') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import CrmActionLanguageTab from '@/views/CRM/components/CrmActionLanguageTab.vue';
import ActivitiesMailActionMixin from '@/views/CRM/mixins/activities-mail-action-mixin.js';
import UiIframe from '@/views/CRM/components/UiIframe.vue';

export default {
  name: 'AddMailActionPreviewPopup',
  components: {
    CrmActionLanguageTab,
    UiIframe,
  },
  mixins: [
    ActivitiesMailActionMixin,
  ],
  data() {
    return {
      isOpen: false,
      isLoading: false,
      index: 0,
      actionGroup: null,
      actionGroups: [],
      actionGroupIndex: -1,
      actions: [],
      emailTemplateId: '',
    };
  },
  watch: {
    currentPreviewStateWatcherParams: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value.currentPreviewState === this.stateEnum.not_loaded) {
          this.loadCurrentPreview();
        }
      },
    },
    index: {
      immediate: true,
      handler() {
        if (this.currentPreviewState !== this.stateEnum.not_avaliable) {
          this.previewList = this.previewList.map((item) => {
            if (item === this.stateEnum.error && item === this.stateEnum.changed) {
              return null;
            }

            return item;
          });
        }
      },
    },
    emailTemplateId: {
      immediate: true,
      handler(value) {
        this.email_template_id = value;
        this.loadCurrentPreview();
      },
    },
  },
  methods: {
    open(actionGroup, payload) {
      const _payload = payload || {};
      this.actionGroup = actionGroup;
      this.actionGroupIndex = this.activity.action_groups.indexOf(actionGroup);

      let actions = this.activity.action_groups[this.actionGroupIndex].actions || [];
      actions = actions.filter(item => item.type === 'email');

      if (!actions.length) {
        actions.push(this.getDefaultForm());
      }

      this.actions = _payload.actions
        ? this.$_.cloneDeep(_payload.actions)
        : this.$_.cloneDeep(actions);

      this.emailTemplateId = this.actions[0].email_template_id;

      this.index = Number.isFinite(_payload.index)
        ? _payload.index
        : 0;

      this.isOpen = true;
      this.previewList = [];
    },
    close() {
      this.isOpen = false;
      this.actionGroup = null;
      this.actionGroups = [];
      this.actionGroupIndex = -1;
      this.previewList = null;
      this.email_template_id = '';
      this.emailTemplateId = '';
    },
  },
};
</script>
