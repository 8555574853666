<template>
  <ui-confirm
    ref="confirm"
    class="crm-activity-actions__confirm-send-test-sms"
    :width="480"
    type="success"
    :action-name="$t('crm.buttons.save')"
    :close-action-name="$t('crm.buttons.cancel')"
    action="send_test_sms"
    action-icon="check"
    :action-disabled="isLoading"
    @save="saveEdit"
  >
    <div
      slot="title"
      v-html="$t('affiliates.public_profile.edit.title')"
    />
    <adm-ui-scrollbar
      wrap-style="max-height: 75vh; height: auto;"
      :native="false"
    >
      <div class="body__wrap">
        <div class="row ui-fg-1">
          <div class="field">
            <ui-input
              v-model="form.name"
              :error="getError('name')"
              :placeholder="$t(`affiliates.public_profile.edit.name`)"
              :label="$t(`affiliates.public_profile.edit.name`)"
              size="big"
              autosize
            />
          </div>
          <div class="field ui-m-xl-t">
            <div v-if="form.contacts.length" class="contacts">
              <div class="column">
                <span class="form-title">{{ $t('affiliates.public_profile.edit.messenger') }}</span>
              </div>
              <div class="column">
                <span class="form-title">{{ $t('affiliates.public_profile.edit.messenger_id') }}</span>
              </div>
            </div>
            <div v-for="(item, i) in form.contacts" :key="i" class="contacts card">
              <div class="column field">
                <el-select
                  v-model="item.type"
                  filterable
                  size="big"
                  class="messenger-select"
                  :class="{ filled: item.type, error: getError(['contacts', i, 'type'])}"
                  :placeholder="$t('affiliates.public_profile.edit.messenger')"
                >
                  <div slot="prefix" class="prefix">
                    <ui-icon
                      v-if="item.type === 'email'"
                      :size="14"
                      lib="fa"
                      :color="$theme.accentColor"
                      substyle="fas"
                      name="envelope"
                      class="ico"
                    />
                    <span v-else class="messenger" :class="item.type" />
                  </div>
                  <el-option
                    v-for="messenger in messengers"
                    :key="messenger.type"
                    :label="messenger.value"
                    :value="messenger.type"
                  />
                </el-select>
              </div>
              <div class="column">
                <ui-input
                  v-model="item.value"
                  :error="getError(['contacts', i, 'value'])"
                  error-tooltip
                  :placeholder="$t('affiliates.public_profile.edit.messenger_id')"
                  size="big"
                  autosize
                />
                <div class="ui-m-md-l" @click="deleteMessenger(i)">
                  <ui-icon
                    lib="fa"
                    name="times"
                    class="pointer"
                    :class="{ disabled: form.contacts.length <= 1 }"
                    :size="14"
                    :color="$theme.dangerColor"
                  />
                </div>
              </div>
            </div>
            <div class="add-messenger" @click="addMessenger">
              <i class="fas fa-plus" />
              <span>{{ $t('affiliates.public_profile.edit.add_messenger') }}</span>
            </div>
          </div>
          <div class="field ui-m-sm-b">
            <div class="textarea-wr">
              <span class="label">{{ $t('affiliates.public_profile.edit.misc') }}</span>
              <el-input
                v-model="form.additional_information"
                class="ui-m-sm-t"
                :maxlength="225"
                :autosize="{ minRows: 4, maxRows: 4}"
                :placeholder="$t('affiliates.public_profile.edit.misc_placeholder')"
                type="textarea"
              />
            </div>
          </div>
        </div>
      </div>
    </adm-ui-scrollbar>
  </ui-confirm>
</template>

<script>
import errorHandleMixin from '@/views/mixins/error-hadle';

export default {
  name: 'EditPublicProfile',
  mixins: [errorHandleMixin],
  props: {
    id: {
      type: String,
      default() {
        return '';
      },
    },
  },

  data() {
    return {
      isLoading: false,
      form: this.defaultData(),
      messengers: [
        {
          type: 'email',
          value: 'Email',
        },
        {
          type: 'telegram',
          value: 'Telegram',
        },
        {
          type: 'skype',
          value: 'Skype',
        },
      ],
    };
  },

  methods: {
    defaultData() {
      return {
        name: '',
        contacts: [{
          type: null,
          value: '',
        }],
        additional_information: '',
      };
    },
    open(data) {
      this.errors = {};
      this.form = this.$_.cloneDeep(data || this.defaultData());
      this.$refs.confirm.open();
    },
    addMessenger() {
      this.form.contacts.push({
        type: null,
        value: '',
      });
    },
    deleteMessenger(i) {
      if (this.form.contacts.length <= 1) return;

      this.form.contacts = [...this.form.contacts.slice(0, i), ...this.form.contacts.slice(i + 1)];
    },
    async saveEdit() {
      if (this.isLoading) return;

      this.isLoading = true;
      this.$api.putAffiliatePublicProfile(this.id, this.form)
        .then(() => {
          this.$emit('update-affiliate');
          this.$refs.confirm.close();
        })
        .catch((e) => {
          this.errors = e.data.errors;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-messenger {
  display: flex;
  align-items: center;
  color: var(--main-color);
  cursor: pointer;
  font-size: 12px;
  margin-top: 16px;

  i {
    font-size: 16px!important;
    line-height: 1.33;
    padding-right: 4px!important;
  }
}

.textarea-wr {
  width: 100%;
  margin-top: 16px;

  .label {
    height: 16px;
    font-size: 14px;
    line-height: normal;
    margin-bottom: 5px;
  }
}

.contacts {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  gap: 16px;

  &.card {
    margin-bottom: 8px;
  }

  .column {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: row;

    &.field {
      padding: 0!important;
    }

    .messenger-select {
      &.filled {
        /deep/ .el-input__inner {
          padding-left: 30px;
        }
      }

      .prefix {
        display: flex;
        height: 100%;
        padding-left: 8px;
      }
    }


    .pointer {
      cursor: pointer;
    }

    .disabled {
      cursor: not-allowed !important;
      opacity: 0.5;
    }

    /deep/ .el-select.error {
      .el-input__inner {
        border-color: var(--danger-color);
      }
    }
  }

  .form-title {
    font-size: 14px;
    line-height: normal;
  }
}
</style>
