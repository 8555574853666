<template>
  <div
    v-loading="isDataLoading"
    class="dedicated-domains"
  >
    <pin-domain-to-affiliate-popup
      ref="pinDomainPopup"
      :is-open-popup="isOpenPinDomain"
      @confirm="pinAffiliate"
    />

    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirm.type"
      :action-name="confirm.actionName"
      :action="confirm.action"
      :data="confirm.data"
      :action-fa-icon="confirm.actionFaIcon"
      @save="actionConfirm"
    >
      <div
        slot="title"
        v-html="confirm.title"
      />
      <div class="body__wrap">
        <i
          :class="confirm.type"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirm.msg"
        />
      </div>
    </ui-confirm>

    <div class="wrapper">
      <div class="subaff-controls">
        <div class="section">
          <span class="title">{{ $t(`domainManagement.pageTitle.dedicatedSiteDomains`) }}</span>
          <ui-input
            v-model.trim="searchQuery"
            :width="180"
            class="subaff-controls__search"
            is-search
            @search="getDedicatedDomain"
          />
        </div>
        <div class="section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="pageChange"
            />
          </div>
          <ui-button
            v-if="canEdit"
            icon="plus"
            lib="fa"
            substyle="fas"
            color="green"
            class="btn pin-button"
            @click="openPinDomainPopup(info)"
          >
            {{ $t('domainManagement.buttons.pin_domain') }}
          </ui-button>
        </div>
      </div>


      <div class="info">
        <ui-table
          v-loading="isDataLoading"
          :fields="fields"
          :data="dataDomain"
          :rows-count="limit"
          :external-height="tableHeight"
          :sort="{
            prop: sortBy,
            order: sortDir,
          }"
          lazy-loading
          is-static
          class="table aff-sub"
          i18n-path="affiliates.dedicated_domains"
          element-loading-custom-class="data-loader-spinner"
          @sort="handleSort"
        >
          <template
            slot="append"
          >
            <el-table-column v-if="canEdit" width="50">
              <template slot-scope="scope">
                <ActionIcon
                  :tooltip="$t('affiliates.affiliatesInfo.unpin')"
                >
                  <div
                    class="action-ico"
                    @click="confirmUnpin(scope.row)"
                  >
                    <ui-icon
                      name="unlink"
                      :color="$theme.dangerColor"
                      lib="fa"
                    />
                  </div>
                </ActionIcon>
              </template>
            </el-table-column>
          </template>
        </ui-table>
      </div>
    </div>
  </div>
</template>

<script>
import PinDomainToAffiliatePopup from '@/components/Popups/PinDomainToAffiliatePopup.vue';
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import updateUrl from '../../../service/updateUrl';

const sizes = {
  1080: 15,
  1440: 25,
};

const viewName = 'affiliate/dedicated_domains/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 10,
  sizes,
});
export default {
  name: 'DedicatedDomains',
  components: {
    PinDomainToAffiliatePopup,
  },
  mixins: [pageSizeMixin],
  data() {
    return {
      viewName,
      id: '',
      info: {},
      permissions: {},
      program: {
        referral_program_enabled: true,
        referral_percent: 0,
        referred_by: '',
      },
      isEditPercentOpen: false,
      editingField: '',
      editingValue: 0,
      isConfirmOpen: false,
      targetAffiliate: {},
      confirmAction: '',
      confirmMsg: '',
      isSelectOpen: false,
      dataDomain: [],
      page: 1,
      limit: pageSize,
      count: 0,
      sortBy: 'created_at',
      sortDir: 'desc',
      searchQuery: '',
      errors: {},
      isDataLoading: true,
      fields: [
        {
          name: 'incrementing_id',
          sortable: 'custom',
          align: 'left',
          width: '60',
        },
        {
          name: 'domain_name',
          label: 'domain_name',
          sortable: 'custom',
          minWidth: '100',
        },
        {
          name: 'affiliate_offer_name',
          label: 'affiliate_offer_name',
          computedValue: row => row.affiliate_offer_name || '—',
        },

        {
          name: 'created_at',
          sortable: 'custom',
          width: '130',
          format: 'date-time',
        },
      ],
      confirm: {
        title: '',
        msg: '',
        type: 'success',
        action: '',
        actionName: '',
        actionFaIcon: '',
        data: {},
      },
      confirmEdit: {
        title: this.$t('affiliates.affiliateSub.confirm.title_1'),
        msg: this.$t('affiliates.affiliateSub.confirm.msg_1'),
        type: 'success',
        actionFaIcon: 'fas fa-check',
        action: '',
        actionName: '',
        data: {},
      },
      isPopupOpen: false,

      isOpenPinDomain: false,
    };
  },

  computed: {
    isSuperUser() {
      return this.$store.getters['auth/adminAcl'].is_superuser;
    },

    canEdit() {
      return (
        this.isSuperUser
        || this.$store.getters['auth/currentAcl'].platform_betting_domains_edit === 'allow'
      );
    },

    tableHeight() {
      if (this.count === 0) {
        return 200;
      }
      return this.count < this.limit ? (this.count * 32) + 38 : (this.limit * 32) + 38;
    },
    offset() {
      return (this.limit * this.page) - this.limit;
    },
    subsQuery() {
      return {
        search: this.searchQuery,
        affiliate_id: this.id,
        limit: this.limit,
        offset: this.offset,
        sort_column: this.sortBy,
        sort_dir: this.sortDir,
      };
    },
  },

  created() {
    /*
    * Проверяем hash
    * */
    const _paramsHash = updateUrl.getParseParams(this.$route.hash);

    if (!this.$_.isEmpty(_paramsHash)) {
      this.searchQuery = this.$_.get(_paramsHash, ['search']) || '';
      this.sortBy = this.$_.get(_paramsHash, ['sort_column']) || 'created_at';
      this.sortDir = this.$_.get(_paramsHash, ['sort_dir']) || 'desc';
      this.limit = Number(this.$_.get(_paramsHash, ['limit'])) || pageSize;
      this.page = Number(this.$_.get(_paramsHash, ['page'])) || 1;
    } else {
      this.limit = +this.$ls.get('affiliate/dedicated_domains/limit') || pageSize;
    }
    this.id = this.$route.params.id;
    if (!this.id) {
      this.$router.push('/affiliates/list');
    }
    this.getDedicatedDomain();
    this.fetchData();

    // this.$eventBus.$on('confirmDeactivate', this.confirmDeactivate);
    this.$eventBus.$on('confirmUnpin', this.confirmUnpin);
  },

  beforeDestroy() {
    // this.$eventBus.$off('confirmDeactivate', this.confirmDeactivate);
    this.$eventBus.$off('confirmUnpin', this.confirmUnpin);
  },

  methods: {
    closePinDomainPopup() {
      this.isOpenPinDomain = false;
    },

    openPinDomainPopup(row) {
      this.isOpenPinDomain = true;
      this.$refs.pinDomainPopup.open(row);
    },

    checkSensitiveAction(action, row) {
      this.$eventBus.$emit('checkSensitiveAction', action, row);
    },

    getAffiliateLink(row) {
      return `/affiliates/${row.id}/info`;
    },

    onClosePopup() {
      this.isPopupOpen = false;
    },

    confirmUnpin(affiliate) {
      this.targetAffiliate = this.$_.clone(affiliate);
      this.confirm.title = `${this.$t('affiliates.affiliateSub.confirm.title_5', { subAffiliateEmail: affiliate.email, affiliateEmail: this.info.email })}`;
      this.confirm.msg = `${this.$t('affiliates.affiliateSub.confirm.msg_5', { subAffiliateEmail: affiliate.email, affiliateEmail: this.info.email })}`;
      this.confirm.type = 'warning';
      this.confirm.actionFaIcon = 'unlink';
      this.confirm.action = 'Unpin';
      this.confirm.actionName = `${this.$t('affiliates.affiliateSub.confirm.action_5')}`;
      this.$refs.confirm.open();
    },

    fetchData() {
      this.isDataLoading = true;
      this.$api
        .getAffiliateInfo(this.id)
        .then((resp) => {
          this.info = this.$_.cloneDeepWith(resp.data.payload);
          this.permissions = this.$_.cloneDeepWith(resp.data.misc.permissions);
          this.program.referral_program_enabled = this.info.referral_program_enabled;
          this.program.referral_percent = this.info.referral_percent;
          this.program.referred_by = this.info.referred_by;
          this.getDedicatedDomain();
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },

    async getDedicatedDomain() {
      this.isDataLoading = true;
      /*
      * Вызываем метод update URL
      * */
      this._completedFilterUrl();
      try {
        const {
          data: { payload, misc },
        } = await this.$api.getPlatformBettingDomain(this.subsQuery);

        this.count = misc.count;
        this.dataDomain = payload;
      } finally {
        this.isDataLoading = false;
      }
    },

    async pinAffiliate(id = this.targetAffiliate.id, affiliate_offer_id = null) {
      await this.$api.updatePlatformBettingDomain(
        id,
        { affiliate_offer_id },
      );
      this.getDedicatedDomain();

      this.closePinDomainPopup();
      this.$refs.confirm.close();
    },

    handleSort({ prop, order }) {
      if (this.sortBy !== prop) {
        this.sortBy = prop;
      } else {
        this.sortDir = order;
      }
      this.getDedicatedDomain();
    },

    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.getDedicatedDomain('pageChange');
      }
    },

    actionConfirm(action) {
      if (action === 'Unpin') {
        this.pinAffiliate();
      }
    },

    /*
    * Создаем filter URL
    * */
    _completedFilterUrl() {
      const _dataFilters = {
        search: this.searchQuery,
        limit: this.limit,
        sort_column: this.sortBy,
        sort_dir: this.sortDir,
        page: this.page,
      };
      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
.dedicated-domains {
  .wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-width: 1140px;
    width: 90%;
  }
}
</style>
