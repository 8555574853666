export default {
  profile: {
    active_sessions: 'Активные сессии',
    terminate_all: 'Завершить все',
    terminate: 'Завершить',
    registration_date: 'Дата регистрации',
    list: {
      fields: {
        device_model: {
          title: 'Устройство',
        },
        os: {
          title: 'ОС',
        },
        user_ip: {
          title: 'IP адрес',
        },
        last_active: {
          title: 'Последняя активность',
        },
      },
    },
    tz_invalid: 'Неверный часовой пояс',
  },
  profile_popup: {
    reset_password_title: 'Изменить пароль',
    reset_password_action: 'Изменить пароль',
    reset_password_old_label: 'Текущий пароль',
    reset_password_old_placeholder: 'Текущий пароль',
    reset_password_new_label: 'Новый пароль',
    reset_password_new_placeholder: 'Новый пароль',
    reset_password_repeat_label: 'Подтвердить новый пароль',
    reset_password_repeat_placeholder: 'Подтвердить новый пароль',

    terminate_title: 'Завершить сессии?',
    terminate_action: 'Завершить',
    terminate_msg: 'Вы уверены, что хотите завершить эту сессию?',

    terminate_title_all: 'Завершить все сессии?',
    terminate_action_all: 'Завершить все',
    terminate_msg_all: 'Вы уверены, что хотите завершить все сессии?',

    setup_2fa: 'Установить 2FA',
    setup_tfa_title: 'Сбросить 2FA',
    setup_tfa_action: 'Сбросить 2FA',
    setup_tfa_text: 'Введите ваш текущий пароль, чтобы установить 2FA',
    setup_tfa_qr_text: 'Откройте приложение Google Authenticator и <span>отсканируйте QR-код</span>',

    repeat_error: 'Пароли не совпадают',
    done_button: 'Готово',
    action_unlock_period: 'Период разблокировки важных действий',
    unlock_period_warning: 'Если вы увеличите период разблокировки важных действий, вы можете столкнуться с рисками в случае несанкционированного доступа к вашему устройству.',
  },
};
