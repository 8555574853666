<template>
  <div class="item-wrap">
    <div class="item--all" @click="clearDrill('all')">
      {{ $t('reports.other.all') }}
    </div>
    <div class="item">
      <i class="fas fa-arrow-right" />
      <div class="item__wrap-gray">
        <i class="fas fa-user item__icon" />
        <div class="item__titile">
          {{ $t('mediaBuying.reports_table_title.fields.affiliate_email.title') }}:
        </div>
        <div class="item__name">
          {{ affiliateEmail }}
        </div>
        <i
          v-if="!mediaBuyingChannelName"
          class="fas fa-times item__icon-close"
          @click="clearDrill"
        />
      </div>
    </div>
    <div v-if="mediaBuyingChannelName" class="item">
      <i class="fas fa-arrow-right" />
      <div class="item__wrap-gray">
        <i class="fas fa-globe item__icon" />
        <div class="item__titile">
          {{ $t('mediaBuying.reports_table_title.fields.root_campaign_name.title') }}:
        </div>
        <div class="item__name">
          {{ mediaBuyingChannelName }}
        </div>
        <i class="fas fa-times item__icon-close" @click="clearDrill" />
      </div>
    </div>
    <div class="item" />
  </div>
</template>

<script>
export default {
  name: 'DrilldownLine',

  props: {
    affiliateEmail: {
      type: String,
      required: true,
    },
    mediaBuyingChannelName: {
      type: String,
      required: true,
    },
  },

  methods: {
    clearDrill(action) {
      this.$emit('clearDrill', action);
    },
  },
};
</script>
