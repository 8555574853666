/**
 * данный метод фильтрует метрики в dashboards (panels[]) которые не доступны данному пользователю в пермишеннах
 */
const filtredMetricIsNotAvailable = (stateDashboards, availebleColumns = [], availebleDimensions = []) => stateDashboards
  .map((dash) => {
    const filters = dash?.filters?.filters || { search: {} };
    /* globalfilter delete not supported  dimensions */
    const parseGlobalFilter = avaalibleDimensionFilterMethod(filters, availebleDimensions);
    /* ////// globalfilter delete not supported  dimensions */

    const rows = dash.rows
      .map((row) => {
        let panels = row.panels.filter((panelItem) => {
          const atLeastOneFalse = [];
          if (panelItem.metric_name !== undefined) {
            atLeastOneFalse.push(availebleColumns.includes(panelItem.metric_name));
            if (!atLeastOneFalse.every(el => el)) return false;
          }
          if (panelItem.series_group_by_metric_name) {
            atLeastOneFalse.push(availebleColumns.includes(panelItem.series_group_by_metric_name));
            if (!atLeastOneFalse.every(el => el)) return false;
          }
          if (typeof (panelItem?.series_group_by_dimension_name) === 'string') {
            atLeastOneFalse.push(availebleDimensions.includes(panelItem.series_group_by_dimension_name));
            if (!atLeastOneFalse.every(el => el)) return false;
          }
          if (typeof (panelItem?.dimension_name) === 'string') {
            atLeastOneFalse.push(availebleDimensions.includes(panelItem.dimension_name));
            if (!atLeastOneFalse.every(el => el)) return false;
          }

          return true;
        });

        panels = panels.map((panelItem) => {
          if (panelItem.type === 'chart') {
            if (panelItem.series && panelItem.series.length) {
              let series = panelItem.series.filter(ser => availebleColumns.includes(ser.metric_name));

              series = series.map((ser) => {
                if (ser.filters?.filters) {
                  const parseSeriesFilter = avaalibleDimensionFilterMethod(ser.filters.filters, availebleDimensions);
                  return { ...ser, filters: { ...ser.filters, filters: parseSeriesFilter } };
                }
                return ser;
              });

              return { ...panelItem, series: series.length ? series : null };
            }
          }
          return panelItem;
        });

        panels = panels.map((panelItem) => {
          if (panelItem.filters?.filters) {
            const parsePanelFilter = avaalibleDimensionFilterMethod(panelItem.filters.filters, availebleDimensions);
            return { ...panelItem, filters: { ...panelItem.filters, filters: parsePanelFilter } };
          }
          return panelItem;
        });

        panels = panels.filter(panelItem => panelItem.series !== null);

        return { ...row, panels };
      })
      .filter(row => row.panels.length !== 0);
    return { ...dash, filters: { ...dash.filters, filters: parseGlobalFilter }, rows };
  })
  .filter(dash => dash.rows.length !== 0);


function avaalibleDimensionFilterMethod(data, availebleDimensions) {
  const dataFilter = data || {};
  const keyFiltersDimensions = Object.keys(dataFilter);
  const filtersDimensions = dataFilter;
  const filtersDimensionsNew = keyFiltersDimensions.reduce((acc, keyFilter) => {
    if (availebleDimensions.includes(keyFilter)) {
      acc[keyFilter] = filtersDimensions[keyFilter];
      return acc;
    }
    return acc;
  }, {});
  return filtersDimensionsNew;
}

export default filtredMetricIsNotAvailable;
