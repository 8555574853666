import FingerprintJS from '@fingerprintjs/fingerprintjs';

const fpPromise = FingerprintJS.load({
  monitoring: false,
});

export default async (Vue) => {
  try {
    const fp = await fpPromise;
    const result = await fp.get();

    window.AffCtrlUserId = result && result.visitorId ? result.visitorId : '';
  } catch (error) {
    window.console.error(error);
  }
  Vue.$api.affiliatesApi.defaults.headers.common['X-User-Id'] = window.AffCtrlUserId || '';
  Vue.$api.miscApi.defaults.headers.common['X-User-Id'] = window.AffCtrlUserId || '';
};
