<template>
  <div
    v-if="isOpen"
    class="domain-registrar-account-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">{{ $t(`domainManagement.popup.${actionName}`) }} {{ $t(`domainManagement.popup.domainRegistrarAccount`) }}</span>
        <ui-icon
          :size="20"
          class="close"
          lib="fa"
          substyle="fas"
          name="times"
          @click.native="close"
        />
      </div>
      <adm-ui-scrollbar
        wrap-style="max-height: 75vh; height: auto;"
        :native="false"
      >
        <div class="form full-width">
          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.account_name.label`) }} <span class="required">*</span></span>
              <ui-input
                v-model="name"
                :placeholder="$t(`domainManagement.popup.form.account_name.placeholder`)"
                autosize
                :class="{ error: isInvalidField('name') }"
                @blur="touchField('name')"
              />
            </div>
          </div>

          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.domin_registrar_provider.label`) }} <span class="required">*</span></span>
              <el-select
                v-model="domain_registrar_id"
                :placeholder="$t(`domainManagement.popup.form.domin_registrar_provider.placeholder`)"
                filterable
                class="select"
                :class="{ error: isInvalidField('domain_registrar_id') }"
                @blur="touchField('domain_registrar_id')"
              >
                <el-option
                  v-for="domainRegistrarProvider in domainRegistrarProviders"
                  :key="domainRegistrarProvider.id"
                  :label="domainRegistrarProvider.name"
                  :value="domainRegistrarProvider.id"
                />
              </el-select>
            </div>
          </div>

          <div class="row">
            <div class="field">
              <span class="label-text">{{ $t(`domainManagement.popup.form.auto_ns_management.label`) }}</span>

              <el-radio
                v-model="auto_ns_management"
                :label="true"
                :disabled="domainRegistrar.auto_ns_management_available === false"
              >
                {{ $t(`domainManagement.popup.form.auto_dns_management.auto`) }}
              </el-radio>
              <el-radio
                v-model="auto_ns_management"
                :label="false"
              >
                {{ $t(`domainManagement.popup.form.auto_dns_management.manual`) }}
              </el-radio>
            </div>
          </div>

          <div v-if="auto_ns_management && authenticationArrayFields && authenticationArrayFields.length">
            <el-divider />

            <h3>
              {{ $t(`domainManagement.popup.form.authentication_data`) }}
            </h3>

            <div
              v-for="(authenticationFields, index) in authenticationArrayFields"
              :key="index"
              class="row"
            >
              <div
                v-for="authenticationField in authenticationFields"
                :key="authenticationField"
                class="field"
              >
                <span class="label-text">{{ $t(`domainManagement.popup.form.${authenticationField}.label`) }} <span v-if="activeGroup[index]" class="required">*</span></span>
                <ui-input
                  v-model="auth_data[authenticationField]"
                  :placeholder="$t(`domainManagement.popup.form.${authenticationField}.placeholder`)"
                  :class="{ error: isInvalidField(`auth_data.${authenticationField}`) }"
                  autosize
                  :disabled="!activeGroup[index]"
                  @blur="touchField(`auth_data.${authenticationField}`)"
                />
              </div>
              <el-divider v-if="authenticationArrayFields.length - 1 !== index" />
            </div>
          </div>
        </div>
      </adm-ui-scrollbar>
      <div class="foot">
        <ui-button
          color="red"
          class="btn cancel-button"
          lib="fa"
          substyle="fas"
          icon="times"
          size="big"
          @click.native="close"
        >
          {{ $t(`domainManagement.popup.cancel`) }}
        </ui-button>
        <ui-button
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          size="big"
          :disabled="$v.$invalid"
          @click="save"
        >
          {{ $t(`domainManagement.popup.save`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  required, minLength, maxLength, requiredIf,
} from 'vuelidate/lib/validators';
import validateMixin from './mixin/validate';


export default {
  name: 'DomainRegistrarAccountPopup',

  mixins: [validateMixin],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    initalData: {
      type: [Object],
      default() {
        return {
          name: '',
          domain_registrar_id: null,
          auto_ns_management: true,
          auth_data: {},
        };
      },
    },
  },

  data() {
    return {
      name: this.initalData.name,
      domain_registrar_id: this.initalData.domain_registrar_id,
      auto_ns_management: this.initalData.auto_ns_management,
      auth_data: this.initalData.auth_data,
    };
  },

  computed: {
    ...mapGetters('misc', ['domainRegistrarProviders']),
    activeGroup() {
      const isEmptyValues = Object.values(this.auth_data).every(el => !el);

      return this.authenticationArrayFields.map((child) => {
        if (isEmptyValues) return true;
        return child.some(el => !!this.auth_data[el]);
      });
    },

    authenticationArrayFields() {
      if (this.domain_registrar_id) {
        return this.domainRegistrar.possible_auth_keys;
      }
      return [];
    },

    domainRegistrar() {
      if (this.domain_registrar_id && this.domainRegistrarProviders) {
        const [domainRegistrarProvider] = this.domainRegistrarProviders.filter(provider => provider.id === this.domain_registrar_id);
        return domainRegistrarProvider;
      }
      return {};
    },

    actionName() {
      return (!this.initalData.id) ? 'add' : 'edit';
    },
  },

  validations() {
    // All available authData fields is required! Example: { api_key: { required }, client_id: { required } }
    const authDataRules = (this.authenticationArrayFields || []).reduce((acc, authenticationFields, i) => {
      authenticationFields.forEach((authenticationField) => {
        acc[authenticationField] = { required: requiredIf(() => this.auto_ns_management && this.activeGroup[i]) };
      });

      return acc;
    }, {});

    return {
      name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255),
      },
      domain_registrar_id: {
        required,
      },
      auth_data: authDataRules,
    };
  },

  watch: {
    domain_registrar_id(value) {
      if (value !== this.initalData.domain_registrar_id) {
        this.auth_data = {};

        if (this.domainRegistrar.auto_ns_management_available === false) {
          this.auto_ns_management = false;
        }
      }
    },
    isOpen() {
      [
        this.name,
        this.domain_registrar_id,
        this.auto_ns_management,
        this.auth_data,
      ] = [
        this.initalData.name,
        this.initalData.domain_registrar_id,
        this.initalData.auto_ns_management,
        this.initalData.auth_data,
      ];
    },
  },

  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },

  destroyed() {
    window.removeEventListener('keyup', this.keyupHandler);
  },

  methods: {
    close() {
      this.resetForm();
      this.$emit('close');
    },

    save() {
      const data = {
        name: this.name,
        domain_registrar_id: this.domain_registrar_id,
        auto_ns_management: this.auto_ns_management,
        auth_data: this.auto_ns_management ? this.$_.omitBy(this.auth_data, el => !el) : {},
      };

      if (!this.initalData.id) {
        this.$emit('create', data);
      } else {
        this.$emit('update', data);
      }
    },

    keyupHandler(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    },

    resetForm() {
      this.resetTouchedFields();

      this.name = '';
      this.domain_registrar_id = null;
      this.auto_ns_management = true;
      this.auth_data = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.domain-registrar-account-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .cancel-button {
    margin-right: 8px;
  }

  .popup {
    position: absolute;
    z-index: 902;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid #eaeaea;
      padding: 16px 24px;
      box-sizing: border-box;
      margin-bottom: 0;

      .name {
        font-size: 20px;
        font-weight: 500;
      }

      .close {
        color: #303634;
        padding: 5px;
        font-size: 32px;
        cursor: pointer;
      }
    }
    .form {
      padding: 16px 24px;
      box-sizing: border-box;
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      margin-top: 0;
    }
  }

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
</style>
