<template>
  <div class="media-reference">
    <div class="card affiliate-info-card">
      <div class="title">
        <span>
          {{ $t('mediaBuying.actions.profile') }}
        </span>
      </div>
      <div class="card-content">
        <div class="field">
          <span class="label big-text word-break affiliate-info-card__label-fs">
            {{ info.affiliate_email }}
          </span>
        </div>
        <div v-if="info.team_name" class="field">
          <span class="label word-break">{{ $t('mediaBuying.other.team') }}</span>
          <div class="values affiliate-info-card__values">
            {{ info.team_name }}
          </div>
        </div>
        <div
          class="field affiliate-info-card__field"
          @click="handleAffiliateBlock"
        >
          <span class="label word-break pre-line"> {{ $t('affiliates.cardView.info.balance') }} </span>
          <div class="values affiliate-info-card__values success-value">
            <!-- <span class="affiliate-info-card__sub-values">
              (${{ balanceFieldsTotalUSD.balance !== undefined
                ? balanceFieldsTotalUSD.balance.pending
                : 0 | formatMoney
              }})
            </span> -->
            {{ currencyLabel }}{{ balanceFieldsTotalConverted.balance !== undefined
              ? balanceFieldsTotalConverted.balance.approved
              : 0 | formatMoney }}
            <ui-icon
              :color="$theme.accentColor"
              size="12px"
              lib="fa"
              substyle="fas"
              name="chevron-down"
              :style="`transform: rotate(${
                !expandedAffiliate ? '0' : '180'
              }deg)`"
              :class="{ 'affiliate-info-card__chevron--open': false }"
              class="affiliate-info-card__chevron"
            />
          </div>
        </div>
        <div
          v-for="(balance, key) in balanceFields.approved"
          :key="key"
          class="field"
        >
          <div class="values affiliate-info-card__values success-value ui-fg-1 ui-jc-end">
            <!-- <span class="affiliate-info-card__sub-values">
              ({{ key }} {{ balanceFields.pending[key] | formatMoney }})
            </span> -->
            {{ getCurrencyLabel(key) }}{{ balance | formatMoney }}
          </div>
        </div>
        <div class="field">
          <span class="label word-break"> {{ $t('mediaBuying.other.paid_in_total') }} </span>
          <div class="values affiliate-info-card__values">
            {{ currencyLabel }}{{ balanceFieldsTotalConverted.payments !== undefined
              ? balanceFieldsTotalConverted.payments.approved
              : 0 | formatMoney }}
          </div>
        </div>
        <div class="field">
          <span class="label word-break"> {{ $t('mediaBuying.other.paid_pending') }} </span>
          <div class="values affiliate-info-card__values">
            {{ currencyLabel }}{{ balanceFieldsTotalConverted.payments !== undefined
              ? balanceFieldsTotalConverted.payments.pending
              : 0 | formatMoney }}
          </div>
        </div>
        <div class="field">
          <span class="label word-break">{{ $t('mediaBuying.other.spent_total') }}</span>
          <div class="values affiliate-info-card__values danger-value">
            {{ currencyLabel }}{{ balanceFieldsTotalConverted.posts !== undefined
              ? balanceFieldsTotalConverted.posts.approved
              : 0 | formatMoney }}
          </div>
        </div>
        <div class="field">
          <span class="label word-break"> {{ $t('mediaBuying.other.spent_pending') }}</span>
          <div class="values affiliate-info-card__values danger-value">
            {{ currencyLabel }}{{ balanceFieldsTotalConverted.posts !== undefined
              ? balanceFieldsTotalConverted.posts.pending
              : 0 | formatMoney }}
          </div>
        </div>
      </div>
    </div>

    <div class="card affiliate-info-card">
      <div class="title">
        <span>{{ $t('mediaBuying.other.camp_info') }}</span>
      </div>
      <div class="card-content">
        <div class="field ui-d-flex ui-fd-column">
          <span v-if="!hasRootCampaign" class="info-label">{{ info.root_campaign_name }}: ID {{ info.root_campaign_incrementing_id }}</span>
          <div class="label big-text word-break" :class="{ 'tree-icon': !hasRootCampaign}">
            {{ info.campaign_name }}
          </div>
          <span class="info-label ui-m-md-t" :class="{ 'ui-m-lg-l': !hasRootCampaign }">ID {{ info.campaign_incrementing_id }}</span>
        </div>
        <div class="field">
          <span class="label description word-break pre-line">
            {{ campaignInfo.description }}
          </span>
        </div>

        <div
          class="field affiliate-info-card__field"
          @click="handleCampaignBlock"
        >
          <span class="label word-break pre-line">{{ $t('affiliates.cardView.info.balance') }}</span>
          <div class="values affiliate-info-card__values success-value">
            <!-- <span class="affiliate-info-card__sub-values">
              (${{ balanceFieldsCampaignTotalUSD.balance !== undefined
                ? balanceFieldsCampaignTotalUSD.balance.pending
                : 0 | formatMoney
              }})
            </span> -->
            {{ currencyLabel }}{{ balanceFieldsCampaignTotalConverted.balance !== undefined
              ? balanceFieldsCampaignTotalConverted.balance.approved
              : 0 | formatMoney }}
            <ui-icon
              :color="$theme.accentColor"
              size="12px"
              lib="fa"
              substyle="fas"
              name="chevron-down"
              :style="`transform: rotate(${
                !expandedCampaign ? '0' : '180'
              }deg)`"
              :class="{ 'affiliate-info-card__chevron--open': false }"
              class="affiliate-info-card__chevron"
            />
          </div>
        </div>
        <div
          v-for="(balance, key) in balanceFieldsCampaign.approved"
          :key="key"
          class="field"
        >
          <div class="values affiliate-info-card__values success-value ui-fg-1 ui-jc-end">
            <!-- <span class="affiliate-info-card__sub-values">
              ({{ key }} {{ balanceFieldsCampaign.pending[key] | formatMoney }})
            </span> -->
            {{ getCurrencyLabel(key) }}{{ balance | formatMoney }}
          </div>
        </div>
        <div class="field">
          <span class="label word-break">{{ $t('mediaBuying.other.paid_in_total') }}</span>
          <div class="values affiliate-info-card__values">
            {{ currencyLabel }}{{ balanceFieldsCampaignTotalConverted.payments !== undefined
              ? balanceFieldsCampaignTotalConverted.payments.approved
              : 0 | formatMoney }}
          </div>
        </div>
        <div class="field">
          <span class="label word-break">{{ $t('mediaBuying.other.paid_pending') }}</span>
          <div class="values affiliate-info-card__values">
            {{ currencyLabel }}{{ balanceFieldsCampaignTotalConverted.payments !== undefined
              ? balanceFieldsCampaignTotalConverted.payments.pending
              : 0 | formatMoney }}
          </div>
        </div>
        <div class="field">
          <span class="label word-break">{{ $t('mediaBuying.other.spent_total') }}</span>
          <div class="values affiliate-info-card__values danger-value">
            {{ currencyLabel }}{{ balanceFieldsCampaignTotalConverted.posts !== undefined
              ? balanceFieldsCampaignTotalConverted.posts.approved
              : 0 | formatMoney }}
          </div>
        </div>
        <div class="field">
          <span class="label word-break">{{ $t('mediaBuying.other.spent_pending') }}</span>
          <div class="values affiliate-info-card__values danger-value">
            {{ currencyLabel }}{{ balanceFieldsCampaignTotalConverted.posts !== undefined
              ? balanceFieldsCampaignTotalConverted.posts.pending
              : 0 | formatMoney }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AffiliateInfoCard',

  props: {
    info: {
      type: Object,
      required: true,
    },
    currencyLabel: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      expandedAffiliate: false,
      expandedCampaign: false,
    };
  },

  computed: {
    ...mapGetters({
      currencies: 'misc/currencies',
    }),
    hasRootCampaign() {
      return this.info.root_campaign_incrementing_id === this.info.campaign_incrementing_id;
    },
    campaignInfo() {
      return this.info?.campaign || {};
    },

    balanceFieldsCampaign() {
      if (!this.expandedCampaign) {
        return {};
      }

      return this.campaignInfo?.money.balance || {};
    },

    balanceFieldsCampaignTotalConverted() {
      return this.campaignInfo?.money_converted || {};
    },

    balanceFields() {
      if (!this.expandedAffiliate) {
        return {};
      }
      return this.info.affiliate?.money.balance || {};
    },

    balanceFieldsTotalConverted() {
      return this.info.affiliate?.money_converted || {};
    },
  },

  methods: {
    getCurrencyLabel(code) {
      const currency = this.currencies.find((el => el.code === code));

      return currency?.symbol || `${code} `;
    },
    handleAffiliateBlock() {
      this.expandedAffiliate = !this.expandedAffiliate;
    },

    handleCampaignBlock() {
      this.expandedCampaign = !this.expandedCampaign;
    },
  },
};
</script>
