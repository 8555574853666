import { mapGetters } from 'vuex';
import numeral from 'numeral';

export default {
  props: {
    propCurrencyCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currencyCode: this.propCurrencyCode,
      defaultCurrency: '$',
    };
  },
  watch: {
    propCurrencyCode(val) {
      this.currencyCode = val;
    },
  },
  computed: {
    ...mapGetters({
      currencies: 'misc/currencies',
      activeCustomerCurrency: 'misc/activeCustomerCurrency',
    }),
    defaultCurrencyCode() {
      return this.activeCustomerCurrency.code || '';
    },
    currencyLabel() {
      if (this.currencyCode === undefined) return this.defaultCurrency;

      const currency = this.currencies.find((el => el.code === this.currencyCode));

      return currency?.symbol || `${this.currencyCode} `;
    },
  },
  methods: {
    formatCurrency(value) {
      return `${this.currencyLabel}${value < 0 ? '-' : ''}${numeral(Math.abs(value)).format('0,0.00')}`;
    },
    formatMetric(metric, currencyLabel = this.currencyLabel) {
      return {
        ...metric,
        ...(currencyLabel && {
          title: metric.title.replace(/, \$|, {{ currencyCode }}/, `, ${currencyLabel}`),
          title_short: metric.title.replace(/, \$|, {{ currencyCode }}/, `, ${currencyLabel}`),
        }),
      };
    },
    checkCurrencyCode(code) {
      if (code && this.customerCurrencies.find(currency => currency.code === code)) {
        return code;
      }

      return this.defaultCurrencyCode;
    },
  },
};
