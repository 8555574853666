import router from '@/router';

let authToken;

let timer;
let updateIntervalData;
let checkIntervalData;

const setLongTimeout = (callback, timeoutMs) => {
  if (timeoutMs > 2147483647) {
    return setTimeout(() => setLongTimeout(callback, (timeoutMs - 2147483647)), 2147483647);
  }

  return setTimeout(callback, timeoutMs);
};

const getters = {
  significantActionsSeconds: state => state.significantActionsSeconds || 0,
};

const mutations = {
  setSessionData(state, payload) {
    state.significantActionsSeconds = payload;
  },
};

const actions = {

  initialSessionData({ dispatch }) {
    dispatch('getSessionData');
  },

  async getSessionData({ dispatch, commit }) {
    try {
      dispatch('clearTimers');
      const { data: { payload } } = await this.$api.getSessionsData();
      commit('setSessionData', payload.significant_actions_remaining_seconds);
      authToken = localStorage.getItem('auth-token');
      if (state.significantActionsSeconds !== 0) {
        window.$cookies.set(authToken, state.significantActionsSeconds, state.significantActionsSeconds);
        dispatch('setTimer');
      } else {
        window.$cookies.remove(authToken);
      }
      dispatch('setUpdateIntervalData');
      dispatch('setCheckIntervalData');
    } catch (error) {
      commit('setSessionData', {});
    }
  },

  setTimer({ dispatch, commit }) {
    timer = setLongTimeout(() => {
      commit('setSessionData', 0);
      if (window.location.pathname.split('/')[1] === 'teams') {
        router.push('/affiliates');
      }
      dispatch('clearTimers');
    }, state.significantActionsSeconds * 1000);
  },

  setUpdateIntervalData({ dispatch }) {
    updateIntervalData = setInterval(() => {
      dispatch('updateSession');
    }, 60000);
  },
  setCheckIntervalData({ dispatch }) {
    checkIntervalData = setInterval(() => {
      dispatch('checkSession');
    }, 2000);
  },

  async updateSession({ dispatch }) {
    await dispatch('getSessionData');
  },

  lockSession({ commit }) {
    commit('setSessionData', 0);
    window.$cookies.remove(localStorage.getItem('auth-token'));
  },
  checkSession({ dispatch, commit }) {
    dispatch('getSessionAuth');
    const significantSeconds = +window.$cookies.get(localStorage.getItem('auth-token'));
    if (significantSeconds === 0) {
      dispatch('lockSession');
    } else {
      commit('setSessionData', significantSeconds);
    }
  },

  getSessionAuth() {
    this.$api.affiliatesApi.defaults.headers.common.Authorization = localStorage.getItem('auth-token');
    this.$api.miscApi.defaults.headers.common.Authorization = localStorage.getItem('auth-token');
  },

  clearTimers() {
    clearTimeout(timer);
    clearInterval(updateIntervalData);
    clearInterval(checkIntervalData);
  },
};

const state = {
  significantActionsSeconds: 0,
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
