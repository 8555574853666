export default {


  timezone: {
    EtcGMT12: 'International Date Line West',
    PacificMidway: 'Midway Island, Samoa',
    PacificHonolulu: 'Hawaii',
    AmericaJuneau: 'Alaska',
    AmericaDawson: 'Pacific Time (US and Canada); Tijuana',
    AmericaBoise: 'Mountain Time (US and Canada)',
    AmericaChihuahua: 'Chihuahua, La Paz, Mazatlan',
    AmericaPhoenix: 'Arizona',
    AmericaChicago: 'Central Time (US and Canada)',
    AmericaRegina: 'Saskatchewan',
    AmericaMexico_City: 'Guadalajara, Mexico City, Monterrey',
    AmericaBelize: 'Central America',
    AmericaDetroit: 'Eastern Time (US and Canada)',
    AmericaIndianaIndianapolis: 'Indiana (East)',
    AmericaBogota: 'Bogota, Lima, Quito',
    AmericaGlace_Bay: 'Atlantic Time (Canada)',
    AmericaCaracas: 'Caracas, La Paz',
    AmericaSantiago: 'Santiago',
    AmericaSt_Johns: 'Newfoundland and Labrador',
    AmericaSao_Paulo: 'Brasilia',
    AmericaArgentinaBuenos_Aires: 'Buenos Aires, Georgetown',
    AmericaGodthab: 'Greenland',
    EtcGMT2: 'Mid-Atlantic',
    AtlanticAzores: 'Azores',
    AtlanticCape_Verde: 'Cape Verde Islands',
    GMT: 'Dublin, Edinburgh, Lisbon, London',
    AfricaCasablanca: 'Casablanca, Monrovia',
    AtlanticCanary: 'Canary Islands',
    EuropeBelgrade: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    EuropeSarajevo: 'Sarajevo, Skopje, Warsaw, Zagreb',
    EuropeBrussels: 'Brussels, Copenhagen, Madrid, Paris',
    EuropeAmsterdam: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    AfricaAlgiers: 'West Central Africa',
    EuropeBucharest: 'Bucharest',
    AfricaCairo: 'Cairo',
    EuropeHelsinki: 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
    EuropeAthens: 'Athens, Istanbul, Minsk',
    AsiaJerusalem: 'Jerusalem',
    AfricaHarare: 'Harare, Pretoria',
    EuropeMoscow: 'Moscow, St. Petersburg, Volgograd',
    AsiaKuwait: 'Kuwait, Riyadh',
    AfricaNairobi: 'Nairobi',
    AsiaBaghdad: 'Baghdad',
    AsiaTehran: 'Tehran',
    AsiaDubai: 'Abu Dhabi, Muscat',
    AsiaBaku: 'Baku, Tbilisi, Yerevan',
    AsiaKabul: 'Kabul',
    AsiaYekaterinburg: 'Ekaterinburg',
    AsiaKarachi: 'Islamabad, Karachi, Tashkent',
    AsiaKolkata: 'Chennai, Kolkata, Mumbai, New Delhi',
    AsiaKathmandu: 'Kathmandu',
    AsiaDhaka: 'Astana, Dhaka',
    AsiaColombo: 'Sri Jayawardenepura',
    AsiaAlmaty: 'Almaty, Novosibirsk',
    AsiaRangoon: 'Yangon Rangoon',
    AsiaBangkok: 'Bangkok, Hanoi, Jakarta',
    AsiaKrasnoyarsk: 'Krasnoyarsk',
    AsiaShanghai: 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
    AsiaKuala_Lumpur: 'Kuala Lumpur, Singapore',
    AsiaTaipei: 'Taipei',
    AustraliaPerth: 'Perth',
    AsiaIrkutsk: 'Irkutsk, Ulaanbaatar',
    AsiaSeoul: 'Seoul',
    AsiaTokyo: 'Osaka, Sapporo, Tokyo',
    AsiaYakutsk: 'Yakutsk',
    AustraliaDarwin: 'Darwin',
    AustraliaAdelaide: 'Adelaide',
    AustraliaSydney: 'Canberra, Melbourne, Sydney',
    AustraliaBrisbane: 'Brisbane',
    AustraliaHobart: 'Hobart',
    AsiaVladivostok: 'Vladivostok',
    PacificGuam: 'Guam, Port Moresby',
    AsiaMagadan: 'Magadan, Solomon Islands, New Caledonia',
    PacificFiji: 'Fiji Islands, Kamchatka, Marshall Islands',
    PacificAuckland: 'Auckland, Wellington',
    PacificTongatapu: "Nuku'alofa",

  },


};
