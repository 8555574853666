<template>
  <div class="media-buying">
    <router-view class="view" />
  </div>
</template>

<script>
export default {

  name: 'MediaBuying',
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.media-buying{
  display: flex;
  flex-direction: column;
  .content-wrapper{
    flex-grow: 10;
    display: flex;
    flex-direction: column;
  }
}
</style>
