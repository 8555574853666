var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"crm-language-tab"},[_c('div',{class:[
      'crm-language-tab__container',
      'ui-d-flex',
      'ui-fg-1',
      'ui-w-wrap',
      'ui-m-xl-r',
    ]},[_vm._l((_vm.languages),function(language,languageIndex){return _c('div',{key:language.language_code,staticClass:"crm-language-tab__item",class:{
        'crm-language-tab__item_active': _vm.selectedIndex === languageIndex,
        'crm-language-tab__item_has-error': !!_vm.errors[languageIndex],
      },on:{"click":function($event){return _vm.$emit('select', languageIndex)}}},[_c('div',{staticClass:"crm-language-tab__item-content"},[_vm._v(" "+_vm._s(language.name)+" "),(language.is_default)?[_vm._v(" ("+_vm._s('Default')+") ")]:_vm._e()],2)])}),_c('div',{key:"filler",staticClass:"crm-language-tab__filler ui-fg-1"})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }