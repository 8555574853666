<template functional>
  <div
    class="crm-panel"
    :class="{
      [data.staticClass]: data.staticClass,
      ...(data.class || {})
    }"
    :style="data.staticStyle || ''"
    v-bind="data.attrs"
  >
    <div
      class="crm-panel__content-wrapper"
      :class="{
        'crm-panel__content-wrapper_type-default': props.type === 'default',
        'crm-panel__content-wrapper_type-compact': props.type === 'compact',
        'crm-panel__content-wrapper_type-contrast': props.type === 'contrast',
        'crm-panel__content-wrapper_error': props.error,
      }"
    >
      <slot name="prepend" />
      <div class="crm-panel__content">
        <slot />
      </div>
      <slot name="append" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.crm-panel {
  &__content-wrapper {
    display: flex;
    color: var(--crm-panel-text);

    &_type-default {
      border-radius: 8px;
      background-color: var(--crm-panel-bg);

      .crm-panel__content {
        padding: 16px 24px 24px;
      }
    }

    &_type-compact {
      border-radius: 8px;
      background-color: var(--crm-panel-bg);
      font-size: 14px;
      line-height: 1.14;

      .crm-panel__content {
        padding: 16px 16px 8px;
      }
    }

    &_type-contrast {
      border-radius: 8px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;

      .crm-panel__content {
        padding: 8px 12px;
      }
    }

    &_error {
      border: 1px solid var(--danger-color);
    }
  }

  &__content {
    flex-grow: 1;
    min-width: 0;
  }
}
</style>
