<template>
  <dashboard-view
    type-view="add"
  />
</template>
<script>
import DashboardView from './DashboardView';

export default {
  name: 'AddDashboard',
  components: { DashboardView },
  data() {
    return {};
  },
};
</script>
