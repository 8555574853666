<template>
  <div
    v-if="isOpen"
    class="crm-add-sms-action-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ $t('crm.activities.actions.action_popup.sms_title') }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div
        v-loading="isLoading"
        class="body"
      >
        <div class="ui-fg-1">
          <ui-input
            v-model="name"
            class="form-input ui-m-xl-b"
            :label="$t('crm.activities.actions.action_popup.sms_fields.name')"
            autosize
            size="big"
            :placeholder="$t('crm.activities.actions.action_popup.sms_fields.name')"
            type="text"
            :error="currentErrors.name"
          />

          <div class="ui-m-xl-b">
            <div class="ui-d-flex ui-m-lg-b">
              <crm-action-language-tab
                :languages="languages"
                :selected-index="index"
                :errors="errors"
                @select="handleSelectTab"
              />
              <crm-action-languages
                class="ui-fg-0"
                :selected="selectedLanguages"
                @select="handleAddLanguage"
              />
            </div>
            <div class="ui-d-flex ui-g-w-md">
              <div class="ui-g-md">
                <ui-button
                  icon="history"
                  lib="fa"
                  substyle="fas"
                  color="green"
                  class="action-btn"
                  :disabled="index === defaultLanguageActionIndex"
                  @click="handleMakeDefault(index)"
                >
                  {{ $t('crm.buttons.make_default') }}
                </ui-button>
              </div>

              <div class="ui-g-md">
                <ui-button
                  icon="times"
                  lib="fa"
                  substyle="fas"
                  color="red"
                  class="action-btn"
                  :disabled="index === defaultLanguageActionIndex"
                  @click="handleDelete(index)"
                >
                  {{ $t('crm.buttons.delete') }}
                </ui-button>
              </div>
            </div>
          </div>

          <div class="select-block ui-m-sm-b">
            <span class="label">{{ $t('crm.activities.actions.action_popup.sms_fields.message') }}</span>
            <el-input
              v-model="message"
              :label="$t('crm.activities.actions.action_popup.sms_fields.message')"
              type="textarea"
              :rows="6"
              tabindex="1"
              :placeholder="$t('crm.activities.actions.action_popup.sms_fields.message')"
              :class="{'error-textarea': currentErrors.message}"
            />
          </div>
          <div
            class="ui-d-flex ui-ai-center ui-jc-end"
            :class="{
              'ui-m-sm-b ui-g-w-md ui-jc-space-between': currentErrors.message,
            }"
          >
            <crm-error-panel
              v-if="currentErrors.message"
              class="ui-g-md ui-ai-baseline"
            >
              {{ currentErrors.message }}
            </crm-error-panel>
            <span class="crm-add-sms-action-popup__statistics" :style="messageStyle">{{ messageStats }}</span>
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          lib="fa"
          substyle="fas"
          icon="paper-plane"
          :disabled="isLoading || !isValidSendTest"
          @click="handleSendTestSms()"
        >
          {{ $t('crm.buttons.send_test_sms') }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="isLoading"
          filled
          @click="handleSave()"
        >
          <i class="fas fa-check plus-icon-custom" />{{ $t('crm.buttons.save') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import CrmActionLanguages from '@/views/CRM/components/CrmActionLanguages.vue';
import CrmActionLanguageTab from '@/views/CRM/components/CrmActionLanguageTab.vue';
import ActivitiesLanguageMixin from '@/views/CRM/mixins/activities-language-mixin';
import CrmErrorPanel from '@/views/CRM/components/CrmErrorPanel.vue';
import SmsStatMixin from '@/views/CRM/mixins/sms-stat-mixin';

export default {
  name: 'AddSmsActionPopup',
  components: {
    CrmActionLanguages,
    CrmActionLanguageTab,
    CrmErrorPanel,
  },
  mixins: [
    ActivitiesLanguageMixin,
    SmsStatMixin,
  ],
  data() {
    return {
      isOpen: false,
      isLoading: false,
      ...this.getDefaultForm(),
      index: -1,
      actionGroupIndex: -1,
      actionGroup: null,
      errors: [],
    };
  },

  computed: {
    currentErrors() {
      return this.errors[this.index] || {};
    },
    isValidSendTest() {
      return this.message;
    },
  },
  watch: {
    index: {
      immediate: true,
      handler(value) {
        if (value !== -1) {
          this.updateActionValue(value);
          if (this.$refs.editor) {
            this.$set(this.$refs.editor.$refs.editorEgine, 'historyStack', []);
          }
        }
      },
    },
    message(value) {
      this.setActionsValue('message', value);
    },
    name(value) {
      this.setActionsValue('name', value);
    },
  },
  created() {},
  mounted() {},
  methods: {
    exportData() {},
    open(actionGroup, payload) {
      this.actionGroup = actionGroup;
      this.actionGroupIndex = this.activity.action_groups.indexOf(actionGroup);

      const actions = (this.activity.action_groups[this.actionGroupIndex].actions || [])
        .filter(item => item.type === 'sms');

      if (!actions.length) {
        actions.push(this.getDefaultForm());
      }

      this.actions = this.$_.cloneDeep(actions);

      this.index = 0;
      this.isOpen = true;
      this.errors = [];

      if (payload?.errors?.sms) {
        this.errors = this.actions.map((action, index) => payload.errors.sms[index] || null);
      }
    },
    close() {
      this.isOpen = false;
      this.$emit('closePopupSegment');
      this.form = this.getDefaultForm();
      this.actionGroupIndex = -1;
      this.index = -1;
      this.actionGroup = null;
    },
    handleSave() {
      this.$emit('save', this.actionGroup, this.actions, 'sms');
      this.close();
    },
    handleSendTestSms() {
      const actionGroup = {
        actions: this.actions,
      };

      this.$emit('send-test-sms', {
        actionGroup,
        index: this.index,
      });
    },
    handleAddLanguage(languageCode) {
      this.actions.push({
        ...this.getDefaultForm(),
        language_code: languageCode,
        is_default: false,
      });
    },
    handleDelete(index) {
      this.actions.splice(index, 1);
      this.errors.splice(index, 1);

      this.index = this.defaultLanguageActionIndex;
      this.updateActionValue(this.index);
    },
    updateActionValue(index) {
      this.message = this.actions[index].message;
      this.name = this.actions[index].name;
    },
    getDefaultForm() {
      return {
        name: '',
        type: 'sms',
        message: '',
        language_code: 'en',
        is_default: true,
      };
    },
  },
};
</script>

<style lang="scss">
.popup {
  .body {
    .error-textarea {
      .el-textarea__inner {
        border-color: var(--danger-color);
      }
    }
  }
}
</style>
