export default {
  reports: {
    name: 'Reports',
    menu: {
      daily: 'Daily',
      affiliate: 'Affiliate',
      player: 'Player',
      revenue: 'Revenue',
      payments: 'Payment Methods',
      custom: 'Custom',
    },
    filters: {
      affiliate_id: 'Affiliate',
      player_id: 'Player',
      country_code: 'Country',
      player_country_code: 'Player Country',
      traffic_type: 'Traffic Type',
    },
    payments: {
      reset_filter: 'Reset Filters',
      page: 'Page',
      page_of: 'of',
      payment_method_report: 'Payment Methods Report',


    },
    other: {
      report: 'Report',
      drilldown: 'Drilldown',
      selected_period: 'Save with selected period',
      exclude: 'Exclude',
      show_player: 'Show Players',
      open_payment: 'Open Payment Report',
      home: 'Home',
      copy_url: 'Copy report URL',
      copy_url2: 'Copy URL',
      pin_report: 'Pin report',
      add_bookmark: 'Add a Bookmark',
      placeholder_name: 'Bookmark Name',
      close: 'Cancel',
      confirm: 'Confirm',
      add_domains: 'Add Domains',
      reset_to_defaults: 'Reset to Defaults',
      close_all: 'Close All',
      edit: 'Edit',
      save: 'Save',
      delete: 'Delete',
      remove_report: 'Remove report',
      reset_default: 'Reset defaults',
      bookmark: 'Bookmarks',
      bookmark_edit: 'Edit Bookmark',
      not_found_bookmark: 'No bookmarks have been found',
      all: 'All',
      traffic: 'Traffic',
      player: 'Players',
      apply: 'Apply',
      reset: 'Reset',
      refresh: 'Refresh',
      start_date: 'Start date',
      end_date: 'End date',
      enter_date: 'Enter date',
      total: 'Total',
      affiliates_created_at: 'Affiliates Created At',
      continue_new_tab: 'Continue w/ new tab',
      start_new_tab: 'Start new tab',
      copied_to_clipboard: 'Copied to clipboard!',
      failed_to_copy: 'Failed to copy link',
      save_period: 'Save with selected period',
      save_bookmark: 'Save Bookmark',
      more: '{number} More',
      share_report: 'Share Report',
      copy: 'Copy',
      search_by: {
        title: 'Search by',
        text: {
          site_player_id: 'Players',
          affiliate_email: 'Affiliates',
          sub_affiliate_email: 'Sub-Affiliates',
          referrer_domain: 'Referrer Domains',
        },
        placeholder: {
          site_player_id: 'Each ID must be entered on a new line',
          affiliate_email: 'Each email must be entered on a new line',
          sub_affiliate_email: 'Each email must be entered on a new line',
          referrer_domain: 'Each domain must be entered on a new line',
        },
        site_player_id: 'Players',
        affiliate_email: 'Affiliates',
        sub_affiliate_email: 'Sub-Affiliates',
        referrer_domain: 'Referrer Domains',
      },
      filters_is_using: 'You\'re already using another search.',
    },
    date_picker: {
      last_7_day: 'Last 7 days',
      last_30_day: 'Last 30 days',
      this_month: 'This Month',
      prev_month: 'Previous Month',
      last_90_day: 'Last 90 days',
      last_year: 'Last Year',
    },
    players_type: {
      all: 'All Players',
      new: 'New Players',
      old: 'Old Players',
    },
    traffic_type: {
      all: 'All',
      affiliates: 'Partners',
      direct: 'Direct',
    },
    search: {
      like: 'Is',
      notLike: 'Is Not',
    },
    fields: {
      sport_casino: {
        title: 'Sport / Casino',
        fullTitle: 'Sport / Casino',
        filter: 'Sport / Casino',
      },
      bet_game_id: {
        title: 'Game',
        fullTitle: 'Game',
        filter: 'Game',
      },
      bet_category_id: {
        title: 'Category',
        fullTitle: 'Category',
        filter: 'Category',
      },

      media_buying: {
        title: 'Media Buying',
        fullTitle: 'Media Buying',
        filter: 'Media Buying',
      },
      media_buying_channel_name: {
        title: 'Channel',
        fullTitle: 'Channel',
        filter: 'Channel',
      },
      media_buying_campaign_name: {
        title: 'Campaign',
        fullTitle: 'Campaign',
        filter: 'Campaign',
      },
      media_buying_channel_id: {
        title: 'MB Channel',
        fullTitle: 'MB Channel',
        filter: 'MB Channel',
      },
      media_buying_campaign_id: {
        title: 'MB Campaign',
        fullTitle: 'MB Campaign',
        filter: 'MB Campaign',
      },
      misc: {
        title: 'Misc',
        fullTitle: 'Misc',
        filter: 'Misc',
      },
      landing_name: {
        title: 'Landing',
        fullTitle: 'Landing',
        filter: 'Landing',
      },
      landing_id: {
        title: 'Landing',
        fullTitle: 'Landing',
        filter: 'Landing',
      },
      marketing_material_name: {
        title: 'Media',
        fullTitle: 'Media',
        filter: 'Media',
      },
      marketing_material_id: {
        title: 'Media',
        fullTitle: 'Media',
        filter: 'Media',
      },
      payment_system_id: {
        title: 'Payment System',
        filter: 'Payment System',
        fullTitle: 'Payment System',
      },
      country_code: {
        title: 'Country',
        fullTitle: 'Country',
        filter: 'Country',
      },
      // Common
      affiliate_email: {
        title: 'Affiliate',
        fullTitle: 'Affiliate',
        filter: 'Affiliate',
      },
      referred_affiliate_email: {
        title: 'Sub-Affiliate',
        fullTitle: 'Sub-Affiliate',
        filter: 'Sub-Affiliate',
      },
      referred_by_affiliate_id: {
        title: 'Sub-Affiliate',
        fullTitle: 'Sub-Affiliate',
        filter: 'Sub-Affiliate',
      },
      player_id: {
        title: 'Player',
        fullTitle: 'Player',
        filter: 'Player ID',
      },
      site_player_id: {
        title: 'Player',
        fullTitle: 'Player',
        filter: 'Player ID',
      },
      affiliate_offer_name: {
        title: 'Offer',
        fullTitle: 'Offer',
        filter: 'Offer',
      },
      affiliate_offer_settings_name: {
        title: 'Offer Settings',
        fullTitle: 'Offer Settings',
        filter: 'Offer Settings',
      },
      offer_type_name: {
        title: 'Offer Type',
        fullTitle: 'Offer Type',
        filter: 'Offer Type',
      },
      site_name: {
        title: 'Brand',
        fullTitle: 'Brand',
        filter: 'Brand',
      },
      site_id: {
        title: 'Brand',
        fullTitle: 'Brand',
        filter: 'Brand',
      },
      offer_type: {
        title: 'Offer Type',
        fullTitle: 'Offer Type',
        filter: 'Offer Type',
      },
      affiliate_offer_settings_id: {
        title: 'Offer Settings',
        fullTitle: 'Offer Settings',
        filter: 'Offer Settings',
      },
      traffic_source_name: {
        title: 'Traffic Source',
        fullTitle: 'Traffic Source',
        filter: 'Traffic Source',
      },
      bet_category_name: {
        title: 'Category',
        fullTitle: 'Category',
        filter: 'Category',
      },
      bet_game_name: {
        title: 'Game',
        fullTitle: 'Game',
        filter: 'Game',
      },
      payment_system_name: {
        title: 'Payment System',
        fullTitle: 'Payment System',
        filter: 'Payment System',
      },
      site_registration_type_id: {
        title: 'Registration Type',
        fullTitle: 'Registration Type',
        filter: 'Registration Type',
      },
      site_registration_type_name: {
        title: 'Registration Type',
        fullTitle: 'Registration Type',
        filter: 'Registration Type',
      },
      affiliate_player_promo_code_name: {
        title: 'Promo Code',
        fullTitle: 'Promo Code',
        filter: 'Promo Code',
      },
      language: {
        title: 'Language',
        fullTitle: 'Language',
        filter: 'Language',
      },
      // Date & Time
      date_and_time: {
        title: 'Date',
        fullTitle: 'Date & Time',
        filter: 'Date',
      },
      date: {
        title: 'Date',
        fullTitle: 'Date',
        filter: 'Date',
      },
      date_formatted: {
        title: 'Date',
        fullTitle: 'Date',
        filter: 'Date',
      },
      year: {
        title: 'Year',
        fullTitle: 'Year',
        filter: 'Year',
      },
      month: {
        title: 'Month',
        fullTitle: 'Month',
        filter: 'Month',
      },
      day_of_month: {
        title: 'Day',
        fullTitle: 'Day of month',
        filter: 'Day of month',
        tip: 'Of Month',
      },
      day_of_week: {
        title: 'Day',
        fullTitle: 'Day of week',
        filter: 'Day of week',
        tip: 'Of Week',
      },
      hour: {
        title: 'Hour',
        fullTitle: 'Hour',
        filter: 'Hour',
      },
      minute: {
        title: 'Minute',
        fullTitle: 'Minute',
        filter: 'Minute',
      },
      start_of_year: {
        title: 'Start of year',
        fullTitle: 'Start of year',
        filter: 'Start of year',
      },
      start_of_quarter: {
        title: 'Start of quarter',
        fullTitle: 'Start of quarter',
        filter: 'Start of quarter',
      },
      start_of_month: {
        title: 'Month',
        fullTitle: 'Month',
        filter: 'Month',
      },
      start_of_week: {
        title: 'Week',
        fullTitle: 'Week',
        filter: 'Week',
      },
      start_of_minute: {
        title: 'Start of minute',
        fullTitle: 'Start of minute',
        filter: 'Start of minute',
      },
      // Referrer
      referrer: {
        title: 'Referrer',
        fullTitle: 'Referrer',
        filter: 'Referrer',
      },
      referrer_domain: {
        title: 'Referrer domain',
        fullTitle: 'Referrer domain',
        filter: 'Referrer domain',
      },
      // GEO
      geo: {
        title: 'Traffic GEO',
        fullTitle: 'Traffic GEO',
        // filter: 'Country',
      },
      region_name: {
        title: 'Region',
        fullTitle: 'Region',
        filter: 'Region',
      },
      country_name: {
        title: 'Country',
        fullTitle: 'Country',
        filter: 'Country',
      },
      player_country_name: {
        title: 'Player country',
        fullTitle: 'Player country',
        filter: 'Player country',
      },
      player_region_name: {
        title: 'Player region',
        fullTitle: 'Player region',
        filter: 'Player region',
      },
      player_country_code: {
        title: 'Player country',
        fullTitle: 'Player country',
        filter: 'Player country',
      },
      state_name: {
        title: 'State',
        fullTitle: 'State',
        filter: 'State',
      },
      city_name: {
        title: 'City',
        fullTitle: 'City',
        filter: 'City',
      },
      // Device
      device: {
        title: 'Device',
        fullTitle: 'Device',
        // filter: 'Device type',
      },
      device_type: {
        title: 'Device type',
        fullTitle: 'Device type',
        filter: 'Device type',
      },
      device_type_id: {
        title: 'Device type',
        fullTitle: 'Device type',
        filter: 'Device type',
      },
      device_vendor: {
        title: 'Device vendor',
        fullTitle: 'Device vendor',
        filter: 'Device vendor',
      },
      device_model: {
        title: 'Device model',
        fullTitle: 'Device model',
        filter: 'Device model',
      },
      // Browser
      browser: {
        title: 'Browser',
        fullTitle: 'Browser',
        filter: 'Browser',
      },
      browser_version: {
        title: 'Browser version',
        fullTitle: 'Browser version',
        filter: 'Browser version',
      },
      // OS
      os: {
        title: 'OS',
        fullTitle: 'OS',
        filter: 'OS',
      },
      os_version: {
        title: 'OS version',
        fullTitle: 'OS version',
        filter: 'OS version',
      },
      // ISP
      isp: {
        title: 'ISP',
        fullTitle: 'Internet Service Provider',
        filter: 'ISP',
      },
      isp_id: {
        title: 'ISP',
        fullTitle: 'Internet Service Provider',
        filter: 'ISP',
      },
      connection_type: {
        title: 'Connection Type',
        fullTitle: 'Connection Type',
        filter: 'Connection Type',
      },
      connection_type_id: {
        title: 'Connection Type',
        fullTitle: 'Connection Type',
        filter: 'Connection Type',
      },
      // Subs
      subs: {
        title: 'SubID',
        fullTitle: 'SubID',
        // filter: 'SubID 1',
      },
      subid1: {
        title: 'SubID 1',
        fullTitle: 'SubID 1',
        filter: 'SubID 1',
        description: 'SubID 1',
      },
      subid2: {
        title: 'SubID 2',
        fullTitle: 'SubID 2',
        filter: 'SubID 2',
        description: 'SubID 2',
      },
      subid3: {
        title: 'SubID 3',
        fullTitle: 'SubID 3',
        filter: 'SubID 3',
        description: 'SubID 3',
      },
      subid4: {
        title: 'SubID 4',
        fullTitle: 'SubID 4',
        filter: 'SubID 4',
        description: 'SubID 4',
      },
      subid5: {
        title: 'SubID 5',
        fullTitle: 'SubID 5',
        filter: 'SubID 5',
        description: 'SubID 5',
      },
      subid6: {
        title: 'SubID 6',
        fullTitle: 'SubID 6',
        filter: 'SubID 6',
      },
      subid7: {
        title: 'SubID 7',
        fullTitle: 'SubID 7',
        filter: 'SubID 7',
      },
      subid8: {
        title: 'SubID 8',
        fullTitle: 'SubID 8',
        filter: 'SubID 8',
      },
      subid9: {
        title: 'SubID 9',
        fullTitle: 'SubID 9',
        filter: 'SubID 9',
      },
      subid10: {
        title: 'SubID 10',
        fullTitle: 'SubID 10',
        filter: 'SubID 10',
      },
      external_id: {
        title: 'External ID',
        fullTitle: 'External ID',
        filter: 'External ID',
        description: 'External ID',
      },
      // Filters
      affiliate_id: {
        title: 'Affiliate ID',
        fullTitle: 'Affiliate ID',
        filter: 'Affiliate',
      },
      affiliate_offer_id: {
        title: 'Offer ID',
        fullTitle: 'Offer ID',
        filter: 'Offer',
      },
      traffic_source_id: {
        title: 'Traffic Source ID',
        fullTitle: 'Traffic Source ID',
        filter: 'Traffic Source',
      },
      affiliate_player_promo_code_id: {
        title: 'Promo Code ID',
        fullTitle: 'Promo Code ID',
        filter: 'Promo Code',
      },
      language_code: {
        title: 'Language Code',
        fullTitle: 'Language Code',
        filter: 'Language Code',
      },
      // Columns
      impressions_count: {
        title: 'Impress.',
        description: 'Promo Materials Impressions',
      },
      impressions_uniq_count: {
        title: 'Impress. (uniq)',
        description: 'Promo Materials Unique Impressions',
      },
      visits_count: {
        title: 'Visits',
        description: 'Number of Visits',
      },
      visits_uniq_count: {
        title: 'Visits (uniq)',
        description: 'Number of Unique Visits',
      },
      registrations_count: {
        title: 'Registr.',
        description: 'Number of Registrations',
      },
      visits_per_impression_percent: {
        title: 'CTR',
        description: '% of Visit/Impression',
      },
      registrations_per_visit_percent: {
        title: 'RPV',
        description: '% of Registration/Visit',
      },
      deposits_first_per_registration_percent: {
        title: 'FDPR',
        description: '% of First Deposits/Registrations',
      },
      deposits_first_per_visit_percent: {
        title: 'FDPV',
        description: '% of First Deposits/Visits',
      },
      deposits_first_count: {
        title: 'First Depos',
        description: 'Number of First Deposits',
      },
      deposits_other_count: {
        title: 'Redeposits',
        description: 'Number of Other Deposits',
      },
      deposits_all_count: {
        title: 'Depos.',
        description: 'Number of All Deposits',
      },
      deposits_withdrawals_count: {
        title: 'Withdrawals',
        description: 'Number of Withdrawals',
      },
      deposits_first_sum: {
        title: 'First Depos',
        description: 'First Deposits Sum',
      },
      deposits_other_sum: {
        title: 'Redeposits, $',
        description: 'Other Deposits Sum',
      },
      deposits_all_sum: {
        title: 'Deposits',
        description: 'Deposits Sum',
      },
      deposits_withdrawals_sum: {
        title: 'Withdrawals',
        description: 'Withdrawals Sum',
      },
      players_with_first_deposit_count: {
        title: 'Players w/ FD',
        description: 'Number of Registrations with First Deposit',
      },
      players_with_deposit_count: {
        title: 'Players w/ Depos.',
        description: 'Number of Players with Deposits',
      },
      re_deposits_percent: {
        title: 'Redeposits, %',
        description: '% of Re-deposits',
      },
      median_player_profit_category: {
        title: 'Med Player Cat',
        description: 'Median Category of Players Profit',
      },
      avg_player_profit_category: {
        title: 'Avg Player Cat',
        description: 'Average Category of Players Profit',
      },
      avg_bet_odd: {
        title: 'Avg. Bet Odd',
        description: 'Average Sports Bet Odd',
      },
      median_bet_odd: {
        title: 'Med. Bet Odd',
        description: 'Median Sports Bet Odd',
      },
      bet_new_count: {
        title: 'Bets',
        description: 'Number of Bets',
      },
      bet_new_sports_count: {
        title: 'Sport Bets',
        description: 'Number of Sports Bets',
      },
      bet_new_games_count: {
        title: 'Casino Bets',
        description: 'Number of Casino/Slots/Etc Bets',
      },
      bet_result_count: {
        title: 'Paid Bets',
        description: 'Number of paid bets',
      },
      bet_result_sports_count: {
        title: 'Paid Sport Bets',
        description: 'Number of paid bets from Sports',
      },
      bet_result_games_count: {
        title: 'Paid Casino Bets',
        description: 'Number of paid bets from Casino',
      },
      bet_sell_count: {
        title: 'Sold Bets',
        description: 'Number of Sold Bets',
      },
      bet_insurance_count: {
        title: 'Insured Bets',
        description: 'Number of Bought Insurances',
      },
      bet_insurance_result_count: {
        title: 'Insurance Payments',
        description: 'Number of Insurance Payments',
      },
      bet_new_sum: {
        title: 'Bets, $',
        description: 'Bets Sum',
      },
      bet_new_sports_sum: {
        title: 'Sport Bets, $',
        description: 'Sports Bets Sum',
      },
      bet_new_games_sum: {
        title: 'Casino Bets, $',
        description: 'Casino Bets Sum',
      },
      bet_result_sum: {
        title: 'Win Sum, $',
        description: 'Winnings Sum',
      },
      bet_result_sports_sum: {
        title: 'Sport Win Sum, $',
        description: 'Winnings Sum from Sports',
      },
      bet_result_games_sum: {
        title: 'Casino Win Sum, $',
        description: 'Winnings Sum from Casino',
      },
      bet_sell_profit_sum: {
        title: 'Bets Sell Profit, $',
        description: 'Profit from Sold Bets',
      },
      bet_insurance_sum: {
        title: 'Insurance Costs, $',
        description: 'Sum of Insurance Costs',
      },
      bet_insurance_result_sum: {
        title: 'Insurance Payments, $',
        description: 'Insurance Payout Sum',
      },
      players_with_bet_count: {
        title: 'Players w/ Bet',
        description: 'Number of Players with Bets',
      },
      profit_sum: {
        title: 'Profit, $',
        description: 'Profit Sum',
      },
      revenue_sum: {
        title: 'Revenue, $',
        description: 'Revenue Sum',
      },
      affiliate_program_profit_sum: {
        title: 'Total Revenue, $',
        description: 'Total Revenue',
      },
      deposits_first_avg: {
        title: 'Avg. FD, $',
        description: 'Average First Deposit Sum',
      },
      deposits_other_avg: {
        title: 'Avg. Redepo, $',
        description: 'Other Deposits Average Sum',
      },
      deposits_all_avg: {
        title: 'Avg. Depo, $',
        description: 'Average Deposit',
      },
      deposits_first_median: {
        title: 'Med. FD, $',
        description: 'Median First Deposits Sum',
      },
      deposits_other_median: {
        title: 'Med. Redepo, $',
        description: 'Other Deposits Median Sum',
      },
      deposits_all_median: {
        title: 'Med. Depo, $',
        description: 'Median Deposit',
      },
      bet_result_profit_sum: {
        title: 'Bets Profit, $',
        description: 'All Bets Profit',
      },
      bet_result_sports_profit_sum: {
        title: 'Sport Bets Profit, $',
        description: 'Sports Bets Profit',
      },
      bet_result_games_profit_sum: {
        title: 'Casino Bets Profit, $',
        description: 'Casino Bets Profit',
      },
      deposit_bonus_sum: {
        title: 'Depo Bonuses, $',
        description: 'Sum of Bonuses for Deposit',
      },
      deposit_bonus_count: {
        title: 'Depo. Bonuses',
        description: 'Number of Bonuses for Deposit',
      },
      players_uniq_count: {
        title: 'Active Players',
        description: 'Number of Players Who Made Any Action',
      },
      affiliates_uniq_count: {
        title: 'Active Affiliates',
        description: 'Number of Affiliates with Players Who Made Any Action',
      },
      median_bet_amount: {
        title: 'Med. Bet, $',
        description: 'Median Bet',
      },
      avg_bet_amount: {
        title: 'Avg Bet, $',
        description: 'Average Bet',
      },
      avg_sports_bet_amount: {
        title: 'Avg Sport Bet, $',
        description: 'Average Sports Bet',
      },
      median_sports_bet_amount: {
        title: 'Med Sport Bet, $',
        description: 'Median Sports Bet',
      },
      avg_games_bet_amount: {
        title: 'Avg Casino Bet, $',
        description: 'Average Casino Bet',
      },
      median_games_bet_amount: {
        title: 'Med Casino Bet, $',
        description: 'Median Casino Bet',
      },
      chargebacks_count: {
        title: 'Chargebacks',
        description: 'Total Number of Charges',
      },
      chargebacks_sum: {
        title: 'Chargebacks, $',
        description: 'Chargebacks Sum',
      },
      chargebacks_players_count: {
        title: 'Player w/ Chargeback',
        description: 'Total Number of Players Which Were Charged',
      },
      deposits_first_cpa_count: {
        title: 'First Depos (CPA)',
        description: 'Number of First Deposits (CPA payout rules)',
      },
      deposits_first_revshare_count: {
        title: 'First Depos (RS)',
        description: 'Number of First Deposits (RevShare payout rules)',
      },
      deposits_first_no_revenue_count: {
        title: 'First Depos (N/A)',
        description: 'Number of First Deposits (without payout rule)',
      },
      deposits_first_cpa_sum: {
        title: 'First Depos (CPA), $',
        description: 'First Deposits Sum (CPA payout rules)',
      },
      deposits_first_revshare_sum: {
        title: 'First Depos (RS), $',
        description: 'First Deposits Sum (RevShare payout rules)',
      },
      deposits_first_no_revenue_sum: {
        title: 'First Depos (N/A), $',
        description: 'First Deposits Sum (without payout rule)',
      },
      deposits_other_cpa_count: {
        title: 'Redeposits (CPA)',
        description: 'Number of Other Deposits (CPA payout rules)',
      },
      deposits_other_revshare_count: {
        title: 'Redeposits (RS)',
        description: 'Number of Other Deposits (RevShare payout rules)',
      },
      deposits_other_no_revenue_count: {
        title: 'Redeposits (N/A)',
        description: 'Number of Other Deposits (without payout rule)',
      },
      deposits_other_cpa_sum: {
        title: 'Redeposits (CPA), $',
        description: 'Other Deposits Sum (CPA payout rules)',
      },
      deposits_other_revshare_sum: {
        title: 'Redeposits (RS), $',
        description: 'Other Deposits Sum (RevShare payout rules)',
      },
      deposits_other_no_revenue_sum: {
        title: 'Redeposits (N/A), $',
        description: 'Other Deposits Sum (without payout rule)',
      },
      deposits_all_cpa_count: {
        title: 'Depos. (CPA)',
        description: 'Number of All Deposits (CPA payout rules)',
      },
      deposits_all_revshare_count: {
        title: 'Depos. (RS)',
        description: 'Number of All Deposits (RevShare payout rules)',
      },
      deposits_all_no_revenue_count: {
        title: 'Depos. (N/A)',
        description: 'Number of All Deposits (without payout rule)',
      },
      deposits_all_cpa_sum: {
        title: 'Deposits (CPA), $',
        description: 'Deposits Sum (CPA payout rules)',
      },
      deposits_all_revshare_sum: {
        title: 'Deposits (RS), $',
        description: 'Deposits Sum (RevShare payout rules)',
      },
      deposits_all_no_revenue_sum: {
        title: 'Deposits (N/A), $',
        description: 'Deposits Sum (without payout rule)',
      },
      deposits_withdrawals_cpa_count: {
        title: 'Withdrawals (CPA)',
        description: 'Number of Withdrawals (CPA payout rules)',
      },
      deposits_withdrawals_revshare_count: {
        title: 'Withdrawals (RS)',
        description: 'Number of Withdrawals (RevShare payout rules)',
      },
      deposits_withdrawals_no_revenue_count: {
        title: 'Withdrawals (N/A)',
        description: 'Number of Withdrawals (without payout rule)',
      },
      deposits_withdrawals_cpa_sum: {
        title: 'Withdrawals (CPA), $',
        description: 'Withdrawals Sum (CPA payout rules)',
      },
      deposits_withdrawals_no_revenue_sum: {
        title: 'Withdrawals (N/A), $',
        description: 'Withdrawals Sum (without payout rule)',
      },
      deposits_withdrawals_revshare_sum: {
        title: 'Withdrawals (RS), $',
        description: 'Withdrawals Sum (RevShare payout rules)',
      },
      deposits_flow_sum: {
        title: 'Turnover, $',
        description: 'Deposits and withdrawals sum',
      },
      bet_result_cpa_sum: {
        title: 'Win Sum (CPA), $',
        description: 'Winnings Sum (CPA payout rules)',
      },
      bet_result_no_revenue_sum: {
        title: 'Win Sum (N/A), $',
        description: 'Winnings Sum (without payout rule)',
      },
      bet_result_revshare_sum: {
        title: 'Win Sum (RS), $',
        description: 'Winnings Sum (RevShare payout rules)',
      },
      bet_result_sports_cpa_sum: {
        title: 'Sport Win Sum (CPA), $',
        description: 'Winnings Sum from Sports (CPA payout rules)',
      },
      bet_result_sports_no_revenue_sum: {
        title: 'Sport Win Sum (N/A), $',
        description: 'Winnings Sum from Sports (without payout rule)',
      },

      bet_result_sports_revshare_sum: {
        title: 'Sport Win Sum (RS), $',
        description: 'Winnings Sum from Sports (RevShare payout rules)',
      },
      bet_result_games_cpa_sum: {
        title: 'Casino Win Sum (CPA), $',
        description: 'Winnings Sum from Casino (CPA payout rules)',
      },
      bet_result_games_revshare_sum: {
        title: 'Casino Win Sum (RS), $',
        description: 'Winnings Sum from Casino (RevShare payout rules)',
      },
      bet_result_games_no_revenue_sum: {
        title: 'Casino Win Sum (N/A), $',
        description: 'Winnings Sum from Casino (without payout rule)',
      },
      bet_result_sports_profit_cpa_sum: {
        title: 'Sport Bets Profit (CPA), $',
        description: 'Sports Bets Profit (CPA payout rules)',
      },
      bet_result_sports_profit_revshare_sum: {
        title: 'Sport Bets Profit (RS), $',
        description: 'Sports Bets Profit (RevShare payout rules)',
      },
      bet_result_sports_profit_no_revenue_sum: {
        title: 'Sport Bets Profit (N/A), $',
        description: 'Sports Bets Profit (without payout rule)',
      },
      bet_result_games_profit_cpa_sum: {
        title: 'Casino Bets Profit (CPA), $',
        description: 'Casino Bets Profit (CPA payout rules)',
      },
      bet_result_games_profit_revshare_sum: {
        title: 'Casino Bets Profit (RS), $',
        description: 'Casino Bets Profit (RevShare payout rules)',
      },
      bet_result_games_profit_no_revenue_sum: {
        title: 'Casino Bets Profit (N/A), $',
        description: 'Casino Bets Profit (without payout rule)',
      },
      bet_result_profit_cpa_sum: {
        title: 'Bets Profit (CPA), $',
        description: 'All Bets Profit (CPA payout rules)',
      },
      bet_result_profit_revshare_sum: {
        title: 'Bets Profit (RS), $',
        description: 'All Bets Profit (RevShare payout rules)',
      },
      bet_result_profit_no_revenue_sum: {
        title: 'Bets Profit (N/A), $',
        description: 'All Bets Profit (without payout rule)',
      },
      bet_sell_profit_cpa_sum: {
        title: 'Bets Sell Profit (CPA), $',
        description: 'Profit from Sold Bets (CPA payout rules)',
      },
      bet_sell_profit_revshare_sum: {
        title: 'Bets Sell Profit (RS), $',
        description: 'Profit from Sold Bets (RevShare payout rules)',
      },
      bet_sell_profit_no_revenue_sum: {
        title: 'Bets Sell Profit (N/A), $',
        description: 'Profit from Sold Bets (without payout rule)',
      },
      bet_insurance_result_cpa_sum: {
        title: 'Insurance Payments (CPA), $',
        description: 'Insurance Payout Sum (CPA payout rules)',
      },
      bet_insurance_result_revshare_sum: {
        title: 'Insurance Payments (RS), $',
        description: 'Insurance Payout Sum (RevShare payout rules)',
      },
      bet_insurance_result_no_revenue_sum: {
        title: 'Insurance Payments (N/A), $',
        description: 'Insurance Payout Sum (without payout rule)',
      },
      profit_cpa_sum: {
        title: 'Profit (CPA), $',
        description: 'Profit Sum (CPA payout rules)',
      },
      profit_revshare_sum: {
        title: 'Profit (RS), $',
        description: 'Profit Sum (RevShare payout rules)',
      },
      profit_no_revenue_sum: {
        title: 'Profit (N/A), $',
        description: 'Profit Sum (without payout rule)',
      },
      new_players_profit_cpa_sum: {
        title: 'NP Profit (CPA), $',
        description: 'New Players Profit Sum (CPA payout rules)',
      },
      new_players_profit_revshare_sum: {
        title: 'NP Profit (RS), $',
        description: 'New Players Profit Sum (RevShare payout rules)',
      },
      new_players_profit_no_revenue_sum: {
        title: 'NP Profit (N/A), $',
        description: 'New Players Profit Sum (without payout rule)',
      },
      revenue_cpa_sum: {
        title: 'Revenue (CPA), $',
        description: 'Revenue Sum (CPA payout rules)',
      },
      revenue_revshare_sum: {
        title: 'Revenue (RS), $',
        description: 'Revenue Sum (RevShare payout rules)',
      },
      revenue_no_revenue_sum: {
        title: 'Revenue (N/A), $',
        description: 'Revenue Sum (without payout rule)',
      },
      affiliate_program_profit_cpa_sum: {
        title: 'Total Revenue (CPA), $',
        description: 'Total Revenue (CPA payout rules)',
      },
      affiliate_program_profit_revshare_sum: {
        title: 'Total Revenue (RS), $',
        description: 'Total Revenue (RevShare payout rules)',
      },
      new_players_revenue_sum: {
        title: 'NP Revenue, $',
        description: 'Revenue sum for new players',
      },
      new_players_revenue_cpa_sum: {
        title: 'NP Revenue (CPA), $',
        description: 'Revenue Sum (CPA payout rules)',
      },
      new_players_revenue_revshare_sum: {
        title: 'NP Revenue (RS), $',
        description: 'Revenue Sum (RevShare payout rules)',
      },
      new_players_revenue_no_revenue_sum: {
        title: 'NP Revenue (N/A), $',
        description: 'Revenue Sum (without payout rule)',
      },
      new_players_chargebacks_count: {
        title: 'NP Chargebacks',
        description: 'Number of chargebacks for new players',
      },
      new_players_chargebacks_sum: {
        title: 'NP Chargebacks, $',
        description: 'Sum of chargebacks for new players',
      },
      new_players_affiliate_program_profit_cpa_sum: {
        title: 'NP Total Revenue (CPA), $',
        description: 'New Players Total Revenue (CPA payout rules)',
      },
      new_players_affiliate_program_profit_revshare_sum: {
        title: 'NP Total Revenue (RS), $',
        description: 'New Players Total Revenue (RevShare payout rules)',
      },
      players_avg_lifetime_seconds: {
        title: 'Avg. lifetime',
        description: 'Players average lifetime',
      },
      players_median_lifetime_seconds: {
        title: 'Med lifetime',
        description: 'Players median lifetime',
      },
      players_avg_fd_since_registration_seconds: {
        title: 'Avg. FTD time',
        description: 'Average time between registration and first deposit',
      },
      players_median_fd_since_registration_seconds: {
        title: 'Med FTD time',
        description: 'Median time between registration and first deposit',
      },
      players_avg_deposits_sum: {
        title: 'Avg. deposit sum',
        description: 'Players average deposit sum',
      },
      players_avg_deposits_count: {
        title: 'Avg. number of depos.',
        description: 'Players average number of deposits',
      },
      players_avg_withdrawals_sum: {
        title: 'Avg. withdrawals sum',
        description: 'Players average withdrawal sum',
      },
      players_avg_withdrawals_count: {
        title: 'Avg. number of withdrawals',
        description: 'Players average number of withdrawals',
      },
      players_avg_profit_sum: {
        title: 'Avg. players profit',
        description: 'Players average profit sum',
      },
      players_median_profit_sum: {
        title: 'Med players profit',
        description: 'Players median profit sum',
      },
      affiliate_payments_sum: {
        title: 'Payments, $',
        description: 'Affiliate payment sum',
      },
      affiliate_payments_count: {
        title: 'Number of payments',
        description: 'Affiliate number of payments',
      },
      affiliate_payments_profit_percent: {
        title: 'Payments Profit, %',
        description: 'Affilaite payments profit percent',
      },
      affiliate_program_profit_no_revenue_sum: {
        title: 'Total Revenue (N/A), $',
        description: 'Total Revenue (without payout rule)',
      },
      new_players_affiliate_program_profit_no_revenue_sum: {
        title: 'NP Total Revenue (N/A), $',
        description: 'New Players Total Revenue (without payout rule)',
      },
      deposits_withdrawals_diff_sum: {
        title: 'Depo/Withdrawals Diff, $',
        description: 'Difference between deposits and withdrawals',
      },
      name: {
        title: 'Name',
        description: 'Player Name',
      },
      email: {
        title: 'Email',
        description: 'Player Email',
      },
      phone_number: {
        title: 'Phone Number',
        description: 'Player Phone Number',
      },
      first_name: {
        title: 'First Name',
        description: 'Player First Name',
      },
      last_name: {
        title: 'Last Name',
        description: 'Player Last Name',
      },
      new_players_profit_sum: {
        title: 'NP Profit, $',
        description: 'New Players Profit Sum',
      },
      new_players_affiliate_program_profit_sum: {
        title: 'NP Total Revenue, $',
        description: 'New Players Total Revenue',
      },
      affiliate_created_at: {
        title: 'Affiliate Created At',
        description: 'Affiliate Register Date',
      },
      referred_affiliate_created_at: {
        title: 'Sub-Affiliate Created At',
        description: 'Sub-Affiliate Register Date',
      },
      player_created_at: {
        title: 'Player Created At',
        description: 'Player Register Date',
      },
    },
    colunms_settings: {
      header: 'Columns Settings',
      title_visible: 'Columns Visibility',
      title_order: 'Columns Order',
      select_all: 'Select All',
      select_match: 'Select Matched',
      placeholder_search: 'Search…',
      show_only_select: 'Show Only Select',
      no_data: 'No Data',
      engagement: 'Engagement',
      deposits: 'Deposits',
      bets: 'Bets',
      other: 'Other',
      revenue: 'Revenue',
      colunms: 'Columns',
      hide: 'Hide',
      show: 'Show',
      vis_column: 'Visible Columns',
      additional: 'Additional',
    },
    time_days: 'day(s)',

  },
};
