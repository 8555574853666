<template>
  <div class="rotation-groups-card">
    <AffiliateRotationGroupsPopup
      v-if="
        (currentAcl.affiliates_rotation_groups_edit === 'allow' &&
          currentAcl.service_rotation_groups_view === 'allow') ||
          adminAcl.is_superuser
      "
      :is-open="isOpenRotationGroupsPopup"
      :inital-data="rotationGroups"
      @close="closeRotationGroupsPopup"
      @save="updateRotationGroupsForAffiliate"
    />
    <adm-ui-confirm ref="confirm" v-bind="confirmConfig" />

    <div v-if="rotationGroups.length" class="card">
      <div class="title">
        <div class="title-content">
          <span>{{
            $t('affiliates.affiliatesInfo.rotation_groups_setting')
          }}</span>
          <span
            v-if="editPermission"
            :style="{ color: $theme.accentColor }"
            class="action"
            @click="addRotationGroupsHandler"
          >
            <ui-icon name="plus" :color="$theme.dangerColor" lib="fa" />
            {{ $t(`affiliates.offer_popup.Add`) }}
          </span>
        </div>
      </div>
      <div class="card-content">
        <div
          v-for="item in rotationGroups"
          :key="item.id"
          class="field without-padding"
        >
          <div class="card">
            <div class="field">
              <div class="column">
                <div class="column-text">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.type !== 'primary' && editPermission" class="field">
            <span
              class="action success"
              @click="handlerConfirm('setMain', item)"
            >
              <ui-icon name="check" class="success" lib="fa" />
              {{ $t('affiliates.affiliatesInfo.set_as_main') }}
            </span>
            <span class="action danger" @click="handlerConfirm('delete', item)">
              <ui-icon
                name="trash-alt"
                class="danger"
                :color="$theme.dangerColor"
                lib="fa"
              />
              {{ $t(`affiliates.affiliatePromocode.delete_promocode`) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AffiliateRotationGroupsPopup from '@/components/Popups/AffiliateRotationGroupsPopup';

export default {
  name: 'RotationGroupsCard',

  components: {
    AffiliateRotationGroupsPopup,
  },

  props: {
    editPermission: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      rotationGroups: [],
      isOpenRotationGroupsPopup: false,
      confirmType: '',
      currentRotation: {},
    };
  },

  computed: {
    ...mapGetters({
      currentAcl: 'auth/currentAcl',
      adminAcl: 'auth/adminAcl',
    }),

    confirmConfig() {
      switch (this.confirmType) {
      case 'setMain':
        return {
          title: this.$t('affiliates.confirn_rotation_set_main.title'),
          icon: 'fas fa-exclamation-triangle',
          text: this.$t('affiliates.confirn_rotation_set_main.text'),
          buttonsData: [
            {
              title: this.$t('affiliates.delete_offer.cancel'),
              key: 'cancel',
              type: 'danger',
              plain: true,
              clickHandler: this.cancelHandler,
            },
            {
              title: this.$t('affiliates.affiliatesInfo.set_as_main'),
              key: 'main',
              type: 'primary',
              icon: 'fas fa-check',
              clickHandler: this.setMain,
            },
          ],
        };

      default:
        return {
          title: this.$t('affiliates.confirn_rotation_delete.title'),
          icon: 'fas fa-exclamation-triangle',
          text: this.$t('affiliates.confirn_rotation_delete.text'),
          buttonsData: [
            {
              title: this.$t('affiliates.delete_offer.cancel'),
              key: 'cancel',
              icon: 'fas fa-times',
              type: 'danger',
              plain: true,
              clickHandler: this.cancelHandler,
            },
            {
              title: this.$t(
                'affiliates.affiliatePromocode.delete_promocode',
              ),
              key: 'delete',
              type: 'danger',
              icon: 'fas fa-trash',
              clickHandler: this.deleteRotation,
            },
          ],
        };
      }
    },
  },

  methods: {
    async deleteRotation() {
      try {
        await this.$api.deleteRotationGroupsForAffiliate(
          this.id,
          this.currentRotation.id,
        );
      } finally {
        this.getRotationGroupsForAffiliate(this.id);
        this.cancelHandler();
      }
    },

    async setMain() {
      try {
        await this.$api.setPrimeryRotationGroupsForAffiliate(
          this.id,
          this.currentRotation.id,
        );
      } finally {
        this.getRotationGroupsForAffiliate(this.id);
        this.cancelHandler();
      }
    },

    cancelHandler() {
      this.$refs.confirm.close();
    },

    handlerConfirm(action, data) {
      this.confirmType = action;
      this.currentRotation = data;
      this.$refs.confirm.open();
    },

    async addRotationGroupsHandler() {
      this.openRotationGroupsPopup();
    },

    openRotationGroupsPopup() {
      this.isOpenRotationGroupsPopup = true;
    },

    closeRotationGroupsPopup() {
      this.isOpenRotationGroupsPopup = false;
    },

    async updateRotationGroupsForAffiliate(data) {
      try {
        await this.$api.addRotationGroupsForAffiliate(this.id, data);
      } finally {
        this.getRotationGroupsForAffiliate(this.id);
      }
    },

    async getRotationGroupsForAffiliate(id) {
      try {
        const {
          data: { payload },
        } = await this.$api.getRotationGroupsForAffiliate(id);
        this.rotationGroups = payload.sort(
          (a, b) => a.type.length - b.type.length,
        );
      } catch (error) {
        this.rotationGroups = [];
      }
    },
  },
};
</script>
