// affiliates views
import Affiliates from '@/views/Affiliates.vue';
import AffiliateCard from '@/views/Affiliates/AffiliateCard.vue';

import AffiliatesList from '@/views/Affiliates/AffiliatesList.vue';
import AffiliatesNotifications from '@/views/Affiliates/AffiliatesNotifications.vue';

import AffiliateInfo from '@/views/Affiliates/Affiliate/AffiliateInfo.vue';
import AffiliateActivity from '@/views/Affiliates/Affiliate/AffiliateActivity.vue';
import BalanceHistory from '@/views/Affiliates/Affiliate/BalanceHistory.vue';
import AffiliateOffers from '@/views/Affiliates/Affiliate/AffiliateOffersNew.vue';
import AffiliateBonuses from '@/views/Affiliates/Affiliate/AffiliateBonuses.vue';
import AffiliatePromoCodes from '@/views/Affiliates/Affiliate/AffiliatePromoCodes.vue';
import EstimatedPayment from '@/views/Affiliates/Affiliate/EstimatedPayment.vue';
import AffiliatePayments from '@/views/Affiliates/Affiliate/AffiliatePayments.vue';
import AffiliateSub from '@/views/Affiliates/Affiliate/AffiliateSub.vue';
import DedicatedDomains from '@/views/Affiliates/Affiliate/DedicatedDomains.vue';

import AffiliatesReview from '@/views/Affiliates/AffiliatesReview.vue';

export default [
  {
    path: '/affiliates',
    component: Affiliates,
    meta: {
      requiresAuth: true,
      defaultLink: true,
      view: 'affiliates',
    },
    children: [
      {
        path: 'list',
        component: AffiliatesList,
        name: 'list',
        meta: {
          view: 'list',
        },
      },
      {
        path: 'review',
        component: AffiliatesReview,
        name: 'review',
        meta: {
          view: 'review',
        },
      },
      {
        path: 'notification',
        component: AffiliatesNotifications,
        name: 'notification',
        meta: {
          view: 'notification',
        },
      },
      {
        path: ':id',
        component: AffiliateCard,
        children: [
          {
            path: 'info',
            component: AffiliateInfo,
            meta: {
              view: 'info',
            },
          },
          {
            path: 'activity',
            component: AffiliateActivity,
            name: 'activity',
            meta: {
              view: 'info',
            },
          },
          {
            path: 'balance-history/:wallet_id',
            name: 'balance-history',
            component: BalanceHistory,
            meta: {
              view: 'info',
            },
          },
          {
            path: 'offers',
            component: AffiliateOffers,
            meta: {
              view: 'offers',
            },
          },
          {
            path: 'bonuses',
            component: AffiliateBonuses,
            meta: {
              view: 'bonuses',
            },
          },
          {
            path: 'promo-codes',
            component: AffiliatePromoCodes,
            meta: {
              view: 'promocodes',
            },
          },
          {
            path: 'payments-reports',
            component: EstimatedPayment,
            meta: {
              view: 'estpayment',
            },
          },
          {
            path: 'payments-history',
            component: AffiliatePayments,
            meta: {
              view: 'payments',
            },
          },
          {
            path: 'sub-affiliates',
            component: AffiliateSub,
            meta: {
              view: 'sub',
            },
          },
          {
            path: 'dedication-domains',
            component: DedicatedDomains,
            meta: {
              view: 'dedication',
            },
          },
        ],
      },
      {
        path: '',
        redirect: 'list',
      },
      {
        path: '*',
        redirect: 'list',
      },
    ],
  },
];
