export default {
  marketingMaterials: {
    pageTitle: 'Promo Materials',
    buttons: {
      add_material: 'Add Material',
      delete: 'Delete',
      edit: 'Edit',
    },
    fields: {
      incrementing_id: {
        title: 'ID',
      },
      name: {
        title: 'Name',
      },
      type: {
        title: 'Type',
        html5_banner: 'HTML5 Banner',
        simple_banner: 'Simple Banner',
        landing: 'Landing',
      },
      visibility: {
        title: 'Availability',
        public: 'Public',
        private: 'Private',
        hidden: 'Hidden',
      },
      status: {
        title: 'Status',
        active: 'Active',
        inactive: 'Inactive',
      },
      created_at: {
        title: 'Created',
      },
      updated_at: {
        title: 'Updated',
      },
      language_codes: {
        title: 'Languages',
      },
      impressions_count: {
        title: 'Impress.',
      },
      visits_count: {
        title: 'Visits',
      },
      registrations_count: {
        title: 'Reg.',
      },
      conversion_rate: {
        title: 'Conv. rate, %',
      },
      brand_visibility: {
        title: 'Brands',
        all: 'All Brands',
      },
    },
    popup: {
      title: 'Promo Material',
      add: 'Add',
      edit: 'Edit',
      form: {
        name: {
          label: 'Name',
          placeholder: 'Material name',
        },
        type: {
          label: 'Material Type',
          banner: 'Banner',
          landing: 'Landing',
        },
        bannerType: {
          label: 'Banner Type',
          html5_banner: 'HTML5 Banner',
          simple_banner: 'Simple Banner',
        },
        visibility: {
          label: 'Availability',
          public: 'Public',
          private: 'Private',
          hidden: 'Hidden',
          placeholder: 'Select Affiliates emails who can use the landings',
        },
        upload: {
          label: 'Upload a file',
        },
        language_codes: {
          label: 'Languages',
          placeholder: 'Choose language',
        },
        width: {
          label: 'Width',
          placeholder: 'Enter width',
        },
        height: {
          label: 'Height',
          placeholder: 'Enter height',
        },
        status: {
          label: 'Status',
          active: 'Active',
          inactive: 'Inactive',
        },
        landing_path: {
          label: 'Landing Path',
          placeholder: 'Enter landing path',
        },
        supported_platforms: {
          label: 'Supported Platforms',
          desktop: 'Desktop',
          mobile: 'Mobile',
        },
      },
      cancel: 'Cancel',
      save: 'Save',
    },
    messages: {
      delete: 'Are you sure you want to delete <span>{name}</span> ?',
      'drop-file-here-or': 'Drop file here or',
      'click-to-upload': 'click to upload',
      replace_file: 'Replace file',
      download_file: 'Download file',
      uploading: 'Uploading...',
    },
  },
};
