<template>
  <div class="dasboard-filter">
    <adm-ui-header class="dasboard-filter__title" tag="h2">
      {{ $t('dashboards.dashboards_filters.filter') }}
    </adm-ui-header>

    <adm-ui-wall class="dasboard-filter__wrap">
      <div class="dasboard-filter__select-filter">
        <ui-filter-select
          ref="filter"
          v-model="filters"
          :use-filters="filtersItems"
          @input="handlerSelectFilter"
          @isDisableSelectFilter="isDisableSelectFilter"
        />
      </div>
      <hr>

      <filter-tabs
        :filter-list="defaultFilterList"
        :current-search="currentSearch"
        :reports-settings="reportsSettings"
        :search-by-list="searchByList"
        :is-searching-filter="isSearchingFilter"
        @deleteCurrentSearchGroup="clearReportsSearchFilter"
        @selectedSearchFilter="selectedSearchFilter"
      />
    </adm-ui-wall>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FilterTabs from '@/components/FilterTabs.vue';

export default {
  name: 'DashboardFilter',
  components: {
    FilterTabs,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },

    initFilters: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      controlsData: [
        {
          title: 'Info',
          type: 'primary',
          icon: 'far fa-clone',
          clickHandler: () => {},
        },
        {
          title: 'Delete',
          type: 'danger',
          icon: 'fas fa-trash-alt',
          clickHandler: () => {},
        },
      ],
      filters: this.initFilters,
      defaultFilterList: {
        site_player_id: {
          icon: 'id-badge',
          order: 1,
        },
        affiliate_email: {
          icon: 'user',
          order: 2,
        },
        sub_affiliate_email: {
          icon: 'users',
          order: 3,
        },
        referrer_domain: {
          icon: 'globe',
          order: 4,
        },
      },
      isOpenOtherPopup: false,
      currentSearch: this.data,
    };
  },

  computed: {
    ...mapGetters('reports', ['reportsSettings']),

    filtersItems() {
      const val = [
        'trafficType',
        'playerFilter',
        'playerCountryNameFilter',
        'playerRegionNameFilter',
        'trafficRegionNameFilter',
      ];
      return val;
    },

    searchByList() {
      return this.reportsSettings.dimensions.reduce((acc, el) => {
        if (this.defaultFilterList.hasOwnProperty(el.column_name)) {
          acc[el.column_name] = this.defaultFilterList[el.column_name];
        }
        return acc;
      }, {});
    },

    isSearchingFilter() {
      const filtersList = Object.keys(this.searchByList);
      return filtersList.reduce((acc, elem) => {
        acc[elem] = this.$_.has(this.currentSearch, elem);
        return acc;
      }, {});
    },
  },

  methods: {
    isDisableSelectFilter(v) {
      this.$emit('isDisableSelectFilter', v);
    },

    handlerSelectFilter(arg) {
      const noParse = ['traffic_type', 'players_filter'];
      const allKeyFilters = Object.keys(arg);

      const parseFilter = allKeyFilters.reduce((acc, val) => {
        if (noParse.includes(val)) {
          acc[val] = arg[val].value;
          return acc;
        }
        acc.filters[val] = {
          op: arg[val].op || '=',
          value: arg[val].value,
        };
        return acc;
      }, { filters: {} });

      this.$emit('handlerDashboardFilter', parseFilter);
    },

    selectedSearchFilter(search) {
      this.currentSearch = this.$_.cloneDeep(search);
      this.$emit('handlerDashboardTabFilter', this.currentSearch);
    },

    clearReportsSearchFilter(group) {
      this.$delete(this.currentSearch, group);
      this.$emit('handlerDashboardTabFilter', this.currentSearch);
    },
  },
};
</script>
