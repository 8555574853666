export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_BY_TOKEN = 'AUTH_BY_TOKEN';
export const AUTH_SAVE_TOKEN = 'AUTH_SAVE_TOKEN';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_2FA_REQUEST = 'AUTH_2FA_REQUEST';
export const AUTH_2FA_SUCCESS = 'AUTH_2FA_SUCCESS';
export const AUTH_2FA_ERROR = 'AUTH_2FA_ERROR';
export const AUTH_2FA_RESET = 'AUTH_2FA_RESET';
export const AUTH_QR_SET = 'AUTH_QR_SET';

export const AUTH_ADMIN_INFO = 'AUTH_ADMIN_INFO';
export const AUTH_ADMIN_ACL = 'AUTH_ADMIN_ACL';
export const AUTH_CURRENT_TEAM = 'AUTH_CURRENT_TEAM';
export const AUTH_CURRENT_ACL = 'AUTH_CURRENT_ACL';
export const BEFORE_GET_ACL = 'BEFORE_GET_ACL';
export const CHANGE_ENOUGH_PERMISSIONS = 'CHANGE_ENOUGH_PERMISSIONS';


export const AUTH_ITEM_AVAILABILITY = 'AUTH_ITEM_AVAILABILITY';
