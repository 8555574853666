<template functional>
  <div
    class="crm-panel-delimiter"
    :class="{
      [data.staticClass]: data.staticClass,
      'crm-panel-delimiter_type-vertical': props.type === 'vertical',
      'crm-panel-delimiter_type-horizontal': props.type === 'horizontal',
      'crm-panel-delimiter_color-default': props.color === 'default',
      'crm-panel-delimiter_color-contrast': props.color === 'contrast',
    }"
    :style="data.staticStyle || ''"
    v-bind="data.attrs"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'horizontal',
    },
    color: {
      type: String,
      default: 'default',
    },
  },
};
</script>

<style lang="scss">
.crm-panel-delimiter {
  display: block;

  &_type-horizontal {
    flex-grow: 1;
    border-bottom: 1px solid;
    width: 100%;
  }

  &_type-vertical {
    border-left: 1px solid;
    height: 100%;
  }

  &_color-default {
    border-color: #e7ecf1;
  }

  &_color-contrast {
    border-color: #d8d8d8;
  }
}
</style>
