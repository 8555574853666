<template>
  <div
    class="user-permissions"
  >
    <div class="popup">
      <div class="head">
        <span class="name">{{ this.$t('team_popup.user_permissions_title', {name: userInfo.name}) }}</span>

        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>

      <div
        ref="scroll"
        v-loading="isLoading"
        class="permissions-wrapper"
      >
        <div class="select-block select-block__full">
          <span class="label">{{ $t('permissions.teams_select') }}</span>
          <el-select
            ref="selectclose"
            v-model="currentTeamSelect"
            :placeholder="$t('permissions.teams_select')"
            filterable
            class="select"
            size="big"
          >
            <el-option
              v-for="item in userTeams"
              :key="item.name"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <el-collapse v-model="activePermissions">
          <el-collapse-item
            :title="$t('permissions.reports.title')"
            name="reports"
          >
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.reports.reports_card_title') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('reports_view')" />

                  <div class="field field-with-field">
                    <div class="field">
                      <span class="label">{{ $t('permissions.reports.reports_affiliates_types') }}</span>
                      <div class="values ">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('reports_affiliates_types')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.reports_affiliates_types"
                              :disabled="inheritIsDisabled('reports_affiliates_types')"
                              :class="['checkbox']"
                              @change="refreshInheritReports(inherits.reports_affiliates_types)"
                            />
                          </div>
                        </el-tooltip>
                        <div class="access-status" />
                      </div>
                    </div>
                    <el-checkbox-group
                      v-model="adminPermissions.reports_affiliates_types"
                      :disabled="reportsDisabledColumns"
                    >
                      <div class="permission-column">
                        <el-checkbox
                          label="independent"
                          class="checkbox"
                        >
                          {{ $t('permissions.independent') }}
                        </el-checkbox>
                        <el-checkbox
                          label="own_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.own_affiliates') }}
                        </el-checkbox>
                      </div>
                      <div class="permission-column">
                        <el-checkbox
                          label="other_teams_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.other_teams_affiliates') }}
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                    <permission-tree
                      :disabled="reportsDisabledColumns"
                      :users-list="usersList"
                      :affiliates-types="adminPermissions.reports_affiliates_types"
                      :teammates-ids.sync="adminPermissions.reports_view_affiliates_visibility_teammates_id"
                    />
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.reports.reports_group_by') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('reports_group_by_affiliates')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('reports_group_by_countries')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('reports_group_by_sub_affiliates')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('reports_group_by_payments')" />
                </div>
              </div>
            </div>
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.reports.reports_view_columns') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('reports_view_revenue_columns')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('reports_view_profit_columns')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('reports_view_deposits_sum_columns')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('reports_view_deposits_count_columns')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('reports_view_withdrawals_sum_columns')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('reports_view_withdrawals_count_columns')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('reports_view_sport_bets_sum_columns')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('reports_view_sport_bets_count_columns')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('reports_view_casino_bets_sum_columns')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('reports_view_casino_bets_count_columns')" />
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item
            :title="$t('permissions.affiliates.title')"
            name="affiliates"
          >
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.affiliates.affiliates_card_title') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_view')" />
                  <div class="field field-with-field">
                    <div class="field">
                      <span class="label">{{ $t('permissions.affiliates.affiliates_types_view') }}</span>
                      <div class="values ">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('affiliates_types_view')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.affiliates_types_view"
                              :disabled="inheritIsDisabled('affiliates_types_view')"
                              :class="['checkbox']"
                              @change="refreshInheritViewAffiliates(inherits.affiliates_types_view)"
                            />
                          </div>
                        </el-tooltip>
                        <div class="access-status" />
                      </div>
                    </div>
                    <el-checkbox-group
                      v-model="adminPermissions.affiliates_types_view"
                      :disabled="AffiliatesViewColumnsDisabled"
                    >
                      <div class="permission-column">
                        <el-checkbox
                          label="independent"
                          class="checkbox"
                        >
                          {{ $t('permissions.independent') }}
                        </el-checkbox>
                        <el-checkbox
                          label="own_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.own_affiliates') }}
                        </el-checkbox>
                      </div>
                      <div class="permission-column">
                        <el-checkbox
                          label="other_teams_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.other_teams_affiliates') }}
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                    <permission-tree
                      :disabled="AffiliatesViewColumnsDisabled"
                      :users-list="usersList"
                      :affiliates-types="adminPermissions.affiliates_types_view"
                      :teammates-ids.sync="adminPermissions.affiliates_view_affiliates_visibility_teammates_id"
                    />
                  </div>

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_edit')" />

                  <div class="field field-with-field">
                    <div class="field">
                      <span class="label">{{ $t('permissions.affiliates.affiliates_types_edit') }}</span>
                      <div class="values ">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('affiliates_types_edit')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.affiliates_types_edit"
                              :disabled="inheritIsDisabled('affiliates_types_edit')"
                              :class="['checkbox']"
                              @change="refreshInheritEditAffiliates(inherits.affiliates_types_edit)"
                            />
                          </div>
                        </el-tooltip>
                        <div class="access-status" />
                      </div>
                    </div>
                    <el-checkbox-group
                      v-model="adminPermissions.affiliates_types_edit"
                      :disabled="AffiliatesEditColumnsDisabled"
                    >
                      <div class="permission-column">
                        <el-checkbox
                          label="independent"
                          class="checkbox"
                        >
                          {{ $t('permissions.independent') }}
                        </el-checkbox>
                        <el-checkbox
                          label="own_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.own_affiliates') }}
                        </el-checkbox>
                      </div>
                      <div class="permission-column">
                        <el-checkbox
                          label="other_teams_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.other_teams_affiliates') }}
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                    <permission-tree
                      :disabled="AffiliatesEditColumnsDisabled"
                      :users-list="usersList"
                      :affiliates-types="adminPermissions.affiliates_types_edit"
                      :teammates-ids.sync="adminPermissions.affiliates_edit_affiliates_visibility_teammates_id"
                    />
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.affiliates.affiliates_sub_affiliates') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_sub_affiliates_edit_settings')" />

                  <div class="field field-input">
                    <span class="label">{{ $t('permissions.affiliates.affiliates_sub_affiliates_max_percent') }}</span>
                    <div class="values ">
                      <div class="inherit-check-wrapper">
                        <el-checkbox
                          v-model="inherits.affiliates_sub_affiliates_max_percent"
                          :disabled="inheritIsDisabled('affiliates_sub_affiliates_max_percent')"
                          :class="['checkbox']"
                          @change="refreshInheritSubAff(inherits.affiliates_sub_affiliates_max_percent)"
                        />
                      </div>
                      <ui-input
                        v-model="adminPermissions.affiliates_sub_affiliates_max_percent"
                        :disabled="SubAffiliatesValueDisabled"
                        :error="errors.affiliates_sub_affiliates_max_percent"
                        error-tooltip
                        size="medium"
                        class=""
                        width="66px"
                        post-icon="percent"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.affiliates.affiliates_actions_title') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_activity_log_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_can_login')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_payment_info_edit')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_domain_change_notifications_edit')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_reporting_settings_rs_profit_visibility_edit')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_reporting_settings_cpa_profit_visibility_edit')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_email_edit')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_payments_can_create')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_media_buying_edit')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_promo_codes_edit')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_balance_edit')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_rotation_groups_edit')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_limits_edit')" />
                </div>
              </div>
            </div>
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.offers.title') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('offers_edit')" />
                </div>
              </div>

              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.offers.offers_revshare') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('offers_revshare_edit_settings')" />

                  <div class="field field-input">
                    <span class="label">{{ $t('permissions.offers.offers_revshare_max_percent_sport') }}</span>
                    <div class="values ">
                      <div class="inherit-check-wrapper">
                        <el-checkbox
                          v-model="inherits.offers_revshare_max_percent_sport"
                          :disabled="inheritIsDisabled('offers_revshare_max_percent_sport')"
                          :class="['checkbox']"
                          @change="refreshInheritRevshareSport(inherits.offers_revshare_max_percent_sport)"
                        />
                      </div>
                      <ui-input
                        v-model="adminPermissions.offers_revshare_max_percent_sport"
                        :disabled="setRevShareDisabledSport"
                        :error="errors.offers_revshare_max_percent_sport"
                        error-tooltip
                        size="medium"
                        class=""
                        width="66px"
                        post-icon="percent"
                      />
                    </div>
                  </div>
                  <div class="field field-input">
                    <span class="label">{{ $t('permissions.offers.offers_revshare_max_percent_casino') }}</span>
                    <div class="values ">
                      <div class="inherit-check-wrapper">
                        <el-checkbox
                          v-model="inherits.offers_revshare_max_percent_casino"
                          :disabled="inheritIsDisabled('offers_revshare_max_percent_casino')"
                          :class="['checkbox']"
                          @change="refreshInheritRevshareCasino(inherits.offers_revshare_max_percent_casino)"
                        />
                      </div>
                      <ui-input
                        v-model="adminPermissions.offers_revshare_max_percent_casino"
                        :disabled="setRevShareDisabledCasino"
                        :error="errors.offers_revshare_max_percent_casino"
                        error-tooltip
                        size="medium"
                        class=""
                        width="66px"
                        post-icon="percent"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.offers.offers_cpa') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('offers_cpa_edit_settings')" />
                  <div class="field field-input select">
                    <span class="label">{{ $t('permissions.offers.offers_cpa_max_payout_currency') }}</span>
                    <div class="inherit-check-wrapper">
                      <el-checkbox
                        v-model="inherits.offers_cpa_max_payout_currency_code"
                        :disabled="inheritIsDisabled('offers_cpa_max_payout_currency_code')"
                        class="checkbox"
                        @change="refreshInheritCpaMaxCurrency(inherits.offers_cpa_max_payout_currency_code)"
                      />
                    </div>
                    <div class="values">
                      <el-select
                        v-model="adminPermissions.offers_cpa_max_payout_currency_code"
                        :placeholder="$t('permissions.offers.not_selected')"
                        class="select"
                        filterable
                        size="small"
                        :disabled="setCpaDisabledMaxCurrencyCode"
                      >
                        <el-option
                          v-for="item in currencyOptions"
                          :key="item.code"
                          :label="item.name"
                          :value="item.code"
                        />
                      </el-select>
                    </div>
                  </div>
                  <div class="field field-input">
                    <span class="label">{{ $t('permissions.offers.offers_cpa_max_payout') }}</span>
                    <div class="values ">
                      <div class="inherit-check-wrapper">
                        <el-checkbox
                          v-model="inherits.offers_cpa_max_payout"
                          :disabled="inheritIsDisabled('offers_cpa_max_payout')"
                          :class="['checkbox']"
                          @change="refreshInheritCpaMax(inherits.offers_cpa_max_payout)"
                        />
                      </div>
                      <ui-input
                        v-model="adminPermissions.offers_cpa_max_payout"
                        :disabled="setCpaDisabledMax"
                        size="medium"
                        class=""
                        width="66px"
                      />
                    </div>
                  </div>
                  <div class="field field-input">
                    <span class="label">{{ $t('permissions.offers.offers_cpa_min_payout_deposits_sum_ratio') }}</span>
                    <div class="values ">
                      <div class="inherit-check-wrapper">
                        <el-checkbox
                          v-model="inherits.offers_cpa_min_payout_deposits_sum_ratio"
                          :disabled="inheritIsDisabled('offers_cpa_min_payout_deposits_sum_ratio')"
                          :class="['checkbox']"
                          @change="refreshInheritCpaRatio(inherits.offers_cpa_min_payout_deposits_sum_ratio)"
                        />
                      </div>
                      <ui-input
                        v-model="adminPermissions.offers_cpa_min_payout_deposits_sum_ratio"
                        :disabled="setCpaDisabledRatio"
                        size="medium"
                        class=""
                        width="66px"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.affiliates.affiliates_notes') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_notes_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_notes_edit')" />
                </div>
              </div>

              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.affiliates.affiliates_security_title') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_password_edit')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_2fa_can_reset')" />
                </div>
              </div>
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.affiliates.affiliates_status_change_title') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_can_approve')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_can_decline')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_can_block')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_can_unblock')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_can_revoke')" />
                </div>
              </div>
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.affiliates.affiliates_notifications') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_notifications_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('affiliates_notifications_edit')" />
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item
            :title="$t('permissions.payments.title')"
            name="payments"
          >
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.payments.payments_card_title') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('payments_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('payments_review_actions')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('payments_chargebacks_actions')" />
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item
            :title="$t('permissions.players.title')"
            name="players"
          >
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.players.players_card_title') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('players_view')" />

                  <div class="field field-with-field">
                    <div class="field">
                      <span class="label">{{ $t('permissions.players.players_view_columns') }}</span>
                      <div class="values ">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('players_available_properties')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.players_available_properties"
                              :disabled="inheritIsDisabled('players_available_properties')"
                              :class="['checkbox']"
                              @change="refreshInheritPlayers(inherits.players_available_properties)"
                            />
                          </div>
                        </el-tooltip>
                        <div class="access-status" />
                      </div>
                    </div>
                    <el-checkbox-group
                      v-model="adminPermissions.players_available_properties"
                      :disabled="PlayersViewDisabledColumns"
                    >
                      <div class="permission-column">
                        <el-checkbox
                          label="email"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_email') }}
                        </el-checkbox>
                        <el-checkbox
                          label="first_name"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_name_first') }}
                        </el-checkbox>
                        <el-checkbox
                          label="last_name"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_name_last') }}
                        </el-checkbox>
                        <el-checkbox
                          label="phone_number"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_phone') }}
                        </el-checkbox>
                        <el-checkbox
                          label="player_country_code"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_country_code') }}
                        </el-checkbox>
                        <el-checkbox
                          label="player_country_name"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_country_name') }}
                        </el-checkbox>
                        <el-checkbox
                          label="is_virtual"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_virtual_acc') }}
                        </el-checkbox>
                        <el-checkbox
                          label="affiliate_id"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_affiliate_id') }}
                        </el-checkbox>
                        <el-checkbox
                          label="affiliate_email"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_affiliate_email') }}
                        </el-checkbox>
                        <el-checkbox
                          label="external_id"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_external_id') }}
                        </el-checkbox>
                      </div>
                      <div class="permission-column">
                        <el-checkbox
                          label="subid1"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_subid1') }}
                        </el-checkbox>
                        <el-checkbox
                          label="subid2"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_subid2') }}
                        </el-checkbox>
                        <el-checkbox
                          label="subid3"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_subid3') }}
                        </el-checkbox>
                        <el-checkbox
                          label="subid4"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_subid4') }}
                        </el-checkbox>
                        <el-checkbox
                          label="subid5"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_subid5') }}
                        </el-checkbox>
                        <el-checkbox
                          label="created_at"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_created_at') }}
                        </el-checkbox>
                        <el-checkbox
                          label="updated_at"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_updated_at') }}
                        </el-checkbox>
                        <el-checkbox
                          label="last_active_at"
                          class="checkbox"
                        >
                          {{ $t('permissions.players.players_view_last_active_at') }}
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.players.players_card_other') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('players_virtual_edit_settings')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('players_assign_affiliate')" />
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item
            :title="$t('permissions.dms.title')"
            name="dms"
          >
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.dms.dns_hosting_accounts') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('dns_hosting_accounts_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('dns_hosting_accounts_edit')" />
                </div>
              </div>
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.dms.domain_registrar_accounts') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('domain_registrar_accounts_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('domain_registrar_accounts_edit')" />
                </div>
              </div>
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.dms.platform_servers') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('platform_servers_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('platform_servers_edit')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('platform_servers_can_run_jobs')" />
                </div>
              </div>
            </div>
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.dms.platform_service_domains') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('platform_service_domains_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('platform_service_domains_edit')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('platform_service_domains_can_run_jobs')" />
                </div>
              </div>
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.dms.service_rotation_groups') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('service_rotation_groups_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('service_rotation_groups_edit')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('service_rotation_groups_domains_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('service_rotation_groups_domains_edit')" />
                </div>
              </div>
              <!-- /// -->
              <div class="card">
                <div class="title">
                  <span>{{ $t(`permissions.dms.dedicated_site_domains`) }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t(`permissions.inherit`) }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t(`permissions.status`) }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('platform_betting_domains_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('platform_betting_domains_edit')" />
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item
            :title="$t('permissions.media_buying.title')"
            name="media-buying"
          >
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.media_buying.payments') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('media_buying_payments_view')" />
                  <div class="field field-with-field">
                    <div class="field">
                      <span class="label">{{ $t('permissions.media_buying.media_buying_payments_affiliates_types_view') }}</span>
                      <div class="values ">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('media_buying_payments_affiliates_types_view')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.media_buying_payments_affiliates_types_view"
                              :disabled="inheritIsDisabled('media_buying_payments_affiliates_types_view')"
                              :class="['checkbox']"
                              @change="refreshInheritViewPayments(inherits.media_buying_payments_affiliates_types_view)"
                            />
                          </div>
                        </el-tooltip>
                        <div class="access-status" />
                      </div>
                    </div>
                    <el-checkbox-group
                      v-model="adminPermissions.media_buying_payments_affiliates_types_view"
                      :disabled="MediaBuyingPaymentsViewColumnsDisabled"
                    >
                      <div class="permission-column">
                        <el-checkbox
                          label="independent"
                          class="checkbox"
                        >
                          {{ $t('permissions.independent') }}
                        </el-checkbox>
                        <el-checkbox
                          label="own_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.own_affiliates') }}
                        </el-checkbox>
                      </div>
                      <div class="permission-column">
                        <el-checkbox
                          label="other_teams_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.other_teams_affiliates') }}
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                    <permission-tree
                      :disabled="MediaBuyingPaymentsViewColumnsDisabled"
                      :users-list="usersList"
                      :affiliates-types="adminPermissions.media_buying_payments_affiliates_types_view"
                      :teammates-ids.sync="adminPermissions.media_buying_payments_view_affiliates_visibility_teammates_id"
                    />
                  </div>

                  <vnodes :vnodes="renderPermissionSwitchControl('media_buying_payments_review')" />

                  <div class="field field-with-field">
                    <div class="field">
                      <span class="label">{{ $t('permissions.media_buying.media_buying_payments_affiliates_types_review') }}</span>
                      <div class="values ">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('media_buying_payments_affiliates_types_review')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.media_buying_payments_affiliates_types_review"
                              :disabled="inheritIsDisabled('media_buying_payments_affiliates_types_review')"
                              :class="['checkbox']"
                              @change="refreshInheritEditPayments(inherits.media_buying_payments_affiliates_types_review)"
                            />
                          </div>
                        </el-tooltip>
                        <div class="access-status" />
                      </div>
                    </div>
                    <el-checkbox-group
                      v-model="adminPermissions.media_buying_payments_affiliates_types_review"
                      :disabled="MediaBuyingPaymentsReviewColumnsDisabled"
                    >
                      <div class="permission-column">
                        <el-checkbox
                          label="independent"
                          class="checkbox"
                        >
                          {{ $t('permissions.independent') }}
                        </el-checkbox>
                        <el-checkbox
                          label="own_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.own_affiliates') }}
                        </el-checkbox>
                      </div>
                      <div class="permission-column">
                        <el-checkbox
                          label="other_teams_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.other_teams_affiliates') }}
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                    <permission-tree
                      :disabled="MediaBuyingPaymentsReviewColumnsDisabled"
                      :users-list="usersList"
                      :affiliates-types="adminPermissions.media_buying_payments_affiliates_types_review"
                      :teammates-ids.sync="adminPermissions.media_buying_payments_review_affiliates_visibility_teammates_id"
                    />
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.media_buying.payments_moderation') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <div class="field field-with-field">
                    <div class="field">
                      <span class="label">{{ $t('permissions.media_buying.statuses') }}</span>
                      <div class="values ">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('media_buying_payments_moderation_statuses_visible')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.media_buying_payments_moderation_statuses_visible"
                              :disabled="inheritIsDisabled('media_buying_payments_moderation_statuses_visible')"
                              :class="['checkbox']"
                              @change="refreshInheritViewPaymentsModeration(inherits.media_buying_payments_moderation_statuses_visible)"
                            />
                          </div>
                        </el-tooltip>
                        <div class="access-status" />
                      </div>
                    </div>
                    <el-checkbox-group
                      v-model="adminPermissions.media_buying_payments_moderation_statuses_visible"
                      :disabled="MediaBuyingPaymentsModerationColumnsDisabled"
                    >
                      <div class="permission-column">
                        <el-checkbox
                          label="on_review"
                          class="checkbox"
                        >
                          {{ $t('permissions.on_review') }}
                        </el-checkbox>
                        <el-checkbox
                          label="for_rework"
                          class="checkbox"
                        >
                          {{ $t('permissions.for_rework') }}
                        </el-checkbox>
                      </div>
                      <div class="permission-column">
                        <el-checkbox
                          label="approved"
                          class="checkbox"
                        >
                          {{ $t('permissions.approved') }}
                        </el-checkbox>
                        <el-checkbox
                          label="declined"
                          class="checkbox"
                        >
                          {{ $t('permissions.declined') }}
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.media_buying.payments_payout') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <div class="field field-with-field">
                    <div class="field">
                      <span class="label">{{ $t('permissions.media_buying.statuses') }}</span>
                      <div class="values ">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('media_buying_payments_payout_statuses_visible')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.media_buying_payments_payout_statuses_visible"
                              :disabled="inheritIsDisabled('media_buying_payments_payout_statuses_visible')"
                              :class="['checkbox']"
                              @change="refreshInheritViewPaymentsPayout(inherits.media_buying_payments_payout_statuses_visible)"
                            />
                          </div>
                        </el-tooltip>
                        <div class="access-status" />
                      </div>
                    </div>
                    <el-checkbox-group
                      v-model="adminPermissions.media_buying_payments_payout_statuses_visible"
                      :disabled="MediaBuyingPaymentsPayoutColumnsDisabled"
                    >
                      <div class="permission-column">
                        <el-checkbox
                          label="not_paid"
                          class="checkbox"
                        >
                          {{ $t('permissions.not_paid') }}
                        </el-checkbox>
                        <el-checkbox
                          label="for_rework"
                          class="checkbox"
                        >
                          {{ $t('permissions.for_rework') }}
                        </el-checkbox>
                        <el-checkbox
                          label="processing"
                          class="checkbox"
                        >
                          {{ $t('permissions.processing') }}
                        </el-checkbox>
                      </div>
                      <div class="permission-column">
                        <el-checkbox
                          label="paid"
                          class="checkbox"
                        >
                          {{ $t('permissions.paid') }}
                        </el-checkbox>
                        <el-checkbox
                          label="failed"
                          class="checkbox"
                        >
                          {{ $t('permissions.failed') }}
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.media_buying.posts') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('media_buying_posts_view')" />
                  <div class="field field-with-field">
                    <div class="field">
                      <span class="label">{{ $t('permissions.list.media_buying_posts_affiliates_types_view') }}</span>
                      <div class="values ">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('media_buying_posts_affiliates_types_view')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.media_buying_posts_affiliates_types_view"
                              :disabled="inheritIsDisabled('media_buying_posts_affiliates_types_view')"
                              :class="['checkbox']"
                              @change="refreshInheritViewPosts(inherits.media_buying_posts_affiliates_types_view)"
                            />
                          </div>
                        </el-tooltip>
                        <div class="access-status" />
                      </div>
                    </div>
                    <el-checkbox-group
                      v-model="adminPermissions.media_buying_posts_affiliates_types_view"
                      :disabled="MediaBuyingPostsViewColumnsDisabled"
                    >
                      <div class="permission-column">
                        <el-checkbox
                          label="independent"
                          class="checkbox"
                        >
                          {{ $t('permissions.independent') }}
                        </el-checkbox>
                        <el-checkbox
                          label="own_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.own_affiliates') }}
                        </el-checkbox>
                      </div>
                      <div class="permission-column">
                        <el-checkbox
                          label="other_teams_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.other_teams_affiliates') }}
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                    <permission-tree
                      :disabled="MediaBuyingPostsViewColumnsDisabled"
                      :users-list="usersList"
                      :affiliates-types="adminPermissions.media_buying_posts_affiliates_types_view"
                      :teammates-ids.sync="adminPermissions.media_buying_posts_view_affiliates_visibility_teammates_id"
                    />
                  </div>

                  <vnodes :vnodes="renderPermissionSwitchControl('media_buying_posts_review')" />

                  <div class="field field-with-field">
                    <div class="field">
                      <span class="label">{{ $t('permissions.list.media_buying_posts_affiliates_types_review') }}</span>
                      <div class="values ">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('media_buying_posts_affiliates_types_review')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.media_buying_posts_affiliates_types_review"
                              :disabled="inheritIsDisabled('media_buying_posts_affiliates_types_review')"
                              :class="['checkbox']"
                              @change="refreshInheritReviewPosts(inherits.media_buying_posts_affiliates_types_review)"
                            />
                          </div>
                        </el-tooltip>
                        <div class="access-status" />
                      </div>
                    </div>
                    <el-checkbox-group
                      v-model="adminPermissions.media_buying_posts_affiliates_types_review"
                      :disabled="MediaBuyingPostsReviewColumnsDisabled"
                    >
                      <div class="permission-column">
                        <el-checkbox
                          label="independent"
                          class="checkbox"
                        >
                          {{ $t('permissions.independent') }}
                        </el-checkbox>
                        <el-checkbox
                          label="own_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.own_affiliates') }}
                        </el-checkbox>
                      </div>
                      <div class="permission-column">
                        <el-checkbox
                          label="other_teams_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.other_teams_affiliates') }}
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                    <permission-tree
                      :disabled="MediaBuyingPostsReviewColumnsDisabled"
                      :users-list="usersList"
                      :affiliates-types="adminPermissions.media_buying_posts_affiliates_types_review"
                      :teammates-ids.sync="adminPermissions.media_buying_posts_review_affiliates_visibility_teammates_id"
                    />
                  </div>
                </div>
                <div class="card-content">
                  <div class="field field-with-field">
                    <div class="field">
                      <span class="label">{{ $t('permissions.media_buying.statuses') }}</span>
                      <div class="values ">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('media_buying_posts_statuses_visible')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.media_buying_posts_statuses_visible"
                              :disabled="inheritIsDisabled('media_buying_posts_statuses_visible')"
                              :class="['checkbox']"
                              @change="refreshInheritPostStatuses(inherits.media_buying_posts_statuses_visible)"
                            />
                          </div>
                        </el-tooltip>
                        <div class="access-status" />
                      </div>
                    </div>
                    <el-checkbox-group
                      v-model="adminPermissions.media_buying_posts_statuses_visible"
                      :disabled="MediaBuyingPostsStatusesColumnsDisabled"
                    >
                      <div class="permission-column">
                        <el-checkbox
                          label="on_review"
                          class="checkbox"
                        >
                          {{ $t('permissions.on_review') }}
                        </el-checkbox>
                        <el-checkbox
                          label="for_rework"
                          class="checkbox"
                        >
                          {{ $t('permissions.for_rework') }}
                        </el-checkbox>
                      </div>
                      <div class="permission-column">
                        <el-checkbox
                          label="approved"
                          class="checkbox"
                        >
                          {{ $t('permissions.approved') }}
                        </el-checkbox>
                        <el-checkbox
                          label="declined"
                          class="checkbox"
                        >
                          {{ $t('permissions.declined') }}
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.media_buying.reports') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('media_buying_reports_view')" />
                  <div class="field field-with-field">
                    <div class="field">
                      <span class="label">{{ $t('permissions.list.media_buying_reports_affiliates_types_view') }}</span>
                      <div class="values ">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('media_buying_reports_affiliates_types_view')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.media_buying_reports_affiliates_types_view"
                              :disabled="inheritIsDisabled('media_buying_reports_affiliates_types_view')"
                              :class="['checkbox']"
                              @change="refreshInheritViewMediaReports(inherits.media_buying_reports_affiliates_types_view)"
                            />
                          </div>
                        </el-tooltip>
                        <div class="access-status" />
                      </div>
                    </div>
                    <el-checkbox-group
                      v-model="adminPermissions.media_buying_reports_affiliates_types_view"
                      :disabled="MediaBuyingReportsViewColumnsDisabled"
                    >
                      <div class="permission-column">
                        <el-checkbox
                          label="independent"
                          class="checkbox"
                        >
                          {{ $t('permissions.independent') }}
                        </el-checkbox>
                        <el-checkbox
                          label="own_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.own_affiliates') }}
                        </el-checkbox>
                      </div>
                      <div class="permission-column">
                        <el-checkbox
                          label="other_teams_affiliates"
                          class="checkbox"
                        >
                          {{ $t('permissions.other_teams_affiliates') }}
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                    <permission-tree
                      :disabled="MediaBuyingReportsViewColumnsDisabled"
                      :users-list="usersList"
                      :affiliates-types="adminPermissions.media_buying_reports_affiliates_types_view"
                      :teammates-ids.sync="adminPermissions.media_buying_reports_view_affiliates_visibility_teammates_id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item
            :title="$t('permissions.crm.title')"
            name="crm"
          >
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.crm.segments') }}</span>
                  <span class="radio-block radio-block_allow">
                    {{ $t('permissions.inherit') }}
                  </span>
                </div>
                <div class="card-content">
                  <div class="field">
                    <span class="label">{{ $t(`permissions.crm.segments_view`) }} </span>
                    <div class="values ">
                      <div class="inherit-check-wrapper">
                        <el-checkbox
                          v-model="inherits.crm_segments_view"
                          :class="['checkbox']"
                          @change="refreshInheritSegmentsView(inherits.crm_segments_view)"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <el-radio-group
                      v-model="adminPermissions.crm_segments_view"
                      :disabled="SegmentsViewTypeDisabled"
                      @change="checkSegmentPermission(adminPermissions.crm_segments_view, 'view')"
                    >
                      <div class="permission-column">
                        <el-radio
                          label="all"
                          class="checkbox"
                        >
                          {{ $t(`permissions.all`) }}
                        </el-radio>
                        <el-radio
                          label="teammates"
                          class="checkbox"
                        >
                          {{ $t(`permissions.teammates`) }}
                        </el-radio>
                      </div>
                      <div class="permission-column">
                        <el-radio
                          label="own"
                          class="checkbox"
                        >
                          {{ $t(`permissions.own`) }}
                        </el-radio>
                        <el-radio
                          label="deny"
                          class="checkbox"
                        >
                          {{ $t(`permissions.deny`) }}
                        </el-radio>
                      </div>
                    </el-radio-group>
                  </div>
                </div>
                <div class="card-content">
                  <div class="field">
                    <span class="label">{{ $t(`permissions.crm.segments_edit`) }} </span>
                    <div class="values ">
                      <div class="inherit-check-wrapper">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('crm_segments_edit')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.crm_segments_edit"
                              :disabled="SegmentsEditDisabled"
                              :class="['checkbox']"
                              @change="refreshInheritSegmentsEdit(inherits.crm_segments_edit)"
                            />
                          </div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <el-radio-group
                      v-model="adminPermissions.crm_segments_edit"
                      :disabled="SegmentsEditTypeDisabled"
                      @change="checkSegmentPermission(adminPermissions.crm_segments_edit, 'edit')"
                    >
                      <div class="permission-column">
                        <el-radio
                          label="all"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_segments_view', 'all')"
                        >
                          {{ $t(`permissions.all`) }}
                        </el-radio>
                        <el-radio
                          label="teammates"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_segments_view', 'teammates')"
                        >
                          {{ $t(`permissions.teammates`) }}
                        </el-radio>
                      </div>
                      <div class="permission-column">
                        <el-radio
                          label="own"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_segments_view', 'own')"
                        >
                          {{ $t(`permissions.own`) }}
                        </el-radio>
                        <el-radio
                          label="deny"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_segments_view', 'deny')"
                        >
                          {{ $t(`permissions.deny`) }}
                        </el-radio>
                      </div>
                    </el-radio-group>
                  </div>
                </div>
                <div class="card-content">
                  <div class="field">
                    <span class="label">{{ $t(`permissions.crm.segments_review`) }} </span>
                    <div class="values ">
                      <div class="inherit-check-wrapper">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('crm_segments_review')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.crm_segments_review"
                              :disabled="SegmentsReviewDisabled"
                              :class="['checkbox']"
                              @change="refreshInheritSegmentsReview(inherits.crm_segments_review)"
                            />
                          </div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <el-radio-group
                      v-model="adminPermissions.crm_segments_review"
                      :disabled="SegmentsReviewTypeDisabled"
                      @change="checkSegmentPermission(adminPermissions.crm_segments_review, 'review')"
                    >
                      <div class="permission-column">
                        <el-radio
                          label="all"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_segments_edit', 'all')"
                        >
                          {{ $t(`permissions.all`) }}
                        </el-radio>
                        <el-radio
                          label="teammates"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_segments_edit', 'teammates')"
                        >
                          {{ $t(`permissions.teammates`) }}
                        </el-radio>
                      </div>
                      <div class="permission-column">
                        <el-radio
                          label="own"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_segments_edit', 'own')"
                        >
                          {{ $t(`permissions.own`) }}
                        </el-radio>
                        <el-radio
                          label="deny"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_segments_edit', 'deny')"
                        >
                          {{ $t(`permissions.deny`) }}
                        </el-radio>
                      </div>
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.crm.activities') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <div class="field">
                    <span class="label">{{ $t(`permissions.crm.activities_view`) }} </span>
                    <div class="values ">
                      <div class="inherit-check-wrapper">
                        <el-checkbox
                          v-model="inherits.crm_activities_view"
                          :class="['checkbox']"
                          @change="refreshInheritActivitiesView(inherits.crm_activities_view)"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <el-radio-group
                      v-model="adminPermissions.crm_activities_view"
                      :disabled="ActivitiesViewTypeDisabled"
                      @change="checkActivitiesPermission(adminPermissions.crm_activities_view, 'view')"
                    >
                      <div class="permission-column">
                        <el-radio
                          label="all"
                          class="checkbox"
                        >
                          {{ $t(`permissions.all`) }}
                        </el-radio>
                        <el-radio
                          label="teammates"
                          class="checkbox"
                        >
                          {{ $t(`permissions.teammates`) }}
                        </el-radio>
                      </div>
                      <div class="permission-column">
                        <el-radio
                          label="own"
                          class="checkbox"
                        >
                          {{ $t(`permissions.own`) }}
                        </el-radio>
                        <el-radio
                          label="deny"
                          class="checkbox"
                        >
                          {{ $t(`permissions.deny`) }}
                        </el-radio>
                      </div>
                    </el-radio-group>
                  </div>
                </div>
                <div class="card-content">
                  <div class="field">
                    <span class="label">{{ $t(`permissions.crm.activities_edit`) }} </span>
                    <div class="values ">
                      <div class="inherit-check-wrapper">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('crm_activities_edit')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.crm_activities_edit"
                              :disabled="ActivitiesEditDisabled"
                              :class="['checkbox']"
                              @change="refreshInheritActivitiesEdit(inherits.crm_activities_edit)"
                            />
                          </div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <el-radio-group
                      v-model="adminPermissions.crm_activities_edit"
                      :disabled="ActivitiesEditTypeDisabled"
                    >
                      <div class="permission-column">
                        <el-radio
                          label="all"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_activities_view', 'all')"
                        >
                          {{ $t(`permissions.all`) }}
                        </el-radio>
                        <el-radio
                          label="teammates"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_activities_view', 'teammates')"
                        >
                          {{ $t(`permissions.teammates`) }}
                        </el-radio>
                      </div>
                      <div class="permission-column">
                        <el-radio
                          label="own"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_activities_view', 'own')"
                        >
                          {{ $t(`permissions.own`) }}
                        </el-radio>
                        <el-radio
                          label="deny"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_activities_view', 'deny')"
                        >
                          {{ $t(`permissions.deny`) }}
                        </el-radio>
                      </div>
                    </el-radio-group>
                  </div>
                </div>
                <div class="card-content">
                  <div class="field">
                    <span class="label">{{ $t(`permissions.crm.activities_review`) }} </span>
                    <div class="values ">
                      <div class="inherit-check-wrapper">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('crm_activities_review')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.crm_activities_review"
                              :disabled="ActivitiesReviewDisabled"
                              :class="['checkbox']"
                              @change="refreshInheritActivitiesReview(inherits.crm_activities_review)"
                            />
                          </div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <el-radio-group
                      v-model="adminPermissions.crm_activities_review"
                      :disabled="ActivitiesReviewTypeDisabled"
                    >
                      <div class="permission-column">
                        <el-radio
                          label="all"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_activities_view', 'all')"
                        >
                          {{ $t(`permissions.all`) }}
                        </el-radio>
                        <el-radio
                          label="teammates"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_activities_view', 'teammates')"
                        >
                          {{ $t(`permissions.teammates`) }}
                        </el-radio>
                      </div>
                      <div class="permission-column">
                        <el-radio
                          label="own"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_activities_view', 'own')"
                        >
                          {{ $t(`permissions.own`) }}
                        </el-radio>
                        <el-radio
                          label="deny"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_activities_view', 'deny')"
                        >
                          {{ $t(`permissions.deny`) }}
                        </el-radio>
                      </div>
                    </el-radio-group>
                  </div>
                </div>
                <div class="card-content">
                  <div class="field">
                    <span class="label">{{ $t(`permissions.crm.activities_run`) }} </span>
                    <div class="values ">
                      <div class="inherit-check-wrapper">
                        <el-tooltip placement="top">
                          <div slot="content">
                            <h3 class="depends-on-header">
                              {{ $t('permissions.depends') }}
                            </h3>
                            <li
                              v-for="dependency in getPermissionDepends('crm_activities_run')"
                              :key="dependency"
                              :class="{'dependency-item': true, selected: (adminPermissions[dependency] === 'allow' && inherits[dependency] === false),}"
                            >
                              {{ $t(`permissions.list.${dependency}`) }}
                            </li>
                          </div>
                          <div class="inherit-check-wrapper">
                            <el-checkbox
                              v-model="inherits.crm_activities_run"
                              :disabled="ActivitiesRunDisabled"
                              :class="['checkbox']"
                              @change="refreshInheritActivitiesRun(inherits.crm_activities_run)"
                            />
                          </div>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <el-radio-group
                      v-model="adminPermissions.crm_activities_run"
                      :disabled="ActivitiesRunTypeDisabled"
                    >
                      <div class="permission-column">
                        <el-radio
                          label="all"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_activities_view', 'all')"
                        >
                          {{ $t(`permissions.all`) }}
                        </el-radio>
                        <el-radio
                          label="teammates"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_activities_view', 'teammates')"
                        >
                          {{ $t(`permissions.teammates`) }}
                        </el-radio>
                      </div>
                      <div class="permission-column">
                        <el-radio
                          label="own"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_activities_view', 'own')"
                        >
                          {{ $t(`permissions.own`) }}
                        </el-radio>
                        <el-radio
                          label="deny"
                          class="checkbox"
                          :disabled="isDisabledOption('crm_activities_view', 'deny')"
                        >
                          {{ $t(`permissions.deny`) }}
                        </el-radio>
                      </div>
                    </el-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item
            :title="$t('permissions.others.title')"
            name="others"
          >
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.tools.tools_promo_codes_checker') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('tools_promo_codes_checker_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('tools_promo_landings_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('tools_promo_landings_edit')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('marketing_materials_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('marketing_materials_edit')" />
                </div>
              </div>
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.live.live_card_title') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.status') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <vnodes :vnodes="renderPermissionSwitchControl('last_bets_and_deposits_view')" />

                  <vnodes :vnodes="renderPermissionSwitchControl('top_events_view')" />
                </div>
              </div>
            </div>
            <div class="permission-column">
              <div class="card">
                <div class="title">
                  <span>{{ $t('permissions.geo.geo_card_title') }}</span>
                  <span>
                    <span class="radio-block radio-block_allow">
                      {{ $t('permissions.inherit') }}
                    </span>
                  </span>
                </div>
                <div class="card-content">
                  <div class="field">
                    <span class="label">{{ $t('permissions.geo.geo_statistics') }}</span>
                    <div class="values ">
                      <div class="inherit-check-wrapper">
                        <el-checkbox
                          v-model="inherits.countries_allowed_mode"
                          :class="['checkbox']"
                          @change="refreshInheritGeo(inherits.countries_allowed_mode)"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <el-radio-group
                      v-model="adminPermissions.countries_allowed_mode"
                      :disabled="GeoViewDisabled"
                      @change="checkGeoPermission(adminPermissions.countries_allowed_mode)"
                    >
                      <div class="permission-column">
                        <el-radio
                          label="allow_all"
                          class="checkbox"
                        >
                          {{ $t('permissions.allow_all') }}
                        </el-radio>
                        <el-radio
                          label="deny_all"
                          class="checkbox"
                        >
                          {{ $t('permissions.deny_all') }}
                        </el-radio>
                      </div>
                      <div class="permission-column">
                        <el-radio
                          label="allow_selected"
                          class="checkbox"
                        >
                          {{ $t('permissions.allow_selected') }}
                        </el-radio>
                        <el-radio
                          label="deny_selected"
                          class="checkbox"
                        >
                          {{ $t('permissions.deny_selected') }}
                        </el-radio>
                      </div>
                    </el-radio-group>
                    <ui-tags-input
                      v-model="adminPermissions.countries_allowed_list"
                      :disabled="adminPermissions.countries_allowed_mode === 'allow_all' || adminPermissions.countries_allowed_mode === 'deny_all' || GeoViewDisabled"
                      :typeahead-activation-threshold="0"
                      :only-existing-tags="true"
                      :existing-tags="countryList"
                      :typeahead="true"
                      :placeholder="$t('permissions.add_country')"
                      :class="errors.countries_allowed_list ? 'error-tags' : ''"
                      class="card-filter__input-tag card-filter__input-tag--z"
                      typeahead-style="dropdown"
                    />
                    <p
                      v-if="errors.countries_allowed_list"
                      class="input-error"
                    >
                      {{ errors.countries_allowed_list[0].message }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          @click.native="close"
        >
          {{ $t('team_popup.cancel_button') }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          filled
          @click="save"
        >
          <i class="fas fa-check plus-icon-custom" />{{ $t('team_popup.save_button') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
import { setTimeout } from 'timers';
import { mapGetters } from 'vuex';
import { dependsOnConfig, requiresForConfig } from '@/configs/permissions';
import {
  getInheritsObject, getInheritsSnapshot, getPermissionsObject, getPermissionsSnapshot, sortInheritsSnapshot, sortPermissionsSnapshot,
} from '@/helpers/permissions';
import PermissionTree from '@/components/PermissionTree';

export default {
  name: 'UserPermissions',
  components: {
    PermissionTree,
    Vnodes: {
      functional: true,
      render: (h, ctx) => (ctx.props.vnodes ? ctx.props.vnodes : ''),
    },
  },
  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    isOpen: {
      type: Boolean,
      required: true,
    },

    id: {
      type: String,
      default() {
        return '';
      },
    },
  },

  data() {
    return {
      activePermissions: ['reports'],
      isLoading: true,
      currentTeamSelect: '',
      value: true,
      activeColor: this.$theme.accentColor,
      inactiveColor: this.$theme.dangerColor,

      users: null,

      crmWeight: {
        all: 3,
        teammates: 2,
        own: 1,
        deny: 0,
      },
      crmAllowOptions: {
        all: ['all', 'teammates', 'own', 'deny'],
        teammates: ['teammates', 'own', 'deny'],
        own: ['own', 'deny'],
        deny: ['deny'],
      },

      currencyOptions: [],

      teamAcl: {},
      postPermissions: {},
      errors: {},
      permissionSnapshots: {},
      inheritsSnapshots: {},
      savedGeoValues: {},

      adminPermissions: {
        last_bets_and_deposits_view: 'deny',
        top_events_view: 'deny',
        reports_view: 'deny',
        reports_group_by_affiliates: 'deny',
        reports_group_by_countries: 'deny',
        reports_group_by_sub_affiliates: 'deny',
        reports_group_by_payments: 'deny',
        reports_view_revenue_columns: 'deny',
        reports_view_profit_columns: 'deny',
        reports_view_deposits_sum_columns: 'deny',
        reports_view_deposits_count_columns: 'deny',
        reports_view_withdrawals_sum_columns: 'deny',
        reports_view_withdrawals_count_columns: 'deny',
        reports_view_sport_bets_sum_columns: 'deny',
        reports_view_sport_bets_count_columns: 'deny',
        reports_view_casino_bets_sum_columns: 'deny',
        reports_view_casino_bets_count_columns: 'deny',
        affiliates_view: 'deny',
        affiliates_edit: 'deny',
        affiliates_rotation_groups_edit: 'deny',
        affiliates_sub_affiliates_edit_settings: 'deny',
        offers_edit: 'deny',
        offers_revshare_edit_settings: 'deny',
        offers_cpa_edit_settings: 'deny',
        payments_view: 'deny',
        payments_review_actions: 'deny',
        payments_chargebacks_actions: 'deny',
        players_view: 'deny',
        players_virtual_edit_settings: 'deny',
        players_assign_affiliate: 'deny',
        tools_promo_codes_checker_view: 'deny',
        tools_promo_landings_view: 'deny',
        tools_promo_landings_edit: 'deny',
        marketing_materials_view: 'deny',
        marketing_materials_edit: 'deny',

        media_buying_payments_view: 'deny',
        media_buying_payments_affiliates_types_view: [],
        media_buying_payments_review: 'deny',
        media_buying_payments_affiliates_types_review: [],
        media_buying_payments_moderation_statuses_visible: [],
        media_buying_payments_payout_statuses_visible: [],
        media_buying_posts_view: 'deny',
        media_buying_posts_affiliates_types_view: [],
        media_buying_posts_review: 'deny',
        media_buying_posts_affiliates_types_review: [],
        media_buying_posts_statuses_visible: [],
        media_buying_reports_view: 'deny',
        media_buying_reports_affiliates_types_view: [],

        media_buying_posts_view_affiliates_visibility_teammates_id: [],
        media_buying_posts_review_affiliates_visibility_teammates_id: [],
        media_buying_payments_view_affiliates_visibility_teammates_id: [],
        media_buying_payments_review_affiliates_visibility_teammates_id: [],
        media_buying_reports_view_affiliates_visibility_teammates_id: [],

        countries_allowed_mode: 'allow_all',
        countries_allowed_list: [],

        reports_affiliates_types: [],
        reports_view_affiliates_visibility_teammates_id: [],

        affiliates_types_view: [],
        affiliates_view_affiliates_visibility_teammates_id: [],

        affiliates_types_edit: [],
        affiliates_edit_affiliates_visibility_teammates_id: [],

        players_available_properties: [],
        affiliates_sub_affiliates_max_percent: 0,
        offers_revshare_max_percent_sport: 0,
        offers_revshare_max_percent_casino: 0,
        offers_cpa_max_payout: 0,
        offers_cpa_max_payout_currency_code: null,
        offers_cpa_min_payout_deposits_sum_ratio: 0,
        dns_hosting_accounts_view: 'deny',
        dns_hosting_accounts_edit: 'deny',
        domain_registrar_accounts_view: 'deny',
        domain_registrar_accounts_edit: 'deny',
        platform_servers_view: 'deny',
        platform_servers_edit: 'deny',
        platform_servers_can_run_jobs: 'deny',
        platform_service_domains_view: 'deny',
        platform_service_domains_edit: 'deny',
        platform_service_domains_can_run_jobs: 'deny',
        platform_betting_domains_view: 'deny',
        platform_betting_domains_edit: 'deny',
        service_rotation_groups_view: 'deny',
        service_rotation_groups_edit: 'deny',
        service_rotation_groups_domains_view: 'deny',
        service_rotation_groups_domains_edit: 'deny',

        affiliates_activity_log_view: 'deny',
        affiliates_notes_view: 'deny',
        affiliates_notes_edit: 'deny',

        affiliates_can_login: 'deny',
        affiliates_password_edit: 'deny',
        affiliates_payment_info_edit: 'deny',
        affiliates_domain_change_notifications_edit: 'deny',
        affiliates_can_approve: 'deny',
        affiliates_can_decline: 'deny',
        affiliates_can_block: 'deny',
        affiliates_can_unblock: 'deny',
        affiliates_can_revoke: 'deny',
        affiliates_reporting_settings_rs_profit_visibility_edit: 'deny',
        affiliates_reporting_settings_cpa_profit_visibility_edit: 'deny',
        affiliates_email_edit: 'deny',
        affiliates_payments_can_create: 'deny',
        affiliates_2fa_can_reset: 'Reset 2FA',
        affiliates_media_buying_edit: 'deny',
        affiliates_promo_codes_edit: 'deny',
        affiliates_balance_edit: 'deny',
        affiliates_limits_edit: 'deny',

        crm_segments_view: 'deny',
        crm_segments_review: 'deny',
        crm_segments_edit: 'deny',
        crm_activities_view: 'deny',
        crm_activities_review: 'deny',
        crm_activities_edit: 'deny',
        crm_activities_run: 'deny',

        affiliates_notifications_view: 'deny',
        affiliates_notifications_edit: 'deny',
      },
      inherits: {
        last_bets_and_deposits_view: true,
        top_events_view: true,
        reports_view: true,
        reports_group_by_affiliates: true,
        reports_group_by_countries: true,
        reports_group_by_sub_affiliates: true,
        reports_group_by_payments: true,
        reports_view_revenue_columns: true,
        reports_view_profit_columns: true,
        reports_view_deposits_sum_columns: true,
        reports_view_deposits_count_columns: true,
        reports_view_withdrawals_sum_columns: true,
        reports_view_withdrawals_count_columns: true,
        reports_view_sport_bets_sum_columns: true,
        reports_view_sport_bets_count_columns: true,
        reports_view_casino_bets_sum_columns: true,
        reports_view_casino_bets_count_columns: true,
        affiliates_view: true,
        affiliates_edit: true,
        affiliates_rotation_groups_edit: true,
        affiliates_sub_affiliates_edit_settings: true,
        offers_edit: true,
        offers_revshare_edit_settings: true,
        offers_cpa_edit_settings: true,
        payments_view: true,
        payments_review_actions: true,
        payments_chargebacks_actions: true,
        players_view: true,
        players_virtual_edit_settings: true,
        players_assign_affiliate: true,
        tools_promo_codes_checker_view: true,
        tools_promo_landings_view: true,
        tools_promo_landings_edit: true,
        marketing_materials_view: true,
        marketing_materials_edit: true,
        reports_affiliates_types: true,
        reports_view_affiliates_visibility_teammates_id: true,

        affiliates_view_affiliates_visibility_teammates_id: true,
        affiliates_edit_affiliates_visibility_teammates_id: true,
        media_buying_posts_view_affiliates_visibility_teammates_id: true,
        media_buying_posts_review_affiliates_visibility_teammates_id: true,
        media_buying_payments_view_affiliates_visibility_teammates_id: true,
        media_buying_payments_review_affiliates_visibility_teammates_id: true,
        media_buying_reports_view_affiliates_visibility_teammates_id: true,

        affiliates_types_view: true,
        affiliates_types_edit: true,
        countries_allowed_mode: true,
        countries_allowed_list: true,

        media_buying_payments_view: true,
        media_buying_payments_affiliates_types_view: true,
        media_buying_payments_review: true,
        media_buying_payments_affiliates_types_review: true,
        media_buying_payments_moderation_statuses_visible: true,
        media_buying_payments_payout_statuses_visible: true,
        media_buying_posts_view: true,
        media_buying_posts_affiliates_types_view: true,
        media_buying_posts_review: true,
        media_buying_posts_affiliates_types_review: true,
        media_buying_posts_statuses_visible: true,
        media_buying_reports_view: true,
        media_buying_reports_affiliates_types_view: true,

        players_available_properties: [],
        affiliates_sub_affiliates_max_percent: 0,
        offers_revshare_max_percent_sport: 0,
        offers_revshare_max_percent_casino: 0,
        offers_cpa_max_payout: 0,
        offers_cpa_max_payout_currency_code: null,
        offers_cpa_min_payout_deposits_sum_ratio: 0,

        dns_hosting_accounts_view: true,
        dns_hosting_accounts_edit: true,
        domain_registrar_accounts_view: true,
        domain_registrar_accounts_edit: true,
        platform_servers_view: true,
        platform_servers_edit: true,
        platform_servers_can_run_jobs: true,
        platform_service_domains_view: true,
        platform_service_domains_edit: true,
        platform_service_domains_can_run_jobs: true,
        platform_betting_domains_view: true,
        platform_betting_domains_edit: true,
        service_rotation_groups_view: true,
        service_rotation_groups_edit: true,
        service_rotation_groups_domains_view: true,
        service_rotation_groups_domains_edit: true,

        affiliates_activity_log_view: true,
        affiliates_notes_view: true,
        affiliates_notes_edit: true,

        affiliates_can_login: true,
        affiliates_password_edit: true,
        affiliates_payment_info_edit: true,
        affiliates_domain_change_notifications_edit: true,
        affiliates_can_approve: true,
        affiliates_can_decline: true,
        affiliates_can_block: true,
        affiliates_can_unblock: true,
        affiliates_can_revoke: true,
        affiliates_reporting_settings_rs_profit_visibility_edit: true,
        affiliates_reporting_settings_cpa_profit_visibility_edit: true,
        affiliates_email_edit: true,
        affiliates_payments_can_create: true,
        affiliates_2fa_can_reset: true,
        affiliates_media_buying_edit: true,
        affiliates_promo_codes_edit: true,
        affiliates_balance_edit: true,
        affiliates_limits_edit: true,

        crm_segments_view: true,
        crm_segments_review: true,
        crm_segments_edit: true,
        crm_activities_view: true,
        crm_activities_review: true,
        crm_activities_edit: true,
        crm_activities_run: true,

        affiliates_notifications_view: true,
        affiliates_notifications_edit: true,
      },
    };
  },

  computed: {
    ...mapGetters({
      countries: 'misc/countries',
    }),

    usersList: {
      get() {
        if (this.users === null) {
          return null;
        }
        const users = this.$_.cloneDeep(this.users);
        const usersList = {};
        users.forEach((e) => {
          usersList[e.id] = e.email;
        });
        return usersList || '';
      },
    },

    countryList: {
      get() {
        const countries = this.$_.clone(this.countries);
        const countryList = {};
        countries.forEach((e) => {
          countryList[e.code] = e.name;
        });
        return countryList || '';
      },
    },

    teamId: {
      get() {
        return this.currentTeamSelect ? this.currentTeamSelect : '';
      },
      set(newVal) {
        this.currentTeamSelect = newVal;
      },
    },

    userTeams: {
      get() {
        return this.teamAcl ? this.teamAcl.teams : {};
      },
    },

    computedAcl: {
      get() {
        return this.teamAcl && this.teamAcl.permissions ? this.$_.cloneDeep(this.teamAcl.permissions[this.teamId]) : {};
      },
    },

    reportsDisabled: {
      get() {
        return this.inherits.reports_view === false && this.adminPermissions.reports_view === 'deny';
      },
    },

    reportsDisabledColumns: {
      get() {
        return this.reportsDisabled || this.inherits.reports_affiliates_types === true;
      },
    },

    MediaBuyingPaymentsViewDisabled: {
      get() {
        return this.inherits.media_buying_payments_view === false && this.adminPermissions.media_buying_payments_view === 'deny';
      },
    },

    MediaBuyingPaymentsViewColumnsDisabled: {
      get() {
        return this.MediaBuyingPaymentsViewDisabled || this.inherits.media_buying_payments_affiliates_types_view === true;
      },
    },

    MediaBuyingPaymentsReviewDisabled: {
      get() {
        return this.MediaBuyingPaymentsViewDisabled || (this.inherits.media_buying_payments_review === false && this.adminPermissions.media_buying_payments_review === 'deny');
      },
    },

    MediaBuyingPaymentsReviewColumnsDisabled: {
      get() {
        return this.MediaBuyingPaymentsReviewDisabled || this.inherits.media_buying_payments_affiliates_types_review === true;
      },
    },

    MediaBuyingPaymentsModerationColumnsDisabled: {
      get() {
        return this.MediaBuyingPaymentsViewDisabled || this.inherits.media_buying_payments_moderation_statuses_visible === true;
      },
    },

    MediaBuyingPaymentsPayoutColumnsDisabled: {
      get() {
        return this.MediaBuyingPaymentsViewDisabled || this.inherits.media_buying_payments_payout_statuses_visible === true;
      },
    },

    MediaBuyingPostsViewDisabled: {
      get() {
        return this.inherits.media_buying_posts_view === false && this.adminPermissions.media_buying_posts_view === 'deny';
      },
    },

    MediaBuyingPostsViewColumnsDisabled: {
      get() {
        return this.MediaBuyingPostsViewDisabled || this.inherits.media_buying_posts_affiliates_types_view === true;
      },
    },

    MediaBuyingReportsViewDisabled: {
      get() {
        return this.inherits.media_buying_reports_view === false && this.adminPermissions.media_buying_reports_view === 'deny';
      },
    },
    MediaBuyingReportsViewColumnsDisabled: {
      get() {
        return this.MediaBuyingReportsViewDisabled || this.inherits.media_buying_reports_affiliates_types_view === true;
      },
    },

    MediaBuyingPostsReviewDisabled: {
      get() {
        return this.MediaBuyingPostsViewDisabled || (this.inherits.media_buying_posts_review === false && this.adminPermissions.media_buying_posts_review === 'deny');
      },
    },

    MediaBuyingPostsReviewColumnsDisabled: {
      get() {
        return this.MediaBuyingPostsReviewDisabled || this.inherits.media_buying_posts_affiliates_types_review === true;
      },
    },

    MediaBuyingPostsStatusesColumnsDisabled: {
      get() {
        return this.MediaBuyingPostsViewDisabled || this.inherits.media_buying_posts_statuses_visible === true;
      },
    },

    AffiliatesViewColumnsDisabled: {
      get() {
        return this.AffiliatesViewDisabled || this.inherits.affiliates_types_view === true;
      },
    },

    AffiliatesViewDisabled: {
      get() {
        return this.inherits.affiliates_view === false && this.adminPermissions.affiliates_view === 'deny';
      },
    },

    AffiliatesEditDisabled: {
      get() {
        return this.AffiliatesViewDisabled || (this.inherits.affiliates_edit === false && this.adminPermissions.affiliates_edit === 'deny');
      },
    },

    AffiliatesEditColumnsDisabled: {
      get() {
        return this.AffiliatesEditDisabled || this.inherits.affiliates_types_edit === true;
      },
    },

    SubAffiliatesEditDisabled: {
      get() {
        return this.AffiliatesEditDisabled || (this.inherits.affiliates_sub_affiliates_edit_settings === false
            && this.adminPermissions.affiliates_sub_affiliates_edit_settings === 'deny');
      },
    },

    SubAffiliatesValueDisabled: {
      get() {
        return this.SubAffiliatesEditDisabled || this.inherits.affiliates_sub_affiliates_max_percent === true;
      },
    },

    OffersEditDisabled: {
      get() {
        return this.AffiliatesEditDisabled || (
          this.inherits.offers_edit === false && this.adminPermissions.offers_edit === 'deny');
      },
    },

    setRevShareDisabled: {
      get() {
        return this.OffersEditDisabled || (this.inherits.offers_revshare_edit_settings === false && this.adminPermissions.offers_revshare_edit_settings === 'deny');
      },
    },

    setRevShareDisabledSport: {
      get() {
        return this.setRevShareDisabled || this.inherits.offers_revshare_max_percent_sport === true;
      },
    },

    setRevShareDisabledCasino: {
      get() {
        return this.setRevShareDisabled || this.inherits.offers_revshare_max_percent_casino === true;
      },
    },

    setCpaDisabled: {
      get() {
        return this.OffersEditDisabled || (this.inherits.offers_cpa_edit_settings === false && this.adminPermissions.offers_cpa_edit_settings === 'deny');
      },
    },

    setCpaDisabledMaxCurrencyCode: {
      get() {
        return this.setCpaDisabled || this.inherits.offers_cpa_max_payout_currency_code === true;
      },
    },

    setCpaDisabledMax: {
      get() {
        return this.setCpaDisabled || this.inherits.offers_cpa_max_payout === true;
      },
    },

    setCpaDisabledRatio: {
      get() {
        return this.setCpaDisabled || this.inherits.offers_cpa_min_payout_deposits_sum_ratio === true;
      },
    },

    PlayersViewDisabled: {
      get() {
        return this.inherits.players_view === false && this.adminPermissions.players_view === 'deny';
      },
    },

    PlayersViewDisabledColumns: {
      get() {
        return this.PlayersViewDisabled || this.inherits.players_available_properties === true;
      },
    },

    PaymentsViewDisabled: {
      get() {
        return this.inherits.payments_view === false && this.adminPermissions.payments_view === 'deny';
      },
    },

    LandingViewDisabled: {
      get() {
        return this.inherits.tools_promo_landings_view === false && this.adminPermissions.tools_promo_landings_view === 'deny';
      },
    },

    GeoViewDisabled: {
      get() {
        return this.inherits.countries_allowed_mode === true;
      },
    },


    SegmentsViewTypeDisabled: {
      get() {
        return this.inherits.crm_segments_view === true;
      },
    },
    SegmentsEditDisabled: {
      get() {
        return this.SegmentsViewTypeDisabled || this.adminPermissions.crm_segments_view === 'deny';
      },
    },
    SegmentsEditTypeDisabled: {
      get() {
        return this.SegmentsEditDisabled || this.inherits.crm_segments_edit === true;
      },
    },
    SegmentsReviewDisabled: {
      get() {
        return this.SegmentsEditTypeDisabled || this.adminPermissions.crm_segments_edit === 'deny';
      },
    },
    SegmentsReviewTypeDisabled: {
      get() {
        return this.SegmentsReviewDisabled || this.inherits.crm_segments_review === true;
      },
    },

    ActivitiesViewTypeDisabled: {
      get() {
        return this.inherits.crm_activities_view === true;
      },
    },
    ActivitiesEditDisabled: {
      get() {
        return this.ActivitiesViewTypeDisabled || this.adminPermissions.crm_activities_view === 'deny';
      },
    },
    ActivitiesEditTypeDisabled: {
      get() {
        return this.ActivitiesEditDisabled || this.inherits.crm_activities_edit === true;
      },
    },
    ActivitiesReviewDisabled: {
      get() {
        return this.ActivitiesViewTypeDisabled || this.adminPermissions.crm_activities_view === 'deny';
      },
    },
    ActivitiesReviewTypeDisabled: {
      get() {
        return this.ActivitiesReviewDisabled || this.inherits.crm_activities_review === true;
      },
    },
    ActivitiesRunDisabled: {
      get() {
        return this.ActivitiesViewTypeDisabled || this.adminPermissions.crm_activities_view === 'deny';
      },
    },
    ActivitiesRunTypeDisabled: {
      get() {
        return this.ActivitiesRunDisabled || this.inherits.crm_activities_run === true;
      },
    },
  },

  watch: {
    isOpen(v) {
      if (v) {
        this.getAdminAcl();
        this.$refs.scroll.scrollTop = 0;
        document.querySelector('.user-permissions .permissions-wrapper').addEventListener('scroll', this.hiddenSelectOptions);
      } else {
        document.querySelector('.user-permissions .permissions-wrapper').removeEventListener('scroll', this.hiddenSelectOptions);
      }
    },
    currentTeamSelect() {
      this.getAdminPermissions();

      if (this.inherits.reports_affiliates_types === true) {
        this.refreshInheritReports(true);
      }

      if (this.inherits.offers_revshare_max_percent_sport === true) {
        this.refreshInheritRevshareSport(true);
      }

      if (this.inherits.offers_revshare_max_percent_casino === true) {
        this.refreshInheritRevshareCasino(true);
      }

      if (this.inherits.offers_cpa_max_payout === true) {
        this.refreshInheritCpaMax(true);
      }

      if (this.inherits.offers_cpa_max_payout_currency_code === true) {
        this.refreshInheritCpaMaxCurrency(true);
      }

      if (this.inherits.offers_cpa_min_payout_deposits_sum_ratio === true) {
        this.refreshInheritCpaRatio(true);
      }

      if (this.inherits.affiliates_sub_affiliates_max_percent === true) {
        this.refreshInheritSubAff(true);
      }

      if (this.inherits.affiliates_types_view === true) {
        this.refreshInheritViewAffiliates(true);
      }

      if (this.inherits.media_buying_payments_affiliates_types_view === true) {
        this.refreshInheritViewPayments(true);
      }

      if (this.inherits.media_buying_payments_moderation_statuses_visible === true) {
        this.refreshInheritViewPaymentsModeration(true);
      }
      if (this.inherits.media_buying_payments_payout_statuses_visible === true) {
        this.refreshInheritViewPaymentsPayout(true);
      }

      if (this.inherits.media_buying_payments_affiliates_types_review === true) {
        this.refreshInheritEditPayments(true);
      }

      if (this.inherits.media_buying_posts_affiliates_types_view === true) {
        this.refreshInheritViewPosts(true);
      }
      if (this.inherits.media_buying_reports_affiliates_types_view === true) {
        this.refreshInheritViewMediaReports(true);
      }
      if (this.inherits.media_buying_posts_affiliates_types_review === true) {
        this.refreshInheritReviewPosts(true);
      }
      if (this.inherits.media_buying_posts_statuses_visible === true) {
        this.refreshInheritPostStatuses(true);
      }

      if (this.inherits.affiliates_types_edit === true) {
        this.refreshInheritEditAffiliates(true);
      }

      if (this.inherits.players_available_properties === true) {
        this.refreshInheritPlayers(true);
      }

      if (this.inherits.countries_allowed_mode === true) {
        this.refreshInheritGeo(true);
      }
    },
    'adminPermissions.crm_segments_view': {
      handler() {
        this.checkWeight('crm_segments_view', 'crm_segments_edit');
      },
      deep: true,
    },
    'adminPermissions.crm_segments_edit': {
      handler() {
        this.checkWeight('crm_segments_edit', 'crm_segments_review');
      },
      deep: true,
    },
    'adminPermissions.crm_activities_view': {
      handler() {
        this.checkWeight('crm_activities_view', 'crm_activities_edit');
        this.checkWeight('crm_activities_view', 'crm_activities_review');
        this.checkWeight('crm_activities_view', 'crm_activities_run');
      },
      deep: true,
    },
  },

  methods: {

    getUsers() {
      this.isLoading = true;
      this.errors = {};
      const _query = {
        team_id: this.teamId,
      };
      this.$api.getAdmins(_query).then((response) => {
        this.users = response.data.payload;
      })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    hiddenSelectOptions() {
      this.$refs.selectclose.handleClose();
    },

    checkGeoPermission(permission) {
      this.errors = {};
      if (permission === 'allow_all' || permission === 'deny_all') {
        this.adminPermissions.countries_allowed_list = [];
      }
    },
    checkSegmentPermission(permission, type) {
      if (permission === 'deny') {
        if (type === 'view') {
          this.inherits.crm_segments_edit = true;
          this.refreshInheritSegmentsEdit(true);
        }
        if (type === 'edit') {
          this.inherits.crm_segments_review = true;
          this.refreshInheritSegmentsReview(true);
        }
      }
    },
    checkActivitiesPermission(permission, type) {
      if (permission === 'deny') {
        if (type === 'view') {
          this.inherits.crm_activities_edit = true;
          this.inherits.crm_activities_review = true;
          this.inherits.crm_activities_run = true;

          this.refreshInheritActivitiesEdit(true);
        }
        if (type === 'edit') {
          this.inherits.crm_activities_review = true;
          this.refreshInheritActivitiesReview(true);
        }
        if (type === 'review') {
          this.inherits.crm_activities_run = true;
          this.refreshInheritActivitiesRun(true);
        }
      }
    },

    getAdminPermissions: _.debounce(function getAdminPermissions() {
      this.$api.getAdminPermissions(this.userInfo.id, this.teamId)
        .then((response) => {
          this.adminPermissions = response.data.payload;
          const includeArray = [
            'crm_activities_edit',
            'crm_activities_review',
            'crm_activities_run',
            'crm_activities_view',
            'crm_segments_edit',
            'crm_segments_review',
            'crm_segments_view',
            'countries_allowed_mode',
            'countries_allowed_list',
            'reports_affiliates_types',
            'reports_view_affiliates_visibility_teammates_id',
            'affiliates_view_affiliates_visibility_teammates_id',
            'affiliates_edit_affiliates_visibility_teammates_id',
            'media_buying_posts_view_affiliates_visibility_teammates_id',
            'media_buying_posts_review_affiliates_visibility_teammates_id',
            'media_buying_payments_view_affiliates_visibility_teammates_id',
            'media_buying_payments_review_affiliates_visibility_teammates_id',
            'media_buying_reports_view_affiliates_visibility_teammates_id',
            'affiliates_types_view',
            'affiliates_types_edit',
            'media_buying_payments_affiliates_types_view',
            'media_buying_payments_affiliates_types_review',
            'media_buying_payments_moderation_statuses_visible',
            'media_buying_payments_payout_statuses_visible',
            'media_buying_posts_affiliates_types_view',
            'media_buying_posts_affiliates_types_review',
            'media_buying_reports_affiliates_types_view',
            'media_buying_posts_statuses_visible',
            'players_available_properties',
            'affiliates_sub_affiliates_max_percent',
            'offers_revshare_max_percent_sport',
            'offers_revshare_max_percent_casino',
            'offers_cpa_max_payout',
            'offers_cpa_max_payout_currency_code',
            'offers_cpa_min_payout_deposits_sum_ratio',
          ];
          this.$_.forOwn(this.adminPermissions, (value, key) => {
            if (value == null) {
              this.inherits[key] = true;
              if (includeArray.indexOf(key) !== -1) {
                this.adminPermissions[key] = this.$_.cloneDeep(this.computedAcl[key]);
              }
            } else {
              this.inherits[key] = false;
            }
          });

          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    }, 50),

    getAdminAcl() {
      this.$api.getAdminAcl(this.userInfo.id)
        .then((response) => {
          this.teamAcl = this.$_.cloneDeepWith(response.data.payload);
          this.currentTeamSelect = this.id ? this.id : this.userTeams[0].id;
          this.getUsers();
          this.getAdminPermissions();
          this.getCurrencies();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    getCurrencies() {
      this.$api.getMiscCustomerCurrencies().then((res) => {
        this.currencyOptions = res.data.payload;
      });
    },

    refreshInheritReports(val) {
      if (val === true) {
        this.adminPermissions.reports_affiliates_types = this.$_.cloneDeep(this.computedAcl.reports_affiliates_types);
        this.adminPermissions.reports_view_affiliates_visibility_teammates_id = this.$_.cloneDeep(this.computedAcl.reports_view_affiliates_visibility_teammates_id);
      }
    },

    refreshInheritCpaMaxCurrency(val) {
      if (val === true) {
        this.adminPermissions.offers_cpa_max_payout_currency_code = this.computedAcl.offers_cpa_max_payout_currency_code;
      }
    },

    refreshInheritCpaMax(val) {
      if (val === true) {
        this.adminPermissions.offers_cpa_max_payout = this.computedAcl.offers_cpa_max_payout;
      }
    },

    refreshInheritCpaRatio(val) {
      if (val === true) {
        this.adminPermissions.offers_cpa_min_payout_deposits_sum_ratio = this.computedAcl.offers_cpa_min_payout_deposits_sum_ratio;
      }
    },

    refreshInheritRevshareSport(val) {
      if (val === true) {
        this.adminPermissions.offers_revshare_max_percent_sport = this.computedAcl.offers_revshare_max_percent_sport;
      }
    },

    refreshInheritRevshareCasino(val) {
      if (val === true) {
        this.adminPermissions.offers_revshare_max_percent_casino = this.computedAcl.offers_revshare_max_percent_casino;
      }
    },

    refreshInheritSubAff(val) {
      if (val === true) {
        this.adminPermissions.affiliates_sub_affiliates_max_percent = this.computedAcl.affiliates_sub_affiliates_max_percent;
      }
    },

    refreshInheritEditAffiliates(val) {
      if (val === true) {
        this.adminPermissions.affiliates_types_edit = this.computedAcl.affiliates_types_edit;
        this.adminPermissions.affiliates_edit_affiliates_visibility_teammates_id = this.computedAcl.affiliates_edit_affiliates_visibility_teammates_id;
      }
    },

    refreshInheritViewAffiliates(val) {
      if (val === true) {
        this.adminPermissions.affiliates_types_view = this.computedAcl.affiliates_types_view;
        this.adminPermissions.affiliates_view_affiliates_visibility_teammates_id = this.computedAcl.affiliates_view_affiliates_visibility_teammates_id;
      }
    },
    refreshInheritSegmentsView(val) {
      if (val === true) {
        this.adminPermissions.crm_segments_view = this.computedAcl.crm_segments_view;
        this.inherits.crm_segments_edit = true;
        this.refreshInheritSegmentsEdit(true);
      }
    },
    refreshInheritSegmentsEdit(val) {
      if (val === true) {
        this.adminPermissions.crm_segments_edit = this.computedAcl.crm_segments_edit;
        this.inherits.crm_segments_review = true;
        this.refreshInheritSegmentsReview(true);
      }
    },
    refreshInheritSegmentsReview(val) {
      if (val === true) {
        this.adminPermissions.crm_segments_review = this.computedAcl.crm_segments_review;
      }
    },


    refreshInheritActivitiesView(val) {
      if (val === true) {
        this.adminPermissions.crm_activities_view = this.computedAcl.crm_activities_view;
        this.inherits.crm_activities_edit = true;
        this.inherits.crm_activities_review = true;
        this.inherits.crm_activities_run = true;
        this.refreshInheritActivitiesEdit(true);
      }
    },
    refreshInheritActivitiesEdit(val) {
      if (val === true) {
        this.adminPermissions.crm_activities_edit = this.computedAcl.crm_activities_edit;
      }
    },
    refreshInheritActivitiesReview(val) {
      if (val === true) {
        this.adminPermissions.crm_activities_review = this.computedAcl.crm_activities_review;
      }
    },
    refreshInheritActivitiesRun(val) {
      if (val === true) {
        this.adminPermissions.crm_activities_run = this.computedAcl.crm_activities_run;
      }
    },


    refreshInheritViewPayments(val) {
      if (val === true) {
        this.adminPermissions.media_buying_payments_affiliates_types_view = this.$_.cloneDeep(this.computedAcl.media_buying_payments_affiliates_types_view);
        this.adminPermissions.media_buying_payments_view_affiliates_visibility_teammates_id = this.$_.cloneDeep(this.computedAcl.media_buying_payments_view_affiliates_visibility_teammates_id);
      }
    },
    refreshInheritViewPaymentsModeration(val) {
      if (val === true) {
        this.adminPermissions.media_buying_payments_moderation_statuses_visible = this.computedAcl.media_buying_payments_moderation_statuses_visible;
      }
    },
    refreshInheritViewPaymentsPayout(val) {
      if (val === true) {
        this.adminPermissions.media_buying_payments_payout_statuses_visible = this.computedAcl.media_buying_payments_payout_statuses_visible;
      }
    },
    refreshInheritEditPayments(val) {
      if (val === true) {
        this.adminPermissions.media_buying_payments_affiliates_types_review = this.$_.cloneDeep(this.computedAcl.media_buying_payments_affiliates_types_review);
        this.adminPermissions.media_buying_payments_review_affiliates_visibility_teammates_id = this.$_.cloneDeep(this.computedAcl.media_buying_payments_review_affiliates_visibility_teammates_id);
      }
    },
    refreshInheritViewPosts(val) {
      if (val === true) {
        this.adminPermissions.media_buying_posts_affiliates_types_view = this.$_.cloneDeep(this.computedAcl.media_buying_posts_affiliates_types_view);
        this.adminPermissions.media_buying_posts_view_affiliates_visibility_teammates_id = this.$_.cloneDeep(this.computedAcl.media_buying_posts_view_affiliates_visibility_teammates_id);
      }
    },
    refreshInheritViewMediaReports(val) {
      if (val === true) {
        this.adminPermissions.media_buying_reports_affiliates_types_view = this.$_.cloneDeep(this.computedAcl.media_buying_reports_affiliates_types_view);
        this.adminPermissions.media_buying_reports_view_affiliates_visibility_teammates_id = this.$_.cloneDeep(this.computedAcl.media_buying_reports_view_affiliates_visibility_teammates_id);
      }
    },
    refreshInheritReviewPosts(val) {
      if (val === true) {
        this.adminPermissions.media_buying_posts_affiliates_types_review = this.$_.cloneDeep(this.computedAcl.media_buying_posts_affiliates_types_review);
        this.adminPermissions.media_buying_posts_review_affiliates_visibility_teammates_id = this.$_.cloneDeep(this.computedAcl.media_buying_posts_review_affiliates_visibility_teammates_id);
      }
    },
    refreshInheritPostStatuses(val) {
      if (val === true) {
        this.adminPermissions.media_buying_posts_statuses_visible = this.computedAcl.media_buying_posts_statuses_visible;
      }
    },

    refreshInheritPlayers(val) {
      if (val === true) {
        this.adminPermissions.players_available_properties = this.computedAcl.players_available_properties;
      }
    },

    refreshInheritGeo(val) {
      if (val === true) {
        const { countries_allowed_mode, countries_allowed_list } = this.adminPermissions;
        this.savedGeoValues = this.$_.cloneDeep({ countries_allowed_mode, countries_allowed_list });

        this.adminPermissions.countries_allowed_mode = this.computedAcl.countries_allowed_mode;
        this.adminPermissions.countries_allowed_list = this.computedAcl.countries_allowed_list;
      } else {
        const { countries_allowed_mode, countries_allowed_list } = this.savedGeoValues;

        if (countries_allowed_mode) this.adminPermissions.countries_allowed_mode = countries_allowed_mode;
        if (countries_allowed_list) this.adminPermissions.countries_allowed_list = countries_allowed_list;
      }
    },

    close() {
      this.isLoading = true;
      this.errors = {};
      this.$emit('close');
    },

    checkWeight(currentOption, childOption) {
      if (!this.inherits[currentOption]) {
        const currentOptionVal = this.adminPermissions[currentOption];
        const childOptionVal = this.adminPermissions[childOption];
        if (childOptionVal && (this.crmWeight[childOptionVal] > this.crmWeight[currentOptionVal])) {
          this.adminPermissions[childOption] = this.adminPermissions[currentOption];
        }
      }
    },
    isDisabledOption(property, option) {
      const value = this.adminPermissions[property];
      return !this.crmAllowOptions[value].includes(option);
    },

    save() {
      this.isLoading = true;
      this.$_.forOwn(this.inherits, (value, key) => {
        if (key === 'countries_allowed_mode') {
          this.inherits.countries_allowed_list = this.inherits[key];
        }
        if (key === 'reports_affiliates_types') {
          this.inherits.reports_view_affiliates_visibility_teammates_id = this.inherits[key];
        }
        if (key === 'affiliates_types_view') {
          this.inherits.affiliates_view_affiliates_visibility_teammates_id = this.inherits[key];
        }
        if (key === 'affiliates_types_edit') {
          this.inherits.affiliates_edit_affiliates_visibility_teammates_id = this.inherits[key];
        }
        if (key === 'media_buying_payments_view') {
          this.inherits.media_buying_payments_view_affiliates_visibility_teammates_id = this.inherits[key];
        }
        if (key === 'media_buying_payments_review') {
          this.inherits.media_buying_payments_review_affiliates_visibility_teammates_id = this.inherits[key];
        }
        if (key === 'media_buying_posts_view') {
          this.inherits.media_buying_payments_view_affiliates_visibility_teammates_id = this.inherits[key];
        }
        if (key === 'media_buying_posts_review') {
          this.inherits.media_buying_posts_review_affiliates_visibility_teammates_id = this.inherits[key];
        }
        if (key === 'media_buying_reports_view') {
          this.inherits.media_buying_reports_view_affiliates_visibility_teammates_id = this.inherits[key];
        }
      });
      this.$_.forOwn(this.inherits, (value, key) => {
        if (value === true) {
          this.postPermissions[key] = null;
        } else {
          this.postPermissions[key] = this.adminPermissions[key];
        }
      });
      this.errors = {};
      this.$api.postAdminPermissions(this.userInfo.id, this.teamId, this.postPermissions)
        .then(() => {
          this.close();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    permissionIsDeny(permission) {
      return this.adminPermissions[permission] !== 'allow' && this.inherits[permission] === false;
    },

    inheritIsDeny(permission) {
      return this.inherits[permission];
    },

    updatePermissions(currentPermission, useSnapshot = true) {
      const currentPermissionsSettings = requiresForConfig[currentPermission];

      if (currentPermissionsSettings !== undefined && this.permissionIsDeny(currentPermission)) {
        if (useSnapshot) {
        // Get current state of permissions for switch off and save
          const permissionShapshot = getPermissionsSnapshot(this.adminPermissions, currentPermissionsSettings);
          this.permissionSnapshots = { ...this.permissionSnapshots, [currentPermission]: permissionShapshot };

          // Get current state of inherits for switch off and save
          const inheritsShapshot = getInheritsSnapshot(this.inherits, currentPermissionsSettings);
          this.inheritsSnapshots = { ...this.inheritsSnapshots, [currentPermission]: inheritsShapshot };

          this.inherits = { ...this.inherits, ...getInheritsObject(currentPermissionsSettings, false) };
        }

        this.adminPermissions = { ...this.adminPermissions, ...getPermissionsObject(currentPermissionsSettings) };
      } else if (useSnapshot) {
        // Return saved permissions state
        const permissionShapshot = sortPermissionsSnapshot(this.permissionSnapshots[currentPermission], this.adminPermissions);
        this.adminPermissions = { ...this.adminPermissions, ...permissionShapshot };

        // Return saved inherits state
        const inheritsShapshot = sortInheritsSnapshot(this.inheritsSnapshots[currentPermission], this.inherits);
        this.inherits = { ...this.inherits, ...getInheritsObject(currentPermissionsSettings, false), ...inheritsShapshot };
      }
    },

    updateInherits(currentPermission, useSnapshot = true) {
      const currentPermissionsSettings = requiresForConfig[currentPermission];

      if (currentPermissionsSettings !== undefined && this.inheritIsDeny(currentPermission)) {
        if (useSnapshot) {
        // Get current state of inherits for switch off and save
          const inheritsShapshot = getInheritsSnapshot(this.inherits, currentPermissionsSettings);
          this.inheritsSnapshots = { ...this.inheritsSnapshots, [currentPermission]: inheritsShapshot };
        }

        this.inherits = { ...this.inherits, ...getInheritsObject(currentPermissionsSettings) };
      } else if (useSnapshot) {
        // Return saved inherits state
        const inheritsShapshot = sortInheritsSnapshot(this.inheritsSnapshots[currentPermission], this.inherits);
        this.inherits = { ...this.inherits, ...inheritsShapshot };
      }
    },

    getPermissionDepends(currentPermission) {
      return dependsOnConfig[currentPermission] ? dependsOnConfig[currentPermission] : [];
    },

    permissionIsDisabled(currentPermission) {
      const dependsOn = this.getPermissionDepends(currentPermission);

      return dependsOn.some(dependency => this.permissionIsDeny(dependency));
    },

    inheritIsDisabled(currentPermission) {
      const dependsOn = this.getPermissionDepends(currentPermission);

      return dependsOn.some(dependency => this.inheritIsDeny(dependency));
    },

    renderPermissionSwitchControl(permissionName) {
      const elSwitch = !this.inherits[permissionName] ? this.$createElement('el-switch', {
        class: 'label',
        props: {
          disabled: this.permissionIsDisabled(permissionName) || this.inheritIsDisabled(permissionName),
          activeColor: this.activeColor,
          inactiveColor: this.inactiveColor,
          activeValue: 'allow',
          inactiveValue: 'deny',
          value: this.adminPermissions[permissionName],
        },
        on: {
          change: (value) => {
            this.adminPermissions = { ...this.adminPermissions, [permissionName]: value };
            this.updatePermissions(permissionName);
            this.updateInherits(permissionName, false);
          },
        },
      }) : this.$createElement('div', {
        class: ['access-status', this.computedAcl[permissionName] === 'deny' ? 'access-status__deny' : ''],
      }, this.computedAcl[permissionName] ? this.$t(`permissions.${this.computedAcl[permissionName]}`) : '');

      const elCheckbox = this.$createElement('el-checkbox', {
        class: ['checkbox', this.computedAcl[permissionName] === 'deny' ? 'inherit-deny' : ''],
        props: {
          disabled: this.inheritIsDisabled(permissionName) || this.permissionIsDisabled(permissionName),
          value: this.inherits[permissionName],
        },
        on: {
          change: (value) => {
            this.inherits = { ...this.inherits, [permissionName]: value };
            this.updateInherits(permissionName);
            this.updatePermissions(permissionName, false);
            this.adminPermissions = { ...this.adminPermissions, [permissionName]: this.computedAcl[permissionName] };
          },
        },
      });

      const inheritCheckWrapper = this.$createElement('div', { class: 'inherit-check-wrapper' }, [elCheckbox]);

      const depends = this.getPermissionDepends(permissionName).map(dependency => this.$createElement('li', {
        class: {
          'dependency-item': true,
          selected: (this.adminPermissions[dependency] === 'allow' && this.inherits[dependency] === false),
        },
      }, this.$t(`permissions.list.${dependency}`)));

      const dependsOnText = this.$createElement('h3', { class: 'depends-on-header' }, this.$t('permissions.depends'));
      const contentSlot = this.$createElement('div', { slot: 'content' }, [dependsOnText, depends]);

      const switchWrapper = this.$createElement('div', { class: 'values' }, depends.length ? [inheritCheckWrapper, elSwitch] : [inheritCheckWrapper, elSwitch]);
      const elTooltip = depends.length ? this.$createElement('el-tooltip', { props: { placement: 'right' } }, [switchWrapper, contentSlot]) : switchWrapper;

      const label = this.$createElement('span', { class: 'label' }, this.$t(`permissions.list.${permissionName}`));

      return this.$createElement('div', { class: 'field' }, [label, elTooltip]);
    },
  },
};
</script>
