<template>
  <transition name="fade">
    <div
      v-if="isOpen"
      class="reports-filter-popup"
    >
      <div class="popup">
        <div class="head">
          <span class="name">{{ $t('reports.other.search_by.title') }} {{ $t(`reports.other.search_by.${group}`) }}</span>
          <ui-icon
            :size="20"
            lib="fa"
            substyle="fas"
            class="close"
            name="times"
            @click.native="close"
          />
        </div>
        <div class="body">
          <div class="title">
            {{ $t(`reports.other.search_by.text.${group}`) }}
          </div>
          <div class="textarea-wrapper">
            <el-input
              v-model="searchValue"
              type="textarea"
              :rows="6"
              :placeholder="$t(`reports.other.search_by.placeholder.${group}`)"
            />
            <div
              v-if="searchValue"
              class="clear-search fa fas fa-times-circle"
              @click="clearTextarea()"
            />
          </div>
          <el-checkbox
            v-model="exclude"
          >
            {{ $t('reports.other.exclude') }}
          </el-checkbox>
        </div>
        <div class="foot">
          <ui-button
            color="red"
            lib="fa"
            substyle="fas"
            icon="times"
            @click.native="close"
          >
            {{ $t('reports.other.close') }}
          </ui-button>
          <ui-button
            :color="$theme.mainColor"
            :disabled="searchValue.split('\n').map(item => item.trim()).filter(String).length === 0"
            filled
            lib="fa"
            substyle="fas"
            icon="check"
            @click="confirm"
          >
            {{ $t('reports.other.confirm') }}
          </ui-button>
        </div>
      </div>
      <div class="wrapper" />
    </div>
  </transition>
</template>

<script>

export default {
  name: 'ReportsFilterPopup',

  props: {
    isOpenPopup: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data() {
    return {
      isOpen: false,
      searchData: {},
      group: '',
      op: [
        {
          value: 'like',
        },
        {
          value: 'notLike',
        },
      ],

      exclude: false,
      searchValue: '',
    };
  },

  computed: {

  },

  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.keyupHandler);
  },

  methods: {
    open(searchObject, group) {
      this.searchData = this.$_.cloneDeep(searchObject);
      this.group = group;
      this.getData();
      this.isOpen = true;
    },

    getData() {
      if (this.searchData && this.searchData[this.group]) {
        this.exclude = this.searchData[this.group].op === 'notLike';
        this.searchValue = this.searchData[this.group].value.join('\n');
      } else {
        this.exclude = false;
        this.searchValue = '';
      }
    },

    close() {
      this.isOpen = false;
      this.exclude = false;
      this.searchValue = '';
      setTimeout(() => {
        this.$emit('close');
      }, 0);
    },

    keyupHandler(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    },

    confirm() {
      this.searchData[this.group] = {
        op: this.exclude ? 'notLike' : 'like',
        value: this.searchValue.split('\n').map(item => item.trim()).filter(String),
      };
      this.$emit('confirm', this.searchData);
      this.close();
    },

    splitString(stringToSplit, separator) {
      return stringToSplit.split(separator);
    },

    clearTextarea() {
      this.searchValue = '';
    },
  },
};
</script>


<style lang="scss" scoped>
.reports-filter-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;

  &.fade-enter-active, &.fade-leave-active{
    transition: all .4s;
    .wrapper{
      transition: all .4s;
    }
  }
  &.fade-enter, &.fade-leave-to{
    opacity: 0;
    .wrapper{
      transform: translateY(20px);
    }
  }

  .cancel-button {
    margin-right: 8px;
  }

  .popup {
    position: absolute;
    z-index: 902;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: 1px solid #eaeaea;
      padding: 16px 24px;
      box-sizing: border-box;
      margin-bottom: 0;

      .name {
        font-size: 20px;
        font-weight: 500;
      }

      .close {
        color: #303634;
        padding: 5px;
        font-size: 32px;
        cursor: pointer;
      }
    }
    .body {
      padding: 16px 24px;
      box-sizing: border-box;

      .el-radio-button__inner {
        min-width: 70px;
        &:hover {
          color: #303634;
          background-color: #e8f2ee;
        }
      }

      .title{
        margin-bottom: 8px;
        font-size: 14px;
      }

      .el-radio-group{
        margin-bottom: 16px;
      }

      .textarea-wrapper{
        position: relative;
        margin-bottom: 8px;

        .clear-search{
          position: absolute;
          top: 12px;
          right: 25px;
          font-size: 16px;
          color: #d26370;
          cursor: pointer;
        }
      }
    }
    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      margin-top: 0;

      .ui-button{
        margin-left: 8px;
      }
    }
  }

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
</style>

<style lang="scss">
.reports-filter-popup {
  .popup {
    .body {
      .el-radio-button__inner {
        min-width: 70px;
        outline: none;
        &:hover {
          color: #303634;
          background-color: #e8f2ee;
        }
      }
      .el-textarea__inner{
        resize: none;
        padding: 10px 40px 10px 10px;
      }
    }
  }
}
</style>
