<template>
  <el-popover
    v-model="isOpen"
    placement="bottom"
    trigger="manual"
    transition="el-zoom-in-top"
    popper-class="campaign-statistics-filter-popover"
  >
    <ui-button
      ref="statsPopover"
      slot="reference"
      :filled="hasFilters"
      class="btn empty filter-button ui-m-md-l"
      lib="fa"
      substyle="fas"
      icon="filter"
      @click="popoverCampaignStatisticsTrigger"
    >
      <div
        v-show="hasFilters"
        class="clear-search fa fas fa-times"
        @click.stop="resetFilter"
      />
    </ui-button>
    <div class="campaign-statistics-filter">
      <span class="label">{{ $t('mediaBuying.statistics.period') }}</span>
      <el-radio-group
        v-model="filterType"
        class="ui-radio-group"
        size="medium"
      >
        <el-radio-button label="all">
          {{ $t('ui.filters.select.all') }}
        </el-radio-button>
        <el-radio-button label="custom">
          {{ $t('ui.filters.select.custom') }}
        </el-radio-button>
      </el-radio-group>
      <div class="ui-m-xl-t">
        <span class="label">{{ $t('mediaBuying.statistics.select_period') }}</span>
        <div>
          <el-date-picker
            v-model="period"
            :disabled="filterType !== 'custom'"
            type="daterange"
            :start-placeholder="$t('reports.other.start_date')"
            :end-placeholder="$t('reports.other.end_date')"
            :placeholder="$t('reports.other.enter_date')"
            format="dd MMM yyyy"
            size="small"
            class="date-picker"
          />
        </div>
      </div>
    </div>

    <div class="campaign-statistics-btn-wrap">
      <ui-button
        class="btn"
        color="red"
        lib="fa"
        substyle="fas"
        icon="times"
        @click="resetFilter"
      >
        {{ $t('reports.other.reset') }}
      </ui-button>
      <ui-button
        class="btn"
        filled
        lib="fa"
        substyle="fas"
        icon="check"
        @click="applyCampaignStatistics"
      >
        {{ $t('reports.other.apply') }}
      </ui-button>
    </div>
  </el-popover>
</template>

<script>

export default {
  name: 'CampaignStatisticFilterPopup',
  components: {},
  props: {
    propsPeriod: {
      type: [Array, null],
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      filterType: 'all',
      period: null,
    };
  },
  computed: {
    hasFilters() {
      return this.propsPeriod !== null;
    },
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.period = this.propsPeriod;
        this.filterType = this.propsPeriod ? 'custom' : 'all';
      }
    },
  },
  mounted() {
    window.addEventListener('keyup', this.escHandler);
    window.addEventListener('click', this.eventCloseStatsPopup, true);
  },
  destroyed() {
    window.removeEventListener('keyup', this.escHandler);
    document.removeEventListener('click', this.eventCloseStatsPopup, true);
  },
  methods: {
    resetFilter() {
      this.isOpen = false;
      this.$emit('apply-filters', null);
    },
    applyCampaignStatistics() {
      this.isOpen = false;
      this.$emit('apply-filters', this.period);
    },
    popoverCampaignStatisticsTrigger() {
      this.isOpen = !this.isOpen;
    },
    escHandler(e) {
      if (this.isOpen && e.key === 'Escape') {
        this.isOpen = false;
      }
    },
    eventCloseStatsPopup(e) {
      if (!this.isOpen) return;

      if (!this.$el.contains(e.target) && this.$parent.$el.parentElement.contains(e.target)) {
        this.isOpen = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign-statistics-filter {
  width: 284px;
  display: flex;
  flex-direction: column;
  padding: 16px;

  .label {
    font-size: 14px;
    color: #303634;
  }

  .date-picker {
    margin-top: 6px;
  }

  .ui-radio-group {
    margin-top: 6px;

    /deep/ {
      .el-radio-button {
        flex: 1;

        .el-radio-button__inner {
          justify-content: center;
        }
      }
    }
  }
}

.campaign-statistics-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid #eaeaea;

  .btn + .btn{
    margin-left: 8px;
  }
}

.clear-search {
  position: absolute;
  top: -8px;
  right: -8px;
  border: none;
  width: 16px;
  height: 16px !important;
  margin: 0;
  border-radius: 50%;
  background-color: #d26370;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;

  &:before {
    font-size: 8px;
  }
}
</style>
