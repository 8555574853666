export default {
  mainColor: 'green',
  logoName: 'staging-logo.svg',
  maxLogoHeight: '24px',
  accentColor: '#20815e',
  activeColor: '#ffce06',
  dangerColor: '#d26370',
  uiTheme: {
    primaryColor: '#20815e',
    dangerColor: '#d26370',
    infoColor: '#3b94e3',
    warningColor: '#ffce06',
    lightColor: '#fff',
    darkColor: '#303634',
    // the-header
    headerColor: '#20815e',
    headerBorder: '#4d997e',
    headerText: '#ffffff',
    headerTextHover: '#ffffff',
    headerTextBorder: '#ffce06',
    reportDropdownBackground: '#136648',
    reportDropdownBackgroundHover: '#20815e',
    reportDropdownActive: '#ffce06',
    reportDropdownActiveHover: '#ffce06',
    scrollThumb: '#c3c3c3',
    scrollBackground: '#20815e',
    colorFirst: '#0d3627',
    colorSecond: '#ffce05',
    backLink: '#20815e',
  },
};
