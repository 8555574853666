export default {
  live: {
    name: 'Live',
    menu: {
      'last-bets': 'Last Bets & Deposits',
      'top-events': 'Top Events',
    },
    tables: {
      last20bets: 'Last 20 Bets on Sport',
      last20deposits: 'Last 20 Deposits',
      top20eventsCalculated: 'Top 20 Events With Result',
      top20eventsUncalculated: 'Top 20 Events Without Result',
    },
    list: {
      fields: {
        events: {
          title: 'Event',
        },
        events_count: {
          title: 'Event Type',
        },
        player_country_name: {
          title: 'Country',
        },
        site_player_id: {
          title: 'Player',
        },
        bet_sum: {
          title: 'Sum',
        },
        original_bet_sum: {
          title: 'In Original Currency',
        },
        deposit_sum: {
          title: 'Sum',
        },
        original_deposit_sum: {
          title: 'In Original Currency',
        },
        coefficient: {
          title: 'Odd',
        },
        payment_system_name: {
          title: 'Method',
        },
        event_name: {
          title: 'Event',
        },
        bets_count: {
          title: 'Bets Count',
        },
        bets_sum: {
          title: 'Bets Sum',
        },
        wins_sum: {
          title: 'Wins Sum',
        },
        profit_sum: {
          title: 'Profit Sum',
        },
        timestamp: {
          title: 'Date & Time',
        },
      },
    },
    toolbar: {
      refresh: 'Auto refresh',
    },
  },
};
