<template>
  <div
    :class="{'blocks-right__empty': isOfferLoading && !isList}"
    class="blocks-right offer-info-block"
  >
    <p v-if="isOfferLoading && !isList">
      {{ $t('affiliates.affiliatesOffers.offer_detail') }}
    </p>
    <div
      v-if="isOfferLoading && isList && offerInfo"
      class="offer-card offer-card__cling-up"
    >
      <div class="offer-card__up-border offer-card__up">
        <div>
          <span class="text-fade">ID: {{ offerInfo.incrementing_id || '' }}</span>
          <h4 class="offer-card__title-inner">
            {{ offerInfo.name }}
          </h4>
        </div>
        <div class="tech-wrapper">
          <div
            v-if="offerInfo.scheduled_offer_settings"
            class="pending-changes-info"
            v-html="$t('affiliates.affiliatesOffers.pending_date', {date: pendingChangesDate})"
          />
          <div
            v-if="isEditOffer"
            class="offer-card__down-btns"
          >
            <ui-button
              :loading="loadingBtn"
              color="red"
              class="btn-red"
              icon="unlink"
              lib="fa"
              substyle="fas"
              @click="checkUnpin"
            >
              {{ $t('affiliates.affiliatesOffers.unpin') }}
            </ui-button>
            <ui-button
              color="green"
              class="btn action-btn"
              lib="fa"
              substyle="fas"
              icon="pen"
              @click="editOffer(offerInfo.scheduled_changes_effective_timestamp !== null)"
            >
              {{ $t('affiliates.affiliatesOffers.edit') }}
            </ui-button>
          </div>
        </div>
      </div>
      <div class="ui-d-flex ui-fd-column">
        <div class="title-blue brands ui-d-flex ui-jc-space-between">
          {{ isDiff ? $t('affiliates.affiliatesOffers.available_brands') : $t('affiliates.affiliatesOffers.available_brands_links') }}
          <div
            v-if="!isDiff"
            v-clipboard:copy="formattedLinks"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
            class="ui-d-flex copy-links"
          >
            <ui-icon
              name="copy"
              lib="fa"
              :color="$theme.accentColor"
              class="ui-m-sm-r"
            />
            {{ $t('affiliates.affiliatesOffers.copyLinks') }}
          </div>
        </div>
        <div class="title-blue ui-d-flex offer-card__brands">
          <div
            v-for="item in availableBrands(offerInfo.brand_visibility, offerInfo.visible_brands)"
            :key="item.id"
            class="brands-item-info"
            :class="{'active': !isDiff && item.id === currentOfferCardBrand, 'pending': isDiff}"
            @click="onSelectBrand(item.id)"
          >
            <img
              v-if="item.favicon_base64"
              :src="item.favicon_base64"
            >
            {{ item.name }}
          </div>
        </div>
        <div class="ui-m-md-t links">
          <div
            v-for="(tracking_links, index) in offerInfo.tracking_links"
            :key="index"
            class="w-100 offer-card__table"
          >
            <div
              v-for="(item, ind) in filterOfferUrls(tracking_links.urls)"
              :key="ind"
              class="offer-card__table-row"
            >
              <div
                class="offer-card__down-item link"
              >
                <h5 class="offer-card__title-inner">
                  {{ item.name }}
                </h5>
                <span class="text-tooltip text-tooltip__viridian">{{ item.url }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isOfferLoading && isList && offerInfo"
      class="offer-card offer-card__cling-down"
    >
      <div class="offer-card__left">
        <h5 class="offer-card__title-inner condition">
          {{ $t('affiliates.affiliatesOffers.rule_candiion') }}
        </h5>
        <ui-input
          v-model="searchText"
          is-search
          autosize
          @search="searchRule"
        />
        <div v-if="rulesList.length !== 0">
          <div
            v-for="(item, index) in rulesList"
            :key="index"
            class="title-blue__wrap"
            @click="isActiveRule = index"
          >
            <div
              :class="{'title-blue__active': isActiveRule === index}"
              class="title-blue"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="offer-card__right">
        <!-- Country -->
        <div v-if="rulesList && rulesList.length !== 0">
          <div
            v-if="hideCountry()"
            class="offer-card__down-item"
          >
            <h5 class="offer-card__title-inner">
              {{ $t('affiliates.offersView.labels.countryFilter') }}
            </h5>
            <span class="text-tooltip">
              {{ getCountryCode.map(getCountryName).join(', ') }}
            </span>
          </div>
          <div v-if="hasRevShare" class="ui-d-flex rules-container">
            <div v-if="rulesList[isActiveRule].settings.rev_share.sport_levels !== null" class="rule">
              <div class="title-blue">
                {{ rulesList[isActiveRule].settings.rev_share.casino_levels !== null ?
                  $t('affiliates.affiliatesOffers.rev_sport') :
                  $t('affiliates.affiliatesOffers.revshare')
                }}
              </div>
              <div class="offer-card__table">
                <div
                  v-for="(level, i) in rulesList[isActiveRule].settings.rev_share.sport_levels"
                  :key="i"
                  class="offer-card__table-row ui-jc-space-between"
                >
                  <div class="ui-d-flex">
                    <ui-icon
                      name="greater-than-equal"
                      substyle="fas"
                      lib="fa"
                      class="less-or-equal"
                    />
                    <span class="ui-m-sm-l">{{ level.ftd_count }} FTD</span>
                  </div>
                  <span>{{ level.percent }}%</span>
                </div>
              </div>
            </div>

            <div v-if="rulesList[isActiveRule].settings.rev_share.casino_levels !== null" class="rule">
              <div class="title-blue">
                {{ $t('affiliates.affiliatesOffers.rev_casino') }}
              </div>

              <div class="offer-card__table">
                <div
                  v-for="(level, i) in rulesList[isActiveRule].settings.rev_share.casino_levels"
                  :key="i"
                  class="offer-card__table-row ui-jc-space-between"
                >
                  <div class="ui-d-flex">
                    <ui-icon
                      name="greater-than-equal"
                      substyle="fas"
                      lib="fa"
                      class="less-or-equal"
                    />
                    <span class="ui-m-sm-l">{{ level.ftd_count }} FTD</span>
                  </div>
                  <span>{{ level.percent }}%</span>
                </div>
              </div>
            </div>
          </div>

          <!-- CPA -->
          <div v-for="(cpaItem, i) in rulesList[isActiveRule].settings.cpa" :key="`dep-${i}`" class="ui-m-sm-t">
            <div
              v-if="cpaItem.amount !== null"
              class="title-blue"
            >
              {{ $t('affiliates.affiliatesOffers.cpa') }}
            </div>
            <div class="line root">
              <div
                v-if="cpaItem.amount !== null"
                class="offer-card__table"
              >
                <div class="offer-card__table-row">
                  <span>{{ $t('affiliates.affiliatesOffers.payout') }}</span>
                  <span> <vnodes :vnodes="renderOfferInfo('amount', 'uncountable', i)" /></span>
                </div>
                <div
                  v-if="offerInfo.cap_players_count !== null && offerInfo.cap_period_seconds !== null"
                  class="offer-card__table-row"
                >
                  <span>{{ $t('affiliates.affiliatesOffers.cap_players') }}</span>
                  <span>
                    <vnodes :vnodes="renderOfferInfo('daily_cap', 'uncountable', i)" />
                  </span>
                </div>
                <div
                  v-if="offerInfo.global_hold_seconds_cpa !== null"
                  class="offer-card__table-row"
                >
                  <span>{{ $t('affiliates.affiliatesOffers.hold') }}</span>
                  <span>
                    <vnodes :vnodes="renderOfferInfo('hold_seconds', 'uncountable', i)" />
                  </span>
                </div>
              </div>
            </div>

            <!-- deposits -->
            <div v-if="hasDeposits(cpaItem)" class="line">
              <div class="title-blue">
                {{ $t('affiliates.affiliatesOffers.deposit') }}
              </div>
              <div
                v-if="viewRules('deposits_count', i) || viewRules('deposits_sum', i) || viewRules('deposit_amount', i)"
                ref="deposits"
                class="offer-card__table"
              >
                <div
                  v-if="viewRules('deposits_count', i)"
                  class="offer-card__table-row"
                >
                  <span>{{ $t('affiliates.affiliatesOffers.deposit_count') }}</span>
                  <vnodes :vnodes="renderMinMax('deposits_count', i)" />
                </div>

                <div
                  v-if="viewRules('deposits_sum', i)"
                  class="offer-card__table-row"
                >
                  <span>{{ $t('affiliates.affiliatesOffers.deposit_total') }}</span>
                  <vnodes :vnodes="renderMinMax('deposits_sum', i)" />
                </div>

                <div
                  v-if="viewRules('deposit_amount', i)"
                  class="offer-card__table-row"
                >
                  <span>{{ $t('affiliates.affiliatesOffers.one_deposit') }}</span>
                  <vnodes :vnodes="renderMinMax('deposit_amount', i)" />
                </div>
              </div>
            </div>
            <!-- Sport Bets -->
            <div v-if="hasSportBets(cpaItem)" class="line">
              <div class="title-blue">
                {{ $t('affiliates.affiliatesOffers.sport_bet') }}
              </div>
              <div
                v-if="viewRules('sport_bets_count', i) ||
                  viewRules('sport_bets_sum', i) ||
                  viewRules('sport_bet_amount', i) ||
                  viewRules('sport_bets_odd', i)"
                ref="sport_bet"
                class="offer-card__table"
              >
                <div
                  v-if="viewRules('sport_bets_count', i)"
                  class="offer-card__table-row"
                >
                  <span>{{ $t('affiliates.affiliatesOffers.bet_count') }}</span>
                  <vnodes :vnodes="renderMinMax('sport_bets_count', i)" />
                </div>

                <div
                  v-if="viewRules('sport_bets_sum', i)"
                  class="offer-card__table-row"
                >
                  <span>{{ $t('affiliates.affiliatesOffers.bet_sum') }}</span>
                  <vnodes :vnodes="renderMinMax('sport_bets_sum', i)" />
                </div>

                <div
                  v-if="viewRules('sport_bet_amount', i)"
                  class="offer-card__table-row"
                >
                  <span>{{ $t('affiliates.affiliatesOffers.one_bet_amount') }}</span>
                  <vnodes :vnodes="renderMinMax('sport_bet_amount', i)" />
                </div>

                <div
                  v-if="viewRules('sport_bets_odd', i)"
                  class="offer-card__table-row"
                >
                  <span>{{ $t('affiliates.affiliatesOffers.bet_odd') }}</span>
                  <vnodes :vnodes="renderMinMax('sport_bets_odd', i)" />
                </div>
              </div>
            </div>
            <!-- Casino Bets -->
            <div v-if="hasCasinoBets(cpaItem)" class="line">
              <div class="title-blue">
                {{ $t('affiliates.affiliatesOffers.casino_bet') }}
              </div>
              <div
                v-if="viewRules('casino_bets_count', i) || viewRules('casino_bets_sum', i) || viewRules('casino_bet_amount', i)"
                ref="casino_bet"
                class="offer-card__table"
              >
                <div
                  v-if="viewRules('casino_bets_count', i)"
                  class="offer-card__table-row"
                >
                  <span>{{ $t('affiliates.affiliatesOffers.bet_count') }}</span>
                  <vnodes :vnodes="renderMinMax('casino_bets_count', i)" />
                </div>

                <div
                  v-if="viewRules('casino_bets_sum', i)"
                  class="offer-card__table-row"
                >
                  <span>{{ $t('affiliates.affiliatesOffers.bet_sum') }}</span>
                  <vnodes :vnodes="renderMinMax('casino_bets_sum', i)" />
                </div>

                <div
                  v-if="viewRules('casino_bet_amount', i)"
                  class="offer-card__table-row"
                >
                  <span>{{ $t('affiliates.affiliatesOffers.one_bet_amount') }}</span>
                  <vnodes :vnodes="renderMinMax('casino_bet_amount', i)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import app from '@/main';

const depositKeys = [
  'deposits_count',
  'deposits_sum',
  'deposit_amount',
].map(key => [`${key}_max`, `${key}_min`]).flat();

const sportBetsKeys = [
  'sport_bets_count',
  'sport_bets_sum',
  'sport_bet_amount',
  'sport_bets_odd',
].map(key => [`${key}_max`, `${key}_min`]).flat();

const casinoBetsKeys = [
  'casino_bets_count',
  'casino_bets_sum',
  'casino_bet_amount',
].map(key => [`${key}_max`, `${key}_min`]).flat();

export default {
  name: 'OfferInfo',

  components: {
    Vnodes: {
      functional: true,
      render: (h, ctx) => (ctx.props.vnodes ? ctx.props.vnodes : ''),
    },
  },

  props: {
    isOfferLoading: {
      type: Boolean,
      default() {
        return true;
      },
    },
    isList: {
      type: Boolean,
      default() {
        return true;
      },
    },
    isEditOffer: {
      type: Boolean,
      default() {
        return true;
      },
    },
    loadingBtn: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isDiff: {
      type: Boolean,
      default() {
        return false;
      },
    },
    offerInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      currentOfferCardBrand: '',
      searchText: '',
      rulesList: [],
      isActiveRule: 0,

      hiddenCasino: 1,
      hiddenSport: 1,
      hiddenDeposits: 1,
    };
  },

  computed: {
    ...mapGetters({
      countries: 'misc/countries',
      brands: 'misc/brands',
      currencies: 'misc/currencies',
    }),
    hasRevShare() {
      const amount = this.$_.get(this.rulesList[this.isActiveRule], ['settings', 'cpa', 0, 'amount']);

      if (this.rulesList[this.isActiveRule].settings.rev_share) {
        return [
          this.rulesList[this.isActiveRule].settings.rev_share?.sport_levels,
          this.rulesList[this.isActiveRule].settings.rev_share?.casino_levels,
        ].some(this.hasRs) || !amount;
      }

      return false;
    },
    pendingChangesDate() {
      return this.$options.filters.formatDate3(this.offerInfo.scheduled_changes_effective_timestamp);
    },

    formattedLinks() {
      return !this.isDiff ? this.$_.join(
        this.offerInfo.tracking_links[0].urls.filter(rule => rule.site_id === this.currentOfferCardBrand)
          .map(item => `${item.url} - ${item.name_short}`),
        '\n',
      ) : '';
    },

    getCountryCode() {
      let cc = [''];
      if (this.offerInfo.payout_rules[this.isActiveRule].filters.hasOwnProperty('country_code')) {
        cc = this.offerInfo.payout_rules[this.isActiveRule].filters.country_code;
      }
      return cc;
    },
  },

  watch: {
    offerInfo() {
      this.defaultBrandsSelectValue();
      this.rulesList = this.offerInfo.payout_rules.map((rule, i) => {
        const { values, displayValues } = this.getOfferName(this.offerInfo.payout_rules, i, 46);
        return {
          ...rule,
          name: displayValues,
          values,
        };
      });
      this.isActiveRule = 0;
      this.searchText = '';
    },
  },

  mounted() {
    // this.$eventBus.$on('delete-note-component', note => this.deleteNoteComponent(note));
  },

  beforeDestroy() {
    // this.$eventBus.$off('delete-note-component');
  },

  methods: {
    getCurrencyLabel(code) {
      const currency = this.currencies.find((el => el.code === code));

      return currency?.symbol || `${code} `;
    },
    hasRs(lvl) {
      return lvl?.some(el => !!+el.percent);
    },
    onSelectBrand(id) {
      if (this.isShowOfferLinksSelect()) {
        this.currentOfferCardBrand = id;
      }
    },
    hasDeposits(cpa) {
      return Object.values(this.$_.pick(cpa, depositKeys)).some(el => !!el || el === 0);
    },
    hasSportBets(cpa) {
      return Object.values(this.$_.pick(cpa, sportBetsKeys)).some(el => !!el || el === 0);
    },
    hasCasinoBets(cpa) {
      return Object.values(this.$_.pick(cpa, casinoBetsKeys)).some(el => !!el || el === 0);
    },
    countryPartName(rules, index, rowLen) {
      const prefix = this.prefixName(rules, index);
      const values = rules[index].filters?.country_code || [];

      if (!values?.length) {
        return {
          values: prefix,
          displayValues: prefix,
        };
      }

      const fitValues = this.$_.truncate(values.join(', '), {
        length: rowLen - prefix.length,
        separator: ', ',
        omission: '',
      });

      const notFit = values.filter(val => !fitValues.split(', ').includes(val));
      const countNotFit = notFit.length ? ` [+${notFit.length}]` : '';
      return {
        values: `${prefix} ${values.join(', ')}`,
        displayValues: `${prefix} ${fitValues}${countNotFit}`,
      };
    },
    isEmptyRs(lvl) {
      if (!lvl) return true;

      return lvl.every(el => !+el.percent);
    },
    prefixName(rules, index) {
      const { rev_share, cpa } = rules[index].settings;

      if ([rev_share?.sport_levels, rev_share?.casino_levels].every(this.isEmptyRs) && cpa && cpa.some(el => el.amount > 0)) {
        return '[CPA] ';
      }

      if (rev_share && cpa) {
        return '[Hyb] ';
      }

      if (rev_share) {
        return '[RS] ';
      }

      if (cpa) {
        return '[CPA] ';
      }

      return '';
    },
    getOfferName(rules, index, rowLen) {
      if (index === rules.length - 1) {
        return {
          values: 'Default',
          displayValues: 'Default',
        };
      }

      return this.countryPartName(rules, index, rowLen);
    },
    humanizeDuration(value) {
      if (value === 0) {
        return 0;
      }
      const hd = this.$moment.duration(value, 'seconds');
      let res = '';
      Object.keys(hd._data)
        .reverse()
        .forEach((key) => {
          // eslint-disable-next-line
          if (hd._data[key] !== 0) {
            res += `${this.$tc(`affiliates.time.${key}`, hd._data[key], { count: hd._data[key] })}`;
          }
        });
      return res;
    },

    defaultBrandsSelectValue() {
      this.currentOfferCardBrand = this.offerInfo ? this.availableBrands(this.offerInfo.brand_visibility, this.offerInfo.visible_brands)[0].id : '';
    },

    availableBrands(visibility, offerBrands) {
      if (visibility !== 'all') {
        const formatedBrands = [];
        offerBrands.forEach((brand) => {
          formatedBrands.push({
            id: brand.site_id,
            name: brand.site_name,
            favicon_base64: brand.site_favicon,
          });
        });
        return formatedBrands;
      }
      return this.brands;
    },

    getBrandImgUrl() {
      const brandFavicon = this.currentOfferCardBrand.length !== 0 ? this.brands.filter(brand => brand.id === this.currentOfferCardBrand)[0].favicon_base64 : '';
      return brandFavicon !== undefined ? brandFavicon : '';
    },

    isShowOfferLinksSelect() {
      return !(this.offerInfo.brand_visibility !== 'all' && this.offerInfo.visible_brands.length === 1);
    },

    filterOfferUrls(urls) {
      return urls.filter(rule => rule.site_id === this.currentOfferCardBrand);
    },

    onCopyError() {
      this.$noty.error(this.$t('affiliates.affiliatesOffers.failedCopyLink'));
    },

    onCopySuccess() {
      this.$noty.info(this.$t('affiliates.affiliatesOffers.copiedClipboard'));
    },

    searchRule(v) {
      this.isActiveRule = 0;
      this.rulesList = this.offerInfo.payout_rules.map((rule, i) => {
        const { values, displayValues } = this.getOfferName(this.offerInfo.payout_rules, i, 50);
        return {
          ...rule,
          name: displayValues,
          values,
        };
      });
      this.rulesList = this.rulesList.filter(e => e.values.replaceAll(' ', '').toLowerCase().indexOf(v.replaceAll(' ', '').toLowerCase()) !== -1);
    },

    viewRules(property, i) {
      const offerPropertyMin = `${property}_min`;
      const offerPropertyMax = `${property}_max`;
      const min = this.$_.get(this.rulesList, [this.isActiveRule, 'settings', 'cpa', i, offerPropertyMin], null);
      const max = this.$_.get(this.rulesList, [this.isActiveRule, 'settings', 'cpa', i, offerPropertyMax], null);
      return !(min === null && max === null);
    },

    getCountryPredicate() {
      if (this.rulesList[this.isActiveRule].filters.hasOwnProperty('country_code')) {
        const { predicate } = this.rulesList[this.isActiveRule].filters.country_code;
        if (predicate === 'EQUALS') {
          return this.$t('affiliates.affiliatesOffers.allowed');
        }
        if (predicate === 'NOT_EQUALS') {
          return this.$t('affiliates.affiliatesOffers.unallowed');
        }
      }
      return '';
    },

    getCountryName(code) {
      const c = this.countries.filter(country => country.code === code);
      if (c.length) {
        return c[0].name;
      }
      return '';
    },

    hideCountry() {
      if (this.rulesList[this.isActiveRule].filters.hasOwnProperty('country_code')) {
        return this.rulesList[this.isActiveRule].filters.country_code.length !== 0;
      }
      return false;
    },

    renderOfferInfoPerDays(index) {
      const cap_players_count_val = this.rulesList[this.isActiveRule].settings.cpa[index].cap_players_count;

      if (cap_players_count_val === 0) {
        return this.$createElement('span', {}, '0');
      }

      const cap_period_days_val = this.$moment.duration(this.rulesList[this.isActiveRule].settings.cpa[index].cap_period_seconds, 'seconds').asDays();
      return this.$createElement('span', {}, this.$tc('affiliates.affiliatesOffers.cap_players_per_days', cap_period_days_val, { count: cap_players_count_val, days: cap_period_days_val }));
    },
    renderOfferInfo(offerProperty, translate, index) {
      let span = this.$createElement('span', {}, this.$t(`affiliates.affiliatesOffers.${translate}`, { count: this.rulesList[this.isActiveRule].settings.cpa[index][offerProperty] }));

      if (offerProperty === 'daily_cap') {
        return this.renderOfferInfoPerDays(index);
      }

      if (offerProperty === 'hold_seconds') {
        const hold_seconds_cpa = this.humanizeDuration(this.rulesList[this.isActiveRule].settings.cpa[index].hold_seconds);
        span = this.$createElement('span', {}, this.$t(`affiliates.affiliatesOffers.${translate}`, { count: hold_seconds_cpa }));
      }

      if (offerProperty === 'amount') {
        const currencyLabel = this.getCurrencyLabel(this.rulesList[this.isActiveRule].settings.cpa[index].currency_code);
        const cpa_payout = currencyLabel + this.$options.filters.formatMoney(this.rulesList[this.isActiveRule].settings.cpa[index].amount);

        span = this.$createElement('span', {}, this.$t(`affiliates.affiliatesOffers.${translate}`, { count: cpa_payout }));
      }

      return span;
    },

    renderMinMax(offerProperty, index) {
      const offerPropertyMin = `${offerProperty}_min`;
      const offerPropertyMax = `${offerProperty}_max`;

      let offerPropertyMinValue = this.rulesList[this.isActiveRule].settings.cpa[index][offerPropertyMin];
      let offerPropertyMaxValue = this.rulesList[this.isActiveRule].settings.cpa[index][offerPropertyMax];

      const hasMinValue = !!offerPropertyMinValue || offerPropertyMinValue === 0;
      const hasMaxValue = !!offerPropertyMaxValue || offerPropertyMaxValue === 0;

      if (offerProperty !== 'deposits_count' && offerProperty !== 'sport_bets_count' && offerProperty !== 'sport_bets_odd' && offerProperty !== 'casino_bets_count') {
        const currencyLabel = this.getCurrencyLabel(this.rulesList[this.isActiveRule].settings.cpa[index].currency_code);

        if (hasMinValue) {
          offerPropertyMinValue = currencyLabel
            + this.$options.filters.formatMoney(this.rulesList[this.isActiveRule].settings.cpa[index][offerPropertyMin]);
        }

        if (hasMaxValue) {
          offerPropertyMaxValue = currencyLabel
            + this.$options.filters.formatMoney(this.rulesList[this.isActiveRule].settings.cpa[index][offerPropertyMax]);
        }
      }

      const spanMin = this.$createElement('span', {}, this.$t('affiliates.affiliatesOffers.min', { count: offerPropertyMinValue }));
      const spanSeparator = this.$createElement('span', {}, ' - ');
      const spanMax = this.$createElement('span', {}, this.$t('affiliates.affiliatesOffers.max', { count: offerPropertyMaxValue }));
      const elementsArray = [];

      if (hasMinValue && hasMaxValue) {
        elementsArray.push(spanMin);
        elementsArray.push(spanSeparator);
        elementsArray.push(spanMax);
      } else if (hasMinValue) {
        elementsArray.push(spanMin);
      } else if (hasMaxValue) {
        elementsArray.push(spanMax);
      }

      return this.$createElement('span', {}, [elementsArray]);
    },

    checkUnpin() {
      app.$eventBus.$emit('checkUnpin');
    },

    editOffer(scheduled_hanges) {
      app.$eventBus.$emit('editOffer', scheduled_hanges);
    },
  },
};
</script>

<style lang="scss" scoped>
.line {
  position: relative;
  padding-left: 18px;
  padding-bottom: 4px;

  /deep/ {
    .offer-card__table {
      margin-left: 14px;
    }
    .offer-card__table-row {
      padding-left: 0!important;
    }
  }

  &.root /deep/ .offer-card__table {
    margin-left: 2px;
  }

  .title-blue {
    margin-left: 4px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -10px;
      top: 50%;
      height: 1px;
      width: 10px;
      background-color: #eaeaea;
    }
  }

  &:not(:last-child) {
    /deep/ {
      .offer-card__table {
        &::after {
          content: '';
          position: absolute;
          left: 12px;
          top: 0;
          height: 100%;
          width: 1px;
          background-color: #eaeaea;
        }
      }

      .title-blue::after {
        content: '';
        position: absolute;
        left: -10px;
        top: 0;
        height: 100%;
        width: 1px;
        background-color: #eaeaea;
      }
    }
  }
  &:last-child {
    .title-blue::after {
      content: '';
      position: absolute;
      left: -10px;
      top: 0;
      height: 50%;
      width: 1px;
      background-color: #eaeaea;
    }
  }
}
.copy-links {
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
}
.rules-container {
  gap: 16px;

  .rule {
    width: 100%;
  }
}
.less-or-equal {
  font-weight: 300!important;
}
</style>
