export default {
  state: {
    dataPaymentReport: null,
    currentReportClone: 0,
    reportsClone: [],
    isDragged: false,
    panelSettings: {},
    uuidRender: 0,
    requestCall: [],
  },

  mutations: {
    setRequestCall(state) {
      if (Array.isArray(state.requestCall)) {
        state.requestCall.push('req');
        return;
      }
      state.requestCall = [];
      state.requestCall.push('req');
    },

    deleteRequestCall(state) {
      if (Array.isArray(state.requestCall)) {
        state.requestCall.pop('req');
      }
    },

    uuidRender(state) {
      state.uuidRender = +new Date();
    },

    getSettingsPanel(state, payload) {
      state.panelSettings = payload;
    },

    DATA_PR: (state, payload) => {
      state.dataPaymentReport = payload;
    },

    setCurrentReportClone(state, payload) {
      state.currentReportClone = payload;
    },

    setReportsClone(state, payload) {
      state.reportsClone = payload;
    },

    isDragged(state, payload) {
      state.isDragged = payload;
    },
  },

  action: {},

  getters: {
    disabledRequestStats(state) {
      if (state.requestCall === undefined) {
        return false;
      }
      return state.requestCall.length !== 0;
    },

    getDataPr(state) {
      return state.dataPaymentReport;
    },
  },

  namespaced: true,
};
