<template>
  <div class="tfa-setup" @keyup.enter="qrSetup">
    <div class="header">
      <primary-logo class="logo" />
    </div>
    <div class="wrapper">
      <div class="top">
        <a
          class="back-link"
          @click="signOut"
        >
          <i class="fas fa-chevron-left" />
          {{ $t('tfaSetupView.backLink') }}
        </a>
        <span
          class="title"
          v-html="$t('tfaSetupView.title')"
        />
        <span
          class="step"
          v-html="$t('tfaSetupView.steps[0]')"
        />
        <div class="apps">
          <a
            href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
            class="app-link"
          >
            <img
              src="@/assets/images/apps/app-store-button.png"
              alt="App Store"
              class="app-logo"
            >
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            class="app-link"
          >
            <img
              src="@/assets/images/apps/google-play-button.png"
              alt="Google Play"
              class="app-logo"
            >
          </a>
          <img
            src="@/assets/images/apps/app-icon.png"
            alt="Google Authenticator"
            class="app-logo"
          >
          <span class="gagg">{{ $t('tfaSetupView.google') }}<br>{{
            $t('tfaSetupView.authenticator')
          }}</span>
        </div>
      </div>
      <div class="step-2-wr">
        <img :src="qrSrc" class="qr">
        <div v-loading="isLoading" class="code-form">
          <span class="step" v-html="$t('tfaSetupView.steps[1]')" />
          <CodeInput
            class="tfa-input"
            :field-width="35"
            :field-height="35"
            @complete="confirmCode"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CodeInput from 'vue-verification-code-input';
import { mapGetters } from 'vuex';

import { AUTH_2FA_REQUEST, AUTH_LOGOUT } from '@/store/action_types/auth';

import PrimaryLogo from '@/components/PrimaryLogo.vue';

export default {
  name: 'TfaSetup',
  components: {
    PrimaryLogo,
    CodeInput,
  },

  data() {
    return {
      code: '',
      errors: {},
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      qrSrc: 'auth/qrSrc',
    }),
  },

  methods: {
    async signOut() {
      try {
        await this.$store.dispatch(`auth/${AUTH_LOGOUT}`);
        this.$router.push({
          name: 'login',
          params: {
            action: 'auth',
          },
        });
      } catch (error) {
        this.errors = error.data.errors;
      }
    },

    confirmCode(code) {
      this.code = code;
      this.qrSetup();
    },

    async qrSetup() {
      this.errors = {};

      if (this.code !== '') {
        this.isLoading = true;
        const payload = {
          token: this.code,
          trust: false,
        };
        try {
          await this.$store.dispatch(`auth/${AUTH_2FA_REQUEST}`, payload);
          this.$router.push('/affiliates');
        } catch (error) {
          this.errors = error;
          this.$noty.error(error.message);
        } finally {
          this.isLoading = false;
        }
      } else {
        this.errors = {
          token: [{ code: 1000 }],
        };
        this.$noty.error(this.$t('loginView.codeIsRequired'));
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.qrSrc) {
        next(from);
      }
      next();
    });
  },
};
</script>
