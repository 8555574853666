export default {
  tfaSetupView: {
    backLink: 'Login Page',
    title: 'Two-Factor Authentication Setting',
    steps: [
      '1. Open Google Authenticator and <span>scan QR-code</span>',
      '2. <span>Enter the code</span> from <br> Google Authenticator',
    ],
    codeLabel: 'Code',
    checkBtn: {
      text: 'Check Code',
    },
    google: 'Google',
    authenticator: 'Authenticator',
  },
};
