export default {
  getAffiliatePromoCodes(params) {
    return this.affiliatesApi.get('/promo-codes', { params });
  },
  postAffiliatePromoCodes(params) {
    return this.affiliatesApi.post('/promo-codes', params);
  },
  putAffiliatePromoCodes(id, params) {
    return this.affiliatesApi.put(`/promo-codes/${id}`, params);
  },
  deleteAffiliatePromoCodes(id) {
    return this.affiliatesApi.delete(`/promo-codes/${id}`);
  },
};
