// Teams views
import Teams from '@/views/Teams.vue';
import TeamsList from '@/views/Teams/TeamsList.vue';
import TeamInfo from '@/views/Teams/TeamInfo.vue';
import TeamsUsers from '@/views/Teams/Info/Users.vue';
import TeamsPermissions from '@/views/Teams/Info/Permissions.vue';
import UserCard from '@/views/Teams/UserCard.vue';
import UserInfo from '@/views/Teams/User/UserInfo.vue';

export default [
  {
    path: '/teams',
    name: 'teams',
    component: Teams,
    meta: {
      requiresAuth: true,
      view: 'teams',
    },
    children: [
      {
        path: 'all',
        component: TeamsList,
        name: 'all',
        meta: {
          view: 'teamsList',
        },
        children: [
          {
            path: 'details',
            component: TeamInfo,
            name: 'details',
            meta: {
              view: 'TeamInfo',
            },
            children: [
              {
                path: 'users',
                component: TeamsUsers,
                name: 'all-users',
                meta: {
                  view: 'users',
                },
              },
              {
                path: 'permissions',
                component: TeamsPermissions,
                name: 'all-permissions',
                meta: {
                  view: 'permissions',
                },
              },
            ],
          },
        ],
      },
      {
        path: ':id',
        component: TeamsList,
        name: 'teams-list',
        meta: {
          view: 'teamsList',
        },
        children: [
          {
            path: 'details',
            component: TeamInfo,
            name: 'details',
            meta: {
              view: 'TeamInfo',
            },
            children: [
              {
                path: 'users',
                component: TeamsUsers,
                name: 'users',
                meta: {
                  view: 'users',
                },
              },
              {
                path: 'permissions',
                component: TeamsPermissions,
                name: 'permissions',
                meta: {
                  view: 'permissions',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'user/:id',
        component: UserCard,
        name: ':id',
        children: [
          {
            path: 'info',
            component: UserInfo,
            name: 'user-info',
            meta: {
              view: 'info',
            },
          },
        ],
      },
      {
        path: '',
        redirect: 'all/details/users',
      },
      {
        path: '*',
        redirect: 'all/details/users',
      },
    ],
  },
];
