<template>
  <div class="live-lastBets">
    <div class="content">
      <div class="column left">
        <div class="head">
          <div class="title">
            {{ $t('live.tables.last20bets') }}
          </div>
          <ui-currency-picker
            :value="last20bets.currencyCode"
            :currencies="customerCurrencies"
            @input="changeBetsCurrency"
          />
        </div>
        <div class="table-wrapper">
          <ui-table
            v-loading="last20bets.isDataLoading"
            :lazy-top="-42"
            :lazy-loading-delay="2000"
            :fields="last20bets.fields"
            :data="last20bets.data"
            :currency="last20bets.currencyCode"
            lazy-loading
            disable-sort
            i18n-path="live.list"
            element-loading-custom-class="data-loader-spinner"
          />
        </div>
      </div>
      <div class="column right">
        <div class="head">
          <div class="title title__with-refresh">
            {{ $t('live.tables.last20deposits') }}
            <div class="ui-d-flex">
              <ui-currency-picker
                class="ui-m-md-r"
                :value="last20deposits.currencyCode"
                :currencies="customerCurrencies"
                @input="changeDepositsCurrency"
              />
              <ui-button
                :disabled="dasabledRefresh"
                icon="sync-alt"
                color="green"
                lib="fa"
                class="btn"
                @click="handleRefresh"
              >
                {{ $t('reports.other.refresh') }}
              </ui-button>
            </div>
          </div>
        </div>

        <div class="table-wrapper">
          <ui-table
            v-loading="last20deposits.isDataLoading"
            :lazy-loading-delay="2000"
            :fields="last20deposits.fields"
            :data="last20deposits.data"
            :currency="last20deposits.currencyCode"
            lazy-loading
            disable-sort
            i18n-path="live.list"
            element-loading-custom-class="data-loader-spinner"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import detectPermissions from '@/service/detectPermissions';
import updateUrl from '@/service/updateUrl';
import formatCurrency from '@/views/mixins/format-currency';

const LS_KEY = 'PlayerLast';

export default {
  name: 'PlayerLast',
  mixins: [formatCurrency],
  data() {
    return {
      id: '',
      last20bets: {
        fields: [
          {
            name: 'events',
            align: 'left',
            headerAlign: 'left',
            format: 'event',
          },
          {
            name: 'events_count',
            align: 'left',
            headerAlign: 'left',
            width: '100',
            format: 'eventType',
          },
          {
            name: 'player_country_name',
            align: 'left',
            headerAlign: 'right',
            width: '100',
          },
          {
            name: 'bet_sum',
            align: 'right',
            headerAlign: 'left',
            width: '100',
            class: 'bold',
            format: 'formatMoney',
          },
          {
            name: 'coefficient',
            align: 'right',
            headerAlign: 'left',
            width: '80',
            class: 'bold',
          },
          {
            name: 'timestamp',
            align: 'left',
            headerAlign: 'left',
            width: '120',
            class: 'bold',
            format: 'date-time',
          },
        ],
        data: [],
        currencyCode: '',
        sortProp: '',
        sortOrder: '',
        isDataLoading: false,
      },
      last20deposits: {
        fields: [
          {
            name: 'player_country_name',
            align: 'left',
            headerAlign: 'left',
          },
          {
            name: 'deposit_sum',
            align: 'right',
            headerAlign: 'left',
            width: '100',
            format: 'formatMoney',
          },
          {
            name: 'payment_system_name',
            align: 'left',
            headerAlign: 'left',
            width: '100',
            class: 'method logo',
          },
          {
            name: 'timestamp',
            align: 'left',
            headerAlign: 'left',
            width: '120',
            class: 'bold',
            format: 'date-time',
          },
        ],
        data: [],
        currencyCode: '',
        sortProp: '',
        sortOrder: '',
        isDataLoading: false,
      },
      refresh: true,
      dasabledRefresh: false,
      counterId: '',
      refreshInterval: 30000,
    };
  },

  computed: {
    ...mapGetters({
      customerCurrencies: 'misc/customerCurrencies',
    }),
  },
  watch: {
    'last20bets.currencyCode': {
      handler(val) {
        this.$ls.set(`${LS_KEY}/bets_currency_code`, val);
        this._completedFilterUrl();
      },
    },
    'last20deposits.currencyCode': {
      handler(val) {
        this.$ls.set(`${LS_KEY}/deposits_currency_code`, val);
        this._completedFilterUrl();
      },
    },
    refresh(val) {
      if (val) {
        this.autoRefreshOn();
      } else {
        this.autoRefreshOff();
      }
    },
  },
  created() {
    if (detectPermissions.checkRequest('last_bets_and_deposits_view')) {
      this.id = this.$route.params.id;

      const _urlData = updateUrl.getParseParams(this.$route.hash);

      this.last20bets.currencyCode = this.checkCurrencyCode(this.$_.get(_urlData, ['bets_currency_code'], this.$ls.get(`${LS_KEY}/bets_currency_code`)));
      this.last20deposits.currencyCode = this.checkCurrencyCode(this.$_.get(_urlData, ['deposits_currency_code'], this.$ls.get(`${LS_KEY}/deposits_currency_code`)));

      this.getNewSportBets();
      this.getNewDeposits();
      this.autoRefreshOn();
    }
    detectPermissions.checkRoute('last_bets_and_deposits_view');
  },
  mounted() {
  },
  beforeDestroy() {
    this.autoRefreshOff();
  },
  methods: {
    _query() {
      return {
        bets_currency_code: this.last20bets.currencyCode,
        deposits_currency_code: this.last20deposits.currencyCode,
        param_playerlist: this.$route.query.param_playerlist,
      };
    },

    _completedFilterUrl() {
      const _dataFilters = this._query();
      updateUrl.updateGetParams(_dataFilters);
    },
    changeBetsCurrency(value) {
      this.getNewSportBets(value);
    },
    changeDepositsCurrency(value) {
      this.getNewDeposits(value);
    },
    autoRefreshOn() {
      const counterId = setInterval(() => {
        this.getNewSportBets();
        this.getNewDeposits();
      }, this.refreshInterval);
      this.counterId = counterId;
    },
    autoRefreshOff() {
      clearInterval(this.counterId);
    },
    handleRefresh() {
      this.getNewDeposits();
      this.getNewSportBets();
      this.dasabledRefresh = true;
      setTimeout(() => {
        this.dasabledRefresh = false;
      }, 8000);
    },
    getNewSportBets(currencyCode) {
      this.last20bets.isDataLoading = true;

      if (currencyCode) {
        this.last20bets.currencyCode = currencyCode;
      }

      this.$api
        .getNewSportBets({ player_id: this.id, currency_code: this.last20bets.currencyCode })
        .then((response) => {
          this.last20bets.data = this.$_.cloneDeep(response.data.payload);
          this.last20bets.currencyCode = response.data.misc.currency_code;
        })
        .finally(() => {
          this.last20bets.isDataLoading = false;
        });
    },
    getNewDeposits(currencyCode) {
      this.last20deposits.isDataLoading = true;

      if (currencyCode) {
        this.last20deposits.currencyCode = currencyCode;
      }

      this.$api
        .getNewDeposits({ player_id: this.id, currency_code: this.last20deposits.currencyCode })
        .then((response) => {
          this.last20deposits.data = this.$_.cloneDeep(response.data.payload);
          this.last20deposits.currencyCode = response.data.misc.currency_code;
        })
        .finally(() => {
          this.last20deposits.isDataLoading = false;
        });
    },
  },
};
</script>
