export default {
  validation: {
    errors: {
      // Common
      1: 'Access Denied',
      2: 'Internal Error',
      3: 'Action Is Not Allowed',
      4: 'Two-Factor Authentification Required',
      5: 'Unauthorized',
      6: 'Validation Error',
      7: 'Not Found',
      8: 'Rate Limit Exceeded',
      9: 'Access denied',
      // Validation
      1000: 'Validation Required',
      1001: 'Invalid Value',
      1002: 'Invalid URL Value',
      1003: 'Invalid Boolean Value',
      1004: 'Max Lenght Exceeded',
      1005: 'Invalid Date',
      1006: 'Invalid Phone Number',
      1007: 'Invalid Country Code',
      1008: 'Email Was Already Taken',
      1009: 'Password Is Too Short',
      1010: 'Invalid One-Time Password',
      1011: 'Invalid Token',
      1012: 'Invalid Email',
      1013: 'Invalid Password',
      1014: 'Such Email Was Not Found',
      1015: 'Invalid Reset Password Token',
      1016: 'String Expected',
      1017: 'Promocode Was Already Taken',
      1018: 'Payment Method Already Exists',
      1019: 'Invalid Offer Settings',
      1020: 'Must Be Non-Negative',
      1021: 'Invalid Percent Value',
      1022: 'Array Or Null Expected',
      1023: 'Array Expected',
      1024: 'String Expected',
      1025: 'Malformed Settings Object',
      1026: 'Invalid Characters In Promocode',
      1027: 'Object Expected',
      1038: 'Payment wallet invalid',
      // Auth
      2000: 'Invalid Credentials',
      2001: 'Account Was Blocked',
      2002: 'Account Was Rejected',
      2003: 'Account Is Still Pending For Review',
      2004: 'Invalid Recaptcha Response',
      2005: 'Email Was Not Verified Yet',
      2006: 'Wrong Code',

      // Auth
      AUTH_CREDENTIALS_NOT_PROVIDED: 'Credentials were not provided in the request',
      AUTH_INVALID_CREDENTIALS: 'Provided credentials are invalid',
      AUTH_ACCOUNT_INACTIVE: 'Your account is inactive',
      AUTH_EMAIL_NOT_VERIFIED: 'You should complete email verification',
      // Validation
      AFFILIATE_ACCOUNT_STATUS_IS_NOT_SUITABLE: 'Account status cannot be transformed to new because of initial account status',
      NOT_ALLOWED_AFFILIATE_ADDITIONAL_EMAIL_RESEND_VERIFICATION: 'Email confirmation cannot be sent too frequent',
      AFFILIATE_HAS_APPROVED_PAYMENTS: 'Payment method cannot be updated if affiliate has unpaid payments',
      AFFILIATE_LIMITS_EXCEEDED: 'Affiliate has too many entities and cannot create more',
      AFFILIATE_PAYMENT_METHOD_CANT_BE_UPDATED: 'Affiliate cannot update payment method because balance is greater than safe limit',
      COMMENT_EDIT_BLOCKED: 'Comment cannot be edited or deleted after 1 day from creation',
      DNS_HOSTING_ACCOUNT_HAS_DOMAINS: 'DNS hosting account cannot be delete because there is active domains for this account',
      MEDIA_BUYING_CAMPAIGN_CANNOT_BE_CREATED_IN_INACTIVE_CHANNEL: 'Media buying campaign can be created only in active channel',
      MEDIA_BUYING_CAMPAIGN_NOT_ACTIVE: 'Action cannot be performed because media buying campaign is inactive',
      MEDIA_BUYING_UNABLE_TO_FINISHED_WITH_UNREPORTED_FUNDS: 'Media buying channel or campaign cannot be archived because it has unreported funds on balance',
      MEDIA_BUYING_CHANNEL_NOT_ACTIVE: 'Action cannot be performed because media buying channel is inactive',
      MEDIA_BUYING_PAYMENT_STATUS_IS_NOT_SUITABLE: 'Payment status cannot be transformed to new because of initial payment status',
      MEDIA_BUYING_POST_STATUS_IS_NOT_SUITABLE: 'Post status cannot be transformed to new because of initial post status',
      NOT_ENOUGH_PERMISSIONS: 'Admin doesnot have enough permissions to perform operation',
      OFFER_HAS_SCHEDULES_CHANGES: 'Offer cannot be updated because it has scheduled changes. You should abort them before doing update',
      PAYMENT_CANNOT_BE_ORDERED: 'Payment cannot be ordered because payment method is not set or balance is lower than minimum payment for selected payment method',
      PAYMENT_FILE_CANNOT_BE_ATTACHED: 'Files cannot be attached to this payment',
      PAYMENT_FILE_SHOULD_BE_ATTACHED: 'Files should be attached to this payment',
      PAYMENT_STATUS_IS_NOT_SUITABLE: 'Payment status cannot be transformed to new because of initial payment status',
      PLATFORM_SERVER_HAS_DOMAINS: 'Server cannot be deleted because there is active domains for this server',
      PLAYER_VIRTUAL_CANNOT_BE_ATTACHED: 'Virtual player cannot be attached to affiliate',
      REGISTRAR_ACCOUNT_HAS_DOMAINS: 'Registrar account cannot be deleted because there is active domains for this account',
      ROTATION_GROUP_DEFAULT_EDIT_BLOCKED: 'Default rotation group cannot be edited or deleted',
      SERVICE_DOMAIN_IS_IN_USE: 'Domain cannot be deleted because it is in use by rotation group(s)',
      SERVICE_ROTATION_GROUP_IS_IN_USE: 'Rotation group cannot be deleted because it is in use by affiliate(s)',
      TEAM_OWNERS_CANNOT_BE_CHANGED: 'Team with type "owners" cannot be changed',
      // 2fa
      AUTH_TOTP_EXPIRED: 'Two-factor authentication expired',
      AUTH_TOTP_LOCKED: 'Requested action is locked with 2FA, you need to unlock significant actions for your session',
    },
  },
};
