export default {
  /**
   * Get DNS hosting accounts count
   *
   * @param {Object} params
   * @param {String} [params.search]
   * @param {String} [params.dns_hosting_id]
   * @param {Boolean} [params.auto_dns_management]
   *
   * @return {Promise<Object>} api response
   */
  getDNSHostingAccountsCount(params) {
    return this.affiliatesApi.get('/domains-management/dns-hosting-accounts/count', { params });
  },

  /**
   * Get DNS hosting accounts
   *
   * @param {Object} params
   * @param {String} [params.sort_column]
   * @param {String} [params.sort_dir]
   * @param {String} [params.search]
   * @param {String} [params.dns_hosting_id]
   * @param {Boolean} [params.auto_dns_management]
   * @param {Number} [params.limit]
   * @param {Number} [params.offset]
   *
   * @return {Promise<Object>} api response
   */
  getDNSHostingAccounts(params) {
    return this.affiliatesApi.get('/domains-management/dns-hosting-accounts', { params });
  },

  /**
   * Create DNS hosting account
   *
   * @param {Object} data
   * @param {String} data.name
   * @param {String} data.dns_hosting_id
   * @param {Boolean} data.auto_dns_management
   * @param {Object} data.auth_data
   *
   * @return {Promise<Object>} api response
   */
  createDNSHostingAccount(data) {
    return this.affiliatesApi.post('/domains-management/dns-hosting-accounts', data);
  },

  /**
   * Get DNS hosting account by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  getDNSHostingAccountById(id) {
    return this.affiliatesApi.get(`/domains-management/dns-hosting-accounts/${id}`);
  },

  /**
   * Update DNS hosting account
   *
   * @param {String} id
   * @param {Object} data
   * @param {String} data.name
   * @param {Boolean} data.auto_dns_management
   * @param {Object} data.auth_data
   *
   * @return {Promise<Object>} api response
   */
  updateDNSHostingAccount(id, data) {
    return this.affiliatesApi.put(`/domains-management/dns-hosting-accounts/${id}`, data);
  },

  /**
   * Delete DNS hosting account by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  deleteDNSHostingAccount(id) {
    return this.affiliatesApi.delete(`/domains-management/dns-hosting-accounts/${id}`);
  },
};
