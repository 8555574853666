export default {
  ui: {
    filters: {
      playerFilter: 'Players',
      playerCountryNameFilter: 'Player Country',
      playerRegionNameFilter: 'Player Region',
      trafficRegionNameFilter: 'Traffic Country',
      affiliate: 'Affiliate',
      affiliateLastBets: 'Affiliate',
      affiliateLastDeposits: 'Affiliate',
      affiliatePaymentsReport: 'Affiliate',
      affiliateReport: 'Affiliate',
      pinnedAffiliate: 'Pinned to Affiliate',
      lastActivity: 'Last Activity',
      profitCategory: 'Class',
      deposits: 'Deposits',
      player: 'Player',
      playerReport: 'Player',
      country: 'Country',
      countryPaymentsReport: 'Country',
      playerCountry: 'Player Country',
      playerCountryLastDeposits: 'Player Country',
      playerCountryLastBets: 'Player Country',
      playerCountryPaymentsReport: 'Player Country',
      trafficType: 'Traffic Type',
      contacts: 'Contacts',
      brandLive: 'Brand',
      brand: 'Brand',
      autoDnsManagement: 'DNS Records Management',
      dnsHostingId: 'DNS Hosting Provider',
      autoNsManagement: 'NS Servers Management',
      domainRegistrarId: 'Domain Registrar',
      dnsManagement: 'DNS Records Management',
      nsManagement: 'NS Records Management',
      sslIssuance: 'SSL Issuance',
      platformServiceOrigin: 'Service Type',
      platformServer: 'Server',
      domainRegistrarAccount: 'Domain Registrar Account',
      dnsHostingAccount: 'DNS Hosting Account',
      marketingMaterialType: 'Material Type',
      marketingMaterialStatus: 'Material Status',
      availabilityMaterials: 'Availability',
      languageCode: 'Language',
      blockedInAnyCountry: 'Blocked in any country',
      usedInAnyGroup: 'Used in any group',
      inner: {
        filter: 'Filter',
        days: 'Days',
        apply_filter: 'Apply Filters',
        reset_filter: 'Reset Filters',
        cancel: 'Cancel',
      },
      select: {
        only_content: 'Only With Contacts',
        all: 'All',
        custom: 'Custom',
        without: 'Without',
        only_first_dep: 'Only first deposits',
        more_one: 'More than one',
        only_with_dep: 'Only with deposits',
        no: 'No',
        yes: 'Yes',
        disabled: 'Disabled',
        enabled: 'Enabled',
        partners: 'Partners',
        direct: 'Direct',
        and_more: '- 100% and more',
        auto: 'Auto',
        manual: 'Manual',
        html5_banner: 'HTML5 Banner',
        simple_banner: 'Simple Banner',
        landing: 'Landing',
        active: 'Active',
        inactive: 'Inactive',
        public: 'Public',
        private: 'Private',
        hidden: 'Hidden',
      },
    },
    buttons: {
      add: 'Add',
      delete: 'Delete',
    },
    input: {
      search: 'Search ...',
    },
    select: {
      no_match: 'No matching options',
      no_options: 'No options',
      default: 'Select item',
      filter_option: 'Filter options',
    },
    table: {
      no_data: 'No Data',
      total: 'Total',
      value: 'Value',
      reset: 'Reset',
      apply: 'Apply',
      from: 'From',
      to: 'To',
      exclude: 'Exclude Selected Segment',
      exclude2: 'Exclude Boundary Numbers',
    },
    shortcuts: {
      relative_time_ranges: 'Relative Time Ranges',
      other_quick_ranges: 'Other Quick Ranges',
      last_24_hours: 'Last 24 hours',
      last_2_days: 'Last 2 days',
      last_7_days: 'Last 7 days',
      last_30_days: 'Last 30 days',
      last_90_days: 'Last 90 days',
      last_6_months: 'Last 6 months',
      last_1_year: 'Last 1 year',
      today: 'Today',
      today_so_far: 'Today so far',
      yesterday: 'Yesterday',
      day_before_yesterday: 'Day before yesterday',
      this_day_last_week: 'This day last week',
      this_week: 'This week',
      this_week_so_far: 'This week so far',
      previous_week: 'Previous week',
      previous_month: 'Previous month',
      previous_year: 'Previous year',
      this_month: 'This month',
      this_month_so_far: 'This month so far',
      this_year: 'This year',
      this_year_so_far: 'This year so far',
    },
  },
};
