import _ from 'lodash';
import store from '@/store';
import detectPermissions from '../../service/detectPermissions';

import {
  MISC_INIT_DATA,
  MISC_INIT_COUNTERS,
  MISC_SET_AFFILIATES_REVIEWS_COUNT,
  MISC_FETCH_MESSENGERS,
  MISC_FETCH_BRANDS,
  MISC_FETCH_TIMEZONES,
  MISC_FETCH_COUNTRIES,
  MISC_FETCH_REGIONS,
  MISC_FETCH_PAYMENTS_SYSTEMS,
  MISC_FETCH_SPORT_TYPE,
  MISC_FETCH_SPORT_LEAGUE,
  MISC_FETCH_LANGUAGES,
  MISC_FETCH_NOTE_TAGS,
  MISC_FETCH_PAYMENT_METHODS,
  MISC_SET_MEDIA_BUYING_POSTS_REVIEW_COUNT,
  MISC_SET_MEDIA_BUYING_POSTS_REWORK_COUNT,
  MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REVIEW_COUNT,
  MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REWORK_COUNT,
  MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_NOT_PAID_COUNT,
  MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_REWORK_COUNT,
  MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_PROCESSING_COUNT,
  MISC_SET_PAYMENTS_REVIEWS_COUNT,
  MISC_SET_PAYMENTS_NOT_PAID_COUNT,
  MISC_CONNECTION_ENABLED,
  MISC_CONNECTION_DISABLED,
  MISC_FETCH_DNS_HOSTING_PROVIDERS,
  MISC_FETCH_DOMAIN_REGISTRAR_PROVIDERS,
  MISC_GET_POST_PLATFORM,
  MISC_SET_PAYMENTS_FOR_REWORK_COUNT,
  MISC_GET_CURRENCIES,
  MISC_GET_CUSTOMER_CURRENCIES,
  MISC_GET_CUSTOMER_LANGUAGES,
} from '../action_types/misc';


const getters = {
  affiliatesTotal: state => state.notifications.affiliates.requests
    + state.notifications.affiliates.reviews,
  affiliatesRequests: state => state.notifications.affiliates.requests,
  affiliatesReviews: state => state.notifications.affiliates.reviews,
  mediaBuyingPostsReviews: state => state.notifications.mediaBuying.posts.review,
  mediaBuyingPostsRework: state => state.notifications.mediaBuying.posts.rework,
  mediaBuyingPaymentsModerationReviews: state => state.notifications.mediaBuying.payments.moderation.review,
  mediaBuyingPaymentsModerationRework: state => state.notifications.mediaBuying.payments.moderation.rework,
  mediaBuyingPaymentsPayoutNotPaid: state => state.notifications.mediaBuying.payments.payout.not_paid,
  mediaBuyingPaymentsPayoutRework: state => state.notifications.mediaBuying.payments.payout.rework,
  mediaBuyingPaymentsPayoutProcessing: state => state.notifications.mediaBuying.payments.payout.processing,
  paymentsReviews: state => state.notifications.payments.reviews,
  paymentsNotPaid: state => state.notifications.payments.notPaid,
  paymentsForRework: state => state.notifications.payments.forRework,
  brands: state => state.brands,
  apiMessengers: state => state.apiMessengers,
  timezones: state => state.timezones,
  countries: state => state.countries,
  regions: state => state.regions,
  paymentsSystems: state => state.paymentsSystems,
  sportType: state => state.sportType,
  sportLeague: state => state.sportLeague,
  noteTags: state => state.noteTags,
  groups: state => state.groups,
  languages: state => state.languages,
  paymentMethods: state => state.paymentMethods,
  paymentPeriods: state => state.paymentPeriods,
  messengers: state => state.messengers,
  countersEnabled: state => state.countersEnabled,
  connectionEnabled: state => state.connectionEnabled,
  dnsHostingProviders: state => state.dnsHostingProviders,
  domainRegistrarProviders: state => state.domainRegistrarProviders,
  postPlatform: state => state.postPlatform,
  currencies: state => state.currencies,
  customerCurrencies: state => state.customerCurrencies,
  activeCustomerCurrency: (state) => {
    if (state.customerCurrencies.length) {
      if (state.customerCurrencies[0].is_default) {
        return state.customerCurrencies[0];
      }

      return state.customerCurrencies.find(el => el.is_default) || {};
    }

    return {};
  },
  customerLanguages: state => state.customerLanguages,
};

const actions = {
  [MISC_INIT_DATA]: ({ dispatch }) => Promise.all([
    dispatch(MISC_FETCH_MESSENGERS),
    dispatch(MISC_FETCH_TIMEZONES),
    dispatch(MISC_FETCH_COUNTRIES),
    dispatch(MISC_FETCH_LANGUAGES),
    dispatch(MISC_FETCH_NOTE_TAGS),
    dispatch(MISC_FETCH_PAYMENT_METHODS),
    dispatch(MISC_FETCH_DNS_HOSTING_PROVIDERS),
    dispatch(MISC_FETCH_DOMAIN_REGISTRAR_PROVIDERS),
    dispatch(MISC_GET_POST_PLATFORM),
    dispatch(MISC_GET_CURRENCIES),
    dispatch(MISC_GET_CUSTOMER_CURRENCIES),
    dispatch(MISC_GET_CUSTOMER_LANGUAGES),
  ]),
  [MISC_INIT_COUNTERS]: ({ dispatch, commit }, flag = true) => {
    commit(MISC_INIT_COUNTERS);
    dispatch(MISC_SET_AFFILIATES_REVIEWS_COUNT);
    dispatch(MISC_SET_MEDIA_BUYING_POSTS_REVIEW_COUNT);
    dispatch(MISC_SET_MEDIA_BUYING_POSTS_REWORK_COUNT);
    dispatch(MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REVIEW_COUNT);
    dispatch(MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REWORK_COUNT);
    dispatch(MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_NOT_PAID_COUNT);
    dispatch(MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_REWORK_COUNT);
    dispatch(MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_PROCESSING_COUNT);
    dispatch(MISC_SET_PAYMENTS_REVIEWS_COUNT);
    dispatch(MISC_SET_PAYMENTS_NOT_PAID_COUNT);
    dispatch(MISC_SET_PAYMENTS_FOR_REWORK_COUNT);
    if (flag) {
      setInterval(() => {
        if (state.connectionEnabled && store.getters['auth/isAuthenticated']) {
          dispatch(MISC_SET_AFFILIATES_REVIEWS_COUNT);
          dispatch(MISC_SET_MEDIA_BUYING_POSTS_REVIEW_COUNT);
          dispatch(MISC_SET_MEDIA_BUYING_POSTS_REWORK_COUNT);
          dispatch(MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REVIEW_COUNT);
          dispatch(MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REWORK_COUNT);
          dispatch(MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_NOT_PAID_COUNT);
          dispatch(MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_REWORK_COUNT);
          dispatch(MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_PROCESSING_COUNT);
          dispatch(MISC_SET_PAYMENTS_REVIEWS_COUNT);
          dispatch(MISC_SET_PAYMENTS_NOT_PAID_COUNT);
          dispatch(MISC_SET_PAYMENTS_FOR_REWORK_COUNT);
        }
      }, 60000);
    }
  },

  // Affiliates counters
  [MISC_SET_AFFILIATES_REVIEWS_COUNT]: ({ commit }) => {
    if (detectPermissions.checkRequest('affiliates_view')) {
      store.$api.getAffiliatesCount({ account_status: 'pending' })
        .then((response) => {
          commit(MISC_SET_AFFILIATES_REVIEWS_COUNT, response.data.payload.count);
        });
    }
  },

  // Media buying posts counters
  [MISC_SET_MEDIA_BUYING_POSTS_REVIEW_COUNT]: ({ commit }) => {
    if (detectPermissions.checkRequest('media_buying_posts_view', 'media_buying_posts_statuses_visible', 'on_review')) {
      store.$api.getMediaBuyingPostsCount({ status: 'on_review' })
        .then((response) => {
          commit(MISC_SET_MEDIA_BUYING_POSTS_REVIEW_COUNT, response.data.payload.count);
        });
    }
  },
  [MISC_SET_MEDIA_BUYING_POSTS_REWORK_COUNT]: ({ commit }) => {
    if (detectPermissions.checkRequest('media_buying_posts_view', 'media_buying_posts_statuses_visible', 'for_rework')) {
      store.$api.getMediaBuyingPostsCount({ status: 'for_rework' })
        .then((response) => {
          commit(MISC_SET_MEDIA_BUYING_POSTS_REWORK_COUNT, response.data.payload.count);
        });
    }
  },
  // Media buying payments moderations counters
  [MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REVIEW_COUNT]: ({ commit }) => {
    if (detectPermissions.checkRequest('media_buying_payments_view', 'media_buying_payments_moderation', 'on_review')) {
      store.$api.getMediaBuyingPaymentsCount({ moderation_status: 'on_review' })
        .then((response) => {
          commit(MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REVIEW_COUNT, response.data.payload.count);
        });
    }
  },
  [MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REWORK_COUNT]: ({ commit }) => {
    if (detectPermissions.checkRequest('media_buying_payments_view', 'media_buying_payments_moderation', 'for_rework')) {
      store.$api.getMediaBuyingPaymentsCount({ moderation_status: 'for_rework' })
        .then((response) => {
          commit(MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REWORK_COUNT, response.data.payload.count);
        });
    }
  },
  // Media buying payments payouts counters
  [MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_NOT_PAID_COUNT]: ({ commit }) => {
    if (detectPermissions.checkRequest('media_buying_payments_view', 'media_buying_payments_payout', 'not_paid')) {
      store.$api.getMediaBuyingPaymentsCount({ moderation_status: 'approved', payout_status: 'not_paid' })
        .then((response) => {
          commit(MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_NOT_PAID_COUNT, response.data.payload.count);
        });
    }
  },
  [MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_REWORK_COUNT]: ({ commit }) => {
    if (detectPermissions.checkRequest('media_buying_payments_view', 'media_buying_payments_payout', 'for_rework')) {
      store.$api.getMediaBuyingPaymentsCount({ moderation_status: 'approved', payout_status: 'for_rework' })
        .then((response) => {
          commit(MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_REWORK_COUNT, response.data.payload.count);
        });
    }
  },
  [MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_PROCESSING_COUNT]: ({ commit }) => {
    if (detectPermissions.checkRequest('media_buying_payments_view', 'media_buying_payments_payout', 'processing')) {
      store.$api.getMediaBuyingPaymentsCount({ moderation_status: 'approved', payout_status: 'processing' })
        .then((response) => {
          commit(MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_PROCESSING_COUNT, response.data.payload.count);
        });
    }
  },

  // Payments counters
  [MISC_SET_PAYMENTS_REVIEWS_COUNT]: ({ commit }) => {
    if (detectPermissions.checkRequest('payments_view')) {
      store.$api.getPaymentsHistoryCount({ moderation_status: 'on_review' })
        .then((response) => {
          commit(MISC_SET_PAYMENTS_REVIEWS_COUNT, response.data.payload.count);
        });
    }
  },
  [MISC_SET_PAYMENTS_NOT_PAID_COUNT]: ({ commit }) => {
    if (detectPermissions.checkRequest('payments_view')) {
      store.$api.getPaymentsHistoryCount({ moderation_status: 'approved', payout_status: 'not_paid' })
        .then((response) => {
          commit(MISC_SET_PAYMENTS_NOT_PAID_COUNT, response.data.payload.count);
        });
    }
  },
  [MISC_SET_PAYMENTS_FOR_REWORK_COUNT]: ({ commit }) => {
    if (detectPermissions.checkRequest('payments_view')) {
      store.$api.getPaymentsHistoryCount({ moderation_status: 'approved', payout_status: 'for_rework' })
        .then((response) => {
          commit(MISC_SET_PAYMENTS_FOR_REWORK_COUNT, response.data.payload.count);
        });
    }
  },

  [MISC_FETCH_MESSENGERS]: ({ commit }) => {
    store.$api.getMessengers()
      .then((response) => {
        commit(MISC_FETCH_MESSENGERS, response.data.payload);
      });
  },

  [MISC_FETCH_BRANDS]: async ({ commit }) => {
    if (store.getters['auth/adminAcl'].is_superuser
    || store.getters['auth/adminAcl'].teams.length) {
      const response = await store.$api.getSiteSettings();
      commit(MISC_FETCH_BRANDS, response.data.payload);
    }
  },

  [MISC_FETCH_TIMEZONES]: ({ commit }) => {
    store.$api.getTimezones()
      .then((response) => {
        commit(MISC_FETCH_TIMEZONES, response.data.payload);
      });
  },

  [MISC_FETCH_COUNTRIES]: ({ commit }) => {
    store.$api.getCountries()
      .then((response) => {
        commit(MISC_FETCH_COUNTRIES, response.data.payload);
      });
  },

  [MISC_FETCH_REGIONS]: async ({ commit }) => {
    const { data } = await store.$api.getRegions();
    commit(MISC_FETCH_REGIONS, data.payload);
  },
  [MISC_FETCH_PAYMENTS_SYSTEMS]: async ({ commit }) => {
    const { data } = await store.$api.getPaymentSystems();
    commit(MISC_FETCH_PAYMENTS_SYSTEMS, data.payload.map(obj => _.mapKeys(obj, (value, key) => (key === 'id' ? 'code' : key))));
  },
  [MISC_FETCH_SPORT_TYPE]: async ({ commit }, payload) => {
    const { data } = await store.$api.getSportCategories({ site_id: payload });
    commit(MISC_FETCH_SPORT_TYPE, data.payload.map(obj => _.mapKeys(obj, (value, key) => (key === 'id' ? 'code' : key))));
  },
  [MISC_FETCH_SPORT_LEAGUE]: async ({ commit }, payload) => {
    const { data } = await store.$api.getSportLeagues({ site_id: payload });
    commit(MISC_FETCH_SPORT_LEAGUE, data.payload.map(obj => _.mapKeys(obj, (value, key) => (key === 'id' ? 'code' : key))));
  },

  [MISC_FETCH_LANGUAGES]: async ({ commit }) => {
    const { data } = await store.$api.getLanguages();
    commit(MISC_FETCH_LANGUAGES, data.payload);
  },

  [MISC_FETCH_NOTE_TAGS]: ({ commit }) => {
    store.$api.getNoteTags()
      .then((response) => {
        commit(MISC_FETCH_NOTE_TAGS, response.data.payload);
      });
  },
  [MISC_FETCH_PAYMENT_METHODS]: ({ commit }) => {
    if (localStorage.getItem('active-team') || (!localStorage.getItem('active-team') && store.getters['auth/adminAcl'].is_superuser)) {
      store.$api.getPaymentMethods()
        .then((response) => {
          commit(MISC_FETCH_PAYMENT_METHODS, response.data.payload);
        });
    }
  },

  [MISC_FETCH_DNS_HOSTING_PROVIDERS]: async ({ commit }) => {
    if (store.getters['auth/adminAcl'].is_superuser || store.getters['auth/currentAcl'].dns_hosting_accounts_view === 'allow') {
      const { data } = await store.$api.getDNSHostingProviders();
      commit(MISC_FETCH_DNS_HOSTING_PROVIDERS, data.payload);
    }
  },

  [MISC_FETCH_DOMAIN_REGISTRAR_PROVIDERS]: async ({ commit }) => {
    if (store.getters['auth/adminAcl'].is_superuser || store.getters['auth/currentAcl'].domain_registrar_accounts_view === 'allow') {
      const { data } = await store.$api.getDomainRegistrarProviders();
      commit(MISC_FETCH_DOMAIN_REGISTRAR_PROVIDERS, data.payload);
    }
  },

  [MISC_GET_POST_PLATFORM]: async ({ commit }) => {
    const { data } = await store.$api.getPostPlatform();
    commit(MISC_GET_POST_PLATFORM, data.payload);
  },
  [MISC_GET_CURRENCIES]: async ({ commit }) => {
    const { data } = await store.$api.getCurrencies();
    commit(MISC_GET_CURRENCIES, data.payload);
  },
  [MISC_GET_CUSTOMER_CURRENCIES]: async ({ commit }) => {
    const { data } = await store.$api.getMiscCustomerCurrencies();
    commit(MISC_GET_CUSTOMER_CURRENCIES, data.payload);
  },
  [MISC_GET_CUSTOMER_LANGUAGES]: async ({ commit, rootGetters }) => {
    const { data } = await store.$api.getLanguages({ code: rootGetters['reports/supportedLanguages'] });
    commit(MISC_GET_CUSTOMER_LANGUAGES, data.payload);
  },
};

const mutations = {
  [MISC_CONNECTION_ENABLED]: (state) => {
    state.connectionEnabled = true;
  },
  [MISC_CONNECTION_DISABLED]: (state) => {
    state.connectionEnabled = false;
  },
  [MISC_INIT_COUNTERS]: (state) => {
    state.countersEnabled = true;
  },
  [MISC_SET_AFFILIATES_REVIEWS_COUNT]: (state, payload) => {
    state.notifications.affiliates.reviews = payload;
  },
  [MISC_SET_MEDIA_BUYING_POSTS_REVIEW_COUNT]: (state, payload) => {
    state.notifications.mediaBuying.posts.review = payload;
  },
  [MISC_SET_MEDIA_BUYING_POSTS_REWORK_COUNT]: (state, payload) => {
    state.notifications.mediaBuying.posts.rework = payload;
  },
  [MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REVIEW_COUNT]: (state, payload) => {
    state.notifications.mediaBuying.payments.moderation.review = payload;
  },
  [MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REWORK_COUNT]: (state, payload) => {
    state.notifications.mediaBuying.payments.moderation.rework = payload;
  },
  [MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_NOT_PAID_COUNT]: (state, payload) => {
    state.notifications.mediaBuying.payments.payout.not_paid = payload;
  },
  [MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_REWORK_COUNT]: (state, payload) => {
    state.notifications.mediaBuying.payments.payout.rework = payload;
  },
  [MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_PROCESSING_COUNT]: (state, payload) => {
    state.notifications.mediaBuying.payments.payout.processing = payload;
  },
  [MISC_SET_PAYMENTS_REVIEWS_COUNT]: (state, payload) => {
    state.notifications.payments.reviews = payload;
  },
  [MISC_SET_PAYMENTS_NOT_PAID_COUNT]: (state, payload) => {
    state.notifications.payments.notPaid = payload;
  },
  [MISC_SET_PAYMENTS_FOR_REWORK_COUNT]: (state, payload) => {
    state.notifications.payments.forRework = payload;
  },
  [MISC_FETCH_MESSENGERS]: (state, payload) => {
    state.apiMessengers = _.cloneDeep(payload);
  },
  [MISC_FETCH_BRANDS]: (state, payload) => {
    state.brands = _.cloneDeep(payload);
  },
  [MISC_FETCH_TIMEZONES]: (state, payload) => {
    state.timezones = _.cloneDeep(payload);
  },
  [MISC_FETCH_COUNTRIES]: (state, payload) => {
    state.countries = _.cloneDeep(payload);
  },
  [MISC_FETCH_REGIONS]: (state, payload) => {
    state.regions = _.cloneDeep(payload);
  },
  [MISC_FETCH_PAYMENTS_SYSTEMS]: (state, payload) => {
    state.paymentsSystems = _.cloneDeep(payload);
  },
  [MISC_FETCH_SPORT_TYPE]: (state, payload) => {
    state.sportType = _.cloneDeep(payload);
  },
  [MISC_FETCH_SPORT_LEAGUE]: (state, payload) => {
    state.sportLeague = _.cloneDeep(payload);
  },
  [MISC_FETCH_NOTE_TAGS]: (state, payload) => {
    state.noteTags = _.cloneDeep(payload);
  },
  [MISC_FETCH_PAYMENT_METHODS]: (state, payload) => {
    state.paymentMethods = _.cloneDeep(payload);
  },

  [MISC_FETCH_DNS_HOSTING_PROVIDERS]: (state, payload) => {
    state.dnsHostingProviders = payload;
  },

  [MISC_FETCH_DOMAIN_REGISTRAR_PROVIDERS]: (state, payload) => {
    state.domainRegistrarProviders = payload;
  },

  [MISC_FETCH_LANGUAGES]: (state, payload) => {
    state.languages = payload;
  },

  [MISC_GET_POST_PLATFORM]: (state, payload) => {
    state.postPlatform = payload;
  },
  [MISC_GET_CURRENCIES]: (state, payload) => {
    state.currencies = payload;
  },
  [MISC_GET_CUSTOMER_CURRENCIES]: (state, payload) => {
    state.customerCurrencies = payload;
  },
  [MISC_GET_CUSTOMER_LANGUAGES]: (state, payload) => {
    state.customerLanguages = payload;
  },
};

const state = {
  countersEnabled: Boolean,
  connectionEnabled: Boolean,
  currencies: [],
  customerCurrencies: [],
  notifications: {
    affiliates: {
      reviews: 0,
      requests: 0,
    },
    payments: {
      reviews: 0,
      notPaid: 0,
      forRework: 0,
    },
    mediaBuying: {
      posts: {
        review: 0,
        rework: 0,
      },
      payments: {
        moderation: {
          review: 0,
          rework: 0,
        },
        payout: {
          not_paid: 0,
          rework: 0,
          processing: 0,
        },
      },
    },
  },

  apiMessengers: [],
  brands: [],
  timezones: [],
  countries: [],
  regions: [],
  paymentsSystems: [],
  sportType: [],
  sportLeague: [],
  languages: [],
  noteTags: [],
  groups: [],
  paymentMethods: [],
  dnsHostingProviders: [],
  domainRegistrarProviders: [],
  postPlatform: [],
  paymentPeriods: [
    'manual',
    'net7',
    'net15',
    'net30',
    'net60',
  ],
  messengers: [
    'skype',
    'telegram',
    'viber',
    'whatsapp',
    'facebook',
    'instagram',
    'twitter',
  ],
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
