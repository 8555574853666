import { helpers } from 'vuelidate/lib/validators';

const v6seg = '[a-fA-F\\d]{1,4}';
const v4 = '(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}';
const v6 = `(
(?:${v6seg}:){7}(?:${v6seg}|:)|
(?:${v6seg}:){6}(?:${v4}|:${v6seg}|:)|
(?:${v6seg}:){5}(?::${v4}|(:${v6seg}){1,2}|:)|
(?:${v6seg}:){4}(?:(:${v6seg}){0,1}:${v4}|(:${v6seg}){1,3}|:)|
(?:${v6seg}:){3}(?:(:${v6seg}){0,2}:${v4}|(:${v6seg}){1,4}|:)|
(?:${v6seg}:){2}(?:(:${v6seg}){0,3}:${v4}|(:${v6seg}){1,5}|:)|
(?:${v6seg}:){1}(?:(:${v6seg}){0,4}:${v4}|(:${v6seg}){1,6}|:)|
(?::((?::${v6seg}){0,5}:${v4}|(?::${v6seg}){1,7}|:))
)(%[0-9a-zA-Z]{1,})?`
  .replace(/\s*\/\/.*$/gm, '')
  .replace(/\n/g, '')
  .trim();


/**
 * Validate IPv6 address
 *
 * @param {String} value Example: 1200:0000:AB00:1234:0000:2552:7777:1313
 *
 * @returns {Boolean}
 */
export const ipv6Address = value => !helpers.req(value) || new RegExp(`^${v6}$`).test(value);

/**
 * Validate IPv6 addresses newline divided
 *
 * @param {String} valuesString Example: 21DA:D3:0:2F3B:2AA:FF:FE28:9C5A\nFE80:0000:0000:0000:0202:B3FF:FE1E:8329
 *
 * @returns {Boolean}
 */
export const ipv6Addresses = (valuesString) => {
  let passed = true;
  const values = valuesString.trim() ? valuesString.trim().split('\n') : [];

  values.forEach((value) => {
    if (!new RegExp(`^${v6}$`).test(value.trim())) {
      passed = false;
    }
  });

  return !helpers.req(valuesString) || passed;
};

/**
 * Validate IPv4 addresses newline divided
 *
 * @param {String} valuesString Example: 11.22.33.44\n33.44.55.66
 *
 * @returns {Boolean}
 */
export const ipv4Addresses = (valuesString) => {
  let passed = true;
  const values = valuesString.trim() ? valuesString.trim().split('\n') : [];

  values.forEach((value) => {
    if (!new RegExp(`^${v4}$`).test(value.trim())) {
      passed = false;
    }
  });

  return !helpers.req(valuesString) || passed;
};

export const isDomain = value => /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9а-яА-я][a-zA-Z0-9а-яА-я-_]+\.[a-zA-Zа-яА-я]{2,11}?$/.test(value);

export const nsServers = (valuesString) => {
  let passed = true;
  const values = valuesString.trim() ? valuesString.trim().split('\n') : [];

  values.forEach((value) => {
    if (!new RegExp(`^${v4}$`).test(value.trim()) && !new RegExp(`^${v6}$`).test(value.trim()) && !isDomain(value.trim())) {
      passed = false;
    }
  });

  return !helpers.req(valuesString) || passed;
};

// eslint-disable-next-line no-useless-escape
export const isLandingPath = (landingPath) => {
  if (!helpers.req(landingPath)) {
    return true;
  }

  const regEx = /([a-z-0-9]+)/;
  if (typeof landingPath !== 'string' || landingPath.length === 0 || !regEx.test(landingPath)) {
    return false;
  }

  const matches = landingPath.match(regEx);
  if (!matches || !matches[1]) {
    return false;
  }

  return matches[1] === landingPath;
};

export default {
  ipv6Address, ipv6Addresses, ipv4Addresses, nsServers, isLandingPath,
};
