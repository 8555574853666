import Vue from 'vue';
import Vuex from 'vuex';

// module
import auth from './modules/auth';
import i18n from './modules/i18n';
import nav from './modules/nav';
import misc from './modules/misc';
import siteSettings from './modules/site-settings/index';
import other from './modules/other';
import dashboards from './modules/dashboards';
import reports from './modules/reports';
import session from './modules/session';
import crm from './modules/crm';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

const StoreInstance = new Vuex.Store({
  modules: {
    auth,
    i18n,
    nav,
    misc,
    siteSettings,
    other,
    dashboards,
    reports,
    session,
    crm,
  },
  strict: debug,
});

export default StoreInstance;
