<template>
  <div class="ui-filter popover-filter dashboard-global-filter">
    <ui-button
      ref="mainBtn"
      :filled="!!filtersOpen || !$_.isEmpty(currentFilters) || !$_.isEmpty(currentSearch)"
      :disabled="disabled"
      icon="filter"
      lib="fa"
      substyle="fas"
      color="green"
      class="main-btn"
      @click="toggleFilters"
    >
      {{ $t('ui.filters.inner.filter') }}
    </ui-button>
    <transition name="fade">
      <div
        v-show="filtersOpen"
        ref="pop"
        class="pop"
      >
        <div class="arrow" />
        <div
          v-for="(item, index) in currentFilters"
          :key="index"
          class="filter-row"
        >
          <ui-button
            :icon-size="12"
            color="red"
            lib="fa"
            substyle="fas"
            icon="minus"
            class="btn"
            :disabled="disabledMode"
            @click="removeFilter(index)"
          />
          <ui-select
            v-model="item.type"
            :options="availableFilters"
            :exclude-options="excludeFilters"
            :disabled="disabledMode"
            :label-i18n="'ui.filters'"
            value-prop="type"
            label-prop="type"
            class="filter_type"
            @select="item.values = getFilter(item.type).multiple ? [] : ''"
            @input="inputSelect($event, index)"
          />
          <el-radio-group
            v-if="item.op !== null"
            v-model="item.op"
            :disabled="disabledMode"
            :class="{'disabled': disabledMode}"
            class="radio-operator"
            size="small"
          >
            <el-radio-button label="=">
              =
            </el-radio-button>
            <el-radio-button label="!=">
              ≠
            </el-radio-button>
          </el-radio-group>
          <ui-select
            v-if="item.type !== 'lastActivity' && item.type !== 'profitCategory'"
            v-model="item.values"
            :options="getFilter(item.type).options"
            :value-prop="getFilter(item.type).valueProp"
            :label-prop="getFilter(item.type).labelProp"
            :remote="getFilter(item.type).remote"
            :filter-method="getFilter(item.type).method"
            :disabled="!item.type || disabledMode"
            :multiple="getFilter(item.type).multiple"
            :filterable="getFilter(item.type).filterable"
            class="filter_type"
            :remote-auto="false"
          />

          <div
            v-if="item.type === 'lastActivity'"
            class="f-day"
          >
            <div class="f-day__tabs">
              <div
                :class="{'f-day__tab--active': item.values[1] === 'from'}"
                class="f-day__tab"
                @click="item.values[1] = 'from'; clone()"
              >
                &lt;
              </div>
              <div
                :class="{'f-day__tab--active': item.values[1] === 'to'}"
                class="f-day__tab "
                @click="item.values[1] = 'to'; clone()"
              >
                &gt;
              </div>
            </div>
            <ui-input
              v-model="item.values[0]"
              placeholder=""
              class="f-day__input"
            />
            <span class="f-day__text">{{ $t('ui.filters.inner.days') }}</span>
          </div>
          <div
            v-if="item.type === 'profitCategory'"
            class="f-day"
          >
            <div class="f-day__tabs">
              <div
                :class="{'f-day__tab--active': allFilters.profitCategory.field === 'max_profit_category'}"
                class="f-day__tab"
                @click="allFilters.profitCategory.field = 'max_profit_category'; clone()"
              >
                &le;
              </div>
              <div
                :class="{'f-day__tab--active': allFilters.profitCategory.field === 'min_profit_category'}"
                class="f-day__tab "
                @click="allFilters.profitCategory.field = 'min_profit_category'; clone()"
              >
                &ge;
              </div>
            </div>
            <ui-select
              v-if="item.type === 'profitCategory'"
              v-model="item.values"
              :options="getFilter(item.type).options"
              :value-prop="getFilter(item.type).valueProp"
              :label-prop="getFilter(item.type).labelProp"
              :sufix-prop="getFilter(item.type).sufixProp"
              :remote="getFilter(item.type).remote"
              :filter-method="getFilter(item.type).method()"
              :disabled="!item.type || disabledMode"
              :multiple="getFilter(item.type).multiple"
              :filterable="getFilter(item.type).filterable"
              class="select-class"
              sufix
            />
          </div>
        </div>
        <div v-if="!disabledMode" class="dashboard-global-filter__add-filter-wrap">
          <ui-button
            :disabled="availableFilters.length === currentFilters.length"
            icon="plus"
            lib="fa"
            substyle="fas"
            color="green"
            class="btn ui-filter-select__add-btn"
            @click="addFilter"
          >
            {{ $t('affiliates.offer_popup.add_filter') }}
          </ui-button>
        </div>
        <filter-tabs
          :filter-list="defaultFilterList"
          :current-search="currentSearchClone"
          :reports-settings="reportsSettings"
          :search-by-list="searchByList"
          :is-searching-filter="isSearchingFilter"
          :disabled="disabledMode"
          @deleteCurrentSearchGroup="clearReportsSearchFilter"
          @selectedSearchFilter="selectedSearchFilter"
        />

        <div v-if="!disabledMode" class="controls">
          <div>
            <ui-button
              color="red"
              lib="fa"
              substyle="fas"
              icon="times"
              class="btn ui-filter-select__add-btn ui-filter-select__add-btn--danger"
              @click="resetFilters"
            >
              {{ $t('ui.filters.inner.reset_filter') }}
            </ui-button>
          </div>

          <div>
            <ui-button
              color="red"
              lib="fa"
              substyle="fas"
              icon="times"
              class="btn"
              @click="toggleFilters"
            >
              {{ $t('ui.filters.inner.cancel') }}
            </ui-button>
            <ui-button
              :disabled="isDisabledApply"
              filled
              lib="fa"
              substyle="fas"
              color="green"
              icon="check"
              class="btn"
              @click="applyFilters"
            >
              {{ $t('ui.filters.inner.apply_filter') }}
            </ui-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import updateUrl from '@/service/updateUrl';
import FilterTabs from '@/components/FilterTabs.vue';

export default {
  name: 'DasboardGlobalFilter',
  components: {
    FilterTabs,
  },

  props: {
    setCurrentSearch: {
      type: Object,
      default() {
        return {};
      },
    },
    disabledMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    useFilters: {
      type: Array,
      default() {
        return [
          'affiliate',
          'affiliateReport',
          'player',
          'playerReport',
          'country',
          'playerCountry',
          'trafficType',
        ];
      },
    },
    from: {
      type: Object,
      default() {
        return this.$moment().startOf('day').subtract(30, 'd');
      },
    },
    to: {
      type: Object,
      default() {
        return this.$moment().endOf('day');
      },
    },
  },
  data() {
    return {
      currentSearch: this.$_.cloneDeep(this.setCurrentSearch),
      currentSearchClone: this.$_.cloneDeep(this.setCurrentSearch),
      defaultFilterList: {
        site_player_id: {
          icon: 'id-badge',
          order: 1,
        },
        affiliate_email: {
          icon: 'user',
          order: 2,
        },
        sub_affiliate_email: {
          icon: 'users',
          order: 3,
        },
        referrer_domain: {
          icon: 'globe',
          order: 4,
        },
      },

      isDisabled: true,
      tab: 'from',
      countInput: 30,
      filtersOpen: false,
      currentFilters: [],
    };
  },
  computed: {
    allFilters() {
      const allFilters = {
        playerFilter: {
          type: 'playerFilter',
          field: 'players_filter',
          options: [
            {
              type: 'all',
              name: this.$t('reports.players_type.all'),
            },
            {
              type: 'new',
              name: this.$t('reports.players_type.new'),
            },
            {
              type: 'old',
              name: this.$t('reports.players_type.old'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        trafficType: {
          type: 'trafficType',
          field: 'traffic_type',
          options: [
            {
              type: 'all',
              name: this.$t('ui.filters.select.all'),
            },
            {
              type: 'affiliates',
              name: this.$t('ui.filters.select.partners'),
            },
            {
              type: 'direct',
              name: this.$t('ui.filters.select.direct'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },


        playerCountryNameFilter: {
          type: 'playerCountryNameFilter',
          checkSupported: true,
          field: 'player_country_code',
          options: [],
          labelProp: 'player_country_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const dateFrom = this.$moment().startOf('day').subtract(1, 'month');
            const dateTo = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            const query = {
              search: q ? {
                player_country_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['player_country_name'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'player_country_name',
                sort_dir: 'asc',
              }],
              metrics: ['impressions_count'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        playerRegionNameFilter: {
          type: 'playerRegionNameFilter',
          checkSupported: true,
          field: 'player_region_name',
          options: [],
          labelProp: 'player_region_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const dateFrom = this.$moment().startOf('day').subtract(1, 'month');
            const dateTo = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            const query = {
              search: q ? {
                player_region_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['player_region_name'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'player_region_name',
                sort_dir: 'asc',
              }],
              metrics: ['impressions_count'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        trafficRegionNameFilter: {
          type: 'trafficRegionNameFilter',
          checkSupported: true,
          field: 'country_code',
          options: [],
          labelProp: 'country_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const dateFrom = this.$moment().startOf('day').subtract(1, 'month');
            const dateTo = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            const query = {
              search: q ? {
                country_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['country_name'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'country_name',
                sort_dir: 'asc',
              }],
              metrics: ['impressions_count'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        /* end filter dash  */


        affiliate: {
          type: 'affiliate',
          checkSupported: true,
          field: 'affiliate_id',
          options: [],
          labelProp: 'email',
          valueProp: 'id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              limit: 100,
              offset: 0,
              search: q,
              account_status: ['approved'],
            };
            return new Promise((resolve, reject) => {
              this.$api.getAffiliates(query)
                .then((response) => {
                  resolve(response.data.payload);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        affiliatePaymentsReport: {
          type: 'affiliatePaymentsReport',
          checkSupported: true,
          field: 'affiliate_payments_report_id',
          options: [],
          labelProp: 'affiliate_email',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const _dateParams = updateUrl.getParseParams(this.$route.hash, ['date']);
            const dateFrom = this.$moment(this.$_.get(_dateParams, ['date', 'from']));
            const dateTo = this.$moment(this.$_.get(_dateParams, ['date', 'to']));
            const query = {
              search: q ? {
                affiliate_email: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['affiliate_email'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'affiliate_email',
                sort_dir: 'asc',
              }],
              metrics: ['impressions_count'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        affiliateLastBets: {
          type: 'affiliateLastBets',
          checkSupported: true,
          field: 'affiliate_last_bets_id',
          options: [],
          labelProp: 'affiliate_email',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              search: q ? {
                affiliate_email: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['affiliate_email'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'affiliate_email',
                sort_dir: 'asc',
              }],
              metrics: ['impressions_count'],
              from: this.$moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        affiliateLastDeposits: {
          type: 'affiliateLastDeposits',
          checkSupported: true,
          field: 'affiliate_last_deposits_id',
          options: [],
          labelProp: 'affiliate_email',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              search: q ? {
                affiliate_email: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['affiliate_email'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'affiliate_email',
                sort_dir: 'asc',
              }],
              metrics: ['impressions_count'],
              from: this.$moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        profitCategory: {
          type: 'profitCategory',
          field: 'min_profit_category',
          options: [
            {
              type: 'A++',
              name: 'A++',
              sufix: '<span class="green">(+ 90-100%)</span>',
            },
            {
              type: 'A+',
              name: 'A+',
              sufix: '<span class="green">(+ 70-90%)</span>',
            },
            {
              type: 'A',
              name: 'A',
              sufix: '<span class="green">(+ 50-70%)</span>',
            },
            {
              type: 'B',
              name: 'B',
              sufix: '<span class="green">(+ 25-50%)</span>',
            },
            {
              type: 'C',
              name: 'C',
              sufix: '<span class="green">(+ 10-25%)</span>',
            },
            {
              type: 'D',
              name: 'D',
              sufix: '<span class="green">(+ 0-10%)</span>',
            },
            {
              type: 'E',
              name: 'E',
              sufix: '<span class="red">(- 0-50%)</span>',
            },
            {
              type: 'F',
              name: 'F',
              sufix: '<span class="red">(- 50-100%)</span>',
            },
            {
              type: 'G',
              name: 'G',
              sufix: `<span class="red">(${this.$t('ui.filters.select.and_more')})</span>`,
            },
          ],
          valueProp: 'type',
          labelProp: 'name',
          sufixProp: 'sufix',
          multiple: false,
          filterable: false,
          remote: false,
          method: () => null,
        },
        lastActivity: {
          type: 'lastActivity',
          field: 'activity',
          values: [
            '30',
            'from',
          ],
          method: null,
        },
        contacts: {
          type: 'contacts',
          field: 'contacts',
          options: [
            {
              type: 'only',
              name: this.$t('ui.filters.select.only_content'),
            },
            {
              type: 'all',
              name: this.$t('ui.filters.select.all'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        deposits: {
          type: 'deposits',
          field: 'deposits',
          options: [
            {
              type: 'without',
              name: this.$t('ui.filters.select.without'),
            },
            {
              type: 'first',
              name: this.$t('ui.filters.select.only_first_dep'),
            },
            {
              type: 'more',
              name: this.$t('ui.filters.select.more_one'),
            },
            {
              type: 'onlydep',
              name: this.$t('ui.filters.select.only_with_dep'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        pinnedAffiliate: {
          type: 'pinnedAffiliate',
          field: 'belongs_to_affiliate',
          options: [
            {
              type: false,
              name: this.$t('ui.filters.select.no'),
            },
            {
              type: true,
              name: this.$t('ui.filters.select.yes'),
            },
          ],
          valueProp: 'type',
          labelProp: 'name',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        affiliateReport: {
          type: 'affiliateReport',
          checkSupported: true,
          field: 'affiliate_id',
          options: [],
          labelProp: 'affiliate_email',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              search: q ? {
                affiliate_email: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['affiliate_email'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'affiliate_email',
                sort_dir: 'desc',
              }],
              metrics: ['impressions_count'],
              from: this.$moment(this.from)
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(this.to)
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        player: {
          type: 'player',
          checkSupported: true,
          field: 'player_id',
          options: [],
          labelProp: 'site_player_id',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const _dateParams = updateUrl.getParseParams(this.$route.hash, ['date']);
            const dateFrom = this.$moment(this.$_.get(_dateParams, ['date', 'from']));
            const dateTo = this.$moment(this.$_.get(_dateParams, ['date', 'to']));
            const query = {
              limit: 100,
              offset: 0,
              search: q ? {
                site_player_id: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['site_player_id'],
              sorting: [{
                sort_by: 'site_player_id',
                sort_dir: 'asc',
              }],
              metrics: ['impressions_count'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        playerReport: {
          type: 'playerReport',
          checkSupported: true,
          field: 'player_id',
          options: [],
          labelProp: 'site_player_id',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              search: q,
              dimensions: ['site_player_id'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'site_player_id',
                sort_dir: 'asc',
              }],
              metrics: ['impressions_count'],
              from: this.$moment(this.from)
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(this.to)
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        countryPaymentsReport: {
          type: 'countryPaymentsReport',
          checkSupported: true,
          field: 'country_code_payments_report',
          options: [],
          labelProp: 'country_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const _dateParams = updateUrl.getParseParams(this.$route.hash, ['date']);
            const dateFrom = this.$moment(this.$_.get(_dateParams, ['date', 'from']));
            const dateTo = this.$moment(this.$_.get(_dateParams, ['date', 'to']));
            const query = {
              search: q ? {
                country_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['country_name'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'country_name',
                sort_dir: 'asc',
              }],
              metrics: ['impressions_count'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        country: {
          type: 'country',
          field: 'country_code',
          options: [],
          labelProp: 'name',
          valueProp: 'code',
          multiple: true,
          filterable: true,
          remote: false,
          method: null,
        },
        playerCountry: {
          type: 'playerCountry',
          field: 'player_country_code',
          options: [],
          labelProp: 'name',
          valueProp: 'code',
          multiple: true,
          filterable: true,
          remote: false,
          method: null,
        },
        playerCountryLastBets: {
          type: 'playerCountryLastBets',
          checkSupported: true,
          field: 'player_country_code_last_bets',
          options: [],
          labelProp: 'player_country_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              search: q ? {
                player_country_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: 'player_country_name',
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'player_country_name',
                sort_dir: 'asc',
              }],
              metrics: ['impressions_count'],
              from: this.$moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        playerCountryLastDeposits: {
          type: 'playerCountryLastDeposits',
          checkSupported: true,
          field: 'player_country_code_last_deposits',
          options: [],
          labelProp: 'player_country_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const query = {
              search: q ? {
                player_country_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              limit: 100,
              offset: 0,
              dimensions: 'player_country_name',
              sorting: [{
                sort_by: 'player_country_name',
                sort_dir: 'asc',
              }],
              metrics: ['impressions_count'],
              from: this.$moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        playerCountryPaymentsReport: {
          type: 'playerCountryPaymentsReport',
          checkSupported: true,
          field: 'player_country_code_payments_report',
          options: [],
          labelProp: 'player_country_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const _dateParams = updateUrl.getParseParams(this.$route.hash, ['date']);
            const dateFrom = this.$moment(this.$_.get(_dateParams, ['date', 'from']));
            const dateTo = this.$moment(this.$_.get(_dateParams, ['date', 'to']));
            const query = {
              search: q ? {
                player_country_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['player_country_name'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'player_country_name',
                sort_dir: 'asc',
              }],
              metrics: ['impressions_count'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },

        autoDnsManagement: {
          type: 'autoDnsManagement',
          field: 'auto_dns_management',
          options: [
            {
              type: true,
              name: this.$t('ui.filters.select.auto'),
            },
            {
              type: false,
              name: this.$t('ui.filters.select.manual'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        dnsHostingId: {
          type: 'dnsHostingId',
          field: 'dns_hosting_id',
          options: [],
          labelProp: 'name',
          valueProp: 'id',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        autoNsManagement: {
          type: 'autoNsManagement',
          field: 'auto_ns_management',
          options: [
            {
              type: true,
              name: this.$t('ui.filters.select.auto'),
            },
            {
              type: false,
              name: this.$t('ui.filters.select.manual'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        domainRegistrarId: {
          type: 'domainRegistrarId',
          field: 'domain_registrar_id',
          options: [],
          labelProp: 'name',
          valueProp: 'id',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        nsManagement: {
          type: 'nsManagement',
          field: 'ns_management',
          options: [
            {
              type: 'auto',
              name: this.$t('ui.filters.select.auto'),
            },
            {
              type: 'manual',
              name: this.$t('ui.filters.select.manual'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        dnsManagement: {
          type: 'dnsManagement',
          field: 'dns_management',
          options: [
            {
              type: 'auto',
              name: this.$t('ui.filters.select.auto'),
            },
            {
              type: 'manual',
              name: this.$t('ui.filters.select.manual'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        sslIssuance: {
          type: 'sslIssuance',
          field: 'ssl_issuance',
          options: [
            {
              type: 'auto',
              name: this.$t('ui.filters.select.auto'),
            },
            {
              type: 'manual',
              name: this.$t('ui.filters.select.manual'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        platformServiceOrigin: {
          type: 'platformServiceOrigin',
          field: 'platform_service_origin_id',
          labelProp: 'name',
          valueProp: 'id',
          multiple: false,
          filterable: true,
          remote: true,
          method: async (search) => {
            const { data: { payload } } = await this.$api.getServiceOrigins({ limit: 25, search });
            return payload;
          },
        },
        platformServer: {
          type: 'platformServer',
          field: 'platform_server_id',
          labelProp: 'name',
          valueProp: 'id',
          multiple: false,
          filterable: true,
          remote: true,
          method: async (search) => {
            const { data: { payload } } = await this.$api.getPlatformServers({ limit: 25, search });
            return payload;
          },
        },
        domainRegistrarAccount: {
          type: 'domainRegistrarAccount',
          field: 'domain_registrar_account_id',
          labelProp: 'name',
          valueProp: 'id',
          multiple: false,
          filterable: true,
          remote: true,
          method: async (search) => {
            const { data: { payload } } = await this.$api.getDomainRegistrarAccounts({ limit: 25, search });
            return payload;
          },
        },
        dnsHostingAccount: {
          type: 'dnsHostingAccount',
          field: 'dns_hosting_account_id',
          labelProp: 'name',
          valueProp: 'id',
          multiple: false,
          filterable: true,
          remote: true,
          method: async (search) => {
            const { data: { payload } } = await this.$api.getDNSHostingAccounts({ limit: 25, search });
            return payload;
          },
        },
        marketingMaterialType: {
          type: 'marketingMaterialType',
          field: 'type',
          options: [
            {
              type: 'html5_banner',
              name: this.$t('ui.filters.select.html5_banner'),
            },
            {
              type: 'simple_banner',
              name: this.$t('ui.filters.select.simple_banner'),
            },
            {
              type: 'landing',
              name: this.$t('ui.filters.select.landing'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        marketingMaterialStatus: {
          type: 'marketingMaterialStatus',
          field: 'status',
          options: [
            {
              type: 'active',
              name: this.$t('ui.filters.select.active'),
            },
            {
              type: 'inactive',
              name: this.$t('ui.filters.select.inactive'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        languageCode: {
          type: 'languageCode',
          field: 'language_code',
          labelProp: 'name',
          valueProp: 'code',
          multiple: false,
          filterable: true,
          remote: true,
          async method(search) {
            const { data: { payload } } = await this.$api.getLanguages({ search });
            return payload;
          },
        },
        usedInAnyGroup: {
          type: 'usedInAnyGroup',
          field: 'used_in_any_group',
          options: [
            {
              type: true,
              name: this.$t('ui.filters.select.yes'),
            },
            {
              type: false,
              name: this.$t('ui.filters.select.no'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        blockedInAnyCountry: {
          type: 'blockedInAnyCountry',
          field: 'blocked_in_any_country',
          options: [
            {
              type: true,
              name: this.$t('ui.filters.select.yes'),
            },
            {
              type: false,
              name: this.$t('ui.filters.select.no'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        brand: {
          type: 'brand',
          field: 'site_id',
          options: [],
          labelProp: 'site_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const _dateParams = updateUrl.getParseParams(this.$route.hash, ['date']);
            const dateFrom = this.$moment(this.$_.get(_dateParams, ['date', 'from']));
            const dateTo = this.$moment(this.$_.get(_dateParams, ['date', 'to']));
            const query = {
              limit: 100,
              offset: 0,
              search: q ? {
                site_name: {
                  op: 'like',
                  value: [q],
                },
              } : undefined,
              dimensions: ['site_name'],
              sorting: [{
                sort_by: 'site_name',
                sort_dir: 'asc',
              }],
              metrics: ['deposits_all_count', 'deposits_all_sum'],
              from: this.$moment(dateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        brandLive: {
          type: 'brandLive',
          field: 'live_site_id',
          options: [],
          labelProp: 'site_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: async (q) => {
            const query = {
              search: q,
              dimensions: ['site_name'],
              limit: 100,
              offset: 0,
              sorting: [{
                sort_by: 'site_name',
                sort_dir: 'asc',
              }],
              metrics: ['deposits_withdrawals_count'],
              from: this.$moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            };
            const { data: { payload: { data } } } = await this.$api.getMainReport(query);
            return data;
          },
        },
      };

      const keyAllFilters = Object.keys(allFilters);

      const supportAllFilters = keyAllFilters.reduce((acc, value) => {
        if (!this.supportedDimension.includes(allFilters[value]?.labelProp)
          && allFilters[value]?.checkSupported) {
          return acc;
        }
        acc[value] = allFilters[value];
        return acc;
      }, {});

      return supportAllFilters;
    },

    supportedDimension() {
      return (this.reportsSettings.dimensions || []).map(dim => dim.column_name);
    },

    isDisabledApply() {
      return (
        (this.isDisabled && this.currentFilters.length !== 0)
          || (this.isDisabledTab && this.currentFilters.length === 0));
    },

    isDisabledTab() {
      return this.$_.isEmpty(this.currentSearchClone);
    },

    isAffiliatesFilter() {
      if (!this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl']) {
        return this.$store.getters['auth/currentAcl'].affiliates_view === 'allow';
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },
    isAffiliatesLiveFilter() {
      if (!this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl']) {
        return this.$store.getters['auth/currentAcl'].reports_group_by_affiliates === 'allow';
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },

    isPlayerCountryFilter() {
      if (!this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl']) {
        return this.$store.getters['auth/currentAcl'].reports_group_by_countries === 'allow';
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },
    availableFilters() {
      const fs = this.$_.filter(this.allFilters, filter => this.useFilters.indexOf(filter.type) !== -1);
      return fs;
    },
    excludeFilters() {
      return this.$_.map(this.currentFilters, filter => filter.type);
    },

    searchByList() {
      return this.reportsSettings.dimensions.reduce((acc, el) => {
        if (this.defaultFilterList.hasOwnProperty(el.column_name)) {
          acc[el.column_name] = this.defaultFilterList[el.column_name];
        }
        return acc;
      }, {});
    },

    isSearchingFilter() {
      const filtersList = Object.keys(this.searchByList);
      return filtersList.reduce((acc, elem) => {
        acc[elem] = this.$_.has(this.currentSearchClone, elem);
        return acc;
      }, {});
    },

    ...mapGetters('reports', ['reportsSettings']),
    ...mapGetters('misc', ['countries', 'domainRegistrarProviders', 'dnsHostingProviders']),
  },
  watch: {
    currentFilters: {
      deep: true,
      handler(v) {
        this.handleDisabled(v);
      },
    },
    countries() {
      this.initCountries();
    },

    domainRegistrarProviders() {
      this.allFilters.domainRegistrarId.options = this.domainRegistrarProviders;
    },

    dnsHostingProviders() {
      this.allFilters.dnsHostingId.options = this.dnsHostingProviders;
    },
  },
  destroyed() {
    document.removeEventListener('click', this.clickOutside, true);
  },
  created() {
    if (this.value.hasOwnProperty('max_profit_category')) {
      this.allFilters.profitCategory.field = 'max_profit_category';
    }

    this.allFilters.domainRegistrarId.options = this.domainRegistrarProviders;
    this.allFilters.dnsHostingId.options = this.dnsHostingProviders;

    this.initCountries();
    let affiliateType = '';
    let playerCountryType = '';

    if (this.value.hasOwnProperty('affiliate_id')) {
      affiliateType = 'affiliate';
    }
    if (this.value.hasOwnProperty('affiliate_last_bets_id')) {
      affiliateType = 'affiliateLastBets';
    }
    if (this.value.hasOwnProperty('affiliate_last_deposits_id')) {
      affiliateType = 'affiliateLastDeposits';
    }
    if (this.value.hasOwnProperty('affiliate_payments_report_id')) {
      affiliateType = 'affiliatePaymentsReport';
    }

    if ((this.value.hasOwnProperty('affiliate_id') && this.isAffiliatesFilter) || (this.value.hasOwnProperty('affiliate_last_bets_id')
        && this.isAffiliatesLiveFilter) || (this.value.hasOwnProperty('affiliate_last_deposits_id') && this.isAffiliatesLiveFilter)
        || (this.value.hasOwnProperty('affiliate_payments_report_id') && this.isAffiliatesLiveFilter)) {
      this.allFilters[affiliateType].method('')
        .then((response) => {
          this.allFilters[affiliateType].options = response;
        });
    }


    if (this.value.hasOwnProperty('player_country_code_last_bets')) {
      playerCountryType = 'playerCountryLastBets';
    }
    if (this.value.hasOwnProperty('player_country_code_last_deposits')) {
      playerCountryType = 'playerCountryLastDeposits';
    }
    if (this.value.hasOwnProperty('player_country_code_payments_report')) {
      playerCountryType = 'playerCountryPaymentsReport';
    }
    if (this.value.hasOwnProperty('country_code_payments_report')) {
      playerCountryType = 'countryPaymentsReport';
    }

    if ((this.value.hasOwnProperty('country_code_payments_report') && this.isPlayerCountryFilter) || (this.value.hasOwnProperty('player_country_code_last_bets') && this.isPlayerCountryFilter)
        || (this.value.hasOwnProperty('player_country_code_last_deposits') && this.isPlayerCountryFilter)) {
      this.allFilters[playerCountryType].method('')
        .then((response) => {
          this.allFilters[playerCountryType].options = response;
        });
    }
  },

  mounted() {
    document.addEventListener('click', this.clickOutside, true);
    if (!this.$_.isEmpty(this.value)) {
      this.transformValue();
    }
  },

  methods: {
    selectedSearchFilter(search) {
      this.currentSearchClone = this.$_.cloneDeep({ ...this.currentSearchClone, ...search });
    },

    clearReportsSearchFilter(group) {
      this.$delete(this.currentSearchClone, group);
    },

    handleDisabled(v) {
      this.isDisabled = false;
      if (v.length === 0) {
        this.isDisabled = true;
        return;
      }
      this.isDisabled = v.some(e => e.type === '' || e.values.length === 0);
    },
    clickOutside(e) {
      if (this.$refs.mainBtn.$el.contains(e.target)) return;
      if (!this.$refs.pop.contains(e.target) && this.filtersOpen === true) {
        this.toggleFilters();
      }
    },

    inputSelect(v, index) {
      const noParse = ['trafficType', 'playerFilter'];
      if (v === 'lastActivity') {
        this.currentFilters.forEach((e, i) => {
          if (e.type === 'lastActivity') {
            this.currentFilters[i].values = [30, this.tab];
          }
        });
      }

      if (!noParse.includes(v)) {
        this.$set(this.currentFilters[index], 'op', '=');
      } else {
        this.$set(this.currentFilters[index], 'op', null);
      }
    },

    clone() {
      this.currentFilters = this.$_.clone(this.currentFilters);
    },
    getFilter(type) {
      const filter = this.allFilters[type];
      if (filter) {
        if (typeof filter.options === 'function') {
          filter.options = filter.options();
        }
        return filter;
      }
      return { multiple: true };
    },
    initCountries() {
      this.allFilters.playerCountry.options = this.countries;
      this.allFilters.country.options = this.countries;
    },
    toggleFilters(silent, action = null) {
      this.filtersOpen = action === null ? !this.filtersOpen : action;
      if (this.filtersOpen === false) {
        setTimeout(() => {
          this.transformValue();
        }, 300);
      }

      if (silent) this.currentSearchClone = this.$_.cloneDeep(this.setCurrentSearch);
      /* при открытии добавляем в фильтр дефортное поле пустых инпутов */
      // else if (this.filtersOpen && this.currentFilters.length === 0) {
      //   this.addFilter();
      // }
      this.$emit('toggle', this.filtersOpen);
    },

    addFilter() {
      this.currentFilters.push({
        type: '',
        values: [],
        op: null,
      });
    },

    removeFilter(i) {
      this.currentFilters.splice(i, 1);
    },

    applyFilters() {
      const val = {};
      this.$_.forEach(this.currentFilters, (filter) => {
        this.$_.assign(val, {
          [this.getFilter(filter.type).field]: {
            value: filter.values,
            op: filter.op,
          },
        });
      });
      this.currentSearch = this.currentSearchClone;
      this.$emit('input', val, this.currentSearch);
      this.toggleFilters(false, false);
    },
    resetFilters() {
      this.currentFilters = [];
      this.currentSearch = {};
      this.currentSearchClone = {};
      this.$emit('input', {}, {});
      this.toggleFilters();
    },
    reset() {
      this.currentSearch = {};
      this.currentSearchClone = {};
      this.currentFilters = [];
      this.$emit('input', {}, {});
    },

    transformValue() {
      let fs = [];
      fs = this.$_.map(Object.keys(this.value), key => ({
        type: this.$_.find(this.allFilters, f => f.field === key).type,
        values: this.value[key].value,
        op: this.value[key].op,
      }));
      if (!this.$_.isEqual(fs, this.currentFilters)) {
        this.currentFilters = fs;
      }
    },
  },
};
</script>

<style lang="scss">
.popover-filter{
  .pop{

    .ui-select .control-wrapper .tags .value {
      max-width: 188px;
      .label {
        max-width: 155px;
        line-height: 16px;
      }
    }
    .f-day{
      .ui-select {
        .pop{
          width: 108% !important;
        }
      }
      .del{
        display: none !important;
      }
      .tags{
        .value{
          justify-content: flex-start !important;
        }
        .sufix{
          span{
            width: 78px;
            height: 14.5px;
            word-break: break-all;
            overflow: hidden;
            display: block;
          }
        }
      }
    }

  }

}
</style>


<style lang="scss" scoped>
.ui-filter{
  position: relative;


  .f-day{
    width: 230px;
    height: 34px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    .sufix{
      color: #334444;
    }
    &__tabs{
      width: 70px;
      min-width: 70px;
      height: 34px;
      border-radius: 4px;
      display: flex;
      margin-right: 8px;
      .select-class{
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
    &__tab{
      height: 32px;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      background-color: #fff;
      cursor: pointer;
      &:first-child{
        border: solid 1px #d3d3d3;
        border-right-color: transparent;
        border-radius: 5px 0 0 5px;
      }
      &:last-child{
        border: solid 1px #d3d3d3;
        border-left-color: transparent;
        border-radius: 0 5px 5px 0;
      }
      &--active{
        cursor: pointer;
        color: #fff;
        &:first-child{
          border-right-color: transparent;
        }
        &:last-child{
          border-left-color: transparent;
        }
      }
    }
    &__input{
      width: 100% !important;
      max-width: 62px !important;
    }
    &__text{
      padding-left: 8px;
      font-size: 12px;
      color: #000000;
    }
  }
  .pop{
    position: absolute;
    z-index: 9999;
    top: 44px;
    left: -20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    user-select: none;
    .ui-select.filter_type.medium{
      // width: 200px !important;
      .tag .value {
        max-width: 181px !important;
      }
    }
    &.fade-enter-active, &.fade-leave-active{
      transition: all .4s;
    }
    &.fade-enter, &.fade-leave-to{
      opacity: 0;
      transform: translateY(8px);
    }
    .arrow{
      position: absolute;
      top: -5px;
      left: 56px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
    }
    .filter-row{
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .filter_type{
        width: 160px;
        margin-left: 8px !important;
      }
      .filter_value{
        width: 280px;
      }
      .btn{
        margin-left: 0 !important;
      }
    }
    .controls{
      display: inline-flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      width: auto;
      .btn{
        margin-left: 0 !important;
      }
      .btn + .btn {
        margin-left: 8px !important;
      }
    }
  }
}

</style>

<style lang="scss">

.ui-filter-new{
  .pop .ui-select .control-wrapper .tags .value:first-child{
    width: 100% !important;
  }
  .pop .ui-select .control-wrapper .tags .value.with-count{
    max-width: 154px !important;
  }
  .ui-icon.btn-icon.tick-icon{
    font-size: 10px !important;
  }
  .control-wrapper{

    flex-wrap: initial !important;
    .value{
      .label{
        margin-bottom: 1px;
      }
      .sufix {
        margin-bottom: 0px !important;
      }
      .ui-icon.del.fa-times{
        display: none;
      }
    }
  }
  .ui-select.filterable.multiple{
    .ui-icon.del.fa-times{
      display: flex;
    }
  }
}
</style>
