function getCurrencyClass(value) {
  return value < 0 ? 'negative' : '';
}

const Config = {
  allDimensions: [
    {
      name: 'date',
      type: 'datetime',
      minWidth: '120',
      format: 'date',
      formatOptions: {
        in: 'YYYY-MM-DD',
        out: 'DD MMM YYYY',
      },
    },
    {
      name: 'affiliate_email',
      type: 'affiliate',
      minWidth: '180',
      permission: 'reports_group_by_affiliates',
    },
    {
      name: 'referred_affiliate_email',
      type: 'affiliate',
      minWidth: '180',
      permission: 'reports_group_by_sub_affiliates',
    },
    {
      name: 'affiliate_offer_name',
      type: 'offer',
      minWidth: '180',
    },
    {
      name: 'affiliate_offer_settings_name',
      type: 'offer',
      minWidth: '150',
    },
    {
      name: 'offer_type_name',
      type: 'offer',
      minWidth: '150',
    },
    {
      name: 'site_name',
      type: 'offer',
      minWidth: '150',
    },
    {
      name: 'traffic_source_name',
      type: 'traffic',
      minWidth: '130',
    },
    {
      name: 'bet_category_name',
      type: 'sport_casino',
      minWidth: '130',
    },
    {
      name: 'bet_game_name',
      type: 'sport_casino',
      minWidth: '130',
    },
    {
      name: 'media_buying_campaign_name',
      type: 'media_buying',
      minWidth: '180',
    },
    {
      name: 'media_buying_channel_name',
      type: 'media_buying',
      minWidth: '180',
    },
    {
      name: 'landing_name',
      type: 'misc',
      minWidth: '180',
    },
    {
      name: 'marketing_material_name',
      type: 'misc',
      minWidth: '180',
    },
    {
      name: 'payment_system_name',
      type: 'misc',
      minWidth: '130',
      permission: 'reports_group_by_payments',
    },
    {
      name: 'affiliate_player_promo_code_name',
      type: 'misc',
      minWidth: '120',
    },
    {
      name: 'site_player_id',
      type: 'player',
      minWidth: '130',
    },
    {
      name: 'site_registration_type_name',
      type: 'player',
      minWidth: '130',
    },
    {
      name: 'player_region_name',
      type: 'player',
      minWidth: '130',
      permission: 'reports_group_by_countries',
    },
    {
      name: 'player_country_name',
      type: 'player',
      minWidth: '130',
      permission: 'reports_group_by_countries',
    },
    {
      name: 'subid1',
      type: 'subid',
      minWidth: '110',
    },
    {
      name: 'region_name',
      type: 'geo',
      minWidth: '110',
      permission: 'reports_group_by_countries',
    },
    {
      name: 'country_name',
      type: 'geo',
      minWidth: '110',
      permission: 'reports_group_by_countries',
    },
    {
      name: 'state_name',
      type: 'geo',
      minWidth: '120',
      permission: 'reports_group_by_countries',
    },
    {
      name: 'city_name',
      type: 'geo',
      minWidth: '120',
      permission: 'reports_group_by_countries',
    },
    {
      name: 'referrer',
      type: 'referrer',
      minWidth: '110',
    },
    {
      name: 'referrer_domain',
      type: 'referrer',
      minWidth: '140',
      class: 'link',
    },
    {
      name: 'device_type',
      type: 'device',
      minWidth: '120',
    },
    {
      name: 'device_vendor',
      type: 'device',
      minWidth: '130',
    },
    {
      name: 'device_model',
      type: 'device',
      minWidth: '150',
    },
    {
      name: 'browser',
      type: 'browser',
      minWidth: '150',
    },
    {
      name: 'browser_version',
      type: 'browser',
      minWidth: '150',
    },
    {
      name: 'language',
      type: 'browser',
      minWidth: '110',
    },
    {
      name: 'os',
      type: 'os',
      minWidth: '100',
    },
    {
      name: 'os_version',
      type: 'os',
      minWidth: '120',
    },
    {
      name: 'isp',
      type: 'isp',
      minWidth: '180',
    },
    {
      name: 'connection_type',
      type: 'isp',
      minWidth: '140',
    },
    {
      name: 'start_of_week',
      type: 'datetime',
      minWidth: '120',
      format: 'date',
      formatOptions: {
        in: 'YYYY-MM-DD',
        out: 'DD MMM YYYY',
      },
    },
    {
      name: 'start_of_month',
      type: 'datetime',
      minWidth: '126',
      format: 'date',
      formatOptions: {
        in: 'YYYY-MM-DD',
        out: 'MMMM YYYY',
      },
    },
    {
      name: 'year',
      type: 'datetime',
      minWidth: '80',
      format: 'date',
      formatOptions: {
        in: 'YYYY',
        out: 'YYYY',
      },
    },
    {
      name: 'day_of_week',
      type: 'datetime',
      minWidth: '100',
      format: 'date',
      formatOptions: {
        in: 'E',
        out: 'dddd',
      },
    },
    {
      name: 'day_of_month',
      type: 'datetime',
      minWidth: '100',
      format: 'date',
      formatOptions: {
        in: 'D',
        out: 'Do',
      },
    },
    {
      name: 'hour',
      type: 'datetime',
      minWidth: '80',
      format: 'date',
      formatOptions: {
        in: 'H',
        out: 'HH:mm',
      },
    },
    {
      name: 'subid2',
      type: 'subid',
      minWidth: '110',
    },
    {
      name: 'subid3',
      type: 'subid',
      minWidth: '110',
    },
    {
      name: 'subid4',
      type: 'subid',
      minWidth: '110',
    },
    {
      name: 'subid5',
      type: 'subid',
      minWidth: '110',
    },
    {
      name: 'subid6',
      type: 'subid',
      minWidth: '110',
    },
    {
      name: 'subid7',
      type: 'subid',
      minWidth: '110',
    },
    {
      name: 'subid8',
      type: 'subid',
      minWidth: '110',
    },
    {
      name: 'subid9',
      type: 'subid',
      minWidth: '110',
    },
    {
      name: 'subid10',
      type: 'subid',
      minWidth: '110',
    },
    {
      name: 'external_id',
      type: 'subid',
      minWidth: '110',
    },
  ],

  allMetrics: [
    {
      name: 'impressions_count',
      minWidth: '100',
      filterable: true,
      defaultOrder: 0,
      order: 0,
    },
    {
      name: 'impressions_uniq_count',
      minWidth: '115',
      filterable: true,
      defaultOrder: 1,
      order: 1,
    },
    {
      name: 'visits_count',
      minWidth: '100',
      filterable: true,
      defaultOrder: 2,
      order: 2,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'visits_uniq_count',
      minWidth: '98',
      filterable: true,
      defaultOrder: 3,
      order: 3,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'registrations_count',
      minWidth: '120',
      filterable: true,
      group: 'raising',
      defaultOrder: 5,
      order: 5,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_first_count',
      minWidth: '120',
      filterable: true,
      defaultOrder: 7,
      order: 7,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_other_count',
      minWidth: '130',
      filterable: true,
      defaultOrder: 8,
      order: 8,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_all_count',
      minWidth: '120',
      filterable: true,
      defaultOrder: 9,
      order: 9,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_withdrawals_count',
      minWidth: '134',
      filterable: true,
      defaultOrder: 10,
      order: 10,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_first_sum',
      minWidth: '130',
      filterable: true,
      defaultOrder: 16,
      order: 16,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_other_sum',
      minWidth: '130',
      filterable: true,
      defaultOrder: 19,
      order: 19,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_all_sum',
      minWidth: '120',
      class: 'positive',
      filterable: true,
      defaultOrder: 22,
      order: 22,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_withdrawals_sum',
      minWidth: '134',
      class: 'negative',
      filterable: true,
      defaultOrder: 25,
      order: 25,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'players_with_first_deposit_count',
      minWidth: '140',
      filterable: true,
      defaultOrder: 26,
      order: 26,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'players_with_deposit_count',
      minWidth: '154',
      filterable: true,
      defaultOrder: 27,
      order: 27,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 're_deposits_percent',
      minWidth: '130',
      filterable: true,
      defaultOrder: 13,
      order: 13,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_new_count',
      minWidth: '110',
      filterable: true,
      defaultOrder: 28,
      order: 28,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_new_sports_count',
      minWidth: '126',
      filterable: true,
      defaultOrder: 29,
      order: 29,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_new_games_count',
      minWidth: '134',
      filterable: true,
      defaultOrder: 30,
      order: 30,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_count',
      minWidth: '120',
      filterable: true,
      defaultOrder: 31,
      order: 31,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_sports_count',
      minWidth: '140',
      filterable: true,
      defaultOrder: 32,
      order: 32,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_games_count',
      minWidth: '146',
      filterable: true,
      defaultOrder: 33,
      order: 33,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_sell_count',
      minWidth: '100',
      filterable: true,
      defaultOrder: 34,
      order: 34,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_insurance_count',
      minWidth: '124',

      filterable: true,
      defaultOrder: 35,
      order: 35,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_insurance_result_count',
      minWidth: '124',
      filterable: true,
      defaultOrder: 36,
      order: 36,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_new_sum',
      minWidth: '110',
      filterable: true,
      defaultOrder: 39,
      order: 39,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_new_sports_sum',
      minWidth: '124',

      filterable: true,
      defaultOrder: 37,
      order: 37,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_new_games_sum',
      minWidth: '130',

      filterable: true,
      defaultOrder: 38,
      order: 38,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_sum',
      minWidth: '150',
      filterable: true,
      defaultOrder: 50,
      order: 50,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_sports_sum',
      minWidth: '126',
      // computedClass: getCurrencyClass,
      filterable: true,
      defaultOrder: 48,
      order: 48,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_games_sum',
      minWidth: '132',
      filterable: true,
      defaultOrder: 49,
      order: 49,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_sell_profit_sum',
      minWidth: '124',
      filterable: true,
      defaultOrder: 54,
      order: 54,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_insurance_sum',
      minWidth: '126',

      filterable: true,
      defaultOrder: 55,
      order: 55,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_insurance_result_sum',
      minWidth: '126',

      filterable: true,
      defaultOrder: 56,
      order: 56,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'players_with_bet_count',
      minWidth: '134',

      filterable: true,
      defaultOrder: 57,
      order: 57,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'profit_sum',
      minWidth: '100',

      computedClass: getCurrencyClass,
      filterable: true,
      defaultOrder: 60,
      order: 60,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'revenue_sum',
      minWidth: '114',

      filterable: true,
      defaultOrder: 62,
      order: 62,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'affiliate_program_profit_sum',
      minWidth: '140',

      filterable: true,
      defaultOrder: 64,
      order: 64,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'chargebacks_sum',
      minWidth: '136',

      class: 'negative',
      filterable: true,
      defaultOrder: 63,
      order: 63,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_first_avg',
      minWidth: '150',

      filterable: true,
      group: 'deposit',
      defaultOrder: 14,
      order: 14,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_other_avg',
      minWidth: '160',

      filterable: true,
      defaultOrder: 17,
      order: 17,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_all_avg',
      minWidth: '124',

      filterable: true,
      group: 'deposit',
      defaultOrder: 20,
      order: 20,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_first_median',
      minWidth: '154',

      filterable: true,
      group: 'deposit',
      defaultOrder: 15,
      order: 15,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_other_median',
      minWidth: '160',

      filterable: true,
      group: 'deposit',
      defaultOrder: 18,
      order: 18,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_all_median',
      minWidth: '126',

      filterable: true,
      group: 'deposit',
      defaultOrder: 21,
      order: 21,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_profit_sum',
      minWidth: '140',

      filterable: true,
      defaultOrder: 53,
      order: 53,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_sports_profit_sum',
      minWidth: '156',

      filterable: true,
      defaultOrder: 51,
      order: 51,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'bet_result_games_profit_sum',
      minWidth: '164',

      filterable: true,
      defaultOrder: 52,
      order: 52,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'visits_per_impression_percent',
      minWidth: '100',

      filterable: true,
      group: 'raising',
      defaultOrder: 4,
      order: 4,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'registrations_per_visit_percent',
      minWidth: '100',

      filterable: true,
      group: 'raising',
      defaultOrder: 6,
      order: 6,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_first_per_registration_percent',
      minWidth: '100',

      filterable: true,
      group: 'deposit',
      defaultOrder: 12,
      order: 12,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_first_per_visit_percent',
      minWidth: '100',

      filterable: true,
      group: 'deposit',
      defaultOrder: 11,
      order: 11,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'median_player_profit_category',
      minWidth: '150',
      filterable: true,
      defaultOrder: 66,
      order: 66,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'avg_player_profit_category',
      minWidth: '150',
      filterable: true,
      defaultOrder: 67,
      order: 67,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposit_bonus_sum',
      minWidth: '160',
      filterable: true,

      defaultOrder: 24,
      order: 24,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposit_bonus_count',
      minWidth: '150',
      filterable: true,
      defaultOrder: 23,
      order: 23,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'players_uniq_count',
      minWidth: '134',
      filterable: true,
      defaultOrder: 58,
      order: 58,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'affiliates_uniq_count',
      minWidth: '144',
      filterable: true,
      defaultOrder: 59,
      order: 59,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'avg_bet_odd',
      minWidth: '130',

      filterable: true,
      computedValue(row) {
        return row.avg_bet_odd === null ? 0 : row.avg_bet_odd;
      },
      defaultOrder: 46,
      order: 46,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'median_bet_odd',
      minWidth: '130',

      filterable: true,
      computedValue(row) {
        return row.median_bet_odd === null ? 0 : row.median_bet_odd;
      },
      defaultOrder: 47,
      order: 47,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'new_players_profit_sum',
      minWidth: '160',
      filterable: true,

      defaultOrder: 61,
      order: 61,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'new_players_affiliate_program_profit_sum',
      minWidth: '205',
      filterable: true,

      defaultOrder: 65,
      order: 65,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'median_bet_amount',
      minWidth: '120',

      filterable: true,
      defaultOrder: 40,
      order: 40,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'avg_bet_amount',
      minWidth: '120',

      filterable: true,
      defaultOrder: 41,
      order: 41,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'avg_sports_bet_amount',
      minWidth: '120',

      filterable: true,
      defaultOrder: 42,
      order: 42,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'median_sports_bet_amount',
      minWidth: '120',

      filterable: true,
      defaultOrder: 43,
      order: 43,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'avg_games_bet_amount',
      minWidth: '120',

      filterable: true,
      defaultOrder: 44,
      order: 44,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'median_games_bet_amount',
      minWidth: '120',

      filterable: true,
      defaultOrder: 45,
      order: 45,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'chargebacks_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 68,
      order: 68,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'chargebacks_players_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 69,
      order: 69,
      selected: true,
      shadowSelected: true,
    },

    // //////////////ncurrency
    {
      name: 'deposits_first_cpa_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 70,
      order: 70,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_first_revshare_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 71,
      order: 71,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_first_no_revenue_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 72,
      order: 72,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_first_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 73,
      order: 73,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_first_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 74,
      order: 74,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_first_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 75,
      order: 75,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_other_cpa_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 76,
      order: 76,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_other_revshare_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 77,
      order: 77,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_other_no_revenue_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 78,
      order: 78,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'deposits_other_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 79,
      order: 79,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_other_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 80,
      order: 80,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_other_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 81,
      order: 81,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_all_cpa_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 82,
      order: 82,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_all_revshare_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 83,
      order: 83,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_all_no_revenue_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 84,
      order: 84,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_all_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 85,
      order: 85,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_all_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 86,
      order: 86,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_all_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 87,
      order: 87,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_withdrawals_cpa_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 88,
      order: 88,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_withdrawals_revshare_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 89,
      order: 89,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_withdrawals_no_revenue_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 90,
      order: 90,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_withdrawals_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 91,
      order: 91,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_withdrawals_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 92,
      order: 92,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_withdrawals_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 93,
      order: 93,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_flow_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 94,
      order: 94,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 95,
      order: 95,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 96,
      order: 96,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 140,
      order: 140,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_sports_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 141,
      order: 141,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'bet_result_sports_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 142,
      order: 142,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_sports_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 97,
      order: 97,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_games_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 98,
      order: 98,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_games_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 99,
      order: 99,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_games_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 100,
      order: 100,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_sports_profit_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 101,
      order: 101,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_sports_profit_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 102,
      order: 102,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_sports_profit_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 103,
      order: 103,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_games_profit_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 104,
      order: 104,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_games_profit_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 105,
      order: 105,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_games_profit_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 106,
      order: 106,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_profit_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 107,
      order: 107,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_profit_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 108,
      order: 108,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_result_profit_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 109,
      order: 109,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'bet_sell_profit_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 110,
      order: 110,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'bet_sell_profit_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 111,
      order: 111,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_sell_profit_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 112,
      order: 112,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'bet_insurance_result_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 113,
      order: 113,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_insurance_result_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 114,
      order: 114,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'bet_insurance_result_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 115,
      order: 115,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'profit_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 116,
      order: 116,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'profit_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 117,
      order: 117,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'profit_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 118,
      order: 118,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'new_players_profit_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 119,
      order: 119,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'new_players_profit_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 120,
      order: 120,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'new_players_profit_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 121,
      order: 121,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'revenue_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 122,
      order: 122,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'revenue_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 123,
      order: 123,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'revenue_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 124,
      order: 124,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'affiliate_program_profit_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 125,
      order: 125,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'affiliate_program_profit_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 126,
      order: 126,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'new_players_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 127,
      order: 127,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'new_players_revenue_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 128,
      order: 128,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'new_players_revenue_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 129,
      order: 129,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'new_players_revenue_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 130,
      order: 130,
      selected: true,
      shadowSelected: true,
    },
    {
      name: 'new_players_chargebacks_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 131,
      order: 131,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'new_players_chargebacks_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 132,
      order: 132,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'new_players_affiliate_program_profit_cpa_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 133,
      order: 133,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'new_players_affiliate_program_profit_revshare_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 134,
      order: 134,
      selected: false,
      shadowSelected: false,
    },

    // ///////////////////  check formater
    {
      name: 'players_avg_lifetime_seconds',
      minWidth: '120',

      filterable: true,
      defaultOrder: 135,
      order: 135,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'players_median_lifetime_seconds',
      minWidth: '120',

      filterable: true,
      defaultOrder: 136,
      order: 136,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'players_avg_fd_since_registration_seconds',
      minWidth: '120',

      filterable: true,
      defaultOrder: 137,
      order: 137,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'players_median_fd_since_registration_seconds',
      minWidth: '120',

      filterable: true,
      defaultOrder: 138,
      order: 138,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'players_avg_deposits_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 139,
      order: 139,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'players_avg_deposits_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 143,
      order: 143,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'players_avg_withdrawals_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 144,
      order: 144,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'players_avg_withdrawals_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 145,
      order: 145,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'players_avg_profit_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 146,
      order: 146,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'players_median_profit_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 147,
      order: 147,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'affiliate_payments_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 148,
      order: 148,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'affiliate_payments_count',
      minWidth: '120',

      filterable: true,
      defaultOrder: 134,
      order: 134,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'affiliate_payments_profit_percent',
      minWidth: '120',

      filterable: true,
      defaultOrder: 149,
      order: 149,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'affiliate_program_profit_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 150,
      order: 150,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'new_players_affiliate_program_profit_no_revenue_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 151,
      order: 151,
      selected: false,
      shadowSelected: false,
    },
    {
      name: 'deposits_withdrawals_diff_sum',
      minWidth: '120',

      filterable: true,
      defaultOrder: 152,
      order: 152,
      selected: false,
      shadowSelected: false,
    },
    // additional_columns
    {
      name: 'affiliate_created_at',
      minWidth: '120',

      filterable: true,
      defaultOrder: 153,
      order: 153,
      selected: false,
      shadowSelected: false,
      filterInputType: 'date',
      formatOptions: {
        in: 'YYYY-MM-DD',
        out: 'DD MMM YYYY',
      },
    },
    {
      name: 'referred_affiliate_created_at',
      minWidth: '120',

      filterable: true,
      defaultOrder: 154,
      order: 154,
      selected: false,
      shadowSelected: false,
      filterInputType: 'date',
      formatOptions: {
        in: 'YYYY-MM-DD',
        out: 'DD MMM YYYY',
      },
    },
    {
      name: 'external_id',
      minWidth: '120',
      filterable: true,
      defaultOrder: 155,
      order: 155,
      selected: false,
      shadowSelected: false,
      allowedOperators: ['=', '!='],
    },
    {
      name: 'subid1',
      minWidth: '120',
      filterable: true,
      defaultOrder: 156,
      order: 156,
      selected: false,
      shadowSelected: false,
      allowedOperators: ['=', '!='],
    },
    {
      name: 'subid2',
      minWidth: '120',
      filterable: true,
      defaultOrder: 157,
      order: 157,
      selected: false,
      shadowSelected: false,
      allowedOperators: ['=', '!='],
    },
    {
      name: 'subid3',
      minWidth: '120',
      filterable: true,
      defaultOrder: 158,
      order: 158,
      selected: false,
      shadowSelected: false,
      allowedOperators: ['=', '!='],
    },
    {
      name: 'subid4',
      minWidth: '120',
      filterable: true,
      defaultOrder: 159,
      order: 159,
      selected: false,
      shadowSelected: false,
      allowedOperators: ['=', '!='],
    },
    {
      name: 'subid5',
      minWidth: '120',
      filterable: true,
      defaultOrder: 160,
      order: 160,
      selected: false,
      shadowSelected: false,
      allowedOperators: ['=', '!='],
    },

    {
      name: 'first_name',
      minWidth: '130',
      filterable: true,
      defaultOrder: 161,
      order: 161,
      selected: false,
      shadowSelected: false,
      allowedOperators: ['=', '!='],
    },
    {
      name: 'last_name',
      minWidth: '130',
      filterable: true,
      defaultOrder: 162,
      order: 162,
      selected: false,
      shadowSelected: false,
      allowedOperators: ['=', '!='],
    },
    {
      name: 'email',
      minWidth: '180',
      filterable: true,
      defaultOrder: 163,
      order: 163,
      selected: false,
      shadowSelected: false,
      allowedOperators: ['=', '!='],
    },
    {
      name: 'phone_number',
      minWidth: '140',
      filterable: true,
      defaultOrder: 164,
      order: 164,
      selected: false,
      shadowSelected: false,
      allowedOperators: ['=', '!='],
    },

    // additional_columns <<
  ],
  metricExaple: {
    name: 'col',
    minWidth: '130',
    filterable: true,
    defaultOrder: 1,
    order: 1,
    selected: false,
    shadowSelected: false,
  },
};

export default Config;
