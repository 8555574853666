export default {
  players: {
    name: 'Players',
    menu: {
      playersList: 'Players List',
    },
    fields: {
      site_player_id: {
        title: 'ID',
      },
      player_country_name: {
        title: 'Country',
      },
      phone_number: {
        title: 'Phone number',
      },
      affiliate_email: {
        title: 'Affiliate',
      },
      is_virtual: {
        title: 'Virtual account',
      },
      email: {
        title: 'Email',
      },
      first_name: {
        title: 'Name',
      },
      created_at: {
        title: 'Created at',
      },
    },
    nav: {
      info: 'Information',
      last: 'Last Operations',
      crm: 'CRM',
    },
    playersList: {
      players: 'Players',
      change: 'Change',
      res_filter: 'Reset Filters',
      refresh: 'Refresh',
      yes: 'Yes',
      no: 'No',
      select_filter: 'Please select a number of filters to get a list of interested players or input an ID in a search.',
      confirm: {
        title: 'Change Account',
        to_real: 'to Real?',
        to_virtuals: 'to Virtual?',
        msg: 'Warning! This action can\'t be undone.',
        msg_1: 'If you are sure you want to change account {player_id} to virtual, type {yes} below and press Change',
        yes: 'YES',
        real: 'real?',
        virtual: 'virtual?',
      },
    },
    pinPlayer: {
      pinned: 'Pin Player',
      to_affiliate: 'to Affiliate',
      top_text_1: 'Choose an affilaite and offer to pin him to the Player',
      top_text_2: 'Choose a new affiliate and offer for the',
      player: 'Player',
      or_unpin: 'or unpin current',
      aff: 'Affiliate',
      aff_req: 'This field is required',
      placeholder_aff: 'Select Affiliate',
      unpin: 'Unpin',
      offer: 'Offer',
      placeholder_offer: 'Select Offer',
      cancel: 'Cancel',
      pin_aff: 'Pin Affiliate',
      save: 'Save',
    },
    confirm: {
      title_1: 'Change player to Affiliate',
      msg_1_1: 'Change player:',
      msg_1_2: 'to',
      msg_1_3: '?',
      action_1: 'Link',

      title_2: 'Change player from Affiliate',
      msg_2_2: 'from',
      msg_2_3: '?',
      action_2: 'Unpin',

      title_3_1: 'Change Account',
      title_3_2: 'to Real?',
      title_3_3: 'to Virtual?',
      msg_3_1: 'Are you sure you want to make account',
      msg_3_2: 'real?',
      msg_3_3: 'virtual?',
      action_3: 'Change',

    },
    info: {
      info: 'Information',
      country: 'Сountry',
      create_at: 'Created At',
      verif_account: 'Virtual Account',
      aff: 'Affiliate',
      edit: 'Edit',
      pin: 'Pin',
      unpin: 'Unpin',
      payment_method: 'Top Payment Methods',
      no_data: 'No Data',
      top_sport: 'Top Sports',
      top_leagues: 'Top Leagues',
      bets: 'Bets',
      blocked_by_security_service: 'The player was blocked by Security Service',
    },
    crm: {
      title: 'Interaction History',
      fields: {
        language: {
          title: 'Language',
        },
        activity_name: {
          title: 'Activity',
        },
        event_timestamp: {
          title: 'Date',
        },
        type: {
          title: 'Type',
        },
      },
    },
  },
};
