<template>
  <div
    v-loading="isDataLoading"
    class="estimated-payment"
  >
    <div class="head" />
    <div class="info">
      <div class="wrapper">
        <div class="card">
          <div class="title">
            <span>{{ $t(`affiliates.estimatedPayment.title`) }}</span>
          </div>
          <div class="card-content">
            <div class="field">
              <span class="label">{{ $t(`affiliates.estimatedPayment.total`) }}</span>
              <div class="value color">
                {{ currencyLabel }}{{ info.amount | formatMoney }}
              </div>
            </div>
            <div class="field">
              <span class="label">{{ $t(`affiliates.estimatedPayment.next_payment`) }}</span>
              <div class="values">
                {{ formatDate(info.next_payment_timestamp, { out: 'DD MMM YYYY HH:mm' }) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="stats">
      <div class="wrapper">
        <div class="content">
          <div class="header">
            <span class="title">{{ $t(`affiliates.estimatedPayment.offer_static`) }}</span>
            <div class="tools">
              <el-date-picker
                v-if="include_players !== 'to_be_paid_in_next_payment' && include_players !== 'to_be_paid_after_next_payment'"
                v-model="dateRange"
                :end-placeholder="$t('reports.other.end_date')"
                :picker-options="{
                  firstDayOfWeek: 1,
                  shortcuts: shortcutsDates
                }"
                :start-placeholder="$t('reports.other.start_date')"
                class="date-picker"
                :class="{'isPeriod': dateRange.length > 0}"
                clear-icon="fas fa-times-circle"
                format="dd MMM yyyy"
                prefix-icon="far fa-calendar-alt"
                range-separator="-"
                type="daterange"
              />
              <el-select
                v-model="include_players"
                :placeholder="$t(`affiliates.estimatedPayment.players`)"
                class="columns"
                popper-class="columns_popper"
                collapse-tags
                @change="handleSelectIncludePlayers"
              >
                <el-option
                  v-for="(item, key) in playersFilterSelect"
                  :key="key"
                  :label="`${$t(`affiliates.estimatedPayment.playersFilter.${item}.title`)}`"
                  :value="item"
                />
              </el-select>
            </div>
          </div>
          <el-table
            v-if="!$_.isEmpty(info)"
            :data="info.offers_stats.data"
            :summary-method="getSummaryRow"
            lazy-loading
            show-summary
            border
            class="table"
          >
            <el-table-column
              :width="450"
              :label="$t(`affiliates.estimatedPayment.offer`)"
              prop="offer_name"
              align="left"
              header-align="left"
            />
            <el-table-column
              :width="96"
              :label="$t('payments.info.table_players')"
              prop="uniq_players_count"
              align="right"
              header-align="right"
            >
              <template slot-scope="scope">
                <div
                  class="row-value"
                >
                  <span>{{ scope.row.uniq_players_count }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :width="104"
              :label="getCurrencyHeaderLabel($t(`affiliates.estimatedPayment.revshare`))"
              prop="revshare_revenue"
              align="right"
              header-align="right"
            >
              <template slot-scope="scope">
                <div
                  :class="getCurrencyClass(scope.row.revshare_revenue)"
                  class="row-value"
                >
                  <span>{{ currencyLabel }}{{ scope.row.revshare_revenue | formatMoney }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :width="96"
              :label="getCurrencyHeaderLabel($t(`affiliates.estimatedPayment.cpa`))"
              prop="cpa_revenue"
              align="right"
              header-align="right"
            >
              <template slot-scope="scope">
                <div
                  :class="getCurrencyClass(scope.row.cpa_revenue)"
                  class="row-value"
                >
                  <span>{{ currencyLabel }}{{ scope.row.cpa_revenue | formatMoney }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :width="96"
              :label="getCurrencyHeaderLabel($t(`affiliates.estimatedPayment.total`))"
              prop="revenue"
              align="right"
              header-align="right"
            >
              <template slot-scope="scope">
                <div
                  :class="getCurrencyClass(scope.row.revenue)"
                  class="row-value"
                >
                  <span>{{ currencyLabel }}{{ scope.row.revenue | formatMoney }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div
      v-loading="isPlayersLoading"
      class="players"
    >
      <div class="wrapper">
        <div class="header">
          <span class="title">{{ $t(`affiliates.estimatedPayment.cpa_player`) }}</span>
          <div class="tools">
            <el-checkbox
              v-model="hide_stats_after_conversion"
              class="check"
              @change="getPlayers"
            >
              {{ $t(`affiliates.estimatedPayment.hide_stats`) }}
            </el-checkbox>
            <el-select
              v-model="affiliate_offer_id"
              :class="{'two': affiliate_offer_id.length > 1}"
              :placeholder="$t(`affiliates.estimatedPayment.all_offer`)"
              class="offer-select"
              popper-class="columns_popper"
              clearable
              multiple
              collapse-tags
              @change="handleSelectOffer"
            >
              <el-option
                v-for="(item, key) in offersSelectOptions"
                :key="key"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count || 1"
              show-size-select
              @page-change="pageChange"
            />
            <div class="select">
              <ui-button
                class="btn"
                lib="fa"
                substyle="fas"
                icon="eye"
                @click="openColumnsSelect"
              />
              <el-select
                id="select_group"
                ref="select_group"
                v-model="selectedFields"
                :placeholder="$t(`affiliates.estimatedPayment.col_select`)"
                class="columns"
                popper-class="columns_popper"
                clearable
                multiple
                collapse-tags
                @change="handleSelectColumns"
              >
                <el-option
                  v-for="(item, key) in playersFields"
                  :key="key"
                  :label="`${$t(`affiliates.estimatedPayment.cpaPlayers.fields.${item.name}.title`)}
                  ${$te(`affiliates.estimatedPayment.cpaPlayers.fields.${item.name}.tip`) ? '' : ''}`"
                  :value="item.name"
                />
              </el-select>
            </div>
            <export-data
              :data="configExport"
              @exportData="exportPlayers"
            />
          </div>
        </div>
        <ui-table
          :lazy-loading-delay="2000"
          :fields="$_.filter(playersFields, field => selectedFields.includes(field.name))"
          :currency="cpaCurrencyCode"
          :data="playersStats"
          :total="playerSums"
          :rows-count="limit"
          :external-height="playersTableHeight"
          :sort="{
            prop: sortBy,
            order: sortDir,
          }"
          lazy-loading
          show-total
          is-static
          i18n-path="affiliates.estimatedPayment.cpaPlayers"
          class="max-h"
          element-loading-custom-class="data-loader-spinner"
          @sort="handleSortPlayers"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import numeral from 'numeral';
import updateUrl from '../../../service/updateUrl';
import ExportData from '@/components/ExportData';

export default {
  name: 'EstimatedPayment',
  components: {
    ExportData,
  },
  data() {
    return {
      id: '',
      from: '',
      include_players: 'to_be_paid_in_next_payment',
      affiliate_offer_id: [],
      info: {},
      currencyCode: '',
      cpaCurrencyCode: '',
      affiliateInfo: {},
      isDataLoading: true,
      backLink: {
        link: '',
        title: '',
      },
      exportUrls: {},
      configExport: [
        {
          command: 'csv',
          label: 'CSV',
        },
        {
          command: 'xlsx',
          label: 'XLSX',
        },
        {
          command: 'csv_network',
          label: `CSV ( ${this.$t('affiliates.estimatedPayment.for_net')} )`,
        },
        {
          command: 'xlsx_network',
          label: `XLSX ( ${this.$t('affiliates.estimatedPayment.for_net')} )`,
        },
      ],
      playersStats: [],
      playerSums: {},
      playersFields: [
        {
          name: 'site_player_id',
          minWidth: 150,
          align: 'left',
          sortable: 'custom',
          link: this.getPlayerLink,
          action: () => {},
          actionLib: 'fa',
          controlType: 'icon',
          controlColorIco: () => this.$theme.dangerColor,
          popperClass: 'light-tooltip',
          actionName: row => (row.is_blocked ? 'lock' : ''),
          computedClass: (_, row) => (row.is_blocked ? 'right-action-icon' : ''),
          tooltip: 'players.info.blocked_by_security_service',
        },
        {
          name: 'external_id',
          minWidth: 110,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'subid1',
          minWidth: 100,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'subid2',
          minWidth: 100,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'subid3',
          minWidth: 100,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'subid4',
          minWidth: 100,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'subid5',
          minWidth: 100,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'player_country_name',
          minWidth: 100,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'registration_timestamp',
          minWidth: 135,
          align: 'left',
          sortable: 'custom',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD.MM.YYYY HH:mm',
          },
        },
        {
          name: 'site_registration_type_name',
          minWidth: 135,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'first_deposit_timestamp',
          minWidth: 140,
          align: 'left',
          sortable: 'custom',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD.MM.YYYY HH:mm',
          },
        },
        {
          name: 'conversion_timestamp',
          minWidth: 135,
          align: 'left',
          sortable: 'custom',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD.MM.YYYY HH:mm',
          },
        },
        {
          name: 'hold_expiration_timestamp',
          minWidth: 135,
          align: 'left',
          sortable: 'custom',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD.MM.YYYY HH:mm',
          },
        },
        {
          name: 'latest_activity_timestamp',
          minWidth: 130,
          align: 'left',
          sortable: 'custom',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD.MM.YYYY HH:mm',
          },
        },
        {
          name: 'latest_deposit_timestamp',
          minWidth: 130,
          align: 'left',
          sortable: 'custom',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD.MM.YYYY HH:mm',
          },
        },
        {
          name: 'days_since_reg',
          minWidth: 130,
          align: 'right',
          sortable: 'custom',
        },
        {
          name: 'days_active',
          minWidth: 110,
          align: 'right',
          sortable: 'custom',
        },
        {
          name: 'top_deposits_payment_system_name',
          minWidth: 170,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'top_withdrawals_payment_system_name',
          minWidth: 170,
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'deposits_count',
          minWidth: 130,
          align: 'right',
          sortable: 'custom',
        },
        {
          name: 'deposits_sum',
          minWidth: 120,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'withdrawals_count',
          minWidth: 150,
          align: 'right',
          sortable: 'custom',
        },
        {
          name: 'withdrawals_sum',
          minWidth: 150,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'sport_bets_count',
          minWidth: 140,
          align: 'right',
          sortable: 'custom',
        },
        {
          name: 'sport_bets_sum',
          minWidth: 130,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'avg_sport_bets_odd',
          minWidth: 150,
          align: 'right',
          sortable: 'custom',
          format: 'odd',
        },
        {
          name: 'median_sport_bets_odd',
          minWidth: 150,
          align: 'right',
          sortable: 'custom',
          format: 'odd',
        },
        {
          name: 'games_bets_count',
          minWidth: 150,
          align: 'right',
          sortable: 'custom',
        },
        {
          name: 'games_bets_sum',
          minWidth: 140,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'betting_profit',
          minWidth: 120,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'cpa_revenue',
          minWidth: 120,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'revshare_revenue',
          minWidth: 120,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'revenue',
          minWidth: 100,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
        },
        {
          name: 'total_profit',
          minWidth: 90,
          align: 'right',
          sortable: 'custom',
          format: 'formatMoney',
          computedClass(value) {
            return value < 0 ? 'negative' : '';
          },
        },
      ],
      selectedFields: [],
      isPlayersLoading: false,
      page: 1,
      limit: 10,
      sortBy: 'site_player_id',
      sortDir: 'asc',
      count: 0,
      hide_stats_after_conversion: false,
      playersFilterSelect: [
        'to_be_paid_in_next_payment', 'to_be_paid_after_next_payment', 'all_unpaid_players', 'all_paid_players', 'all_players',
      ],
      shortcutsDates: [
        {
          text: this.$t('reports.date_picker.last_30_day'),
          range: [
            this.$moment().startOf('day').subtract(30, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.this_month'),
          range: [
            this.$moment().startOf('month'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.prev_month'),
          range: [
            this.$moment().subtract(1, 'months').startOf('month'),
            this.$moment().subtract(1, 'months').endOf('month'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.last_90_day'),
          range: [
            this.$moment().startOf('day').subtract(90, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
      ],
      dateRange: [],
    };
  },
  computed: {
    ...mapGetters({
      currencies: 'misc/currencies',
    }),
    currencyLabel() {
      const currency = this.currencies.find((el => el.code === this.currencyCode));

      return currency?.symbol || `${this.currencyCode} `;
    },
    statisticQuery() {
      const params = {
        include_players: this.include_players,
      };
      if (this.include_players !== 'to_be_paid_in_next_payment' && this.include_players !== 'to_be_paid_after_next_payment' && !this.$_.isEmpty(this.dateRange)) {
        params.start_timestamp = this.dateRange[0] ? this.$moment(this.dateRange[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '';
        params.end_timestamp = this.dateRange[1] ? this.$moment(this.dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss') : '';
      }
      return params;
    },
    playersQuery() {
      const params = {
        limit: this.limit,
        offset: (this.page * this.limit) - this.limit,
        sort_column: this.sortBy,
        sort_dir: this.sortDir,
        hide_stats_after_conversion: this.hide_stats_after_conversion,
        include_players: this.include_players,
        affiliate_offer_id: this.affiliate_offer_id,
      };
      if (this.include_players !== 'to_be_paid_in_next_payment' && this.include_players !== 'to_be_paid_after_next_payment' && !this.$_.isEmpty(this.dateRange)) {
        params.start_timestamp = this.dateRange[0] ? this.$moment(this.dateRange[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '';
        params.end_timestamp = this.dateRange[1] ? this.$moment(this.dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss') : '';
      }
      return params;
    },
    playersTableHeight() {
      if (this.count === 0) {
        return 200;
      }

      return this.count < this.limit ? (this.count * 32) + 88 : (this.limit * 32) + 88;
    },
    offersSelectOptions() {
      return this.info.offers_stats ? this.$_.map(this.info.offers_stats.data, item => ({ id: item.affiliate_offer_id, name: item.offer_name })) : [];
    },
  },
  watch: {
    dateRange(nv) {
      if (!nv) {
        this.dateRange = [];
      }
      if (!this.isDataLoading) {
        this.getPaymentsReportInfo();
      }
    },
  },

  created() {
    /*
     * Проверяем hash
     * */
    const _defaultColumns = [
      'site_player_id',
      'player_country_name',
      'registration_timestamp',
      'site_registration_type_name',
      'conversion_timestamp',
      'hold_expiration_timestamp',
      'days_active',
      'deposits_count',
      'deposits_sum',
      'withdrawals_sum',
      'betting_profit',
      'cpa_revenue',
      'revshare_revenue',
      'chargebacks_sum',
      'total_profit',
    ];

    const _paramsHash = updateUrl.getParseParams(this.$route.hash);

    if (!this.$_.isEmpty(_paramsHash)) {
      this.sortBy = this.$_.get(_paramsHash, ['sort_column']) || 'site_player_id';
      this.sortDir = this.$_.get(_paramsHash, ['sort_dir']) || 'asc';
      this.include_players = this.$_.get(_paramsHash, ['include_players']) || this.include_players;
      this.hide_stats_after_conversion = this.$_.get(_paramsHash, ['hide_stats_after_conversion']) || this.hide_stats_after_conversion;
      this.affiliate_offer_id = this.$_.get(_paramsHash, ['affiliate_offer_id']) || this.affiliate_offer_id;
      this.selectedFields = Object.values(this.$_.get(_paramsHash, ['columns']) || _defaultColumns);
      this.limit = Number(this.$_.get(_paramsHash, ['limit'])) || this.limit;
      this.page = Number(this.$_.get(_paramsHash, ['page'])) || 1;
      this.dateRange = [this.$_.get(_paramsHash, ['start_timestamp']), this.$_.get(_paramsHash, ['end_timestamp'])];
    } else {
      this.selectedFields = JSON.parse(this.$ls.get('affiliateEstimatedPaymentCpaPlayersColumns')) || _defaultColumns;
    }
  },

  mounted() {
    this.id = this.$route.params.id;
    this.getPaymentsReportInfo();
    this.$eventBus.$on('changeLocaleGlobal', this.changeLocaleGlobal);
  },

  destroyed() {
    this.$eventBus.$off('changeLocaleGlobal', this.changeLocaleGlobal);
  },

  methods: {
    getCurrencyHeaderLabel(label) {
      if (this.currencyCode) {
        return `${label}, ${this.currencyLabel.trim()}`;
      }

      return label;
    },
    changeLocaleGlobal() {
      this.getPlayers('silent');
    },

    getSummaryRow() {
      const sums = [
        this.$t('affiliates.estimatedPayment.total'),
        this.info.offers_stats.totals.uniq_players_count,
        this.formatCurrency(this.info.offers_stats.totals.revshare_revenue),
        this.formatCurrency(this.info.offers_stats.totals.cpa_revenue),
        this.formatCurrency(this.info.offers_stats.totals.revenue),
      ];
      return sums;
    },
    formatCurrency(value) {
      return `${this.currencyLabel}${value < 0 ? '-' : ''}${numeral(Math.abs(value)).format('0,0.00')}`;
    },
    formatDate(value, options) {
      if (value) {
        if (options) {
          const m = value._isAMomentObject ? value : this.$moment(value, options.in).locale(this.$i18n.locale);
          return m.isValid() ? m.locale(this.$i18n.locale).format(options.out) : value;
        }
        return this.$moment(value).locale(this.$i18n.locale).format('DD MMM YYYY');
      }
      return value;
    },
    formatActivity(value) {
      return this.$moment(value).locale(this.$i18n.locale).format('DD MMM YYYY HH:mm');
    },
    getCurrencyClass(value) {
      return value < 0 ? 'negative' : '';
    },
    getPaymentsReportInfo() {
      this.isDataLoading = true;
      /*
      * Refresh URL
      * */
      this._completedFilterUrl();

      this.$api.getPaymentsReportInfo(this.id, this.statisticQuery)
        .then((response) => {
          const { payload, misc } = response.data;
          this.currencyCode = misc.currency_code;
          this.info = this.$_.cloneDeepWith(payload);
          this.getPlayers();
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },

    getPlayers(action) {
      if (action !== 'silent') {
        this.isPlayersLoading = true;
      }

      /*
      * Refresh URL
      * */
      this._completedFilterUrl();

      this.$api.getPaymentsReportCpaPlayersInfo(this.id, this.playersQuery).then((response) => {
        const { payload, misc } = response.data;
        this.count = misc.count;
        this.cpaCurrencyCode = misc.currency_code;
        this.exportUrls = misc.export_urls;
        this.playersStats = payload.data;
        this.playerSums = payload.totals;
      })
        .finally(() => {
          if (action !== 'silent') {
            this.isPlayersLoading = false;
          }
        });
    },

    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.getPlayers();
      }
    },
    openColumnsSelect() {
      this.$refs.select_group.toggleMenu();
    },
    handleSelectColumns() {
      this.$ls.set('affiliateEstimatedPaymentCpaPlayersColumns', JSON.stringify(this.selectedFields));
    },
    handleSelectOffer() {
      this.getPlayers();
    },
    handleSelectIncludePlayers() {
      this.getPaymentsReportInfo();
    },
    handleSortPlayers({ prop, order }) {
      if (this.sortBy !== prop) {
        this.sortBy = prop;
      } else {
        this.sortDir = order;
      }
      this.getPlayers();
    },
    exportPlayers(format) {
      window.open(this.exportUrls[format]);
    },
    getPlayerLink(row) {
      const isUser = !this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl'].players_view === 'allow';
      const isSuperUser = this.$store.getters['auth/adminAcl'].is_superuser;
      return isSuperUser || isUser ? `/players/${row.player_id}/info` : false;
    },
    /*
    * Создаем filter URL
    * */
    _completedFilterUrl() {
      const _dataFilters = {
        limit: this.limit,
        page: this.page,
        sort_column: this.sortBy,
        sort_dir: this.sortDir,
        hide_stats_after_conversion: this.hide_stats_after_conversion,
        include_players: this.include_players,
        affiliate_offer_id: this.affiliate_offer_id,
        columns: this.selectedFields,
      };
      if (this.include_players !== 'to_be_paid_in_next_payment' && this.include_players !== 'to_be_paid_after_next_payment' && !this.$_.isEmpty(this.dateRange)) {
        _dataFilters.start_timestamp = this.dateRange[0] ? this.$moment(this.dateRange[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '';
        _dataFilters.end_timestamp = this.dateRange[1] ? this.$moment(this.dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss') : '';
      }
      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>
