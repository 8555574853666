<template>
  <div
    v-loading="!isMetricsLoaded"
    class="crm-activity-metrics"
  >
    <activity-metrics-popup
      ref="activityMetricsPopup"
      :selected-metrics="metrics"
      @select="handleAddMetric"
    />
    <div class="ui-d-flex ui-jc-space-between ui-m-xl-b">
      <panel-header-title>
        {{ $t('crm.activities.metrics.title') }}
      </panel-header-title>
      <div class="ui-d-flex ui-g-w-md">
        <template v-if="pPreviewMode">
          <div
            v-if="cooldownStats.length > 0"
            class="ui-d-flex ui-ai-center ui-g-md"
          >
            <div
              v-for="stats in cooldownStats"
              :key="stats.key"
              class="crm-activity-metrics__cooldown-preview-param ui-m-xl-r"
            >
              <i
                class="ui-m-sm-r"
                :class="stats.icon"
              />
              {{ stats.text }}
            </div>
          </div>
          <div class="ui-g-md">
            <el-date-picker
              v-show="isEditMetricsDate"
              ref="datepicker"
              v-model="metricsDateRange"
              :min-date="new Date()"
              :picker-options="{
                disabledDate: disabledDueDate,
              }"
              :start-placeholder="$t('reports.other.start_date')"
              :end-placeholder="$t('reports.other.end_date')"
              :placeholder="$t(`tools.match_popup.placeholder_date_time`)"
              format="dd MMM yyyy"
              type="daterange"
              class="date-picker-range"
              @change="onChangeActivityRange"
            />
            <ui-button
              v-if="!isEditMetricsDate"
              icon="calendar-alt"
              lib="fa"
              substyle="fa"
              color="green"
              class="btn ui-m-md-l"
              :disabled="isLoading"
              @click="isEditMetricsDate = !isEditMetricsDate"
            />
            <ui-button
              v-if="isEditMetricsDate"
              icon="times"
              lib="fa"
              substyle="fas"
              color="red"
              class="btn ui-m-md-l"
              :disabled="isLoading"
              @click="isEditMetricsDate = !isEditMetricsDate; onChangeActivityRange(null)"
            />
          </div>
          <ui-button
            v-if="!edit && canEdit"
            key="edit"
            icon="pen"
            lib="fa"
            substyle="fas"
            color="green"
            class="btn ui-g-md"
            :disabled="isLoading"
            @click="handleEdit"
          />
          <ui-button
            v-if="edit"
            key="save"
            icon="check"
            lib="fa"
            substyle="fas"
            color="green"
            class="btn ui-g-md"
            :disabled="isLoading"
            @click="handleSave"
          >
            {{ $t('crm.buttons.save') }}
          </ui-button>
          <ui-button
            v-if="edit"
            key="cancel"
            icon="times"
            lib="fa"
            substyle="fas"
            color="red"
            class="btn ui-g-md"
            :disabled="isLoading"
            @click="handleEdit"
          >
            {{ $t('crm.buttons.cancel') }}
          </ui-button>
        </template>
        <ui-button
          v-if="edit || !pPreviewMode"
          key="addMetric"
          icon="plus"
          lib="fa"
          substyle="fas"
          color="green"
          class="btn ui-g-md"
          :disabled="isLoading"
          @click="handleAddMetricPopupOpen"
        >
          {{ $t('crm.activities.info.add_metric') }}
        </ui-button>
      </div>
    </div>
    <panel
      v-if="!pPreviewMode"
      :error="errorsDetailed.length > 0"
      class="crm-activity-metrics__cooldown-panel ui-m-xl-b"
    >
      <div class="ui-d-flex ui-ai-center">
        <div class="ui-d-flex ui-ai-center ui-m-lg-r">
          <panel-header-subtext
            type="large"
            class="ui-m-sm-r"
          >
            {{ $t('crm.activities.metrics.cooldown') }}
          </panel-header-subtext>
          <ActionIcon
            class="ui-m-md-r"
            :tooltip="$t('crm.activities.metrics.cooldown_tooltip')"
          >
            <ui-icon
              name="question-circle"
              color="var(--tooltip-icon)"
              lib="fa"
              size="14px"
            />
          </ActionIcon>
          <el-switch
            v-model="activity.metrics_has_cooldown"
            :active-color="$theme.accentColor"
            :inactive-color="$theme.dangerColor"
          />
        </div>
        <ui-input
          v-model="activity.metrics_cooldown"
          class="crm-activity-metrics__hide-input-error ui-m-md-r"
          width="48px"
          :disabled="!activity.metrics_has_cooldown"
          :input-mask="'######'"
          :error="metricsCooldownError"
        />
        <panel-header-subtext
          type="large"
          class="ui-m-md-r"
        >
          {{ $t('crm.activities.metrics.days') }}
        </panel-header-subtext>
        <crm-error-panel
          v-if="metricsCooldownError"
          class="ui-ai-baseline"
        >
          {{ metricsCooldownError }}
        </crm-error-panel>
      </div>
    </panel>
    <div
      :class="{
        'crm-activity-metrics__group-list': true,
        'crm-activity-metrics__group-list_keep-height': keepHeight || !isMetricsLoaded,
        'ui-d-flex': true,
        'ui-m-xl-b': true,
        'ui-g-w-xl': true,
      }"
    >
      <template v-if="isMetricsLoaded">
        <template v-for="(metric, j) in computedMetrics">
          <div
            v-if="metric.name === 'special-add'"
            :key="j"
            :class="[
              'crm-activity-metrics__metric-card-add-wrapper',
              'crm-activity-metrics__metric-panel-wrapper',
              'ui-g-xl'
            ]"
          >
            <div
              :class="[
                'dashboard-overview__metric-card-add',
                'crm-activity-metrics__metric-card-add',
              ]"
              @click="handleAddMetricPopupOpen()"
            >
              <adm-ui-icon :icon="['fas', 'fa-plus']" />
              <adm-ui-header tag="h3">
                {{ $t('crm.activities.info.add_metric') }}
              </adm-ui-header>
            </div>
          </div>

          <panel
            v-else-if="metric.name === 'special-empty'"
            :key="j"
            class="crm-activity-metrics__no-metrics-panel ui-g-xl"
          >
            <crm-error-panel
              class="crm-activity-metrics__no-metrics-error"
              type="no-data"
            >
              {{ $t('crm.activities.metrics.no_metrics_selected_error') }}
            </crm-error-panel>
          </panel>
          <div
            v-else
            :key="j"
            class="crm-activity-metrics__metric-panel-wrapper"
          >
            <panel
              class="crm-activity-metrics__metric-panel ui-g-xl"
              type="compact"
            >
              <div
                :class="[
                  'crm-activity-metrics__title-wrapper',
                  'ui-d-flex',
                  'ui-ai-center',
                  'ui-jc-space-between',
                ]"
              >
                <panel-header-title
                  class="crm-activity-metrics__title"
                  type="compact"
                >
                  {{ metric.title }}
                </panel-header-title>
                <panel-action-icon
                  v-if="edit || !pPreviewMode"
                  :tooltip="$t('crm.tooltips.delete')"
                  icon="trash-alt"
                  :color="$theme.dangerColor"
                  :disabled="isLoading"
                  @click="handleDeleteMetric(metric.name)"
                />
              </div>
              <template v-if="!metric.isActionGroupsEmpty">
                <template v-for="(action, index) in metric.actionGroups">
                  <div
                    v-if="!action.value.is_control || controlGroupActive"
                    :key="index"
                    class="ui-m-md-b"
                  >
                    <div
                      class="crm-activity-metrics__metric-text-wrapper"
                      :class="{
                        'crm-activity-metrics__metric-text-wrapper_growth-positive': action.metricValues.growth >= 0,
                        'crm-activity-metrics__metric-text-wrapper_growth-negative': action.metricValues.growth < 0,
                      }"
                    >
                      <span class="ui-m-sm-r">{{ action.metricValues.value }}</span>
                      <span
                        v-if="pPreviewMode && action.metricValues.percent !== 0"
                        class="crm-activity-metrics__metric-text"
                        :class="{
                          'crm-activity-metrics__metric-text_success': action.metricValues.growth >= 0,
                          'crm-activity-metrics__metric-text_danger': action.metricValues.growth < 0,
                        }"
                      >
                        <i
                          v-if="action.metricValues.growth >= 0"
                          key="positive-arrow"
                          class="far fa-long-arrow-up"
                        />
                        <i
                          v-if="action.metricValues.growth < 0"
                          key="negative-arrow"
                          class="far fa-long-arrow-down"
                        />
                        <span>{{ action.metricValues.percent }}</span>
                      </span>
                    </div>
                    <div
                      class="crm-activity-metrics__metric-text-wrapper"
                    >
                      <div class="ui-d-flex ui-ai-center crm-activity-metrics__statistic-wrapper">
                        <ui-icon
                          name="solid user"
                          color="var(--tooltip-icon)"
                          lib="fa"
                          size="10px"
                          class="ui-m-sm-r"
                        />
                        <span class="ui-m-sm-r">{{ action.metricValues.playersCount }} ({{ action.metricValues.playersPercent }})</span>
                        <panel-delimiter
                          class="ui-m-sm-r crm-activity-actions__header-delimiter"
                          type="vertical"
                          color="contrast"
                        />
                        <span class="ui-m-sm-r">{{ action.metricValues.playersTotalCount }}</span>
                        <ActionIcon
                          class="ui-m-md-r"
                          :tooltip="$t('crm.activities.metrics.players_tooltip')"
                        >
                          <ui-icon
                            name="solid info-circle"
                            color="var(--tooltip-icon)"
                            lib="fa"
                            substyle="fas"
                            size="10px"
                          />
                        </ActionIcon>
                      </div>
                    </div>
                    <panel-header-subtext
                      class="crm-activity-metrics__metric-subtext"
                      :class="{
                        'crm-activity-metrics__metric-subtext_success': pPreviewMode && metric.leader === index,
                      }"
                      type="compact"
                    >
                      <template v-if="action.value.is_control">
                        {{ $t('crm.activities.actions.control_group') }}
                      </template>
                      <template v-else>
                        {{ $t('crm.activities.actions.group') }} {{ getLiter(index - (controlGroupActive ? 1 : 0)) }}
                      </template>
                    </panel-header-subtext>
                    <panel-delimiter
                      v-if="index < metric.actionGroups.length - 1"
                      class="ui-m-md-t"
                    />
                  </div>
                </template>
              </template>
              <crm-error-panel
                v-else
                class="crm-activity-metrics__metric-no-data"
                type="no-data"
              >
                {{ $t('crm.activities.metrics.no_avaliable_data_error') }}
              </crm-error-panel>
            </panel>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as Panel from '@/views/CRM/components/Panel';
// import ActivityMetricsPagination from '@/views/CRM/Activities/components/ActivityMetricsPagination.vue';
import ActivityMetricsPopup from '@/views/CRM/components/ActivityMetricsPopup.vue';
import ActivitiesActionsCommonMixin from '@/views/CRM/mixins/activities-actions-common-mixin';
import CrmErrorPanel from '@/views/CRM/components/CrmErrorPanel.vue';
import formatColumn from '@/views/Dashboard/helper/customFormat.js';
import CrmMetrics from '@/views/CRM/mixins/crm-metrics.js';

export default {
  components: {
    // ActivityMetricsPagination,
    ActivityMetricsPopup,
    ...Panel,
    CrmErrorPanel,
  },
  mixins: [
    ActivitiesActionsCommonMixin,
    CrmMetrics,
  ],
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    activity: {
      type: Object,
      default: () => ({}),
    },
    controlGroupActive: {
      type: Boolean,
      default: true,
    },
    metricValues: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      page: 0,
      pEdit: false,
      pMetrics: [],
      isLoading: false,
      metricsDateRange: [],
      isEditMetricsDate: false,
    };
  },
  computed: {
    ...mapGetters('reports', ['reportsSettings']),
    ...mapGetters('crm', ['crmMetrics']),
    errorsDetailed() {
      return [
        this.metricsCooldownError,
      ].filter(item => !!item);
    },
    metricsCooldownError() {
      return this.getError('metrics_cooldown');
    },
    cooldownStats() {
      const result = [];

      if (this.activity.metrics_has_cooldown) {
        result.push({
          key: 'cooldown',
          icon: 'fas fa-history',
          text: this.$tc('crm.activities.metrics.cooldown_stat', this.activity.metrics_cooldown, { cooldown: this.activity.metrics_cooldown }),
        });
      }

      if (this.activity.metrics_updated_at) {
        result.push({
          key: 'updated-at',
          icon: 'fas fa-clock',
          text: this.$t('crm.activities.metrics.updated_at_stat', { datetime: this.activity.metrics_updated_at }),
        });
      }

      return result;
    },
    edit() {
      return this.previewMode && this.pEdit;
    },
    metrics() {
      return this.edit
        ? this.pMetrics
        : this.activity.metrics || [];
    },
    keepHeight() {
      return false;
      // return !(this.previewMode && !this.edit && !this.metrics.length);
    },
    actionGroupsStats() {
      return this.activity.action_groups || [];
    },
    computedMetrics() {
      const { metrics } = this;
      const result = !metrics.length
        ? metrics.concat([
          this.edit || !this.previewMode
            ? 'special-add'
            : 'special-empty',
        ])
        : metrics;

      return result.map((metric) => {
        const actionGroups = this.getActionGroupMetrics(metric);
        const actionGroupsLeaderMeta = actionGroups.reduce((acc, item, index) => {
          const leaderResult = (!item.value.is_control || this.controlGroupActive) && item.metricValues.percent > acc.value
            ? {
              index,
              value: item.metricValues.percent,
            }
            : acc;
          return leaderResult;
        }, {
          index: -1,
          value: Number.MIN_SAFE_INTEGER,
        });

        const isActionGroupsEmpty = actionGroups.filter(item => !item.metricValues.isEmpty).length <= 0;

        return {
          name: metric,
          title: !['special-add', 'special-empty'].includes(metric)
            ? this.$_.get(this.metricsIndex, [metric, 'title'], metric)
            : '',
          actionGroups,
          leader: actionGroupsLeaderMeta.index,
          isActionGroupsEmpty,
        };
      });
    },
    metricsIndex() {
      const result = {};
      this.pCrmMetrics.forEach((item) => {
        result[item.column_name] = item;
      });
      return result;
    },
    pPreviewMode() {
      return this.previewMode || this.previewMode === '';
    },
    pMetricValues() {
      const metricValues = {};

      this.metricValues.forEach((metricValue) => {
        const result = metricValue.values.map((item) => {
          if (this.pPreviewMode) {
            const percent = typeof item.percent !== 'undefined'
              ? item.percent
              : 0;

            return {
              id: item.crm_action_group_id,
              value: formatColumn(item.value, this.$_.get(this.metricsIndex, [metricValue.name, 'column_format'], 'number')),
              percent: formatColumn(percent, 'percent'),
              growth: percent,
              playersCount: formatColumn(item.players_count, 'number'),
              playersPercent: formatColumn(item.players_percent, 'percent'),
              playersTotalCount: formatColumn(item.players_total_count, 'number'),
            };
          }
          return this.getMetricValueStub(item.crm_action_group_id);
        });

        result.sort((a, b) => {
          const aIndex = this.actionGroupsStatsIndex[a.id];
          const bIndex = this.actionGroupsStatsIndex[b.id];

          if (aIndex < bIndex) {
            return -1;
          }

          if (aIndex > bIndex) {
            return 1;
          }

          return 0;
        });

        metricValues[metricValue.name] = result;
      });

      return metricValues;
    },
    actionGroupsStatsSorted() {
      const result = this.actionGroupsStats
        .filter(item => this.controlGroupActive || !item.is_control)
        .map((value, index) => ({
          value,
          index,
        }));

      return result;
    },
    actionGroupsStatsIndex() {
      const result = {};

      this.actionGroupsStatsSorted.forEach((actionGroup, index) => {
        if (actionGroup && actionGroup.value && actionGroup.value.id) {
          result[actionGroup.value.id] = index;
        }
      });

      return result;
    },
    isMetricsLoaded() {
      return !this.$_.isEmpty(this.crmMetrics);
    },
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      async handler() {
        await this.$store.dispatch('crm/getMetrics');
      },
    },
  },
  methods: {
    onChangeActivityRange(date) {
      this.$emit('change-activity-range', date);
    },
    disabledDueDate(date) {
      return !this.$moment(date.getTime()).isBetween(
        this.$moment(this.activity.activity_period_start).subtract(1, 'd'),
        this.activity.activity_period_end,
      );
    },
    getMetricValueStub(id = '') {
      return {
        id,
        value: 0,
        percent: 0,
        growth: 0,
        isEmpty: true,
      };
    },
    getError(field) {
      return this.errors[field] && this.errors[field][0]
        ? this.errors[field][0].message
        : '';
    },
    getActionGroupMetrics(metric) {
      return this.actionGroupsStatsSorted.map((value, index) => ({
        ...value,
        value: {
          ...value.value,
          players_count: formatColumn(value.value.players_count, 'number'),
        },
        metricValues: this.getMetricValues(index, metric),
      }));
    },
    getMetricValues(actionGroupIndex, metric) {
      return this.$_.get(this.pMetricValues, [metric, actionGroupIndex], this.getMetricValueStub());
    },
    handleEdit() {
      const next = !this.pEdit;
      this.pEdit = next;

      if (next) {
        this.pMetrics = this.$_.cloneDeep(this.activity.metrics);
      } else {
        this.pMetrics = [];
      }
    },
    handleAddMetricPopupOpen() {
      if (!this.$refs.activityMetricsPopup) {
        return;
      }

      this.$refs.activityMetricsPopup.open();
    },
    handleAddMetric(metrics) {
      const copy = this.$_.cloneDeep(metrics);
      if (this.edit) {
        this.pMetrics = copy;
      } else {
        this.activity.metrics = copy;
      }
    },
    handleDeleteMetric(name) {
      const index = this.metrics.indexOf(name);
      this.metrics.splice(index, 1);
    },
    handleSave() {
      this.activity.metrics = this.$_.cloneDeep(this.pMetrics);
      this.isLoading = true;
      this.$api.updateActivityMetrics(this.activity.id, this.activity.metrics)
        .then(() => {
          this.handleEdit();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // saveRequest() {
    //   await this.$api.updateActivityMetrics()
    //   // return new Promise((resolve) => {
    //   //   setTimeout(() => {
    //   //     resolve();
    //   //   }, 3000);
    //   // });
    // },
  },
};
</script>

<style lang="scss">
.crm-activity-metrics {
  .el-date-editor {
    .el-range-input {
      margin-left: 10px;
    }

    .el-icon-date {
      position: absolute;
      height: 100%;
      left: 5px;
      top: 50%;
      transform: translate(0, -50%);
    }

    .el-range__close-icon {
      position: absolute;
      height: 100%;
      right: 5px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &__statistic-wrapper {
    font-size: 10px;
  }

  &__title-wrapper {
    margin-bottom: 10px;
  }

  &__group-list {
    //overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    &_keep-height {
      min-height: 200px;
    }
  }

  &__group {
    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    width: 100%;
    flex-shrink: 0;
  }
  &__metric-card-add-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 182px;
  }

  & &__metric-card-add {
    flex-grow: 1;
    flex-direction: column;
    transition: none;

    h3 {
      margin-top: 8px;
      margin-left: 0;
    }
  }

  &__metric-panel {
    flex-shrink: 0;

    .crm-panel__content-wrapper {
      flex-grow: 1;
    }
  }

  &__metric-text-wrapper {
    margin-bottom: 2px;
  }

  // &__metric-subtext_success,
  // &__metric-text_success {
  //   // color: var(--main-color);
  // }

  &__metric-subtext_danger,
  &__metric-text_danger {
    color: var(--danger-color);
  }

  &__metric-no-data.crm-error-panel {
    padding: 2px 0 4px;
  }

  &__no-metrics-panel {
    flex-grow: 1;
    align-self: baseline;

    .crm-panel__content-wrapper {
      flex-grow: 1;
    }

    .crm-panel__content {
      justify-content: center;
      display: flex;
      padding: 16px 0 18px;
    }
  }

  &__no-metrics-error.crm-error-panel {
    padding: 0;
  }

  &__cooldown-panel {
    .crm-panel__content {
      padding: 16px 24px;
    }
  }

  &__cooldown-preview-param {
    display: flex;
    font-size: 12px;
    line-height: normal;
    color: var(--crm-panel-stats);
  }

  &__hide-input-error .input-error {
    display: none;
  }

  &__metric-panel-wrapper {
    @media (max-width: 2560px) {
      width: 16.66%;
    }

    @media (max-width: 1950px) {
      width: 20%;
    }

    @media (max-width: 1400px) {
      width: 25%;
    }

    width: 10%;
  }
}
</style>
