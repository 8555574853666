export const MISC_INIT_DATA = 'MISC_INIT_DATA';
export const MISC_INIT_COUNTERS = 'MISC_INIT_COUNTERS';
export const MISC_FETCH_LANGUAGES = 'MISC_FETCH_LANGUAGES';
export const MISC_FETCH_NOTE_TAGS = 'MISC_FETCH_NOTE_TAGS';
export const MISC_RESET_COUNTERS = 'MISC_RESET_COUNTERS';
export const MISC_SET_AFFILIATES_REVIEWS_COUNT = 'MISC_SET_AFFILIATES_REVIEWS_COUNT';
export const MISC_FETCH_MESSENGERS = 'MISC_FETCH_MESSENGERS';
export const MISC_FETCH_TIMEZONES = 'MISC_FETCH_TIMEZONES';
export const MISC_FETCH_COUNTRIES = 'MISC_FETCH_COUNTRIES';
export const MISC_FETCH_REGIONS = 'MISC_FETCH_REGIONS';
export const MISC_FETCH_PAYMENTS_SYSTEMS = 'MISC_FETCH_PAYMENTS_SYSTEMS';
export const MISC_FETCH_SPORT_TYPE = 'MISC_FETCH_SPORT_TYPE';
export const MISC_FETCH_SPORT_LEAGUE = 'MISC_FETCH_SPORT_LEAGUE';
export const MISC_FETCH_PAYMENT_METHODS = 'MISC_FETCH_PAYMENT_METHODS';
export const MISC_FETCH_BRANDS = 'MISC_FETCH_BRANDS';

export const MISC_SET_MEDIA_BUYING_POSTS_REVIEW_COUNT = 'MISC_SET_MEDIA_BUYING_POSTS_REVIEW_COUNT';
export const MISC_SET_MEDIA_BUYING_POSTS_REWORK_COUNT = 'MISC_SET_MEDIA_BUYING_POSTS_REWORK_COUNT';

export const MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REVIEW_COUNT = 'MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REVIEW_COUNT';
export const MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REWORK_COUNT = 'MISC_SET_MEDIA_BUYING_PAYMENTS_MODERATION_REWORK_COUNT';

export const MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_NOT_PAID_COUNT = 'MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_NOT_PAID_COUNT';
export const MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_REWORK_COUNT = 'MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_REWORK_COUNT';
export const MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_PROCESSING_COUNT = 'MISC_SET_MEDIA_BUYING_PAYMENTS_PAYOUT_PROCESSING_COUNT';

export const MISC_SET_PAYMENTS_REVIEWS_COUNT = 'MISC_SET_PAYMENTS_REVIEWS_COUNT';
export const MISC_SET_PAYMENTS_NOT_PAID_COUNT = 'MISC_SET_PAYMENTS_NOT_PAID_COUNT';
export const MISC_SET_PAYMENTS_FOR_REWORK_COUNT = 'MISC_SET_PAYMENTS_FOR_REWORK_COUNT';

export const MISC_CONNECTION_ENABLED = 'MISC_CONNECTION_ENABLED';
export const MISC_CONNECTION_DISABLED = 'MISC_CONNECTION_DISABLED';

export const MISC_FETCH_DNS_HOSTING_PROVIDERS = 'MISC_FETCH_DNS_HOSTING_PROVIDERS';
export const MISC_FETCH_DOMAIN_REGISTRAR_PROVIDERS = 'MISC_FETCH_DOMAIN_REGISTRAR_PROVIDERS';

export const MISC_GET_POST_PLATFORM = 'MISC_GET_POST_PLATFORM';
export const MISC_GET_CURRENCIES = 'MISC_GET_CURRENCIES';
export const MISC_GET_CUSTOMER_CURRENCIES = 'MISC_GET_CUSTOMER_CURRENCIES';
export const MISC_GET_CUSTOMER_LANGUAGES = 'MISC_GET_CUSTOMER_LANGUAGES';
