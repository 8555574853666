export default {
  live: {
    name: 'Live',
    menu: {
      'last-bets': 'Последние ставки & депозиты',
      'top-events': 'Лучшие события',
    },
    tables: {
      last20bets: 'Последние 20 ставок на спорт',
      last20deposits: 'Последние 20 депозитов',
      top20eventsCalculated: 'Топ-20 отыгранных событий',
      top20eventsUncalculated: 'Топ-20 неотыгранных событий',
    },
    list: {
      fields: {
        events: {
          title: 'Событие',
        },
        events_count: {
          title: 'Тип события',
        },
        player_country_name: {
          title: 'Страна',
        },
        site_player_id: {
          title: 'Игрок',
        },
        bet_sum: {
          title: 'Сумма',
        },
        original_bet_sum: {
          title: 'В оригинальной валюте',
        },
        deposit_sum: {
          title: 'Сумма',
        },
        original_deposit_sum: {
          title: 'В оригинальной валюте',
        },
        coefficient: {
          title: 'Коэф.',
        },
        payment_system_name: {
          title: 'Метод',
        },
        event_name: {
          title: 'Событие',
        },
        bets_count: {
          title: 'Кол-во ставок',
        },
        bets_sum: {
          title: 'Сумма ставок',
        },
        wins_sum: {
          title: 'Сумма выигрышей',
        },
        profit_sum: {
          title: 'Сумма профита',
        },
        timestamp: {
          title: 'Дата & Время',
        },
      },
    },
    toolbar: {
      refresh: 'Автообновление',
    },
  },
};
