<template>
  <div
    class="filter-item"
    :class="{
      'filter-item--mode-view': mode === 'view',
      'filter-item--mode-create': mode === 'create',
    }"
  >
    <ui-button
      v-if="isCreated"
      :icon-size="12"
      color="red"
      lib="fa"
      substyle="fas"
      icon="minus"
      class="btn filter-item__delete"
      @click="deleteFilter()"
    />
    <div
      v-if="isCreated"
      class="filter-item__field type"
    >
      <el-select
        v-model="groupRule.rule_type"
        filterable
        :placeholder="$t('crm.segments.filters.select_rule_placeholder')"
        class="select"
        :class="{ error: isInvalidField('rule_type') }"
        @change="changeRuleType()"
      >
        <el-option-group
          v-for="filter in availableFilters"
          :key="filter.name"
          class="filter-select-group"
          :label="$t(`crm.segments.filters.${filter.name}`)"
        >
          <el-option
            v-for="item in filter.items"
            :key="item.name"
            :label="$t(`crm.segments.filters.${item.name}`)"
            :value="item.name"
          />
        </el-option-group>
      </el-select>
      <div
        v-if="isInvalidField('rule_type')"
        class="filter-item__field-error"
      >
        {{ getError('rule_type') }}
      </div>
    </div>
    <div
      v-else
      class="filter-item_rule"
    >
      {{ $t(`crm.segments.filters.${groupRule.rule_type}`) }}
    </div>
    <div
      v-if="isCreated"
      class="filter-item__field operator"
    >
      <el-select
        v-model="groupRule.operator"
        filterable
        :disabled="groupRule.rule_type === ''"
        :placeholder="$t('crm.segments.filters.select_operator_placeholder')"
        class="select"
        :class="{ error: isInvalidField('operator') }"
        @change="onChangeOperator"
      >
        <el-option
          v-for="operator in filteredOperators(groupRule.rule_type)"
          :key="operator"
          :label="$t(`crm.segments.operators.${operator}`)"
          :value="operator"
        />
      </el-select>
      <div
        v-if="isInvalidField('operator')"
        class="filter-item__field-error"
      >
        {{ getError('operator') }}
      </div>
    </div>
    <div
      v-else
      class="filter-item_option"
    >
      {{ $t(`crm.segments.operators.${groupRule.operator}`) }}
    </div>
    <div
      v-if="getInputType(groupRule.rule_type) === 'text' && isCreated"
      key="text"
      class="filter-item__field"
    >
      <el-select
        ref="ruleValueSelect"
        v-model="groupRule.values"
        filterable
        multiple
        collapse-tags
        :disabled="groupRule.operator === ''"
        :placeholder="$t('crm.segments.create_segment_value_placeholder')"
        :class="{ error: isInvalidField('values') }"
        class="select"
        @visible-change="setValueSelectPopupWidth"
      >
        <el-option
          v-for="value in dictionaryForFilter"
          :key="value.code"
          class="filter-item__select-option"
          :label="value.name"
          :value="value.code"
          :title="value.name"
        />
      </el-select>
      <div
        v-if="isInvalidField('values')"
        class="filter-item__field-error"
      >
        {{ getError('values') }}
      </div>
    </div>
    <div
      v-if="getInputType(groupRule.rule_type) === 'time' && isCreated"
      class="filter-item__field filter-item__field--time"
    >
      <ui-input
        v-model="timeValue"
        size="big"
        :placeholder="$t('crm.segments.create_segment_value_placeholder')"
        class="form-input"
        type="text"
        :error="errors.values"
        :disabled="groupRule.operator === ''"
      />
      <el-select
        v-model="time"
        filterable
        :disabled="groupRule.operator === ''"
        class="select"
      >
        <el-option
          v-for="item in timeRanges"
          :key="item"
          :label="$t(`crm.segments.time_ranges.${item}`)"
          :value="item"
        />
      </el-select>
    </div>
    <div
      v-if="getInputType(groupRule.rule_type) === 'boolean' && isCreated"
      key="boolean"
      class="filter-item__field"
    >
      <el-select
        v-model="groupRule.values"
        filterable
        :disabled="groupRule.operator === ''"
        :placeholder="$t('crm.segments.create_segment_value_placeholder')"
        :class="{ error: isInvalidField('values') }"
        class="select"
      >
        <el-option
          v-for="value in booleanList"
          :key="value"
          :label="$t(`crm.segments.boolean.${value}`)"
          :value="value"
        />
      </el-select>
      <div
        v-if="isInvalidField('operator')"
        class="filter-item__field-error"
      >
        {{ getError('values') }}
      </div>
    </div>
    <div
      v-if="getInputType(groupRule.rule_type) === 'number' && isCreated"
      class="filter-item__field"
    >
      <ui-input
        v-model="groupRule.values"
        autosize
        :disabled="groupRule.operator === ''"
        :placeholder="$t('crm.segments.create_segment_value_placeholder')"
        :class="{ error: isInvalidField('values') }"
        type="text"
      />
      <div
        v-if="isInvalidField('values')"
        class="filter-item__field-error"
      >
        {{ getError('values') }}
      </div>
    </div>
    <div
      v-if="getInputType(groupRule.rule_type) === 'date_picker' && isCreated && mode === 'create'"
      class="filter-item__field"
    >
      <el-date-picker
        v-show="groupRule.operator === 'between'"
        ref="datepicker"
        v-model="dateRange"
        :disabled="groupRule.operator === ''"
        :default-time="['00:00:00', '23:59:59']"
        :start-placeholder="$t('reports.other.start_date')"
        :end-placeholder="$t('reports.other.end_date')"
        :placeholder="$t(`tools.match_popup.placeholder_date_time`)"
        format="dd MMM yyyy HH:mm"
        type="datetimerange"
        :class="{ error: isInvalidField('values') }"
        class="date-picker-range"
        @change="changeDateRange"
        @pick="groupRule.values = ''; dateRange = null;"
      />
      <el-date-picker
        v-show="groupRule.operator !== 'between'"
        ref="datepicker"
        v-model="date"
        :disabled="groupRule.operator === ''"
        :placeholder="$t(`tools.match_popup.placeholder_date_time`)"
        format="dd MMM yyyy HH:mm"
        type="datetime"
        :class="{ error: isInvalidField('values') }"
        class="date-picker-range"
        @change="changeDateRange"
        @pick="groupRule.values = null; date = null;"
      />
      <div
        v-if="isInvalidField('values')"
        class="filter-item__field-error"
      >
        {{ getError('values') }}
      </div>
    </div>
    <div
      v-if="getInputType(groupRule.rule_type) === 'sport_casino' && isCreated"
      key="sport_casino"
      class="filter-item__field"
    >
      <el-select
        v-model="groupRule.values"
        filterable
        :disabled="groupRule.operator === ''"
        :placeholder="$t('crm.segments.create_segment_value_placeholder')"
        :class="{ error: isInvalidField('values') }"
        class="select"
      >
        <el-option
          v-for="value in favouriteBetList"
          :key="value"
          :label="$t(`crm.segments.favourite_bet.${value}`)"
          :value="value"
        />
      </el-select>
      <div
        v-if="isInvalidField('values')"
        class="filter-item__field-error"
      >
        {{ getError('operator') }}
      </div>
    </div>
    <div
      v-if="getInputType(groupRule.rule_type) === 'player_contacts' && isCreated"
      key="player_contacts"
      class="filter-item__field"
    >
      <el-select
        v-model="groupRule.values"
        filterable
        :disabled="groupRule.operator === ''"
        :placeholder="$t('crm.segments.create_segment_value_placeholder')"
        :class="{ error: isInvalidField('values') }"
        class="select"
      >
        <el-option
          v-for="value in contactList"
          :key="value"
          :label="$t(`crm.segments.player_contacts.${value}`)"
          :value="value"
        />
      </el-select>
      <div
        v-if="isInvalidField('values')"
        class="filter-item__field-error"
      >
        {{ getError('operator') }}
      </div>
    </div>
    <div
      v-if="(getInputType(groupRule.rule_type) === 'player_affiliate_id'
        || getInputType(groupRule.rule_type) === 'player_sub_affiliate_id') && isCreated"
      key="text"
      class="filter-item__field"
    >
      <el-select
        ref="ruleValueSelect"
        v-model="autocompleteValues"
        filterable
        multiple
        remote
        collapse-tags
        :disabled="groupRule.operator === ''"
        :placeholder="$t('crm.segments.create_segment_value_placeholder')"
        :class="[
          { error: isInvalidField('values') },
          'filter-item__field--autocomplete',
        ]"
        :filter-method="setAutocompleteQueryDebounced"
        popper-class="filter-item__autocomplete-popper"
        reserve-keyword
        class="select"
        @visible-change="handleAutocompleteVisibleChange"
      >
        <el-option
          v-for="value in autocompleteQueryResultComputed"
          :key="value.id"
          class="filter-item__select-option"
          :label="value.email"
          :value="value.id"
          :title="value.email"
        />
      </el-select>
      <div
        v-if="isInvalidField('values')"
        class="filter-item__field-error"
      >
        {{ getError('values') }}
      </div>
    </div>
    <div
      v-if="!isCreated"
      class="filter-item_value"
    >
      {{ getValues(groupRule.rule_type, groupRule.values) }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'FilterComponent',

  props: {
    mode: {
      type: String,
      default: 'create',
    },
    groupRule: {
      type: Object,
      default() {
        return {
          rule_type: '',
          operator: '',
          values: '',
        };
      },
    },
    availableFilters: {
      type: Array,
      default() {
        return [];
      },
    },
    errors: {
      type: Object,
      default() {
        return {};
      },
    },
    currentBrand: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      booleanList: [true, false],
      favouriteBetList: ['sport', 'games'],
      contactList: ['email', 'phone'],
      timeRanges: ['seconds', 'minutes', 'hours', 'days'],
      time: 'days',
      autocompleteQuery: '',
      autocompleteQueryResult: null,
      autocompleteQueryResultDefault: [],
      autocompleteDictionaryIndex: {},
      dictionariesMap: {
        countries: 'MISC_FETCH_COUNTRIES',
        regions: 'MISC_FETCH_REGIONS',
        paymentsSystems: 'MISC_FETCH_PAYMENTS_SYSTEMS',
        sportType: 'MISC_FETCH_SPORT_TYPE',
        sportLeague: 'MISC_FETCH_SPORT_LEAGUE',
      },
      date: '',
      dateRange: [],
    };
  },

  computed: {
    isCreated() {
      return this.mode === 'create';
    },

    currentFilter() {
      const filters = this.availableFilters.map(({ items }) => items).flat();
      return filters.find(({ name }) => name === this.groupRule.rule_type);
    },

    hasDictionary() {
      return this.currentFilter.dictionary !== undefined;
    },

    dictionaryForFilter() {
      if (this.currentFilter) {
        const { head, tail } = this.$store.getters[`misc/${this.currentFilter.dictionary}`].reduce((acc, item) => {
          if (this.groupRule.values.includes(item.code)) {
            acc.head.push(item);
          } else {
            acc.tail.push(item);
          }

          return acc;
        }, { head: [], tail: [] });

        return [...head, ...tail];
      }
      return [];
    },

    timeValue: {
      get() {
        const duration = this.$moment.duration(
          this.groupRule.values,
          'seconds',
        );
        return duration.as(this.time) === 'NaN' ? 0 : duration.as(this.time);
      },
      set(value) {
        let valueTemp = value;
        if (value === '') valueTemp = '0';
        this.groupRule.values = this.$moment
          .duration(parseFloat(valueTemp), this.time)
          .asSeconds();
      },
    },

    autocompleteQueryResultComputed() {
      const { head, tail } = (this.autocompleteQueryResult || this.autocompleteQueryResultDefaultComputed).reduce((acc, item) => {
        if ((this.groupRule.values || []).some(({ id }) => id === item.id)) {
          acc.head.push(item);
        } else {
          acc.tail.push(item);
        }

        return acc;
      }, { head: [], tail: [] });

      return [...head, ...tail];
    },
    inputType() {
      return this.getInputType(this.groupRule.rule_type);
    },
    setAutocompleteQueryDebounced() {
      return this.$_.debounce(this.setAutocompleteQuery, 300);
    },
    autocompleteQueryResultDefaultComputed() {
      const values = this.groupRule.values || [];
      const keys = this.autocompleteQueryResultDefault.map(item => item.id);
      const valuesFiltered = values
        .filter(item => !keys.includes(item.id));

      const result = valuesFiltered.concat(this.autocompleteQueryResultDefault);
      return result;
    },
    autocompleteValues: {
      get() {
        return this.groupRule.values
          ? this.groupRule.values.map((item) => {
            if (item?.id) {
              return item.id;
            }
            return item;
          })
          : [];
      },
      set(value) {
        this.groupRule.values = value.map(item => ({
          id: item,
          email: this.autocompleteDictionaryIndex[item].email,
        }));
      },
    },
  },

  watch: {
    groupRule: {
      immediate: true,
      handler(rule) {
        // console.log('rule', this.$_.cloneDeep(rule));
        if (this.getInputType(rule.rule_type) === 'time') {
          this.setTimePeriod();
        }

        if (this.getInputType(rule.rule_type) === 'date_picker') {
          this.setPeriod();
        }
      },
    },
    async autocompleteQuery(value) {
      if ((this.inputType === 'player_affiliate_id' || this.inputType === 'player_sub_affiliate_id') && this.isCreated) {
        if (!value) {
          this.autocompleteQueryResult = null;
        } else {
          this.autocompleteQueryResult = await this.handleAffiliateSearch(value);
        }
      }
    },
    inputType: {
      immediate: true,
      async handler(value) {
        if (value === 'player_affiliate_id' || value === 'player_sub_affiliate_id') {
          const promises = [];
          if (this.isCreated) {
            promises.push(new Promise(async (resolve) => {
              this.autocompleteQueryResultDefault = await this.handleAffiliateSearch();
              resolve();
            }));
          }

          if (this.groupRule.values.length > 0) {
            this.updateAutocompleteDictionaryIndex(this.groupRule.values);
          }

          try {
            this.$emit('loading', true);
            await Promise.all(promises);
          } finally {
            this.$emit('loading', false);
          }
        }
      },
    },
  },

  created() {
    this.getDictionary();
  },

  methods: {
    formatDate(value) {
      return this.$moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
    changeDateRange(value) {
      if (!value) {
        this.groupRule.values = [];
        return;
      }

      if (this.groupRule.operator === 'between') {
        const [d1, d2] = value;
        this.groupRule.values = [this.formatDate(d1), this.formatDate(d2)];
      } else {
        this.groupRule.values = this.formatDate(value);
      }
    },
    setAutocompleteQuery(value) {
      this.autocompleteQuery = value;
    },
    handleAffiliateSearch(search) {
      const query = {
        search,
        limit: 50,
        referral_program_enabled: this.inputType === 'player_sub_affiliate_id' ? true : undefined,
        account_status: 'approved',
      };

      return new Promise((resolve, reject) => {
        this.$api.getAffiliates(query)
          .then((response) => {
            this.updateAutocompleteDictionaryIndex(response.data.payload);
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    handleAutocompleteVisibleChange(value) {
      this.setAutocompleteQuery('');
      this.setValueSelectPopupWidth(value);
    },
    setValueSelectPopupWidth(value) {
      if (value) {
        if (this.$refs.ruleValueSelect && this.$refs.ruleValueSelect.$refs.popper) {
          window.requestAnimationFrame(() => {
            window.requestAnimationFrame(() => {
              const selectEl = this.$refs.ruleValueSelect.$el;
              const popperEl = this.$refs.ruleValueSelect.$refs.popper.$el;
              popperEl.style.maxWidth = `${selectEl.offsetWidth}px`;
            });
          });
        }
      }
    },
    updateAutocompleteDictionaryIndex(list) {
      list.forEach((item) => {
        if (!this.autocompleteDictionaryIndex[item.id]) {
          this.autocompleteDictionaryIndex[item.id] = item;
        }
      });
    },
    isInvalidField(fieldName) {
      return this.errors !== null ? this.errors.hasOwnProperty(fieldName) : false;
    },

    getError(fieldName) {
      return this.isInvalidField(fieldName) ? this.errors[fieldName][0].message : '';
    },

    filteredOperators(ruleType) {
      if (ruleType) {
        if (this.currentFilter.comparable) {
          const operators = ['>=', '<='];

          if (this.currentFilter.type === 'date_picker') {
            operators.push('between');
          }

          return operators;
        }
        return ['='];
      }
      return [];
    },

    getDictionary() {
      if (this.currentFilter) {
        if (this.hasDictionary) {
          if (this.$store.getters[`misc/${this.currentFilter.dictionary}`] === null
            || this.$store.getters[`misc/${this.currentFilter.dictionary}`].length === 0) {
            this.$store.dispatch(`misc/${this.dictionariesMap[this.currentFilter.dictionary]}`, this.currentBrand);
          }
        }
      }
    },
    clearDate() {
      this.date = '';
      this.dateRange = [];
    },
    onChangeOperator() {
      if (this.getInputType(this.groupRule.rule_type) === 'date_picker') {
        this.clearDate();
      }
    },
    changeRuleType() {
      this.getDictionary();
      this.groupRule.operator = this.filteredOperators(this.groupRule.rule_type).length === 1 ? '=' : '';
      this.groupRule.values = '';

      if (this.getInputType(this.groupRule.rule_type) === 'date_picker') {
        this.clearDate();
      }
    },

    getInputType(ruleType) {
      const filters = this.availableFilters.map(({ items }) => items).flat();
      const filter = filters.find(({ name }) => name === this.groupRule.rule_type);

      return ruleType ? filter.type : 'text';
    },

    deleteFilter() {
      this.$emit('deleteFilter');
    },

    setPeriod() {
      if (this.groupRule.operator === 'between') {
        this.dateRange = this.groupRule.values;
      } else {
        this.date = this.groupRule.values;
      }
    },

    setTimePeriod() {
      const duration = this.$moment.duration(
        this.groupRule.values,
        'seconds',
      );
      Object.keys(duration._data)
        .reverse()
        .forEach((key) => {
          if (duration._data[key] !== 0) {
            this.time = key;
          }
        });
      if (this.groupRule.values === 0 || this.groupRule.values === '') {
        this.time = 'days';
      }
    },

    getValues(type, value) {
      if (this.getInputType(type) === 'boolean') {
        return `${this.$t(`crm.segments.boolean.${value}`)}`;
      }
      if (this.getInputType(type) === 'number') {
        return value;
      }
      if (this.getInputType(type) === 'sport_casino') {
        return `${this.$t(`crm.segments.favourite_bet.${value}`)}`;
      }
      if (this.getInputType(type) === 'player_contacts') {
        return `${this.$t(`crm.segments.player_contacts.${value}`)}`;
      }
      if (this.getInputType(type) === 'time') {
        return `${this.timeValue} ${this.$t(`crm.segments.time_ranges.${this.time}`)}`;
      }
      if (this.getInputType(type) === 'date_picker') {
        return Array.isArray(value)
          ? value.map(val => this.$moment(val).format('DD.MM.YYYY HH:mm')).join(' - ')
          : this.$moment(value).format('DD.MM.YYYY HH:mm');
      }

      if (value) {
        if (this.inputType === 'player_affiliate_id' || this.inputType === 'player_sub_affiliate_id') {
          if (value.length > 0) {
            return value
              .map(item => item.email)
              .join(', ');
          }

          return '';
        }

        this.getDictionary();
        const result = value.map((item) => {
          const filterItem = this.$_.find(this.dictionaryForFilter, {
            code: item,
          });
          return filterItem ? filterItem.name : '';
        });
        return result.join(', ');
      }
      return '';
    },

  },
};
</script>

<style lang="scss">
  .filter-select-group {
    .el-select-group__title {
      color: var(--primary-color);
      margin-left: -6px;
      font-weight: 500;
    }
  }

  .filter-item{
    display: flex;
    padding-top: 8px;

    .el-select.error .el-input.is-disabled .el-input__inner:hover,
    .el-select.error .el-input .el-input__inner{
      border-color: #d26370;
    }

    &--mode-view {
      padding: 6px 12px;
      border-radius: 8px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      display: inline-flex;
      align-items: center;
      font-size: 12px;
    }

    &--mode-view & {
      &_rule{
        font-weight: 500;
        margin-right: 8px;
      }
      &_option{
        margin-right: 8px;
      }
      &_value{
        font-weight: 500;
        padding: 4px 8px;
        border-radius: 4px;
        border: solid 1px #a6b2c4;
        align-items: center;
      }
    }

    &:last-of-type{
      margin-bottom: 0;
    }
    &__delete{
      min-width: 30px;
      background-color: transparent;
    }
    &__field{
      min-width: 215px;
      height: 100%;
      margin-left: 8px;
      display: flex;
      align-items: flex-start;
      width: 100%;
      flex-direction: column;

      .date-picker-range {
        .el-input__icon {
          line-height: normal;
        }
      }

      &.type {
        min-width: 200px;
        width: 200px;
      }

      &.operator {
        min-width: 150px;
        width: 150px;
      }

      & > div{
        width: 100%;
      }
      .ui-input{
        height: 100%;
      }
      .input-wrapper{
        height: 30px !important;
      }
      &-error{
        margin-top: 6px;
        font-weight: 500;
        align-self: flex-end;
        font-size: 12px;
        height: 14px;
        line-height: 14px;
        font-style: italic;
        text-align: right;
        color: #d26370;
      }
      &--time{
        flex-direction: row;
        .ui-input{
          margin-left: 0;
          margin-right: 8px;
          width: 50% !important;
        }
      }
      .el-date-editor {
        width: 100% !important;

        .el-input__prefix {
          left: 6px;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
        }

        .el-range-input {
          font-size: 12px;
          color: #303634;
        }

        .el-range__close-icon {
          position: absolute;
          height: 100%;
          right: 5px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }

      .el-select__input {
        margin-left: 8px;
      }

      &--autocomplete .el-select__tags {
        max-width: 216px !important;
      }

      .el-select .el-select__tags {
        & > span {
          max-width: 170px;
          overflow: hidden;
          display: flex;
          flex-wrap: nowrap;
        }
      }

      .el-select__tags {
        .el-tag:first-child {
          display: flex;
          overflow: hidden;
          align-items: baseline;

          & > .el-select__tags-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
          }
        }

        .el-tag__close {
          flex-shrink: 0;
        }
      }
    }

    &__common-param-input {
      width: 100%;
    }

    &__select-option {
      max-width: 100%;

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
