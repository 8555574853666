export default {
  getAdminInfo() {
    return this.affiliatesApi.get('/profile');
  },
  getMessengers() {
    return this.miscApi.get('/messengers');
  },
  getTimezones() {
    return this.miscApi.get('/timezones');
  },
  getCountries() {
    return this.miscApi.get('/countries');
  },
  getRegions() {
    return this.miscApi.get('/regions');
  },
  getPaymentSystems() {
    return this.affiliatesApi.get('/misc/site-payment-systems');
  },
  getSportCategories(params) {
    return this.affiliatesApi.get('/misc/sport-categories', { params });
  },
  getSportLeagues(params) {
    return this.affiliatesApi.get('/misc/sport-leagues', { params });
  },
  getPaymentMethods(params) {
    return this.affiliatesApi.get('/payment-methods', { params });
  },
  getCountryTags() {
    return this.affiliatesApi.get('/misc/filter-tags/countries');
  },
  getNoteTags() {
    return this.affiliatesApi.get('/misc/note-tags');
  },
  getPostPlatform() {
    return this.miscApi.get('/media-buying/post-platforms');
  },

  /**
   * Get available languages
   *
   * @param {Object} params
   * @param {String} [params.sort_column]
   * @param {String} [params.sort_dir]
   * @param {String} [params.search]
   * @param {Number} [params.limit]
   * @param {Number} [params.offset]
   * @param {String[]} [params.code]
   *
   * @return {Promise<Object>} api response
   */
  getLanguages(params) {
    return this.miscApi.get('/languages', { params });
  },

  getCurrencies() {
    return this.miscApi.get('/currencies');
  },

  getMiscCustomerCurrencies() {
    return this.miscApi.get('/customer-currencies');
  },
};
