import DNSHostingAccounts from './dnsHostingAccounts';
import DNSHostings from './dnsHostings';
import DomainRegistrarAccounts from './domainRegistrarAccounts';
import DomainRegistrars from './domainRegistrars';
import PlatformServers from './platformServers';
import RotationGroups from './rotationGroups';
import ServiceDomains from './serviceDomains';
import ServiceOrigins from './serviceOrigins';
import PlatformBettingDomains from './platformBettingDomains';

export default {
  ...DNSHostingAccounts,
  ...DNSHostings,
  ...DomainRegistrarAccounts,
  ...DomainRegistrars,
  ...PlatformServers,
  ...RotationGroups,
  ...ServiceDomains,
  ...ServiceOrigins,
  ...PlatformBettingDomains,
};
