var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"balance-history"},[_c('div',{staticClass:"toolbar"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"section"},[_c('el-date-picker',{ref:"dateRange",staticClass:"date-picker",attrs:{"picker-options":{
            firstDayOfWeek: 1,
            shortcuts: _vm.shortcutsDates,
          },"start-placeholder":_vm.$t('reports.other.start_date'),"end-placeholder":_vm.$t('reports.other.end_date'),"type":"daterange","range-separator":"-","format":"dd MMM yyyy"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('el-checkbox-group',{on:{"change":_vm.handleTypeChange},model:{value:(_vm.dataQuery.transaction_type),callback:function ($$v) {_vm.$set(_vm.dataQuery, "transaction_type", $$v)},expression:"dataQuery.transaction_type"}},_vm._l((_vm.types),function(type){return _c('el-checkbox',{key:type,attrs:{"label":type}},[_vm._v(" "+_vm._s(_vm.$t(`affiliates.balanceHistory.transactions.${type}`))+" ")])}),1)],1),_c('div',{staticClass:"section"},[_c('div',{staticClass:"pagination"},[_c('ui-pagination',{attrs:{"page":_vm.page,"page-size":_vm.limit,"count":_vm.count,"show-size-select":""},on:{"page-change":_vm.pageChange}})],1)])])]),_c('div',{ref:"wrapper",staticClass:"table-wrapper"},[_c('ui-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isDataLoading),expression:"isDataLoading"}],attrs:{"fields":_vm.fields,"currency":_vm.currencyCode,"data":_vm.data,"rows-count":_vm.limit,"sort":{
        prop: _vm.sortProp,
        order: _vm.sortOrder,
      },"lazy-loading":"","is-static":"","i18n-path":"affiliates.list","element-loading-custom-class":"data-loader-spinner"},on:{"sort":_vm.handleSort}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }