<template>
  <div v-if="isOpen" class="send-notification-popup">
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ $t('affiliates.notifications.popup.sendNotification') }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div v-loading="isLoading" class="body overflow-visible">
        <div class="form">
          <div class="container">
            <div class="title">
              {{ $t('affiliates.notifications.popup.notificationDetails') }}
            </div>
            <div class="ui-d-flex ui-m-xl-t">
              <crm-action-language-tab
                :languages="languages"
                :errors="errors.translateErrors"
                :selected-index="index"
                @select="handleSelectTab"
              />
              <crm-action-languages
                class="ui-fg-0"
                :languages="customerLanguages"
                :selected="selectedLanguages"
                :disabled="customerLanguages.length === selectedLanguages.length"
                @select="handeAddLanguage"
              >
                <template slot="actions">
                  <div class="ui-d-flex" @click.stop>
                    <div class="ui-m-md-r">
                      <ui-button
                        icon="trash-can"
                        lib="fa"
                        substyle="fas"
                        color="red"
                        class="action-btn"
                        :disabled="index === 0"
                        @click.stop="handleDelete(index)"
                      />
                    </div>
                  </div>
                </template>
              </crm-action-languages>
            </div>
            <ui-input
              v-model="actions[index].title"
              class="form-input ui-m-xl-t"
              :label="$t('affiliates.notifications.popup.title')"
              autosize
              size="big"
              :class="{ error: getError(['translateErrors', index, 'title']) }"
              :error="getError(['translateErrors', index, 'title'])"
              :placeholder="$t('affiliates.notifications.popup.title')"
              type="text"
            />
            <div class="ui-m-xl-t textarea">
              <div class="label">
                {{ $t('affiliates.notifications.popup.description') }}
              </div>
              <el-input
                v-model="actions[index].message"
                :rows="9"
                :class="{ error: getError(['translateErrors', index, 'message']) }"
                :placeholder="$t('affiliates.notifications.popup.description')"
                type="textarea"
                resize="none"
              />
              <div v-if="getError(['translateErrors', index, 'message'])" class="field-error">
                {{ getError(['translateErrors', index, 'message']) }}
              </div>
            </div>
            <div class="row-bg ui-m-xl-t">
              <span>{{ $t('affiliates.notifications.popup.requiredRead') }}</span>
              <el-switch
                v-model="isRequiredRead"
                class="ui-m-sm-l"
                :active-color="$theme.accentColor"
                :inactive-color="$theme.dangerColor"
              />
            </div>
          </div>
          <div>
            <div class="title">
              {{ $t('affiliates.notifications.affiliates') }}
            </div>
            <el-radio-group v-model="filter" class="manual-radio ui-m-xl-t">
              <el-radio label="all">
                {{ $t('affiliates.notifications.popup.all') }}
              </el-radio>
              <el-radio label="only">
                {{ $t('affiliates.notifications.popup.onlySelected') }}
              </el-radio>
              <el-radio label="except">
                {{ $t('affiliates.notifications.popup.allExcept') }}
              </el-radio>
            </el-radio-group>
            <div class="ui-d-flex ui-m-xl-t">
              <ui-input
                v-model.trim="searchQuery"
                width="100%"
                class="select-label"
                is-search
                @search="handleRefresh"
              />
              <ui-pagination
                :page="page"
                :page-size="limit"
                :count="count"
                @page-change="pageChange"
              />
            </div>
            <ui-table
              ref="table"
              v-loading="isDataLoading"
              class="ui-m-xl-t"
              :class="{ disabled: filter === 'all' }"
              :data="tableData"
              :select-row="filter !== 'all'"
              select-type="checkbox"
              :external-height="478"
              is-static
              checked-row-id="id"
              :show-check-all="false"
              :rows-count="limit"
              element-loading-custom-class="data-loader-spinner"
              @checkedRows="checkedRows"
            >
              <template slot="append">
                <el-table-column>
                  <template slot="header">
                    E-Mail
                  </template>
                  <template slot-scope="scope">
                    <el-checkbox
                      :value="filter === 'all' || selectedMap[scope.row.id]"
                      :disabled="filter === 'all'"
                      class="form-checkbox"
                    />
                    {{ scope.row.email }}
                  </template>
                </el-table-column>
              </template>
            </ui-table>
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          lib="fa"
          filled
          substyle="fas"
          icon="check"
          :disabled="isLoading || (filter !== 'all' && !selectedIds.length)"
          @click="handleSend"
        >
          {{ $tc('affiliates.notifications.popup.confirmSend', countNotification, { count: countNotification || '' }) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import errorHandleMixin from '@/views/mixins/error-hadle';
import CrmActionLanguages from '@/views/CRM/components/CrmActionLanguages.vue';
import CrmActionLanguageTab from '@/views/CRM/components/CrmActionLanguageTab.vue';


const pageSize = 13;

export default {
  name: 'SendNotificationPopup',
  components: {
    CrmActionLanguageTab,
    CrmActionLanguages,
  },
  mixins: [errorHandleMixin],
  props: {
    currency: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,

      actions: [],
      index: 0,
      isRequiredRead: false,
      filter: 'all',
      selectedIds: [],

      page: 1,
      limit: pageSize,
      count: 0,
      affiliatesCount: 0,
      searchQuery: '',
      sortBy: 'email',
      sortDir: 'asc',
      isDataLoading: true,

      tableData: [],
      fields: [
        {
          name: 'email',
          align: 'left',
          sortable: false,
          resizable: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      customerLanguages: 'misc/customerLanguages',
    }),
    languages() {
      return this.actions.map(action => ({
        ...action,
        ...(this.customerLanguages.find(
          language => language.code === action.language_code,
        ) || {}),
      }));
    },
    selectedLanguages() {
      return this.languages.map(language => language.language_code);
    },
    selectedMap() {
      return this.selectedIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
    },
    countNotification() {
      if (this.filter === 'only') {
        return this.selectedIds.length;
      }

      if (this.filter === 'except') {
        return this.affiliatesCount - this.selectedIds.length;
      }

      return this.affiliatesCount;
    },
  },
  watch: {
    filter() {
      if (this.$refs.table) {
        this.$refs.table.clearCheck();
      }

      this.selectedIds = [];
    },
  },
  mounted() {
    this.fetchDataPopup = this.$_.debounce(this.fetchData, 1000);
    this.fetchDataPopup();
  },
  methods: {
    handleDelete(index) {
      this.index = 0;
      this.actions.splice(index, 1);

      if (this.errors.translateErrors) {
        this.errors.translateErrors.splice(index, 1);
      }
    },
    checkedRows(id, ids) {
      this.selectedIds = ids;
    },
    _query() {
      return {
        search: this.searchQuery,
        limit: this.limit,
        offset: this.limit * this.page - this.limit,
        sort_column: this.sortBy,
        sort_dir: this.sortDir,
        account_status: ['approved'],
        email_verified: true,
      };
    },
    handleRefresh() {
      this.page = 1;
      this.fetchData();
    },
    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.fetchData();
      }
    },
    handleSelectTab(index) {
      this.index = index;
    },
    getDefaultForm() {
      return {
        title: '',
        message: '',
        language_code: 'en',
        is_default: true,
      };
    },
    handeAddLanguage(languageCode) {
      const newAction = this.getDefaultForm();
      newAction.language_code = languageCode;
      newAction.is_default = false;

      this.actions.push(newAction);
    },
    resetToDefaultData() {
      this.actions = [];
      this.index = 0;
      this.errors = [];
      this.isRequiredRead = false;
      this.filter = 'all';
      this.selectedIds = [];
      this.searchQuery = '';
    },
    fetchData() {
      const options = this._query();

      this.$api
        .getAffiliates(options)
        .then((res) => {
          if (this.searchQuery === '') {
            this.affiliatesCount = res.data.misc.count;
          }

          this.count = res.data.misc.count;
          this.tableData = this.$_.cloneDeep(res.data.payload);
        })
        .finally(() => (this.isDataLoading = false));
    },
    open() {
      this.isOpen = true;
      this.resetToDefaultData();

      if (!this.actions.length) {
        this.actions.push(this.getDefaultForm());
      }
    },
    close() {
      this.isOpen = false;

      if (this.searchQuery) {
        this.searchQuery = '';
        this.fetchData();
      }
    },
    handleSend() {
      const [main, ...restActions] = this.actions;
      const query = {
        title: main.title,
        message: main.message,
        filter: this.filter,
        is_required_to_read: this.isRequiredRead,
        affiliate_ids: this.selectedIds,
        translations: restActions,
      };
      this.$api.postAffiliatesNotifications(query).then(() => {
        this.close();
        this.$emit('confirm');
      }).catch((e) => {
        const { title, message, translations = [] } = e.data.errors || {};
        const mainError = title || message ? { title, message } : null;

        this.errors = {
          ...e.data.errors,
          translateErrors: [mainError, ...translations],
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.field-error {
  text-align: right;
  margin-top: 6px;
  text-transform: uppercase;
  font-weight: 500;
}

.send-notification-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
    width: 100%;
  }

  .popup {
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 8vh;
    width: 1140px;
    height: 720px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;

    .head {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      .name {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
      }
      .close {
        font-size: 15px;
        cursor: pointer;
        &:hover {
          color: var(--main-color) !important;
        }
      }
    }

    .body {
      display: flex;
      position: relative;
      max-height: 84vh;
      min-height: 215px;
      overflow-y: auto;
      font-size: 14px;

      .form {
        display: flex;
        width: 100%;
        overflow: hidden;

        > div {
          width: 50%;
          padding: 16px 24px;

          &:nth-child(2) {
            padding-bottom: 0;
          }

          + div {
            border-left: 1px solid #eaeaea;
          }

          .title {
            font-size: 16px;
            font-weight: 500;
            color: #303634;
          }
        }

        .textarea {
          .label {
            color: #303634;
            font-size: 14px;
            margin-bottom: 5px;
          }

          .error {
            /deep/ .el-textarea__inner {
              border-color: var(--danger-color);
            }
          }
        }

        .row-bg {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 9px 14px 9px 8px;
          border-radius: 4px;
          background-color: #f4f7fa;

          font-size: 14px;
          font-weight: 500;
          color: #303634;
        }

        .manual-radio {
          /deep/ {
            .el-radio {
              margin-right: 0 !important;

              .el-radio__label {
                color: #303634;
                font-size: 12px;
                font-weight: 400;
              }
            }

            .el-radio + .el-radio {
              margin-left: 16px !important;
            }
          }
        }

        /deep/ .ui-table {
          padding-bottom: 0 !important;

          &.disabled {
            .el-table__row {
              cursor: default;
            }
          }

          .el-table__row {
            cursor: pointer;
          }

          th,
          tr td,
          .el-table,
          .el-table__body {
            border: none;
          }
        }
      }
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      .ui-button + .ui-button {
        margin-left: 8px;
      }
    }
  }
}
</style>
