var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isOpen)?_c('div',{staticClass:"crm-add-mail-action-popup"},[_c('div',{staticClass:"popup"},[_c('div',{staticClass:"head"},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.$t('crm.activities.actions.action_popup.mail_title'))+" ")]),_c('ui-icon',{staticClass:"close",attrs:{"size":20,"lib":"fa","substyle":"fas","name":"times"},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}})],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"body"},[_c('div',{staticClass:"ui-fg-1"},[_c('ui-input',{staticClass:"form-input ui-m-xl-b",attrs:{"label":_vm.$t('crm.activities.actions.action_popup.email_fields.name'),"autosize":"","size":"big","placeholder":_vm.$t('crm.activities.actions.action_popup.email_fields.name'),"type":"text","disabled":_vm.preview,"error":_vm.curentErrors.name},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('div',{class:[
            'crm-add-mail-action-popup__tooltip-hint',
            'ui-m-xl-b',
          ]},[_vm._v(" "+_vm._s(_vm.$t('crm.activities.actions.action_popup.mail_hint'))+" ")]),_c('div',{staticClass:"ui-m-xl-b"},[_c('div',{staticClass:"ui-d-flex ui-m-xl-r ui-m-lg-b"},[_c('crm-action-language-tab',{attrs:{"languages":_vm.languages,"selected-index":_vm.index,"errors":_vm.errors},on:{"select":_vm.handleSelectTab}}),_c('crm-action-languages',{staticClass:"ui-fg-0",attrs:{"selected":_vm.selectedLanguages,"disabled":_vm.preview},on:{"select":_vm.handeAddLanguage}},[_c('template',{slot:"actions"},[_c('div',{staticClass:"ui-d-flex",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"ui-m-md-r"},[_c('ui-button',{staticClass:"action-btn",attrs:{"icon":"trash-can","lib":"fa","substyle":"fas","color":"red","disabled":_vm.index === _vm.defaultLanguageActionIndex || _vm.preview},on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(_vm.index)}}})],1),_c('div',{staticClass:"ui-m-md-r"},[_c('ui-button',{staticClass:"action-btn",attrs:{"icon":"history","lib":"fa","substyle":"fas","color":"green","disabled":_vm.index === _vm.defaultLanguageActionIndex || _vm.preview},on:{"click":function($event){$event.stopPropagation();return _vm.handleMakeDefault(_vm.index)}}},[_vm._v(" "+_vm._s(_vm.$t('crm.buttons.make_default'))+" ")])],1)])])],2)],1)]),_c('ui-input',{staticClass:"form-input ui-m-sm-b",attrs:{"label":_vm.$t('crm.activities.actions.action_popup.email_fields.subject'),"autosize":"","size":"big","placeholder":_vm.$t('crm.activities.actions.action_popup.email_fields.subject'),"type":"text","disabled":_vm.preview},model:{value:(_vm.emailSubject),callback:function ($$v) {_vm.emailSubject=$$v},expression:"emailSubject"}}),_c('div',{class:[
            'ui-d-flex',
            'ui-jc-space-between',
            'ui-m-xl-b',
          ]},[_c('crm-action-params',{attrs:{"disabled":_vm.preview,"message-params":_vm.messageParams},on:{"select":function($event){return _vm.handleAddParam($event, 'emailSubject')}}})],1),_c('div',{staticClass:"template-wrapper ui-d-flex ui-jc-space-between"},[_c('div',{staticClass:"ui-d-flex ui-ai-center"},[_c('ui-icon',{attrs:{"lib":"fa","substyle":"fas","name":"solid memo-pad","color":_vm.$theme.accentColor}}),_c('div',{staticClass:"ui-m-sm-l"},[_c('b',[_vm._v(_vm._s(_vm.$t('crm.activities.actions.mail_template.template'))+":")]),_vm._v(" "+_vm._s(_vm.activeTemplate.name)+" ")])],1),(_vm.templates.length > 1)?_c('div',{staticClass:"ui-d-flex ui-ai-center change-action",on:{"click":function($event){return _vm.handleChangeTemplate(_vm.activeTemplate.id)}}},[_c('ui-icon',{attrs:{"lib":"fa","substyle":"fas","name":"solid arrows-rotate","color":_vm.$theme.accentColor}}),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('crm.activities.actions.mail_template.title'))+" ")])],1):_vm._e()]),_c('div',{staticClass:"select-block ui-m-sm-b"},[_c('div',{staticClass:"ui-d-flex ui-g-w-md ui-ai-center",class:{
              'ui-m-sm-b': _vm.curentErrors.message,
            }},[_c('span',{staticClass:"crm-add-mail-action-popup__label label ui-g-md",class:{
                'crm-add-mail-action-popup__label_has-errors': _vm.curentErrors.message,
              }},[_vm._v(_vm._s(_vm.$t('crm.activities.actions.action_popup.email_fields.message')))]),(_vm.curentErrors.message)?_c('crm-error-panel',{staticClass:"ui-g-md ui-ai-baseline"},[_vm._v(" "+_vm._s(_vm.curentErrors.message)+" ")]):_vm._e()],1),_c('div',{staticClass:"ui-d-flex ui-jc-space-between"},[_c('v-md-editor',{ref:"editor",staticClass:"crm-add-mail-action-popup__message-editor",class:{
                'crm-add-mail-action-popup__message-editor_has-error': _vm.curentErrors.message,
              },attrs:{"mode":"edit","right-toolbar":"fullscreen","left-toolbar":"undo redo clear | h bold italic | ul ol | link image | parameter","toolbar":_vm.mdEditorToolbar,"height":"370px","tabindex":"1"},on:{"input":function($event){return _vm.setPreviewToChangedDebounced(_vm.index)},"change":_vm.injectTabIndexDebounced},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),(_vm.currentPreviewState === _vm.stateEnum.loaded)?_c('ui-iframe',{staticClass:"crm-add-mail-action-popup__message-preview",attrs:{"html":_vm.previewList[_vm.index],"height":"370px"}}):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],staticClass:"ui-fg-1",staticStyle:{"width":"626px"}})],1)])],1)]),_c('div',{staticClass:"foot"},[_c('ui-button',{attrs:{"color":"red","lib":"fa","substyle":"fas","icon":"times","disabled":_vm.isLoading},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t('crm.buttons.cancel'))+" ")]),_c('ui-button',{attrs:{"lib":"fa","substyle":"fas","icon":"paper-plane","disabled":_vm.isLoading || _vm.isActionsSendDisabled},on:{"click":function($event){return _vm.handleSendTestEmail()}}},[_vm._v(" "+_vm._s(_vm.$t('crm.buttons.send_test_email'))+" ")]),_c('ui-button',{attrs:{"color":_vm.$theme.mainColor,"disabled":_vm.isLoading || !_vm.isValid,"filled":""},on:{"click":function($event){return _vm.handleSave()}}},[_c('i',{staticClass:"fas fa-check plus-icon-custom"}),_vm._v(_vm._s(_vm.$t('crm.buttons.save_action'))+" ")])],1)]),_c('div',{staticClass:"wrapper"})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }