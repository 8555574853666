import store from '@/store';
import { SITE_SETTINGS_FETCH } from './action-types';

export default {
  [SITE_SETTINGS_FETCH]: async ({ commit }) => {
    if (store.getters['auth/adminAcl'].is_superuser
      || store.getters['auth/adminAcl'].teams.length) {
      const response = await store.$api.getSiteSettings({});
      commit(SITE_SETTINGS_FETCH, response.data.payload);
    }
  },
};
