import _ from 'lodash';
import store from '@/store';
import i18n from '@/i18n';

import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_SAVE_TOKEN,
  AUTH_BY_TOKEN,
  AUTH_LOGOUT,
  AUTH_2FA_REQUEST,
  AUTH_2FA_SUCCESS,
  AUTH_2FA_ERROR,
  AUTH_2FA_RESET,
  AUTH_QR_SET,
  AUTH_ADMIN_INFO,
  AUTH_ADMIN_ACL,
  AUTH_CURRENT_TEAM,
  AUTH_CURRENT_ACL,
  BEFORE_GET_ACL,
  CHANGE_ENOUGH_PERMISSIONS,
} from '../action_types/auth';
import {
  MISC_INIT_DATA,
  MISC_INIT_COUNTERS,
} from '../action_types/misc';

import { GET_DASHBOARDS, GET_DASHBOARDS_FLAG_BLOCK } from '../action_types/dashboards';
import { I18N_SET_LANG } from '@/store/action_types/i18n';

const getters = {
  isAuthenticated: state => state.status === 'success' && state.status2fa === 'success',
  authStatus: state => state.status,
  tfaStatus: state => state.status2fa,
  qrSrc: state => state.qrSrc,
  token: state => state.token,

  adminInfo: state => state.adminInfo,
  adminAcl: state => state.adminAcl,
  currentTeam: state => state.currentTeam,
  currentAcl: state => state.currentAcl,
};

const actions = {
  [AUTH_REQUEST]: async ({ commit }, payload) => {
    commit(AUTH_REQUEST);
    try {
      const response = await store.$api.signIn(payload);
      if (response.data.success) {
        commit(AUTH_SUCCESS);
        store.$api.affiliatesApi.defaults.headers.common.Authorization = response.data.payload.id;
        store.$api.miscApi.defaults.headers.common.Authorization = response.data.payload.id;
        commit(AUTH_SAVE_TOKEN, response.data.payload);
        if (response.data.payload.otp_auth_url && response.data.payload.otp_qr_code_url) {
          commit(AUTH_QR_SET, response.data.payload.otp_qr_code_url);
        }
        return response.data.payload;
      }
      commit(AUTH_ERROR);
      throw new Error('AUTH ERROR');
    } catch (error) {
      if (error.data.errors) {
        const errorMessage = error.data.errors;
        commit(AUTH_ERROR);
        throw errorMessage;
      }

      if (error.data.errors === null) {
        const errorMessage = {
          message: error.data.message,
          email: [{
            message: error.data.message,
            code: error.data.code,
          }],
          password: [{
            message: error.data.message,
            code: error.data.code,
          }],
        };
        commit(AUTH_ERROR);
        throw errorMessage;
      }
      commit(AUTH_ERROR);
      throw error;
    }
  },
  [AUTH_LOGOUT]: async ({ commit }) => {
    try {
      await store.$api.signOut();
      commit(AUTH_LOGOUT);
      return;
    } catch (error) {
      commit(AUTH_LOGOUT);
      throw error;
    }
  },
  [AUTH_2FA_REQUEST]: async ({ commit }, payload) => {
    try {
      const { data: { payload: response } } = await store.$api.confirm2fa(payload);

      if (response.result) {
        await store.dispatch(`auth/${AUTH_ADMIN_ACL}`);

        store.dispatch(`auth/${AUTH_ADMIN_INFO}`).then(() => {
          store.dispatch(`i18n/${I18N_SET_LANG}`, store.getters['auth/adminInfo'].language_code);
        });
        store.dispatch(`misc/${MISC_INIT_DATA}`);
        store.dispatch('session/initialSessionData');
        store.dispatch(`misc/${MISC_INIT_COUNTERS}`);
        commit(AUTH_SUCCESS);
        commit(AUTH_2FA_SUCCESS);
        return response;
      }
      commit(AUTH_2FA_ERROR);
      const errorMessage = i18n.messages[i18n.locale].validation.errors[2006];
      const er = {
        message: 'WRONG 2FA CODE',
        token: [
          {
            message: errorMessage,
            code: 2006,
          },
        ],
      };
      throw er;
    } catch (error) {
      commit(AUTH_2FA_ERROR);
      throw error;
    }
  },
  [AUTH_2FA_RESET]: ({ commit }) => {
    commit(AUTH_2FA_RESET);
  },
  [AUTH_ADMIN_INFO]: async ({ commit }) => {
    const response = await store.$api.getAdminInfo();
    commit(AUTH_ADMIN_INFO, response.data.payload);
  },
  [AUTH_ADMIN_ACL]: async ({ commit, dispatch }) => {
    const response = await store.$api.getAcl();
    commit(AUTH_ADMIN_ACL, response.data.payload);
    const findTeam = _.find(state.adminAcl.teams, ['id', localStorage.getItem('active-team')]);
    if (state.adminAcl.is_superuser) {
      if ((localStorage.getItem('active-team') && findTeam === undefined) || localStorage.getItem('active-team') === null) {
        localStorage.setItem('active-team', '');
      }
    } else if (!state.adminAcl.is_superuser && state.adminAcl.teams.length === 0) {
      localStorage.setItem('active-team', '');
    } else if (!localStorage.getItem('active-team') || findTeam === undefined || localStorage.getItem('active-team') === null) {
      localStorage.setItem('active-team', state.adminAcl.teams[0].id);
    }
    if (localStorage.getItem('active-team') && localStorage.getItem('active-team') !== null) {
      commit(AUTH_CURRENT_TEAM, localStorage.getItem('active-team'));
      commit(AUTH_CURRENT_ACL, state.adminAcl.permissions[localStorage.getItem('active-team')]);
    }
    store.$api.affiliatesApi.defaults.headers.common['X-Team-Id'] = localStorage.getItem('active-team');

    await dispatch(BEFORE_GET_ACL);
  },

  [CHANGE_ENOUGH_PERMISSIONS]: async ({ commit }) => {
    const { data: { payload } } = await store.$api.getAcl();
    commit(AUTH_ADMIN_ACL, payload);
    const findTeam = _.find(payload.teams, ['id', localStorage.getItem('active-team')]);
    if (payload.is_superuser) {
      if ((localStorage.getItem('active-team') && findTeam === undefined) || localStorage.getItem('active-team') === null) {
        localStorage.setItem('active-team', '');
      }
    } else if (!payload.is_superuser && payload.teams.length === 0) {
      localStorage.setItem('active-team', '');
    } else if (!localStorage.getItem('active-team') || findTeam === undefined || localStorage.getItem('active-team') === null) {
      localStorage.setItem('active-team', payload.teams[0].id);
    }
    commit(AUTH_CURRENT_TEAM, localStorage.getItem('active-team'));
    commit(AUTH_CURRENT_ACL, localStorage.getItem('active-team') ? payload.permissions[localStorage.getItem('active-team')] : {});
  },

  [BEFORE_GET_ACL]: async () => {
    await store.dispatch(`auth/${AUTH_ADMIN_INFO}`);
    await store.dispatch(`i18n/${I18N_SET_LANG}`, store.getters['auth/adminInfo'].language_code);

    await Promise.all([
      store.dispatch('reports/getSettings'),
      store.dispatch(`dashboards/${GET_DASHBOARDS}`),
    ]);
    store.commit(`dashboards/${GET_DASHBOARDS_FLAG_BLOCK}`, true);
    setTimeout(() => store.commit(`dashboards/${GET_DASHBOARDS_FLAG_BLOCK}`, false), 1000);
  },
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [AUTH_SUCCESS]: (state) => {
    localStorage.setItem('auth-token', state.token);
    state.status = 'success';
  },
  [AUTH_SAVE_TOKEN]: (state, response) => {
    state.token = response.id;
  },
  [AUTH_ERROR]: (state) => {
    localStorage.removeItem('auth-token');
    state.status = 'error';
  },
  [AUTH_LOGOUT]: (state) => {
    localStorage.removeItem('auth-token');
    localStorage.removeItem('status2fa');
    state.token = '';
    state.status = '';
    state.status2fa = '';
  },
  [AUTH_2FA_REQUEST]: (state) => {
    state.status2fa = 'loading';
  },
  [AUTH_2FA_SUCCESS]: (state) => {
    localStorage.setItem('status2fa', 'success');
    state.status2fa = 'success';
  },
  [AUTH_2FA_ERROR]: (state) => {
    localStorage.removeItem('status2fa');
    state.status2fa = 'error';
  },
  [AUTH_BY_TOKEN]: (state, token) => {
    localStorage.setItem('auth-token', token);
    localStorage.setItem('status2fa', 'success');
    state.status = 'success';
    state.status2fa = 'success';
    state.token = token;
  },
  [AUTH_2FA_RESET]: (state) => {
    localStorage.removeItem('status2fa');
    state.status2fa = '';
  },
  [AUTH_QR_SET]: (state, qrSrc) => {
    state.qrSrc = qrSrc;
  },


  [AUTH_ADMIN_INFO]: (state, payload) => {
    state.adminInfo = _.cloneDeep(payload);
  },

  [AUTH_ADMIN_ACL]: (state, payload) => {
    state.adminAcl = _.cloneDeep(payload);
  },

  [AUTH_CURRENT_TEAM]: (state, payload) => {
    state.currentTeam = payload;
  },

  [AUTH_CURRENT_ACL]: (state, payload) => {
    state.currentAcl = payload;
  },
};

const state = {
  token: localStorage.getItem('auth-token') || '',
  status: localStorage.getItem('auth-token') ? 'success' : '',
  status2fa: localStorage.getItem('status2fa') || '',
  qrSrc: '',

  adminAcl: {},
  adminInfo: {},
  currentTeam: {},
  currentAcl: {},
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
