<template>
  <ui-select
    :value="value"
    :options="currencies"
    :disabled="disabled"
    :filter-placeholder="$t('settings.currencies.currency')"
    class="select"
    width="initial"
    value-prop="code"
    label-prop="name"
    name-prop="code"
    prefix-prop="symbol"
    :placeholder="$t('settings.currencies.allCurrencies')"
    placeholder-icon="fas fa-money-bill-wave"
    :allow-remove-value="false"
    :filterable="true"
    @input="handleInput"
  />
</template>

<script>
export default {
  name: 'UiCurrencyPicker',
  props: {
    currencies: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleInput(code) {
      this.$emit('input', code);
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  /deep/ {
    height: 30px;

    .pop {
      width: calc(100% + 100px);
      left: -50px;
    }

    .control-wrapper {
      height: 100%;
      flex-wrap: nowrap;

      .tags {
        height: 100%;
      }

      .tags .value {
        justify-content: center;
        padding-left: 0;

        .prefix {
          color: #7d7d7d;
          margin-right: 4px;
          font-size: 12px;
        }

        .label {
          color: #303634;
          font-size: 12px;
          margin: 0;
        }
      }

      .placeholder {
        text-wrap: nowrap;
      }

      span.placeholder {
        margin-bottom: 1px;
      }

      .fa-chevron-down {
        color: #7d7d7d!important;
        font-size: 12px!important;
      }
    }
  }
}
</style>
