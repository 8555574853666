export default {
  marketingMaterials: {
    pageTitle: 'Промо-материалы',
    buttons: {
      add_material: 'Добавить материал',
      delete: 'Удалить',
      edit: 'Редактировать',
    },
    fields: {
      incrementing_id: {
        title: 'ID',
      },
      name: {
        title: 'Имя',
      },
      type: {
        title: 'Тип',
        html5_banner: 'HTML5 баннер',
        simple_banner: 'Простой баннер',
        landing: 'Лендинг',
      },
      visibility: {
        title: 'Доступность',
        public: 'Публичный',
        private: 'Приватный',
        hidden: 'Скрытый',
      },
      status: {
        title: 'Статус',
        active: 'Активный',
        inactive: 'Не активный',
      },
      created_at: {
        title: 'Дата создания',
      },
      updated_at: {
        title: 'Дата обновления',
      },
      language_codes: {
        title: 'Языки',
      },
      impressions_count: {
        title: 'Показы',
      },
      visits_count: {
        title: 'Посещения',
      },
      registrations_count: {
        title: 'Регистрации',
      },
      conversion_rate: {
        title: 'Конверсия, %',
      },
      brand_visibility: {
        title: 'Бренды',
        all: 'Все бренды',
      },
    },
    popup: {
      title: 'Промо-материала',
      add: 'Добавление',
      edit: 'Редактирование',
      form: {
        name: {
          label: 'Имя',
          placeholder: 'Название материала',
        },
        type: {
          label: 'Тип',
          banner: 'Баннер',
          landing: 'Лендинг',
        },
        bannerType: {
          label: 'Тип баннера',
          html5_banner: 'HTML5 баннер',
          simple_banner: 'Простой баннер',
        },
        visibility: {
          label: 'Доступность',
          public: 'Публичный',
          private: 'Приватный',
          hidden: 'Скрытый',
          placeholder: 'Выберите партнеров, которые могут использовать лендинг',
        },
        upload: {
          label: 'Загрузка файла',
        },
        language_codes: {
          label: 'Языки',
          placeholder: 'Выберите языки',
        },
        width: {
          label: 'Ширина',
          placeholder: 'Введите ширину',
        },
        height: {
          label: 'Высота',
          placeholder: 'Введите высоту',
        },
        status: {
          label: 'Статус',
          active: 'Активный',
          inactive: 'Не активный',
        },
        landing_path: {
          label: 'Путь до лендинга',
          placeholder: 'Введите путь до лендинга',
        },
        supported_platforms: {
          label: 'Поддерживаемые платформы',
          desktop: 'Десктопная',
          mobile: 'Мобильная',
        },
      },
      cancel: 'Отмена',
      save: 'Сохранить',
    },
    messages: {
      delete: 'Вы уверены, что хотите удалить <span>{name}</span> ?',
      'drop-file-here-or': 'Перетащите файл сюда или',
      'click-to-upload': 'кликните для загрузки файла.',
      replace_file: 'Заменить файл',
      download_file: 'Скачать файл',
      uploading: 'Загрузка...',
    },
  },
};
