// import { storagePrefix } from '@/service/configLocalStorage';

export const resolvePageSize = (viewName = 'viewName', { _default, sizes }) => {
  // const manual = window.localStorage.getItem(`${storagePrefix}${viewName}`);
  const manual = window.localStorage.getItem(`${viewName}`);
  if (manual && manual !== 'null') {
    return parseInt(manual, 10);
  }

  const screenHeight = window.screen.height;

  if (sizes[screenHeight]) return sizes[screenHeight];

  if (screenHeight >= 1080 && screenHeight <= 1200) {
    return sizes[1080];
  }

  if (screenHeight > 1200 && screenHeight < 1440) {
    return sizes[1440];
  }

  return _default;
};

export const pageSizeMixin = {
  updated() {
    setTimeout(() => {
      const { pageLimitProp = 'limit', viewName = 'viewName' } = this;
      this.$ls.set(viewName, this[pageLimitProp]);
    }, 1000);
  },
};
