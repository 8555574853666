<template>
  <div class="btn-drill-exc-wr">
    <ui-button
      :disabled="disabledBtn"
      class="btn"
      lib="fa"
      substyle="fas"
      :icon="icon"
      @click="disabledDropdown
        ? createReport(null)
        : drilldownReport()"
    >
      {{ btnTitle }}
    </ui-button>
    <el-dropdown
      :class="{'disabled': disabledDropdown}"
      trigger="click"
    >
      <ui-button :disabled="disabledDropdown">
        <i class="el-icon-arrow-down el-icon--right" />
      </ui-button>
      <el-dropdown-menu
        slot="dropdown"
        :class="{'disabled': disabledDropdown}"
        class="drill-exc ddgroup"
      >
        <el-dropdown-item>
          <div
            @click="createReport(report, 'continue')"
          >
            {{ $t('reports.other.continue_new_tab') }}
          </div>
        </el-dropdown-item>
        <el-dropdown-item>
          <div @click="createReport(null)">
            {{ $t('reports.other.start_new_tab') }}
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: 'DrilldownButton',
  props: {
    report: {
      type: Object,
      required: true,
    },
    typeAction: {
      type: String,
      required: true,
    },
    disabledBtn: {
      type: Boolean,
      default: false,
    },
    disabledDropdown: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      action: this.typeAction === 'exclude' ? '!=' : '=',
      btnTitle: this.typeAction === 'exclude' ? this.$t('reports.other.exclude') : this.$t('reports.other.drilldown'),
      icon: this.typeAction === 'exclude' ? 'page-break' : 'random',
    };
  },

  methods: {
    createReport(data, continueType) {
      this.$emit('createReport', data, this.action, continueType);
    },

    drilldownReport() {
      this.$emit('drilldownReport', this.action);
    },
  },
};
</script>
