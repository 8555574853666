<template>
  <div
    v-loading="isDataLoading"
    class="landing-leagues"
  >
    <div class="head">
      <div class="title">
        <div class="title-with-button">
          {{ $t('tools.landingLeagues.leagues.land_leagues') }}
          <el-select
            ref="brandsSelect"
            v-model="currentBrand"
            filterable
            class="select"
            value-key="currentBrand"
            @change="handleRefresh"
          >
            <template slot="prefix">
              <img
                v-if="getBrandImgUrl()"
                :src="getBrandImgUrl()"
                class="prefix"
              >
            </template>
            <el-option
              v-for="item in brands"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <img
                v-if="item.favicon_base64"
                :src="item.favicon_base64"
              >
              <span>{{ item.name }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <ui-table
      :fields="fields"
      :data="leagues"
      :rows-count="count"
      :sort="{
        prop: sortBy,
        order: sortDir,
      }"
      :computed-row-class="getRowClass"
      lazy-loading
      is-static
      i18n-path="tools.landingLeagues.leagues"
      element-loading-custom-class="data-loader-spinner"
      @current-change="handleCurrentRowChange"
      @sort="handleSortLeagues"
    >
      <template slot="append">
        <el-table-column
          v-if="false"
          :width="60"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row.landing_preview_url !== null"
              class="row-value actions"
            >
              <a
                :href="scope.row.landing_preview_url"
                target="_blank"
                class="act green"
                @click.stop
              >{{ $t('tools.landingLeagues.leagues.preview') }}</a>
            </div>
            <!-- :to="`/tools/league/${scope.row.id}/matches`" -->
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import detectPermissions from '../../service/detectPermissions';

export default {
  name: 'LandingLeagues',
  data() {
    return {
      currentBrand: '',
      fields: [
        {
          class: 'league-name',
          name: 'name',
          align: 'left',
          sortable: 'custom',
        },
        {
          name: 'last_starts_at',
          width: 120,
          align: 'left',
          sortable: 'custom',
          format: 'date',
          formatOptions: {
            in: '',
            out: 'DD MMM YYYY HH:mm',
          },
          class: 'last-match',
        },
        {
          name: 'matches_count',
          width: 125,
          align: 'right',
          sortable: 'custom',
        },
      ],
      leagues: [],
      count: 0,
      sortBy: 'name',
      sortDir: 'asc',
      isDataLoading: false,
      previewClick: true,
    };
  },
  computed: {
    ...mapGetters({
      brands: 'misc/brands',
    }),

    siteId: {
      get() {
        return this.currentBrand ? this.currentBrand : '';
      },
      set(newVal) {
        this.currentBrand = newVal;
      },
    },
  },
  watch: {
    sortBy(v) {
      this.$ls.set('tools/league/list/sortProp', JSON.stringify(v));
    },
    sortDir(v) {
      this.$ls.set('tools/league/list/sortOrder', JSON.stringify(v));
    },
    currentBrand(v) {
      this.$ls.set('tools/league/list/siteId', JSON.stringify(v));
    },
  },
  created() {
    if (detectPermissions.checkRequest('tools_promo_landings_view')) {
      this.sortBy = JSON.parse(this.$ls.get('tools/league/list/sortProp')) || 'name';
      this.sortDir = JSON.parse(this.$ls.get('tools/league/list/sortOrder')) || 'asc';
      this.currentBrand = this.$route.query.site_id ? this.$route.query.site_id : JSON.parse(this.$ls.get('tools/league/list/siteId')) || this.brands[0].id;
    }
    detectPermissions.checkRoute('tools_promo_landings_view');
  },
  mounted() {
    if (detectPermissions.checkRequest('tools_promo_landings_view')) {
      this.getLeagues();
    }
  },
  methods: {
    handleCurrentRowChange(row) {
      this.$router.push(`/tools/league/${row.id}/matches?site_id=${this.siteId}`);
    },
    handleRefresh() {
      this.getLeagues();
    },
    getBrandImgUrl() {
      const brandFavicon = this.currentBrand.length !== 0 ? this.brands.filter(brand => brand.id === this.currentBrand)[0].favicon_base64 : '';
      return brandFavicon !== undefined ? brandFavicon : false;
    },
    getLeagues() {
      this.isDataLoading = true;
      this.$api.getLeagues({
        sort_column: this.sortBy,
        sort_dir: this.sortDir,
        site_id: this.currentBrand,
      })
        .then((response) => {
          this.leagues = this.$_.cloneDeep(response.data.payload);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    getRowClass({ row }) {
      return this.$moment(row.last_starts_at).diff(this.$moment(), 'hours', true) < 72 ? 'late' : '';
    },
    handleSortLeagues({ prop, order }) {
      if (order === '') return;
      if (this.sortBy !== prop) {
        this.sortBy = prop;
      } else {
        this.sortDir = order;
      }
      this.getLeagues();
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-leagues{
  width: 90%;
  min-width: 1140px;
  max-width: 1700px;
  margin: 0 auto;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
}
</style>
