<template>
  <div v-if="isOpen" class="blocked-countries-popup">
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ $t('domainManagement.blockedPopup.title') }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div class="body">
        <div class="ui-fg-1">
          <div v-if="canEdit" class="field">
            <span class="label-text">{{
              $t('domainManagement.blockedPopup.addCountry')
            }}</span>
            <div class="content">
              <el-select
                v-model="countryCode"
                :placeholder="
                  $t('domainManagement.blockedPopup.addCountryPlaceholder')
                "
                class="select"
                filterable
                size="big"
              >
                <el-option
                  v-for="item in filteredCountries"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
              <ui-button
                :color="$theme.mainColor"
                :disabled="isLoading || !countryCode"
                lib="fa"
                substyle="fas"
                icon="plus"
                size="big"
                class="ui-m-md-l"
                @click="handleAdd()"
              >
                {{ $t('ui.buttons.add') }}
              </ui-button>
            </div>
          </div>
          <div>
            <ui-table
              v-loading="isLoading"
              :fields="fields"
              disable-sort
              is-static
              external-height="240px"
              :data="blockedCountries"
              i18n-path="domainManagement.blockedPopup"
              element-loading-custom-class="data-loader-spinner"
              class="table ui-m-xl-t"
              :padding="0"
            >
              <template v-if="canEdit" slot="append">
                <el-table-column width="74">
                  <template slot="header" slot-scope="scope">
                    <div :key="scope.$index" class="table-action">
                      {{ $t('settings.tokens.actions') }}
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div class="table-action">
                      <ActionIcon :tooltip="$t('ui.buttons.delete')">
                        <el-link
                          class="action-ico"
                          type="danger"
                          :underline="false"
                          @click="handleDelete(scope.row)"
                        >
                          <ui-icon
                            name="trash-alt"
                            :color="$theme.dangerColor"
                            lib="fa"
                          />
                        </el-link>
                      </ActionIcon>
                    </div>
                  </template>
                </el-table-column>
              </template>
            </ui-table>
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          lib="fa"
          filled
          substyle="fas"
          icon="check"
          :disabled="isLoading"
          size="big"
          @click="close"
        >
          {{ $t('crm.buttons.close') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BlockedCountriesPopup',
  props: {
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      id: null,
      isOpen: false,
      isLoading: true,
      countryCode: null,
      blockedCountries: [],
      fields: [
        {
          name: 'country_name',
        },
      ],
    };
  },

  computed: {
    ...mapGetters('misc', ['countries']),
    filteredCountries() {
      return this.$_.differenceBy(this.countries, this.blockedCountries, 'code');
    },
  },
  methods: {
    async handleDelete({ country_code }) {
      this.isLoading = true;
      try {
        await this.$api.deleteBlockedCountry(this.id, { country_code });
        await this.fetchData();
        this.$emit('submit');
      } finally {
        this.isLoading = false;
      }
    },
    async handleAdd() {
      this.isLoading = true;
      try {
        await this.$api.updateBlockedCountry(this.id, { country_code: this.countryCode });
        await this.fetchData();
        this.countryCode = null;
        this.$emit('submit');
      } finally {
        this.isLoading = false;
      }
    },
    async fetchData() {
      this.isLoading = true;

      try {
        const { data: { payload: { blocked_countries } } } = await this.$api.getServiceDomainById(this.id);
        this.blockedCountries = blocked_countries.map(country => ({
          ...country,
          code: country.country_code,
        }));
      } finally {
        this.isLoading = false;
      }
    },
    async open(id) {
      this.countryCode = null;
      this.id = id;
      this.isOpen = true;

      await this.fetchData();
    },
    close() {
      this.isOpen = false;
      this.isLoading = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.blocked-countries-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
    width: 100%;
  }

  .popup {
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 8vh;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;

    .head {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;

      .name {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
      }

      .close {
        font-size: 15px;
        cursor: pointer;

        &:hover {
          color: var(--main-color);
        }
      }
    }

    .body {
      padding: 16px 24px;
      display: flex;
      position: relative;

      .table {
        /deep/ {
          th,
          tr td,
          .el-table,
          .el-table__body {
            border: none;
          }

          .el-table {
            &:after,
            &:before {
              display: none;
            }
          }
        }
      }

      .table-action {
        display: flex;
        flex: 1;
        justify-content: center;
      }

      .field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .content {
          display: flex;
          width: 100%;
        }

        .select {
          width: 100%;
        }

        .label-text {
          font-size: 14px;
          color: var(--crm-panel-text);
          margin-bottom: 5px;
        }
      }
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
    }
  }
}
</style>
