export default {
  payments: {
    name: 'Payments',
    page_title: {
      payments_moderation: {
        name: 'Payments Moderation — ',
        on_review: 'Review',
        approved: 'Approved',
        declined: 'Declined',
      },
      payments_payout: {
        name: 'Payments Payout — ',
        not_paid: 'Not Paid',
        for_rework: 'For Rework',
        processing: 'Processing',
        paid: 'Paid Out',
        failed: 'Failed',
      },
      cpa_conversions: 'CPA Conversions',
    },
    dashboard: {
      title: 'Payment History',
      fields: {
        date: {
          title: 'Date',
        },
        affiliates_count: {
          title: 'Affiliate',
        },
        payments_count: {
          title: 'Payments',
        },
        paid_amount: {
          title: 'Sum',
        },
      },
    },
    outsiders: {
      title: 'Outsiders',
      fields: {
        affiliate_email: {
          title: 'Affiliate',
        },
        latest_payment_amount: {
          title: 'Amount',
        },
        latest_payment_at: {
          title: 'Last Payment',
        },
        last_7_days_revenue: {
          title: '7d. Revenue',
        },
        last_14_days_revenue: {
          title: '14d. Revenue',
        },
        last_30_days_revenue: {
          title: '30d. Revenue',
        },
      },
    },
    history: {
      fields: {
        incrementing_id: {
          title: 'ID',
        },
        affiliate_email: {
          title: 'Affiliate',
        },
        team_name: {
          title: 'Team',
        },
        amount: {
          title: 'Amount',
        },
        available_balance: {
          title: 'Balance',
        },
        hold_balance: {
          title: 'Hold',
        },
        payment_period_end: {
          title: 'Period',
          tooltip: 'smth',
        },
        created_at: {
          title: 'Created at',
        },
        site_payment_method_name: {
          title: 'Method',
        },
        payment_wallet: {
          title: 'Wallet',
        },
        approved_by_admin_name: {
          title: 'Approved By',
        },
      },
      notPaid: 'Not Paid',
      paidAt: 'Paid at',
      back: 'Back',
      info: 'Info',
      toReview: 'To Review',
      decline: 'Decline',
      affiliate: 'Affiliate',
      balance: 'Balance',
      hold: 'Hold',
      period: 'Period',
      method: 'Method',
      approvedBy: 'Approved By',
      actions: 'Actions',
      periodTooltip: 'Period tooltip',
      approvedTooltip: 'Approved by tooltip',
      payments_dashboard: 'Payments Dashboard',
    },
    info: {
      players_selected: '{count} Player Selected | {count} Player Selected | {count} Players Selected',
      payment_info: 'Payment Info',
      payment_for: 'Payment for',
      decline: 'Decline',
      approve: 'Approve',
      paidManually: 'Paid Manually',
      to_review: 'To Review',
      reworked: 'Reworked',
      created_at: 'Created at',
      affiliate_email: 'Affiliate',
      approved_by: 'Approved by',
      team: 'Team',
      introduced_by: 'Introduced by',
      amount: 'Amount',
      hold_balance: 'Hold Balance',
      available_balance: 'Available Balance',
      payment_method: 'Payment Method',
      wallet_number: 'Wallet Number',
      payment_period: 'Payment Period',
      statistics: 'Statistics',
      players: 'CPA Players',
      hide_conversion: 'Hide stats after conversion',
      for_network: 'for network',
      cancel: 'Cancel',
      charge: 'Charge',
      history: 'History',
      rev_share: 'RevShare',
      offer: 'Offer',
      cpa: 'CPA',
      table_players: 'Players',
      am_balance: 'Amount & Balance',
      in_hold: 'In Hold',
      available: 'Available',
      period: 'Period',
      information: 'Information',
      add_information: 'Additional Info',
      actions: 'Actions',
      to: 'To:',
      approve_tooltip: 'Invoice required',
      approve_wallet: 'Method is not set',
    },
    uploadFilesPopup: {
      title: 'Attach File',
      label: 'Upload a File',
      'drop-file-here-or': 'Drop file here or',
      'click-to-upload': 'click to upload',
      cancel: 'Cancel',
      save: 'Save',
    },
    noty: {
      req_fail: 'Request failed with status code {statusCode}',
      charge_soon: 'Charge will be created soon',
    },
    confirm: {
      title_1: 'Approve Payment?',
      msg_1: 'Are you sure want to approve payment for <span>{affiliateEmail}</span> ?',
      action_1: 'Approve',

      title_2: 'Decline Payment?',
      msg_2: 'Are you sure want to decline this payment for <span>{affiliateEmail}</span> ?',
      action_2: 'Decline',

      title_3: 'Return Payment To Review?',
      msg_3: 'Are you sure want to return this payment to review?',
      action_3: 'To Review',

      title_4: 'Decline Payment?',
      msg_4: 'Are you sure want to decline this payment?',
      action_4: 'Decline',

      title_5: 'Charge Players?',
      msg_5: 'Are you sure you want to charge {id} selected players?',
      action_5: 'Charge',

      title_6: 'Approve And Pay Manually?',
      msg_6: 'Are you sure you want to approve and pay manually for <span>{affiliateEmail}</span> ?',
      action_6: 'Approve',

      title_7: 'Return to «Not Paid»?',
      msg_7: 'Are you sure that you have finished making changes to the payment request?',
      action_7: 'Yes',

      decline: 'Decline',
    },
    route: {
      history: 'History',
      not_paid: 'Not Paid',
      payments: 'Payments',
      review: 'Review',
      affiliate: 'Affiliate',
    },
    review: {
      title: 'Payments Review',

      fields: {
        incrementing_id: {
          title: 'ID',
        },
        affiliate_email: {
          title: 'Affiliate Email',
        },
        team_name: {
          title: 'Team',
        },
        amount: {
          title: 'Amount',
        },
        available_balance: {
          title: 'Balance',
        },
        hold_balance: {
          title: 'Hold',
        },
        payment_period_end: {
          title: 'Period',
        },
        site_payment_method_name: {
          title: 'Method',
        },
        payment_wallet: {
          title: 'Wallet',
        },
        created_at: {
          title: 'Created at',
        },
        affiliate_referred_by_info: {
          title: 'Introduced by',
        },
      },
    },
    moderation: {
      title: 'Moderation',
      on_review: 'On Review',
      approved: 'Approved',
      declined: 'Declined',
    },
    payout: {
      title: 'Payout',
      not_paid: 'Not Paid',
      processing: 'Processing',
      paid: 'Paid',
      failed: 'Failed',
      for_rework: 'For Rework',
    },
    players: {
      fields: {
        site_player_id: {
          title: 'Player ID',
        },
        external_id: {
          title: 'External ID',
        },
        registration_timestamp: {
          title: 'Registration Date',
        },
        site_registration_type_name: {
          title: 'Registration Type',
        },
        latest_activity: {
          title: 'Latest Activity',
        },
        latest_activity_timestamp: {
          title: 'Latest Activity',
        },
        latest_deposit_timestamp: {
          title: 'Latest Deposit',
        },
        chargebacks_count: {
          title: 'Charges Count',
          tip: 'Count',
        },
        chargebacks_sum: {
          title: 'Charges Sum',
          tip: 'Sum',
        },
        first_deposit_timestamp: {
          title: 'First Depo. Date',
        },
        conversion_timestamp: {
          title: 'Conversion Date',
        },
        deposits_count: {
          title: 'Deposits Count',
          tip: 'Count',
        },
        deposits_sum: {
          title: 'Deposits Sum',
          tip: 'Sum',
        },
        withdrawals_count: {
          title: 'Withdrawals Count',
          tip: 'Count',
        },
        withdrawals_sum: {
          title: 'Withdrawals Sum',
          tip: 'Sum',
        },
        betting_profit: {
          title: 'Betting Profit',
        },
        cpa_revenue: {
          title: 'CPA Revenue',
        },
        revshare_revenue: {
          title: 'RS Revenue',
        },
        revenue: {
          title: 'Revenue',
        },
        subid1: {
          title: 'SubID 1',
        },
        subid2: {
          title: 'SubID 2',
        },
        subid3: {
          title: 'SubID 3',
        },
        subid4: {
          title: 'SubID 4',
        },
        subid5: {
          title: 'SubID 5',
        },
        total_profit: {
          title: 'Total',
        },
        player_country_name: {
          title: 'Country',
        },
        top_deposits_payment_system_name: {
          title: 'Deposit Top PS',
        },
        top_withdrawals_payment_system_name: {
          title: 'Withdrawal Top PS',
        },
        sport_bets_count: {
          title: 'Sport Bets Count',
          tip: 'Count',
        },
        sport_bets_sum: {
          title: 'Sport Bets Sum',
          tip: 'Sum',
        },
        avg_sport_bets_odd: {
          title: 'Sport Bets Avg Odd',
          tip: 'Avg Odd',
        },
        games_bets_count: {
          title: 'Casino Bets Count',
          tip: 'Count',
        },
        games_bets_sum: {
          title: 'Casino Bets Sum',
          tip: 'Sum',
        },
        days_since_reg: {
          title: 'Days Since Reg',
        },
        days_active: {
          title: 'Days Active',
        },
      },
    },
    cpa_conversions: {
      statuses: {
        pending_qualification: 'Pending Qualification',
        in_hold: 'In Hold',
        on_review: 'On Review',
        approved: 'Approved',
        declined: 'Declined',
      },
      filter_popup: {
        brand: 'Brand',
        affiliate: 'Affiliate',
        offer: 'Offer',
        status: 'Status',
      },
      fields: {
        incrementing_id: {
          title: 'ID',
        },
        site_name: {
          title: 'Brand',
        },
        site_player_id: {
          title: 'Player',
        },
        affiliate_email: {
          title: 'Affiliate',
        },
        affiliate_offer_name: {
          title: 'Offer',
        },
        status: {
          title: 'Status',
        },
        revenue: {
          title: 'Revenue',
        },
        hold_expires_at: {
          title: 'Hold Expiration',
        },
        reviewed_by: {
          title: 'Reviewed',
        },
      },
    },
  },
};
