<template>
  <div v-if="isOpen" class="view-notification-popup">
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ $t('affiliates.notifications.popup.notification') }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div class="body overflow-visible">
        <div class="form">
          <crm-action-language-tab
            :languages="languages"
            :selected-index="index"
            @select="handleSelectTab"
          />
          <div class="date">
            {{ data.created_at | formatDate3 }}
          </div>
          <div class="title">
            {{ translations.title }}
          </div>
          <div class="description">
            {{ translations.message }}
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button lib="fa" filled substyle="fas" icon="check" @click="close()">
          {{ $t('crm.buttons.close') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CrmActionLanguageTab from '@/views/CRM/components/CrmActionLanguageTab.vue';

export default {
  name: 'ViewNotificationPopup',
  components: { CrmActionLanguageTab },
  mixins: [],
  props: {},
  data() {
    return {
      isOpen: false,
      data: null,
      index: 0,
    };
  },
  computed: {
    ...mapGetters({
      customerLanguages: 'misc/customerLanguages',
    }),
    languages() {
      const translations = this.data.translations.map(translation => this.customerLanguages.find(el => el.code === translation.language_code));

      return [{
        name: 'English',
        name_native: 'English',
        language_code: 'en',
        is_default: true,
      }, ...translations];
    },
    translations() {
      if (this.index === 0) {
        return { title: this.data.title, message: this.data.message };
      }

      return this.data.translations[this.index - 1];
    },

  },
  methods: {
    handleSelectTab(index) {
      this.index = index;
    },
    open(data) {
      this.isOpen = true;
      this.index = 0;
      this.data = data;
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow-visible {
  overflow: visible !important;
}

.view-notification-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
    width: 100%;
  }

  .popup {
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 8vh;
    width: 480px;
    height: auto;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;

    .head {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      .name {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
      }
      .close {
        font-size: 15px;
        cursor: pointer;
        &:hover {
          color: var(--main-color) !important;
        }
      }
    }

    .body {
      display: flex;
      position: relative;
      max-height: 84vh;
      overflow-y: auto;
      font-size: 14px;

      .form {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        padding: 8px 24px 16px 24px;

        .date {
          margin-top: 16px;
          font-size: 12px;
          font-weight: 500;
          color: #a6b2c4;
        }

        .title {
          font-size: 16px;
          font-weight: 500;
          color: #303634;
          margin-top: 8px;
          white-space: pre-line
        }

        .description {
          color: #303634;
          font-size: 13px;
          margin-top: 8px;
          white-space: pre-line;
        }
      }
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      .ui-button + .ui-button {
        margin-left: 8px;
      }
    }
  }
}
</style>
