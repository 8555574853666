import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('crm', ['crmMetrics']),
    pCrmMetrics() {
      const base = this.crmMetrics || [];
      return base.map(item => ({
        ...item,
        name: item.column_name,
        items: [],
      }));
    },
    crmMetricGroups() {
      return [{
        group_name: '',
        items: this.pCrmMetrics,
      }];
    },
  },
};
