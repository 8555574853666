<template>
  <div class="quality-assurance">
    <router-view
      class="quality-assurance__view"
      :current-brand="currentBrand"
    />
  </div>
</template>

<script>

export default {
  props: {
    currentBrand: {
      type: String,
      default: '',
    },
  },
};
</script>
