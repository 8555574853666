import i18n from '@/i18n';
import app from '@/main';

import {
  I18N_SET_LANG,
  I18N_LOAD_LANG,
} from '../action_types/i18n';

const getters = {
  currentLang: state => state.lang,
};

const actions = {
  [I18N_SET_LANG]: ({ commit }, payload) => new Promise((resolve, reject) => {
    if (payload !== state.lang) {
      if (state.loadedLangs.includes(payload)) {
        commit(I18N_SET_LANG, payload);
        resolve(payload);
      } else {
        import(`@/i18n/locales/${payload}.js`)
          .then((response) => {
            commit(I18N_LOAD_LANG, {
              lang: payload,
              locale: response,
            });
            commit(I18N_SET_LANG, payload);
            resolve(payload);
          })
          .catch((e) => {
            reject(e);
          });
      }
    }
    resolve(payload);
  }),
};

const mutations = {
  [I18N_SET_LANG]: (state, payload) => {
    state.lang = payload;
    i18n.locale = payload;
    app.$api.affiliatesApi.defaults.headers.common['X-Selected-Locale'] = payload;
    app.$api.miscApi.defaults.headers.common['X-Selected-Locale'] = payload;
    document.querySelector('html').setAttribute('lang', payload);
    localStorage.setItem('language', payload);
  },
  [I18N_LOAD_LANG]: (state, payload) => {
    state.loadedLangs.push(payload.lang);
    i18n.setLocaleMessage(payload.lang, payload.locale.default);
  },
};

const state = {
  lang: '',
  loadedLangs: ['en'],
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
