<template>
  <div class="payments">
    <div class="content-wrapper">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Payments',
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.payments{
  display: flex;
  flex-direction: column;
  .content-wrapper{
    flex-grow: 10;
    display: flex;
    flex-direction: column;
  }
}
</style>
