export default {
  /**
   * Get marketing materials count
   *
   * @param {Object} params
   * @param {String} [params.search]
   * @param {String} [params.language_code]
   * @param {String} [params.type]
   * @param {String} [params.status]
   *
   * @return {Promise<Object>} api response
   */
  getMarketingMaterialsCount(params) {
    return this.affiliatesApi.get('/marketing-materials/count', { params });
  },

  /**
   * Get marketing materials
   *
   * @param {Object} params
   * @param {String} [params.sort_column]
   * @param {String} [params.sort_dir]
   * @param {String} [params.search]
   * @param {String} [params.language_code]
   * @param {String} [params.type]
   * @param {String} [params.status]
   * @param {Number} [params.limit]
   * @param {Number} [params.offset]
   *
   * @return {Promise<Object>} api response
   */
  getMarketingMaterials(params) {
    return this.affiliatesApi.get('/marketing-materials', { params });
  },

  /**
   * Create marketing material
   *
   * @param {Object} data
   * @param {String} data.name
   * @param {String} data.type
   * @param {String} data.status
   * @param {String} data.landing_path
   * @param {String[]} data.supported_platforms
   * @param {Object[]} data.assets
   * @param {String} data.assets[].file_id
   * @param {String[]} data.assets[].sizes
   * @param {String[]} data.assets[].language_codes
   *
   * @return {Promise<Object>} api response
   */
  createMarketingMaterial(data) {
    return this.affiliatesApi.post('/marketing-materials', data);
  },

  /**
   * Get marketing material by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  getMarketingMaterialById(id) {
    return this.affiliatesApi.get(`/marketing-materials/${id}`);
  },

  /**
   * Update marketing material
   *
   * @param {String} id
   * @param {Object} data
   * @param {String} data.name
   * @param {String} data.status
   * @param {String[]} data.supported_platforms
   * @param {Object[]} data.assets
   * @param {String} data.assets[].file_id
   * @param {String[]} data.assets[].sizes
   * @param {String[]} data.assets[].language_codes
   *
   * @return {Promise<Object>} api response
   */
  updateMarketingMaterial(id, data) {
    return this.affiliatesApi.put(`/marketing-materials/${id}`, data);
  },

  /**
   * Delete marketing material by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  deleteMarketingMaterial(id) {
    return this.affiliatesApi.delete(`/marketing-materials/${id}`);
  },
};
