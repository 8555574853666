export default {
  theHeader: {
    menu: {
      dashboards: {
        main: 'Дашборды',
        overview: 'Overview',
        add_dashboard: 'Добавить Дашборд',
        templates: 'Templates',
      },
      live: {
        main: 'Live',
        'last-bets': 'Последние ставки & Депозиты',
        'top-events': 'Топ события',
      },
      reports: {
        main: 'Отчеты',
        report: 'Отчеты',
        paymentMethod: 'Методы оплаты',
      },
      affiliates: {
        main: 'Партнеры',
        list: 'Список партнеров',
        review: 'Партнеры на рассмотрении',
        notifications: 'Уведомления',
        requests: 'Запросы партнеров',
        groups: 'Группы',
      },
      payments: {
        main: 'Выплаты',
        dashboard: 'Панель',

        payment_payout_section: 'Проведение платежей',
        payment_moderation_section: 'Модерация платежей',
        on_review: 'На рассмотрении',
        approved: 'Одобрено',
        declined: 'Отклонено',
        not_paid: 'Не оплачено',
        for_rework: 'На доработке',
        processing: 'В обработке',
        paid: 'Выплачено',
        failed: 'Неудачно',
        payment_cpa_section: 'CPA',
        cpa_conversions: 'CPA конверсии',
      },
      players: {
        main: 'Игроки',
      },
      tools: {
        main: 'Инструменты',
        promoChecker: 'Проверка промо-кодов',
        landingLeagues: 'Лендинги по лигам',
        rotation_groups: 'Ротационные группы',
        service_domains: 'Сервисные домены',
        servers: 'Сервера',
        dns_hosting_accounts: 'Учетные записи хостинга DNS',
        domain_registrar_accounts: 'Учетные записи регистратора домена',
        dedicated_site_domains: 'Выделенные домены',
        'marketing-materials': 'Промо-материалы',
        'promo-materials': 'Промо-материалы',
        'promo-codes': 'Промо-коды',
        dms: 'DMS',
      },
      teams: {
        main: 'Команды',
      },
      'media-buying': {
        main: 'Медиабаинг',
        reports_section: 'Отчеты',
        summary_reports: 'Сводный отчет',
        blacklist: 'Черный список',
        unreported_funds: 'Без отчётов',
        payment_payout_section: 'Проведение платежей',
        payment_moderation_section: 'Модерация платежей',
        posts_section: 'Публикации',
        on_review: 'На рассмотрении',
        for_rework: 'На доработке',
        approved: 'Одобрено',
        declined: 'Отклонено',
        not_paid: 'Не оплачено',
        processing: 'В обработке',
        paid_out: 'Выплачено',
        failed: 'Неудачно',
      },
      settings: {
        main: 'Настройки',
        domains: 'Домены продукта',
        fees: 'Комиссии',
        affiliateProgram: 'Центр Управления',
        currencies: 'Валюты',
        paymentMethod: 'Платежные методы',
        systemSettings: 'Системные настройки',
        systemDomains: 'Системные домены',
        mediaBuying: 'Медиабаинг',
        predefinedPage: 'Предопределенные URL',
        offersCountryGroups: 'Группы стран для офферов',
      },
      crm: {
        main: 'CRM',
      },
      test: 'Тест',
    },
    language: {
      language: 'Язык',
      ru: 'Русский',
      en: 'Английский',
    },
    profile: {
      main: 'Мой профиль',
      logout: 'Выход',
      all_teams: 'Все команды',
    },
    confirm: {
      title: 'Выход',
      msg: 'Вы точно хотите выйти?',
      action: 'Выйти',
    },
  },
};
