// перевод на русский либы (https://github.com/code-farmer-i/vue-markdown-editor) заявлен, но не выполнен, пока что это файл будет фолбэком

export default {
  undo: {
    toolbar: 'Отменить',
  },
  redo: {
    toolbar: 'Повторить',
  },
  clear: {
    toolbar: 'Очистить',
  },
  h: {
    toolbar: 'Заголовки',
  },
  h1: {
    toolbar: 'Заголовок 1',
    placeholder: 'Заголовок 1',
  },
  h2: {
    toolbar: 'Заголовок 2',
    placeholder: 'Заголовок 2',
  },
  h3: {
    toolbar: 'Заголовок 3',
    placeholder: 'Заголовок 3',
  },
  h4: {
    toolbar: 'Заголовок 4',
    placeholder: 'Заголовок 4',
  },
  h5: {
    toolbar: 'Заголовок 5',
    placeholder: 'Заголовок 5',
  },
  h6: {
    toolbar: 'Заголовок 6',
    placeholder: 'Заголовок 6',
  },
  bold: {
    toolbar: 'Полужирный',
    placeholder: 'Полужирный',
  },
  italic: {
    toolbar: 'Курсив',
    placeholder: 'Курсив',
  },
  strikethrough: {
    toolbar: 'Зачеркнутый',
    placeholder: 'Зачеркнутый',
  },
  quote: {
    toolbar: 'Цитата',
    placeholder: 'Цитата',
  },
  ul: {
    toolbar: 'Маркированный список',
    placeholder: 'Маркированный список',
  },
  ol: {
    toolbar: 'Нумерованный список',
    placeholder: 'Нумерованный список',
  },
  table: {
    toolbar: 'Вставить таблицу',
  },
  hr: {
    toolbar: 'Линия',
  },
  link: {
    toolbar: 'Вставить ссылку',
    descPlaceholder: 'Ссылка',
  },
  image: {
    toolbar: 'Вставить изображение',
  },
  imageLink: {
    toolbar: 'Вставить ссылку',
  },
  uploadImage: {
    toolbar: 'Загрузить изображение',
  },
  code: {
    toolbar: 'Вставить код',
  },
  save: {
    toolbar: 'Сохранить',
  },
  preview: {
    enabled: 'Разрешить превью',
    disabled: 'Запретить превью',
  },
  toc: {
    title: 'Навигация',
    enabled: 'Разрешить содержание',
    disabled: 'Запретить содержание',
  },
  syncScroll: {
    enabled: 'Разрешить синхронизированную прокрутку',
    disabled: 'Запретить синхронизированную прокрутку',
  },
  fullscreen: {
    enabled: 'Полноэкранный режим (Нажмите ESC для выхода)',
    disabled: 'Выйти из полноэкранного режима',
  },
};
