<template>
  <div
    v-if="openPopup"
    class="add-offer-popup"
  >
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirm.type"
      :action-name="confirm.actionName"
      :close-action-name="confirm.closeActionName"
      :action="confirm.action"
      :data="confirm.data"
      :action-icon="confirm.actionIcon"
      @save="actionConfirm"
    >
      <div slot="title">
        {{ confirm.title }}
      </div>
      <div class="body__wrap">
        <i
          :class="confirm.type"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirm.msg"
        />
      </div>
    </ui-confirm>
    <div
      v-loading="isOfferProcessing"
      class="popup"
    >
      <div class="head">
        <span class="name">
          {{ $t(`affiliates.offer_popup.${action}`) }} {{ $t(`affiliates.offer_popup.offer`) }}</span>
        <ui-icon
          :size="19"
          class="close"
          lib="fa"
          substyle="fas"
          name="times"
          @click.native="confirmClosePopup"
        />
      </div>
      <div
        class="form"
      >
        <div class="add-offer-popup__left">
          <div class="title-wrap">
            <h2 class="title">
              {{ $t(`affiliates.offer_popup.general`) }}
            </h2>
          </div>
          <ui-input
            v-model="offerData.name"
            :placeholder="$t(`affiliates.offer_popup.offer_placeholder`)"
            :label="$t(`affiliates.offer_popup.offer_label`)"
            size="big"
            error-tooltip
            :error="errors && errors.name"
            class="offer-input offer-input-name"
          />
          <div class="brands-wrap">
            <div class="title-wrap">
              <h2 class="title">
                {{ $t(`affiliates.offer_popup.brands`) }}
              </h2>
            </div>
            <div class="card-filter__body">
              <div class="card-filter__radio-wrap">
                <el-radio-group
                  v-model="offerData.brand_visibility"
                  @change="checkBrands(offerData.brand_visibility)"
                >
                  <el-radio
                    label="all"
                    class="checkbox"
                  >
                    {{ $t(`affiliates.offer_popup.brands_all`) }}
                  </el-radio>
                  <el-radio
                    label="only_selected"
                    class="checkbox"
                  >
                    {{ $t(`affiliates.offer_popup.brands_only_selected`) }}
                  </el-radio>
                </el-radio-group>
              </div>
              <ui-tags-input
                v-model="offerData.visible_brands"
                :disabled="offerData.brand_visibility === 'all'"
                :typeahead-activation-threshold="0"
                :only-existing-tags="true"
                :existing-tags="brandsList"
                :typeahead="true"
                :placeholder="$t(`affiliates.offer_popup.add_brands`)"
                :class="errors && errors.visible_brands ? 'error-tags' : ''"
                class="card-filter__input-tag"
                typeahead-style="dropdown"
              />
              <p
                v-if="errors.visible_brands"
                class="input-error"
              >
                <span
                  v-for="(item, index) in errors.visible_brands"
                  :key="index"
                >
                  {{ item.message }}
                </span>
              </p>
            </div>
          </div>
          <div class="offer-rules">
            <div class="title-wrap">
              <h2 class="title">
                {{ $t(`affiliates.offer_popup.rules`) }}
              </h2>
              <span class="title-order" />
            </div>
            <ui-button
              :color="$theme.mainColor"
              class="offer-rules__btn"
              @click="addRule"
            >
              <i class="fas fa-plus plus-icon-custom" />{{ $t(`affiliates.offer_popup.add_rules`) }}
            </ui-button>
            <div class="offer-rules__tabs-wrap">
              <!-- draggebleeeeeeeee -->
              <draggable
                :list="offerData.payout_rules"
                :disabled="disabledDraggable"
                draggable=".rules-drag:not(.exclude)"
                @start="startDraggable"
                @end="endDraggable"
                @change="draggable"
              >
                <div
                  v-for="(item, index) in offerData.payout_rules"
                  :key="index"
                  :class="{
                    'exclude': index === defaultIndex,
                    'offer-rules__tab--active': indexRule === index && hideRightBlock,
                    'offer-rules__tab--error': errorsRules.length !== 0 && errorsRules[index] !== null,
                  }"
                  class="offer-rules__tab rules-drag"
                  @click="indexRule = index;"
                >
                  <span class="offer-rules__tab-text">
                    <ui-icon
                      size="16px"
                      lib="fa"
                      substyle="fas"
                      name="grip-lines"
                      :class="{'exclude': index === defaultIndex}"
                      color="#a6b2c4"
                    />
                    {{ getOfferName(index, 39) }}
                  </span>
                  <div class="offer-rules__icon-right-wr">
                    <ui-icon
                      size="12px"
                      lib="fa"
                      substyle="far"
                      name="clone"
                      :color="$theme.accentColor"
                      @click.stop.native="copyRule(index)"
                    />
                    <ui-icon
                      size="14px"
                      lib="fa"
                      class="remove-rule"
                      :class="{ disabled: index === defaultIndex}"
                      substyle="fas"
                      name="times"
                      :color="$theme.dangerColor"
                      @click.stop.native="confirmRule(index)"
                    />
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>

        <div
          v-loading="!hideRightBlock"
          class="add-offer-popup__right"
        >
          <div class="filters-and-rules-wrapper">
            <div ref="offer-container" class="title-wrap offer-container">
              <h2 class="title">
                {{ offerName }}
              </h2>
            </div>
            <!-- filters -->
            <div
              v-if="defaultIndex !== indexRule && hideRightBlock"
              class="filters-block"
            >
              <div class="title-wrap">
                <h2 class="title">
                  {{ $t(`affiliates.offer_popup.filters`) }}
                </h2>
              </div>
              <!-- card  country-->
              <div
                :class="{'card-filter--error': errorFilter(indexRule).indexOf('country_code') !== -1}"
                class="card-filter country-card country_code"
              >
                <div class="card-filter__header">
                  <h2 class="card-filter__title">
                    {{ $t(`affiliates.offer_popup.country`) }}
                  </h2>
                </div>
                <div class="card-filter__body">
                  <div class="card-filter__input-wr">
                    <ui-tags-input
                      :key="indexRule"
                      v-model="offerData.payout_rules[indexRule].filters.country_code"
                      :typeahead-activation-threshold="0"
                      :only-existing-tags="true"
                      :existing-tags="countryList"
                      :typeahead="true"
                      :placeholder="$t(`affiliates.offer_popup.add_country`)"
                      class="card-filter__input-tag card-filter__input-tag--z"
                      typeahead-style="dropdown"
                      @tags-updated="onTagsUpdated"
                    />
                  </div>
                  <div class="card-filter__tags-wrap">
                    <span
                      v-for="(item, index) in tags"
                      :key="index"
                      :class="{'card-filter__tag--active': tagActive === index}"
                      class="card-filter__tag"
                      @click="countryTag(item, index)"
                    >{{ item.name }}</span>
                  </div>
                </div>
                <p
                  v-if="errorFilter(indexRule).indexOf('country_code') !== -1 ? true : false"
                  class="input-error"
                >
                  {{ countryError }}
                </p>
              </div>
            </div>
            <RevShare
              :payout-rule.sync="offerData.payout_rules[indexRule]"
              :error-rules.sync="$_.get(errorsRules, indexRule, {})"
              :index-rule.sync="indexRule"
              :action="action"
            />
            <Cpa
              :payout-rule.sync="offerData.payout_rules[indexRule]"
              :error-rules.sync="$_.get(errorsRules, indexRule, {})"
              :currency.sync="currency"
              :index-rule.sync="indexRule"
              :customer-currencies.sync="customerCurrencies"
            />
          </div>
        </div>
      </div>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          @click.native="confirmClosePopup"
        >
          {{ $t(`affiliates.offer_popup.cancel`) }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :class="action"
          filled
          @click="save"
        >
          <i class="fas fa-check plus-icon-custom" />{{ action === 'Add' ? $t(`affiliates.offer_popup.apply`) : $t(`affiliates.offer_popup.apply_now`) }}
        </ui-button>
        <el-popover
          v-if="action === 'Edit'"
          v-model="effDatePopover"
          :transition="null"
          popper-class="eff-date"
          placement="top-end"
          width="250"
          trigger="click"
        >
          <ui-icon
            slot="reference"
            lib="fa"
            substyle="fas"
            color="#ffffff"
            name="calendar-alt"
          />
          <div v-loading="isOfferProcessing">
            <div class="field">
              <span class="label">{{ $t(`affiliates.offer_popup.eff_date_time`) }}</span>
              <el-date-picker
                ref="datetimepicker"
                v-model="date"
                :local-time="false"
                :picker-options="{
                  firstDayOfWeek: 1,
                }"
                :placeholder="$t(`tools.match_popup.placeholder_date_time`)"
                format="dd MMM yyyy HH:mm"
                type="datetime"
                class="dtpicker"
                @focus="isOpenPicker = true"
                @blur="isOpenPicker = false"
              />
              <span
                v-if="$_.isArray(errors.effective_timestamp)"
                class="input-error"
              >
                {{ `${errors.effective_timestamp[0].message}` }}
              </span>
            </div>
            <div class="field eff-date__timezone">
              <span class="label">{{ $t(`tools.match_popup.timezone`) }}</span>
              <el-select
                v-model="utc"
                :placeholder="$t(`tools.match_popup.placeholder_timezone`)"
                filterable
                class="select eff-date__select"
              >
                <el-option
                  v-for="(item) in timezones"
                  :key="item.name"
                  :label="item.name"
                  :value="item.zone"
                />
              </el-select>
            </div>
            <div class="field eff-date__btn-wr">
              <ui-button
                color="red"
                lib="fa"
                substyle="fas"
                icon="times"
                @click.native="effDatePopover = false"
              >
                {{ $t(`affiliates.offer_popup.cancel`) }}
              </ui-button>
              <ui-button
                :color="$theme.mainColor"
                filled
                lib="fa"
                substyle="fas"
                icon="check"
                @click="save('tz'); effDatePopover = false;"
              >
                {{ $t(`affiliates.offer_popup.schedule_changes`) }}
              </ui-button>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import { setTimeout } from 'timers';
import RevShare from './RevShare';
import Cpa from './Cpa/Cpa';
import aclMixin from '@/views/mixins/acl';

export default {
  name: 'AddOfferPopup',
  components: {
    draggable,
    RevShare,
    Cpa,
  },
  mixins: [aclMixin],
  props: {
    action: {
      type: String,
      default: 'Add',
    },
    currency: {
      type: String,
      default: '',
    },
    customerCurrencies: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Object,
      default: () => ({

      }),
    },
    affiliate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpenPicker: false,
      effDatePopover: false,
      date: null,
      utc: 'GMT',
      edited: false,
      unwatch: Function,
      disabledDraggable: false,
      isOfferProcessing: false,
      hideRightBlock: true,
      hideRightBlockNext: true,
      tagActive: -1,
      tags: [],
      countryList: {},
      brandsList: {},
      listFilter: [],
      openFilter: [],
      visible2: false,
      openPopup: false,
      indexRule: 0,
      offerData: {},
      dataDefault: {
        affiliate_id: '',
        name: '',
        global_cap_period_seconds: 0,
        global_cap_players_count: 0,
        global_hold_seconds_rev_share: null,
        global_hold_seconds_cpa: null,
        payout_rules: [
          {
            settings: {
              rev_share: {
                sport_levels: [
                  {
                    percent: 0,
                    ftd_count: 0,
                  },
                ],
                casino_levels: null,
                // casino_levels: [
                //   {
                //     percent: 0,
                //     ftd_count: 0,
                //   },
                // ],
              },
              cpa: null,
            },
            filters: {
              country_code: [],
            },
          },
        ],
        brand_visibility: 'all',
      },
      visible3: false,
      errors: {},
      errorsRules: [],
      hideBlockFilter: true,
      arrOldGDC: [],
      confirm: {
        title: '',
        msg: '',
        type: 'success',
        actionIcon: 'check',
        action: '',
        actionName: '',
        data: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      countries: 'misc/countries',
      timezones: 'misc/timezones',
      brands: 'misc/brands',
    }),
    defaultCurrency() {
      return this.customerCurrencies.find(el => el.is_default)?.code || 'USD';
    },
    countryError() {
      const error = this.errorsRules[this.indexRule].filters.country_code.filter(el => !!el);
      if (this.$_.get(error, [0, 'message'])) {
        return this.$_.get(error, [0, 'message']);
      }

      return this.$_.get(error, [0, 0, 'message'], '');
    },
    defaultIndex() {
      return this.offerData.payout_rules.length - 1;
    },
    offerName() {
      return this.getOfferName(this.indexRule, 75);
    },
    profile() {
      return this.$store.getters['auth/adminInfo'];
    },
    realDate() {
      return this.$moment(this.date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  watch: {
    /* вочер который блокирует скрытее поповера, если открыт датапикер */
    effDatePopover(v) {
      if (!v && this.isOpenPicker) {
        this.$nextTick(() => {
          this.effDatePopover = true;
        });
      }
    },
    indexRule(v) {
      this.timeoutMethod();
      this.getKeyFilter(v);
      this.$nextTick(() => {
        this.errorsRules = this.$_.cloneDeep(this.errorsRules);
      });
      this.tagActive = -1;
      this.selectTagCountry(v);
    },
    isOpen(v) {
      // open
      if (v === true) {
        document.querySelector('#app').classList.add('offer-ovf');
        this.edited = false;
        this.disabledDraggable = false;
        this.visible2 = false;

        this.offerData = this.$_.cloneDeep({
          ...this.data,
          payout_rules: this.data.payout_rules.map((elem, i) => {
            const defaultCpa = [{
              amount: 0,
              currency_code: this.defaultCurrency,
              cap_period_seconds: 0,
              cap_players_count: 0,
              hold_seconds: 0,
            }];

            const defaultRs = {
              sport_levels: [
                {
                  percent: 0,
                  ftd_count: 0,
                },
              ],
              casino_levels: null,
            };

            return {
              ...elem,
              settings: {
                ...elem.settings,
                cpa: (this.ableToSetCpa && (elem?.settings?.cpa || (!this.ableToSetRevShare && defaultCpa))) || null,
                rev_share: (this.ableToSetRevShare && (elem?.settings?.rev_share || (!this.ableToSetCpa && defaultRs))) || null,
                name: this.data.payout_rules.length - 1 === i ? 'Default' : '',
              },
            };
          }),
        });

        this.offerData.visible_brands = this.getVisibleBrands();
        this.arrOldGDC.push(this.offerData.global_daily_cap);
        this.indexRule = 0;

        this.getKeyFilter(this.indexRule);
        this.listCounrty();
        this.listBrands();

        if (this.action === 'Add') {
          // this.offerData.payout_rules[0].settings.name = `${this.$t('affiliates.offer_popup.rule')} 1`;
          // this.offerData.payout_rules[0].settings.cpa_payout_currency_code = 'usd';
          const countPayoutRule = this.offerData.payout_rules.length || 1;
          this.errorsRules = new Array(countPayoutRule).fill(null);
        }
        if (this.action === 'Edit') {
          this.selectTagCountry(this.indexRule);
        }
        setTimeout(() => {
          document.querySelector('.filters-and-rules-wrapper').addEventListener('scroll', this.hiddenPopover);
        });

        this.$nextTick(() => {
          const editedStart = this.$_.cloneDeep(this.offerData);
          this.unwatch = this.$watch('offerData', (nv) => {
            this.edited = !this.$_.isEqual(editedStart, nv);
          }, { deep: true });
        });

        this.utc = this.profile.timezone;
        this.date = this.$moment(+new Date() + 86400000).startOf('day');
      }
      // close
      if (v === false) {
        document.querySelector('#app').classList.remove('offer-ovf');
        document.querySelector('.filters-and-rules-wrapper').removeEventListener('scroll', this.hiddenPopover);
        this.disabledDraggable = false;
        this.errorsRules = [];
        this.arrOldGDC = [];
        this.errors = {};
        this.unwatch();
      }
      this.openPopup = v;
    },
  },
  async mounted() {
    const { data: { payload } } = await this.$api.getCountryTags();
    this.tags = payload;
    this.errors = {};
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && this.isOpen) {
        this.close('confirm');
      }
    });
    // filters-and-rules-wrapper
  },
  methods: {
    insertByIndex(arr, index, item) {
      const cloned = this.$_.cloneDeep(arr);
      cloned.splice(index, 0, this.$_.cloneDeep(item));
      return cloned;
    },
    countryPartName(index, rowLen) {
      const prefix = this.prefixName(index);
      const values = this.offerData.payout_rules[index].filters?.country_code || [];

      if (!values?.length) {
        return prefix;
      }

      if (rowLen === null) {
        return values.join(', ');
      }

      const fitValues = this.$_.truncate(values.join(', '), {
        length: rowLen - prefix.length,
        separator: ', ',
        omission: '',
      });

      const notFit = values.filter(val => !fitValues.split(', ').includes(val));
      const countNotFit = notFit.length ? ` [+${notFit.length}]` : '';
      return `${prefix} ${fitValues}${countNotFit}`;
    },
    isEmptyRs(lvl) {
      if (!lvl || this.$_.isEmpty(lvl)) return true;

      return lvl.every(el => !+el.percent);
    },
    prefixName(index) {
      const { rev_share, cpa } = this.offerData.payout_rules[index].settings;

      if ([rev_share?.sport_levels, rev_share?.casino_levels].every(this.isEmptyRs) && cpa && cpa.some(el => el.amount > 0)) {
        return '[CPA] ';
      }

      if (rev_share && cpa) {
        return '[Hyb] ';
      }

      if (rev_share) {
        return '[RS] ';
      }

      if (cpa) {
        return '[CPA] ';
      }

      return '';
    },
    getOfferName(index, rowLen) {
      if (index === this.defaultIndex) {
        return 'Default';
      }

      return this.countryPartName(index, rowLen);
    },

    checkBrands(value) {
      this.errors = {};
      if (value === 'all') {
        this.offerData.visible_brands = null;
      }
    },
    getVisibleBrands() {
      return _.map(this.offerData.visible_brands, 'site_id');
    },
    hiddenPopover() {
      this.visible2 = false;
      this.visible3 = false;
    },
    toScroll(elem) {
      setTimeout(() => {
        const el = this.$el.getElementsByClassName(elem)[0];
        const wrap = this.$el.getElementsByClassName('filters-and-rules-wrapper')[0];
        wrap.scrollTop = el.offsetTop - wrap.offsetTop;
      });
    },
    afterDeleteFirstRule(v) {
      this.timeoutMethod();
      this.getKeyFilter(v);
      this.$nextTick(() => {
        this.errorsRules = this.$_.cloneDeep(this.errorsRules);
      });
      this.tagActive = -1;
      this.selectTagCountry(v);
    },

    confirmRule(index) {
      if ((index === 0 && this.offerData.payout_rules.length === 1) || index === this.defaultIndex) return;
      this.confirm.title = this.$t('affiliates.offer_popup.confirm_rule.title');
      this.confirm.msg = this.$t('affiliates.offer_popup.confirm_rule.msg');
      this.confirm.type = 'warning';
      this.confirm.action = 'rule';
      this.confirm.actionName = this.$t('affiliates.offer_popup.confirm_rule.action');
      this.confirm.closeActionName = '';
      this.confirm.data = index;
      this.$refs.confirm.open();
    },
    confirmClosePopup() {
      if (this.edited) {
        this.confirm.title = this.$t('affiliates.offer_popup.confirm_close.title');
        this.confirm.msg = this.$t('affiliates.offer_popup.confirm_close.msg');
        this.confirm.type = 'warning';
        this.confirm.action = 'close';
        this.confirm.actionName = this.$t('affiliates.offer_popup.confirm_close.action');
        this.confirm.closeActionName = this.$t('affiliates.offer_popup.confirm_close.closeAction');
        this.confirm.data = '';
        this.$refs.confirm.open();
      } else {
        this.$refs.confirm.close();
        this.close();
      }
    },
    actionConfirm(action, v) {
      switch (action) {
      case 'rule':
        this.deleteRule(v);
        this.$refs.confirm.close();
        break;
      case 'filter':
        this.deleteFilter(v, this.indexRule);
        this.$refs.confirm.close();
        break;
      case 'close':
        this.$refs.confirm.close();
        this.close();
        break;
      default:
        break;
      }
    },
    timeoutMethod() {
      this.hideBlockFilter = false;
      setTimeout(() => {
        this.hideBlockFilter = true;
      }, 50);
    },
    errorFilter(index) {
      if (this.errors.hasOwnProperty('payout_rules')) {
        if (this.errors.payout_rules[index] === null || this.errors.payout_rules[index] === undefined) return [];
        if (this.errors.payout_rules[index].hasOwnProperty('filters')) {
          return this.$_.keys(this.errors.payout_rules[index].filters);
        }
      }
      return [];
    },
    makeErrArray() {
      const errLength = this.errorsRules.length;
      const ruleLength = this.offerData.payout_rules.length;
      if (errLength < ruleLength) {
        for (let i = 0; i < ruleLength - errLength; i += 1) {
          this.errorsRules.push(null);
        }
      }
    },
    startDraggable() {
      this.hideRightBlock = false;
      this.hideRightBlockNext = false;
    },
    draggable(added) {
      this.indexRule = added.moved.oldIndex;
    },
    endDraggable(e) {
      setTimeout(() => {
        this.hideRightBlock = true;
        this.indexRule = e.newIndex;
      }, 100);
    },
    selectTagCountry(/* index */) {
      setTimeout(() => {
        this.tags.forEach((el, i) => {
          if (this.$_.has(this.offerData.payout_rules[this.indexRule].filters, 'country_code') && this.$_.isEqual(
            this.$_.sortBy(el),
            this.$_.sortBy(this.offerData.payout_rules[this.indexRule].filters.country_code),
          )) {
            this.tagActive = i;
          }
        });
      }, 51);
    },
    onTagsUpdated() {
      if (this.tagActive === -1) return;
      if (this.$_.isEqual(
        this.$_.sortBy(this.tags[this.tagActive].values),
        this.$_.sortBy(this.offerData.payout_rules[this.indexRule].filters.country_code),
      )) {
        return;
      }

      this.tagActive = -1;
    },
    countryTag(item, index) {
      if (this.tagActive !== index) {
        this.tagActive = index;
        this.offerData.payout_rules[this.indexRule].filters.country_code = [];
        this.offerData.payout_rules[this.indexRule].filters.country_code = item.values;
      } else {
        this.tagActive = -1;
        this.offerData.payout_rules[this.indexRule].filters.country_code = [];
      }
    },
    listCounrty() {
      this.countryList = _.mapValues(_.keyBy(this.countries, 'code'), 'name');
    },
    listBrands() {
      this.brandsList = _.mapValues(_.keyBy(this.brands, 'id'), 'name');
    },
    deleteFilter(v, index) {
      delete this.offerData.payout_rules[index].filters[v];
      this.getKeyFilter(this.indexRule);
    },
    addFilter(objFilter) {
      this.$set(this.offerData.payout_rules[this.indexRule].filters, objFilter, []);
      this.getKeyFilter(this.indexRule);
      this.toScroll(objFilter);
    },
    getKeyFilter(index) {
      let listFilter = [];
      const defaultFiltersName = ['country_code'];
      listFilter = this.$_.keys(this.offerData.payout_rules[index].filters);
      listFilter.forEach((e) => {
        if (defaultFiltersName.indexOf(e) !== -1) {
          defaultFiltersName.splice(defaultFiltersName.indexOf(e), 1);
        }
      });
      this.openFilter = listFilter;
      this.listFilter = defaultFiltersName;
    },
    addRule() {
      const insertIndex = (this.defaultIndex) || 0;
      const defaultRule = {
        ...this.dataDefault.payout_rules[0],
        settings: {
          ...this.dataDefault.payout_rules[0].settings,
          cpa: this.ableToSetCpa && !this.ableToSetRevShare ? [{
            amount: 0,
            currency_code: this.defaultCurrency,
            cap_players_count: null,
            cap_period_seconds: null,
            hold_seconds: 0,
          }] : null,
        },
      };

      if (this.action === 'Add') {
        this.offerData.payout_rules = this.insertByIndex(this.offerData.payout_rules, insertIndex, defaultRule);
        this.errorsRules = this.insertByIndex(this.errorsRules, insertIndex, null);
      }
      if (this.action === 'Edit') {
        this.offerData.payout_rules = this.insertByIndex(this.offerData.payout_rules, insertIndex, defaultRule);
      }

      this.indexRule = this.defaultIndex - 1;
    },
    copyRule(index) {
      const insertIndex = (this.defaultIndex) || 0;

      const copiedRule = this.$_.cloneDeep({
        ...this.offerData.payout_rules[index],
        filters: {
          country_code: [],
        },
        settings: {
          ...this.offerData.payout_rules[index].settings,
          name: '',
        },
      });

      this.offerData.payout_rules = this.insertByIndex(this.offerData.payout_rules, insertIndex, copiedRule);

      if (this.action === 'Add') {
        this.errorsRules = this.insertByIndex(this.errorsRules, insertIndex, null);
      }

      this.indexRule = this.defaultIndex - 1;
    },
    deleteRule(index) {
      if (index === 0 && this.offerData.payout_rules.length > 1) {
        this.indexRule = index;
        this.openFilter.forEach((v) => {
          this.deleteFilter(v, index);
        });

        this.offerData.payout_rules.splice(index, 1);
        this.errorsRules.splice(index, 1);
        this.afterDeleteFirstRule(index);
      } else {
        this.indexRule = index - 1;
        this.openFilter.forEach((v) => {
          this.deleteFilter(v, index);
        });
        this.offerData.payout_rules.splice(index, 1);
        this.errorsRules.splice(index, 1);
      }
    },
    close(flag, action, isLastAdded = false) {
      if (flag === 'confirm') {
        this.confirmClosePopup();
      } else {
        this.$emit('close', action || this.action, isLastAdded);
      }
    },
    getCpaParams(data) {
      const {
        amount,
        currency_code,
        cap_players_count,
        cap_period_seconds,
        hold_seconds,
        ...restParams
      } = data;

      const mapValue = {
        _min: 'min_value',
        _max: 'max_value',
      };
      const conditions = Object.entries(restParams).reduce((acc, [key, value]) => {
        const group = key.match(/(_min$|_max$)/g);
        const name = key.replace(group, '');

        if (acc[name]) {
          acc[name] = {
            ...acc[name],
            [mapValue[group]]: value,
          };
        } else {
          acc[name] = {
            name,
            [mapValue[group]]: value,
          };
        }

        return acc;
      }, {});

      return {
        amount,
        currency_code,
        cap_players_count,
        cap_period_seconds,
        hold_seconds,
        conditions: Object.values(conditions).filter(({ min_value, max_value }) => !(min_value === null && max_value === null)),
      };
    },
    hasRs(lvl) {
      return lvl?.some(el => !!+el.percent);
    },
    save(act) {
      const q = {
        name: this.offerData.name,
        global_cap_players_count: this.offerData.global_cap_players_count,
        global_cap_period_seconds: this.offerData.global_cap_period_seconds,
        global_hold_seconds_rev_share: this.offerData.global_hold_seconds_rev_share,
        effective_timestamp: act === 'tz' ? this.$moment.tz(this.realDate, this.utc).utc().format('YYYY-MM-DD HH:mm:ss') : null,
        global_hold_seconds_cpa: this.offerData.global_hold_seconds_cpa,
        brand_visibility: this.offerData.brand_visibility,
        visible_brands: this.offerData.visible_brands,
        payout_rules: this.offerData.payout_rules.map(rule => ({
          ...rule,
          settings: {
            rev_share: this.ableToSetRevShare && rule.settings?.rev_share ? {
              sport_levels: rule.settings.rev_share.sport_levels,
              casino_levels: rule.settings.rev_share.casino_levels || rule.settings.rev_share.sport_levels,
            } : null,
            cpa: (this.ableToSetCpa && rule.settings.cpa?.map(this.getCpaParams)) || null,
          },
        })),
      };

      switch (this.action) {
      case 'Add':
        q.affiliate_id = this.affiliate;
        this.isOfferProcessing = true;
        this.errors = {};
        this.errorsRules = [];
        this.$api
          .addAffiliateOffer(q)
          .then(() => {
            this.isOfferProcessing = false;
            this.close(null, 'save', true);
          })
          .catch((error) => {
            this.disabledDraggable = true;
            this.isOfferProcessing = false;
            this.errors = this.$_.cloneDeep(error.data.errors);
            if (this.errors.hasOwnProperty('payout_rules')) {
              this.errorsRules = error.data.errors.payout_rules?.map((rule, indexRule) => {
                if (!rule) return rule;

                return {
                  ...rule,
                  settings: {
                    ...rule.settings,
                    cpa: rule.settings?.cpa?.map((cpa, indexCpa) => ({
                      ...cpa,
                      conditions: cpa?.conditions?.reduce((acc, condition, indexCondition) => {
                        if (condition?.code === 1000) {
                          return [condition];
                        }
                        if (condition) {
                          const key = q.payout_rules[indexRule].settings.cpa[indexCpa].conditions[indexCondition].name;
                          acc[key] = condition;
                        }

                        return acc;
                      }, {}) || null,
                    })) || null,
                  },
                };
              });
              this.makeErrArray();
            }
          });
        break;
      case 'Edit':
        this.isOfferProcessing = true;
        this.errors = {};
        this.errorsRules = [];
        this.$api
          .putAffiliateOffer(this.data.id, q)
          .then(() => {
            this.isOfferProcessing = false;
            this.close();
          })
          .catch((error) => {
            this.disabledDraggable = true;
            this.isOfferProcessing = false;
            this.errors = this.$_.cloneDeep(error.data.errors);

            if (this.errors.hasOwnProperty('payout_rules')) {
              this.errorsRules = error.data.errors.payout_rules?.map((rule, indexRule) => {
                if (!rule) return rule;

                return {
                  ...rule,
                  settings: {
                    ...rule.settings,
                    cpa: rule.settings?.cpa?.map((cpa, indexCpa) => ({
                      ...cpa,
                      conditions: cpa?.conditions?.reduce((acc, condition, indexCondition) => {
                        if (condition?.code === 1000) {
                          return [condition];
                        }
                        if (condition) {
                          const key = q.payout_rules[indexRule].settings.cpa[indexCpa].conditions[indexCondition].name;
                          acc[key] = condition;
                        }

                        return acc;
                      }, {}) || null,
                    })) || null,
                  },
                };
              });
              this.makeErrArray();
            }
          });
        break;
      default:
        break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-container {
  overflow: hidden;
  white-space: nowrap;
}

.remove-rule.disabled, .rules-drag .exclude {
  opacity: .5;
}

.remove-rule.disabled {
  cursor: not-allowed;
}

.add-offer-popup{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .blur{
    filter: blur(10px);
  }
  .popup {
    width: 1140px;
    height: 80vh;
    min-height: 320px;
    position: relative;
    z-index: 902;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .filters-and-rules-wrapper {
      overflow-y: auto;
      scrollbar-gutter: stable;
      flex: 1;
      padding-right: 4px;
    }
    .title-wrap{
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.rev-share {
        justify-content: flex-start;
        align-items: flex-end;
      }
    }
    .title {
      font-size: 20px;
      font-weight: 500;
      color: #303634;
    }
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      padding: 16px 24px;
      .name {
        font-size: 20px;
        font-weight: 500;
      }
      .close {
        color: #303634;
        padding: 5px;
        font-size: 32px;
        cursor: pointer;
      }
    }
    .form {
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      align-items: stretch;
      height: calc(100% - 124px);
      .offer-input-name{
        margin-bottom: 16px;
        min-width: 320px;
        width: 100% !important;
      }
      .sub-title{
        font-size: 16px;
        font-weight: 500;
        color: #303634;
      }
      .add-offer-popup__left{
        overflow-y: auto;
        padding-right: 24px;
        padding-top: 16px;
        padding-bottom: 16px;
        border-right: 1px solid #eaeaea;
        .wrap-global{
          display: flex;
          align-items: flex-end;
          .offer-input-daily{
            max-width: 144px;
          }
          .offer-input-hold{
            margin-left: 16px;
            max-width: 74px;
          }
          .select{
            margin-left: 8px;
            max-width: 88px;
          }
        }
        .brands-wrap{
          margin-top: 16px;
          .title-wrap{
            margin-bottom: 8px;
          }

          .tags-input-root{
            width: 320px;
            &.disabled {
              opacity: .5;
              cursor: default;
              pointer-events: none;
            }
          }
        }
      }
      .offer-rules{
        padding-top: 36px;
        //height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .title-order{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #696969;
          i{
            margin-left: 6px;
          }
        }
        &__btn{
          width: 100%;
          font-size: 12px;
          border-radius: 4px;
          height: 34px;
        }
        &__tabs-wrap{
          margin-top: 8px;
          overflow-y: auto;
          flex: 1;
          width: 100%;
        }
        &__tab{
          padding-left: 8px;
          padding-right: 8px;
          margin-bottom: 8px;
          height: 34px;
          border-radius: 4px;
          background-color: #f4f7fa;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          border: 1px solid #f4f7fa;
          transition: all .3s ease 0s;
          &:hover{
            transition: all .3s ease 0s;
          }
          &--active{
            border: 1px solid #20815e;
          }
          &--error{
            background-color: rgba(255, 72, 6, 0.092);
          }
        }
        &__tab-text{
          display: flex;
          align-items: center;
          font-size: 12px;
          white-space: nowrap;
          i{
            margin-right: 8px;
          }
        }
        &__icon-right-wr {
          display: flex;
          align-items: center;
          .fa-clone{
            margin-right: 16px;
          }
        }

      }
      .add-offer-popup__right{
        width: 710px;
        padding-top: 16px;
        padding-left: 24px;
        display: flex;
        flex-direction: column;
        transition: all .6s ease-out;
      }
    }
    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      .ui-button + .ui-button{
        margin-left: 8px;
      }
      .ui-button.green{
        border-radius: 5px 0 0 5px;
        &.Add{
          border-radius: 5px;
        }
      }

      .plus-icon{
        font-weight: bold !important;
      }
      >span {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #20815e;
        width: 26px;
        border-radius: 0 5px 5px 0;
        border-left: 1px solid  #40a27f;
        cursor: pointer;
        i{
          padding-top: 6px;padding-bottom: 6px;
        }
      }
    }
  }
  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
</style>
