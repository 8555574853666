<template>
  <div class="players-list">
    <div class="toolbar">
      <link-affiliate
        v-show="isPopupOpen"
        :is-open="isPopupOpen"
        :data="dataAffiliate"
        :site-id="siteId"
        :action="1"
        :leagues="1"
        :league-id="1"
        :match="1"
        @close="onClosePopup"
      />
      <ui-confirm
        ref="confirm"
        :width="480"
        :type="confirm.type"
        :action-name="$t('players.playersList.change')"
        :action="$t('players.playersList.change')"
        :action-disabled="!virtualActionDisabled"
        action-icon="check"
        @save="changeStatus"
      >
        <div slot="title">
          {{ confirm.title }}
        </div>
        <div class="body__wrap">
          <i
            :class="confirm.type"
            class="fas fa-exclamation-triangle"
          />
          <div
            class="body__msg"
            v-html="confirm.msg"
          />
        </div>
        <div
          class="body__msg body__msg--padding"
          v-html="confirm.info"
        />
        <ui-input
          v-model="captcha"
          :placeholder="$t('players.playersList.confirm.yes')"
          width="auto"
        />
      </ui-confirm>
      <div class="wrapper">
        <div class="section">
          <span class="title">{{ $t('players.playersList.players') }}</span>
          <ui-input
            v-model.trim="search"
            class="player-search"
            :width="180"
            :is-search="isSearch"
            @search="getPlayer()"
          />
          <el-select
            ref="brandsSelect"
            v-model="currentBrand"
            filterable
            class="select"
            @change="handleRefresh"
          >
            <template slot="prefix">
              <img
                v-if="getBrandImgUrl()"
                :src="getBrandImgUrl()"
                class="prefix"
              >
            </template>
            <el-option
              v-for="item in brands"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <img
                v-if="item.favicon_base64"
                :src="item.favicon_base64"
              >
              <span>{{ item.name }}</span>
            </el-option>
          </el-select>
          <div class="filters">
            <player-filter
              ref="filter"
              v-model="filters"
              class="btn ui-filter-new"
              @input="getPlayerFilter"
            />
            <span
              v-if="filtersObj"
              class="reset_link"
              @click="resetFilters"
            >
              {{ $t('players.playersList.res_filter') }}
            </span>
          </div>
        </div>
        <div class="section">
          <div
            v-if="!isEmptyFilter && isCompleteRequest"
            class="pagination"
          >
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="pageChange"
            />
          </div>
          <ui-button
            v-if="!($_.has(filters, 'player_country_code') || $_.has(filters, 'affiliate_id'))"
            disabled
            class="btn"
            lib="fa"
            substyle="fas"
            icon="long-arrow-down"
          />
          <export-data
            v-else
            :data="configExport"
            @exportData="exportData"
          />
          <ui-button
            icon="sync-alt"
            lib="fa"
            substyle="fas"
            color="green"
            class="btn"
            @click="getPlayer()"
          >
            {{ $t('players.playersList.refresh') }}
          </ui-button>
        </div>
      </div>
    </div>
    <ui-table
      ref="dataTable"
      v-loading="isDataLoading"
      :lazy-loading="!isEmptyFilter"
      :fields="fieldsFiltered"
      :data="data"
      :rows-count="limit"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      :empty-text="isEmptyFilter || !isCompleteRequest ? $t('players.playersList.select_filter') : $t('ui.table.no_data')"
      class="table"
      :class="{'no-data': !isEmptyFilter && isCompleteRequest}"
      i18n-path="players"
      element-loading-custom-class="data-loader-spinner"
      @sort="handleSort"
      @headerDragend="headerDragend"
      @update="resizeHeaderDragend()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LinkAffiliate from '@/components/Popups/LinkAffiliate.vue';
import detectPermissions from '@/service/detectPermissions';
import updateUrl from '@/service/updateUrl';
import ExportData from '@/components/ExportData';
import PlayerFilter from './PlayerFilter.vue';
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';

import tableFieldsEx from './tableHelpers/fieldTable';
import dragendColumnMixin from '@/components/UIElements/UiTableHelper/dragendColumnMixin';


const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = 'player/list/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'PlayersList',
  components: {
    LinkAffiliate,
    ExportData,
    PlayerFilter,
  },
  mixins: [pageSizeMixin, dragendColumnMixin],

  data() {
    return {
      currentBrand: '',
      tableFieldsEx,
      fieldsLocalStorageKey: 'playerList/width-column',
      resizeHeaderDragendFlag: true,

      isSearch: true,
      viewName,
      dataAffiliate: {},
      isPopupOpen: false,
      data: [],
      pickerDateRange: [],
      exportUrls: {},
      configExport: [
        {
          command: 'csv',
          label: 'CSV',
        },
        {
          command: 'xlsx',
          label: 'XLSX',
        },
      ],
      shortcutsDates: [
        {
          text: this.$t('reports.date_picker.last_30_day'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(30, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.this_month'),
          range: [this.$moment().startOf('month'), this.$moment().endOf('day')],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.prev_month'),
          range: [
            this.$moment()
              .subtract(1, 'months')
              .startOf('month'),
            this.$moment()
              .subtract(1, 'months')
              .endOf('month'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
        {
          text: this.$t('reports.date_picker.last_90_day'),
          range: [
            this.$moment()
              .startOf('day')
              .subtract(90, 'd'),
            this.$moment().endOf('day'),
          ],
          onClick(picker) {
            picker.$emit('pick', this.range);
          },
        },
      ],

      search: '',
      count: 0,
      page: 1,
      limit: pageSize,
      isDataLoading: false,
      sortProp: 'created_at',
      sortOrder: 'desc',
      filters: {},
      onlyContacts: false,
      onlyDeposits: false,
      all: true,
      range: [],
      filtersQuery: {},
      virtualData: {},
      isCompleteRequest: false,
      confirm: {
        title: '',
        msg: '',
        type: 'success',
        info: '',
      },
      getPlayers: Function,
      captcha: '',
      fields: [
        {
          name: 'site_player_id',
          sortable: 'custom',
          link: this.getPlayerLink,
          minWidth: '70',
          width: '160',
        },
        {
          name: 'affiliate_email',
          minWidth: '200',
          width: '200',
          className: 'affiliate_email',
          link: this.getAffiliateLink,
          action: this.checkSensitiveAction,
          actionName: (row) => {
            let link = row.affiliate_id ? 'unlink' : 'link';
            if (row.is_virtual || (!this.$store.getters['auth/adminAcl'].is_superuser && !this.editAssignAffiliate) || row.affiliate_id
                === undefined || row.is_virtual === undefined) {
              link = '';
            }
            return link;
          },
          actionLib: 'fa',
          controlType: 'icon',
          controlColorIco: row => (row.affiliate_id ? this.$theme.dangerColor : '#20815e'),
        },
        {
          name: 'player_country_name',
          minWidth: '170',
        },
        {
          name: 'phone_number',
          minWidth: '170',
          width: '170',
        },
        {
          name: 'email',
          minWidth: '100',
          flexible: true,
        },
        {
          name: 'first_name',
          minWidth: '100',
          flexible: true,
          computedValue(row) {
            return `${row.first_name || ''} ${row.last_name || ''}`;
          },
        },
        {
          name: 'is_virtual',
          width: '100',
          minWidth: '100',
          resizable: false,
          class: 'actions',
          action: row => (!row.is_virtual && this.editVirtual ? this.clickEditHandler(row) : false),
          actionName: row => (!row.is_virtual && this.editVirtual ? 'pen' : ''),
          actionLib: 'fa',
          controlType: 'icon',
          controlColorIco() {
            return '#20815e';
          },
          controlColor(row) {
            return row.is_virtual ? '#20815e' : '#303634';
          },
          computedValue: row => (row.is_virtual ? this.$t('players.playersList.yes') : `${this.$t('players.playersList.no')}`),
        },
        {
          name: 'created_at',
          minWidth: '120',
          width: '120',
          align: 'left',
          headerAlign: 'left',
          sortable: 'custom',
          format: 'date-time',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      brands: 'misc/brands',
    }),
    playersAvailableKeys() {
      return this.$store.getters['auth/currentAcl'].players_available_properties || [];
    },
    siteId: {
      get() {
        return this.currentBrand ? this.currentBrand : '';
      },
      set(newVal) {
        this.currentBrand = newVal;
      },
    },
    accentColor() {
      return this.$theme.accentColor || '#20815e';
    },
    filtersObj() {
      return Object.keys(this.filters).length;
    },
    virtualActionDisabled() {
      return this.$t('players.playersList.confirm.yes').toLowerCase() === this.captcha.toLowerCase();
    },
    editVirtual() {
      return this.calcPermissions('players_virtual_edit_settings');
    },
    editAssignAffiliate() {
      return this.calcPermissions('players_assign_affiliate');
    },
    /* ??? */
    isAffiliatesFilter() {
      if (!this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl']) {
        return this.$store.getters['auth/currentAcl'].affiliates_view === 'allow' && this.isPinnedAffiliatesFilter;
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },

    isPinnedAffiliatesFilter() {
      if (!this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl']) {
        return this.$store.getters['auth/currentAcl'].players_available_properties.indexOf('affiliate_email') !== -1
            && this.$store.getters['auth/currentAcl'].players_available_properties.indexOf('affiliate_id') !== -1;
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },
    isProfitCategoryFilter() {
      if (!this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl']) {
        return this.$store.getters['auth/currentAcl'].players_available_properties.indexOf('profit_category') !== -1;
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },
    isPlayerCountryFilter() {
      if (!this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl']) {
        return this.$store.getters['auth/currentAcl'].players_available_properties.indexOf('player_country_name') !== -1
            && this.$store.getters['auth/currentAcl'].players_available_properties.indexOf('player_country_code') !== -1;
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },
    isLastActivityFilter() {
      if (!this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl']) {
        return this.$store.getters['auth/currentAcl'].players_available_properties.indexOf('last_active_at') !== -1;
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },

    fieldsFiltered() {
      if (this.$store.getters['auth/currentAcl'] && this.$store.getters['auth/currentAcl'].players_available_properties) {
        return this.$_.filter(this.fields, item => this.$store.getters['auth/currentAcl'].players_available_properties.includes(item.name)
            || item.name === 'site_player_id' || (this.$store.getters['auth/currentAcl'].players_available_properties.includes('last_name') ? item.name
                === 'first_name' : ''));
      } if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return this.fields;
      }
      return [];
    },
    isEmptyFilter() {
      return this.$_.isEmpty(this.filters) && (this.search === '' || this.search === null);
    },
  },

  watch: {
    filters: {
      handler(nv) {
        this.filtersQuery = this.$_.clone(nv);
        this.$ls.set('players/list/filters', JSON.stringify(nv));
        if (nv.hasOwnProperty('deposits')) {
          if (nv.deposits === 'without') {
            this.filtersQuery.max_deposits_count = 0;
            delete this.filtersQuery.deposits;
          }
          if (nv.deposits === 'first') {
            this.filtersQuery.max_deposits_count = 1;
            this.filtersQuery.min_deposits_count = 1;
            delete this.filtersQuery.deposits;
          }
          if (nv.deposits === 'more') {
            this.filtersQuery.min_deposits_count = 2;
            delete this.filtersQuery.deposits;
          }
          if (nv.deposits === 'onlydep') {
            this.filtersQuery.min_deposits_count = 1;
            delete this.filtersQuery.deposits;
          }
        }

        if (nv.hasOwnProperty('contacts')) {
          if (nv.contacts === 'all') {
            delete this.filtersQuery.contacts;
          }
          if (nv.contacts === 'only') {
            this.filtersQuery.only_with_contacts = true;
            delete this.filtersQuery.contacts;
          }
        }

        if (nv.hasOwnProperty('activity')) {
          const lastDay = nv.activity[0];
          const dir = nv.activity[1];

          if (dir === 'from') {
            this.filtersQuery.last_active_at_from = this.$moment().subtract(lastDay, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
          }
          if (dir === 'to') {
            this.filtersQuery.last_active_at_to = this.$moment().subtract(lastDay, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
          }
          delete this.filtersQuery.activity;
        }
      },
      deep: true,
    },
    pickerDateRange(v) {
      sessionStorage.setItem('players/list/daterange', JSON.stringify(v));
    },
    sortProp(v) {
      this.$ls.set('players/list/sortProp', JSON.stringify(v));
    },
    sortOrder(v) {
      this.$ls.set('players/list/sortOrder', JSON.stringify(v));
    },
    currentBrand(v) {
      this.$ls.set('players/list/siteId', JSON.stringify(v));
    },
  },

  created() {
    /* если в url есть search отключаем его; избегая debounce внутри поиска и задваивания запроса */
    if (this.$route.query.search) this.isSearch = false;

    if (detectPermissions.checkRequest('players_view')) {
      const _urlData = updateUrl.getParseParams(this.$route.hash);
      if (!this.$_.isEmpty(_urlData)) {
        this.page = Number(this.$_.get(_urlData, ['page'])) || 1;
        this.limit = Number(this.$_.get(_urlData, ['limit'])) || pageSize;

        const sortKey = this.$_.get(_urlData, ['sort_column']) || 'created_at';
        this.sortProp = this.getSortBy(sortKey);
        this.sortOrder = this.$_.get(_urlData, ['sort_dir']) || 'desc';
        this.search = this.$_.get(_urlData, ['search']) || null;
        this.currentBrand = this.$route.query.site_id ? this.$route.query.site_id : JSON.parse(this.$ls.get('players/list/siteId')) || this.brands[0].id;

        /*
        * @Нужно исправить после декодирование url пропадают '++'
        * */
        if (this.$_.has(_urlData, 'filtersQuery')) {
          switch (this.$_.get(_urlData.filtersQuery, ['min_profit_category'])) {
          case 'A_two':
            _urlData.filtersQuery.min_profit_category = 'A++';
            break;
          case 'A_one':
            _urlData.filtersQuery.min_profit_category = 'A+';
            break;
          default:
            break;
          }
        }
        if (this.$_.get(_urlData, ['filtersQuery'])) {
          const { affiliate_id = [], ...restQuery } = this.$_.get(_urlData, ['filtersQuery']);
          this.filters = {
            affiliate_id: Object.values(affiliate_id),
            ...restQuery,
          };
        } else {
          this.filters = JSON.parse(this.$ls.get('players/list/filters')) || {};
        }
        if (this.$_.has(this.filters, ['belongs_to_affiliate'])) {
          this.filters.belongs_to_affiliate = this.filters.belongs_to_affiliate === 'true';
        }
      } else {
        this.filters = JSON.parse(this.$ls.get('players/list/filters')) || {};
        this.limit = +this.$ls.get('player/list/limit') || pageSize;
        const sortKey = JSON.parse(this.$ls.get('players/list/sortProp')) || 'created_at';
        this.sortProp = this.getSortBy(sortKey);
        this.sortOrder = JSON.parse(this.$ls.get('players/list/sortOrder')) || 'desc';
        this.currentBrand = JSON.parse(this.$ls.get('players/list/siteId')) || this.brands[0].id;
      }
      this.pickerDateRange = JSON.parse(sessionStorage.getItem('players/list/daterange')) || this.shortcutsDates[0].range;
    }
    detectPermissions.checkRoute('players_view');
    this.$eventBus.$on('clickEditAffiliateHandler', this.clickEditAffiliateHandler);
  },

  beforeDestroy() {
    this.$eventBus.$off('clickEditAffiliateHandler', this.clickEditAffiliateHandler);
  },

  mounted() {
    if (this.filters && ((this.filters.affiliate_id && !this.isAffiliatesFilter) || (this.filters.player_country_code && !this.isPlayerCountryFilter)
        || (this.filters.belongs_to_affiliate && !this.isPinnedAffiliatesFilter) || (this.filters.activity && !this.isLastActivityFilter) || (this.filters.min_profit_category && !this.isProfitCategoryFilter))) {
      this.resetFilters();
    }
    this.getPlayers = this.$_.debounce(this.getPlayer, 450);
    this.$nextTick(() => {
      this.getPlayers('created');
    });

    this.$eventBus.$on('changeLocaleGlobal', this.changeLocaleGlobal);
  },

  destroyed() {
    this.$eventBus.$off('changeLocaleGlobal', this.changeLocaleGlobal);
  },

  methods: {
    getSortBy(key) {
      if (this.$store.getters['auth/adminAcl'].is_superuser || this.playersAvailableKeys.includes(key)) {
        return key;
      }

      return 'site_player_id';
    },
    changeLocaleGlobal() {
      this.getPlayer('silent');
    },

    checkSensitiveAction(row) {
      this.$eventBus.$emit('checkSensitiveAction', 'clickEditAffiliateHandler', row);
    },

    calcPermissions(item) {
      return this.$store.getters['auth/currentAcl'][item] === 'allow' || (this.$store.getters['auth/adminAcl'].is_superuser && !localStorage.getItem('active-team'));
    },

    openConfirm() {
      this.$refs.confirm.open();
    },

    handleRefresh() {
      this.page = 1;
      this.getPlayers('changeBrand');
    },

    getBrandImgUrl() {
      const brandFavicon = this.currentBrand.length !== 0 ? this.brands.filter(brand => brand.id === this.currentBrand)[0]?.favicon_base64 : '';
      return brandFavicon !== undefined ? brandFavicon : false;
    },

    clickEditAffiliateHandler(row) {
      this.isPopupOpen = true;
      this.dataAffiliate = row;
    },

    onClosePopup(v) {
      this.isPopupOpen = false;
      if (v === 'reload') {
        this.getPlayers('onClosePopup');
      }
    },

    clickEditHandler(value) {
      this.virtualData = value;
      this.confirm.title = `${this.$t('players.playersList.confirm.title')} ${value.site_player_id} ${value.is_virtual ? `${this.$t('players.playersList.confirm.to_real')}` : `${this.$t('players.playersList.confirm.to_virtuals')}`}`;
      this.confirm.msg = `${this.$t('players.playersList.confirm.msg')}`;
      this.confirm.info = `${this.$t('players.playersList.confirm.msg_1', { player_id: `<span>${value.site_player_id}</span>`, yes: `<span>${this.$t('players.playersList.confirm.yes')}</span>` })}`;
      this.confirm.type = 'success';
      this.captcha = '';
      this.openConfirm();
    },
    changeStatus() {
      this.isDataLoading = true;
      this.$api
        .markAsVirtual(this.virtualData.id)
        .then(() => {
          this.$refs.confirm.close();
          this.getPlayers('changeStatus');
        })
        .catch(() => {
          this.isDataLoading = false;
        });
    },
    dataQuery() {
      const q = {
        limit: this.limit,
        offset: (this.limit * this.page) - this.limit,
        sort_column: this.getSortBy(this.sortProp),
        sort_dir: this.sortOrder,
        search: this.search,
        only_with_contacts: this.onlyContacts,
        min_deposits_count: this.onlyDeposits ? 1 : 0,
        site_id: this.siteId,
        ...this.filtersQuery,
      };
      return q;
    },
    selectDateRange() {
      if (this.pickerDateRange === null) {
        this.pickerDateRange = this.shortcutsDates[0].range;
        this.$nextTick(() => {
          this.pickerDateRange = this.shortcutsDates[0].range;
        });
      }
      this.range[0] = this.$moment(this.pickerDateRange[0]).format('YYYY-MM-DD HH:mm:ss');
      this.range[1] = this.$moment(this.pickerDateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      this.getPlayers('selectDateRange');
    },
    getPlayerFilter() {
      this.$nextTick(() => {
        this.getPlayers('getPlayerFilter');
      });
    },

    async getPlayer(action) {
      if (!this.isEmptyFilter) {
        if (action !== 'silent') {
          this.isDataLoading = true;
        }
        return this.$api.getPlayers(this.dataQuery()).then((response) => {
          this.count = response.data.misc.count;
          this.exportUrls = response.data.misc.export_urls;
          this.data = response.data.payload;
          this._completedUrl();
        }).finally(() => {
          if (action !== 'silent') {
            this.isDataLoading = false;
          }
          this.isCompleteRequest = true;
          this.isSearch = true;
        });
      }
      this.data = [];
      this.isCompleteRequest = false;
      this._completedUrl();
      return false;
    },

    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.getPlayers('handleSort');
    },
    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.getPlayers('pageChange');
      }
    },
    getAffiliateLink(row) {
      if (row.affiliate_referred_by_info) {
        return row.affiliate_id && row.affiliate_referred_by_info.permissions.view ? `/affiliates/${row.affiliate_id}/info` : false;
      }
      return row.affiliate_id ? `/affiliates/${row.affiliate_id}/info` : false;
    },
    getPlayerLink(row) {
      const urlObj = {
        back: window.location.pathname + window.location.search,
      };
      return `/players/${row.id}/info?param_playerlist=${this.$qs.stringify(urlObj)}`;
    },
    resetFilters() {
      this.page = 1;
      this.$refs.filter.reset();
    },
    exportData(format) {
      window.open(this.exportUrls[format]);
    },
    /*
    * Собираем URL
    * */
    _completedUrl() {
      const _dataFilters = {
        limit: this.limit,
        page: this.page,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        search: this.search,
        site_id: this.siteId,
        filtersQuery: {
          ...this.filters,
        },
      };
      /*
      * @ нужно исправить..
      *  после декодирования url пропадают '++'
      * */
      switch (this.$_.get(_dataFilters.filtersQuery, ['min_profit_category'])) {
      case 'A++':
        _dataFilters.filtersQuery.min_profit_category = 'A_two';
        break;
      case 'A+':
        _dataFilters.filtersQuery.min_profit_category = 'A_one';
        break;
      default:
        break;
      }

      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
  .players-list {
    .toolbar {
      .wrapper {
        width: 90%;
        min-width: 1140px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
  }
</style>
