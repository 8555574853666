export default {
  dashboards: {
    autorefresh: {
      title: 'Автообновление',
      300: '5 Мин.',
      600: '10 Мин.',
      900: '15 Мин.',
      1800: '30 Мин.',
      3600: '1 Час',
    },

    compared: {
      this_vs_last: 'Текущий / Предыдущий',
      this_vs_last_td: 'Текущий / Предыдущий (КД)',
      last_vs_one_before: 'Предыдущий / Предшествующий предыдущему',
    },

    peroid: {
      daily: 'За день',
      weekly: 'За неделю',
      monthly: 'За месяц',
      yearly: 'За год',
      rolling_30_days: 'За последние 30 дней',
    },

    peroid_table: {
      last_24_hours: 'За день',
      last_week: 'За неделю',
      last_month: 'За месяц',
      last_year: 'За год',
    },

    peroid_stat: {
      this_day: 'За день',
      this_week: 'За неделю',
      this_month: 'За месяц',
      this_year: 'За год',
      rolling_30_days: 'За последние 30 дней',
    },

    popup_column: {
      metrics: 'Метрика',
      select_metric: 'Выберите метрику',
      configure_metric: 'Настройка панели',
      preview_panel: 'Панель предварительного просмотра',
      view: 'Вид',
      period: 'Период',
      compare: 'Сравнение',
      type: 'Тип',
      card: 'Стат.',
      chart: 'График',
      table: 'Таблица',
    },

    type_chart: {
      line: 'Линейный график',
      bar: 'Гистограмма',
      pie: 'Круговая диаграмма',
    },

    other: {
      edit_alert: 'Перетащите метрики, чтобы изменить их порядок.',
      set_as_default: 'Установить по умолчанию',
      dont_set_as_default: 'Не устанавливать по умолчанию',
      add_metric: 'Добавить панель',
      edit_metric: 'Редактировать панель',
      new_dashboard: 'Новый Дашборд',
      new_section: 'Добавить секцию',
      default_section: 'Новая секция',
      edit_dashboard: 'Редактировать Дашборд',
      refresh: 'Обновить',
      edit: 'Редактировать',
      delete: 'Удалить',
      cancel: 'Отменить',
      save_changes: 'Сохранить',
      save_dashboard: 'Сохранить Дашборд',
      panel_name: 'Имя панели',
      panel_name_placeholder: 'Введите имя панели',
      others: 'Остальные',
      forecast: 'Прогноз',
      show_forecast: 'Показать прогноз',
      hide_forecast: 'Скрыть прогноз',
      enable_comparison: 'Сравнение',
      clone: 'Клонировать',
      clone_success: 'Клонирован:',
      clone_danger: 'Ошибка клонирования',
    },

    confirm: {
      title: 'Внимание',
      text: 'Вы ввели новую информацию на этой странице. Если вы покинете страницу, не сохранив изменения, они будут потеряны',
      btn_сonfirm: 'Перейти',

      delete_title: 'Удалить Дашборд?',
      delete_text: 'Вы уверены, что хотите удалить Дашборд?',
      delete_btn_сonfirm: 'Удалить',

      cancel_title: 'Внимание',
      cancel_text: 'Вы внесли некоторые изменения на странице. Хотите закрыть ее без их сохранения?',
      cancel_btn_сonfirm: 'Да',
      cancel_btn_cancel: 'Нет',

      clone_title: 'Клонирование дашборда',
      clone_text: 'Клонировать дашборд с именем:',
      clone_copy: 'Копия',
    },

    metric_table: {
      fields: {
        dimension: {
          title: 'Параметр',
        },
        current: {
          title: 'Текущий',
        },
        previous: {
          title: 'Предыдущий',
        },
        difference_percent: {
          title: 'Изменение (%)',
        },
      },
    },

    grouping: {
      grouping: 'Группировка',
      group_by: 'Группировать по',
      and_show: 'и показать',
      result: 'значений',
      other_value: 'Показать остальные',
      affiliate_email: 'Партнеры',
      sub_affiliate_email: 'Суб-партнёры',
      player_country_name: 'Страна игрока',
      player_region_name: 'Регион игрока',
      affiliate_offer_name: 'Офферы',
      payment_system_name: 'Платежная система',
      top: 'Топ',
    },

    dashboards_filters: {
      filter: 'Фильтр',
      add_filter: 'Добавить фильтр',
      reload_filter: 'Обновить для просмотра',
      series: 'Серия',
      label_series: 'Имя серии',
      series_placeholder: 'Введите имя серии',
      name_series: 'Имя серии',
      new_series: 'Новая серия',
      add_series: 'Добавить Серию',
      tooltip_copy: 'Копировать',
      tooltip_delete: 'Удалить',
    },

  },
};
