export default {
  computed: {
    statusMeta() {
      const result = [{
        label: 'status',
        options: [{
          value: 'draft',
          label: 'draft',
          entity: [
            'segment',
            'activity',
          ],
        }, {
          value: 'active',
          label: 'active',
          entity: [
            'segment',
            'activity',
          ],
        }, {
          value: 'archived',
          label: 'archived',
          entity: [
            'activity',
          ],
        }],
      }, {
        label: 'moderation_status',
        options: [{
          value: 'on_review',
          label: 'on_review',
          expect: [{
            field: 'status',
            toBe: 'active',
          }],
          entity: [
            'segment',
            'activity',
          ],
        }, {
          value: 'declined',
          label: 'declined',
          expect: [{
            field: 'status',
            toBe: 'active',
          }],
          entity: [
            'segment',
            'activity',
          ],
        }, {
          value: 'for_rework',
          label: 'for_rework',
          expect: [{
            field: 'status',
            toBe: 'active',
          }],
          entity: [
            'segment',
            'activity',
          ],
        }, {
          value: 'approved',
          label: 'approved',
          expect: [{
            field: 'status',
            toBe: 'active',
          }],
          entity: [
            'segment',
            'activity',
          ],
        }],
      }, {
        label: 'launch_status',
        options: [{
          value: 'paused',
          label: 'paused',
          expect: [{
            field: 'status',
            toBe: 'active',
          }, {
            field: 'moderation_status',
            toBe: 'approved',
          }],
          entity: [
            'activity',
          ],
        }, {
          value: 'running',
          label: 'running',
          expect: [{
            field: 'status',
            toBe: 'active',
          }, {
            field: 'moderation_status',
            toBe: 'approved',
          }],
          entity: [
            'activity',
          ],
        }, {
          value: 'completed',
          label: 'completed',
          expect: [{
            field: 'status',
            toBe: 'active',
          }, {
            field: 'moderation_status',
            toBe: 'approved',
          }],
          entity: [
            'activity',
          ],
        }, {
          value: 'pending',
          label: 'pending',
          expect: [{
            field: 'status',
            toBe: 'active',
          }, {
            field: 'moderation_status',
            toBe: 'approved',
          }],
          entity: [
            'activity',
          ],
        }],
      }];

      return result;
    },
    confirmIndex() {
      return {
        'save-and-send-to-review': (activity, confirmAction) => ({
          title: this.$t('crm.confirm.save_and_to_review_activity.title'),
          msg: `${this.$t('crm.confirm.save_and_to_review_activity.msg', { activityName: activity.name })}`,
          type: 'success',
          action: 'approve',
          actionName: `${this.$t('crm.confirm.save_and_to_review_activity.action')}`,
          confirmAction,
        }),
        'send-to-review': (activity, confirmAction) => ({
          title: this.$t('crm.confirm.to_review_activity.title'),
          msg: `${this.$t('crm.confirm.to_review_activity.msg', { activityName: activity.name })}`,
          type: 'success',
          action: 'approve',
          actionName: `${this.$t('crm.confirm.to_review_activity.action')}`,
          confirmAction,
        }),
        approve: (activity, confirmAction) => ({
          title: this.$t('crm.confirm.approve_activity.title'),
          msg: `${this.$t('crm.confirm.approve_activity.msg', { activityName: activity.name })}`,
          type: 'success',
          action: 'approve',
          actionName: `${this.$t('crm.confirm.approve_activity.action')}`,
          confirmAction,
        }),
        'for-rework': (activity, confirmAction) => ({
          title: this.$t('crm.confirm.for_rework_activity.title'),
          msg: `${this.$t('crm.confirm.for_rework_activity.msg', { activityName: activity.name })}`,
          type: 'success',
          action: 'for-rework',
          actionName: `${this.$t('crm.confirm.for_rework_activity.action')}`,
          confirmAction,
        }),
        decline: (activity, confirmAction) => ({
          title: this.$t('crm.confirm.decline_activity.title'),
          msg: `${this.$t('crm.confirm.decline_activity.msg', { activityName: activity.name })}`,
          type: 'warning',
          action: 'decline',
          actionName: `${this.$t('crm.confirm.decline_activity.action')}`,
          confirmAction,
        }),
        archive: (activity, confirmAction) => ({
          title: this.$t('crm.confirm.delete_activity.title'),
          msg: `${this.$t('crm.confirm.delete_activity.msg', { activityName: activity.name })}`,
          type: 'warning',
          action: 'archive',
          actionName: `${this.$t('crm.confirm.delete_activity.action')}`,
          confirmAction,
        }),
        run: (activity, confirmAction) => ({
          title: this.$t('crm.confirm.run_activity.title'),
          msg: `${this.$t('crm.confirm.run_activity.msg', {
            activityName: activity.name,
            dateTime: this.$options.filters.formatDate3(activity.activity_period_start),
          })}`,
          type: 'success',
          action: 'run',
          actionName: `${this.$t('crm.confirm.run_activity.action')}`,
          confirmAction,
        }),
        pause: (activity, confirmAction) => ({
          title: this.$t('crm.confirm.pause_activity.title'),
          msg: `${this.$t('crm.confirm.pause_activity.msg', { activityName: activity.name })}`,
          type: 'success',
          action: 'pause',
          actionName: `${this.$t('crm.confirm.pause_activity.action')}`,
          confirmAction,
        }),
        complete: (activity, confirmAction) => ({
          title: this.$t('crm.confirm.complete_activity.title'),
          msg: `${this.$t('crm.confirm.complete_activity.msg', { activityName: activity.name })}`,
          type: 'warning',
          action: 'complete',
          actionName: `${this.$t('crm.confirm.complete_activity.action')}`,
          confirmAction,
        }),
        clone: (activity, confirmAction) => ({
          title: this.$t('crm.confirm.clone_activity.title'),
          msg: `${this.$t('crm.confirm.clone_activity.msg', { activityName: activity.name })}`,
          type: 'success',
          action: 'complete',
          actionName: `${this.$t('crm.confirm.clone_activity.action')}`,
          confirmAction,
        }),
      };
    },
  },
  methods: {
    sanitizeStatus(filterStatus) {
      const filters = this.getStatusGroupFromList(filterStatus);
      return filterStatus.filter(status => !this.isStatusDisabled(filters, status));
    },
    isStatusDisabled(filters, status, statusMeta) {
      let _statusMeta = null;
      if (statusMeta) {
        _statusMeta = statusMeta;
      } else {
        const statusGroup = this.getStatusGroupByStatus(status);
        _statusMeta = statusGroup.options.find(option => option.value === status);
      }

      if (_statusMeta.expect) {
        return !_statusMeta.expect.every(assert => filters[assert.field] && filters[assert.field].includes(assert.toBe));
      }

      return false;
    },
    getStatusMetaForEntities(entity) {
      const result = this.statusMeta.map(statusGroup => ({
        ...statusGroup,
        options: statusGroup.options
          .filter(status => !status.entity || status.entity.includes(entity)),
      }));

      return result.filter(statusGroup => statusGroup.options.length > 0);
    },
    getStatusGroupByStatus(status) {
      const result = this.statusMeta.find(statusGroup => statusGroup.options.some(option => option.value === status));
      return result || {};
    },
    getStatusGroupFromList(filterStatus) {
      const result = {};

      filterStatus.forEach((status) => {
        const key = this.getStatusGroupByStatus(status).label;
        if (!result[key]) {
          result[key] = [status];
        } else {
          result[key].push(status);
        }
      });

      return result;
    },
    detectActivityStatus(activity) {
      const {
        status,
        moderation_status,
        launch_status,
      } = activity;

      if (status === 'draft') {
        return 'draft';
      }
      if (status === 'active') {
        if (moderation_status === 'on_review') {
          return 'on_review';
        }
        if (moderation_status === 'declined') {
          return 'declined';
        }
        if (moderation_status === 'for_rework') {
          return 'for_rework';
        }
        if (moderation_status === 'approved') {
          if (launch_status === 'paused') {
            return 'paused';
          }
          if (launch_status === 'running') {
            return 'running';
          }
          if (launch_status === 'completed') {
            return 'completed';
          }
          if (launch_status === 'pending') {
            return 'approved';
          }

          return 'approved';
        }
      }
      if (status === 'archived') {
        return 'archived';
      }

      return 'unknown';
    },
    detectSegmentStatus(segment) {
      const {
        status,
        moderation_status,
      } = segment;

      if (status === 'draft') {
        return 'draft';
      }
      if (status === 'active') {
        if (moderation_status === 'on_review') {
          return 'on_review';
        }
        if (moderation_status === 'declined') {
          return 'declined';
        }
        if (moderation_status === 'for_rework') {
          return 'for_rework';
        }
        if (moderation_status === 'approved') {
          return 'approved';
        }
      }
      if (status === 'archived') {
        return 'archived';
      }

      return 'unknown';
    },
    isCanBeArchived(activity) {
      const status = this.detectActivityStatus(activity);
      return status === 'approved' || status === 'completed' || status === 'declined' || status === 'draft';
    },
    isCanBeCloned(activity) {
      return activity.moderation_status === 'approved';
    },
    getLiter(index) {
      let i = index;
      const literCodes = [];
      const startLiter = 'A'.charCodeAt(0);
      const maxLiters = 26;

      do {
        const literIndex = i % maxLiters;
        literCodes.unshift(literIndex + startLiter);
        i = (i - literIndex) / maxLiters;
      } while (i > 0);

      if (literCodes.length > 1) {
        literCodes[0] -= 1;
      }

      return String.fromCharCode(...literCodes);
    },
    getDefaultConfirm() {
      return {
        title: '',
        msg: '',
        type: 'warning',
        action: 'delete',
        actionName: '',
        confirmAction: () => {},
      };
    },
  },
};
