<template>
  <el-dropdown
    :hide-timeout="0"
    :show-timeout="0"
    trigger="hover"
    class="select"
    placement="bottom-start"
    @command="command"
    @visible-change="visibleChange"
  >
    <div
      :class="{active : activeClass}"
      class="select-link"
    >
      <span
        class="text"
        @click.prevent="handlerClick"
      ><slot /></span>
      <ui-icon
        class="chev-d"
        lib="fa"
        substyle="fas"
        name="chevron-down"
        size="8px"
      />
    </div>
    <el-dropdown-menu
      slot="dropdown"
      class="ddgroup ddgroup-new"
    >
      <el-dropdown-item
        v-for="field in allDimensionsFilter"
        :key="field.name"
        :command="field"
        :class="{selected : groupingSelects[typeGroup] && groupingSelects[typeGroup].name === field.name}"
        class="grouping_select_item"
      >
        {{ field.title }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>

export default {
  name: 'GroupingDropdown',
  props: {
    groupingSelects: {
      type: Object,
      required: true,
    },

    activeClass: {
      type: [Object, String, Array],
      default() {
        return null;
      },
    },

    allDimensionsFilter: {
      type: [Array, Object],
      required: true,
    },

    typeGroup: {
      type: String,
      required: true,
    },
  },

  methods: {
    command(gb, block) {
      this.$emit('command', gb, block);
    },

    visibleChange(v) {
      this.$emit('visibleChange', v);
    },

    handlerClick(e) {
      this.$emit('handlerClick', e);
    },
  },
};
</script>
