export default {
  /**
   * Get all available domain registrars
   *
   * @returns {Promise<Object>} api response
   */
  getDomainRegistrarProviders() {
    return this.affiliatesApi.get('/domains-management/registrars');
  },
};
