<template>
  <transition name="fade">
    <div
      v-if="isOpen"
      class="ui-popup"
    >
      <div
        :style="{
          position: fixed ? 'fixed' : 'absolute',
          width: `${width}px`,
          left: `calc(50% - ${width / 2 }px`,}"
        class="wrapper"
      >
        <div class="head">
          <div class="title">
            <slot name="title">
              {{ title }}
            </slot>
          </div>
          <ui-icon
            :size="19"
            lib="fa"
            substyle="fas"
            name="times"
            class="close"
            @click.native="() => {
              if (escClose) {
                close(); $emit('cancel');
                return
              }
              cancel()
            }
            "
          />
        </div>
        <div class="body">
          <slot />
        </div>
        <div class="controls">
          <slot name="controls">
            <span
              class="sync"
              @click="reset"
            ><i class="fas fa-sync-alt" />
              {{ $t('reports.other.reset_to_defaults') }}
            </span>
            <div class="controls__right">
              <ui-button
                size="big"
                color="red"
                class="btn"
                lib="fa"
                substyle="fas"
                icon="times"
                @click="cancel"
              >
                {{ $t('reports.other.close') }}
              </ui-button>
              <ui-button
                :disabled="actionsDisabled"
                size="big"
                filled
                class="btn"
                color="green"
                lib="fa"
                substyle="fas"
                icon="check"
                @click="save"
              >
                {{ $t('reports.other.save') }}
              </ui-button>
            </div>
          </slot>
        </div>
      </div>
      <div class="overlay" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'UiPopup',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 600,
    },
    actionName: {
      type: String,
      default: 'Save',
    },
    actionsDisabled: {
      type: Boolean,
      default: false,
    },
    escClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {

  },
  mounted() {
    window.addEventListener('keyup', this.escHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.escHandler);
  },
  methods: {
    open() {
      this.isOpen = true;
      this.$emit('open');
    },
    close() {
      this.isOpen = false;
      this.$emit('close');
    },
    save() {
      this.$emit('save');
    },
    reset() {
      this.$emit('reset');
    },
    cancel() {
      this.$emit('cancel');
    },
    escHandler(e) {
      if (!this.escClose) return;
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
        this.$emit('cancel');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-popup{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
  &.fade-enter-active, &.fade-leave-active{
    transition: all .4s;
    .wrapper{
      transition: all .4s;
    }
  }
  &.fade-enter, &.fade-leave-to{
    opacity: 0;
    .wrapper{
      transform: translateY(20px);
    }
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 8vh;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    overflow: hidden;
    .sync{
      font-size: 12px;
      &:hover{
        cursor: pointer;
      }
      i{
        margin-right: 6px;
        font-size: 12px;
      }
    }
    .head{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px 16px;
      border-bottom: 1px solid #eaeaea;
      .title{
        font-size: 20px;
        line-height: 20px;
        font-weight: 500;
        color: #303634;
      }
      .close{
        color: #303634;
        cursor: pointer;
      }
    }
    .body{
      padding: 16px 24px;
    }
    .controls{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      padding: 16px 24px;
      border-top: 1px solid #eaeaea;
      .btn{
        font-size: 14px;
        font-weight: bold;
        text-transform: capitalize;
        & + .btn{
          margin-left: 16px;
        }
      }
    }
  }
  .overlay{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
</style>
