<template>
  <div class="add-user-in-team">
    <div class="popup">
      <div class="head">
        <span class="name"> {{ $t(`team_popup.add_user_in_team_title`, {teamName: team.name}) }}</span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div class="wrap-control">
        <ui-input
          v-model.trim="searchQuery"
          class="search-popup"
          is-search
          autosize
          @search="getAdmins"
        />
      </div>
      <ui-table
        ref="tablePopup"
        v-loading="isDataLoading"
        :select-row="true"
        :fields="fields"
        :data="tableData"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        i18n-path="team_users.list"
        :show-check-all="false"
        select-type="radio"
        checked-row
        checked-row-id="id"
        element-loading-custom-class="data-loader-spinner"
        class="table"
        @sort="handleSort"
        @checkedRows="checkedRows"
      />
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          @click.native="close"
        >
          {{ $t(`team_popup.cancel_button`) }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="admin_id.length === 0"
          filled
          @click="save"
        >
          <i class="fas fa-plus plus-icon-custom" />{{ $t(`team_popup.add_user_button`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>

import app from '@/main';

export default {
  name: 'AddUsersInTeam',

  props: {
    team: {
      type: Object,
      default() {
        return {};
      },
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },


  data() {
    return {
      admin_id: [],
      users: {},
      errors: {},
      searchQuery: '',
      options: {
        search: '',
        sort_column: '',
        sort_dir: '',
      },
      sortProp: 'name',
      sortOrder: 'asc',

      isDataLoading: true,
      fields: [
        {
          name: 'name',
          sortable: 'custom',
        },
        {
          name: 'email',
          sortable: 'custom',
        },
      ],
    };
  },

  computed: {
    tableData: {
      get() {
        if (this.team.id) {
          const { id } = this.team;
          return this.$_.filter(this.users, user => !(this.$_.find(user.teams, item => item.id === id)));
        }
        return undefined;
      },
      set(newVal) {
        this.users = newVal;
      },
    },
  },

  watch: {
    searchQuery() {
      this.options.search = this.searchQuery;
    },
    isOpen(v) {
      if (v) {
        this.getAdmins();
      }
    },
  },

  methods: {
    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.getAdmins();
    },
    _query() {
      this.options.sort_column = this.sortProp;
      this.options.sort_dir = this.sortOrder;
      const _paramsFilter = {
        ...this.options,
      };
      return _paramsFilter;
    },

    checkedRows(id, ids) {
      this.admin_id = ids;
    },

    getAdmins() {
      this.errors = {};
      this.$api.getAdmins(this._query()).then((response) => {
        this.users = response.data.payload;
      })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          setTimeout(() => {
            this.isDataLoading = false;
          }, 500);
        });
    },

    close() {
      this.searchQuery = '';
      this.isDataLoading = true;
      app.$eventBus.$emit('clear-check');
      this.$emit('close');
    },
    save() {
      this.$api
        .postAttachToTeam(this.admin_id, this.team.id)
        .then(() => {
          this.$emit('refresh');
          app.$eventBus.$emit('clear-check');
          this.close();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
  },
};
</script>
