export default {
  mediaBuying: {
    name: 'Media Buying',
    page_title: {
      media_buying_unreported_funds: {
        name: 'Unreported Funds',
      },
      media_buying_payments_moderation: {
        name: 'Payments Moderation — ',
        on_review: 'On Review',
        for_rework: 'For Rework',
        approved: 'Approved',
        declined: 'Declined',
      },
      media_buying_payments_payout: {
        name: 'Payments Payout — ',
        not_paid: 'Not Paid',
        for_rework: 'For Rework',
        processing: 'Processing',
        paid: 'Paid Out',
        failed: 'Failed',
      },
      posts: {
        name: 'Posts — ',
        post: 'Post',
        on_review: 'On Review',
        for_rework: 'For Rework',
        approved: 'Approved',
        declined: 'Declined',
      },
    },
    blacklist: {
      title: 'Blacklist',
      add: 'Add',
      popup: {
        title: 'Add to Blacklist',
        titleEdit: 'Edit Blacklist',
        address: 'Address',
        placeholderAddress: 'Enter Payment Address',
        paymentMethod: 'Payment Method',
        placeholderPaymentMethod: 'Select Payment Method',

        delete: {
          title: 'Delete from Blacklist',
          msg: 'Are you sure you want to delete address <span>{address}</span>?',
          action: 'delete',
        },
      },
      fields: {
        media_buying_payment_method_name: {
          title: 'Payment Method',
        },
        payment_wallet: {
          title: 'Address',
        },
        site_payment_method_name: {
          title: 'Payment Method',
        },
        created_at: {
          title: 'Created At',
        },
      },
    },
    other: {
      aff_stats: 'Affiliate Statistics',
      camp_stats: 'Campaign Statistics',
      paid_in_total: 'Paid',
      spent_total: 'Spent',
      team: 'Team',
      camp_info: 'Campaign Info',
      btn_mark: 'Mark as «Requiring Attention»',
      btn_unmark: 'Mark as «Read»',
      spent_pending: 'Pending Posts',
      paid_pending: 'Pending Payments',
      action_required: 'Action Required',
      add_to_blacklist: 'Add to Blacklist',
    },
    statistics: {
      registrations_count: 'Registrations',
      first_deposits_count: 'First Deposits',
      deposits_sum_usd: 'Deposits',
      profit_sum_usd: 'Gaming Profit',
      total_profit_sum_usd: 'Net Profit',
      cost_per_player_usd: 'Cost Per Player',
      period: 'Period',
      select_period: 'Select Period',
    },
    status: {
      moderation: 'Moderation',
      payout: 'Payout',
      not_paid: 'Not Paid',
      for_rework: 'For Rework',
      processing: 'Processing',
      paid: 'Paid Out',
      failed: 'Failed',
      on_review: 'On Review',
      approved: 'Approved',
      declined: 'Declined',
    },
    types: {
      cancellation: 'Write-Off',
      publication: 'Post',
    },
    method: {
      wire: 'Bank Transfer',
      bitcoin_micro: 'Bitcoin (micro)',
      card_rub: 'Card RUB',
      ecopayz_eur: 'ecoPayz EUR',
      ecopayz_usd: 'ecoPayz USD',
      neteller_eur: 'Neteller EUR',
      neteller_gbr: 'Neteller GBR',
      neteller_usd: 'Neteller USD',
      payeer_usd: 'Payeer USD',
      paypal: 'PayPal',
      player_account: 'Player Account',
      qiwi: 'Qiwi',
      skrill_eur: 'Skrill EUR',
      skrill_gbp: 'Skrill GBP',
      skrill_usd: 'Skrill USD',
      webmoney_r: 'Webmoney R',
      webmoney_z: 'Webmoney Z',
    },

    reports_table_title: {
      fields: {
        affiliate_email: {
          title: 'Affiliate',
        },
        root_campaign_name: {
          title: 'Root Campaign',
        },
        campaign_name: {
          title: 'Campaign',
        },
        impressions_count: {
          title: 'Impress.',
        },
        visits_count: {
          title: 'Visits',
        },
        registrations_count: {
          title: 'Registr.',
        },
        first_deposits_count: {
          title: 'First Depos.',
        },
        first_deposits_sum: {
          title: 'First Depos',
        },
        deposits_count: {
          title: 'Deposits',
        },
        deposits_sum: {
          title: 'Deposits',
        },
        payments_sum: {
          title: 'Payments',
        },
        cost_per_player: {
          title: 'Cost Per Player',
        },
        profit_sum: {
          title: 'Gaming Profit',
        },
        total_profit_sum: {
          title: 'Net Profit',
        },
      },
    },
    list_table_title: {
      fields: {
        incrementing_id: {
          title: 'ID',
        },
        type: {
          title: 'Type',
        },
        affiliate_email: {
          title: 'Affiliate Email',
        },
        team_name: {
          title: 'Team',
        },
        channel_name: {
          title: 'Advertising Channel',
        },
        campaign_name: {
          title: 'Campaign Name',
        },
        payment_method_id: {
          title: 'Method',
        },
        amount: {
          title: 'Amount',
        },
        amount_converted: {
          title: 'Amount',
        },
        amount_usd: {
          title: 'Amount, USD',
        },
        created_at: {
          title: 'Created',
        },
        updated_at: {
          title: 'Updated',
        },
        period: {
          title: 'Period',
        },
        campaigns_without_posts_count: {
          title: 'Campaings w/out Posts',
        },
        unreported_sum: {
          title: 'Unreported Sum',
        },
        reported_sum: {
          title: 'Reported Sum',
        },
      },
    },
    actions: {
      info: 'Info',
      decline: 'Decline',
      approve: 'Approve',
      send_for_rework: 'Send For Rework',
      send_to_review: 'Send On Review',
      paid: 'Paid Out',
      failed: 'Failed',
      initiate_payment: 'Initiate Payment',
      profile: 'Affiliate Info',
    },
    info: {
      title: 'Request from {affiliate}',
      post_title: 'Post from {affiliate}',
      advertising_channel_card: {
        title: 'Advertising Channel',
      },
      campaign_card: {
        title: 'Campaign',
      },
      summary: {
        cost_per_dep: 'Cost per 1st Dep.',
        visits: 'Visits',
        profit: 'Profit',
        registrations: 'Registrations',
        ndep_reg_rait: 'New Dep. / Reg. Ratio',
        deposits: 'Deposits',
        players_w_bets: 'Players w/ Bet',
        sport_bets: 'Sport Bets',
        casino_bets: 'Casino Bets',
      },
      payment_info_card: {
        title: 'Payment Info',
        type: 'Type',
        method: 'Payment Method',
        period: 'Payment Period',
        wallet: 'Wallet',
        platform: 'Platform',
        affiliate: 'Affiliate',
        requested_at: 'Requested at',
        updated_at: 'Updated at',
        from: 'From: ',
        status: {
          title: 'Status',
          on_review: 'On Review',
          not_paid: 'Not Paid',
          processing: 'Processing',
          paid: 'Paid Out',
        },
      },
      post_info_card: {
        title: 'Post Info',
        description: 'Description',
        period: 'Period',
        urls: 'URL’s',
      },
      campaign_costs_card: {
        title: 'Campaign Сosts',
        balance: 'Balance',
        spent: 'Spent',
        paid_in_total: 'Paid in Total',
        on_review: 'On Review',
      },
      account_overview: {
        title: 'Account Overview',
      },
      payment_description_card: {
        title: 'Payment Description',
      },
      files_card: {
        title: 'Files',
        download: 'Download',
        no_files: 'No Files',
      },
      history_card: {
        title: 'History',
        no_history: 'No History',
      },
      popup: {
        cancel: 'Cancel',
        failed_request: {
          title: 'Failed Payment',
          text: 'Сonfirm the failure of the payment?',
          button: 'Confirm',
        },
        decline_request: {
          title: 'Decline Request',
          text: 'Сonfirm the denial of payment?',
          button: 'Decline Request',
        },
        rework_request: {
          title: 'Send For Rework',
          text: 'Confirm sending this request for rework?',
          button: 'Confirm',
        },
        on_review_request: {
          title: 'Send On Review',
          text: 'Confirm sending this request to review?',
          button: 'Confirm',
        },
        approve_request: {
          title: 'Approve',
          text: 'Are you sure you want to approve this request?',
          button: 'Approve',
        },
        initiate_request: {
          title: 'Initiate Payment',
          text: 'Are you sure you want to initiate payment this request?',
          button: 'Initiate Payment',
        },
        paid_request: {
          title: 'Paid',
          text: 'Are you sure you want to paid this request?',
          button: 'Paid',
        },
        approve_post: {
          title: 'Approve post',
          text: 'Are you sure you want to approve this post?',
          button: 'Approve',
        },
        decline_post: {
          title: 'Decline Post',
          text: 'Confirm the denial of post?',
          button: 'Decline Post',
        },
        rework_post: {
          title: 'Send For Rework',
          text: 'Confirm sending this post for rework?',
          button: 'Confirm',
        },
        on_review_post: {
          title: 'Send On Review',
          text: 'Confirm sending this post to review?',
          button: 'Confirm',
        },
        reply: 'Reply',
        reply_placeholder: 'Describe the reason',
        files: 'Files',
        files_placeholder: 'Drop file here or <em>click to upload</em>',
      },
      payment_auto: 'Payment was initiated automatically',
    },
    comments: {
      title: 'Comments',
      placeholder: 'Your comment…',
      button_create: 'Send',
      button_update: 'Save',
      types: {
        user: 'Comments',
        system: 'History',
        all: 'Comments & History',
      },
    },
    cost_model: {
      fixed: 'Fixed Price',
      cpm: 'CPM',
      cpc: 'CPC',
      cpv: 'CPV',
      cpi: 'CPI',
    },
  },
};
