<template>
  <div class="dashboard-template">
    <div class="toolbar">
      <div class="wrapper">
        <div class="section">
          <span class="title">Dashboard Template</span>
        </div>
        <div class="section">
          <adm-ui-button
            title="Create Template"
            icon="fas fa-plus"
            size="small"
          />
        </div>
      </div>
    </div>

    <ui-table
      v-loading="isDataLoading"
      :fields="fields"
      :data="data"
      class="table"
      i18n-path="dashboards"
      element-loading-custom-class="data-loader-spinner"
    >
      <template slot="append">
        <el-table-column width="110">
          <template slot-scope="scope">
            <adm-ui-controls-widget
              :data="controlsData(scope.row)"
              :action-value="scope"
            />
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>
import queryParamsMixin from '../mixins/query-params';

export default {
  name: 'DashboardTemplate',

  mixins: [queryParamsMixin],

  data() {
    return {
      isDataLoading: false,

      data: [
        {
          incrementing_id: 1,
          name: 'Critical Business Metrics',
          type: 'visible',
          description:
            'A real-time dashboard to understand critical metrics related to growth, retention, and leakage and to stay on top of business growth...',
        },
        {
          incrementing_id: 2,
          name: 'Finance Watch',
          type: 'unvisible',
          description:
            'This dashboard helps you understand how much revenue your company is actually making as compared to MRR which is a notional v…',
        },
        {
          incrementing_id: 4,
          name: 'Critical Business Metrics',
          type: 'visible',
          description:
            'A real-time dashboard to understand critical metrics related to growth, retention, and leakage and to stay on top of business growth...',
        },
        {
          incrementing_id: 3,
          name: 'Finance Watch',
          type: 'unvisible',
          description:
            'This dashboard helps you understand how much revenue your company is actually making as compared to MRR which is a notional v…',
        },
      ],
      fields: [
        {
          name: 'incrementing_id',
          align: 'left',
          width: '50',
        },
        {
          name: 'name',
          minWidth: '200',
        },
        {
          name: 'description',
        },
      ],
    };
  },
  methods: {
    controlsData(row) {
      return [
        {
          title: 'Delete',
          type: 'danger',
          icon: 'fas fa-trash-alt',
          clickHandler: () => {},
        },
        {
          title: 'Info',
          type: row.type === 'visible' ? 'primary' : 'danger',
          icon: row.type === 'visible' ? 'fas fa-eye' : 'fas fa-eye-slash',
          clickHandler: () => {},
        },
        {
          title: 'Edit',
          type: 'primary',
          icon: 'fas fa-pen',
          clickHandler: () => {},
        },
        {
          title: 'Column',
          type: 'primary',
          icon: 'fas fa-columns',
          clickHandler: () => {},
        },
      ];
    },
  },
};
</script>
