<template>
  <div class="confirm-popup">
    <div class="popup">
      <div
        class="close"
        @click="$emit('close')"
      >
        X
      </div>
      <span class="title">{{ title }}</span>
      <div class="msg">
        <slot>Message</slot>
      </div>
      <div class="btns">
        <ui-button
          color="red"
          class="btn"
          @click="onCancel || $emit('close')"
        >
          {{ $t('affiliates.cardView.toolbar.btns.cancel') }}
        </ui-button>
        <ui-button
          color="green"
          filled
          class="btn"
          @click="onConfirm"
        >
          {{ actionTitle }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>
export default {
  name: 'ConfirmPopup',
  props: {
    title: {
      type: String,
      default: 'Confirmation',
    },
    actionTitle: {
      type: String,
      required: true,
    },
    onCancel: {
      type: Function,
      default: null,
    },
    onConfirm: {
      type: Function,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && this.isOpen) {
        this.$emit('close');
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/colors.scss";

.confirm-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;
  .popup {
    position: fixed;
    z-index: 902;
    left: calc(50% - 180px);
    top: 30%;
    width: 360px;
    padding: 32px 16px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .close {
      position: absolute;
      top: -80px;
      font-size: 46px;
      cursor: pointer;
      color: white;
    }
    .title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 16px;
    }
    .msg {
      width: 280px;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 24px;
    }
    .btns {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .btn {
        width: 156px;
        height: 46px;
        flex: 0 0 156px;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
  }
  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
</style>
