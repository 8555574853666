export default {
  /**
   * Get service domains count
   *
   * @param {Object} params
   * @param {String} params.search
   * @param {String} params.platform_service_origin_id
   * @param {String} params.platform_server_id
   * @param {String} params.domain_registrar_account_id
   * @param {String} params.dns_hosting_account_id
   * @param {String} params.ssl_issuance
   * @param {String} params.ns_management
   * @param {String} params.dns_management
   *
   * @return {Promise<Object>} api response
   */
  getServiceDomainsCount(params) {
    return this.affiliatesApi.get('/domains-management/platform-service-domains/count', { params });
  },

  /**
   * Get service domains
   *
   * @param {Object} params
   * @param {String} params.sort_column
   * @param {String} params.sort_dir
   * @param {String} params.search
   * @param {Number} params.limit
   * @param {Number} params.offset
   * @param {String} params.platform_service_origin_id
   * @param {String} params.platform_server_id
   * @param {String} params.domain_registrar_account_id
   * @param {String} params.dns_hosting_account_id
   * @param {String} params.ssl_issuance
   * @param {String} params.ns_management
   * @param {String} params.dns_management
   *
   * @return {Promise<Object>} api response
   */
  getServiceDomains(params) {
    return this.affiliatesApi.get('/domains-management/platform-service-domains', { params });
  },

  /**
   * Create service domain
   *
   * @param {Object} data
   * @param {String} data.platform_service_origin_id
   * @param {String} data.domain_name
   * @param {String} data.pick_platform_server_type
   * @param {String} data.platform_server_id
   * @param {String} data.platform_server_ipv4_address_id
   * @param {String} data.platform_server_ipv6_address_id
   * @param {String} data.ssl_issuance
   * @param {String} data.ns_management
   * @param {String} data.domain_registrar_account_id
   * @param {Array} data.ns_servers
   * @param {String} data.dns_management
   * @param {String} data.dns_hosting_account_id
   *
   * @return {Promise<Object>} api response
   */
  createServiceDomain(data) {
    return this.affiliatesApi.post('/domains-management/platform-service-domains', data);
  },

  /**
   * Get service domain by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  getServiceDomainById(id) {
    return this.affiliatesApi.get(`/domains-management/platform-service-domains/${id}`);
  },

  /**
   * Update service domain
   *
   * @param {String} id
   * @param {Object} data
   * @param {String} data.platform_server_id
   * @param {String} data.platform_server_ipv4_address_id
   * @param {String} data.platform_server_ipv6_address_id
   * @param {String} data.ssl_issuance
   * @param {String} data.ns_management
   * @param {String} data.domain_registrar_account_id
   * @param {Array} data.ns_servers
   * @param {String} data.dns_management
   * @param {String} data.dns_hosting_account_id
   *
   * @return {Promise<Object>} api response
   */
  updateServiceDomain(id, data) {
    return this.affiliatesApi.put(`/domains-management/platform-service-domains/${id}`, data);
  },

  /**
   * Delete service domain by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  deleteServiceDomain(id) {
    return this.affiliatesApi.delete(`/domains-management/platform-service-domains/${id}`);
  },

  /**
   * Re-Check service domain by id
   *
   * @param {String} id
   *
   * @return {Promise<Object>} api response
   */
  reCheckServiceDomain(id) {
    return this.affiliatesApi.post(`/domains-management/platform-service-domains/${id}/recheck`);
  },

  updateBlockedCountry(id, { country_code }) {
    return this.affiliatesApi.put(`/domains-management/platform-service-domains/${id}/blocked-countries`, { country_code });
  },
  deleteBlockedCountry(id, { country_code }) {
    return this.affiliatesApi.delete(`/domains-management/platform-service-domains/${id}/blocked-countries`, { data: { country_code } });
  },
};
