export default {
  domainManagement: {
    pageTitle: {
      dnsHostingAccounts: 'Уч. записи хостинга DNS',
      dedicatedSiteDomains: 'Выделенные домены',
      domainRegistrarAccounts: 'Уч. записи рег. домена',
      platformServers: 'Сервера',
      rotationGroups: 'Ротационные группы',
      serviceDomains: 'Сервисные домены',
    },
    fields: {
      affiliate_email: {
        title: 'Партнер',
      },
      affiliate_offer_name: {
        title: 'Оффер',
      },
      incrementing_id: {
        title: 'ID',
      },
      name: {
        title: 'Название',
      },
      setup_mode: {
        title: 'Настройка',
      },
      group_name: {
        title: 'Имя группы',
      },
      server_name: {
        title: 'Название сервера',
      },
      account_name: {
        title: 'Имя аккаунта',
      },
      dns_hosting_name: {
        title: 'Провайдер хостинга DNS',
      },
      domain_registrar_name: {
        title: 'Провайдер регистратора домена',
      },
      auto_dns_management: {
        title: 'Записи DNS',
      },
      auto_ns_management: {
        title: 'Записи NS',
      },
      platform_service_domains_count: {
        title: 'Количество доменов',
      },
      created_at: {
        title: 'Создан',
      },
      ipv4_addresses_total_count: {
        title: 'IPv4',
      },
      ipv6_addresses_total_count: {
        title: 'IPv6',
      },
      main_ipv4_address: {
        title: 'IPv4',
      },
      main_ipv6_address: {
        title: 'IPv6',
      },
      ipv4_addresses_available_count: {
        title: 'Свободные IPv4 адреса',
      },
      ipv6_addresses_available_count: {
        title: 'Свободные IPv6 адреса',
      },
      can_be_picked_automatically: {
        title: 'Автовыбор',
        values: {
          true: 'Включено',
          false: 'Выключено',
        },
      },
      affiliates_using_group_count: {
        title: 'Количество партнеров',
      },
      traffic_last_30_days_count: {
        title: 'Трафик за 30 дней',
      },
      traffic_last_7_days_count: {
        title: 'Трафик за 7 дней',
      },
      traffic_last_day_count: {
        title: 'Трафик за вчерашний день',
      },
      type: {
        title: 'Вид',
        values: {
          default: 'По умолчанию',
          custom: 'Пользовательский',
        },
      },
      latest_config_request_status: {
        title: 'Обновление конфигурации',
        values: {
          unknown: 'Неизвестно',
          ok: 'ОК',
          warning: 'Внимание',
        },
      },
      dns_records_state: {
        title: 'Статус DNS записей',
        values: {
          unknown: 'Неизвестный',
          valid: 'Действительный',
          invalid: 'Недействительный',
        },
      },
      ns_records_state: {
        title: 'Статус NS записей',
        values: {
          unknown: 'Неизвестный',
          valid: 'Действительный',
          invalid: 'Недействительный',
        },
      },
      ssl_certificate_state: {
        title: 'Статус SSL',
        values: {
          unknown: 'Неизвестный',
          active: 'Активный',
          expired: 'Истекший',
          expiring_soon: 'Скоро истекает',
        },
      },
      reachability_state: {
        title: 'Доступность',
        values: {
          unknown: 'Неизвестно',
          ok: 'ОК',
          warning: 'Небольшие проблемы',
          severe: 'Серьезные проблемы',
        },
      },
      domain_name: {
        title: 'Название домена',
      },
      platform_service_origin_name: {
        title: 'Тип сервиса',
      },
      platform_server_name: {
        title: 'Сервер',
      },
      used_in_groups_count: {
        title: 'Ротац. группы',
      },
      blocked_countries_count: {
        title: 'Заблок. страны',
      },
      domain_registrar_account_name: {
        title: 'Рег. доменов',
      },
      dns_hosting_account_name: {
        title: 'Хостинг DNS',
      },
    },
    buttons: {
      add_dns_hosting_account: 'Добавить уч. запись хостинга DNS',
      edit: 'Редактировать',
      delete: 'Удалить',
      recheck: 'Перепроверить',
      domains: 'Домены',
      add_domain_registrar_account: 'Добавить учетную запись',
      add_domain: 'Добавить домен',
      pin_domain: 'Прикрепить домен',
      add_platform_server: 'Добавить сервер',
      rebuild_servers_config: 'Модифицировать настройки конфигурации',
      view_script: 'Посмотреть скрипт',
      add_rotation_group: 'Добавить ротационную группу',
      add_service_domain: 'Добавить сервисный домен',
    },
    popup: {
      add_dedicated_site_domain: 'Добавить выделенный домен',
      placeholder_offer: 'Выберите оффер',
      placeholder_domain: 'Выберите выделенный домен',
      domain_title: 'Выделить домен',
      pin_aff_to_domain: 'Привязать партнера к домену',
      info_input: 'Введите имена доменов каждое с новой строки.',
      text_error: 'Следующие домены не добавлены:',
      domain_names: 'Доменные имена',
      pinned: 'Прикрепить',
      save: 'Сохранить',
      cancel: 'Отменить',
      close: 'Закрыть',
      add: 'Добавить',
      edit: 'Отредактировать',
      done: 'Готово',
      empty: 'Пусто',
      allocate: 'Выделить',
      dnsHostingAccount: 'Учетную запись хостинга DNS',
      domainRegistrarAccount: 'Учетную запись регистратора домена',
      initCommands: 'Посмотреть скрипт',
      step: 'Шаг ',
      rotationGroup: 'ротационную группу',
      domains: 'Домены',
      serviceDomain: 'сервисный домен',
      platformServer: 'Сервер',
      rotationGroups: 'Ротационные группы',
      form: {
        authentication_data: 'Данные аутентификации',
        name: {
          label: 'Название',
          placeholder: 'Ввести название',
        },
        account_name: {
          label: 'Имя аккаунта',
          placeholder: 'Введите имя аккаунта',
        },
        auto_dns_management: {
          label: 'Автоматическое управление DNS',
          auto: 'Автоматическое',
          manual: 'Ручное',
        },
        dns_hosting_provider: {
          label: 'Провайдер хостинга DNS',
          placeholder: 'Выбрать провайдера хостинга DNS',
        },
        domin_registrar_provider: {
          label: 'Провайдер регистратора домена',
          placeholder: 'Выбрать регистратор хостинга домена',
        },
        auto_ns_management: {
          label: 'Автоматическое управление NS',
        },
        domain_registrar_account: {
          label: 'Учетная запись хостинга DNS',
          placeholder: 'Выбрать учетную запись хостинга DNS',
        },
        api_token: {
          label: 'API Token',
          placeholder: 'Ввести API Token',
        },
        account_id: {
          label: 'ID аккаунта',
          placeholder: 'Ввести ID аккаунта',
        },
        server_name: {
          label: 'Название сервера',
          placeholder: 'Ввести название сервера',
        },
        server_description: {
          label: 'Описание сервера',
          placeholder: 'Ввести описание сервера',
        },
        main_ipv4_address: {
          label: 'Главный IPv4',
          placeholder: 'Ввести адрес IPv4',
        },
        main_ipv6_address: {
          label: 'Главный IPv6',
          placeholder: 'Ввести адрес IPv6',
        },
        additional_ipv4_addresses: {
          label: 'Дополнительный IPv4',
          placeholder: 'Ввести IPv4 адрес',
        },
        additional_ipv6_addresses: {
          label: 'Дополнительный IPv6',
          placeholder: 'Ввести IPv6 адрес',
        },
        group_name: {
          label: 'Название группы',
          placeholder: 'Ввести название группы',
        },
        group_description: {
          label: 'Описание группы',
          placeholder: 'Ввести описание группы',
        },
        auto_replace_banned_domains: {
          label: 'Автозамена заблокированных доменов',
          yes: 'Да',
          no: 'Нет',
        },
        countries_list: 'Список стран',
        countries_placeholder: 'Выберите страны',
        countries_to_check_availability: {
          label: 'Страны для проверки доступности',
          any: 'Любые',
          only_selected: 'Только выбранные',
          except_selected: 'Кроме выбранных',
        },
        redirect_domain_id: {
          label: 'Redirects',
        },
        adserver_domain_id: {
          label: 'Adserver',
        },
        server_auto_pick: {
          label: 'Автовыбор',
        },
        landings_storage_domain_id: {
          label: 'Promo API',
        },
        banners_storage_domain_id: {
          label: 'Хранилище лендингов',
        },
        promo_api_domain_id: {
          label: 'Хранилище баннеров',
        },
        domain_name: {
          label: 'Название домена',
          placeholder: 'Ввести название домена',
        },
        dns_management: {
          label: 'Управление DNS',
          auto: 'Автоматическое',
          manual: 'Ручное',
        },
        ns_management: {
          label: 'Управление NS',
          auto: 'Автоматическое',
          manual: 'Ручное',
        },
        setup_mode: {
          label: 'Режим настройки',
          managed: 'Управляемая',
          manual: 'Ручная',
        },
        ssl_issuance: {
          label: 'Размещение SSL',
          auto: 'Автоматическое',
          manual: 'Ручное',
        },
        pick_platform_server_type: {
          label: 'Выбрать сервер & IP',
          auto: 'Автоматический',
          manual: 'Ручной',
        },
        platform_service_origin_id: {
          label: 'Тип сервиса',
          placeholder: 'Выбрать тип сервиса',
        },
        platform_server_id: {
          label: 'Сервер',
          placeholder: 'Выбрать сервер',
        },
        domain_registrar_account_id: {
          label: 'Учетная запись регистратора домена',
          placeholder: 'Выбрать учетную запись регистратора домена',
        },
        ns_servers: {
          label: 'Серверы NS',
          placeholder: 'Ввести серверы NS',
        },
        dns_hosting_account_id: {
          label: 'Учетная запись хостинга',
          placeholder: 'Выбрать учетную запись хостинга DNS',
        },
        platform_server_ipv4_address_id: {
          label: 'Адрес IPv4',
          placeholder: 'Выбрать адрес IPv4',
        },
        platform_server_ipv6_address_id: {
          label: 'Адрес IPv6',
          placeholder: 'Выбрать адрес IPv6',
        },
        platform_service_rotation_group_id: {
          label: 'Площадка ротационных групп',
        },
      },
    },
    blockedPopup: {
      title: 'Заблокированные страны',
      addCountry: 'Добавить страну',
      addCountryPlaceholder: 'Выберите страну',
      fields: {
        country_name: {
          title: 'Страна',
        },
      },
    },
    messages: {
      servers_config_update_success: 'Конфигурация серверов обновлена!',
      servers_config_update_fail: 'Конфигурация серверов не обновлена!',
      success: 'Выполнено!',
      failed: 'Не выполнено!',
      delete: 'Вы уверены, что хотите удалить <span>{name}</span>?',
      copied_to_clipboard: 'Скопировано в буфер обмена',
      copy_error: 'Ошибка копирования!',
    },
  },
};
