export default [
  {
    path: '/',
    name: 'default',
    redirect: '/login/auth',
  },
  {
    path: '*',
    name: '404',
    redirect: '/',
  },
];
