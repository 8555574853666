export default {
  /**
   * Get all available dns hostings
   *
   * @returns {Promise<Object>} api response
   */
  getDNSHostingProviders() {
    return this.affiliatesApi.get('/domains-management/dns-hostings');
  },
};
