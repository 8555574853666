<template>
  <div class="ui-filter-select popover-filter">
    <div ref="pop" class="pop">
      <div class="arrow" />
      <div
        v-for="(item, index) in currentFilters"
        :key="index"
        class="filter-row"
      >
        <ui-button
          :icon-size="12"
          color="red"
          lib="fa"
          substyle="fas"
          icon="minus"
          class="btn"
          @click="removeFilter(index)"
        />
        <ui-select
          v-model="item.type"
          :options="availableFilters"
          :exclude-options="excludeFilters"
          :label-i18n="'ui.filters'"
          value-prop="type"
          label-prop="type"
          class="filter_type"
          @select="item.values = getFilter(item.type).multiple ? [] : ''"
          @input="inputSelect($event, index)"
        />
        <el-radio-group
          v-if="item.op !== null"
          v-model="item.op"
          class="radio-operator"
          size="small"
          @change="()=>{
            if (item.values.length) {
              handlerInputMultiselect(true);
            }
          }"
        >
          <el-radio-button label="=">
            =
          </el-radio-button>
          <el-radio-button label="!=">
            ≠
          </el-radio-button>
        </el-radio-group>
        <ui-select
          v-if="item.type !== 'lastActivity' && item.type !== 'profitCategory'"
          v-model="item.values"
          :options="getFilter(item.type).options"
          :value-prop="getFilter(item.type).valueProp"
          :label-prop="getFilter(item.type).labelProp"
          :remote="getFilter(item.type).remote"
          :filter-method="getFilter(item.type).method"
          :disabled="!item.type"
          :multiple="getFilter(item.type).multiple"
          :filterable="getFilter(item.type).filterable"
          :remote-auto="true"
          class="filter_type"
          @input="handlerInputMultiselect"
        />
      </div>
      <div class="controls ui-filter-select__controls">
        <ui-button
          :disabled="availableFilters.length === currentFilters.length"
          icon="plus"
          lib="fa"
          substyle="fas"
          class="btn ui-filter-select__add-btn"
          @click="addFilter"
        >
          {{ $t('dashboards.dashboards_filters.add_filter') }}
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UiFilterSelect',
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    useFilters: {
      type: Array,
      default() {
        return ['trafficType'];
      },
    },
    from: {
      type: Object,
      default() {
        return this.$moment().startOf('day').subtract(30, 'd');
      },
    },
    to: {
      type: Object,
      default() {
        return this.$moment().endOf('day');
      },
    },
  },
  data() {
    return {
      readyUpdate: this.$_.debounce(this._readyUpdate, 700),
      isDisabled: false,
      tab: 'from',
      countInput: 30,
      filtersOpen: false,

      currentFilters: [],
    };
  },
  computed: {
    allFilters() {
      const allFilters = {
        playerFilter: {
          type: 'playerFilter',
          field: 'players_filter',
          options: [
            {
              type: 'all',
              name: this.$t('reports.players_type.all'),
            },
            {
              type: 'new',
              name: this.$t('reports.players_type.new'),
            },
            {
              type: 'old',
              name: this.$t('reports.players_type.old'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
        trafficType: {
          type: 'trafficType',
          field: 'traffic_type',
          options: [
            {
              type: 'all',
              name: this.$t('ui.filters.select.all'),
            },
            {
              type: 'affiliates',
              name: this.$t('ui.filters.select.partners'),
            },
            {
              type: 'direct',
              name: this.$t('ui.filters.select.direct'),
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },

        playerCountryNameFilter: {
          type: 'playerCountryNameFilter',
          checkSupported: true,
          field: 'player_country_code',
          options: [],
          labelProp: 'player_country_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const dateFrom = this.$moment()
              .startOf('day')
              .subtract(1, 'month');
            const dateTo = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            const query = {
              search: q
                ? {
                  player_country_name: {
                    op: 'like',
                    value: [q],
                  },
                }
                : undefined,
              dimensions: ['player_country_name'],
              limit: 100,
              offset: 0,
              sorting: [
                {
                  sort_by: 'player_country_name',
                  sort_dir: 'asc',
                },
              ],
              metrics: ['impressions_count'],
              from: this.$moment(dateFrom)
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo)
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api
                .getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        playerRegionNameFilter: {
          type: 'playerRegionNameFilter',
          checkSupported: true,
          field: 'player_region_name',
          options: [],
          labelProp: 'player_region_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const dateFrom = this.$moment()
              .startOf('day')
              .subtract(1, 'month');
            const dateTo = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            const query = {
              search: q
                ? {
                  player_region_name: {
                    op: 'like',
                    value: [q],
                  },
                }
                : undefined,
              dimensions: ['player_region_name'],
              limit: 100,
              offset: 0,
              sorting: [
                {
                  sort_by: 'player_region_name',
                  sort_dir: 'asc',
                },
              ],
              metrics: ['impressions_count'],
              from: this.$moment(dateFrom)
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo)
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api
                .getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        trafficRegionNameFilter: {
          type: 'trafficRegionNameFilter',
          checkSupported: true,
          field: 'country_code',
          options: [],
          labelProp: 'country_name',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method: (q) => {
            const dateFrom = this.$moment()
              .startOf('day')
              .subtract(1, 'month');
            const dateTo = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            const query = {
              search: q
                ? {
                  country_name: {
                    op: 'like',
                    value: [q],
                  },
                }
                : undefined,
              dimensions: ['country_name'],
              limit: 100,
              offset: 0,
              sorting: [
                {
                  sort_by: 'country_name',
                  sort_dir: 'asc',
                },
              ],
              metrics: ['impressions_count'],
              from: this.$moment(dateFrom)
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(dateTo)
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api
                .getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
      };

      const keyAllFilters = Object.keys(allFilters);

      const supportAllFilters = keyAllFilters.reduce((acc, value) => {
        if (!this.supportedDimension.includes(allFilters[value]?.labelProp)
          && allFilters[value]?.checkSupported) {
          return acc;
        }
        acc[value] = allFilters[value];
        return acc;
      }, {});

      return supportAllFilters;
    },

    supportedDimension() {
      return (this.reportsSettings.dimensions || []).map(dim => dim.column_name);
    },

    isAffiliatesFilter() {
      if (
        !this.$store.getters['auth/adminAcl'].is_superuser
        && this.$store.getters['auth/currentAcl']
      ) {
        return (
          this.$store.getters['auth/currentAcl'].affiliates_view === 'allow'
        );
      }
      if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },

    isAffiliatesLiveFilter() {
      if (
        !this.$store.getters['auth/adminAcl'].is_superuser
        && this.$store.getters['auth/currentAcl']
      ) {
        return (
          this.$store.getters['auth/currentAcl'].reports_group_by_affiliates
          === 'allow'
        );
      }
      if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },

    isPlayerCountryFilter() {
      if (
        !this.$store.getters['auth/adminAcl'].is_superuser
        && this.$store.getters['auth/currentAcl']
      ) {
        return (
          this.$store.getters['auth/currentAcl'].reports_group_by_countries
          === 'allow'
        );
      }
      if (this.$store.getters['auth/adminAcl'].is_superuser) {
        return true;
      }
      return false;
    },
    availableFilters() {
      const fs = this.$_.filter(
        this.allFilters,
        filter => this.useFilters.indexOf(filter.type) !== -1,
      );
      return fs;
    },
    excludeFilters() {
      return this.$_.map(this.currentFilters, filter => filter.type);
    },

    ...mapGetters('reports', ['reportsSettings']),
    ...mapGetters('misc', [
      'countries',
      'domainRegistrarProviders',
      'dnsHostingProviders',
    ]),
  },
  watch: {
    isDisabled(value) {
      this.$emit('isDisableSelectFilter', value);
    },

    currentFilters: {
      deep: true,
      handler(v) {
        this.readyUpdate(v);
        this.handleDisabled(v);
      },
    },
  },

  created() {
    if (this.value.hasOwnProperty('max_profit_category')) {
      this.allFilters.profitCategory.field = 'max_profit_category';
    }

    let playerCountryType = '';

    if (this.value.hasOwnProperty('affiliate_id')) {
      affiliateType = 'affiliate';
    }
    if (this.value.hasOwnProperty('affiliate_last_bets_id')) {
      affiliateType = 'affiliateLastBets';
    }
    if (this.value.hasOwnProperty('affiliate_last_deposits_id')) {
      affiliateType = 'affiliateLastDeposits';
    }
    if (this.value.hasOwnProperty('affiliate_payments_report_id')) {
      affiliateType = 'affiliatePaymentsReport';
    }

    if (this.value.hasOwnProperty('player_country_code_last_bets')) {
      playerCountryType = 'playerCountryLastBets';
    }
    if (this.value.hasOwnProperty('player_country_code_last_deposits')) {
      playerCountryType = 'playerCountryLastDeposits';
    }
    if (this.value.hasOwnProperty('player_country_code_payments_report')) {
      playerCountryType = 'playerCountryPaymentsReport';
    }
    if (this.value.hasOwnProperty('country_code_payments_report')) {
      playerCountryType = 'countryPaymentsReport';
    }

    if (
      (this.value.hasOwnProperty('country_code_payments_report')
        && this.isPlayerCountryFilter)
      || (this.value.hasOwnProperty('player_country_code_last_bets')
        && this.isPlayerCountryFilter)
      || (this.value.hasOwnProperty('player_country_code_last_deposits')
        && this.isPlayerCountryFilter)
    ) {
      this.allFilters[playerCountryType].method('').then((response) => {
        this.allFilters[playerCountryType].options = response;
      });
    }
  },
  mounted() {
    if (!this.$_.isEmpty(this.value)) {
      this.transformValue();
    }
  },

  methods: {
    _readyUpdate(value) {
      const typeNotReady = val => val.every(
        ev => !this.$_.isEmpty(ev.values) && !this.$_.isEmpty(ev.type),
      );

      if (typeNotReady(value)) this.applyFilters();
    },

    async handlerInputMultiselect(block) {
      await this.readyUpdate(this.currentFilters);
      if (block) return;
      this.isDisabled = false;
    },

    handleDisabled(v) {
      this.isDisabled = false;
      if (v.length === 0) {
        this.isDisabled = false;
        return;
      }
      this.isDisabled = v.some(e => e.type === '' || e.values.length === 0);
    },

    inputSelect(v, index) {
      const noParse = ['trafficType', 'playerFilter'];
      if (v === 'lastActivity') {
        this.currentFilters.forEach((e, i) => {
          if (e.type === 'lastActivity') {
            this.currentFilters[i].values = [30, this.tab];
          }
        });
      }

      if (!noParse.includes(v)) {
        this.$set(this.currentFilters[index], 'op', '=');
      } else {
        this.$set(this.currentFilters[index], 'op', null);
      }
    },
    clone() {
      this.currentFilters = this.$_.clone(this.currentFilters);
    },
    getFilter(type) {
      const filter = this.allFilters[type];
      if (filter) {
        if (typeof filter.options === 'function') {
          filter.options = filter.options();
        }
        return filter;
      }
      return { multiple: true };
    },
    toggleFilters() {
      this.filtersOpen = !this.filtersOpen;
      if (this.filtersOpen === false) {
        setTimeout(() => {
          this.transformValue();
        }, 300);
      }

      // /* при открытии добавляем в фильтр дефортное поле пустых инпутов */
      // else if (this.filtersOpen && this.currentFilters.length === 0) {
      //   this.addFilter();
      // }
      this.$emit('toggle', this.filtersOpen);
    },

    addFilter() {
      this.currentFilters.push({
        type: '',
        values: [],
        op: null,
      });
    },

    removeFilter(i) {
      this.$delete(this.currentFilters, i);
    },
    applyFilters() {
      const val = {};
      this.$_.forEach(this.currentFilters, (filter) => {
        this.$_.assign(val, {
          [this.getFilter(filter.type).field]: {
            value: filter.values,
            op: filter.op,
          },
        });
      });
      this.$emit('input', val);
      this.toggleFilters();
    },
    resetFilters() {
      this.currentFilters = [];
      this.$emit('input', {});
      this.toggleFilters();
    },
    reset() {
      this.currentFilters = [];
      this.$emit('input', {});
    },

    transformValue() {
      let fs = [];
      fs = this.$_.map(Object.keys(this.value), key => ({
        type: this.$_.find(this.allFilters, f => f.field === key).type,
        values: this.value[key].value,
        op: this.value[key].op,
      }));
      if (!this.$_.isEqual(fs, this.currentFilters)) {
        this.currentFilters = fs;
      }
    },
  },
};
</script>

<style lang="scss">
.ui-filter-select {
  position: relative;

  .radio-operator {
    margin-left: 8px;
    .el-radio-button__inner {
      height: 32px;
      width: 23px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ui-select {
    > .el-loading-mask {
      .el-loading-spinner {
        margin-top: -10px;
        .circular {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  &__add-btn {
    border: none !important;
    background-color: transparent !important;
    padding: 0 !important;

    &.ui-button.green:hover {
      background-color: transparent !important;
    }
    &.ui-button.red:hover {
      background-color: transparent !important;
    }

    > span {
      font-weight: initial;
    }
  }

  .f-day {
    width: 230px;
    height: 34px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    .sufix {
      color: #334444;
    }
    &__tabs {
      width: 70px;
      min-width: 70px;
      height: 34px;
      border-radius: 4px;
      display: flex;
      margin-right: 8px;
      .select-class {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
    &__tab {
      height: 32px;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      background-color: #fff;
      cursor: pointer;
      &:first-child {
        border: solid 1px #d3d3d3;
        border-right-color: transparent;
        border-radius: 5px 0 0 5px;
      }
      &:last-child {
        border: solid 1px #d3d3d3;
        border-left-color: transparent;
        border-radius: 0 5px 5px 0;
      }
      &--active {
        cursor: pointer;
        color: #fff;
        &:first-child {
          border-right-color: transparent;
        }
        &:last-child {
          border-left-color: transparent;
        }
      }
    }
    &__input {
      width: 100% !important;
      max-width: 62px !important;
    }
    &__text {
      padding-left: 8px;
      font-size: 12px;
      color: #000000;
    }
  }
  .pop {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: transparent;
    border-radius: 8px;
    user-select: none;
    .ui-select.filter_type.medium {
      width: 100% !important;
      .tag .value {
        max-width: 181px !important;
      }
    }
    &.fade-enter-active,
    &.fade-leave-active {
      transition: all 0.4s;
    }
    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
      transform: translateY(8px);
    }
    .arrow {
      display: none;
      position: absolute;
      top: -5px;
      left: 56px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
    }
    .filter-row {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .filter_type {
        width: 160px;
        margin-left: 8px !important;
      }
      .filter_value {
        width: 280px;
      }
      .btn {
        margin-left: 0 !important;
        min-width: 30px;
      }
    }
    .controls {
      display: inline-flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      width: auto;
      margin-bottom: 0 !important;
      .btn {
        margin-left: 0 !important;
      }
      .btn + .btn {
        margin-left: 8px !important;
      }
    }
  }
}
</style>
