
export default {
  methods: {
    clearSegment(value) {
      if (value.type !== 'manual') {
        const segmentCopy = this.$_.cloneDeep(value);
        segmentCopy.rules_group.rules = segmentCopy.rules_group.rules
          .filter((group) => {
            group.rules = this.clearRules(group.rules);
            return group.rules.length !== 0;
          });
        return segmentCopy;
      }

      return value;
    },

    clearRules(rules) {
      return rules
        .filter(filter => filter.values !== '' && filter.values.length !== 0)
        .map(filter => ({
          ...filter,
          values: filter.rule_type === 'player_affiliate_id'
              || filter.rule_type === 'player_sub_affiliate_id'
            ? filter.values.map((item) => {
              if (item.id) return item.id;
              return item;
            })
            : filter.values,
        }));
    },
  },
};
