export default {
  reports: {
    name: 'Отчеты',
    menu: {
      daily: 'По дням',
      affiliate: 'Партнер',
      player: 'Игрок',
      revenue: 'Заработок',
      payments: 'Способы оплаты',
      custom: 'Пользовательский',
    },
    filters: {
      affiliate_id: 'Партнер',
      player_id: 'Игрок',
      country_code: 'Страна',
      player_country_code: 'Страна игрока',
      traffic_type: 'Тип трафика',
    },
    payments: {
      reset_filter: 'Сбросить фильтры',
      page: 'Стр.',
      page_of: 'из',
      payment_method_report: 'Отчет по способам оплаты',
    },
    other: {
      report: 'Отчет',
      drilldown: 'Детализация',
      exclude: 'Исключить',
      show_player: 'Показать игроков',
      selected_period: 'Сохранить выбранный период',
      open_payment: 'Открыть отчет об оплатах',
      home: 'Главная страница',
      copy_url: 'Скопировать ссылку отчета',
      copy_url2: 'Копировать URL',
      pin_report: 'Прикрепить отчет',
      add_bookmark: 'Добавить закладку',
      placeholder_name: 'Название закладки',
      close: 'Отменить',
      reset_to_defaults: 'Сброс к настройкам по умолчанию',
      close_all: 'Закрыть все',
      edit: 'Редактировать',
      confirm: 'Подтвердить',
      add_domains: 'Добавить Домены',
      save: 'Сохранить',
      delete: 'Удалить',
      remove_report: 'Удалить отчет',
      reset_default: 'Сбросить настройки по умолчанию',
      bookmark: 'Закладки',
      bookmark_edit: 'Редактировать закладку',
      not_found_bookmark: 'Закладок не найдено',
      all: 'Все',
      traffic: 'Трафик',
      player: 'Игроки',
      apply: 'Применить',
      reset: 'Сбросить',
      refresh: 'Обновить',
      start_date: 'Дата начала',
      end_date: 'Дата окончания',
      enter_date: 'Ввести дату',
      total: 'Общий',
      affiliates_created_at: 'Партнеры, созданные',
      continue_new_tab: 'Продолжить в новой вкладке',
      start_new_tab: 'Открыть новую вкладку',
      copied_to_clipboard: 'Скопировано в буфер обмена!',
      failed_to_copy: 'Не удалось скопировать ссылку',
      save_period: 'Сохранить с выбранным периодом',
      save_bookmark: 'Сохранить закладку',
      more: 'Еще {number}',
      share_report: 'Поделиться отчетом',
      copy: 'Скопировать',
      search_by: {
        title: 'Искать по',
        text: {
          site_player_id: 'Игроки',
          affiliate_email: 'Партнеры',
          sub_affiliate_email: 'Суб-партнёры',
          referrer_domain: 'Домены реферера',
        },
        placeholder: {
          site_player_id: 'Каждый ID должен вводиться с новой строки',
          affiliate_email: 'Каждый email должен вводиться с новой строки',
          sub_affiliate_email: 'Каждый email должен вводиться с новой строки',
          referrer_domain: 'Каждый домен должен вводиться с новой строки',
        },
        site_player_id: 'Игрокам',
        affiliate_email: 'Партнерам',
        sub_affiliate_email: 'Суб-партнерам',
        referrer_domain: 'Домену реферера',
      },
      filters_is_using: 'Вы уже используете другой поиск.',
    },
    date_picker: {
      last_7_day: 'Последние 7 дней',
      last_30_day: 'Последние 30 дней',
      this_month: 'Этот месяц',
      prev_month: 'Предыдущий месяц',
      last_90_day: 'Последние 90 дней',
      last_year: 'Последний год',
    },
    players_type: {
      all: 'Все',
      new: 'Новые',
      old: 'Старые',
    },
    traffic_type: {
      all: 'Все',
      affiliates: 'Партнеры',
      direct: 'Прямой',
    },
    search: {
      like: 'Совпадает',
      notLike: 'Не совпадает',
    },
    fields: {
      sport_casino: {
        title: 'Спорт / Казино',
        fullTitle: 'Спорт / Казино',
        filter: 'Спорт / Казино',
      },
      bet_game_id: {
        title: 'Игры',
        fullTitle: 'Игры',
        filter: 'Игры',
      },
      bet_category_id: {
        title: 'Категория',
        fullTitle: 'Категория',
        filter: 'Категория',
      },
      media_buying: {
        title: 'Медиабаинг',
        fullTitle: 'Медиабаинг',
        filter: 'Медиабаинг',
      },
      media_buying_channel_name: {
        title: 'Канал',
        fullTitle: 'Канал',
        filter: 'Канал',
      },
      media_buying_campaign_name: {
        title: 'Кампания',
        fullTitle: 'Кампания',
        filter: 'Кампания',
      },
      media_buying_channel_id: {
        title: 'МБ Канал',
        fullTitle: 'МБ Канал',
        filter: 'МБ Канал',
      },
      media_buying_campaign_id: {
        title: 'МБ Кампания',
        fullTitle: 'МБ Кампания',
        filter: 'МБ Кампания',
      },
      misc: {
        title: 'Прочее',
        fullTitle: 'Прочее',
        filter: 'Прочее',
      },
      landing_name: {
        title: 'Лендинг',
        fullTitle: 'Лендинг',
        filter: 'Лендинг',
      },
      landing_id: {
        title: 'Лендинг',
        fullTitle: 'Лендинг',
        filter: 'Лендинг',
      },
      marketing_material_name: {
        title: 'Медиа',
        fullTitle: 'Медиа',
        filter: 'Медиа',
      },
      marketing_material_id: {
        title: 'Медиа',
        fullTitle: 'Медиа',
        filter: 'Медиа',
      },
      payment_system_id: {
        title: 'Платежная система',
        filter: 'Платежная система',
        fullTitle: 'Платежная система',
      },
      country_code: {
        title: 'Страна',
        fullTitle: 'Страна',
        filter: 'Страна',
      },
      // Common
      affiliate_email: {
        title: 'Партнер',
        fullTitle: 'Партнер',
        filter: 'Партнер',
      },
      referred_affiliate_email: {
        title: 'Суб-партнер',
        fullTitle: 'Суб-партнер',
        filter: 'Суб-партнер',
      },
      referred_by_affiliate_id: {
        title: 'Суб-партнер',
        fullTitle: 'Суб-партнер',
        filter: 'Суб-партнер',
      },
      player_id: {
        title: 'Игрок',
        fullTitle: 'Игрок',
        filter: 'ID игрока',
      },
      site_player_id: {
        title: 'Игрок',
        fullTitle: 'Игрок',
        filter: 'ID Игрока',
      },
      affiliate_offer_name: {
        title: 'Оффер',
        fullTitle: 'Оффер',
        filter: 'Оффер',
      },
      affiliate_offer_settings_name: {
        title: 'Настройки оффера',
        fullTitle: 'Настройки оффера',
        filter: 'Настройки оффера',
      },
      offer_type_name: {
        title: 'Тип оффера',
        fullTitle: 'Тип оффера',
        filter: 'Тип оффера',
      },
      site_name: {
        title: 'Бренд',
        fullTitle: 'Бренд',
        filter: 'Бренд',
      },
      site_id: {
        title: 'Бренд',
        fullTitle: 'Бренд',
        filter: 'Бренд',
      },
      offer_type: {
        title: 'Тип оффера',
        fullTitle: 'Тип оффера',
        filter: 'Тип оффера',
      },
      affiliate_offer_settings_id: {
        title: 'Настройки оффера',
        fullTitle: 'Настройки оффера',
        filter: 'Настройки оффера',
      },
      traffic_source_name: {
        title: 'Источник трафика',
        fullTitle: 'Источник трафика',
        filter: 'Источник трафика',
      },
      bet_category_name: {
        title: 'Категория',
        fullTitle: 'Категория',
        filter: 'Категория',
      },
      bet_game_name: {
        title: 'Игры',
        fullTitle: 'Игры',
        filter: 'Игры',
      },
      payment_system_name: {
        title: 'Платежная система',
        fullTitle: 'Платежная система',
        filter: 'Платежная система',
      },
      site_registration_type_id: {
        title: 'Тип регистрации',
        fullTitle: 'Тип регистрации',
        filter: 'Тип регистрации',
      },
      site_registration_type_name: {
        title: 'Тип регистрации',
        fullTitle: 'Тип регистрации',
        filter: 'Тип регистрации',
      },
      affiliate_player_promo_code_name: {
        title: 'Промокод',
        fullTitle: 'Промокод',
        filter: 'Промокод',
      },
      language: {
        title: 'Язык',
        fullTitle: 'Язык',
        filter: 'Язык',
      },
      // Date & Time
      date_and_time: {
        title: 'Дата',
        fullTitle: 'Дата & Время',
        filter: 'Дата',
      },
      date: {
        title: 'Дата',
        fullTitle: 'Дата',
        filter: 'Дата',
      },
      date_formatted: {
        title: 'Дата',
        fullTitle: 'Дата',
        filter: 'Дата',
      },
      year: {
        title: 'Год',
        fullTitle: 'Год',
        filter: 'Год',
      },
      month: {
        title: 'Месяц',
        fullTitle: 'Месяц',
        filter: 'Месяц',
      },
      day_of_month: {
        title: 'День',
        fullTitle: 'День месяца',
        filter: 'День месяца',
        tip: 'месяца',
      },
      day_of_week: {
        title: 'День',
        fullTitle: 'День недели',
        filter: 'День недели',
        tip: 'недели',
      },
      hour: {
        title: 'Час',
        fullTitle: 'Час',
        filter: 'Час',
      },
      minute: {
        title: 'Минута',
        fullTitle: 'Минута',
        filter: 'Минута',
      },
      start_of_year: {
        title: 'Начало года',
        fullTitle: 'Начало года',
        filter: 'Начало года',
      },
      start_of_quarter: {
        title: 'Начало квартала',
        fullTitle: 'Начало квартала',
        filter: 'Начало квартала',
      },
      start_of_month: {
        title: 'Месяц',
        fullTitle: 'Месяц',
        filter: 'Месяц',
      },
      start_of_week: {
        title: 'Неделя',
        fullTitle: 'Неделя',
        filter: 'Неделя',
      },
      start_of_minute: {
        title: 'Начало минуты',
        fullTitle: 'Начало минуты',
        filter: 'Начало минуты',
      },
      // Referrer
      referrer: {
        title: 'Реферер',
        fullTitle: 'Реферер',
        filter: 'Реферер',
      },
      referrer_domain: {
        title: 'Домен реферера',
        fullTitle: 'Домен реферера',
        filter: 'Домен реферера',
      },
      // GEO
      geo: {
        title: 'Трафик по гео',
        fullTitle: 'Трафик по гео',
        // filter: 'Country',
      },
      region_name: {
        title: 'Регион',
        fullTitle: 'Регион',
        filter: 'Регион',
      },
      country_name: {
        title: 'Страна',
        fullTitle: 'Страна',
        filter: 'Страна',
      },
      player_country_name: {
        title: 'Страна игрока',
        fullTitle: 'Страна игрока',
        filter: 'Страна игрока',
      },
      player_region_name: {
        title: 'Регион игрока',
        fullTitle: 'Регион игрока',
        filter: 'Регион игрока',
      },
      player_country_code: {
        title: 'Страна игрока',
        fullTitle: 'Страна игрока',
        filter: 'Страна игрока',
      },
      state_name: {
        title: 'Область',
        fullTitle: 'Область',
        filter: 'Область',
      },
      city_name: {
        title: 'Город',
        fullTitle: 'Город',
        filter: 'Город',
      },
      // Device
      device: {
        title: 'Устройство',
        fullTitle: 'Устройство',
        // filter: 'Вид устройства',
      },
      device_type: {
        title: 'Вид устройства',
        fullTitle: 'Вид устройства',
        filter: 'Вид устройства',
      },
      device_type_id: {
        title: 'Вид устройства',
        fullTitle: 'Вид устройства',
        filter: 'Вид устройства',
      },
      device_vendor: {
        title: 'Производитель устройства',
        fullTitle: 'Производитель устройства',
        filter: 'Производитель устройства',
      },
      device_model: {
        title: 'Модель устройства',
        fullTitle: 'Модель устройства',
        filter: 'Модель устройства',
      },
      // Browser
      browser: {
        title: 'Браузер',
        fullTitle: 'Браузер',
        filter: 'Браузер',
      },
      browser_version: {
        title: 'Версия браузера',
        fullTitle: 'Версия браузера',
        filter: 'Версия браузера',
      },
      // OS
      os: {
        title: 'ОС',
        fullTitle: 'ОС',
        filter: 'ОС',
      },
      os_version: {
        title: 'Версия ОС',
        fullTitle: 'Версия ОС',
        filter: 'Версия ОС',
      },
      // ISP
      isp: {
        title: 'ИП',
        fullTitle: 'Интернет-провайдер',
        filter: 'Интернет-провайдер',
      },
      isp_id: {
        title: 'Интернет-провайдер',
        fullTitle: 'Интернет-провайдер',
        filter: 'Интернет-провайдер',
      },
      connection_type: {
        title: 'Тип соединения',
        fullTitle: 'Тип соединения',
        filter: 'Тип соединения',
      },
      connection_type_id: {
        title: 'Тип соединения',
        fullTitle: 'Тип соединения',
        filter: 'Тип соединения',
      },
      // Subs
      subs: {
        title: 'SubID',
        fullTitle: 'SubID',
        // filter: 'SubID 1',
      },
      subid1: {
        title: 'SubID 1',
        fullTitle: 'SubID 1',
        filter: 'SubID 1',
        description: 'SubID 1',
      },
      subid2: {
        title: 'SubID 2',
        fullTitle: 'SubID 2',
        filter: 'SubID 2',
        description: 'SubID 2',
      },
      subid3: {
        title: 'SubID 3',
        fullTitle: 'SubID 3',
        filter: 'SubID 3',
        description: 'SubID 3',
      },
      subid4: {
        title: 'SubID 4',
        fullTitle: 'SubID 4',
        filter: 'SubID 4',
        description: 'SubID 4',
      },
      subid5: {
        title: 'SubID 5',
        fullTitle: 'SubID 5',
        filter: 'SubID 5',
        description: 'SubID 5',
      },
      subid6: {
        title: 'SubID 6',
        fullTitle: 'SubID 6',
        filter: 'SubID 6',
      },
      subid7: {
        title: 'SubID 7',
        fullTitle: 'SubID 7',
        filter: 'SubID 7',
      },
      subid8: {
        title: 'SubID 8',
        fullTitle: 'SubID 8',
        filter: 'SubID 8',
      },
      subid9: {
        title: 'SubID 9',
        fullTitle: 'SubID 9',
        filter: 'SubID 9',
      },
      subid10: {
        title: 'SubID 10',
        fullTitle: 'SubID 10',
        filter: 'SubID 10',
      },
      external_id: {
        title: 'External ID',
        fullTitle: 'External ID',
        filter: 'External ID',
        description: 'External ID',
      },
      // Filters
      affiliate_id: {
        title: 'ID партнера',
        fullTitle: 'ID партнера',
        filter: 'Партнер',
      },
      affiliate_offer_id: {
        title: 'ID оффера',
        fullTitle: 'ID оффера',
        filter: 'Оффер',
      },
      traffic_source_id: {
        title: 'ID источника трафика',
        fullTitle: 'ID источника трафика',
        filter: 'Источник трафика',
      },
      affiliate_player_promo_code_id: {
        title: 'ID Промокода',
        fullTitle: 'ID Промокода',
        filter: 'Промокод',
      },
      language_code: {
        title: 'Код языка',
        fullTitle: 'Код языка',
        filter: 'Код языка',
      },
      // Columns
      impressions_count: {
        title: 'Показы',
        description: 'Показы по промо материалам',
      },
      impressions_uniq_count: {
        title: 'Показы (уник.)',
        description: 'Уникальные показы по промо материалам',
      },
      visits_count: {
        title: 'Посещения',
        description: 'Количество посещений',
      },
      visits_uniq_count: {
        title: 'Посещения (уник)',
        description: 'Количество уникальных посещений',
      },
      registrations_count: {
        title: 'Регистрации',
        description: 'Количество регистраций',
      },
      visits_per_impression_percent: {
        title: 'CTR',
        description: '% посещений/показов',
      },
      registrations_per_visit_percent: {
        title: 'RPV',
        description: '% регистраций/посещений',
      },
      deposits_first_per_registration_percent: {
        title: 'FDPR',
        description: '% первых депозитов/регистраций',
      },
      deposits_first_per_visit_percent: {
        title: 'FDPV',
        description: '% First Deposits/Visits',
      },
      deposits_first_count: {
        title: 'Первые депозиты',
        description: 'Количество первых депозитов',
      },
      deposits_other_count: {
        title: 'Повторные депозиты',
        description: 'Количество повторных депозитов',
      },
      deposits_all_count: {
        title: 'Депозиты',
        description: 'Количество всех депозитов',
      },
      deposits_withdrawals_count: {
        title: 'Выводы',
        description: 'Количество выводов',
      },
      deposits_first_sum: {
        title: 'Первые депозиты',
        description: 'Сумма первых депозитов',
      },
      deposits_other_sum: {
        title: 'Повторные депозиты, $',
        description: 'Сумма повторных депозитов',
      },
      deposits_all_sum: {
        title: 'Депозиты',
        description: 'Сумма депозитов',
      },
      deposits_withdrawals_sum: {
        title: 'Выводы',
        description: 'Сумма выводов',
      },
      players_with_first_deposit_count: {
        title: 'Игроки с первым депозитом',
        description: 'Количество регистраций с первым депозитом',
      },
      players_with_deposit_count: {
        title: 'Игроки с депозитами',
        description: 'Количество игроков с депозитами',
      },
      re_deposits_percent: {
        title: 'Повторные депозиты, %',
        description: '% повторных депозитов',
      },
      median_player_profit_category: {
        title: 'Медианная категория игрока',
        description: 'Медианная категория профита игрока',
      },
      avg_player_profit_category: {
        title: 'Средняя категория игрока',
        description: 'Средняя категория профита игрока',
      },
      avg_bet_odd: {
        title: 'Средний коэффициент',
        description: 'Средний коэффициент на спортивные ставки',
      },
      median_bet_odd: {
        title: 'Медианный коэффициент',
        description: 'Медианный коэффициент на спортивные ставки',
      },
      bet_new_count: {
        title: 'Ставки',
        description: 'Количество ставок',
      },
      bet_new_sports_count: {
        title: 'Ставки на спорт',
        description: 'Количество ставок на спорт',
      },
      bet_new_games_count: {
        title: 'Ставки на казино',
        description: 'Количество ставок на казино/слоты/и т.д.',
      },
      bet_result_count: {
        title: 'Оплаченные ставки',
        description: 'Количество оплаченных ставок',
      },
      bet_result_sports_count: {
        title: 'Оплаченные ставки на спорт',
        description: 'Количество оплаченных ставок на спорт',
      },
      bet_result_games_count: {
        title: 'Оплаченные ставки на казино',
        description: 'Количество оплаченных ставок на казино',
      },
      bet_sell_count: {
        title: 'Проданные ставки',
        description: 'Количество проданных ставок',
      },
      bet_insurance_count: {
        title: 'Застрахованные ставки',
        description: 'Количество купленных страховок',
      },
      bet_insurance_result_count: {
        title: 'Страховые выплаты',
        description: 'Количество страховых выплат',
      },
      bet_new_sum: {
        title: 'Ставки, $',
        description: 'Сумма ставок',
      },
      bet_new_sports_sum: {
        title: 'Ставки на спорт, $',
        description: 'Сумма ставок на спорт',
      },
      bet_new_games_sum: {
        title: 'Ставки на казино, $',
        description: 'Сумма ставок на казино',
      },
      bet_result_sum: {
        title: 'Сумма выигрыша, $',
        description: 'Сумма выигрышей',
      },
      bet_result_sports_sum: {
        title: 'Сумма выигрыша по ставкам на спорт, $',
        description: 'Сумма выигрышей по ставкам на спорт',
      },
      bet_result_games_sum: {
        title: 'Сумма выигрыша по ставкам на казино, $',
        description: 'Сумма выигрышей по ставкам на казино',
      },
      bet_sell_profit_sum: {
        title: 'Прибыль с проданных ставок, $',
        description: 'Прибыль с проданных ставок',
      },
      bet_insurance_sum: {
        title: 'Стоимость страховки, $',
        description: 'Сумма стоимости страховок',
      },
      bet_insurance_result_sum: {
        title: 'Страховые выплаты, $',
        description: 'Сумма страховой выплаты',
      },
      players_with_bet_count: {
        title: 'Игроки со ставками',
        description: 'Количество игроков со ставками',
      },
      profit_sum: {
        title: 'Прибыль, $',
        description: 'Сумма прибыли',
      },
      revenue_sum: {
        title: 'Заработок, $',
        description: 'Сумма заработка',
      },
      affiliate_program_profit_sum: {
        title: 'Общий заработок, $',
        description: 'Общий заработок',
      },
      deposits_first_avg: {
        title: 'Средний ПД, $',
        description: 'Средняя сумма первых депозитов',
      },
      deposits_other_avg: {
        title: 'Средний повторный депозит, $',
        description: 'Средняя сумма повторных депозитов',
      },
      deposits_all_avg: {
        title: 'Средний депозит, $',
        description: 'Средний депозит',
      },
      deposits_first_median: {
        title: 'Медианный ПД, $',
        description: 'Медианная сумма первых депозитов',
      },
      deposits_other_median: {
        title: 'Медианный повторный депозит, $',
        description: 'Медианная сумма повторных депозитов',
      },
      deposits_all_median: {
        title: 'Медианный депозит, $',
        description: 'Медианный депозит',
      },
      bet_result_profit_sum: {
        title: 'Прибыль со ставок, $',
        description: 'Прибыль со всех ставок',
      },
      bet_result_sports_profit_sum: {
        title: 'Прибыль со ставок на спорт, $',
        description: 'Прибыль со ставок на спорт',
      },
      bet_result_games_profit_sum: {
        title: 'Прибыль со ставок на казино, $',
        description: 'Прибыль со ставок на казино',
      },
      deposit_bonus_sum: {
        title: 'Бонусы за депозит, $',
        description: 'Сумма бонусов за депозит',
      },
      deposit_bonus_count: {
        title: 'Бонусы за депозит',
        description: 'Количество бонусов за депозит',
      },
      players_uniq_count: {
        title: 'Активные игроки',
        description: 'Количество игроков, совершивших какое-либо действие',
      },
      affiliates_uniq_count: {
        title: 'Активные партнеры',
        description: 'Количество партнеров с игроками, совершившими какое-либо действие',
      },
      median_bet_amount: {
        title: 'Медианная ставка, $',
        description: 'Медианная ставка',
      },
      avg_bet_amount: {
        title: 'Средняя ставка, $',
        description: 'Средняя ставка',
      },
      avg_sports_bet_amount: {
        title: 'Средняя ставка на спорт, $',
        description: 'Средняя ставка на спорт',
      },
      median_sports_bet_amount: {
        title: 'Медианная ставка на спорт, $',
        description: 'Медианная ставка на спорт',
      },
      avg_games_bet_amount: {
        title: 'Средняя ставка на спорт, $',
        description: 'Средняя ставка на спорт',
      },
      median_games_bet_amount: {
        title: 'Медианная ставка на спорт, $',
        description: 'Медианная ставка на спорт',
      },
      chargebacks_count: {
        title: 'Списание',
        description: 'Общее количество списаний',
      },
      chargebacks_sum: {
        title: 'Списания, $',
        description: 'Сумма списаний',
      },
      chargebacks_players_count: {
        title: 'Игроки, по которым было списание',
        description: 'Общее количество игроков, по которым было списание',
      },
      deposits_first_cpa_count: {
        title: 'Первый депозит (CPA)',
        description: 'Количество первых депозитов (правило оплаты CPA)',
      },
      deposits_first_revshare_count: {
        title: 'Первый депозит (RS)',
        description: 'Количество первых депозитов (правило оплаты RevShare)',
      },
      deposits_first_no_revenue_count: {
        title: 'Первые депозиты (N/A)',
        description: 'Количество первых депозитов (без правила оплаты)',
      },
      deposits_first_cpa_sum: {
        title: 'Первые депозиты (CPA), $',
        description: 'Сумма первых депозитов (правило оплаты CPA)',
      },
      deposits_first_revshare_sum: {
        title: 'Первые депозиты (RS), $',
        description: 'Сумма первых депозитов (правило оплаты RevShare)',
      },
      deposits_first_no_revenue_sum: {
        title: 'Первые депозиты (N/A), $',
        description: 'Сумма первых депозитов (без правила оплаты)',
      },
      deposits_other_cpa_count: {
        title: 'Повторные депозиты (CPA)',
        description: 'Количество повторных депозитов (правило оплаты CPA)',
      },
      deposits_other_revshare_count: {
        title: 'Повторные депозиты  (RS)',
        description: 'Количество повторных депозито (правило оплаты RevShare)',
      },
      deposits_other_no_revenue_count: {
        title: 'Повторные депозиты (N/A)',
        description: 'Количество повторных депозито (без правила оплаты)',
      },
      deposits_other_cpa_sum: {
        title: 'Повторные депозиты (CPA), $',
        description: 'Сумма повторных депозитов (правило оплаты CPA)',
      },
      deposits_other_revshare_sum: {
        title: 'Повторные депозиты (RS), $',
        description: 'Сумма повторных депозитов (правило оплаты RevShare)',
      },
      deposits_other_no_revenue_sum: {
        title: 'Повторные депозиты (N/A), $',
        description: 'Сумма повторных депозитов (без правила оплаты)',
      },
      deposits_all_cpa_count: {
        title: 'Депозиты (CPA)',
        description: 'Сумма всех депозитов (правило оплаты CPA)',
      },
      deposits_all_revshare_count: {
        title: 'Депозиты (RS)',
        description: 'Количество всех депозитов (правило оплаты RevShare)',
      },
      deposits_all_no_revenue_count: {
        title: 'Депозиты (N/A)',
        description: 'Количество всех депозитов (без правила оплаты)',
      },
      deposits_all_cpa_sum: {
        title: 'Депозиты (CPA), $',
        description: 'Сумма депозитов (правило оплаты CPA)',
      },
      deposits_all_revshare_sum: {
        title: 'Депозиты (RS), $',
        description: 'Сумма депозитов (правило оплаты RevShare)',
      },
      deposits_all_no_revenue_sum: {
        title: 'Депозиты (N/A), $',
        description: 'Сумма депозитов (без правила оплаты)',
      },
      deposits_withdrawals_cpa_count: {
        title: 'Выводы (CPA)',
        description: 'Количество выводов (правило оплаты CPA)',
      },
      deposits_withdrawals_revshare_count: {
        title: 'Выводы (RS)',
        description: 'Количество выводов (правило оплаты RevShare)',
      },
      deposits_withdrawals_no_revenue_count: {
        title: 'Выводы (N/A)',
        description: 'Количество выводов (без правила оплаты)',
      },
      deposits_withdrawals_cpa_sum: {
        title: 'Выводы (CPA), $',
        description: 'Withdrawals Sum (правило оплаты CPA)',
      },
      deposits_withdrawals_no_revenue_sum: {
        title: 'Выводы (N/A), $',
        description: 'Сумма выводов (без правила оплаты)',
      },
      deposits_withdrawals_revshare_sum: {
        title: 'Выводы (RS), $',
        description: 'Сумма выводов (правило оплаты RevShare)',
      },
      deposits_flow_sum: {
        title: 'Оборот, $',
        description: 'Сумма депозитов и выводов',
      },
      bet_result_cpa_sum: {
        title: 'Сумма выигрыша (CPA), $',
        description: 'Сумма выигрышей (правило оплаты CPA)',
      },
      bet_result_no_revenue_sum: {
        title: 'Сумма выигрыша (N/A), $',
        description: 'Сумма выигрышей (без правила оплаты)',
      },
      bet_result_revshare_sum: {
        title: 'Сумма выигрыша (RS), $',
        description: 'Сумма выигрышей (правило оплаты RevShare)',
      },
      bet_result_sports_cpa_sum: {
        title: 'Сумма выигрыша по ставкам на спорт (CPA), $',
        description: 'Сумма выигрышей по ставкам на спорт (правило оплаты CPA)',
      },
      bet_result_sports_no_revenue_sum: {
        title: 'Сумма выигрыша по ставкам на спорт (N/A), $',
        description: 'Сумма выигрышей по ставкам на спорт (без правила оплаты)',
      },

      bet_result_sports_revshare_sum: {
        title: 'Сумма выигрыша по ставкам на спорт (RS), $',
        description: 'Сумма выигрышей по ставкам на спорт (правило оплаты RevShare)',
      },
      bet_result_games_cpa_sum: {
        title: 'Сумма выигрыша по ставкам на казино (CPA), $',
        description: 'Сумма выигрышей по ставкам на казино (правило оплаты CPA)',
      },
      bet_result_games_revshare_sum: {
        title: 'Сумма выигрыша по ставкам на казино (RS), $',
        description: 'Сумма выигрышей по ставкам на казино (правило оплаты RevShare)',
      },
      bet_result_games_no_revenue_sum: {
        title: 'Сумма выигрыша по ставкам на казино (N/A), $',
        description: 'Сумма выигрышей по ставкам на казино (без правила оплаты)',
      },
      bet_result_sports_profit_cpa_sum: {
        title: 'Прибыль со ставок на спорт (CPA), $',
        description: 'Прибыль со ставок на спорт (правило оплаты CPA)',
      },
      bet_result_sports_profit_revshare_sum: {
        title: 'Прибыль со ставок на спорт (RS), $',
        description: 'Прибыль со ставок на спорт (правило оплаты RevShare)',
      },
      bet_result_sports_profit_no_revenue_sum: {
        title: 'Прибыль со ставок на спорт (N/A), $',
        description: 'Прибыль со ставок на спорт (без правила оплаты)',
      },
      bet_result_games_profit_cpa_sum: {
        title: 'Прибыль со ставок на казино (CPA), $',
        description: 'Прибыль со ставок на казино (правило оплаты CPA)',
      },
      bet_result_games_profit_revshare_sum: {
        title: 'Прибыль со ставок на казино (RS), $',
        description: 'Прибыль со ставок на казино (правило оплаты RevShare)',
      },
      bet_result_games_profit_no_revenue_sum: {
        title: 'Прибыль со ставок на казино (N/A), $',
        description: 'Прибыль со ставок на казино (без правила оплаты)',
      },
      bet_result_profit_cpa_sum: {
        title: 'Прибыль со ставок (CPA), $',
        description: 'Прибыль со всех ставок (правило оплаты CPA)',
      },
      bet_result_profit_revshare_sum: {
        title: 'Прибыль со ставок(RS), $',
        description: 'Прибыль со всех ставок (правило оплаты RevShare)',
      },
      bet_result_profit_no_revenue_sum: {
        title: 'Прибыль со ставок (N/A), $',
        description: 'Прибыль со всех ставок (без правила оплаты)',
      },
      bet_sell_profit_cpa_sum: {
        title: 'Прибыль с проданных ставок (CPA), $',
        description: 'Прибыль с проданных ставок (правило оплаты CPA)',
      },
      bet_sell_profit_revshare_sum: {
        title: 'Прибыль с проданных ставок (RS), $',
        description: 'Прибыль с проданных ставок (правило оплаты RevShare)',
      },
      bet_sell_profit_no_revenue_sum: {
        title: 'Прибыль с проданных ставок (N/A), $',
        description: 'Прибыль с проданных ставок (без правила оплаты)',
      },
      bet_insurance_result_cpa_sum: {
        title: 'Страховые выплаты (CPA), $',
        description: 'Сумма страховой выплаты (правило оплаты CPA)',
      },
      bet_insurance_result_revshare_sum: {
        title: 'Страховые выплаты (RS), $',
        description: 'Сумма страховой выплаты (правило оплаты RevShare)',
      },
      bet_insurance_result_no_revenue_sum: {
        title: 'Страховые выплаты (N/A), $',
        description: 'Сумма страховой выплаты (без правила оплаты)',
      },
      profit_cpa_sum: {
        title: 'Прибыль (CPA), $',
        description: 'Сумма прибыли (правило оплаты CPA)',
      },
      profit_revshare_sum: {
        title: 'Прибыль (RS), $',
        description: 'Сумма прибыли (правило оплаты RevShare)',
      },
      profit_no_revenue_sum: {
        title: 'Прибыль (N/A), $',
        description: 'Сумма прибыли (без правила оплаты)',
      },
      new_players_profit_cpa_sum: {
        title: 'Прибыль за новых игроков (CPA), $',
        description: 'Сумма прибыли за новых игроков (правило оплаты CPA)',
      },
      new_players_profit_revshare_sum: {
        title: 'Прибыль за новых игроков (RS), $',
        description: 'Сумма прибыли за новых игроков (правило оплаты RevShare)',
      },
      new_players_profit_no_revenue_sum: {
        title: 'Прибыль за новых игроков (N/A), $',
        description: 'Сумма прибыли за новых игроков (без правила оплаты)',
      },
      revenue_cpa_sum: {
        title: 'Заработок (CPA), $',
        description: 'Сумма заработка (правило оплаты CPA)',
      },
      revenue_revshare_sum: {
        title: 'Заработок (RS), $',
        description: 'Сумма заработка (правило оплаты RevShare)',
      },
      revenue_no_revenue_sum: {
        title: 'Заработок (N/A), $',
        description: 'Сумма заработка (без правила оплаты)',
      },
      affiliate_program_profit_cpa_sum: {
        title: 'Общий заработок (CPA), $',
        description: 'Общий заработок (правило оплаты CPA)',
      },
      affiliate_program_profit_revshare_sum: {
        title: 'Общий заработок (RS), $',
        description: 'Общий заработок (правило оплаты RevShare)',
      },
      new_players_revenue_sum: {
        title: 'Заработок за новых игроков, $',
        description: 'Сумма заработка за новых игроков',
      },
      new_players_revenue_cpa_sum: {
        title: 'Заработок за новых игроков (CPA), $',
        description: 'Сумма заработка за новых игроков (правило оплаты CPA)',
      },
      new_players_revenue_revshare_sum: {
        title: 'Заработок за новых игроков (RS), $',
        description: 'Сумма заработка за новых игроков (правило оплаты RevShare)',
      },
      new_players_revenue_no_revenue_sum: {
        title: 'Заработок за новых игроков (N/A), $',
        description: 'Сумма заработка за новых игроков (без правила оплаты)',
      },
      new_players_chargebacks_count: {
        title: 'Списания за новых игроков',
        description: 'Количество списаний за новых игроков',
      },
      new_players_chargebacks_sum: {
        title: 'Списания за новых игроков, $',
        description: 'Сумма списания за новых игроков',
      },
      new_players_affiliate_program_profit_cpa_sum: {
        title: 'Общий заработок по новым игрокам (CPA), $',
        description: 'Общий заработок по новым игрокам (правило оплаты CPA)',
      },
      new_players_affiliate_program_profit_revshare_sum: {
        title: 'Общий заработок за новых игроков (RS), $',
        description: 'Общий заработок за новых игроков (правило оплаты RevShare)',
      },
      players_avg_lifetime_seconds: {
        title: 'Среднее время жизни игрока',
        description: 'Среднее время жизни игрока',
      },
      players_median_lifetime_seconds: {
        title: 'Медианное время жизни игрока',
        description: 'Среднее время жизни игрока',
      },
      players_avg_fd_since_registration_seconds: {
        title: 'Среднее время совершения ПД',
        description: 'Среднее время между регистрацией и первым депозитом',
      },
      players_median_fd_since_registration_seconds: {
        title: 'Медианное время совершения ПД',
        description: 'Медианное время между регистрацией и первым депозитом',
      },
      players_avg_deposits_sum: {
        title: 'Средняя сумма депозита',
        description: 'Средняя сумма депозита игрока',
      },
      players_avg_deposits_count: {
        title: 'Среднее количество депозитов',
        description: 'Среднее количество депозитов игрока',
      },
      players_avg_withdrawals_sum: {
        title: 'Средняя сумма вывода',
        description: 'Средняя сумма вывода игроков',
      },
      players_avg_withdrawals_count: {
        title: 'Среднее количество выводов',
        description: 'Среднее количество выводов игроков',
      },
      players_avg_profit_sum: {
        title: 'Средняя прибыль по игрокам',
        description: 'Средняя сумма прибыли по игрокам',
      },
      players_median_profit_sum: {
        title: 'Медианная прибыль по игрокам',
        description: 'Медианная сумма прибыли по игрокам',
      },
      affiliate_payments_sum: {
        title: 'Выплаты, $',
        description: 'Сумма партнерской выплаты',
      },
      affiliate_payments_count: {
        title: 'Количество выплат',
        description: 'Количество партнерских выплат',
      },
      affiliate_payments_profit_percent: {
        title: 'Прибыль по платежам, %',
        description: 'Процент прибыли по партнерским платежам',
      },
      affiliate_program_profit_no_revenue_sum: {
        title: 'Общий заработок (N/A), $',
        description: 'Общий заработок (без правила оплаты)',
      },
      new_players_affiliate_program_profit_no_revenue_sum: {
        title: 'Общий заработок по новым игрокам (N/A), $',
        description: 'Общий заработок по новым игрокам (без правила оплаты)',
      },
      deposits_withdrawals_diff_sum: {
        title: 'Разница депозиты/выводы, $',
        description: 'Разница между депозитами и выводами',
      },
      name: {
        title: 'Имя',
        description: 'Имя игрока',
      },
      email: {
        title: 'Email',
        description: 'Email игрока',
      },
      phone_number: {
        title: 'Контактный номер',
        description: 'Контактный номер игрока',
      },
      first_name: {
        title: 'Имя',
        description: 'Имя игрока',
      },
      last_name: {
        title: 'Фамилия',
        description: 'Фамилия игрока',
      },
      new_players_profit_sum: {
        title: 'Прибыль по новым игрокам, $',
        description: 'Сумма прибыли по новым игрокам',
      },
      new_players_affiliate_program_profit_sum: {
        title: 'Заработок по новым игрокам, $',
        description: 'Сумма заработка по новым игрокам',
      },
      affiliate_created_at: {
        title: 'Партнер создан',
        description: 'Дата регистрации партнера',
      },
      referred_affiliate_created_at: {
        title: 'Суб-партнер создан',
        description: 'Дата регистрации суб-партнера',
      },
      player_created_at: {
        title: 'Игрок создан',
        description: 'Дата регистрации игрока',
      },
    },
    colunms_settings: {
      header: 'Настройка колонок',
      title_visible: 'Отображение колонок',
      title_order: 'Порядок колонок',
      select_all: 'Выбрать все',
      select_match: 'Выбрать соответствующие',
      placeholder_search: 'Поиск…',
      show_only_select: 'Показывать только выбранные',
      no_data: 'Нет данных',
      engagement: 'Активность пользователя',
      deposits: 'Депозиты',
      bets: 'Ставки',
      other: 'Другое',
      revenue: 'Заработок',
      colunms: 'Колонки',
      hide: 'Скрыть',
      show: 'Показать',
      vis_column: 'Видимые колонки',
      additional: 'Дополнительный',
    },
    time_days: 'дн.',
  },
};
