<template>
  <div class="add-user-in-team">
    <div class="popup">
      <div class="head">
        <span class="name">{{ this.$t(`team_popup.add_user_in_teams_title`, {name: userInfo.name}) }}</span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <div class="wrap-control">
        <ui-input
          v-model.trim="searchQuery"
          class="search-popup"
          is-search
          autosize
          @search="getTeams"
        />
      </div>
      <ui-table
        ref="tablePopup"
        v-loading="isDataLoading"
        :select-row="true"
        :fields="fields"
        :data="tableData"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        i18n-path="team_users.list"
        checked-row
        :show-check-all="false"
        select-type="radio"
        checked-row-id="id"
        element-loading-custom-class="data-loader-spinner"
        class="table"
        @sort="handleSort"
        @checkedRows="checkedRows"
      />
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          @click.native="close"
        >
          {{ $t(`team_popup.cancel_button`) }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="!team_id"
          filled
          @click="save"
        >
          <i class="fas fa-plus plus-icon-custom" />{{ $t(`team_popup.add_team_button`) }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>

import app from '@/main';

export default {
  name: 'AddUserInTeams',

  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },


  data() {
    return {
      isDataLoading: true,
      searchQueryPopup: '',
      team_id: null,
      teams: {},
      errors: {},
      searchQuery: '',
      options: {
        search: '',
        sort_column: '',
        sort_dir: '',
      },
      sortProp: 'name',
      sortOrder: 'asc',
      fields: [
        {
          name: 'name',
          sortable: 'custom',
        },
        {
          name: 'members_count',
          sortable: 'custom',
        },
      ],
    };
  },
  computed: {
    tableData: {
      get() {
        return this.$_.differenceBy(this.teams, this.userInfo.teams, 'id');
      },
      set(newVal) {
        this.teams = newVal;
      },
    },
  },

  watch: {
    searchQuery() {
      this.options.search = this.searchQuery;
    },
    isOpen(v) {
      if (v) {
        this.getTeams();
      }
    },
  },

  methods: {
    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.getTeams();
    },
    _query() {
      this.options.sort_column = this.sortProp;
      this.options.sort_dir = this.sortOrder;
      const _paramsFilter = {
        ...this.options,
      };
      return _paramsFilter;
    },

    getTeams() {
      this.errors = {};
      this.$api.getTeamsList(this._query()).then((response) => {
        this.teams = response.data.payload;
      })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        })
        .finally(() => {
          setTimeout(() => {
            this.isDataLoading = false;
          }, 500);
        });
    },

    checkedRows(id) {
      this.team_id = this.team_id !== id ? id : null;
    },

    close() {
      this.searchQuery = '';
      this.team_id = null;
      this.isDataLoading = true;
      app.$eventBus.$emit('clear-check');
      this.$emit('close');
    },
    save() {
      this.$api
        .postAttachToTeam(this.userInfo.id, this.team_id)
        .then(() => {
          this.$emit('refresh');
          app.$eventBus.$emit('clear-check');
          this.close();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
  },
};
</script>
