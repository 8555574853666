export default {
  loginView: {

    auth: {
      title: 'Login to Your Account',
      email: {
        label: 'Email',
        placeholder: 'Your email',
      },
      password: {
        label: 'Password',
      },
      rememder: {
        label: 'Remember me',
      },
      loginBtn: {
        text: 'Login',
      },
    },
    tfa: {
      title: 'Two-Factor Authentication',
      message: 'Provide your authentication code from <span>Google Authenticator</span> in the field below to complete sign in.',
      message_action: 'Provide your authentication code from <span>Google Authenticator</span> in the field below to complete action.',
      codeLabel: 'Code',
      trust: {
        label: 'Trust This Browser',
      },
      checkBtn: {
        text: 'Check Code',
      },
    },
    success: {
      message: 'Thank you, a two-factor authorization is successfully completed!',
      enterBtn: {
        text: 'Enter',
      },
    },
    LoginIsRequired: 'LOGIN AND PASSWORD IS REQUIRED',
    codeIsRequired: '2FA CODE IS REQUIRED',
  },
};
